import "react-circular-progressbar/dist/styles.css";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import {
  CloseComplaintStatus,
  InLight,
  OpenComplaintStatus,
  OutLight,
} from "../../assets/images/ImagesV2";
import Constants, { TASK_PRIORITY } from "../../shared/Constants";
import { Dispatch, bindActionCreators } from "redux";
import {
  dashboardComplaint,
  dashboardCounts,
  dashboardReport,
  getAllCompletedDailyTasks,
  getAllDailyTasks,
  getAllFollowUpDailyTasks,
  handleFilterSpecialTaskStatus,
  updateDashboardViewReportStatus,
  updateSidebar,
} from "../../shared/Reducers";

import AnimatedProgressProvider from "./AnimatedProgressProvider";
import { IDashboardData } from "../../shared/ActionTypes";
import { Navigate } from "react-router-dom";
import React from "react";
import { RootState } from "../../index";
import SideBar from "../../components/sidebar/SideBar";
import TopNav from "../../components/topnav/TopNav";
import ViewAllReport from "./ViewAllReport";
import { connect } from "react-redux";
import { easeQuadInOut } from "d3-ease";
import moment from "moment";

interface IDashboardProps {
  dashboardCounts: any;
  dashboardReport: any;
  Countdata: IDashboardData | null;
  reportData: any;
  getAllFollowUpDailyTasks: any;
  getAllCompletedDailyTasks: any;
  filteredDailyTaskData: any;
  followDailyTask: any;
  completeDailyTask: any;
  handleFilterSpecialTaskStatus: any;
  status: any;
  dashboardComplaint: any;
  complaintData: any;
  updateSidebar: any;
  updateDashboardViewReportStatus: any;
  getAllDailyTasks: any;
  userLoginDetails?: any;
}

export interface IDashboardState {
  viewReportType: string;
  redirect: string;
  filterData: any;
}

class Dashboard extends React.Component<IDashboardProps, IDashboardState> {
  constructor(props: IDashboardProps) {
    super(props);
    this.state = {
      redirect: "",
      viewReportType: "",
      filterData: 0,
    };
  }
  async componentDidMount() {
    this.props.updateSidebar(true);
    document.title = "Dashboard";
    let queryComplaint = `complaints?search=&sort=&order=&offset=0&limit=5&client=all&branch=all`;
    await this.props.dashboardComplaint(queryComplaint);

    console.log(this.props.complaintData);

    await this.props.dashboardReport();
  }

  componentDidUpdate(
    prevProps: Readonly<IDashboardProps>,
    prevState: Readonly<IDashboardState>,
    snapshot?: any
  ): void {
    if (this.props.filteredDailyTaskData !== prevProps.filteredDailyTaskData) {
      this.props.updateDashboardViewReportStatus(true);
    }
  }

  renderOverDue = (data: any) => {
    return data.map((row: any, index: number) => {
      let start: string = moment().format("YYYY-MM-DD"),
        end: string = moment(row.task_date, "DD-MM-YYYY").format("YYYY-MM-DD");
      var startDate = moment(start);
      var endDate = moment(end);

      var difference = endDate.diff(startDate, "days");

      return (
        <tr key={index}>
          <td>{row.branch_name}</td>
          <td>{row.title}</td>
          <td>{moment(row.task_date, "DD-MM-YYYY").format("DD/MM/YYYY")}</td>
          <td>
            <p
              className={
                row.priority === TASK_PRIORITY.LOW
                  ? "commented-option view-task-comment-badge"
                  : row.priority === TASK_PRIORITY.MEDIUM
                  ? "inprogress-badge view-task-comment-badge"
                  : row.priority === TASK_PRIORITY.HIGH
                  ? "created-badge view-task-comment-badge"
                  : ""
              }
            >
              {row.priority === TASK_PRIORITY.LOW
                ? "Low"
                : row.priority === TASK_PRIORITY.MEDIUM
                ? "Medium"
                : row.priority === TASK_PRIORITY.HIGH
                ? "High"
                : ""}
            </p>
          </td>
          <td>{difference} Days</td>
        </tr>
      );
    });
  };
  renderDue = (data: any) => {
    return data.map((row: any, index: number) => {
      let start: string = moment().format("YYYY-MM-DD"),
        end: string = moment(row.task_date, "DD-MM-YYYY").format("YYYY-MM-DD");
      var startDate = moment(start);
      var endDate = moment(end);

      var difference = endDate.diff(startDate, "days");

      return (
        <tr key={index}>
          <td>{row.branch_name}</td>
          <td>{row.title}</td>
          <td>{moment(row.task_date, "DD-MM-YYYY").format("DD/MM/YYYY")}</td>
          <td>
            <p
              className={
                row.priority === TASK_PRIORITY.LOW
                  ? "reopen-badge view-task-comment-badge"
                  : row.priority === TASK_PRIORITY.MEDIUM
                  ? "inprogress-badge view-task-comment-badge"
                  : row.priority === TASK_PRIORITY.HIGH
                  ? "todo-badge view-task-comment-badge"
                  : ""
              }
            >
              {row.priority === TASK_PRIORITY.LOW
                ? "Low"
                : row.priority === TASK_PRIORITY.MEDIUM
                ? "Medium"
                : row.priority === TASK_PRIORITY.HIGH
                ? "High"
                : ""}
            </p>
          </td>
          <td>{difference} Days</td>
        </tr>
      );
    });
  };
  renderFollowUp = (data: any) => {
    return data.map((row: any, index: number) => {
      return (
        <tr key={index}>
          <td>{row.branch_name}</td>
          <td>{row.title}</td>
          <td>{moment(row.task_date, "DD-MM-YYYY").format("DD/MM/YYYY")}</td>
          <td>
            <p
              className={
                row.priority === TASK_PRIORITY.LOW
                  ? "commented-option view-task-comment-badge"
                  : row.priority === TASK_PRIORITY.MEDIUM
                  ? "inprogress-badge view-task-comment-badge"
                  : row.priority === TASK_PRIORITY.HIGH
                  ? "created-badge view-task-comment-badge"
                  : ""
              }
            >
              {row.priority === TASK_PRIORITY.LOW
                ? "Low"
                : row.priority === TASK_PRIORITY.MEDIUM
                ? "Medium"
                : row.priority === TASK_PRIORITY.HIGH
                ? "High"
                : ""}
            </p>
          </td>
        </tr>
      );
    });
  };
  renderCompleted = (data: any) => {
    return data.map((row: any, index: number) => {
      return (
        <tr key={index}>
          <td>{row.branch_name}</td>
          <td>{row.title}</td>
          <td>{moment(row.task_date, "DD-MM-YYYY").format("DD/MM/YYYY")}</td>
          <td>
            <p
              className={
                row.priority === TASK_PRIORITY.LOW
                  ? "reopen-badge view-task-comment-badge"
                  : row.priority === TASK_PRIORITY.MEDIUM
                  ? "inprogress-badge view-task-comment-badge"
                  : row.priority === TASK_PRIORITY.HIGH
                  ? "created-badge view-task-comment-badge"
                  : ""
              }
            >
              {row.priority === TASK_PRIORITY.LOW
                ? "Low"
                : row.priority === TASK_PRIORITY.MEDIUM
                ? "Medium"
                : row.priority === TASK_PRIORITY.HIGH
                ? "High"
                : ""}
            </p>
          </td>
        </tr>
      );
    });
  };
  renderCleanerLogs = (data: any) => {
    return data.map((row: any, index: number) => {
      return (
        <div key={index} className="cleaner-singned">
          <table className="table m-0">
            <tbody>
              <tr>
                <td>
                  <p className="name m-0 fw-600 ">
                    {row.first_name} {row.last_name}
                  </p>
                </td>
                <td>
                  <p className="text-end m-0">
                    {row.sign_out_date_time === null ? (
                      <InLight />
                    ) : (
                      <OutLight />
                    )}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="branch m-0">{row.branch_name}</p>
                </td>
                <td>
                  <p className="time m-0 text-end">
                    {row.sign_out_date_time === null
                      ? moment(row.sign_in_date_time).format("hh:mm A")
                      : moment(row.sign_out_date_time).format("hh:mm A")}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    });
  };

  renderComplaints = (data: any) => {
    return data.map((row: any, index: number) => {
      return (
        <tr key={index}>
          <td>{row.name}</td>
          <td>{row.user_first_name + " " + row.user_last_name}</td>
          <td>{row.client_first_name + " " + row.client_last_name}</td>
          <td>{row.branch_name}</td>
          <td>
            {row.complaint_status === 1 ? (
              <OpenComplaintStatus />
            ) : (
              <CloseComplaintStatus />
            )}
          </td>
          <td>{moment(row.created_at).format("DD/MM/YYYY")}</td>
        </tr>
      );
    });
  };
  handleCheckboxChange = () => {
    const SignOut = document.getElementById(
      "exampleCheck2"
    ) as HTMLInputElement;
    const SignIn = document.getElementById("exampleCheck1") as HTMLInputElement;
    const isSignInChecked = SignIn.checked;
    const isSignOutChecked = SignOut.checked;

    console.log(isSignInChecked);
    console.log(isSignOutChecked);

    if (isSignInChecked && isSignOutChecked) {
      this.setState({ filterData: 0 });
    } else if (!isSignInChecked && !isSignOutChecked) {
      this.setState({ filterData: 0 });
    } else if (isSignInChecked) {
      const filteredLog = this.props.reportData.today_cleaner_logs.filter(
        (log: any) => log.sign_out_date_time === null
      );
      console.log("Filtered Logs in:", filteredLog);
      this.setState({ filterData: filteredLog });
    } else {
      const isChecked = SignOut.checked;
      const filteredLogs = this.props.reportData.today_cleaner_logs.filter(
        (log: any) => log.sign_out_date_time !== null
      );
      console.log("Filtered Logs out:", filteredLogs);
      this.setState({ filterData: filteredLogs });
    }
  };

  getViewTaskList = async (type: string) => {
    if (type === "overdue") {
      // list for table
      let query = `search=&sort=&order=&offset=${Constants.OFFSET}&limit=50&client=all&branch=all&task_type=all&task_priority=all&due_type=overdue&task_status=all&special_request_task_status=all&status&start_date=&end_date=`;

      await this.props.getAllDailyTasks(query);
    } else if (type === "due") {
      // list for table
      let query = `search=&sort=&order=&offset=${Constants.OFFSET}&limit=50&client=all&branch=all&task_type=all&task_priority=all&due_type=due&task_status=all&special_request_task_status=all&status&start_date=&end_date=`;

      await this.props.getAllDailyTasks(query);
    } else if (type === "completed") {
      // list for table
      let query = `search=&sort=&order=&offset=${Constants.OFFSET}&limit=50&client=all&branch=all&task_type=all&task_priority=all&due_type=all&task_status=${Constants.TASK_COMPLETED}&special_request_task_status=all&status&start_date=&end_date=`;

      await this.props.getAllDailyTasks(query);
    } else if (type === "followup") {
      // list for table
      let query = `search=&sort=&order=&offset=${Constants.OFFSET}&limit=50&client=all&branch=all&task_type=all&task_priority=all&due_type=all&task_status=all&special_request_task_status=${Constants.TASK_FOLLOWUP}&status&start_date=&end_date=`;

      await this.props.getAllDailyTasks(query);
    }
  };
  render(): React.ReactNode {
    console.log('jay pro',this.props)

    // Navigate to Home
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className={this.props.status ? "main-panel" : "main-panel2"}>
          <TopNav />
          <div className="content-wrapper">
            <div className="row">
              <div className="col-md-8 main-dashboard">
                <div className="container-fluid p-0">
                  {this.props.userLoginDetails?.permission
                    ?.dashboard_todaytask === 1 && (
                    <div className="row mb-4">
                      <div className="col-md-6 col-sm-6 col-xl-12 col-lg-12">
                        <div className="card">
                          <div className="card-body todaytaskbox p-4">
                            <p className="dashboard-title">Today Task</p>
                            <div className="d-flex flex-column flex-lg-row gap-5 pl-3 pr-3 pt-3 pb-2">
                              <div className="circlebox">
                                <AnimatedProgressProvider
                                  valueStart={0}
                                  valueEnd={
                                    this.props.reportData &&
                                    this.props.reportData.today_completed_count
                                  }
                                  duration={1.4}
                                  easingFunction={easeQuadInOut}
                                >
                                  {(value: any) => {
                                    const roundedValue = Math.round(value);
                                    return (
                                      <CircularProgressbar
                                        value={value}
                                        text={`${roundedValue}`}
                                        styles={buildStyles({
                                          pathTransition: "none",
                                          textColor: "#000000",
                                          trailColor: "#CED6FD",
                                          pathColor: "#5A75F8",
                                        })}
                                      />
                                    );
                                  }}
                                </AnimatedProgressProvider>
                                <p className="text-center mt-2 mb-0">
                                  Completed
                                </p>
                              </div>
                              {/* <div className="circlebox">
                                <AnimatedProgressProvider
                                  valueStart={0}
                                  valueEnd={this.props.reportData && this.props.reportData.today_progress_count}
                                  duration={1.4}
                                  easingFunction={easeQuadInOut}

                                >
                                  {(value: any) => {
                                    const roundedValue = Math.round(value);
                                    return (
                                      <CircularProgressbar
                                        value={value}
                                        text={`${roundedValue}`}
                                        styles={buildStyles({
                                          pathTransition: "none",
                                          textColor: '#000000',
                                          trailColor: '#CDEBFE',
                                          pathColor: '#10A1FC',
                                        })}
                                      />
                                    );
                                  }}
                                </AnimatedProgressProvider>
                                <p className="text-center mt-2 mb-0">Progress</p>
                              </div> */}
                              <div className="circlebox">
                                <AnimatedProgressProvider
                                  valueStart={0}
                                  valueEnd={
                                    this.props.reportData &&
                                    this.props.reportData.today_incomplete_count
                                  }
                                  duration={1.4}
                                  easingFunction={easeQuadInOut}
                                >
                                  {(value: any) => {
                                    const roundedValue = Math.round(value);
                                    return (
                                      <CircularProgressbar
                                        value={value}
                                        text={`${roundedValue}`}
                                        styles={buildStyles({
                                          pathTransition: "none",
                                          textColor: "#000000",
                                          trailColor: "#FAD1E4",
                                          pathColor: "#E83E8C",
                                        })}
                                      />
                                    );
                                  }}
                                </AnimatedProgressProvider>
                                <p className="text-center mt-2 mb-0">
                                  Incomplete
                                </p>
                              </div>
                              <div className="circlebox">
                                <AnimatedProgressProvider
                                  valueStart={0}
                                  valueEnd={
                                    this.props.reportData &&
                                    this.props.reportData.today_follow_up_count
                                  }
                                  duration={1.4}
                                  easingFunction={easeQuadInOut}
                                >
                                  {(value: any) => {
                                    const roundedValue = Math.round(value);
                                    return (
                                      <CircularProgressbar
                                        value={value}
                                        text={`${roundedValue}`}
                                        styles={buildStyles({
                                          pathTransition: "none",
                                          textColor: "#000000",
                                          trailColor: "#FFF1CC",
                                          pathColor: "#FFC833",
                                        })}
                                      />
                                    );
                                  }}
                                </AnimatedProgressProvider>
                                <p className="text-center mt-2 mb-0">
                                  FollowUp
                                </p>
                              </div>
                              <div className="circlebox">
                                <AnimatedProgressProvider
                                  valueStart={0}
                                  valueEnd={
                                    this.props.reportData &&
                                    this.props.reportData.today_resolve_count
                                  }
                                  duration={1.4}
                                  easingFunction={easeQuadInOut}
                                >
                                  {(value: any) => {
                                    const roundedValue = Math.round(value);
                                    return (
                                      <CircularProgressbar
                                        value={value}
                                        text={`${roundedValue}`}
                                        styles={buildStyles({
                                          pathTransition: "none",
                                          textColor: "#000000",
                                          trailColor: "#D8EFF3",
                                          pathColor: "#4BB6C6",
                                        })}
                                      />
                                    );
                                  }}
                                </AnimatedProgressProvider>
                                <p className="text-center mt-2 mb-0">
                                  Resolved
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    {this.props.userLoginDetails?.permission
                      ?.dashboard_overdue === 1 &&
                    this.props.reportData &&
                    this.props.reportData.over_due &&
                    this.props.reportData.over_due.length ? (
                      <div className="col-md-12 mb-4">
                        <div className="card ">
                          <div className="card-body p-4">
                            <p className="dashboard-title">Overdue Reports</p>
                            <div className="table-responsive">
                              <table className="dashboard-table table">
                                <thead>
                                  <tr>
                                    <th>Branch</th>
                                    <th>Task</th>
                                    <th>Date</th>
                                    <th>Priority</th>
                                    <th>Days</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.renderOverDue(
                                    this.props.reportData.over_due
                                  )}
                                </tbody>
                              </table>
                              <div>
                                <a
                                  onClick={() => {
                                    this.getViewTaskList("overdue");
                                    this.setState({
                                      viewReportType: "Overdue",
                                    });
                                  }}
                                  className="text-link cursor-pointer cursor-pointer"
                                >
                                  View alll...
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {this.props.userLoginDetails?.permission
                      ?.dashboard_followUp === 1 &&
                    this.props.reportData &&
                    this.props.reportData.follow_up &&
                    this.props.reportData.follow_up.length > 0 ? (
                      <div className="col-md-12 mb-4">
                        <div className="card">
                          <div className="card-body p-4">
                            <p className="dashboard-title">Follow Up Reports</p>
                            <div className="table-responsive">
                              <table className="dashboard-table table">
                                <thead>
                                  <tr>
                                    <th>Branch</th>
                                    <th>Task</th>
                                    <th>Date</th>
                                    <th>Priority</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.renderFollowUp(
                                    this.props.reportData.follow_up
                                  )}
                                </tbody>
                              </table>
                              <div>
                                <a
                                  onClick={() => {
                                    this.getViewTaskList("followup");
                                    this.setState({
                                      viewReportType: "Followup",
                                    });
                                  }}
                                  className="text-link cursor-pointer"
                                >
                                  View all...
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {this.props.userLoginDetails?.permission?.dashboard_due ===
                      1 &&
                    this.props.reportData &&
                    this.props.reportData.due &&
                    this.props.reportData.due.length ? (
                      <div className="col-md-12 mb-4">
                        <div className="card">
                          <div className="card-body p-4">
                            <p className="dashboard-title">Due Reports</p>
                            <div className="table-responsive">
                              <table className="dashboard-table table">
                                <thead>
                                  <tr>
                                    <th>Branch</th>
                                    <th>Task</th>
                                    <th>Date</th>
                                    <th>Priority</th>
                                    <th>Days</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.renderDue(this.props.reportData.due)}
                                </tbody>
                              </table>
                              <div>
                                <a
                                  // onClick={() => {
                                  //   this.setState({ redirect: "/task/due" });
                                  // }}
                                  onClick={() => {
                                    this.getViewTaskList("due");
                                    this.setState({ viewReportType: "Due" });
                                  }}
                                  className="text-link cursor-pointer"
                                >
                                  View all...
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {this.props.userLoginDetails?.permission
                      ?.dashboard_completed === 1 &&
                    this.props.reportData &&
                    this.props.reportData.completed &&
                    this.props.reportData.completed.length ? (
                      <div className="col-md-12 mb-4">
                        <div className="card">
                          <div className="card-body p-4">
                            <p className="dashboard-title">Completed Reports</p>
                            <div className="table-responsive">
                              <table className="dashboard-table table">
                                <thead>
                                  <tr>
                                    <th>Branch</th>
                                    <th>Task</th>
                                    <th>Date</th>
                                    <th>Priority</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.renderCompleted(
                                    this.props.reportData.completed
                                  )}
                                </tbody>
                              </table>
                              <div>
                                <a
                                  onClick={() => {
                                    this.getViewTaskList("completed");
                                    this.setState({
                                      viewReportType: "Completed",
                                    });
                                  }}
                                  className="text-link cursor-pointer"
                                >
                                  View all...
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {this.props.userLoginDetails?.permission
                      ?.dashboard_complaint === 1 &&
                    this.props.complaintData &&
                    this.props.complaintData.complaints &&
                    this.props.complaintData.complaints.length ? (
                      <div className="col-md-12 col-lg-12 mb-4">
                        <div className="card">
                          <div className="card-body p-4">
                            <p className="dashboard-title">Complaint Reports</p>
                            <div className="table-responsive">
                              <table className="dashboard-table table">
                                <thead>
                                  <tr>
                                    <th>Complaint Name</th>
                                    <th>User</th>
                                    <th>Client</th>
                                    <th>Branch</th>
                                    <th>Complaint status</th>
                                    <th>Created date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.renderComplaints(
                                    this.props.complaintData.complaints
                                  )}
                                </tbody>
                              </table>
                              <div>
                                <a
                                  onClick={() => {
                                    this.setState({
                                      redirect: "/complaints",
                                    });
                                  }}
                                  className="text-link cursor-pointer cursor-pointer"
                                >
                                  View all...
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              {this.props.userLoginDetails?.permission
                ?.dashboard_todaycleaner === 1
                 &&
                (Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
                  Constants.OPERATION_MANAGERUTINT ===
                    Constants.LOGIN_USER_TYPE ||
                  Constants.SUPERVISORUTINT === Constants.LOGIN_USER_TYPE ||
                  Constants.ADMINUTINT === Constants.LOGIN_USER_TYPE
                  
                  )
                   && ( 
                  <div className="col-md-4">
                    <div className="card fix-dash-right-card">
                      <div className="card-body p-4">
                        <p className="dashboard-title m-0">Today Cleaner</p>
                        <div className="d-flex  flex-column flex-lg-row gap-5 mt-1 p-3 cleaner-circle-box">
                          <div className="circlebox">
                            <AnimatedProgressProvider
                              valueStart={0}
                              valueEnd={
                                this.props.reportData &&
                                this.props.reportData.today_cleaner_logs
                                  .length -
                                  this.props.reportData.today_signedout_count
                              }
                              duration={1.4}
                              easingFunction={easeQuadInOut}
                            >
                              {(value: any) => {
                                const roundedValue = Math.round(value);
                                return (
                                  <CircularProgressbar
                                    value={value}
                                    text={`${roundedValue}`}
                                    styles={buildStyles({
                                      pathTransition: "none",
                                      textColor: "#000000",
                                      trailColor: "#D3F8E4",
                                      pathColor: "#1AB15E",
                                    })}
                                  />
                                );
                              }}
                            </AnimatedProgressProvider>
                            <p className="text-center mt-2 mb-0">
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="exampleCheck1"
                                  onChange={this.handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleCheck1"
                                >
                                  Signed In
                                </label>
                              </div>
                            </p>
                          </div>
                          <div className="circlebox">
                            <AnimatedProgressProvider
                              valueStart={0}
                              valueEnd={
                                this.props.reportData &&
                                this.props.reportData.today_signedout_count
                              }
                              duration={1.4}
                              easingFunction={easeQuadInOut}
                            >
                              {(value: any) => {
                                const roundedValue = Math.round(value);
                                return (
                                  <CircularProgressbar
                                    value={value}
                                    text={`${roundedValue}`}
                                    styles={buildStyles({
                                      pathTransition: "none",
                                      textColor: "#000000",
                                      trailColor: "#FAD1D9",
                                      pathColor: "#E41A41",
                                    })}
                                  />
                                );
                              }}
                            </AnimatedProgressProvider>
                            <p className="text-center mt-2 mb-0">
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="exampleCheck2"
                                  onChange={this.handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleCheck1"
                                >
                                  Signed Out
                                </label>
                              </div>
                            </p>
                          </div>
                        </div>
                        {this.props.reportData &&
                        this.props.reportData.today_cleaner_logs &&
                        this.props.reportData.today_cleaner_logs.length ? (
                          <div>
                            {this.renderCleanerLogs(
                              this.state.filterData !== 0
                                ? this.state.filterData
                                : this.props.reportData.today_cleaner_logs
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                 )} 
            </div>
          </div>

          <ViewAllReport reportType={this.state.viewReportType} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  Countdata: state.dashboard.Countdata,
  reportData: state.dashboard.reportData,
  complaintData: state.dashboard.complaintsData,
  filteredDailyTaskData: state.dailytask.filteredDailyTaskData,
  followDailyTask: state.dailytask.followDailyTask,
  completeDailyTask: state.dailytask.completeDailyTask,
  status: state.sidebar.status,
  userLoginDetails: state.sidebar.userLoginDetails,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dashboardCounts: bindActionCreators(dashboardCounts, dispatch),
    dashboardReport: bindActionCreators(dashboardReport, dispatch),
    getAllFollowUpDailyTasks: bindActionCreators(
      getAllFollowUpDailyTasks,
      dispatch
    ),
    getAllCompletedDailyTasks: bindActionCreators(
      getAllCompletedDailyTasks,
      dispatch
    ),
    handleFilterSpecialTaskStatus: bindActionCreators(
      handleFilterSpecialTaskStatus,
      dispatch
    ),
    dashboardComplaint: bindActionCreators(dashboardComplaint, dispatch),
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
    updateDashboardViewReportStatus: bindActionCreators(
      updateDashboardViewReportStatus,
      dispatch
    ),
    getAllDailyTasks: bindActionCreators(getAllDailyTasks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
