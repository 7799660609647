import React from "react";
import { RootState } from '../../index';
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AttachmentDownloadSVG } from "../../assets/images/ImagesSVG";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";
import docImage from "../../assets/images/google-docs.png";
import Constants from "../../shared/Constants";
import { DisableSVG, EnableSVG } from "../../assets/images/ImagesV2";
import AttachmentPreview from "../../shared/Components/AttachmentPreview";

class ViewQuotedTask extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            attachments: "",
            attachmentUrls: [],
            AttachmentPreviewStatus : false,
            previewUrl  : "",
            previewType : "",
        };
    }

    componentDidMount(): void {
        if (this.props.data) {

            this.setState({
                attachmentUrls: this.props.data.attachments.map((obj: any) => ({ url: `${Constants.imgThumbUrl}${obj.name}`, name: obj.name, id: obj.id, type: 'download', download_url: `${Constants.imgUrl}${obj.name}`, file_type: obj.type }))
            });
        }
    }

    downloadFile = (url: string, name = 'image.png') => {
        this.downloadResource(url, name);
    }

    forceDownload = (blob: any, filename: String) => {
        const a: any = document.createElement('a');
        a.download = filename;
        a.href = blob;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    // Current blob size limit is around 500MB for browsers
    downloadResource = (url: any, filename: any) => {
        if (url) {
            if (!filename) filename = url.split('\\').pop().split('/').pop();
            fetch(url, {
                headers: new Headers({
                    'Origin': window.location.origin
                }),
                mode: 'cors'
            })
                .then(response => response.blob())
                .then(blob => {
                    let blobUrl = window.URL.createObjectURL(blob);
                    this.forceDownload(blobUrl, filename);
                })
                .catch(e => console.error(e));
        }
    }

    handlePreviewClose = () => {
        this.setState({ AttachmentPreviewStatus: false });
    }
    handlePreviewOpen = (file_type: any, url: any) => {
        if (file_type === "image/png" || file_type === "image/jpeg" || file_type === "image/jpg" || file_type === "video/mp4" || file_type === "video/webm" || file_type === "application/pdf") {
            this.setState({
                AttachmentPreviewStatus: true,
                previewUrl: url,
                previewType: file_type
            });
        }
    }
    render(): React.ReactNode {
        const { attachments, first_name, last_name, end_date, start_date, status } = this.props.data;
        return (
            <div>
                <div className="row">
                    <div className="col-md-6">
                        <p className="mb-1">Client</p>
                        <p className="fw-600">{`${first_name} ${last_name}`}</p>
                    </div>
                    <div className="col-md-6">
                        <p className="mb-1">Status</p>
                        <p className="fw-600">{status === 1 ? <EnableSVG /> : <DisableSVG />}</p>
                    </div>
                    <div className="col-md-6">
                        <p className="mb-1">Start date</p>
                        <p className="fw-600">{start_date}</p>
                    </div>
                    <div className="col-md-6">
                        <p className="mb-1">End date</p>
                        <p className="fw-600">{end_date}</p>
                    </div>
                    <div className="col-md-12">
                        <p className="mb-1">Attachment</p>
                        <div className="row">
                            {this.state.attachmentUrls &&
                                this.state.attachmentUrls.map((obj: any, index: number) => (
                                    <div className="img-card mr-16px mb-3">
                                        {
                                            obj.type === 'download' && <span className="delete">
                                            </span>
                                        }

                                        <div className="img-preview" onClick={() => this.handlePreviewOpen(obj.file_type, Constants.imgUrl + obj.name)}>
                                            <img className="img-thumb-perview" src={
                                                obj.file_type === "application/pdf" ? pdfImage :
                                                    obj.file_type === "video/mp4" || obj.file_type === "video/webm" ? videoImage :
                                                        obj.file_type === "image/png" || obj.file_type === "image/jpeg" || obj.file_type === "image/jpg" ? obj.url : docImage
                                            } alt="attach" />
                                        </div>
                                    </div>
                                ))}
                            <AttachmentPreview displayPreview={this.state.AttachmentPreviewStatus} previewUrl={this.state.previewUrl} previewType={this.state.previewType} handlePreviewClose={this.handlePreviewClose} />

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state: RootState) => ({

})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewQuotedTask);