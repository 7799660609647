import React, { Component } from "react";

import AllComplaintCard from "./AllComplaintCard";
import { AllComplaintTableProps } from "../../types/allreport";
import AllReportCard from "./AllReportCard";

export default class AllComplaintTable extends Component<AllComplaintTableProps> {
  render() {
    return (
      <tbody>
        {this.props.data.map((complaints: any, index: any) => {
          return (
            <AllComplaintCard
              key={index + complaints.id}
              all_complaints={complaints}
            />
          );
        })}
      </tbody>
    );
  }
}
