import { AnyAction } from "redux";

export const UPDATE_SIDEBAR = 'UPDATE_SIDEBAR';
export const API_GET_LOGIN_USER_DETAILS = "get_login_user_details/LOAD";
export const API_GET_LOGIN_USER_DETAILS_SUCCESS = "get_login_user_details/LOAD_SUCCESS";
export const API_GET_LOGIN_USER_DETAILS_FAIL = "get_login_user_details/LOAD_FAIL";
export const API_GET_LOGIN_USER_PERMISSION = "get_login_user_permission/LOAD";
export const API_GET_LOGIN_USER_PERMISSION_SUCCESS = "get_login_user_permission/LOAD_SUCCESS";
export const API_GET_LOGIN_USER_PERMISSION_FAIL = "get_login_user_permission/LOAD_FAIL";

export type SidebarState = {
    status: boolean;
    userLoginDetails? : any;
    userLoginDetailsResStatus? : boolean;
    userLoginTaskstatus?: any;
    userLoginPermission?:any;
    userLoginTaskStatusPermission?:any;
}

// export type SidebarAction = {
//     type: string;
//     status: boolean;
// }

export type SidebarDispatchType = (args: AnyAction) => AnyAction;