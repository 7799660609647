import {
  CloseComplaintStatus,
  OpenComplaintStatus,
} from "../../assets/images/ImagesV2";
import { Dispatch, bindActionCreators } from "redux";
import React, { Component } from "react";

import { AllComplaintCardprops } from "../../types/allreport";
import { Button } from "react-bootstrap";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { getComplaint_ByIdForChat } from "../../shared/Reducers";
import moment from "moment";

class AllComplaintCard extends Component<AllComplaintCardprops> {
  render() {
    const {
      id,
      name,
      user_first_name,
      user_last_name,
      branch_name,
      client_first_name,
      client_last_name,
      complaint_status,
      unread_messages_count,
      created_at,
    } = this.props.all_complaints;
    return (
      <tr>
        <td>{name}</td>
        <td>{user_first_name + " " + user_last_name}</td>
        <td>{client_first_name + " " + client_last_name}</td>
        <td>{branch_name}</td>
        <td>
          {complaint_status === 1 ? (
            <OpenComplaintStatus />
          ) : (
            <CloseComplaintStatus />
          )}
        </td>
        <td>{moment(created_at).format("DD-MM-YYYY")}</td>
       
      </tr>
    );
  }
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getComplaint_ByIdForChat: bindActionCreators(
      getComplaint_ByIdForChat,
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AllComplaintCard);
