import { Dispatch, bindActionCreators } from "redux";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  getLoginUserDetails,
  getLoginUserPermission,
  getTaskStatusList,
  updateSubAdmin,
} from "./shared/Reducers/index";

import ActivateAcc from "./pages/loginSuccessful/ActivateAcc";
import AllReport from "./pages/All_Report/AllReport";
import AssignedTask from "./pages/DailyTask/DailyTask";
import Attendance from "./pages/Attendance/Attendance";
import Audit from "./pages/Audit/Audit";
import Branch from "./pages/Branch/Branch";
import CleanerCertificate from "./pages/CleanerCertificate/CleanerCertificate";
import CleanerContactForm from "./pages/CleanerContact/CleanerContactForm";
import CleanerHome from "./pages/CleanerHome/CleanerHome";
import CleanerInOut from "./pages/CleanerInOut/CleanerInOut";
import CleanerLogs from "./pages/CleanerLogs/CleanerLogs";
import ClientContract from "./pages/ClientContract/ClientContract";
import Complaints from "./pages/Complaint/Complaint";
import Constants from "./shared/Constants";
import ContractContactEditForm from "./pages/CleanerContact/CleanerContactEditForm";
import Contractor from "./pages/CleanerContact/Contractor";
import Dashboard from "./pages/Dashboard/Dashboard";
import DrawBoard from "./export/drawingBoard";
import EmailTemplate from "./pages/Email/EmailTemplate";
import ExportQuotedTask from "./pages/ExportQuotedTask/ExportQuotedTask";
import ForgotPassword from "./pages/forgotpassword/ForgotPassword";
import Invoice from "./pages/Invoice/Invoice";
import LoginSuccess from "./pages/loginSuccessful/LoginSuccess";
import Notification from "./pages/Notification/Notification";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import QuotedTask from "./pages/QuotedTask/QuotedTask";
import React from "react";
import ResetPassword from "./pages/forgotpassword/ResetPassword";
import { RootState } from "./index";
import Schedule from "./pages/Schedule/Schedule";
import Settings from "./pages/Settings/Settings";
import Recommended from "./pages/Recommended/Recommended";
import Shift from "./pages/Shift/Shift";
import SignUp from "./pages/signup/SignUp";
import Task from "./pages/Task/Task";
import ApprovedTask from "./pages/ApprovedTask/ApprovedTask";
import User from "./pages/UserManagement/Usermanagement";
import UserRole from "./pages/UserRole/UserRole";
import { connect } from "react-redux";
import { Certificate } from "crypto";
import CertificatePage from "./pages/Certificate/Certificate"

const Signin: any = React.lazy(() => import("./pages/signin/Signin"));

type Props = {
  token: string;
  updateSubAdmin: any;
  userLoginDetails?: any;
  getLoginUserDetails?: any;
  userLoginDetailsResStatus?: boolean;
  getTaskStatusList: any;
  getLoginUserPermission: any;
};

class App extends React.Component<Props> {
  componentDidMount(): void {
    this.props.getLoginUserDetails(Constants.LOGIN_USER_ID);
    this.props.getLoginUserPermission(Constants.LOGIN_USER_ID);
    this.props.getTaskStatusList();
    const subAdmin = localStorage.getItem("subAdmin");
    if (subAdmin) {
      this.props.updateSubAdmin(JSON.parse(subAdmin));
    }
  }
  // componentDidUpdate(
  //   prevProps: Readonly<Props>,
  //   prevState: Readonly<{}>,
  //   snapshot?: any
  // ): void {
  //   if (prevProps.userLoginDetails !== this.props.userLoginDetails) {
  //     this.props.getLoginUserDetails(Constants.LOGIN_USER_ID);
  //   }
  // }

  render(): React.ReactNode {
    const isNextNavigatePage = () => {
      let sidebar_permission: any = this.props.userLoginDetails?.permission;
      const sidebarKeysWithValue: string[] = Object.keys(sidebar_permission)
        .filter(
          (key) => key.startsWith("sidebar_") && sidebar_permission[key] === 1
        )
        .map((key) => key.replace("sidebar_", ""));
      if (sidebarKeysWithValue.length === 0) {
        return "/";
      } else {
        return "/" + sidebarKeysWithValue[0];
      }
    };
    const isLoggedIn = (location: string): boolean => {
      switch (location) {
        case "/attendance":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_attendance === 1
            ? true
            : false;
        case "/audit":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_audit === 1
            ? true
            : false;
        case "/in-out":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission
              ?.sidebar_branch_login_out === 1
            ? true
            : false;
        case "/branch":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_branch === 1
            ? true
            : false;
        case "/contractor":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission
              ?.sidebar_cleaner_contract === 1
            ? true
            : false;
        case "/home":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_cleaner_home === 1
            ? true
            : false;
        case "/client-contract":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_client_contract ===
            1
            ? true
            : false;
        case "/complaints":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_complaints === 1
            ? true
            : false;
        case "/":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_dashboard === 1
            ? true
            : this.props.userLoginDetails?.permission?.sidebar_cleaner_home ===
              1
              ? true
              : false;
        case "/dashboard":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_dashboard === 1
            ? true
            : false;
        case "/recommended":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_recommended ===
            1
            ? true
            : false;
        case "/add-certificate":
            return this.props.userLoginDetailsResStatus === false ||
              this.props.userLoginDetails?.permission?.sidebar_email_template ===
                1
                ? true
                : false;
        case "/email-template":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_email_template ===
            1
            ? true
            : false;
        case "/notification":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_notification === 1
            ? true
            : false;
        case "/quoted-tasks":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_quated_task === 1
            ? true
            : false;
        case "/export-quoted-tasks":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_quated_task === 1
            ? true
            : false;
        case "/invoice":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_report === 1
            ? true
            : false;
        case "/report":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_report === 1
            ? true
            : false;
        case "/cleaner-log":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_report === 1
            ? true
            : false;
        case "/settings":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_settings === 1
            ? true
            : false;
        case "/add-certificae":
            return this.props.userLoginDetailsResStatus === false ||
                this.props.userLoginDetails?.permission?.sidebar_schedule === 1
                ? true
                : false;
        case "/schedule":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_schedule === 1
            ? true
            : false;
        case "/make-schedule":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_make_schedule === 1
            ? true
            : false;
        case "/shift":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_shift === 1
            ? true
            : false;
        case "/task-template":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_task_template === 1
            ? true
            : false;
        case "/task":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_task === 1
            ? true
            : false;
        case "/approvedtask":
          return true;
        case "/user":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_user === 1
            ? true
            : false;
        case "/userrole":
          return this.props.userLoginDetailsResStatus === false ||
            this.props.userLoginDetails?.permission?.sidebar_user_role === 1
            ? true
            : false;
        case "/privacy":
        case "/from-signup/:user":
        case "/signup":
        case "/forgotpassword":
        case "/activate-account":
        case "/reset-password":
        case "/loginsuccess":
          return true;
        default:
          return false;
      }
    };

    return (
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              isLoggedIn("/") === false ? (
                <Signin />
              ) : Constants.LOGIN_USER_TYPE === Constants.CLEANERUTINT ? (
                <Navigate to="/home" replace />
              ) : (
                <Navigate to="/dashboard" replace />
              )
            }
          />
          <Route path="/from-signup/:user" element={<Signin />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/activate-account" element={<ActivateAcc />} />
          <Route path="/cleaner-certificate" element={<CleanerCertificate />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/loginsuccess" element={<LoginSuccess />} />
          <Route
            path="/dashboard"
            element={
              isLoggedIn("/dashboard") ? (
                <Dashboard />
              ) : (
                <>
                  {this.props.userLoginDetails?.permission
                    ?.sidebar_dashboard === 0 ? (
                    <Navigate to={isNextNavigatePage()} replace />
                  ) : (
                    <Navigate to="/" replace />
                  )}
                </>
              )
            }
          />
          <Route
            path="/userrole"
            element={
              isLoggedIn("/userrole") ? (
                <UserRole />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/user"
            element={
              isLoggedIn("/user") ? <User /> : <Navigate to="/" replace />
            }
          />
          <Route
            path="/client-contract"
            element={
              isLoggedIn("/client-contract") ? (
                <ClientContract />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/contractor"
            element={
              isLoggedIn("/contractor") ? (
                <Contractor />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/quoted-tasks"
            element={
              isLoggedIn("/quoted-tasks") ? (
                <QuotedTask />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/export-quoted-tasks"
            element={
              isLoggedIn("/export-quoted-tasks") ? (
                <ExportQuotedTask />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/branch"
            element={
              isLoggedIn("/branch") ? <Branch /> : <Navigate to="/" replace />
            }
          />
          <Route
            path="/audit"
            element={
              isLoggedIn("/audit") ? <Audit /> : <Navigate to="/" replace />
            }
          />
          <Route
            path="/task-template"
            element={
              isLoggedIn("/task-template") ? (
                <Task />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/approvedtask"
            element={
              isLoggedIn("/approvedtask") ? (
                <ApprovedTask />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/schedule"
            element={
              isLoggedIn("/schedule") ? (
                <Schedule />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/make-schedule"
            element={
              isLoggedIn("/make-schedule") ? (
                <Schedule />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/task"
            element={
              isLoggedIn("/task") ? (
                <AssignedTask />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/task/overdue"
            element={
              isLoggedIn("/task") ? (
                <AssignedTask />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/task/due"
            element={
              isLoggedIn("/task") ? (
                <AssignedTask />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/task/followup"
            element={
              isLoggedIn("/task") ? (
                <AssignedTask />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/task/completed"
            element={
              isLoggedIn("/task") ? (
                <AssignedTask />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/cleaner-log"
            element={
              isLoggedIn("/cleaner-log") ? (
                <CleanerLogs />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/invoice"
            element={
              isLoggedIn("/invoice") ? <Invoice /> : <Navigate to="/" replace />
            }
          />
          <Route
            path="/report"
            element={
              isLoggedIn("/report") ? (
                <AllReport />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/shift"
            element={
              isLoggedIn("/shift") ? <Shift /> : <Navigate to="/" replace />
            }
          />
          <Route
            path="/attendance"
            element={
              isLoggedIn("/attendance") ? (
                <Attendance />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/complaints"
            element={
              isLoggedIn("/complaints") ? (
                <Complaints />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/notification"
            element={
              isLoggedIn("/notification") ? (
                <Notification />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/email-template"
            element={
              isLoggedIn("/email-template") ? (
                <EmailTemplate />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
              <Route
            path="/add-certificate"
            element={
              //  isLoggedIn("/add-certificate") ? (
                <CertificatePage />
              // ) : (
              //   <Navigate to="/" replace />
              // )
            }
          />
          <Route path="/privacy" element={<PrivacyPolicy />} />

          <Route
            path="/home"
            element={
              isLoggedIn("/home") ? (
                <CleanerHome />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/in-out"
            element={
              isLoggedIn("/in-out") ? (
                <CleanerInOut />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/contract-contact-form"
            element={<CleanerContactForm />}
          />
          <Route
            path="/contract-contact-edit-form"
            element={<ContractContactEditForm />}
          />
          <Route
            path="/settings"
            element={
              isLoggedIn("/settings") ? (
                <Settings />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/recommended"
            element={
              isLoggedIn("/recommended") ? (
                <Recommended />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route path="/drawing-image" element={<DrawBoard />} />
        </Routes>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  token: state.app.token,
  userLoginDetails: state.sidebar.userLoginDetails,
  userLoginDetailsResStatus: state.sidebar.userLoginDetailsResStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateSubAdmin: bindActionCreators(updateSubAdmin, dispatch),
    getLoginUserDetails: bindActionCreators(getLoginUserDetails, dispatch),
    getLoginUserPermission: bindActionCreators(
      getLoginUserPermission,
      dispatch
    ),
    getTaskStatusList: bindActionCreators(getTaskStatusList, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
