import React from "react";
import { Formik, Field, Form, ErrorMessage, FieldProps } from "formik";
import Constants, { USER_ROLE } from "../../shared/Constants";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import {
  IAddUsermanagementProps,
  IAddUsermanagementState,
  IUsermanagementPayload,
} from "../../types/userManagement.type";
import { bindActionCreators, Dispatch } from "redux";
import {
  addUsermanagement,
  listAllClient,
  listAllBranch,
  getAllCertificates,
  getUserTypesList
} from "../../shared/Reducers/index";
import { RootState } from "../../index";
import { CustomSelect } from "../../shared/Components/CustomSelect";
// import { IBranchSelect, ICleaner } from "../../shared/ActionTypes";
import { connect } from "react-redux";
import {
  EyeOffSVG,
  EyeSVG,
  PlusSVG,
  PlusCloseSVG,
} from "../../assets/images/UsermanagementsSVG";
import Select from "react-select";
import { AlertToastSVG } from "../../assets/images/ImagesV2";
import { CloseBtnSVG } from "../../assets/images/ImagesSVG";
import { Modal, ProgressBar, Toast } from "react-bootstrap";
import { AppState, } from "../../shared/ActionTypes";

const reactSelectCusStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#fff",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    },
  }),
};

class AddUsermanagement extends React.Component<
  IAddUsermanagementProps,
  IAddUsermanagementState
> {
  constructor(props: IAddUsermanagementProps) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      status: "",
      branch: null,
      user_type: "",
      password: "",
      hidden: true,

      addBranches: [
        {
          options: [],
        },
      ],
      selectClientInx: null,
      selectClientId: null,
      selectClientStatus: false,
      clientList: [],
      addToast: false,
      clientSelectErrorShow: false,
      branchSelectErrorShow: false,
      userTypesStateList: [],
      branchCondition: "",
      cleanerDocumentShow: false,
      clearnerCertificateList: [],
      cleaner_certificate: [],
      cleanerValidate: false
    };
  }

  componentDidMount(): void {
    (async () => {
      await this.listData();
      await this.props.getUserTypesList();
      await this.props.getCertificateList();
      this.setState({ userTypesStateList: this.props.userTypesList.filter((Utypes: any) => Utypes.id !== Constants.ADMIN) })
      const clearnerCertificateList = this.props.getCeritficateData.map((certi: any) => ({
        label: certi.name,
        value: certi.id
      }));
      console.log(clearnerCertificateList);
      
      this.setState({ clearnerCertificateList:clearnerCertificateList });
    })();

  }

  componentDidUpdate(
    prevProps: Readonly<IAddUsermanagementProps>,
    prevState: Readonly<IAddUsermanagementState>,
    snapshot?: any
  ): void {
    if (
      prevProps.addUsermanagementError !== this.props.addUsermanagementError
    ) {
      if (this.props.addUsermanagementError === "Email already exist") {
        this.setState({ addToast: true });
        setTimeout(() => {
          this.setState({ addToast: false });
        }, 5000);
      }
    }
    if (prevProps.processTrigger !== this.props.processTrigger) {
    }
    if (
      prevProps.addUsermanagementSuccess !== this.props.addUsermanagementSuccess
    ) {
    }
  }

  addToastClose = () => {
    this.setState({
      addToast: false,
    });
  };

  listData = async () => {
    await this.props.listAllClient();
    // let clientList = this.props.clientList.map((client: any) => ({ value: client.id, label: client.first_name + " " + client.last_name }));
    // this.setState({ clientList: clientList });
  };

  // Password Eye Show/Hide
  toggleShow = (e: any) => {
    e.preventDefault();
    this.setState({ hidden: !this.state.hidden });
  };

  handleOnChangeClient = async (id: number, inx: number) => {
    await this.props.listAllBranch(id);

    this.setState({ selectClientInx: inx });
    this.setState({ selectClientId: id });
    this.setState({ selectClientStatus: true });
    this.setState({ clientSelectErrorShow: false });
  };

  handleOnChangeBranch = async (e: any, inx: number) => {
    let addBranches = this.state.addBranches;
    addBranches[inx].branch = e;


    this.setState({ addBranches: [...addBranches] });
    console.log(this.state.addBranches);

    this.setState({ branchSelectErrorShow: false });
  };

  handleAddMultiSelect = () => {
    let addBranchesArray = [
      {
        options: [],
      },
    ];
    this.setState({
      addBranches: [...this.state.addBranches, ...addBranchesArray],
    });
  };

  onUserRoleChangeHandle = (val: any, value: any) => {
    this.setState({  cleanerDocumentShow: false });
    this.setState({ clientSelectErrorShow: false });
    this.setState({ branchSelectErrorShow: false });
    this.setState({ branchCondition: val.branchaccess })
    this.setState({ user_type: val.value });
    // let addBranchesArray = [{
    //     options: []
    // }];

    this.setState({
      addBranches: [
        {
          options: [],
        },
      ],
    });
    if (val.value === Constants.CLEANERUTINT || val.value === Constants.CLIENTUTINT) {
      this.setState({ addBranches: 0, clientSelectErrorShow: false });
    }
    if (val.value === Constants.CLEANERUTINT ) {
      this.setState({  cleanerDocumentShow: true });
    }
  };

  handleCloseMultiSelect = (inx: number) => {
    let addBranches = this.state.addBranches;
    addBranches.splice(inx, 1);

    this.setState({ addBranches: addBranches });
  };

  // Add Usermanagement
  handleAddUsermanagement = async (formValue: IAddUsermanagementState) => {

    if(Constants.CLEANER_UT ==this.state.user_type) {
      console.log(formValue.cleaner_certificate, "cleaner");
      
      if(formValue.cleaner_certificate.length>0){
       this.setState({cleanerValidate: false})
      }else{
       return this.setState({cleanerValidate: true})
      }
    }
    let assigned_branches_array: any = [];
    if (this.state.addBranches.length === 0 && this.state.branchCondition !== "") {
      return false;
    }
    console.log(this.state.addBranches);

    if (this.state.addBranches.length > 0) {
      await this.state.addBranches.map(async (val: any, index: number) => {
        let branchList: any = [];
        console.log(val.branch);
        console.log(val.branch.length);

        if (val.branch !== undefined) {
          if (val.branch.length > 0) {
            await val.branch.map((brn_val: any, index: number) => {
              branchList.push(brn_val.value);
            });
          } else {
            branchList.push(val.branch?.value);
          }
        }

        if (val.client_id === undefined || branchList.length === 0) {
          return false;
        }
        let assigned_branches = {
          client: val.client_id,
          branches: branchList,
        };
        console.log(assigned_branches);

        assigned_branches_array.push(assigned_branches);
      });
    }

    console.log(formValue);

    const payload: IUsermanagementPayload = {
      first_name: formValue.first_name,
      last_name: formValue.last_name,
      email: formValue.email,
      phone: formValue.phone,
      password: formValue.password,
      user_type: this.state.user_type,
      assigned_branches: assigned_branches_array,
      status: formValue.status,
      certificates: formValue.cleaner_certificate
    };
    await this.props.addUsermanagement(payload);
    // if (  
    //   this.state.user_type = Constants.SUPERVISOR_UT ||
    //   this.state.user_type = Constants.OPERATION_MANAGER_UT
    // ) {
    //   let assigned_branches_array: any = [];
    //   if (this.state.addBranches.length === 0) {
    //     return false;
    //   }
    //   await this.state.addBranches.map(async (val: any, index: number) => {
    //     console.log(this.state.addBranches,"today");

    //     let branchList: any = [];

    //     if (val.branch !== undefined) {
    //       await val.branch.map((brn_val: any, index: number) => {
    //         branchList.push(brn_val.value);
    //       });
    //     }

    //     if (val.client_id === undefined || branchList.length === 0) {
    //       return false;
    //     }

    //     let assigned_branches = {
    //       client: val.client_id,
    //       branches: branchList,
    //     };
    //     assigned_branches_array.push(assigned_branches);
    //   });
    //   const payload: IUsermanagementPayload = {
    //     first_name: formValue.first_name,
    //     last_name: formValue.last_name,
    //     email: formValue.email,
    //     phone: formValue.phone,
    //     password: formValue.password,
    //     user_type: this.state.user_type,
    //     assigned_branches: assigned_branches_array,
    //     status: formValue.status,
    //   };
    //   await this.props.addUsermanagement(payload);
    // } else if (
    //   this.state.user_type = Constants.MANAGER_UT ||
    //   this.state.user_type = Constants.QA_MANAGER_UT ||
    //   this.state.user_type = Constants.SUPERVISOR_MANAGER_UT
    // ) {
    //   let assigned_branches_array: any = [];

    //   if (this.state.addBranches.length === 0) {
    //     return false;
    //   }
    //   await this.state.addBranches.map(async (val: any, index: number) => {
    //     let branchList: any = [];

    //     if (val.branch !== undefined) {
    //       await val.branch.map((brn_val: any, index: number) => {
    //         branchList.push(brn_val.value);
    //       });
    //     }
    //     if (val.client_id === undefined || branchList.length === 0) {
    //       return false;
    //     }
    //     assigned_branches_array = {
    //       client: val.client_id,
    //       branches: branchList,
    //     };
    //   });
    //   if (assigned_branches_array.length === 0) {
    //     return false;
    //   }
    //   const payload: IUsermanagementPayload = {
    //     first_name: formValue.first_name,
    //     last_name: formValue.last_name,
    //     email: formValue.email,
    //     phone: formValue.phone,
    //     password: formValue.password,
    //     user_type: this.state.user_type,
    //     assigned_branches: assigned_branches_array,
    //     status: formValue.status,
    //   };
    //   await this.props.addUsermanagement(payload);
    // } else if (this.state.user_type = Constants.QA_UT) {
    //   let clientID = "";
    //   let branchId = "";
    //   if (this.state.addBranches.length === 0) {
    //     return false;
    //   }
    //   await this.state.addBranches.map(async (val: any, index: number) => {
    //     if (val.branch !== undefined) {
    //       branchId = val.branch.value;
    //     }

    //     if (val.client_id == undefined || branchId === "") {
    //       return false;
    //     }
    //     clientID = val.client_id;
    //   });
    //   if (clientID === "" || branchId === "") {
    //     return false;
    //   }
    //   const payload: IUsermanagementPayload = {
    //     client: clientID,
    //     branch: branchId,
    //     first_name: formValue.first_name,
    //     last_name: formValue.last_name,
    //     email: formValue.email,
    //     phone: formValue.phone,
    //     password: formValue.password,
    //     user_type: this.state.user_type,
    //     status: formValue.status,
    //   };
    //   console.log(payload);

    //   await this.props.addUsermanagement(payload);
    // } else {
    //   const payload: IUsermanagementPayload = {
    //     first_name: formValue.first_name,
    //     last_name: formValue.last_name,
    //     email: formValue.email,
    //     phone: formValue.phone,
    //     password: formValue.password,
    //     user_type: this.state.user_type,
    //     status: formValue.status,
    //   };
    //   await this.props.addUsermanagement(payload);
    // }
  };

  handleChangeUserForm = (e: any) => {
    // console.log(e);
  };

  // Formik Validation Schema
  validationSchema = () => {
    // const branchId = this.props.branchList.map((branch: IBranchSelect) => branch.value)
    // const clientId = this.props.clientList.map((client: ICleaner) => client.id);

    // if (
    //   // this.state.user_type == Constants.MANAGER_UT ||
    //   // this.state.user_type == Constants.SUPERVISOR_UT ||
    //   // this.state.user_type == Constants.QA_UT ||
    //   // this.state.user_type == Constants.QA_MANAGER_UT ||
    //   // this.state.user_type == Constants.SUPERVISOR_MANAGER_UT ||
    //   // this.state.user_type == Constants.OPERATION_MANAGER_UT
    //   this.state.userTypesStateList === 

    // ) {

    // }
    // this.state.addBranches.map(async (val: any, index: number) => {
    //   console.log(val);

    //   if (val.client_id === undefined) {
    //     this.setState({ clientSelectErrorShow: true });
    //   }
    //   if (val.options.length === 0) {
    //     this.setState({ branchSelectErrorShow: true });
    //   }
    // });

    if (this.state.branchCondition !== "") {
      this.state.addBranches.map(async (val: any, index: number) => {

        if (val.client_id === undefined) {
          this.setState({ clientSelectErrorShow: true });
        }
        if (val.options.length === 0) {
          this.setState({ branchSelectErrorShow: true });
        }
      });
    }




    if (this.state.user_type == Constants.CLIENT_UT) {
      return Yup.object().shape({
        user_type: Yup.string().required("Select User type"),
        first_name: Yup.string()
          .required("Enter a valid First Name")
          .test(
            "no-special-characters",
            "Special characters are not allowed",
            (val: any) => !/[!@#$%^&*(),.?":{}|<>]/.test(val)
          )
          .test(
            "len",
            "Enter First Name",
            (val: any) => val && val.toString() !== "" && val.toString().replace(/\s/g, "") !== ""
          ),
        last_name: Yup.string()
          .test(
            "no-special-characters",
            "Special characters are not allowed",
            (val: any) => !/[!@#$%^&*(),.?":{}|<>]/.test(val)
          )
          .test(
            "len",
            "Enter Last Name",
            (val: any) => val && val.toString() !== "" && val.toString().replace(/\s/g, "") !== ""
          ),
        email: Yup.string()
          .email("Enter valid Email address")
          .required("Enter Email"),
        phone: Yup.string()
          .required("Enter Mobile number")
          .test(
            "len",
            "Enter minimum 10 digit Phone number",
            (val: any) => val && val.toString().length >= 10
          ),
      });
    } else {
      return Yup.object().shape({
        user_type: Yup.string().required("Select User type"),
        first_name: Yup.string()
          .required("Enter a valid First Name")
          .test(
            "no-special-characters",
            "Special characters are not allowed",
            (val: any) => !/[!@#$%^&*(),.?":{}|<>]/.test(val)
          )
          .test(
            "len",
            "Enter First Name",
            (val: any) => val && val.toString() !== "" && val.toString().replace(/\s/g, "") !== ""
          ),
        last_name: Yup.string()
          .test(
            "no-special-characters",
            "Special characters are not allowed",
            (val: any) => !/[!@#$%^&*(),.?":{}|<>]/.test(val)
          )
          .test(
            "len",
            "Enter Last Name",
            (val: any) => val && val.toString() !== "" && val.toString().replace(/\s/g, "") !== ""
          ),
        email: Yup.string()
          .email("Enter valid Email address")
          .required("Enter Email"),
        password: Yup.string()
          .required("Enter Password")
          .test(
            "len",
            "Enter min 8 Character",
            (val: any) =>
              val &&
              val.toString().length >= 8 &&
              val.toString().replace(/\s/g, "") !== ""
          )
          .test(
            "len",
            "Enter max 16 Character",
            (val: any) =>
              val &&
              val.toString().length <= 16 &&
              val.toString().replace(/\s/g, "") !== ""
          ),
        phone: Yup.string()
          .required("Enter Phone number")
          .test(
            "len",
            "Enter minimum 10 digit Phone number",
            (val: any) => val && val.toString().length >= 10
          ),
      });
    }
  };

  // validationSchema = () => {
  //     return Yup.object().shape({
  //         user_type: Yup.string().required("Select User type"),
  //         phone: Yup.string()
  //             .required("Enter Phone number")
  //             .test(
  //                 "len",
  //                 "Enter minimum 10 digit Phone number",
  //                 (val: any) =>
  //                     val &&
  //                     val.toString().length >= 10
  //             ),
  //     })
  // }

  handlebranch = () => {
    if (
      this.state.branchCondition === "single_client_multi_branch"
    ) {
      return (
        <>
          <div className="col-md-12">
            <div className="row mt-3">
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Client<span className="text-project">*</span>
                </label>
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Branch (you can select multiple)
                  <span className="text-project">*</span>
                </label>
              </div>
            </div>
          </div>
          {this.state.addBranches &&
            this.state.addBranches.map((val: any, index: number) => (
              <div className="col-md-12">
                <div className="row">
                  <div className="mb-2 col-md-6">
                    <Select
                      styles={reactSelectCusStyles}
                      placeholder="Select Client"
                      isMulti={false}
                      options={this.props.clientList.map((client: any) => ({
                        value: client.id,
                        label: client.first_name + " " + client.last_name,
                      }))}
                      id={"select-client-" + index}
                      onChange={(e: any) =>
                        this.handleOnChangeClient(e.value, index)
                      }
                    />
                  </div>
                  <div className="mb-2 col-md-6">
                    <Select
                      styles={reactSelectCusStyles}
                      placeholder="Select Branch"
                      id={"select-branch-" + index}
                      isMulti
                      value={val.branch}
                      options={val.options}
                      onChange={(e: any) =>
                        this.handleOnChangeBranch(e, index)
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
        </>
      );
    }
    else if (this.state.branchCondition === "single_client_branch"
    ) {
      return (
        <>
          <div className="col-md-12">
            <div className="row mt-3">
              <div className="mb-2 col-md-6">
                {/* <label className="form-label">
                  Client<span className="text-project">*</span>
                </label> */}
              </div>
              <div className="mb-2 col-md-6">
                {/* <label className="form-label">
                  Branch (you can select multiple)
                  <span className="text-project">*</span>
                </label> */}
              </div>
            </div>
          </div>
          {this.state.addBranches &&
            this.state.addBranches.map((val: any, index: number) => (
              <div className="row">
                <div className="mb-2 col-md-6">
                  <label className="form-label">
                    Client<span className="text-project">*</span>
                  </label>
                  <Select
                    styles={reactSelectCusStyles}
                    placeholder="Select Client"
                    isMulti={false}
                    options={this.props.clientList.map((client: any) => ({
                      value: client.id,
                      label: client.first_name + " " + client.last_name,
                    }))}
                    onChange={(e: any) =>
                      this.handleOnChangeClient(e.value, index)
                    }
                  />
                </div>
                <div className="mb-2 col-md-6">
                  <label className="form-label">
                    Branch<span className="text-project">*</span>
                  </label>
                  <Select
                    styles={reactSelectCusStyles}
                    placeholder="Select Branch"
                    id={"select-branch-" + index}
                    value={val.branch}
                    options={val.options}
                    onChange={(e: any) =>
                      this.handleOnChangeBranch(e, index)
                    }
                  />
                </div>
              </div>
            ))}
        </>
      );
    }
    else if (this.state.branchCondition === "multi_client_branch") {
      return (
        <>
          <div className="col-md-12">
            <div className="row mt-3">
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Client<span className="text-project">*</span>
                </label>
              </div>
              <div className="mb-2 col-md-5">
                <label className="form-label">
                  Branch (you can select multiple)
                  <span className="text-project">*</span>
                </label>
              </div>
              <div className="mb-2 col-md-1 ">
                <button
                  onClick={this.handleAddMultiSelect}
                  type="button"
                  className="btn btn-primary plus-btn"
                >
                  <PlusSVG />
                </button>
              </div>
            </div>
          </div>
          {this.state.addBranches &&
            this.state.addBranches.map((val: any, index: number) => (
              <div className="col-md-12">
                <div className="row">
                  <div className="mb-2 col-md-6">
                    <Select
                      styles={reactSelectCusStyles}
                      placeholder="Select Client"
                      isMulti={false}
                      options={this.props.clientList.map((client: any) => ({
                        value: client.id,
                        label: client.first_name + " " + client.last_name,
                      }))}
                      id={"select-client-" + index}
                      onChange={(e: any) =>
                        this.handleOnChangeClient(e.value, index)
                      }
                    />
                  </div>
                  <div className="mb-2 col-md-5">
                    <Select
                      styles={reactSelectCusStyles}
                      placeholder="Select Branch"
                      id={"select-branch-" + index}
                      isMulti
                      value={val.branch}
                      options={val.options}
                      onChange={(e: any) =>
                        this.handleOnChangeBranch(e, index)
                      }
                    />
                  </div>
                  <div className="mb-2 col-md-1">
                    <a
                      className="close-new-btn"
                      onClick={() => this.handleCloseMultiSelect(index)}
                    >
                      <PlusCloseSVG />
                    </a>
                  </div>
                </div>
              </div>
            ))}
        </>
      )

    } else {
      return null;
    }

  }
  render(): React.ReactNode {
    if (
      this.props.branchList.length > 0 &&
      this.state.selectClientStatus === true
    ) {

      if (this.props.branchList[0].client_id === this.state.selectClientId) {
        let addBranches = this.state.addBranches;
        addBranches[this.state.selectClientInx].options = this.props.branchList;
        addBranches[this.state.selectClientInx].client_id =
          this.state.selectClientId;
        addBranches[this.state.selectClientInx].branch = [];

        this.setState({ selectClientStatus: false });
        this.setState({ addBranches: [...addBranches] });
      } else {
        let addBranches = this.state.addBranches;

        if (addBranches[this.state.selectClientInx].options.length > 0) {
          addBranches[this.state.selectClientInx].options = [];
          this.setState({ addBranches: [...addBranches] });
        }
      }
    }

    const userActiveStatus = [
      { value: Constants.ENABLE, label: "Enable" },
      { value: Constants.DISABLE, label: "Disable" },
    ];


    const initialValues: IAddUsermanagementState = {
      addBranches: [
        {
          options: [],
        },
      ],
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      branch: null,
      password: "",
      status: Constants.ACTIVE,
      user_type: "",
      userTypesStateList: [],
      branchCondition: "",
      cleanerDocumentShow: false,
      clearnerCertificateList: [],
      cleaner_certificate: [],
      cleanerValidate: false
    };
    return (
      <div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={this.handleAddUsermanagement}
          validationSchema={this.validationSchema}
        >
          <Form onChange={this.handleChangeUserForm}>
            <div className="row">
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Role<span className="text-project">*</span>
                </label>
                <Field
                  className="custom-select dropdown-field"
                  name="user_type"
                  options={this.state.userTypesStateList.map((Utype: any) => {
                    return (
                      { value: Utype.id, label: Utype.name.replace(/_/g, ' ').replace(/\b\w/g, (match: any) => match.toUpperCase()), branchaccess: Utype.clientbranchaccess }
                    )

                  }
                  )}
                  component={CustomSelect}
                  placeholder="Select user role"
                  isMulti={false}

                  onChange={(e: any) => this.onUserRoleChangeHandle(e, e.value)}
                />

                {/* <ErrorMessage
                  name="user_type"
                  component={"div"}
                  className="cus-alert"
                /> */}
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  First name<span className="text-project">*</span>
                </label>
                <Field
                  name="first_name"
                  type={"text"}
                  className="form-control usermanagement-input"
                />
                <ErrorMessage
                  name="first_name"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">Last name</label>
                <Field
                  name="last_name"
                  type={"text"}
                  className="form-control usermanagement-input"
                />
                <ErrorMessage
                  name="last_name"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Email<span className="text-project">*</span>
                </label>
                <Field
                  name="email"
                  type={"text"}
                  className="form-control usermanagement-input"
                />
                <ErrorMessage
                  name="email"
                  component={"div"}
                  className="cus-alert"
                />
                {/* {this.props.addUsermanagementError === "Email already exist" && <div className="cus-alert">{this.props.addUsermanagementError}</div>} */}
                {this.state.addToast ? (
                  <Toast className="yellow-alert">
                    <Toast.Body>
                      <div className="row align-items-center">
                        <div className="col-md-2">
                          <AlertToastSVG />
                        </div>
                        <div className="col-md-8">
                          {this.props.addUsermanagementError}
                        </div>
                        <div className="col-md-2">
                          <button
                            className="btn"
                            type="button"
                            onClick={this.addToastClose}
                          >
                            <CloseBtnSVG />
                          </button>
                        </div>
                      </div>
                    </Toast.Body>
                  </Toast>
                ) : (
                  ""
                )}
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Phone<span className="text-project">*</span>
                </label>
                <Field name="phone">
                  {({ field, form, meta }: FieldProps<any>) => (
                    <div>
                      <PhoneInput
                        {...field}
                        inputProps={{ name: "phone" }}
                        specialLabel={""}
                        onChange={(value, data, e) => field.onChange(e)}
                        onBlur={field.onBlur}
                        value={field.value}
                        country={"au"}
                        inputStyle={{
                          paddingTop: 15,
                          paddingRight: 15,
                          paddingBottom: 15,
                          paddingLeft: 57,
                          width: "100%",
                          background: "#FFFFFF",
                          border: "1px solid #F0F0F0",
                          borderRadius: "8px",
                        }}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
              </div>
              {this.state.user_type != Constants.CLIENT_UT ? (
                <div className="mb-2 col-md-6">
                  <label className="form-label">
                    Password<span className="text-project">*</span>
                  </label>
                  <div className="eye-field">
                    <Field
                      name="password"
                      className="form-control usermanagement-input password-input"
                      type={this.state.hidden ? "password" : "text"}
                    />
                    <button
                      type="button"
                      className="eye btn"
                      onClick={this.toggleShow}
                    >
                      {this.state.hidden ? <EyeOffSVG /> : <EyeSVG />}
                    </button>
                  </div>
                  <ErrorMessage
                    name="password"
                    component={"div"}
                    className="cus-alert"
                  />
                </div>
              ) : (
                <></>
              )}
              {this.handlebranch()}



              {/* {this.state.user_type == Constants.QA_MANAGER_UT ||
                this.state.user_type == Constants.MANAGER_UT ||
                this.state.user_type == Constants.SUPERVISOR_MANAGER_UT ? (
                <div className="col-md-12">
                  <div className="row mt-3">
                    <div className="mb-2 col-md-6">
                      <label className="form-label">
                        Client<span className="text-project">*</span>
                      </label>
                    </div>
                    <div className="mb-2 col-md-6">
                      <label className="form-label">
                        Branch (you can select multiple)
                        <span className="text-project">*</span>
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )} */}
              {/* {(this.state.user_type == Constants.MANAGER_UT || this.state.user_type == Constants.QA_MANAGER_UT) &&
                this.state.addBranches &&
                this.state.addBranches.map((val: any, index: number) => (
                  <div className="col-md-12">
                    <div className="row">
                      <div className="mb-2 col-md-6">
                        <Select
                          styles={reactSelectCusStyles}
                          placeholder="Select Client"
                          isMulti={false}
                          options={this.props.clientList.map((client: any) => ({
                            value: client.id,
                            label: client.first_name + " " + client.last_name,
                          }))}
                          id={"select-client-" + index}
                          onChange={(e: any) =>
                            this.handleOnChangeClient(e.value, index)
                          }
                        />
                      </div>
                      <div className="mb-2 col-md-6">
                        <Select
                          styles={reactSelectCusStyles}
                          placeholder="Select Branch"
                          id={"select-branch-" + index}
                          isMulti
                          value={val.branch}
                          options={val.options}
                          onChange={(e: any) =>
                            this.handleOnChangeBranch(e, index)
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}
              {this.state.user_type == Constants.SUPERVISOR_MANAGER_UT &&
                this.state.addBranches &&
                this.state.addBranches.map((val: any, index: number) => (
                  <div className="col-md-12">
                    <div className="row">
                      <div className="mb-2 col-md-6">
                        <Select
                          styles={reactSelectCusStyles}
                          placeholder="Select Client"
                          isMulti={false}
                          options={this.props.clientList.map((client: any) => ({
                            value: client.id,
                            label: client.first_name + " " + client.last_name,
                          }))}
                          id={"select-client-" + index}
                          onChange={(e: any) =>
                            this.handleOnChangeClient(e.value, index)
                          }
                        />
                      </div>
                      <div className="mb-2 col-md-6">
                        <Select
                          styles={reactSelectCusStyles}
                          placeholder="Select Branch"
                          id={"select-branch-" + index}
                          isMulti
                          value={val.branch}
                          options={val.options}
                          onChange={(e: any) =>
                            this.handleOnChangeBranch(e, index)
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}
              {this.state.user_type == Constants.SUPERVISOR_UT ||
                this.state.user_type == Constants.OPERATION_MANAGER_UT ? (
                <div className="col-md-12">
                  <div className="row mt-3">
                    <div className="mb-2 col-md-6">
                      <label className="form-label">
                        Client<span className="text-project">*</span>
                      </label>
                    </div>
                    <div className="mb-2 col-md-5">
                      <label className="form-label">
                        Branch (you can select multiple)
                        <span className="text-project">*</span>
                      </label>
                    </div>
                    <div className="mb-2 col-md-1 ">
                      <button
                        onClick={this.handleAddMultiSelect}
                        type="button"
                        className="btn btn-primary plus-btn"
                      >
                        <PlusSVG />
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {this.state.user_type == Constants.SUPERVISOR_UT &&
                this.state.addBranches &&
                this.state.addBranches.map((val: any, index: number) => (
                  <div className="col-md-12">
                    <div className="row">
                      <div className="mb-2 col-md-6">
                        <Select
                          styles={reactSelectCusStyles}
                          placeholder="Select Client"
                          isMulti={false}
                          options={this.props.clientList.map((client: any) => ({
                            value: client.id,
                            label: client.first_name + " " + client.last_name,
                          }))}
                          id={"select-client-" + index}
                          onChange={(e: any) =>
                            this.handleOnChangeClient(e.value, index)
                          }
                        />
                      </div>
                      <div className="mb-2 col-md-5">
                        <Select
                          styles={reactSelectCusStyles}
                          placeholder="Select Branch"
                          id={"select-branch-" + index}
                          isMulti
                          value={val.branch}
                          options={val.options}
                          onChange={(e: any) =>
                            this.handleOnChangeBranch(e, index)
                          }
                        />
                      </div>
                      <div className="mb-2 col-md-1">
                        <a
                          className="close-new-btn"
                          onClick={() => this.handleCloseMultiSelect(index)}
                        >
                          <PlusCloseSVG />
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              {this.state.user_type == Constants.OPERATION_MANAGER_UT &&
                this.state.addBranches &&
                this.state.addBranches.map((val: any, index: number) => (
                  <div className="col-md-12">
                    <div className="row">
                      <div className="mb-2 col-md-6">
                        <Select
                          styles={reactSelectCusStyles}
                          placeholder="Select Client"
                          isMulti={false}
                          options={this.props.clientList.map((client: any) => ({
                            value: client.id,
                            label: client.first_name + " " + client.last_name,
                          }))}
                          id={"select-client-" + index}
                          onChange={(e: any) =>
                            this.handleOnChangeClient(e.value, index)
                          }
                        />
                      </div>
                      <div className="mb-2 col-md-5">
                        <Select
                          styles={reactSelectCusStyles}
                          placeholder="Select Branch"
                          id={"select-branch-" + index}
                          isMulti
                          value={val.branch}
                          options={val.options}
                          onChange={(e: any) =>
                            this.handleOnChangeBranch(e, index)
                          }
                        />
                      </div>
                      <div className="mb-2 col-md-1">
                        <a
                          className="close-new-btn"
                          onClick={() => this.handleCloseMultiSelect(index)}
                        >
                          <PlusCloseSVG />
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              {this.state.user_type == Constants.QA_UT &&
                this.state.addBranches &&
                this.state.addBranches.map((val: any, index: number) => (
                  <div className="row">
                    <div className="mb-2 col-md-6">
                      <label className="form-label">
                        Client<span className="text-project">*</span>
                      </label>
                      <Select
                        styles={reactSelectCusStyles}
                        placeholder="Select Client"
                        isMulti={false}
                        options={this.props.clientList.map((client: any) => ({
                          value: client.id,
                          label: client.first_name + " " + client.last_name,
                        }))}
                        onChange={(e: any) =>
                          this.handleOnChangeClient(e.value, index)
                        }
                      />
                    </div>
                    <div className="mb-2 col-md-6">
                      <label className="form-label">
                        Branch<span className="text-project">*</span>
                      </label>
                      <Select
                        styles={reactSelectCusStyles}
                        placeholder="Select Branch"
                        id={"select-branch-" + index}
                        value={val.branch}
                        options={val.options}
                        onChange={(e: any) =>
                          this.handleOnChangeBranch(e, index)
                        }
                      />
                    </div>
                  </div>
                ))} */}

              {this.state.addBranches !== 0 && (
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-6">
                      {this.state.clientSelectErrorShow === true && (
                        <p className="text-danger">Please select Client</p>
                      )}
                    </div>
                    <div className="col-6">
                      {this.state.branchSelectErrorShow === true && (
                        <p className="text-danger">Please select Branch</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
{this.props.getCeritficateData && this.state.cleanerDocumentShow === true ?(
<div className="mb-4 col-md-6">
                <label className="form-label">Cleaner Ceritficate</label>
                <Field
                  className="custom-select"
                  name="cleaner_certificate"
                  options={this.state.clearnerCertificateList}
                  component={CustomSelect}
                  isMulti={true}
                  onChange={()=> this.setState({cleanerValidate:false})}
                />
                       {this.state.cleanerValidate && this.state.cleanerDocumentShow?
                     <div style={{color:"red"}}>
                      Please atleast assign one certificate to cleaner
                     </div>
            :null}
              </div>
              ):null}
              <div className="mb-4 col-md-6">
                <label className="form-label">Is Active</label>
                <Field
                  className="custom-select"
                  name="status"
                  options={userActiveStatus}
                  component={CustomSelect}
                  isMulti={false}
                />
              </div>
              <div className="row">
                <div className="col-md-12 cus-progress">
                  <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.props.processTrigger}
                  >
                    <Modal.Body className="p-0">
                      <ProgressBar
                        now={this.props.progressPercentage}
                        label={
                          this.props.progressPercentage === 100
                            ? `Processing your request...`
                            : `User Adding....${this.props.progressPercentage}%`
                        }
                      />
                    </Modal.Body>
                  </Modal>
                  <div className=" mb-3 d-flex ">
                    <div className="mr-16px">
                      <button
                        type="submit"
                        id="create-button"
                        className="btn btn-primary create-button"
                      >
                        Create
                        <span
                          id="spinner-border"
                          className="spinner-border spinner-border-sm d-none"
                        ></span>
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn cancel-button"
                        onClick={() => {
                          this.props.onClose(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.props.addUsermanagementError !== "Email already exist" && (
              <div className="cus-alert">
                {this.props.addUsermanagementError}
              </div>
            )}
          </Form>
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  addUsermanagementError: state.usermanagement.addUsermanagementError,
  branchList: state.tasktemplate.branchList,
  // branchList: state.tasktemplate.branchList,
  // getBranchClientId: state.tasktemplate.getBranchClientId,
  clientList: state.contract.clientList,
  processTrigger: state.usermanagement.processTrigger,
  addUsermanagementSuccess: state.usermanagement.addUsermanagementSuccess,
  progressPercentage: state.usermanagement.progressPercentage,
  userTypesList: state.app.userTypesList,
  getCeritficateData: state.usermanagement.cleanerCertificates
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addUsermanagement: bindActionCreators(addUsermanagement, dispatch),
    listAllClient: bindActionCreators(listAllClient, dispatch),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
    getUserTypesList: bindActionCreators(getUserTypesList, dispatch),
    getCertificateList: bindActionCreators(getAllCertificates, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUsermanagement);
