import React from "react";
import { RootState } from '../../index';
import { connect } from "react-redux";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { bindActionCreators, Dispatch } from "redux";
import { editDailyTask, listAllCleaner, updateReassignDailyTaskDlgStatus, reassignDailyTask } from "../../shared/Reducers";
import { IReassignDailyTaskProps } from "../../types/dailyTask.type";
import CustomSelect from "../../shared/Components/CustomSelect";

class ReassignDailyTask extends React.Component<IReassignDailyTaskProps, any> {
    constructor(props: IReassignDailyTaskProps) {
        super(props);
        this.state = {
            formSubmitWarning: "",
        }
    }

    componentDidMount(): void {
        this.props.listAllCleaner();
    }

    handleReassignTask = async (formValues: any) => {
        console.log(formValues.cleaners);

        if (formValues.cleaners.length === 0) {
            this.setState({ formSubmitWarning: "Please select cleaner" });
        } else {
            let data = {
                'cleaner': formValues.cleaners
            };
            // this.setState({ formSubmitWarning : "Page Under construction"});

            if (this.props.id) {
                this.props.reassignDailyTask(data, this.props.id);
            }
        }
    }

    validationSchema = () => {
        this.setState({ formSubmitWarning: "" });
        return Yup.object().shape({
            cleaners: Yup.mixed().required('Please select Cleaner').nullable(),
        })
    }

    render(): React.ReactNode {
        if (this.props.data) {
            console.log(this.props.data.cleaners.map((val: any) => val.cleaner_id));
        }
        return (
            <div>
                <Formik initialValues={{
                    cleaners: this.props.data ? this.props.data.cleaners.map((val: any) => val.cleaner_id) : '',
                }}
                    enableReinitialize={true}
                    onSubmit={this.handleReassignTask}
                    validationSchema={this.validationSchema}>
                    {
                        props => {
                            const {
                                values,
                            } = props;
                            return (
                                <Form>
                                    <div className="row">
                                        <div className="mb-2 col-md-12">
                                            <label className="form-label">Cleaner<span className="text-project">*</span></label>
                                            <Field
                                                className="custom-select"
                                                name="cleaners"
                                                options={this.props.cleanerList}
                                                component={CustomSelect}
                                                placeholder="Select Cleaner"
                                                isMulti={true}
                                            />
                                            {this.state.formSubmitWarning !== "" && <div className="cus-alert">{this.state.formSubmitWarning}</div>}
                                        </div>
                                    </div>
                                    <div className="row mt-3 mb-3">
                                        <div className="col-md-12">
                                            <div className="d-flex">
                                                <div className="mr-16px">
                                                    <button type="submit" id="create-button" className="btn btn-primary create-button">Save <span id="spinner-border" className="spinner-border spinner-border-sm d-none"></span></button>
                                                </div>
                                                <div>
                                                    <button type="button" className="btn cancel-button" onClick={() => this.props.updateReassignDailyTaskDlgStatus(false)}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>
        )
    }
}
const mapStateToProps = (state: RootState) => ({
    cleanerList: state.tasktemplate.cleanerList
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        listAllCleaner: bindActionCreators(listAllCleaner, dispatch),
        editDailyTask: bindActionCreators(editDailyTask, dispatch),
        reassignDailyTask: bindActionCreators(reassignDailyTask, dispatch),
        updateReassignDailyTaskDlgStatus: bindActionCreators(updateReassignDailyTaskDlgStatus, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ReassignDailyTask);