import React from "react";
import { Modal, Button } from "react-bootstrap";
import { CloseBtnSVG } from "../../assets/images/Location";
import { RootState } from '../../index';
import { connect } from "react-redux";
import { Dispatch } from "redux";

type Props = {
    displayPreview : boolean;
    previewUrl  : string,
    previewType : string,
    handlePreviewClose: (status: boolean) => void;
}
class attachmentPreview extends React.Component<Props> {
    
    render(): React.ReactNode {
        return (
            <div >
                <Modal
                className="attachment-preview-box edit-comments"
                size="lg"
                show={this.props.displayPreview}
                onHide={() => this.props.handlePreviewClose(false)}
                aria-labelledby="example-modal-sizes-title-lg">
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body className="p-4">
                        {/* image */}
                        {
                            this.props.previewType === "image/png" || this.props.previewType === "image/jpeg" || this.props.previewType === "image/jpg" ? (
                                <>
                                    <img src={this.props.previewUrl} width="50%" height="70%"/>
                                </>
                            ) : ( <></> )
                        }
                        {/* video */}
                        {   
                            this.props.previewType === "video/mp4" || this.props.previewType === "video/webm" ? (
                                <>
                                    <video className="mt-4" controls width="100%" height="50%">
                                        <source
                                            src={this.props.previewUrl}
                                            type={this.props.previewType}
                                        />
                                        Sorry, your browser doesn't support videos.
                                    </video>
                                </>
                            ) : ( <></> )
                        }
                        {/* Document and pdf */}
                        {   
                            this.props.previewType !== "image/png" && this.props.previewType !== "image/jpeg" && this.props.previewType !== "image/jpg" && this.props.previewType !== "video/mp4" && this.props.previewType !== "video/webm" ? (
                                <>
                                    <iframe src={this.props.previewUrl} width="100%" height="100%" ></iframe>
                                </>
                            ) : ( <></> )
                        }
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = (state: RootState) => ({

})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(attachmentPreview);
