import { Dispatch, bindActionCreators } from "redux";
import {
  allCleanerNotification,
  allTaskNotification,
  searchNotification,
  updateSidebar,
} from "../../shared/Reducers";

import NotificationTable from "./NotificationTable";
import React from "react";
import { RootState } from "../../index";
import { SearchSVG } from "../../assets/images/EmployeesSVG";
import SideBar from "../../components/sidebar/SideBar";
import TopNav from "../../components/topnav/TopNav";
import { connect } from "react-redux";
import { Toast } from "react-bootstrap";
import { SuccessToastSVG } from "../../assets/images/ImagesV2";
import { CloseBtnSVG } from "../../assets/images/Location";

type Props = {
  status: any;
  searchNotification: any;
  updateSidebar: any;
  allTaskNotification: any;
  deletenotificationsuccess: any;
};

type ActivityState = {
  searchTerm: string;
  status: string;
  active_employee: string;
  inactive_employee: string;
  deleteToast: any;
};

class Notification extends React.Component<Props, ActivityState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchTerm: "",
      status: "all",
      active_employee: "",
      inactive_employee: "",
      deleteToast: false,
    };
  }

  componentDidMount(): void {
    this.props.updateSidebar(true);
    document.title = "Notification";
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<ActivityState>,
    snapshot?: any
  ): void {
    // if (
    //   prevProps.deletenotificationsuccess !==
    //   this.props.deletenotificationsuccess
    // ) {
    //   if (this.props.deletenotificationsuccess === "Successfully deleted") {
    //     let query = `notification?search=&sort=&order=&offset=${0}&limit=${10}`;
    //     this.props.allTaskNotification(query);
    //   }
    // }
    if (prevProps.deletenotificationsuccess !== this.props.deletenotificationsuccess) {
      console.log("entered");
      if (this.props.deletenotificationsuccess === "Successfully deleted") {
        console.log("true")
        this.setState({ deleteToast: true });
        console.log("true", true)
        setTimeout(() => {
          this.setState({ deleteToast: false });
        }, 1000)
      }
    }
  }

  deleteToastClose = () => {
    this.setState({ deleteToast: false });
  };

  handleFilter = (e: any) => {
    let filterVal: string = e.target.value.toLowerCase();
    let query = `notification?search=${filterVal}&sort=&order=&offset=${0}&limit=${10}`;
    this.props.allTaskNotification(query);
    // this.props.searchNotification(filterVal);
  };
  render(): React.ReactNode {
    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className={this.props.status ? "main-panel" : "main-panel2"}>
          <TopNav />
          <div className="content-wrapper">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="mb-0">Notification</h3>
                </div>
              </div>
              
              <div className="row mt-4 justify-content-between">
                <div className="col-md-5">
                  <div className="input-group search d-none">
                    <span
                      className="input-group-text search-icon"
                      id="basic-addon1"
                    >
                      <SearchSVG />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={this.state.searchTerm}
                      onInput={this.handleFilter}
                      onChange={(e) =>
                        this.setState({ searchTerm: e.target.value })
                      }
                    />
                  </div>
                </div>
                {/* <div className="col-md-2 text-end res-mt-3">
                                    <select className="form-select" onChange={this.handleStatusFilter} value={this.state.status}>
                                        <option value="all">All</option>
                                        <option value="in">Sign in</option>
                                        <option value="out">Sign out</option>
                                    </select>
                                </div> */}
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <NotificationTable search={"44"} />
                </div>
              </div>
            </div>
          </div>
          {this.state.deleteToast ? (
                <Toast className="success-alerts">
                  <Toast.Body>
                    <div className="row align-items-center">
                      <div className="col-md-2">
                        <SuccessToastSVG />
                      </div>
                      <div className="col-md-8">
                        {this.props.deletenotificationsuccess}
                      </div>
                      <div className="col-md-2">
                        <button
                          type="button"
                          className="btn"
                          onClick={this.deleteToastClose}
                        >
                          <CloseBtnSVG />
                        </button>
                      </div>
                    </div>
                  </Toast.Body>
                </Toast>
              ) : (
                ""
              )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  task_total_count: state.activity.task_total_count,
  cleaner_total_count: state.activity.cleaner_total_count,
  taskData: state.activity.taskData,
  cleanerData: state.activity.cleanerData,
  status: state.sidebar.status,
  deletenotificationsuccess: state.activity.deletenotificationsuccess,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    allCleanerNotification: bindActionCreators(
      allCleanerNotification,
      dispatch
    ),
    allTaskNotification: bindActionCreators(allTaskNotification, dispatch),
    searchNotification: bindActionCreators(searchNotification, dispatch),
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
