import { Dispatch, AnyAction } from "redux";
import { AppDispatch } from "../..";
import { TTaskTable } from "../../types/user.type";
import {
  API_GET_ALL_CLIENTS,
  API_GET_ALL_CLIENTS_SUCCESS,
  API_GET_ALL_CLIENTS_FAIL,
  API_GET_ALL_CLEANER,
  API_GET_ALL_CLEANER_SUCCESS,
  API_GET_ALL_CLEANER_FAIL,
  UPDATE_CLIENT,
  API_GET_ALL_BRANCH,
  API_GET_ALL_BRANCH_SUCCESS,
  API_GET_ALL_BRANCH_FAIL,
  TaskTemplateState,
  ICleaner,
  API_GET_ALL_TASKS,
  API_GET_ALL_TASKS_SUCCESS,
  API_GET_ALL_TASKS_FAIL,
  API_GET_TASK_BY_ID,
  API_GET_TASK_BY_ID_SUCCESS,
  API_GET_TASK_BY_ID_FAIL,
  API_ADD_TASK,
  API_ADD_TASK_SUCCESS,
  API_ADD_TASK_FAIL,
  API_EDIT_TASK,
  API_EDIT_TASK_SUCCESS,
  API_EDIT_TASK_FAIL,
  API_DELETE_TASK,
  API_DELETE_TASK_SUCCESS,
  API_DELETE_TASK_FAIL,
  UPDATE_ADD_TASK_DLG_STATUS,
  UPDATE_EDIT_TASK_DLG_STATUS,
  UPDATE_DELETE_TASK_DLG_STATUS,
  UPDATE_TASK_FETCH_STATUS,
  FILTER_TASK,
  UPDATE_TASKS,
  IEditTask,
  IBranchSelect,
  API_ADD_TASK_PROGRESS,
  API_EDIT_TASK_PROGRESS,
  API_GET_ALL_SETTING,
  API_GET_ALL_SETTING_SUCCESS,
  API_GET_ALL_SETTING_FAIL,
  API_GET_ALL_SETTING_UPDATED,
  API_GET_ALL_SETTING_UPDATED_SUCCESS,
  API_GET_ALL_SETTING_UPDATED_FAIL,
} from "../ActionTypes";
import Constants from "../Constants";

const initialState: TaskTemplateState = {
  branchList: [],
  cleanerList: [],
  clientList: [],
  addTaskStatus: false,
  addTaskSuccess: "",
  deleteTaskStatus: false,
  deleteTaskSuccess: "",
  editTaskStatus: false,
  editTaskSuccess: "",
  taskFetchStatus: false,
  selectedTaskData: null,
  taskData: [],
  filteredTaskData: [],
  id: "",
  total_count: 0,
  getbranchlistStatus: false,
  processTrigger: false,
  progressPercentage: 0,
  settingList: [],
  settingUpdated: "",
};

export const TaskTemplateReducer = (
  state: TaskTemplateState = initialState,
  action: AnyAction
): TaskTemplateState => {
  switch (action.type) {
    case UPDATE_TASKS:
      return { ...state };
    case API_GET_ALL_CLIENTS:
      return { ...state };
    case API_GET_ALL_CLIENTS_SUCCESS:
      let clientData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "clients" in data && data.clients) {
          clientData = data.clients;
        }
      }
      return { ...state, clientList: clientData };
    case API_GET_ALL_CLIENTS_FAIL:
      return { ...state };
    case API_GET_ALL_CLEANER:
      return { ...state };
    case API_GET_ALL_CLEANER_SUCCESS:
      let cleanerData: IBranchSelect[] = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "cleaners" in data && data.cleaners) {
          cleanerData = data.cleaners.map((cleanerValue: any) => ({
            value: cleanerValue.id,
            label: `${cleanerValue.first_name} ${cleanerValue.last_name}`,
          }));
        }
      }
      return { ...state, cleanerList: cleanerData };
    case API_GET_ALL_CLEANER_FAIL:
      return { ...state };
    case UPDATE_CLIENT:
      return { ...state, clientList: action.payload };
    case API_GET_ALL_BRANCH:
      return { ...state };
    case API_GET_ALL_BRANCH_SUCCESS:
      let branchData: IBranchSelect[] = [];
      // let branchDataUpdate: IBranchSelect[] = [];
      // let branchClientId = 0;
      if (action.payload) {
        const { data } = action.payload;
        // branchClientId = data.client_id;
        if (data && "branches" in data && data.branches) {
          branchData = data.branches.map((branchValue: any) => ({
            value: branchValue.id,
            label: branchValue.name,
            lat: branchValue.lat,
            lng: branchValue.lng,
            client_id:
              data.client_id > 0 ? data.client_id : branchValue.client_id,
          }));
          // if (Constants.LOGIN_USER_TYPE === Constants.MANAGERUTINT) {
          //   branchDataUpdate = [
          //     {
          //       value: "all",
          //       label: "All",
          //     },
          //   ].concat(branchData);
          // }
        }
      }
      // , getBranchClientId : true
      // if (Constants.LOGIN_USER_TYPE === Constants.MANAGERUTINT) {
      //   return { ...state, branchList: branchDataUpdate };
      // } else {
      return { ...state, branchList: branchData };
    // }
    case API_GET_ALL_BRANCH_FAIL:
      return { ...state };
    case UPDATE_TASK_FETCH_STATUS:
      return { ...state, taskFetchStatus: action.payload };
    case API_GET_ALL_TASKS:
      return { ...state };
    case API_GET_ALL_TASKS_SUCCESS:
      let taskData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task" in data && data.task) {
          taskData = data.task;
        }
      }
      let total_count = 0;
      if (action.payload) {
        const { data } = action.payload;
        if (data && "total_count" in data && data.total_count) {
          total_count = data.total_count;
        }
      }
      console.log(state.total_count, "API_GET_ALL_TASKS_SUCCESS");

      return {
        ...state,
        total_count: total_count,
        editTaskStatus: false,
        addTaskStatus: false,
        deleteTaskStatus: false,
        filteredTaskData: taskData,
      };
    case API_GET_ALL_TASKS_FAIL:
      return { ...state };
    case API_GET_TASK_BY_ID:
      return { ...state, id: action.payload.id };
    case API_GET_TASK_BY_ID_SUCCESS:
      let tasks = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task" in data && data.task) {
          tasks = data.task;
        }
      }
      return {
        ...state,
        selectedTaskData: tasks,
        editTaskStatus: true,
        taskFetchStatus: false,
      };
    case API_GET_TASK_BY_ID_FAIL:
      return { ...state };
    case API_ADD_TASK:
      let values: boolean = false;
      if (action.payload.request.data.FormData === undefined) {
        values = true;
      }
      return { ...state, addTaskSuccess: "", processTrigger: values };
    case API_ADD_TASK_SUCCESS:
      let message = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          message = data.message;
        }
      }
      return {
        ...state,
        addTaskStatus: false,
        addTaskSuccess: message,
        processTrigger: false,
        progressPercentage: 0,
      };
    case API_ADD_TASK_FAIL:
      return { ...state };
    case API_ADD_TASK_PROGRESS:
      return { ...state, progressPercentage: action.payload };
    case API_EDIT_TASK_PROGRESS:
      return { ...state, progressPercentage: action.payload };
    case UPDATE_ADD_TASK_DLG_STATUS:
      return { ...state, addTaskStatus: action.payload };
    case API_EDIT_TASK:
      let value: boolean = false;
      if (action.payload.request.data.FormData === undefined) {
        value = true;
      }
      return { ...state, editTaskSuccess: "", processTrigger: value };
    case API_EDIT_TASK_SUCCESS:
      let messages = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          messages = data.message;
        }
      }
      return {
        ...state,
        editTaskStatus: false,
        taskFetchStatus: true,
        editTaskSuccess: messages,
        processTrigger: false,
        progressPercentage: 0,
      };
    case API_EDIT_TASK_FAIL:
      return { ...state };
    case UPDATE_EDIT_TASK_DLG_STATUS:
      return { ...state, editTaskStatus: action.payload };
    case API_DELETE_TASK:
      return { ...state, deleteTaskSuccess: "" };
    case API_DELETE_TASK_SUCCESS:
      let msg = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          msg = data.message;
        }
      }
      return {
        ...state,
        deleteTaskStatus: false,
        taskFetchStatus: true,
        deleteTaskSuccess: msg,
      };
    case API_DELETE_TASK_FAIL:
      return { ...state };
    case UPDATE_DELETE_TASK_DLG_STATUS:
      return { ...state, deleteTaskStatus: action.payload };
    case FILTER_TASK:
      const filterTask: TTaskTable[] = state.filteredTaskData.filter((obj) => {
        return (
          obj.title.toLowerCase().includes(action.payload) ||
          obj.branch_name.toLowerCase().includes(action.payload) ||
          obj.first_name.toLowerCase().includes(action.payload) ||
          obj.last_name.toLowerCase().includes(action.payload)
        );
      });
      return { ...state, filteredTaskData: filterTask };
    case API_GET_ALL_SETTING:
      return { ...state };
    case API_GET_ALL_SETTING_SUCCESS:
      console.log(action.payload, "action.payload");

      return { ...state, settingList: action.payload.data.settings };
    case API_GET_ALL_SETTING_FAIL:
      return { ...state };
    case API_GET_ALL_SETTING_UPDATED:
      return { ...state };
    case API_GET_ALL_SETTING_UPDATED_SUCCESS:
      console.log(action.payload, "action.payload");

      return { ...state, settingUpdated: action.payload.data };
    case API_GET_ALL_SETTING_UPDATED_FAIL:
      return { ...state };
    default:
      return state;
  }
};

//
export function updateClients(data: ICleaner[]) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_CLIENT,
      payload: data,
    });
  };
}

// list client
export function listAllClient() {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ALL_CLIENTS,
      payload: {
        request: {
          method: "get",
          url: `users/client/drop-down-list`,
        },
      },
    });
  };
}

// List Branch
export function listAllBranch(id: any) {
  console.log("listAllBranch", id);

  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_ALL_BRANCH,
      payload: {
        request: {
          method: "get",
          url: `branch/drop-down-list/${id}`,
        },
      },
    });
  };
}

// settings
export function settingslists() {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_ALL_SETTING,
      payload: {
        request: {
          method: "get",
          url: `settings`,
        },
      },
    });
  };
}
// settings Updated
export function settingsUpdated(datas: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_ALL_SETTING_UPDATED,
      payload: {
        request: {
          method: "put",
          url: `settings`,
          data: datas,
        },
      },
    });
  };
}
// list cleaner
export function listAllCleaner() {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ALL_CLEANER,
      payload: {
        request: {
          method: "get",
          url: `users/cleaner/drop-down-list`,
        },
      },
    });
  };
}

// update task
export function updateTasks(data: any) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_TASKS,
      payload: data,
    });
  };
}

// get task by id
export function getTaskById(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_TASK_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `task-template/${id}` : `task-template`,
        },
        id,
      },
    });
  };
}

// get all tasks for table
export function getAllTasks(queryStr: string) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_ALL_TASKS,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `task-template?${queryStr}` : `task-template?`,
        },
      },
    });
  };
}

// add task
export function addTask(payload: FormData) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_ADD_TASK,
      payload: {
        request: {
          method: "post",
          url: `task-template`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({ type: API_ADD_TASK_PROGRESS, payload: progress });
          },
        },
      },
    });
  };
}

export function updateAddTask(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_ADD_TASK_DLG_STATUS,
      payload: status,
    });
  };
}

// edit task
export function editTask(payload: IEditTask, id: number) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_EDIT_TASK,
      payload: {
        request: {
          method: "put",
          url: `task-template/${id}`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_EDIT_TASK_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

// update edit employee
export function updateEditTaskDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_EDIT_TASK_DLG_STATUS,
      payload: status,
    });
  };
}

// Fetch Task after add, edit, delete
export function updateTaskFetchStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_TASK_FETCH_STATUS,
      payload: status,
    });
  };
}

// delete task
export function deleteTask(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_DELETE_TASK,
      payload: {
        request: {
          method: "delete",
          url: `task-template/${id}`,
        },
      },
    });
  };
}

// update delete task
export function updateDeleteTaskDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_DELETE_TASK_DLG_STATUS,
      payload: status,
    });
  };
}

// Filter value
export function filterTask(filterVal: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: FILTER_TASK,
      payload: filterVal,
    });
  };
}
