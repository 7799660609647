import * as Yup from "yup";

import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { addCertificate, listAllClient, listAllBranch } from "../../shared/Reducers";
import { CustomSelect } from "../../shared/Components/CustomSelect";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import Button from "@material-ui/core/Button";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { PaperClipSVG, TrashSVG } from "../../assets/images/ImagesV2";
// date picker
import AttachmentPreview from "../../shared/Components/AttachmentPreview";
import moment from "moment";
import { PlusSVG, PlusCloseSVG } from "../../assets/images/UsermanagementsSVG";
import Select from "react-select";
import docImage from "../../assets/images/google-docs.png";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";
import { Modal, ProgressBar, Toast } from "react-bootstrap";

type Props = {
  onClose: (status: boolean) => void;
  addAuditError?: any;
  addAudit?: any;
  processTrigger: any;
  addAuditSuccess: any;
  progressPercentage: number;
  clientList?: any;
  branchList?: any;
  listAllClient?: any;
  listAllBranch?: any;
  addCertficate?: any;
};

type TAddCertificateState = {
  areaTask: any;
  client_id: any;
  branch_id: any;
  clientErrorShow: boolean;
  branchErrorShow: boolean;
  certificateUrls: any;
  certificatePreviewStatus: boolean;
  previewUrl: any;
  previewType: any;
  files_size: any;
  certificateFiles: any;
  certificateValidate: boolean;
};

const reactSelectCusStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#fff",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    },
  }),
};

class AddAudit extends React.Component<Props, TAddCertificateState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      areaTask: [],
      client_id: "",
      branch_id: "",
      clientErrorShow: false,
      branchErrorShow: false,
      certificateUrls: [],
      certificatePreviewStatus : false,
      previewUrl: "",
      previewType: "",
      files_size: "",
      certificateFiles: [],
      certificateValidate: false
    };
  }

  componentDidMount(): void {
    this.listData();

    // default area task
    let addArea: any = {
      area: "",
      task: [""],
    };
    let areaTask: any = this.state.areaTask;
    areaTask.push(addArea);

    this.setState({ areaTask: areaTask });

    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.add("d-none");
      button.removeAttribute("disabled");
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<TAddCertificateState>,
    snapshot?: any
  ): void {
    if (prevProps.processTrigger !== this.props.processTrigger) {
    }
    if (prevProps.addAuditSuccess !== this.props.addAuditSuccess) {
    }
  }

  listData = async () => {
    await this.props.listAllClient();
  };

  handleAddCertificate = async (formValues: any) => {
    if (this.state.certificateUrls.length>0){

    let data = new FormData();
    data.append("name", formValues.name);
    data.append("status", formValues.status);
    for (let index = 0; index < this.state.certificateFiles.length; index++) {
      data.append("attachments", this.state.certificateFiles[index]);
    }
    if (
      formValues.name !== ""
    ) {
      var element = document.getElementById("spinner-border");
      var button = document.getElementById("create-button");
      if (element !== null && button !== null) {
        element.classList.remove("d-none");
        button.setAttribute("disabled", "");
      }
      await this.props.addCertficate(data);

    } 
  }
  setTimeout(() => {
    this.props.onClose(false);
  }, 1000);
  };

  validationSchema = () => {
    // this.setState({ clientErrorShow: false });
    // this.setState({ branchErrorShow: false });
    // if (this.state.client_id === "") {
    //   this.setState({ clientErrorShow: true });
    // }
    // if (this.state.branch_id === "") {
    //   this.setState({ branchErrorShow: true });
    // }
    if (this.state.certificateUrls.length>0){
      this.setState({certificateValidate:false})
    }else{
      this.setState({certificateValidate:true})
    }
    return Yup.object().shape({
      name: Yup.string().required("Enter Certificate Name"),
      // areaTask: Yup.array().of(
      //   Yup.object().shape({
      //     area: Yup.string().required("Enter Area"),
      //     task: Yup.array().of(Yup.string().required("Enter Task")),
      //   })
      // ),
    });
  };

  progress = (e: any) => {
    console.log("progress", e);
  };

  handleOnChangeClient = async (id: number) => {
    this.setState({ client_id: id });
    await this.props.listAllBranch(id);
  };

  handleAddArea = async () => {
    const addArea: any = {
      area: "",
      task: [""],
    };
    // this.setState((prevState) => ({
    //   areaTask: [...prevState.areaTask, addArea],
    // }));
    let areaTask: any = this.state.areaTask;
    areaTask.push(addArea);
    this.setState({
      areaTask: areaTask
    });
  };

  handleRemoveArea = (inx: any) => {
    this.setState((prevState) => ({
      areaTask: prevState.areaTask.filter(
        (_: any, index: any) => index !== inx
      ),
    }));
  };

  // handleAddTask = (inx: any) => {
  //   this.setState((prevState) => {
  //     const areaTask = [...prevState.areaTask];
  //     areaTask[inx].task.push("");
  //     return { areaTask };
  //   });
  // };

  // handleRemoveTask = (p_inx: any, c_inx: any) => {
  //   this.setState((prevState) => {
  //     const areaTask = [...prevState.areaTask];
  //     if (p_inx >= 0 && p_inx < areaTask.length) {
  //       areaTask[p_inx].task.splice(c_inx, 1);
  //     }
  //     return { areaTask };
  //   });
  // };

  // changeAreaValue = (value: any, inx: any) => {
  //   this.setState((prevState) => {
  //     const areaTask = [...prevState.areaTask];
  //     areaTask[inx].area = value;
  //     return { areaTask };
  //   });
  // };

  // changeTaskValue = (value: any, area_inx: any, task_inx: any) => {
  //   this.setState((prevState) => {
  //     const areaTask = [...prevState.areaTask];
  //     areaTask[area_inx].task[task_inx] = value;
  //     return { areaTask };
  //   });
  // };

  handleAddTask = async (inx: number) => {
    let areaTask: any = this.state.areaTask;
    areaTask[inx].task.push("");
    this.setState({ areaTask: areaTask });
  }

  handleRemoveTask = async (p_inx: number, c_inx: number) => {
    let areaTask: any = this.state.areaTask;
    if (p_inx >= 0 && p_inx < areaTask.length) {
      if (c_inx >= 0 && c_inx < areaTask[p_inx].task.length) {
        areaTask[p_inx].task.splice(c_inx, 1);
      }
    }
    this.setState({ areaTask: areaTask });
  }

  changeAreaValue = async (value: any, inx: number) => {
    let areaTask: any = this.state.areaTask;
    areaTask[inx].area = value;
    this.setState({ areaTask: areaTask });
  }

  changeTaskValue = async (value: any, area_inx: number, task_inx: number) => {
    let areaTask: any = this.state.areaTask;
    areaTask[area_inx].task[task_inx] = value;
    this.setState({ areaTask: areaTask });
  }

  handleImageChange = async (e: any) => {
    
    const file = e.target.files[0];
    this.setState(
      { files_size: this.state.files_size + file.size },
      async () => {
        if (this.state.files_size > 524288000) {
          alert("File Size Must Be Less Than 500MB");
          e.target.value = null;
          return this.setState({
            files_size: this.state.files_size - file.size,
          });
        }

        let files = [];
        let urls = [];
        files = [...e.target.files];
        let fileurls: any = [];
        await files.map(async (file: any) => {
          fileurls.push({
            url: URL.createObjectURL(file),
            file_type: file.type,
          });
        });
        this.setState(
          {
            certificateFiles: [...this.state.certificateFiles, ...files],
          },
          () => {
            urls = [...fileurls];
            this.setState(
              { certificateUrls: [...this.state.certificateUrls, ...urls] },
              () => {
                e.target.value = "";
              }
            );
          }
        );
      }
    );

    if (file){
      this.setState({certificateValidate:false})
    }else{
      this.setState({certificateValidate:true})
    }
  };

  handlePreviewClose = () => {
    this.setState({ certificatePreviewStatus: false });
  };

  handlePreviewOpen = (file_type: any, url: any) => {
    if (
      file_type === "application/pdf"
    ) {
      this.setState({
        certificatePreviewStatus: true,
        previewUrl: url,
        previewType: file_type,
      });
    }
  };

  handleDeleteImage = (index: number) => {
    const files = this.state.certificateFiles;
    const urls = this.state.certificateUrls;
    files.splice(index, 1);
    urls.splice(index, 1);
    this.setState({ certificateFiles: files }, () => {
      this.setState({ certificateUrls: urls });
    });
    if (urls.length>0){
      this.setState({certificateValidate:false})
    }else{
      this.setState({certificateValidate:true})
    }
  };

  render(): React.ReactNode {
    const initialValues = {
      name: "",
      status: 1,
    };
    return (
      <div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={this.handleAddCertificate}
          validationSchema={this.validationSchema}
        >
          <Form encType="multipart/form-data">
            <div className="mt-4">
              <div className="d-flex gap-2">
                <div style={{ width: "28%" }}>
                  <label className="form-label">Certificate Name</label>
                  <Field
                    name="name"
                    type={"text"}
                    className="form-control usermanagement-input"
                  />
                  <ErrorMessage
                    name="name"
                    component={"div"}
                    className="cus-alert"
                  />
                </div>
{/*       
                <div style={{ width: "17%", marginTop: "31px" }}>
                  <button
                    type="button"
                    className="btn btn-primary plus-btn data-ins-plus w-100"
                    onClick={this.handleAddArea}
                  >
                    <PlusSVG /> <span>Question</span>
                  </button>
                </div> */}
              </div>
              <div className="row">
             
              <div className="col-md-12 mb-2">
                    <label className="form-label">Upload Certificate</label>
                    <div className="attachment-btn dropdown-field">
                      <input
                        type="file"
                        multiple
                        accept=".pdf,.PDF"
                        style={{ display: "none" }}
                        onChange={this.handleImageChange}
                        id="contained-button-file"
                      />
                      <label htmlFor="contained-button-file">
                        <Button
                          variant="contained"
                          color="default"
                          component="span"
                        >
                          <PaperClipSVG /> Add Certificate
                        </Button>
                      </label>
                    </div>
                  </div>
                  {this.state.certificateUrls.length > 0 ? (
                    <>
                    <div className="col-md-12 mb-2">
                      <label className="form-label">Certificate File</label>
                    </div>
                         <div className="col-md-12">
                         <div className="d-flex row">
                           {this.state.certificateUrls &&
                             this.state.certificateUrls.map(
                               (url: any, index: number) => (
                                 <div className="img-card mr-16px mb-3">
                                   <span className="delete">
                                     <a
                                       onClick={() =>
                                         this.handleDeleteImage(index)
                                       }
                                     >
                                       <TrashSVG />
                                     </a>
                                   </span>
                                   <div
                                     className="img-preview"
                                     onClick={() =>
                                       this.handlePreviewOpen(
                                         url.file_type,
                                         url.url
                                       )
                                     }
                                   >
                                     <img
                                       className="img-thumb-perview"
                                       src={
                                         url.file_type === "application/pdf"
                                           ? pdfImage
                                           : url.file_type === "video/mp4" ||
                                             url.file_type === "video/webm"
                                           ? videoImage
                                           : url.file_type === "image/png" ||
                                             url.file_type === "image/jpeg" ||
                                             url.file_type === "image/jpg"
                                           ? url.url
                                           : docImage
                                       }
                                     />
                                   </div>
                                 </div>
                               )
                             )}
                           <AttachmentPreview
                             displayPreview={this.state.certificatePreviewStatus}
                             previewUrl={this.state.previewUrl}
                             previewType={this.state.previewType}
                             handlePreviewClose={this.handlePreviewClose}
                           />
                         </div>
                       </div>
                       </>
                  ) : (
                    <></>
                  )}
                  {this.state.certificateValidate?
                <div style={{color:"red"}}>
                  Please upload atleast one certificate
                  </div>  
                  :null
                }

<div className="col-md-6 mb-2">
                    <label className="form-label">Status</label>
                    <Field
                        className="custom-select"
                        name="status"
                        options={[
                          { value: 1, label: "Enable" },
                          { value: 0, label: "Disable" },
                        ]}
                        component={CustomSelect}
                        isMulti={false}
                      />
                    <ErrorMessage
                      name="status"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                  </div>
              {/* {this.state.areaTask &&
                this.state.areaTask.map((val: any, index: number) => (
                  <div className="row mt-3 p-0" key={index}>
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body p-4">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="d-flex gap-3">
                                <div className="col mb-2">
                                  <label className="form-label">Question</label> */}
                                  {/* <input type="area" value={val.area} className="form-control br-dark" onChange={(e) => this.changeAreaValue(e.target.value, index)} /> */}
                                  {/* <Field
                                    type="area"
                                    value={val.area}
                                    name={`areaTask[${index}].area`}
                                    className="form-control br-dark"
                                    onChange={(e: any) =>
                                      this.changeAreaValue(
                                        e.target.value,
                                        index
                                      )
                                    }
                                  />
                                  <ErrorMessage
                                    name={`areaTask[${index}].area`}
                                    component="div"
                                    className="cus-alert"
                                  />
                                </div>
                                <div style={{ marginTop: "29px" }}>
                                  <button
                                    type="button"
                                    className="btn close-new-btn data-ins-plus p-0"
                                    onClick={() => {
                                      this.handleRemoveArea(index);
                                    }}
                                  >
                                    <PlusCloseSVG />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          {/* {val.task &&
                            val.task.map((task: any, t_inx: number) => (
                              <div className="row" key={t_inx}>
                                <div className="col-md-11">
                                  <div className="d-flex gap-3">
                                    <div className="col mb-2">
                                      <label className="form-label">Answer</label> */}
                                      {/* <textarea className="form-control br-dark" style={{ height: "40px" }}
                                        onChange={(e) => this.changeTaskValue(e.target.value, index, t_inx)}></textarea> */}
                                      {/* <Field
                                        as="textarea"
                                        name={`areaTask[${index}].task[${t_inx}]`}
                                        className="form-control br-dark"
                                        style={{ height: "40px" }}
                                        onChange={(e: any) =>
                                          this.changeTaskValue(
                                            e.target.value,
                                            index,
                                            t_inx
                                          )
                                        }
                                      />
                                      <ErrorMessage
                                        name={`areaTask[${index}].task[${t_inx}]`}
                                        component="div"
                                        className="cus-alert"
                                      />
                                    </div>

                                    <div style={{ marginTop: "29px" }}>
                                      <button
                                        type="button"
                                        className="btn close-new-btn data-ins-plus p-0"
                                        onClick={() => {
                                          this.handleRemoveTask(index, t_inx);
                                        }}
                                      >
                                        <PlusCloseSVG />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          <div className="row mt-3">
                            <div className="col-md-12">
                              <button
                                type="button"
                                className="btn btn-primary plus-btn data-ins-plus"
                                onClick={() => {
                                  this.handleAddTask(index);
                                }}
                              >
                                <PlusSVG />
                                <span style={{ paddingRight: "4px" }}>
                                  Add Answer
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))} */}
            </div>

            <div className="row">
              <div className="col-md-12 cus-progress">
                <Modal
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={this.props.processTrigger}
                >
                  <Modal.Body className="p-0">
                    <ProgressBar
                      now={this.props.progressPercentage}
                      label={
                        this.props.progressPercentage === 100
                          ? `Processing your request...`
                          : `User Adding....${this.props.progressPercentage}%`
                      }
                    />
                  </Modal.Body>
                </Modal>
                <div className="mt-3 mb-3 d-flex ">
                  <div className="mr-16px">
                    <button
                      type="submit"
                      id="create-button"
                      className="btn btn-primary create-button"
                    >
                      Create
                      <span
                        id="spinner-border"
                        className="spinner-border spinner-border-sm d-none"
                      ></span>
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn cancel-button"
                      onClick={() => {
                        this.props.onClose(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  processTrigger: state.certificate.processTrigger,
  addAuditSuccess: state.certificate.addCertificateSuccess,
  progressPercentage: state.certificate.progressPercentage,
  clientList: state.contract.clientList,
  branchList: state.tasktemplate.branchList,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addCertficate: bindActionCreators(addCertificate, dispatch),
    listAllClient: bindActionCreators(listAllClient, dispatch),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAudit);
