export const API_GET_EMAIL_BY_SLUG = "get_by_slug/LOAD";
export const API_GET_EMAIL_BY_SLUG_SUCCESS = "get_by_slug/LOAD_SUCCESS";
export const API_GET_EMAIL_BY_SLUG_FAIL = "get_by_slug/LOAD_FAIL";
export const API_GET_ALL_EMAIL_TEMPLATE = "get_all_email/LOAD";
export const API_GET_ALL_EMAIL_TEMPLATE_SUCCESS = "get_all_email/LOAD_SUCCESS";
export const API_GET_ALL_EMAIL_TEMPLATE_FAIL = "get_all_email/LOAD_FAIL";
export const UPDATE_EMAIL_TEMPLATE = "update_email/LOAD";
export const UPDATE_EMAIL_TEMPLATE_SUCCESS = "update_email/LOAD_SUCCESS";
export const UPDATE_EMAIL_TEMPLATE_FAIL = "update_email/LOAD_FAIL";

export interface EmailTemplateState {
  email_Data: IGetBySlug | null;
  emailSuccess: string;
  emailTemplate: any;
}

export interface IGetBySlug {
  subject: string;
  message: string;
}
