
export const GET_CLEANER_DETAILS = "get_cleaner_details/LOAD";
export const GET_CLEANER_DETAILS_SUCCESS = "get_cleaner_details/LOAD_SUCCESS";
export const GET_CLEANER_DETAILS_FAIL = "get_cleaner_details/LOAD_FAIL";
export const CLEANER_CERTIFICATE_COMPLETION = "cleaner_certificate_completion/LOAD";
export const CLEANER_CERTIFICATE_COMPLETION_SUCCESS = "cleaner_certificate_completion/LOAD_SUCCESS";
export const CLEANER_CERTIFICATE_COMPLETION_FAIL = "cleaner_certificate_completion/LOAD_FAIL";

export interface CleanerCertificateState {
    getCleanerDetails:any | null;
    certificateCompleteStatus : any;
}
