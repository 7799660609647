/* eslint-disable import/no-anonymous-default-export */
const userStr = localStorage.getItem("user");

let user_type = 0;
let user_id = null;
let user_email = null;
let user_phone = null;
let user_name = null;

if (userStr) {
  const userDetails = JSON.parse(userStr);
  user_type = userDetails.user_type;
  user_id = userDetails.id;
  user_email = userDetails.email;
  user_phone = userDetails.email;
  user_name = userDetails.first_name + " " + userDetails.last_name;
}

export default {
  // local system
  // baseUrl: "http://192.168.1.19:4001/api/",
  // imgUrl: "http://192.168.1.19:4001/upload/",
  // imgThumbUrl: "http://192.168.1.19:4001/upload/thumb/",
  
  // test server
  // baseUrl: "http://ct.rayi.in:4001/api/",
  // imgUrl: "http://ct.rayi.in:4001/upload/",
  // imgThumbUrl: "http://ct.rayi.in:4001/upload/thumb/",

  // production server
  baseUrl: "https://smmncleanstar.com.au/api/",
  imgUrl: "https://smmncleanstar.com.au/upload/",
  imgThumbUrl: "https://smmncleanstar.com.au/upload/thumb/",

  userType: [
    "SUPERADMIN",
    "ADMIN",
    "SUPERVISOR",
    "CLEANER",
    "CLIENT",
    "QA",
    "MANAGER",
    "SUPERVISOR_MANAGER",
    "OPERATION_MANAGER",
    "QA_MANAGER",
  ],
  ADMIN_UT: "1",
  SUPERVISOR_UT: "2",
  CLEANER_UT: "3",
  CLIENT_UT: "4",
  QA_UT: "5",
  MANAGER_UT: "6",
  SUPERVISOR_MANAGER_UT: "7",
  OPERATION_MANAGER_UT: "8",
  QA_MANAGER_UT: "9",
  ADMINUTINT: 1,
  SUPERVISORUTINT: 2,
  CLEANERUTINT: 3,
  CLIENTUTINT: 4,
  QAUTINT: 5,
  MANAGERUTINT: 6,
  SUPERVISOR_MANAGERUTINT: 7,
  OPERATION_MANAGERUTINT: 8,
  QA_MANAGERUTINT: 9,
  IS_ACTIVE: 1,
  ENABLE: 1,
  DISABLE: 0,
  ACTIVE: 1,
  DELETED: 0,
  YES: 1,
  NO: 0,
  TASK_CREATED: 1,
  TASK_INPROGRESS: 2,
  TASK_COMPLETED: 3,
  TASK_COMMENTED: 4,
  TASK_INCOMPLETED: 5,
  TASK_RESOLVED: 6,
  TASK_FOLLOWUP: 7,
  LOG_APP_TYPE: 3, // 1 - ios, 2 - android, 3 - web

  REGULAR_TASK: 0,
  SPECIAL_REQUEST: 1,
  ADDITIONAL_TASK: 2,
  RECOMMENDED_TASK: 3,
  SPECIAL_REQUEST_STR: "1",
  STATISFIED: 1,
  UNSTATISFIED: 0,
  READ: 0,
  READ_WRITE: 1,
  ADMIN: 1,
  USER: 2,
  OPEN: 1,
  CLOSED: 0,
  SIGN_IN: "in",
  SIGN_OUT: "out",
  PER_PAGE: 10,
  OFFSET: 0,
  LOGIN_USER_EMAIL: user_email,
  LOGIN_USER_NAME: user_name,
  LOGIN_USER_PHONE: user_phone,
  LOGIN_USER_TYPE: user_type,
  LOGIN_USER_ID: user_id,
  TASK_WITH_SIGN_IN_YES: 1,
  TASK_WITH_SIGN_IN_NO: 0,
  SATISFIED: 1,
  UNSATISFIED: 2,
};

export const TASK_PRIORITY = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
};
export const ATTENDENCE_STATUS = {
  PENDING: 1,
  APPROVED: 2,
  LEAVE: 3,
};

export enum DAYS {
  SUNDAY = 1,
  MONDAY = 2,
  TUESDAY = 3,
  WEDNESDAY = 4,
  THURSDAY = 5,
  FRIDAY = 6,
  SATURDAY = 7,
}

export const USER_ROLE = [
  { value: "4", label: "Client" },
  { value: "3", label: "Cleaner" },
  { value: "2", label: "Supervisor" },
  { value: "6", label: "Manager" },
  { value: "7", label: "Supervisor Manager" },
  { value: "8", label: "Operation Manager" },
  { value: "9", label: "QA Manager" },
  { value: "5", label: "QA" },
];

export const FILTER_USER_ROLE = [
  { value: "all", label: "All" },
  { value: "4", label: "Client" },
  { value: "3", label: "Cleaner" },
  { value: "2", label: "Supervisor" },
  { value: "6", label: "Manager" },
  { value: "7", label: "Supervisor Manager" },
  { value: "8", label: "Operation Manager" },
  { value: "9", label: "QA Manager" },
  { value: "5", label: "QA" },
];

export const EveryMonth = [
  { label: 1, value: "1" },
  { label: 2, value: "2" },
  { label: 3, value: "3" },
  { label: 4, value: "4" },
  { label: 5, value: "5" },
  { label: 6, value: "6" },
  { label: 7, value: "7" },
  { label: 8, value: "8" },
  { label: 9, value: "9" },
  { label: 10, value: "10" },
  { label: 11, value: "11" },
  { label: 12, value: "12" },
  { label: 13, value: "13" },
  { label: 14, value: "14" },
  { label: 15, value: "15" },
  { label: 16, value: "16" },
  { label: 17, value: "17" },
  { label: 18, value: "18" },
  { label: 19, value: "19" },
  { label: 20, value: "20" },
  { label: 21, value: "21" },
  { label: 22, value: "22" },
  { label: 23, value: "23" },
  { label: 24, value: "24" },
  { label: 25, value: "25" },
  { label: 26, value: "26" },
  { label: 27, value: "27" },
  { label: 28, value: "28" },
  { label: 29, value: "29" },
  { label: 30, value: "30" },
  { label: 31, value: "31" },
];
export const DaysOption = [
  { label: "0", value: "SUNDAY" },
  { label: "1", value: "MONDAY" },
  { label: "2", value: "TUESDAY" },
  { label: "3", value: "WEDNESDAY" },
  { label: "4", value: "THURSDAY" },
  { label: "5", value: "FRIDAY" },
  { label: "6", value: "SATURDAY" },
];

export const EveryWeekDays = [
  { label: "SUN", value: 0 },
  { label: "MON", value: 1 },
  { label: "TUE", value: 2 },
  { label: "WED", value: 3 },
  { label: "THU", value: 4 },
  { label: "FRI", value: 5 },
  { label: "SAT", value: 6 },
];

export const EMAIL_TEMPLATE_SLUG = [
  { label: "Forgot password", value: "forgot-password" },
  { label: "Welcome supervisor", value: "welcome-supervisor" },
  { label: "Welcome cleaner", value: "welcome-cleaner" },
  { label: "Welcome manager", value: "welcome-manager" },
  { label: "Welcome supervisor manager", value: "welcome-supervisor-manager" },
  { label: "Welcome operation manager", value: "welcome-operation-manager" },
  { label: "Welcome QA manager", value: "welcome-qa-manager" },
  { label: "Welcome QA", value: "welcome-qa" },
  { label: "Send task", value: "send-task" },
];

export const FILTER_USER_TABLE = [
  { label: "Role", value: "user_type_name" },
  { label: "First name", value: "first_name" },
  { label: "Last name", value: "last_name" },
  { label: "Email", value: "email" },
  { label: "Phone", value: "phone" },
  { label: "Active", value: "status" },
];

export const FILTER_SCHEDULE_TABLE = [
  { value: "0", label: "client" },
  { value: "1", label: "branch" },
  { value: "2", label: "schedule name" },
  { value: "3", label: "task template name" },
  { value: "4", label: "active" },
];

export const COMPLAINT_STATUS = [
  { value: 0, label: "Close" },
  { value: 1, label: "Open" },
];

export enum SCHEDULE_OPTION {
  EVERY_MONTH_DAYS = "every_month_days",
  EVERY_WEEK_DAYS = "every_week_days",
  SPECIFIC_DATES = "specific_dates",
  EVERY_WEEK = "every_week",
  DATE_TO_DATE = "date_to_date",
  EVERY_MONTH_WEEK = "every_month_week",
}

export const EveryWeekMonth = [
  { label: "First", value: "0" },
  { label: "Second", value: "1" },
  { label: "Third", value: "2" },
  { label: "Fourth", value: "3" },
  { label: "Last", value: "last" },
];

export enum COMMENTS_TASK_STATUS {
  COMPLETE = 3,
  INCOMPLETE = 5,
}
