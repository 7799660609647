import { AnyAction } from "redux";

// export const FETCH_SCHEDULE = "fetch_schedule/LOAD";
export const API_ADD_SCHEDULE = "add_schedule/LOAD";
export const API_ADD_SCHEDULE_SUCCESS = "add_schedule/LOAD_SUCCESS";
export const API_ADD_SCHEDULE_FAIL = "add_schedule/LOAD_FAIL";

export const API_GET_ALL_SCHEDULES = "get_all_data/LOAD";
export const API_GET_ALL_SCHEDULES_SUCCESS = "get_all_data/LOAD_SUCCESS";
export const API_GET_ALL_SCHEDULES_FAIL = "get_all_data/LOAD_FAIL";

export const API_DELETE_BY_SCHEDULE = "delete_schedule_by_id/LOAD";
export const API_DELETE_BY_SCHEDULE_SUCCESS =
  "delete_schedule_by_id/LOAD_SUCCESS";
export const API_DELETE_BY_SCHEDULE_FAIL = "delete_schedule_by_id/LOAD_FAIL";

export const API_DELETE_BY_SP_SCHEDULE = "delete_sp_schedule_by_id/LOAD";
export const API_DELETE_BY_SCHEDULE_SP_SUCCESS =
  "delete_sp_schedule_by_id/LOAD_SUCCESS";
export const API_DELETE_BY_SCHEDULE_SP_FAIL = "delete_sp_schedule_by_id/LOAD_FAIL";

export const API_GET_TASK_NAME = "task_name_get/LOAD";
export const API_GET_TASK_NAME_SUCCESS = "task_name_get/LOAD_SUCCESS";
export const API_GET_TASK_NAME_FAIL = "task_name_get/LOAD_FAIL";

export const API_EDIT_SCHEDULE = "edit_schedule/LOAD";
export const API_EDIT_SCHEDULE_SCCESSS = "edit_schedule/LOAD_SUCCESS";
export const API_EDIT_SCHEDULE_FAIL = "edit_schedule/LOAD_FAIL";

export const API_UPDATE_SCHEDULE = "update_schedule/LOAD";
export const API_UPDATE_SCHEDULE_SCCESSS = "update_schedule/LOAD_SUCCESS";
export const API_UPDATE_SCHEDULE_FAIL = "update_schedule/LOAD_FAIL";

export const API_BRANCH_DROP_DOWN = "branch_drop_down/LOAD";
export const API_BRANCH_DROP_DOWN_SUCCESS = "branch_drop_down/LOAD_SUCCESS";
export const API_BRANCH_DROP_DOWN_FAIL = "branch_drop_down/LOAD_FAIL";

export const API_TASK_DROP_DOWN = "task_drop_down/LOAD";
export const API_TASK_DROP_DOWN_SUCCESS = "task_drop_down/LOAD_SUCCESS";
export const API_TASK_DROP_DOWN_FAIL = "task_drop_down/LOAD_FAIL";

export const FILTER_SCHEDULE_BRANCH = "filter_serarch/LOAD";
export const UPDATE_SCHEDULE_FETCH_STATUS = "UPDATE_SCHEDULE_FETCH_STATUS";
export const API_ADD_SCHEDULE_PROGRESS = "API_ADD_SCHEDULE_PROGRESS";
export const API_EDIT_SCHEDULE_PROGRESS = "API_EDIT_SCHEDULE_PROGRESS";
export const RUN_SCHEDULE_API = "run_schedule_api/LOAD";
export const RUN_SCHEDULE_API_SUCCESS = "run_schedule_api/LOAD_SUCCESS";
export const RUN_SCHEDULE_API_FAIL = "run_schedule_api/LOAD_FAIL";
export const ADD_SCHEDULE_UPDATE_STATUS="ADD_SCHEDULE_UPDATE_STATUS";
export interface ScheduleState {
  scheduleFetch: any[];
  data: any[];
  deleteResponse: {};
  taskDetails: [];
  addTaskMessage: any;
  editScheduleDetails: any;
  scheduleFilterData: any;
  updateMessage: any;
  taskDropDownList: any;
  branchDropDown: any;
  total_count: any;
  addScheduleSuccess: string;
  editScheduleSuccess: string;
  deleteScheduleSuccess: string;
  scheduleFetchStatus: boolean;
  processTrigger: boolean,
  progressPercentage: number,
  runScheduleResponse: string,
  addScheduleModalStatus: boolean,
}

export type ScheduleDispatch = (args: AnyAction) => AnyAction;
