import {
  API_ADD_BRANCHES,
  API_ADD_BRANCHES_FAIL,
  API_ADD_BRANCHES_SUCCESS,
  API_ADD_BRANCH_PROGRESS,
  API_DELETE_BRANCH,
  API_DELETE_BRANCH_FAIL,
  API_DELETE_BRANCH_SUCCESS,
  API_EDIT_BRANCH,
  API_EDIT_BRANCH_FAIL,
  API_EDIT_BRANCH_PROGRESS,
  API_EDIT_BRANCH_SUCCESS,
  API_GET_ALL_BRANCHES,
  API_GET_ALL_BRANCHES_FAIL,
  API_GET_ALL_BRANCHES_SUCCESS,
  API_GET_BRANCHES,
  API_GET_BRANCHES_FAIL,
  API_GET_BRANCHES_SUCCESS,
  API_GET_BRANCH_BY_ID,
  API_GET_BRANCH_BY_ID_FAIL,
  API_GET_BRANCH_BY_ID_SUCCESS,
  API_GET_BRANCH_BY_ID_VIEW,
  API_GET_BRANCH_BY_ID_VIEW_FAIL,
  API_GET_BRANCH_BY_ID_VIEW_SUCCESS,
  API_VIEW_BRANCH,
  API_VIEW_BRANCH_FAIL,
  API_VIEW_BRANCH_SUCCESS,
  BranchState,
  FILTER_BRANCH,
  IAddBranch,
  UPDATE_BRANCH,
  UPDATE_BRANCH_DLG_STATUS,
  UPDATE_BRANCH_FETCH_STATUS,
  UPDATE_DELETE_BRANCH_DLG_STATUS,
  UPDATE_EDIT_BRANCH_DLG_STATUS,
  UPDATE_VIEW_BRANCH_DLG_STATUS,
} from "../ActionTypes/index";
import { AnyAction, Dispatch } from "redux";

import { AppDispatch } from "../..";
import { IBranch } from "../../types/branch.type";
import { TBranchTable } from "../../types/user.type";

const initialState: BranchState = {
  branches: [],
  addBranchError: "",
  addBranchStatus: false,
  editBranchStatus: false,
  viewBranchStatus: false,
  editBranchError: "",
  branchFetchStatus: false,
  deleteBranchStatus: false,
  selectedData: null,
  viewData:null,
  id: "",
  data: [],
  filteredData: [],
  total_count: 0,
  addBranchSuccess: "",
  deleteBranchSuccess: "",
  editBranchSuccess: "",
  processTrigger:false,
  progressPercentage:0,
};

export const BranchReducer = (
  state: BranchState = initialState,
  action: AnyAction
): BranchState => {
  switch (action.type) {
    case UPDATE_BRANCH:
      return { ...state, branches: action.payload };
    case API_GET_ALL_BRANCHES:
      return { ...state };
    case API_GET_ALL_BRANCHES_SUCCESS:
      let branchdata = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "branches" in data && data.branches) {
          branchdata = data.branches;
        }
      }
      let total_count = 0;
      if (action.payload) {
        const { data } = action.payload;
        if (data && "total_count" in data && data.total_count) {
          total_count = data.total_count;
        }
      }
      return {
        ...state,
        total_count: total_count,
        data: branchdata,
        editBranchStatus: false,
        viewBranchStatus: false,
        filteredData: branchdata,
      };
    case API_GET_ALL_BRANCHES_FAIL:
      return { ...state };
    case API_GET_BRANCH_BY_ID:
      return { ...state, id: action.payload.id };
    case API_GET_BRANCH_BY_ID_SUCCESS:
      let branch: any = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "branch" in data && data.branch) {
          branch = data.branch;
        }
      }
      return {
        ...state,
        selectedData: branch,
        editBranchStatus: true,
        branchFetchStatus: false,
      };
    case API_GET_BRANCH_BY_ID_FAIL:
      return { ...state };
    case API_GET_BRANCH_BY_ID_VIEW:
      return { ...state, id: action.payload.id };
    case API_GET_BRANCH_BY_ID_VIEW_SUCCESS:
      let branchs: any = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "branch" in data && data.branch) {
          branchs = data.branch;
        }
      }
      console.log("branch", branchs);
      
      return {
        ...state,
        viewData: branchs,
        viewBranchStatus: true,
        branchFetchStatus: false,
      };
    case API_GET_BRANCH_BY_ID_VIEW_FAIL:
      return { ...state };
    case API_GET_BRANCHES:
      return { ...state };
    case API_GET_BRANCHES_SUCCESS:
      let branches = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "branches" in data && data.branches) {
          branches = data.branches;
        }
      }
      return { ...state, branches: branches };
    case API_GET_BRANCHES_FAIL:
      return { ...state };
    case API_ADD_BRANCHES:
      let values:boolean = false
      if(action.payload.request.data.FormData === undefined){
        values = true
      }
      return { ...state, addBranchSuccess:"", addBranchError:"",  processTrigger: values};
    case API_ADD_BRANCHES_SUCCESS:
      let message = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          message = data.message;
        }
      }
      return { ...state, addBranchStatus: false, addBranchSuccess: message, processTrigger:false, progressPercentage:0 };
    case API_ADD_BRANCHES_FAIL:
      let err = "";
      if (
        "error" in action &&
        action.error &&
        "response" in action.error &&
        action.error.response &&
        "data" in action.error.response &&
        action.error.response.data &&
        "message" in action.error.response.data
      ) {
        err = action.error.response.data.message;
      }
      return { ...state, addBranchError: err };
    case API_ADD_BRANCH_PROGRESS:
      return {...state, progressPercentage: action.payload };
    case UPDATE_BRANCH_DLG_STATUS:
      return { ...state, addBranchStatus: action.payload };
    case API_EDIT_BRANCH:
      let value:boolean = false
      if(action.payload.request.data.FormData === undefined){
        value = true
      }
      return { ...state, editBranchSuccess:"", processTrigger: value};
    case API_EDIT_BRANCH_SUCCESS:
      let messages = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          messages = data.message;
        }
      }
      return {
        ...state,
        editBranchStatus: false,
        branchFetchStatus: true,
        editBranchSuccess: messages,
        processTrigger:false,
        progressPercentage:0
      };
    case API_EDIT_BRANCH_FAIL:
      let error = "";
      if (
        "error" in action &&
        action.error &&
        "response" in action.error &&
        action.error.response &&
        "data" in action.error.response &&
        action.error.response.data &&
        "message" in action.error.response.data
      ) {
        error = action.error.response.data.message;
      }
      return { ...state, editBranchError: error };
    case API_EDIT_BRANCH_PROGRESS:
      return {...state, progressPercentage: action.payload };
    case UPDATE_EDIT_BRANCH_DLG_STATUS:
      return { ...state, editBranchStatus: action.payload };
    case API_VIEW_BRANCH:
      return { ...state };
    case API_VIEW_BRANCH_SUCCESS:
      return { ...state, viewBranchStatus: false, branchFetchStatus: true };
    case API_VIEW_BRANCH_FAIL:
      return { ...state };
    case UPDATE_VIEW_BRANCH_DLG_STATUS:
      return { ...state, viewBranchStatus: action.payload };
    case UPDATE_BRANCH_FETCH_STATUS:
      return { ...state, branchFetchStatus: action.payload };
    case API_DELETE_BRANCH:
      return { ...state, deleteBranchSuccess:"" };
    case API_DELETE_BRANCH_SUCCESS:
      let msg = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          msg = data.message;
        }
      }
      return {
        ...state,
        deleteBranchStatus: false,
        branchFetchStatus: true,
        deleteBranchSuccess: msg,
      };
    case API_DELETE_BRANCH_FAIL:
      return { ...state };
    case UPDATE_DELETE_BRANCH_DLG_STATUS:
      return { ...state, deleteBranchStatus: action.payload };
    case FILTER_BRANCH:
      const filterLoc: TBranchTable[] = state.data.filter((obj) => {
        return (
          obj.name.toLowerCase().includes(action.payload) ||
          obj.address.toLowerCase().includes(action.payload)
        );
      });
      return { ...state, filteredData: filterLoc };
    default:
      return state;
  }
};

export function updateBranchs(data: IBranch[]) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_BRANCH,
      payload: data,
    });
  };
}

// Get all branches by query for table
export function getAllBranches(query: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ALL_BRANCHES,
      payload: {
        request: {
          method: "get",
          url: query ? `branch?${query}` : `branch`,
        },
      },
    });
  };
}

// get branch by id for edit branch
export function getBranchById(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_BRANCH_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `branch/${id}` : `branch`,
        },
        id,
      },
    });
  };
}
// get branch by id for edit branch
export function getBranchByIdView(id: string) {
  return async (dispatch: Dispatch) => {
   await dispatch({
      type: API_GET_BRANCH_BY_ID_VIEW,
      payload: {
        request: {
          method: "get",
          url: id ? `branch/${id}` : `branch/0`,
        },
        id,
      },
    });
  };
}

// get only branch for dropdown
export function getBranches() {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_BRANCHES,
      payload: {
        request: {
          method: "get",
          url: `branch/get-drop-down-list`,
        },
      },
    });
  };
}

// add branch
export function addBranch(payload: IAddBranch) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_ADD_BRANCHES,
      payload: {
        request: {
          method: "post",
          url: `branch`,
          data: payload,
          onUploadProgress: (progressEvent:any) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            return dispatch({ type: API_ADD_BRANCH_PROGRESS, payload: progress });
          }
        },
      },
    });
  };
}

// update add branch
export function updateBranchStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_BRANCH_DLG_STATUS,
      payload: status,
    });
  };
}

// edit branch
export function editBranch(payload: IAddBranch, id: number) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_EDIT_BRANCH,
      payload: {
        request: {
          method: "put",
          url: `branch/${id}`,
          data: payload,
          onUploadProgress: (progressEvent:any) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            return dispatch({ type: API_EDIT_BRANCH_PROGRESS, payload: progress });
          }
        },
      },
    });
  };
}

export function viewBranch(payload: IAddBranch, id: number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_VIEW_BRANCH,
      payload: {
        request: {
          method: "put",
          url: `branch/${id}`,
          data: payload,
        },
      },
    });
  };
}

// update edit branch
export function updateEditBranchDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_EDIT_BRANCH_DLG_STATUS,
      payload: status,
    });
  };
}

export function updateViewBranchDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_VIEW_BRANCH_DLG_STATUS,
      payload: status,
    });
  };
}

// Fetch Branch after add, edit and delete branch
export function updateBranchFetchStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_BRANCH_FETCH_STATUS,
      payload: status,
    });
  };
}

// delete branch
export function deleteBranch(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_DELETE_BRANCH,
      payload: {
        request: {
          method: "delete",
          url: `branch/${id}`,
        },
      },
    });
  };
}

// update delete branch
export function updateDeleteBranchDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_DELETE_BRANCH_DLG_STATUS,
      payload: status,
    });
  };
}

// filter table branch
export function filterBranch(filterVal: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: FILTER_BRANCH,
      payload: filterVal,
    });
  };
}
