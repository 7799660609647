import { AnyAction } from "redux";

export const API_GET_ALL_CLEANER_INVOICE_NOTIFICATION = "cleaner_invoice_notification/LOAD";
export const API_GET_ALL_CLEANER_INVOICE_NOTIFICATION_SUCCESS =
  "cleaner_invoice_notification/LOAD_SUCCESS";
export const API_GET_ALL_CLEANER_INVOICE_NOTIFICATION_FAIL =
  "cleaner_invoice_notification/LOAD_FAIL";
  export const API_GET_ALL_CLEANER_NOTIFICATION = "cleaner_notification/LOAD";
  export const API_GET_ALL_CLEANER_NOTIFICATION_SUCCESS =
    "cleaner_notification/LOAD_SUCCESS";
  export const API_GET_ALL_CLEANER_NOTIFICATION_FAIL =
    "cleaner_notification/LOAD_FAIL";
  export const API_GET_CLEANER_BRANCH_LOGOUT_ACTIVITY = "cleaner_logout_activity/LOAD";
export const API_GET_CLEANER_BRANCH_LOGOUT_ACTIVITY_SUCCESS =
  "cleaner_logout_activity/LOAD_SUCCESS";
export const API_GET_CLEANER_BRANCH_LOGOUT_ACTIVITY_FAIL =
  "cleaner_logout_activity/LOAD_FAIL";
export const API_GET_ALL_TASK_NOTIFICATION = "task_notification/LOAD";
export const API_GET_ALL_TASK_NOTIFICATION_SUCCESS =
  "task_notification/LOAD_SUCCESS";
export const API_GET_ALL_TASK_NOTIFICATION_FAIL = "task_notification/LOAD_FAIL";
export const API_GET_CLEANER_INVOICE_BY_ID = "cleaner_invoice_form/LOAD";
export const API_GET_CLEANER_INVOICE_BY_ID_SUCCESS =
  "cleaner_invoice_form/LOAD_SUCCESS";
export const API_GET_CLEANER_INVOICE_BY_ID_FAIL = "cleaner_invoice_form/LOAD_FAIL";
export const API_APPROVE_CLEANER_LOGIN = "approve_cleaner_login/LOAD";
export const API_APPROVE_CLEANER_LOGIN_SUCCESS =
  "approve_cleaner_login/LOAD_SUCCESS";
export const API_APPROVE_CLEANER_LOGIN_FAIL = "approve_cleaner_login/LOAD_FAIL";
export const UPDATE_NOTIFICATION_FETCH_STATUS = "UPDATE_ACTIVITY_FETCH_STATUS";

export const FILTER_NOTIFICATION = "FILTER_NOTIFICATION_RESULT";

export const API_GET_ALL_DELETE_NOTIFICATION = "deletenotification/LOAD";
export const API_GET_ALL_DELETE_NOTIFICATION_SUCCESS =
  "deletenotification/LOAD_SUCCESS";
export const API_GET_ALL_DELETE_NOTIFICATION_FAIL =
  "deletenotification/LOAD_FAIL";
export const API_GET_ALL_CONTRACT_EXPIRY = "contractExpiry/LOAD"
export const API_GET_ALL_CONTRACT_EXPIRY_SUCCESS =
  "contractExpiry/LOAD_SUCCESS";
export const API_GET_ALL_CONTRACT_EXPIRY_FAIL =
  "contractExpiry/LOAD_FAIL";
export const API_GET_ALL_DELETE_CONTRACT_NOTIFICATION = "deletecontractExpiry/LOAD";
export const API_GET_ALL_DELETE_CONTRACT_NOTIFICATION_SUCCESS = "deletecontractExpiry/LOAD_SUCCESS";
export const API_GET_ALL_DELETE_CONTRACT_NOTIFICATION_FAIL = "deletecontractExpiry/LOAD_FAIL"

export interface NotificationState {
  cleanerData: any[];
  cleanerInvoiceDatas: any[];
  taskData: ITaskNotification[] | any;
  cleaner_total_count: number;
  cleaner_invoice_total_count: number;
  task_total_count: number;
  filteredNotification: any;
  delete_notification: any;
  deletenotificationsuccess: any;
  deletenotificationstatus: any;
  cleanerLogoutActivityData : any;
  cleanerLogoutActivityTotalCount : number;
  approveCleanerLoginStatus:boolean;
  cleanerInvoiceLists: any;
  contractExpiryNotifyList: any;
}

export interface ITaskNotification {
  id: string;
  task_date: string;
  title: string;
  description: string;
  any_time: number;
  task_type: number;
  completed: number;
  start_time: null | string;
  end_time: null | string;
  task_status: number;
  is_active: number;
  statisfied_status: null | string | number;
  task_with_sign_in: number;
  status: number;
  created_at: string;
  ref_task_id: string;
  client_id: string;
  branch_id: string;
  task_id: null | number | string;
  schedule_id: null | number | string;
  branch_name: string;
  first_name: string;
  last_name: string;
  commants: string;
  user_type_id: number;
  user_id: string;
}

export type NotificationDispatchType = (args: AnyAction) => AnyAction;
