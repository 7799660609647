import { AnyAction } from "redux";

export const API_LOGIN = "loginapi/LOAD";
export const API_LOGIN_SUCCESS = "loginapi/LOAD_SUCCESS";
export const API_LOGIN_FAIL = "loginapi/LOAD_FAIL";
export const CONTRACTOR_CONTACT_FORM = "contract_contact_form_submit/LOAD";
export const CONTRACTOR_CONTACT_FORM_SUCCESS =
  "contract_contact_form_submit/LOAD_SUCCESS";
export const CONTRACTOR_CONTACT_FORM_FAIL =
  "contract_contact_form_submit/LOAD_FAIL";
export const UPDATE_CONTRACTOR_CONTACT_FORM = "update_form_submit/LOAD";
export const UPDATE_CONTRACTOR_CONTACT_FORM_SUCCESS =
  "update_form_submit/LOAD_SUCCESS";
export const UPDATE_CONTRACTOR_CONTACT_FORM_FAIL =
  "update_form_submit/LOAD_FAIL";
export const UPDATE_USER_TYPES = "UPDATE_USER_TYPES/LOAD";
export const UPDATE_USER_TYPES_SUCCESS = "UPDATE_USER_TYPES/LOAD_SUCCESS";
export const UPDATE_USER_TYPES_FAIL = "UPDATE_USER_TYPES/LOAD_FAIL";
export const GET_TOKEN = "GET_TOKEN";
export const UPDATE_SUB_ADMIN = "UPDATE_SUB_ADMIN";
export const API_GET_REFRESH_TOKEN = "refresh_token/LOAD";
export const API_GET_REFRESH_TOKEN_SUCCESS = "refresh_token/LOAD_SUCCESS";
export const API_GET_REFRESH_TOKEN_FAIL = "refresh_token/LOAD_FAIL";
export const API_ADD_COMMENT = "add_comments/LOAD";
export const API_ADD_COMMENT_SUCCESS = "add_comments/LOAD_SUCCESS";
export const API_ADD_COMMENT_FAIL = "add_comments/LOAD_FAIL";
export const API_TASK_STATUS_LIST = "task_status_list/LOAD";
export const API_TASK_STATUS_LIST_SUCCESS = "task_status_list/LOAD_SUCCESS";
export const API_TASK_STATUS_LIST_FAIL = "task_status_list/LOAD_FAIL";
export const API_USER_TYPES_LIST = "user_types_list/LOAD";
export const API_USER_TYPES_LIST_SUCCESS = "user_types_list/LOAD_SUCCESS";
export const API_USER_TYPES_LIST_FAIL = "user_types_list/LOAD_FAIL";
export const API_SINGOUT_CLEANER = "signout_cleaner/LOAD";
export const API_SINGOUT_CLEANER_SUCCESS = "signout_cleaner/LOAD_SUCCESS";
export const API_SINGOUT_CLEANER_FAIL = "signout_cleaner/LOAD_FAIL";

export interface ILoginPayload {
  email: string;
  password: string;
}

export interface ILoggedInUser {
  token: string;
  refresh_token: string;
  gmail: string;
  first_name: string;
  last_name: string;
}

export interface AppState {
  loading: boolean;
  user: ILoggedInUser | null;
  loginError: string;
  loginCertificateUrl: string;
  token: string;
  subAdmin: ILoginPayload | null;
  refreshToken: IRefreshToken | null;
  contractContactFormSuccessStatus: boolean | null;
  update_contractContactFormSuccessStatus: boolean | null;
  addCommentStatus: boolean;
  taskStatusList: ITaskStatusList | null;
  userTypesList: IUserTypesList | Array<IUserTypesList>;
  deleteCommentStatus: boolean;
  commentFetchStatus: boolean;
  deleteCommentmessage: string;
  signoutCleaner: any;
  singOut_id: any;
  progressPercentage: number;
  contractFormMessage: any;
  update_UserTypes: any;
  delete_UserTypes: any;
  contractformResponse: any;
}

// export interface AppAction {
//     type: string;
//     payload: AxiosResponse;
// }
export interface ITaskStatusList {
  id: number;
  name: string;
}
export interface IUserTypesList {
  id: number;
  name: string;
  status: number;
}
export interface IRefreshToken {
  token: string;
  refresh_token: string;
  email: string;
  first_name: string;
  last_name: string;
}

export type AppDispatchType = (args: AnyAction) => AnyAction;
