import { Dispatch, bindActionCreators } from "redux";
import {
  InvoiceReportTableProps,
  InvoiceReportTableState,
} from "../../types/invoice.type";
import {
  Invoice_Report_ById,
  updateExportInvoice,
  updateViewInvoice,
} from "../../shared/Reducers/InvoiceReducer";
import React, { Component } from "react";

import { InvoicePDFLogoSVG } from "../../assets/images/ImagesV2";
import { Modal } from "react-bootstrap";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { exportinvoice } from "../../shared/Reducers/InvoiceReducer";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
// import html2pdf from "html2pdf.js";
// import {
//   invoicePDFLogo,
// } from "../../assets/images/ImagesV2";
import moment from "moment";

class InvoiceReportTable extends Component<
  InvoiceReportTableProps,
  InvoiceReportTableState
> {
  constructor(props: InvoiceReportTableProps) {
    super(props);
    this.state = {
      working_list: [],
      subTotal: 0,
      add_amount: 0,
      gst_amount: 0,
      net_amount: 0,
      additional_working_task: [],
      reason: "",
      extra_amount: 0,
      selectAmountIndex: null,
      selectedExAmountInx: null,
      gst_input: false,
      exportModalStatus: false,
      export_list: null,
    };
  }

  componentDidMount(): void {
    document.title = "Cleaner woking List";

    this.setState({ working_list: this.props.invoiceReportdata });
  }
  componentDidUpdate(
    prevProps: Readonly<InvoiceReportTableProps>,
    prevState: Readonly<InvoiceReportTableState>,
    snapshot?: any
  ): void {
    if (prevProps.invoiceReportdata !== this.props.invoiceReportdata) {
      this.setState({ working_list: this.props.invoiceReportdata });
    }
  }

  // handleExport_Invoice = () => {
  //   let export_invoice = [];
  //   let response = {
  //     workingList: this.state.working_list,
  //     additional_workingList: this.state.additional_working_task,
  //     total: {
  //       subtoal: this.state.subTotal,
  //       addAmount: this.state.add_amount,
  //       gstAmount: this.state.gst_amount,
  //       netAmount: this.state.net_amount,
  //     },
  //   };
  //   export_invoice.push(response);
  //   console.log(export_invoice, "export_invoice");

  //   this.setState({ export_list: export_invoice });
  // };

  calculateTotalAmount = async () => {
    // working_list calculation
    let working_list = [...this.state.working_list];
    const workignsubtotal: any = await working_list.reduce((sum, itm) => {
      return sum + Number(itm.total);
    }, 0);

    // extra task calculation
    let additional_working_task = [...this.state.additional_working_task];
    const additionalsubtotal: any = await additional_working_task.reduce(
      (sum, itm) => {
        return sum + Number(itm.amount);
      },
      0
    );

    // subtotal amount set in state
    this.setState({
      subTotal: (workignsubtotal + additionalsubtotal).toFixed(2),
    });

    // calsulate neypayment amount
    this.setState({
      net_amount: (
        Number(this.state.gst_amount) +
        Number(workignsubtotal) +
        Number(additionalsubtotal)
      ).toFixed(2),
    });
  };

  handleAmount = (item: any, index: any) => {
    // update onchange amount in state
    let working_list: any = this.state.working_list;
    working_list[index].amount = item;
    this.setState({ working_list: working_list });

    // multiply to hours and amount
    working_list[index].total = parseFloat(
      (Number(working_list[index].hours) * Number(item)).toFixed(2)
    );
    this.setState({ working_list: working_list });

    // calculate total amount
    this.calculateTotalAmount();
  };

  handleExportInvoice = async (e: any) => {
    this.props.updateExportInvoice(true);
    let response: any = {
      cleanerLoginDetails: this.props.invoiceReportdata,
      TotalAmount: this.state.subTotal,
      gst: this.state.gst_amount,
      Net_Payment: this.state.net_amount,
      extra_payment: this.state.additional_working_task,
    };
    this.props.exportinvoice(response);
  };

  handleReason = (reason: string, inx: number) => {
    let add_reason = [...this.state.additional_working_task];
    add_reason[inx].reason = reason;
    this.setState({ additional_working_task: add_reason });
  };

  handleExtraAmount = async (item: any, index: number) => {
    // update onchange amount in state
    let additional_working_task: any = this.state.additional_working_task;
    additional_working_task[index].amount = item;
    this.setState({ additional_working_task: additional_working_task });

    // calculate total amount
    this.calculateTotalAmount();
  };

  handleAddAmount = () => {
    let add = {
      reason: "",
      amount: 0,
    };
    let add_item = [...this.state.additional_working_task];
    add_item.push(add);
    this.setState({ additional_working_task: add_item });
  };

  handleRemove = (index: any) => {
    let remove_item = this.state.additional_working_task.filter(
      (item: any, inx: any) => index !== inx
    );
    this.setState({ additional_working_task: remove_item });
  };

  downloadInvoicePdf = () => {
    const input = document.querySelector(".invoice-export-modal") as HTMLElement;

    const doc = new jsPDF({
      format: [595, 842], // A4 page size in points
      unit: "px"
    });

    // Adding the fonts
    doc.setFont("Inter-Regular", "normal");

    doc.html(input, {
      async callback(doc) {
        await doc.save("document");
      }
    });
  };

  render() {
    return (
      <>
        <tbody>
          {this.state.working_list.map((item: any, index: any) => {
            return (
              <tr>
                <td className="invoice_input">{item.client_name}</td>
                <td className="invoice_input">{item.branch_name}</td>
                <td className="invoice_input">{item.reason}</td>
                <td className="invoice_input">{item.shift_type}</td>
                <td className="invoice_input">{item.sign_in_date_time}</td>
                <td className="invoice_input">{item.sign_out_date_time}</td>
                <td className="invoice_input">{item.hours}</td>
                <td className="cursor: pointer invoice_input">
                  {this.state.selectAmountIndex === index ? (
                    <input
                      className="form-control btn-border"
                      style={{ width: "90%" }}
                      type="number"
                      value={item.amount}
                      onChange={(e: any) =>
                        this.handleAmount(e.target.value, index)
                      }
                    />
                  ) : (
                    <div
                      onClick={() =>
                        this.setState({ selectAmountIndex: index })
                      }
                    >
                      {item.amount}
                    </div>
                  )}
                </td>
                <td>{item.total}</td>
              </tr>
            );
          })}
        </tbody>
        <tbody>
          {this.state.additional_working_task.map((item: any, inx: any) => {
            return (
              <>
                <tr>
                  <td>
                    <button
                      className="btn btn-border"
                      onClick={() => this.handleRemove(inx)}
                    >
                      X
                    </button>
                  </td>
                  <td
                    className="cursor: pointer invoice_input"
                    colSpan={7}
                    onClick={() => this.setState({ selectedExAmountInx: inx })}
                  >
                    {this.state.selectedExAmountInx === inx ? (
                      <input
                        className="form-control btn-border"
                        value={item.reason}
                        type="text"
                        placeholder="Reason"
                        onChange={(e: any) =>
                          this.handleReason(e.target.value, inx)
                        }
                      />
                    ) : (
                      <div className="invoice_input">
                        <span>
                          {this.state.selectedExAmountInx === null
                            ? "Reason"
                            : item.reason}{" "}
                        </span>
                      </div>
                    )}
                  </td>{" "}
                  <td
                    className="cursor: pointer invoice_input"
                    onClick={() => this.setState({ selectedExAmountInx: inx })}
                  >
                    {this.state.selectedExAmountInx === inx ? (
                      <input
                        className="form-control btn-border"
                        value={item.amount}
                        type="number"
                        placeholder="Amount"
                        onChange={(e) => {
                          this.handleExtraAmount(e.target.value, inx);
                        }}
                      />
                    ) : (
                      <div>
                        <span>
                          {this.state.selectedExAmountInx === inx
                            ? "Amount"
                            : item.amount}
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
        <button
          className="btn btn-border"
          style={{ marginTop: "10px" }}
          onClick={this.handleAddAmount}
        >
          + Additional Work
        </button>

        <tbody>
          <>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2} className="bold-text invoice_input">
                {" "}
                Total Amount :
              </td>
              <td className="invoice_input">{this.state.subTotal}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2} className="bold-text invoice_input ">
                GST Amount :
              </td>
              <td
                className="cursor: pointer invoice_input"
                onClick={() =>
                  this.setState({
                    gst_input: true,
                  })
                }
              >
                {this.state.gst_input === true ? (
                  <input
                    type="number"
                    className="form-control btn-border"
                    value={this.state.gst_amount}
                    style={{ width: "80%" }}
                    onChange={(e: any) => {
                      this.setState({ gst_amount: e.target.value });
                      this.calculateTotalAmount();
                    }}
                  />
                ) : (
                  <div className="invoice_input">
                    <span>{this.state.gst_amount}</span>
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2} className="bold-text invoice_input">
                NET Amount :
              </td>
              <td className="invoice_input">{this.state.net_amount}</td>
            </tr>
          </>
        </tbody>
        <div>
          <button
            type="button"
            className="btn btn-outline-primary"
            style={{ marginTop: "10px" }}
            onClick={() => {
              this.setState({ exportModalStatus: true });
              // this.props.updateViewInvoice(false);
            }}
          >
            Export
          </button>
        </div>
        <>
          <Modal
            className="edit-comments invoice-export-modal-modal"
            size="lg"
            dialogClassName="modal-90w"
            aria-labelledby="contained-modal-title-vcenter"
            show={this.state.exportModalStatus}
            onHide={() => {
              // this.props.updateViewInvoice(true);
              this.setState({ exportModalStatus: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                <h3 className="m-0">Export Invoice Report</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
              <div>
                <button
                  className="btn btn-outline-primary"
                  onClick={() => this.downloadInvoicePdf()}
                >
                  Download
                </button>
              </div>
              <div
                className="container invoice-export-modal"
                style={{ padding: "20px", width: "595px", fontSize:"14px" }}
              >
                <div className="top-section">
                  <div className="column">
                    {/* <button className="btn btn-primary update-btn-svg">
                      <InvoicePDFLogoSVG />
                    </button> */}
                  </div>
                  <div>
                    <h3>TAX INVOICE</h3>
                  </div>
                  <div className="column">
                    {/* <p className="top-section-address">
                      0402 668 816 | 1300 142 715<br></br>
                      quotes@smmnclean.com.au<br></br>
                      www.smmnclean.com.au<br></br>
                      Melbourine, VIC-3000
                    </p> */}
                  </div>
                </div>

                <div className="row">
                  <div className="column1">
                    <p className="fs-12">Invoice Date</p>
                  </div>
                  <div className="column1">
                    <p className="fs-12">Invoice Number</p>
                  </div>
                </div>
                <div className="row">
                  <div className="column2">
                    <p className="fs-12">{moment(Date()).format("DD MMM YYYY")}</p>
                  </div>
                  <div className="column2">
                    <p className="fs-12">CTINV{moment(Date()).format("hmsMDYY")}</p>
                  </div>
                </div>
                <div className="table" style={{ width: "100%" }}>
                  <table className="invoice-export-modal table">
                    <tr className="head">
                      <th className="fs-12">Client</th>
                      <th className="fs-12">Branch</th>
                      <th className="fs-12">Shift</th>
                      <th className="fs-12">Date</th>
                      <th className="fs-12">Hours</th>
                      <th className="fs-12">Amount</th>
                      <th className="fs-12" style={{ textAlign: "right" }}>
                        Total
                      </th>
                    </tr>

                    {this.state.working_list &&
                      this.state.working_list.map((exp: any) => (
                        <tr className="data">
                          <td
                            className="fs-12 invoice_input_export"
                          >
                            {exp.client_name}
                          </td>
                          <td
                            className="fs-12 invoice_input_export"
                          >
                            {exp.branch_name}
                          </td>
                          <td
                            className="fs-12 invoice_input_export"
                          >
                            {exp.shift_type}
                          </td>
                          <td
                            className="fs-12 invoice_input_export"
                          >
                            {exp.sign_in_date_time} To {exp.sign_out_date_time}
                          </td>
                          <td
                            className="fs-12 invoice_input_export"
                          >
                            {exp.hours}
                          </td>
                          <td
                            className="fs-12 invoice_input_export"
                          >
                            {exp.amount}
                          </td>
                          <td
                            className="fs-12 invoice_input_export"
                            style={{ textAlign: "right" }}
                          >
                            {exp.total}
                          </td>
                        </tr>
                      ))}
                  </table>
                  {this.state.additional_working_task.length !== 0 && (
                    <div>
                      <h3
                        className="fs-12 invoice_input_export"
                        style={{ marginTop: "36px" }}
                      >
                        Extra Payment
                      </h3>
                    </div>
                  )}
                  <table className="invoice-export-modal table">
                    {this.state.additional_working_task.length !== 0 && (
                      <tr className="head">
                        <th
                          className="fs-12 invoice_input_export"
                          style={{ width: "90%" }}
                        >
                          Reason
                        </th>
                        <th
                          className="fs-12 invoice_input_export"
                          style={{ width: "10%", textAlign: "right" }}
                        >
                          Amount
                        </th>
                      </tr>
                    )}
                    {this.state.additional_working_task &&
                      this.state.additional_working_task.map((ext: any) => (
                        <tr className="data">
                          <td
                            className="fs-12 invoice_input_export"
                            style={{ width: "90%" }}
                          >
                            {ext.reason}
                          </td>
                          <td
                            className="fs-12 invoice_input_export"
                            style={{ width: "10%", textAlign: "right" }}
                          >
                            {ext.amount}
                          </td>
                        </tr>
                      ))}
                  </table>
                </div>
                <div className="container-fluvid">
                  <div className="row">
                    <div className="col-md-6">
                    </div>
                    <div className="col-md-6">
                      <div className="text-end">
                        <div className="row">
                          <p className="col-md-4"></p>
                          <p className="col-md-5 invoice_input_export bold-text fs-12">SubTotal : </p>
                          <p className="col-md-3 invoice_input_export bold-text fs-12">
                            {this.state.subTotal}
                          </p>
                        </div>
                        <div className="row">
                          <p className="col-md-4"></p>
                          <p className="col-md-5 invoice_input_export bold-text fs-12">
                            GST Amount : 
                          </p>
                          <p className="col-md-3 invoice_input_export bold-text fs-12">
                            {this.state.gst_amount}
                          </p>
                        </div>
                        <div className="row">
                          <p className="col-md-4"> </p>
                          <p className="col-md-5 invoice_input_export bold-text">
                            Net Payment : 
                          </p>
                          <p className="col-md-3 invoice_input_export bold-text">
                            {this.state.net_amount}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="container-fluvid">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="bottom-section">
                        <div className="box">
                          <h3 style={{ margin: 0 }}>EFT PAYMENT DETAILS:</h3>
                          <p style={{ margin: 0 }}>
                            WHYTE SPECIALISED EQUIPMENT PTY LTD
                          </p>
                          <p style={{ margin: 0 }}>
                            BSB : 063-239 ACCOUNT : 10282576
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="bottom-section">
                        <div className="box">
                          <p style={{ margin: 0 }}>
                            ALL GOODS REMAIN THE PROPERTY OF WHYTE SPECIALISED
                            EQUIPMENT PTY LTD UNTIL FULL PAYMENT HAS BEEN MADE
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="text-end">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => this.downloadInvoicePdf()}
                >
                  Download
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  invoiceReportdata: state.invoice.invoiceReportdata,
});
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    Invoice_Report_ById: bindActionCreators(Invoice_Report_ById, dispatch),
    exportinvoice: bindActionCreators(exportinvoice, dispatch),
    updateViewInvoice: bindActionCreators(updateViewInvoice, dispatch),
    updateExportInvoice: bindActionCreators(updateExportInvoice, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceReportTable);
