import * as Yup from "yup";

import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { PaperClipSVG, TrashSVG } from "../../assets/images/ImagesV2";
import { addShift, listAllCleaner } from "../../shared/Reducers";
import dayjs, { Dayjs } from "dayjs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AttachmentPreview from "../../shared/Components/AttachmentPreview";
import Button from "@material-ui/core/Button";
import { CustomSelect } from "../../shared/Components/CustomSelect";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { ICleaner } from "../../shared/ActionTypes";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Modal } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import React from "react";
import { RootState } from "../../index";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import TimePicker from "../DailyTask/TimePicker";
import { connect } from "react-redux";
import docImage from "../../assets/images/google-docs.png";
// date picker
import moment from "moment";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";

type Props = {
  onClose: (status: boolean) => void;
  addShiftError?: any;
  addShift?: any;
  processTrigger: any;
  addShiftSuccess: any;
  progressPercentage: number;
  cleanerList?: any;
  listAllCleaner?: any;
};

type TAddShiftState = {
  endDatevalue: any;
  endDateError: any;
  startDatevalue: any;
  start_time: string;
  end_time: string;
};

class AddShift extends React.Component<Props, TAddShiftState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      endDatevalue: "",
      startDatevalue: dayjs(),
      start_time: moment(new Date()).format("HH:mm"),
      end_time: moment(new Date()).format("HH:mm"),
      endDateError: ""
    };
  }

  componentDidMount(): void {
    this.listData();
    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.add("d-none");
      button.removeAttribute("disabled");
    }

    // date picker readonly
    let datePicker: NodeListOf<Element> = document.querySelectorAll(
      ".MuiFormControl-root input"
    ) as NodeListOf<Element>;
    if (datePicker.length > 0) {
      for (let index = 0; index < datePicker.length; index++) {
        const element = datePicker[index];
        datePicker[index].setAttribute("readonly", "true");
      }
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<TAddShiftState>,
    snapshot?: any
  ): void {
    if (prevProps.processTrigger !== this.props.processTrigger) {
    }
    if (prevProps.addShiftSuccess !== this.props.addShiftSuccess) {
    }
  }

  listData = () => {
    this.props.listAllCleaner();
  };

  handleAddShift = async (formValues: any) => {

    if(this.state.endDatevalue !== "" && this.state.endDatevalue !== undefined){
      this.setState({ endDateError : "" });
      let data: any = [];

      data = {
        name: formValues.name,
        start_time: this.state.start_time,
        end_time: this.state.end_time,
        start_date: moment(
          new Date(this.state.startDatevalue?.toString())
        ).format("YYYY/MM/DD"),
        end_date: moment(new Date(this.state.endDatevalue?.toString())).format(
          "YYYY/MM/DD"
        ),
        cleaners: formValues.cleaners,
      };
      var element = document.getElementById("spinner-border");
      var button = document.getElementById("create-button");
      if (element !== null && button !== null) {
        element.classList.remove("d-none");
        button.setAttribute("disabled", "");
      }
      await this.props.addShift(data);
      setTimeout(() => {
        this.props.onClose(false);
      }, 1000);
    }else{
      this.setState({ endDateError : "Please select End Date" });
    }
    
  };
  validationSchema = () => {
    if(this.state.endDatevalue !== "" && this.state.endDatevalue !== undefined){
      this.setState({ endDateError : "" });
    }else{
      this.setState({ endDateError : "Please select End Date" });
    }
    return Yup.object().shape({
      name: Yup.string().required("Enter Shift Name"),
      cleaners: Yup.array().min(1, "Select atleast one cleaner"),
    });
  };

  handleStartDateClick = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let startDate: HTMLElement = document.querySelector(
        ".startdate button.MuiButtonBase-root"
      ) as HTMLElement;
      startDate.click();
    }
  };

  handleEndDateClick = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let endDate: HTMLElement = document.querySelector(
        ".enddate button.MuiButtonBase-root"
      ) as HTMLElement;
      endDate.click();
    }
  };

  handleStartDateChange = async (newValue: Dayjs | null) => {
    console.log(newValue);
    this.setState({ startDatevalue: newValue });
  };
  handleEndDateChange = async (newValue: Dayjs | null) => {
    this.setState({ endDatevalue: newValue, endDateError: "" });
  };

  handleStartTime = (val: string) => {
    this.setState({ start_time: val });
  };

  handleEndTime = (val: string) => {
    this.setState({ end_time: val });
  };

  progress = (e: any) => {
    console.log("progress", e);
  };

  render(): React.ReactNode {
    const initialValues = {
      name: "",
      cleaners: [],
    };
    return (
      <div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={this.handleAddShift}
          validationSchema={this.validationSchema}
        >
          <Form encType="multipart/form-data">
            <div className="row">
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Name<span className="text-project">*</span>
                </label>
                <Field
                  name="name"
                  type={"text"}
                  className="form-control text-field"
                />
                <ErrorMessage
                  name="name"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Cleaner<span className="text-project">*</span>
                </label>
                <Field
                  className="custom-select"
                  name="cleaners"
                  options={this.props.cleanerList}
                  component={CustomSelect}
                  placeholder="Select Cleaner"
                  isMulti={true}
                />
                <ErrorMessage
                  name="cleaners"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">Start date</label>
                <div
                  className="startdate"
                  onClick={() => this.handleStartDateClick()}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    id="startDate"
                  >
                    <Stack spacing={3}>
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        maxDate={this.state.endDatevalue}
                        value={this.state.startDatevalue}
                        onChange={this.handleStartDateChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </div>

              <div className="mb-2 col-md-6">
                <label className="form-label">End date</label>
                <div
                  className="enddate"
                  onClick={() => this.handleEndDateClick()}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs} id="endDate">
                    <Stack spacing={3}>
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        minDate={this.state.startDatevalue}
                        value={this.state.endDatevalue}
                        onChange={this.handleEndDateChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
                <div className="cus-alert">{this.state.endDateError}</div>
              </div>
              <div className=" mb-2 col-md-6">
                <label className="form-label">Start Time</label>
                <TimePicker
                  name="start_time"
                  value={this.state.start_time}
                  onChange={this.handleStartTime}
                />
              </div>
              <div className=" mb-2 col-md-6">
                <label className="form-label">End Time</label>
                <TimePicker
                  name="end_time"
                  value={this.state.end_time}
                  onChange={this.handleEndTime}
                />
              </div>
              <div className="row mt-3 mb-3">
                <div className="col-md-12 cus-progress">
                  <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.props.processTrigger}
                  >
                    <Modal.Body className="p-0">
                      <ProgressBar
                        now={this.props.progressPercentage}
                        label={
                          this.props.progressPercentage === 100
                            ? `Processing your request...`
                            : `Shift Adding....${this.props.progressPercentage}%`
                        }
                      />
                    </Modal.Body>
                  </Modal>
                  {/* {this.props.processTrigger ? <ProgressBar now={this.state.processValue} label={`${this.state.processValue}%`} /> : ""} */}
                  <div className="d-flex">
                    <div className="mr-16px">
                      <button
                        type="submit"
                        id="create-button"
                        className="btn btn-primary create-button"
                        onClick={(e) => this.progress(e)}
                      >
                        Create
                        <span
                          id="spinner-border"
                          className="spinner-border spinner-border-sm d-none"
                        ></span>
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn cancel-button"
                        onClick={() => this.props.onClose(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  processTrigger: state.shift.processTrigger,
  addShiftSuccess: state.shift.addShiftSuccess,
  progressPercentage: state.shift.progressPercentage,
  cleanerList: state.tasktemplate.cleanerList,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addShift: bindActionCreators(addShift, dispatch),
    listAllCleaner: bindActionCreators(listAllCleaner, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddShift);
