import React from 'react';
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from '../../index';
import { changeAddEditStatusCommetsStatus, changeViewTaskDetailsStatus, addComments, editCleanerComments, changeEditTaskDetailsData, deleteAttachementId } from '../../shared/Reducers';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Constants from "../../shared/Constants";
import { CustomSelect } from '../../shared/Components/CustomSelect';
import Button from '@material-ui/core/Button';
import { PaperClipSVG, TrashSVG } from "../../assets/images/ImagesV2";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";
import docImage from "../../assets/images/google-docs.png";
import AttachmentPreview from "../../shared/Components/AttachmentPreview";
import { AttachmentDownloadSVG } from "../../assets/images/ImagesSVG";
import { ModalBackSVG } from "../../assets/images/ImagesV2";
import { confirmAlert } from 'react-confirm-alert';

export interface IAddEditCommentsCleanerHomeProps {
    addEditTaskCommentsModalStatus ? : any;
    addCommentStatus ?: any;
    changeAddEditStatusCommetsStatus ? : any;
    getCleanerBranchTaskDetailsData? : any;
    getEditTaskDetailsData? : any;
    changeViewTaskDetailsStatus? : any;
    addComments ? : any;
    editCleanerComments? : any;
    changeEditTaskDetailsData?:any;
    deleteAttachementId?:any;
}

export interface IAddEditCommentsCleanerHomeState {
    attachmentUrls: any,
    deleteAttachementId?:any;
    AttachmentPreviewStatus : boolean;
    previewUrl  : string;
    previewType : string;
}

class AddEditCommentsCleanerHome extends React.Component<IAddEditCommentsCleanerHomeProps, IAddEditCommentsCleanerHomeState> {
    constructor(props: IAddEditCommentsCleanerHomeProps) {
        super(props);
        this.state = {
            attachmentUrls: [],
            deleteAttachementId : [],
            AttachmentPreviewStatus : false,
            previewUrl  : "",
            previewType : "",
        }
    }

    componentDidMount(): void {
        if(this.props.getEditTaskDetailsData.attachment !== undefined ){
            this.setState({
                attachmentUrls: this.props.getEditTaskDetailsData.attachment.map((obj: any) => ({url: `${Constants.imgThumbUrl}${obj.file_name}`, name: obj.file_name, id: obj.id,  type: 'download', download_url: `${Constants.imgUrl}${obj.file_name}`, file_type: obj.type}))
            });
        }
    }
    componentDidUpdate(prevProps: Readonly<IAddEditCommentsCleanerHomeProps>, prevState: Readonly<IAddEditCommentsCleanerHomeState>, snapshot?: any): void {
        
        if (prevProps.getEditTaskDetailsData !== this.props.getEditTaskDetailsData) {
            if(this.props.getEditTaskDetailsData.attachment !== undefined ){
                this.setState({
                    attachmentUrls: this.props.getEditTaskDetailsData.attachment.map((obj: any) => ({url: `${Constants.imgThumbUrl}${obj.file_name}`, name: obj.file_name, id: obj.id,  type: 'download', download_url: `${Constants.imgUrl}${obj.file_name}`, file_type: obj.type}))
                });
            }
        }
        
        if(prevProps.addCommentStatus != this.props.addCommentStatus && this.props.addCommentStatus == true){
            this.props.changeAddEditStatusCommetsStatus(false);
            this.props.changeViewTaskDetailsStatus(true);
            this.setState({ attachmentUrls : [] });
            this.props.changeEditTaskDetailsData([]);
        }
    }

    handleImageChange = async (e: any) => {
        let files = [];
        let urls = [];
        files = [...e.target.files];

        let fileurls: any = [];
        await files.map(async (file: any) => {
            fileurls.push({
                url : URL.createObjectURL(file),
                file : file,
                id : '0',
                file_type : file.type,
                download_url : URL.createObjectURL(file)
            });
        });
        urls = [...fileurls];
        this.setState({ attachmentUrls: [...this.state.attachmentUrls, ...urls] });
    };

    handleDeleteImage = (index: number) => {
        const urls = this.state.attachmentUrls;
        
        confirmAlert({
            title: 'Confirm deletion',
            message: 'Are you sure you want to delete this Attchment ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        
                        // check exist file
                        if(urls[index].id !== undefined && urls[index].id !== '0'){
                            let deleteAttachementId = this.state.deleteAttachementId;
                            deleteAttachementId.push(urls[index].id);
                            this.setState({ deleteAttachementId: deleteAttachementId });

                            urls.splice(index, 1);
                            this.setState({ attachmentUrls: urls });
                        }else{
                            urls.splice(index, 1);
                            this.setState({ attachmentUrls: urls });
                        }
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }
    handleAddComment = async (formValues: any) => {
        if(this.props.getEditTaskDetailsData.length === 0){
            let data = new FormData();
            for (let index = 0; index < this.state.attachmentUrls.length; index++) {
                if(this.state.attachmentUrls[index].id === '0'){
                    data.append('attachments', this.state.attachmentUrls[index].file);
                }
            }
            data.append('comments', formValues.comments);
            data.append("comments_task_status", formValues.task_status);
            data.append("reference_id", this.props.getCleanerBranchTaskDetailsData.id);
            data.append("reference_type", "task");
            
            await this.props.addComments(data);
            this.setState({ attachmentUrls : [] });
    
            var element = document.getElementById("spinner-border");
            var button = document.getElementById("create-button");
            if (element !== null && button !== null) {
                element.classList.remove("d-none");
                button.setAttribute('disabled', '');
            }
        }else if(this.props.getEditTaskDetailsData !== undefined && this.props.getEditTaskDetailsData.id > 0){
            let data = new FormData();
            for (let index = 0; index < this.state.attachmentUrls.length; index++) {
                if(this.state.attachmentUrls[index].id === '0'){
                    data.append('attachments', this.state.attachmentUrls[index].file);
                }
            }
            data.append('comments', formValues.comments);
            data.append('comments_task_status', formValues.task_status);
            data.append('reference_id', this.props.getCleanerBranchTaskDetailsData.id);
            data.append("reference_type", "task");

            await this.state.deleteAttachementId.map(async (id: any) => {
                this.props.deleteAttachementId(id);
            });
            await this.props.editCleanerComments(data,this.props.getEditTaskDetailsData.id);
            // this.props.changeAddEditStatusCommetsStatus(false);
            // this.props.changeViewTaskDetailsStatus(true);
            this.setState({ attachmentUrls : [] });
            this.props.changeEditTaskDetailsData([]);


            var element = document.getElementById("spinner-border");
            var button = document.getElementById("create-button");
            if (element !== null && button !== null) {
                element.classList.remove("d-none");
                button.setAttribute('disabled', '');
            }
        }
        
        
    }

    downloadFile = (url: string, name = 'image.png') => {
        this.downloadResource(url, name);
    }

    forceDownload = (blob: any, filename: String) => {
        const a: any = document.createElement('a');
        a.download = filename;
        a.href = blob;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    // Current blob size limit is around 500MB for browsers
    downloadResource = (url: any, filename: any) => {
        if (url) {
            if (!filename) filename = url.split('\\').pop().split('/').pop();
            fetch(url, {
                headers: new Headers({
                    'Origin': window.location.origin
                }),
                mode: 'cors'
            })
                .then(response => response.blob())
                .then(blob => {
                    let blobUrl = window.URL.createObjectURL(blob);
                    this.forceDownload(blobUrl, filename);
                })
                .catch(e => console.error(e));
        }
    }
    handlePreviewClose = () => {
        this.setState({ AttachmentPreviewStatus : false });
    }
    handlePreviewOpen = (file_type:any, url:any) => {
        console.log(url);
        
        if(file_type === "image/png" || file_type === "image/jpeg" || file_type === "image/jpg" || file_type === "video/mp4" || file_type === "video/webm" || file_type === "application/pdf"){
            this.setState({ 
                AttachmentPreviewStatus : true,
                previewUrl  : url,
                previewType : file_type
            });
        }
    }
    
    validationSchema = () => {
        if(this.props.getCleanerBranchTaskDetailsData.task_type === Constants.REGULAR_TASK) {
            return Yup.object().shape({
                comments: Yup.string(), //.required("Please enter comments"),
                task_status: Yup.string().required("Please select task status"),
                task_list: Yup.string(),
                attachments: Yup.mixed(),
            })
        }else{
            return Yup.object().shape({
                comments: Yup.string().required("Please enter comments"),
                // task_status: Yup.string().required("Please select task status"),
                task_list: Yup.string(),
                attachments: Yup.mixed(),
            })
        }
        
    }
    render(): React.ReactNode {
        let taskStatusOption:any = [];
        taskStatusOption = [
            { value : Constants.TASK_CREATED, label : "To do" },
            { value : Constants.TASK_INPROGRESS, label : "Inprogress" },
            { value : Constants.TASK_COMPLETED, label : "Completed" },
        ];
        
        return (
            <Modal
                size="lg"
                show={this.props.addEditTaskCommentsModalStatus}
                onHide={() => {
                    this.props.changeAddEditStatusCommetsStatus(false);
                    this.props.changeViewTaskDetailsStatus(true);
                    this.setState({ attachmentUrls : [] });
                    this.props.changeEditTaskDetailsData([]);
                }}
                aria-labelledby="example-modal-sizes-title-lg">
                    {/* <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            <h3 className="m-0">Task Status</h3>
                        </Modal.Title>
                    </Modal.Header> */}
                    <Modal.Header>
                        <div className='w-100'>
                            <div className='row'>
                                <div className='col-10'>
                                    <h3 className="m-0">Task status</h3>
                                </div>
                                <div className='col-2 text-end'>
                                    <Button className='btn btn-primary modal-back-btn' onClick={() => {
                                        this.props.changeAddEditStatusCommetsStatus(false);
                                        this.props.changeViewTaskDetailsStatus(true);
                                        this.setState({ attachmentUrls : [] });
                                        this.props.changeEditTaskDetailsData([]);
                                    }}><ModalBackSVG/>&nbsp;Back</Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                        <div className='row'>
                            <Formik
                                validationSchema={this.validationSchema}
                                onSubmit={ this.handleAddComment }
                                initialValues={{
                                    comments: this.props.getEditTaskDetailsData.comments !== undefined ? this.props.getEditTaskDetailsData.comments : "",
                                    task_status: this.props.getEditTaskDetailsData.comments_task_status !== undefined && this.props.getEditTaskDetailsData.comments_task_status !== null ? this.props.getEditTaskDetailsData.comments_task_status : "",
                                    task_list: "",
                                    attachments: ""
                                }}>
                                <Form id="addCommentsFormReset">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <Field as="textarea" className="form-control comment-field w-100" name="comments" placeholder="Add a comment..." />
                                            <ErrorMessage name={"comments"} component={"div"} className="cus-alert"/>
                                        </div>
                                        <div className="col-md-3 res-mt-3">
                                            {
                                                this.props.getCleanerBranchTaskDetailsData !== null && 
                                                <div className='mb-3'>
                                                    <Field
                                                        className="custom-select dropdown-field"
                                                        name="task_status"
                                                        options={taskStatusOption}
                                                        component={CustomSelect}
                                                        placeholder="Select Status"
                                                        isMulti={false}
                                                    />
                                                </div>
                                            }
                                            <div className="attachment-btn">
                                                <input
                                                    type="file"
                                                    multiple
                                                    accept=".pdf,.PDF,.mp4,.MP4,.webm,.WEBM,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.doc,.DOC,.docx.DOCX"
                                                    style={{ display: 'none' }}
                                                    onChange={this.handleImageChange}
                                                    id="contained-button-file"
                                                />
                                                <label htmlFor="contained-button-file" style={{width: "100%"}}>
                                                    <Button variant="contained" component="span" style={{width: "100%", justifyContent: "left"}}>
                                                        <PaperClipSVG /> Attach
                                                    </Button>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                        <ErrorMessage name={"task_status"} component={"div"} className="cus-alert"/>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        {this.state.attachmentUrls.length > 0 ? (
                                            <div className="mb-2">
                                                <label className="form-label m-0">Attachments</label>
                                            </div>
                                        ) : (<></>)}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="d-flex row">
                                                {this.state.attachmentUrls &&
                                                    this.state.attachmentUrls.map((obj: any, index: number) => (
                                                        <div className="img-card mr-16px mb-3">
                                                            <span className="delete"><a onClick={() => this.handleDeleteImage(index)}><TrashSVG /></a></span>
                                                            {
                                                                obj.type === 'download' && <span className="download">
                                                                    <a href="javascript:void(0)" onClick={() => this.downloadFile(obj.download_url, obj.file_name)}><AttachmentDownloadSVG /></a>
                                                                </span>
                                                            }

                                                            <div className="img-preview" onClick={() => this.handlePreviewOpen(obj.file_type,obj.download_url)}>
                                                                <img className="img-thumb-perview" src={
                                                                    obj.file_type === "application/pdf" ? pdfImage :
                                                                        obj.file_type === "video/mp4" || obj.file_type === "video/webm" ? videoImage :
                                                                            obj.file_type === "image/png" || obj.file_type === "image/jpeg" || obj.file_type === "image/jpg" ? obj.url : docImage
                                                                } alt="attach" />
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <AttachmentPreview displayPreview={this.state.AttachmentPreviewStatus} previewUrl={this.state.previewUrl} previewType={this.state.previewType} handlePreviewClose={this.handlePreviewClose}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-12">
                                            <button type="submit" id="create-button" className="btn btn-primary create-button">Save <span id="spinner-border" className="spinner-border spinner-border-sm d-none"></span></button>
                                        </div>
                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    </Modal.Body>
                </Modal>
        )
    }
}
const mapStateToProps = (state: RootState) => ({
    addEditTaskCommentsModalStatus  : state.cleanerhome.addEditTaskCommentsModalStatus,
    addCommentStatus : state.app.addCommentStatus,
    getCleanerBranchTaskDetailsData : state.cleanerhome.getCleanerBranchTaskDetailsData,
    getEditTaskDetailsData : state.cleanerhome.getEditTaskDetailsData
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        changeAddEditStatusCommetsStatus: bindActionCreators(changeAddEditStatusCommetsStatus, dispatch),
        changeViewTaskDetailsStatus : bindActionCreators(changeViewTaskDetailsStatus, dispatch),
        addComments : bindActionCreators(addComments, dispatch),
        editCleanerComments : bindActionCreators(editCleanerComments, dispatch),
        changeEditTaskDetailsData : bindActionCreators(changeEditTaskDetailsData, dispatch),
        deleteAttachementId: bindActionCreators(deleteAttachementId, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCommentsCleanerHome);