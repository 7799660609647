import React from "react";

export const DrawerSVG = (Props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="255" height="198" viewBox="0 0 255 198" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.85805 134.71C4.8347 131.895 4.71112 128.816 3.51195 126.088C3.39292 125.818 3.81985 125.648 3.93873 125.918C5.1813 128.759 5.30337 131.963 4.28058 134.891C4.1829 135.17 3.7609 134.988 3.85805 134.71Z" fill="white"/>
        <path d="M253.866 6.80664H188.423V11.0037C188.422 11.2186 188.496 11.4271 188.633 11.5935C188.501 14.874 188.423 19.062 188.423 23.6129V135.821C188.423 146.339 188.847 154.896 189.366 154.896H252.924C253.443 154.896 253.866 146.339 253.866 135.821V23.6129C253.866 19.062 253.789 14.8741 253.657 11.5935C253.793 11.4271 253.867 11.2186 253.866 11.0037L253.866 6.80664Z" fill="white"/>
        <path d="M252.924 155.349H189.365C188.87 155.349 188.543 155.349 188.245 149.325C188.066 145.709 187.968 140.913 187.968 135.821V23.6127C187.968 19.2143 188.043 14.9392 188.178 11.575C188.263 9.55515 188.36 7.93717 188.466 6.7654C188.675 4.47147 188.874 4.08398 189.365 4.08398H252.924C253.415 4.08398 253.614 4.47147 253.823 6.7654C253.93 7.9416 254.027 9.55958 254.111 11.5741L254.112 11.575C254.247 14.9357 254.321 19.2107 254.321 23.6127V135.821C254.321 140.914 254.223 145.709 254.044 149.325C253.747 155.349 253.419 155.349 252.924 155.349ZM189.624 154.442H252.665C252.995 153.202 253.411 146.47 253.411 135.821V23.6127C253.411 19.2227 253.337 14.9609 253.202 11.6122H253.202C253.118 9.61208 253.022 8.00895 252.917 6.84737C252.876 6.22344 252.791 5.60322 252.661 4.99145H189.628C189.499 5.60322 189.413 6.22344 189.373 6.84737C189.267 8.00452 189.172 9.60765 189.087 11.6122C188.953 14.9638 188.878 19.226 188.878 23.6127V135.821C188.878 146.47 189.295 153.202 189.624 154.442Z" fill="#E4E4E4"/>
        <path d="M252.924 4.53711H189.366C189.116 4.53759 188.877 4.63669 188.7 4.81272C188.523 4.98875 188.424 5.22738 188.423 5.47634V11.0028C188.422 11.2177 188.496 11.4263 188.633 11.5927C188.721 11.7015 188.833 11.7893 188.96 11.8497C189.086 11.9101 189.225 11.9417 189.366 11.942H252.924C253.065 11.9416 253.203 11.9101 253.33 11.8497C253.457 11.7892 253.568 11.7014 253.657 11.5927C253.793 11.4263 253.867 11.2177 253.866 11.0028V5.47634C253.866 5.2274 253.766 4.98878 253.59 4.81274C253.413 4.63671 253.174 4.53759 252.924 4.53711V4.53711Z" fill="#CACACA"/>
        <path d="M193.894 9.30433C194.633 9.30433 195.232 8.70716 195.232 7.97052C195.232 7.23388 194.633 6.63672 193.894 6.63672C193.155 6.63672 192.556 7.23388 192.556 7.97052C192.556 8.70716 193.155 9.30433 193.894 9.30433Z" fill="white"/>
        <path d="M198.972 9.30433C199.711 9.30433 200.31 8.70716 200.31 7.97052C200.31 7.23388 199.711 6.63672 198.972 6.63672C198.233 6.63672 197.634 7.23388 197.634 7.97052C197.634 8.70716 198.233 9.30433 198.972 9.30433Z" fill="white"/>
        <path d="M204.051 9.30433C204.789 9.30433 205.388 8.70716 205.388 7.97052C205.388 7.23388 204.789 6.63672 204.051 6.63672C203.312 6.63672 202.713 7.23388 202.713 7.97052C202.713 8.70716 203.312 9.30433 204.051 9.30433Z" fill="white"/>
        <path d="M247.95 6.18945H242.101C241.987 6.19021 241.877 6.23588 241.796 6.31655C241.715 6.39723 241.67 6.50642 241.669 6.6205C241.667 6.68545 241.683 6.7497 241.714 6.80653C241.749 6.8796 241.804 6.94139 241.872 6.98475C241.941 7.02811 242.02 7.05127 242.101 7.05155H247.95C248.031 7.05195 248.112 7.02912 248.181 6.98574C248.25 6.94235 248.306 6.8802 248.341 6.80653C248.369 6.74852 248.383 6.68483 248.382 6.6205C248.382 6.56388 248.371 6.50781 248.349 6.45549C248.327 6.40318 248.296 6.35564 248.255 6.31561C248.215 6.27558 248.168 6.24383 248.115 6.22219C248.063 6.20054 248.006 6.18942 247.95 6.18945V6.18945Z" fill="white"/>
        <path d="M247.95 7.80859H242.101C241.986 7.80859 241.876 7.85401 241.795 7.93484C241.714 8.01568 241.669 8.12532 241.669 8.23964C241.669 8.35396 241.714 8.4636 241.795 8.54444C241.876 8.62527 241.986 8.67068 242.101 8.67068H247.95C248.064 8.67068 248.174 8.62527 248.255 8.54444C248.336 8.4636 248.382 8.35396 248.382 8.23964C248.382 8.12532 248.336 8.01568 248.255 7.93484C248.174 7.85401 248.064 7.80859 247.95 7.80859V7.80859Z" fill="white"/>
        <path d="M247.95 9.42773H242.101C241.986 9.42773 241.876 9.47315 241.795 9.55399C241.714 9.63483 241.669 9.74446 241.669 9.85878C241.669 9.9731 241.714 10.0827 241.795 10.1636C241.876 10.2444 241.986 10.2898 242.101 10.2898H247.95C248.064 10.2898 248.174 10.2444 248.255 10.1636C248.336 10.0827 248.382 9.9731 248.382 9.85878C248.382 9.74446 248.336 9.63483 248.255 9.55399C248.174 9.47315 248.064 9.42773 247.95 9.42773V9.42773Z" fill="white"/>
        <path d="M227.469 24.0566H215.435C214.832 24.0569 214.253 24.296 213.826 24.7214C213.399 25.1468 213.16 25.7237 213.159 26.3253V36.7158C213.16 37.3172 213.401 37.8936 213.827 38.3189C214.254 38.7441 214.832 38.9835 215.435 38.9845H227.469C228.072 38.9827 228.649 38.7431 229.076 38.318C229.502 37.893 229.743 37.317 229.744 36.7158V26.3253C229.743 25.7239 229.503 25.1475 229.077 24.7222C228.65 24.297 228.072 24.0576 227.469 24.0566Z" fill="#134989"/>
        <path d="M242.42 45.7734H200.48C200.206 45.7734 199.943 45.8819 199.749 46.0751C199.555 46.2683 199.447 46.5302 199.447 46.8034C199.447 47.0766 199.555 47.3386 199.749 47.5317C199.943 47.7249 200.206 47.8334 200.48 47.8334H242.42C242.694 47.8334 242.957 47.7249 243.15 47.5317C243.344 47.3386 243.453 47.0766 243.453 46.8034C243.453 46.5302 243.344 46.2683 243.15 46.0751C242.957 45.8819 242.694 45.7734 242.42 45.7734V45.7734Z" fill="#E4E4E4"/>
        <path d="M216.409 50.5996H200.48C200.206 50.5996 199.943 50.7081 199.749 50.9013C199.555 51.0944 199.447 51.3564 199.447 51.6296C199.447 51.9028 199.555 52.1647 199.749 52.3579C199.943 52.5511 200.206 52.6596 200.48 52.6596H216.409C216.683 52.6596 216.946 52.5511 217.14 52.3579C217.333 52.1647 217.442 51.9028 217.442 51.6296C217.442 51.3564 217.333 51.0944 217.14 50.9013C216.946 50.7081 216.683 50.5996 216.409 50.5996V50.5996Z" fill="#E4E4E4"/>
        <path d="M227.469 68.9766H215.435C214.832 68.9768 214.253 69.2159 213.826 69.6413C213.399 70.0667 213.16 70.6436 213.159 71.2452V81.6357C213.16 82.2371 213.401 82.8136 213.827 83.2388C214.254 83.664 214.832 83.9034 215.435 83.9044H227.469C228.072 83.9026 228.649 83.663 229.076 83.238C229.502 82.8129 229.743 82.2369 229.744 81.6357V71.2452C229.743 70.6438 229.503 70.0674 229.077 69.6421C228.65 69.2169 228.072 68.9776 227.469 68.9766Z" fill="#134989"/>
        <path d="M242.42 90.6914H200.48C200.206 90.6914 199.943 90.7999 199.749 90.9931C199.555 91.1862 199.447 91.4482 199.447 91.7214C199.447 91.9945 199.555 92.2565 199.749 92.4497C199.943 92.6428 200.206 92.7514 200.48 92.7514H242.42C242.694 92.7514 242.957 92.6428 243.15 92.4497C243.344 92.2565 243.453 91.9945 243.453 91.7214C243.453 91.4482 243.344 91.1862 243.15 90.9931C242.957 90.7999 242.694 90.6914 242.42 90.6914V90.6914Z" fill="#E4E4E4"/>
        <path d="M216.409 95.5195H200.48C200.206 95.5195 199.943 95.6281 199.749 95.8212C199.555 96.0144 199.447 96.2763 199.447 96.5495C199.447 96.8227 199.555 97.0847 199.749 97.2778C199.943 97.471 200.206 97.5795 200.48 97.5795H216.409C216.683 97.5795 216.946 97.471 217.14 97.2778C217.333 97.0847 217.442 96.8227 217.442 96.5495C217.442 96.2763 217.333 96.0144 217.14 95.8212C216.946 95.6281 216.683 95.5195 216.409 95.5195V95.5195Z" fill="#E4E4E4"/>
        <path d="M227.469 113.896H215.435C214.832 113.897 214.253 114.136 213.826 114.561C213.399 114.987 213.16 115.564 213.159 116.165V126.556C213.16 127.157 213.401 127.733 213.827 128.159C214.254 128.584 214.832 128.823 215.435 128.824H227.469C228.072 128.823 228.649 128.583 229.076 128.158C229.502 127.733 229.743 127.157 229.744 126.556V116.165C229.743 115.564 229.503 114.987 229.077 114.562C228.65 114.137 228.072 113.897 227.469 113.896V113.896Z" fill="#134989"/>
        <path d="M242.42 135.611H200.48C200.206 135.611 199.943 135.72 199.749 135.913C199.555 136.106 199.447 136.368 199.447 136.641C199.447 136.914 199.555 137.176 199.749 137.37C199.943 137.563 200.206 137.671 200.48 137.671H242.42C242.694 137.671 242.957 137.563 243.15 137.37C243.344 137.176 243.453 136.914 243.453 136.641C243.453 136.368 243.344 136.106 243.15 135.913C242.957 135.72 242.694 135.611 242.42 135.611V135.611Z" fill="#E4E4E4"/>
        <path d="M216.409 140.439H200.48C200.206 140.439 199.943 140.548 199.749 140.741C199.555 140.934 199.447 141.196 199.447 141.469C199.447 141.743 199.555 142.005 199.749 142.198C199.943 142.391 200.206 142.499 200.48 142.499H216.409C216.683 142.499 216.946 142.391 217.14 142.198C217.333 142.005 217.442 141.743 217.442 141.469C217.442 141.196 217.333 140.934 217.14 140.741C216.946 140.548 216.683 140.439 216.409 140.439Z" fill="#E4E4E4"/>
        <path d="M170.578 1.39236C170.577 1.14342 170.478 0.904803 170.301 0.728767C170.125 0.552731 169.885 0.453614 169.636 0.453125H1.39759C1.14786 0.453606 0.908494 0.552708 0.731894 0.728739C0.555294 0.90477 0.455849 1.14339 0.455328 1.39236V6.91882C0.45453 7.14891 0.540697 7.37085 0.69667 7.54044C0.546441 11.2928 0.455328 16.2385 0.455328 21.6561V146.392C0.455328 158.089 0.878676 167.599 1.39759 167.599H169.636C170.155 167.599 170.578 158.089 170.578 146.392V21.6561C170.578 16.2385 170.487 11.2928 170.337 7.54044C170.493 7.37083 170.579 7.14888 170.578 6.91882V1.39236Z" fill="white"/>
        <path d="M169.636 168.054H1.39739C0.883091 168.054 0.572629 168.054 0.276955 161.403C0.0983353 157.385 0 152.054 0 146.392V21.6567C0 16.4354 0.0858363 11.416 0.241731 7.52294C0.54091 6.05799e-06 0.88164 0 1.39739 0H169.636C170.151 0 170.492 0.00133535 170.791 7.52294C170.947 11.4125 171.033 16.4319 171.033 21.6567V146.392C171.033 152.054 170.934 157.385 170.756 161.403C170.46 168.054 170.15 168.054 169.636 168.054ZM1.66306 167.146H169.37C169.742 165.724 170.122 157.736 170.122 146.392V21.6567C170.122 16.4436 170.037 11.4368 169.882 7.55926V7.55905C169.692 2.7933 169.471 1.34303 169.365 0.907466H1.66801C1.56217 1.34305 1.34083 2.7933 1.15121 7.55905C0.995816 11.4406 0.910257 16.4472 0.910257 21.6567V146.392C0.910257 157.736 1.29057 165.723 1.66306 167.146V167.146Z" fill="#E4E4E4"/>
        <path d="M169.636 0.453125H1.39759C1.14786 0.453606 0.908494 0.552708 0.731894 0.728739C0.555294 0.90477 0.455849 1.14339 0.455328 1.39236V6.91882C0.45453 7.14891 0.540697 7.37085 0.69667 7.54044C0.784126 7.64002 0.891887 7.71986 1.01275 7.77463C1.13362 7.8294 1.26481 7.85784 1.39757 7.85805H169.636C169.769 7.85784 169.9 7.8294 170.021 7.77463C170.141 7.71986 170.249 7.64002 170.337 7.54044C170.493 7.37083 170.579 7.14888 170.578 6.91882V1.39236C170.577 1.14342 170.478 0.904816 170.301 0.728781C170.125 0.552746 169.885 0.453624 169.636 0.453125V0.453125Z" fill="#CACACA"/>
        <path d="M5.9258 5.22034C6.6647 5.22034 7.2637 4.62318 7.2637 3.88654C7.2637 3.1499 6.6647 2.55273 5.9258 2.55273C5.18689 2.55273 4.58789 3.1499 4.58789 3.88654C4.58789 4.62318 5.18689 5.22034 5.9258 5.22034Z" fill="white"/>
        <path d="M11.0042 5.22034C11.7431 5.22034 12.3421 4.62318 12.3421 3.88654C12.3421 3.1499 11.7431 2.55273 11.0042 2.55273C10.2653 2.55273 9.66626 3.1499 9.66626 3.88654C9.66626 4.62318 10.2653 5.22034 11.0042 5.22034Z" fill="white"/>
        <path d="M16.0825 5.22034C16.8214 5.22034 17.4204 4.62318 17.4204 3.88654C17.4204 3.1499 16.8214 2.55273 16.0825 2.55273C15.3436 2.55273 14.7446 3.1499 14.7446 3.88654C14.7446 4.62318 15.3436 5.22034 16.0825 5.22034Z" fill="white"/>
        <path d="M164.661 2.10547H158.813C158.698 2.10547 158.588 2.15088 158.507 2.23172C158.426 2.31255 158.38 2.42219 158.38 2.53651C158.38 2.65083 158.426 2.76047 158.507 2.84131C158.588 2.92215 158.698 2.96757 158.813 2.96757H164.661C164.776 2.96757 164.886 2.92215 164.967 2.84131C165.048 2.76047 165.093 2.65083 165.093 2.53651C165.093 2.42219 165.048 2.31255 164.967 2.23172C164.886 2.15088 164.776 2.10547 164.661 2.10547Z" fill="white"/>
        <path d="M164.661 3.72461H158.813C158.698 3.72461 158.588 3.77002 158.507 3.85086C158.426 3.93169 158.38 4.04133 158.38 4.15565C158.38 4.26998 158.426 4.37961 158.507 4.46045C158.588 4.54129 158.698 4.5867 158.813 4.5867H164.661C164.776 4.5867 164.886 4.54129 164.967 4.46045C165.048 4.37961 165.093 4.26998 165.093 4.15565C165.093 4.04133 165.048 3.93169 164.967 3.85086C164.886 3.77002 164.776 3.72461 164.661 3.72461V3.72461Z" fill="white"/>
        <path d="M164.661 5.3457H158.813C158.698 5.3457 158.588 5.39112 158.507 5.47196C158.426 5.5528 158.38 5.66243 158.38 5.77675C158.38 5.89107 158.426 6.00071 158.507 6.08154C158.588 6.16238 158.698 6.2078 158.813 6.2078H164.661C164.776 6.2078 164.886 6.16238 164.967 6.08154C165.048 6.00071 165.093 5.89107 165.093 5.77675C165.093 5.66243 165.048 5.5528 164.967 5.47196C164.886 5.39112 164.776 5.3457 164.661 5.3457V5.3457Z" fill="white"/>
        <path d="M55.7763 28.1406H36.0873C35.1003 28.1418 34.1541 28.5333 33.4562 29.2291C32.7583 29.9248 32.3657 30.8682 32.3645 31.8522V48.8536C32.3657 49.8375 32.7583 50.7809 33.4562 51.4767C34.1541 52.1724 35.1003 52.5639 36.0873 52.5651H55.7763C56.7633 52.5639 57.7095 52.1724 58.4074 51.4767C59.1053 50.7809 59.4979 49.8375 59.4991 48.8536V31.8522C59.4979 30.8682 59.1053 29.9248 58.4074 29.2291C57.7095 28.5333 56.7633 28.1418 55.7763 28.1406V28.1406Z" fill="#134989"/>
        <path d="M136.985 34.7148H68.3605C67.912 34.7148 67.482 34.8924 67.1649 35.2086C66.8478 35.5247 66.6697 35.9534 66.6697 36.4005C66.6697 36.8475 66.8478 37.2763 67.1649 37.5924C67.482 37.9085 67.912 38.0861 68.3605 38.0861H136.985C137.433 38.0861 137.863 37.9085 138.18 37.5924C138.497 37.2763 138.675 36.8475 138.675 36.4005C138.675 35.9534 138.497 35.5247 138.18 35.2086C137.863 34.8924 137.433 34.7148 136.985 34.7148V34.7148Z" fill="#E4E4E4"/>
        <path d="M94.4257 42.6152H68.3605C67.912 42.6152 67.482 42.7928 67.1649 43.1089C66.8478 43.4251 66.6697 43.8538 66.6697 44.3009C66.6697 44.7479 66.8478 45.1766 67.1649 45.4928C67.482 45.8089 67.912 45.9865 68.3605 45.9865H94.4257C94.8741 45.9865 95.3042 45.8089 95.6213 45.4928C95.9383 45.1766 96.1165 44.7479 96.1165 44.3009C96.1165 43.8538 95.9383 43.4251 95.6213 43.1089C95.3042 42.7928 94.8741 42.6152 94.4257 42.6152V42.6152Z" fill="#E4E4E4"/>
        <path d="M55.7763 73.5137H36.0873C35.1003 73.5149 34.1541 73.9063 33.4562 74.6021C32.7583 75.2979 32.3657 76.2412 32.3645 77.2252V94.2266C32.3657 95.2106 32.7583 96.1539 33.4562 96.8497C34.1541 97.5455 35.1003 97.9369 36.0873 97.9381H55.7763C56.7633 97.9369 57.7095 97.5455 58.4074 96.8497C59.1053 96.1539 59.4979 95.2106 59.4991 94.2266V77.2252C59.4979 76.2412 59.1053 75.2979 58.4074 74.6021C57.7095 73.9063 56.7633 73.5149 55.7763 73.5137V73.5137Z" fill="#134989"/>
        <path d="M136.985 80.0879H68.3605C67.912 80.0879 67.482 80.2655 67.1649 80.5816C66.8478 80.8977 66.6697 81.3265 66.6697 81.7735C66.6697 82.2206 66.8478 82.6493 67.1649 82.9654C67.482 83.2815 67.912 83.4591 68.3605 83.4591H136.985C137.433 83.4591 137.863 83.2815 138.18 82.9654C138.497 82.6493 138.675 82.2206 138.675 81.7735C138.675 81.3265 138.497 80.8977 138.18 80.5816C137.863 80.2655 137.433 80.0879 136.985 80.0879V80.0879Z" fill="#E4E4E4"/>
        <path d="M94.4257 87.9883H68.3605C67.912 87.9883 67.482 88.1659 67.1649 88.482C66.8478 88.7981 66.6697 89.2268 66.6697 89.6739C66.6697 90.121 66.8478 90.5497 67.1649 90.8658C67.482 91.1819 67.912 91.3595 68.3605 91.3595H94.4257C94.8741 91.3595 95.3042 91.1819 95.6213 90.8658C95.9383 90.5497 96.1165 90.121 96.1165 89.6739C96.1165 89.2268 95.9383 88.7981 95.6213 88.482C95.3042 88.1659 94.8741 87.9883 94.4257 87.9883V87.9883Z" fill="#E4E4E4"/>
        <path d="M55.7763 118.887H36.0873C35.1003 118.888 34.1541 119.279 33.4562 119.975C32.7583 120.671 32.3657 121.614 32.3645 122.598V139.6C32.3657 140.584 32.7583 141.527 33.4562 142.223C34.1541 142.919 35.1003 143.31 36.0873 143.311H55.7763C56.7633 143.31 57.7095 142.919 58.4074 142.223C59.1053 141.527 59.4979 140.584 59.4991 139.6V122.598C59.4979 121.614 59.1053 120.671 58.4074 119.975C57.7095 119.279 56.7633 118.888 55.7763 118.887V118.887Z" fill="#134989"/>
        <path d="M136.985 125.461H68.3605C67.912 125.461 67.482 125.639 67.1649 125.955C66.8478 126.271 66.6697 126.699 66.6697 127.147C66.6697 127.594 66.8478 128.022 67.1649 128.338C67.482 128.655 67.912 128.832 68.3605 128.832H136.985C137.433 128.832 137.863 128.655 138.18 128.338C138.497 128.022 138.675 127.594 138.675 127.147C138.675 126.699 138.497 126.271 138.18 125.955C137.863 125.639 137.433 125.461 136.985 125.461V125.461Z" fill="#E4E4E4"/>
        <path d="M94.4257 133.361H68.3605C67.912 133.361 67.482 133.539 67.1649 133.855C66.8478 134.171 66.6697 134.6 66.6697 135.047C66.6697 135.494 66.8478 135.923 67.1649 136.239C67.482 136.555 67.912 136.733 68.3605 136.733H94.4257C94.8741 136.733 95.3042 136.555 95.6213 136.239C95.9383 135.923 96.1165 135.494 96.1165 135.047C96.1165 134.6 95.9383 134.171 95.6213 133.855C95.3042 133.539 94.8741 133.361 94.4257 133.361V133.361Z" fill="#E4E4E4"/>
        <path d="M199.675 197.546H86.9634C86.4437 197.546 86.021 189.583 86.021 179.794V75.3746C86.021 65.5858 86.4437 57.623 86.9634 57.623H199.675C200.195 57.623 200.618 65.5858 200.618 75.3746V179.794C200.618 189.583 200.195 197.546 199.675 197.546Z" fill="white"/>
        <path d="M199.675 198H86.9634C86.4866 198 86.1421 198 85.8429 192.365C85.6643 189.001 85.5659 184.537 85.5659 179.795V75.3753C85.5659 70.6335 85.6643 66.1691 85.8429 62.8048C86.1421 57.1699 86.4866 57.1699 86.9634 57.1699H199.675C200.152 57.1699 200.497 57.1699 200.796 62.8048C200.975 66.169 201.073 70.6335 201.073 75.3753V179.795C201.073 184.537 200.975 189.001 200.796 192.365C200.497 198 200.152 198 199.675 198ZM87.2153 197.093H199.424C199.75 195.917 200.163 189.654 200.163 179.795V75.3753C200.163 65.5159 199.75 59.2529 199.424 58.0774H87.2153C86.8886 59.2529 86.4762 65.5159 86.4762 75.3753V179.795C86.4762 189.654 86.8886 195.917 87.2153 197.093Z" fill="#E4E4E4"/>
        <path d="M199.675 65.0282H86.9634C86.7135 65.0279 86.474 64.9288 86.2973 64.7527C86.1206 64.5766 86.0213 64.3378 86.021 64.0887V58.5625C86.0213 58.3134 86.1206 58.0746 86.2973 57.8985C86.474 57.7224 86.7135 57.6233 86.9634 57.623H199.675C199.925 57.6233 200.165 57.7224 200.341 57.8985C200.518 58.0746 200.617 58.3134 200.618 58.5625V64.0887C200.617 64.3378 200.518 64.5766 200.341 64.7527C200.165 64.9288 199.925 65.0279 199.675 65.0282V65.0282Z" fill="#CACACA"/>
        <path d="M91.49 62.3903C92.2289 62.3903 92.8279 61.7931 92.8279 61.0565C92.8279 60.3198 92.2289 59.7227 91.49 59.7227C90.7511 59.7227 90.1521 60.3198 90.1521 61.0565C90.1521 61.7931 90.7511 62.3903 91.49 62.3903Z" fill="white"/>
        <path d="M96.5681 62.3903C97.307 62.3903 97.906 61.7931 97.906 61.0565C97.906 60.3198 97.307 59.7227 96.5681 59.7227C95.8292 59.7227 95.2302 60.3198 95.2302 61.0565C95.2302 61.7931 95.8292 62.3903 96.5681 62.3903Z" fill="white"/>
        <path d="M101.647 62.3903C102.386 62.3903 102.985 61.7931 102.985 61.0565C102.985 60.3198 102.386 59.7227 101.647 59.7227C100.908 59.7227 100.309 60.3198 100.309 61.0565C100.309 61.7931 100.908 62.3903 101.647 62.3903Z" fill="white"/>
        <path d="M194.7 60.1391H188.852C188.737 60.1391 188.627 60.0936 188.546 60.0126C188.465 59.9316 188.419 59.8218 188.419 59.7072C188.419 59.5927 188.465 59.4829 188.546 59.4019C188.627 59.3209 188.737 59.2754 188.852 59.2754H194.7C194.815 59.2758 194.924 59.3214 195.005 59.4024C195.086 59.4833 195.132 59.5929 195.132 59.7072C195.132 59.8215 195.086 59.9312 195.005 60.0121C194.924 60.093 194.815 60.1387 194.7 60.1391Z" fill="white"/>
        <path d="M194.7 61.7582H188.852C188.737 61.7582 188.627 61.7127 188.546 61.6317C188.465 61.5508 188.419 61.4409 188.419 61.3264C188.419 61.2118 188.465 61.102 188.546 61.021C188.627 60.94 188.737 60.8945 188.852 60.8945H194.7C194.815 60.8945 194.925 60.94 195.006 61.021C195.087 61.102 195.133 61.2118 195.133 61.3264C195.133 61.4409 195.087 61.5508 195.006 61.6317C194.925 61.7127 194.815 61.7582 194.7 61.7582V61.7582Z" fill="white"/>
        <path d="M194.7 63.3774H188.852C188.737 63.3774 188.627 63.3318 188.546 63.2509C188.465 63.1699 188.419 63.06 188.419 62.9455C188.419 62.831 188.465 62.7211 188.546 62.6401C188.627 62.5592 188.737 62.5137 188.852 62.5137H194.7C194.815 62.514 194.924 62.5597 195.005 62.6406C195.086 62.7216 195.132 62.8312 195.132 62.9455C195.132 63.0598 195.086 63.1694 195.005 63.2504C194.924 63.3313 194.815 63.377 194.7 63.3774V63.3774Z" fill="white"/>
        <path d="M121.599 99.3295H107.22C106.499 99.3287 105.808 99.0428 105.298 98.5346C104.788 98.0264 104.501 97.3373 104.5 96.6186V84.203C104.501 83.4843 104.788 82.7953 105.298 82.2871C105.808 81.7789 106.499 81.493 107.22 81.4922H121.599C122.32 81.493 123.011 81.7789 123.52 82.2871C124.03 82.7953 124.317 83.4843 124.318 84.203V96.6186C124.317 97.3373 124.03 98.0264 123.52 98.5346C123.011 99.0428 122.32 99.3287 121.599 99.3295Z" fill="#134989"/>
        <path d="M180.904 88.7583H130.789C130.462 88.7583 130.148 88.6287 129.917 88.3979C129.685 88.1671 129.555 87.854 129.555 87.5276C129.555 87.2012 129.685 86.8882 129.917 86.6573C130.148 86.4265 130.462 86.2969 130.789 86.2969H180.904C181.231 86.2969 181.545 86.4265 181.777 86.6573C182.008 86.8882 182.138 87.2012 182.138 87.5276C182.138 87.854 182.008 88.1671 181.777 88.3979C181.545 88.6287 181.231 88.7583 180.904 88.7583V88.7583Z" fill="#E4E4E4"/>
        <path d="M149.824 94.5259H130.789C130.462 94.5259 130.148 94.3963 129.917 94.1654C129.685 93.9346 129.555 93.6216 129.555 93.2952C129.555 92.9688 129.685 92.6557 129.917 92.4249C130.148 92.1941 130.462 92.0645 130.789 92.0645H149.824C149.986 92.0641 150.147 92.0958 150.297 92.1575C150.447 92.2192 150.583 92.3098 150.698 92.4241C150.813 92.5384 150.904 92.6742 150.966 92.8237C151.029 92.9731 151.061 93.1334 151.061 93.2952C151.061 93.457 151.029 93.6172 150.966 93.7667C150.904 93.9162 150.813 94.052 150.698 94.1663C150.583 94.2806 150.447 94.3712 150.297 94.4329C150.147 94.4946 149.986 94.5262 149.824 94.5259Z" fill="#E4E4E4"/>
        <path d="M121.599 136.081H107.22C106.499 136.081 105.808 135.795 105.298 135.287C104.788 134.778 104.501 134.089 104.5 133.371V120.955C104.501 120.236 104.788 119.547 105.298 119.039C105.808 118.531 106.499 118.245 107.22 118.244H121.599C122.32 118.245 123.011 118.531 123.52 119.039C124.03 119.547 124.317 120.236 124.318 120.955V133.371C124.317 134.089 124.03 134.778 123.52 135.287C123.011 135.795 122.32 136.081 121.599 136.081V136.081Z" fill="#134989"/>
        <path d="M180.904 125.51H130.789C130.462 125.51 130.148 125.381 129.917 125.15C129.685 124.919 129.555 124.606 129.555 124.28C129.555 123.953 129.685 123.64 129.917 123.409C130.148 123.178 130.462 123.049 130.789 123.049H180.904C181.231 123.049 181.545 123.178 181.777 123.409C182.008 123.64 182.138 123.953 182.138 124.28C182.138 124.606 182.008 124.919 181.777 125.15C181.545 125.381 181.231 125.51 180.904 125.51V125.51Z" fill="#E4E4E4"/>
        <path d="M149.824 131.278H130.789C130.462 131.278 130.148 131.148 129.917 130.917C129.685 130.687 129.555 130.374 129.555 130.047C129.555 129.721 129.685 129.408 129.917 129.177C130.148 128.946 130.462 128.816 130.789 128.816H149.824C149.986 128.816 150.147 128.848 150.297 128.909C150.447 128.971 150.583 129.062 150.698 129.176C150.813 129.29 150.904 129.426 150.966 129.576C151.029 129.725 151.061 129.885 151.061 130.047C151.061 130.209 151.029 130.369 150.966 130.519C150.904 130.668 150.813 130.804 150.698 130.918C150.583 131.033 150.447 131.123 150.297 131.185C150.147 131.247 149.986 131.278 149.824 131.278V131.278Z" fill="#E4E4E4"/>
        <path d="M121.599 172.835H107.22C106.499 172.834 105.808 172.549 105.298 172.04C104.788 171.532 104.501 170.843 104.5 170.124V157.709C104.501 156.99 104.788 156.301 105.298 155.793C105.808 155.285 106.499 154.999 107.22 154.998H121.599C122.32 154.999 123.011 155.285 123.52 155.793C124.03 156.301 124.317 156.99 124.318 157.709V170.124C124.317 170.843 124.03 171.532 123.52 172.04C123.011 172.549 122.32 172.834 121.599 172.835Z" fill="#134989"/>
        <path d="M180.904 162.262H130.789C130.462 162.262 130.148 162.133 129.917 161.902C129.685 161.671 129.555 161.358 129.555 161.032C129.555 160.705 129.685 160.392 129.917 160.161C130.148 159.93 130.462 159.801 130.789 159.801H180.904C181.231 159.801 181.545 159.93 181.777 160.161C182.008 160.392 182.138 160.705 182.138 161.032C182.138 161.358 182.008 161.671 181.777 161.902C181.545 162.133 181.231 162.262 180.904 162.262Z" fill="#E4E4E4"/>
        <path d="M149.824 168.032H130.789C130.462 168.032 130.148 167.902 129.917 167.671C129.685 167.44 129.555 167.127 129.555 166.801C129.555 166.475 129.685 166.162 129.917 165.931C130.148 165.7 130.462 165.57 130.789 165.57H149.824C149.986 165.57 150.147 165.602 150.297 165.663C150.447 165.725 150.583 165.816 150.698 165.93C150.813 166.044 150.904 166.18 150.966 166.33C151.029 166.479 151.061 166.639 151.061 166.801C151.061 166.963 151.029 167.123 150.966 167.273C150.904 167.422 150.813 167.558 150.698 167.672C150.583 167.786 150.447 167.877 150.297 167.939C150.147 168 149.986 168.032 149.824 168.032V168.032Z" fill="#E4E4E4"/>
        </svg>
        

    )
}
export const VectorSVG = (Props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 15L12.5 10L7.5 5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}