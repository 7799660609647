import React from "react";
import { IBranchTableProps } from "../../types/branch.type";
import { RootState } from '../../index';
import { connect } from "react-redux";
import Offcanvas from "react-bootstrap/Offcanvas";
import EditBranch from "./EditBranch";
import BranchCard from "./BranchCard";
import { bindActionCreators, Dispatch } from "redux";
import { deleteBranch, getBranchById, getAllBranches, getBranchByIdView, updateEditBranchDlgStatus } from "../../shared/Reducers";
import ViewBranch from "./ViewBranch";

class BranchTable extends React.Component<IBranchTableProps> {
    // constructor(props: IBranchTableProps) {
    //     super(props);
    // }

    handleDeleteBranch = async (id: string) => {
        await this.props.deleteBranch(id);
        // const query = `search=&sort=&order=&offset=0&limit=10&client=all`;
        // this.props.getAllBranches(query);
    }

    handleEditBranch = async (id: string) => {
        try {
            this.props.getBranchById(id);
        } catch (error) {
            console.log(error);
        }
    }

    handleViewBranch = async (id:string) => {
        try {
            this.props.getBranchById(id);
        } catch (error) {
            console.log(error);
        }
    }

    render(): React.ReactNode {
        return (
            <tbody>
                {
                    this.props.data.map((branch, index) =>
                        <BranchCard branch={branch}
                            key={index + branch.id}
                            handleDeleteBranch={this.handleDeleteBranch}
                            handleEditBranch={this.handleEditBranch}
                            handleViewBranch={this.handleViewBranch}
                            deleteBranchStatus={false} viewData={this.props.data} />)
                }
                <Offcanvas placement="end" show={this.props.editBranchStatus} onHide={() => this.props.updateEditBranchDlgStatus(false)}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title><span className="offc-title">Edit Branch</span></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <EditBranch id={this.props.id} data={this.props.selectedData} />
                    </Offcanvas.Body>
                </Offcanvas>
            </tbody>
        )
    }
}
const mapStateToProps = (state: RootState) => ({
    editBranchStatus: state.branch.editBranchStatus,
    selectedData: state.branch.selectedData,
    id: state.branch.id,

})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateEditBranchDlgStatus: bindActionCreators(updateEditBranchDlgStatus, dispatch),
        getBranchById: bindActionCreators(getBranchById, dispatch),
        getAllBranches: bindActionCreators(getAllBranches, dispatch),
        getBranchByIdView: bindActionCreators(getBranchByIdView, dispatch),
        deleteBranch: bindActionCreators(deleteBranch, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchTable);