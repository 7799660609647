import { Dispatch, AnyAction } from "redux";
import { AppDispatch } from "../..";
import { TAuditTable } from "../../types/user.type";
import {
  CertificateTemplateState,
  IEditCertificate,
  API_GET_ALL_CERTIFICAE,
  API_GET_ALL_CERTIFICATE_SUCCESS,
  API_GET_ALL_CERTIFICATE_FAIL,
  API_ADD_CERTIFICATE,
  API_ADD_CERTIFICATE_PROGRESS,
  API_ADD_CERTIFICATE_SUCCESS,
  API_ADD_CERTIFICATE_FAIL,
  API_DELETE_CERTIFICATE,
  API_DELETE_CERTIFICATE_SUCCESS,
  API_DELETE_CERTIFICATE_FAIL,
  API_GET_CERTIFICATE_BY_ID,
  API_GET_CERTIFICATE_BY_ID_SUCCESS,
  API_GET_CERTIFICATE_BY_ID_FAIL,
  UPDATE_EDIT_CERTIFICATE_DLG_STATUS,
  API_EDIT_CERTIFICATE,
  API_EDIT_CERTIFICATE_SUCCESS,
  API_EDIT_CERTIFICATE_FAIL,
  API_EDIT_CERTIFICATE_PROGRESS,
 
} from "../ActionTypes";

const initialState: CertificateTemplateState = {
  certificateList: [],
  totalCount: 0,
  editCertificateData: [],
  editCertificateStatus: false,
  id: '',
  addCertificateStatus:false,
  addCertificateSuccess:false,
  processTrigger: false,
  progressPercentage: 0,
  editCertificateSuccess: false,
  editAuditStatus: false,
  certificateFetchStatus: false,
  deleteCertificateSuccess: false,
  deleteCertificateStatus: false
};

export const CertificateReducer = (
  state: CertificateTemplateState = initialState,
  action: AnyAction
): CertificateTemplateState => {
  switch (action.type) {
  
    case API_GET_ALL_CERTIFICAE:
      return { ...state };
    case API_GET_ALL_CERTIFICATE_SUCCESS:
      let auditData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "certificates" in data && data.certificates) {
          auditData = data.certificates;
        }
      }
      let total_count = 0;
      if (action.payload) {
        const { data } = action.payload;
        if (data && "total_count" in data && data.total_count) {
          total_count = data.total_count;
        }
      }

      return {
        ...state,
        certificateList: auditData,
        totalCount: total_count
      };
    case API_GET_ALL_CERTIFICATE_FAIL:
      return { ...state };
    case API_ADD_CERTIFICATE:
        let values: boolean = false;
        if(action.payload.request.data){
        if (action.payload.request.data.FormData === undefined) {
          values = true;
        }
      }
        return { ...state, addCertificateSuccess: "", processTrigger: values };
    case API_ADD_CERTIFICATE_SUCCESS:
        let message = [];
        if (action.payload) {
          const { data } = action.payload;
          if (data && "message" in data && data.message) {
            message = data.message;
          }
        }
        return {
          ...state,
          addCertificateStatus: false,
          addCertificateSuccess: message,
          processTrigger: false,
          progressPercentage: 0,
        };
    case API_ADD_CERTIFICATE_FAIL:
        return { ...state };
    case API_ADD_CERTIFICATE_PROGRESS:
        return { ...state, progressPercentage: action.payload };
    case API_GET_CERTIFICATE_BY_ID:
        return { ...state };
    case API_GET_CERTIFICATE_BY_ID_SUCCESS:
        let editAuditData = [];
        if (action.payload) {
          const { data } = action.payload;
          if (data && "certificate" in data && data.certificate) {
            editAuditData = data.certificate;
          }
        }
  
        return {
          ...state,
          editCertificateData: editAuditData,
          editCertificateStatus: true,
          id: editAuditData.id
        };
    case API_GET_CERTIFICATE_BY_ID_FAIL:
        return { ...state };
    case UPDATE_EDIT_CERTIFICATE_DLG_STATUS:
          return { ...state, editCertificateStatus: action.payload };
    case API_EDIT_CERTIFICATE:
            let value: boolean = true;
            return { ...state, editCertificateSuccess: "", processTrigger: value };
    case API_EDIT_CERTIFICATE_SUCCESS:
            let messages = [];
            if (action.payload) {
              const { data } = action.payload;
              if (data && "message" in data && data.message) {
                messages = data.message;
              }
            }
            return {
              ...state,
              editCertificateStatus: false,
              certificateFetchStatus: true,
              editCertificateSuccess: messages,
              processTrigger: false,
              progressPercentage: 0,
            };
    case API_EDIT_CERTIFICATE_FAIL:
            let error = "";
            if (
              "error" in action &&
              action.error &&
              "response" in action.error &&
              action.error.response &&
              "data" in action.error.response &&
              action.error.response.data &&
              "message" in action.error.response.data
            ) {
              error = action.error.response.data.message;
            }
            return { ...state};
    case API_DELETE_CERTIFICATE:
              return { ...state, deleteCertificateSuccess: "" };
    case API_DELETE_CERTIFICATE_SUCCESS:
              let msg = [];
              if (action.payload) {
                const { data } = action.payload;
                if (data && "message" in data && data.message) {
                  msg = data.message;
                }
              }
              console.log("reducer delete", msg);
              
              return {
                ...state,
                deleteCertificateStatus: false,
                certificateFetchStatus: true,
                deleteCertificateSuccess: msg,
                // deleteAuditSuccess: msg,.
              };
    case API_DELETE_CERTIFICATE_FAIL:
              return { ...state };
    default:
      return state;
  }
};

// get all audits for table
export function getAllCertificate(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ALL_CERTIFICAE,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `certificates?${queryStr}` : `certificates?`,
        },
      },
    });
  };
}


export function addCertificate(payload: FormData) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_ADD_CERTIFICATE,
      payload: {
        request: {
          method: "post",
          url: `certificates`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_ADD_CERTIFICATE_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}


// delete certificate
export function deleteCertificate(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_DELETE_CERTIFICATE,
      payload: {
        request: {
          method: "delete",
          url: `certificates/${id}`,
        },
      },
    });
  };
}

// get certificate by id
export function getCertificateById(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_CERTIFICATE_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `certificates/${id}` : `audit`,
        },
        id,
      },
    });
  };
}

// update edit cerificate
export function updateEditCertificateDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_EDIT_CERTIFICATE_DLG_STATUS,
      payload: status,
    });
  };
}

// edit certificate
export function editCertificate(payload: IEditCertificate, id: number) {
  console.log("payload", payload);

  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_EDIT_CERTIFICATE,
      payload: {
        request: {
          method: "put",
          url: `certificates/${id}`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_EDIT_CERTIFICATE_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}
// Add Comment
// export function addAuditComments(payload: FormData) {
//   return async (dispatch: Dispatch) => {
//     dispatch({
//       type: API_ADD_INSPECTION_COMMENT,
//       payload: {
//         request: {
//           method: "post",
//           url: `audit/audit-comments`,
//           data: payload,
//         },
//       },
//     });
//   };
// }
