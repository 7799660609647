import * as Yup from "yup";

import {
  AttachmentDownloadSVG,
  CloseBtnSVG,
} from "../../assets/images/ImagesSVG";
import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { PaperClipSVG, TrashSVG } from "../../assets/images/ImagesV2";
import {
  deleteAttachementId,
  deleteCleanerComments,
  editCleanerComments,
  getTaskHistoryById,
} from "../../shared/Reducers";

import AttachmentPreview from "../../shared/Components/AttachmentPreview";
import ImageDrawing from "../../export/ImageDrawing";
import Button from "@material-ui/core/Button";
import Constants from "../../shared/Constants";
import { CustomSelect } from "../../shared/Components/CustomSelect";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { RootState } from "../../index";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import docImage from "../../assets/images/google-docs.png";
import moment from "moment";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";

export interface IDisplayCommentsProps {
  data: any;
  task_id: string;
  deleteCleanerComments?: any;
  getTaskHistoryById: any;
  task_type: any;
  deleteAttachementId: any;
  editCleanerComments: any;
  addEditTaskCommentsModalStatus: any;
  special_request_task_status: any;
  userLoginTaskstatus?: any;
}
export interface IDisplayCommentsState {
  editAttachmentUrls: any;
  editCommentStatus: boolean;
  AttachmentPreviewStatus: boolean;
  previewUrl: string;
  previewType: string;
  displayDelete: boolean;
  deleteCommentsId: number;
  editCommentData: any;
  deleteAttachementId: any;
  optionselect: any;
  file_count: any;
}

// let attachmentBindStatus:boolean = false;
class DisplayComments extends React.Component<
  IDisplayCommentsProps,
  IDisplayCommentsState
> {
  constructor(props: IDisplayCommentsProps) {
    super(props);
    this.state = {
      editAttachmentUrls: [],
      editCommentStatus: false,
      AttachmentPreviewStatus: false,
      previewUrl: "",
      previewType: "",
      displayDelete: false,
      deleteCommentsId: 0,
      editCommentData: [],
      deleteAttachementId: [],
      optionselect: [],
      file_count: "",
    };
  }
  componentDidMount(): void {
    this.setState({
      optionselect: this.props.userLoginTaskstatus.map((i: any) => ({
        value: i.id,
        label: i.name,
      })),
    });
  }

  componentDidUpdate(
    prevProps: Readonly<IDisplayCommentsProps>,
    prevState: Readonly<IDisplayCommentsState>,
    snapshot?: any
  ): void {
    if (
      this.props.addEditTaskCommentsModalStatus !==
      prevProps.addEditTaskCommentsModalStatus
    ) {
      this.setState({ editCommentStatus: false });
      this.props.getTaskHistoryById(this.props.task_id);
    }
  }

  handleDeleteModalStatus = (status: boolean) => {
    this.setState({ displayDelete: status });
  };

  deleteCleanerComments = () => {
    this.props.deleteCleanerComments(this.state.deleteCommentsId);

    var element = document.getElementById("spinner-border-delete");
    var button = document.getElementById("delete-button");
    if (element !== null && button !== null) {
      element.classList.remove("d-none");
      button.setAttribute("disabled", "");
    }

    setTimeout(() => {
      this.setState({ displayDelete: false, deleteCommentsId: 0 });
      this.props.getTaskHistoryById(this.props.task_id);
    }, 500);
  };

  downloadFile = (url: string, name = "image.png") => {
    this.downloadResource(url, name);
  };
  downloadResource = (url: any, filename: any) => {
    if (url) {
      if (!filename) filename = url.split("\\").pop().split("/").pop();
      fetch(url, {
        headers: new Headers({
          Origin: window.location.origin,
        }),
        mode: "cors",
      })
        .then((response) => response.blob())
        .then((blob) => {
          let blobUrl = window.URL.createObjectURL(blob);
          this.forceDownload(blobUrl, filename);
        })
        .catch((e) => console.error(e));
    }
  };

  // viewTaskStatus = (statusKey: number) => {
  //   switch (statusKey) {
  //     case Constants.TASK_RESOLVED:
  //       return "Resolved";
  //     case Constants.TASK_FOLLOWUP:
  //       return "Followup";
  //     case Constants.TASK_INPROGRESS:
  //       return "In Progress";
  //     case Constants.TASK_COMPLETED:
  //       return "Completed";
  //     case Constants.TASK_COMMENTED:
  //       return "Commented";
  //     case Constants.TASK_INCOMPLETED:
  //       return "In Completed";
  //     default:
  //       return "In Progress";
  //   }
  // };
  // viewTaskStatusClassName = (statusKey: number) => {
  //   switch (statusKey) {
  //     case Constants.TASK_RESOLVED:
  //       return "satisfied-badge view-task-comment-badge";
  //     case Constants.TASK_FOLLOWUP:
  //       return "unsatisfied-badge view-task-comment-badge";
  //     case Constants.TASK_INPROGRESS:
  //       return "inprogress-badge view-task-comment-badge";
  //     case Constants.TASK_COMPLETED:
  //       return "completed-badge view-task-comment-badge";
  //     case Constants.TASK_COMMENTED:
  //       return "reopen-badge view-task-comment-badge";
  //     case Constants.TASK_INCOMPLETED:
  //       return "notcomplete-badge view-task-comment-badge";
  //     default:
  //       return "reopen-badge view-task-comment-badge";
  //   }
  // };

  forceDownload = (blob: any, filename: String) => {
    const a: any = document.createElement("a");
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
  validationSchema = () => {
    return Yup.object().shape({
      comments: Yup.string().required("Please enter comments"),
      task_status: Yup.string().required("Please select task status"),
      task_list: Yup.string(),
      attachments: Yup.mixed(),
    });
  };

  handleAddComment = async (formValues: any) => {
    console.log("attachment", this.state.editAttachmentUrls);

    let data = new FormData();
    for (let index = 0; index < this.state.editAttachmentUrls.length; index++) {
      if (this.state.editAttachmentUrls[index].id === "0") {
        data.append("attachments", this.state.editAttachmentUrls[index].file);
      }
    }
    data.append("comments", formValues.comments);
    data.append("comments_task_status", formValues.task_status);
    data.append("reference_id", this.props.task_id);
    data.append("task_type", this.props.task_type);
    data.append("reference_type", "task");

    await this.state.deleteAttachementId.map(async (id: any) => {
      this.props.deleteAttachementId(id);
    });
    await this.props.editCleanerComments(data, this.state.editCommentData.id);
    this.setState({ editAttachmentUrls: [] });

    var element = document.getElementById("spinner-border1");
    var button = document.getElementById("create-button1");
    if (element !== null && button !== null) {
      element.classList.remove("d-none");
      button.setAttribute("disabled", "");
    }
  };
  handleEditImageChange = async (e: any) => {
    let files = [];
    let urls = [];
    files = [...e.target.files];

    let fileurls: any = [];
    await files.map(async (file: any) => {
      fileurls.push({
        url: URL.createObjectURL(file),
        file: file,
        id: "0",
        file_type: file.type,
        download_url: URL.createObjectURL(file),
      });
    });
    urls = [...fileurls];
    this.setState({
      editAttachmentUrls: [...this.state.editAttachmentUrls, ...urls],
    });
  };

  handleDeleteImage = (index: number) => {
    const urls = this.state.editAttachmentUrls;

    confirmAlert({
      title: "Confirm deletion",
      message: "Are you sure you want to delete this Attchment ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            // check exist file
            if (urls[index].id !== undefined && urls[index].id !== "0") {
              let deleteAttachementId = this.state.deleteAttachementId;
              deleteAttachementId.push(urls[index].id);
              this.setState({ deleteAttachementId: deleteAttachementId });

              urls.splice(index, 1);
              this.setState({ editAttachmentUrls: urls });
            } else {
              urls.splice(index, 1);
              this.setState({ editAttachmentUrls: urls });
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  handlePreviewClose = () => {
    this.setState({
      AttachmentPreviewStatus: false,
    });
  };
  handlePreviewImageClose = (bool: any, urlArray: any, attachFiles: any) => {
    this.setState({
      AttachmentPreviewStatus: false,
      editAttachmentUrls: urlArray,
    });
  };
  handlePreviewOpen = (file_type: any, url: any, inx: any, obj: any) => {
    console.log(obj, "file_url");

    if (
      file_type === "image/png" ||
      file_type === "image/jpeg" ||
      file_type === "image/jpg" ||
      file_type === "video/mp4" ||
      file_type === "video/webm" ||
      file_type === "application/pdf"
    ) {
      this.setState({
        AttachmentPreviewStatus: true,
        previewUrl: url,
        previewType: file_type,
        file_count: inx,
      });
    }
  };

  handleEditComments = (rowData: any) => {
    this.setState({ editCommentStatus: true });
    this.setState({ editCommentData: rowData });
    this.setState({
      editAttachmentUrls: rowData.attachment.map((obj: any) => ({
        url: `${Constants.imgThumbUrl}${obj.file_name}`,
        name: obj.file_name,
        id: obj.id,
        type: "download",
        download_url: `${Constants.imgUrl}${obj.file_name}`,
        file_type: obj.type,
      })),
    });
  };

  render(): React.ReactNode {
    // let taskStatusOption: any = [];
    // //  Special request supervisor Activity options
    // if (
    //   this.props.task_type === Constants.SPECIAL_REQUEST &&
    //   (Constants.LOGIN_USER_TYPE === Constants.SUPERVISORUTINT ||
    //     Constants.LOGIN_USER_TYPE === Constants.SUPERVISOR_MANAGERUTINT)
    // ) {
    //   taskStatusOption = [
    //     { value: Constants.TASK_FOLLOWUP, label: "Followup" },
    //     { value: Constants.TASK_RESOLVED, label: "Resolved" },
    //   ];
    // }
    // // special request QA and admin or BM activity options
    // else if (
    //   this.props.task_type === Constants.SPECIAL_REQUEST &&
    //   (Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
    //     Constants.OPERATION_MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
    //     Constants.QAUTINT === Constants.LOGIN_USER_TYPE ||
    //     Constants.QA_MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
    //     Constants.ADMINUTINT === Constants.LOGIN_USER_TYPE) &&
    //   this.props.special_request_task_status === Constants.TASK_RESOLVED
    // ) {
    //   taskStatusOption = [
    //     { value: Constants.TASK_COMPLETED, label: "Completed" },
    //     { value: Constants.TASK_INCOMPLETED, label: "InCompleted" },
    //   ];
    // }
    // // regular request admin supervisor activity options
    // else if (
    //   this.props.task_type === Constants.REGULAR_TASK &&
    //   (Constants.SUPERVISORUTINT === Constants.LOGIN_USER_TYPE ||
    //     Constants.ADMINUTINT === Constants.LOGIN_USER_TYPE)
    // ) {
    //   taskStatusOption = [
    //     { value: Constants.TASK_INPROGRESS, label: "In progress" },
    //     { value: Constants.TASK_COMMENTED, label: "Commented" },
    //   ];
    // }
    return (
      <div>
        {this.props.data &&
          this.props.data.map((row: any, index: number) => {
            // let viewTaskStatus = "";
            // switch (row.task_status) {
            //   case Constants.TASK_RESOLVED:
            //     viewTaskStatus = "Resolved";
            //     break;
            //   case Constants.TASK_FOLLOWUP:
            //     viewTaskStatus = "Followup";
            //     break;
            //   case Constants.TASK_INPROGRESS:
            //     viewTaskStatus = "In Progress";
            //     break;
            //   case Constants.TASK_COMPLETED:
            //     viewTaskStatus = "Completed";
            //     break;
            //   case Constants.TASK_COMMENTED:
            //     viewTaskStatus = "Commented";
            //     break;
            //   case Constants.TASK_INCOMPLETED:
            //     viewTaskStatus = "In Completed";
            //     break;
            //   default:
            //     viewTaskStatus = "In Progress";
            //     break;
            // }
            // let viewTaskStatusClassName = "";
            // switch (row.task_status) {
            //   case Constants.TASK_RESOLVED:
            //     viewTaskStatusClassName =
            //       "statisfied-badge view-task-comment-badge";
            //     break;
            //   case Constants.TASK_FOLLOWUP:
            //     viewTaskStatusClassName =
            //       "unstatisfied-badge view-task-comment-badge";
            //     break;
            //   case Constants.TASK_INPROGRESS:
            //     viewTaskStatusClassName =
            //       "inprogress-badge view-task-comment-badge";
            //     break;
            //   case Constants.TASK_COMPLETED:
            //     viewTaskStatusClassName =
            //       "completed-badge view-task-comment-badge";
            //     break;
            //   case Constants.TASK_COMMENTED:
            //     viewTaskStatusClassName =
            //       "reopen-badge view-task-comment-badge";
            //     break;
            //   case Constants.TASK_INCOMPLETED:
            //     viewTaskStatusClassName =
            //       "notcomplete-badge view-task-comment-badge";
            //     break;
            //   default:
            //     viewTaskStatusClassName =
            //       "reopen-badge view-task-comment-badge";
            //     break;
            // }

            // console.log("row.task_status", row.task_status);

            return (
              <div className="mt-4">
                <div
                  id={"comments-details-" + index}
                  className="comments-details"
                >
                  <p>
                    <span className="fw-600">
                      {row.first_name} {row.last_name}
                    </span>{" "}
                    <span className="text-secondary font-weight-light">
                      {moment(`${row.created_at}`).format("MMMM DD,YYYY")} at{" "}
                      {moment(`${row.created_at}`).format("hh:mm A")}
                    </span>
                  </p>
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className="d-flex row">
                        {row.attachment &&
                          row.attachment.map((obj: any, index: number) => (
                            <div className="img-card mr-16px mb-3">
                              <span className="download">
                                <a
                                  href="javascript:void(0)"
                                  onClick={() =>
                                    this.downloadFile(
                                      Constants.imgUrl + obj.file_name,
                                      obj.file_name
                                    )
                                  }
                                >
                                  <AttachmentDownloadSVG />
                                </a>
                              </span>
                              <div
                                className="img-preview"
                                onClick={() =>
                                  this.handlePreviewOpen(
                                    obj.type,
                                    obj.s3_url,
                                    index,
                                    obj
                                  )
                                }
                              >
                                <img
                                  className="img-thumb-perview"
                                  src={
                                    obj.type === "application/pdf"
                                      ? pdfImage
                                      : obj.type === "video/mp4" ||
                                        obj.type === "video/webm"
                                      ? videoImage
                                      : obj.type === "image/png" ||
                                        obj.type === "image/jpeg" ||
                                        obj.type === "image/jpg"
                                      ? obj.s3_url
                                      : docImage
                                  }
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <p>{row.comments}</p>
                  <p
                    className={
                      row.task_status + "-badge view-task-comment-badge"
                    }
                  >
                    {row.task_status}
                  </p>
                  {(Constants.LOGIN_USER_ID === row.user_id ||
                    Constants.LOGIN_USER_ID === Constants.ADMIN_UT) && (
                    <p>
                      <span>
                        <a
                          onClick={() => {
                            this.handleEditComments(row);
                          }}
                          className="terms cursor-pointer"
                        >
                          Edit{" "}
                        </a>{" "}
                        .{" "}
                        <a
                          href="javascript:void(0)"
                          className="terms cursor-pointer"
                          onClick={() => {
                            this.handleDeleteModalStatus(true);
                            this.setState({ deleteCommentsId: row.id });
                          }}
                        >
                          Delete
                        </a>
                      </span>
                    </p>
                  )}
                  <hr></hr>
                </div>
              </div>
            );
          })}

        <AttachmentPreview
          displayPreview={this.state.AttachmentPreviewStatus}
          previewUrl={this.state.previewUrl}
          previewType={this.state.previewType}
          handlePreviewClose={this.handlePreviewClose}
        />
        <Modal
          className="edit-comments"
          size="lg"
          dialogClassName="modal-90w"
          aria-labelledby="contained-modal-title-vcenter"
          show={this.state.editCommentStatus}
          onHide={() => this.setState({ editCommentStatus: false })}
        >
          <Modal.Header>
            <h3 className="mb-0">Edit Comments</h3>
            <a
              href="javascript:void(0)"
              className="qr-modal-close"
              onClick={() => this.setState({ editCommentStatus: false })}
            >
              <CloseBtnSVG />
            </a>
          </Modal.Header>
          <Modal.Body className="task-view">
            <div className="mt-2">
              <Formik
                validationSchema={this.validationSchema}
                onSubmit={this.handleAddComment}
                initialValues={{
                  comments: this.state.editCommentData
                    ? this.state.editCommentData.comments
                    : "",
                  task_status: this.state.editCommentData
                    ? this.state.editCommentData.comments_task_status
                    : "",
                  task_list: "",
                  attachments: "",
                }}
              >
                <Form id="addCommentsFormReset">
                  <div className="row">
                    <div className="col-md-9">
                      <Field
                        as="textarea"
                        className="form-control comment-field w-100"
                        name="comments"
                        placeholder="Add a comment..."
                      />
                      <ErrorMessage
                        name={"comments"}
                        component={"div"}
                        className="cus-alert"
                      />
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <Field
                          className="custom-select dropdown-field"
                          name="task_status"
                          options={this.state.optionselect}
                          // options={[
                          //   {
                          //     value: Constants.TASK_INPROGRESS,
                          //     label: "In progress",
                          //   },
                          //   {
                          //     value: Constants.TASK_COMMENTED,
                          //     label: "Commented",
                          //   },
                          // ]}
                          component={CustomSelect}
                          placeholder="Select Status"
                          isMulti={false}
                        />
                      </div>
                      <div className="attachment-btn">
                        <input
                          type="file"
                          multiple
                          accept=".pdf,.PDF,.mp4,.MP4,.webm,.WEBM,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.doc,.DOC,.docx.DOCX"
                          style={{ display: "none" }}
                          onChange={this.handleEditImageChange}
                          id="edit-contained-button-file"
                        />
                        <label
                          htmlFor="edit-contained-button-file"
                          style={{ width: "100%" }}
                        >
                          <Button
                            variant="contained"
                            component="span"
                            style={{ width: "100%", justifyContent: "left" }}
                          >
                            <PaperClipSVG /> Attach
                          </Button>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <ErrorMessage
                        name={"task_status"}
                        component={"div"}
                        className="cus-alert"
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    {this.state.editAttachmentUrls.length > 0 ? (
                      <div className="mb-2">
                        <label className="form-label m-0">Attachments</label>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="row">
                    <div className=" mb-3 d-flex row">
                      {this.state.editAttachmentUrls &&
                        this.state.editAttachmentUrls.map(
                          (obj: any, index: number) => (
                            <div className="img-card mr-16px mb-3">
                              <span className="delete">
                                <a
                                  onClick={() => this.handleDeleteImage(index)}
                                >
                                  <TrashSVG />
                                </a>
                              </span>
                              {obj.type === "download" && (
                                <span className="download">
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() =>
                                      this.downloadFile(
                                        obj.download_url,
                                        obj.file_name
                                      )
                                    }
                                  >
                                    <AttachmentDownloadSVG />
                                  </a>
                                  {/* <span className="download"><a href={url} download><AttachmentDownloadSVG /></a></span> */}
                                </span>
                              )}

                              <div
                                className="img-preview"
                                onClick={() =>
                                  this.handlePreviewOpen(
                                    obj.file_type,
                                    obj.url,
                                    index,
                                    obj
                                  )
                                }
                              >
                                <img
                                  className="img-thumb-perview"
                                  src={
                                    obj.file_type === "application/pdf"
                                      ? pdfImage
                                      : obj.file_type === "video/mp4" ||
                                        obj.file_type === "video/webm"
                                      ? videoImage
                                      : obj.file_type === "image/png" ||
                                        obj.file_type === "image/jpeg" ||
                                        obj.file_type === "image/jpg"
                                      ? obj.url
                                      : docImage
                                  }
                                  alt="attach"
                                />
                              </div>
                            </div>
                          )
                        )}
                      {/* <AttachmentPreview
                        displayPreview={this.state.AttachmentPreviewStatus}
                        previewUrl={this.state.previewUrl}
                        previewType={this.state.previewType}
                        handlePreviewClose={this.handlePreviewClose}
                      /> */}
                      <ImageDrawing
                        displayPreview={this.state.AttachmentPreviewStatus}
                        previewUrl={this.state.previewUrl}
                        previewType={this.state.previewType}
                        handlePreviewClose={this.handlePreviewImageClose}
                        clickingImage={this.state.file_count}
                        attachUrlArray={this.state.editAttachmentUrls}
                        attachFiles={this.state.editAttachmentUrls}
                        page="edit"
                        deleteProps={this.props.deleteAttachementId}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <button
                        type="submit"
                        id="create-button1"
                        className="btn btn-primary create-button"
                      >
                        Save{" "}
                        <span
                          id="spinner-border1"
                          className="spinner-border spinner-border-sm d-none"
                        ></span>
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          className="delete-modal"
          show={this.state.displayDelete}
          onHide={() => this.handleDeleteModalStatus(false)}
        >
          <Modal.Body>
            <a
              href="javascript:void(0)"
              className="qr-modal-close"
              onClick={() => this.handleDeleteModalStatus(false)}
            >
              <CloseBtnSVG />
            </a>

            <div className="delete-modal-body">
              <h3 className="mb-3">Delete this comments</h3>
              <p>Do you want to delete the comment</p>
            </div>

            <div className="delete-footer qr-modal-footer d-flex justify-content-end pt-2">
              <Button
                className="btn btn-outline-success delete-cancel"
                onClick={() => this.handleDeleteModalStatus(false)}
              >
                <span className="delete-audit-cancel-btn">Cancel</span>
              </Button>
              <Button
                className="delete-cancel delete-button"
                id="delete-button"
                onClick={() => this.deleteCleanerComments()}
              >
                Delete{" "}
                <span
                  id="spinner-border-delete"
                  className="spinner-border spinner-border-sm d-none"
                ></span>
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  addEditTaskCommentsModalStatus:
    state.cleanerhome.addEditTaskCommentsModalStatus,
  userLoginTaskstatus: state.sidebar.userLoginTaskstatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    deleteCleanerComments: bindActionCreators(deleteCleanerComments, dispatch),
    getTaskHistoryById: bindActionCreators(getTaskHistoryById, dispatch),
    editCleanerComments: bindActionCreators(editCleanerComments, dispatch),
    deleteAttachementId: bindActionCreators(deleteAttachementId, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DisplayComments);
