import * as Yup from "yup";

import Constants, { TASK_PRIORITY } from "../../shared/Constants";
import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { IBranchSelect, ICleaner } from "../../shared/ActionTypes";
import { PaperClipSVG, TrashSVG } from "../../assets/images/ImagesV2";
import {
  addDailyTask,
  listAllBranch,
  listAllCleaner,
  listAllClient,
  getSendmailUsers,
  sendQAEmailDailyTask,
} from "../../shared/Reducers";

import AttachmentPreview from "../../shared/Components/AttachmentPreview";
import ImageDrawing from "../../export/ImageDrawing";
import Button from "@material-ui/core/Button";
import { CustomSelect } from "../../shared/Components/CustomSelect";
import DatePicker from "./DatePicker";
import { Modal } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import React from "react";
import { RootState } from "../../index";
import TimePicker from "./TimePicker";
import { connect } from "react-redux";
import docImage from "../../assets/images/google-docs.png";
import moment from "moment";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";
import { log } from "console";
type Props = {
  onClose: (status: boolean) => void;
  clientList?: any;
  listAllClient?: any;
  addDailyTaskError?: any;
  addDailyTask?: any;
  branchList?: any;
  listAllBranch?: any;
  cleanerList?: any;
  listAllCleaner?: any;
  addSpecialRequestTaskStatus?: boolean;
  progressPercentage: number;
  processTrigger: any;
  addDailyTaskSuccess: any;
  userLoginDetails?: any;
  getSendmailUsers: any;
  currentTask_createdTaskid: any;
  sendMailUsers: any;
  sendQAEmailDailyTask: any;
};

type TAddDailyTaskState = {
  attachmentFiles: any | Blob[];
  attachmentUrls: any[];
  task_date: string;
  start_time: string;
  end_time: string;
  AttachmentPreviewStatus: boolean;
  previewUrl: string;
  previewType: string;
  files_size: any;
  file_count: any;
  selectedBranch: any;
  signatureModal: boolean;
  isDrawing: boolean;
  context: CanvasRenderingContext2D | null;
  signatureImageError: string;
  signLastX: number;
  signLastY: number;
  signatureImage: any;
  sendtoMailUsers: any;
};

class AddDailyTask extends React.Component<Props, TAddDailyTaskState> {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  constructor(props: Props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      attachmentFiles: [],
      attachmentUrls: [],
      task_date: moment(new Date()).format("YYYY-MM-DD"),
      start_time: moment(new Date()).format("HH:mm"),
      end_time: moment(new Date()).format("HH:mm"),
      AttachmentPreviewStatus: false,
      previewUrl: "",
      previewType: "",
      files_size: 0,
      file_count: "",
      selectedBranch: { value: "all", label: "All" },
      signatureModal: false,
      signatureImageError: "",
      context: null,
      signLastX: 0,
      signLastY: 0,
      signatureImage: "",
      isDrawing: false,
      sendtoMailUsers: [],
    };
  }

  componentDidMount(): void {
    if (
      Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
      Constants.QA_MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
      Constants.SUPERVISOR_MANAGERUTINT === Constants.LOGIN_USER_TYPE
    ) {
      this.props.listAllBranch(0);
    } else {
      this.listData();
    }
    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.add("d-none");
      button.removeAttribute("disabled");
    }
    this.setState({ sendtoMailUsers: [] });
    // date picker readonly
    let datePicker: NodeListOf<Element> = document.querySelectorAll(
      ".MuiFormControl-root input"
    ) as NodeListOf<Element>;
    if (datePicker.length > 0) {
      for (let index = 0; index < datePicker.length; index++) {
        const element = datePicker[index];
        datePicker[index].setAttribute("readonly", "true");
      }
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<TAddDailyTaskState>,
    snapshot?: any
  ): void {
    if (prevProps.processTrigger !== this.props.processTrigger) {
    }
    if (prevProps.addDailyTaskSuccess !== this.props.addDailyTaskSuccess) {
    }
  }

  componentWillUnmount(): void {
    this.setState({
      attachmentFiles: [],
      attachmentUrls: [],
      task_date: moment(new Date()).format("YYYY-MM-DD"),
      start_time: moment(new Date()).format("HH:mm"),
      end_time: moment(new Date()).format("HH:mm"),
      AttachmentPreviewStatus: false,
      previewUrl: "",
      previewType: "",
    });
  }

  listData = () => {
    this.props.listAllClient();
    this.props.listAllCleaner();
  };

  handleTaskDate = (val: string) => {
    this.setState({ task_date: val });
  };

  handleStartTime = (val: string) => {
    this.setState({ start_time: val });
  };

  handleEndTime = (val: string) => {
    this.setState({ end_time: val });
  };

  handleAddDailyTask = async (formValues: any) => {
    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.remove("d-none");
      button.setAttribute("disabled", "");
    }

    if (this.props.addSpecialRequestTaskStatus === true) {
      console.log("SpecialRequestTask", formValues);
      let data = new FormData();
      data.append("title", formValues.title);
      data.append("status", formValues.is_active);
      for (let index = 0; index < this.state.attachmentFiles.length; index++) {
        data.append("attachments", this.state.attachmentFiles[index]);
      }
      data.append("sp_task_signature", this.state.signatureImage);
      data.append("client", formValues.client);
      data.append("branch", formValues.branch);
      // data.append('cleaners', JSON.stringify([]));
      data.append("task_type", Constants.SPECIAL_REQUEST_STR);
      data.append("description", formValues.description);
      data.append("task_date", this.state.task_date);
      if (formValues.any_time === "1") {
        data.append("any_time", formValues.any_time);
      } else {
        data.append("any_time", "0");
        data.append("start_time", this.state.start_time);
        data.append("end_time", this.state.end_time);
      }
      data.append("ref_task_id", "1");
      data.append("additional_task_status", "1");
      data.append("recommended_task_status", "1");
      data.append("priority", formValues.priority);
      await this.props.addDailyTask(data);
      setTimeout(() => {
        this.props.getSendmailUsers(JSON.stringify(formValues.branch));
        let data = [...this.props.sendMailUsers];
        const emailTags: any = [];
        data.map((item) => {
          emailTags.push(item.email);
        });
        let taskSendToMail = {
          receipents: emailTags,
          task_list: this.props.currentTask_createdTaskid,
        };
        this.props.sendQAEmailDailyTask(taskSendToMail);
        this.props.onClose(false);
      }, 1000);
    } else {
      let data = new FormData();
      data.append("client", formValues.client);
      data.append("branch", formValues.branch);
      data.append("title", formValues.title);
      data.append("status", formValues.is_active);
      for (let index = 0; index < this.state.attachmentFiles.length; index++) {
        data.append("attachments", this.state.attachmentFiles[index]);
      }
      data.append("cleaners", JSON.stringify(formValues.cleaners));
      data.append("task_type", formValues.task_type);
      data.append("description", formValues.description);
      data.append("task_date", this.state.task_date);
      if (formValues.any_time === "1") {
        data.append("any_time", formValues.any_time);
      } else {
        data.append("any_time", "0");
        // data.append('start_time', formValues.start_time);
        // data.append('end_time', formValues.end_time);
        data.append("start_time", this.state.start_time);
        data.append("end_time", this.state.end_time);
      }
      data.append("ref_task_id", "1");
      data.append("task_with_sign_in", formValues.task_with_sign_in);
      data.append("priority", formValues.priority);
      data.append("additional_task_status", "1");
      data.append("recommended_task_status", "1");
      await this.props.addDailyTask(data);
      setTimeout(() => {
        console.log("dvnsjnvjnv", formValues.task_type);
        if (formValues.task_type === '1') {
          this.props.getSendmailUsers(JSON.stringify(formValues.branch));
          let data = [...this.props.sendMailUsers];
          const emailTags: any = [];
          data.map((item) => {
            emailTags.push(item.email);
          });
          let taskSendToMail = {
            receipents: emailTags,
            task_list: this.props.currentTask_createdTaskid,
          };
          this.props.sendQAEmailDailyTask(taskSendToMail);
        }
        this.props.onClose(false);
      }, 1000);
    }
  };

  handleImageChange = async (e: any) => {
    const file = e.target.files[0];
    this.setState(
      { files_size: this.state.files_size + file.size },
      async () => {
        if (this.state.files_size > 524288000) {
          alert("File Size Must Be Less Than 500MB");
          e.target.value = null;
          return this.setState({
            files_size: this.state.files_size - file.size,
          });
        }

        let files = [];
        let urls = [];
        files = [...e.target.files];

        let fileurls: any = [];
        await files.map(async (file: any) => {
          fileurls.push({
            url: URL.createObjectURL(file),
            file_type: file.type,
          });
        });
        urls = [...fileurls];

        this.setState({
          attachmentFiles: [...this.state.attachmentFiles, ...files],
        });
        this.setState(
          { attachmentUrls: [...this.state.attachmentUrls, ...urls] },
          () => {
            e.target.value = "";
          }
        );
      }
    );
  };

  handleDeleteImage = (index: number) => {
    const files = this.state.attachmentFiles;
    const urls = this.state.attachmentUrls;
    files.splice(index, 1);
    urls.splice(index, 1);

    this.setState({ attachmentFiles: files });
    this.setState({ attachmentUrls: urls });
  };

  validationSchema = () => {
    if (this.props.addSpecialRequestTaskStatus === true) {
      return Yup.object().shape({
        title: Yup.string().required("Enter Task Name"),
        description: Yup.string().required("Enter Task Description"),
        is_active: Yup.string(),
        attachment: Yup.mixed(),
        any_time: Yup.string(),
        custom_time: Yup.string(),
        start_time: Yup.string(),
        end_time: Yup.string(),
        client: Yup.string().required("Client is required"),
        branch: Yup.string().required("Branch is required"),
      });
    } else {
      return Yup.object().shape({
        title: Yup.string().required("Enter Task Name"),
        description: Yup.string().required("Enter Task Description"),
        client: Yup.string().required("Client is required"),
        branch: Yup.string().required("Branch is required"),
        cleaners: Yup.array().min(1, "Select atleast one cleaner"),
        is_active: Yup.string(),
        attachment: Yup.mixed(),
        // task_date: Yup.string().required("Select Task Date"),
      });
    }
  };

  handleStartDateClick = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let startDate: HTMLElement = document.querySelector(
        ".startdate button.MuiButtonBase-root"
      ) as HTMLElement;
      startDate.click();
    }
  };

  handleChange = (e: any) => {
    if (e.target.name === "client") {
      this.setState({ selectedBranch: { value: "all", label: "All" } });
      this.props.listAllBranch(e.target.value);
    }
  };

  handlePreviewClose = (cond: any, data: any, files: any) => {
    this.setState({
      AttachmentPreviewStatus: false,
      attachmentUrls: data,
      attachmentFiles: files,
    });
  };
  handlePreviewOpen = (file_type: any, url: any, inx: any) => {
    if (
      file_type === "image/png" ||
      file_type === "image/jpeg" ||
      file_type === "image/jpg" ||
      file_type === "video/mp4" ||
      file_type === "video/webm" ||
      file_type === "application/pdf"
    ) {
      this.setState({
        AttachmentPreviewStatus: true,
        previewUrl: url,
        previewType: file_type,
        file_count: inx,
      });
    }
  };

  handleSignModal = () => {
    this.setState({ signatureModal: true });
  };

  handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
    this.setState({ signatureImageError: "" });

    this.setState({ isDrawing: true });
    const canvas = this.canvasRef.current;

    if (!canvas) return;
    this.context = canvas.getContext("2d");
    this.setState({ context: canvas.getContext("2d") });
    if (!this.context) return;

    const { offsetX, offsetY } = event.nativeEvent;

    this.setState({ signLastX: offsetX, signLastY: offsetY });
  };

  handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    this.setState({ signatureImageError: "" });

    if (!this.state.isDrawing) return;
    const canvas = this.canvasRef.current;

    if (canvas) {
      const { offsetX, offsetY } = event.nativeEvent;

      // Draw a line segment from the last recorded point to the current point
      let context: any = this.state.context;
      context.strokeStyle = "#000"; // Stroke color
      context.lineWidth = 2; // Stroke width
      context.beginPath();
      context.moveTo(this.state.signLastX, this.state.signLastY);
      context.lineTo(offsetX, offsetY);
      context.stroke();

      this.setState({ signLastX: offsetX, signLastY: offsetY });
    }
  };

  handleMouseUp = () => {
    this.setState({ signatureImageError: "" });

    this.setState({ isDrawing: false });
  };

  handleTouchStart = (event: React.TouchEvent<HTMLCanvasElement>) => {
    this.setState({ signatureImageError: "" });

    this.setState({ isDrawing: true });
    const canvas = this.canvasRef.current;
    console.log(canvas);

    if (!canvas) return;
    let context: any = this.state.context;
    context = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    if (!context) return;

    const { clientX, clientY } = event.nativeEvent.changedTouches[0];

    this.setState({ signLastX: clientX - rect.left, signLastY: clientY - 111 });
  };

  handleTouchMove = (event: React.TouchEvent<HTMLCanvasElement>) => {
    this.setState({ signatureImageError: "" });

    if (!this.state.isDrawing) return;
    const canvas = this.canvasRef.current;
    let context: any = this.state.context;
    if (!canvas || !context) return;

    const { clientX, clientY } = event.nativeEvent.changedTouches[0];
    const rect = canvas.getBoundingClientRect();
    // const x = touch.clientX - rect.left;
    // const y = touch.clientY - rect.top;

    // Draw a line segment from the last recorded point to the current point
    context.strokeStyle = "#000"; // Stroke color
    context.lineWidth = 2; // Stroke width
    context.beginPath();
    context.moveTo(this.state.signLastX, this.state.signLastY);
    context.lineTo(clientX - rect.left, clientY - rect.top);
    context.stroke();
    this.setState({
      signLastX: clientX - rect.left,
      signLastY: clientY - rect.top,
    });
  };

  handleTouchEnd = () => {
    this.setState({ signatureImageError: "" });

    this.setState({ isDrawing: false });
  };

  handleClear = () => {
    const canvas = this.canvasRef.current;
    let context: any = this.state.context;
    if (!canvas || !context) return;

    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  handleConvertToImage = () => {
    const canvas = this.canvasRef.current;
    if (!canvas) return;

    const image: string = canvas.toDataURL("image/png");

    if (
      image !==
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACWCAYAAABkW7XSAAAAAXNSR0IArs4c6QAABGJJREFUeF7t1AEJAAAMAsHZv/RyPNwSyDncOQIECEQEFskpJgECBM5geQICBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAgQdWMQCX4yW9owAAAABJRU5ErkJggg=="
    ) {
      this.setState({ signatureImage: image, signatureModal: false });
    }
  };

  render(): React.ReactNode {
    const initialValues = {
      title: "",
      description: "",
      client: "",
      cleaners: [],
      branch: this.state.selectedBranch,
      attachments: "",
      task_type: 0,
      start_time: "00:00",
      end_time: "00:00",
      any_time: "1",
      custom_time: "0",
      task_with_sign_in: Constants.TASK_WITH_SIGN_IN_YES,
      is_active: Constants.IS_ACTIVE,
      priority: TASK_PRIORITY.MEDIUM,
      task_date: "",
    };
    return (
      <div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={this.handleAddDailyTask}
          validationSchema={() => {
            return this.validationSchema();
          }}
        >
          {(props) => {
            return (
              <Form onChange={this.handleChange}>
                <div className="mb-2">
                  <label className="form-label">
                    Task Name<span className="text-project">*</span>
                  </label>
                  <Field
                    name="title"
                    type={"text"}
                    className="form-control text-field"
                  />
                  <ErrorMessage
                    name="title"
                    component={"div"}
                    className="cus-alert"
                  />
                </div>
                <div className="mb-2">
                  <label className="form-label">
                    Task Description<span className="text-project">*</span>
                  </label>
                  <Field
                    as="textarea"
                    name="description"
                    className="form-control text-area"
                  />
                  <ErrorMessage
                    name="description"
                    component={"div"}
                    className="cus-alert"
                  />
                </div>
                <div>
                  <label className="form-label">Attachment</label>
                  <div className="attachment-btn dropdown-field">
                    <input
                      type="file"
                      multiple
                      accept=".pdf,.PDF,.mp4,.MP4,.webm,.WEBM,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.doc,.DOC,.docx,.DOCX"
                      style={{ display: "none" }}
                      onChange={this.handleImageChange}
                      id="contained-button-file"
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        color="default"
                        component="span"
                      >
                        <PaperClipSVG /> Add attachment
                      </Button>
                    </label>
                  </div>
                </div>
                {this.state.attachmentUrls.length > 0 ? (
                  <div className="mb-2">
                    <label className="form-label">Attachments</label>
                  </div>
                ) : (
                  <></>
                )}
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex row">
                      {this.state.attachmentUrls &&
                        this.state.attachmentUrls.map(
                          (obj: any, index: number) => {
                            return (
                              <div className="img-card mr-16px mb-3">
                                <span className="delete">
                                  <a
                                    onClick={() =>
                                      this.handleDeleteImage(index)
                                    }
                                  >
                                    <TrashSVG />
                                  </a>
                                </span>
                                <div
                                  className="img-preview"
                                  onClick={() =>
                                    this.handlePreviewOpen(
                                      obj.file_type,
                                      obj.url,
                                      index
                                    )
                                  }
                                >
                                  <img
                                    className="img-thumb-perview"
                                    src={
                                      obj.file_type === "application/pdf"
                                        ? pdfImage
                                        : obj.file_type === "video/mp4" ||
                                          obj.file_type === "video/webm"
                                        ? videoImage
                                        : obj.file_type === "image/png" ||
                                          obj.file_type === "image/jpeg" ||
                                          obj.file_type === "image/jpg"
                                        ? obj.url
                                        : docImage
                                    }
                                  />
                                </div>
                              </div>
                            );
                          }
                        )}
                      {/* <AttachmentPreview
                        displayPreview={this.state.AttachmentPreviewStatus}
                        previewUrl={this.state.previewUrl}
                        previewType={this.state.previewType}
                        handlePreviewClose={this.handlePreviewClose}
                      /> */}
                      <ImageDrawing
                        displayPreview={this.state.AttachmentPreviewStatus}
                        previewUrl={this.state.previewUrl}
                        previewType={this.state.previewType}
                        handlePreviewClose={this.handlePreviewClose}
                        clickingImage={this.state.file_count}
                        attachUrlArray={this.state.attachmentUrls}
                        attachFiles={this.state.attachmentFiles}
                        page="add"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-2 col-md-6">
                    <label className="form-label">
                      Client<span className="text-project">*</span>
                    </label>
                    <Field
                      as="select"
                      className="form-select dropdown-field"
                      name="client"
                    >
                      <option value={""} disabled>
                        Select Client
                      </option>
                      {this.props.clientList.map((client: ICleaner) => (
                        <option
                          value={client.id.toString()}
                          key={client.id}
                        >{`${client.first_name} ${client.last_name}`}</option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="client"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                  <div className="mb-2 col-md-6">
                    <label className="form-label">
                      Branch<span className="text-project">*</span>
                    </label>
                    <Field
                      className="custom-select dropdown-field"
                      name="branch"
                      options={[
                        { value: "all", label: "All" },
                        ...this.props.branchList, // Spread the elements of the input array
                      ]}
                      value={this.state.selectedBranch}
                      component={CustomSelect}
                      placeholder="Select Branch"
                      isMulti={false}
                    />
                    <ErrorMessage
                      name="branch"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                  {this.props.userLoginDetails?.permission
                    ?.task_view_cleaner === 1 &&
                    this.props.addSpecialRequestTaskStatus !== true && (
                      <div className="mb-2 col-md-12">
                        <label className="form-label">
                          Cleaner<span className="text-project">*</span>
                        </label>
                        <Field
                          className="custom-select"
                          name="cleaners"
                          options={this.props.cleanerList}
                          component={CustomSelect}
                          placeholder="Select Cleaner"
                          isMulti={true}
                        />
                        <ErrorMessage
                          name="cleaners"
                          component={"div"}
                          className="cus-alert"
                        />
                      </div>
                    )}
                </div>
                <div className="row">
                  {/* <div className="mb-2 col-md-6">
                    <label className="form-label">
                      Branch<span className="text-project">*</span>
                    </label>
                    <Field
                      className="custom-select"
                      name="branch"
                      options={this.props.branchList}
                      component={CustomSelect}
                      placeholder="Select Branch"
                      isMulti={true}
                    />
                    <ErrorMessage
                      name="branch"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div> */}
                  <div className="mb-2 col-md-6">
                    <label className="form-label">
                      Date<span className="text-project">*</span>
                    </label>
                    <div
                      className="startdate"
                      onClick={() => this.handleStartDateClick()}
                    >
                      <DatePicker
                        name="task_date"
                        value={this.state.task_date}
                        onChange={this.handleTaskDate}
                      />
                      <ErrorMessage
                        name="task_date"
                        component={"div"}
                        className="cus-alert"
                      />
                    </div>
                  </div>
                  {this.props.addSpecialRequestTaskStatus !== true && (
                    <div className="mb-2 col-md-6">
                      <label className="form-label">
                        Task type<span className="text-project">*</span>
                      </label>
                      <Field
                        as="select"
                        className="form-select dropdown-field"
                        name="task_type"
                      >
                        <option value={Constants.REGULAR_TASK}>Regular</option>
                        <option value={Constants.SPECIAL_REQUEST}>
                          Special type
                        </option>
                      </Field>
                      <ErrorMessage
                        name="task_type"
                        component={"div"}
                        className="cus-alert"
                      />
                    </div>
                  )}
                  <div className="mb-2 col-md-12">
                    <label className="form-label">
                      Time<span className="text-project">*</span>
                    </label>
                    <div>
                      <label>
                        <Field type="radio" name="any_time" value="1" />
                        <span className="check-left-space">Any Time</span>
                      </label>
                    </div>
                  </div>
                  <div className="mt-2 mb-2 col-md-12">
                    <Field type="radio" name="any_time" value="0" />
                    <label className="check-time">
                      <span className="check-left-space d-flex">
                        <div className="mr-8px">Start</div>
                        <TimePicker
                          name="start_time"
                          value={this.state.start_time}
                          onChange={this.handleStartTime}
                        />
                      </span>
                    </label>
                    <label
                      className="check-time"
                      style={{ marginLeft: "16px" }}
                    >
                      <span className="d-flex">
                        <div className="mr-8px">End</div>
                        <TimePicker
                          name="end_time"
                          value={this.state.end_time}
                          onChange={this.handleEndTime}
                        />
                      </span>
                    </label>
                  </div>
                  {this.props.addSpecialRequestTaskStatus !== true && (
                    <div className="mt-2 mb-2 col-md-6">
                      <label className="form-label">
                        Task with sign in
                        <span className="text-project">*</span>
                      </label>
                      <Field
                        className="custom-select"
                        name="task_with_sign_in"
                        options={[
                          {
                            value: Constants.TASK_WITH_SIGN_IN_YES,
                            label: "Task with sign in",
                          },
                          {
                            value: Constants.TASK_WITH_SIGN_IN_NO,
                            label: "Task without sign in",
                          },
                        ]}
                        component={CustomSelect}
                        isMulti={false}
                      />
                      <ErrorMessage
                        name="task_with_sign_in"
                        component={"div"}
                        className="cus-alert"
                      />
                    </div>
                  )}
                  <div className="mb-2 col-md-6">
                    <label className="form-label">
                      Task priority<span className="text-project">*</span>
                    </label>
                    <Field
                      className="custom-select"
                      name="priority"
                      options={[
                        { value: TASK_PRIORITY.LOW, label: "Low" },
                        { value: TASK_PRIORITY.MEDIUM, label: "Medium" },
                        { value: TASK_PRIORITY.HIGH, label: "High" },
                      ]}
                      component={CustomSelect}
                      isMulti={false}
                    />
                    <ErrorMessage
                      name="priority"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                  {this.props.addSpecialRequestTaskStatus !== true && (
                    <div className="mb-2 col-md-6">
                      <label className="form-label">
                        Is Active<span className="text-project"></span>
                      </label>
                      <Field
                        className="custom-select"
                        name="is_active"
                        options={[
                          { value: Constants.ENABLE, label: "Enable" },
                          { value: Constants.DISABLE, label: "Disable" },
                        ]}
                        component={CustomSelect}
                        isMulti={false}
                      />
                      <ErrorMessage
                        name="is_active"
                        component={"div"}
                        className="cus-alert"
                      />
                    </div>
                  )}
                </div>

                <div className="row mt-3 mb-3">
                  <div className="col-md-12 cus-progress">
                    <Modal
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      show={this.props.processTrigger}
                    >
                      <Modal.Body className="p-0">
                        <ProgressBar
                          now={this.props.progressPercentage}
                          label={
                            this.props.progressPercentage === 100
                              ? `Processing your request...`
                              : `Task Adding....${this.props.progressPercentage}%`
                          }
                        />
                      </Modal.Body>
                    </Modal>
                    <div className="d-flex">
                      <div className="mr-16px">
                        <button
                          type="submit"
                          id="create-button"
                          className="btn btn-primary create-button"
                        >
                          Create{" "}
                          <span
                            id="spinner-border"
                            className="spinner-border spinner-border-sm d-none"
                          ></span>
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn cancel-button"
                          onClick={() => this.props.onClose(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                    <Modal
                      size="lg"
                      show={this.state.signatureModal}
                      onHide={() => this.setState({ signatureModal: false })}
                      aria-labelledby="example-modal-sizes-title-lg"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                          <h3 className="m-0">Add signature</h3>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="p-4">
                        <div className="signature-pad">
                          <canvas
                            ref={this.canvasRef}
                            style={{ width: "100% !important" }}
                            onMouseDown={this.handleMouseDown}
                            onMouseMove={this.handleMouseMove}
                            onMouseUp={this.handleMouseUp}
                            onTouchStart={this.handleTouchStart}
                            onTouchEnd={this.handleTouchEnd}
                            onTouchMove={this.handleTouchMove}
                          />
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.handleClear}
                        >
                          Clear
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          id="add-sign-image"
                          style={{ marginLeft: "10px" }}
                          onClick={this.handleConvertToImage}
                        >
                          Add
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  addSpecialRequestTaskStatus: state.dailytask.addSpecialRequestTaskStatus,
  clientList: state.tasktemplate.clientList,
  branchList: state.tasktemplate.branchList,
  cleanerList: state.tasktemplate.cleanerList,
  processTrigger: state.dailytask.processTrigger,
  progressPercentage: state.dailytask.progressPercentage,
  addDailyTaskSuccess: state.dailytask.addDailyTaskSuccess,
  userLoginDetails: state.sidebar.userLoginDetails,
  currentTask_createdTaskid: state.dailytask.currentTask_createdTaskid,
  sendMailUsers: state.dailytask.sendMailUsers,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    listAllClient: bindActionCreators(listAllClient, dispatch),
    addDailyTask: bindActionCreators(addDailyTask, dispatch),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
    listAllCleaner: bindActionCreators(listAllCleaner, dispatch),
    getSendmailUsers: bindActionCreators(getSendmailUsers, dispatch),
    sendQAEmailDailyTask: bindActionCreators(sendQAEmailDailyTask, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDailyTask);
