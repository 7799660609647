import { AnyAction } from "redux";
import { IContractType } from "../../types/contract.type";
import { TClientContractTable } from "../../types/user.type";
import { ICleaner } from "./TaskTemplateActionTypes";

export const API_GET_ALL_CONTRACTS = "get_all_contract/LOAD";
export const API_GET_ALL_CONTRACTS_SUCCESS = "get_all_contract/LOAD_SUCCESS";
export const API_GET_ALL_CONTRACTS_FAIL = "get_all_contract/LOAD_FAIL";
export const API_ADD_CONTRACT = "contract/LOAD";
export const API_ADD_CONTRACT_SUCCESS = "contract/LOAD_SUCCESS";
export const API_ADD_CONTRACT_FAIL = "contract/LOAD_FAIL";
export const API_ADD_CONTRACT_PROGRESS = "API_ADD_CONTRACT_PROGRESS";
export const UPDATE_ADD_CONTRACT_DLG_STATUS = "UPDATE_ADD_CONTRACT_DLG_STATUS";
export const API_EDIT_CONTRACT = "edit_contract/LOAD";
export const API_EDIT_CONTRACT_SUCCESS = "edit_contract/LOAD_SUCCESS";
export const API_EDIT_CONTRACT_FAIL = "edit_contract/LOAD_FAIL";
export const API_EDIT_CONTRACT_PROGRESS = "API_EDIT_CONTRACT_PROGRESS"
export const UPDATE_EDIT_CONTRACT_DLG_STATUS =
  "UPDATE_EDIT_CONTRACT_DLG_STATUS";
export const API_DELETE_CONTRACT = "delete_contract/LOAD";
export const API_DELETE_CONTRACT_SUCCESS = "delete_contract/LOAD_SUCCESS";
export const API_DELETE_CONTRACT_FAIL = "delete_contract/LOAD_FAIL";
export const UPDATE_DELETE_CONTRACT_DLG_STATUS =
  "UPDATE_DELETE_CONTRACT_DLG_STATUS";
export const API_GET_CONTRACT_BY_ID = "contract_by_id/LOAD";
export const API_GET_CONTRACT_BY_ID_SUCCESS = "contract_by_id/LOAD_SUCCESS";
export const API_GET_CONTRACT_BY_ID_FAIL = "contract_by_id/LOAD_FAIL";
export const UPDATE_CONTRACT_FETCH_STATUS = "UPDATE_CONTRACT_FETCH_STATUS";
export const FILTER_CONTRACT = "FILTER_STATUS";
export const UPDATE_CONTRACTS = "UPDATE_CONTRACTS";
export const UPDATE_CONTRACT_DLG_STATUS = "UPDATE_CONTRACT_DLG_STATUS";
export const API_DELETE_ATTACHMENT = "ATTACHMENT_DELETE";
export const API_VIEW_CONTRACT_BY_ID = "view_contract/LOAD";
export const API_VIEW_CONTRACT_BY_ID_SUCCESS = "view_contract/LOAD_SUCCESS";
export const API_VIEW_CONTRACT_BY_ID_FAIL = "view_contract/LOAD_FAIL";
export const UPDATE_VIEW_CONTRACT = "UPDATE_VIEW_CONTRACT";
export const CONTRACT_SORT = "CONTRACT_SORT";

export interface IAddClientContract {
  client: string;
  attachments: any | Blob;
}

export interface IEditClientContract {
  client: string;
  branch: string;
  title: string;
  is_active: string;
  attachments: any | Blob;
  description: string;
}

export interface ContractTemplateState {
  cleanerList: ICleaner[];
  clientList: ICleaner[];
  addContractStatus: boolean;
  editContractStatus: boolean;
  deleteContractStatus: boolean;
  contractFetchStatus: boolean;
  addContractSuccess: string;
  addContractError: string;
  editContractSuccess: string;
  editContractError: string;
  deleteContractSuccess: string;
  deleteContractError: string;
  selectedContractData: IContractType | null;
  id: string;
  contractData: TClientContractTable[];
  filteredContractData: TClientContractTable[];
  total_count: number;
  processTrigger:boolean;
  progressPercentage:number;
  viewContractData: IContractType | null;
  viewContractStatus: boolean;
  contractSortBy : string;
  contractOrderBy : string;
}

export type ContractTemplateDispatchType = (args: AnyAction) => AnyAction;
