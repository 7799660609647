import React from "react";
import { RootState } from '../../index';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { updateEditAuditDlgStatus } from "../../shared/Reducers";
import DeleteAudit from "./DeleteCertificate";
import { IAuditCardProps, IAuditCardState } from "../../types/audit.type";
import { TableDeleteSVG, TableEditSVG, TableViewSVG } from "../../assets/images/ImagesV2";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ICertificateCardProps, ICertificateCardState } from "../../types/certificate.type";
import moment from "moment";
import {
    DisableSVG,
    EnableSVG
  } from "../../assets/images/UsermanagementsSVG";

class AuditCard extends React.Component<ICertificateCardProps, ICertificateCardState> {
    constructor(props: ICertificateCardProps) {
        super(props);
        this.state = {
            show: false,
            displayDelete: false,
            currentAuditId: "",
            editShow: false,
            displayView: false
        }
    }

    handleClose = () => {
        this.setState({ show: false })
    }

    handleShow = () => {
        this.setState({ show: true })
    }
    handleViewAudit = (id: string) => {
        this.setState({ displayView: true, currentAuditId: id })
    }

    handleViewClose = (status: boolean) => {
        this.setState({ displayView: false });
    }


    // Delete audit based on ID
    handleDelete = (id: string) => {
        this.setState({ displayDelete: true, currentAuditId: id })
    }

    // delete popup close
    handleDeleteClose = (status: boolean) => {
        this.setState({ displayDelete: false, currentAuditId: "" })
        if (status) {
            this.props.handleDeleteCertificate(this.state.currentAuditId)
        }
    }

    render(): React.ReactNode {
        const { id, name, status, created_at } = this.props.certificates;
        console.log("this.props.audits",this.props.certificates);
        
        return (
            <tr>
                <td>{name}</td>
                <td>{moment(
            new Date(created_at)
          ).format("DD/MM/YYYY")}</td>
                <td>{status===1?
                <EnableSVG/>:
                <DisableSVG/>}</td>
                <td>
                    <div className="btn-group">
                        {/* <span className="mr-20px">
                            <a href="javascript:void(0)" onClick={() => this.props.handleViewAudit(id)}>
                                <OverlayTrigger placement="top"
                                    overlay={<Tooltip>{"View"}</Tooltip>}>
                                   <Button className="action-btn"><TableViewSVG/></Button>
                                </OverlayTrigger>
                            </a>
                            </span> */}
                            <span className="mr-20px">
                            <a href="javascript:void(0)" onClick={() => this.props.handleEditAudit(id)}>
                                <OverlayTrigger placement="top"
                                    overlay={<Tooltip>{"Edit"}</Tooltip>}>
                                    <Button className="action-btn"><TableEditSVG /></Button>
                                </OverlayTrigger>
                            </a>
                        </span>
                       
                        <span>
                            <a href="javascript:void(0)" onClick={() => this.handleDelete(id)}>
                                <OverlayTrigger placement="top"
                                    overlay={<Tooltip>{"Delete"}</Tooltip>}>
                                   <Button className="action-btn"><TableDeleteSVG /></Button>
                                </OverlayTrigger>
                            </a>
                        </span>
                        <DeleteAudit displayDelete={this.state.displayDelete} deletedName={name} handleDeleteClose={this.handleDeleteClose} />
                    </div>
                </td>
            </tr>
        )
    }
}
const mapStateToProps = (state: RootState) => ({
    editAuditStatus: state.audit.editAuditStatus
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateEditAuditDlgStatus: bindActionCreators(updateEditAuditDlgStatus, dispatch),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AuditCard);