export const API_GET_INVOICE_ALLCLIENTS = "api_invoice_all_clients/LOAD";
export const API_GET_INVOICE_ALLCLIENTS_SUCCESS =
  "api_invoice_all_clients/LOAD_SUCCESS";
export const API_GET_INVOICE_ALLCLIENTS_FAIL =
  "api_invoice_all_clients/LOAD_FAIL";

export const API_GET_INVOICE_ALLBRANCH = "api_get_invoice_all_branches/LOAD";
export const API_GET_INVOICE_ALLBRANCH_SUCCESS =
  "api_get_invoice_all_branches/LOAD_SUCCESS";
export const API_GET_INVOICE_ALLBRANCH_FAIL =
  "api_get_invoice_all_branches/LOAD_FAIL";
export const FILTER_ALL_INVOICE_CLIENT = "FILTER_ALL_INVOICE_CLIENT";
export const API_GET_ALL_INVOICE_REPORT = "API_GET_ALL_INVOICE_REPORT/LOAD";
export const API_GET_ALL_INVOICE_REPORT_SUCCESS =
  "API_GET_ALL_INVOICE_REPORT/LOAD_SUCCESS";
export const API_GET_ALL_INVOICE_REPORT_FAIL =
  "API_GET_ALL_INVOICE_REPORT/LOAD_FAIL";
export const FILTER_ALL_INVOICE_BRANCH = "FILTER_ALL_INVOICE_BRANCH";
export const UPDATE_VIEW_INVOICE = " UPDATE_VIEW_INVOICE";
export const UPDATE_EXPORT_INVOICE = " UPDATE_EXPORT_INVOICE";
export const API_GET_INVOICE_REPORT = "API_GET_INVOICE_REPORT/LOAD";
export const API_GET_INVOICE_REPORT_SUCCESS =
  "API_GET_INVOICE_REPORT/LOAD_SUCCESS";
export const API_GET_INVOICE_REPORT_FAIL = "API_GET_INVOICE_REPORT/LOAD_FAIL";
export const UPDATE_INVOICE_FROM_DATE = "UPDATE_INVOICE_FROM_DATE";
export const UPDATE_INVOICE_TO_DATE = "UPDATE_INVOICE_TO_DATE";
export const API_GET_INVOICE_EXPORT_REPORT =
  "API_GET_INVOICE_EXPORT_REPORT/LOAD";
export const API_GET_INVOICE_EXPORT_REPORT_SUCCESS =
  "API_GET_INVOICE_EXPORT_REPORT/LOAD_SUCCESS";
export const API_GET_INVOICE_EXPORT_REPORT_FAIL =
  "API_GET_INVOICE_EXPORT_REPORT/LOAD_FAIL";

export interface InvoiceReducerState {
  clientList_Invoice: any;
  branchList_Invoice: any;
  filter_InvoiceClientById: any;
  filter_InvoiceBranchById: any;
  allInvoice_Data: any;
  totalInvoiceCount: any;
  invoice_Status: any;
  invoiceReportdata: any;
  invoice_from_date: any;
  invoice_to_date: any;
  export: any;
  export_status: any
}
