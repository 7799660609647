import {
  API_ADD_COMMENT,
  API_ADD_COMMENT_FAIL,
  API_ADD_COMMENT_SUCCESS,
  API_ADD_DAILY_TASK,
  API_ADD_DAILY_TASK_FAIL,
  API_ADD_DAILY_TASK_PROGRESS,
  API_ADD_DAILY_TASK_SUCCESS,
  API_GET_ALL_ADDITIONAL_TASK,
  API_GET_ALL_ADDITIONAL_TASK_SUCCESS,
  API_GET_ALL_ADDITIONAL_TASK_FAIL,
  API_GET_SENDMAIL_APPROVED_TASK,
  API_DELETE_DAILY_TASK,
  API_DELETE_DAILY_TASK_FAIL,
  API_DELETE_DAILY_TASK_SUCCESS,
  API_EDIT_COMMENT,
  API_EDIT_COMMENT_FAIL,
  API_EDIT_COMMENT_SUCCESS,
  API_EDIT_DAILY_TASK,
  API_EDIT_DAILY_TASK_FAIL,
  API_EDIT_DAILY_TASK_SUCCESS,
  API_EDIT_ADDITIONAL_TASK,
  API_EDIT_ADDITIONAL_TASK_SUCCESS,
  API_EDIT_ADDITIONAL_TASK_FAIL,
  API_EDIT_MOVE_TO_TASK,
  API_EDIT_MOVE_TO_TASK_SUCCESS,
  API_EDIT_MOVE_TO_TASK_FAIL,
  API_EDIT_RECOMMENDED_TASK,
  API_EDIT_RECOMMENDED_TASK_SUCCESS,
  API_EDIT_RECOMMENDED_TASK_FAIL,
  API_GET_ALL_COMPLETE_DAILY_TASKS,
  API_GET_ALL_COMPLETE_DAILY_TASKS_FAIL,
  API_GET_ALL_COMPLETE_DAILY_TASKS_SUCCESS,
  API_GET_ALL_DAILY_TASKS,
  API_GET_ALL_DAILY_TASKS_FAIL,
  API_GET_ALL_DAILY_TASKS_SUCCESS,
  API_GET_ALL_DAILY_TASKS_WITHOUT_LIMIT,
  API_GET_ALL_DAILY_TASKS_WITHOUT_LIMIT_FAIL,
  API_GET_ALL_DAILY_TASKS_WITHOUT_LIMIT_SUCCESS,
  API_GET_ALL_APPROVED_TASKS,
  API_GET_ALL_APPROVED_TASKS_FAIL,
  API_GET_ALL_APPROVED_TASKS_SUCCESS,
  API_GET_ALL_RECOMMENDED_TASKS,
  API_GET_ALL_RECOMMENDED_TASKS_FAIL,
  API_GET_ALL_RECOMMENDED_TASKS_SUCCESS,
  API_GET_ALL_FOLLOWUP_DAILY_TASKS,
  API_GET_ALL_FOLLOWUP_DAILY_TASKS_FAIL,
  API_GET_ALL_FOLLOWUP_DAILY_TASKS_SUCCESS,
  API_GET_COMMENT_BY_ID,
  API_GET_COMMENT_BY_ID_FAIL,
  API_GET_COMMENT_BY_ID_SUCCESS,
  API_GET_ADDITIONAL_TASK_BY_ID,
  API_GET_ADDITIONAL_TASK_BY_ID_SUCCESS,
  API_GET_ADDITIONAL_TASK_BY_ID_FAIL,
  API_GET_RECOMMENDED_TASK_BY_ID,
  API_GET_RECOMMENDED_TASK_BY_ID_SUCCESS,
  API_GET_RECOMMENDED_TASK_BY_ID_FAIL,
  API_GET_DAILY_TASK_BY_ID,
  API_GET_DAILY_TASK_BY_ID_FAIL,
  API_GET_DAILY_TASK_BY_ID_FOR_COPY,
  API_GET_DAILY_TASK_BY_ID_FOR_COPY_FAIL,
  API_GET_DAILY_TASK_BY_ID_FOR_COPY_SUCCESS,
  API_GET_DAILY_TASK_BY_ID_FOR_VIEW,
  API_GET_DAILY_TASK_BY_ID_FOR_VIEW_FAIL,
  API_GET_DAILY_TASK_BY_ID_FOR_VIEW_SUCCESS,
  API_GET_DAILY_TASK_BY_ID_FOR_MAKESCHEDULE,
  API_GET_DAILY_TASK_BY_ID_FOR_MAKESCHEDULE_FAIL,
  API_GET_DAILY_TASK_BY_ID_FOR_MAKESCHEDULE_SUCCESS,
  API_GET_DAILY_TASK_BY_ID_SUCCESS,
  API_GET_REASSIGN_DAILY_TASK_BY_ID,
  API_GET_REASSIGN_DAILY_TASK_BY_ID_FAIL,
  API_GET_REASSIGN_DAILY_TASK_BY_ID_SUCCESS,
  API_GET_SEND_QA_EMAIL_DAILY_TASK_BY_ID,
  API_GET_SEND_QA_EMAIL_DAILY_TASK_BY_ID_FAIL,
  API_GET_SEND_QA_EMAIL_DAILY_TASK_BY_ID_SUCCESS,
  API_GET_TASK_HISTORY_BY_ID,
  API_GET_TASK_HISTORY_BY_ID_FAIL,
  API_GET_TASK_HISTORY_BY_ID_SUCCESS,
  API_REASSIGN_DAILY_TASK,
  API_REASSIGN_DAILY_TASK_FAIL,
  API_REASSIGN_DAILY_TASK_SUCCESS,
  API_SEND_QA_EMAIL_DAILY_TASK,
  API_SEND_QA_EMAIL_DAILY_TASK_FAIL,
  API_SEND_QA_EMAIL_DAILY_TASK_SUCCESS,
  API_UPDATE_COMMENT,
  API_UPDATE_COMMENT_FAIL,
  API_UPDATE_COMMENT_SUCCESS,
  DailyTaskState,
  FILTER_DAILY_TASK,
  FILTER_TASK_TYPE,
  FILTER_TASK_PRIORITY,
  FILTER_TASK_STATUS,
  FILTER_SPECIAL_REQUEST_TASK_STATUS,
  FILTER_TASK_DUE_TYPE,
  IEditDailyTask,
  UPDATE_ADD_COMMENT,
  UPDATE_ADD_DAILY_TASK_DLG_STATUS,
  UPDATE_COMMENT,
  UPDATE_DAILY_TASKS,
  UPDATE_DAILY_TASK_FETCH_STATUS,
  UPDATE_DELETE_COMMENT,
  UPDATE_DELETE_DAILY_TASK_DLG_STATUS,
  UPDATE_EDIT_COMMENT,
  UPDATE_EDIT_DAILY_TASK_DLG_STATUS,
  UPDATE_REASSIGN_DAILY_TASK_DLG_STATUS,
  UPDATE_SEND_QA_EMAIL_DAILY_TASK_DLG_STATUS,
  UPDATE_TASK_HISTORY_DLG_STATUS,
  FILTER_TASK_DATE,
  FILTER_TASK_CLIENT,
  FILTER_TASK_BRANCH,
  EDIT_MODAL_DAILY_TASK,
  API_GET_USERS_DETAILS_FOR_MAIL,
  API_GET_USERS_DETAILS_FOR_MAIL_SUCCESS,
  API_GET_USERS_DETAILS_FOR_MAIL_FAIL,
} from "../ActionTypes";
import { AnyAction, Dispatch } from "redux";

import { AppDispatch } from "../..";
import { Console } from "console";
import { IDailyTaskTable } from "../../types/dailyTask.type";

const initialState: DailyTaskState = {
  addDailyTaskStatus: false,
  addSpecialRequestTaskStatus: false,
  addDailyTaskSuccess: "",
  deleteDailyTaskStatus: false,
  deleteDailyTaskSuccess: "",
  editDailyTaskStatus: false,
  copyDailyTaskStatus: false,
  makeScheduleDailyTaskStatus: false,
  reassignDailyTaskStatus: false,
  sendqaemailDailyTaskStatus: false,
  viewDailyTaskStatus: false,
  editDailyTaskSuccess: "",
  editadditionalTaskSuccess: "",
  dailyTaskFetchStatus: false,
  selectedDailyTaskData: null,
  selectedAdditionalTaskData: null,
  selectedRecommendedTaskData: null,
  dailyTaskData: [],
  filteredDailyTaskData: [],
  filteredAdditionalTaskData: [],
  filteredDailyTaskAllData: [],
  filteredAllApprovedTask: [],
  filteredAllRecommendedTask: [],
  id: "",
  taskHistoryData: [],
  addCommentStatus: false,
  addCommentSuccess: "",
  commentFetchStatus: false,
  deleteCommentStatus: false,
  deleteCommentSuccess: "",
  editCommentStatus: false,
  editCommentSuccess: "",
  commentsData: [],
  total_count: 0,
  total_Count: 0,
  recommended_count: 0,
  processTrigger: false,
  progressPercentage: 0,
  filterTaskTypeById: "all",
  filterTaskPriorityById: "all",
  filterTaskStatusById: "all",
  filterSpecialRequestTaskStatusById: "all",
  filterTaskDueTypeByValue: "all",
  filterTaskClientById: "all",
  filterTaskBranchById: "all",
  filterTaskDateTypeValue: { from_date: "", to_date: "" },
  followDailyTask: [],
  completeDailyTask: [],
  editDailyTaskstatusvalue: false,
  additionalTaskid: "",
  recommendedTaskId: "",
  editRecommendedTaskSuccess: "",
  sendMailModal: false,
  sendMailUsers: [],
  currentTask_createdTaskid: null,
};

export const DailyTaskReducer = (
  state: DailyTaskState = initialState,
  action: AnyAction
): DailyTaskState => {
  switch (action.type) {
    case UPDATE_DAILY_TASKS:
      return { ...state };
    case UPDATE_DAILY_TASK_FETCH_STATUS:
      return { ...state, dailyTaskFetchStatus: action.payload };

    case API_GET_ALL_DAILY_TASKS:
      return { ...state };
    case API_GET_ALL_DAILY_TASKS_SUCCESS:
      let taskData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task_list" in data && data.task_list) {
          taskData = data.task_list;
        }
      }
      let total_count = 0;
      if (action.payload) {
        const { data } = action.payload;
        if (data && "total_count" in data && data.total_count) {
          total_count = data.total_count;
        }
      }
      return {
        ...state,
        total_count: total_count,
        editDailyTaskStatus: false,
        copyDailyTaskStatus: false,
        makeScheduleDailyTaskStatus: false,
        addDailyTaskStatus: false,
        deleteDailyTaskStatus: false,
        filteredDailyTaskData: taskData,
      };
    case API_GET_ALL_DAILY_TASKS_FAIL:
      return { ...state };
    case API_GET_ALL_ADDITIONAL_TASK:
      return { ...state };
    case API_GET_ALL_ADDITIONAL_TASK_SUCCESS:
      let task_Data = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task_list" in data && data.task_list) {
          task_Data = data.task_list;
        }
      }
      let total_Count = 0;
      if (action.payload) {
        const { data } = action.payload;
        if (data && "total_count" in data && data.total_count) {
          total_Count = data.total_count;
        }
      }
      return {
        ...state,
        filteredAdditionalTaskData: task_Data,
        id: task_Data.id,
        total_Count: total_Count,
      };
    case API_GET_ALL_ADDITIONAL_TASK_FAIL:
      return { ...state };
    case API_GET_SENDMAIL_APPROVED_TASK:
      return { ...state, sendMailModal: action.payload.val };
    case API_GET_ALL_DAILY_TASKS_WITHOUT_LIMIT:
      return { ...state };
    case API_GET_ALL_DAILY_TASKS_WITHOUT_LIMIT_SUCCESS:
      let allTaskData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task_list" in data && data.task_list) {
          allTaskData = data.task_list;
        }
      }
      return {
        ...state,
        filteredDailyTaskAllData: allTaskData,
      };
    case API_GET_ALL_DAILY_TASKS_WITHOUT_LIMIT_FAIL:
      return { ...state };
    case API_GET_USERS_DETAILS_FOR_MAIL:
      return { ...state };
    case API_GET_USERS_DETAILS_FOR_MAIL_SUCCESS:
      let allusersDetails = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "usersDetails" in data && data.usersDetails) {
          allusersDetails = data.usersDetails;
          console.log(
            "API_GET_USERS_DETAILS_FOR_MAIL_SUCCESS",
            data.usersDetails
          );
        }
      }
      return { ...state, sendMailUsers: allusersDetails };
    case API_GET_USERS_DETAILS_FOR_MAIL_FAIL:
      return { ...state };
    case API_GET_ALL_APPROVED_TASKS:
      return { ...state };
    case API_GET_ALL_APPROVED_TASKS_SUCCESS:
      let allApprovedTaskData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task_list" in data && data.task_list) {
          allApprovedTaskData = data.task_list;
        }
      }
      return {
        ...state,
        filteredAllApprovedTask: allApprovedTaskData,
      };
    case API_GET_ALL_APPROVED_TASKS_FAIL:
      return { ...state };

    case API_GET_ALL_RECOMMENDED_TASKS:
      return { ...state };
    case API_GET_ALL_RECOMMENDED_TASKS_SUCCESS:
      let allRecommendedTaskData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task_list" in data && data.task_list) {
          allRecommendedTaskData = data.task_list;
        }
      }
      let recommended_count = 0;
      if (action.payload) {
        const { data } = action.payload;
        if (data && "total_count" in data && data.total_count) {
          recommended_count = data.total_count;
        }
      }
      console.log("AllRecomended", allRecommendedTaskData);

      return {
        ...state,
        filteredAllRecommendedTask: allRecommendedTaskData,
        recommended_count: recommended_count,
      };
    case API_GET_ALL_RECOMMENDED_TASKS_FAIL:
      return { ...state };

    // API_GET_ALL_FOLLOW_DAILY_TASKS
    case API_GET_ALL_FOLLOWUP_DAILY_TASKS:
      return { ...state };
    case API_GET_ALL_FOLLOWUP_DAILY_TASKS_SUCCESS:
      let followuptasks = [];
      if (action.payload) {
        const { data } = action.payload;

        if (data && "task_list" in data && data.task_list) {
          followuptasks = data.task_list;
        }
      }
      // console.log('reducerFollow',followuptasks)
      return {
        ...state,
        followDailyTask: followuptasks,
      };
    case API_GET_ALL_FOLLOWUP_DAILY_TASKS_FAIL:
      return { ...state };

    // API_GET_ALL_COMPLETE_DAILY_TASKS
    case API_GET_ALL_COMPLETE_DAILY_TASKS:
      return { ...state };
    case API_GET_ALL_COMPLETE_DAILY_TASKS_SUCCESS:
      let completetasks = [];
      if (action.payload) {
        const { data } = action.payload;

        if (data && "task_list" in data && data.task_list) {
          completetasks = data.task_list;
        }
      }
      return {
        ...state,
        completeDailyTask: completetasks,
      };
    case API_GET_ALL_COMPLETE_DAILY_TASKS_FAIL:
      return { ...state };

    case API_GET_DAILY_TASK_BY_ID:
      return { ...state, id: action.payload.id };
    case API_GET_DAILY_TASK_BY_ID_SUCCESS:
      let tasks = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task_list" in data && data.task_list) {
          tasks = data.task_list;
        }
      }
      return {
        ...state,
        selectedDailyTaskData: tasks,
        editDailyTaskStatus: true,
        dailyTaskFetchStatus: false,
        editDailyTaskstatusvalue: true,
      };
    case API_GET_DAILY_TASK_BY_ID_FAIL:
      return { ...state };
    case API_GET_ADDITIONAL_TASK_BY_ID:
      return { ...state, additionalTaskid: action.payload.id };
    case API_GET_ADDITIONAL_TASK_BY_ID_SUCCESS:
      let additional_task = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task_list" in data && data.task_list) {
          additional_task = data.task_list;
        }
      }
      return {
        ...state,
        selectedAdditionalTaskData: additional_task,
      };
    case API_GET_ADDITIONAL_TASK_BY_ID_FAIL:
      return { ...state };

    case API_GET_RECOMMENDED_TASK_BY_ID:
      return { ...state, recommendedTaskId: action.payload.id };
    case API_GET_RECOMMENDED_TASK_BY_ID_SUCCESS:
      let recommended_task = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task_list" in data && data.task_list) {
          recommended_task = data.task_list;
        }
      }
      return {
        ...state,
        selectedRecommendedTaskData: recommended_task,
      };
    case API_GET_RECOMMENDED_TASK_BY_ID_FAIL:
      return { ...state };

    case API_GET_DAILY_TASK_BY_ID_FOR_COPY:
      return { ...state, id: action.payload.id };
    case API_GET_DAILY_TASK_BY_ID_FOR_COPY_SUCCESS:
      let copy_tasks = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task_list" in data && data.task_list) {
          copy_tasks = data.task_list;
        }
      }
      return {
        ...state,
        selectedDailyTaskData: copy_tasks,
        copyDailyTaskStatus: true,
        dailyTaskFetchStatus: false,
      };
    case API_GET_DAILY_TASK_BY_ID_FOR_COPY_FAIL:
      return { ...state };
    case API_GET_DAILY_TASK_BY_ID_FOR_VIEW:
      return { ...state, id: action.payload.id };
    case API_GET_DAILY_TASK_BY_ID_FOR_VIEW_SUCCESS:
      let view_tasks = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task_list" in data && data.task_list) {
          view_tasks = data.task_list;
        }
      }
      return {
        ...state,
        selectedDailyTaskData: view_tasks,
        dailyTaskFetchStatus: false,
      };
    case API_GET_DAILY_TASK_BY_ID_FOR_VIEW_FAIL:
      return { ...state };
    case API_GET_DAILY_TASK_BY_ID_FOR_MAKESCHEDULE:
      return { ...state, id: action.payload.id };
    case API_GET_DAILY_TASK_BY_ID_FOR_MAKESCHEDULE_SUCCESS:
      let schedule_tasks = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task_list" in data && data.task_list) {
          schedule_tasks = data.task_list;
        }
      }
      return {
        ...state,
        selectedDailyTaskData: schedule_tasks,
        makeScheduleDailyTaskStatus: true,
        dailyTaskFetchStatus: false,
      };
    case API_GET_DAILY_TASK_BY_ID_FOR_MAKESCHEDULE_FAIL:
      return { ...state };
    case API_GET_TASK_HISTORY_BY_ID:
      return { ...state, id: action.payload.id };
    case API_GET_TASK_HISTORY_BY_ID_SUCCESS:
      let task = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task" in data && data.task) {
          task = data.task;
        }
      }
      return {
        ...state,
        taskHistoryData: task,
        viewDailyTaskStatus: true,
        dailyTaskFetchStatus: false,
      };
    case API_GET_TASK_HISTORY_BY_ID_FAIL:
      return { ...state };
    case UPDATE_TASK_HISTORY_DLG_STATUS:
      return { ...state, viewDailyTaskStatus: action.payload };
    case API_ADD_DAILY_TASK:
      let values: boolean = false;
      if (action.payload.request.data.FormData === undefined) {
        values = true;
      }
      return {
        ...state,
        addDailyTaskSuccess: "",
        processTrigger: values,
        currentTask_createdTaskid: null,
      };
    case API_ADD_DAILY_TASK_SUCCESS:
      let message = [];
      console.log("API_ADD_DAILY_TASK_SUCCESS", action.payload);

      if (action.payload) {
        const { data } = action.payload;
        var value = data.taskId;
        if (data && "message" in data && data.message) {
          message = data.message;
        }
      }
      return {
        ...state,
        addDailyTaskStatus: false,
        copyDailyTaskStatus: false,
        makeScheduleDailyTaskStatus: false,
        addDailyTaskSuccess: message,
        processTrigger: false,
        progressPercentage: 0,
        currentTask_createdTaskid: value,
      };
    case API_ADD_DAILY_TASK_FAIL:
      return { ...state, processTrigger: false, progressPercentage: 0 };
    case API_ADD_DAILY_TASK_PROGRESS:
      return { ...state, progressPercentage: action.payload };
    case UPDATE_ADD_DAILY_TASK_DLG_STATUS:
      return {
        ...state,
        addDailyTaskStatus: action.payload.status,
        addSpecialRequestTaskStatus: action.payload.special_request,
      };
    case API_EDIT_DAILY_TASK:
      return { ...state, editDailyTaskSuccess: "" };
    case API_EDIT_DAILY_TASK_SUCCESS:
      let messages = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          messages = data.message;
        }
      }
      return {
        ...state,
        editDailyTaskStatus: false,
        dailyTaskFetchStatus: true,
        editDailyTaskSuccess: messages,
        progressPercentage: 0,
        processTrigger: false,
        editDailyTaskstatusvalue: false,
      };
    case API_EDIT_DAILY_TASK_FAIL:
      return {
        ...state,
        progressPercentage: 0,
        processTrigger: false,
      };
    case API_EDIT_ADDITIONAL_TASK:
      return { ...state };
    case API_EDIT_ADDITIONAL_TASK_SUCCESS:
      let update = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          update = data.message;
        }
      }
      console.log("approvedUpdate", update);

      return { ...state, editadditionalTaskSuccess: update };
    case API_EDIT_ADDITIONAL_TASK_FAIL:
      return { ...state, editadditionalTaskSuccess: "Edit Failed" };

    case API_EDIT_MOVE_TO_TASK:
      return { ...state };
    case API_EDIT_MOVE_TO_TASK_SUCCESS:
      let moveToTask = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          moveToTask = data.message;
        }
      }
      console.log("approvedUpdate", moveToTask);

      return { ...state, editadditionalTaskSuccess: moveToTask };
    case API_EDIT_MOVE_TO_TASK_FAIL:
      return { ...state, editadditionalTaskSuccess: "Edit Failed" };

    case API_EDIT_RECOMMENDED_TASK:
      return { ...state, editRecommendedTaskSuccess: "" };
    case API_EDIT_RECOMMENDED_TASK_SUCCESS:
      let Update = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          Update = data.message;
        }
      }
      return { ...state, editRecommendedTaskSuccess: Update };
    case API_EDIT_RECOMMENDED_TASK_FAIL:
      return { ...state };

    case API_GET_REASSIGN_DAILY_TASK_BY_ID:
      return { ...state, id: action.payload.id };
    case API_GET_REASSIGN_DAILY_TASK_BY_ID_SUCCESS:
      let tasksss = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task_list" in data && data.task_list) {
          tasksss = data.task_list;
        }
      }
      return {
        ...state,
        selectedDailyTaskData: tasksss,
        reassignDailyTaskStatus: true,
        dailyTaskFetchStatus: false,
      };
    case API_GET_REASSIGN_DAILY_TASK_BY_ID_FAIL:
      return { ...state };
    case API_REASSIGN_DAILY_TASK:
      return { ...state };
    case API_REASSIGN_DAILY_TASK_SUCCESS:
      return {
        ...state,
        reassignDailyTaskStatus: false,
        dailyTaskFetchStatus: true,
      };
    case API_REASSIGN_DAILY_TASK_FAIL:
      return { ...state };
    case UPDATE_EDIT_DAILY_TASK_DLG_STATUS:
      return {
        ...state,
        editDailyTaskStatus: action.payload,
        copyDailyTaskStatus: action.payload,
        makeScheduleDailyTaskStatus: action.payload,
      };
    case UPDATE_REASSIGN_DAILY_TASK_DLG_STATUS:
      return { ...state, reassignDailyTaskStatus: action.payload };
    case API_DELETE_DAILY_TASK:
      return { ...state, deleteDailyTaskSuccess: "" };
    case API_DELETE_DAILY_TASK_SUCCESS:
      let msg = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          msg = data.message;
        }
      }
      return {
        ...state,
        deleteDailyTaskStatus: false,
        dailyTaskFetchStatus: true,
        deleteDailyTaskSuccess: msg,
      };
    case API_DELETE_DAILY_TASK_FAIL:
      return { ...state };
    case UPDATE_DELETE_DAILY_TASK_DLG_STATUS:
      return { ...state, deleteDailyTaskStatus: action.payload };
    case FILTER_DAILY_TASK:
      const filterTask: IDailyTaskTable[] = state.filteredDailyTaskData.filter(
        (obj: any) => {
          return (
            obj.title.toLowerCase().includes(action.payload) ||
            obj.branch_name.toLowerCase().includes(action.payload) ||
            obj.first_name.toLowerCase().includes(action.payload) ||
            obj.last_name.toLowerCase().includes(action.payload)
          );
        }
      );
      return { ...state, filteredDailyTaskData: filterTask };
    case API_GET_COMMENT_BY_ID:
      return { ...state, id: action.payload.id };
    case API_GET_COMMENT_BY_ID_SUCCESS:
      let comments = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "comments" in data && data.comments) {
          comments = data.comments;
        }
      }
      return {
        ...state,
        commentsData: comments,
        editCommentStatus: true,
        commentFetchStatus: false,
      };
    case API_GET_COMMENT_BY_ID_FAIL:
      return { ...state };
    case UPDATE_COMMENT:
      return { ...state };
    case API_ADD_COMMENT:
      return { ...state, addCommentStatus: true };
    case API_ADD_COMMENT_SUCCESS:
      return { ...state, addCommentStatus: false };
    case API_ADD_COMMENT_FAIL:
    case API_UPDATE_COMMENT:
      return { ...state };
    case API_UPDATE_COMMENT_SUCCESS:
      return { ...state, addCommentStatus: false };
    case API_UPDATE_COMMENT_FAIL:
      return { ...state };
    case UPDATE_ADD_COMMENT:
      return { ...state, addCommentStatus: action.payload };
    case API_EDIT_COMMENT:
      return { ...state };
    case API_EDIT_COMMENT_SUCCESS:
      return { ...state, editCommentStatus: false, commentFetchStatus: true };
    case API_EDIT_COMMENT_FAIL:
      return { ...state };
    case UPDATE_EDIT_COMMENT:
      return { ...state, editCommentStatus: action.payload };

    case UPDATE_DELETE_COMMENT:
      return { ...state, deleteCommentStatus: action.payload };
    case EDIT_MODAL_DAILY_TASK:
      return { ...state, editDailyTaskstatusvalue: action.payload };
    case API_GET_SEND_QA_EMAIL_DAILY_TASK_BY_ID:
      return { ...state, id: action.payload.id };
    case API_GET_SEND_QA_EMAIL_DAILY_TASK_BY_ID_SUCCESS:
      let emailTasks = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task_list" in data && data.task_list) {
          emailTasks = data.task_list;
        }
      }
      return {
        ...state,
        selectedDailyTaskData: emailTasks,
        sendqaemailDailyTaskStatus: true,
        dailyTaskFetchStatus: false,
      };
    case API_GET_SEND_QA_EMAIL_DAILY_TASK_BY_ID_FAIL:
      return { ...state };
    case API_SEND_QA_EMAIL_DAILY_TASK:
      return { ...state };
    case API_SEND_QA_EMAIL_DAILY_TASK_SUCCESS:
      return {
        ...state,
        sendqaemailDailyTaskStatus: false,
        dailyTaskFetchStatus: true,
      };
    case API_SEND_QA_EMAIL_DAILY_TASK_FAIL:
      return { ...state };
    case UPDATE_SEND_QA_EMAIL_DAILY_TASK_DLG_STATUS:
      return { ...state, sendqaemailDailyTaskStatus: action.payload };
    case FILTER_TASK_TYPE:
      return { ...state, filterTaskTypeById: action.payload };
    case FILTER_TASK_PRIORITY:
      return { ...state, filterTaskPriorityById: action.payload };
    case FILTER_TASK_STATUS:
      return { ...state, filterTaskStatusById: action.payload };
    case FILTER_SPECIAL_REQUEST_TASK_STATUS:
      return { ...state, filterSpecialRequestTaskStatusById: action.payload };
    case FILTER_TASK_DUE_TYPE:
      return { ...state, filterTaskDueTypeByValue: action.payload };
    case FILTER_TASK_DATE:
      return { ...state, filterTaskDateTypeValue: action.payload };
    case FILTER_TASK_CLIENT:
      const result: string[] = [];
      for (const item of action.payload) {
        const values: string[] = item.split(",");
        const filteredValues: string[] = values.filter(
          (value) => value !== "all"
        );
        const modifiedItem: string = filteredValues.join(",");
        result.push(modifiedItem);
      }
      return { ...state, filterTaskClientById: action.payload };
    case FILTER_TASK_BRANCH:
      return { ...state, filterTaskBranchById: action.payload };
    default:
      return state;
  }
};

// update task
export function updateDailyTasks(data: any) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_DAILY_TASKS,
      payload: data,
    });
  };
}

// get task by id
export function getDailyTaskById(id: string, type: string = "edit") {
  console.log("getDailyTaskById", getDailyTaskById);
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_DAILY_TASK_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `task/${id}` : `task`,
        },
        id,
        type: type,
      },
    });
  };
}

// get task by id
export function getAdditionalTaskById(id: string, type: string = "edit") {
  console.log("getAdditionalTaskById", id);
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ADDITIONAL_TASK_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `task/${id}` : `task`,
        },
        id,
        type: type,
      },
    });
  };
}

export function getRecommendedTaskById(id: string, type: string = "edit") {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_RECOMMENDED_TASK_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `task/${id}` : `task`,
        },
        id,
        type: type,
      },
    });
  };
}

// get task by id
export function getDailyTaskByIdForView(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_DAILY_TASK_BY_ID_FOR_VIEW,
      payload: {
        request: {
          method: "get",
          url: id ? `task/${id}` : `task`,
        },
        id,
      },
    });
  };
}

// get supervisor , supervisor-manager users  by id
export function getSendmailUsers(id: string) {
  console.log("getSendMailUsers", id);
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_USERS_DETAILS_FOR_MAIL,
      payload: {
        request: {
          method: "get",
          url: id ? `users/user-details/${id}` : `users/user-details/`,
        },
        id,
      },
    });
  };
}

// get task by id for duplicate
export function getDailyTaskByIdForDuplicate(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_DAILY_TASK_BY_ID_FOR_COPY,
      payload: {
        request: {
          method: "get",
          url: id ? `task/${id}` : `task`,
        },
        id,
      },
    });
  };
}

// get task by id for duplicate
export function getDailyTaskByIdForMakeSchedule(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_DAILY_TASK_BY_ID_FOR_MAKESCHEDULE,
      payload: {
        request: {
          method: "get",
          url: id ? `task/${id}` : `task`,
        },
        id,
      },
    });
  };
}

// get all tasks for table
export function getAllDailyTasks(queryStr: string) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_GET_ALL_DAILY_TASKS,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `task?${queryStr}` : `task?`,
        },
      },
    });
  };
}

// get additional task only
export function getAllAdditionalTasks(queryStr: string) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_GET_ALL_ADDITIONAL_TASK,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `task?${queryStr}` : `task?`,
        },
      },
    });
  };
}

// get additional task only
export function SendMailModalApprovedTask(val: string) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_GET_SENDMAIL_APPROVED_TASK,
      payload: {
        val,
      },
    });
  };
}

// get all data with no limit
export function getAllListDailyTasks(queryStr: string) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_GET_ALL_DAILY_TASKS_WITHOUT_LIMIT,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `task?${queryStr}` : `task?`,
        },
      },
    });
  };
}

export function getAllApprovedTasks(queryStr: string) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_GET_ALL_APPROVED_TASKS,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `task?${queryStr}` : `task?`,
        },
      },
    });
  };
}

export function getAllRecommendedTasks(queryStr: string) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_GET_ALL_RECOMMENDED_TASKS,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `task?${queryStr}` : `task?`,
        },
      },
    });
  };
}

export function getAllRecommendedCleanerTasks(queryStr: string) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_GET_ALL_RECOMMENDED_TASKS,
      payload: {
        request: {
          method: "get",
          url: queryStr
            ? `task/cleaner-recommended-task?${queryStr}`
            : `task/cleaner-recommended-task?`,
        },
      },
    });
  };
}

// add task
export function addDailyTask(payload: FormData) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_ADD_DAILY_TASK,
      payload: {
        request: {
          method: "post",
          url: `task`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_ADD_DAILY_TASK_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

// copy task
export function copyDailyTask(payload: FormData) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_ADD_DAILY_TASK,
      payload: {
        request: {
          method: "post",
          url: `task/copy`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_ADD_DAILY_TASK_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

// make schedule from task
export function makeScheduleDailyTask(payload: FormData) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_ADD_DAILY_TASK,
      payload: {
        request: {
          method: "post",
          url: `sp-schedules`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_ADD_DAILY_TASK_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

export function updateAddDailyTask(
  status: boolean,
  special_request: boolean = false
) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_ADD_DAILY_TASK_DLG_STATUS,
      payload: { status: status, special_request: special_request },
    });
  };
}

// edit task
export function editDailyTask(payload: IEditDailyTask, id: number) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_EDIT_DAILY_TASK,
      payload: {
        request: {
          method: "put",
          url: `task/${id}`,
          data: payload,
        },
      },
    });
  };
}

export function editAdditionalTask(payload: IEditDailyTask, id: number) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_EDIT_ADDITIONAL_TASK,
      payload: {
        request: {
          method: "put",
          url: `task/${id}`,
          data: payload,
        },
      },
    });
  };
}

export function moveToTask(payload: IEditDailyTask, id: number) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_EDIT_MOVE_TO_TASK,
      payload: {
        request: {
          method: "put",
          url: `task/move-to-task/${id}`,
          data: payload,
        },
      },
    });
  };
}

export function editRecommendedTask(payload: IEditDailyTask, id: number) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_EDIT_RECOMMENDED_TASK,
      payload: {
        request: {
          method: "put",
          url: `task/${id}`,
          data: payload,
        },
      },
    });
  };
}

// update edit employee
export function updateEditDailyTaskDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_EDIT_DAILY_TASK_DLG_STATUS,
      payload: status,
    });
  };
}
export function updateReassignDailyTaskDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_REASSIGN_DAILY_TASK_DLG_STATUS,
      payload: status,
    });
  };
}
export function getReassignDailyTaskById(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_REASSIGN_DAILY_TASK_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `task/${id}` : `task`,
        },
        id,
      },
    });
  };
}

// reassign task
export function reassignDailyTask(payload: any, id: number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_REASSIGN_DAILY_TASK,
      payload: {
        request: {
          method: "put",
          url: `task/cleaner/reassign/${id}`,
          data: payload,
        },
      },
    });
  };
}
// Fetch Task after add, edit, delete
export function updateDailyTaskFetchStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_DAILY_TASK_FETCH_STATUS,
      payload: status,
    });
  };
}

// delete task
export function deleteDailyTask(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_DELETE_DAILY_TASK,
      payload: {
        request: {
          method: "delete",
          url: `task/${id}`,
        },
      },
    });
  };
}

// update delete task
export function updateDeleteDailyTaskDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_DELETE_DAILY_TASK_DLG_STATUS,
      payload: status,
    });
  };
}

// Filter value
export function filterDailyTask(filterVal: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: FILTER_DAILY_TASK,
      payload: filterVal,
    });
  };
}

// get task HISTORY by id
export function getTaskHistoryById(id: string) {
  return async (dispatch: Dispatch) => {
    console.log("getTaskHistoryById", id);

    dispatch({
      type: API_GET_TASK_HISTORY_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `task/task-history/${id}` : `task/task-history/`,
        },
        id,
      },
    });
  };
}

// UPDATE_TASK_HISTORY_DLG_STATUS
export function updateViewDailyTaskDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_TASK_HISTORY_DLG_STATUS,
      payload: status,
    });
  };
}

export function getCommentsById(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_COMMENT_BY_ID,
      payload: {
        request: {
          method: "",
          url: id ? `comments${id}` : `comments`,
        },
        id,
      },
    });
  };
}

// Add Comment
export function addCommentstask(payload: FormData) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_ADD_COMMENT,
      payload: {
        request: {
          method: "post",
          url: `comments`,
          data: payload,
        },
      },
    });
  };
}

// Update Comment
export function updateComments(payload: FormData, id: number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_UPDATE_COMMENT,
      payload: {
        request: {
          method: "put",
          url: `comments/${id}`,
          data: payload,
        },
      },
    });
  };
}

// Add Comment
export function addFeedbacks(payload: FormData) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_ADD_COMMENT,
      payload: {
        request: {
          method: "post",
          url: `comments/qa`,
          data: payload,
        },
      },
    });
  };
}

export function updateAddComments(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_ADD_COMMENT,
      payload: status,
    });
  };
}

export function editComments(payload: any, id: number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_EDIT_COMMENT,
      payload: {
        request: {
          method: "put",
          url: `comments/${id}`,
          data: payload,
        },
      },
    });
  };
}

export function updateEditComments(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_EDIT_COMMENT,
      payload: status,
    });
  };
}

export function updateCommentFetchStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_COMMENT,
      payload: status,
    });
  };
}

export function updateDeleteComment(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_DELETE_COMMENT,
      payload: status,
    });
  };
}

export function EditModalDailyTask(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: EDIT_MODAL_DAILY_TASK,
      payload: status,
    });
  };
}

// send email
export function updateSendQAEmailDailyTaskDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_SEND_QA_EMAIL_DAILY_TASK_DLG_STATUS,
      payload: status,
    });
  };
}

export function getSendQAEmailDailyTaskById(id: string) {
  console.log("getSendQAEmailDailyTaskById", id);
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_SEND_QA_EMAIL_DAILY_TASK_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `task/${id}` : `task`,
        },
        id,
      },
    });
  };
}

export function updateSendQAEmailTaskDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_SEND_QA_EMAIL_DAILY_TASK_DLG_STATUS,
      payload: status,
    });
  };
}

// sendQAEmail task
export function sendQAEmailDailyTask(payload: any) {
  console.log("sendQAEmailDailyTask", payload);
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_SEND_QA_EMAIL_DAILY_TASK,
      payload: {
        request: {
          method: "post",
          url: `task/send-to-mail`,
          data: payload,
        },
      },
    });
  };
}
export function handleFilterTaskType(status: number) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_TASK_TYPE,
      payload: status,
    });
  };
}
export function handleFilterTaskPriority(status: number) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_TASK_PRIORITY,
      payload: status,
    });
  };
}
export function handleFilterTaskStatus(status: number) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_TASK_STATUS,
      payload: status,
    });
  };
}
export function handleFilterSpecialTaskStatus(status: number) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_SPECIAL_REQUEST_TASK_STATUS,
      payload: status,
    });
  };
}
export function handleFilterTaskDueType(status: string) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_TASK_DUE_TYPE,
      payload: status,
    });
  };
}

export function handleFilterTaskDate(status: string) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_TASK_DATE,
      payload: status,
    });
  };
}
export function handleFilterTaskClient(id: any) {
  id = id !== "all" ? id.join() : id;

  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_TASK_CLIENT,
      payload: id,
    });
  };
}
export function handleFilterTaskBranch(id: any) {
  id = id !== "all" ? id.join() : id;
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_TASK_BRANCH,
      payload: id,
    });
  };
}
// get all tasks for table
export function getAllFollowUpDailyTasks(queryStr: string) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_GET_ALL_FOLLOWUP_DAILY_TASKS,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `task?${queryStr}` : `task?`,
        },
      },
    });
  };
}

// get all tasks for table
export function getAllCompletedDailyTasks(queryStr: string) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_GET_ALL_COMPLETE_DAILY_TASKS,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `task?${queryStr}` : `task?`,
        },
      },
    });
  };
}
