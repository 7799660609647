import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Dispatch, bindActionCreators } from "redux";
import dayjs, { Dayjs } from "dayjs";
import { getAllReports, updateSignInOutDateTime } from "../../shared/Reducers";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CloseBtnSVG } from "../../assets/images/ImagesV2";
import Constants from "../../shared/Constants";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import DeleteReport from "./DeleteReport";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { IReportCardProps } from "../../types/report.type";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import { RootState } from "../../index";
import Stack from "@mui/material/Stack";
import { TableDeleteSVG } from "../../assets/images/ImagesV2";
import TextField from "@mui/material/TextField";
import { UpdateBtnSVG } from "../../assets/images/ImagesV2";
import { connect } from "react-redux";
import { deleteReport } from "../../shared/Reducers";
import haversine from "haversine-distance";
import moment from "moment";
import { sign } from "crypto";

// date picker

type ReportState = {
  editDateTime: any;
  displayDelete: any;
  currentReportId: any;
  
};
class ReportCard extends React.Component<IReportCardProps, ReportState> {
  constructor(props: IReportCardProps) {
    super(props);
    this.state = {
      editDateTime: "",
      displayDelete: false,
      currentReportId: "",
    };
  }

  signinoutTimeChange = (time: any, classname: string) => {
    this.setState({ editDateTime: time });

    var allTimeText = document.getElementsByClassName("TimeText");
    var allTimeInput = document.getElementsByClassName("TimeInput");
    if (allTimeInput !== null && allTimeText !== null) {
      for (let i = 0; i < allTimeText.length; i++) {
        allTimeText[i].classList.remove("d-none");
      }
      for (let i = 0; i < allTimeInput.length; i++) {
        allTimeInput[i].classList.add("d-none");
      }
    }

    var TimeText = document.getElementById("TimeText-" + classname);
    var TimeInput = document.getElementById("TimeInput-" + classname);
    if (TimeInput !== null && TimeText !== null) {
      TimeText.classList.add("d-none");
      TimeInput.classList.remove("d-none");
    }
  };

  handleChangeDateTime = async (newValue: Dayjs | null) => {
    this.setState({ editDateTime: newValue });
  };

  closesigninoutTimeUpdate = () => {
    var allTimeText = document.getElementsByClassName("TimeText");
    var allTimeInput = document.getElementsByClassName("TimeInput");
    if (allTimeInput !== null && allTimeText !== null) {
      for (let i = 0; i < allTimeText.length; i++) {
        allTimeText[i].classList.remove("d-none");
      }
      for (let i = 0; i < allTimeInput.length; i++) {
        allTimeInput[i].classList.add("d-none");
      }
    }
  };

  signinoutTimeUpdate = async (id: any, type: string) => {
    let dateTime: any = moment(new Date(this.state.editDateTime)).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    let data: any;
    if (type === "in") {
      data = {
        sign_in_date_time: dateTime,
      };
    } else {
      data = {
        sign_out_date_time: dateTime,
      };
    }
    this.props.updateSignInOutDateTime(data, id);

    let Offset: number = 0;
    let paginationActive = document.querySelector(
      ".pagination li.active a"
    ) as HTMLInputElement;
    if (paginationActive !== null) {
      let page: string | null = paginationActive.textContent;
      if (page !== null) {
        let pageNum: number = parseInt(page);
        console.log(pageNum);
        Offset = (pageNum - 1) * Constants.PER_PAGE;
        console.log(Offset);
        setTimeout(() => {
          try {
            const query = `branch=${
              this.props.latestState.filteredBranch
            }&search=${
              this.props.latestState.searchTerm
            }&sort=&order=&offset=${Offset}&limit=${
              Constants.PER_PAGE
            }&cleaner=all&from=${
              this.props.latestState.reportFromTo.filterReportFromDate ===
              undefined
                ? ""
                : this.props.latestState.reportFromTo.filterReportFromDate
            }&to=${
              this.props.latestState.reportFromTo.filterReportToDate ===
              undefined
                ? ""
                : this.props.latestState.reportFromTo.filterReportToDate
            }`;

            this.props.getAllReports(query);
          } catch (error) {
            console.log(error);
          }
          var allTimeText = document.getElementsByClassName("TimeText");
          var allTimeInput = document.getElementsByClassName("TimeInput");
          if (allTimeInput !== null && allTimeText !== null) {
            for (let i = 0; i < allTimeText.length; i++) {
              allTimeText[i].classList.remove("d-none");
            }
            for (let i = 0; i < allTimeInput.length; i++) {
              allTimeInput[i].classList.add("d-none");
            }
          }
        }, 500);
      }
    }
  };

  handleDelete = (id: string) => {
    console.log(true, "true");

    this.setState({ displayDelete: true, currentReportId: id });
  };
  handleDeleteClose = (status: boolean) => {
    console.log('prere');
    
    this.props.deleteReport(this.state.currentReportId);
    this.setState({ displayDelete: false, currentReportId: "" });
  };

  render(): React.ReactNode {
    const {
      id,
      first_name,
      last_name,
      branch_name,
      reason,
      sign_in_date_time,
      sign_out_date_time,
      branch_lat,
      branch_lng,
      sign_out_lat,
      sign_out_lng,
      client_first_name,
      client_last_name
    } = this.props.task;

    if (
      branch_lat !== "0" &&
      branch_lng !== "0" &&
      sign_out_lat !== "0" &&
      sign_out_lng !== "0" &&
      branch_lat !== null &&
      branch_lng !== null &&
      sign_out_lat !== null &&
      sign_out_lng !== null
    ) {
      //First point in your haversine calculation
      var point1: any = { lat: branch_lat, lng: branch_lng };

      //Second point in your haversine calculation
      var point2: any = { lat: sign_out_lat, lng: sign_out_lng };

      var haversine_m = haversine(point1, point2); //Results in meters (default)
      var haversine_km: any = haversine_m / 1000; //Results in kilometers
      haversine_km = haversine_km.toFixed(2) + " KM";
    } else {
      var haversine_km: any = "";
    }

    return (
      <tr>
        <td>{`${first_name} ${last_name}`}</td>
        <td>{client_first_name +' '+ client_last_name}</td>
        <td>{branch_name}</td>
        <td>
          <p
            onClick={() =>
              this.signinoutTimeChange(sign_in_date_time, "EditInTime-" + id)
            }
            id={"TimeText-EditInTime-" + id}
            className={"TimeText m-0"}
          >
            {sign_in_date_time != null
              ? moment(sign_in_date_time, "YYYY-MM-DD HH:mm:ss").format(
                  "DD/MM/YYYY hh:mm A"
                )
              : ""}
          </p>
          <div
            id={"TimeInput-EditInTime-" + id}
            className={"d-flex align-items-center d-none TimeInput"}
          >
            <div className="mr-6px">
              <LocalizationProvider dateAdapter={AdapterDayjs} id="startDate">
                <Stack spacing={3}>
                  <DateTimePicker
                    inputFormat="DD/MM/YYYY hh:mm A"
                    value={this.state.editDateTime}
                    onChange={this.handleChangeDateTime}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </div>
            <div className="mr-6px">
              <button
                className="btn btn-primary update-btn-svg"
                onClick={() => this.signinoutTimeUpdate(id, "in")}
              >
                <UpdateBtnSVG />
              </button>
            </div>
            <div className="mr-16px">
              <button
                className="btn btn-primary close-btn-svg"
                onClick={() => this.closesigninoutTimeUpdate()}
              >
                <CloseBtnSVG />
              </button>
            </div>
          </div>
        </td>
        <td>
          <p
            onClick={() =>
              this.signinoutTimeChange(sign_out_date_time, "EditOutTime-" + id)
            }
            id={"TimeText-EditOutTime-" + id}
            className={"TimeText"}
          >
            {sign_out_date_time != null
              ? moment(sign_out_date_time, "YYYY-MM-DD HH:mm:ss").format(
                  "DD/MM/YYYY hh:mm A"
                )
              : ""}
          </p>
          <div
            id={"TimeInput-EditOutTime-" + id}
            className={"d-flex align-items-center d-none TimeInput"}
          >
            <div className="mr-6px">
              <LocalizationProvider dateAdapter={AdapterDayjs} id="startDate">
                <Stack spacing={3}>
                  <DateTimePicker
                    inputFormat="DD/MM/YYYY hh:mm A"
                    value={this.state.editDateTime}
                    onChange={this.handleChangeDateTime}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </div>
            <div className="mr-6px">
              <button
                className="btn btn-primary update-btn-svg"
                onClick={() => this.signinoutTimeUpdate(id, "out")}
              >
                <UpdateBtnSVG />
              </button>
            </div>
            <div className="mr-16px">
              <button
                className="btn btn-primary close-btn-svg"
                onClick={() => this.closesigninoutTimeUpdate()}
              >
                <CloseBtnSVG />
              </button>
            </div>
          </div>
        </td>
        {/* <td onClick={() => this.signinoutTimeChange(sign_out_date_time, "out")}>{sign_out_date_time != null ? moment(sign_out_date_time,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY hh:mm A') : ""}</td> */}
        <td>{reason}</td>
        {(Constants.LOGIN_USER_TYPE === Constants.ADMINUTINT ||
          Constants.LOGIN_USER_TYPE === Constants.SUPERVISORUTINT) && (
          <td>{haversine_km}</td>
        )}
        <td>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{"Delete"}</Tooltip>}
          >
            <Button
              className="action-btn"
              onClick={() => this.handleDelete(id)}
            >
              <TableDeleteSVG />
            </Button>
          </OverlayTrigger>
          <DeleteReport
            displayDelete={this.state.displayDelete}
            handleDeleteClose={this.handleDeleteClose}
          />
        </td>
      </tr>
    );
  }
}
const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateSignInOutDateTime: bindActionCreators(
      updateSignInOutDateTime,
      dispatch
    ),
    getAllReports: bindActionCreators(getAllReports, dispatch),
    deleteReport: bindActionCreators(deleteReport, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportCard);
