import React, { Component } from "react";

import AllReportCard from "./AllReportCard";
import { AllReportTableProps } from "../../types/allreport";

export default class AllReportTable extends Component<AllReportTableProps> {
  render() {
    return (
      <tbody>
        {this.props.data.map((task_Report: any, index: any) => {
          return (
            <AllReportCard
              key={index + task_Report.branch_name}
              task={task_Report}
            />
          );
        })}
      </tbody>
    );
  }
}
