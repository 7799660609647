import * as Yup from "yup";

import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { IEditBranchProps, IEditBranchState } from "../../types/branch.type";
import { Modal, ProgressBar } from "react-bootstrap";
import { PaperClipSVG, TrashSVG } from "../../assets/images/ImagesV2";
import {
  deleteAttachementId,
  editBranch,
  listAllBranch,
  listAllClient,
  updateBranchFetchStatus,
  updateEditBranchDlgStatus,
} from "../../shared/Reducers";

import { AttachmentDownloadSVG } from "../../assets/images/ImagesSVG";
import Button from "@material-ui/core/Button";
import Constants from "../../shared/Constants";
import { ICleaner } from "../../shared/ActionTypes";
import React from "react";
import { RootState } from "../../index";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import docImage from "../../assets/images/google-docs.png";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";

const reactSelectCusStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#fff",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    },
  }),
};

class EditBranch extends React.Component<IEditBranchProps, IEditBranchState> {
  constructor(props: IEditBranchProps) {
    super(props);
    this.state = {
      attachmentFiles: [],
      attachmentUrls: [],
      deleteAttachementId: [],
      client_id: this.props.data?.client_id,
      editClient: {
        value: this.props.data?.client_id,
        label: this.props.data?.first_name + " " + this.props.data?.last_name,
      },
    };
  }

  componentDidMount(): void {
    this.props.listAllClient();
    if (this.props.data) {
      this.setState({
        attachmentUrls: this.props.data.attachments.map((obj: any) => ({
          url: obj.s3_url,
          name: obj.file_name,
          id: obj.id,
          type: "download",
          download_url: obj.s3_url,
          file_type: obj.type,
        })),
      });
      console.log(this.state.attachmentUrls);
      
    }
    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.add("d-none");
      button.removeAttribute("disabled");
    }
  }

  convertToBlob = async (
    url: string,
    name: string,
    defaultType = "image/png"
  ) => {
    const response = await fetch(url);
    const data = await response.blob();
    const file = new File([data], name, {
      type: data.type || defaultType,
    });
    console.log(file);
    this.setState((prev) => ({
      attachmentFiles: [...prev.attachmentFiles, file],
    }));
  };

  handleEditBranchClient = async (id: number) => {
    this.setState({ client_id: id });
    console.log(id, "id");

    await this.props.listAllBranch(id);
  };

  // Edit Branch with Existing Form Value
  handleEditBranch = async (formValue: any) => {
    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.remove("d-none");
      button.setAttribute("disabled", "");
    }
    let data = new FormData();
    data.append("name", formValue.name);
    data.append("status", formValue.status);
    data.append("lat", formValue.lat);
    data.append("lng", formValue.lng);
    data.append("address", formValue.address);
    for (let index = 0; index < this.state.attachmentFiles.length; index++) {
      data.append("attachments", this.state.attachmentFiles[index]);
    }
    data.append("client", this.state.client_id);
    if (this.props.id) {
      await this.state.deleteAttachementId.map(async (id: any) => {
        this.props.deleteAttachementId(id);
      });
      this.props.editBranch(data, this.props.id);
    }
  };

  downloadFile = (url: string, name = "image.png") => {
    this.downloadResource(url, name);
  };

  forceDownload = (blob: any, filename: String) => {
    const a: any = document.createElement("a");
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  downloadResource = (url: any, filename: any) => {
    if (url) {
      console.log("filename", filename);

      if (!filename) filename = url.split("\\").pop().split("/").pop();
      fetch(url, {
        headers: new Headers({
          Origin: window.location.origin,
        }),
        mode: "cors",
      })
        .then((response) => response.blob())
        .then((blob) => {
          let blobUrl = window.URL.createObjectURL(blob);
          this.forceDownload(blobUrl, filename);
        })
        .catch((e) => console.error(e));
    }
  };

  handleImageChange = async (e: any) => {
    let files = [];
    let urls = [];
    files = [...e.target.files];

    let fileurls: any = [];
    await files.map(async (file: any) => {
      fileurls.push({
        url: URL.createObjectURL(file),
        file_type: file.type,
      });
    });
    urls = [...fileurls];
    this.setState({
      attachmentFiles: [...this.state.attachmentFiles, ...files],
    });
    this.setState({ attachmentUrls: [...this.state.attachmentUrls, ...urls] });
  };

  //   handleDeleteImage = (index: number) => {
  //     const files = this.state.attachmentFiles;
  //     const urls = this.state.attachmentUrls;
  //     files.splice(index, 1);
  //     urls.splice(index, 1);
  //     this.setState({ attachmentFiles: files });
  //     this.setState({ attachmentUrls: urls });
  //   };

  handleDeleteImage = (index: number) => {
    const urls = this.state.attachmentUrls;

    confirmAlert({
      title: "Confirm deletion",
      message: "Are you sure you want to delete this Attachment ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            // check exist file
            if (urls[index].id !== undefined && urls[index].id !== "0") {
              let deleteAttachementId = this.state.deleteAttachementId;
              deleteAttachementId.push(urls[index].id);
              this.setState({ deleteAttachementId: deleteAttachementId });

              urls.splice(index, 1);
              this.setState({ attachmentUrls: urls });
            } else {
              urls.splice(index, 1);
              this.setState({ attachmentUrls: urls });
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  // Form Validation
  validationSchema = () => {
    const clientId = this.props.clientList.map((client: ICleaner) => client.id);
    const long =
      /^(\+|-)?(?:180(?:(?:\.0{1,})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,})?))$/;
    const lat =
      /^(\+|-)?(?:90(?:(?:\.0{1,})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,})?))$/;
      const alphanumericRegex = /^(?=.*[a-zA-Z0-9 ])[a-zA-Z0-9 ]+$/;
      const addressRegex = /[a-zA-Z0-9]/;
      return Yup.object().shape({
        name: Yup.string()
          .required("Enter a valid location")
          .matches(
            alphanumericRegex,
            "Branch name should contain only alphanumeric characters"
          )
          .test(
            "len",
            "Enter Your Branch",
            (val: any) => val && val.toString().length >= 3
          ),
        lat: Yup.string()
          .required("Enter Latitude")
          .test("validate-latitude", "Enter Valid Latitude", (value: any) => {
            return value <= 90 && value >= -90 && lat.test(value);
          }),
        lng: Yup.string()
          .required("Enter Longitude")
          .test("validate-longitude", "Enter Valid Longitude", (value: any) => {
            return value <= 180 && value >= -180 && long.test(value);
          }),
        address: Yup.string()
          .required("Enter Address")
          .test(
            "validate-address",
            "Address should contain at least one alphanumeric character",
            (value: any) => addressRegex.test(value)
          ),
      client: Yup.string()
        .oneOf([...clientId], "Invalid Client")
        .required()
        .nullable(),
      status: Yup.string(),
      image: Yup.mixed(),
    });
  };
  render(): React.ReactNode {
    const initialValues = this.props.data;
    return (
      <div>
        <Formik
          initialValues={{
            name: this.props.data ? this.props.data.name : "",
            lat: this.props.data ? this.props.data.lat : "",
            lng: this.props.data ? this.props.data.lng : "",
            address: this.props.data ? this.props.data.address : "",
            status: this.props.data ? this.props.data.status : "",
            client: this.props.data ? this.props.data.client_id : "",
            image: this.props.data ? this.props.data.image : [],
          }}
          enableReinitialize={true}
          onSubmit={this.handleEditBranch}
          validationSchema={this.validationSchema}
        >
          <Form>
            <div className="row">
              {/* <div className="col-md-6 mb-2">
                <label className="form-label">
                  Client<span className="text-project">*</span>
                </label>
                <Field
                  as="select"
                  className="form-select dropdown-field"
                  name="client"
                >
                  {this.props.clientList.map((client: ICleaner) => (
                    <option
                      value={client.id.toString()}
                      key={client.id}
                    >{`${client.first_name} ${client.last_name}`}</option>
                  ))}
                </Field>
                <ErrorMessage
                  name="client"
                  component={"div"}
                  className="cus-alert"
                />
              </div> */}
              <div className="mb-2 col-md-6">
                <label className="form-label">Client</label>
                <Select
                  styles={reactSelectCusStyles}
                  placeholder="Select Client"
                  isMulti={false}
                  value={this.state.editClient}
                  options={this.props.clientList.map((client: any) => ({
                    value: client.id,
                    label: client.first_name + " " + client.last_name,
                  }))}
                  onChange={(e: any) => {
                    console.log(e, "e");

                    this.handleEditBranchClient(e.value);
                    this.setState({ editClient: e });
                    console.log(e, "setstate of edit client id");
                  }}
                />
              </div>
              <div className="col-md-6 mb-2">
                <label className="form-label">
                  Branch<span className="text-project">*</span>
                </label>
                <Field
                  name="name"
                  type={"text"}
                  className="form-control dropdown-field"
                />
                <ErrorMessage
                  name="name"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="col-md-6 mb-2">
                <label className="form-label">
                  Latitude<span className="text-project">*</span>
                </label>
                <Field
                  name="lat"
                  type="number"
                  className="form-control dropdown-field"
                  step="any"
                  input
                />
                <ErrorMessage
                  name="lat"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="col-md-6 mb-2">
                <label className="form-label">
                  Longitude<span className="text-project">*</span>
                </label>
                <Field
                  name="lng"
                  type="number"
                  className="form-control dropdown-field"
                  step="any"
                />
                <ErrorMessage
                  name="lng"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="col-md-6 mb-2">
                <label className="form-label">
                  Address<span className="text-project">*</span>
                </label>
                <Field
                  name="address"
                  type="text"
                  className="form-control dropdown-field"
                />
                <ErrorMessage
                  name="address"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="col-md-6 mb-2">
                <label className="form-label">Is Active</label>
                <Field
                  as="select"
                  className="form-select dropdown-field"
                  name="status"
                >
                  <option value={1}>Enable</option>
                  <option value={0}>Disable</option>
                </Field>
                <ErrorMessage
                  name="status"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">Attach</label>
                <div className="attachment-btn dropdown-field">
                  <input
                    type="file"
                    multiple
                    accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                    style={{ display: "none" }}
                    onChange={this.handleImageChange}
                    id="contained-button-file"
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="default"
                      component="span"
                    >
                      <PaperClipSVG /> Add attachment
                    </Button>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              {this.state.attachmentUrls.length > 0 ? (
                <div className="col-md-12 mb-2">
                  <label className="form-label">Attachments</label>
                </div>
              ) : (
                <></>
              )}
              <div className="row">
                <div className="d-flex row">
                  {this.state.attachmentUrls &&
                    this.state.attachmentUrls.map((obj: any, index: number) => (
                      <div className="img-card mr-16px mb-3">
                        <span className="delete">
                          <a onClick={() => this.handleDeleteImage(index)}>
                            <TrashSVG />
                          </a>
                        </span>
                        {obj.type === "download" && (
                          <span className="download">
                            <a
                              href="javascript:void(0)"
                              onClick={() =>
                                this.downloadFile(
                                  obj.download_url,
                                  obj.file_name
                                )
                              }
                            >
                              <AttachmentDownloadSVG />
                            </a>
                          </span>
                        )}

                        <div className="img-preview">
                          <img
                            className="img-thumb-perview"
                            src={
                              obj.file_type === "application/pdf"
                                ? pdfImage
                                : obj.file_type === "video/mp4" ||
                                  obj.file_type === "video/webm"
                                ? videoImage
                                : obj.file_type === "image/png" ||
                                  obj.file_type === "image/jpeg" ||
                                  obj.file_type === "image/jpg"
                                ? obj.url
                                : docImage
                            }
                            alt="attach"
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-md-12 cus-progress">
                <Modal
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={this.props.processTrigger}
                >
                  <Modal.Body className="p-0">
                    <ProgressBar
                      now={this.props.progressPercentage}
                      label={
                        this.props.progressPercentage === 100
                          ? `Processing your request...`
                          : `Branch Updating....${this.props.progressPercentage}%`
                      }
                    />
                  </Modal.Body>
                </Modal>
                <div className="d-flex">
                  <div className="mr-16px">
                    <button
                      type="submit"
                      id="create-button"
                      className="btn btn-primary create-button"
                    >
                      Save{" "}
                      <span
                        id="spinner-border"
                        className="spinner-border spinner-border-sm d-none"
                      ></span>
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn cancel-button"
                      onClick={() =>
                        this.props.updateEditBranchDlgStatus(false)
                      }
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  clientList: state.tasktemplate.clientList,
  processTrigger: state.branch.processTrigger,
  editBranchSuccess: state.branch.editBranchSuccess,
  progressPercentage: state.branch.progressPercentage,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    editBranch: bindActionCreators(editBranch, dispatch),
    deleteAttachementId: bindActionCreators(deleteAttachementId, dispatch),
    updateEditBranchDlgStatus: bindActionCreators(
      updateEditBranchDlgStatus,
      dispatch
    ),
    updateBranchFetchStatus: bindActionCreators(
      updateBranchFetchStatus,
      dispatch
    ),
    listAllClient: bindActionCreators(listAllClient, dispatch),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBranch);
