import React, { useRef, useState, useEffect } from "react";
import { fabric } from "fabric";
import { Modal, Button } from "react-bootstrap";
import ClosIcon from "../assets/images/delete icon.svg";
import EditIcon from "../assets/images/edit icon.svg";

function ImageDrawing(props) {
  const canvasRef = useRef(null);
  const [canvas, setCanvas] = useState(null);

  const handleImageUpload = (url) => {
    const image = new Image();
    image.src = url;
    image.onload = () => {
      const newCanvas = new fabric.Canvas(canvasRef.current);
      newCanvas.setWidth(image.width); // Set canvas width to match image width
      newCanvas.setHeight(image.height); // Set canvas height to match image height

      const imgInstance = new fabric.Image(image, {
        left: 0,
        top: 0,
      });

      newCanvas.add(imgInstance);
      setCanvas(newCanvas);
    };
  };

  useEffect(() => {
    if (props) {
      fetch(props.previewUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onload = () => {
            handleImageUpload(reader.result);

            // setImageData(reader.result);
          };
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          console.error("Error fetching image:", error);
        });
      // console.log(props.attachFiles[props.clickingImage].name, "click image");
    }
  }, [props]);
  // const handleImageUpload = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       const image = new Image();
  //       image.src = e.target.result;
  //       image.onload = () => {
  //         const newCanvas = new fabric.Canvas(canvasRef.current);
  //         newCanvas.setWidth(image.width); // Set canvas width to match image width
  //         newCanvas.setHeight(image.height); // Set canvas height to match image height

  //         const imgInstance = new fabric.Image(image, {
  //           left: 0,
  //           top: 0,
  //         });

  //         newCanvas.add(imgInstance);
  //         setCanvas(newCanvas);
  //       };
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const enablePencilDraw = () => {
    if (canvas) {
      canvas.isDrawingMode = true;

      // Access the free drawing brush
      const freeDrawingBrush = canvas.freeDrawingBrush;

      // Set the stroke color and width
      freeDrawingBrush.color = "red"; // Set the pencil color
      freeDrawingBrush.width = 15; // Set the pencil width
    }
  };

  const enableEraser = () => {
    if (canvas) {
      canvas.isDrawingMode = false;
      canvas.clear();
      props.attachUrlArray[props.clickingImage] = {
        file_type: "image/jpeg",
        url: props.previewUrl,
      };
      props.handlePreviewClose(false, props.attachUrlArray);
    }
  };

  const handleFinishDrawing = () => {
    console.log("finish function work");
    if (canvas) {
      canvas.isDrawingMode = false;

      // Render the canvas
      canvas.renderAll();
      console.log(canvas, "canvas log");

      // const canvasID = document.getElementById("yourCanvas"); // Replace 'yourCanvas' with your canvas element's ID

      // canvasID.toBlob((blob) => {
      //   if (blob) {
      //     // Use the 'blob' as needed, for example, creating a File object
      //     const file = new File([blob], "image.png", { type: "image/png" });

      //     // Continue with further processing or handling the image
      //   }
      // }, "image/png");
      const dataURL = canvas.toDataURL("image/png");
      console.log(dataURL, "url marked");
      if (props.page === "add") {
        // Convert the canvas content to a data URL

        // Convert the data URL to a Blob
        const byteCharacters = atob(dataURL.split(",")[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "image/png" });

        // Create a Blob URL from the Blob object
        const blobUrl = URL.createObjectURL(blob);
        console.log(blobUrl, "blob url here");
        props.attachUrlArray[props.clickingImage] = {
          file_type: "image/jpeg",
          url: blobUrl,
        };

        // Fetch the Blob data from the Blob URL
        fetch(blobUrl)
          .then((response) => response.blob())
          .then((blob) => {
            // Create a File object from the Blob
            let fileName;
            try {
              fileName = props.attachFiles[props.clickingImage].name; // Provide a name for the file
            } catch (error) {
              fileName = "";
            }
            const file = new File([blob], fileName, { type: blob.type });

            // Now 'file' is a File object
            console.log(file);
            props.attachFiles[props.clickingImage] = file;
          })
          .catch((error) => {
            console.error(error);
          });

        props.handlePreviewClose(
          false,
          props.attachUrlArray,
          props.attachFiles
        );
      } else {
        const byteCharacters = atob(dataURL.split(",")[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "image/png" });

        // Create an object in the desired format
        const drawingFile = new File([blob], "test.jpeg", {
          type: blob.type,
        });

        // Create an array to store the object
        const fileurls = {
          url: URL.createObjectURL(blob),
          file: drawingFile,
          id: "0",
          file_type: blob.type,
          download_url: URL.createObjectURL(blob),
        };
        props.deleteProps(props.attachUrlArray[props.clickingImage].id);
        props.attachUrlArray[props.clickingImage] = fileurls;

        props.handlePreviewClose(
          false,
          props.attachUrlArray,
          props.attachFiles
        );
      }
    }
  };

  return (
    <div>
      <Modal
        className="attachment-preview-box edit-comments"
        size="lg"
        show={props.displayPreview}
        onHide={() => enableEraser()}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-4">
          {/* <input type="file" accept="image/*" onChange={handleImageUpload} /> */}
          <div className="d-flex">
            <canvas
              id="yourCanvas"
              width={"250"}
              height={"250"}
              ref={canvasRef}
            />

            <button
              type="submit"
              id="draw-button"
              className="btn"
              onClick={enablePencilDraw}
            >
              <img style={{ height: "15px" }} alt="edit-icon" src={EditIcon} />
              Draw
            </button>
          </div>
          {/* <button onClick={enablePencilDraw}>
            <img style={{ height: "15px" }} alt="edit-icon" src={EditIcon} />
            Pencil Draw
          </button> */}
          <div className="d-flex mt-2">
            <div className="mr-16px">
              <button
                type="submit"
                id="create-button"
                className="btn btn-primary create-button"
                onClick={handleFinishDrawing}
              >
                Change
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn cancel-button"
                onClick={enableEraser}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ImageDrawing;
