import React from "react";
import { RootState } from '../../index';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IQuotedTaskTableProps } from "../../types/quotedTask.type";
import QuotedTaskCard from "./QuotedTaskCard";
import EditQuotedTask from "./EditQuotedTask";
import { Modal, Offcanvas } from "react-bootstrap";
import { deleteQuotedTask, getAllQuotedTasks, getQuotedTaskById, updateEditQuotedTaskDlgStatus, updateViewQuotedTask, viewQuotedTaskById } from "../../shared/Reducers";
import ViewQuotedTask from "./ViewQuotedTask";

class QuotedTaskTable extends React.Component<IQuotedTaskTableProps> {
    // constructor(props: IQuotedTaskTableProps ){
    //     super(props);
    // }

    handleDeleteQuotedTask = async (id: string) => {
        await this.props.deleteQuotedTask(id);
        // console.log(id);
        // const query = `search=&sort=&order=&offset=0&limit=100&client=all&branch=all`
        // this.props.getAllQuotedTasks(query);
    }

    handleEditBranch = async (id:string) => {
        try {
            this.props.getQuotedTaskById(id);
        } catch (error) {
            console.log(error);
        }
    }

    handleViewQuotedTask = async (id:string) => {
        try {
            this.props.viewQuotedTaskById(id);
        } catch (error) {
            console.log(error);
        }
    }

    render(): React.ReactNode {
        return (
            <tbody>
                {
                    this.props.data.map((quotedTasks, index) =>
                        <QuotedTaskCard quotedTasks={quotedTasks}
                            key={index + quotedTasks.id}
                            handleDeleteQuotedTask={this.handleDeleteQuotedTask}
                            handleEditQuotedTask={this.handleEditBranch}
                            handleViewQuotedTask={this.handleViewQuotedTask}
                            deleteQuotedTaskStatus={false} />)
                }
                <Offcanvas placement="end" show={this.props.editQuotedTaskStatus} onHide={() => this.props.updateEditQuotedTaskDlgStatus(false)}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title><span className="offc-title">Edit QuotedTask</span></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <EditQuotedTask id={this.props.id} data={this.props.selectedQuotedTaskData} />
                    </Offcanvas.Body>
                </Offcanvas>
                <Modal
                size="lg"
                show={this.props.viewQuotedTaskStatus}
                onHide={() => this.props.updateViewQuotedTask(false)}
                aria-labelledby="example-modal-sizes-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            <h3 className="m-0">User Details</h3>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                        <ViewQuotedTask data={this.props.viewQuotedTaskData}/>
                    </Modal.Body>
                </Modal>
            </tbody>
        )
    }
}
const mapStateToProps = (state: RootState) => ({
    editQuotedTaskStatus: state.quotedTask.editQuotedTaskStatus,
    selectedQuotedTaskData: state.quotedTask.selectedQuotedTaskData,
    id: state.quotedTask.id,
    viewQuotedTaskStatus: state.quotedTask.viewQuotedTaskStatus,
    viewQuotedTaskData: state.quotedTask.viewQuotedTaskData
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateEditQuotedTaskDlgStatus: bindActionCreators(updateEditQuotedTaskDlgStatus, dispatch),
        getQuotedTaskById: bindActionCreators(getQuotedTaskById, dispatch),
        getAllQuotedTasks: bindActionCreators(getAllQuotedTasks, dispatch),
        deleteQuotedTask: bindActionCreators(deleteQuotedTask, dispatch),
        viewQuotedTaskById: bindActionCreators(viewQuotedTaskById, dispatch),
        updateViewQuotedTask: bindActionCreators (updateViewQuotedTask, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(QuotedTaskTable);