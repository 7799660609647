import React from "react";
import { RootState } from '../../index';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IShiftTableProps } from "../../types/shift.type";
import ShiftCard from "./ShiftCard";
import EditShift from "./EditShift";
import { Modal, Offcanvas } from "react-bootstrap";
import { deleteShift, getAllShifts, getShiftById, updateEditShiftDlgStatus, updateViewShift, viewShiftById } from "../../shared/Reducers";
import ViewShift from "./ViewShift";

class ShiftTable extends React.Component<IShiftTableProps> {
    // constructor(props: IShiftTableProps ){
    //     super(props);
    // }

    handleDeleteShift = async (id: string) => {
        await this.props.deleteShift(id);
        console.log(id);
        const query = `search=&sort=&order=&offset=0&limit=10&client=all&branch=all`
        this.props.getAllShifts(query);
    }

    handleEditBranch = async (id:string) => {
        try {
            this.props.getShiftById(id);
        } catch (error) {
            console.log(error);
        }
    }

    handleViewShift = async (id:string) => {
        try {
            this.props.viewShiftById(id);
        } catch (error) {
            console.log(error);
        }
    }

    render(): React.ReactNode {
        console.log(this.props.data);
        
        return (
            <tbody>
                {
                    this.props.data.map((shifts, index) =>
                        <ShiftCard shifts={shifts}
                            key={index + shifts.start_date}
                            handleDeleteShift={this.handleDeleteShift}
                            handleEditShift={this.handleEditBranch}
                            handleViewShift={this.handleViewShift}
                            deleteShiftStatus={false} />)
                }
                <Offcanvas placement="end" show={this.props.editShiftStatus} onHide={() => this.props.updateEditShiftDlgStatus(false)}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title><span className="offc-title">Edit Shift</span></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <EditShift id={this.props.id} data={this.props.selectedShiftData} />
                    </Offcanvas.Body>
                </Offcanvas>
                <Modal
                size="lg"
                show={this.props.viewShiftStatus}
                onHide={() => this.props.updateViewShift(false)}
                aria-labelledby="example-modal-sizes-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            <h3 className="m-0">User Details</h3>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                        <ViewShift data={this.props.viewShiftData}/>
                    </Modal.Body>
                </Modal>
            </tbody>
        )
    }
}
const mapStateToProps = (state: RootState) => ({
    editShiftStatus: state.shift.editShiftStatus,
    selectedShiftData: state.shift.selectedShiftData,
    id: state.shift.id,
    viewShiftStatus: state.shift.viewShiftStatus,
    viewShiftData: state.shift.viewShiftData
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateEditShiftDlgStatus: bindActionCreators(updateEditShiftDlgStatus, dispatch),
        getShiftById: bindActionCreators(getShiftById, dispatch),
        getAllShifts: bindActionCreators(getAllShifts, dispatch),
        deleteShift: bindActionCreators(deleteShift, dispatch),
        viewShiftById: bindActionCreators(viewShiftById, dispatch),
        updateViewShift: bindActionCreators (updateViewShift, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ShiftTable);