import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { updateEditQuotedTaskDlgStatus } from "../../shared/Reducers";
import DeleteQuotedTask from "./DeleteQuotedTask";
import {
  IQuotedTaskCardProps,
  IQuotedTaskCardState,
} from "../../types/quotedTask.type";
import moment from "moment";
import {
  TableDeleteSVG,
  TableEditSVG,
  TableViewSVG,
} from "../../assets/images/ImagesV2";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

class QuotedTaskCard extends React.Component<
  IQuotedTaskCardProps,
  IQuotedTaskCardState
> {
  constructor(props: IQuotedTaskCardProps) {
    super(props);
    this.state = {
      show: false,
      displayDelete: false,
      currentQuotedTaskId: "",
      editShow: false,
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  // Delete quotedTask based on ID
  handleDelete = (id: string) => {
    this.setState({ displayDelete: true, currentQuotedTaskId: id });
  };

  // delete popup close
  handleDeleteClose = (status: boolean) => {
    this.setState({ displayDelete: false, currentQuotedTaskId: "" });
    if (status) {
      this.props.handleDeleteQuotedTask(this.state.currentQuotedTaskId);
    }
  };

  render(): React.ReactNode {
    const {
      branch_name,
      created_at,
      friday,
      id,
      modified_at,
      monday,
      saturday,
      status,
      sunday,
      task,
      task_type,
      thursday,
      tuesday,
      wednesday,
      total_time,
      first_name,
      last_name,
    } = this.props.quotedTasks;
    let totalTime: number = 0;
    if (task_type === 1) {
      totalTime = total_time;
    } else {
      let sundayStr: string = sunday;
      let mondayStr: string = monday;
      let tuesdayStr: string = tuesday;
      let wednesdayStr: string = wednesday;
      let thursdayStr: string = thursday;
      let fridayStr: string = friday;
      let saturdayStr: string = saturday;

      let sundayInt: number = parseInt(sundayStr);
      let mondayInt: number = parseInt(mondayStr);
      let tuesdayInt: number = parseInt(tuesdayStr);
      let wednesdayInt: number = parseInt(wednesdayStr);
      let thursdayInt: number = parseInt(thursdayStr);
      let fridayInt: number = parseInt(fridayStr);
      let saturdayInt: number = parseInt(saturdayStr);

      sundayInt = sundayInt > 0 ? sundayInt : 0;
      mondayInt = mondayInt > 0 ? mondayInt : 0;
      tuesdayInt = tuesdayInt > 0 ? tuesdayInt : 0;
      wednesdayInt = wednesdayInt > 0 ? wednesdayInt : 0;
      thursdayInt = thursdayInt > 0 ? thursdayInt : 0;
      fridayInt = fridayInt > 0 ? fridayInt : 0;
      saturdayInt = saturdayInt > 0 ? saturdayInt : 0;

      totalTime =
        sundayInt +
        mondayInt +
        tuesdayInt +
        wednesdayInt +
        thursdayInt +
        fridayInt +
        saturdayInt;
    }

    return (
      <tr>
        <td>{task}</td>
        <td>{first_name + " " + last_name}</td>
        <td>{branch_name}</td>
        <td>{task_type === 0 ? "Additional Task" : "Fixed task"}</td>
        <td className="total-time">{totalTime}</td>
        <td>
          <div className="btn-group">
            <span className="mr-20px d-none">
              <a
                href="javascript:void(0)"
                onClick={() => this.props.handleViewQuotedTask(id)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"View"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableViewSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
            <span className="mr-20px">
              <a
                href="javascript:void(0)"
                onClick={() => this.props.handleEditQuotedTask(id)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"Edit"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableEditSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
            <span>
              <a
                href="javascript:void(0)"
                onClick={() => this.handleDelete(id)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"Delete"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableDeleteSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
            <DeleteQuotedTask
              displayDelete={this.state.displayDelete}
              deletedName={task}
              handleDeleteClose={this.handleDeleteClose}
            />
          </div>
        </td>
      </tr>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  editQuotedTaskStatus: state.quotedTask.editQuotedTaskStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateEditQuotedTaskDlgStatus: bindActionCreators(
      updateEditQuotedTaskDlgStatus,
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(QuotedTaskCard);
