import {
  API_ADD_USERMANAGEMENT,
  API_ADD_USERMANAGEMENT_FAIL,
  API_ADD_USERMANAGEMENT_PROGRESS,
  API_ADD_USERMANAGEMENT_SUCCESS,
  API_DELETE_USERMANAGEMENT,
  API_DELETE_USERMANAGEMENT_FAIL,
  API_DELETE_USERMANAGEMENT_SUCCESS,
  API_EDIT_USERMANAGEMENT,
  API_EDIT_USERMANAGEMENT_FAIL,
  API_EDIT_USERMANAGEMENT_PROGRESS,
  API_EDIT_USERMANAGEMENT_SUCCESS,
  API_EMAIL_VERIFY_USERMANAGEMENT,
  API_EMAIL_VERIFY_USERMANAGEMENT_FAIL,
  API_EMAIL_VERIFY_USERMANAGEMENT_SUCCESS,
  API_GET_ALLUSERROLEPERMISSION,
  API_GET_ALLUSERROLEPERMISSION_FAIL,
  API_GET_ALLUSERROLEPERMISSION_SUCCESS,
  API_GET_ALL_USERMANAGEMENTS,
  API_GET_ALL_USERMANAGEMENTS_FAIL,
  API_GET_ALL_USERMANAGEMENTS_SUCCESS,
  API_GET_PERMISSION,
  API_GET_PERMISSION_FAIL,
  API_GET_PERMISSION_SUCCESS,
  API_GET_USERMANAGEMENTS,
  API_GET_USERMANAGEMENTS_FAIL,
  API_GET_USERMANAGEMENTS_SUCCESS,
  API_GET_USERMANAGEMENT_BY_ID,
  API_GET_USERMANAGEMENT_BY_ID_FAIL,
  API_GET_USERMANAGEMENT_BY_ID_SUCCESS,
  API_GET_branch,
  API_UPDATE_PERMISSION,
  API_UPDATE_PERMISSION_FAIL,
  API_UPDATE_PERMISSION_SUCCESS,
  API_UPDATE_PROFILE,
  API_UPDATE_PROFILE_EDIT,
  API_UPDATE_PROFILE_EDIT_FAIL,
  API_UPDATE_PROFILE_EDIT_SUCCESS,
  API_UPDATE_PROFILE_FAIL,
  API_UPDATE_PROFILE_SUCCESS,
  API_UPDATE_USERTYPES,
  API_UPDATE_USERTYPES_FAIL,
  API_UPDATE_USERTYPES_SUCCESS,
  API_VIEW_USERMANAGEMENT,
  API_VIEW_USERMANAGEMENT_FAIL,
  API_VIEW_USERMANAGEMENT_SUCCESS,
  FILTER_ROLE_ID,
  FILTER_STATUS,
  FILTER_USERMANAGEMENT,
  IAddUsermanagement,
  IEditUsermanagement,
  UPDATE_DELETE_USERMANAGEMENT_DLG_STATUS,
  UPDATE_EDIT_USERMANAGEMENT_DLG_STATUS,
  UPDATE_EMAIL_VERIFY_USERMANAGEMENT_DLG_STATUS,
  UPDATE_USERMANAGEMENT,
  UPDATE_USERMANAGEMENT_DLG_STATUS,
  UPDATE_USERMANAGEMENT_FETCH_STATUS,
  UPDATE_VIEW_PERMISSION,
  UPDATE_VIEW_USERMANAGEMENT,
  USERMANAGEMENT_SELECTION,
  UsermanagementState,
  API_GET_USER_BY_ID,
  API_GET_USER_BY_ID_SUCCESS,
  API_GET_USER_BY_ID_FAIL,
  API_GET_CERTIFICATES_DROP_DOWN,
  API_GET_CERTIFICATES_DROP_DOWN_SUCCESS,
  API_GET_CERTIFICATES_DROP_DOWN_FAIL
} from "../ActionTypes";
import { AnyAction, Dispatch } from "redux";

import { AppDispatch } from "../..";
import { IUsermanagement } from "../../types/userManagement.type";
import { TUsermanagementTable } from "../../types/user.type";

const initialState: UsermanagementState = {
  branchList: [],
  usermanagements: [],
  branchdropdown: [],
  selectedUsermanagement: null,
  addUsermanagementStatus: false,
  addUsermanagementSuccess: "",
  addUsermanagementError: "",
  editUsermanagementStatus: false,
  editUsermanagementError: "",
  editUsermanagementSuccess: "",
  deleteUsermanagementStatus: false,
  emailVerifyUsermanagementStatus: false,
  deleteUsermanagementSuccess: "",
  emailVerifyUsermanagementSuccess: "",
  usermanagementFetchStatus: false,
  usermanagementData: null,
  currentUsermanagementId: "",
  data: [],
  filteredData: [],
  total_count: 0,
  processTrigger: false,
  progressPercentage: 0,
  viewUsermanagementData: [],
  viewUsermanagementStatus: false,
  filterUserRoleById: "all",
  updateProfileState: "",
  updateWrongProfile: "",
  viewPermission: false,
  UserPermissionPages: [],
  update_permission: "",
  permissionUpdatestatus: false,
  alluserrolepermission: [],
  updateProfileEditState: "",
  updateWrongProfileEdit: "",
  updateUserTypesState: "",
  updateWrongUserTypes: "",
  currentUserUsertype: [],
  get_User: null,
  cleanerCertificates: []
};

export const UsermanagementReducer = (
  state: UsermanagementState = initialState,
  action: AnyAction
): UsermanagementState => {
  switch (action.type) {
    case USERMANAGEMENT_SELECTION:
      return { ...state, selectedUsermanagement: action.payload };
    case UPDATE_USERMANAGEMENT:
      console.log("usermanagerments", action.payload.data);

      return { ...state, usermanagements: action.payload };
    case API_GET_ALL_USERMANAGEMENTS:
      return { ...state };
    case API_GET_ALL_USERMANAGEMENTS_SUCCESS:
      let allUsermanagementData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "users" in data && data.users) {
          allUsermanagementData = data.users;
        }
      }
      let total_count = 0;
      if (action.payload) {
        const { data } = action.payload;
        if (data && "total_count" in data && data.total_count) {
          total_count = data.total_count;
        }
      }
      return {
        ...state,
        total_count: total_count,
        data: allUsermanagementData,
        editUsermanagementStatus: false,
        filteredData: allUsermanagementData,
      };
    case API_GET_ALL_USERMANAGEMENTS_FAIL:
      return { ...state };
    case API_GET_USERMANAGEMENT_BY_ID:
      return { ...state, currentUsermanagementId: action.payload.id };
    case API_GET_USERMANAGEMENT_BY_ID_SUCCESS:
      let usersId = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "user" in data && data.user) {
          usersId = data.user;
        }
      }
      return {
        ...state,
        usermanagements: usersId,
        editUsermanagementStatus: true,
        usermanagementFetchStatus: false,
        currentUserUsertype: usersId?.permission?.task_status,
      };
    case API_GET_USERMANAGEMENT_BY_ID_FAIL:
      return { ...state };
    case API_GET_USER_BY_ID:
      return { ...state };
    case API_GET_USER_BY_ID_SUCCESS:
      let user = null;
      if (action.payload) {
        const { data } = action.payload;
        if (data && "getUser" in data && data.getUser) {
          user = data.getUser;
        }
      }
      console.log("reducer data", user);

      const newState = { ...state, get_User: user };
      console.log("updatedState", newState.get_User);
      return newState;
    case API_GET_USER_BY_ID_FAIL:
      console.log("FailById");
      return { ...state };
    case API_VIEW_USERMANAGEMENT:
      return { ...state, currentUsermanagementId: action.payload.id };
    case API_VIEW_USERMANAGEMENT_SUCCESS:
      let usersView = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "user" in data && data.user) {
          usersView = data.user;
        }
      }
      return {
        ...state,
        viewUsermanagementData: usersView,
        viewUsermanagementStatus: true,
        usermanagementFetchStatus: false,
      };
    case API_VIEW_USERMANAGEMENT_FAIL:
      return { ...state };
    case UPDATE_VIEW_USERMANAGEMENT:
      return { ...state, viewUsermanagementStatus: action.payload };
    case UPDATE_VIEW_PERMISSION:
      return { ...state, viewPermission: action.payload };
    case API_GET_USERMANAGEMENTS:
      return { ...state };
    case API_GET_USERMANAGEMENTS_SUCCESS:
      let users = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "users" in data && data.users) {
          users = data.users;
        }
      }
      return { ...state, usermanagements: users };
    case API_GET_USERMANAGEMENTS_FAIL:
      return { ...state };
    case API_ADD_USERMANAGEMENT:
      let values: boolean = false;
      if (action.payload.request.data.FormData === undefined) {
        values = true;
      }
      return {
        ...state,
        addUsermanagementSuccess: "",
        addUsermanagementError: "",
        processTrigger: values,
      };
    case API_ADD_USERMANAGEMENT_SUCCESS:
      let message = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          message = data.message;
        }
      }
      return {
        ...state,
        addUsermanagementStatus:
          message === "Successfully created" ? false : true,
        addUsermanagementError: "",
        addUsermanagementSuccess: message,
        processTrigger: false,
        progressPercentage: 0,
      };
    case API_ADD_USERMANAGEMENT_PROGRESS:
      return { ...state, progressPercentage: action.payload };
    case API_ADD_USERMANAGEMENT_FAIL:
      let err = "";
      if (
        "error" in action &&
        action.error &&
        "response" in action.error &&
        action.error.response &&
        "data" in action.error.response &&
        action.error.response.data &&
        "message" in action.error.response.data
      ) {
        err = action.error.response.data.message;
      }
      return {
        ...state,
        addUsermanagementError: err,
        processTrigger: false,
        progressPercentage: 0,
      };
    case UPDATE_USERMANAGEMENT_DLG_STATUS:
      return { ...state, addUsermanagementStatus: action.payload };
    case API_EDIT_USERMANAGEMENT:
      let value: boolean = false;
      if (action.payload.request.data.FormData === undefined) {
        value = true;
      }
      return {
        ...state,
        editUsermanagementSuccess: "",
        editUsermanagementError: "",
        processTrigger: value,
      };
    case API_EDIT_USERMANAGEMENT_SUCCESS:
      let messages = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          messages = data.message;
        }
      }
      return {
        ...state,
        editUsermanagementStatus: false,
        usermanagementFetchStatus: true,
        editUsermanagementSuccess: messages,
        processTrigger: false,
        progressPercentage: 0,
      };
    case API_EDIT_USERMANAGEMENT_FAIL:
      let error = "";
      if (
        "error" in action &&
        action.error &&
        "response" in action.error &&
        action.error.response &&
        "data" in action.error.response &&
        action.error.response.data &&
        "message" in action.error.response.data
      ) {
        error = action.error.response.data.message;
      }
      return {
        ...state,
        editUsermanagementError: error,
        processTrigger: false,
        progressPercentage: 0,
      };
    case API_EDIT_USERMANAGEMENT_PROGRESS:
      return { ...state, progressPercentage: action.payload };
    case UPDATE_EDIT_USERMANAGEMENT_DLG_STATUS:
      return { ...state, editUsermanagementStatus: action.payload };
    case UPDATE_USERMANAGEMENT_FETCH_STATUS:
      return { ...state, usermanagementFetchStatus: action.payload };
    case API_DELETE_USERMANAGEMENT:
      return { ...state, deleteUsermanagementSuccess: "" };
    case API_UPDATE_PROFILE:
      return { ...state };
    case API_UPDATE_PROFILE_SUCCESS:
      return { ...state, updateWrongProfile: action.payload };
    case API_UPDATE_PROFILE_FAIL:
      return { ...state, updateWrongProfile: action.error };
    case API_UPDATE_PROFILE_EDIT:
      return { ...state };
    case API_UPDATE_PROFILE_EDIT_SUCCESS:
      return { ...state, updateProfileEditState: action.payload };
    case API_UPDATE_PROFILE_EDIT_FAIL:
      return { ...state, updateWrongProfileEdit: action };

    case API_UPDATE_USERTYPES:
      return { ...state };
    case API_UPDATE_USERTYPES_SUCCESS:
      return {
        ...state,
        updateUserTypesState: action.payload.data.message,
        updateWrongUserTypes: "",
      };
    case API_UPDATE_USERTYPES_FAIL:
      return { ...state, updateWrongUserTypes: action };

    case API_DELETE_USERMANAGEMENT_SUCCESS:
      let msg = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          msg = data.message;
        }
      }
      return {
        ...state,
        deleteUsermanagementSuccess: msg,
        deleteUsermanagementStatus: false,
        usermanagementFetchStatus: true,
      };
    case API_DELETE_USERMANAGEMENT_FAIL:
      return { ...state };
    case API_GET_CERTIFICATES_DROP_DOWN:
        return { ...state };
    case API_GET_CERTIFICATES_DROP_DOWN_SUCCESS:
      let certificates_list: any = [];
      if (action.payload) {
        const { data } = action.payload;
        console.log(data, "certificate");
        
        if (data && "certificates" in data && data.certificates) {
          certificates_list = data.certificates;
          console.log(certificates_list);
          
        }
      }
      return { ...state, cleanerCertificates: certificates_list };
    case API_GET_CERTIFICATES_DROP_DOWN_FAIL:
        return { ...state };
    case UPDATE_DELETE_USERMANAGEMENT_DLG_STATUS:
      return { ...state, deleteUsermanagementStatus: action.payload };
    case FILTER_USERMANAGEMENT:
      const filterEmp: TUsermanagementTable[] = state.filteredData.filter(
        (obj) => {
          return (
            obj.first_name.toLowerCase().includes(action.payload) ||
            obj.last_name.toLowerCase().includes(action.payload) ||
            obj.email.toLowerCase().includes(action.payload) ||
            obj.phone.includes(action.payload)
          );
        }
      );
      return { ...state, filteredData: filterEmp };
    case API_EMAIL_VERIFY_USERMANAGEMENT:
      return { ...state, emailVerifyUsermanagementSuccess: "" };
    case API_EMAIL_VERIFY_USERMANAGEMENT_SUCCESS:
      let verifymsg = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          verifymsg = data.message;
        }
      }
      return {
        ...state,
        emailVerifyUsermanagementSuccess: verifymsg,
        emailVerifyUsermanagementStatus: false,
        usermanagementFetchStatus: true,
      };
    case API_EMAIL_VERIFY_USERMANAGEMENT_FAIL:
      return { ...state };
    case UPDATE_EMAIL_VERIFY_USERMANAGEMENT_DLG_STATUS:
      return { ...state, emailVerifyUsermanagementStatus: action.payload };
    case FILTER_STATUS:
      const filterMapping =
        action.payload === "in" ? 1 : action.payload === "out" ? 0 : "all";
      const filteredStatus = state.data.filter((obj) => {
        return filterMapping === "all"
          ? obj
          : obj.last_activity_status === filterMapping;
      });
      return { ...state, filteredData: filteredStatus };
    case FILTER_ROLE_ID:
      console.log(action.payload, "mine");

      return { ...state, filterUserRoleById: action.payload };

    case API_GET_PERMISSION:
      return { ...state };
    case API_GET_PERMISSION_SUCCESS:
      let permission = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "permission" in data && data.permission) {
          permission = data.permission;
        }
      }
      return {
        ...state,
        UserPermissionPages: permission,
        viewPermission: true,
      };
    case API_GET_PERMISSION_FAIL:
      return { ...state };
    case API_UPDATE_PERMISSION:
      return { ...state, permissionUpdatestatus: false };
    case API_UPDATE_PERMISSION_SUCCESS:
      return { ...state, permissionUpdatestatus: true };
    case API_UPDATE_PERMISSION_FAIL:
      return { ...state };
    case API_GET_ALLUSERROLEPERMISSION:
      return { ...state };
    case API_GET_ALLUSERROLEPERMISSION_SUCCESS:
      return { ...state, alluserrolepermission: action.payload.data };
    case API_GET_ALLUSERROLEPERMISSION_FAIL:
      return { ...state };
    default:
      return state;
  }
};

export function selectUsermanagement(id: number) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: USERMANAGEMENT_SELECTION,
      payload: id,
    });
  };
}

export function updateUsermanagements(data: IUsermanagement[]) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: UPDATE_USERMANAGEMENT,
      payload: data,
    });
  };
}

// get usermanagement by id
export function getUsermanagementById(id: string) {
  console.log("getUsermanagement", id);
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_USERMANAGEMENT_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `users/${id}` : `users`,
        },
        id,
      },
    });
  };
}

//getUserById based on users table only
export function getUserById(id: string) {
  console.log("getUserById", id);
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_USER_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `users/user/${id}` : `users/user`,
        },
      },
    });
  };
}

// view usermanagement by id
export function viewUsermanagementById(id: string) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_VIEW_USERMANAGEMENT,
      payload: {
        request: {
          method: "get",
          url: id ? `users/${id}` : `user`,
        },
        id,
      },
    });
  };
}

// update view user management
export function updateViewUserManagement(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_VIEW_USERMANAGEMENT,
      payload: status,
    });
  };
}
// open close view permission page
export function hendleviewPermission(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_VIEW_PERMISSION,
      payload: status,
    });
  };
}

// get all usermanagements for table
export function getAllUsermanagements(queryStr: string) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_ALL_USERMANAGEMENTS,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `users?${queryStr}` : `users`,
        },
      },
    });
  };
}

// get usermanagement for activity search
export function getAllUsermanagement(queryStr: string) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_USERMANAGEMENTS,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `users?${queryStr}` : `users`,
        },
      },
    });
  };
}

// get branch for activity search
export function getAllBranch(id: number) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_branch,
      payload: {
        request: {
          method: "get",
          url: `branch/drop-down-list/${id}`,
        },
      },
    });
  };
}

// get branch for activity search
export function getAllCertificates() {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_CERTIFICATES_DROP_DOWN,
      payload: {
        request: {
          method: "get",
          url: `certificates/dropdown-list`,
        },
      },
    });
  };
}

// add Usermanagement
export function addUsermanagement(payload: IAddUsermanagement) {
  return async (dispatch: AppDispatch) => {
    await dispatch({
      type: API_ADD_USERMANAGEMENT,
      payload: {
        request: {
          method: "post",
          url: `users`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_ADD_USERMANAGEMENT_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

// update add usermanagement
export function updateUsermanagementStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: UPDATE_USERMANAGEMENT_DLG_STATUS,
      payload: status,
    });
  };
}

// edit usermanagement
export function editUsermanagement(payload: IEditUsermanagement, id: number) {
  return async (dispatch: AppDispatch) => {
    await dispatch({
      type: API_EDIT_USERMANAGEMENT,
      payload: {
        request: {
          method: "put",
          url: `users/${id}`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_EDIT_USERMANAGEMENT_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

// Update edit usermanagement
export function updateEditUsermanagementDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: UPDATE_EDIT_USERMANAGEMENT_DLG_STATUS,
      payload: status,
    });
  };
}

// Fetch Usermanagement after add, edit and delete usermanagement
export function updateUsermanagementFetchStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: UPDATE_USERMANAGEMENT_FETCH_STATUS,
      payload: status,
    });
  };
}

// delete usermanagement
export function deleteUsermanagement(id: number) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_DELETE_USERMANAGEMENT,
      payload: {
        request: {
          method: "delete",
          url: `users/${id}`,
        },
      },
    });
  };
}

// verify user email status
export function emailVerifyUsermanagement(id: number, status: number) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_EMAIL_VERIFY_USERMANAGEMENT,
      payload: {
        request: {
          method: "get",
          url: `users/email-verify/${id}/${status}`,
        },
      },
    });
  };
}

// update delete usermanagement
export function updateDeleteUsermanagementDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: UPDATE_DELETE_USERMANAGEMENT_DLG_STATUS,
      payload: status,
    });
  };
}

// filter usermanagement table
export function filterUsermanagement(filterVal: string) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_USERMANAGEMENT,
      payload: filterVal,
    });
  };
}

export function filterStatus(status: string) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_STATUS,
      payload: status,
    });
  };
}

export function handleFilterRole(roleId: number) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_ROLE_ID,
      payload: roleId,
    });
  };
}

// update profile
export function updateProfile(data: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_UPDATE_PROFILE,
      payload: {
        request: {
          method: "PUT",
          url: `users/update-password`,
          data: data,
        },
      },
    });
  };
}
// update profile Edit
export function updateProfileEdit(data: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_UPDATE_PROFILE_EDIT,
      payload: {
        request: {
          method: "PUT",
          url: `users/profile`,
          data: data,
        },
      },
    });
  };
}

// update usertypes edit
export function updateUserTypesEdit(data: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_UPDATE_USERTYPES,
      payload: {
        request: {
          method: "POST",
          url: `user_types`,
          data: data,
        },
      },
    });
  };
}

// UserPermissionPages
export function getUserPermission(type: string, id: string) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_PERMISSION,
      payload: {
        request: {
          method: "GET",
          url: `permission/user_permission/${type}/${id}`,
        },
      },
    });
  };
}

// all user role permission
export function getAllUserRolePermission(type: string, id: string) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_ALLUSERROLEPERMISSION,
      payload: {
        request: {
          method: "GET",
          url: `permission/user_role_permission`,
        },
      },
    });
  };
}

//Update_permission
export function updatePermission(data: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_UPDATE_PERMISSION,
      payload: {
        request: {
          method: "POST",
          url: `/permission`,
          data: data,
        },
      },
    });
  };
}
