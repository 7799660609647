import React from "react";
import 'react-phone-input-2/lib/style.css'
import { DrawerSVG } from "../../assets/images/SigninSVG";
import PhoneInput from 'react-phone-input-2';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import AuthService from "../../services/auth.service";
import { EyeOffSVG, EyeSVG } from "../../assets/images/EmployeesSVG";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { updateSignin } from '../../shared/Reducers/index';
import { ISignupProps, ISignupState } from "../../types/user.type";
import { LogoSVG } from "../../assets/images/ImagesV2";

class SignUp extends React.Component<ISignupProps, ISignupState> {
    constructor(props: ISignupProps) {
        super(props);
        this.state = {
            tab: true,
            firstname: "",
            lastname: "",
            email: "",
            phone: '',
            company: "",
            address: "",
            password: "",
            successful: false,
            message: "",
            confirm_password: "",
            hide_conf: true,
            hide_pass: true,
        };
        this.handleSignup = this.handleSignup.bind(this);
        // this.togglePass = this.togglePass.bind(this);
        // this.toggleConf = this.toggleConf.bind(this);
    }

    togglePass = (e: any) => {
        e.preventDefault();
        this.setState({ hide_pass: !this.state.hide_pass });
    }

    toggleConf = (e: any) => {
        e.preventDefault();
        this.setState({ hide_conf: !this.state.hide_conf });
    }



    handleSignup(formValue: { firstname: string; lastname: string; email: string; phone: string; company: string; address: string; password: string, confirm_password: string }) {
        console.log(formValue);
        const { firstname, lastname, email, phone, company, address, password } = formValue;

        this.setState({
            message: "",
            successful: false,
        });

        AuthService.signup(
            firstname,
            lastname,
            email,
            phone,
            company,
            address,
            password,
        ).then(
            response => {
                this.setState({
                    message: response.data.message,
                    successful: true,
                    firstname: firstname
                })
            },
            error => {
                const resMessage = (
                    error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();

                this.setState({
                    successful: false,
                    message: resMessage
                });
            }
        );
    }

    handleNext = () => {
        this.setState({ tab: false })
    }

    handleStepBack = () => {
        this.setState({ tab: true })
    }

    handlePhoneNumber = (e: any) => {
        console.log(e);
    }

    handleSignIn = () => {
        console.log('triggered');
        this.props.updateSignin('sign-up');
    }

    validationSchema() {
        return Yup.object().shape({
            firstname: Yup.string()
                .min(2, 'Too Short !')
                .max(255, 'To Long !')
                .required("Enter first name"),
            lastname: Yup.string()
                .min(2, 'Too Short !')
                .max(255, 'To Long !')
                .required("Enter last name"),
            email: Yup.string()
                .email("This is not a valid Email.")
                .trim('The contact name cannot include leading and trailing spaces')
                .strict(true)
                .required("Enter Email Address"),
            company: Yup.string()
                .required("Enter a valid company name")
                .test(
                    "len",
                    "Enter company name.",
                    (val: any) =>
                        val &&
                        val.toString().length >= 3 &&
                        val.toString().length <= 25
                ),
            address: Yup.string()
                .min(2, 'Too Short !')
                .max(255, 'To Long !'),
            password: Yup.string()
                .min(8, 'Too Short !')
                .max(25, 'To Long !')
                .required("Enter Password")
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                ),
            confirm_password: Yup.string()
                .min(8, 'Too Short !')
                .max(25, 'To Long !')
                .required("Enter Confirm Password")
                .oneOf([Yup.ref('password'), null], 'Passwords does not match'),
            PhoneInput: Yup.string()
                .matches(
                    /^ ((\\+[1 - 9]{ 1, 4}[\\-]*) | (\\([0 - 9]{ 2, 3 } \\)[\\-] *)| ([0 - 9]{ 2, 4 })[\\-] *)*? [0 - 9]{ 3, 4 }?[\\-] * [0 - 9]{ 3, 4 }?$ /,
                    "Phone number is not valid"
                ),
        });
    }

    render() {
        const { successful, message } = this.state;
        const initialValues = {
            firstname: "",
            lastname: "",
            email: "",
            phone: '',
            company: "",
            address: "",
            password: "",
            confirm_password: ""
        };

        return (
            <div className="container-fluid h-100">
                <div className="row vh-100">
                    <div className="col-md-5 d-flex justify-content-center flex-column bg-green p-6-5-rem p-1-5-rem res-d-none">
                    <div className="signoutlogo" style={{marginBottom: '22px'}}>
             <LogoSVG />
         </div>
                        <div className="w-100">
                            <h5 className="text-white">Start your free 14 day trial</h5>
                            <h2 className="text-white mt-4"> Sign up</h2>
                            <p className="text-white mt-3">
                                Quick and easy setup<br />
                                No credit card required<br />
                                No commitment.
                            </p>
                            <div className="mt-5 signinup-left-svg">
                                <DrawerSVG />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 d-flex justify-content-center align-items-center flex-column  p-6-5-rem p-1-5-rem">
                        <div>
                            {
                                (this.state.successful && this.state.firstname) ? <p className="pos-signin-up">Already a member? <a className="terms text-project" href={`/from-signup/${this.state.firstname}`} onClick={this.handleSignIn}>Sign in</a></p> : <p className="pos-signin-up">Already a member? <a className="terms text-project" href="/">Sign in</a></p>
                            }
                        </div>
                        <div className="w-450px responsive-w-100">
                            <h2>Sign up to <span className="text-project">Clean Task</span></h2>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={this.validationSchema}
                                onSubmit={this.handleSignup}
                            >

                                <Form className="mt-30px">
                                    {
                                        !successful &&
                                        <div>
                                            <div className="row mb-2">
                                                <div className="col-md-6">
                                                    <label className="form-label">First name<span className="text-project">*</span></label>
                                                    <Field name="firstname" type={'text'} className="form-control input" />
                                                    <ErrorMessage
                                                        name="firstname"
                                                        component={"div"}
                                                        className="cus-alert" />
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label">Last name<span className="text-project">*</span></label>
                                                    <Field name="lastname" type={'text'} className="form-control input" />
                                                    <ErrorMessage
                                                        name="lastname"
                                                        component={"div"}
                                                        className="cus-alert" />
                                                </div>
                                            </div>

                                            <div className="form-group mb-2">
                                                <label className="form-label">Email<span className="text-project">*</span></label>
                                                <Field name="email" type={'email'} className="form-control input" />
                                                <ErrorMessage
                                                    name="email"
                                                    component={"div"}
                                                    className="cus-alert" />
                                            </div>

                                            <div className="form-group mb-2">
                                                <label className="form-label">Phone number</label>
                                                <PhoneInput
                                                    specialLabel={''}
                                                    value={this.state.phone}
                                                    country={'in'}
                                                    inputStyle={{
                                                        paddingTop: 15,
                                                        paddingRight: 15,
                                                        paddingBottom: 15,
                                                        paddingLeft: 57,
                                                        width: "100%",
                                                        background: '#FFFFFF',
                                                        border: '1px solid #F0F0F0',
                                                        borderRadius: '8px'
                                                    }} />
                                            </div>

                                            <div className="form-group mb-2">
                                                <label className="form-label">Company name<span className="text-project">*</span></label>
                                                <Field name="company" type={'text'} className="form-control" />
                                                <ErrorMessage
                                                    name="company"
                                                    component={"div"}
                                                    className="cus-alert" />
                                            </div>

                                            <div className="form-group mb-2">
                                                <label className="form-label">Address</label>
                                                <Field name="address" type={'text'} className="form-control" />
                                                <ErrorMessage
                                                    name="address"
                                                    component={"div"}
                                                    className="cus-alert" />
                                            </div>

                                            <div className="form-group mb-2">
                                                {/* <label className="form-label">Password</label>
                                        <Field
                                            name="password"
                                            type="password"
                                            className="form-control" />
                                        <ErrorMessage
                                            name="password"
                                            component={"div"}
                                            className="acus-alert"
                                        /> */}
                                                <div className="row mb-3">
                                                    <div className="col-md-6">
                                                        <label className="form-label">Password<span className="text-project">*</span></label>
                                                        <div className="eye-field">
                                                            <Field name="password" type={this.state.hide_pass ? 'password' : 'text'} className="form-control input password-input" />
                                                            <button type="button" className="btn eye" onClick={this.togglePass}>{this.state.hide_pass ? <EyeOffSVG /> : <EyeSVG />}</button>
                                                        </div>
                                                        <ErrorMessage
                                                            name="password"
                                                            component={"div"}
                                                            className="cus-alert" />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label className="form-label">Confirm Password<span className="text-project">*</span></label>
                                                        <div className="eye-field">
                                                            <Field name="confirm_password" type={this.state.hide_conf ? 'password' : 'text'} className="form-control input password-input" />
                                                            <button type="button" className="btn eye" onClick={this.toggleConf}>{this.state.hide_conf ? <EyeOffSVG /> : <EyeSVG />}</button>
                                                        </div>
                                                        <ErrorMessage
                                                            name="confirm_password"
                                                            component={"div"}
                                                            className="cus-alert" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pt-2">
                                                <button type="submit" className="btn btn-primary login-button">Register</button>
                                            </div>
                                        </div>

                                    }
                                    {
                                        message &&
                                        <div className="form-group">
                                            <div className={
                                                successful ? "alert alert-success" : "alert"
                                            }
                                                role="alert"
                                            >
                                                {message}
                                            </div>
                                        </div>
                                    }
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
              
            </div>
        )
    }
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateSignin: bindActionCreators(updateSignin, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);