import React, { Component } from "react";

import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import { RootState } from "../../index";
import { SearchSVG } from "../../assets/images/UsermanagementsSVG";
import SideBar from "../../components/sidebar/SideBar";
import TopNav from "../../components/topnav/TopNav";
import { connect } from "react-redux";
import {
  getAllExportQuotedTask,
  ExportExcelQuotedTask,
  updateSidebar,
} from "../../shared/Reducers";
import { Dispatch, bindActionCreators } from "redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import Button from "@material-ui/core/Button";
import { TableDeleteSVG, TableEditSVG, TableViewSVG } from "../../assets/images/ImagesV2";
import axios from 'axios';
import ProgressBar from "react-bootstrap/ProgressBar";
import Constants from "../../shared/Constants";
import { constants } from "buffer";
import { PaperClipSVG, TrashSVG } from "../../assets/images/ImagesV2";
import AttachmentPreview from "../../shared/Components/AttachmentPreview";

export interface ExportQuotedTaskProps {
  status: any;
  getAllExportQuotedTask: any;
  filteredQuotedTaskData: any;
  ExportExcelQuotedTask: any;
  progressPercentage: number;
  processTrigger: any;
  exportFileURL: string;
  updateSidebar: any;
}
export interface ExportQuotedTasktatus {
  viewQuotedTaskModal: boolean;
  clientId: any;
  branchId: any;
  viewBranch: string;
  fixedTaskList: any;
  additionalTaskList: any;
  exportDatevalue: any;
  reportDatevalue: any;
  exportBranchTaskList: any;
  editTableDataValue: any;
  managerName: string;
  managerNameError: string;
  attachmentFiles: any | Blob[];
  attachmentUrls: any[];
  AttachmentPreviewStatus: boolean;
  previewUrl: string;
  previewType: string;
  searchValue: any;
}

class ExportQuotedTask extends Component<
  ExportQuotedTaskProps,
  ExportQuotedTasktatus
> {
  constructor(props: ExportQuotedTaskProps) {
    super(props);
    this.state = {
      viewQuotedTaskModal: false,
      clientId: 0,
      branchId: 0,
      viewBranch: "",
      fixedTaskList: [],
      additionalTaskList: [],
      exportBranchTaskList: [],
      searchValue: "",
      exportDatevalue: dayjs(),
      reportDatevalue: dayjs(),
      editTableDataValue: null,
      managerName: "",
      managerNameError: "",
      attachmentFiles: [],
      attachmentUrls: [],
      AttachmentPreviewStatus: false,
      previewUrl: "",
      previewType: "",
    };
  }

  componentDidMount(): void {
    this.listAllExportQuotedTask();
    this.props.updateSidebar(true);

    // date picker readonly
    let datePicker: NodeListOf<Element> = document.querySelectorAll(
      ".MuiFormControl-root input"
    ) as NodeListOf<Element>;
    if (datePicker.length > 0) {
      for (let index = 0; index < datePicker.length; index++) {
        const element = datePicker[index];
        datePicker[index].setAttribute("readonly", "true");
      }
    }
    const currentDate = moment();
    this.setState({ exportDatevalue: currentDate.startOf('isoWeek').format('YYYY-MM-DD') });

  }

  componentDidUpdate(prevProps: Readonly<ExportQuotedTaskProps>, prevState: Readonly<ExportQuotedTasktatus>, snapshot?: any): void {
    if (this.props.filteredQuotedTaskData !== prevProps.filteredQuotedTaskData && this.state.viewQuotedTaskModal === true) {
      let filteredQuotedTaskData = this.props.filteredQuotedTaskData;

      const newArray = filteredQuotedTaskData.map((obj: any) => {
        const newKey2 = "active_total";
        const newValue2 = obj.status === 1 && obj.task_type === 1 ? obj.total_time :
          obj.status === 1 && obj.task_type === 0 ? parseInt(obj.sunday) + parseInt(obj.monday) + parseInt(obj.tuesday) + parseInt(obj.wednesday) + parseInt(obj.thursday) + parseInt(obj.friday) + parseInt(obj.saturday) : 0
          ;

        return { ...obj, [newKey2]: newValue2 };
      });

      const fixedTaskList = newArray.filter((obj: any) => obj.task_type === 1);
      const additionalTaskList = newArray.filter((obj: any) => obj.task_type === 0);
      this.setState({ fixedTaskList: fixedTaskList, additionalTaskList: additionalTaskList, branchId: filteredQuotedTaskData[0].branch_id, clientId: filteredQuotedTaskData[0].client_id, managerName: filteredQuotedTaskData[0].manager });
    }

    if (this.props.filteredQuotedTaskData !== prevProps.filteredQuotedTaskData && this.state.viewQuotedTaskModal === false) {
      this.setState({ exportBranchTaskList: this.props.filteredQuotedTaskData });
    }

    if (this.props.exportFileURL !== prevProps.exportFileURL && this.props.exportFileURL !== "") {
      const fileUrl = Constants.imgUrl + this.props.exportFileURL;

      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          window.open(url, '_blank');
        })
        .catch((error) => {
          console.error('Error downloading file:', error);
        });
    }

    if (this.state.searchValue !== prevState.searchValue) {
      this.props.getAllExportQuotedTask(0, 0, "group", this.state.searchValue)
    }
  }

  listAllExportQuotedTask = () => {
    this.props.getAllExportQuotedTask(0, 0, "group")
  }

  getViewExportQuotedTask = (clientID: any, branchId: any) => {
    this.props.getAllExportQuotedTask(clientID, branchId, "all");
    this.setState({ viewQuotedTaskModal: true });
  };

  handleExportDateChange = async (newValue: Dayjs | null) => {
    this.setState({ exportDatevalue: newValue });
  };
  handleExportDateClick = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let exportDate: HTMLElement = document.querySelector(
        ".export_date button.MuiButtonBase-root"
      ) as HTMLElement;
      exportDate.click();
    }
  };

  handleReportDateChange = async (newValue: Dayjs | null) => {
    this.setState({ reportDatevalue: newValue });
  };
  handleReportDateClick = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let reportDate: HTMLElement = document.querySelector(
        ".report_date button.MuiButtonBase-root"
      ) as HTMLElement;
      reportDate.click();
    }
  };

  handleAdditionalTaskActive = (inx: number, status: number) => {

    let filteredQuotedTaskData = this.state.additionalTaskList;

    if (filteredQuotedTaskData[inx]) {
      filteredQuotedTaskData[inx].status = status === 0 ? 1 : 0;
      filteredQuotedTaskData[inx].active_total = status === 0 ? parseInt(filteredQuotedTaskData[inx].sunday) + parseInt(filteredQuotedTaskData[inx].monday) + parseInt(filteredQuotedTaskData[inx].tuesday) + parseInt(filteredQuotedTaskData[inx].wednesday) + parseInt(filteredQuotedTaskData[inx].thursday) + parseInt(filteredQuotedTaskData[inx].friday) + parseInt(filteredQuotedTaskData[inx].saturday) : 0;
      this.setState({ additionalTaskList: filteredQuotedTaskData });
    }

  };
  handleActive = (inx: number, status: number) => {

    let filteredQuotedTaskData = this.state.fixedTaskList;

    if (filteredQuotedTaskData[inx]) {
      filteredQuotedTaskData[inx].status = status === 0 ? 1 : 0;
      filteredQuotedTaskData[inx].active_total = status === 0 ? parseInt(filteredQuotedTaskData[inx].total_time) : 0;
      this.setState({ fixedTaskList: filteredQuotedTaskData });
    }

  };


  handleChangeTime = (inx: number, arrayType: string) => {

    if (arrayType === "fixed") {
      let filteredQuotedTaskData = this.state.fixedTaskList;

      if (filteredQuotedTaskData[inx] && filteredQuotedTaskData[inx].status === 1) {
        filteredQuotedTaskData[inx].active_total = parseInt(filteredQuotedTaskData[inx].total_time);
        this.setState({ fixedTaskList: filteredQuotedTaskData });
      }
    } else {
      let filteredQuotedTaskData = this.state.additionalTaskList;

      if (filteredQuotedTaskData[inx] && filteredQuotedTaskData[inx].status === 1) {
        filteredQuotedTaskData[inx].active_total = parseInt(filteredQuotedTaskData[inx].sunday) + parseInt(filteredQuotedTaskData[inx].monday) + parseInt(filteredQuotedTaskData[inx].tuesday) + parseInt(filteredQuotedTaskData[inx].wednesday) + parseInt(filteredQuotedTaskData[inx].thursday) + parseInt(filteredQuotedTaskData[inx].friday) + parseInt(filteredQuotedTaskData[inx].saturday);
        this.setState({ additionalTaskList: filteredQuotedTaskData });
      }
    }

  };

  handleAdd = () => {
    let add = {
      friday: 0,
      monday: 0,
      saturday: 0,
      status: 1,
      sunday: 0,
      task: "",
      task_type: 0,
      thursday: 0,
      tuesday: 0,
      wednesday: 0,
      active_total: 0
    };

    let add_item = [...this.state.additionalTaskList];
    add_item.push(add);
    this.setState({ additionalTaskList: add_item });
    console.log(add_item, "add");
  };

  handleRemove = (id: any, index: any) => {
    if (this.state.additionalTaskList.length > 1) {
      let remove = this.state.additionalTaskList.filter(
        (item: any, i: any) => item !== id
      );
      this.setState({ additionalTaskList: remove });
      console.log(remove, "remove");
    } else {
      console.log("Never Closed");
    }
  };

  handleEditValue = (inx: number, type: string, editValue: any) => {
    this.setState({ editTableDataValue: editValue })

    var tableDataText = document.getElementsByClassName("table-data-text");
    var tableDataInput = document.getElementsByClassName("table-data-input");
    if (tableDataInput !== null && tableDataText !== null) {
      for (let i = 0; i < tableDataText.length; i++) {
        tableDataText[i].classList.remove("d-none");
      }
      for (let i = 0; i < tableDataInput.length; i++) {
        tableDataInput[i].classList.add("d-none");
      }
    }

    var editTableDataText = document.getElementById(type + "-text-" + inx);
    var editTableDataInput = document.getElementById(type + "-input-" + inx);
    if (editTableDataInput !== null && editTableDataText !== null) {
      editTableDataText.classList.add("d-none");
      editTableDataInput.classList.remove("d-none");
      editTableDataInput.focus();
    }
  }

  handleChangeValueUpdate = (arrayType: string, valueKey: string, arrayInx: number, newValue: any) => {
    if (arrayType === "fixed") {
      let fixedTaskList = this.state.fixedTaskList;

      if (fixedTaskList[arrayInx]) {
        if (newValue === false || newValue === true) {
          fixedTaskList[arrayInx][valueKey] = newValue === false ? 0 : 1;
        } else {
          fixedTaskList[arrayInx][valueKey] = newValue;
        }

        this.setState({ fixedTaskList: fixedTaskList });
        this.handleChangeTime(arrayInx, arrayType);
      }
    } else {
      let additionalTaskList = this.state.additionalTaskList;

      if (additionalTaskList[arrayInx]) {
        additionalTaskList[arrayInx][valueKey] = newValue;

        this.setState({ additionalTaskList: additionalTaskList });
        this.handleChangeTime(arrayInx, arrayType);
      }
    }
  }

  ExportQuotedTask = () => {

    if (this.state.managerName === "") {
      this.setState({ managerNameError: "Please Enter Manager Name" });
    } else {
      let data = new FormData();

      for (let index = 0; index < this.state.attachmentFiles.length; index++) {
        data.append("attachments", this.state.attachmentFiles[index]);
      }
      data.append("fixed_list", JSON.stringify(this.state.fixedTaskList));
      data.append("additional_list", JSON.stringify(this.state.additionalTaskList));
      data.append("manager_name", this.state.managerName);
      data.append("week_date", moment(new Date(this.state.exportDatevalue?.toString())).format("YYYY-MM-DD"));
      data.append("export_date", moment(new Date(this.state.reportDatevalue?.toString())).format("DD/MM/YYYY"));
      data.append("branch_name", this.state.viewBranch);
      data.append("branch_id", this.state.branchId);
      data.append("client_id", this.state.clientId);
      // data.append("status", "1")

      this.props.ExportExcelQuotedTask(data);
    }
  }

  handleAttachmentImageChange = async (e: any) => {
    const file = e.target.files[0];

    let files = [];
    let urls = [];
    files = [...e.target.files];

    let fileurls: any = [];
    await files.map(async (file: any) => {
      fileurls.push({
        url: URL.createObjectURL(file),
        file_type: file.type,
      });
    });
    urls = [...fileurls];

    this.setState({
      attachmentFiles: [...this.state.attachmentFiles, ...files],
    });
    this.setState({ attachmentUrls: [...this.state.attachmentUrls, ...urls] }, () => {
      e.target.value = '';
    });
  }

  handleDeleteImage = (index: number, type: string) => {
    const files = this.state.attachmentFiles;
    const urls = this.state.attachmentUrls;
    files.splice(index, 1);
    urls.splice(index, 1);

    this.setState({ attachmentFiles: files });
    this.setState({ attachmentUrls: urls });
  };

  handlePreviewOpen = (file_type: any, url: any) => {
    if (
      file_type === "image/png" ||
      file_type === "image/jpeg" ||
      file_type === "image/jpg" ||
      file_type === "video/mp4" ||
      file_type === "video/webm" ||
      file_type === "application/pdf"
    ) {
      this.setState({
        AttachmentPreviewStatus: true,
        previewUrl: url,
        previewType: file_type,
      });
    }
  };

  handlePreviewClose = () => {
    this.setState({ AttachmentPreviewStatus: false });
  };

  render() {
    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className={this.props.status ? "main-panel" : "main-panel2"}>
          <TopNav />
          <div className="content-wrapper">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="mb-0">Export Quoted Task List</h3>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-end">
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12 col-lg-4">
                  <div className="input-group search">
                    <span
                      className="input-group-text search-icon"
                      id="basic-addon1"
                    >
                      <SearchSVG />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={this.state.searchValue}
                      onChange={(e: any) => this.setState({ searchValue: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table employee-table">
                          <thead>
                            <tr>
                              <th>Client</th>
                              <th>Branch</th>
                              <th>Total tasks</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.filteredQuotedTaskData &&
                              this.props.filteredQuotedTaskData.map((branchs: any, index: number) => {
                                console.log("branchs",branchs);
                                
                                return (
                                  <tr key={index}>
                                    <td>{branchs.first_name} {branchs.last_name}</td>
                                    <td>{branchs.branch_name}</td>
                                    <td>{branchs.totalTask}</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-secondary btn-light fs-12 btn-cleaner-count"
                                        onClick={() => {
                                          this.getViewExportQuotedTask(branchs.client_id, branchs.branch_id);
                                          this.setState({ viewBranch: branchs.branch_name })
                                        }}
                                      >
                                        View
                                      </button>
                                    </td>
                                  </tr>
                                )
                              }
                              )
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Modal
            size="xl"
            show={this.state.viewQuotedTaskModal}
            onHide={() => {
              this.setState({ viewQuotedTaskModal: false });
              this.listAllExportQuotedTask();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title><h5 className="offc-title text-capitalize">{this.state.viewBranch}</h5></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-8"><h5 className="mt-2">Fixed Quoted Task</h5></div>
                    <div className="col-md-4">
                      <label className="form-label pull-left" style={{ paddingTop: "10px", paddingRight: "10px" }}>
                        Week Start Date<span className="text-project">*</span>
                      </label>
                      <div
                        className="export_date"
                        onClick={() => this.handleExportDateClick()}
                      >
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          id="export_date"
                        >
                          <Stack spacing={3}>
                            <DesktopDatePicker
                              inputFormat="DD/MM/YYYY"
                              value={this.state.exportDatevalue}
                              onChange={this.handleExportDateChange}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table employee-table">
                              <thead>
                                <tr>
                                  <th>Active</th>
                                  <th>Description</th>
                                  <th>Total Time</th>
                                  <th>Monday</th>
                                  <th>Tuesday</th>
                                  <th>Wednesday</th>
                                  <th>Thursday</th>
                                  <th>Friday</th>
                                  <th>Saturday</th>
                                  <th>Sunday</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.fixedTaskList.map((item: any, index: any) => {
                                  return (
                                    <tr key={index} className={item.status === 1 ? "select-row-bg-green" : ""}>
                                      <td style={{ width: "5%" }}>
                                        {
                                          <Form.Check
                                            type="switch"
                                            checked={item.status}
                                            onClick={() =>
                                              this.handleActive(index, item.status)
                                            }
                                          />
                                        }
                                      </td>
                                      <td onClick={() => this.handleEditValue(index, "fixed-task", item.task)} style={{ width: "15%" }}>
                                        <p className="table-data-text data-text-style" id={"fixed-task-text-" + index}>{item.task}</p>
                                        <input
                                          className="d-none table-data-input form-control"
                                          id={"fixed-task-input-" + index}
                                          type="text"
                                          value={this.state.editTableDataValue}
                                          onChange={(e) => {
                                            this.setState({ editTableDataValue: e.target.value });
                                            this.handleChangeValueUpdate("fixed", "task", index, e.target.value);
                                          }
                                          }
                                        />
                                      </td>
                                      <td onClick={() => this.handleEditValue(index, "fixed-total_time", item.total_time)} style={{ width: "10%" }}>
                                        <p className="table-data-text data-text-style" id={"fixed-total_time-text-" + index}>{item.total_time}</p>
                                        <input
                                          className="d-none table-data-input form-control"
                                          id={"fixed-total_time-input-" + index}
                                          type="number"

                                          value={this.state.editTableDataValue}
                                          onChange={(e) => {
                                            this.setState({ editTableDataValue: e.target.value });
                                            this.handleChangeValueUpdate("fixed", "total_time", index, e.target.value)
                                          }
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className=""
                                          id={"fixed-monday-input-" + index}
                                          type="checkbox"
                                          checked={item.monday === 0 ? false : true}
                                          onChange={(e) => {
                                            this.handleChangeValueUpdate("fixed", "monday", index, e.target.checked);
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className=""
                                          id={"fixed-tuesday-input-" + index}
                                          type="checkbox"
                                          checked={item.tuesday === 0 ? false : true}
                                          onChange={(e) => {
                                            this.handleChangeValueUpdate("fixed", "tuesday", index, e.target.checked);
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className=""
                                          id={"fixed-wednesday-input-" + index}
                                          type="checkbox"
                                          checked={item.wednesday === 0 ? false : true}
                                          onChange={(e) => {
                                            this.handleChangeValueUpdate("fixed", "wednesday", index, e.target.checked);
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className=""
                                          id={"fixed-thursday-input-" + index}
                                          type="checkbox"
                                          checked={item.thursday === 0 ? false : true}
                                          onChange={(e) => {
                                            this.handleChangeValueUpdate("fixed", "thursday", index, e.target.checked);
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className=""
                                          id={"fixed-friday-input-" + index}
                                          type="checkbox"
                                          checked={item.friday === 0 ? false : true}
                                          onChange={(e) => {
                                            this.handleChangeValueUpdate("fixed", "friday", index, e.target.checked);
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className=""
                                          id={"fixed-saturday-input-" + index}
                                          type="checkbox"
                                          checked={item.saturday === 0 ? false : true}
                                          onChange={(e) => {
                                            this.handleChangeValueUpdate("fixed", "saturday", index, e.target.checked);
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className=""
                                          id={"fixed-sunday-input-" + index}
                                          type="checkbox"
                                          checked={item.sunday === 0 ? false : true}
                                          onChange={(e) => {
                                            this.handleChangeValueUpdate("fixed", "sunday", index, e.target.checked);
                                          }}
                                        />
                                      </td>
                                      <td className="text-right pr-3" style={{ width: "10%" }}>
                                        {item.status === 1 ? item.total_time : ''}
                                      </td>
                                    </tr>
                                  );
                                })}
                                <tr>
                                  <th style={{ borderBottomStyle: "none" }} colSpan={9}></th>
                                  <th style={{ fontWeight: "bold", borderBottomStyle: "none" }}>Total</th>
                                  <th className="text-right pr-3" style={{ borderBottomStyle: "none" }}>{this.state.fixedTaskList.reduce((array: any, obj: any) => array + obj.active_total, 0)}</th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <h5>Additional Special Request Task</h5>
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table employee-table">
                          <thead>
                            <tr>
                              <th>Active</th>
                              <th>Description</th>
                              <th>Monday</th>
                              <th>Tuesday</th>
                              <th>Wednesday</th>
                              <th>Thursday</th>
                              <th>Friday</th>
                              <th>Saturday</th>
                              <th>Sunday</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.additionalTaskList.map((item: any, index: any) => {
                              return (
                                <tr key={index} className={item.status === 1 ? "select-row-bg-green" : ""}>
                                  <td style={{ width: "5%" }}>
                                    {
                                      <>
                                        <Form.Check
                                          type="switch"
                                          checked={item.status}
                                          onClick={() =>
                                            this.handleAdditionalTaskActive(index, item.status)
                                          }
                                        />
                                      </>
                                    }
                                  </td>
                                  <td onClick={() => this.handleEditValue(index, "additional-task", item.task)} style={{ width: "15%" }}>
                                    <p className="table-data-text data-text-style" id={"additional-task-text-" + index}>{item.task}</p>
                                    <input
                                      className="d-none table-data-input form-control"
                                      id={"additional-task-input-" + index}
                                      type="text"
                                      value={this.state.editTableDataValue}
                                      onChange={(e) => {
                                        this.setState({ editTableDataValue: e.target.value });
                                        this.handleChangeValueUpdate("additional", "task", index, e.target.value)
                                      }
                                      }
                                    />
                                  </td>
                                  <td onClick={() => this.handleEditValue(index, "additional-monday", item.monday)} style={{ width: "10%" }}>
                                    <p className="table-data-text data-text-style" id={"additional-monday-text-" + index}>{item.monday}</p>
                                    <input
                                      className="d-none table-data-input form-control"
                                      id={"additional-monday-input-" + index}
                                      type="number"

                                      value={this.state.editTableDataValue}
                                      onChange={(e) => {
                                        this.setState({ editTableDataValue: e.target.value });
                                        this.handleChangeValueUpdate("additional", "monday", index, e.target.value)
                                      }
                                      }
                                    />
                                  </td>
                                  <td onClick={() => this.handleEditValue(index, "additional-tuesday", item.tuesday)} style={{ width: "10%" }}>
                                    <p className="table-data-text data-text-style" id={"additional-tuesday-text-" + index}>{item.tuesday}</p>
                                    <input
                                      className="d-none table-data-input form-control"
                                      id={"additional-tuesday-input-" + index}
                                      type="number"

                                      value={this.state.editTableDataValue}
                                      onChange={(e) => {
                                        this.setState({ editTableDataValue: e.target.value });
                                        this.handleChangeValueUpdate("additional", "tuesday", index, e.target.value)
                                      }
                                      }
                                    />
                                  </td>
                                  <td onClick={() => this.handleEditValue(index, "additional-wednesday", item.wednesday)} style={{ width: "10%" }}>
                                    <p className="table-data-text data-text-style" id={"additional-wednesday-text-" + index}>{item.wednesday}</p>
                                    <input
                                      className="d-none table-data-input form-control"
                                      id={"additional-wednesday-input-" + index}
                                      type="number"

                                      value={this.state.editTableDataValue}
                                      onChange={(e) => {
                                        this.setState({ editTableDataValue: e.target.value });
                                        this.handleChangeValueUpdate("additional", "wednesday", index, e.target.value)
                                      }
                                      }
                                    />
                                  </td>
                                  <td onClick={() => this.handleEditValue(index, "additional-thursday", item.thursday)} style={{ width: "10%" }}>
                                    <p className="table-data-text data-text-style" id={"additional-thursday-text-" + index}>{item.thursday}</p>
                                    <input
                                      className="d-none table-data-input form-control"
                                      id={"additional-thursday-input-" + index}
                                      type="number"

                                      value={this.state.editTableDataValue}
                                      onChange={(e) => {
                                        this.setState({ editTableDataValue: e.target.value });
                                        this.handleChangeValueUpdate("additional", "thursday", index, e.target.value)
                                      }
                                      }
                                    />
                                  </td>
                                  <td onClick={() => this.handleEditValue(index, "additional-friday", item.friday)} style={{ width: "10%" }}>
                                    <p className="table-data-text" id={"additional-friday-text-" + index}>{item.friday}</p>
                                    <input
                                      className="d-none table-data-input form-control"
                                      id={"additional-friday-input-" + index}
                                      type="number"

                                      value={this.state.editTableDataValue}
                                      onChange={(e) => {
                                        this.setState({ editTableDataValue: e.target.value });
                                        this.handleChangeValueUpdate("additional", "friday", index, e.target.value)
                                      }
                                      }
                                    />
                                  </td>
                                  <td onClick={() => this.handleEditValue(index, "additional-saturday", item.saturday)} style={{ width: "10%" }}>
                                    <p className="table-data-text" id={"additional-saturday-text-" + index}>{item.saturday}</p>
                                    <input
                                      className="d-none table-data-input form-control"
                                      id={"additional-saturday-input-" + index}
                                      type="number"

                                      value={this.state.editTableDataValue}
                                      onChange={(e) => {
                                        this.setState({ editTableDataValue: e.target.value });
                                        this.handleChangeValueUpdate("additional", "saturday", index, e.target.value)
                                      }
                                      }
                                    />
                                  </td>
                                  <td onClick={() => this.handleEditValue(index, "additional-sunday", item.sunday)} style={{ width: "10%" }}>
                                    <p className="table-data-text data-text-style" id={"additional-sunday-text-" + index}>{item.sunday}</p>
                                    <input
                                      className="d-none table-data-input form-control"
                                      id={"additional-sunday-input-" + index}
                                      type="number"

                                      value={this.state.editTableDataValue}
                                      onChange={(e) => {
                                        this.setState({ editTableDataValue: e.target.value });
                                        this.handleChangeValueUpdate("additional", "sunday", index, e.target.value)
                                      }
                                      }
                                    />
                                  </td>
                                  <td className="text-right pr-3" style={{ width: "10%" }}>
                                    {item.status === 1 ? item.active_total : ''}
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <th colSpan={8} style={{ borderBottomStyle: "none" }}>
                                <button className="btn btn-border" onClick={this.handleAdd}>
                                  + Add Additional Task
                                </button>
                              </th>
                              <th style={{ borderBottomStyle: "none" }}>Total</th>
                              <th className="text-right pr-3" style={{ borderBottomStyle: "none" }}>{this.state.additionalTaskList.reduce((array: any, obj: any) => array + obj.active_total, 0)}</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table employee-table m-0">
                          <thead>
                            <tr>
                              <th style={{ borderBottomStyle: "none" }}>Grand Total</th>
                              <th className="text-right pr-3" style={{ borderBottomStyle: "none" }}>{this.state.fixedTaskList.reduce((array: any, obj: any) => array + obj.active_total, 0) + this.state.additionalTaskList.reduce((array: any, obj: any) => array + obj.active_total, 0)}</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="mb-2 col-md-6">
                  <label className="form-label pull-left" style={{ padding: "10px", paddingRight: "10px" }}>
                    Factory Manager Name<span className="text-project">*</span>
                  </label>
                  <input
                    style={{ width: "auto" }}
                    value={this.state.managerName}
                    onChange={(e) => this.setState({ managerName: e.target.value, managerNameError: "" })}
                    name="manager_name"
                    type={"text"}
                    className="form-control text-field"
                  />
                  <div className="cus-alert">{this.state.managerNameError}</div>
                </div>
                <div className="mb-2 col-md-6">
                  <label className="form-label pull-left" style={{ padding: "10px", paddingRight: "10px" }}>
                    Report Date<span className="text-project">*</span>
                  </label>
                  <div
                    className="report_date"
                    onClick={() => this.handleReportDateClick()}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      id="report_date"
                    >
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          inputFormat="DD/MM/YYYY"
                          value={this.state.reportDatevalue}
                          onChange={this.handleReportDateChange}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="mb-2 col-md-12">
                  <label className="form-label">
                    Attachment :
                  </label>
                  <div className="attachment-btn dropdown-field">
                    <input
                      type="file"
                      multiple
                      accept=".pdf,.PDF,.webm,.WEBM,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                      style={{ display: "none" }}
                      onChange={this.handleAttachmentImageChange}
                      id="contained-button-file1"
                    />
                    <label htmlFor="contained-button-file1">
                      <Button
                        variant="contained"
                        color="default"
                        component="span"
                      >
                        <PaperClipSVG /> Add attachment
                      </Button>
                    </label>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex row">
                        {this.state.attachmentUrls &&
                          this.state.attachmentUrls.map(
                            (url: any, index: number) => (
                              <div className="col-md-3 img-card mb-3">
                                <span className="delete">
                                  <a onClick={() => this.handleDeleteImage(index, "attachment")}>
                                    <TrashSVG />
                                  </a>
                                </span>
                                <div
                                  className="img-preview"
                                  onClick={() =>
                                    this.handlePreviewOpen(url.file_type, url.url)
                                  }
                                >
                                  <img
                                    className="img-thumb-perview"
                                    src={
                                      url.file_type === "image/png" ||
                                        url.file_type === "image/jpeg" ||
                                        url.file_type === "image/jpg"
                                        ? url.url
                                        : url.url
                                    }
                                  />
                                </div>
                              </div>
                            )
                          )}
                        <AttachmentPreview
                          displayPreview={this.state.AttachmentPreviewStatus}
                          previewUrl={this.state.previewUrl}
                          previewType={this.state.previewType}
                          handlePreviewClose={this.handlePreviewClose}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-2 mb-2 col-md-12 cus-progress">
                  <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.props.processTrigger}
                  >
                    <Modal.Body className="p-0">
                      <ProgressBar
                        now={this.props.progressPercentage}
                        label={
                          this.props.progressPercentage === 100
                            ? `Processing your request...`
                            : `File Exporting....${this.props.progressPercentage}%`
                        }
                      />
                    </Modal.Body>
                  </Modal>
                  <div className="d-flex">
                    <div className="mr-16px">
                      <button
                        type="button"
                        id="create-button"
                        className="btn btn-primary create-button"
                        onClick={this.ExportQuotedTask}
                      >
                        Export
                        <span
                          id="spinner-border"
                          className="spinner-border spinner-border-sm d-none"
                        ></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  status: state.sidebar.status,
  filteredQuotedTaskData: state.quotedTask.filteredQuotedTaskData,
  progressPercentage: state.quotedTask.progressPercentage,
  processTrigger: state.quotedTask.processTrigger,
  exportFileURL: state.quotedTask.exportFileURL
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
    getAllExportQuotedTask: bindActionCreators(getAllExportQuotedTask, dispatch),
    ExportExcelQuotedTask: bindActionCreators(ExportExcelQuotedTask, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ExportQuotedTask);
