import * as Yup from "yup";

import { Button, Offcanvas, Toast } from "react-bootstrap";
import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { IShiftDashboardState, IShiftProps } from "../../types/shift.type";
import {
  SortDown,
  SortReqular,
  SortUp,
  SuccessToastSVG,
} from "../../assets/images/ImagesV2";
import {
  filterShift,
  getAllShifts,
  handleShiftSort,
  updateAddShift,
  updateShiftFetchStatus,
  updateSidebar,
} from "../../shared/Reducers";

import AddShift from "./AddShift";
import { CloseBtnSVG } from "../../assets/images/Location";
import Constants from "../../shared/Constants";
import ShiftTable from "./ShiftTable";
import { CustomSelect } from "../../shared/Components/CustomSelect";
import React from "react";
import ReactPaginate from "react-paginate";
import { RootState } from "../../index";
import { SearchSVG } from "../../assets/images/EmployeesSVG";
import SideBar from "../../components/sidebar/SideBar";
import TopNav from "../../components/topnav/TopNav";
import { connect } from "react-redux";

class Shift extends React.Component<IShiftProps, IShiftDashboardState> {
  constructor(props: IShiftProps) {
    super(props);
    this.state = {
      show: false,
      total_shift: "",
      searchTerm: "",
      addToast: false,
      deleteToast: false,
      editToast: false,
      closeAdd: false,
      sortBy: null,
      sortDirection: null,
      pageOffset: Constants.OFFSET,
      addShiftStatus: false,
      fieldName: "",
      orderType: "",
      pagehandle: false,
      timeout: null,
    };
  }

  componentDidMount(): void {
    this.props.updateSidebar(true);
    document.title = "Shift";
    this.getShifts(Constants.OFFSET, Constants.PER_PAGE);
  }

  componentDidUpdate(
    prevProps: Readonly<IShiftProps>,
    prevState: Readonly<IShiftDashboardState>,
    snapshot?: any
  ): void {
    if (prevProps.addShiftStatus !== this.props.addShiftStatus) {
      if (!this.props.addShiftStatus) {
        this.getShifts(Constants.OFFSET, Constants.PER_PAGE);
      }
    }

    if (prevProps.shiftFetchStatus !== this.props.shiftFetchStatus) {
      if (this.props.shiftFetchStatus) {
        this.getShifts(Constants.OFFSET, Constants.PER_PAGE);
      }
    }
    if (prevProps.addShiftSuccess !== this.props.addShiftSuccess) {
      if (this.props.addShiftSuccess === "Successfully created") {
        this.setState({ addToast: true });
        this.setState({ closeAdd: false });
        setTimeout(() => {
          this.setState({ addToast: false });
          this.setState({ closeAdd: false });
        }, 5000);
      }
    }
    if (prevProps.editShiftSuccess !== this.props.editShiftSuccess) {
      if (this.props.editShiftSuccess === "Successfully updated") {
        this.setState({ editToast: true });
        setTimeout(() => {
          this.setState({ editToast: false });
        }, 5000);
      }
    }
    if (prevProps.deleteShiftSuccess !== this.props.deleteShiftSuccess) {
      if (this.props.deleteShiftSuccess === "Successfully deleted") {
        this.setState({ deleteToast: true });
        setTimeout(() => {
          this.setState({ deleteToast: false });
        }, 5000);
      }
    }
  }

  addToastClose = () => {
    this.setState({ addToast: false });
  };

  editToastClose = () => {
    this.setState({ editToast: false });
  };

  deleteToastClose = () => {
    this.setState({ deleteToast: false });
  };

  getShifts = async (offset: number, limit: number) => {
    try {
      const query = `search=&sort=${this.props.shiftSortBy}&order=${this.props.shiftOrderBy}&offset=${offset}&limit=${limit}&client=all&branch=all`;
      this.props.getAllShifts(query);
    } catch (error) {
      console.log(error);
    }
  };

  handleAddShiftCLose = (status: boolean) => {
    this.handleClose();
    if (!status) {
      this.getShifts(Constants.OFFSET, Constants.PER_PAGE);
    }
  };

  // Canvas Show
  handleShow() {
    // this.props.updateAddShift(true);
    this.setState({ addShiftStatus: true });
  }

  // Canvas Close
  handleClose() {
    // this.props.updateAddShift(false);
    this.setState({ addShiftStatus: false });
  }

  // Search Branch Filter
  handleFilter = (e: any) => {
    const value: string = e.target.value.toLowerCase();
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

    this.setState({
      searchTerm: value,
      timeout: setTimeout(async () => {
        try {
          const query = `search=${value}&sort=${this.props.shiftSortBy}&order=${this.props.shiftOrderBy}&offset=0&limit=10&client=all&branch=all`;
          this.props.getAllShifts(query);
        } catch (error) {
          console.log(error);
        }
        // Trigger your function here that you want to call when user stops typing
      }, 1000), // Set the delay time in ms
    });
  };

  handlePageClick = (e: any) => {
    console.log(e);
    if (e.selected < this.props.total_count / Constants.PER_PAGE) {
      // this.getShifts(e.selected * Constants.PER_PAGE, Constants.PER_PAGE);
      const query = `search=${this.state.searchTerm}&sort=${
        this.state.fieldName
      }&order=${this.state.orderType}&offset=${
        e.selected * Constants.PER_PAGE
      }&limit=${Constants.PER_PAGE}&name=all&branch=all`;
      this.props.getAllShifts(query);
    }
  };

  handleSort = (column: string) => {
    if (this.state.sortBy === column) {
      this.setState({
        sortDirection: this.state.sortDirection === "asc" ? "desc" : "asc",
      });
      this.props.handleShiftSort(
        column,
        this.state.sortDirection === "asc" ? "desc" : "asc"
      );
    } else {
      this.setState({ sortBy: column });
      this.setState({ sortDirection: "asc" });
      this.props.handleShiftSort(column, "asc");
    }
  };

  validationSchema = () => {
    return Yup.object().shape({
      column_name: Yup.string().required("Select Filter Column"),
      order_type: Yup.string().required("Select Sort Order"),
    });
  };
  handleSearch = (formValue: any) => {
    try {
      this.setState({ pagehandle: true }, () => {
        const query = `search=&sort=${formValue.column_name}&order=${formValue.order_type}&offset=${Constants.OFFSET}&limit=${Constants.PER_PAGE}&name=all&branch=all`;
        this.props.getAllShifts(query);
        this.setState({
          fieldName: formValue.column_name,
          orderType: formValue.order_type,
          pagehandle: false,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  render(): React.ReactNode {
    console.log(this.props.filteredShiftData);

    let taskStatusOption: any = [];
    taskStatusOption = [
      { value: "name", label: "Name" },
      { value: "start_date", label: "Start Date" },
      { value: "end_date", label: "End Date" },
    ];
    let shiftOrder: any = [];
    shiftOrder = [
      { value: "asc", label: "Ascending" },
      { value: "desc", label: "Descending" },
    ];
    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className={this.props.status ? "main-panel" : "main-panel2"}>
          <TopNav />
          <div className="content-wrapper">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="mb-0">Shift management</h3>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-end">
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12 col-lg-4">
                  <div className="input-group search">
                    <span
                      className="input-group-text search-icon"
                      id="basic-addon1"
                    >
                      <SearchSVG />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={this.state.searchTerm}
                      onInput={this.handleFilter}
                      onChange={(e) =>
                        this.setState({ searchTerm: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12 col-lg-6 tab-res-mt-3">
                  <div>
                    <Formik
                      validationSchema={this.validationSchema}
                      onSubmit={this.handleSearch}
                      initialValues={{
                        column_name: "",
                        order_type: "asc",
                      }}
                    >
                      <Form className="d-flex flex-column flex-md-row">
                        <Field
                          className="custom-select dropdown-field mr-16px res-mt-3 filter-w-180px"
                          name="column_name"
                          options={taskStatusOption}
                          component={CustomSelect}
                          placeholder="Select Column"
                          isMulti={false}
                        />
                        <Field
                          className="custom-select dropdown-field mr-16px res-mt-3 filter-w-180px"
                          name="order_type"
                          options={shiftOrder}
                          component={CustomSelect}
                          placeholder="Select Order"
                          isMulti={false}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary filter-button res-mt-3"
                        >
                          Filter
                        </button>
                      </Form>
                    </Formik>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 text-start text-lg-end tab-res-mt-3">
                  <Offcanvas
                    className="shift-offcanvas"
                    placement="end"
                    show={this.state.addShiftStatus}
                    onHide={() => this.handleClose()}
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>
                        <span className="offc-title">Add Shift</span>
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <AddShift onClose={this.handleAddShiftCLose} />
                    </Offcanvas.Body>
                  </Offcanvas>
                  <Button
                    aria-controls="offcanvasRight"
                    className="btn btn-primary login-button mob-res-w-100"
                    onClick={() => this.handleShow()}
                  >
                    Add Shift
                  </Button>
                  {this.state.addToast ? (
                    <Toast className="success-alerts">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <SuccessToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.addShiftSuccess}
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.addToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                  {this.state.editToast ? (
                    <Toast className="success-alerts">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <SuccessToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.editShiftSuccess}
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.editToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                  {this.state.deleteToast ? (
                    <Toast className="success-alerts">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <SuccessToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.deleteShiftSuccess}
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.deleteToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      {this.props.filteredShiftData.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table employee-table">
                            <thead>
                              <tr>
                                <th>NAME</th>
                                <th>CLEANER</th>
                                <th>STRAT DATE</th>
                                <th>END DATE</th>
                                <th>START TIME</th>
                                <th>END TIME</th>
                                <th scope="col">ACTION</th>
                              </tr>
                            </thead>
                            <ShiftTable
                              data={this.props.filteredShiftData}
                              onDelete={() =>
                                this.getShifts(
                                  Constants.OFFSET,
                                  Constants.PER_PAGE
                                )
                              }
                              onEditClose={() =>
                                this.getShifts(
                                  Constants.OFFSET,
                                  Constants.PER_PAGE
                                )
                              }
                              deleteShiftStatus={false}
                            />
                          </table>
                        </div>
                      ) : (
                        <p className="text-center">
                          There is no shift data found
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                {this.props.total_count > 10 ? (
                  <div className="col-md-12">
                    {this.state.pagehandle ? null : (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.props.total_count / Constants.PER_PAGE}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        nextClassName={"next-page"}
                        activeClassName={"active"}
                      />
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  addShiftStatus: state.shift.addShiftStatus,
  shiftFetchStatus: state.shift.shiftFetchStatus,
  shiftData: state.shift.shiftData,
  filteredShiftData: state.shift.filteredShiftData,
  total_count: state.shift.total_count,
  addShiftSuccess: state.shift.addShiftSuccess,
  editShiftSuccess: state.shift.editShiftSuccess,
  deleteShiftSuccess: state.shift.deleteShiftSuccess,
  shiftSortBy: state.shift.shiftSortBy,
  shiftOrderBy: state.shift.shiftOrderBy,
  status: state.sidebar.status,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateAddShift: bindActionCreators(updateAddShift, dispatch),
    updateShiftFetchStatus: bindActionCreators(
      updateShiftFetchStatus,
      dispatch
    ),
    filterShift: bindActionCreators(filterShift, dispatch),
    getAllShifts: bindActionCreators(getAllShifts, dispatch),
    handleShiftSort: bindActionCreators(handleShiftSort, dispatch),
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Shift);
