import { AnyAction } from 'redux';
import * as actionTypes from '../ActionTypes/index';

const initialState: actionTypes.SigninState = {
    component: "sign-up"
}

export const SigninReducer = (state: actionTypes.SigninState = initialState, action: AnyAction): actionTypes.SigninState => {
    switch(action.type) {
        case actionTypes.UPDATE_SIGNIN:
            return {...state, component: action.component};
        default:
            return state;
    }
}

export function updateSignin(component: string) {
    return async (dispatch: actionTypes.SigninDispatchType) => {
        dispatch({
            type: actionTypes.UPDATE_SIGNIN,
            component
        })
    }
}