import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { bindActionCreators, Dispatch } from "redux";
import {
  editDailyTask,
  updateSendQAEmailDailyTaskDlgStatus,
  sendQAEmailDailyTask,
} from "../../shared/Reducers";
import { ISendQAEmailDailyTaskProps } from "../../types/dailyTask.type";
import TagsInput from "react-tagsinput";

// import TagsInput from "../../shared/Components/TagsInput";
let addEventStatus = false;
class SendQAEmailDailyTask extends React.Component<
  ISendQAEmailDailyTaskProps,
  any
> {
  tagRef: any = null;
  constructor(props: ISendQAEmailDailyTaskProps) {
    super(props);
    this.tagRef = React.createRef();
    this.state = {
      formSubmitWarning: "",
      emailtags: [],
    };
  }

  componentDidMount(): void {
    let TagsInputTag = document.querySelector(".react-tagsinput-input");
    if (TagsInputTag !== null) {
      TagsInputTag.addEventListener("keyup", this.emailOnPress);
    }
  }

  emailValidate = (email: string) => {
    let errors: any = false;

    if (!email) {
      errors = false;
      // errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errors = false;
      // errors.email = 'Invalid email address'
    } else {
      errors = true;
    }

    return errors;
  };

  emailOnPress = () => {
    let TagsInputTag = document.querySelector(
      ".react-tagsinput-input"
    ) as HTMLInputElement;
    if (
      TagsInputTag !== null &&
      (TagsInputTag.value.slice(-1) === "," ||
        TagsInputTag.value.slice(-1) === " ")
    ) {
      let email = TagsInputTag.value;
      email = email.replace(/\,/g, "");
      email = email.replace(/\s/g, "");
      if (this.emailValidate(email)) {
        let emailtags = this.state.emailtags;
        emailtags.push(email);
        this.setState({ emailtags: emailtags });
        console.log(this.tagRef.current.clearInput());
      } else {
        this.setState({ formSubmitWarning: "Please add valid email" });
      }
    } else {
      if (TagsInputTag.value !== "") {
        this.setState({ formSubmitWarning: "" });
      }
    }
  };

  handleReassignTask = async () => {
    let emailtags = this.state.emailtags;
    if (emailtags.length === 0) {
      this.setState({ formSubmitWarning: "Please add email" });
    } else {
      let data = {
        receipents: emailtags,
        task_list: this.props.id,
      };
      console.log("handleReassignTask", data);
      if (this.props.id) {
        this.props.sendQAEmailDailyTask(data);
      }
    }
  };

  handleTasg = (e: any) => {
    if (this.emailValidate(e[e.length - 1])) {
      this.setState({ emailtags: e });
    } else {
      this.setState({ emailtags: [] });
      this.setState({ formSubmitWarning: "Please add valid email" });
    }
  };

  render(): React.ReactNode {
    return (
      <div>
        <Formik
          initialValues={{
            email: [],
          }}
          enableReinitialize={true}
          onSubmit={this.handleReassignTask}
        >
          {(props) => {
            const { values } = props;
            return (
              <Form>
                <div className="row">
                  <div className="mb-2 col-md-12">
                    <label className="form-label">
                      Send email (
                      <small>
                        Please enter space or comma to add new email
                      </small>
                      )<span className="text-project">*</span>
                    </label>
                    <TagsInput
                      ref={this.tagRef}
                      value={this.state.emailtags}
                      onChange={(e) => this.handleTasg(e)}
                    />
                    {this.state.formSubmitWarning !== "" && (
                      <div className="cus-alert">
                        {this.state.formSubmitWarning}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mt-3 mb-3">
                  <div className="col-md-12">
                    <div className="d-flex">
                      <div className="mr-16px">
                        <button
                          type="submit"
                          id="create-button"
                          className="btn btn-primary create-button"
                        >
                          Send
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn cancel-button"
                          onClick={() =>
                            this.props.updateSendQAEmailDailyTaskDlgStatus(
                              false
                            )
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  cleanerList: state.tasktemplate.cleanerList,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    // listAllCleaner: bindActionCreators(listAllCleaner, dispatch),
    editDailyTask: bindActionCreators(editDailyTask, dispatch),
    sendQAEmailDailyTask: bindActionCreators(sendQAEmailDailyTask, dispatch),
    updateSendQAEmailDailyTaskDlgStatus: bindActionCreators(
      updateSendQAEmailDailyTaskDlgStatus,
      dispatch
    ),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendQAEmailDailyTask);
