import { DisableSVG, EnableSVG } from "../../assets/images/UsermanagementsSVG";
import { Dispatch } from "redux";
import AttachmentPreview from "../../shared/Components/AttachmentPreview";
import Constants from "../../shared/Constants";
import { Modal } from "react-bootstrap";
import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
import docImage from "../../assets/images/google-docs.png";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";
import moment from "moment";

export interface ITaskViewProps {
  handleViewClose: any;
  displayView: any;
  selectedDailyTaskData: any;
  userLoginDetails: any;
}

export interface ITaskViewState {
  attachmentFiles: string;
  attachmentUrls: string;
  AttachmentPreviewStatus: boolean;
  previewUrl: string;
  previewType: string;
}

class TaskView extends React.Component<ITaskViewProps, ITaskViewState> {
  constructor(props: ITaskViewProps) {
    super(props);
    this.state = {
      attachmentFiles: "",
      attachmentUrls: "",
      AttachmentPreviewStatus: false,
      previewUrl: "",
      previewType: "",
    };
  }

  handlePreviewClose = () => {
    this.setState({ AttachmentPreviewStatus: false });
  };
  handlePreviewOpen = (type: any, url: any) => {
    if (
      type === "image/png" ||
      type === "image/jpeg" ||
      type === "image/jpg" ||
      type === "video/mp4" ||
      type === "video/webm" ||
      type === "application/pdf"
    ) {
      this.setState({
        AttachmentPreviewStatus: true,
        previewUrl: url,
        previewType: type,
      });
    }
  };
  render(): React.ReactNode {
    const {
      title,
      first_name,
      last_name,
      branch_name,
      task_date,
      start_time,
      end_time,
      task_type,
      task_status_name,
      cleaners,
      attachments,
      task_feedback_name,
      status,
    } = this.props.selectedDailyTaskData;

    //Handle the case where task_feedback_name is null
    const taskFeedbackStatus = task_feedback_name
      ? task_feedback_name.toLowerCase()
      : "Unknown";

    return (
      <div>
        <Modal
          size="lg"
          dialogClassName="modal-90w"
          aria-labelledby="contained-modal-title-vcenter"
          show={this.props.displayView}
          onHide={() => this.props.handleViewClose(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              <h3 className="m-0">Daily Task</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <div className="row">
              <div className="col-6">
                <p className="mb-1">Task Name</p>
                <p className="fw-600">{title}</p>
              </div>
              <div className="col-6">
                <p className="mb-1">Task Type</p>
                <p className="fw-600">
                  {" "}
                  {task_type === Constants.REGULAR_TASK
                    ? "Regular"
                    : task_type === Constants.SPECIAL_REQUEST
                    ? "Special Request"
                    : ""}
                </p>
              </div>
              <div className="col-6">
                <p className="mb-1">Client</p>
                <p className="fw-600">{first_name + " " + last_name}</p>
              </div>
              <div className="col-6">
                <p className="mb-1">Branch</p>
                <p className="fw-600">{branch_name}</p>
              </div>
              <div className="col-6">
                <p className="mb-1">Date</p>
                <p className="fw-600">{task_date}</p>
              </div>
              <div className="col-6">
                <p className="mb-1">Time</p>
                <p className="fw-600">
                  {" "}
                  {start_time === null
                    ? `Any Time`
                    : moment(start_time, "HH:mm:ss").format("hh:mm A") +
                      "-" +
                      moment(end_time, "HH:mm:ss").format("hh:mm A")}
                </p>
              </div>
              <div className="col-md-6 mb-2">
                <p className="mb-1 fw-600">Is Active</p>
                {status !== Constants.IS_ACTIVE && <DisableSVG />}
                {status === Constants.IS_ACTIVE && <EnableSVG />}
              </div>

              <div className="col-6">
                <p className="mb-1 fw-600">Task Status</p>
                <p
                  className={
                    task_status_name + "-badge view-task-comment-badge mb-1"
                  }
                >
                  {task_status_name}
                </p>
              </div>
              {/* Special request task feedback */}
              {this.props.userLoginDetails?.permission
                ?.task_add_special_task === 1 &&
                task_feedback_name !== null && (
                  <div className="col-6">
                    <p className="mb-1 fw-600">Task Feedback</p>
                    <p
                      className={
                        task_feedback_name +
                        "-badge view-task-comment-badge mb-1"
                      }
                    >
                      {task_feedback_name}
                    </p>
                  </div>
                )}

              {cleaners.length > 0 && (
                <div className="col-6">
                  <p className="mb-1">Cleaner Count</p>
                  <p className="fw-600">{cleaners.length}</p>
                </div>
              )}
              {cleaners.length > 0 && (
                <div className="col-6 row">
                  <p className="mb-1">Cleaner List</p>
                  <p className="fw-600"></p>
                  {cleaners.length > 0
                    ? cleaners.map((value: any, index: number) => {
                        return (
                          <p className="fw-600">
                            {index +
                              1 +
                              ". " +
                              value.first_name +
                              " " +
                              value.last_name}
                          </p>
                        );
                      })
                    : null}
                </div>
              )}

              {attachments.length > 0 && (
                <div className="col-12 row">
                  <p className="mb-1">Attachments</p>
                  <p className="fw-600"></p>
                  {attachments.length > 0
                    ? attachments.map((obj: any) => {
                        return (
                          <div className="img-card mr-16px mb-3">
                            <div
                              className="img-preview"
                              onClick={() =>
                                this.handlePreviewOpen(obj.type, obj.s3_url)
                              }
                            >
                              <img
                                className="img-thumb-perview"
                                src={
                                  obj.type === "application/pdf"
                                    ? pdfImage
                                    : obj.type === "video/mp4" ||
                                      obj.type === "video/webm"
                                    ? videoImage
                                    : obj.type === "image/png" ||
                                      obj.type === "image/jpeg" ||
                                      obj.type === "image/jpg"
                                    ? obj.s3_url
                                    : docImage
                                }
                              />
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
        <AttachmentPreview
          displayPreview={this.state.AttachmentPreviewStatus}
          previewUrl={this.state.previewUrl}
          previewType={this.state.previewType}
          handlePreviewClose={this.handlePreviewClose}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  selectedDailyTaskData: state.dailytask.selectedDailyTaskData,
  userLoginDetails: state.sidebar.userLoginDetails,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskView);
