import { AnyAction } from "redux";
import { IContractorType } from "../../types/contractor.type";
import { TContractorTable } from "../../types/user.type";
import { ICleaner } from "./TaskTemplateActionTypes";

export const API_GET_ALL_CONTRACTORS = "get_all_contractor/LOAD";
export const API_GET_ALL_CONTRACTORS_SUCCESS = "get_all_contractor/LOAD_SUCCESS";
export const API_GET_ALL_CONTRACTORS_FAIL = "get_all_contractor/LOAD_FAIL";
export const API_ADD_CONTRACTOR = "contractor/LOAD";
export const API_ADD_CONTRACTOR_SUCCESS = "contractor/LOAD_SUCCESS";
export const API_ADD_CONTRACTOR_FAIL = "contractor/LOAD_FAIL";
export const API_ADD_CONTRACTOR_PROGRESS = "API_ADD_CONTRACTOR_PROGRESS";
export const UPDATE_ADD_CONTRACTOR_DLG_STATUS = "UPDATE_ADD_CONTRACTOR_DLG_STATUS";
export const API_EDIT_CONTRACTOR = "edit_contractor/LOAD";
export const API_EDIT_CONTRACTOR_SUCCESS = "edit_contractor/LOAD_SUCCESS";
export const API_EDIT_CONTRACTOR_FAIL = "edit_contractor/LOAD_FAIL";
export const API_EDIT_CONTRACTOR_PROGRESS = "API_EDIT_CONTRACTOR_PROGRESS"
export const UPDATE_EDIT_CONTRACTOR_DLG_STATUS =
  "UPDATE_EDIT_CONTRACTOR_DLG_STATUS";
export const API_DELETE_CONTRACTOR = "delete_contractor/LOAD";
export const API_DELETE_CONTRACTOR_SUCCESS = "delete_contractor/LOAD_SUCCESS";
export const API_DELETE_CONTRACTOR_FAIL = "delete_contractor/LOAD_FAIL";
export const UPDATE_DELETE_CONTRACTOR_DLG_STATUS =
  "UPDATE_DELETE_CONTRACTOR_DLG_STATUS";
export const API_GET_CONTRACTOR_BY_ID = "contractor_by_id/LOAD";
export const API_GET_CONTRACTOR_BY_ID_SUCCESS = "contractor_by_id/LOAD_SUCCESS";
export const API_GET_CONTRACTOR_BY_ID_FAIL = "contractor_by_id/LOAD_FAIL";
export const UPDATE_CONTRACTOR_FETCH_STATUS = "UPDATE_CONTRACTOR_FETCH_STATUS";
export const FILTER_CONTRACTOR = "FILTER_STATUS";
export const UPDATE_CONTRACTORS = "UPDATE_CONTRACTORS";
export const UPDATE_CONTRACTOR_DLG_STATUS = "UPDATE_CONTRACTOR_DLG_STATUS";
// export const API_DELETE_ATTACHMENT = "ATTACHMENT_DELETE";
export const API_VIEW_CONTRACTOR_BY_ID = "view_contractor/LOAD";
export const API_VIEW_CONTRACTOR_BY_ID_SUCCESS = "view_contractor/LOAD_SUCCESS";
export const API_VIEW_CONTRACTOR_BY_ID_FAIL = "view_contractor/LOAD_FAIL";
export const UPDATE_VIEW_CONTRACTOR = "UPDATE_VIEW_CONTRACTOR";
export const CONTRACTOR_SORT = "CONTRACTOR_SORT";

export interface IAddClientContractor {
  client: string;
  attachments: any | Blob;
}

export interface IEditClientContractor {
  client: string;
  branch: string;
  title: string;
  is_active: string;
  attachments: any | Blob;
  description: string;
}

export interface ContractorTemplateState {
  cleanerList: ICleaner[];
  clientList: ICleaner[];
  addContractorStatus: boolean;
  editContractorStatus: boolean;
  deleteContractorStatus: boolean;
  contractorFetchStatus: boolean;
  addContractorSuccess: string;
  addContractorError: string;
  editContractorSuccess: string;
  editContractorError: string;
  deleteContractorSuccess: any;
  deleteContractorError: string;
  selectedContractorData: IContractorType | null;
  id: string;
  contractorData: TContractorTable[];
  filteredContractorData: TContractorTable[];
  total_count: number;
  processTrigger:boolean;
  progressPercentage:number;
  viewContractorData: IContractorType | null;
  viewContractorStatus: boolean;
  contractorSortBy : string;
  contractorOrderBy : string;
}

export type ContractorTemplateDispatchType = (args: AnyAction) => AnyAction;
