import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../..';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TableDeleteSVG, TableEditSVG, TableViewSVG } from '../../assets/images/ImagesV2';
import { getRecommendedTaskById, getBranchById, getAllRecommendedTasks, moveToTask, getAllRecommendedCleanerTasks } from "../../shared/Reducers";
import { Dispatch, bindActionCreators } from 'redux';
import RecommendedView from '../Recommended/RecommendedView';
import ApprovedRecommendedTask from '../Recommended/ApprovedRecommendeTask';
import DeleteRecommendedTask from '../Recommended/DeleteRecommendedTask';
import Constants from '../../shared/Constants';
import moment from 'moment';
interface ICleanerInOutProps {
    status: any;
    // handleToOpenEdit: any;
    task: any;
    handleEditDailyTask: any;
    getRecommendedTaskById: any;
    userLoginPermission: any;
    getBranchById: any;
    selectedAdditionalTaskData: any;
    selectedRecommendedTaskData: any;
    handleDeleteDailyTask: any;
    getAllRecommendedTasks: any;
    id: any;
    moveToTask: any;
    getAllRecommendedCleanerTasks: any;
}

interface ICleanerInOutState {
    displayView: any;
    displayApproved: any;
    displayDelete: any;
    currentTaskId: any;
}
class RecommendedCard extends Component<ICleanerInOutProps, ICleanerInOutState> {
    constructor(props: ICleanerInOutProps) {
        super(props);
        this.state = {
            displayView: false,
            displayApproved: false,
            displayDelete: false,
            currentTaskId: "",
        }
    }

    componentDidMount(): void {
        this.props.getRecommendedTaskById();
    }
    handleView = async (id: string) => {
        await this.setState({ displayView: true });
        await this.props.getRecommendedTaskById(id);
        console.log("View id", id);
    };

    handleApproved = async (id: any) => {
        console.log("approvedid", id);

        this.setState({
            displayApproved: true
        });
        await this.props.getRecommendedTaskById(id);
    };

    handleViewClose = () => {
        this.setState({ displayView: false });
        this.setState({ displayApproved: false });
    };

    handleDelete = (id: string) => {
        this.setState({ displayDelete: true, currentTaskId: id });
        console.log(id);
    };

    handleDeleteClose = (status: boolean) => {
        console.log(this.state.currentTaskId);

        this.setState({ displayDelete: false, currentTaskId: "" });
        if (status) {
            this.props.handleDeleteDailyTask(this.state.currentTaskId);
        }
        this.getTasks();
    };

    getTasks = async () => {
        try {
            if (Constants.LOGIN_USER_TYPE === 3) {
                // list for table
                let query = `search=&sort=&order=&offset=0&limit=10&client=all&branch=all&task_priority=&due_type=&task_status=&special_request_task_status=&recommended_task_status=all&cleaner_id=${Constants.LOGIN_USER_ID}`;

                // list for excel report
                // let AllListquery = `search=&sort=&order=&offset=${offset}&limit=10000&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=3&task_priority=${this.props.filterTaskPriorityById}&due_type=${this.props.filterTaskDueTypeByValue}&task_status=${this.props.filterTaskStatusById}&special_request_task_status=${this.props.filterSpecialRequestTaskStatusById}&status&start_date=${this.props.filterTaskDateTypeValue.from_date}&end_date=${this.props.filterTaskDateTypeValue.to_date}`;
                await this.props.getAllRecommendedCleanerTasks(query);
                // await this.props.getAllRecommendedTasks(AllListquery);
            } else {
                // list for table
                let query = `search=&sort=&order=&offset=0&limit=10&client=all&branch=all&task_type=3&task_priority=&due_type=&task_status=&special_request_task_status=&recommended_task_status=all`;

                // list for excel report
                // let AllListquery = `search=&sort=&order=&offset=${offset}&limit=10000&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=3&task_priority=${this.props.filterTaskPriorityById}&due_type=${this.props.filterTaskDueTypeByValue}&task_status=${this.props.filterTaskStatusById}&special_request_task_status=${this.props.filterSpecialRequestTaskStatusById}&status&start_date=${this.props.filterTaskDateTypeValue.from_date}&end_date=${this.props.filterTaskDateTypeValue.to_date}`;
                await this.props.getAllRecommendedTasks(query);
                // await this.props.getAllRecommendedTasks(AllListquery);
            }
        } catch (error) {
            console.log(error);
        }
    };

    handleToMoveTask = (id: string) => {
        console.log("handleToMoveTask", id);
        let data = { recommended_task_status: 1, additional_task_status: 1 };
        this.props.moveToTask(data, id);
        this.getTasks();
    };

    render() {

        const {
            id,
            title,
            first_name,
            task_type,
            last_name,
            branch_name,
            task_date,
            description,
            branch_id,
            created_user,
            created_user_name,
            created_role_name,
            recommended_task_status,
        } = this.props.task
        return (
            <>
                <tr>
                    {Constants.LOGIN_USER_TYPE !== 3 ? <td className='text-capitalize'>{created_role_name}</td> : <td className='text-capitalize'>{created_user.name}</td>}
                    {Constants.LOGIN_USER_TYPE !== 3 ? <td className='text-capitalize'>{created_user_name}</td> : <td className='text-capitalize'>{`${created_user.first_name}${created_user.last_name}`}</td>}
                    {this.props.userLoginPermission?.recommended_approved === 1 &&
                        <td>
                            <a
                                className="pointer"
                                href="javascript:void(0)"
                                onClick={() => this.handleToMoveTask(id)}
                            >
                                {recommended_task_status === 0 ?
                                    <button className='move-to-task-btn'>
                                        <span className="move-to-task-text">Request</span>
                                    </button> :
                                    <button className='move-to-recommende-task-btn' disabled>
                                        <span className="move-to-recommended-task-text">Approved</span>
                                    </button>
                                }
                            </a>
                        </td>
                    }
                    <td>{task_type === Constants.RECOMMENDED_TASK ? "Recommended Task" : ""}</td>
                    <td>{`${first_name} ${last_name}`}</td>
                    <td>{branch_name}</td>
                    <td>{moment(new Date(task_date?.toString())).format('DD-MM-YYYY')}</td>
                    {/* {this.props.userLoginPermission?.recommended_approved === 1 &&
                        <td>
                            <span className='mr-20px'>
                                <a
                                    className="pointer"
                                    href="javascript:void(0)"
                                    onClick={() => this.handleApproved(id)}
                                >
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={<Tooltip>{"Approved This Recommended Task"}</Tooltip>}
                                    >
                                        <Button className='approved-btn'>
                                            Request
                                        </Button>
                                    </OverlayTrigger>
                                </a>
                            </span>
                        </td>
                    } */}
                    <td>
                        <div className='btn-group'>
                            <span className='mr-20px'>
                                <a
                                    className="pointer"
                                    href="javascript:void(0)"
                                    onClick={() => this.handleView(id)}
                                >
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={<Tooltip>{"View"}</Tooltip>}
                                    >
                                        <Button className='action-btn'>
                                            <TableViewSVG />
                                        </Button>
                                    </OverlayTrigger>
                                </a>
                            </span>
                            {this.props.userLoginPermission?.recommended_edit === 1 &&
                                <span className='mr-20px'>
                                    <a
                                        href='javascript:void(0)'
                                        onClick={async () => {
                                            await this.props.handleEditDailyTask(id)
                                            this.props.getBranchById(branch_id)
                                        }}
                                    >
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip>{"Edit"}</Tooltip>}
                                        >
                                            <Button className='action-btn'>
                                                <TableEditSVG />
                                            </Button>
                                        </OverlayTrigger>
                                    </a>
                                </span>
                            }
                            {this.props.userLoginPermission?.recommended_delete === 1 &&
                                <span className="mr-20px" title="Delete">
                                    <a
                                        href="javascript:void(0)"
                                        onClick={() => this.handleDelete(id)}
                                    >
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip>{"Delete"}</Tooltip>}
                                        >
                                            <Button className='action-btn'>
                                                <TableDeleteSVG />
                                            </Button>
                                        </OverlayTrigger>
                                    </a>
                                </span>
                            }
                            {this.state.displayView &&
                                this.props.selectedRecommendedTaskData && (
                                    <RecommendedView
                                        displayView={this.state.displayView}
                                        handleViewClose={this.handleViewClose}
                                    />
                                )}
                            <DeleteRecommendedTask
                                deletedName={title}
                                displayDelete={this.state.displayDelete}
                                handleDeleteClose={this.handleDeleteClose}
                            />
                            {this.state.displayApproved && (
                                <ApprovedRecommendedTask
                                    id={this.props.id}
                                    displayApproved={this.state.displayApproved}
                                    handleViewClose={this.handleViewClose}
                                />
                            )}
                        </div>
                    </td>
                </tr>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    status: state.sidebar.status,
    userLoginPermission: state.sidebar.userLoginPermission,
    selectedAdditionalTaskData: state.dailytask.selectedAdditionalTaskData,
    selectedRecommendedTaskData: state.dailytask.selectedRecommendedTaskData,
    id: state.dailytask.recommendedTaskId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getRecommendedTaskById: bindActionCreators(getRecommendedTaskById, dispatch),
    getBranchById: bindActionCreators(getBranchById, dispatch),
    getAllRecommendedTasks: bindActionCreators(getAllRecommendedTasks, dispatch),
    moveToTask: bindActionCreators(moveToTask, dispatch),
    getAllRecommendedCleanerTasks: bindActionCreators(getAllRecommendedCleanerTasks, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedCard);
