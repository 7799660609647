import * as Yup from "yup";

import Constants, { FILTER_USER_TABLE } from "../../shared/Constants";
import { Dispatch, bindActionCreators } from "redux";
import { Field, Form, Formik } from "formik";
import {
  IUsermanagementProps,
  IUsermanagementState,
} from "../../types/userManagement.type";
import {
  filterStatus,
  filterUsermanagement,
  getAllBranch,
  getAllUsermanagements,
  handleFilterRole,
  updateSidebar,
  updateUsermanagementFetchStatus,
  updateUsermanagementStatus,
  getUserTypesList
} from "../../shared/Reducers/index";

import AddUsermanagement from "./AddUsermanagement";
import { Button } from "react-bootstrap";
import { CloseBtnSVG } from "../../assets/images/ImagesSVG";
import CustomSelect from "../../shared/Components/CustomSelect";
import { FILTER_USER_ROLE } from "../../shared/Constants";
import Offcanvas from "react-bootstrap/Offcanvas";
import ProfileImg from "../../assets/images/profile.png";
import React from "react";
import ReactPaginate from "react-paginate";
import { RootState } from "../../index";
import { SearchSVG } from "../../assets/images/UsermanagementsSVG";
import Select from "react-select";
import SideBar from "../../components/sidebar/SideBar";
import { SuccessToastSVG } from "../../assets/images/ImagesV2";
import { Toast } from "react-bootstrap";
import TopNav from "../../components/topnav/TopNav";
import UsermanagementTable from "./UsermanagementTable";
import { connect } from "react-redux";
import { type } from "os";

const reactSelectCusStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#fff",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    textTransform: 'capitalize',
  }),
};

class Usermanagement extends React.Component<
  IUsermanagementProps,
  IUsermanagementState
> {
  private timeoutId: number | undefined;
  constructor(props: IUsermanagementProps) {
    super(props);
    this.state = {
      searchTerm: "",
      active_usermanagement: "",
      inactive_usermanagement: "",
      status: "all",
      showToast: false,
      editToast: false,
      deleteToast: false,
      emailVerifyToast: false,
      addUserModal: false,
      fieldName: "",
      orderType: "",
      userTableData: [],
      pagehandle: false,
      timeout: null, // Initialize the timeout property
      userTypesStateList: [],

    };
  }

  componentDidMount(): void {
    (async () => {
    this.props.updateSidebar(true);
    document.title = "User Management";
    this.getUsermanagements(0, 10);
    if (this.props.total_count) {
      console.log(this.props.total_count);
    }
    this.props.handleFilterRole("all");
   await this.props.getUserTypesList();
   var newArray:any =[]
 
  newArray.push( { id: "all", name:"All", clientbranchaccess:"all" })
  newArray.push(... this.props.userTypesList.filter((Utypes: any) => Utypes.id !== Constants.ADMIN))
    this.setState({ userTypesStateList: newArray })
  })();
  }

  componentDidUpdate(
    prevProps: Readonly<IUsermanagementProps>,
    prevState: Readonly<IUsermanagementState>,
    snapshot?: any
  ): void {
    if (
      prevProps.addUsermanagementStatus !== this.props.addUsermanagementStatus
    ) {
      if (!this.props.addUsermanagementStatus) {
        this.getUsermanagements(0, Constants.PER_PAGE);
      }
    }
    if (
      prevProps.usermanagementFetchStatus !==
      this.props.usermanagementFetchStatus
    ) {
      if (this.props.usermanagementFetchStatus) {
        this.getUsermanagements(0, Constants.PER_PAGE);
      }
    }
    if (
      prevProps.addUsermanagementSuccess !== this.props.addUsermanagementSuccess
    ) {
      if (this.props.addUsermanagementSuccess === "Successfully created") {
        this.handleAddUsermanagementClose(true);
        this.setState({ showToast: true });
        setTimeout(() => {
          this.setState({ showToast: false });
        }, 5000);
      }
    }
    if (
      prevProps.editUsermanagementSuccess !==
      this.props.editUsermanagementSuccess
    ) {
      if (this.props.editUsermanagementSuccess === "Successfully updated") {
        this.setState({ editToast: true });
        setTimeout(() => {
          this.setState({ editToast: false });
        }, 5000);
        // this.getUsermanagements(1, Constants.PER_PAGE);
      }

    }
    if (
      prevProps.emailVerifyUsermanagementSuccess !==
      this.props.emailVerifyUsermanagementSuccess
    ) {
      // don't delete logs
      console.log(prevProps.emailVerifyUsermanagementSuccess);
      console.log(this.props.emailVerifyUsermanagementSuccess);
    
      if (this.props.emailVerifyUsermanagementSuccess === "Successfully verified") {
          
        // page reset 
        let Offset: number = 0;
        let paginationActive = document.querySelector(
          ".pagination li.active a"
        ) as HTMLInputElement;
        if (paginationActive !== null) {
          let page: string | null = paginationActive.textContent;
          if (page !== null) {
            let pageNum: number = parseInt(page);
            Offset = (pageNum - 1) * Constants.PER_PAGE;
            this.getUsermanagements(Offset, Constants.PER_PAGE);
          }
        }

        this.setState({ emailVerifyToast: true });
        setTimeout(() => {
          this.setState({ emailVerifyToast: false });
        }, 5000);
      }
    }
    if (
      prevProps.deleteUsermanagementSuccess !==
      this.props.deleteUsermanagementSuccess
    ) {
      if (this.props.deleteUsermanagementSuccess === "Successfully deleted") {
        // page reset 
        let Offset: number = 0;
        let paginationActive = document.querySelector(
          ".pagination li.active a"
        ) as HTMLInputElement;
        if (paginationActive !== null) {
          let page: string | null = paginationActive.textContent;
          if (page !== null) {
            let pageNum: number = parseInt(page);
            Offset = (pageNum - 1) * Constants.PER_PAGE;
            this.getUsermanagements(Offset, Constants.PER_PAGE);
          }
        }

        this.setState({ deleteToast: true });
        setTimeout(() => {
          this.setState({ deleteToast: false });
        }, 5000);
      }
    }
    if (prevProps.filterUserRoleById !== this.props.filterUserRoleById) {
      this.getUsermanagements(0, Constants.PER_PAGE);
    }
  }

  addClose = () => {
    this.setState({ showToast: false });
  };

  editClose = () => {
    this.setState({ editToast: false });
  };

  deleteClose = () => {
    console.log("call");
    this.setState({ deleteToast: false });
  };

  emailVerifyClose = () => {
    this.setState({ emailVerifyToast: false });
  }

  // Get All Usermanagements data
  getUsermanagements = async (offset: number, perPage: number) => {
    try {
      const query = `search=${this.state.searchTerm}&sort=&order=&offset=${offset}&limit=${perPage}&user_type=${this.props.filterUserRoleById}`;
      await this.props.getAllUsermanagements(query);
      await this.props.getAllBranch("1");
      this.setState({ userTableData: this.props.filteredData });
    } catch (error) {
      console.log(error);
    }
  };

  // After add Usermanagement Close the canvas
  handleAddUsermanagementClose = async (status: boolean) => {
    this.handleClose();
    const query = `search=&sort=&order=&offset=${Constants.OFFSET}&limit=${Constants.PER_PAGE}&user_type=all`;
    await this.props.getAllUsermanagements(query);
    this.setState({ userTableData: this.props.filteredData });
    if (status) {
      this.getUsermanagements(Constants.OFFSET, Constants.PER_PAGE);
    }
  };

  // Close the canvas
  handleClose() {
    this.setState({ addUserModal: false });
  }

  // Search Filter
  handleFilter = async (e: any) => {
    const filterVal: string = e.target.value.toLowerCase();
    // this.props.filterUsermanagement(filterVal);
    // if (e.target.value === "") {
    //   this.getUsermanagements(Constants.OFFSET, Constants.PER_PAGE);
    // }
  };

  handleTyping = (e: any) => {
    const value: string = e.target.value.toLowerCase();

    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

    this.setState({
      searchTerm: value,
      timeout: setTimeout(async () => {
        console.log("User stopped typing:", value);
        try {
          // const query = `search=${value}&sort=&order=&offset=0&limit=10&user_type=${this.props.filterUserRoleById}`;
          const query = `search=${encodeURIComponent(value)}&sort=&order=&offset=0&limit=10&user_type=${this.props.filterUserRoleById}`;
          console.log(query,"today");
          
          await this.props.getAllUsermanagements(query);
          this.setState({ userTableData: this.props.filteredData });          
        } catch (error) {
          console.log(error);
        }
        // Trigger your function here that you want to call when user stops typing
      }, 1000), // Set the delay time in ms
    });
  };

  // Active Status Filter
  handleStatusFilter = (e: any) => {
    const status = e.target.value;
    this.setState({ status: e.target.value });
    this.props.filterStatus(status);
  };

  handlePageClick = async (e: any) => {
    if (e.selected < this.props.total_count / Constants.PER_PAGE) {
      const query = `search=${this.state.searchTerm}&sort=${
        this.state.fieldName
      }&order=${this.state.orderType}&offset=${
        e.selected * Constants.PER_PAGE
      }&limit=${Constants.PER_PAGE}&user_type=${this.props.filterUserRoleById}`;
      console.log(query);

      await this.props.getAllUsermanagements(query);
      this.setState({ userTableData: this.props.filteredData });
      // this.getUsermanagements(
      //   e.selected * Constants.PER_PAGE,
      //   Constants.PER_PAGE
      // );
    }
  };

  validationSchema = () => {
    return Yup.object().shape({
      column_name: Yup.string().required("Select Filter Column"),
      order_type: Yup.string().required("Select Sort Order"),
    });
  };
  handleSearch = async (formValue: any) => {
    try {
      this.setState({ pagehandle: true }, () => {
        setTimeout(async () => {
          this.setState({
            fieldName: formValue.column_name,
            orderType: formValue.order_type,
            pagehandle: false,
          });
          const query = `search=&sort=${formValue.column_name}&order=${formValue.order_type}&offset=${Constants.OFFSET}&limit=${Constants.PER_PAGE}&user_type=${this.props.filterUserRoleById}`;
          await this.props.getAllUsermanagements(query);
          await this.props.getAllBranch("1");
          this.setState({ userTableData: this.props.filteredData });
        }, 0);
      });
    } catch (error) {
      console.log(error);
    }
  };

  render(): React.ReactNode {
    const initialValue = {};
    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className={this.props.status ? "main-panel" : "main-panel2"}>
          <TopNav />
          <div className="content-wrapper">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="mb-0">User management</h3>
                </div>
                <div className="col-md-6">
                  <div className="clearfix"></div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12 col-lg-4 pr-5px">
                  <div className="input-group search">
                    <span
                      className="input-group-text search-icon"
                      id="basic-addon1"
                    >
                      <SearchSVG />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={this.state.searchTerm}
                      onChange={this.handleTyping}
                    />
                  </div>
                </div>
                <div className="col-md-12 col-lg-4 tab-res-mt-3">
                  <Select
                    placeholder="Select Role"
                    styles={reactSelectCusStyles}
                    isMulti={false}
                    // options={FILTER_USER_ROLE}
                    options={this.state.userTypesStateList.map((Utype: any) => {
                      return (
                        { value: Utype.id, label: Utype.name, branchaccess: Utype.clientbranchaccess }
                      )
  
                    }
                    )}
                    onChange={(e: any) => this.props.handleFilterRole(e.value)}
                  />
                </div>

                {this.state.showToast ? (
                  <Toast className="success-alerts">
                    <Toast.Body>
                      <div className="row align-items-center">
                        <div className="col-md-2">
                          <SuccessToastSVG />
                        </div>
                        <div className="col-md-8">
                          {this.props.addUsermanagementSuccess}
                        </div>
                        <div className="col-md-2">
                          <button
                            type="button"
                            className="btn"
                            onClick={this.addClose}
                          >
                            <CloseBtnSVG />
                          </button>
                        </div>
                      </div>
                    </Toast.Body>
                  </Toast>
                ) : (
                  ""
                )}
                {this.state.editToast ? (
                  <Toast className="success-alerts">
                    <Toast.Body>
                      <div className="row align-items-center">
                        <div className="col-md-2">
                          <SuccessToastSVG />
                        </div>
                        <div className="col-md-8">
                          {this.props.editUsermanagementSuccess}
                        </div>
                        <div className="col-md-2">
                          <button
                            type="button"
                            className="btn"
                            onClick={this.editClose}
                          >
                            <CloseBtnSVG />
                          </button>
                        </div>
                      </div>
                    </Toast.Body>
                  </Toast>
                ) : (
                  ""
                )}
                {this.state.deleteToast ? (
                  <Toast className="success-alerts">
                    <Toast.Body>
                      <div className="row align-items-center">
                        <div className="col-md-2">
                          <SuccessToastSVG />
                        </div>
                        <div className="col-md-8">
                          {this.props.deleteUsermanagementSuccess}
                        </div>
                        <div className="col-md-2">
                          <button
                            type="button"
                            className="btn"
                            onClick={this.deleteClose}
                          >
                            <CloseBtnSVG />
                          </button>
                        </div>
                      </div>
                    </Toast.Body>
                  </Toast>
                ) : (
                  ""
                )}
                {this.state.emailVerifyToast ? (
                  <Toast className="success-alerts">
                    <Toast.Body>
                      <div className="row align-items-center">
                        <div className="col-md-2">
                          <SuccessToastSVG />
                        </div>
                        <div className="col-md-8">
                          {this.props.emailVerifyUsermanagementSuccess}
                        </div>
                        <div className="col-md-2">
                          <button
                            type="button"
                            className="btn"
                            onClick={this.emailVerifyClose}
                          >
                            <CloseBtnSVG />
                          </button>
                        </div>
                      </div>
                    </Toast.Body>
                  </Toast>
                ) : (
                  ""
                )}
              </div>
              <div className="row mt-3">
                <div className="col-md-12 col-lg-6">
                  <div>
                    <Formik
                      onSubmit={this.handleSearch}
                      validationSchema={this.validationSchema}
                      initialValues={{
                        column_name: "",
                        order_type: "asc",
                      }}
                    >
                      <Form className="d-flex flex-column flex-md-row">
                        <Field
                          className="custom-select dropdown-field flex-mr-16px filter-w-180px"
                          name="column_name"
                          options={FILTER_USER_TABLE}
                          component={CustomSelect}
                          placeholder="Select Column"
                          isMulti={false}
                        />
                        <Field
                          className="custom-select dropdown-field flex-mr-16px res-mt-3 filter-w-180px"
                          name="order_type"
                          options={[
                            { value: "asc", label: "Ascending" },
                            { value: "desc", label: "Descending" },
                          ]}
                          component={CustomSelect}
                          placeholder="Select Order"
                          isMulti={false}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary filter-button res-mt-3"
                        >
                          Filter
                        </button>
                      </Form>
                    </Formik>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 tab-res-mt-3">
                  <div className="text-start text-lg-end">
                    <Offcanvas
                      placement="end"
                      show={this.state.addUserModal}
                      onHide={() => this.handleClose()}
                    >
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                          <img
                            src={ProfileImg}
                            className="offc-img"
                            alt="profile"
                          />
                          <span className="offc-title">Add User</span>
                        </Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <AddUsermanagement
                          onClose={this.handleAddUsermanagementClose}
                        />
                      </Offcanvas.Body>
                    </Offcanvas>
                    <Button
                      aria-controls="offcanvasRight"
                      className="btn btn-primary login-button mob-res-w-100 add-user-button"
                      onClick={() => this.setState({ addUserModal: true })}
                    >
                      Add User
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {this.props.filteredData.length > 0 ? (
                      <div className="table-responsive">
                        <table className="table usermanagement-table">
                          <thead>
                            <tr>
                              {/* <th scope="col">ID</th> */}
                              <th scope="col">ROLE</th>
                              <th scope="col">NAME</th>
                              <th scope="col">EMAIL</th>
                              {/* <th scope="col">Permissions</th> */}
                              <th scope="col">PHONE</th>
                              <th scope="col">IS ACTIVE</th>
                              <th scope="col">ACTION</th>
                            </tr>
                          </thead>
                          <UsermanagementTable
                            data={this.props.filteredData}
                            onDelete={() =>
                              this.getUsermanagements(
                                Constants.OFFSET,
                                Constants.PER_PAGE
                              )
                            }
                            onEditClose={() =>
                              this.getUsermanagements(
                                Constants.OFFSET,
                                Constants.PER_PAGE
                              )
                            }
                            deleteUsermanagementStatus={false}
                            emailVerifyUsermanagementStatus={false}
                          />
                        </table>
                      </div>
                    ) : (
                      <p className="text-center">There is no user data found</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                {this.props.total_count > 10 ? (
                  <div className="col-md-12">
                    {this.state.pagehandle ? null : (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.props.total_count / Constants.PER_PAGE}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        nextClassName={"next-page"}
                        activeClassName={"active"}
                      />
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  addUsermanagementStatus: state.usermanagement.addUsermanagementStatus,
  usermanagementFetchStatus: state.usermanagement.usermanagementFetchStatus,
  filteredData: state.usermanagement.filteredData,
  data: state.usermanagement.data,
  usermanagementData: state.usermanagement.usermanagementData,
  total_count: state.usermanagement.total_count,
  addUsermanagementSuccess: state.usermanagement.addUsermanagementSuccess,
  addUsermanagementError: state.usermanagement.addUsermanagementError,
  editUsermanagementSuccess: state.usermanagement.editUsermanagementSuccess,
  deleteUsermanagementSuccess: state.usermanagement.deleteUsermanagementSuccess,
  emailVerifyUsermanagementSuccess: state.usermanagement.emailVerifyUsermanagementSuccess,
  filterUserRoleById: state.usermanagement.filterUserRoleById,
  status: state.sidebar.status,
  userTypesList: state.app.userTypesList,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateUsermanagementStatus: bindActionCreators(
      updateUsermanagementStatus,
      dispatch
    ),
    updateUsermanagementFetchStatus: bindActionCreators(
      updateUsermanagementFetchStatus,
      dispatch
    ),
    getAllUsermanagements: bindActionCreators(getAllUsermanagements, dispatch),
    getAllBranch: bindActionCreators(getAllBranch, dispatch),
    filterUsermanagement: bindActionCreators(filterUsermanagement, dispatch),
    filterStatus: bindActionCreators(filterStatus, dispatch),
    handleFilterRole: bindActionCreators(handleFilterRole, dispatch),
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
    getUserTypesList: bindActionCreators(getUserTypesList, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Usermanagement);
