
export const GET_CLEANER_LAST_ACTIVITY = "get_cleaner_last_activity/LOAD";
export const GET_CLEANER_LAST_ACTIVITY_SUCCESS = "get_cleaner_last_activity/LOAD_SUCCESS";
export const GET_CLEANER_LAST_ACTIVITY_FAIL = "get_cleaner_last_activity/LOAD_FAIL";
export const CLEANER_LOGIN_LOGS = "post_cleaner_login_logs/LOAD";
export const CLEANER_LOGIN_LOGS_SUCCESS = "post_cleaner_login_logs/LOAD_SUCCESS";
export const CLEANER_LOGIN_LOGS_FAIL = "post_cleaner_login_logs/LOAD_FAIL";

export interface CleanerInOutState {
    getLastActivityData:any;
    cleanerLoginLogs:any;
}
