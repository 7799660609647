import { AnyAction } from "redux";
import { IQuotedTaskType } from "../../types/quotedTask.type";
import { TQuotedTaskTable } from "../../types/user.type";
import { ICleaner } from "./TaskTemplateActionTypes";

export const API_GET_ALL_QUOTEDTASKS = "get_all_quotedTask/LOAD";
export const API_GET_ALL_QUOTEDTASKS_SUCCESS = "get_all_quotedTask/LOAD_SUCCESS";
export const API_GET_ALL_QUOTEDTASKS_FAIL = "get_all_quotedTask/LOAD_FAIL";
export const API_ADD_QUOTEDTASK = "quotedTask/LOAD";
export const API_ADD_QUOTEDTASK_SUCCESS = "quotedTask/LOAD_SUCCESS";
export const API_ADD_QUOTEDTASK_FAIL = "quotedTask/LOAD_FAIL";
export const API_ADD_QUOTEDTASK_PROGRESS = "API_ADD_QUOTEDTASK_PROGRESS";
export const UPDATE_ADD_QUOTEDTASK_DLG_STATUS = "UPDATE_ADD_QUOTEDTASK_DLG_STATUS";
export const API_EDIT_QUOTEDTASK = "edit_quotedTask/LOAD";
export const API_EDIT_QUOTEDTASK_SUCCESS = "edit_quotedTask/LOAD_SUCCESS";
export const API_EDIT_QUOTEDTASK_FAIL = "edit_quotedTask/LOAD_FAIL";
export const API_EDIT_QUOTEDTASK_PROGRESS = "API_EDIT_QUOTEDTASK_PROGRESS"
export const UPDATE_EDIT_QUOTEDTASK_DLG_STATUS =
  "UPDATE_EDIT_QUOTEDTASK_DLG_STATUS";
export const API_DELETE_QUOTEDTASK = "delete_quotedTask/LOAD";
export const API_DELETE_QUOTEDTASK_SUCCESS = "delete_quotedTask/LOAD_SUCCESS";
export const API_DELETE_QUOTEDTASK_FAIL = "delete_quotedTask/LOAD_FAIL";
export const UPDATE_DELETE_QUOTEDTASK_DLG_STATUS =
  "UPDATE_DELETE_QUOTEDTASK_DLG_STATUS";
export const API_GET_QUOTEDTASK_BY_ID = "quotedTask_by_id/LOAD";
export const API_GET_QUOTEDTASK_BY_ID_SUCCESS = "quotedTask_by_id/LOAD_SUCCESS";
export const API_GET_QUOTEDTASK_BY_ID_FAIL = "quotedTask_by_id/LOAD_FAIL";
export const UPDATE_QUOTEDTASK_FETCH_STATUS = "UPDATE_QUOTEDTASK_FETCH_STATUS";
export const FILTER_QUOTEDTASK = "FILTER_STATUS";
export const UPDATE_QUOTEDTASKS = "UPDATE_QUOTEDTASKS";
export const UPDATE_QUOTEDTASK_DLG_STATUS = "UPDATE_QUOTEDTASK_DLG_STATUS";
export const API_VIEW_QUOTEDTASK_BY_ID = "view_quotedTask/LOAD";
export const API_VIEW_QUOTEDTASK_BY_ID_SUCCESS = "view_quotedTask/LOAD_SUCCESS";
export const API_VIEW_QUOTEDTASK_BY_ID_FAIL = "view_quotedTask/LOAD_FAIL";
export const UPDATE_VIEW_QUOTEDTASK = "UPDATE_VIEW_QUOTEDTASK";
export const QUOTEDTASK_SORT = "QUOTEDTASK_SORT";
export const QUOTEDTASK_TASKTYPE = "QUOTEDTASK_TASKTYPE";
export const QUOTEDTASK_BRANCH = "QUOTEDTASK_BRANCH";
export const API_EXPORT_EXCEL_QUOTED_TASK = "API_EXPORT_EXCEL_QUOTED_TASK";
export const API_EXPORT_EXCEL_QUOTED_TASK_SUCCESS = "API_EXPORT_EXCEL_QUOTED_TASK_SUCCESS";
export const API_EXPORT_EXCEL_QUOTED_TASK_FAIL = "API_EXPORT_EXCEL_QUOTED_TASK_FAIL";
export const API_EXPORT_EXCEL_QUOTED_TASK_PROGRESS = "API_EXPORT_EXCEL_QUOTED_TASK_PROGRESS";

export interface IAddQuotedTask {
  client: string;
  attachments: any | Blob;
}

export interface IEditQuotedTask {
  client: string;
  branch: string;
  title: string;
  is_active: string;
  attachments: any | Blob;
  description: string;
}

export interface QuotedTaskTemplateState {
  cleanerList: ICleaner[];
  clientList: ICleaner[];
  addQuotedTaskStatus: boolean;
  editQuotedTaskStatus: boolean;
  deleteQuotedTaskStatus: boolean;
  quotedTaskFetchStatus: boolean;
  addQuotedTaskSuccess: string;
  addQuotedTaskError: string;
  editQuotedTaskSuccess: string;
  editQuotedTaskError: string;
  deleteQuotedTaskSuccess: string;
  deleteQuotedTaskError: string;
  selectedQuotedTaskData: IQuotedTaskType | null;
  id: string;
  quotedTaskData: TQuotedTaskTable[];
  filteredQuotedTaskData: TQuotedTaskTable[];
  total_count: number;
  processTrigger:boolean;
  progressPercentage:number;
  viewQuotedTaskData: IQuotedTaskType | null;
  viewQuotedTaskStatus: boolean;
  quotedTaskSortBy : string;
  quotedTaskOrderBy : string;
  quotedTaskTaskType : any;
  quotedTaskBranch : string;
  exportQuotedTaskFetchStatus: boolean,
  exportQuotedTaskData: any,
  filteredExportQuotedTaskData: any,
  export_total_count: number,
  exportQuotedTaskSortBy: string,
  exportQuotedTaskOrderBy: string,
  exportFileURL : string,
}

export type QuotedTaskTemplateDispatchType = (args: AnyAction) => AnyAction;
