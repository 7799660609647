import React from "react";
import { RootState } from '../../index';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IContractorTableProps } from "../../types/contractor.type";
import ContractorCard from "./ContractorCard";
// import EditClientContractor from "./EditClientContractor";
import { Modal, Offcanvas } from "react-bootstrap";
import { deleteContractor, getAllContractors, getContractorById, updateEditClientContractorDlgStatus, updateViewContractor, viewContractorById } from "../../shared/Reducers";
import ViewContractor from "./ViewContractor";

class ContractorTable extends React.Component<IContractorTableProps> {
    // constructor(props: IContractorTableProps ){
    //     super(props);
    // }

    handleDeleteContractor = async (id: string) => {
        await this.props.deleteContractor(id);
        // console.log(id);
        // const query = `search=&sort=&order=&offset=0&limit=100&client=all&branch=all`
        // this.props.getAllContractors(query);
    }

    handleEditBranch = async (id:string) => {
        try {
            this.props.getContractorById(id);
        } catch (error) {
            console.log(error);
        }
    }

    handleViewContractor = async (id:string) => {
        try {
            this.props.viewContractorById(id);
        } catch (error) {
            console.log(error);
        }
    }

    render(): React.ReactNode {
        return (
            <tbody>
                {
                    this.props.data.map((contractors, index) =>
                        <ContractorCard contractors={contractors}
                            key={index + contractors.id}
                            handleDeleteContractor={this.handleDeleteContractor}
                            handleEditClientContractor={this.handleEditBranch}
                            handleViewContractor={this.handleViewContractor}
                            deleteContractorStatus={false}
                         />)
                }
                {/* <Offcanvas placement="end" show={this.props.editContractorStatus} onHide={() => this.props.updateEditClientContractorDlgStatus(false)}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title><span className="offc-title">Edit Contractor</span></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <EditClientContractor id={this.props.id} data={this.props.selectedContractorData} />
                    </Offcanvas.Body>
                </Offcanvas> */}
                <Modal
                size="lg"
                show={this.props.viewContractorStatus}
                onHide={() => this.props.updateViewContractor(false)}
                aria-labelledby="example-modal-sizes-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            <h3 className="m-0">Contractor Details</h3>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                        <ViewContractor data={this.props.viewContractorData}/>
                    </Modal.Body>
                </Modal>
            </tbody>
        )
    }
}
const mapStateToProps = (state: RootState) => ({
    editContractorStatus: state.contractor.editContractorStatus,
    selectedContractorData: state.contractor.selectedContractorData,
    id: state.contractor.id,
    viewContractorStatus: state.contractor.viewContractorStatus,
    viewContractorData: state.contractor.viewContractorData
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateEditClientContractorDlgStatus: bindActionCreators(updateEditClientContractorDlgStatus, dispatch),
        getContractorById: bindActionCreators(getContractorById, dispatch),
        getAllContractors: bindActionCreators(getAllContractors, dispatch),
        deleteContractor: bindActionCreators(deleteContractor, dispatch),
        viewContractorById: bindActionCreators(viewContractorById, dispatch),
        updateViewContractor: bindActionCreators (updateViewContractor, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContractorTable);