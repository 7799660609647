import React from "react";
import { VerifiedSVG } from "../../assets/images/VerifyEmail";
import { RootState } from '../../index';
import { connect } from "react-redux";
import { Dispatch } from "redux";

class LoginSuccess extends React.Component {

    handleLogin = () => {
        window.location.href = "/"
    }

    render(): React.ReactNode {
        return (
            <div className="bg-grey">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 info-card">
                            <div className="card align-items-center">
                                <div className="card-body p-5">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <h2 className="text-green fs-27 lh-27px">Clean Task</h2>
                                            <div className="mt-4"><VerifiedSVG /></div>
                                            <p className="mt-4 mb-2 d-none">Hi Nithish Amstrong</p>
                                            <p className="mb-2 fw-700">Your account has been successfully activated!</p>
                                            <p className="mb-2">You can log in to your account!</p>
                                            <button className="btn btn-primary login-button mt-4" type="button" onClick={this.handleLogin}>Log in</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state: RootState) => ({
    // selectedEmployee: state.employee.selectedEmployee
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        // selectEmployee: bindActionCreators(selectEmployee, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginSuccess);