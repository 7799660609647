import React from "react";
import { Sidebar, Menu, ProSidebarProvider } from "react-pro-sidebar";
import { bindActionCreators, Dispatch } from "redux";
import {
  getCleanerDetails,
  certificateCompletion,
} from "../../shared/Reducers";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { LogoSVG } from "../../assets/images/ImagesV2";
import { MenuSVG } from "../../assets/images/TopNavSVG";
import { CertificateDoneSVG } from "../../assets/images/TopNavSVG";
import moment from "moment";
import { Navigate } from "react-router-dom";
import Constants from "../../shared/Constants";
import MarkPNG from "../../assets/images/correct.png";
import { constants } from "buffer";

export interface CleanerCertificateProps {
  cleanerDetails: any;
  getCleanerDetails: any | null;
  certificateCompletion: any;
  certificateCompleteStatus: boolean;
}

export interface CleanerCertificateState {
  sidebarStatus: boolean;
  australiaTime: any;
  currentCertificate: any;
  currentCertificateInx: number;
  currentCertificateImageInx: number;
  cleaner_id: any;
  cleaner_token: any;
  deviceType: string;
}

class CleanerCertificate extends React.Component<
  CleanerCertificateProps,
  CleanerCertificateState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      sidebarStatus: true,
      cleaner_id: 0,
      cleaner_token: "",
      currentCertificate: [],
      currentCertificateInx: 0,
      currentCertificateImageInx: 0,
      australiaTime: moment(
        new Date().toLocaleString("en-AU", { timeZone: "Australia/Sydney" }),
        "DD/MM/YYYY, h:mm:ss a"
      ).format("DD MMM YYYY hh:mm:ss A"),
      deviceType: "",
    };
  }

  componentDidMount(): void {
    const interval: any = setInterval(() => {
      this.setState({
        australiaTime: moment(
          new Date().toLocaleString("en-AU", { timeZone: "Australia/Sydney" }),
          "DD/MM/YYYY, h:mm:ss a"
        ).format("DD MMM YYYY hh:mm:ss A"),
      });
    }, 1000);
    const path = window.location.search;
    console.log(path);
    const [part1, part2] = path.split("?")[1].split("&");
    const token = part1.split("=")[1];
    const id = part2.split("=")[1];
    this.setState({ cleaner_id: id, cleaner_token: token });
    let cleanerData = {
      id: id,
      token: token,
    };
    this.props.getCleanerDetails(cleanerData);

    //#Source https://bit.ly/2neWfJ2
    const detectDeviceType = () =>
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
        ? "Mobile"
        : "Desktop";
    this.setState({ deviceType: detectDeviceType() });
  }

  componentDidUpdate(
    prevProps: Readonly<CleanerCertificateProps>,
    prevState: Readonly<CleanerCertificateState>,
    snapshot?: any
  ): void {
    if (this.props.cleanerDetails !== prevProps.cleanerDetails) {
      if (
        this.state.currentCertificate &&
        this.state.currentCertificate.length === 0
      ) {
        console.log(this.props.cleanerDetails);

        for (
          let i = this.props.cleanerDetails.cerificate_list.length - 1;
          i >= 0;
          i--
        ) {
          const row = this.props.cleanerDetails.cerificate_list[i];
          if (row.cleaner_certificate_status === 0) {
            this.setState({
              currentCertificate: row,
              currentCertificateInx: i,
              currentCertificateImageInx: 0,
            });

            var access: HTMLElement | null = document.getElementById(
              "side-bar-certificate-" + i
            );
            if (access) {
              access.scrollIntoView({ behavior: "smooth" });
            }
          }
        }
      }
    }

    if (
      this.props.certificateCompleteStatus !==
      prevProps.certificateCompleteStatus
    ) {
      this.setState({ currentCertificate: [], currentCertificateImageInx: 0 });
      let cleanerData = {
        id: this.state.cleaner_id,
        token: this.state.cleaner_token,
      };
      this.props.getCleanerDetails(cleanerData);
    }
  }

  certificateComplete = () => {
    let payloadData = {
      cleaner_id: this.state.cleaner_id,
      certificate_id: this.state.currentCertificate.id,
    };
    this.props.certificateCompletion(payloadData);
  };

  scrollCertificateImage = (flag: string) => {
    let currentCertificateImageInx = this.state.currentCertificateImageInx;

    if (flag === "next") {
      currentCertificateImageInx++;
      this.setState({ currentCertificateImageInx: currentCertificateImageInx });
    } else {
      currentCertificateImageInx--;
      this.setState({ currentCertificateImageInx: currentCertificateImageInx });
    }
    var access: HTMLElement | null = document.getElementById(
      "certificate-image-" + currentCertificateImageInx
    );
    if (access) {
      access.scrollIntoView({ behavior: "smooth" });
    }
  };

  handleLogin = () => {
    window.location.href = "/";
  };
  render(): React.ReactNode {
    if (this.props.cleanerDetails === null) {
      return <Navigate to="/" />;
    }
    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <div
          className={`sidebar-container sidebar-offcanvas ${
            this.state.sidebarStatus ? "show" : "hide"
          }`}
        >
          <ProSidebarProvider>
            <Sidebar>
              <Menu>
                <div className="sidebar-logo text-center pb-37px">
                  <LogoSVG />
                </div>
                <div className="side-nav">
                  {this.props.cleanerDetails &&
                    this.props.cleanerDetails.cerificate_list &&
                    this.props.cleanerDetails.cerificate_list.length > 0 &&
                    this.props.cleanerDetails.cerificate_list.map(
                      (row: any, inx: number) => (
                        <div
                          className="img-card certificate p-3"
                          id={"side-bar-certificate-" + inx}
                          onClick={() =>
                            this.setState({
                              currentCertificate: row,
                              currentCertificateInx: inx,
                            })
                          }
                        >
                          {row.cleaner_certificate_status === 1 && (
                            <span className="certificate-mark">
                              <a>
                                <CertificateDoneSVG />
                              </a>
                            </span>
                          )}
                          <div className="img-preview text-center">
                            <img
                              className={
                                this.state.currentCertificateInx === inx
                                  ? "select-side-img-preview h-100"
                                  : "side-img-preview h-100"
                              }
                              src={
                                Constants.imgUrl +
                                "certificate_image/" +
                                row.certificate_attachment[0].file_name
                              }
                              alt="profile"
                            />
                          </div>
                          <p className="text-white text-center mt-4">
                            {row.file_name}
                          </p>
                        </div>
                      )
                    )}
                </div>
              </Menu>
            </Sidebar>
          </ProSidebarProvider>
        </div>
        <div
          className={this.state.sidebarStatus ? "main-panel" : "main-panel2"}
        >
          <div
            className={`container-fluid  ${
              this.state.sidebarStatus ? "topnav" : "topnavshow"
            } `}
          >
            <div className="row align-items-center h-100">
              <div className="col-md-6">
                <div className="head-nav d-flex justify-content-between justify-content-sm-start res-mtb-10px">
                  <div className="res-d-block d-none">
                    <button
                      className="mob-menu"
                      onClick={() =>
                        this.setState({
                          sidebarStatus: !this.state.sidebarStatus,
                        })
                      }
                    >
                      <MenuSVG />
                    </button>
                  </div>
                  <h3 className="mb-0">
                    Hi{" "}
                    {this.props.cleanerDetails && this.props.cleanerDetails.first_name +
                        " " +
                        this.props.cleanerDetails.last_name
                     }
                  </h3>
                </div>
              </div>
              <div className="col-md-6">
                <div className=" d-flex align-items-center justify-content-end res-mb-10px">
                  <p className="m-0">{this.state.australiaTime}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="content-wrapper p-0">
            <div className="container-fluid">
              {this.state.currentCertificate &&
              this.state.currentCertificate.length !== 0 ? (
                <div className="row justify-content-center">
                  <div className="col-md-12 mt-4 mb-3">
                    <h3 className="text-center">
                      {this.state.currentCertificate.file_name}
                    </h3>
                  </div>
                  <div className="col-md-10 mb-7rem">
                    {this.state.currentCertificate.certificate_attachment.map(
                      (attachment: any, inx: number) => (
                        <div
                          className="img-full pdf-card"
                          id={"certificate-image-" + inx}
                        >
                          <div className="img-preview">
                            <img
                              className="img-perview h-100 w-100"
                              src={
                                Constants.imgUrl +
                                "certificate_image/" +
                                attachment.file_name
                              }
                              alt="profile"
                            />
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="col-md-12 position-relative">
                    <div className="position-fixed bottom-0 end-0 pt-2 pb-2 w-100 bg-grey br-top pl-300px">
                      <div className="row">
                        <div className="col-md-6 mt-1 text-center text-md-start">
                          <input
                            type="checkbox"
                            name="certificate-complete"
                            id="certificate-complete"
                            onClick={this.certificateComplete}
                          />
                          <span style={{ paddingLeft: "10px" }}>
                            You have read this certificate?
                          </span>
                        </div>
                        <div className="col-md-6 text-center text-md-end res-mt-3">
                          <button
                            onClick={() => this.scrollCertificateImage("prev")}
                            type="button"
                            className={
                              this.state.currentCertificateImageInx === 0
                                ? "d-none"
                                : "btn btn-light btn-prev-next mr-16px"
                            }
                          >
                            previous
                          </button>
                          <button
                            onClick={() => this.scrollCertificateImage("next")}
                            type="button"
                            className={
                              this.state.currentCertificate &&
                              this.state.currentCertificate
                                .certificate_attachment &&
                              this.state.currentCertificate
                                .certificate_attachment.length -
                                1 ===
                                this.state.currentCertificateImageInx
                                ? "d-none"
                                : "btn btn-light btn-prev-next mr-16px"
                            }
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row justify-content-center pt-5">
                  {this.props.cleanerDetails &&
                  this.props.cleanerDetails.cleaner_certificate_status &&
                  this.props.cleanerDetails.cleaner_certificate_status === 1 ? (
                    <div className="col-md-5 text-center">
                      <div className="card">
                        <div className="card-body p-37px">
                          <div className="row">
                            <div className="col-md-12 text-center">
                              <h2 className="text-green fs-27 lh-27px">
                                Clean Task
                              </h2>
                              <div className="mt-4">
                                <CertificateDoneSVG />
                              </div>
                              <p className="mt-4 mb-2 d-none">
                                Hi{" "}
                                {this.props.cleanerDetails &&
                                  this.props.cleanerDetails.first_name +
                                    " " +
                                    this.props.cleanerDetails.last_name}
                              </p>
                              <p className="mb-2 fw-700">
                                Your certificate has been successfully
                                completed!
                              </p>
                              <p className="mb-2">
                                You can log in to your account!
                              </p>

                              {this.state.deviceType === "Desktop" && (
                                <button
                                  className="btn btn-primary login-button mt-4"
                                  type="button"
                                  onClick={this.handleLogin}
                                >
                                  Log in
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  cleanerDetails: state.cleanercertificate.getCleanerDetails,
  certificateCompleteStatus: state.cleanercertificate.certificateCompleteStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getCleanerDetails: bindActionCreators(getCleanerDetails, dispatch),
    certificateCompletion: bindActionCreators(certificateCompletion, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CleanerCertificate);
