import { DisableSVG, EnableSVG } from "../../assets/images/UsermanagementsSVG";
import { Dispatch, bindActionCreators } from "redux";
import {
  editTask,
  listAllBranch,
  listAllClient,
  updateEditTaskDlgStatus,
  updateTaskFetchStatus,
} from "../../shared/Reducers";
import AttachmentPreview from "../../shared/Components/AttachmentPreview";
import { AttachmentDownloadSVG } from "../../assets/images/ImagesSVG";
import Constants from "../../shared/Constants";
import { Modal } from "react-bootstrap";
import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
import docImage from "../../assets/images/google-docs.png";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";

export interface IViewTaskProps {
  handleViewClose: any;
  displayView: any;
  listAllClient: any;
  data: any;
  listAllBranch: any;
  id: any;
  editTask: any;
}

export interface IViewTaskState {
  attachmentFiles: any;
  attachmentUrls: any;
  AttachmentPreviewStatus: boolean;
  previewUrl: string;
  previewType: string;
}

class ViewTask extends React.Component<IViewTaskProps, IViewTaskState> {
  constructor(props: IViewTaskProps) {
    super(props);

    this.state = {
      attachmentFiles: [],
      attachmentUrls: [],
      AttachmentPreviewStatus: false,
      previewUrl: " ",
      previewType: " ",
    };
  }

  componentDidMount(): void {
    // this.props.listAllClient();
    // console.log(this.props.data);
    // if (this.props.data) {
    //   this.props.listAllBranch(this.props.data.client_id);
    // }
  }

  handlePreviewOpen = (file_type: any, url: any) => {
    console.log(file_type, url);

    if (
      file_type === "image/png" ||
      file_type === "image/jpeg" ||
      file_type === "image/jpg" ||
      file_type === "video/mp4" ||
      file_type === "video/webm" ||
      file_type === "application/pdf"
    ) {
      this.setState({
        AttachmentPreviewStatus: true,
        previewUrl: url,
        previewType: file_type,
      });
    }
  };

  handlePreviewClose = () => {
    this.setState({ AttachmentPreviewStatus: false });
  };

  convertToBlob = async (
    url: string,
    name: string,
    defaultType = "image/png"
  ) => {
    const response = await fetch(url);
    const data = await response.blob();
    const file = new File([data], name, {
      type: data.type || defaultType,
    });
    console.log(file);
    this.setState((prev) => ({
      attachmentFiles: [...prev.attachmentFiles, file],
    }));
  };

  handleImageChange = (e: any) => {
    const arr = [];
    const files: any[] = Array.from(e.target.files);
    for (let i = 0; i < files.length; i++) {
      arr.push({
        url: URL.createObjectURL(files[i]),
        name: files[i].name,
        type: "new",
      });
    }
    this.setState(
      {
        attachmentUrls: [...this.state.attachmentUrls, ...arr],
        attachmentFiles: [...this.state.attachmentFiles, ...e.target.files],
      },
      () => console.log(this.state.attachmentUrls)
    );
  };

  handleDeleteImage = (index: number) => {
    const files = this.state.attachmentFiles;
    const urls = this.state.attachmentUrls;
    files.splice(index, 1);
    urls.splice(index, 1);
    this.setState({ attachmentFiles: files });
    this.setState({ attachmentUrls: urls });
  };

  handleEditTask = async (formValues: any) => {
    let data = new FormData();
    data.append("client", formValues.client);
    data.append("branch", formValues.branch);
    data.append("title", formValues.title);
    data.append("status", formValues.status);
    for (let index = 0; index < this.state.attachmentFiles.length; index++) {
      data.append("attachments", this.state.attachmentFiles[index]);
    }
    data.append("description", formValues.description);

    if (this.props.id) {
      this.props.editTask(data, this.props.id);
    }
  };

  handleChange = (e: any) => {
    console.log(e);
    if (e.target.name === "client") {
      console.log(e.target.value);
      this.props.listAllBranch(e.target.value);
    }
  };

  downloadFile = (url: string, name = "image.png") => {
    this.downloadResource(url, name);
  };

  // https://stackoverflow.com/questions/49474775/chrome-65-blocks-cross-origin-a-download-client-side-workaround-to-force-down
  forceDownload = (blob: any, filename: String) => {
    const a: any = document.createElement("a");
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  // Current blob size limit is around 500MB for browsers
  downloadResource = (url: any, filename: any) => {
    if (url) {
      if (!filename) filename = url.split("\\").pop().split("/").pop();
      fetch(url, {
        headers: new Headers({
          Origin: window.location.origin,
        }),
        mode: "cors",
      })
        .then((response) => response.blob())
        .then((blob) => {
          let blobUrl = window.URL.createObjectURL(blob);
          this.forceDownload(blobUrl, filename);
        })
        .catch((e) => console.error(e));
    }
  };
  render(): React.ReactNode {
    const {
      first_name,
      last_name,
      email,
      phone,
      user_type_id,
      title,
      status,
      assigned_branches,
      description,
      branch_name,
      attachments,
    } = this.props.data;

    return (
      <div>
        <Modal
          size="lg"
          show={this.props.displayView}
          onHide={() => this.props.handleViewClose(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              <h3 className="m-0">Task Details</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <div className="row">
              <div className="col-md-6">
                <p className="mb-1">Task Name</p>
                <p className="fw-600">{title}</p>
              </div>
              <div className="col-md-6">
                <p className="mb-1">Is Active</p>
                <p className="fw-600">
                  {" "}
                  {status !== Constants.IS_ACTIVE && <DisableSVG />}
                  {status === Constants.IS_ACTIVE && <EnableSVG />}
                </p>
              </div>
              <div className="col-md-6">
                <p className="mb-1">Client</p>
                <p className="fw-600">{first_name + " " + last_name}</p>
              </div>
              <div className="col-md-6">
                <p className="mb-1">Branch</p>
                <p className="fw-600">{branch_name}</p>
              </div>
              <div className="col-md-12 row">
                <p className="mb-1">Task Description</p>
                <p className="fw-600">{description}</p>
              </div>

              {attachments.length !== 0 && (
                <div className="col-md-12 row">
                  <p className="mb-1">Attachment</p>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <div className="d-flex row">
                        {attachments &&
                          attachments.map((obj: any, index: number) => (
                            <div className="img-card mr-16px mb-3">
                              <span className="download">
                                {/* <a href="javascript:void(0)" onClick={() => this.downloadFile(Constants.imgUrl+obj.name, obj.name)}><AttachmentDownloadSVG /></a> */}
                              </span>
                              <div
                                className="img-preview"
                                onClick={() =>
                                  this.handlePreviewOpen(
                                    obj.type,
                                    Constants.imgUrl + obj.file_name
                                  )
                                }
                              >
                                <img
                                  className="img-thumb-perview"
                                  src={
                                    obj.type === "application/pdf"
                                      ? pdfImage
                                      : obj.type === "video/mp4" ||
                                        obj.type === "video/webm"
                                        ? videoImage
                                        : obj.type === "image/png" ||
                                          obj.type === "image/jpeg" ||
                                          obj.type === "image/jpg"
                                          ? Constants.imgThumbUrl + obj.file_name
                                          : docImage
                                  }
                                />
                              </div>
                            </div>
                          ))}
                        {/* <AttachmentPreview displayPreview={this.state.AttachmentPreviewStatus} previewUrl={this.state.previewUrl} previewType={this.state.previewType} handlePreviewClose={this.handlePreviewClose}/> */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
        <AttachmentPreview
          displayPreview={this.state.AttachmentPreviewStatus}
          previewUrl={this.state.previewUrl}
          previewType={this.state.previewType}
          handlePreviewClose={this.handlePreviewClose}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  clientList: state.tasktemplate.clientList,
  branchList: state.tasktemplate.branchList,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    listAllClient: bindActionCreators(listAllClient, dispatch),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
    editTask: bindActionCreators(editTask, dispatch),
    updateEditTaskDlgStatus: bindActionCreators(
      updateEditTaskDlgStatus,
      dispatch
    ),
    updateTaskFetchStatus: bindActionCreators(updateTaskFetchStatus, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewTask);
