import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../..';
import { Dispatch, bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import AttachmentPreview from '../../shared/Components/AttachmentPreview';
import moment from 'moment';
import docImage from "../../assets/images/google-docs.png";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";

interface ICleanerInOutProps {
    displayView: any;
    handleViewClose: any;
    selectedRecommendedTaskData: any;
}
interface IRecommendedTableState {
    AttachmentPreviewStatus: any;
    previewUrl: any;
    previewType: any;
}

class RecommendedView extends Component<ICleanerInOutProps, IRecommendedTableState> {
    constructor(props: ICleanerInOutProps) {
        super(props);
        this.state = {
            AttachmentPreviewStatus: false,
            previewUrl: "",
            previewType: "",
        }
    };

    handlePreviewClose = () => {
        this.setState({ AttachmentPreviewStatus: false });
    };

    handlePreviewOpen = (type: any, url: any) => {
        if (
          type === "image/png" ||
          type === "image/jpeg" ||
          type === "image/jpg" ||
          type === "video/mp4" ||
          type === "video/webm" ||
          type === "application/pdf"
        ) {
          this.setState({
            AttachmentPreviewStatus: true,
            previewUrl: url,
            previewType: type,
          });
        }
      };

    render() {
        const {
            description,
            first_name,
            last_name,
            branch_name,
            task_date,
            attachments,
        } = this.props.selectedRecommendedTaskData;
        console.log("this.props.selectedAdditionalTaskData", this.props.selectedRecommendedTaskData);
        return (
            <>
                <Modal
                    size="lg"
                    dialogClassName="modal-90w"
                    aria-labelledby="contained-modal-title-vcenter"
                    show={this.props.displayView}
                    onHide={() => this.props.handleViewClose(false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            <h3 className="m-0">Recommended Task</h3>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                        <div className="row">
                        <div className="col-6">
                                <p className="mb-1">Recommended Description</p>
                                <p className="fw-600">{description}</p>
                            </div>
                            <div className="col-6">
                                <p className="mb-1">Task Type</p>
                                <p className="fw-600">Recommended Task</p>
                            </div>
                            <div className="col-6">
                                <p className="mb-1">Client</p>
                                <p className="fw-600">{first_name + " " + last_name}</p>
                            </div>
                            <div className="col-6">
                                <p className="mb-1">Branch</p>
                                <p className="fw-600">{branch_name}</p>
                            </div>
                            <div className="col-6">
                                <p className="mb-1">Date</p>
                                <p className="fw-600">
                                    {moment(task_date).format("DD-MM-YYYY")}
                                </p>
                            </div>
                            {attachments.length > 0 && (
                                <div className="col-12 row">
                                    <p className="mb-1">Attachments</p>
                                    <p className="fw-600"></p>
                                    {attachments.length > 0
                                        ? attachments.map((obj: any) => {
                                            console.log("attachment_Obj", obj);
                                            if (
                                                obj.contractor_attachment_type !== "sp_task_signature"
                                            ) {
                                                return (
                                                    <div className="img-card mr-16px mb-3">
                                                        <div
                                                            className="img-preview"
                                                            onClick={() =>
                                                                this.handlePreviewOpen(obj.type, obj.s3_url)
                                                            }
                                                        >
                                                            <img
                                                                className="img-thumb-perview"
                                                                src={
                                                                    obj.type === "application/pdf"
                                                                        ? pdfImage
                                                                        : obj.type === "video/mp4" ||
                                                                            obj.type === "video/webm"
                                                                            ? videoImage
                                                                            : obj.type === "image/png" ||
                                                                                obj.type === "image/jpeg" ||
                                                                                obj.type === "image/jpg"
                                                                                ? obj.s3_url
                                                                                : docImage
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })
                                        : null}
                                </div>
                            )}
                            {attachments.length > 0 && (
                                <div className="col-12 row">
                                    <p className="mb-1">Signature</p>
                                    <p className="fw-600"></p>
                                    {attachments.length > 0
                                        ? attachments.map((obj: any) => {
                                            console.log("attachment_Obj", obj);
                                            if (
                                                obj.contractor_attachment_type === "sp_task_signature"
                                            ) {
                                                return (
                                                    <div className="img-card mr-16px mb-3">
                                                        <div
                                                            className="img-preview"
                                                            onClick={() =>
                                                                this.handlePreviewOpen(obj.type, obj.s3_url)
                                                            }
                                                        >
                                                            <img
                                                                className="img-thumb-perview"
                                                                src={
                                                                    obj.type === "application/pdf"
                                                                        ? pdfImage
                                                                        : obj.type === "video/mp4" ||
                                                                            obj.type === "video/webm"
                                                                            ? videoImage
                                                                            : obj.type === "image/png" ||
                                                                                obj.type === "image/jpeg" ||
                                                                                obj.type === "image/jpg"
                                                                                ? obj.s3_url
                                                                                : docImage
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })
                                        : null}
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                </Modal>
                <AttachmentPreview
                    displayPreview={this.state.AttachmentPreviewStatus}
                    previewUrl={this.state.previewUrl}
                    previewType={this.state.previewType}
                    handlePreviewClose={this.handlePreviewClose}
                />
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    selectedRecommendedTaskData: state.dailytask.selectedRecommendedTaskData,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedView);
