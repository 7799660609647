import * as Yup from "yup";

import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Modal, ProgressBar } from "react-bootstrap";
import { PaperClipSVG, TrashSVG } from "../../assets/images/ImagesV2";
import { addBranch, listAllBranch, listAllClient } from "../../shared/Reducers";
import dayjs, { Dayjs } from "dayjs";

import AttachmentPreview from "../../shared/Components/AttachmentPreview";
import Button from "@material-ui/core/Button";
import { IAddBranchProps } from "../../types/branch.type";
import { ICleaner } from "../../shared/ActionTypes";
import React from "react";
import { RootState } from "../../index";
import Select from "react-select";
import { connect } from "react-redux";
import docImage from "../../assets/images/google-docs.png";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";

type IAddBranchState = {
  attachmentFiles: any | Blob[];
  attachmentUrls: any[];
  endDatevalue: any;
  startDatevalue: any;
  AttachmentPreviewStatus: boolean;
  previewUrl: string;
  previewType: string;
  files_size: any;
  client_id: any;
  clientErrorShow: any;
};

const reactSelectCusStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#fff",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    },
  }),
};

class AddBranch extends React.Component<IAddBranchProps, IAddBranchState> {
  constructor(props: IAddBranchProps) {
    super(props);
    this.state = {
      attachmentFiles: [],
      attachmentUrls: [],
      endDatevalue: "",
      startDatevalue: dayjs(),
      AttachmentPreviewStatus: false,
      previewUrl: "",
      previewType: "",
      files_size: 0,
      client_id: "",
      clientErrorShow: false,
    };
  }

  componentDidMount(): void {
    this.props.listAllClient();

    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.add("d-none");
      button.removeAttribute("disabled");
    }
  }

  componentDidUpdate(prevProps: IAddBranchProps, prevState: IAddBranchState) {
    if (prevProps.processTrigger !== this.props.processTrigger) {
    }
    if (prevProps.addBranchSuccess !== this.props.addBranchSuccess) {
    }
  }

  // Add Branch
  handleAddBranch = async (formValue: any) => {
    if (this.state.client_id === "" || null) {
      return false;
    }
    let data = new FormData();
    data.append("name", formValue.name);
    data.append("address", formValue.address);
    data.append("lat", formValue.lat);
    data.append("lng", formValue.lng);
    data.append("status", formValue.status);
    console.log(formValue.status, "status");

    for (let index = 0; index < this.state.attachmentFiles.length; index++) {
      data.append("attachments", this.state.attachmentFiles[index]);
    }
    data.append("client", this.state.client_id);
    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.remove("d-none");
      button.setAttribute("disabled", "");
    }
    await this.props.addBranch(data);
    setTimeout(() => {
      this.props.onClose(false);
    }, 1000);
  };

  handleImageChange = async (e: any) => {
    const file = e.target.files[0];
    this.setState(
      { files_size: this.state.files_size + file.size },
      async () => {
        if (this.state.files_size > 524288000) {
          alert("File Size Must Be Less Than 500MB");
          e.target.value = null;
          return this.setState({
            files_size: this.state.files_size - file.size,
          });
        }

        let files = [];
        let urls = [];
        files = [...e.target.files];

        let fileurls: any = [];
        await files.map(async (file: any) => {
          fileurls.push({
            url: URL.createObjectURL(file),
            file_type: file.type,
          });
        });
        urls = [...fileurls];
        this.setState({
          attachmentFiles: [...this.state.attachmentFiles, ...files],
        });
        this.setState(
          { attachmentUrls: [...this.state.attachmentUrls, ...urls] },
          () => {
            e.target.value = "";
          }
        );
      }
    );
  };
  handlePreviewClose = () => {
    this.setState({ AttachmentPreviewStatus: false });
  };

  handleBranchClient = async (id: number) => {
    console.log(id, "handleBranchClient");
    this.setState({ client_id: id, clientErrorShow: false });
    await this.props.listAllBranch(id);
  };
  handlePreviewOpen = (file_type: any, url: any) => {
    if (
      file_type === "image/png" ||
      file_type === "image/jpeg" ||
      file_type === "image/jpg" ||
      file_type === "video/mp4" ||
      file_type === "video/webm" ||
      file_type === "application/pdf"
    ) {
      this.setState({
        AttachmentPreviewStatus: true,
        previewUrl: url,
        previewType: file_type,
      });
    }
  };

  handleDeleteImage = (index: number) => {
    const files = this.state.attachmentFiles;
    const urls = this.state.attachmentUrls;
    files.splice(index, 1);
    urls.splice(index, 1);
    this.setState({ attachmentFiles: files });
    this.setState({ attachmentUrls: urls });
  };

  // Form Validation
  validationSchema = () => {
    if (this.state.client_id === "") {
      this.setState({ clientErrorShow: true });
    } else {
      this.setState({ clientErrorShow: false });
    }
    // const clientId = this.props.clientList.map((client: ICleaner) => client.id);
    const long =
      /^(\+|-)?(?:180(?:(?:\.0{1,})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,})?))$/;
    const lat =
      /^(\+|-)?(?:90(?:(?:\.0{1,})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,})?))$/;
    // Alphanumeric character check for address
    const alphanumericRegex = /^(?=.*[a-zA-Z0-9 ])[a-zA-Z0-9 ]+$/;
    const addressRegex = /[a-zA-Z0-9]/;
    return Yup.object().shape({
      name: Yup.string()
        .required("Enter a valid location")
        .matches(
          alphanumericRegex,
          "Branch name should contain only alphanumeric characters"
        )
        .test(
          "len",
          "Enter Your Branch",
          (val: any) => val && val.toString().length >= 3
        ),
      lat: Yup.string()
        .required("Enter Latitude")
        .test("validate-latitude", "Enter Valid Latitude", (value: any) => {
          return value <= 90 && value >= -90 && lat.test(value);
        }),
      lng: Yup.string()
        .required("Enter Longitude")
        .test("validate-longitude", "Enter Valid Longitude", (value: any) => {
          return value <= 180 && value >= -180 && long.test(value);
        }),
      address: Yup.string()
        .required("Enter Address")
        .test(
          "validate-address",
          "Address should contain at least one alphanumeric character",
          (value: any) => addressRegex.test(value)
        ),
      // client: Yup.string().required("Select Client"),
      // client: Yup.string().oneOf([...clientId], 'Invalid Client').required().nullable(),
    });
  };

  render(): React.ReactNode {
    const initialValues = {
      name: "",
      lat: "",
      lng: "",
      address: "",
      status: "1",
      image: "",
      // client: "",
      //client: this.state.client_id,
    };

    return (
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={this.handleAddBranch}
          validationSchema={this.validationSchema}
        >
          <Form>
            <div className="row">
              <div className="mb-2 col-md-6">
                <label className="form-label">Client</label>
                <Select
                  styles={reactSelectCusStyles}
                  placeholder="Select Client"
                  isMulti={false}
                  options={this.props.clientList.map((client: any) => ({
                    value: client.id,
                    label: client.first_name + " " + client.last_name,
                  }))}
                  onChange={(e: any) => this.handleBranchClient(e.value)}
                />
                {this.state.clientErrorShow === true && (
                  <p className="text-danger"> Please Select the Client</p>
                )}
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Branch<span className="text-project">*</span>
                </label>
                <Field
                  name="name"
                  type={"text"}
                  className="form-control dropdown-field"
                />
                <ErrorMessage
                  name="name"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Latitude<span className="text-project">*</span>
                </label>
                <Field
                  name="lat"
                  type="number"
                  className="form-control dropdown-field"
                  step="any"
                  input
                />
                <ErrorMessage
                  name="lat"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Longitude<span className="text-project">*</span>
                </label>
                <Field
                  name="lng"
                  type="number"
                  className="form-control dropdown-field"
                  step="any"
                />
                <ErrorMessage
                  name="lng"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Address<span className="text-project">*</span>
                </label>
                <Field
                  name="address"
                  type="text"
                  className="form-control dropdown-field"
                />
                <ErrorMessage
                  name="address"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">Is Active</label>
                <Field
                  as="select"
                  className="form-select dropdown-field"
                  name="status"
                >
                  <option value={1}>Enable</option>
                  <option value={0}>Disable</option>
                </Field>
                <ErrorMessage
                  name="status"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">Attach</label>
                <div className="attachment-btn dropdown-field">
                  <input
                    type="file"
                    multiple
                    accept=".pdf,.PDF,.mp4,.MP4,.webm,.WEBM,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.doc,.DOC,.docx,.DOCX"
                    style={{ display: "none" }}
                    onChange={this.handleImageChange}
                    id="contained-button-file"
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="default"
                      component="span"
                    >
                      <PaperClipSVG /> Add attachment
                    </Button>
                  </label>
                </div>
              </div>
            </div>
            {this.state.attachmentUrls.length > 0 ? (
              <div className="col-md-12 mb-2">
                <label className="form-label">Attachments</label>
              </div>
            ) : (
              <></>
            )}
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex row">
                  {this.state.attachmentUrls &&
                    this.state.attachmentUrls.map((url: any, index: number) => (
                      <div className="img-card mr-16px mb-3">
                        <span className="delete">
                          <a onClick={() => this.handleDeleteImage(index)}>
                            <TrashSVG />
                          </a>
                        </span>
                        <div
                          className="img-preview"
                          onClick={() =>
                            this.handlePreviewOpen(url.file_type, url.url)
                          }
                        >
                          <img
                            className="img-thumb-perview"
                            src={
                              url.file_type === "application/pdf"
                                ? pdfImage
                                : url.file_type === "video/mp4" ||
                                  url.file_type === "video/webm"
                                ? videoImage
                                : url.file_type === "image/png" ||
                                  url.file_type === "image/jpeg" ||
                                  url.file_type === "image/jpg"
                                ? url.url
                                : docImage
                            }
                          />
                        </div>
                      </div>
                    ))}
                  <AttachmentPreview
                    displayPreview={this.state.AttachmentPreviewStatus}
                    previewUrl={this.state.previewUrl}
                    previewType={this.state.previewType}
                    handlePreviewClose={this.handlePreviewClose}
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3 mt-3">
              <div className="col-md-12 cus-progress">
                <Modal
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={this.props.processTrigger}
                >
                  <Modal.Body className="p-0">
                    <ProgressBar
                      now={this.props.progressPercentage}
                      label={
                        this.props.progressPercentage === 100
                          ? `Processing your request...`
                          : `Branch Adding....${this.props.progressPercentage}%`
                      }
                    />
                  </Modal.Body>
                </Modal>
                <div className="d-flex">
                  <div className="mr-16px">
                    <button
                      type="submit"
                      id="create-button"
                      className="btn btn-primary create-button"
                    >
                      Create
                      <span
                        id="spinner-border"
                        className="spinner-border spinner-border-sm d-none"
                      ></span>
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn cancel-button"
                      onClick={() => this.props.onClose(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  clientList: state.tasktemplate.clientList,
  processTrigger: state.branch.processTrigger,
  addBranchSuccess: state.branch.addBranchSuccess,
  progressPercentage: state.branch.progressPercentage,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addBranch: bindActionCreators(addBranch, dispatch),
    listAllClient: bindActionCreators(listAllClient, dispatch),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBranch);
