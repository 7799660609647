import { AnyAction } from "redux";
import { ICleaner } from "./TaskTemplateActionTypes";

export const API_GET_ALL_CERTIFICAE = "get_all_certificate/LOAD";
export const API_GET_ALL_CERTIFICATE_SUCCESS = "get_all_certificate/LOAD_SUCCESS";
export const API_GET_ALL_CERTIFICATE_FAIL = "get_all_certificate/LOAD_FAIL";
export const API_ADD_CERTIFICATE = "certificate/LOAD";
export const API_ADD_CERTIFICATE_SUCCESS = "certificate/LOAD_SUCCESS";
export const API_ADD_CERTIFICATE_FAIL = "certificate/LOAD_FAIL";
export const API_ADD_CERTIFICATE_PROGRESS = "CERTIFICATE_ADD_PROGRESS"
export const API_DELETE_CERTIFICATE = "delete_certificate/LOAD";
export const API_DELETE_CERTIFICATE_SUCCESS = "delete_certificate/LOAD_SUCCESS";
export const API_DELETE_CERTIFICATE_FAIL = "delete_certificate/LOAD_FAIL";
export const API_GET_CERTIFICATE_BY_ID = "certificate_by_id/LOAD";
export const API_GET_CERTIFICATE_BY_ID_SUCCESS = "certificate_by_id/LOAD_SUCCESS";
export const API_GET_CERTIFICATE_BY_ID_FAIL = "certificate_by_id/LOAD_FAIL"
export const UPDATE_EDIT_CERTIFICATE_DLG_STATUS = "UPDATE_EDIT_CERTIFICATE_DLG_STATUS"
export const API_EDIT_CERTIFICATE = "edit_certificate/LOAD"
export const API_EDIT_CERTIFICATE_SUCCESS = "edit_certificate/LOAD_SUCCESS"
export const API_EDIT_CERTIFICATE_FAIL = "edit_certificate/LOAD_FAIL"
export const API_EDIT_CERTIFICATE_PROGRESS = "API_EDIT_CERTIFICATE_PROGRESS"

export interface ICertificateAudit {
  client: string;
  attachments: any | Blob;
}


export interface IEditCertificate {
  client: string;
  attachments: any | Blob;
}
export interface CertificateTemplateState {
  certificateList: Array<any>;
  totalCount: number;
  editCertificateData: any;
  editCertificateStatus: boolean,
  id:any;
  addCertificateSuccess: any;
  addCertificateStatus: boolean;
  processTrigger: boolean;
  progressPercentage: any;
  editCertificateSuccess: any;
  editAuditStatus: any;
  certificateFetchStatus: any;
  deleteCertificateSuccess: any;
  deleteCertificateStatus: any
}

export type CertificateTemplateDispatchType = (args: AnyAction) => AnyAction;
