import * as Yup from "yup";

import { Button, Modal, Offcanvas, ProgressBar, Toast } from "react-bootstrap";
import { Console, log } from "console";
import Constants, {
  DaysOption,
  TASK_PRIORITY,
  SCHEDULE_OPTION,
} from "../../shared/Constants";
import { DisableSVG, EnableSVG } from "../../assets/images/UsermanagementsSVG";
import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  EveryMonth,
  EveryWeekDays,
  FILTER_SCHEDULE_TABLE,
  FILTER_USER_ROLE,
  FILTER_USER_TABLE,
  EveryWeekMonth,
} from "../../shared/Constants";
import {
  ScheduleBranch,
  ScheduleCleaner,
  ScheduleState,
  TaskCleaner,
} from "../../types/schedule.type";
import {
  SuccessToastSVG,
  TableDeleteSVG,
  TableEditSVG,
  TableViewSVG,
  SortDown,
  SortReqular,
  SortUp,
} from "../../assets/images/ImagesV2";
import { confirmAlert } from "react-confirm-alert";
import {
  addSchedule,
  listAllBranch,
  editSPSchedule,
  editSchedule,
  filterSchedule,
  getAllSchedules,
  getAllTaskSchedules,
  getTaskById,
  getTaskDetails,
  listAllCleaner,
  listAllClient,
  runApiSchedule,
  scheduleDelete,
  taskDropDown,
  updateSPSchedule,
  updateSchedule,
  updateSidebar,
  spScheduleDelete,
  updateAddScheduleStatus,
} from "../../shared/Reducers";
import dayjs, { Dayjs } from "dayjs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CloseBtnSVG } from "../../assets/images/Location";
import CustomSelect from "../../shared/Components/CustomSelect";
import DatePicker from "react-multi-date-picker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import FormCheck from "react-bootstrap/Form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ProfileImg from "../../assets/images/profile.png";
import React from "react";
import ReactPaginate from "react-paginate";
import { RootState } from "../../index";
import ScheduleDeleteConfirm from "./ScheduleDeleteConfirm";
import { SearchSVG } from "../../assets/images/EmployeesSVG";
import Select from "react-select";
import SideBar from "../../components/sidebar/SideBar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Tooltip from "react-bootstrap/Tooltip";
import TopNav from "../../components/topnav/TopNav";
import ViewSchedule from "./ViewSchedule";
import { any } from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import AddSchedule from "./AddSchedule";

const reactSelectCusStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#fff",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    },
  }),
};

type ScheduleProps = {
  scheduleFetch?: any;
  allScheduleList?: any;
  createSchedule?: any;
  getAllSchedules?: any;
  getAllTaskSchedules?: any;
  data?: any;
  client_select?: any;
  getTaskNameById: any;
  scheduleDelete?: any;
  spScheduleDelete?: any;
  getResponseDelete?: any;
  clientList?: any;
  listAllClient?: any;
  branchList?: any;
  listAllBranch?: any;
  taskNames?: any;
  listAllTask?: any;
  listAllCleaner?: any;
  cleanerList?: any;
  addSchedule?: any;
  editContent: any;
  editSchedule?: any;
  editSPSchedule?: any;
  scheduleFilterData?: any;
  filterBranch?: any;
  updateSchedule: any;
  updateSPSchedule: any;
  updateMessage: any;
  getTaskDropDown: any;
  taskDropDownList: any;
  total_count: any;
  addScheduleSuccess?: string;
  editScheduleSuccess?: string;
  deleteScheduleSuccess?: string;
  scheduleFetchStatus: boolean;
  progressPercentage: any;
  processTrigger: boolean;
  runApiSchedule?: any;
  runScheduleResponse?: any;
  editScheduleDetails: any;
  status: any;
  updateSidebar: any;
  userLoginPermission: any;
  updateAddScheduleStatus?: any;
  addScheduleModalStatus: boolean;
  userLoginDetails?: any;
};

const today = new Date();
const tomorrow = new Date();

class Schedule extends React.Component<ScheduleProps, ScheduleState> {
  constructor(props: ScheduleProps) {
    super(props);
    this.state = {
      schedule_name: "",
      client_select: "",
      branchSelect: "",
      taskSelect: "",
      endSchedule: "",
      startSchedule: "",
      cleanerSelect: [],
      selectDate: [],
      everyWeek: [],
      everyMonthWeek: [],
      everyMonthWeekNo: [],
      scheduleEndDate: "",
      scheduleList: [],
      displayDelete: false,
      currentUsermanagementId: "",
      taskList: [],
      listOfCleaners: [{ label: any, value: any }],
      specificDate: [],
      dateType: "",
      anyTime: 1,
      startTime: "",
      endTime: "",
      editScheduleId: "",
      scheduleEditCanvas: false,
      filterChange: "",
      scheduleData: [],
      editScheduleClient: any,
      editschedulename: any,
      editSheduleTask: any,
      editSheduleBranch: any,
      editCleanerSelect: any,
      scheduleEditwait: false,
      listOfCleanersIds: [],
      editSpecificChecked: any,
      editWeekChecked: any,
      editMonthChecked: any,
      editWeekMonthChecked: any,
      editStartChecked: any,
      timeFormat: any,
      anyTimeFormat: any,
      editStarttimeSchedule: any,
      editEndtimeSchedule: any,
      editDateGet: any,
      editEveryWeek: [],
      editSelectDate: any,
      editWeekDays: any,
      editeveryMonthWeek: any,
      editeveryMonthWeekNo: any,
      weekDays: [],
      monthWeek: [],
      monthWeekNo: [],
      offset: 0,
      totalPages: 10,
      task_with_sign_in: Constants.TASK_WITH_SIGN_IN_YES,
      priority: TASK_PRIORITY.MEDIUM,
      status: Constants.ENABLE,
      edit_task_with_sign_in: null,
      edit_priority: null,
      edit_is_active: null,
      viewScheduleId: "",
      viewDisplay: false,
      editEndTimeShow: "",
      editStartTimeShow: "",
      addToast: false,
      deleteToast: false,
      editToast: false,
      runScheduleToast: false,
      deletedScheduleName: "",
      scheduleRunApiStatus: false,
      pagehandle: false,
      timeout: "",
      filterOption: "",
      orderType: "",
      spSchedView: "",
      dateOptionStore: "",
      checkRenewalDate: "",
      lastPath: "",
      getSearchData: "",
      getSortData: "id",
      getOrderData: "desc",
      getOffsetData: "0",
      getLimitData: "10",
      getClientData: "all",
      getBranchData: "all",
      getReferenceData: "task",
    };
  }

  async componentDidMount() {
    this.props.updateSidebar(true);
    document.title = "Schedule";
    const interval: any = setInterval(() => {
      let date = moment(
        new Date().toLocaleString("en-AU", { timeZone: "Australia/Sydney" }),
        "DD/MM/YYYY, h:mm:ss a"
      ).format("dddd");
      if (date === "Friday") {
        this.setState({ scheduleRunApiStatus: true });
      } else {
        this.setState({ scheduleRunApiStatus: false });
      }
    }, 1000);

    const url = window.location.pathname;
    const splitUrl = url.split("/");
    const lastPath = splitUrl[splitUrl.length - 1];
    this.setState({ lastPath: lastPath });
    if (
      this.props.userLoginPermission?.sidebar_make_schedule === 1 &&
      this.props.userLoginPermission?.sidebar_schedule === 1
    ) {
      this.setState({ getReferenceData: "all" });
    } else if (
      lastPath === "make-schedule" ||
      this.props.userLoginPermission?.sidebar_make_schedule === 1
    ) {
      this.setState({ getReferenceData: "task" });
    } else if (this.props.userLoginPermission?.sidebar_schedule === 1) {
      this.setState({ getReferenceData: "task_template" });
    }
    await this.getScheduleList();
  }

  componentDidUpdate(
    prevProps: Readonly<ScheduleProps>,
    prevState: Readonly<ScheduleState>,
    snapshot?: any
  ): void {
    if (this.props.userLoginPermission !== prevProps.userLoginPermission) {
      const url = window.location.pathname;
      const splitUrl = url.split("/");
      const lastPath = splitUrl[splitUrl.length - 1];
      this.setState({ lastPath: lastPath });
      if (
        this.props.userLoginPermission?.sidebar_make_schedule === 1 &&
        this.props.userLoginPermission?.sidebar_schedule === 1
      ) {
        this.setState({ getReferenceData: "all" });
      } else if (
        lastPath === "make-schedule" ||
        this.props.userLoginPermission?.sidebar_make_schedule === 1
      ) {
        this.setState({ getReferenceData: "task" });
      } else if (this.props.userLoginPermission?.sidebar_schedule === 1) {
        this.setState({ getReferenceData: "task_template" });
      }
    }

    // reference type change
    if (this.state.getReferenceData !== prevState.getReferenceData) {
      this.getScheduleList();
    }

    // sorting changes
    if (
      this.state.getSortData !== prevState.getSortData ||
      this.state.getOrderData !== prevState.getOrderData
    ) {
      this.getScheduleList();
    }

    if (prevProps.scheduleFetchStatus !== this.props.scheduleFetchStatus) {
      if (this.props.scheduleFetchStatus) {
        this.getScheduleList();
      }
    }
    if (prevProps.addScheduleSuccess !== this.props.addScheduleSuccess) {
      if (
        this.props.addScheduleSuccess === "Successfully created" ||
        this.props.addScheduleSuccess === "Internal server error"
      ) {
        this.setState({ addToast: true });
        setTimeout(() => {
          this.setState({ addToast: false });
        }, 5000);
      }
    }

    if (
      prevProps.runScheduleResponse !== this.props.runScheduleResponse &&
      this.props.runScheduleResponse !== ""
    ) {
      console.log(this.props.runScheduleResponse);

      this.setState({ runScheduleToast: true });
      setTimeout(() => {
        this.setState({ runScheduleToast: false });
      }, 5000);
    }

    if (prevProps.editScheduleSuccess !== this.props.editScheduleSuccess) {
      if (
        this.props.editScheduleSuccess === "Successfully updated" ||
        this.props.editScheduleSuccess === "Internal server error"
      ) {
        this.setState({ editToast: true });
        setTimeout(() => {
          this.setState({ editToast: false });
        }, 5000);
      }
    }
    if (prevProps.deleteScheduleSuccess !== this.props.deleteScheduleSuccess) {
      if (
        this.props.deleteScheduleSuccess === "Successfully deleted" ||
        this.props.deleteScheduleSuccess === "Internal server error"
      ) {
        this.setState({ deleteToast: true });
        setTimeout(() => {
          this.setState({ deleteToast: false });
        }, 5000);
      }
    }
  }

  handleSort = (column: string) => {
    if (this.state.getSortData === column) {
      this.setState({
        getOrderData: this.state.getOrderData === "asc" ? "desc" : "asc",
      });
    } else {
      this.setState({ getSortData: column });
      this.setState({ getOrderData: "asc" });
    }
  };

  async fetchFunction(offset: number, perPage: number) {
    let cleanerArray = [];
    this.getScheduleList();
    await this.props.listAllClient();
    await this.props.listAllCleaner();

    this.setState({
      scheduleData: this.props.scheduleFilterData,
    });
    for (let index = 0; index < this.props.cleanerList.length; index++) {
      const element = this.props.cleanerList[index];
      let obj = {
        label: element.label,
        value: element.value,
      };
      cleanerArray.push(obj);
    }
    this.setState({ listOfCleaners: cleanerArray });
  }

  getScheduleList = async () => {
    const query = `search=${this.state.filterChange}&sort=${this.state.getSortData}&order=${this.state.getOrderData}&offset=${this.state.getOffsetData}&limit=${this.state.getLimitData}&client=${this.state.getClientData}&branch=${this.state.getBranchData}&task_reference_type=${this.state.getReferenceData}`;
    this.props.getAllSchedules(query);
  };

  DateReadOnly = () => {
    // date picker readonly
    setTimeout(() => {
      let datePicker: NodeListOf<Element> = document.querySelectorAll(
        ".MuiFormControl-root input"
      ) as NodeListOf<Element>;
      if (datePicker.length > 0) {
        for (let index = 0; index < datePicker.length; index++) {
          const element = datePicker[index];
          datePicker[index].setAttribute("readonly", "true");
        }
      }
    }, 2000);
  };

  // Formik Validation Schema
  validationSchema = () => {
    console.log("log");
    const clientId = this.props.clientList.map(
      (client: ScheduleCleaner) => client.id
    );
    const branchId = this.props.branchList.map(
      (branch: ScheduleBranch) => branch.value
    );
    // if (this.state.dateType === "") {
    //   let dateAlert: HTMLElement = document.getElementById(
    //     "date-alert"
    //   ) as HTMLElement;
    //   dateAlert.style.display = "block";
    // }
    // if (this.state.scheduleEndDate === "") {
    //   let dateAlert: HTMLElement = document.getElementById(
    //     "schedule-end-date-alert"
    //   ) as HTMLElement;
    //   dateAlert.style.display = "block";
    // }
    // const taskId = this.props.taskNames.map((task: TaskCleaner) => task.id);
    return Yup.object().shape({
      column_name: Yup.string().required("Select a client"),
      order_type: Yup.string().required("Select a branch"),
    });
  };

  // Formik Validation Schema
  editValidationSchema = () => {
    // console.log("Validation");

    if (
      Constants.QAUTINT === Constants.LOGIN_USER_TYPE ||
      Constants.QA_MANAGERUTINT === Constants.LOGIN_USER_TYPE
    ) {
      return Yup.object().shape({
        editschedulename: Yup.string().required("Enter Schedule name"),
      });
    } else {
      // const clientId = this.props.clientList.map(
      //   (client: ScheduleCleaner) => client.id
      // );
      // const branchId = this.props.branchList.map(
      //   (branch: ScheduleBranch) => branch.value
      // );
      // if (this.state.dateType === "") {
      //   let dateAlert: HTMLElement = document.getElementById(
      //     "date-alert"
      //   ) as HTMLElement;
      //   dateAlert.style.display = "block";
      // }
      // const taskId = this.props.taskNames.map((task: TaskCleaner) => task.id);
      // console.log(editScheduleClient);

      return Yup.object().shape({
        // editScheduleClient: Yup.string().required("Select a client"),
        // editScheduleBranch: Yup.string().required("Select a branch"),
        // editTaskSchedule: Yup.string().required("Select a task"),
        // group1: Yup.string().required("Select a date"),
        // editCleanerSelect: Yup.array().min(1, "Select atleast one cleaner"),
        // .oneOf([...clientId], "Invalid Client")
        // .required()
        // .nullable(),
        // branchSelect: Yup.string()
        //   .oneOf([...branchId], "Invalid Branch")
        //   .required()
        //   .nullable(),
        // taskSelect: Yup.string().required("Select a task"),
        // everyWeek: Yup.array().required("Select a cleaner"),
      });
    }
  };
  tConvert(time: any) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  // Delete Popup close
  handleDeleteClose = (status: boolean) => {
    this.setState({ displayDelete: false, currentUsermanagementId: "" });
  };
  handleViewClose = (status: boolean) => {
    this.setState({ viewDisplay: false, viewScheduleId: "" });
  };
  handleDeleteSuccess = async () => {
    await this.props.scheduleDelete(this.state.currentUsermanagementId);
    this.fetchFunction(Constants.OFFSET, 10);
    this.setState({ displayDelete: false });
    // if (this.props.getResponseDelete.message === "Successfully deleted") {
    // }
  };

  // Actually this is the Schedule Delete function
  handleSpDeleteSuccess = async (id: any) => {
    console.log("scheduleDelete", id);
    await this.props.scheduleDelete(id);
    this.fetchFunction(Constants.OFFSET, 10);
    this.setState({ displayDelete: false });
    if (this.props.getResponseDelete.message === "Successfully deleted") {
    }
  };

  // Delete Usermanagement Based on ID
  handleDelete = (id: string, title: string, date: any) => {
    console.log("get the ic in schedule", id, title);
    this.setState({
      displayDelete: true,
      currentUsermanagementId: id,
      deletedScheduleName: title,
      checkRenewalDate: { date: date, id: id },
    });
  };

  editHandleChangeScedule = async (e: any) => {
    console.log(e, "e value");
    if (e.target.name === "editSheduleBranch") {
      await this.props.getTaskDropDown(e.target.value);
      this.setState({ taskList: this.props.taskDropDownList });
    }
    if (e.target.name === "editScheduleClient") {
      await this.props.listAllBranch(e.target.value);
    }
    if (e.target.name === "editGroup1") {
      this.setState({
        editMonthChecked: "",
        editWeekMonthChecked: "",
        editWeekChecked: "",
        editSpecificChecked: "",
        editStartChecked: "",
      });
      if (e.target.id === "edit-inline-11") {
        this.setState({
          dateType: "every_month_days",
          editMonthChecked: true,
        });
      } else if (e.target.id === "edit-inline-12") {
        this.setState({
          dateType: "specific_dates",
          editSpecificChecked: true,
        });
      } else if (e.target.id === "edit-inline-13") {
        this.setState({
          dateType: "every_week",
          editWeekChecked: true,
        });
      } else if (e.target.id === "edit-inline-14") {
        this.setState({
          dateType: "date_to_date",
          editStartChecked: true,
        });
      } else if (e.target.id === "edit-inline-15") {
        this.setState({
          dateType: "every_month_week",
          editWeekMonthChecked: true,
        });
      }
    }
    if (e.target.id === "edit-inline-1") {
      this.setState({ anyTime: 1, timeFormat: false, anyTimeFormat: true });
    } else if (e.target.id === "edit-inline-2") {
      this.setState({ anyTime: 0, anyTimeFormat: false, timeFormat: true });
    }
    if (e.target.id === "edit-start-time") {
      this.setState({ startTime: e.target.value });
    } else if (e.target.id === "edit-end-time") {
      this.setState({ endTime: e.target.value });
    }
  };

  handleEditDateClick = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let scheduleEditEnd: HTMLElement = document.querySelector(
        ".endDate button.MuiButtonBase-root"
      ) as HTMLElement;
      if (scheduleEditEnd !== null) {
        scheduleEditEnd.click();
      }
    }
  };

  handleScheduleDate = async (newValue: Dayjs | null) => {
    this.setState({ scheduleEndDate: newValue });
    // if (this.state.dateType !== "") {
    //   let dateAlert: HTMLElement = document.getElementById(
    //     "date-alert"
    //   ) as HTMLElement;
    //   dateAlert.style.display = "none";
    // }
    let dateAlert: HTMLElement = document.getElementById(
      "schedule-end-date-alert"
    ) as HTMLElement;
    if (dateAlert !== null) {
      dateAlert.click();
    }
  };

  handleEnd = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let endDate: HTMLElement = document.querySelector(
        ".end button.MuiButtonBase-root"
      ) as HTMLElement;
      endDate.click();
    }
  };

  handleEndDate = async (newValue: Dayjs | null) => {
    this.setState({ endSchedule: newValue });
  };

  handleStart = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let startDate: HTMLElement = document.querySelector(
        ".start button.MuiButtonBase-root"
      ) as HTMLElement;
      startDate.click();
    }
  };

  handleStartDate = async (newValue: Dayjs | null) => {
    this.setState({ startSchedule: newValue });
    let endDate: HTMLElement = document.querySelector(
      ".start button.MuiButtonBase-root"
    ) as HTMLElement;
    if (endDate === null) {
      let startDate: HTMLElement = document.querySelector(
        ".start button.MuiButtonBase-root"
      ) as HTMLElement;
      startDate.click();
    }
    // endDate.click();
  };

  async editScheduleOpen(e: any) {
    console.log("this.props.editContent.client_ids", e);

    this.DateReadOnly();
    this.setState({
      editEndtimeSchedule: "",
      editStarttimeSchedule: "",
      timeFormat: "",
      anyTimeFormat: "",
      editMonthChecked: "",
      editWeekMonthChecked: "",
      editWeekChecked: "",
      editSpecificChecked: "",
      editStartChecked: "",
      editWeekDays: [],
      editeveryMonthWeek: [],
      editeveryMonthWeekNo: [],
      startSchedule: "",
      endSchedule: "",
      specificDate: "",
      editEndTimeShow: "",
      editStartTimeShow: "",
    });
    // if (
    //   // Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
    //   Constants.OPERATION_MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
    //   Constants.QAUTINT === Constants.LOGIN_USER_TYPE ||
    //   Constants.QA_MANAGERUTINT === Constants.LOGIN_USER_TYPE
    // ) {
    //   await this.props.editSPSchedule(e);
    // } else {
      await this.props.editSchedule(e);
    // }

    let listOfIds: any = [];
    if (
      this.props.editContent.cleaners &&
      this.props.editContent.cleaners.length > 0
    ) {
      let array: any = this.props.editContent.cleaners;
      console.log("ArrayValue", array);

      for (let i = 0; i < array.length; i++) {
        const element = array[i];
        if (element.hasOwnProperty("cleaner_id")) {
          listOfIds.push(element.cleaner_id);
        }
      }
    }

    await this.props.listAllBranch(this.props.editContent.client_id);

    if (this.props.editContent?.task_reference_type === "task_template") {
      await this.props.getTaskDropDown(this.props.editContent?.branch_id);
      this.setState({ taskList: this.props.taskDropDownList });
    } else if (this.props.editContent?.task_reference_type === "task") {
      await this.props.listAllTask(this.props.editContent.branch_id);
      this.setState({ taskList: this.props.taskNames.data.task_list });
    }

    if (this.props.editContent.schedule_option === "every_month_days") {
      let month_day_get = this.props.editContent.days.split(",");
      this.setState({
        editEveryWeek: month_day_get,
        editMonthChecked: true,
        dateType: "every_month_days",
      });
    } else if (this.props.editContent.schedule_option === "specific_dates") {
      let date_split = this.props.editContent.specific_dates.split(",");
      let datesplit: any = [];
      await date_split.map(async (element: any) => {
        datesplit.push(moment(element, "YYYY/MM/DD").format("DD/MM/YYYY"));
      });
      this.setState({
        editSpecificChecked: true,
        specificDate: datesplit,
        dateType: "specific_dates",
      });
    } else if (this.props.editContent.schedule_option === "every_week") {
      let day_get_ = this.props.editContent.day_names.split(",");
      let day_get = [];
      for (let index = 0; index < day_get_.length; index++) {
        const element = day_get_[index];
        day_get.push(parseInt(element));
      }
      this.setState({
        weekDays: day_get,
        editWeekChecked: true,
        dateType: "every_week",
      });
      // }
    } else if (this.props.editContent.schedule_option === "every_month_week") {
      let week_get_ = this.props.editContent.day_names.split(",");
      let weekno_get_ = this.props.editContent.week_names.split(",");
      let week_get = [];
      let weekno_get = [];
      for (let index = 0; index < week_get_.length; index++) {
        const element = week_get_[index];
        week_get.push(parseInt(element));
      }
      for (let index = 0; index < weekno_get_.length; index++) {
        const element = weekno_get_[index];
        weekno_get.push(element);
      }

      this.setState({
        monthWeekNo: weekno_get,
        monthWeek: week_get,
        editWeekMonthChecked: true,
        dateType: "every_month_week",
      });
      // }
    } else {
      this.setState({
        startSchedule: this.props.editContent.specific_dates.split(",")[0],
        endSchedule: this.props.editContent.specific_dates.split(",")[1],
        editStartChecked: true,
        dateType: "date_to_date",
        scheduleEndDate: this.props.editContent.schedule_end_date,
      });
    }
    if (this.props.editContent.any_time === 1) {
      this.setState({
        anyTimeFormat: true,
        anyTime: 1,
        startTime: this.props.editContent.start_time,
        endTime: this.props.editContent.end_time,
      });
    } else if (this.props.editContent.any_time === 0) {
      this.setState({
        timeFormat: true,
        anyTime: 0,
        editStarttimeSchedule: dayjs(
          `${new Date().getFullYear}-${new Date().getMonth}-${
            new Date().getDay
          }T${this.props.editContent.start_time}:00`
        ),
        editEndtimeSchedule: dayjs(
          `${new Date().getFullYear}-${new Date().getMonth}-${
            new Date().getDay
          }T${this.props.editContent.end_time}:00`
        ),
        editEndTimeShow: dayjs(
          `2022-01-02T${this.props.editContent.end_time}:00`
        ),
        editStartTimeShow: dayjs(
          `2022-01-02T${this.props.editContent.start_time}:00`
        ),
      });
    }
    // radioCheckBox.click();
    this.setState({
      editScheduleClient: this.props.editContent.client_id,
      editSheduleBranch: this.props.editContent.branch_id,
      listOfCleanersIds: listOfIds,
      scheduleEndDate: this.props.editContent.schedule_end_date,
    });

    this.setState(
      {
        scheduleEditCanvas: true,
        editScheduleId: e,
      },
      () => {
        console.log(
          "this,state.scheduleEditCanvas",
          this.state.scheduleEditCanvas
        );
      }
    );
  }

  // filter function
  handleFilter = async (e: any) => {
    console.log(e.target.value);
    let value: string = e.target.value.toLowerCase();
    // console.log(value)
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

    this.setState({
      filterChange: e.target.value.toLowerCase(),
      timeout: setTimeout(async () => {
        try {
          // if (
          //   Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
          //   Constants.OPERATION_MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
          //   Constants.QAUTINT === Constants.LOGIN_USER_TYPE ||
          //   Constants.QA_MANAGERUTINT === Constants.LOGIN_USER_TYPE
          // ) {
          //   const taskQuery = `sp-schedules?search=${this.state.filterChange}&sort=${this.state.filterOption}&order=${this.state.orderType}&offset=${Constants.OFFSET}&limit=${Constants.PER_PAGE}&client=1&branch=2&schedule_option=all`;
          //   await this.props.getAllTaskSchedules(taskQuery);
          // } else {
          const query = `search=${this.state.filterChange}&sort=${this.state.getSortData}&order=${this.state.getOrderData}&offset=${this.state.getOffsetData}&limit=${this.state.getLimitData}&client=${this.state.getClientData}&branch=${this.state.getBranchData}&task_reference_type=${this.state.getReferenceData}`;
          console.log("Checked", query);
          this.props.getAllSchedules(query);
          // }
        } catch (error) {
          console.log(error);
        }
        // Trigger your function here that you want to call when user stops typing
      }, 1000), // Set the delay time in ms
    });
  };

  handlePageNext = async (e: any) => {
    if (e.selected < this.props.total_count / Constants.PER_PAGE) {
      // if (
      //   Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
      //   Constants.OPERATION_MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
      //   Constants.QAUTINT === Constants.LOGIN_USER_TYPE ||
      //   Constants.QA_MANAGERUTINT === Constants.LOGIN_USER_TYPE
      // ) {
      //   console.log("QA AND MANG");
      //   const taskQuery = `sp-schedules?search=${this.state.filterChange}&sort=${this.state.filterOption}&order=${this.state.orderType}&offset=${Constants.OFFSET}&limit=${Constants.PER_PAGE}&client=1&branch=2&schedule_option=all`;
      //   await this.props.getAllTaskSchedules(taskQuery);
      // } else {
        console.log(
          "ADMIN",
          e.selected * Constants.PER_PAGE,
          Constants.PER_PAGE
        );
        let query = `search=${this.state.filterChange}&sort=${
          this.state.filterOption
        }&order=${this.state.orderType}&offset=${
          e.selected * Constants.PER_PAGE
        }&limit=${Constants.PER_PAGE}&client=all&branch=all`;
        await this.props.getAllSchedules(
          query
          // e.selected * Constants.PER_PAGE,
          // Constants.PER_PAGE,
          // "first_name",
          // "asc"
        );
      // }
    }
  };

  async viewOpenSchedule(data: any) {
    console.log(data);

    if (
      Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
      Constants.OPERATION_MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
      Constants.QAUTINT === Constants.LOGIN_USER_TYPE ||
      Constants.QA_MANAGERUTINT === Constants.LOGIN_USER_TYPE
    ) {
      await this.props.editSPSchedule(data);
    } else {
      await this.props.editSchedule(data);
    }
    this.setState({ viewDisplay: true, viewScheduleId: data });
  }

  handleEditEndTime = (newValue: Dayjs | null | any) => {
    this.setState({
      editEndtimeSchedule: newValue.$H + ":" + newValue.$M,
      editEndTimeShow: newValue,
    });
  };
  handleEditStartTime = (newValue: Dayjs | null | any) => {
    this.setState({
      editStarttimeSchedule: newValue.$H + ":" + newValue.$M,
      editStartTimeShow: newValue,
    });
  };

  addClose = () => {
    this.setState({ addToast: false, runScheduleToast: false });
  };

  editClose = () => {
    this.setState({ editToast: false });
  };

  deleteClose = () => {
    this.setState({ deleteToast: false });
  };
  handleSearch = async (formValue: any) => {
    console.log(formValue, "formValue", Constants.LOGIN_USER_TYPE);
    try {
      this.setState(
        {
          pagehandle: true,
          filterOption: formValue.column_name,
          orderType: formValue.order_type,
        },
        () => {
          setTimeout(() => {
            this.setState({ pagehandle: false }, async () => {
              // if (
              //   Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
              //   Constants.OPERATION_MANAGERUTINT ===
              //     Constants.LOGIN_USER_TYPE ||
              //   Constants.QAUTINT === Constants.LOGIN_USER_TYPE ||
              //   Constants.QA_MANAGERUTINT === Constants.LOGIN_USER_TYPE
              // ) {
              //   const taskQuery = `sp-schedules?search=${this.state.filterChange}&sort=${formValue.column_name}&order=${formValue.order_type}&offset=${Constants.OFFSET}&limit=${Constants.PER_PAGE}&client=1&branch=2&schedule_option=all`;
              //   await this.props.getAllTaskSchedules(taskQuery);
              // } else {
              const query = `search=${this.state.filterChange}&sort=${
                formValue.column_name
              }&order=${formValue.order_type}&offset=${
                Constants.OFFSET
              }&limit=${Constants.PER_PAGE}&client=${
                Constants.LOGIN_USER_TYPE === 1 ? "all" : "all"
              }&branch=all`;
              // search=&sort=${formValue.column_name}order=&offset=0&limit=100&client=1&branch=2&schedule_option=all
              this.props.getAllSchedules(query);
              // }
            });
          }, 0);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  handleView = (id: string) => {
    //  this.setState({ currentScheduleId: id });
  };
  handleEditShedule = async (formValue: ScheduleState) => {
    if (this.state.dateType !== "") {
      let specificDateGet: any = [];
      for (let index = 0; index < this.state.specificDate.length; index++) {
        const element = this.state.specificDate[index];
        console.log(element);

        if (element.day !== undefined) {
          specificDateGet.push(
            element.year + "-" + element.month + "-" + element.day
          );
        } else {
          specificDateGet.push(
            moment(element, "DD/MM/YYYY").format("YYYY-MM-DD")
          );
        }
      }

      if (this.state.dateType === "every_month_days") {
        let dataArray = {
          client: formValue.editScheduleClient,
          status: formValue.edit_is_active,
          branch: formValue.editSheduleBranch,
          task: formValue.editSheduleTask,
          schedule_name: formValue.editschedulename,
          schedule_option: this.state.dateType,
          cleaners: formValue.editCleanerSelect,
          days: formValue.editSelectDate,
          any_time: this.state.anyTime,
          schedule_end_date: moment(
            new Date(this.state.scheduleEndDate?.toString())
          ).format("DD/MM/YYYY"),
          start_time:
            this.state.anyTime === 0
              ? moment(
                  new Date(this.state.editStartTimeShow?.toString())
                ).format("HH:mm")
              : "",
          end_time:
            this.state.anyTime === 0
              ? moment(new Date(this.state.editEndTimeShow?.toString())).format(
                  "HH:mm"
                )
              : "",
          task_with_sign_in: formValue.edit_task_with_sign_in,
          priority: formValue.edit_priority,
        };
        await this.props.updateSchedule(this.state.editScheduleId, dataArray);
      } else if (this.state.dateType === "every_week_days") {
        let dataArray = {
          status: formValue.edit_is_active,
          client: formValue.editScheduleClient,
          branch: formValue.editSheduleBranch,
          task: formValue.editSheduleTask,
          schedule_option: this.state.dateType,
          schedule_name: formValue.editschedulename,
          cleaners: formValue.editCleanerSelect,
          days: formValue.everyWeek,
          week: [1, 2, 3, 4, 5, 6],
          any_time: this.state.anyTime,
          schedule_end_date: moment(
            new Date(this.state.scheduleEndDate?.toString())
          ).format("DD/MM/YYYY"),
          start_time:
            this.state.anyTime === 0
              ? moment(
                  new Date(this.state.editStartTimeShow?.toString())
                ).format("HH:mm")
              : "",
          end_time:
            this.state.anyTime === 0
              ? moment(new Date(this.state.editEndTimeShow?.toString())).format(
                  "HH:mm"
                )
              : "",
          task_with_sign_in: formValue.edit_task_with_sign_in,
          priority: formValue.edit_priority,
        };
        await this.props.updateSchedule(this.state.editScheduleId, dataArray);
      } else if (this.state.dateType === "specific_dates") {
        let dataArray = {
          status: formValue.edit_is_active,
          client: formValue.editScheduleClient,
          branch: formValue.editSheduleBranch,
          task: formValue.editSheduleTask,
          schedule_option: this.state.dateType,
          schedule_name: formValue.editschedulename,
          cleaners: formValue.editCleanerSelect,
          specific_dates: specificDateGet,
          any_time: this.state.anyTime,
          schedule_end_date: moment(
            new Date(this.state.scheduleEndDate?.toString())
          ).format("DD/MM/YYYY"),
          start_time:
            this.state.anyTime === 0
              ? moment(
                  new Date(this.state.editStartTimeShow?.toString())
                ).format("HH:mm")
              : "",
          end_time:
            this.state.anyTime === 0
              ? moment(new Date(this.state.editEndTimeShow?.toString())).format(
                  "HH:mm"
                )
              : "",
          task_with_sign_in: formValue.edit_task_with_sign_in,
          priority: formValue.edit_priority,
        };
        await this.props.updateSchedule(this.state.editScheduleId, dataArray);
      } else if (this.state.dateType === "every_week") {
        let dataArray = {
          status: formValue.edit_is_active,
          client: formValue.editScheduleClient,
          branch: formValue.editSheduleBranch,
          task: formValue.editSheduleTask,
          schedule_option: this.state.dateType,
          schedule_name: formValue.editschedulename,
          cleaners: formValue.editCleanerSelect,
          days: formValue.editWeekDays,
          any_time: this.state.anyTime,
          schedule_end_date: moment(
            new Date(this.state.scheduleEndDate?.toString())
          ).format("DD/MM/YYYY"),
          start_time:
            this.state.anyTime === 0
              ? moment(
                  new Date(this.state.editStartTimeShow?.toString())
                ).format("HH:mm")
              : "",
          end_time:
            this.state.anyTime === 0
              ? moment(new Date(this.state.editEndTimeShow?.toString())).format(
                  "HH:mm"
                )
              : "",
          task_with_sign_in: formValue.edit_task_with_sign_in,
          priority: formValue.edit_priority,
        };
        await this.props.updateSchedule(this.state.editScheduleId, dataArray);
      } else if (this.state.dateType === "every_month_week") {
        let dataArray = {
          status: formValue.edit_is_active,
          client: formValue.editScheduleClient,
          branch: formValue.editSheduleBranch,
          task: formValue.editSheduleTask,
          schedule_option: this.state.dateType,
          schedule_name: formValue.editschedulename,
          cleaners: formValue.editCleanerSelect,
          days: formValue.editeveryMonthWeek,
          week: formValue.editeveryMonthWeekNo,
          any_time: this.state.anyTime,
          schedule_end_date: moment(
            new Date(this.state.scheduleEndDate?.toString())
          ).format("DD/MM/YYYY"),
          start_time:
            this.state.anyTime === 0
              ? moment(
                  new Date(this.state.editStartTimeShow?.toString())
                ).format("HH:mm")
              : "",
          end_time:
            this.state.anyTime === 0
              ? moment(new Date(this.state.editEndTimeShow?.toString())).format(
                  "HH:mm"
                )
              : "",
          task_with_sign_in: formValue.edit_task_with_sign_in,
          priority: formValue.edit_priority,
        };
        await this.props.updateSchedule(this.state.editScheduleId, dataArray);
      } else {
        let dataArray = {
          status: formValue.edit_is_active,
          client: formValue.editScheduleClient,
          branch: formValue.editSheduleBranch,
          task: formValue.editSheduleTask,
          schedule_option: this.state.dateType,
          schedule_name: formValue.editschedulename,
          cleaners: formValue.editCleanerSelect,
          specific_dates: [
            moment(new Date(this.state.startSchedule?.toString())).format(
              "YYYY-MM-DD"
            ),
            moment(new Date(this.state.endSchedule?.toString())).format(
              "YYYY-MM-DD"
            ),
          ],
          any_time: this.state.anyTime,
          schedule_end_date: moment(
            new Date(this.state.scheduleEndDate?.toString())
          ).format("DD/MM/YYYY"),
          start_time:
            this.state.anyTime === 0
              ? moment(
                  new Date(this.state.editStartTimeShow?.toString())
                ).format("HH:mm")
              : "",
          end_time:
            this.state.anyTime === 0
              ? moment(new Date(this.state.editEndTimeShow?.toString())).format(
                  "HH:mm"
                )
              : "",
          task_with_sign_in: formValue.edit_task_with_sign_in,
          priority: formValue.edit_priority,
        };
        await this.props.updateSchedule(this.state.editScheduleId, dataArray);
      }
      if (this.props.updateMessage.status === 200) {
        this.setState({ scheduleEditCanvas: false });
        this.fetchFunction(Constants.OFFSET, 10);
      }
    } else {
      let dateAlert: HTMLElement = document.getElementById(
        "date-alert"
      ) as HTMLElement;
      dateAlert.style.display = "block";
    }
  };
  render(): React.ReactNode {
    let taskStatusOption: any = [];
    taskStatusOption = [
      { value: "first_name", label: "Client" },
      { value: "branch_name", label: "Branch" },
      { value: "schedule_name", label: "Schedule name" },
      { value: "title", label: "Task name" },
    ];
    let contractOrder: any = [];
    contractOrder = [
      { value: "asc", label: "Ascending" },
      { value: "desc", label: "Descending" },
    ];
    const initialValues: any = {
      schedule_name: "",
      client_select: "",
      branchSelect: "",
      taskSelect: "",
      anyTime: 1,
      cleanerSelect: [],
      selectDate: [],
      scheduleEndDate: "",
      endSchedule: "",
      startSchedule: "",
      scheduleList: [],
      displayDelete: false,
      currentUsermanagementId: "",
      taskList: [],
      listOfCleaners: [{ label: any, value: any }],
      specificDate: [today, tomorrow],
      dateType: "",
      startTime: any,
      editScheduleId: "",
      endTime: "",
      scheduleEditCanvas: false,
      filterChange: "",
      task_with_sign_in: Constants.TASK_WITH_SIGN_IN_YES,
      priority: TASK_PRIORITY.MEDIUM,
      status: Constants.ENABLE,
      scheduleData: [],
      editschedulename: this.props.editContent.schedule_name,
      editScheduleClient: this.props.editContent.client_id,
      editSheduleTask: this.props.editContent.task_reference_id,
      editSheduleBranch: this.props.editContent.branch_id,
      editCleanerSelect: this.state.listOfCleanersIds,
      scheduleEditwait: false,
      listOfCleanersIds: [],
      editSpecificChecked: [],
      editWeekChecked: any,
      editMonthChecked: any,
      editWeekMonthChecked: any,
      editStartChecked: any,
      timeFormat: any,
      anyTimeFormat: any,
      editStarttimeSchedule: any,
      editEndtimeSchedule: any,
      editDateGet: any,
      everyWeek: [],
      everyMonthWeek: [],
      everyMonthWeekNo: [],
      editSelectDate: this.state.editEveryWeek,
      editEveryWeek: [],
      editWeekDays: this.state.weekDays,
      editeveryMonthWeek: this.state.monthWeek,
      editeveryMonthWeekNo: this.state.monthWeekNo,
      weekDays: [],
      monthWeek: [],
      monthWeekNo: [],
      offset: 0,
      totalPages: 10,
      edit_task_with_sign_in: this.props.editContent.task_with_sign_in,
      edit_priority: this.props.editContent.priority,
      edit_is_active: this.props.editContent.status,
      viewScheduleId: "",
      viewDisplay: false,
      editEndTimeShow: any,
      editStartTimeShow: any,
      pagehandle: false,
      timeout: "",
      filterOption: "",
      orderType: "",
      spSchedView: "",
      dateOptionStore: "",
      checkRenewalDate: "",
      lastPath: "",
      // displayView: false,
      // currentScheduleId: "",
    };
    let pages = [];
    for (let i = 0; i < this.state.totalPages; i++) {
      pages.push(i);
    }
    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className={this.props.status ? "main-panel" : "main-panel2"}>
          <TopNav />
          <div className="content-wrapper">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="mb-0">Schedule</h3>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-end">
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12 col-lg-4 pr-5px">
                  <div className="input-group search">
                    <span
                      className="input-group-text search-icon"
                      id="basic-addon1"
                    >
                      <SearchSVG />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={this.state.filterChange}
                      onInput={this.handleFilter}
                      onChange={(e) =>
                        this.setState({ filterChange: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12 col-lg-6">
                  <div>
                    <Formik
                      validationSchema={this.validationSchema}
                      onSubmit={this.handleSearch}
                      initialValues={{
                        column_name: "",
                        order_type: "asc",
                      }}
                    >
                      <Form className="d-flex flex-column flex-md-row">
                        <Field
                          className="custom-select dropdown-field flex-mr-16px filter-w-180px"
                          name="column_name"
                          options={taskStatusOption}
                          component={CustomSelect}
                          placeholder="Select Column"
                          isMulti={false}
                        />
                        <Field
                          className="custom-select dropdown-field flex-mr-16px res-mt-3 filter-w-180px"
                          name="order_type"
                          options={contractOrder}
                          component={CustomSelect}
                          placeholder="Select Order"
                          isMulti={false}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary filter-button res-mt-3"
                        >
                          Filter
                        </button>
                      </Form>
                    </Formik>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 text-start text-lg-end tab-res-mt-3 ">
                  <div className="schedule-button">
                    {this.state.scheduleRunApiStatus &&
                      Constants.ADMINUTINT === Constants.LOGIN_USER_TYPE &&
                      Constants.SUPERVISORUTINT ===
                        Constants.LOGIN_USER_TYPE && (
                        <button
                          onClick={() => this.props.runApiSchedule()}
                          className="btn btn-primary create-button mr-16px"
                        >
                          Run Scedule
                        </button>
                      )}
                    {this.state.scheduleEditCanvas === false ? (
                      <div>
                        <Offcanvas
                          placement="end"
                          show={this.props.addScheduleModalStatus}
                          onHide={() =>
                            this.props.updateAddScheduleStatus(false)
                          }
                          className="off-canvas-schedule"
                        >
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                              <img
                                alt="office-img"
                                src={ProfileImg}
                                className="offc-img"
                              />
                              <span className="offc-title">Add Schedule</span>
                            </Offcanvas.Title>
                          </Offcanvas.Header>
                          {/* {Object.keys(this.props.editContent).length > 0 ? ( */}
                          <Offcanvas.Body>
                            <AddSchedule />
                          </Offcanvas.Body>
                          {/* ) : (
                          ""
                        )} */}
                        </Offcanvas>
                        {this.props.userLoginPermission?.sidebar_schedule ===
                          1 && (
                          <Button
                            aria-controls="offcanvasRight"
                            className="add-schedule-btn btn btn-primary login-button mob-res-w-100"
                            onClick={() => {
                              this.setState({
                                taskList: [],
                                startSchedule: "",
                                endSchedule: "",
                                startTime: "",
                                endTime: "",
                                specificDate: "",
                                anyTimeFormat: true,
                                timeFormat: false,
                                dateType: "",
                              });
                              this.props.updateAddScheduleStatus(true);
                              this.DateReadOnly();
                            }}
                          >
                            Add Schedule
                          </Button>
                        )}
                      </div>
                    ) : (
                      <div className="d-flex justify-content-end">
                        <Offcanvas
                          placement="end"
                          show={this.state.scheduleEditCanvas}
                          onHide={() =>
                            this.setState({ scheduleEditCanvas: false })
                          }
                          className="off-canvas-schedule"
                        >
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                              <img
                                alt="office-img"
                                src={ProfileImg}
                                className="offc-img"
                              />
                              <span className="offc-title">Edit Schedule</span>
                            </Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <Formik
                              initialValues={initialValues}
                              enableReinitialize={true}
                              onSubmit={this.handleEditShedule}
                              validationSchema={this.editValidationSchema}
                            >
                              <Form onChange={this.editHandleChangeScedule}>
                                <div className="row">
                                  <div className="mb-2 col-md-6">
                                    <label className="form-label">
                                      Schedule name
                                      <span className="text-project">*</span>
                                    </label>
                                    <Field
                                      name="editschedulename"
                                      type="text"
                                      className="form-control dropdown-field"
                                    />
                                    <ErrorMessage
                                      name="editschedulename"
                                      component={"div"}
                                      className="cus-alert"
                                    />
                                  </div>
                                  {/* {Constants.QAUTINT !== Constants.LOGIN_USER_TYPE &&
                                    Constants.QA_MANAGERUTINT !== Constants.LOGIN_USER_TYPE &&
                                    Constants.MANAGERUTINT !==
                                    Constants.LOGIN_USER_TYPE && ( */}
                                  <div className="col-md-6 mb-2">
                                    <label className="form-label">
                                      Client
                                      <span className="text-project">*</span>
                                    </label>
                                    {/* <Field
                                          as="select"
                                          className="form-select"
                                          name="editScheduleClient"
                                        >
                                          <option selected value="">
                                            Select client
                                          </option>
                                          {this.props.clientList.map(
                                            (client: ScheduleCleaner) => (
                                              <option
                                                value={client.id.toString()}
                                                key={client.id}
                                              >{`${client.first_name} ${client.last_name}`}</option>
                                            )
                                          )}
                                        </Field> */}
                                    <Field
                                      className="custom-select"
                                      name="editScheduleClient"
                                      component={CustomSelect}
                                      placeholder="Select client"
                                      options={this.props.clientList.map(
                                        (client: ScheduleCleaner) => ({
                                          value: client.id,
                                          label: `${client.first_name} ${client.last_name}`,
                                        })
                                      )}
                                      onChange={async (e: any) => {
                                        await this.props.listAllBranch(e.value);
                                      }}
                                    />
                                    <ErrorMessage
                                      name="editSheduleClient"
                                      component={"div"}
                                      className="cus-alert"
                                    />
                                  </div>
                                  {/* )} */}
                                  <div className="col-md-6 mb-2">
                                    <label className="form-label">
                                      Branch
                                      <span className="text-project">*</span>
                                    </label>
                                    {/* <Field
                                      as="select"
                                      className="form-select"
                                      name="editSheduleBranch"
                                    >
                                      {this.state.branchListState.length >
                                        0 ? (
                                        <>
                                          <option value={""} disabled>
                                            Select Branch
                                          </option>
                                          {this.state.branchListState.map(
                                            (branch: any) => (
                                              <option
                                                value={branch.id.toString()}
                                                key={branch.id}
                                              >{`${branch.name}`}</option>
                                            )
                                          )}
                                        </>
                                      ) : (
                                        <option value="">
                                          Branch list empty
                                        </option>
                                      )}
                                    </Field> */}
                                    <Field
                                      className="custom-select"
                                      name="editSheduleBranch"
                                      component={CustomSelect}
                                      placeholder="Select branch"
                                      options={this.props.branchList.map(
                                        (branch: any) => ({
                                          value: branch.value,
                                          label: branch.label,
                                        })
                                      )}
                                      onChange={async (e: any) => {
                                        // Handle selected option
                                        console.log("Selected option:", e);
                                        await this.props.getTaskDropDown(
                                          e.value
                                        );
                                        this.setState({
                                          taskList: this.props.taskDropDownList,
                                        });
                                      }}
                                    />
                                    <ErrorMessage
                                      name="editSheduleBranch"
                                      component={"div"}
                                      className="cus-alert"
                                    />
                                  </div>
                                  <div className="col-md-6 mb-2">
                                    <label className="form-label">
                                      Task
                                      <span className="text-project">*</span>
                                    </label>
                                    {/* {this.props.} */}
                                    <Field
                                      as="select"
                                      className="form-select"
                                      name="editSheduleTask"
                                      // onChange={this.onChangeHandle}
                                    >
                                      <option selected value="" disabled>
                                        Select Task
                                      </option>
                                      {this.state.taskList?.map((task: any) => (
                                        <option
                                          value={task.id.toString()}
                                          key={task.id}
                                        >{`${task.title}`}</option>
                                      ))}
                                    </Field>
                                    {/* <Field
                                      className="custom-select"
                                      name="editSheduleTask"
                                      component={CustomSelect}
                                      placeholder="Select task"
                                      options={this.state.taskList.map((task: any) => ({
                                        value: task.id.toString(),
                                        label: task.title
                                      }))}
                                      onChange={async (e: any) => {
                                        // Handle selected option
                                        
                                      }}
                                    /> */}
                                    <ErrorMessage
                                      name="editSheduleTas"
                                      component={"div"}
                                      className="cus-alert"
                                    />
                                  </div>
                                  {this.props.userLoginDetails?.permission
                                    ?.task_view_cleaner === 1 && (
                                    <div className="col-md-12 mb-2">
                                      <label className="form-label">
                                        Cleaner
                                        <span className="text-project">*</span>
                                      </label>
                                      <Field
                                        className="custom-select"
                                        name="editCleanerSelect"
                                        value={this.state.cleanerSelect}
                                        options={this.props.cleanerList}
                                        component={CustomSelect}
                                        placeholder="Select Cleaner"
                                        isMulti={true}
                                        onChange={() => console.log("ee")}
                                      />
                                      <ErrorMessage
                                        name="editCleanerSelect"
                                        component={"div"}
                                        className="cus-alert"
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="mb-2">
                                  <label className="form-label">
                                    Date<span className="text-project">*</span>
                                  </label>
                                  <div className="d-flex mt-2">
                                    <div className="">
                                      <FormCheck.Check
                                        inline
                                        label="Every month"
                                        value="every_month_days"
                                        name="editGroup1"
                                        type={"radio"}
                                        id={`edit-inline-11`}
                                        checked={this.state.editMonthChecked}
                                      />
                                    </div>
                                    <div className="schedule-select-date">
                                      <Field
                                        className="custom-select"
                                        name="editSelectDate"
                                        options={EveryMonth}
                                        component={CustomSelect}
                                        placeholder="Select Date"
                                        // value={"6"}
                                        isMulti={true}
                                        onChange={() => console.log("ee")}
                                      />
                                    </div>
                                    <div className="">
                                      <label className="form-label">Date</label>
                                    </div>
                                  </div>
                                  <div className="d-flex mt-3">
                                    <div className="">
                                      <FormCheck.Check
                                        inline
                                        label="Specific dates"
                                        value="specific_dates"
                                        name="editGroup1"
                                        type={"radio"}
                                        id={`edit-inline-12`}
                                        checked={this.state.editSpecificChecked}
                                      />
                                    </div>
                                    <div className="schedule-specific-date">
                                      <DatePicker
                                        multiple
                                        format="DD/MM/YYYY"
                                        placeholder="dd/mm/yyyy"
                                        value={this.state.specificDate}
                                        onChange={(e) => {
                                          this.setState({ specificDate: e });
                                        }}
                                      />
                                      <>
                                        {/* {console.log(this.state.specificDate)} */}
                                      </>
                                    </div>
                                  </div>
                                  <div className="d-flex mt-3">
                                    <div className="">
                                      <FormCheck.Check
                                        inline
                                        label="Every"
                                        value="every_week"
                                        name="editGroup1"
                                        type={"radio"}
                                        id={`edit-inline-13`}
                                        checked={this.state.editWeekChecked}
                                      />
                                    </div>
                                    <div className="schedule-every-select-day">
                                      <Field
                                        className="custom-select"
                                        name="editWeekDays"
                                        options={EveryWeekDays}
                                        component={CustomSelect}
                                        placeholder="Select Day"
                                        isMulti={true}
                                      ></Field>
                                      <ErrorMessage
                                        name="editWeekDays"
                                        component={"div"}
                                        className="cus-alert"
                                      />
                                    </div>
                                  </div>
                                  <div className="d-flex mt-3">
                                    <div className="">
                                      <FormCheck.Check
                                        inline
                                        label="Every month"
                                        value="every_month_week"
                                        type={"radio"}
                                        id={`edit-inline-15`}
                                        name="editGroup1"
                                        checked={
                                          this.state.editWeekMonthChecked
                                        }
                                      />
                                    </div>
                                    <div className="schedule-every-select-day">
                                      <Field
                                        className="custom-select"
                                        name="editeveryMonthWeekNo"
                                        options={EveryWeekMonth}
                                        component={CustomSelect}
                                        placeholder="Select Number of week"
                                        isMulti={true}
                                        onChange={() => console.log("ee")}
                                      ></Field>
                                      <ErrorMessage
                                        name="editeveryMonthWeekNo"
                                        component={"div"}
                                        className="cus-alert"
                                      />
                                    </div>
                                    <div className="schedule-every-select-day">
                                      <Field
                                        className="custom-select"
                                        name="editeveryMonthWeek"
                                        options={EveryWeekDays}
                                        component={CustomSelect}
                                        placeholder="Select Day"
                                        isMulti={true}
                                        onChange={() => console.log("ee")}
                                      ></Field>
                                      <ErrorMessage
                                        name="editeveryMonthWeek"
                                        component={"div"}
                                        className="cus-alert"
                                      />
                                    </div>
                                  </div>
                                  <div className="d-flex mt-3">
                                    <div className="">
                                      <FormCheck.Check
                                        inline
                                        label="Start date"
                                        name="editGroup1"
                                        type={"radio"}
                                        id={`edit-inline-14`}
                                        checked={this.state.editStartChecked}
                                      />
                                    </div>
                                    <div className="schedule-start-date">
                                      <div
                                        className="start"
                                        onClick={() => this.handleStart()}
                                      >
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                          id="startDate"
                                        >
                                          <Stack spacing={3}>
                                            <DesktopDatePicker
                                              inputFormat="DD/MM/YYYY"
                                              closeOnSelect={true}
                                              // maxDate={this.state.endDatevalue}
                                              value={this.state.startSchedule}
                                              onChange={this.handleStartDate}
                                              renderInput={(params) => (
                                                <TextField {...params} />
                                              )}
                                              disablePast={true}
                                            />
                                          </Stack>
                                        </LocalizationProvider>
                                      </div>
                                      <ErrorMessage
                                        name="editStartDate"
                                        component={"div"}
                                        className="cus-alert"
                                      />
                                    </div>
                                    <div className="">
                                      <label className="form-label">
                                        End date
                                      </label>
                                    </div>
                                    <div className="schedule-end-date">
                                      <div
                                        className="end"
                                        onClick={() => this.handleEnd()}
                                      >
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                          id="startDate"
                                        >
                                          <Stack spacing={3}>
                                            <DesktopDatePicker
                                              inputFormat="DD/MM/YYYY"
                                              // maxDate={this.state.endDatevalue}
                                              value={this.state.endSchedule}
                                              onChange={this.handleEndDate}
                                              renderInput={(params) => (
                                                <TextField {...params} />
                                              )}
                                              disablePast={true}
                                            />
                                          </Stack>
                                        </LocalizationProvider>
                                      </div>
                                      <ErrorMessage
                                        name="editEndDate"
                                        component={"div"}
                                        className="cus-alert"
                                      />
                                    </div>
                                  </div>
                                  {/* <ErrorMessage
                                  name="editSelectDate"
                                  component={"div"}
                                  className="cus-alert"
                                /> */}
                                  <p
                                    className="cus-alert"
                                    id="date-alert"
                                    style={{ display: "none", color: "red" }}
                                  >
                                    Please select a date
                                  </p>
                                </div>

                                <div className="row mt-3">
                                  <label className="form-label">
                                    Time<span className="text-project">*</span>
                                  </label>
                                  <div className="mt-3">
                                    <FormCheck.Check
                                      inline
                                      label="Any time"
                                      name="editGroup2"
                                      type={"radio"}
                                      id={`edit-inline-1`}
                                      checked={this.state.anyTimeFormat}
                                    />
                                  </div>
                                  <div className="d-flex mt-3">
                                    <FormCheck.Check
                                      inline
                                      label="Start"
                                      name="editGroup2"
                                      type={"radio"}
                                      id={`edit-inline-2`}
                                      checked={this.state.timeFormat}
                                    />
                                    <div className="schedule-start-time">
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <Stack spacing={3}>
                                          <TimePicker
                                            value={this.state.editStartTimeShow}
                                            onChange={this.handleEditStartTime}
                                            renderInput={(params) => (
                                              <TextField {...params} />
                                            )}
                                          />
                                        </Stack>
                                      </LocalizationProvider>
                                    </div>
                                    <div className="">
                                      <label className="form-label">End</label>
                                    </div>
                                    <div className="schedule-end-time">
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <Stack spacing={3}>
                                          <TimePicker
                                            value={this.state.editEndTimeShow}
                                            onChange={this.handleEditEndTime}
                                            renderInput={(params) => (
                                              <TextField {...params} />
                                            )}
                                          />
                                        </Stack>
                                      </LocalizationProvider>
                                      {/* <TextField
                                      id="edit-end-time"
                                      type="time"
                                      value={this.state.editEndtimeSchedule}
                                      onChange={(e) =>
                                        this.setState({
                                          editEndtimeSchedule: e.target.value,
                                        })
                                      }
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        step: 300, // 5 min
                                      }}
                                      sx={{ width: 100 }}
                                    /> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6 mt-3">
                                    <label className="form-label">
                                      Schedule end date{" "}
                                      <span className="text-project">*</span>
                                    </label>
                                    <div
                                      className="endDate"
                                      onClick={() => this.handleEditDateClick()}
                                    >
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        id="endDate"
                                      >
                                        <Stack spacing={3}>
                                          <DesktopDatePicker
                                            inputFormat="DD/MM/YYYY"
                                            // maxDate={this.state.endDatevalue}
                                            value={this.state.scheduleEndDate}
                                            onChange={this.handleScheduleDate}
                                            renderInput={(params) => (
                                              <TextField {...params} />
                                            )}
                                            disablePast={true}
                                          />
                                        </Stack>
                                      </LocalizationProvider>
                                    </div>
                                    {/* </Field> */}
                                    <ErrorMessage
                                      name="editScheduleEndDate"
                                      component={"div"}
                                      className="cus-alert"
                                    />
                                  </div>
                                  {Constants.ADMINUTINT ===
                                    Constants.LOGIN_USER_TYPE ||
                                    (Constants.SUPERVISORUTINT ===
                                      Constants.LOGIN_USER_TYPE && (
                                      <div className="mt-2 mb-2 col-md-6">
                                        <label className="form-label">
                                          Task with sign in
                                          <span className="text-project">
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          className="custom-select"
                                          name="edit_task_with_sign_in"
                                          options={[
                                            {
                                              value:
                                                Constants.TASK_WITH_SIGN_IN_YES,
                                              label: "Task with sign in",
                                            },
                                            {
                                              value:
                                                Constants.TASK_WITH_SIGN_IN_NO,
                                              label: "Task without sign in",
                                            },
                                          ]}
                                          component={CustomSelect}
                                          isMulti={false}
                                        />
                                        <ErrorMessage
                                          name="edit_task_with_sign_in"
                                          component={"div"}
                                          className="cus-alert"
                                        />
                                      </div>
                                    ))}
                                  <div className="mt-2 mb-2 col-md-6">
                                    <label className="form-label">
                                      Task priority
                                      <span className="text-project">*</span>
                                    </label>
                                    <Field
                                      className="custom-select"
                                      name="edit_priority"
                                      options={[
                                        {
                                          value: TASK_PRIORITY.LOW,
                                          label: "Low",
                                        },
                                        {
                                          value: TASK_PRIORITY.MEDIUM,
                                          label: "Medium",
                                        },
                                        {
                                          value: TASK_PRIORITY.HIGH,
                                          label: "High",
                                        },
                                      ]}
                                      component={CustomSelect}
                                      isMulti={false}
                                    />
                                    <ErrorMessage
                                      name="edit_priority"
                                      component={"div"}
                                      className="cus-alert"
                                    />
                                  </div>
                                  <div className="mb-4 col-md-6">
                                    <label className="form-label">
                                      Is Active
                                      <span className="text-project">*</span>
                                    </label>
                                    <Field
                                      className="custom-select"
                                      name="edit_is_active"
                                      options={[
                                        {
                                          value: Constants.ENABLE,
                                          label: "Enable",
                                        },
                                        {
                                          value: Constants.DISABLE,
                                          label: "Disable",
                                        },
                                      ]}
                                      component={CustomSelect}
                                      isMulti={false}
                                    />
                                    <ErrorMessage
                                      name="edit_is_active"
                                      component={"div"}
                                      className="cus-alert"
                                    />
                                  </div>
                                </div>
                                <div className="d-flex mb-3 mt-3 cus-progress">
                                  <Modal
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    show={this.props.processTrigger}
                                  >
                                    <Modal.Body className="p-0">
                                      <ProgressBar
                                        now={this.props.progressPercentage}
                                        label={`updating schedule....${this.props.progressPercentage}%`}
                                      />
                                    </Modal.Body>
                                  </Modal>
                                  <div className="mr-16px">
                                    <Button
                                      type="submit"
                                      className="btn btn-primary create-button"
                                    >
                                      Save
                                    </Button>
                                  </div>
                                  <div className="">
                                    <button
                                      className="btn cancel-button"
                                      onClick={() =>
                                        this.setState({
                                          scheduleEditCanvas: false,
                                        })
                                      }
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            </Formik>
                          </Offcanvas.Body>
                        </Offcanvas>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      {this.props.scheduleFilterData.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table schedule-table">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  onClick={() =>
                                    this.handleSort("schedule_name")
                                  }
                                >
                                  SCHEDULE NAME
                                  <span className="table-sort-wrap">
                                    <span
                                      className={
                                        this.state.getSortData ===
                                          "schedule_name" &&
                                        this.state.getOrderData === "asc"
                                          ? ""
                                          : "d-none"
                                      }
                                    >
                                      <SortDown />
                                    </span>
                                    <span
                                      className={
                                        this.state.getSortData ===
                                          "schedule_name" &&
                                        this.state.getOrderData === "desc"
                                          ? ""
                                          : "d-none"
                                      }
                                    >
                                      <SortUp />
                                    </span>
                                    <span
                                      className={
                                        this.state.getSortData !==
                                        "schedule_name"
                                          ? ""
                                          : "d-none"
                                      }
                                    >
                                      <SortReqular />
                                    </span>
                                  </span>
                                </th>
                                <th scope="col">TASK TYPE</th>
                                <th
                                  scope="col"
                                  onClick={() => this.handleSort("title")}
                                >
                                  TASK NAME
                                  <span className="table-sort-wrap">
                                    <span
                                      className={
                                        this.state.getSortData === "title" &&
                                        this.state.getOrderData === "asc"
                                          ? ""
                                          : "d-none"
                                      }
                                    >
                                      <SortDown />
                                    </span>
                                    <span
                                      className={
                                        this.state.getSortData === "title" &&
                                        this.state.getOrderData === "desc"
                                          ? ""
                                          : "d-none"
                                      }
                                    >
                                      <SortUp />
                                    </span>
                                    <span
                                      className={
                                        this.state.getSortData !== "title"
                                          ? ""
                                          : "d-none"
                                      }
                                    >
                                      <SortReqular />
                                    </span>
                                  </span>
                                </th>
                                <th
                                  scope="col"
                                  onClick={() => this.handleSort("first_name")}
                                >
                                  CLIENT
                                  <span className="table-sort-wrap">
                                    <span
                                      className={
                                        this.state.getSortData ===
                                          "first_name" &&
                                        this.state.getOrderData === "asc"
                                          ? ""
                                          : "d-none"
                                      }
                                    >
                                      <SortDown />
                                    </span>
                                    <span
                                      className={
                                        this.state.getSortData ===
                                          "first_name" &&
                                        this.state.getOrderData === "desc"
                                          ? ""
                                          : "d-none"
                                      }
                                    >
                                      <SortUp />
                                    </span>
                                    <span
                                      className={
                                        this.state.getSortData !== "first_name"
                                          ? ""
                                          : "d-none"
                                      }
                                    >
                                      <SortReqular />
                                    </span>
                                  </span>
                                </th>
                                <th
                                  scope="col"
                                  onClick={() => this.handleSort("branch_name")}
                                >
                                  BRANCH
                                  <span className="table-sort-wrap">
                                    <span
                                      className={
                                        this.state.getSortData ===
                                          "branch_name" &&
                                        this.state.getOrderData === "asc"
                                          ? ""
                                          : "d-none"
                                      }
                                    >
                                      <SortDown />
                                    </span>
                                    <span
                                      className={
                                        this.state.getSortData ===
                                          "branch_name" &&
                                        this.state.getOrderData === "desc"
                                          ? ""
                                          : "d-none"
                                      }
                                    >
                                      <SortUp />
                                    </span>
                                    <span
                                      className={
                                        this.state.getSortData !== "branch_name"
                                          ? ""
                                          : "d-none"
                                      }
                                    >
                                      <SortReqular />
                                    </span>
                                  </span>
                                </th>
                                {this.props.userLoginDetails?.permission
                                  ?.task_view_cleaner === 1 && (
                                  <th scope="col">CLEANERS</th>
                                )}
                                <th scope="col">SCHEDULE DATE</th>
                                <th scope="col">SCHEDULE TIME</th>
                                <th scope="col">SCHEDULE END DATE</th>
                                <th scope="col">IS ACTIVE</th>
                                <th scope="col">ACTION</th>
                              </tr>
                            </thead>
                            {this.props.scheduleFilterData.length > 0 ? (
                              <tbody>
                                {this.props.scheduleFilterData.map(
                                  (val: any) => (
                                    <tr>
                                      <td>{val.schedule_name}</td>
                                      <td>
                                        {val.task_reference_type
                                          .replace(/_/g, " ")
                                          .replace(/\b\w/g, (c: string) =>
                                            c.toUpperCase()
                                          )}
                                      </td>
                                      <td>
                                        {val.task_reference_type === "task"
                                          ? val.task_title
                                          : val.task_template_title}
                                      </td>
                                      <td>
                                        {val.first_name + " " + val.last_name}
                                      </td>
                                      <td>{val.branch_name}</td>
                                      {this.props.userLoginDetails?.permission
                                        ?.task_view_cleaner === 1 && (
                                        <td>
                                          <OverlayTrigger
                                            placement="right"
                                            overlay={
                                              <Tooltip>
                                                {val.cleaners &&
                                                  val.cleaners.map(
                                                    (element: any) => (
                                                      <p className="text-white mb-0 fs-12px">
                                                        {element.first_name +
                                                          " " +
                                                          element.last_name}
                                                      </p>
                                                    )
                                                  )}
                                              </Tooltip>
                                            }
                                          >
                                            <Button
                                              variant="light"
                                              className="btn btn-secondary btn-cleaner-count fs-12px"
                                            >
                                              {val.cleaners &&
                                                val.cleaners.length}
                                            </Button>
                                          </OverlayTrigger>
                                        </td>
                                      )}
                                      <td>
                                        {val.schedule_option === "every_week"
                                          ? "Every week "
                                          : val.schedule_option ===
                                            "every_month_days"
                                          ? "Every month "
                                          : val.schedule_option ===
                                            "specific_dates"
                                          ? "Specific dates "
                                          : val.schedule_option ===
                                            "every_month_week"
                                          ? "Every month "
                                          : val.schedule_option ===
                                            "date_to_date"
                                          ? ""
                                          : ""}
                                        <>
                                          {val.schedule_option ===
                                          "date_to_date" ? (
                                            <>
                                              {val.specific_dates
                                                .split(",")
                                                .map((e: any, inx: number) => (
                                                  <>
                                                    {inx === 0
                                                      ? "Start : "
                                                      : " to End : "}
                                                    {moment(
                                                      e,
                                                      "YYYY/MM/DD"
                                                    ).format("DD/MM/YYYY")}
                                                  </>
                                                ))}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                        <OverlayTrigger
                                          placement="right"
                                          overlay={
                                            <Tooltip>
                                              {val.schedule_option ===
                                              "every_week" ? (
                                                <p className="text-white mb-0 fs-12px">
                                                  {val.day_names
                                                    .split(",")
                                                    .map(
                                                      (e: any, i: number) => (
                                                        <>
                                                          {" "}
                                                          {i !== 0 ? ", " : ""}
                                                          {
                                                            DaysOption.find(
                                                              (i) =>
                                                                i.label === e
                                                            )?.value
                                                          }
                                                        </>
                                                      )
                                                    )}
                                                </p>
                                              ) : val.schedule_option ===
                                                "every_month_days" ? (
                                                <p className="text-white mb-0 fs-12px">
                                                  {val.days}
                                                </p>
                                              ) : val.schedule_option ===
                                                "specific_dates" ? (
                                                <>
                                                  {val.specific_dates
                                                    .split(",")
                                                    .map((e: any) => (
                                                      <p className="text-white mb-0 fs-12px">
                                                        {moment(
                                                          e,
                                                          "YYYY/MM/DD"
                                                        ).format("DD/MM/YYYY")}
                                                      </p>
                                                    ))}
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </Tooltip>
                                          }
                                        >
                                          {val.schedule_option ===
                                          "date_to_date" ? (
                                            <></>
                                          ) : (
                                            <Button
                                              variant="light"
                                              className="btn btn-secondary btn-cleaner-count fs-12px"
                                            >
                                              View
                                            </Button>
                                          )}
                                        </OverlayTrigger>
                                        {val.schedule_option ===
                                        "every_month_days"
                                          ? " days"
                                          : ""}
                                      </td>
                                      <td>
                                        {val.any_time === 1
                                          ? `Any Time`
                                          : moment(
                                              val.start_time,
                                              "HH:mm:ss"
                                            ).format("hh:mm A") +
                                            "-" +
                                            moment(
                                              val.end_time,
                                              "HH:mm:ss"
                                            ).format("hh:mm A")}
                                      </td>
                                      <td>
                                        {moment(
                                          val.schedule_end_date,
                                          "YYYY-MM-DD"
                                        ).format("DD/MM/YYYY")}
                                      </td>
                                      <td>
                                        <span>
                                          {val.status !== Constants.ENABLE && (
                                            <DisableSVG />
                                          )}
                                          {val.status === Constants.ENABLE && (
                                            <EnableSVG />
                                          )}
                                        </span>
                                      </td>
                                      <td>
                                        <div className="btn-group">
                                          <span className="mr-20px">
                                            <a
                                              className="pointer"
                                              href="javascript:void(0)"
                                              onClick={() =>
                                                this.viewOpenSchedule(val.id)
                                              }
                                            >
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip>{"View"}</Tooltip>
                                                }
                                              >
                                                <Button className="action-btn">
                                                  <TableViewSVG />
                                                </Button>
                                              </OverlayTrigger>
                                            </a>
                                          </span>
                                          <span className="mr-20px">
                                            <a
                                              className="pointer"
                                              href="javascript:void(0)"
                                              onClick={() =>
                                                this.editScheduleOpen(val.id)
                                              }
                                            >
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip>{"Edit"}</Tooltip>
                                                }
                                              >
                                                <Button className="action-btn">
                                                  <TableEditSVG />
                                                </Button>
                                              </OverlayTrigger>
                                            </a>
                                          </span>
                                          <span>
                                            <a
                                              className="pointer"
                                              // href="javascript:void(0)"
                                              onClick={() =>
                                                this.handleDelete(
                                                  val.id,
                                                  val.title,
                                                  val.schedule_renewal_date
                                                )
                                              }
                                            >
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip>{"Delete"}</Tooltip>
                                                }
                                              >
                                                <Button className="action-btn">
                                                  <TableDeleteSVG />
                                                </Button>
                                              </OverlayTrigger>
                                            </a>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            ) : null}
                          </table>
                          <ScheduleDeleteConfirm
                            deletedScheduleName={this.state.deletedScheduleName}
                            displayDelete={this.state.displayDelete}
                            handleDeleteClose={this.handleDeleteClose}
                            handleDeleteSuccess={this.handleDeleteSuccess}
                            handleRenewDate={this.state.checkRenewalDate}
                            handleSpDelete={this.handleSpDeleteSuccess}
                            handleDeleteId={this.state.currentUsermanagementId}
                          />
                          <ViewSchedule
                            scheduleData={this.props.editScheduleDetails}
                            viewSchedule={this.state.viewDisplay}
                            handleViewClose={this.handleViewClose}
                          />
                          {this.state.runScheduleToast ? (
                            <Toast
                              className="success-alerts"
                              style={{ right: "35px" }}
                            >
                              <Toast.Body>
                                <div className="row align-items-center">
                                  <div className="col-md-2">
                                    <SuccessToastSVG />
                                  </div>
                                  <div className="col-md-8">
                                    {this.props.runScheduleResponse}
                                  </div>
                                  <div className="col-md-2">
                                    <button
                                      type="button"
                                      className="btn"
                                      onClick={this.addClose}
                                    >
                                      <CloseBtnSVG />
                                    </button>
                                  </div>
                                </div>
                              </Toast.Body>
                            </Toast>
                          ) : (
                            ""
                          )}
                          {this.state.addToast ? (
                            <Toast
                              className="success-alerts"
                              style={{ right: "35px" }}
                            >
                              <Toast.Body>
                                <div className="row align-items-center">
                                  <div className="col-md-2">
                                    <SuccessToastSVG />
                                  </div>
                                  <div className="col-md-8">
                                    {this.props.addScheduleSuccess}
                                  </div>
                                  <div className="col-md-2">
                                    <button
                                      type="button"
                                      className="btn"
                                      onClick={this.addClose}
                                    >
                                      <CloseBtnSVG />
                                    </button>
                                  </div>
                                </div>
                              </Toast.Body>
                            </Toast>
                          ) : (
                            ""
                          )}
                          {this.state.editToast ? (
                            <Toast
                              className="success-alerts"
                              style={{ right: "35px" }}
                            >
                              <Toast.Body>
                                <div className="row align-items-center">
                                  <div className="col-md-2">
                                    <SuccessToastSVG />
                                  </div>
                                  <div className="col-md-8">
                                    {this.props.editScheduleSuccess}
                                  </div>
                                  <div className="col-md-2">
                                    <button
                                      type="button"
                                      className="btn"
                                      onClick={this.editClose}
                                    >
                                      <CloseBtnSVG />
                                    </button>
                                  </div>
                                </div>
                              </Toast.Body>
                            </Toast>
                          ) : (
                            ""
                          )}
                          {this.state.deleteToast ? (
                            <Toast
                              className="success-alerts"
                              style={{ right: "35px" }}
                            >
                              <Toast.Body>
                                <div className="row align-items-center">
                                  <div className="col-md-2">
                                    <SuccessToastSVG />
                                  </div>
                                  <div className="col-md-8">
                                    {this.props.deleteScheduleSuccess}
                                  </div>
                                  <div className="col-md-2">
                                    <button
                                      type="button"
                                      className="btn"
                                      onClick={this.deleteClose}
                                    >
                                      <CloseBtnSVG />
                                    </button>
                                  </div>
                                </div>
                              </Toast.Body>
                            </Toast>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        <p className="text-center">
                          There is no schedule datas found
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row mt-2">
                    {this.props.total_count > 10 ? (
                      <div className="col-md-12">
                        {this.state.pagehandle ? null : (
                          <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={
                              this.props.total_count / Constants.PER_PAGE
                            }
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={10}
                            onPageChange={this.handlePageNext}
                            containerClassName={"pagination"}
                            nextClassName={"next-page"}
                            activeClassName={"active"}
                          />
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    // allScheduleList: bindActionCreators(fetchSchedule, dispatch),
    // createSchedule: bindActionCreators(addSchedule, dispatch),
    getAllSchedules: bindActionCreators(getAllSchedules, dispatch),
    getAllTaskSchedules: bindActionCreators(getAllTaskSchedules, dispatch),
    getTaskNameById: bindActionCreators(getTaskById, dispatch),
    scheduleDelete: bindActionCreators(scheduleDelete, dispatch),
    spScheduleDelete: bindActionCreators(spScheduleDelete, dispatch),
    listAllClient: bindActionCreators(listAllClient, dispatch),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
    runApiSchedule: bindActionCreators(runApiSchedule, dispatch),
    listAllTask: bindActionCreators(getTaskDetails, dispatch),
    listAllCleaner: bindActionCreators(listAllCleaner, dispatch),
    addSchedule: bindActionCreators(addSchedule, dispatch),
    editSchedule: bindActionCreators(editSchedule, dispatch),
    editSPSchedule: bindActionCreators(editSPSchedule, dispatch),
    filterBranch: bindActionCreators(filterSchedule, dispatch),
    updateSchedule: bindActionCreators(updateSchedule, dispatch),
    updateSPSchedule: bindActionCreators(updateSPSchedule, dispatch),
    getTaskDropDown: bindActionCreators(taskDropDown, dispatch),
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
    updateAddScheduleStatus: bindActionCreators(
      updateAddScheduleStatus,
      dispatch
    ),
  };
};
const mapStateToProps = (state: RootState) => ({
  // scheduleFetch: state.schedule.scheduleFetch,
  data: state.schedule.data,
  getResponseDelete: state.schedule.deleteResponse,
  clientList: state.tasktemplate.clientList,
  branchList: state.tasktemplate.branchList,
  taskNames: state.schedule.taskDetails,
  cleanerList: state.tasktemplate.cleanerList,
  editContent: state.schedule.editScheduleDetails,
  scheduleFilterData: state.schedule.scheduleFilterData,
  updateMessage: state.schedule.updateMessage,
  taskDropDownList: state.schedule.taskDropDownList,
  total_count: state.schedule.total_count,
  addScheduleSuccess: state.schedule.addScheduleSuccess,
  editScheduleSuccess: state.schedule.editScheduleSuccess,
  deleteScheduleSuccess: state.schedule.deleteScheduleSuccess,
  scheduleFetchStatus: state.schedule.scheduleFetchStatus,
  processTrigger: state.schedule.processTrigger,
  progressPercentage: state.schedule.progressPercentage,
  runScheduleResponse: state.schedule.runScheduleResponse,
  editScheduleDetails: state.schedule.editScheduleDetails,
  userLoginPermission: state.sidebar.userLoginPermission,
  status: state.sidebar.status,
  userLoginDetails: state.sidebar.userLoginDetails,
  addScheduleModalStatus: state.schedule.addScheduleModalStatus,
});

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
