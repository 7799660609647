import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";
import docImage from "../../assets/images/google-docs.png";
import Constants from "../../shared/Constants";
import { Modal } from "react-bootstrap";
import {
  StatisticsCount,
  Complaint,
  ChatMessage,
} from "../../assets/images/ImagesV2";
import {
  AttachmentDownloadSVG,
  CloseBtnSVG,
} from "../../assets/images/ImagesSVG";
import { TableDeleteSVG1 } from "../../assets/images/ImagesV2";
import ChatDummyImg from "../../assets/images/chat-dummy-img.jpg";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Formbootstrap from "react-bootstrap/Form";
import Button from "@material-ui/core/Button";
import { PaperClipSVG, TrashSVG } from "../../assets/images/ImagesV2";
import AttachmentPreview from "../../shared/Components/AttachmentPreview";
import { constants } from "buffer";
import { CustomSelect } from "../../shared/Components/CustomSelect";
import {
  updateSatisfiedStatus,
  addComments,
  viewAuditById,
  getAuditById,
  deleteAuditComments,
  getAllAudits,
} from "../../shared/Reducers";
import moment from "moment";

export interface IViewAuditState {
  editCommentData: any;
  addCommentStatus: any;
  areaTask: any;
  attachmentFiles: any;
  attachmentUrls: any;
  comments_audit_branch_id: any;
  comments_audit_area_id: any;
  optionselect: any;
  comments_audit_task_id: any;
  selectedAttachment: any;
  AttachmentPreviewStatus: boolean;
  previewType: any;
  previewUrl: any;
  comments_delete: any;
  comment_delete_modal: any;
}
export interface IViewAuditProps {
  updateSatisfiedStatus: any;
  data: any;
  addComments: any;
  viewAuditById: any;
  addCommentStatus: boolean;
  userLoginTaskstatus?: any;
  userLoginDetails?: any;
  editAuditSuccess?: string;
  getAuditById: any;
  deleteAuditComments: any;
  // getAuditById: any;
}
class ViewAudit extends React.Component<IViewAuditProps, IViewAuditState> {
  constructor(props: any) {
    super(props);
    this.state = {
      editCommentData: "",
      addCommentStatus: "",
      areaTask: [],
      attachmentFiles: [],
      attachmentUrls: [],
      comments_audit_branch_id: 0,
      comments_audit_area_id: 0,
      comments_audit_task_id: 0,
      optionselect: [],
      selectedAttachment: null,
      AttachmentPreviewStatus: false,
      previewType: "",
      previewUrl: "",
      comments_delete: null,
      comment_delete_modal: false,
    };
  }

  componentDidMount(): void {
    if (this.props.data) {
      this.setState({ areaTask: this.props.data.area });
    }

    this.setState({
      optionselect: this.props.userLoginTaskstatus.map((i: any) => ({
        value: i.id,
        label: i.name,
      })),
    });
  }

  componentDidUpdate(
    prevProps: Readonly<IViewAuditProps>,
    prevState: Readonly<IViewAuditState>,
    snapshot?: any
  ): void {
    if (this.props.data !== prevProps.data) {
      this.setState({ areaTask: this.props.data.area });
    }
    if (prevProps.addCommentStatus !== this.props.addCommentStatus) {
      if (this.props.data && this.props.data.id > 0) {
        this.props.viewAuditById(this.props.data.id);
        this.setState({ addCommentStatus: false });
      }
    }

    if (this.props.editAuditSuccess !== prevProps.editAuditSuccess) {
      this.props.viewAuditById(this.props.data.id);
    }
  }

  validationSchema = () => {
    return Yup.object().shape({
      comments: Yup.string().required("Please enter comments"),
      task_status: Yup.string().required("Please Select Your Status"),
    });
  };

  handleAddComment = async (formValues: any) => {
    let data = new FormData();
    for (let index = 0; index < this.state.attachmentFiles.length; index++) {
      data.append("attachments", this.state.attachmentFiles[index]);
    }
    data.append("comments", formValues.comments);
    data.append("comments_task_status", formValues.task_status);
    data.append("reference_type", "audit_task");
    data.append("reference_id", this.state.comments_audit_task_id);
    await this.props.addComments(data);
  };

  handleImageChange = async (e: any) => {
    let files = [];
    let urls = [];
    files = [...e.target.files];

    let fileurls: any = [];
    await files.map(async (file: any) => {
      fileurls.push({
        url: URL.createObjectURL(file),
        file_type: file.type,
      });
    });
    urls = [...fileurls];
    this.setState({
      attachmentFiles: [...this.state.attachmentFiles, ...files],
    });
    this.setState(
      { attachmentUrls: [...this.state.attachmentUrls, ...urls] },
      () => {
        e.target.value = "";
      }
    );
  };

  handleDeleteImage = (index: number) => {
    const files = this.state.attachmentFiles;
    const urls = this.state.attachmentUrls;
    files.splice(index, 1);
    urls.splice(index, 1);
    this.setState({ attachmentFiles: files });
    this.setState({ attachmentUrls: urls });
  };

  selectSatisfiedStatus = (
    status: number,
    area_index: number,
    task_index: number,
    task_id: string
  ) => {
    this.props.updateSatisfiedStatus(status, task_id);

    let areaTask: any = this.state.areaTask;
    areaTask[area_index].task[task_index].satisfied_status = status;
    this.setState({ areaTask: areaTask });
  };
  handlePreviewClose = () => {
    this.setState({ AttachmentPreviewStatus: false });
  };
  handlePreviewOpen = (type: any, url: any) => {
    console.log("preview", type, url);

    if (
      type === "image/png" ||
      type === "image/jpeg" ||
      type === "image/jpg" ||
      type === "video/mp4" ||
      type === "video/webm" ||
      type === "application/pdf"
    ) {
      this.setState({
        AttachmentPreviewStatus: true,
        previewUrl: url,
        previewType: type,
      });
    }
  };
  openAttachmentPreview = (attachment: any) => {
    console.log("Attachment to preview:", attachment);
    this.setState({ selectedAttachment: attachment.target });
  };

  closeAttachmentPreview = () => {
    this.setState({ selectedAttachment: null });
  };

  downloadFile = (url: string, name: any) => {
    console.log({ url, name });

    this.downloadAttachment(url, name);
  };

  downloadAttachment = (url: any, filename: any) => {
    if (url) {
      if (!filename) filename = url.split("\\").pop().split("/").pop();
      fetch(url, {
        headers: new Headers({
          Origin: window.location.origin,
        }),
        mode: "cors",
      })
        .then((response) => response.blob())
        .then((blob) => {
          let blobUrl = window.URL.createObjectURL(blob);
          this.forceDownload(blobUrl, filename);
        })
        .catch((e) => console.error(e));
    }
  };

  forceDownload = (blob: any, filename: String) => {
    const a: any = document.createElement("a");
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  handleComment_delete_modal = (id: any) => {
    this.setState({ comments_delete: id });
    this.setState({ comment_delete_modal: true });
  };
  handleComments_delete = () => {
    this.props.deleteAuditComments(this.state.comments_delete);
    this.props.addComments();
    this.setState({ comment_delete_modal: false, comments_delete: null });
  };

  render(): React.ReactNode {
    const initialValues = {
      comments: "",
      task_status: "",
      task_list: "",
      attachments: "",
    };
    const {
      address,
      area,
      area_count,
      branch_id,
      branch_name,
      client_id,
      comments_count,
      first_name,
      id,
      last_name,
      name,
      score,
      status,
      task_count,
    } = this.props.data;
    console.log("this.props.data", this.props.data);

    return (
      <div>
        <div>
          <h3>
            {first_name + " " + last_name} - {branch_name},
            <small style={{ fontWeight: "300" }}>{address}</small>
          </h3>
        </div>
        <div className="row justify-content-between mt-4 mb-4">
          <div className="col-md-6 col-49per">
            <div className="row chat-complaint-des">
              <div className="col">
                <p className="m-0 text-grey">Score</p>
              </div>
              <div className="col">
                <p className="m-0">
                  {/* {this.props.data?.score}/{task_count} */}
                  {score+"/"+task_count}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 text-right">
            <a
              className="btn btn-primary btn-border"
              href={Constants.baseUrl + `audit/export/` + id}
            >
              Export
            </a>
          </div>
          {/* <div className="col-md-6 col-49per">
            <div className="row chat-complaint-des">
              <div className="col">
                <p className="m-0 text-grey">Created Date</p>
              </div>
              <div className="col">
                <p className="m-0">12-07-2023</p>
              </div>
            </div>
          </div> */}
        </div>
        {this.state.areaTask &&
          this.state.areaTask.map((val: any, index: number) => (
            <div className="row mt-3">
              <div className="col-md-12">
                <h6 className="text-capitalize">{val.area}</h6>
              </div>

              {/* View Audit Page Design */}
              {val.task &&
                val.task.map((task: any, task_index: number) => (
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-5">
                          <p className="m-0 fw-500">{task.task}</p>
                        </div>
                        <div className="col-7">
                          {this.props.userLoginDetails?.permission
                            ?.audit_satisfied_unsatisfied === 0 &&
                            task.satisfied_status !== 0 ? (
                            <p
                              className={
                                task.satisfied_status === Constants.SATISFIED
                                  ? "completed-badge view-task-comment-badge"
                                  : "incompleted-badge view-task-comment-badge"
                              }
                            >
                              {task.satisfied_status === Constants.SATISFIED
                                ? "Satisfied"
                                : "Unsatisfied"}
                            </p>
                          ) : (
                            <></>
                          )}
                          {this.props.userLoginDetails?.permission
                            ?.audit_satisfied_unsatisfied === 1 ? (
                            <div className="audit-check-box">
                              <input
                                name="satisfied"
                                id={"satisfied-" + index + "-" + task_index}
                                checked={
                                  task.satisfied_status === Constants.SATISFIED
                                    ? true
                                    : false
                                }
                                type="checkbox"
                                value={Constants.SATISFIED}
                                onClick={() =>
                                  this.selectSatisfiedStatus(
                                    Constants.SATISFIED,
                                    index,
                                    task_index,
                                    task.task_id
                                  )
                                }
                              />
                              <label
                                className="form-label fw-500"
                                style={{
                                  paddingLeft: "8px",
                                  paddingRight: "8px",
                                }}
                              >
                                Satisfied
                              </label>
                              <input
                                name="unsatisfied"
                                id={"unsatisfied-" + index + "-" + task_index}
                                checked={
                                  task.satisfied_status ===
                                    Constants.UNSATISFIED
                                    ? true
                                    : false
                                }
                                type="checkbox"
                                value={Constants.UNSATISFIED}
                                onClick={() =>
                                  this.selectSatisfiedStatus(
                                    Constants.UNSATISFIED,
                                    index,
                                    task_index,
                                    task.task_id
                                  )
                                }
                              />
                              <label
                                className="form-label fw-500"
                                style={{
                                  paddingRight: "8px",
                                  paddingLeft: "8px",
                                }}
                              >
                                UnSatisfied
                              </label>
                              {task.satisfied_at !== null && (
                                <span className="text-secondary font-weight-light text-right">
                                  {moment(`${task.satisfied_at}`).format(
                                    "MMMM DD,YYYY"
                                  )}{" "}
                                  at{" "}
                                  {moment(`${task.satisfied_at}`).format(
                                    "hh:mm A"
                                  )}
                                </span>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}

                          <p
                            className="text-end m-0"
                            onClick={() =>
                              this.setState({
                                addCommentStatus: true,
                                comments_audit_branch_id: id,
                                comments_audit_area_id: val.area_id,
                                comments_audit_task_id: task.task_id,
                                attachmentFiles: [],
                                attachmentUrls: [],
                              })
                            }
                          >
                            <ChatMessage />
                          </p>
                          <hr></hr>

                          {task.comments &&
                            task.comments.map(
                              (comments: any, comments_index: number) => {
                                console.log("comments", comments);
                                return (
                                  <div className="mb-4">
                                    <p className="mb-2">{comments.comments}</p>
                                    <p
                                      className="text-end m-0"
                                      style={{cursor: "pointer"}}
                                      onClick={() =>
                                        this.handleComment_delete_modal(
                                          comments.id
                                        )
                                      }
                                    >
                                      <TableDeleteSVG1 />
                                    </p>
                                    <div className="row mt-2">
                                      <div className="col-md-12 d-flex col flex-end">
                                        <div className="d-flex row">
                                          {comments.attachment &&
                                            comments.attachment.map(
                                              (obj: any, index: number) => (
                                                <div className="img-card mr-16px mb-3">
                                                  <span className="download">
                                                    <a
                                                      href="javascript:void(0)"
                                                      onClick={() =>
                                                        this.downloadFile(
                                                          Constants.imgUrl +
                                                          obj.file_name,
                                                          obj.file_name
                                                        )
                                                      }
                                                    >
                                                      <AttachmentDownloadSVG />
                                                    </a>
                                                  </span>
                                                  <div
                                                    className="img-preview"
                                                    onClick={() =>
                                                      this.handlePreviewOpen(
                                                        obj.type,
                                                        obj.s3_url
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      className="img-thumb-perview"
                                                      src={
                                                        obj.type ===
                                                          "application/pdf"
                                                          ? pdfImage
                                                          : obj.type ===
                                                            "video/mp4" ||
                                                            obj.type ===
                                                            "video/webm"
                                                            ? videoImage
                                                            : obj.type ===
                                                              "image/png" ||
                                                              obj.type ===
                                                              "image/jpeg" ||
                                                              obj.type ===
                                                              "image/jpg"
                                                              ? obj.s3_url
                                                              : docImage
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              )
                                            )}
                                        </div>

                                      </div>
                                    </div>
                                    <p
                                      className={
                                        comments.task_status +
                                        "-badge view-task-comment-badge"
                                      }
                                    >
                                      {comments.task_status}
                                    </p>
                                    <div className="d-flex align-items-center gap-2">
                                      <p className="text-capitalize mb-0">
                                        <span>-&nbsp;</span>By,{" "}
                                        {comments.first_name +
                                          " " +
                                          comments.last_name}{" "}
                                        <span className="text-secondary font-weight-light">
                                          {moment(
                                            `${comments.created_at}`
                                          ).format("MMMM DD,YYYY")}{" "}
                                          at{" "}
                                          {moment(
                                            `${comments.created_at}`
                                          ).format("hh:mm A")}
                                        </span>
                                      </p>
                                    </div>
                                    <hr></hr>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ))}
        <Modal
          className="edit-comments"
          size="lg"
          dialogClassName="modal-90w"
          aria-labelledby="contained-modal-title-vcenter"
          show={this.state.addCommentStatus}
          onHide={() => this.setState({ addCommentStatus: false })}
        >
          <Modal.Header>
            <h3 className="mb-0">Add Comments</h3>
            <a
              href="javascript:void(0)"
              className="qr-modal-close"
              onClick={() => this.setState({ addCommentStatus: false })}
            >
              <CloseBtnSVG />
            </a>
          </Modal.Header>
          <Modal.Body className="task-view">
            <div className="mt-2">
              <Formik
                validationSchema={this.validationSchema}
                onSubmit={this.handleAddComment}
                initialValues={initialValues}
              >
                <Form id="addCommentsFormReset">
                  <div className="row">
                    <div className="col-md-12 col-lg-9">
                      <Field
                        as="textarea"
                        className="form-control comment-field w-100"
                        name="comments"
                        placeholder="Add a comment..."
                      />
                      <ErrorMessage
                        name={"comments"}
                        component={"div"}
                        className="cus-alert"
                      />
                    </div>
                    <div className="col-md-12 col-lg-3">
                      <div className="mb-3 ipad-mt-3">
                        <Field
                          className="custom-select dropdown-field"
                          name="task_status"
                          // values={{ value: Constants.TASK_CREATED, lable: "To do" }}
                          options={this.state.optionselect}
                          component={CustomSelect}
                          placeholder="Select Status"
                          isMulti={false}
                        />
                        <ErrorMessage
                          name={"task_status"}
                          component={"div"}
                          className="cus-alert"
                        />
                      </div>
                      <div className="attachment-btn">
                        <input
                          type="file"
                          multiple
                          accept=".pdf,.PDF,.mp4,.MP4,.webm,.WEBM,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.doc,.DOC,.docx.DOCX"
                          style={{ display: "none" }}
                          onChange={this.handleImageChange}
                          id="contained-button-file"
                        />
                        <label
                          htmlFor="contained-button-file"
                          style={{ width: "100%" }}
                        >
                          <Button
                            variant="contained"
                            component="span"
                            style={{ width: "100%", justifyContent: "left" }}
                          >
                            <PaperClipSVG /> Attach
                          </Button>
                        </label>
                      </div>
                    </div>
                    {/* <div className="col-md-12">
                          <ErrorMessage name={"task_status"} component={"div"} className="cus-alert" />
                      </div> */}
                  </div>
                  <div className="row mt-3 mb-1">
                    {this.state.attachmentUrls.length > 0 ? (
                      <div className="mb-2">
                        <label className="form-label m-0">Attachments</label>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex row">
                        {this.state.attachmentUrls &&
                          this.state.attachmentUrls.map(
                            (url: any, index: number) => (
                              <div className="img-card mr-16px mb-3">
                                <span className="delete">
                                  <a
                                    onClick={() =>
                                      this.handleDeleteImage(index)
                                    }
                                  >
                                    <TrashSVG />
                                  </a>
                                </span>
                                <div className="img-preview">
                                  <img
                                    className="img-thumb-perview"
                                    src={
                                      url.file_type === "application/pdf"
                                        ? pdfImage
                                        : url.file_type === "video/mp4" ||
                                          url.file_type === "video/webm"
                                          ? videoImage
                                          : url.file_type === "image/png" ||
                                            url.file_type === "image/jpeg" ||
                                            url.file_type === "image/jpg"
                                            ? url.url
                                            : docImage
                                    }
                                  />
                                </div>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <button
                        type="submit"
                        id="create-button"
                        className="btn btn-primary create-button"
                      >
                        Save{" "}
                        <span
                          id="spinner-border"
                          className="spinner-border spinner-border-sm d-none"
                        ></span>
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          className="edit-comments"
          // size="lg"
          dialogClassName="modal-90w"
          aria-labelledby="contained-modal-title-vcenter"
          show={this.state.comment_delete_modal}
          onHide={() => this.setState({ comment_delete_modal: false })}
        >
          <Modal.Header>
            <h3 className="mb-0">Add Comments</h3>
            <a
              href="javascript:void(0)"
              className="qr-modal-close"
              onClick={() => this.setState({ comment_delete_modal: false })}
            >
              <CloseBtnSVG />
            </a>
          </Modal.Header>
          <Modal.Body>
            <div className="delete-modal-body">
              <h3 className="mb-3">Delete audit</h3>
              <p>Do you want to delete this comment with photo</p>
            </div>
            <div className="delete-footer qr-modal-footer d-flex justify-content-end pt-2">
              <Button
                className="comment-delete-btn"
                onClick={() => {
                  this.setState({ comment_delete_modal: false });
                }}
              >
                <span className="delete-audit-cancel-btn">Cancel</span>
              </Button>
              <Button
                className="delete-cancel delete-button"
                // variant="primary"
                onClick={() => {
                  this.handleComments_delete();
                }}
              >
                Delete
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        {/* <Modal
          show={this.state.selectedAttachment !== null}
          onHide={this.closeAttachmentPreview}
        >
          <Modal.Header closeButton>
            <Modal.Title>Attachment Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.selectedAttachment && (
              <img
                src={this.state.selectedAttachment.src}
                alt="Attachment"
                style={{ width: "100%", height: "450px" }}
              />
            )}
          </Modal.Body>
        </Modal> */}
        <AttachmentPreview
          displayPreview={this.state.AttachmentPreviewStatus}
          previewUrl={this.state.previewUrl}
          previewType={this.state.previewType}
          handlePreviewClose={this.handlePreviewClose}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  addCommentStatus: state.app.addCommentStatus,
  userLoginTaskstatus: state.sidebar.userLoginTaskstatus,
  userLoginDetails: state.sidebar.userLoginDetails,
  data: state.audit.viewAuditData,
  editAuditSuccess: state.audit.editAuditSuccess,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateSatisfiedStatus: bindActionCreators(updateSatisfiedStatus, dispatch),
    addComments: bindActionCreators(addComments, dispatch),
    viewAuditById: bindActionCreators(viewAuditById, dispatch),
    getAuditById: bindActionCreators(getAuditById, dispatch),
    deleteAuditComments: bindActionCreators(deleteAuditComments, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewAudit);
