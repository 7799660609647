import { THomeActive } from "../../types/user.type";

export const API_DASHBOARD_COUNTS = "dashboard_count/LOAD";
export const API_DASHBOARD_COUNTS_SUCCESS = "dashboard_count/LOAD_SUCCESS";
export const API_DASHBOARD_COUNTS_FAIL = "dashboard_count/LOAD_FAIL";
export const API_DASHBOARD_REPORT = "dashboard_report/LOAD";
export const API_DASHBOARD_REPORT_SUCCESS = "dashboard_report/LOAD_SUCCESS";
export const API_DASHBOARD_REPORT_FAIL = "dashboard_report/LOAD_FAIL";
export const API_LAST_ACTIVITY = "last_activity/LOAD";
export const API_LAST_ACTIVITY_SUCCESS = "last_activity/LOAD_SUCCESS";
export const API_LAST_ACTIVITY_FAIL = "last_activity/LOAD_FAIL";

export const API_DASHBOARD_COMPLAINT = "api_dashboard_complaint/LOAD";
export const API_DASHBOARD_COMPLAINT_SUCCESS = "api_dashboard_complaint/LOAD_SUCCESS";
export const API_DASHBOARD_COMPLAINT_FAIL = "api_dashboard_complaint/LOAD_FAIL";
export const UPDATE_DASHBOARD_VIEW_REPORT_STATUS = "UPDATE_DASHBOARD_VIEW_REPORT_STATUS";

export interface DashboardState {
  Countdata: IDashboardData | null;
  reportData: any;
  homeActivityData: THomeActive[];
  complaintsData: any;
  dashboardViewReportStatus: boolean;
}

export interface IDashboardData {
  total_client: string;
  total_branch: string;
  total_cleaner: string;
}
