import { Button, Modal, OverlayTrigger, Toast, Tooltip } from "react-bootstrap";
import { CloseBtnSVG, SuccessToastSVG } from "../../assets/images/ImagesV2";
import { Dispatch, bindActionCreators } from "redux";
import {
  allCleanerInvoiceNotification,
  allCleanerNotification,
  allTaskNotification,
  cleanerApproveForLogin,
  cleanerInvoiceDetails,
  getCleanerBranchLogoutActivity,
  allContractDocumentExpiry,
  deleteContractExpiry
} from "../../shared/Reducers";
import { TableDeleteSVG } from "../../assets/images/ImagesV2";
import DeleteNotification from "./DeleteNotification";

import Accordion from "react-bootstrap/Accordion";
import CleanersCard from "./CleanersCard";
import Constants from "../../shared/Constants";
import React from "react";
import ReactPaginate from "react-paginate";
import { RootState } from "../../index";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TaskCard from "./TaskCard";
import { connect } from "react-redux";
import { constants } from "buffer";
import { log } from "console";
import moment from "moment";

type Props = {
  allCleanerNotification: any;
  allTaskNotification: any;
  allCleanerInvoiceNotification: any;
  taskData: any;
  cleanerData: any;
  cleanerInvoiceDatas: any;
  contractDocumentExpiryData: any;
  task_total_count: number;
  cleaner_total_count: number;
  cleaner_invoice_total_count: number;
  filteredNotification: any;
  search: any;
  userLoginDetails?: any;
  getCleanerBranchLogoutActivity: any;
  cleanerLogoutActivityData?: any;
  approveCleanerLoginStatus: boolean;
  cleanerLogoutActivityTotalCount?: number;
  cleanerApproveForLogin: any;
  cleanerInvoiceDetails: any;
  cleanerInvoiceLists: any;
  allContractExpiry: any;
  deleteExpiry: any;
};

type State = {
  offset: number;
  totalPages: number;
  timeout: any;
  cleanerBranchActivityCleanerId?: any;
  cleanerBranchActivityLogs?: any;
  approve_login_id: number;
  approve_login_modal: boolean;
  invoiceViewId: number;
  cleaner_invoice_modal: boolean;
  showToast: any;
  invoiceCleanerName: string;
  displayDelete: boolean;
  currentDeleteId: any;
};

class HomeActivity extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      offset: 0,
      totalPages: 2,
      timeout: null,
      cleanerBranchActivityCleanerId: [],
      cleanerBranchActivityLogs: [],
      approve_login_id: 0,
      approve_login_modal: false,
      invoiceViewId: 0,
      cleaner_invoice_modal: false,
      invoiceCleanerName: "",
      showToast: false,
      displayDelete: false,
      currentDeleteId: ""
    };
  }
  componentDidMount(): void {
    
    
    if (Constants.LOGIN_USER_TYPE === Constants.CLEANERUTINT) {
      this.getCleanerData(0, 10);
    }
    this.getTaskData(0, 10);
    this.getCleanerInvoiceData(0, 10);
    this.props.getCleanerBranchLogoutActivity();
    this.getContractExpiryList()
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
   
    // if (prevProps.search !== this.props.search) {
    //   this.handleFilter(this.props.search);
    // }

    if (
      this.props.approveCleanerLoginStatus !==
        prevProps.approveCleanerLoginStatus &&
      this.props.approveCleanerLoginStatus === true
    ) {
      this.props.getCleanerBranchLogoutActivity();
      this.setState({ approve_login_modal: false });
    }
    if (
      this.props.cleanerLogoutActivityData !==
      prevProps.cleanerLogoutActivityData
    ) {
      let CleanerIds: any = [];
      const groupedData = this.props.cleanerLogoutActivityData.reduce(
        (acc: any, item: any) => {
          const { cleaner_id, ...rest } = item;
          if (!acc[cleaner_id]) {
            acc[cleaner_id] = [];
          }

          CleanerIds = this.addNumberToArrayIfNotExist(CleanerIds, cleaner_id);

          acc[cleaner_id].push(rest);

          this.setState({
            cleanerBranchActivityCleanerId: CleanerIds,
            cleanerBranchActivityLogs: acc,
          });
          return acc;
        },
        {} as Record<string, any[]>
      );
    }
  }

  addNumberToArrayIfNotExist = (arr: number[], numToAdd: number): number[] => {
    if (!arr.includes(numToAdd)) {
      arr.push(numToAdd);
    }
    return arr;
  };

  deleteToastClose = () => {
    this.setState({ showToast: false });
  };

  handleDelete = (id: any)=>{
    this.setState({ displayDelete: true, currentDeleteId: id });
  }

  handleDeleteClose = async (status: boolean) => {
    this.setState({ displayDelete: false, currentDeleteId: "" });
    if(status) {
      await this.props.deleteExpiry(this.state.currentDeleteId);
      setTimeout(async () => {
      await this.props.allContractExpiry()
        
      }, 1000);
    }
  };

  handleFilter = (e: any) => {
    console.log(this.props.cleanerApproveForLogin,"approve for login");
    const value: string = e.toLowerCase();
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

    this.setState({
      timeout: setTimeout(async () => {
        try {
          const querys = `notification?search=${value}&sort=&order=&offset=0&limit=10`;
          this.props.allTaskNotification(querys);
        } catch (error) {
          console.log(error);
        }
        // Trigger your function here that you want to call when user stops typing
      }, 1000), // Set the delay time in ms
    });
  };

  showToastMessage = () => {
    console.log(this.props.cleanerApproveForLogin,"cleaner login");
    this.setState({
      showToast: true,
    });
    setTimeout(() => {
      this.setState({ showToast: false });
    }, 2000);
  };

  getContractExpiryList = async () => {
    try {
      await this.props.allContractExpiry();
    } catch (error) {
      console.log(error);
    }
  };

  getCleanerData = async (offset: number, perPage: number) => {
    try {
      const query = `cleaner-notification?search=&sort=&order=&offset=${offset}&limit=${perPage}`;
      this.props.allCleanerNotification(query);
    } catch (error) {
      console.log(error);
    }
  };

  getTaskData = async (offset: number, perPage: number) => {
    try {
      const query = `notification?search=&sort=&order=&offset=${offset}&limit=${perPage}`;
      this.props.allTaskNotification(query);
    } catch (error) {
      console.log(error);
    }
  };

  handleCleanerInvoicePageClick = (e: any) => {
    if (
      e.selected <
      this.props.cleaner_invoice_total_count / Constants.PER_PAGE
    ) {
      this.getCleanerInvoiceData(
        e.selected * Constants.PER_PAGE,
        Constants.PER_PAGE
        // this.props.search
      );
    }
  };

  handleContractExpiryPageClick = (e: any) => {
    if (
      e.selected <
      this.props.contractDocumentExpiryData / Constants.PER_PAGE
    ) {
      this.props.allContractExpiry(
        e.selected * Constants.PER_PAGE,
        Constants.PER_PAGE
        // this.props.search
      );
    }
  };

  getCleanerInvoiceData = async (offset: number, perPage: number) => {
    try {
      const query = `search=&sort=&order=&offset=${offset}&limit=${perPage}`;
      this.props.allCleanerInvoiceNotification(query);
    } catch (error) {
      console.log(error);
    }
  };

  handleCleanerPageClick = (e: any) => {
    if (e.selected < this.props.cleaner_total_count / Constants.PER_PAGE) {
      this.getCleanerData(
        e.selected * Constants.PER_PAGE,
        Constants.PER_PAGE
        // this.props.search
      );
    }
  };

  handleTaskPageClick = (e: any) => {
    if (e.selected < this.props.task_total_count / Constants.PER_PAGE) {
      this.getTaskData(
        e.selected * Constants.PER_PAGE,
        Constants.PER_PAGE
        // this.props.search
      );
    }
  };

  render(): React.ReactNode {
    return (
      <div className="notification-tab">
        <Tabs
          defaultActiveKey={
            this.props.userLoginDetails?.permission?.notification_task_notifictaion === 1 ? "task-notification" :
            this.props.userLoginDetails?.permission?.notification_cleaner_activity === 1 ? "cleaner_activity" :
            this.props.userLoginDetails?.permission?.notification_cleaner_invoice_form === 1 ? "cleaner_invoice_form" : "task-notification"}
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          {/* <Tab eventKey="cleaner-activity" title="CLEANER ACTIVITY">
        <Tabs
          defaultActiveKey="task-notification"
          transition={false}
          id="uncontrolled-tab-example"
          className="mb-3">
          {/* <Tab eventKey="cleaner-activity" title="CLEANER ACTIVITY">
                        {this.props.cleanerData.map((cleaners: any, index: any) =>
                            <CleanersCard key={index + cleaners.first_name} cleaners={cleaners} cleanersCount={this.props.cleaner_total_count} />
                        )}
                        <div className="card mb-3">
                            <div className="card-body pl-pr-25px">
                                <div className="row">
                                    <div className="col-md-10">
                                        <p className="mb-1"><span className="fw-600">James Callum</span> signed in to branch 1 on the chennai</p>
                                        <p className="mb-0 fs-12">Nov 5, 12:26pm</p>
                                    </div>
                                    <div className="col-md-2 align-self-center">
                                        <p className="mb-0 signout-badge float-end">Signed out</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={this.props.cleaner_total_count / Constants.PER_PAGE}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={this.handleCleanerPageClick}
                                containerClassName={"pagination"}
                                nextClassName={"next-page"}
                                activeClassName={"active"}
                            />
                        </div>
                    </Tab> */}
          {this.props.userLoginDetails?.permission
            ?.notification_task_notifictaion === 1 && (
            <Tab eventKey="task-notification" title="TASK NOTIFICATIONS">
              {this.props.filteredNotification.length === 0 ? (
                <p className="no-notificate">There is no Notification found !</p>
              ) : (
                this.props.filteredNotification.map(
                  (tasks: any, index: any) => (
                    <TaskCard
                      key={index + tasks.first_name}
                      tasks={tasks}
                      taskCount={this.props.task_total_count}
                      delete_Notification={true}
                    />
                  )
                )
              )}

              <div className="row mt-2">
                {this.props.task_total_count > 10 ? (
                  <div className="col-md-12">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={
                        this.props.task_total_count / Constants.PER_PAGE
                      }
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={this.handleTaskPageClick}
                      containerClassName={"pagination"}
                      nextClassName={"next-page"}
                      activeClassName={"active"}
                    />
                  </div>
                ) : null}
              </div>
            </Tab>
          )}
          {/* Cleaner activity tab*/}
          {this.props.userLoginDetails?.permission
            ?.notification_cleaner_activity === 1 && (
            <Tab
              eventKey="cleaner_activity"
              title="CLEANER OUT OF BRANCH ACTIVITY"
            >
              <div className="cleaner-act-accordion">
                {this.state.cleanerBranchActivityCleanerId &&
                this.state.cleanerBranchActivityCleanerId.length > 0 ? (
                  this.state.cleanerBranchActivityCleanerId.map(
                    (cleanerId: any, index: any) => {
                      return (
                        <Accordion key={cleanerId}>
                          <Accordion.Item eventKey={index}>
                            <Accordion.Header>
                              <div className="w-95per">
                                {this.state.cleanerBranchActivityLogs[
                                  cleanerId
                                ].map((logs: any, logIndex: number) => {
                                  return (
                                    <>
                                      {logIndex === 0 && (
                                        <>
                                          <div className="row align-items-center w-97_4per">
                                            <div className="col-md-8">
                                              <p className="mb-1">
                                                <span className="fw-600">
                                                  {logs.cleaner_name}
                                                </span>{" "}
                                                is out of the branch now without
                                                logout his work time
                                              </p>
                                            </div>

                                            <>
                                              {this.state.cleanerBranchActivityLogs[
                                                cleanerId
                                              ].reduce(
                                                (count: any, item: any) => {
                                                  if (
                                                    item.approve_status === 0
                                                  ) {
                                                    return count + 1;
                                                  }
                                                  return count;
                                                },
                                                0
                                              ) >= logs.warning_count && (
                                                <div className="col-md-4 text-center">
                                                  <p className="m-0 incompleted-badge">
                                                    Automatically logged out
                                                    from the branch
                                                  </p>
                                                </div>
                                              )}
                                            </>
                                          </div>
                                          <>
                                            {this.state.cleanerBranchActivityLogs[
                                              cleanerId
                                            ].reduce(
                                              (count: any, item: any) => {
                                                if (item.approve_status === 0) {
                                                  return count + 1;
                                                }
                                                return count;
                                              },
                                              0
                                            ) >= logs.warning_count && (
                                              <div className="row align-items-center w-97_4per">
                                                <div className="col-md-12">
                                                  <button
                                                    className="btn btn-outline-primary"
                                                    onClick={() => {
                                                      this.setState({
                                                        approve_login_id:
                                                          cleanerId,
                                                        approve_login_modal:
                                                          true,
                                                      });
                                                    }}
                                                  >
                                                    Approve to Login
                                                  </button>
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                              </div>
                            </Accordion.Header>
                            <Accordion.Body className="p-0">
                              {this.state.cleanerBranchActivityLogs[
                                cleanerId
                              ].map((logs: any, index: any) => {
                                return (
                                  <div className="w-100 cus-accr-row">
                                    <div className="row align-items-center w-97_4per">
                                      <div className="col-md-10">
                                        <p className="mb-1">
                                          <span className="fw-600">
                                            {logs.cleaner_name}{" "}
                                          </span>
                                          has logged in at{" "}
                                          {logs.sign_in_date_time !== null
                                            ? " in " +
                                              moment(
                                                logs.sign_in_date_time
                                              ).format("MMM DD, hh:mm A")
                                            : ""}{" "}
                                          the {logs.branch_name},{" "}
                                          {logs.branch_address}, but the cleaner
                                          is out of the branch now without
                                          logout his work time. His current
                                          location is {logs.distance} km far
                                          away from the {logs.branch_name}.
                                        </p>
                                        <p className="mb-0 fs-12">
                                          {moment(logs.created_at).format(
                                            "MMM DD, hh:mm A"
                                          )}
                                        </p>
                                      </div>
                                      {/* <div className="col-md-2 text-end">
                                <p className="m-0 approve-badge">Approve</p>
                              </div> */}
                                    </div>
                                  </div>
                                );
                              })}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      );
                    }
                  )
                ) : (
                  <p className="no-notificate">There is no Notification found !</p>
                )}
                <Modal
                  className="delete-modal"
                  show={this.state.approve_login_modal}
                  onHide={() => this.setState({ approve_login_modal: false })}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Approve Cleaner Branch Login</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="delete-modal-body">
                      <p>
                        Do you want to approve to branch login for this cleaner
                      </p>
                    </div>

                    <div className="delete-footer qr-modal-footer d-flex justify-content-end pt-2">
                      <Button
                        className="btn btn-outline-success delete-cancel"
                        variant="outline"
                        onClick={() =>
                          this.setState({ approve_login_modal: false })
                        }
                      >
                        No
                      </Button>
                      <Button
                        className="delete-cancel delete-button"
                        variant="primary"
                        onClick={() => {
                          this.props.cleanerApproveForLogin(
                            this.state.approve_login_id
                          );
                          this.showToastMessage();
                        }}
                      >
                        Yes
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </Tab>
          )}

          {/* Cleaner invoice form*/}
          {this.props.userLoginDetails?.permission
            ?.notification_cleaner_invoice_form === 1 && (
            <Tab eventKey="cleaner_invoice_form" title="CLEANER INVOICE FORM">
              {this.props.cleanerInvoiceDatas &&
              this.props.cleanerInvoiceDatas.length > 0 ? (
                this.props.cleanerInvoiceDatas.map(
                  (cleaners: any, index: any) => (
                    <div className="card mb-3">
                      <div className="card-body pl-pr-25px">
                        <div className="row">
                          <div className="col-md-10">
                            <p className="mb-1">
                              {cleaners.first_name} {cleaners.last_name}
                            </p>
                          </div>
                          <div className="col-md-2 align-self-center">
                            <p
                              onClick={() => {
                                this.setState({
                                  invoiceViewId: cleaners.id,
                                  cleaner_invoice_modal: true,
                                  invoiceCleanerName:
                                    cleaners.first_name +
                                    " " +
                                    cleaners.last_name,
                                });
                                this.props.cleanerInvoiceDetails(cleaners.id);
                              }}
                              className="mb-0 signin-badge float-end"
                              style={{ cursor: "pointer" }}
                            >
                              View
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )
              ) : (
                <p className="no-notificate">There is no Notification found !</p>
              )}
              <Modal
                size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                show={this.state.cleaner_invoice_modal}
                onHide={() => this.setState({ cleaner_invoice_modal: false })}
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    <h3 className="m-0">
                      {this.state.invoiceCleanerName} Invoice List
                    </h3>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                  <div className="card mb-3">
                    <div className="card-body pl-pr-25px">
                      <table className="dashboard-table table">
                        <thead>
                          <tr>
                            <th>Invoice Number</th>
                            <th>Company</th>
                            <th>Due Date</th>
                            <th>GST Number</th>
                            <th>Working Date</th>
                            <th>Download</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.cleanerInvoiceLists.map(
                            (invoice: any, index: any) => (
                              <tr>
                                <td>{invoice.invoice_number}</td>
                                <td>
                                  {invoice.company_name === "1"
                                    ? "SM & MN Property Group Pty Ltd"
                                    : invoice.company_name === "2"
                                    ? "Sujee Business Group Pty Ltd"
                                    : "Muhaini Cleaning Services Pty Ltd"}
                                </td>
                                <td>
                                  {moment(invoice.due_date).format(
                                    "MMM DD, YYYY"
                                  )}
                                </td>
                                <td>{invoice.gst_number}</td>
                                <td>{invoice.working_date}</td>
                                <td>
                                  {invoice.contractor_details === "yes" && (
                                    <a
                                      download
                                      href={
                                        Constants.baseUrl +
                                        "invoice/download-invoice-form-pdf/" +
                                        invoice.id
                                      }
                                      className="mb-0 signin-badge float-end"
                                    >
                                      Download
                                    </a>
                                  )}
                                  {invoice.contractor_details === "no" && (
                                    <p className="mb-0 signout-badge float-end">
                                      Please add Contract Details
                                    </p>
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <div>
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={
                    this.props.cleaner_invoice_total_count / Constants.PER_PAGE
                  }
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={this.handleCleanerInvoicePageClick}
                  containerClassName={"pagination"}
                  nextClassName={"next-page"}
                  activeClassName={"active"}
                />
              </div>
            </Tab>
          )}

                {/* Cleaner Contract form*/}
                {this.props.userLoginDetails?.permission
            ?.notification_cleaner_invoice_form === 1 && (
            <Tab eventKey="contract_expiry_notifi" title="EXPIRY NOTIFY">
              {this.props.contractDocumentExpiryData &&
              this.props.contractDocumentExpiryData.length > 0 ? (
                this.props.contractDocumentExpiryData.map(
                  (cleaners: any, index: any) => (
                    <div className="card mb-3">
                      <div className="card-body pl-pr-25px">
                        <div className="row">
                        <div className="col-md-6">
                            <p className="mb-1">
                            <span className="fw-600">Name:</span> {JSON.parse(cleaners.to).name}
                            </p>
                          </div>
                          <div className="col-md-6">
                            <p className="mb-1">
                            <span className="fw-600">Email:</span> {JSON.parse(cleaners.to).email}
                            </p>
                          </div>
                          <div className="col-md-10">
                            <p className="mb-1">
                            <span className="fw-600"> Message:</span>{cleaners.message}
                            </p>
                          </div>

                          <div className="col-md-1 align-self-center text-end">
                  <Button
                    className="action-btn"
                    onClick={() => this.handleDelete(cleaners.id)}
                  >
                    <TableDeleteSVG />
                  </Button>
                </div>
                <DeleteNotification
                  displayDelete={this.state.displayDelete}
                  handleDeleteClose={this.handleDeleteClose}
                />
                          {/* <div className="col-md-2 align-self-center"> 
                            <p
                              onClick={() => {
                                this.setState({
                                  invoiceViewId: cleaners.id,
                                  cleaner_invoice_modal: true,
                                  invoiceCleanerName:
                                    cleaners.first_name +
                                    " " +
                                    cleaners.last_name,
                                });
                                this.props.cleanerInvoiceDetails(cleaners.id);
                              }}
                              className="mb-0 signin-badge float-end"
                              style={{ cursor: "pointer" }}
                            >
                              View
                            </p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  )
                )
              ) : (
                <p className="no-notificate">There is no Notification found !</p>
              )}
              <Modal
                size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                show={this.state.cleaner_invoice_modal}
                onHide={() => this.setState({ cleaner_invoice_modal: false })}
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    <h3 className="m-0">
                      {this.state.invoiceCleanerName} Invoice List
                    </h3>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                  <div className="card mb-3">
                    <div className="card-body pl-pr-25px">
                      <table className="dashboard-table table">
                        <thead>
                          <tr>
                            <th>Invoice Number</th>
                            <th>Company</th>
                            <th>Due Date</th>
                            <th>GST Number</th>
                            <th>Working Date</th>
                            <th>Download</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.cleanerInvoiceLists.map(
                            (invoice: any, index: any) => (
                              <tr>
                                <td>{invoice.invoice_number}</td>
                                <td>
                                  {invoice.company_name === "1"
                                    ? "SM & MN Property Group Pty Ltd"
                                    : invoice.company_name === "2"
                                    ? "Sujee Business Group Pty Ltd"
                                    : "Muhaini Cleaning Services Pty Ltd"}
                                </td>
                                <td>
                                  {moment(invoice.due_date).format(
                                    "MMM DD, YYYY"
                                  )}
                                </td>
                                <td>{invoice.gst_number}</td>
                                <td>{invoice.working_date}</td>
                                <td>
                                  {invoice.contractor_details === "yes" && (
                                    <a
                                      download
                                      href={
                                        Constants.baseUrl +
                                        "invoice/download-invoice-form-pdf/" +
                                        invoice.id
                                      }
                                      className="mb-0 signin-badge float-end"
                                    >
                                      Download
                                    </a>
                                  )}
                                  {invoice.contractor_details === "no" && (
                                    <p className="mb-0 signout-badge float-end">
                                      Please add Contract Details
                                    </p>
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* <div>
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={
                    this.props.contractDocumentExpiryData.length / Constants.PER_PAGE
                  }
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={this.handleContractExpiryPageClick}
                  containerClassName={"pagination"}
                  nextClassName={"next-page"}
                  activeClassName={"active"}
                />
              </div> */}
            </Tab>
          )}
        </Tabs>
        {this.state.showToast ? (
          <Toast className="success-alerts">
            <Toast.Body>
              <div className="row align-items-center">
                <div className="col-md-2">
                  <SuccessToastSVG />
                </div>
                <div className="col-md-8"><p>Approve Successfully</p></div>
                <div className="col-md-2">
                  <button
                    type="button"
                    className="btn"
                    onClick={this.deleteToastClose}
                  >
                    <CloseBtnSVG />
                  </button>
                </div>
              </div>
            </Toast.Body>
          </Toast>
        ) : (
          ""
        )}
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  task_total_count: state.activity.task_total_count,
  cleaner_total_count: state.activity.cleaner_total_count,
  taskData: state.activity.taskData,
  filteredNotification: state.activity.filteredNotification,
  cleanerData: state.activity.cleanerData,
  userLoginDetails: state.sidebar.userLoginDetails,
  cleanerLogoutActivityData: state.activity.cleanerLogoutActivityData,
  approveCleanerLoginStatus: state.activity.approveCleanerLoginStatus,
  cleanerLogoutActivityTotalCount:
    state.activity.cleanerLogoutActivityTotalCount,
  cleanerInvoiceDatas: state.activity.cleanerInvoiceDatas,
  cleaner_invoice_total_count: state.activity.cleaner_invoice_total_count,
  cleanerInvoiceLists: state.activity.cleanerInvoiceLists,
  contractDocumentExpiryData: state.activity.contractExpiryNotifyList,

});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    allCleanerNotification: bindActionCreators(
      allCleanerNotification,
      dispatch
    ),
    allTaskNotification: bindActionCreators(allTaskNotification, dispatch),
    allCleanerInvoiceNotification: bindActionCreators(
      allCleanerInvoiceNotification,
      dispatch
    ),
    getCleanerBranchLogoutActivity: bindActionCreators(
      getCleanerBranchLogoutActivity,
      dispatch
    ),
    cleanerApproveForLogin: bindActionCreators(
      cleanerApproveForLogin,
      dispatch
    ),
    cleanerInvoiceDetails: bindActionCreators(cleanerInvoiceDetails, dispatch),
    allContractExpiry: bindActionCreators(allContractDocumentExpiry, dispatch),
    deleteExpiry: bindActionCreators(deleteContractExpiry, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeActivity);
