import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { bindActionCreators, Dispatch } from "redux";
import { ICleaner, IBranchSelect } from "../../shared/ActionTypes";
import {
  editQuotedTask,
  listAllClient,
  updateEditQuotedTaskDlgStatus,
  updateQuotedTaskFetchStatus,
  listAllBranch,
} from "../../shared/Reducers";
import { CustomSelect } from "../../shared/Components/CustomSelect";
import { Modal } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import Constants, { COMPLAINT_STATUS } from "../../shared/Constants";

export interface IEditQuotedTaskProps {
  id: string;
  data: any | null;
  updateEditQuotedTaskDlgStatus?: any;
  editQuotedTask?: any;
  updateQuotedTaskFetchStatus?: any;
  clientList?: any;
  listAllClient?: any;
  branchList?: any;
  listAllBranch?: any;
  processTrigger: any;
  editQuotedTaskSuccess: any;
  progressPercentage: number;
}

export interface IEditQuotedTaskState {
  taskType: any;
}

class EditQuotedTask extends React.Component<
  IEditQuotedTaskProps,
  IEditQuotedTaskState
> {
  constructor(props: IEditQuotedTaskProps) {
    super(props);
    this.state = {
      taskType: 1,
    };
  }

  componentDidMount(): void {
    this.props.listAllClient();
    console.log(this.props.data);

    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.add("d-none");
      button.removeAttribute("disabled");
    }
    if (this.props.data) {
      this.props.listAllBranch(this.props.data.client_id);
      if (this.props.data.task_type === 0) {
        this.setState({ taskType: 0 });
      }
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IEditQuotedTaskProps>,
    prevState: Readonly<IEditQuotedTaskState>,
    snapshot?: any
  ): void {
    if (prevProps.processTrigger !== this.props.processTrigger) {
    }
    if (prevProps.editQuotedTaskSuccess !== this.props.editQuotedTaskSuccess) {
    }
  }

  handleEditQuotedTask = async (formValues: any) => {
    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.remove("d-none");
      button.setAttribute("disabled", "");
    }
    let data: any = [];
    data = {
      client_id: formValues.client,
      branch_id: formValues.branch,
      task: formValues.task,
      task_type: formValues.task_type,
      sunday: formValues.sunday,
      monday: formValues.monday,
      wednesday: formValues.wednesday,
      thursday: formValues.thursday,
      tuesday: formValues.tuesday,
      friday: formValues.friday,
      saturday: formValues.saturday,
      total_time: formValues.total_time,
    };
    if (this.props.id) {
      this.props.editQuotedTask(data, this.props.id);
    }
  };

  validationSchema = () => {
    const clientId = this.props.clientList.map((client: ICleaner) => client.id);
    const branchId = this.props.branchList.map(
      (branch: IBranchSelect) => branch.value
    );
    return Yup.object().shape({
      task: Yup.string().required("Enter Task Name"),
      client: Yup.string()
        .oneOf([...clientId], "Invalid Client")
        .required()
        .nullable(),
      branch: Yup.string().required("Select a branch"),
      task_type: Yup.string().required("Select a Task type"),
      is_active: Yup.string(),
    });
  };

  handleOnChangeClient = (id: any) => {
    this.props.listAllBranch(id);
  };
  render(): React.ReactNode {
    return (
      <div>
        <Formik
          initialValues={{
            client: this.props.data ? this.props.data.client_id : "",
            is_active: this.props.data ? this.props.data.is_active : "",
            branch: this.props.data ? this.props.data.branch_id : "",
            friday: this.props.data ? this.props.data.friday : "",
            monday: this.props.data ? this.props.data.monday : "",
            saturday: this.props.data ? this.props.data.saturday : "",
            sunday: this.props.data ? this.props.data.sunday : "",
            task: this.props.data ? this.props.data.task : "",
            task_type: this.props.data ? this.props.data.task_type : "",
            thursday: this.props.data ? this.props.data.thursday : "",
            tuesday: this.props.data ? this.props.data.tuesday : "",
            wednesday: this.props.data ? this.props.data.wednesday : "",
            total_time: this.props.data ? this.props.data.total_time : "",
          }}
          enableReinitialize={true}
          onSubmit={this.handleEditQuotedTask}
          validationSchema={this.validationSchema}
        >
          <Form>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-2">
                      <label className="form-label">
                        Task Name<span className="text-project">*</span>
                      </label>
                      <Field
                        name="task"
                        type={"text"}
                        className="form-control text-field"
                      />
                      <ErrorMessage
                        name="task"
                        component={"div"}
                        className="cus-alert"
                      />
                    </div>
                  </div>
                  <div className="mb-2 col-md-6">
                    <label className="form-label">
                      Client<span className="text-project">*</span>
                    </label>
                    <Field
                      className="custom-select dropdown-field"
                      name="client"
                      options={this.props.clientList.map((client: any) => ({
                        value: client.id,
                        label: client.first_name + " " + client.last_name,
                      }))}
                      onChange={(e: any) => this.handleOnChangeClient(e.value)}
                      component={CustomSelect}
                      placeholder="Select Client"
                      isMulti={false}
                    />
                    <ErrorMessage
                      name="client"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                  <div className="mb-2 col-md-6">
                    <label className="form-label">
                      Branch<span className="text-project">*</span>
                    </label>
                    <Field
                      className="custom-select dropdown-field"
                      name="branch"
                      options={this.props.branchList}
                      component={CustomSelect}
                      placeholder="Select Branch"
                      isMulti={false}
                    />
                    <ErrorMessage
                      name="branch"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                  <div className="mb-2 col-md-6">
                    <label className="form-label">
                      Task type<span className="text-project">*</span>
                    </label>
                    <Field
                      className="custom-select dropdown-field"
                      name="task_type"
                      options={[
                        { value: 0, label: "Additional Task" },
                        { value: 1, label: "Fixed Task" },
                      ]}
                      onChange={(e: any) =>
                        this.setState({ taskType: e.value })
                      }
                      component={CustomSelect}
                      placeholder="Select Task Type"
                      isMulti={false}
                    />
                    <ErrorMessage
                      name="task_type"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                  {this.state.taskType === 1 ? (
                    <>
                      <div className="col-md-6">
                        <div className="mb-2">
                          <label className="form-label">Total time (Hrs)</label>
                          <Field
                            name="total_time"
                            type={"number"}
                            className="form-control text-field"
                          />
                          <ErrorMessage
                            name="total_time"
                            component={"div"}
                            className="cus-alert"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-md-6">
                        <div className="mb-2">
                          <label className="form-label">Sunday</label>
                          <Field
                            name="sunday"
                            type={"number"}
                            className="form-control text-field"
                          />
                          <ErrorMessage
                            name="sunday"
                            component={"div"}
                            className="cus-alert"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-2">
                          <label className="form-label">Monday</label>
                          <Field
                            name="monday"
                            type={"number"}
                            className="form-control text-field"
                          />
                          <ErrorMessage
                            name="monday"
                            component={"div"}
                            className="cus-alert"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-2">
                          <label className="form-label">Tuesday</label>
                          <Field
                            name="tuesday"
                            type={"number"}
                            className="form-control text-field"
                          />
                          <ErrorMessage
                            name="tuesday"
                            component={"div"}
                            className="cus-alert"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-2">
                          <label className="form-label">Wednesday</label>
                          <Field
                            name="wednesday"
                            type={"number"}
                            className="form-control text-field"
                          />
                          <ErrorMessage
                            name="wednesday"
                            component={"div"}
                            className="cus-alert"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-2">
                          <label className="form-label">Thursday</label>
                          <Field
                            name="thursday"
                            type={"number"}
                            className="form-control text-field"
                          />
                          <ErrorMessage
                            name="thursday"
                            component={"div"}
                            className="cus-alert"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-2">
                          <label className="form-label">Friday</label>
                          <Field
                            name="friday"
                            type={"number"}
                            className="form-control text-field"
                          />
                          <ErrorMessage
                            name="friday"
                            component={"div"}
                            className="cus-alert"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-2">
                          <label className="form-label">Saturday</label>
                          <Field
                            name="saturday"
                            type={"number"}
                            className="form-control text-field"
                          />
                          <ErrorMessage
                            name="saturday"
                            component={"div"}
                            className="cus-alert"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="row mt-3 mb-3">
                <div className="col-md-12 cus-progress">
                  <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.props.processTrigger}
                  >
                    <Modal.Body className="p-0">
                      <ProgressBar
                        now={this.props.progressPercentage}
                        label={
                          this.props.progressPercentage === 100
                            ? `Processing your request...`
                            : `QuotedTask Updating....${this.props.progressPercentage}%`
                        }
                      />
                    </Modal.Body>
                  </Modal>
                  <div className="d-flex">
                    <div className="mr-16px">
                      <button
                        type="submit"
                        id="create-button"
                        className="btn btn-primary create-button"
                      >
                        Save{" "}
                        <span
                          id="spinner-border"
                          className="spinner-border spinner-border-sm d-none"
                        ></span>
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn cancel-button"
                        onClick={() =>
                          this.props.updateEditQuotedTaskDlgStatus(false)
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>

        <iframe
          style={{ display: "none" }}
          name="hiddenIframe"
          id="hiddenIframe"
        />
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  clientList: state.quotedTask.clientList,
  branchList: state.tasktemplate.branchList,
  processTrigger: state.quotedTask.processTrigger,
  editQuotedTaskSuccess: state.quotedTask.editQuotedTaskSuccess,
  progressPercentage: state.quotedTask.progressPercentage,
  loginUserDetail: state.sidebar.userLoginDetails,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    listAllClient: bindActionCreators(listAllClient, dispatch),
    editQuotedTask: bindActionCreators(editQuotedTask, dispatch),
    updateEditQuotedTaskDlgStatus: bindActionCreators(
      updateEditQuotedTaskDlgStatus,
      dispatch
    ),
    updateQuotedTaskFetchStatus: bindActionCreators(
      updateQuotedTaskFetchStatus,
      dispatch
    ),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditQuotedTask);
