import Constants, { TASK_PRIORITY } from "../../shared/Constants";
import { DisableSVG, EnableSVG } from "../../assets/images/UsermanagementsSVG";
import { Dispatch, bindActionCreators } from "redux";
import {
  IDailyTaskCardProps,
  IDailyTaskCardState,
} from "../../types/dailyTask.type";
import {
  TableDeleteSVG,
  TableEditSVG,
  TableReassignSVG,
  TableViewSVG,
} from "../../assets/images/ImagesV2";
import {
  updateReassignDailyTaskDlgStatus,
  updateViewDailyTaskDlgStatus,
  getLoginUserDetails,
} from "../../shared/Reducers";

import Button from "react-bootstrap/Button";
import DeleteDailyTask from "./DeleteDailyTask";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import React from "react";
import { RootState } from "../../index";
import { TableMailSVG } from "../../assets/images/ImagesSVG";
import TaskView from "./TaskView";
import Tooltip from "react-bootstrap/Tooltip";
import { connect } from "react-redux";
import moment from "moment";

class DailyTaskCard extends React.Component<
  IDailyTaskCardProps,
  IDailyTaskCardState
> {
  constructor(props: IDailyTaskCardProps) {
    super(props);
    this.state = {
      show: false,
      displayDelete: false,
      currentTaskId: "",
      editShow: false,
      displayView: false,
    };
  }

  componentDidMount(): void {
    
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  // Delete task based on ID
  handleDelete = (id: string) => {
    this.setState({ displayDelete: true, currentTaskId: id });
  };

  // delete popup close
  handleDeleteClose = (status: boolean) => {
    console.log(this.state.currentTaskId);

    this.setState({ displayDelete: false, currentTaskId: "" });
    if (status) {
      this.props.handleDeleteDailyTask(this.state.currentTaskId);
    }
  };

  handleView = (id: string) => {
    this.setState({ displayView: true, });
    this.props.handleViewDailyTaskModel(id);
  };

  handleViewClose = () => {
    this.setState({ displayView: false });
  };

  render(): React.ReactNode {
    const {
      title,
      first_name,
      special_request_task_status,
      task_status,
      cleaners,
      task_type,
      last_name,
      branch_name,
      start_time,
      end_time,
      any_time,
      id,
      task_date,
      is_active,
      task_status_name,
      task_feedback_name,
      priority,
      status,
    } = this.props.task;

    return (
      <tr>
        <td>{title}</td>
        <td>
          <span className="mr-20px">
            <a
              href="javascript:void(0)"
              onClick={() => this.props.handleTaskHistory(id)}
            >
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{"History"}</Tooltip>}
              >
                {/* <TableViewSVG /> */}
                <Button className="btn btn-secondary btn-cleaner-count fs-12px">
                  View
                </Button>
              </OverlayTrigger>
            </a>
          </span>
        </td>
        <td
          className={
            this.props.userLoginDetails?.permission?.task_view_cleaner !== 1
              ? "d-none"
              : ""
          }
        >
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip>
                {cleaners.map((element: any) => (
                  <p className="text-white mb-0 fs-12px">
                    {element.first_name + " " + element.last_name}
                  </p>
                ))}
              </Tooltip>
            }
          >
            {cleaners.length > 0 ? (
              <Button
                variant="light"
                className="btn btn-secondary btn-cleaner-count fs-12px"
              >
                {cleaners.length}
              </Button>
            ) : (
              <></>
            )}
          </OverlayTrigger>
        </td>
        <td>
          {/* Task types */}
          {task_type === Constants.REGULAR_TASK
            ? "Regular"
            : task_type === Constants.SPECIAL_REQUEST
            ? "Special Request"
            : task_type === Constants.RECOMMENDED_TASK
            ? "Recommended Task"
            : task_type === Constants.ADDITIONAL_TASK 
            ? "Approved Task"
            : ""
          }
        </td>
        {/* task status */}
        <td>
          <p className={task_status_name+"-badge view-task-comment-badge"}>
            {task_status_name}
          </p>
        </td>
        {/* Special request task feedback */}
        {
          this.props.userLoginDetails?.permission?.task_add_special_task === 1 && (
            <td>
              <p className={task_feedback_name+"-badge view-task-comment-badge"}>
                {task_feedback_name}
              </p>
            </td>
          )
        }
        
        <td>{`${first_name} ${last_name}`}</td>
        <td>
          {branch_name}
        </td>

        <td>{moment(task_date).format("DD/MM/YYYY")}</td>
        <td>
          {any_time === 1
            ? `Any Time`
            : moment(start_time, "HH:mm:ss").format("hh:mm A") +
              "-" +
              moment(end_time, "HH:mm:ss").format("hh:mm A")}
        </td>

        {/* task priority */}
        <td>
          <p
            className={
              priority === TASK_PRIORITY.LOW
                ? "commented-option view-task-comment-badge"
                : priority === TASK_PRIORITY.MEDIUM
                ? "inprogress-badge view-task-comment-badge"
                : priority === TASK_PRIORITY.HIGH
                ? "created-badge view-task-comment-badge"
                : ""
            }
          >
            {priority === TASK_PRIORITY.LOW
              ? "Low"
              : priority === TASK_PRIORITY.MEDIUM
              ? "Medium"
              : priority === TASK_PRIORITY.HIGH
              ? "High"
              : ""}
          </p>
        </td>
        <td
            className={
              this.props.userLoginDetails?.permission?.task_send_email === 1
                ? ""
                : "d-none"
            }
          >
          <span>
            <a
              href="javascript:void(0)"
              onClick={() => this.props.handleSendQAEmailDailyTask(id)}
            >
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{"Send mail"}</Tooltip>}
              >
                {/* <TableMailSVG /> */}
                <Button className="btn btn-secondary btn-cleaner-count fs-12px">
                  Send Email
                </Button>
              </OverlayTrigger>
            </a>
          </span>
        </td>
        <td
          className={
           this.props.userLoginDetails?.permission?.task_make_schedule === 1
              ? ""
              : "d-none"
          }
        >
          <span>
            <a
              href="javascript:void(0)"
              onClick={() => this.props.handleMakeScheduleDailyTask(id)}
            >
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{"Make schedule"}</Tooltip>}
              >
                <Button className="btn btn-secondary btn-cleaner-count fs-12px">
                  Make schedule
                </Button>
              </OverlayTrigger>
            </a>
          </span>
        </td>
        <td
          className={
           this.props.userLoginDetails?.permission?.task_copy === 1 
              ? ""
              : "d-none"
          }
        >
          <span>
            <a
              href="javascript:void(0)"
              onClick={() => this.props.handleCopyDailyTask(id)}
            >
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{"Task duplicate"}</Tooltip>}
              >
                <Button className="btn btn-secondary btn-cleaner-count fs-12px">
                  Copy
                </Button>
              </OverlayTrigger>
            </a>
          </span>
        </td>
        <td
            className={
              this.props.userLoginDetails?.permission?.task_assign_reassign_cleaner !== 1
                ? "d-none"
                : ""
            }
          >
          <span>
            <a
              href="javascript:void(0)"
              onClick={() => this.props.handleReassignDailyTask(id)}
            >
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{"Assign & Reassign"}</Tooltip>}
              >
                {/* <TableReassignSVG /> */}
                <Button className="btn btn-secondary btn-cleaner-count fs-12px">
                  Assign & Reassign
                </Button>
              </OverlayTrigger>
            </a>
          </span>
        </td>
        <td>
          <span>
            {status !== Constants.IS_ACTIVE && <DisableSVG />}
            {status === Constants.IS_ACTIVE && <EnableSVG />}
          </span>
        </td>
        <td>
          <div className="btn-group">
            <span className="mr-20px">
              <a
                className="pointer"
                href="javascript:void(0)"
                onClick={() => this.handleView(id)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"View"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableViewSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
            <span className="mr-20px">
              <a
                href="javascript:void(0)"
                onClick={() => this.props.handleEditDailyTask(id)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"Edit"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableEditSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
            <span className="mr-20px" title="Delete">
              <a
                href="javascript:void(0)"
                onClick={() => this.handleDelete(id)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"Delete"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableDeleteSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
            {this.state.displayView && this.props.selectedDailyTaskData && (
              <TaskView
                displayView={this.state.displayView}
                handleViewClose={this.handleViewClose}
              />
            )}
            <DeleteDailyTask
              deletedName={title}
              displayDelete={this.state.displayDelete}
              handleDeleteClose={this.handleDeleteClose}
            />
          </div>
        </td>
      </tr>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  editDailyTaskStatus: state.dailytask.editDailyTaskStatus,
  copyDailyTaskStatus: state.dailytask.copyDailyTaskStatus,
  makeScheduleDailyTaskStatus: state.dailytask.makeScheduleDailyTaskStatus,
  viewDailyTaskStatus: state.dailytask.viewDailyTaskStatus,
  selectedDailyTaskData: state.dailytask.selectedDailyTaskData,
  userLoginDetails: state.sidebar.userLoginDetails,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateReassignDailyTaskDlgStatus: bindActionCreators(
      updateReassignDailyTaskDlgStatus,
      dispatch
    ),
    updateViewDailyTaskDlgStatus: bindActionCreators(
      updateViewDailyTaskDlgStatus,
      dispatch
    ),
    getLoginUserDetails: bindActionCreators(getLoginUserDetails, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DailyTaskCard);
