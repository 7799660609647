import {
  DisableSVG,
  EnableSVG,
  NotVerifiedSVG,
  VerifiedSVG,
} from "../../assets/images/UsermanagementsSVG";
import { Dispatch, bindActionCreators } from "redux";

import { AttachmentDownloadSVG } from "../../assets/images/ImagesSVG";
import AttachmentPreview from "../../shared/Components/AttachmentPreview";
import Constants from "../../shared/Constants";
import { Modal } from "react-bootstrap";
import React from "react";
import { RootState } from "../../index";
import Select from "react-select";
import { Switch } from "@material-ui/core";
import { connect } from "react-redux";
import docImage from "../../assets/images/google-docs.png";
import moment from "moment";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";

class ViewUsermanagement extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      attachments: "",
      attachmentUrls: [],
      AttachmentPreviewStatus: false,
      previewUrl: "",
      previewType: "",
    };
  }
  renderClientName() {
    if (this.props.data.assigned_branches) {
      console.log(this.props.data.assigned_branches);
      let data = this.props.data.assigned_branches.map(
        (item: any, index: any) => {
        
          return (
            <div className="col-md-12 row p-0 m-0">
              <div className="col-md-6">
                <p className="fw-600">
                  {index +
                    1 +
                    ". " +
                    item.client.first_name +
                    " " +
                    item.client.last_name}
                </p>
              </div>
              <div className="col-md-6">
                {item.branches.map((value: any, inx: number) => {
                  return (
                    <p className="fw-600">{inx + 1 + ". " + value.name}</p>
                  );
                })}
              </div>
            </div>
          );
        }
      );
      return data;
          
    }
  }
  renderQAView() {
    console.log(this.props.data, "renderQAView");
    if (this.props.data.assigned_branches) {
      let data = this.props.data.assigned_branches.map(
        (item: any, index: any) => {
          return (
            <div className="col-md-12 row p-0 m-0">
              <div className="col-md-6">
                <p className="fw-600">
                  {index +
                    1 +
                    ". " +
                    item.client.first_name +
                    " " +
                    item.client.last_name}
                </p>
              </div>
              <div className="col-md-6">
                {item.branches.map((value: any, inx: number) => {
                  return (
                    <p className="fw-600">{inx + 1 + ". " + value.name}</p>
                  );
                })}
              </div>
            </div>
          );
        }
      );
      return data;
    }
  }

  renderManagerView() {
    if (this.props.data.assigned_branches) {
      let data = this.props.data.assigned_branches.map(
        (item: any, index: any) => {
          return (
            <div className="col-md-12 row p-0 m-0">
              <div className="col-md-6">
                <p className="fw-600">
                  {index +
                    1 +
                    ". " +
                    item.client.first_name +
                    " " +
                    item.client.last_name}
                </p>
              </div>
              <div className="col-md-6">
                {item.branches.map((value: any, inx: number) => {
                  return (
                    <p className="fw-600">{inx + 1 + ". " + value.name}</p>
                  );
                })}
              </div>
            </div>
          );
        }
      );
      return data;
    }
  }

  componentDidMount(): void {
    console.log(this.props.data.contractor_details);

    if (this.props.data.contractor_details) {
      this.setState({
        attachmentUrls: this.props.data.contractor_details.attachments.map(
          (obj: any) => ({
            url: `${Constants.imgThumbUrl}${obj.file_name}`,
            name: obj.file_name,
            id: obj.id,
            type: "download",
            download_url: `${Constants.imgUrl}${obj.file_name}`,
            file_type: obj.type,
            attachment_type: obj.attachment_type,
          })
        ),
      });
    }
  }

  downloadFile = (url: string, name = "image.png") => {
    this.downloadResource(url, name);
  };

  downloadResource = (url: any, filename: any) => {
    if (url) {
      if (!filename) filename = url.split("\\").pop().split("/").pop();
      fetch(url, {
        headers: new Headers({
          Origin: window.location.origin,
        }),
        mode: "cors",
      })
        .then((response) => response.blob())
        .then((blob) => {
          let blobUrl = window.URL.createObjectURL(blob);
          this.forceDownload(blobUrl, filename);
        })
        .catch((e) => console.error(e));
    }
  };

  forceDownload = (blob: any, filename: String) => {
    const a: any = document.createElement("a");
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  handlePreviewClose = () => {
    this.setState({ AttachmentPreviewStatus: false });
  };
  handlePreviewOpen = (file_type: any, url: any) => {
    if (
      file_type === "image/png" ||
      file_type === "image/jpeg" ||
      file_type === "image/jpg" ||
      file_type === "video/mp4" ||
      file_type === "video/webm" ||
      file_type === "application/pdf"
    ) {
      this.setState({
        AttachmentPreviewStatus: true,
        previewUrl: url,
        previewType: file_type,
      });
    }
  };

  render(): React.ReactNode {
    const {
      first_name,
      last_name,
      is_email_verified,
      email,
      phone,
      user_type_id,
      status,
      certificate_count,
      certificate_list,
      assigned_branches,
      branch,
      client,
      contractor_details,
      user_type_name,
    } = this.props.data;
    console.log(this.props.data, "this.props.data");

    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <p className="mb-1">Role</p>
            <p className="fw-600 text-uppercase">               
            {user_type_name}
            </p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">Email</p>
            <p className="fw-600">
              {email}{" "}
              {is_email_verified !== Constants.IS_ACTIVE &&
                user_type_id !== Constants.CLIENTUTINT && <NotVerifiedSVG />}
              {is_email_verified === Constants.IS_ACTIVE &&
                user_type_id !== Constants.CLIENTUTINT && <VerifiedSVG />}
            </p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">First name</p>
            <p className="fw-600">{first_name}</p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">Last name</p>
            <p className="fw-600">{last_name}</p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">Phone</p>
            <p className="fw-600">{phone}</p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">Is Active</p>
            {status !== Constants.IS_ACTIVE && <DisableSVG />}
            {status === Constants.IS_ACTIVE && <EnableSVG />}
          </div>
          {user_type_id === 3 && (
            <div className="col-md-6">
              <p className="mb-1">Completed certificate Count</p>
              <p className="fw-600">{certificate_count}</p>
            </div>
          )}
          {user_type_id === 3 && certificate_count > 0 && (
            <div className="col-md-12">
              <div className="row">
                <div className="mb-2 col-md-6">
                  <p className="fw-600">Completed certificate List</p>
                </div>
              </div>
            </div>
          )}
          {user_type_id === 3 &&
            certificate_list &&
            certificate_list.length>0 &&
            certificate_list.map((val: any, index: number) => (
              <div className="col-md-12">
                <div className="row">
                  <div className="mb-2 col-md-6">
                    <p>
                      {++index}. {val.name}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          {user_type_id !== 3 ? (
            <div className="col-md-6">
              <p className="mb-1">Client</p>
            </div>
          ) : null}
          {user_type_id!== 3 ?  (
            <div className="col-md-6">
              <p className="mb-1">Branch</p>
            </div>
          ) : null}
          {user_type_id !== 3 ?  this.renderClientName() : null}

          {/* {user_type_id === 5 ? (
            <div className="col-md-6">
              <p className="mb-1">Client</p>
            </div>
          ) : null}
          {user_type_id === 5 ? (
            <div className="col-md-6">
              <p className="mb-1">Branch</p>
            </div>
          ) : null}
          {user_type_id === 5 ? this.renderQAView() : null}

          {user_type_id === 6 ? (
            <div className="col-md-6">
              <p className="mb-1">Client</p>
            </div>
          ) : null}
          {user_type_id === 6 ? (
            <div className="col-md-6">
              <p className="mb-1">Branch</p>
            </div>
          ) : null}
          {user_type_id === 6 ? this.renderManagerView() : null} */}
          {contractor_details && (
            <div>
              <h5>Contact Details</h5>
              <div className="row">
                <div className="col-md-12 row">
                  <div className="col-md-6 row">
                    <div className="col-md-12">
                      <p className="dashboard-title">Name :</p>
                    </div>
                    <div className="col-md-12">
                      <p className="mb-1">Contractor</p>
                      <p className="fw-600">{`${first_name} ${last_name}`}</p>
                    </div>
                    <div className="col-md-12">
                      <p className="mb-1">Business name</p>
                      <p className="fw-600">{`${contractor_details.business_name}`}</p>
                    </div>
                  </div>
                  <div className="col-md-6 row">
                    <div className="col-md-12">
                      <p className="dashboard-title">Profile Image :</p>
                    </div>
                    <div className="col-md-12 text-center">
                      {this.state.attachmentUrls &&
                        this.state.attachmentUrls.map(
                          (obj: any, index: number) => (
                            <>
                              {obj.attachment_type === "contract_profile" && (
                                <div className="img-card mr-16px mb-3">
                                  <span className="contract-document-download">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() =>
                                        this.downloadFile(
                                          Constants.imgUrl + obj.file_name,
                                          obj.file_name
                                        )
                                      }
                                    >
                                      <AttachmentDownloadSVG />
                                    </a>
                                  </span>
                                  <div
                                    className="img-preview"
                                    onClick={() =>
                                      this.handlePreviewOpen(
                                        obj.file_type,
                                        Constants.imgUrl + obj.file_name
                                      )
                                    }
                                  >
                                    <img
                                      className="img-thumb-perview"
                                      src={
                                        obj.file_type === "application/pdf"
                                          ? pdfImage
                                          : obj.file_type === "video/mp4" ||
                                            obj.file_type === "video/webm"
                                          ? videoImage
                                          : obj.file_type === "image/png" ||
                                            obj.file_type === "image/jpeg" ||
                                            obj.file_type === "image/jpg"
                                          ? obj.url
                                          : docImage
                                      }
                                      alt="attach"
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          )
                        )}
                      <AttachmentPreview
                        displayPreview={this.state.AttachmentPreviewStatus}
                        previewUrl={this.state.previewUrl}
                        previewType={this.state.previewType}
                        handlePreviewClose={this.handlePreviewClose}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p className="mb-1">Business Name</p>
                  <p className="fw-600">{contractor_details.business_name}</p>
                </div>
                <p className="dashboard-title">TAX File Number</p>
                <div className="col-md-6">
                  <p className="mb-1">ABN/ACN Number</p>
                  <p className="fw-600">{contractor_details.abn_no}</p>
                </div>

                <div className="col-md-6">
                  <p className="mb-1">Date of Start</p>
                  <p className="fw-600">
                    {" "}
                    {moment(
                      new Date(contractor_details.date_of_start?.toString())
                    ).format("DD/MM/YYYY")}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="mb-1">BUSINESS CONTACT NUMBER</p>
                  <p className="fw-600">
                    {contractor_details.business_contact_no}
                  </p>
                </div>
                <p className="dashboard-title">Contract Details</p>
                <div className="col-md-6">
                  <p className="mb-1">Job Title</p>
                  <p className="fw-600">{contractor_details.job_title}</p>
                </div>
                <div className="col-md-6">
                  <p className="mb-1">Contract Date</p>
                  <p className="fw-600">{contractor_details.Contract_date}</p>
                </div>
                <p className="dashboard-title">Personal Contact Details</p>
                <div className="col-md-6">
                  <p className="mb-1">Mobile</p>
                  <p className="fw-600">{contractor_details.mobile}</p>
                </div>
                <div className="col-md-6">
                  <p className="mb-1">Gender</p>
                  <p className="fw-600">{contractor_details.gender}</p>
                </div>
                <div className="col-md-6">
                  <p className="mb-1">Contact Telephone No: Business</p>
                  <p className="fw-600">
                    {contractor_details.personal_contact_no}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="mb-1">Date Of Birth</p>
                  <p className="fw-600">
                    {" "}
                    {moment(
                      new Date(contractor_details.date_of_birth?.toString())
                    ).format("DD/MM/YYYY")}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="mb-1">Email Address</p>
                  <p className="fw-600">{contractor_details.email}</p>
                </div>
                <div className="col-md-6">
                  <p className="mb-1">Home Address</p>
                  <p className="fw-600">{contractor_details.home_address}</p>
                </div>
                <div className="col-md-6">
                  <p className="mb-1">Home City / State</p>
                  <p className="fw-600">{contractor_details.home_city_state}</p>
                </div>
                <div className="col-md-6">
                  <p className="mb-1">Business Address</p>
                  <p className="fw-600">
                    {contractor_details.business_address}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="mb-1">Business City / State</p>
                  <p className="fw-600">
                    {contractor_details.business_city_state}
                  </p>
                </div>
                <p className="dashboard-title">Emergency Contact Info</p>
                <div className="col-md-6">
                  <p className="mb-1">Name</p>
                  <p className="fw-600">{contractor_details.emergency_name}</p>
                </div>
                <div className="col-md-6">
                  <p className="mb-1">Address</p>
                  <p className="fw-600">
                    {contractor_details.emergency_address}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="mb-1">Relationship</p>
                  <p className="fw-600">
                    {contractor_details.emergency_relationship}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="mb-1">Contact Number: Home</p>
                  <p className="fw-600">
                    {contractor_details.emergency_home_no}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="mb-1">Mobile</p>
                  <p className="fw-600">
                    {contractor_details.emergency_mobile_no}
                  </p>
                </div>
                <p className="dashboard-title">Bank Details</p>

                <div className="col-md-6">
                  <p className="mb-1">Account Name</p>
                  <p className="fw-600">{contractor_details.acc_name}</p>
                </div>
                <div className="col-md-6">
                  <p className="mb-1">Account Number</p>
                  <p className="fw-600">{contractor_details.acc_no}</p>
                </div>
                <div className="col-md-6">
                  <p className="mb-1">BSB Number</p>
                  <p className="fw-600">{contractor_details.bsb_no}</p>
                </div>
                <p className="dashboard-title">Documents</p>
                <div className="col-md-12">
                  <p className="mb-1">Insurance</p>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    {this.state.attachmentUrls &&
                      this.state.attachmentUrls.map(
                        (obj: any, index: number) => (
                          <>
                            {obj.attachment_type === "insurance" && (
                              <div className="img-card mr-16px mb-3">
                                <span className="contract-document-download">
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() =>
                                      this.downloadFile(
                                        Constants.imgUrl + obj.file_name,
                                        obj.file_name
                                      )
                                    }
                                  >
                                    <AttachmentDownloadSVG />
                                  </a>
                                </span>
                                <div
                                  className="img-preview"
                                  onClick={() =>
                                    this.handlePreviewOpen(
                                      obj.file_type,
                                      Constants.imgUrl + obj.file_name
                                    )
                                  }
                                >
                                  <img
                                    className="img-thumb-perview"
                                    src={
                                      obj.file_type === "application/pdf"
                                        ? pdfImage
                                        : obj.file_type === "video/mp4" ||
                                          obj.file_type === "video/webm"
                                        ? videoImage
                                        : obj.file_type === "image/png" ||
                                          obj.file_type === "image/jpeg" ||
                                          obj.file_type === "image/jpg"
                                        ? obj.url
                                        : docImage
                                    }
                                    alt="attach"
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )
                      )}
                    <AttachmentPreview
                      displayPreview={this.state.AttachmentPreviewStatus}
                      previewUrl={this.state.previewUrl}
                      previewType={this.state.previewType}
                      handlePreviewClose={this.handlePreviewClose}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <p className="mb-1">Visa</p>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    {this.state.attachmentUrls &&
                      this.state.attachmentUrls.map(
                        (obj: any, index: number) => (
                          <>
                            {obj.attachment_type === "visa" && (
                              <div className="img-card mr-16px mb-3">
                                <span className="contract-document-download">
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() =>
                                      this.downloadFile(
                                        Constants.imgUrl + obj.file_name,
                                        obj.file_name
                                      )
                                    }
                                  >
                                    <AttachmentDownloadSVG />
                                  </a>
                                </span>
                                <div
                                  className="img-preview"
                                  onClick={() =>
                                    this.handlePreviewOpen(
                                      obj.file_type,
                                      Constants.imgUrl + obj.file_name
                                    )
                                  }
                                >
                                  <img
                                    className="img-thumb-perview"
                                    src={
                                      obj.file_type === "application/pdf"
                                        ? pdfImage
                                        : obj.file_type === "video/mp4" ||
                                          obj.file_type === "video/webm"
                                        ? videoImage
                                        : obj.file_type === "image/png" ||
                                          obj.file_type === "image/jpeg" ||
                                          obj.file_type === "image/jpg"
                                        ? obj.url
                                        : docImage
                                    }
                                    alt="attach"
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )
                      )}
                    <AttachmentPreview
                      displayPreview={this.state.AttachmentPreviewStatus}
                      previewUrl={this.state.previewUrl}
                      previewType={this.state.previewType}
                      handlePreviewClose={this.handlePreviewClose}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <p className="mb-1">License</p>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    {this.state.attachmentUrls &&
                      this.state.attachmentUrls.map(
                        (obj: any, index: number) => (
                          <>
                            {obj.attachment_type !== "visa" &&
                              obj.attachment_type !== "visa" &&
                              obj.attachment_type !== "contract_profile" && (
                                <div className="img-card mr-16px mb-3">
                                  <span className="contract-document-download">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() =>
                                        this.downloadFile(
                                          Constants.imgUrl + obj.file_name,
                                          obj.file_name
                                        )
                                      }
                                    >
                                      <AttachmentDownloadSVG />
                                    </a>
                                  </span>
                                  <div
                                    className="img-preview"
                                    onClick={() =>
                                      this.handlePreviewOpen(
                                        obj.file_type,
                                        Constants.imgUrl + obj.file_name
                                      )
                                    }
                                  >
                                    <img
                                      className="img-thumb-perview"
                                      src={
                                        obj.file_type === "application/pdf"
                                          ? pdfImage
                                          : obj.file_type === "video/mp4" ||
                                            obj.file_type === "video/webm"
                                          ? videoImage
                                          : obj.file_type === "image/png" ||
                                            obj.file_type === "image/jpeg" ||
                                            obj.file_type === "image/jpg"
                                          ? obj.url
                                          : docImage
                                      }
                                      alt="attach"
                                    />
                                  </div>
                                  <span style={{ position: "relative" }}>
                                    {obj.attachment_type
                                      ? obj.attachment_type.substring(8)
                                      : ""}
                                  </span>
                                </div>
                              )}
                          </>
                        )
                      )}
                    <AttachmentPreview
                      displayPreview={this.state.AttachmentPreviewStatus}
                      previewUrl={this.state.previewUrl}
                      previewType={this.state.previewType}
                      handlePreviewClose={this.handlePreviewClose}
                    />
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-12">
                    <p>
                      I have provided the above contact information and
                      authorize SM&MN PROPERTY Group Pty Ltd and its
                      representatives to contact any of the above on my behalf
                      in the event of emergency
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6"></div>
                  <div className="col-md-6">
                    <div>
                      {contractor_details.contractor_signature !== "" && (
                        <img
                          src={contractor_details.contractor_signature}
                          alt=""
                        />
                      )}
                    </div>
                    <p className="mb-1">Contractor signature </p>
                    <p className="mb-1">
                      Contract Date :{" "}
                      {moment(
                        new Date(contractor_details.contract_date?.toString())
                      ).format("DD/MM/YYYY")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  viewPermission: state.usermanagement.viewPermission,
  UserPermissionPages: state.usermanagement.UserPermissionPages,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewUsermanagement);
