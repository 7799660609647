import * as Yup from "yup";

import { Button, Offcanvas, Toast } from "react-bootstrap";
import { Dispatch, bindActionCreators } from "redux";
import { IAuditDashboardState, IAuditProps } from "../../types/audit.type";
import {
  SuccessToastSVG,
} from "../../assets/images/ImagesV2";
import {
  filterAudit,
  getAllAudits,
  handleAuditSort,
  updateAddAudit,
  updateAuditFetchStatus,
  updateSidebar,
} from "../../shared/Reducers";

import AddAudit from "./AddAudit";
import { CloseBtnSVG } from "../../assets/images/Location";
import Constants from "../../shared/Constants";
import AuditTable from "./AuditTable";
import React from "react";
import ReactPaginate from "react-paginate";
import { RootState } from "../../index";
import SideBar from "../../components/sidebar/SideBar";
import TopNav from "../../components/topnav/TopNav";
import { connect } from "react-redux";
import { SearchSVG } from "../../assets/images/UsermanagementsSVG";

class Audit extends React.Component<IAuditProps, IAuditDashboardState> {
  constructor(props: IAuditProps) {
    super(props);
    this.state = {
      show: false,
      total_audit: "",
      searchTerm: "",
      addToast: false,
      deleteToast: false,
      editToast: false,
      closeAdd: false,
      sortBy: null,
      sortDirection: null,
      pageOffset: Constants.OFFSET,
      addAuditStatus: false,
      fieldName: "",
      orderType: "",
      pagehandle: false,
      timeout: null,
    };
  }

  componentDidMount(): void {
    this.props.updateSidebar(true);
    document.title = "Audit";
    this.getAudits(Constants.OFFSET, Constants.PER_PAGE);
  }

  componentDidUpdate(
    prevProps: Readonly<IAuditProps>,
    prevState: Readonly<IAuditDashboardState>,
    snapshot?: any
  ): void {
    if (prevProps.addAuditStatus !== this.props.addAuditStatus) {
      if (!this.props.addAuditStatus) {
        this.getAudits(Constants.OFFSET, Constants.PER_PAGE);
      }
    }

    if (prevProps.auditFetchStatus !== this.props.auditFetchStatus) {
      if (this.props.auditFetchStatus) {
        this.getAudits(Constants.OFFSET, Constants.PER_PAGE);
      }
    }
    if (prevProps.addAuditSuccess !== this.props.addAuditSuccess) {
      if (this.props.addAuditSuccess === "Successfully created") {
        this.setState({ addToast: true });
        this.setState({ closeAdd: false });
        setTimeout(() => {
          this.setState({ addToast: false });
          this.setState({ closeAdd: false });
        }, 5000);
      }
    }
    if (prevProps.editAuditSuccess !== this.props.editAuditSuccess) {
      if (this.props.editAuditSuccess === "Successfully updated") {
        this.setState({ editToast: true });
        setTimeout(() => {
          this.setState({ editToast: false });
        }, 5000);
      }
    }
    if (prevProps.deleteAuditSuccess !== this.props.deleteAuditSuccess) {
      if (this.props.deleteAuditSuccess === "Successfully deleted") {
        this.getAudits(Constants.OFFSET, Constants.PER_PAGE);

        this.setState({ deleteToast: true });
        setTimeout(() => {
          this.setState({ deleteToast: false });
        }, 5000);
      }
    }
  }

  addToastClose = () => {
    this.setState({ addToast: false });
  };

  editToastClose = () => {
    this.setState({ editToast: false });
  };

  deleteToastClose = () => {
    this.setState({ deleteToast: false });
  };

  getAudits = async (offset: number, limit: number) => {
    try {
      const query = `search=&sort=${this.props.auditSortBy}&order=${this.props.auditOrderBy}&offset=${offset}&limit=${limit}&client=all&branch=all`;
      this.props.getAllAudits(query);
    } catch (error) {
      console.log(error);
    }
  };

  handleAddAuditCLose = (status: boolean) => {
    this.handleClose();
    if (!status) {
      this.getAudits(Constants.OFFSET, Constants.PER_PAGE);
    }
  };

  // Canvas Show
  handleShow() {
    // this.props.updateAddAudit(true);
    this.setState({ addAuditStatus: true });
  }

  // Canvas Close
  handleClose() {
    // this.props.updateAddAudit(false);
    this.setState({ addAuditStatus: false });
  }

  // Search Branch Filter
  handleFilter = (e: any) => {
    const value: string = e.target.value.toLowerCase();
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

    this.setState({
      searchTerm: value,
      timeout: setTimeout(async () => {
        try {
          const query = `search=${this.state.searchTerm}&sort=${this.props.auditSortBy}&order=${this.props.auditOrderBy}&offset=0&limit=10&client=all&branch=all`;
          await this.props.getAllAudits(query);
        } catch (error) {
          console.log(error);
        }
        // Trigger your function here that you want to call when user stops typing
      }, 1000), // Set the delay time in ms
    });
  };

  handlePageClick = (e: any) => {
    console.log("eee",e);
    if (e.selected < this.props.total_count / Constants.PER_PAGE) {
      this.getAudits(e.selected * Constants.PER_PAGE, Constants.PER_PAGE);
      const query = `search=${this.state.searchTerm}&sort=${this.state.fieldName
        }&order=${this.state.orderType}&offset=${e.selected * Constants.PER_PAGE
        }&limit=${Constants.PER_PAGE}&name=all&branch=all`;
      this.props.getAllAudits(query);
    }
  };

  handleSort = (column: string) => {
    if (this.state.sortBy === column) {
      this.setState({
        sortDirection: this.state.sortDirection === "asc" ? "desc" : "asc",
      });
      this.props.handleAuditSort(
        column,
        this.state.sortDirection === "asc" ? "desc" : "asc"
      );
    } else {
      this.setState({ sortBy: column });
      this.setState({ sortDirection: "asc" });
      this.props.handleAuditSort(column, "asc");
    }
  };

  validationSchema = () => {
    return Yup.object().shape({
      column_name: Yup.string().required("Select Filter Column"),
      order_type: Yup.string().required("Select Sort Order"),
    });
  };
  handleSearch = (formValue: any) => {
    try {
      this.setState({ pagehandle: true }, () => {
        const query = `search=&sort=${formValue.column_name}&order=${formValue.order_type}&offset=${Constants.OFFSET}&limit=${Constants.PER_PAGE}&name=all&branch=all`;
        this.props.getAllAudits(query);
        this.setState({
          fieldName: formValue.column_name,
          orderType: formValue.order_type,
          pagehandle: false,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  render(): React.ReactNode {
    let taskStatusOption: any = [];
    taskStatusOption = [
      { value: "name", label: "Name" },
      { value: "start_date", label: "Start Date" },
      { value: "end_date", label: "End Date" },
    ];
    // let auditOrder: any = [];
    // auditOrder = [
    //   { value: "asc", label: "Ascending" },
    //   { value: "desc", label: "Descending" },
    // ];
    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className={this.props.status ? "main-panel" : "main-panel2"}>
          <TopNav />
          <div className="content-wrapper">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="mb-0">Audit management</h3>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-end">
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12 col-lg-4">
                  <div className="input-group search">
                    <span
                      className="input-group-text search-icon"
                      id="basic-addon1"
                    >
                      <SearchSVG />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={this.state.searchTerm}
                      onInput={this.handleFilter}
                      onChange={(e) =>
                        this.setState({ searchTerm: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div style={{marginTop:"-3%"}} className="text-end">
                <Button
                  aria-controls="offcanvasRight"
                  className="btn btn-primary login-button add-audit"
                  onClick={() => this.handleShow()}
                >
                  Add Audit
                </Button>
                </div>
              </div>
              <div className="row mt-3">
                {/* <div className="col-md-12 col-lg-6 tab-res-mt-3">
                  <div>
                    <Formik
                      validationSchema={this.validationSchema}
                      onSubmit={this.handleSearch}
                      initialValues={{
                        column_name: "",
                        order_type: "asc",
                      }}
                    >
                      <Form className="d-flex flex-column flex-md-row">
                        <Field
                          className="custom-select dropdown-field mr-16px res-mt-3 filter-w-180px"
                          name="column_name"
                          options={taskStatusOption}
                          component={CustomSelect}
                          placeholder="Select Column"
                          isMulti={false}
                        />
                        <Field
                          className="custom-select dropdown-field mr-16px res-mt-3 filter-w-180px"
                          name="order_type"
                          options={auditOrder}
                          component={CustomSelect}
                          placeholder="Select Order"
                          isMulti={false}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary filter-button res-mt-3"
                        >
                          Filter
                        </button>
                      </Form>
                    </Formik>
                  </div>
                </div> */}
                <div className="col-md-12 col-lg-12 text-start text-lg-end tab-res-mt-3">
                  <Offcanvas
                    className="audit-offcanvas"
                    placement="end"
                    show={this.state.addAuditStatus}
                    onHide={() => this.handleClose()}
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>
                        <span className="offc-title">Add Audit</span>
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <AddAudit onClose={this.handleAddAuditCLose} />
                    </Offcanvas.Body>
                  </Offcanvas>

                  {this.state.addToast ? (
                    <Toast className="success-alerts">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <SuccessToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.addAuditSuccess}
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.addToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                  {this.state.editToast ? (
                    <Toast className="success-alerts">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <SuccessToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.editAuditSuccess}
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.editToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                  {this.state.deleteToast ? (
                    <Toast className="success-alerts">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <SuccessToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.deleteAuditSuccess}
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.deleteToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      {this.props.filteredAuditData.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table employee-table">
                            <thead>
                              <tr>
                                <th>NAME</th>
                                <th>CLIENT</th>
                                <th>BRANCH</th>
                                <th>NO. OF AREAS</th>
                                <th>NO. OF TASKS</th>
                                <th>NO. OF COMMENTS</th>
                                <th>SCORE</th>
                                <th>Audit Duplicate</th>
                                {/* <th>STATUS</th> */}
                                <th scope="col">ACTION</th>
                              </tr>
                            </thead>
                            <AuditTable
                              data={this.props.filteredAuditData}
                              onDelete={() =>
                                this.getAudits(
                                  Constants.OFFSET,
                                  Constants.PER_PAGE
                                )
                              }
                              onEditClose={() =>
                                this.getAudits(
                                  Constants.OFFSET,
                                  Constants.PER_PAGE
                                )
                              }
                              deleteAuditStatus={false}
                              reqPage={"audit"}
                            />
                          </table>
                        </div>
                      ) : (
                        <p className="text-center">
                          There is no audit data found
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                {this.props.total_count > 10 ? (
                  <div className="col-md-12">
                    {this.state.pagehandle ? null : (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.props.total_count / Constants.PER_PAGE}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        nextClassName={"next-page"}
                        activeClassName={"active"}
                      />
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  addAuditStatus: state.audit.addAuditStatus,
  auditFetchStatus: state.audit.auditFetchStatus,
  auditData: state.audit.auditData,
  filteredAuditData: state.audit.filteredAuditData,
  total_count: state.audit.total_count,
  addAuditSuccess: state.audit.addAuditSuccess,
  editAuditSuccess: state.audit.editAuditSuccess,
  deleteAuditSuccess: state.audit.deleteAuditSuccess,
  auditSortBy: state.audit.auditSortBy,
  auditOrderBy: state.audit.auditOrderBy,
  status: state.sidebar.status,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateAddAudit: bindActionCreators(updateAddAudit, dispatch),
    updateAuditFetchStatus: bindActionCreators(
      updateAuditFetchStatus,
      dispatch
    ),
    filterAudit: bindActionCreators(filterAudit, dispatch),
    getAllAudits: bindActionCreators(getAllAudits, dispatch),
    handleAuditSort: bindActionCreators(handleAuditSort, dispatch),
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Audit);
