import { Dispatch, bindActionCreators } from "redux";
import { updateDashboardViewReportStatus } from '../../shared/Reducers';
import Constants, { TASK_PRIORITY } from "../../shared/Constants";
import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import { CloseBtnSVG } from '../../assets/images/ImagesSVG';
import moment from "moment";


interface IDashboardViewReportProps {
  updateDashboardViewReportStatus : any;
  dashboardViewReportStatus : any;
  filteredDailyTaskData : any;
  reportType : string;
}

export interface IDashboardViewReportState {

}

class ViewAllReport extends React.Component<IDashboardViewReportProps, IDashboardViewReportState> {
  constructor(props: IDashboardViewReportProps) {
    super(props);
    this.state = {
      redirect: "",
    };
  }
  async componentDidMount() {
    this.props.updateDashboardViewReportStatus(false);
  }

  
  renderTaskList = (data: any) => {
    return data.map((row: any, index: number) => {
      return (
        <tr key={index}>
          <td>{row.branch_name}</td>
          <td>{row.title}</td>
          <td>{moment(row.task_date, "YYYY-MM-DD").format("DD/MM/YYYY")}</td>
          <td>
            {row.any_time === 1
              ? `Any Time`
              : moment(row.start_time, 'HH:mm:ss').format('hh:mm A') +
                '-' +
                moment(row.end_time, 'HH:mm:ss').format('hh:mm A')}
          </td>
          <td>
            <p
            className={
              row.priority === TASK_PRIORITY.LOW
                ? 'commented-option view-task-comment-badge'
                : row.priority === TASK_PRIORITY.MEDIUM
                ? 'inprogress-badge view-task-comment-badge'
                : row.priority === TASK_PRIORITY.HIGH
                ? 'created-badge view-task-comment-badge'
                : ''
            }>
              {row.priority === TASK_PRIORITY.LOW
              ? 'Low'
              : row.priority === TASK_PRIORITY.MEDIUM
              ? 'Medium'
              : row.priority === TASK_PRIORITY.HIGH
              ? 'High'
              : ''}
          </p>
          </td>
        </tr>
      );
    });
  };
  render(): React.ReactNode {

    return (
      <div>
        <Modal
          size="xl"
          className="task-history"
          dialogClassName="modal-90w"
          aria-labelledby="contained-modal-title-vcenter"
          show={this.props.dashboardViewReportStatus}
          onHide={() => this.props.updateDashboardViewReportStatus(false)}>
          <Modal.Header>
            <h3 className="mb-0">{this.props.reportType} Report List</h3>
            <a
              href="javascript:void(0)"
              className="qr-modal-close"
              onClick={() => this.props.updateDashboardViewReportStatus(false)}>
              <CloseBtnSVG />
            </a>
          </Modal.Header>
          <Modal.Body className="task-view">
            <div className="row task-history">
              <div className="table-responsive">
                        <table className="dashboard-table table">
                          <thead>
                            <tr>
                              <th>Branch</th>
                              <th>Task</th>
                              <th>Date</th>
                              <th>Time</th>
                              <th>Priority</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.filteredDailyTaskData &&
                            this.props.filteredDailyTaskData.length > 0 ? (
                              this.renderTaskList(this.props.filteredDailyTaskData)
                            ) : (
                              <tr>
                                <td colSpan={3}>There is no follow up task</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  dashboardViewReportStatus: state.dashboard.dashboardViewReportStatus,
  filteredDailyTaskData: state.dailytask.filteredDailyTaskData,
  status: state.sidebar.status,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateDashboardViewReportStatus : bindActionCreators(updateDashboardViewReportStatus, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAllReport);
