import React from "react";

//vector  button
export const VectorSVG = (Props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.8666 9.20835L10.2082 16.8667C9.27005 17.8049 7.99757 18.332 6.67075 18.332C5.34393 18.332 4.07145 17.8049 3.13325 16.8667C2.19505 15.9285 1.66797 14.656 1.66797 13.3292C1.66797 12.0024 2.19505 10.7299 3.13325 9.79168L10.7916 2.13335C11.4171 1.50788 12.2654 1.15649 13.1499 1.15649C14.0345 1.15649 14.8828 1.50788 15.5082 2.13335C16.1337 2.75882 16.4851 3.60713 16.4851 4.49168C16.4851 5.37623 16.1337 6.22455 15.5082 6.85002L7.84158 14.5083C7.52885 14.8211 7.10469 14.9968 6.66242 14.9968C6.22014 14.9968 5.79598 14.8211 5.48325 14.5083C5.17051 14.1956 4.99482 13.7715 4.99482 13.3292C4.99482 12.8869 5.17051 12.4627 5.48325 12.15L12.5582 5.08335" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

// Close btn in QR modal
export const CloseBtnSVG = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 6L18 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
//attachment download  button
export const AttachmentDownloadSVG = (Props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="10" fill="black" fill-opacity="0.5"/>
        <path d="M14.5 10.5V12.5C14.5 12.7652 14.3946 13.0196 14.2071 13.2071C14.0196 13.3946 13.7652 13.5 13.5 13.5H6.5C6.23478 13.5 5.98043 13.3946 5.79289 13.2071C5.60536 13.0196 5.5 12.7652 5.5 12.5V10.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.5 8L10 10.5L12.5 8" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10 10.5V4.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}
//attachment delete  button
export const AttachmentDeleteSVG = (Props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="10" fill="black" fill-opacity="0.5"/>
        <path d="M5.5 7H6.5H14.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.5 7V14C13.5 14.2652 13.3946 14.5196 13.2071 14.7071C13.0196 14.8946 12.7652 15 12.5 15H7.5C7.23478 15 6.98043 14.8946 6.79289 14.7071C6.60536 14.5196 6.5 14.2652 6.5 14V7M8 7V6C8 5.73478 8.10536 5.48043 8.29289 5.29289C8.48043 5.10536 8.73478 5 9 5H11C11.2652 5 11.5196 5.10536 11.7071 5.29289C11.8946 5.48043 12 5.73478 12 6V7" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9 9.5V12.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11 9.5V12.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}
export const TableMailSVG = (Props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.66536 2.66669H13.332C14.0654 2.66669 14.6654 3.26669 14.6654 4.00002V12C14.6654 12.7334 14.0654 13.3334 13.332 13.3334H2.66536C1.93203 13.3334 1.33203 12.7334 1.33203 12V4.00002C1.33203 3.26669 1.93203 2.66669 2.66536 2.66669Z" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.6654 4L7.9987 8.66667L1.33203 4" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}