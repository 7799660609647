import * as Yup from "yup";

import Constants, { USER_ROLE } from "../../shared/Constants";
import { Dispatch, bindActionCreators } from "redux";
import { IUserRoleState, IUserRoleType } from "../../types/userRole.type";
import {
  getAllUserRolePermission,
  getUserPermission,
  updatePermission,
  updateSidebar,
  getUserTypesList,
  updateUserTypes,
  deleteUserType,
} from "../../shared/Reducers/index";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Form } from "react-bootstrap";
import React, { useState } from "react";
import { RootState } from "../../index";
import SideBar from "../../components/sidebar/SideBar";
import TopNav from "../../components/topnav/TopNav";
import { Toast } from "react-bootstrap";
import {
  DangerSVG,
  SuccessToastSVG,
  TableDeleteSVG,
  TableEditSVG,
} from "../../assets/images/ImagesV2";
import { CloseBtnSVG } from "../../assets/images/Location";
import { connect } from "react-redux";
import { updateUserTypesEdit } from "../../shared/Reducers/index";
interface UserPermissionPageItem {
  id: string;
  permission_page: string;
  permission_access: string;
  status: number;
}

interface GroupedDataItem {
  permission_page: string;
  permission_access: UserPermissionPageItem[];
}
class UserRole extends React.Component<IUserRoleType, IUserRoleState> {
  constructor(props: IUserRoleType) {
    super(props);
    this.state = {
      permissionPages: [],
      tablehandle: false,
      showModal: false,
      deleteModal: false,
      inputrole: "",
      errormessage: "",
      addToast: false,
      roles: [{ id: null, name: "" }],
      updateButton: false,
      updateRoleId: null,
      inputError: "",
      seletedRole: "",
      deleteToast: false,
    };
  }
  componentDidMount(): void {
    this.props.updateSidebar(true);
    document.title = "User Role and Permission";
    this.props.getAllUserRolePermission();
    this.props.getUserTypesList();

    if (this.props.alluserrolepermission?.permission) {
      this.setState({
        permissionPages: this.props.alluserrolepermission?.permission,
      });
    }
  }

  updatePermissionState = (index: number, fieldName: string, value: number) => {
    let permissionPages = this.state.permissionPages;
    permissionPages[index][fieldName] = value;
    this.setState({ permissionPages: permissionPages });
  };
  componentDidUpdate(
    prevProps: Readonly<IUserRoleType>,
    prevState: Readonly<IUserRoleState>,
    snapshot?: any
  ): void {
    if (prevProps.alluserrolepermission !== this.props.alluserrolepermission) {
      if (this.props.alluserrolepermission?.permission) {
        this.setState({
          permissionPages: this.props.alluserrolepermission?.permission,
        });
      }
    }

    if (prevProps.updateUserTypesState !== this.props.updateUserTypesState) {
      if (
        this.props.updateUserTypesState === "Successfully created user role" ||
        this.props.updateUserTypesState === "Internal server error"
      ) {
        this.setState({ addToast: true });
        setTimeout(() => {
          this.setState({ addToast: false });
        }, 5000);
      }
    }
    if (
      prevProps.deleteUsermanagementSuccess !==
      this.props.deleteUsermanagementSuccess
    ) {
      if (this.props.deleteUsermanagementSuccess === "Successfully deleted") {
        // page reset 
        let Offset: number = 0;
        let paginationActive = document.querySelector(
          ".pagination li.active a"
        ) as HTMLInputElement;
        // if (paginationActive !== null) {
        //   let page: string | null = paginationActive.textContent;
        //   if (page !== null) {
        //     let pageNum: number = parseInt(page);
        //     Offset = (pageNum - 1) * Constants.PER_PAGE;
        //     this.getUsermanagements(Offset, Constants.PER_PAGE);
        //   }
        // }

        this.setState({ deleteToast: true });
        setTimeout(() => {
          this.setState({ deleteToast: false });
        }, 5000);
      }
    }
  }

  addToastClose = () => {
    this.setState({ addToast: false });
  };

  openModal = async () => {
    this.setState({ showModal: true, errormessage: "" });
    await this.props.getUserTypesList();
    let role: any = this.props.userTypesList;
    let role_name = role.map((role: any) => {
      return { id: role.id, name: role.name };
    });
    this.setState({ roles: role_name });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      deleteModal: false,
      inputrole: "",
      updateButton: "",
      updateRoleId: null,
    });
  };

  handleAdd = async () => {
    if (this.state.inputrole !== "") {
      let data = {
        name: this.state.inputrole,
      };
      await this.props.updateUserTypesEdit(data);
      await this.props.getUserTypesList();
      const roles = this.props.userTypesList.map((item: any) => {
        return { id: item.id, name: item.name };
      });
      this.setState({
        inputrole: "",
        roles: roles,
      });
    } else {
      this.setState({
        inputError: "Please Enter the Role Name",
      });
    }
  };

  handleEdit = async (role: any) => {
    this.setState({ updateButton: true });
    let selectRole = this.state.roles.find((r: any) => r.id === role.id);
    if (selectRole !== "") {
      this.setState({ updateRoleId: role.id, inputrole: role.name });
    }
  };

  handleDelete = async (item: any) => {
    console.log("deletedId",item);

    this.props.deleteUserType(item);
    await this.props.getUserTypesList();
    const deleteUser = this.props.userTypesList.map((item: any) => {
      return { id: item.id, name: item.name };
    });
    console.log(deleteUser);
    this.setState({
      inputrole: "",
      roles: deleteUser,
    });
    this.closeModal();
  };

  handleDeleteModalOpen = async (role: any) => {
    this.setState({
      seletedRole: role,
      deleteModal: true
    });
  }

  handleDeleteModalClose = () => {
    this.setState({
      deleteModal: false
    })
  }

  handleUpdate = async (role: any) => {
    let data = {
      name: this.state.inputrole,
    };
    await this.props.updateUserTypes(this.state.updateRoleId, data);
    await this.props.getUserTypesList();
    let roles: any = this.props.userTypesList;
    let role_name = roles.map((item: any) => {
      return { id: item.id, name: item.name };
    });
    this.setState({
      updateRoleId: null,
      updateButton: false,
      inputrole: "",
      roles: role_name,
    });
  };

  handleSave = async () => {
    if (this.state.inputrole === "") {
      this.setState({ errormessage: "Please Enter Role Name" });
    } else {
      let data = {
        name: this.state.inputrole,
      };
      // Handle save logic here
      await this.props.updateUserTypesEdit(data);

      if (
        this.props.updateWrongUserTypes !== "" &&
        this.props.updateWrongUserTypes.error.response.status == 406
      ) {
        this.setState({
          errormessage:
            this.props.updateWrongUserTypes.error.response.data.message,
        });
      } else {
        this.props.getUserTypesList();
        this.props.getAllUserRolePermission();

        if (this.props.alluserrolepermission?.permission) {
          this.setState({
            permissionPages: this.props.alluserrolepermission?.permission,
          });
        }
        this.closeModal();
      }
    }
  };

  deleteClose = () => {
    console.log("call");
    this.setState({ deleteToast: false });
  };

  

  render(): React.ReactNode {
    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className={this.props.status ? "main-panel" : "main-panel2"}>
          <TopNav />
          <div className="content-wrapper">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="mb-0">User Role and Permission</h3>
                </div>
                <div className="col-md-6">
                  {/* <div className="clearfix" style={{ float: "right" }}>
                    <Button
                      className="btn btn-primary login-button mob-res-w-100 role-list"
                      onClick={() =>
                        this.setState({ showModal: true, errormessage: "" })
                      }
                    >
                      View Role List
                    </Button>
                  </div> */}
                  <div className="clearfix">
                    <Button
                      className="btn btn-primary login-button add-role-button flex-mr-16px mob-res-w-100"
                      onClick={this.openModal}
                    >
                      Add Role
                    </Button>
                  </div>

                  <Modal show={this.state.deleteModal} onHide={this.handleDeleteModalClose}>
                    <Modal.Header onHide={this.handleDeleteModalClose} closeButton>
                      <Modal.Title><DangerSVG/> Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>Are you sure you want to delete the user role <span><strong>{this.state.seletedRole.name}</strong></span>? This action is irreversible, and users assigned to this role will lose their permissions. Click "OK" to confirm or "Cancel" if you do not wish to proceed.</p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        className="btn btn-primary login-button add-role-button flex-mr-16px mob-res-w-100"
                        onClick={this.handleDeleteModalClose}
                      >
                        Cancel
                      </Button>
                      {this.state.seletedRole.id !== Constants.ADMIN && (
                        <Button
                          className="btn btn-primary login-button add-role-button flex-mr-16px mob-res-w-100"
                          onClick={() => this.handleDelete(this.state.seletedRole.id)}
                          // onClick={() => this.handleDeleteModalOpen(role)}
                        >
                          OK
                        </Button>
                      )}
                    </Modal.Footer>
                  </Modal>

                  <Modal show={this.state.showModal} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Add Role</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <label htmlFor="exampleInputRole" className="form-label">
                        Role Name
                      </label>
                      <div className="container-fluid row mb-2">
                        <div className="col-8">
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputRole"
                            aria-describedby="userRoleHelp"
                            placeholder="Kindly only enter a Name"
                            maxLength={20}
                            style={{
                              maxWidth: "300px",
                            }}
                            value={this.state.inputrole}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              this.setState({
                                inputrole: e.target.value.replace(
                                  /[^a-zA-Z0-9\s]/g,
                                  ""
                                ),
                              });
                            }}
                          />
                          <p className="error">{this.state.inputError}</p>
                        </div>
                        <div className="col-4">
                          {this.state.updateButton === true ? (
                            <Button
                              className="btn btn-primary login-button mob-res-w-100"
                              onClick={this.handleUpdate}
                            >
                              update
                            </Button>
                          ) : (
                            <Button
                              className="btn btn-primary login-button mob-res-w-100"
                              onClick={this.handleAdd}
                            >
                              Add
                            </Button>
                          )}
                        </div>
                      </div>
                      <div className="container-fluid">
                        {this.state.roles.map((role: any, index: any) => {
                          return (
                            <div key={index} className="row mb-2">
                              <div className="col-8">
                                <span>
                                  {index + 1}. {role.name}
                                </span>
                              </div>
                              <div className="col-2">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>{"Edit"}</Tooltip>}
                                >
                                  <Button
                                    className="action-btn"
                                    onClick={() => this.handleEdit(role)}
                                  >
                                    <TableEditSVG />
                                  </Button>
                                </OverlayTrigger>
                              </div>
                              {role.id !== Constants.ADMIN && (
                                <div className="col-2">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>{"Delete"}</Tooltip>}
                                  >
                                    <Button
                                      className="action-btn"
                                      onClick={() => this.handleDeleteModalOpen(role)}
                                    >
                                      <TableDeleteSVG />
                                    </Button>
                                  </OverlayTrigger>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      {/* <Button
                        style={{ backgroundColor: "#134989" }}
                        variant="primary"
                        onClick={this.handleSave}
                      >
                        Save
                      </Button> */}
                      <Button
                        style={{
                          backgroundColor: "transparent",
                          color: "#000000",
                        }}
                        variant="secondary"
                        onClick={this.closeModal}
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
                {this.state.addToast ? (
                  <Toast className="success-alerts">
                    <Toast.Body>
                      <div className="row align-items-center">
                        <div className="col-md-2">
                          <SuccessToastSVG />
                        </div>
                        <div className="col-md-8">
                          {this.props.updateUserTypesState}
                        </div>
                        <div className="col-md-2">
                          <button
                            type="button"
                            className="btn"
                            onClick={this.addToastClose}
                          >
                            <CloseBtnSVG />
                          </button>
                        </div>
                      </div>
                    </Toast.Body>
                  </Toast>
                ) : (
                  ""
                )}
                 {this.state.deleteToast ? (
                  <Toast className="success-alerts">
                    <Toast.Body>
                      <div className="row align-items-center">
                        <div className="col-md-2">
                          <SuccessToastSVG />
                        </div>
                        <div className="col-md-8">
                          {this.props.deleteUsermanagementSuccess}
                        </div>
                        <div className="col-md-2">
                          <button
                            type="button"
                            className="btn"
                            onClick={this.deleteClose}
                          >
                            <CloseBtnSVG />
                          </button>
                        </div>
                      </div>
                    </Toast.Body>
                  </Toast>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table employee-table">
                        <thead>
                          <tr>
                            <th scope="col">Module</th>
                            <th scope="col">Page</th>
                            {this.props.userTypesList &&
                              this.props.userTypesList.map(
                                (role: any, roleinx: number) => (
                                  <>
                                    {role.id !== Constants.CLIENTUTINT &&
                                      role.id !== Constants.CLEANERUTINT && (
                                        <th className="text-capitalize">
                                          {role.name.replace(/[_-]/g, " ")}
                                        </th>
                                      )}
                                  </>
                                )
                              )}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.permissionPages &&
                            this.state.permissionPages.map(
                              (val: any, permissionInx: number) => (
                                <>
                                  <tr>
                                    <td className="text-capitalize">
                                      {val.permission_page.replace(
                                        /[_-]/g,
                                        " "
                                      )}
                                    </td>
                                    <td className="text-capitalize">
                                      {val.permission_access.replace(
                                        /[_-]/g,
                                        " "
                                      )}
                                    </td>
                                    {this.props.userTypesList &&
                                      this.props.userTypesList.map(
                                        (role: any, roleInx: number) => {
                                          console.log("userrole", role);

                                          return (
                                            <>
                                              {role.id !==
                                                Constants.CLIENTUTINT &&
                                                role.id !==
                                                Constants.CLEANERUTINT && (
                                                  <td className="text-capitalize">
                                                    <Form.Check
                                                      type="switch"
                                                      checked={
                                                        val[role.name] === 0
                                                          ? false
                                                          : true
                                                      }
                                                      onClick={() => {
                                                        this.props.updatePermission(
                                                          {
                                                            id: val.id,
                                                            status:
                                                              val[role.name] ===
                                                                0
                                                                ? 1
                                                                : 0,
                                                            name: role.name,
                                                          }
                                                        );
                                                        this.updatePermissionState(
                                                          permissionInx,
                                                          role.name,
                                                          val[role.name] === 0
                                                            ? 1
                                                            : 0
                                                        );
                                                      }}
                                                    />
                                                  </td>
                                                )}
                                            </>
                                          );
                                        }
                                      )}
                                    {/* <td>
                                                                        <Form.Check
                                                                            type="switch"
                                                                            checked={role.permission[index]?.permission_access[inx]?.status === 0 ? false : true}
                                                                            onClick={() => {
                                                                                this.props.updatePermission({
                                                                                    id: val.id,
                                                                                    status: role.permission[index]?.permission_access[inx]?.status === 0 ? 1 : 0,
                                                                                    type: role.permission[index]?.permission_access[inx]?.type,
                                                                                    user_id: role.permission[index]?.permission_access[inx]?.user_id
                                                                                });
                                                                                this.updatePermissionState(roleinx, index, inx, role.permission[index]?.permission_access[inx]?.status === 0 ? 1 : 0);
                                                                            }}
                                                                        />
                                                                    </td> */}
                                    {/* {
                                                                                inx === 0 && (
                                                                                    <td scope="col" className="text-capitalize" id="table-page" rowSpan={val.permission_access.length}>{val.permission_page}</td>
                                                                                )
                                                                            }
                                                                            <td scope="col" className={`text-capitalize ${inx === val.permission_access.length - 1 ? 'last-data-cell' : ''}`} id="table-module">
                                                                                {access.permission_access.replace(/[_-]/g, ' ')}
                                                                            </td>

                                                                            {this.state.permissionPages &&
                                                                                this.state.permissionPages.map((role: any, roleinx: number) => (
                                                                                    <td scope="col" id="table-access" className={inx === role.permission[index]?.permission_access.length - 1 ? 'last-data-cell' : ''}>
                                                                                        <Form.Check
                                                                                            type="switch"
                                                                                            checked={role.permission[index]?.permission_access[inx]?.status === 0 ? false : true}
                                                                                            onClick={() => {
                                                                                                this.props.updatePermission({
                                                                                                    id: val.id,
                                                                                                    status: role.permission[index]?.permission_access[inx]?.status === 0 ? 1 : 0,
                                                                                                    type: role.permission[index]?.permission_access[inx]?.type,
                                                                                                    user_id: role.permission[index]?.permission_access[inx]?.user_id
                                                                                                });
                                                                                                this.updatePermissionState(roleinx, index, inx, role.permission[index]?.permission_access[inx]?.status === 0 ? 1 : 0);
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                ))} */}
                                  </tr>
                                </>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  status: state.sidebar.status,
  alluserrolepermission: state.usermanagement.alluserrolepermission,
  viewPermission: state.usermanagement.viewPermission,
  userLoginDetails: state.sidebar.userLoginDetails,
  updateUserTypesState: state.usermanagement.updateUserTypesState,
  updateWrongUserTypes: state.usermanagement.updateWrongUserTypes,
  deleteUsermanagementSuccess: state.usermanagement.deleteUsermanagementSuccess,
  userTypesList: state.app.userTypesList,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
    updatePermission: bindActionCreators(updatePermission, dispatch),
    getAllUserRolePermission: bindActionCreators(
      getAllUserRolePermission,
      dispatch
    ),
    getUserPermission: bindActionCreators(getUserPermission, dispatch),
    updateUserTypesEdit: bindActionCreators(updateUserTypesEdit, dispatch),
    getUserTypesList: bindActionCreators(getUserTypesList, dispatch),
    updateUserTypes: bindActionCreators(updateUserTypes, dispatch),
    deleteUserType: bindActionCreators(deleteUserType, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRole);
