import {
  API_GET_ALL_DELETE_REPORT,
  API_GET_ALL_DELETE_REPORT_FAIL,
  API_GET_ALL_DELETE_REPORT_SUCCESS,
  API_GET_ALL_REPORTS,
  API_GET_ALL_REPORTS_FAIL,
  API_GET_ALL_REPORTS_SUCCESS,
  API_UPDATE_INOUT_DATE,
  API_UPDATE_INOUT_DATE_FAIL,
  API_UPDATE_INOUT_DATE_PROGRESS,
  API_UPDATE_INOUT_DATE_SUCCESS,
  FILTER_REPORT,
  FILTER_REPORT_FROM_DATE,
  FILTER_REPORT_TO_DATE,
  ReportState,
  UPDATE_REPORT,
  UPDATE_REPORT_FETCH_STATUS,
} from "../ActionTypes/index";
import { AnyAction, Dispatch } from "redux";

const initialState: ReportState = {
  filteredReportData: [],
  reportData: [],
  selectedReportData: null,
  reportFetchStatus: false,
  total_count: 0,
  filterReportFromDate: "",
  filterReportToDate: "",
  Deletereport: "",
  deletereportstatus: false,
  deletereport_success: "",
};

export const ReportReducer = (
  state: ReportState = initialState,
  action: AnyAction
): ReportState => {
  switch (action.type) {
    case API_GET_ALL_REPORTS:
      return { ...state };
    case API_GET_ALL_REPORTS_SUCCESS:
      let reportData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "activities" in data && data.activities) {
          reportData = data.activities;
        }
      }
      let total_count = 0;
      if (action.payload) {
        const { data } = action.payload;
        if (data && "total_count" in data && data.total_count) {
          total_count = data.total_count;
        }
      }
      return {
        ...state,
        total_count: total_count,
        filteredReportData: reportData,
      };
    case API_GET_ALL_REPORTS_FAIL:
      return { ...state };
    case UPDATE_REPORT:
      return { ...state };
    case UPDATE_REPORT_FETCH_STATUS:
      return { ...state, reportFetchStatus: action.payload };
    case FILTER_REPORT:
      const filterReport: any = state.reportData.filter((obj) => {
        console.log(obj, "filterReportklklklk");

        return (
          obj.branch_name.toLowerCase().includes(action.payload) ||
          obj.branch_name.toLowerCase().includes(action.payload) ||
          obj.first_name.toLowerCase().includes(action.payload) ||
          obj.last_name.toLowerCase().includes(action.payload)
        );
      });
      return { ...state, filteredReportData: filterReport };

    case API_UPDATE_INOUT_DATE:
      let value: boolean = false;
      if (action.payload.request.data.FormData === undefined) {
        value = true;
      }
      return {
        ...state,
        // processTrigger: value
      };
    case API_UPDATE_INOUT_DATE_SUCCESS:
      let messages = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          messages = data.message;
        }
      }
      return {
        ...state,
        // processTrigger:false,
        // progressPercentage:0
      };
    case API_UPDATE_INOUT_DATE_FAIL:
      let error = "";
      if (
        "error" in action &&
        action.error &&
        "response" in action.error &&
        action.error.response &&
        "data" in action.error.response &&
        action.error.response.data &&
        "message" in action.error.response.data
      ) {
        error = action.error.response.data.message;
      }
      return {
        ...state,
        // editBranchError: error
      };
    case API_UPDATE_INOUT_DATE_PROGRESS:
      return {
        ...state,
        // progressPercentage: action.payload
      };
    case FILTER_REPORT_FROM_DATE:      
      return { ...state, filterReportFromDate: action.payload };
    case FILTER_REPORT_TO_DATE:
      return { ...state, filterReportToDate: action.payload };
    case API_GET_ALL_DELETE_REPORT:
      return { ...state, deletereport_success: "" };
    case API_GET_ALL_DELETE_REPORT_SUCCESS:
      let msg = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          msg = data.message;
        }
        return {
          ...state,
          Deletereport: data,
          deletereport_success: msg,
          deletereportstatus: false,
        };
      }
      return state;
    case API_GET_ALL_DELETE_REPORT_FAIL:
      return { ...state };
    default:
      return state;
  }
};

export function updateReport(data: any) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_REPORT,
      payload: data,
    });
  };
}

export function getAllReports(queryStr: string) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_ALL_REPORTS,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `activity?${queryStr}` : `activity?`,
        },
      },
    });
  };
}

export function filterReport(filterVal: string) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_REPORT,
      payload: filterVal,
    });
  };
}

export function updateReportFetchStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: UPDATE_REPORT_FETCH_STATUS,
      payload: status,
    });
  };
}

// update branch
export function updateSignInOutDateTime(payload: any, id: number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_UPDATE_INOUT_DATE,
      payload: {
        request: {
          method: "put",
          url: `activity/${id}`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_UPDATE_INOUT_DATE_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

export function handleFilterReportFromDate(Fromdate: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_REPORT_FROM_DATE,
      payload: Fromdate,
    });
  };
}

export function handleFilterReportToDate(Todate: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_REPORT_TO_DATE,
      payload: Todate,
    });
  };
}

export function deleteReport(query: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ALL_DELETE_REPORT,
      payload: {
        request: {
          method: "DELETE",
          url: query ? `activity/${query}` : `activity`,
        },
      },
    });
  };
}
