import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  DisableSVG,
  EnableSVG,
  TableDeleteSVG,
  TableEditSVG,
  TableViewSVG,
} from "../../assets/images/ImagesV2";
import { Dispatch, bindActionCreators } from "redux";
import { IBranchCardProps, IBranchCardState } from "../../types/branch.type";
import {
  getBranchByIdView,
  updateEditBranchDlgStatus,
} from "../../shared/Reducers";

import Constants from "../../shared/Constants";
import DeleteConfirmation from "./DeleteConfirmation";
import DisplayQR from "./DisplayQR";
import React from "react";
import { RootState } from "../../index";
import ViewBranch from "./ViewBranch";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";

class BranchCard extends React.Component<IBranchCardProps, IBranchCardState> {
  constructor(props: IBranchCardProps) {
    super(props);
    this.state = {
      show: false,
      qrImg: "",
      displayDelete: false,
      currentBranchId: "",
      editShow: false,
      displayView: false,
      previewType: '',
      previewUrl: '',
      AttachmentPreviewStatus: false,
      imgURL: ''
    };
  }

  handleClose = () => {
    this.setState({ show: false, qrImg: "" });
  };

  handleShow = (qr: string) => {
    this.setState({ show: true, qrImg: qr });
  };

  handleDelete = (id: string) => {
    this.setState({ displayDelete: true, currentBranchId: id });
  };

  handleDeleteClose = (status: boolean) => {
    this.setState({ displayDelete: false, currentBranchId: "" });
    if (status) {
      this.props.handleDeleteBranch(this.state.currentBranchId);
    }
  };

  handleView = async (id: string) => {
    this.setState({ displayView: true, currentBranchId: id });
    console.log("test", id);

    await this.props.getBranchByIdView(id);
  };

  handleViewClose = () => {
    this.setState({ displayView: false });
  };

  handlePreviewOpen = (url: any) => {
    // this.props.handleViewClose(false)
    this.setState({
      displayView: false,
      AttachmentPreviewStatus: true,
      previewUrl: url,
    });
  };

  handlePreviewClose = () => {
    this.setState({ AttachmentPreviewStatus: false,displayView:true });
  };

  render(): React.ReactNode {
    const {
      name,
      lat,
      lng,
      qr,
      id,
      status,
      address,
      first_name,
      last_name,
    } = this.props.branch;
    return (
      <tr>
        {/* <td>B{id}</td> */}
        <td>
          {first_name} {last_name}
        </td>
        <td>{name}</td>
        <td>{address}</td>
        <td>{lat}</td>
        <td>{lng}</td>
        <td>
          <a href="javascript:void(0)" onClick={() => this.handleShow(qr)}>
            <img src={qr ? qr : "/qrcode.png"} alt="qrcode" />
          </a>
          <DisplayQR
            displayQR={this.state.show}
            branch={this.props.branch}
            handleClose={this.handleClose}
          />
        </td>
        <td>{status === 1 ? <EnableSVG /> : <DisableSVG />}</td>
        <td>
          <div className="btn-group">
            <span className="mr-20px">
              <a href="javascript:void(0)" onClick={() => this.handleView(id)}>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"View"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableViewSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
            <span
              className={
                Constants.LOGIN_USER_TYPE === Constants.SUPERVISORUTINT
                  ? "d-none"
                  : "mr-20px"
              }
            >
              <a
                href="javascript:void(0)"
                onClick={() => this.props.handleEditBranch(id)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"Edit"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableEditSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
            <span
              className={
                Constants.LOGIN_USER_TYPE === Constants.SUPERVISORUTINT
                  ? "d-none"
                  : ""
              }
            >
              <a
                href="javascript:void(0)"
                onClick={() => this.handleDelete(id)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"Delete"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableDeleteSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
           
            <ViewBranch
              displayView={this.state.displayView}
              handleViewClose={this.handleViewClose}
              handleView={this.handleView}
              data={this.props.selectedData}
              id={this.props.id}
            />
            <DeleteConfirmation
              displayDelete={this.state.displayDelete}
              deletedName={
                name +
                "? If you deleted then the related task template, schedule and task would be deleted"
              }
              handleDeleteClose={this.handleDeleteClose}
            />
          </div>
        </td>
      </tr>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  editBranchStatus: state.branch.editBranchStatus,
  selectedData: state.branch.selectedData,
  id: state.branch.id,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateEditBranchDlgStatus: bindActionCreators(
      updateEditBranchDlgStatus,
      dispatch
    ),
    getBranchByIdView: bindActionCreators(getBranchByIdView, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchCard);
