import * as Yup from "yup";

import { Button, Offcanvas, Toast } from "react-bootstrap";
import Constants, { TASK_PRIORITY } from "../../shared/Constants";
import { DailyTaskProps, DailyTaskState } from "../../types/dailyTask.type";
import { Dispatch, bindActionCreators } from "redux";
import { Field, Form, Formik } from "formik";
import { IBranchSelect, ICleaner } from "../../shared/ActionTypes";
import {
  filterDailyTask,
  getAllDailyTasks,
  getAllListDailyTasks,
  handleFilterSpecialTaskStatus,
  handleFilterTaskBranch,
  handleFilterTaskClient,
  handleFilterTaskDate,
  handleFilterTaskDueType,
  handleFilterTaskPriority,
  handleFilterTaskStatus,
  handleFilterTaskType,
  listAllBranch,
  listAllClient,
  updateAddDailyTask,
  updateDailyTaskFetchStatus,
  updateSidebar,
  getLoginUserDetails,
} from "../../shared/Reducers";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddDailyTask from "./AddDailyTask";
import { CloseBtnSVG } from "../../assets/images/Location";
import { CustomSelect } from "../../shared/Components/CustomSelect";
import DailyTaskTable from "./DailyTaskTable";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ExportExcel from "../../export/exportExcel";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import ReactPaginate from "react-paginate";
import { RootState } from "../../index";
import { SearchSVG } from "../../assets/images/EmployeesSVG";
import Select from "react-select";
import SideBar from "../../components/sidebar/SideBar";
import Stack from "@mui/material/Stack";
import { SuccessToastSVG } from "../../assets/images/ImagesV2";
import TextField from "@mui/material/TextField";
import TopNav from "../../components/topnav/TopNav";
import { connect } from "react-redux";
import moment from "moment";
import searchFunction from "../../export/searchFunction";

const reactSelectCusStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#fff",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    },
  }),
};
class DailyTask extends React.Component<DailyTaskProps, DailyTaskState> {
  constructor(props: DailyTaskProps) {
    super(props);
    this.state = {
      show: false,
      total_task: "",
      searchTerm: "",
      addToast: false,
      deleteToast: false,
      editToast: false,
      urlLastSegment: "",
      fieldName: "",
      orderType: "",
      pagehandle: false,
      timeout: null,
      showFilterOption: false,
      start_Date: "",
      to_Date: "",
      get_userDetails: [],
      filterTaskStatusByIdValue: "All",
      filterSpecialRequestTaskStatusByIdValue: "All",
      branchSelectOption: [],
      clientSelectOption: [],
      selectedFilterClient: [],
      selectedFilterBranch: [],
    };
  }

  handleStartDate = (val: any) => {
    this.setState({ start_Date: val }, () => {
      if (this.state.to_Date !== "" && this.state.start_Date !== "") {
        let value: any = {
          from_date: this.state.start_Date,

          to_date: this.state.to_Date,
        };
        this.props.handleFilterTaskDate(value);
      }
    });
  };

  handleStartDateClick = () => {
    let startDate: HTMLElement = document.querySelector(
      ".css-1lwbda4-MuiStack-root button.MuiButtonBase-root"
    ) as HTMLElement;
    console.log(startDate, "startDate");

    startDate.click();
  };

  handleToDate = (val: any) => {
    this.setState({ to_Date: val }, () => {
      if (this.state.to_Date !== "" && this.state.start_Date !== "") {
        let value: any = {
          from_date: this.state.start_Date,

          to_date: this.state.to_Date,
        };
        this.props.handleFilterTaskDate(value);
      }
    });
  };

  handleEndDateClick = () => {
    let endDate: HTMLElement = document.querySelector(
      ".endDate button.MuiButtonBase-root"
    ) as HTMLElement;
    console.log(endDate, "endDate");

    endDate.click();
  };

  componentDidMount(): void {
    this.props.listAllClient();
    this.props.updateSidebar(true);
    document.title = "Daily Task";

    // get url last params
    const currentUrl = window.location.href;
    const segments = currentUrl.split("/");
    let urlLastSegment = segments[segments.length - 1];
    this.setState({ urlLastSegment: urlLastSegment });

    this.props.handleFilterTaskBranch("all");
    this.props.handleFilterTaskClient("all");
    this.props.handleFilterTaskDate("all");
    // this.props.handleFilterTaskType("1");
    if (
      this.props.userLoginDetails &&
      this.props.userLoginDetails?.permission?.task_add_task === 1
    ) {
      this.props.handleFilterTaskType("all");
    } else {
      this.props.handleFilterTaskType(Constants.SPECIAL_REQUEST);
    }

    this.props.handleFilterTaskPriority("all");
    if (urlLastSegment === "followup") {
      this.props.handleFilterSpecialTaskStatus(Constants.TASK_FOLLOWUP);
    } else {
      this.props.handleFilterSpecialTaskStatus("all");
    }

    if (urlLastSegment === "completed") {
      this.props.handleFilterTaskStatus(Constants.TASK_COMPLETED);
    } else {
      this.props.handleFilterTaskStatus("all");
    }

    if (urlLastSegment === "overdue") {
      this.props.handleFilterTaskDueType("overdue");
    } else if (urlLastSegment === "due") {
      this.props.handleFilterTaskDueType("due");
    } else {
      this.props.handleFilterTaskDueType("all");
    }

    if (urlLastSegment === "task") {
      this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
    }

    let stateLastUrl = this.state.urlLastSegment;
    const interval: any = setInterval(async () => {
      // get url last params
      const currentUrl = window.location.href;
      const segments = currentUrl.split("/");
      let urlLastSegment = segments[segments.length - 1];

      // console.log("stateLastUrl",stateLastUrl);

      if (urlLastSegment === "task" && stateLastUrl !== "task") {
        stateLastUrl = urlLastSegment;
        if (this.state.urlLastSegment === "overdue") {
          this.props.handleFilterTaskDueType("");
        } else if (this.state.urlLastSegment === "due") {
          this.props.handleFilterTaskDueType("all");
        } else if (this.state.urlLastSegment === "completed") {
          this.props.handleFilterTaskStatus("all");
        } else if (this.state.urlLastSegment === "followup") {
          this.props.handleFilterSpecialTaskStatus("all");
        }
      }
    }, 1000);

    // Add event listener for URL changes
    window.addEventListener("popstate", this.handleUrlChange);
  }

  componentWillUnmount(): void {
    // Remove event listener when component unmounts
    window.removeEventListener("popstate", this.handleUrlChange);
  }

  handleUrlChange = () => {
    console.log("test");
  };

  componentDidUpdate(
    prevProps: Readonly<DailyTaskProps>,
    prevState: Readonly<DailyTaskState>,
    snapshot?: any
  ): void {
    if (
      this.props.userLoginDetails !== prevProps.userLoginDetails &&
      this.props.userLoginDetails?.permission?.task_add_task === 1
    ) {
      this.props.handleFilterTaskType("all");
    } else if (
      this.props.userLoginDetails !== prevProps.userLoginDetails &&
      this.props.userLoginDetails?.permission?.task_add_special_task === 1
    ) {
      this.props.handleFilterTaskType(Constants.SPECIAL_REQUEST);
    }

    if (prevProps.addCommentStatus !== this.props.addCommentStatus) {
      if (this.props.addCommentStatus) {
        let Offset: number = 0;
        let paginationActive = document.querySelector(
          ".pagination li.active a"
        ) as HTMLInputElement;
        if (paginationActive !== null) {
          let page: string | null = paginationActive.textContent;
          if (page !== null) {
            let pageNum: number = parseInt(page);
            Offset = (pageNum - 1) * Constants.PER_PAGE;
            this.getTasks(Offset, Constants.PER_PAGE);
          }
        }
      }
    }
    if (prevProps.addDailyTaskStatus !== this.props.addDailyTaskStatus) {
      if (!this.props.addDailyTaskStatus) {
        this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
      }
    }
    if (prevProps.copyDailyTaskStatus !== this.props.copyDailyTaskStatus) {
      if (!this.props.copyDailyTaskStatus) {
        let Offset: number = 0;
        let paginationActive = document.querySelector(
          ".pagination li.active a"
        ) as HTMLInputElement;
        if (paginationActive !== null) {
          let page: string | null = paginationActive.textContent;
          if (page !== null) {
            let pageNum: number = parseInt(page);
            Offset = (pageNum - 1) * Constants.PER_PAGE;
            this.getTasks(Offset, Constants.PER_PAGE);
          }
        }
      }
    }

    if (prevProps.filterTaskTypeById !== this.props.filterTaskTypeById) {
      this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
    } else if (
      prevProps.filterTaskPriorityById !== this.props.filterTaskPriorityById
    ) {
      this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
    } else if (
      prevProps.filterTaskDueTypeByValue !== this.props.filterTaskDueTypeByValue
    ) {
      this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
    } else if (
      prevProps.filterSpecialRequestTaskStatusById !==
      this.props.filterSpecialRequestTaskStatusById
    ) {
      let feedbackStatusOptionArray: Array<any> =
        this.props.taskStatusList?.map((status: any) => {
          if (status.id === 6 || status.id === 7) {
            return {
              value: status.id,
              label: status.name,
            };
          }
          return null; // or omit it from the result
        });
      feedbackStatusOptionArray = feedbackStatusOptionArray?.filter(
        (item) => item !== null
      );

      let feedStatusOption; // Declare feedStatusOption in a higher scope

      if (feedbackStatusOptionArray !== undefined) {
        feedStatusOption = [
          { value: "all", label: "All" },
          ...feedbackStatusOptionArray, // Spread the elements of the input array
        ];
      } else {
        feedStatusOption = [{ value: "all", label: "All" }];
      }
      let filterSpecialRequestTaskStatusByIdValue: any = feedStatusOption?.find(
        (status: any) =>
          status.value === this.props.filterSpecialRequestTaskStatusById
      );
      this.setState({
        filterSpecialRequestTaskStatusByIdValue:
          filterSpecialRequestTaskStatusByIdValue.label,
      });

      this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
    } else if (
      prevProps.filterTaskClientById !== this.props.filterTaskClientById
    ) {
      this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
    } else if (
      prevProps.filterTaskBranchById !== this.props.filterTaskBranchById
    ) {
      this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
    } else if (
      prevProps.filterTaskStatusById !== this.props.filterTaskStatusById
    ) {
      let taskStatusOptionArray: Array<any> = this.props.taskStatusList?.map(
        (status: any) => {
          if (status.id !== 6 && status.id !== 7) {
            return {
              value: status.id,
              label: status.name,
            };
          }
          return null;
        }
      );
      taskStatusOptionArray = taskStatusOptionArray?.filter(
        (item) => item !== null
      );

      let taskStatusOption; // Declare taskStatusOption in a higher scope

      if (taskStatusOptionArray !== undefined) {
        taskStatusOption = [
          { value: "all", label: "All" },
          ...taskStatusOptionArray, // Spread the elements of the input array
        ];
      } else {
        taskStatusOption = [{ value: "all", label: "All" }];
      }
      let filterTaskStatusByIdValue: any = taskStatusOption?.find(
        (status: any) => status.value === this.props.filterTaskStatusById
      );
      this.setState({
        filterTaskStatusByIdValue: filterTaskStatusByIdValue.label,
      });

      this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
    }

    if (
      prevProps.filterTaskDateTypeValue !== this.props.filterTaskDateTypeValue
    ) {
      this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
    }
    if (prevProps.dailyTaskFetchStatus !== this.props.dailyTaskFetchStatus) {
      if (this.props.dailyTaskFetchStatus) {
        let Offset: number = 0;
        let paginationActive = document.querySelector(
          ".pagination li.active a"
        ) as HTMLInputElement;
        if (paginationActive !== null) {
          let page: string | null = paginationActive.textContent;
          if (page !== null) {
            let pageNum: number = parseInt(page);
            Offset = (pageNum - 1) * Constants.PER_PAGE;
            this.getTasks(Offset, Constants.PER_PAGE);
          }
        }
      }
    }

    if (prevProps.addDailyTaskSuccess !== this.props.addDailyTaskSuccess) {
      if (
        this.props.addDailyTaskSuccess === "Successfully created" ||
        this.props.addDailyTaskSuccess === "Internal server error"
      ) {
        this.setState({ addToast: true });
        setTimeout(() => {
          this.setState({ addToast: false });
        }, 5000);
      }
    }

    if (prevProps.editDailyTaskSuccess !== this.props.editDailyTaskSuccess) {
      if (
        this.props.editDailyTaskSuccess === "Successfully updated" ||
        this.props.editDailyTaskSuccess === "Internal server error"
      ) {
        this.setState({ editToast: true });

        setTimeout(() => {
          this.setState({ editToast: false });
        }, 5000);
      }
    }

    if (
      prevProps.deleteDailyTaskSuccess !== this.props.deleteDailyTaskSuccess
    ) {
      if (
        this.props.deleteDailyTaskSuccess === "Successfully deleted" ||
        this.props.deleteDailyTaskSuccess === "Internal server error"
      ) {
        this.setState({ deleteToast: true });
        setTimeout(() => {
          this.setState({ deleteToast: false });
        }, 5000);
      }
    }

    if (
      prevProps.clientList !== this.props.clientList ||
      this.props.userLoginDetails !== prevProps.userLoginDetails
    ) {
      let clientSelectOption = this.props.clientList.map((client: any) => ({
        value: client.id,
        label: client.first_name + " " + client.last_name,
      }));
      if (
        this.props.userLoginDetails?.permission?.user_multi_client_branch === 1
      ) {
        let clientSelectOptionUpdate = [
          {
            value: "all",
            label: "All",
          },
        ].concat(clientSelectOption);
        this.setState({ clientSelectOption: clientSelectOptionUpdate });
      } else {
        this.setState({ clientSelectOption: clientSelectOption });
      }
    }

    if (prevProps.branchList !== this.props.branchList) {
      this.setState({
        branchSelectOption: this.props.branchList,
      });
    }
  }

  addToastClose = () => {
    this.setState({ addToast: false });
  };

  editToastClose = () => {
    this.setState({ editToast: false });
  };

  deleteToastClose = () => {
    this.setState({ deleteToast: false });
  };

  getTasks = async (offset: number, limit: number) => {
    try {
      // list for table
      let query = `search=&sort=&order=&offset=${offset}&limit=${limit}&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=${this.props.filterTaskTypeById}&task_priority=${this.props.filterTaskPriorityById}&due_type=${this.props.filterTaskDueTypeByValue}&task_status=${this.props.filterTaskStatusById}&special_request_task_status=${this.props.filterSpecialRequestTaskStatusById}&status&start_date=${this.props.filterTaskDateTypeValue.from_date}&end_date=${this.props.filterTaskDateTypeValue.to_date}&additional_task_status=1&recommended_task_status=1`;

      // list for excel report
      let AllListquery = `search=&sort=&order=&offset=${offset}&limit=10000&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=${this.props.filterTaskTypeById}&task_priority=${this.props.filterTaskPriorityById}&due_type=${this.props.filterTaskDueTypeByValue}&task_status=${this.props.filterTaskStatusById}&special_request_task_status=${this.props.filterSpecialRequestTaskStatusById}&status&start_date=${this.props.filterTaskDateTypeValue.from_date}&end_date=${this.props.filterTaskDateTypeValue.to_date}&additional_task_status=1&recommended_task_status=1`;
      await this.props.getAllDailyTasks(query);

      // no limit data
      await this.props.getAllListDailyTasks(AllListquery);
    } catch (error) {
      console.log(error);
    }
  };

  handleAddDailyTaskCLose = (status: boolean) => {
    this.handleClose();
    if (status) {
      this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
    }
  };

  // Canvas Show
  handleShow(type: string = "add_task") {
    if (type === "special_request") {
      this.props.updateAddDailyTask(true, true);
    } else {
      this.props.updateAddDailyTask(true);
    }
  }

  // filter cancas show
  handleShowFilter(status: boolean) {
    this.setState({ showFilterOption: status });
  }

  // Canvas Close
  handleClose() {
    this.props.updateAddDailyTask(false);
  }

  // Search Branch Filter
  handleFilter = (e: any) => {
    const value: string = e.target.value.toLowerCase();
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

    this.setState({
      searchTerm: value,
      timeout: setTimeout(async () => {
        try {
          let query = `search=${value}&sort=&order=&offset=0&limit=10&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=${this.props.filterTaskTypeById}&task_priority=${this.props.filterTaskPriorityById}&due_type=${this.props.filterTaskDueTypeByValue}&task_status=${this.props.filterTaskStatusById}&special_request_task_status=${this.props.filterSpecialRequestTaskStatusById}&status&additional_task_status=1&recommended_task_status=1`;

          let AllListquery = `search=${value}&sort=&order=&offset=0&limit=10000&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=${this.props.filterTaskTypeById}&task_priority=${this.props.filterTaskPriorityById}&due_type=${this.props.filterTaskDueTypeByValue}&task_status=${this.props.filterTaskStatusById}&special_request_task_status=${this.props.filterSpecialRequestTaskStatusById}&status&additional_task_status=1&recommended_task_status=1`;
          await this.props.getAllDailyTasks(query);

          // no limit data
          await this.props.getAllListDailyTasks(AllListquery);
        } catch (error) {
          console.log(error);
        }
        // Trigger your function here that you want to call when user stops typing
      }, 1000), // Set the delay time in ms
    });
  };

  handlePageClick = (e: any) => {
    console.log(e);
    if (e.selected < this.props.total_count / Constants.PER_PAGE) {
      let query = `search=${this.state.searchTerm}&sort=${
        this.state.fieldName
      }&order=${this.state.orderType}&offset=${
        e.selected * Constants.PER_PAGE
      }&limit=${Constants.PER_PAGE}&client=${
        this.props.filterTaskClientById
      }&branch=${this.props.filterTaskBranchById}&task_type=${
        this.props.filterTaskTypeById
      }&task_priority=${this.props.filterTaskPriorityById}&due_type=${
        this.props.filterTaskDueTypeByValue
      }&task_status=${
        this.props.filterTaskStatusById
      }&special_request_task_status=${
        this.props.filterSpecialRequestTaskStatusById
      }&status&start_date=${
        this.props.filterTaskDateTypeValue.from_date
      }&end_date=${this.props.filterTaskDateTypeValue.to_date}&additional_task_status=1&recommended_task_status=1`;
      this.props.getAllDailyTasks(query);
      // this.getTasks(e.selected * Constants.PER_PAGE, Constants.PER_PAGE);
    }
  };
  validationSchema = () => {
    return Yup.object().shape({
      column_name: Yup.string().required("Select Filter Column"),
      order_type: Yup.string().required("Select Sort Order"),
    });
  };
  handleSearch = (formValue: any) => {
    try {
      this.setState({ pagehandle: true });
      setTimeout(() => {
        this.setState({
          fieldName: formValue.column_name,
          orderType: formValue.order_type,
          pagehandle: false,
        });
        const query = `search=${this.state.searchTerm}&sort=${formValue.column_name}&order=${formValue.order_type}&offset=${Constants.OFFSET}&limit=${Constants.PER_PAGE}&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&additional_task_status=1&recommended_task_status=1`;
        this.props.getAllDailyTasks(query);
      }, 0);
    } catch (error) {
      console.log(error);
    }
  };

  filterClientBranch = (data: any) => {
    let arrayId: any = [];
    arrayId.push(data.map((client: any) => client.value));
    let clientIds: string = arrayId.toString();
    if (clientIds !== "" && clientIds !== undefined) {
      this.props.listAllBranch(clientIds);
    }
  };

  render(): React.ReactNode {
    const initialValue = {};
    let taskOrderOption: any = [];
    taskOrderOption = [
      { value: "title", label: "Task Name" },
      { value: "first_name", label: "Client" },
      { value: "branch_name", label: "Branch" },
      { value: "task_date", label: "Date" },
      { value: "any_time", label: "Time" },
      { value: "is_active", label: "Active" },
    ];

    let taskStatusOptionArray: Array<any> = this.props.taskStatusList?.map(
      (status: any) => {
        if (status.id !== 6 && status.id !== 7) {
          return {
            value: status.id,
            label: status.name,
          };
        }
        return null; // or omit it from the result
      }
    );
    taskStatusOptionArray = taskStatusOptionArray?.filter(
      (item) => item !== null
    );

    let taskStatusOption; // Declare taskStatusOption in a higher scope

    if (taskStatusOptionArray !== undefined) {
      taskStatusOption = [
        { value: "all", label: "All" },
        ...taskStatusOptionArray, // Spread the elements of the input array
      ];
    } else {
      taskStatusOption = [{ value: "all", label: "All" }];
    }

    // feedback status
    let feedbackStatusOptionArray: Array<any> = this.props.taskStatusList?.map(
      (status: any) => {
        if (status.id === 6 || status.id === 7) {
          return {
            value: status.id,
            label: status.name,
          };
        }
        return null; // or omit it from the result
      }
    );
    feedbackStatusOptionArray = feedbackStatusOptionArray?.filter(
      (item) => item !== null
    );

    let feedStatusOption; // Declare feedStatusOption in a higher scope

    if (feedbackStatusOptionArray !== undefined) {
      feedStatusOption = [
        { value: "all", label: "All" },
        ...feedbackStatusOptionArray, // Spread the elements of the input array
      ];
    } else {
      feedStatusOption = [{ value: "all", label: "All" }];
    }
    let contractOrder: any = [];
    contractOrder = [
      { value: "asc", label: "Ascending" },
      { value: "desc", label: "Descending" },
    ];

    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className={this.props.status ? "main-panel" : "main-panel2"}>
          <TopNav />
          <div className="content-wrapper">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="mb-0">
                    {this.props.userLoginDetails?.permission?.task_add_task ===
                    1
                      ? "Task"
                      : "Special Request"}
                  </h3>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-end">
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              {/* <div className="row mt-4">
                <div className="col-md-8 col-lg-4">
                  <div className="input-group search">
                    <span
                      className="input-group-text search-icon"
                      id="basic-addon1"
                    >
                      <SearchSVG />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={this.state.searchTerm}
                      onInput={this.handleFilter}
                      onChange={(e) =>
                        this.setState({ searchTerm: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-md-4 col-lg-8 text-end res-mt-3">
                  <ExportExcel
                    excelData={this.props.filteredDailyTaskAllData}
                    fileName={"Task list report"}
                  />
                </div>
              </div> */}
              <div className="row mt-3">
                {/* <div className="col-md-12 col-lg-6">
                  <Formik
                    validationSchema={this.validationSchema}
                    onSubmit={this.handleSearch}
                    initialValues={{
                      column_name: "",
                      order_type: "asc",
                    }}
                  >
                    <Form className="d-flex flex-column flex-md-row">
                      <Field
                        className="custom-select dropdown-field mr-16px res-mt-3 filter-w-180px"
                        name="column_name"
                        options={taskOrderOption}
                        component={CustomSelect}
                        placeholder="Select Column"
                        isMulti={false}
                      />
                      <Field
                        className="custom-select dropdown-field mr-16px res-mt-3 filter-w-180px"
                        name="order_type"
                        options={contractOrder}
                        component={CustomSelect}
                        placeholder="Select Order"
                        isMulti={false}
                      />
                      <button
                        type="submit"
                        className="btn btn-primary filter-button res-mt-3"
                      >
                        Filter
                      </button>
                    </Form>
                  </Formik>
                </div> */}
                <div className="col-md-4 col-lg-4">
                  <div className="input-group search">
                    <span
                      className="input-group-text search-icon"
                      id="basic-addon1"
                    >
                      <SearchSVG />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={this.state.searchTerm}
                      onInput={this.handleFilter}
                      onChange={(e) =>
                        this.setState({ searchTerm: e.target.value })
                      }
                    />
                  </div>
                </div>
                <Offcanvas
                  className="task-offcanvas filter-canvas"
                  placement="end"
                  show={this.state.showFilterOption}
                  onHide={() => this.handleShowFilter(false)}
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                      <span className="offc-title">Filter table</span>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <div className="row mt-4">
                      {this.props.userLoginDetails?.permission
                        ?.task_add_task === 1 ? (
                        <div className="col-md-6">
                          <label className="mb-2">Task Type</label>
                          <Select
                            placeholder="Select Task Type"
                            styles={reactSelectCusStyles}
                            isMulti={false}
                            value={{
                              value: this.props.filterTaskTypeById,
                              label:
                                this.props.filterTaskTypeById === "all"
                                  ? "All"
                                  : this.props.filterTaskTypeById ===
                                    Constants.REGULAR_TASK
                                  ? "Regular"
                                  : "Special Request",
                            }}
                            options={[
                              { value: "all", label: "All" },
                              {
                                value: Constants.REGULAR_TASK,
                                label: "Regular",
                              },
                              {
                                value: Constants.SPECIAL_REQUEST,
                                label: "Special Request",
                              },
                            ]}
                            onChange={(e: any) =>
                              this.props.handleFilterTaskType(e.value)
                            }
                          />
                        </div>
                      ) : null}
                      <div className="col-md-6 res-mt-3">
                        <label className="mb-2">Select Client</label>
                        <Select
                          placeholder="Select Client"
                          styles={reactSelectCusStyles}
                          isMulti={true}
                          value={this.state.selectedFilterClient}
                          options={this.state.clientSelectOption}
                          onChange={(e: any) => {
                            this.props.handleFilterTaskClient(
                              e.map((val: any) => val.value)
                            );
                            this.filterClientBranch(e);
                            this.setState({ selectedFilterClient: e });
                          }}
                        />
                      </div>
                      {/* {Constants.LOGIN_USER_TYPE !== Constants.QAUTINT ? ( */}
                      <div className="col-md-6 res-mt-3">
                        <label className="mb-2">Select Branch</label>
                        <Select
                          placeholder="Select Branch"
                          styles={reactSelectCusStyles}
                          value={this.state.selectedFilterBranch}
                          isMulti={true}
                          options={this.state.branchSelectOption}
                          onChange={(e: any) => {
                            this.props.handleFilterTaskBranch(
                              e.map((val: any) => val.value)
                            );
                            this.setState({ selectedFilterBranch: e });
                          }}
                        />
                      </div>
                      {/* ) : (
                        <></>
                      )} */}

                      <div className="col-md-6 res-mt-3">
                        <label className="mb-2">Task Status</label>
                        <Select
                          placeholder="Select Task Status"
                          styles={reactSelectCusStyles}
                          isMulti={false}
                          // value={{
                          //   value: this.props.filterTaskStatusById,
                          //   label: this.state.filterTaskStatusByIdValue
                          // }}
                          options={taskStatusOption}
                          onChange={(e: any) =>
                            this.props.handleFilterTaskStatus(e.value)
                          }
                        />
                      </div>
                      <div className="col-md-6 res-mt-3">
                        <label className="mb-2">Special Request Status</label>
                        <Select
                          placeholder="Select Task Status"
                          styles={reactSelectCusStyles}
                          isMulti={false}
                          // value={{
                          //   value: this.props.filterSpecialRequestTaskStatusById,
                          //   label: this.state.filterSpecialRequestTaskStatusByIdValue
                          // }}
                          options={feedStatusOption}
                          // value={{
                          //   value:
                          //     this.props.filterSpecialRequestTaskStatusById,
                          //   label:
                          //     this.props.filterSpecialRequestTaskStatusById ===
                          //       Constants.TASK_RESOLVED
                          //       ? "Resolved"
                          //       : this.props
                          //         .filterSpecialRequestTaskStatusById ===
                          //         Constants.TASK_FOLLOWUP
                          //         ? "Followup"
                          //         : "All",
                          // }}
                          // options={[
                          //   { value: "all", label: "All" },
                          //   {
                          //     value: Constants.TASK_RESOLVED,
                          //     label: "Resolved",
                          //   },
                          //   {
                          //     value: Constants.TASK_FOLLOWUP,
                          //     label: "Followup",
                          //   },
                          // ]}
                          onChange={(e: any) =>
                            this.props.handleFilterSpecialTaskStatus(e.value)
                          }
                        />
                      </div>
                      <div className="col-md-6 res-mt-3">
                        <label className="mb-2">Task priority</label>
                        <Select
                          placeholder="Select Task Priority"
                          styles={reactSelectCusStyles}
                          isMulti={false}
                          // value={{
                          //   value: this.props.filterTaskPriorityById,
                          //   label:
                          //     this.props.filterTaskPriorityById ===
                          //       TASK_PRIORITY.LOW
                          //       ? "Low"
                          //       : this.props.filterTaskPriorityById ===
                          //         TASK_PRIORITY.MEDIUM
                          //         ? "Medium"
                          //         : this.props.filterTaskPriorityById ===
                          //           TASK_PRIORITY.HIGH
                          //           ? "High"
                          //           : "All",
                          // }}
                          options={[
                            { value: "all", label: "All" },
                            { value: TASK_PRIORITY.LOW, label: "Low" },
                            { value: TASK_PRIORITY.MEDIUM, label: "Medium" },
                            { value: TASK_PRIORITY.HIGH, label: "High" },
                          ]}
                          onChange={(e: any) =>
                            this.props.handleFilterTaskPriority(e.value)
                          }
                        />
                      </div>
                      <div className="col-md-6 res-mt-3">
                        <label className="mb-2">Due Type</label>
                        <Select
                          placeholder="Select Due type"
                          styles={reactSelectCusStyles}
                          isMulti={false}
                          // value={{
                          //   value: this.props.filterTaskDueTypeByValue,
                          //   label:
                          //     this.props.filterTaskDueTypeByValue === "overdue"
                          //       ? "Over due"
                          //       : this.props.filterTaskDueTypeByValue === "due"
                          //         ? "Due"
                          //         : "All",
                          // }}
                          options={[
                            { value: "", label: "All" },
                            { value: "overdue", label: "Over due" },
                            { value: "due", label: "Due" },
                          ]}
                          onChange={(e: any) =>
                            this.props.handleFilterTaskDueType(e.value)
                          }
                        />
                      </div>
                      <div className="col-md-6 res-mt-3">
                        <label className="mb-2">Date</label>
                        <Select
                          placeholder="Select Date"
                          styles={reactSelectCusStyles}
                          isMulti={false}
                          // value={{
                          //   value: this.props.filterTaskDateTypeValue,
                          //   label:
                          //     this.props.filterTaskDateTypeValue.from_date ===
                          //       moment(new Date()).format("YYYY-MM-DD")
                          //       ? "Next week"
                          //       : this.props.filterTaskDateTypeValue
                          //         .from_date ===
                          //         moment()
                          //           .subtract(1, "weeks")
                          //           .endOf("week")
                          //           .format("YYYY-MM-DD") &&
                          //         this.props.filterTaskDateTypeValue.to_date ===
                          //         moment()
                          //           .add(1, "weeks")
                          //           .endOf("week")
                          //           .format("YYYY-MM-DD")
                          //         ? "Current week"
                          //         : this.props.filterTaskDateTypeValue.to_date ===
                          //           moment(new Date()).format("YYYY-MM-DD")
                          //           ? "Last week"
                          //           : "All",
                          // }}
                          options={[
                            {
                              value: { from_date: "", to_date: "" },
                              label: "All",
                            },
                            {
                              value: {
                                from_date: moment()
                                  .startOf("week")
                                  .subtract(1, "weeks")
                                  .format("YYYY-MM-DD"),
                                to_date: moment()
                                  .startOf("week")
                                  .subtract(1, "weeks")
                                  .add(6, "days")
                                  .format("YYYY-MM-DD"),
                              },
                              label: "Last week",
                            },
                            {
                              value: {
                                from_date: moment()
                                  .startOf("week")
                                  .format("YYYY-MM-DD"),
                                to_date: moment()
                                  .endOf("week")
                                  .format("YYYY-MM-DD"),
                              },
                              label: "Current week",
                            },
                            {
                              value: {
                                from_date: moment()
                                  .startOf("week")
                                  .add(7, "days")
                                  .format("YYYY-MM-DD"),
                                to_date: moment()
                                  .add(1, "weeks")
                                  .endOf("week")
                                  .format("YYYY-MM-DD"),
                              },
                              label: "Next week",
                            },
                          ]}
                          onChange={(e: any) => {
                            this.props.handleFilterTaskDate(e.value);
                          }}
                        />
                      </div>
                      <div
                        className="col-md-6 res-mt-3"
                        onClick={() => this.handleStartDateClick()}
                      >
                        <label className="mb-2">From Date</label>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          id="startDate"
                        >
                          <Stack spacing={3}>
                            <DesktopDatePicker
                              inputFormat="DD/MM/YYYY"
                              value={this.state.start_Date}
                              onChange={(e: any) =>
                                this.handleStartDate(
                                  moment(new Date(e?.toString())).format(
                                    "YYYY-MM-DD"
                                  )
                                )
                              }
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                      <div
                        className="col-md-6 res-mt-3 endDate"
                        onClick={() => this.handleEndDateClick()}
                      >
                        <label className="mb-2">To Date</label>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          id="endDate"
                        >
                          <Stack spacing={3}>
                            <DesktopDatePicker
                              inputFormat="DD/MM/YYYY"
                              value={this.state.to_Date}
                              onChange={(e: any) => {
                                this.handleToDate(
                                  moment(new Date(e?.toString())).format(
                                    "YYYY-MM-DD"
                                  )
                                );
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Offcanvas.Body>
                </Offcanvas>
                <div className="col-md-6 col-lg-6">
                  <div className="d-flex justify-content-start res-mt-3">
                    <div className="mob-res-w-100">
                      <Offcanvas
                        className="task-offcanvas"
                        placement="end"
                        show={this.props.addDailyTaskStatus}
                        onHide={() => this.handleClose()}
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            <span className="offc-title">
                              {this.props.addSpecialRequestTaskStatus === false
                                ? "Add Task"
                                : "Add Special Request"}
                            </span>
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <AddDailyTask
                            onClose={this.handleAddDailyTaskCLose}
                          />
                        </Offcanvas.Body>
                      </Offcanvas>
                      {this.props.userLoginDetails?.permission
                        ?.task_add_task === 1 ? (
                        <Button
                          aria-controls="offcanvasRight"
                          className="btn btn-primary login-button add-daily-task-btn flex-mr-16px mob-res-w-100"
                          onClick={() => this.handleShow()}
                        >
                          Add Daily Task
                        </Button>
                      ) : (
                        ""
                      )}
                      {this.props.userLoginDetails?.permission
                        ?.task_add_special_task === 1 ? (
                        <Button
                          aria-controls="offcanvasRight"
                          className="res-mt-3 btn btn-primary login-button w-170px ml-2 flex-mr-16px mob-res-w-100"
                          onClick={() => this.handleShow("special_request")}
                        >
                          Add Special Request
                        </Button>
                      ) : (
                        ""
                      )}

                      <Button
                        aria-controls="offcanvasRight"
                        className="btn btn-primary filter-button res-mt-3"
                        onClick={() => this.handleShowFilter(true)}
                      >
                        Filter
                      </Button>
                    </div>
                    {/* {(Constants.LOGIN_USER_TYPE === Constants.QAUTINT ||
                      Constants.LOGIN_USER_TYPE === Constants.QA_MANAGERUTINT ||
                      Constants.LOGIN_USER_TYPE === Constants.MANAGERUTINT ||
                      Constants.LOGIN_USER_TYPE ===
                      Constants.SUPERVISOR_MANAGERUTINT ||
                      Constants.LOGIN_USER_TYPE ===
                      Constants.OPERATION_MANAGERUTINT) && (
                        <div>
                          <Offcanvas
                            className="task-offcanvas"
                            placement="end"
                            show={this.props.addDailyTaskStatus}
                            onHide={() => this.handleClose()}
                          >
                            <Offcanvas.Header closeButton>
                              <Offcanvas.Title>
                                <span className="offc-title">
                                  Create Special Request
                                </span>
                              </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                              <AddDailyTask
                                onClose={this.handleAddDailyTaskCLose}
                              />
                            </Offcanvas.Body>
                          </Offcanvas>
                          {this.props.userLoginDetails?.permission?.task_add_special_task === 1 ?(
                          <Button
                            aria-controls="offcanvasRight"
                            className="btn btn-primary login-button w-170px flex-mr-16px"
                            onClick={() => this.handleShow()}
                          >
                            Add Special Request
                          </Button>
                          ):""}
                          <Button
                            aria-controls="offcanvasRight"
                            className="btn btn-primary filter-button res-mt-3"
                            onClick={() => this.handleShowFilter(true)}
                          >
                            Filter
                          </Button>
                        </div>
                      )} */}
                    {this.state.addToast ? (
                      <Toast className="success-alerts">
                        <Toast.Body>
                          <div className="row align-items-center">
                            <div className="col-md-2">
                              <SuccessToastSVG />
                            </div>
                            <div className="col-md-8">
                              {this.props.addDailyTaskSuccess}
                            </div>
                            <div className="col-md-2">
                              <button
                                type="button"
                                className="btn"
                                onClick={this.addToastClose}
                              >
                                <CloseBtnSVG />
                              </button>
                            </div>
                          </div>
                        </Toast.Body>
                      </Toast>
                    ) : (
                      ""
                    )}
                    {this.state.editToast ? (
                      <Toast className="success-alerts">
                        <Toast.Body>
                          <div className="row align-items-center">
                            <div className="col-md-2">
                              <SuccessToastSVG />
                            </div>
                            <div className="col-md-8">
                              {this.props.editDailyTaskSuccess}
                            </div>
                            <div className="col-md-2">
                              <button
                                type="button"
                                className="btn"
                                onClick={this.editToastClose}
                              >
                                <CloseBtnSVG />
                              </button>
                            </div>
                          </div>
                        </Toast.Body>
                      </Toast>
                    ) : (
                      ""
                    )}
                    {this.state.deleteToast ? (
                      <Toast className="success-alerts">
                        <Toast.Body>
                          <div className="row align-items-center">
                            <div className="col-md-2">
                              <SuccessToastSVG />
                            </div>
                            <div className="col-md-8">
                              {this.props.deleteDailyTaskSuccess}
                            </div>
                            <div className="col-md-2">
                              <button
                                type="button"
                                className="btn"
                                onClick={this.deleteToastClose}
                              >
                                <CloseBtnSVG />
                              </button>
                            </div>
                          </div>
                        </Toast.Body>
                      </Toast>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-2 col-lg-2 text-end res-mt-3">
                  <ExportExcel
                    excelData={this.props.filteredDailyTaskAllData}
                    fileName={"Task list report"}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      {this.props.filteredDailyTaskData.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table daily-task-table">
                            <thead>
                              <tr>
                                <th scope="col">NAME</th>
                                <th scope="col">HISTORY</th>
                                {this.props.userLoginDetails?.permission
                                  ?.task_view_cleaner === 1 ? (
                                  <th scope="col">CLEANERS</th>
                                ) : (
                                  ""
                                )}
                                <th scope="col">TYPE</th>
                                <th scope="col">STATUS</th>
                                {this.props.userLoginDetails?.permission
                                  ?.task_add_special_task === 1 ? (
                                  <th scope="col">FEEDBACK STATUS</th>
                                ) : (
                                  ""
                                )}
                                <th scope="col">CLIENT</th>
                                <th scope="col">BRANCH</th>
                                <th scope="col">DATE</th>
                                <th scope="col">TIME</th>
                                <th scope="col">PRIORITY</th>
                                {this.props.userLoginDetails?.permission
                                  ?.task_send_email === 1 ? (
                                  <th scope="col">SEND MAIL</th>
                                ) : (
                                  ""
                                )}
                                {this.props.userLoginDetails?.permission
                                  ?.task_make_schedule === 1 ? (
                                  <th scope="col">SCHEDULE</th>
                                ) : (
                                  ""
                                )}
                                {this.props.userLoginDetails?.permission
                                  ?.task_copy === 1 ? (
                                  <th scope="col">TASK DUPILCATE</th>
                                ) : (
                                  ""
                                )}
                                {this.props.userLoginDetails?.permission
                                  ?.task_assign_reassign_cleaner === 1 ? (
                                  <th scope="col">ASSIGN & REASSIGN</th>
                                ) : (
                                  ""
                                )}
                                <th scope="col">IS ACTIVE</th>
                                <th scope="col">ACTION</th>
                              </tr>
                              {/*                               
                              {Constants.LOGIN_USER_TYPE ===
                                Constants.QAUTINT && (
                                  <tr>
                                    <th scope="col">NAME</th>
                                    <th scope="col">HISTORY</th>
                                    <th scope="col">TYPE</th>
                                    <th scope="col">STATUS</th>
                                    <th scope="col">SPECIAL REQUEST TASK STATUS</th>
                                    <th scope="col">DATE</th>
                                    <th scope="col">TIME</th>
                                    <th scope="col">PRIORITY</th>
                                    <th scope="col">SEND MAIL</th>
                                    <th scope="col">TASK DUPLICATE</th>
                                    <th scope="col">SCHEDULE</th>
                                    <th scope="col">IS ACTIVE</th>
                                    <th scope="col">ACTION</th>
                                  </tr>
                                )}
                              {(Constants.LOGIN_USER_TYPE ===
                                Constants.MANAGERUTINT ||
                                Constants.LOGIN_USER_TYPE ===
                                Constants.QA_MANAGERUTINT) && (
                                  <tr>
                                    <th scope="col">NAME</th>
                                    <th scope="col">HISTORY</th>
                                    <th scope="col">TYPE</th>
                                    <th scope="col">STATUS</th>
                                    <th scope="col">SPECIAL REQUEST STATUS</th>
                                    <th scope="col">BRANCH</th>
                                    <th scope="col">DATE</th>
                                    <th scope="col">TIME</th>
                                    <th scope="col">PRIORITY</th>
                                    <th scope="col">SEND MAIL</th>
                                    <th scope="col">TASK DUPLICATE</th>
                                    <th scope="col">SCHEDULE</th>
                                    <th scope="col">IS ACTIVE</th>
                                    <th scope="col">ACTION</th>
                                  </tr>
                                )}
                              {Constants.LOGIN_USER_TYPE ===
                                Constants.SUPERVISOR_MANAGERUTINT && (
                                  <tr>
                                    <th scope="col">NAME</th>
                                    <th scope="col">HISTORY</th>
                                    <th scope="col">TYPE</th>
                                    <th scope="col">STATUS</th>
                                    <th scope="col">SPECIAL REQUEST STATUS</th>
                                    <th scope="col">CLIENT</th>
                                    <th scope="col">DATE</th>
                                    <th scope="col">TIME</th>
                                    <th scope="col">PRIORITY</th>
                                    <th scope="col">SEND MAIL</th>
                                    <th scope="col">IS ACTIVE</th>
                                    <th scope="col">ACTION</th>
                                  </tr>
                                )}
                              {Constants.LOGIN_USER_TYPE ===
                                Constants.OPERATION_MANAGERUTINT && (
                                  <tr>
                                    <th scope="col">NAME</th>
                                    <th scope="col">HISTORY</th>
                                    <th scope="col">TYPE</th>
                                    <th scope="col">STATUS</th>
                                    <th scope="col">SPECIAL REQUEST STATUS</th>
                                    <th scope="col">CLIENT</th>
                                    <th scope="col">BRANCH</th>
                                    <th scope="col">DATE</th>
                                    <th scope="col">TIME</th>
                                    <th scope="col">PRIORITY</th>
                                    <th scope="col">SEND MAIL</th>
                                    <th scope="col">TASK DUPLICATE</th>
                                    <th scope="col">SCHEDULE</th>
                                    <th scope="col">IS ACTIVE</th>
                                    <th scope="col">ACTION</th>
                                  </tr>
                                )} */}
                            </thead>
                            <DailyTaskTable
                              data={this.props.filteredDailyTaskData}
                              onDelete={() =>
                                this.getTasks(
                                  Constants.OFFSET,
                                  Constants.PER_PAGE
                                )
                              }
                              onEditClose={() =>
                                this.getTasks(
                                  Constants.OFFSET,
                                  Constants.PER_PAGE
                                )
                              }
                              deleteDailyTaskStatus={false}
                            />
                          </table>
                        </div>
                      ) : (
                        <p className="text-center">
                          There is no task data found
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                {this.props.total_count > 10 ? (
                  <div className="col-md-12">
                    {this.state.pagehandle ? null : (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.props.total_count / Constants.PER_PAGE}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        nextClassName={"next-page"}
                        activeClassName={"active"}
                      />
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  addDailyTaskStatus: state.dailytask.addDailyTaskStatus,
  copyDailyTaskStatus: state.dailytask.copyDailyTaskStatus,
  addSpecialRequestTaskStatus: state.dailytask.addSpecialRequestTaskStatus,
  dailyTaskFetchStatus: state.dailytask.dailyTaskFetchStatus,
  dailyTaskData: state.dailytask.dailyTaskData,
  filteredDailyTaskData: state.dailytask.filteredDailyTaskData,
  filteredDailyTaskAllData: state.dailytask.filteredDailyTaskAllData,
  total_count: state.dailytask.total_count,
  addDailyTaskSuccess: state.dailytask.addDailyTaskSuccess,
  editDailyTaskSuccess: state.dailytask.editDailyTaskSuccess,
  deleteDailyTaskSuccess: state.dailytask.deleteDailyTaskSuccess,
  filterTaskTypeById: state.dailytask.filterTaskTypeById,
  filterTaskPriorityById: state.dailytask.filterTaskPriorityById,
  filterTaskStatusById: state.dailytask.filterTaskStatusById,
  filterTaskDueTypeByValue: state.dailytask.filterTaskDueTypeByValue,
  filterTaskDateTypeValue: state.dailytask.filterTaskDateTypeValue,
  filterTaskClientById: state.dailytask.filterTaskClientById,
  filterTaskBranchById: state.dailytask.filterTaskBranchById,
  filterSpecialRequestTaskStatusById:
    state.dailytask.filterSpecialRequestTaskStatusById,
  status: state.sidebar.status,
  clientList: state.tasktemplate.clientList,
  branchList: state.tasktemplate.branchList,
  addCommentStatus: state.app.addCommentStatus,
  userLoginDetails: state.sidebar.userLoginDetails,
  taskStatusList: state.app.taskStatusList,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateAddDailyTask: bindActionCreators(updateAddDailyTask, dispatch),
    updateDailyTaskFetchStatus: bindActionCreators(
      updateDailyTaskFetchStatus,
      dispatch
    ),
    filterDailyTask: bindActionCreators(filterDailyTask, dispatch),
    getAllDailyTasks: bindActionCreators(getAllDailyTasks, dispatch),
    getAllListDailyTasks: bindActionCreators(getAllListDailyTasks, dispatch),
    handleFilterTaskType: bindActionCreators(handleFilterTaskType, dispatch),
    handleFilterTaskPriority: bindActionCreators(
      handleFilterTaskPriority,
      dispatch
    ),
    handleFilterTaskStatus: bindActionCreators(
      handleFilterTaskStatus,
      dispatch
    ),
    handleFilterSpecialTaskStatus: bindActionCreators(
      handleFilterSpecialTaskStatus,
      dispatch
    ),
    handleFilterTaskDueType: bindActionCreators(
      handleFilterTaskDueType,
      dispatch
    ),
    handleFilterTaskDate: bindActionCreators(handleFilterTaskDate, dispatch),
    handleFilterTaskClient: bindActionCreators(
      handleFilterTaskClient,
      dispatch
    ),
    handleFilterTaskBranch: bindActionCreators(
      handleFilterTaskBranch,
      dispatch
    ),
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
    listAllClient: bindActionCreators(listAllClient, dispatch),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
    getLoginUserDetails: bindActionCreators(getLoginUserDetails, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DailyTask);
