import React from "react";
import { RootState } from '../../index';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IClientContractTableProps } from "../../types/contract.type";
import ClientContractCard from "./ClientContractCard";
import EditClientContract from "./EditClientContract";
import { Modal, Offcanvas } from "react-bootstrap";
import { deleteContract, getAllContracts, getContractById, updateEditClientContractDlgStatus, updateViewContract, viewContractById } from "../../shared/Reducers";
import ViewContract from "./ViewClientContract";

class ClientContractTable extends React.Component<IClientContractTableProps> {
    // constructor(props: IClientContractTableProps ){
    //     super(props);
    // }

    handleDeleteContract = async (id: string) => {
        await this.props.deleteContract(id);
        // console.log(id);
        // const query = `search=&sort=&order=&offset=0&limit=100&client=all&branch=all`
        // this.props.getAllContracts(query);
    }

    handleEditBranch = async (id:string) => {
        try {
            this.props.getContractById(id);
        } catch (error) {
            console.log(error);
        }
    }

    handleViewContract = async (id:string) => {
        try {
            this.props.viewContractById(id);
        } catch (error) {
            console.log(error);
        }
    }

    render(): React.ReactNode {
        return (
            <tbody>
                {
                    this.props.data.map((contracts, index) =>
                        <ClientContractCard contracts={contracts}
                            key={index + contracts.start_date}
                            handleDeleteContract={this.handleDeleteContract}
                            handleEditClientContract={this.handleEditBranch}
                            handleViewContract={this.handleViewContract}
                            deleteContractStatus={false} />)
                }
                <Offcanvas placement="end" show={this.props.editContractStatus} onHide={() => this.props.updateEditClientContractDlgStatus(false)}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title><span className="offc-title">Edit Contract</span></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <EditClientContract id={this.props.id} data={this.props.selectedContractData} />
                    </Offcanvas.Body>
                </Offcanvas>
                <Modal
                size="lg"
                show={this.props.viewContractStatus}
                onHide={() => this.props.updateViewContract(false)}
                aria-labelledby="example-modal-sizes-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            <h3 className="m-0">User Details</h3>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                        <ViewContract data={this.props.viewContractData}/>
                    </Modal.Body>
                </Modal>
            </tbody>
        )
    }
}
const mapStateToProps = (state: RootState) => ({
    editContractStatus: state.contract.editContractStatus,
    selectedContractData: state.contract.selectedContractData,
    id: state.contract.id,
    viewContractStatus: state.contract.viewContractStatus,
    viewContractData: state.contract.viewContractData
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateEditClientContractDlgStatus: bindActionCreators(updateEditClientContractDlgStatus, dispatch),
        getContractById: bindActionCreators(getContractById, dispatch),
        getAllContracts: bindActionCreators(getAllContracts, dispatch),
        deleteContract: bindActionCreators(deleteContract, dispatch),
        viewContractById: bindActionCreators(viewContractById, dispatch),
        updateViewContract: bindActionCreators (updateViewContract, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClientContractTable);