import { Dispatch, AnyAction } from "redux";
import { AppDispatch } from "../..";
import { TClientContractTable } from "../../types/user.type";
import {
  API_GET_ALL_CLIENTS,
  API_GET_ALL_CLIENTS_SUCCESS,
  API_GET_ALL_CLIENTS_FAIL,
  API_GET_ALL_BRANCH_FAIL,
  ContractTemplateState,
  API_GET_ALL_CONTRACTS,
  API_GET_ALL_CONTRACTS_SUCCESS,
  API_GET_ALL_CONTRACTS_FAIL,
  API_GET_CONTRACT_BY_ID,
  API_GET_CONTRACT_BY_ID_SUCCESS,
  API_GET_CONTRACT_BY_ID_FAIL,
  API_ADD_CONTRACT,
  API_ADD_CONTRACT_SUCCESS,
  API_ADD_CONTRACT_FAIL,
  API_EDIT_CONTRACT,
  API_EDIT_CONTRACT_SUCCESS,
  API_EDIT_CONTRACT_FAIL,
  API_DELETE_CONTRACT,
  API_DELETE_CONTRACT_SUCCESS,
  API_DELETE_CONTRACT_FAIL,
  UPDATE_ADD_CONTRACT_DLG_STATUS,
  UPDATE_EDIT_CONTRACT_DLG_STATUS,
  UPDATE_DELETE_CONTRACT_DLG_STATUS,
  UPDATE_CONTRACT_FETCH_STATUS,
  FILTER_CONTRACT,
  UPDATE_CONTRACTS,
  IEditClientContract,
  API_ADD_CONTRACT_PROGRESS,
  API_EDIT_CONTRACT_PROGRESS,
  API_DELETE_ATTACHMENT,
  API_VIEW_CONTRACT_BY_ID,
  API_VIEW_CONTRACT_BY_ID_FAIL,
  API_VIEW_CONTRACT_BY_ID_SUCCESS,
  UPDATE_VIEW_CONTRACT,
  CONTRACT_SORT
} from "../ActionTypes";

const initialState: ContractTemplateState = {
  cleanerList: [],
  clientList: [],
  addContractStatus: false,
  addContractSuccess: "",
  deleteContractStatus: false,
  deleteContractSuccess: "",
  editContractStatus: false,
  editContractSuccess: "",
  contractFetchStatus: false,
  selectedContractData: null,
  contractData: [],
  filteredContractData: [],
  id: "",
  total_count: 0,
  addContractError: "",
  deleteContractError: "",
  editContractError: "",
  processTrigger: false,
  progressPercentage: 0,
  viewContractData: null,
  viewContractStatus: false,
  contractSortBy: "",
  contractOrderBy: "",
};

export const ContractReducer = (
  state: ContractTemplateState = initialState,
  action: AnyAction
): ContractTemplateState => {
  switch (action.type) {
    case UPDATE_CONTRACTS:
      return { ...state };
    case API_GET_ALL_CLIENTS:
      return { ...state };
    case API_GET_ALL_CLIENTS_SUCCESS:
      let clientData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "clients" in data && data.clients) {
          clientData = data.clients;
        }
      }
      return { ...state, clientList: clientData };
    case API_GET_ALL_CLIENTS_FAIL:
      return { ...state };
    case API_GET_ALL_BRANCH_FAIL:
      return { ...state };
    case UPDATE_CONTRACT_FETCH_STATUS:
      return { ...state, contractFetchStatus: action.payload };
    case API_GET_ALL_CONTRACTS:
      return { ...state };
    case API_GET_ALL_CONTRACTS_SUCCESS:
      let contractData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "contracts" in data && data.contracts) {
          contractData = data.contracts;
        }
      }
      let total_count = 0;
      if (action.payload) {
        const { data } = action.payload;
        if (data && "total_count" in data && data.total_count) {
          total_count = data.total_count;
        }
      }
      return {
        ...state,
        total_count: total_count,
        editContractStatus: false,
        addContractStatus: false,
        deleteContractStatus: false,
        filteredContractData: contractData,
      };
    case API_GET_ALL_CONTRACTS_FAIL:
      return { ...state };
    case API_GET_CONTRACT_BY_ID:
      return { ...state, id: action.payload.id };
    case API_GET_CONTRACT_BY_ID_SUCCESS:
      let contracts = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "contract" in data && data.contract) {
          contracts = data.contract;
        }
      }
      return {
        ...state,
        selectedContractData: contracts,
        editContractStatus: true,
        contractFetchStatus: false,
      };
    case API_GET_CONTRACT_BY_ID_FAIL:
      return { ...state };
    case API_VIEW_CONTRACT_BY_ID:
      return { ...state, id: action.payload.id };
    case API_VIEW_CONTRACT_BY_ID_SUCCESS:
      let contract = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "contract" in data && data.contract) {
          contract = data.contract;
        }
      }
      return {
        ...state,
        viewContractData: contract,
        viewContractStatus: true,
        contractFetchStatus: false,
      };
    case API_VIEW_CONTRACT_BY_ID_FAIL:
      return { ...state };
    case UPDATE_VIEW_CONTRACT:
      return { ...state, viewContractStatus: action.payload };
    case API_ADD_CONTRACT:
      let values: boolean = false;
      if (action.payload.request.data.FormData === undefined) {
        values = true;
      }
      return {
        ...state,
        addContractSuccess: "",
        addContractError: "",
        processTrigger: values,
      };
    case API_ADD_CONTRACT_SUCCESS:
      let message = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          message = data.message;
        }
      }
      return {
        ...state,
        addContractStatus: false,
        addContractSuccess: message,
        processTrigger: false,
        progressPercentage: 0,
      };
    case API_ADD_CONTRACT_FAIL:
      let err = "";
      if (
        "error" in action &&
        action.error &&
        "response" in action.error &&
        action.error.response &&
        "data" in action.error.response &&
        action.error.response.data &&
        "message" in action.error.response.data
      ) {
        err = action.error.response.data.message;
      }
      return { ...state, addContractError: err };
    case API_ADD_CONTRACT_PROGRESS:
      return { ...state, progressPercentage: action.payload };
    case API_EDIT_CONTRACT_PROGRESS:
      return { ...state, progressPercentage: action.payload };
    case UPDATE_ADD_CONTRACT_DLG_STATUS:
      return { ...state, addContractStatus: action.payload };
    case API_EDIT_CONTRACT:
      let value: boolean = false;
      if (action.payload.request.data.FormData === undefined) {
        value = true;
      }
      return { ...state, editContractSuccess: "", processTrigger: value };
    case API_EDIT_CONTRACT_SUCCESS:
      let messages = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          messages = data.message;
        }
      }
      return {
        ...state,
        editContractStatus: false,
        contractFetchStatus: true,
        editContractSuccess: messages,
        processTrigger: false,
        progressPercentage: 0,
      };
    case API_EDIT_CONTRACT_FAIL:
      let error = "";
      if (
        "error" in action &&
        action.error &&
        "response" in action.error &&
        action.error.response &&
        "data" in action.error.response &&
        action.error.response.data &&
        "message" in action.error.response.data
      ) {
        error = action.error.response.data.message;
      }
      return { ...state, editContractError: error };
    case UPDATE_EDIT_CONTRACT_DLG_STATUS:
      return { ...state, editContractStatus: action.payload };
    case API_DELETE_CONTRACT:
      return { ...state, deleteContractSuccess: "" };
    case API_DELETE_CONTRACT_SUCCESS:
      let msg = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          msg = data.message;
        }
      }
      return {
        ...state,
        deleteContractStatus: false,
        contractFetchStatus: true,
        deleteContractSuccess: msg,
      };
    case API_DELETE_CONTRACT_FAIL:
      return { ...state };
    case UPDATE_DELETE_CONTRACT_DLG_STATUS:
      return { ...state, deleteContractStatus: action.payload };
    case FILTER_CONTRACT:
      console.log(action.payload, state.contractData);

      const filterContract: TClientContractTable[] = state.filteredContractData.filter(
        (obj) => {
          console.log(action.payload);

          console.log(obj.first_name.toLowerCase().includes(action.payload));
          return (
            obj.first_name.toLowerCase().includes(action.payload)
          );
        }
      );
      return { ...state, filteredContractData: filterContract };
    case CONTRACT_SORT:
      return { ...state, contractSortBy: action.payload.sort, contractOrderBy: action.payload.order };
    default:
      return state;
  }
};
// update contract
export function updateContracts(data: any) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_CONTRACTS,
      payload: data,
    });
  };
}

// get contract by id
export function getContractById(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_CONTRACT_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `client-contract/${id}` : `client-contract`,
        },
        id,
      },
    });
  };
}

// VIEW contract by id
export function viewContractById(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_VIEW_CONTRACT_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `client-contract/${id}` : `client-contract`,
        },
        id,
      },
    });
  };
}

export function updateViewContract(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_VIEW_CONTRACT,
      payload: status,
    });
  };
}


// get all contracts for table
export function getAllContracts(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ALL_CONTRACTS,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `client-contract?${queryStr}` : `client-contract?`,
        },
      },
    });
  };
}

// add contract
export function addContract(payload: FormData) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_ADD_CONTRACT,
      payload: {
        request: {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            contentType: false,
          },
          method: "post",
          url: `client-contract`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_ADD_CONTRACT_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

export function updateAddClientContract(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_ADD_CONTRACT_DLG_STATUS,
      payload: status,
    });
  };
}

// edit contract
export function editContract(payload: IEditClientContract, id: number) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_EDIT_CONTRACT,
      payload: {
        request: {
          method: "put",
          url: `client-contract/${id}`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_EDIT_CONTRACT_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

// update edit employee
export function updateEditClientContractDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_EDIT_CONTRACT_DLG_STATUS,
      payload: status,
    });
  };
}

// Fetch Contract after add, edit, delete
export function updateContractFetchStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_CONTRACT_FETCH_STATUS,
      payload: status,
    });
  };
}

// delete contract
export function deleteContract(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_DELETE_CONTRACT,
      payload: {
        request: {
          method: "delete",
          url: `client-contract/${id}`,
        },
      },
    });
  };
}

// update delete contract
export function updateDeleteContractDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_DELETE_CONTRACT_DLG_STATUS,
      payload: status,
    });
  };
}

// Filter value
export function filterContract(filterVal: string) {
  console.log(filterVal)
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_CONTRACT,
      payload: filterVal,
    });
  };
}

// Filter value
export function deleteAttachementId(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_DELETE_ATTACHMENT,
      payload: {
        request: {
          method: "delete",
          url: `attachment/${id}`,
        },
      },
    });
  };
}

// handleContractSort
export function handleContractSort(sort: any, order: any) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CONTRACT_SORT,
      payload: {
        sort: sort,
        order: order
      },
    });
  };
}
