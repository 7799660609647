import { DisableSVG, EnableSVG } from "../../assets/images/UsermanagementsSVG";
import { Dispatch, bindActionCreators } from "redux";
import QRCode, { toDataURL } from "qrcode";
import { listAllBranch, listAllClient } from "../../shared/Reducers";

import AttachmentPreview from "../../shared/Components/AttachmentPreview";
import Constants from "../../shared/Constants";
import DisplayQR from "./DisplayQR";
import { Modal } from "react-bootstrap";
import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";
import docImage from "../../assets/images/google-docs.png";
export interface IViewBanchProps {
  handleViewClose: any;
  displayView: any;
  listAllClient: any;
  listAllBranch: any;
  id: any;
  data: any;
  viewData: any;
  handleView: any;
}

export interface IViewBranchState {
  previewType: string;
  previewUrl: string;
  AttachmentPreviewStatus: boolean;
  show: boolean;
  qrImg: string;
  imgURL: string;
  attachmentUrls: any;
}

class ViewBranch extends React.Component<IViewBanchProps, IViewBranchState> {
  constructor(props: IViewBanchProps) {
    super(props);
    this.state = {
      show: true,
      AttachmentPreviewStatus: false,
      previewUrl: " ",
      previewType: " ",
      qrImg: "",
      imgURL: "",
      attachmentUrls: [],
    };
  }

  componentDidMount = () => {
    if (this.props.viewData) {
      this.handleAttachments(this.props.viewData.attachments);
    }
  };

  handleAttachments = async (attachments: any) => {
    const attachmentUrls = [];

    for (const obj of attachments) {
      const url = obj.s3_url;
      const download_url = obj.s3_url;

      attachmentUrls.push({
        url: url,
        name: obj.file_name,
        id: obj.id,
        type: "download",
        download_url: download_url,
        file_type: obj.type,
      });
    }
console.log(attachmentUrls);

    this.setState(
      {
        attachmentUrls: attachmentUrls,
      },
      async () => {
        await this.generateQR();
      }
    );
  };

  generateQR = async () => {
    const { lat, lng, name, id, client_id, attachments } = this.props.viewData;
    try {
      const qrData: any = { lat, lng, name, id, client_id };
      const dataURL = await toDataURL(JSON.stringify(qrData));
      //console.log("image",dataURL);
    } catch (err) {
      console.log(err);
    }
  };

  componentDidUpdate(
    prevProps: Readonly<IViewBanchProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (prevProps.viewData !== this.props.viewData) {
      if (this.props.viewData) {
        this.handleAttachments(this.props.viewData.attachments);
      }
    }
  }

  handlePreviewOpen = (file_type: any, url: any) => {
    if (
      file_type === "image/png" ||
      file_type === "image/jpeg" ||
      file_type === "image/jpg" ||
      file_type === "video/mp4" ||
      file_type === "video/webm" ||
      file_type === "application/pdf"
    ) {
      this.setState({
        AttachmentPreviewStatus: true,
        previewUrl: url,
        previewType: file_type,
      });
      console.log({
        AttachmentPreviewStatus: true,
        previewUrl: url,
        previewType: file_type,
      });
    }
  };

  handlePreviewClose = () => {
    this.setState({ AttachmentPreviewStatus: false });
  };

  handleClose = () => {
    this.setState({ show: false, qrImg: "" }, () => {});
  };

  render(): React.ReactNode {
    if (this.props.viewData) {
      return (
        <div>
          <Modal
            size="lg"
            dialogClassName="modal-90w"
            aria-labelledby="contained-modal-title-vcenter"
            show={this.props.displayView}
            onHide={() => this.props.handleViewClose(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                <h3 className="m-0">Branch Details</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
              <div className="row">
                <div className="col-6">
                  <p className="mb-1">Client</p>
                  <p className="fw-600">
                    {this.props.viewData.first_name +
                      " " +
                      this.props.viewData.last_name}
                  </p>
                </div>
                <div className="col-6">
                  <p className="mb-1">Branch</p>
                  <p className="fw-600">{this.props.viewData.name}</p>
                </div>
                <div className="col-6">
                  <p className="mb-1">Address</p>
                  <p className="fw-600">{this.props.viewData.address}</p>
                </div>
                <div className="col-6">
                  <p className="mb-1">Is Active</p>

                  <p className="fw-600">
                    {" "}
                    {this.props.viewData.status !== Constants.IS_ACTIVE && (
                      <DisableSVG />
                    )}
                    {this.props.viewData.status === Constants.IS_ACTIVE && (
                      <EnableSVG />
                    )}
                  </p>
                </div>
                <div className="col-6">
                  <p className="mb-1">Latitude</p>
                  <p className="fw-600">{this.props.viewData.lat}</p>
                </div>
                <div className="col-6">
                  <p className="mb-1">Longitude</p>
                  <p className="fw-600">{this.props.viewData.lng}</p>
                </div>
                {this.props.viewData.image !== "" ? (
                  <div className="col-6">
                    <p className="mb-1">Attachments</p>
                    <div className="row">
                      {this.state.attachmentUrls &&
                        this.state.attachmentUrls.map(
                          (obj: any, index: number) => (
                            <div className="img-card mr-16px mb-3">
                              {obj.type === "download" && (
                                <span className="delete"></span>
                              )}

                              <div
                                className="img-preview"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.handlePreviewOpen(obj.file_type, obj.url)
                                }
                              >
                                <img
                                  className="img-thumb-perview"
                                  src={
                                    obj.file_type === "application/pdf"
                                      ? pdfImage
                                      : obj.file_type === "video/mp4" ||
                                        obj.file_type === "video/webm"
                                      ? videoImage
                                      : obj.file_type === "image/png" ||
                                        obj.file_type === "image/jpeg" ||
                                        obj.file_type === "image/jpg"
                                      ? obj.url
                                      : docImage
                                  }
                                  alt="attach"
                                />
                              </div>
                            </div>
                          )
                        )}
                      <AttachmentPreview
                        displayPreview={this.state.AttachmentPreviewStatus}
                        previewUrl={this.state.previewUrl}
                        previewType={this.state.previewType}
                        handlePreviewClose={this.handlePreviewClose}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </Modal.Body>
          </Modal>
          <AttachmentPreview
            displayPreview={this.state.AttachmentPreviewStatus}
            previewUrl={this.state.previewUrl}
            previewType={this.state.previewType}
            handlePreviewClose={this.handlePreviewClose}
          />
        </div>
      );
    } else {
      return null;
    }
  }
}
const mapStateToProps = (state: RootState) => ({
  clientList: state.tasktemplate.clientList,
  branchList: state.tasktemplate.branchList,
  viewData: state.branch.viewData,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    listAllClient: bindActionCreators(listAllClient, dispatch),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewBranch);
