import {
  API_GET_ALL_CLEANER_NOTIFICATION,
  API_GET_ALL_CLEANER_NOTIFICATION_FAIL,
  API_GET_ALL_CLEANER_NOTIFICATION_SUCCESS,
  API_GET_ALL_CLEANER_INVOICE_NOTIFICATION,
  API_GET_ALL_CLEANER_INVOICE_NOTIFICATION_FAIL,
  API_GET_ALL_CLEANER_INVOICE_NOTIFICATION_SUCCESS,
  API_GET_CLEANER_BRANCH_LOGOUT_ACTIVITY,
  API_GET_CLEANER_BRANCH_LOGOUT_ACTIVITY_FAIL,
  API_GET_CLEANER_BRANCH_LOGOUT_ACTIVITY_SUCCESS,
  API_GET_ALL_DELETE_NOTIFICATION,
  API_GET_ALL_DELETE_NOTIFICATION_SUCCESS,
  API_GET_ALL_TASK_NOTIFICATION,
  API_GET_ALL_TASK_NOTIFICATION_FAIL,
  API_GET_ALL_TASK_NOTIFICATION_SUCCESS,
  API_APPROVE_CLEANER_LOGIN,
  API_APPROVE_CLEANER_LOGIN_FAIL,
  API_APPROVE_CLEANER_LOGIN_SUCCESS,
  API_GET_CLEANER_INVOICE_BY_ID,
  API_GET_CLEANER_INVOICE_BY_ID_FAIL,
  API_GET_CLEANER_INVOICE_BY_ID_SUCCESS,
  FILTER_NOTIFICATION,
  NotificationState,
  UPDATE_NOTIFICATION_FETCH_STATUS,
  API_GET_ALL_CONTRACT_EXPIRY,
  API_GET_ALL_CONTRACT_EXPIRY_SUCCESS,
  API_GET_ALL_CONTRACT_EXPIRY_FAIL,
  API_GET_ALL_DELETE_CONTRACT_NOTIFICATION,
  API_GET_ALL_DELETE_CONTRACT_NOTIFICATION_SUCCESS,
  API_GET_ALL_DELETE_CONTRACT_NOTIFICATION_FAIL
} from "../ActionTypes/NotificationActionTypes";
import { AnyAction, Dispatch } from "redux";

import { TNotificationContent } from "../../types/notification.type";

const initialState: NotificationState = {
  cleanerData: [],
  taskData: [],
  cleaner_total_count: 0,
  task_total_count: 0,
  filteredNotification: [],
  delete_notification: "",
  deletenotificationsuccess: "",
  deletenotificationstatus: false,
  cleanerLogoutActivityData : [],
  cleanerLogoutActivityTotalCount : 0,
  approveCleanerLoginStatus : false,
  cleanerInvoiceDatas: [],
  cleaner_invoice_total_count: 0,
  cleanerInvoiceLists: [],
  contractExpiryNotifyList: []
};

export const NotificationReducer = (
  state: NotificationState = initialState,
  action: AnyAction
): NotificationState => {
  switch (action.type) {
    case API_GET_ALL_CLEANER_NOTIFICATION:
      return { ...state };
    case API_GET_ALL_CLEANER_NOTIFICATION_SUCCESS:
      let cleanerDatas = [];
      if (action.payload) {
        const { data } = action.payload;
        if (
          data &&
          "cleaner_notification" in data &&
          data.cleaner_notification
        ) {
          cleanerDatas = data.cleaner_notification;
        }
      }
      let cleanerCount = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "total_count" in data && data.total_count) {
          cleanerCount = data.total_count;
        }
      }
      return {
        ...state,
        cleanerData: cleanerDatas,
        cleaner_total_count: cleanerCount,
      };
    case API_GET_ALL_CLEANER_NOTIFICATION_FAIL:
      return { ...state };
    case API_GET_CLEANER_BRANCH_LOGOUT_ACTIVITY:
      return { ...state };
    case API_GET_CLEANER_BRANCH_LOGOUT_ACTIVITY_SUCCESS:
      let activityDatas = [];
      if (action.payload) {
        const { data } = action.payload;
        if (
          data &&
          "activity" in data &&
          data.activity
        ) {
          activityDatas = data.activity;
        }
      }
      let activityCount = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "total_count" in data && data.total_count) {
          activityCount = data.total_count;
        }
      }
      return {
        ...state,
        cleanerLogoutActivityData: activityDatas,
        cleanerLogoutActivityTotalCount: activityCount,
      };
    case API_GET_CLEANER_BRANCH_LOGOUT_ACTIVITY_FAIL:
      return { ...state };
    case API_GET_ALL_TASK_NOTIFICATION:
      return { ...state };
    case API_GET_ALL_TASK_NOTIFICATION_SUCCESS:
      let taskDatas = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task" in data && data.task) {
          taskDatas = data.task;
        }
      }
      let taskCount = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "total_count" in data && data.total_count) {
          taskCount = data.total_count;
        }
      }
      return {
        ...state,
        taskData: taskDatas,
        task_total_count: taskCount,
        filteredNotification: taskDatas,
      };
    case API_GET_ALL_TASK_NOTIFICATION_FAIL:
      return { ...state };
    case API_APPROVE_CLEANER_LOGIN:
      return { ...state, approveCleanerLoginStatus: false };
    case API_APPROVE_CLEANER_LOGIN_SUCCESS:
      return {
        ...state,
        approveCleanerLoginStatus: true,
      };
    case API_APPROVE_CLEANER_LOGIN_FAIL:
      return { ...state };
    case UPDATE_NOTIFICATION_FETCH_STATUS:
      return { ...state };
    case FILTER_NOTIFICATION:
      const filterNotication: TNotificationContent[] = state.cleanerData.filter(
        (obj: any) => {
          console.log(action.payload);

          console.log(obj.first_name.toLowerCase().includes(action.payload));
          return (
            obj.first_name.toLowerCase().includes(action.payload) ||
            obj.branch_name.toLowerCase().includes(action.payload) ||
            obj.title.toLowerCase().includes(action.payload)
          );
        }
      );
      return { ...state, filteredNotification: filterNotication };

    case API_GET_ALL_DELETE_NOTIFICATION:
      return { ...state, deletenotificationsuccess: "" };
    case API_GET_ALL_DELETE_NOTIFICATION_SUCCESS:
      let msg = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          msg = data.message;
        }
        return {
          ...state,
          delete_notification: data,
          deletenotificationsuccess: msg,
          deletenotificationstatus: false,
        };
      }
      return state;
      case API_GET_ALL_CLEANER_INVOICE_NOTIFICATION:
        return { ...state };
      case API_GET_ALL_CLEANER_INVOICE_NOTIFICATION_SUCCESS:
        let cleanerInvoiceDatas = [];
        if (action.payload) {
          const { data } = action.payload;
          if (
            data &&
            "cleaners_invoice" in data &&
            data.cleaners_invoice
          ) {
            cleanerInvoiceDatas = data.cleaners_invoice;
          }
        }
        let cleanerInvoiceCount = [];
        if (action.payload) {
          const { data } = action.payload;
          if (data && "total_count" in data && data.total_count) {
            cleanerInvoiceCount = data.total_count;
          }
        }
        return {
          ...state,
          cleanerInvoiceDatas: cleanerInvoiceDatas,
          cleaner_invoice_total_count: cleanerInvoiceCount,
        };
      case API_GET_ALL_CLEANER_INVOICE_NOTIFICATION_FAIL:
        return { ...state };
      case API_GET_CLEANER_INVOICE_BY_ID:
        return { ...state };
      case API_GET_CLEANER_INVOICE_BY_ID_SUCCESS:
        let cleanerInvoiceLists = [];
        if (action.payload) {
          const { data } = action.payload;
          if (
            data &&
            "invoice" in data &&
            data.invoice
          ) {
            cleanerInvoiceLists = data.invoice;
          }
        }
        return {
          ...state,
          cleanerInvoiceLists: cleanerInvoiceLists,
        };
      case API_GET_CLEANER_INVOICE_BY_ID_FAIL:
        return { ...state };
      case API_GET_ALL_CONTRACT_EXPIRY:
          return { ...state };
      case API_GET_ALL_CONTRACT_EXPIRY_SUCCESS:
        
          let contractExpiryList:any = [];
          if (action.payload) {
            const { data } = action.payload;
            if (
              data &&
              "task" in data &&
              data.task
            ) {
              contractExpiryList = data.task.expiryAlerts;
            }
          }
          
          return {
            ...state,
            contractExpiryNotifyList: contractExpiryList,
          };
            // return { ...state, contractExpiryNotifyList: action.payload };
      case API_GET_ALL_CONTRACT_EXPIRY_FAIL:
            return { ...state };
    default:
      return state;
  }
};

export function allCleanerNotification(query: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ALL_CLEANER_NOTIFICATION,
      payload: {
        request: {
          method: "get",
          url: query ? `activity/${query}` : `activity`,
        },
      },
    });
  };
}
export function allCleanerInvoiceNotification(query: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ALL_CLEANER_INVOICE_NOTIFICATION,
      payload: {
        request: {
          method: "get",
          url: query ? `invoice/get-all-cleaner-invoice-notification?${query}` : `invoice/get-all-cleaner-invoice-notification`,
        },
      },
    });
  };
}

export function allContractDocumentExpiry() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ALL_CONTRACT_EXPIRY,
      payload: {
        request: {
          method: "get",
          url: 'expiry-contract',
        },
      },
    });
  };
}

export function deleteContractExpiry(id: any) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ALL_DELETE_CONTRACT_NOTIFICATION,
      payload: {
        request: {
          method: "DELETE",
          url: `expiry-contract/${id}`
        },
      },
    });
  };
}

export function getCleanerBranchLogoutActivity() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_CLEANER_BRANCH_LOGOUT_ACTIVITY,
      payload: {
        request: {
          method: "get",
          url: `users/cleaner-branch-logout-activity`,
        },
      },
    });
  };
}

export function allTaskNotification(query: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ALL_TASK_NOTIFICATION,
      payload: {
        request: {
          method: "get",
          url: query ? `app-comments/${query}` : `app-comments`,
        },
      },
    });
  };
}


export function cleanerInvoiceDetails(cleaner_id:number) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_CLEANER_INVOICE_BY_ID,
      payload: {
        request: {
          method: "get",
          url: `invoice/get-cleaner-invoice-form-by-id/${cleaner_id}`,
        },
      },
    });
  };
}

export function cleanerApproveForLogin(cleaner_id:number) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: API_APPROVE_CLEANER_LOGIN,
      payload: {
        request: {
          method: "get",
          url: `activity/cleaner-approve-branch-login/${cleaner_id}`,
        },
      },
    });
  };
}

export function searchNotification(filterVal: string) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_NOTIFICATION,
      payload: filterVal,
    });
  };
}

export function deleteNotification(query: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ALL_DELETE_NOTIFICATION,
      payload: {
        request: {
          method: "DELETE",
          url: query
            ? `comments/delete-notification/${query}`
            : `comments/delete-notification`,
        },
      },
    });
  };
}
