import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AttachmentDownloadSVG } from "../../assets/images/ImagesSVG";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";
import docImage from "../../assets/images/google-docs.png";
import Constants from "../../shared/Constants";
import { Modal } from "react-bootstrap";
import { DisableSVG, EnableSVG } from "../../assets/images/ImagesV2";
import moment from "moment";

class ViewShift extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      attachments: "",
      attachmentUrls: [],
    };
  }

  componentDidMount(): void {
    if (this.props.data) {
      console.log("Shift", this.props.data);
    }
  }

  downloadFile = (url: string, name = "image.png") => {
    this.downloadResource(url, name);
  };

  forceDownload = (blob: any, filename: String) => {
    const a: any = document.createElement("a");
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  // Current blob size limit is around 500MB for browsers
  downloadResource = (url: any, filename: any) => {
    if (url) {
      if (!filename) filename = url.split("\\").pop().split("/").pop();
      fetch(url, {
        headers: new Headers({
          Origin: window.location.origin,
        }),
        mode: "cors",
      })
        .then((response) => response.blob())
        .then((blob) => {
          let blobUrl = window.URL.createObjectURL(blob);
          this.forceDownload(blobUrl, filename);
        })
        .catch((e) => console.error(e));
    }
  };
  render(): React.ReactNode {
    const {
      name,
      start_time,
      end_time,
      end_date,
      start_date,
      status,
      cleaners,
    } = this.props.data;
    return (
      <Modal
        size="lg"
        show={this.props.displayView}
        onHide={() => this.props.handleViewClose(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <h3 className="m-0">Task Details</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="row">
            <div className="col-md-6">
              <p className="mb-1">Name</p>
              <p className="fw-600">{name}</p>
            </div>
            <div className="col-md-6">
              <p className="mb-1">Status</p>
              <p className="fw-600">
                {status === 1 ? <EnableSVG /> : <DisableSVG />}
              </p>
            </div>
            <div className="col-md-6">
              <p className="mb-1">Start date</p>
              <p className="fw-600">
                {moment(start_date?.toString()).format("DD/MM/YYYY")}
              </p>
            </div>
            <div className="col-md-6">
              <p className="mb-1">End date</p>
              <p className="fw-600">
                {moment(end_date?.toString()).format("DD/MM/YYYY")}
              </p>
            </div>
            <div className="col-md-6">
              <p className="mb-1">Start Time</p>
              <p className="fw-600">
                {moment(start_time, "HH:mm:ss").format("hh:mm A")}
              </p>
            </div>
            <div className="col-md-6">
              <p className="mb-1">End Time</p>
              <p className="fw-600">
                {moment(end_time, "HH:mm:ss").format("hh:mm A")}
              </p>
            </div>
            <div className="col-6 row">
              <p className="mb-1">Cleaner List</p>
              <p className="fw-600"></p>
              {cleaners.length > 0
                ? cleaners.map((value: any, index: number) => {
                    return (
                      <p className="fw-600">
                        {index +
                          1 +
                          ". " +
                          value.first_name +
                          " " +
                          value.last_name}
                      </p>
                    );
                  })
                : null}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewShift);
