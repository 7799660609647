import { Dispatch, AnyAction } from "redux";
import {
  GET_CLEANER_LAST_ACTIVITY,
  GET_CLEANER_LAST_ACTIVITY_SUCCESS,
  GET_CLEANER_LAST_ACTIVITY_FAIL,
  CLEANER_LOGIN_LOGS,
  CLEANER_LOGIN_LOGS_SUCCESS,
  CLEANER_LOGIN_LOGS_FAIL,
  CleanerInOutState
} from "../ActionTypes";

const initialState: CleanerInOutState = {
  getLastActivityData : [],
  cleanerLoginLogs : []
};

export const CleanerInOutReducer = (
  state: CleanerInOutState = initialState,
  action: AnyAction
): CleanerInOutState => {
  switch (action.type) {
    case GET_CLEANER_LAST_ACTIVITY:
      return { ...state };
    case GET_CLEANER_LAST_ACTIVITY_SUCCESS:
      let getLastActivityData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "last_activity" in data && data.last_activity) {
          getLastActivityData = data.last_activity;
        }
      }
      return {
        ...state,
        getLastActivityData: getLastActivityData,
      };
    case GET_CLEANER_LAST_ACTIVITY_FAIL:
      return { ...state };
  
    case CLEANER_LOGIN_LOGS:
      return { ...state };
    case CLEANER_LOGIN_LOGS_SUCCESS:
      let message = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          message = data.message;
        }
      }
      return {
        ...state,
        cleanerLoginLogs: message,
      };
    case CLEANER_LOGIN_LOGS_FAIL:
      return { ...state };
    default:
      return state;
  }
};

export function getCleanerLastActivity() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: GET_CLEANER_LAST_ACTIVITY,
      payload: {
        request: {
          method: "get",
          url: `activity/last-activity`,
        },
      },
    });
  };
}

export function userLoginLogs(payload:any){
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CLEANER_LOGIN_LOGS,
      payload: {
        request: {
          method: "post",
          url: `users/login-logs`,
          data: payload,
          // onUploadProgress: (progressEvent:any) => {
          //   const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          //   return dispatch({ type: API_ADD_BRANCH_PROGRESS, payload: progress });
          // }
        },
      },
    });
  };
}