import React from "react";
import { RootState } from '../../index';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { updateEditShiftDlgStatus } from "../../shared/Reducers";
import DeleteShift from "./DeleteShift";
import { IShiftCardProps, IShiftCardState } from "../../types/shift.type";
import moment from "moment";
import ViewShift from "./ViewShift";
import { TableDeleteSVG, TableEditSVG, TableViewSVG } from "../../assets/images/ImagesV2";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

class ShiftCard extends React.Component<IShiftCardProps, IShiftCardState> {
    constructor(props: IShiftCardProps) {
        super(props);
        this.state = {
            show: false,
            displayDelete: false,
            currentShiftId: "",
            editShow: false,
            displayView: false,
        }
    }

    handleClose = () => {
        this.setState({ show: false })
    }

    handleShow = () => {
        this.setState({ show: true })
    }
    handleViewShift = (id: string) => {
        this.setState({ displayView: true, currentShiftId: id })
    }

    handleViewClose = (status: boolean) => {
        this.setState({ displayView: false });
    }


    // Delete shift based on ID
    handleDelete = (id: string) => {
        this.setState({ displayDelete: true, currentShiftId: id })
    }

    // delete popup close
    handleDeleteClose = (status: boolean) => {
        this.setState({ displayDelete: false, currentShiftId: "" })
        if (status) {
            this.props.handleDeleteShift(this.state.currentShiftId)
        }
    }

    render(): React.ReactNode {
        const { id, start_date, end_date, name, start_time, end_time, cleaners } = this.props.shifts;
        return (
            <tr>
                <td>{name}</td>
                <td>
                    <OverlayTrigger placement="right"
                        overlay={<Tooltip>{cleaners.map((element: any) => (
                            <p className="text-white mb-0 fs-12px">
                                {element.first_name + " " + element.last_name}
                            </p>
                        ))}</Tooltip>}>
                        {cleaners.length > 0 ? (
                            <Button variant="light" className="btn btn-secondary btn-cleaner-count fs-12px">{cleaners.length}</Button>
                        ) : (<></>)}
                    </OverlayTrigger>
                </td>
                <td>{moment(new Date(start_date?.toString())).format("DD/MM/YYYY")}</td>
                <td>{moment(new Date(end_date?.toString())).format("DD/MM/YYYY")}</td>
                <td>{moment(start_time, "HH:mm:ss").format("hh:mm A")}</td>
                <td>{moment(end_time, "HH:mm:ss").format("hh:mm A")}</td>
                <td>
                    <div className="btn-group">
                        <span className="mr-20px">
                            <a href="javascript:void(0)" onClick={() => this.handleViewShift(id)}>
                                <OverlayTrigger placement="top"
                                    overlay={<Tooltip>{"View"}</Tooltip>}>
                                   <Button className="action-btn"><TableViewSVG/></Button>
                                </OverlayTrigger>
                            </a>
                            </span>
                            <span className="mr-20px">
                            <a href="javascript:void(0)" onClick={() => this.props.handleEditShift(id)}>
                                <OverlayTrigger placement="top"
                                    overlay={<Tooltip>{"Edit"}</Tooltip>}>
                                    <Button className="action-btn"><TableEditSVG /></Button>
                                </OverlayTrigger>
                            </a>
                        </span>
                       
                        <span>
                            <a href="javascript:void(0)" onClick={() => this.handleDelete(id)}>
                                <OverlayTrigger placement="top"
                                    overlay={<Tooltip>{"Delete"}</Tooltip>}>
                                   <Button className="action-btn"><TableDeleteSVG /></Button>
                                </OverlayTrigger>
                            </a>
                        </span>
                        <ViewShift displayView={this.state.displayView} handleViewClose={this.handleViewClose} data={this.props.shifts} id={id} />
                        <DeleteShift displayDelete={this.state.displayDelete} deletedName={name+" ( Start : "+ moment(new Date(start_date?.toString())).format("DD/MM/YYYY")+" to End : "+moment(new Date(end_date?.toString())).format("DD/MM/YYYY")+")"} handleDeleteClose={this.handleDeleteClose} />
                    </div>
                </td>
            </tr>
        )
    }
}
const mapStateToProps = (state: RootState) => ({
    editShiftStatus: state.shift.editShiftStatus
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateEditShiftDlgStatus: bindActionCreators(updateEditShiftDlgStatus, dispatch),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ShiftCard);