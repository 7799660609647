import React, { Component } from 'react';
import * as Yup from "yup";
import { connect } from 'react-redux';
import { RootState } from '../..';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import Button from "@material-ui/core/Button";
import { PaperClipSVG, TrashSVG } from '../../assets/images/ImagesV2';
import { AttachmentDownloadSVG } from '../../assets/images/ImagesSVG';
import CustomSelect from "../../shared/Components/CustomSelect";
import DatePicker from "../DailyTask/DatePicker";
import pdfImage from "../../assets/images/pdf.png";
import docImage from "../../assets/images/google-docs.png";
import videoImage from "../../assets/images/video.png";
import { confirmAlert } from "react-confirm-alert";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import { Dispatch, bindActionCreators } from 'redux';
import { IBranchSelect, ICleaner } from '../../shared/ActionTypes';
import { listAllBranch, listAllClient, editAdditionalTask, editRecommendedTask, getAllRecommendedTasks, getAllRecommendedCleanerTasks, deleteAttachementId } from '../../shared/Reducers';
import moment from 'moment';
import Constants from '../../shared/Constants';
import { Modal } from 'react-bootstrap';

interface ICleanerInOutProps {
    status: any;
    id: any;
    data: any;
    clientList: any;
    branchList: any;
    listAllBranch: any;
    listAllClient: any;
    editRecommendedTask: any;
    filterTaskBranchById: any;
    getAllRecommendedTasks: any;
    filterTaskPriorityById: any;
    filterTaskClientById: any;
    filterTaskDueTypeByValue: any;
    filterTaskStatusById: any;
    filterSpecialRequestTaskStatusById: any;
    filterTaskDateTypeValue: any;
    handleEditModelClose: any;
    selectedData: any;
    getAllRecommendedCleanerTasks: any;
    deleteAttachementId: any;
    editAdditionalTask: any;
}
interface IRecommendedTableState {
    attachmentUrls: any[];
    deleteAttachementId: any[];
    AttachmentPreviewStatus: any;
    previewUrl: any;
    previewType: any;
    file_count: any;
    task_date: any;
    isSignChanged: any;
    signatureImage: any;
    signatureModal: any;
    signatureImageError: any;
    isDrawing: any;
    context: any;
    signLastX: any;
    signLastY: any;
    isSignChange: any;
    attachmentFiles: any;
    signState: any;
}

class RecommendedEdit extends Component<ICleanerInOutProps, IRecommendedTableState> {
    canvasRef: React.RefObject<HTMLCanvasElement>;
    constructor(props: ICleanerInOutProps) {
        super(props);
        this.canvasRef = React.createRef();
        this.state = {
            attachmentUrls: [],
            deleteAttachementId: [],
            AttachmentPreviewStatus: false,
            previewUrl: "",
            previewType: "",
            file_count: "",
            task_date: this.props.data?.task_date,
            isSignChanged: false,
            signatureImage: "",
            signatureModal: false,
            signatureImageError: "",
            isDrawing: false,
            context: null,
            signLastX: 0,
            signLastY: 0,
            isSignChange: false,
            attachmentFiles: [],
            signState: true,
        }
    }

    componentDidMount(): void {
        if (this.props.data) {
            this.setState(
                {
                    attachmentUrls: this.props.data.attachments.map((obj: any) => ({
                        url: obj.s3_url,
                        name: obj.file_name,
                        id: obj.id,
                        type: "download",
                        download_url: `${Constants.imgUrl}${obj.file_name}`,
                        file_type: obj.type,
                        contractor_attachment_type: obj.contractor_attachment_type,
                    })),
                    task_date: this.props.data?.task_date,
                },
                () => {

                }
            );
        }
        this.props.listAllClient();
        if (this.props.data) {
            this.props.listAllBranch(this.props.data.client_id);
        }
        var element = document.getElementById("spinner-border");
        var button = document.getElementById("create-button");
        if (element !== null && button !== null) {
            element.classList.add("d-none");
            button.removeAttribute("disabled");
        }
    }

    componentDidUpdate(
        prevProps: Readonly<ICleanerInOutProps>,
        prevState: Readonly<IRecommendedTableState>,
        snapshot?: any
    ): void {
        if (this.props.data !== prevProps.data) {
            this.setState({
                attachmentUrls: this.props.data?.attachments.map((obj: any) => ({
                    url: `${Constants.imgThumbUrl}${obj.file_name}`,
                    name: obj.file_name,
                    id: obj.id,
                    type: "download",
                    download_url: `${Constants.imgUrl}${obj.file_name}`,
                    file_type: obj.type,
                    contractor_attachment_type: obj.contractor_attachment_type,
                })),
                // task_date: this.props.data ? this.props.data?.task_date : "",
                task_date: this.props.data?.task_date,
            });

            this.props.listAllBranch(this.props.data?.client_id);
        }
    }

    forceDownload = (blob: any, filename: String) => {
        const a: any = document.createElement("a");
        a.download = filename;
        a.href = blob;
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    downloadResource = (url: any, filename: any) => {
        if (url) {
            if (!filename) filename = url.split("\\").pop().split("/").pop();
            fetch(url, {
                headers: new Headers({
                    Origin: window.location.origin,
                }),
                mode: "cors",
            })
                .then((response) => response.blob())
                .then((blob) => {
                    let blobUrl = window.URL.createObjectURL(blob);
                    this.forceDownload(blobUrl, filename);
                })
                .catch((e) => console.error(e));
        }
    };

    handleDeleteImage = (index: number) => {
        const urls = this.state.attachmentUrls;

        confirmAlert({
            title: "Confirm deletion",
            message: "Are you sure you want to delete this Attchment ?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        // check exist file
                        if (urls[index].id !== undefined && urls[index].id !== "0") {
                            let deleteAttachementId = this.state.deleteAttachementId;
                            deleteAttachementId.push(urls[index].id);
                            this.setState({ deleteAttachementId: deleteAttachementId });

                            urls.splice(index, 1);
                            this.setState({ attachmentUrls: urls });
                        } else {
                            urls.splice(index, 1);
                            this.setState({ attachmentUrls: urls });
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    };

    downloadFile = (url: string, name = "image.png") => {
        this.downloadResource(url, name);
    };
    handleChange = (e: any) => {
        if (e.target.name === "client") {
            this.props.listAllBranch(e.target.value);
        }
    };

    handleImageChange = async (e: any) => {
        let files = [];
        let urls = [];
        files = [...e.target.files];

        let fileurls: any = [];
        await files.map(async (file: any) => {
            fileurls.push({
                url: URL.createObjectURL(file),
                file: file,
                id: "0",
                file_type: file.type,
                download_url: URL.createObjectURL(file),
            });
        });
        urls = [...fileurls];
        this.setState({ attachmentUrls: [...this.state.attachmentUrls, ...urls] });
    };

    handlePreviewOpen = (file_type: any, url: any, inx: any) => {
        if (
            file_type === "image/png" ||
            file_type === "image/jpeg" ||
            file_type === "image/jpg" ||
            file_type === "video/mp4" ||
            file_type === "video/webm" ||
            file_type === "application/pdf"
        ) {
            this.setState({
                AttachmentPreviewStatus: true,
                previewUrl: url,
                previewType: file_type,
                file_count: inx,
            });
        }
    };

    getTasks = async (offset: number, limit: number) => {
        try {
            if (Constants.LOGIN_USER_TYPE === 3) {
                // list for table
                let query = `search=&sort=&order=&offset=${offset}&limit=${limit}&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_priority=${this.props.filterTaskPriorityById}&due_type=${this.props.filterTaskDueTypeByValue}&task_status=${this.props.filterTaskStatusById}&special_request_task_status=${this.props.filterSpecialRequestTaskStatusById}&recommended_task_status=all&cleaner_id=${Constants.LOGIN_USER_ID}`;

                // list for excel report
                // let AllListquery = `search=&sort=&order=&offset=${offset}&limit=10000&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=3&task_priority=${this.props.filterTaskPriorityById}&due_type=${this.props.filterTaskDueTypeByValue}&task_status=${this.props.filterTaskStatusById}&special_request_task_status=${this.props.filterSpecialRequestTaskStatusById}&status&start_date=${this.props.filterTaskDateTypeValue.from_date}&end_date=${this.props.filterTaskDateTypeValue.to_date}`;
                await this.props.getAllRecommendedCleanerTasks(query);
                // await this.props.getAllRecommendedTasks(AllListquery);
            } else {
                // list for table
                let query = `search=&sort=&order=&offset=${offset}&limit=${limit}&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=3&task_priority=${this.props.filterTaskPriorityById}&due_type=${this.props.filterTaskDueTypeByValue}&task_status=${this.props.filterTaskStatusById}&special_request_task_status=${this.props.filterSpecialRequestTaskStatusById}&recommended_task_status=all`;

                // list for excel report
                // let AllListquery = `search=&sort=&order=&offset=${offset}&limit=10000&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=3&task_priority=${this.props.filterTaskPriorityById}&due_type=${this.props.filterTaskDueTypeByValue}&task_status=${this.props.filterTaskStatusById}&special_request_task_status=${this.props.filterSpecialRequestTaskStatusById}&status&start_date=${this.props.filterTaskDateTypeValue.from_date}&end_date=${this.props.filterTaskDateTypeValue.to_date}`;
                await this.props.getAllRecommendedTasks(query);
                // await this.props.getAllRecommendedTasks(AllListquery);
            }
        } catch (error) {
            console.log(error);
        }
    };

    validationSchema = () => {
        const branchId = this.props.branchList.map(
            (branch: IBranchSelect) => branch.value
        );
        return Yup.object().shape({
            description: Yup.string().required("Enter Task Description"),
            branch: Yup.string()
                .oneOf([...branchId], "Invalid Branch")
                .required("Please select branch")
                .nullable(),
        });
    };

    handleEditTask = async (formValues: any) => {
        console.log("inside of submit function", formValues);
        let data = new FormData();
        data.append("client", formValues.client);
        data.append("branch", formValues.branch);
        for (let index = 0; index < this.state.attachmentUrls.length; index++) {
            if (this.state.attachmentUrls[index].id === "0") {
                data.append("attachments", this.state.attachmentUrls[index].file);
            }
        }
        if (this.state.isSignChanged) {
            data.append("recommended_task_edit_signature", this.state.signatureImage);
        }
        data.append("isSignChanged", this.state.isSignChanged ? "1" : "0");
        data.append("description", formValues.description);
        data.append(
            "task_date",
            moment(this.state.task_date, "YYYY-MM-DD").format("DD/MM/YYYY")
        );
        this.props.editRecommendedTask(data, this.props.id, this.state.signatureImage);
        await this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
        this.setState({ isSignChanged: false });
        // await this.props.getAllRecommendedTasks();
        this.props.handleEditModelClose();

        if (this.props.id) {
            await this.state.deleteAttachementId.map(async (id: any) => {
                this.props.deleteAttachementId(id);
            });
            console.log("beforesending", data);
            console.log("id", this.props.id);
            this.props.editAdditionalTask(data, this.props.id);
            this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
            this.setState({ isSignChanged: false });
            this.props.handleEditModelClose();
        }
    }

    handleSignModal = () => {
        this.setState({ signatureModal: true, signState: false });
    }

    handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
        this.setState({ signatureImageError: "" });

        this.setState({ isDrawing: true });
        const canvas = this.canvasRef.current;

        if (!canvas) return;
        this.context = canvas.getContext("2d");
        this.setState({ context: canvas.getContext("2d") });
        if (!this.context) return;

        const { offsetX, offsetY } = event.nativeEvent;

        this.setState({ signLastX: offsetX, signLastY: offsetY });
    };

    handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
        this.setState({ signatureImageError: "" });

        if (!this.state.isDrawing) return;
        const canvas = this.canvasRef.current;

        if (canvas) {
            const { offsetX, offsetY } = event.nativeEvent;

            // Draw a line segment from the last recorded point to the current point
            let context: any = this.state.context;
            context.strokeStyle = "#000"; // Stroke color
            context.lineWidth = 2; // Stroke width
            context.beginPath();
            context.moveTo(this.state.signLastX, this.state.signLastY);
            context.lineTo(offsetX, offsetY);
            context.stroke();

            this.setState({ signLastX: offsetX, signLastY: offsetY });
        }
    };

    handleMouseUp = () => {
        this.setState({ signatureImageError: "" });
        this.setState({ isDrawing: false });
    };

    handleTouchStart = (event: React.TouchEvent<HTMLCanvasElement>) => {
        this.setState({ signatureImageError: "" });

        this.setState({ isDrawing: true });
        const canvas = this.canvasRef.current;
        console.log(canvas);

        if (!canvas) return;
        let context: any = this.state.context;
        context = canvas.getContext("2d");
        const rect = canvas.getBoundingClientRect();
        if (!context) return;

        const { clientX, clientY } = event.nativeEvent.changedTouches[0];

        this.setState({ signLastX: clientX - rect.left, signLastY: clientY - 111 });
    };

    handleTouchEnd = () => {
        this.setState({ signatureImageError: "", isDrawing: false });
    };

    handleTouchMove = (event: React.TouchEvent<HTMLCanvasElement>) => {
        this.setState({ signatureImageError: "" });

        if (!this.state.isDrawing) return;
        const canvas = this.canvasRef.current;
        let context: any = this.state.context;
        if (!canvas || !context) return;

        const { clientX, clientY } = event.nativeEvent.changedTouches[0];
        const rect = canvas.getBoundingClientRect();
        // const x = touch.clientX - rect.left;
        // const y = touch.clientY - rect.top;

        // Draw a line segment from the last recorded point to the current point
        context.strokeStyle = "#000"; // Stroke color
        context.lineWidth = 2; // Stroke width
        context.beginPath();
        context.moveTo(this.state.signLastX, this.state.signLastY);
        context.lineTo(clientX - rect.left, clientY - rect.top);
        context.stroke();
        this.setState({
            signLastX: clientX - rect.left,
            signLastY: clientY - rect.top,
        });
    };

    handleClear = () => {
        const canvas = this.canvasRef.current;
        let context: any = this.state.context;
        if (!canvas || !context) return;
        context.clearRect(0, 0, canvas.width, canvas.height);
    };

    handleConvertToImage = () => {
        const canvas = this.canvasRef.current;
        if (!canvas) return;

        const image: string = canvas.toDataURL("image/png");

        if (
            image !==
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACWCAYAAABkW7XSAAAAAXNSR0IArs4c6QAABGJJREFUeF7t1AEJAAAMAsHZv/RyPNwSyDncOQIECEQEFskpJgECBM5geQICBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAgQdWMQCX4yW9owAAAABJRU5ErkJggg=="
        ) {
            this.setState({
                signatureImage: image,
                signatureModal: false,
                isSignChange: true,
                isSignChanged: true,
            });
        }
    };

    render() {
        console.log("selectedProps", this.props.selectedData);

        return (
            <>
                <Formik
                    initialValues={
                        {
                            description: this.props.data ? this.props.data.description : "",
                            client: this.props.data ? this.props.data.client_id : "",
                            branch: this.props.data ? this.props.data.branch_id : "",
                            task_date: this.props.data ? this.props.data.task_date : "",
                            attachments: this.props.data ? this.props.data.attachments : "",
                        }}
                    enableReinitialize={true}
                    onSubmit={this.handleEditTask}
                    validationSchema={this.validationSchema}
                >
                    {(props) => {
                        const { values, errors, touched } = props;
                        return (
                            <Form onChange={this.handleChange}>
                                <div className='mb-2'>
                                    <label className='form-label'>
                                        Recommended Description <span className='text-project'>*</span>
                                    </label>
                                    <Field
                                        as="textarea"
                                        name="description"
                                        className="form-control text-area"
                                    />
                                    <ErrorMessage
                                        name='description'
                                        component={"div"}
                                        className="cus-alert"
                                    />
                                </div>
                                <div>
                                    <label className='form-label'>
                                        Attachment<span className='text-project'>*</span>
                                    </label>
                                    <div className='attachment-btn dropdown-field'>
                                        <input
                                            type='file'
                                            multiple
                                            accept='.pdf,.PDF,.mp4,.MP4,.webm,.WEBM,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.doc,.DOC,.docx.DOCX'
                                            style={{ display: "none" }}
                                            onChange={this.handleImageChange}
                                            id='contained-button-file'
                                        />
                                        <label htmlFor='contained-button-file'>
                                            <Button
                                                variant="contained"
                                                color="default"
                                                component="span"
                                            >
                                                <PaperClipSVG /> Add attachment
                                            </Button>
                                        </label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='mb-3 d-flex row'>
                                        {this.state.attachmentUrls && this.state.attachmentUrls.map((obj: any, index: number) => {
                                            if (obj.contractor_attachment_type != "sp_task_signature") {
                                                return (
                                                    <div className='img-card mr-16px mb-3' key={index}>
                                                        <span className='delete'>
                                                            <a
                                                                onClick={() =>
                                                                    this.handleDeleteImage(index)
                                                                }
                                                            >
                                                                <TrashSVG />
                                                            </a>
                                                        </span>
                                                        {obj.type === "download" && (
                                                            <span className='download'>
                                                                <a
                                                                    href="javascript:void(0)"
                                                                    onClick={() =>
                                                                        this.downloadFile(
                                                                            obj.download_url,
                                                                            obj.file_name
                                                                        )
                                                                    }
                                                                >
                                                                    <AttachmentDownloadSVG />
                                                                </a>
                                                            </span>
                                                        )}
                                                        <div className='img-preview'
                                                            onClick={() =>
                                                                this.handlePreviewOpen(
                                                                    obj.file_type,
                                                                    obj.download_url,
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                className="img-thumb-perview"
                                                                src={
                                                                    obj.file_type === "application/pdf"
                                                                        ? pdfImage
                                                                        : obj.file_type === "video/mp4" ||
                                                                            obj.file_type === "video/webm"
                                                                            ? videoImage
                                                                            : obj.file_type === "image/png" ||
                                                                                obj.file_type === "image/jpeg" ||
                                                                                obj.file_type === "image/jpg"
                                                                                ? obj.url
                                                                                : docImage
                                                                }
                                                                alt="attach"
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                                {Constants.LOGIN_USER_TYPE === 3 ? (
                                    this.props.selectedData !== null && (
                                        <div className='row'>
                                            <div className='mb-2 col-md-6'>
                                                <label className='form-label'>
                                                    Client<span className="text-project">:</span>
                                                </label>
                                                <br />
                                                {this.props.selectedData
                                                    .first_name +
                                                    " " +
                                                    this.props.selectedData
                                                        .last_name}
                                            </div>
                                            <div className='mb-2 col-md-6'>
                                                <label className='form-label'>
                                                    Branch<span className="text-project">:</span>
                                                </label><br />
                                                {this.props.selectedData.name}
                                            </div>
                                        </div>
                                    )
                                ) : (
                                    <div className='row'>
                                        <div className='mb-2 col-md-6'>
                                            <label className='form-label'>
                                                Client<span className="text-project">*</span>
                                            </label>
                                            <Field
                                                as="select"
                                                className="form-select dropdown-field"
                                                name="client"
                                                readOnly
                                            >
                                                {this.props.clientList.map((client: ICleaner) => {
                                                    return (
                                                        <option
                                                            value={client.id.toString()}
                                                            key={client.id}
                                                        >
                                                            {`${client.first_name} ${client.last_name}`}
                                                        </option>
                                                    )
                                                }
                                                )}
                                            </Field>
                                            <ErrorMessage
                                                name="client"
                                                component={"div"}
                                                className="cus-alert"
                                            />
                                        </div>
                                        <div className='mb-2 col-md-6'>
                                            <label className="form-label">
                                                Branch<span className="text-project">*</span>
                                            </label>
                                            <Field
                                                className="custom-select dropdown-field"
                                                name="branch"
                                                options={this.props.branchList}
                                                component={CustomSelect}
                                                placeholder="Select Branch"
                                                isMulti={false}
                                                readOnly
                                            />
                                            <ErrorMessage
                                                name="branch"
                                                component={"div"}
                                                className="cus-alert"
                                            />
                                        </div>
                                    </div>
                                )}

                                <div className='mb-2 col-md-6'>
                                    <label className='form-label'>
                                        Date<span className="text-project">*</span>
                                    </label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            inputFormat='DD/MM/YYYY'
                                            value={this.state.task_date}
                                            onChange={(newValue: any | null) => {
                                                this.setState({
                                                    task_date: moment(new Date(newValue?.toString())).format("YYYY-MM-DD")
                                                });
                                            }} renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                {/* {Constants.LOGIN_USER_TYPE !== 3 ? (
                                    <div className="row mt-3 mb-3">
                                        <label className="form-label">
                                            Signature<span className="text-project">*</span>
                                        </label>
                                        {this.state.signState === true ? (
                                            <div className=" mb-3 d-flex row">
                                                {this.state.signatureImage &&
                                                    this.state.signatureImage.map(
                                                        (obj: any, index: number) => {
                                                            if (
                                                                obj.contractor_attachment_type ===
                                                                "sp_task_signature"
                                                            ) {
                                                                return (
                                                                    <div
                                                                        className="img-card mr-16px mb-3"
                                                                        key={index}
                                                                    >
                                                                        <img
                                                                            className="img-thumb-perview"
                                                                            src={
                                                                                obj.file_type === "image/png" ||
                                                                                    obj.file_type === "image/jpeg" ||
                                                                                    obj.file_type === "image/jpg"
                                                                                    ? obj.url
                                                                                    : docImage
                                                                            }
                                                                            alt="attach"
                                                                            style={{
                                                                                width: "130px",
                                                                                height: "100px",
                                                                                border: "2px solid #E3DDDA",
                                                                            }}
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                        }
                                                    )}
                                            </div>
                                        ) : (
                                            <div>
                                                {this.state.signatureImage !== "" && (
                                                    <img
                                                        src={this.state.signatureImage}
                                                        alt=""
                                                        style={{
                                                            width: "130px",
                                                            height: "100px",
                                                            border: "2px solid #E3DDDA",
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        )}

                                        <button
                                            type="button"
                                            id="create-button"
                                            className="btn btn-primary create-button"
                                            style={{ width: "40%" }}
                                            onClick={() => this.handleSignModal()}
                                        >
                                            Add
                                        </button>
                                    </div>
                                ) : null} */}
                                <div className='d-flex'>
                                    <div className='mr-16px'>
                                        <button
                                            type='submit'
                                            id='create-button'
                                            className='btn btn-primary create-button'
                                        >
                                            Update
                                            <span
                                                id="spinner-border"
                                                className="spinner-border spinner-border-sm d-none"
                                            ></span>
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            type="button"
                                            className="btn cancel-button"
                                            onClick={() => this.props.handleEditModelClose()}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                                <Modal
                                    size="lg"
                                    show={this.state.signatureModal}
                                    onHide={() =>
                                        this.setState({
                                            signatureModal: false,
                                        })
                                    }
                                    aria-labelledby="example-modal-sizes-title-lg"
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title id="example-modal-sizes-title-lg">
                                            <h3 className="m-0">Add signature</h3>
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="p-4">
                                        <div className="signature-pad">
                                            <canvas
                                                ref={this.canvasRef}
                                                style={{ width: "100% !important" }}
                                                onMouseDown={this.handleMouseDown}
                                                onMouseMove={this.handleMouseMove}
                                                onMouseUp={this.handleMouseUp}
                                                onTouchStart={this.handleTouchStart}
                                                onTouchEnd={this.handleTouchEnd}
                                                onTouchMove={this.handleTouchMove}
                                            />
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={this.handleClear}
                                        >
                                            Clear
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            id="add-sign-image"
                                            style={{ marginLeft: "10px" }}
                                            onClick={this.handleConvertToImage}
                                        >
                                            Add
                                        </button>
                                    </Modal.Footer>
                                </Modal>
                            </Form>
                        )
                    }}
                </Formik >
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    status: state.sidebar.status,
    clientList: state.tasktemplate.clientList,
    branchList: state.tasktemplate.branchList,
    filterTaskClientById: state.dailytask.filterTaskClientById,
    filterTaskBranchById: state.dailytask.filterTaskBranchById,
    filterTaskDueTypeByValue: state.dailytask.filterTaskDueTypeByValue,
    filterTaskPriorityById: state.dailytask.filterTaskPriorityById,
    filterTaskStatusById: state.dailytask.filterTaskStatusById,
    filterSpecialRequestTaskStatusById:
        state.dailytask.filterSpecialRequestTaskStatusById,
    filterTaskDateTypeValue: state.dailytask.filterTaskDateTypeValue,
    selectedData: state.branch.selectedData
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
    listAllClient: bindActionCreators(listAllClient, dispatch),
    editRecommendedTask: bindActionCreators(editRecommendedTask, dispatch),
    getAllRecommendedTasks: bindActionCreators(getAllRecommendedTasks, dispatch),
    deleteAttachementId: bindActionCreators(deleteAttachementId, dispatch),
    editAdditionalTask: bindActionCreators(editAdditionalTask, dispatch),
    getAllRecommendedCleanerTasks: bindActionCreators(getAllRecommendedCleanerTasks, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedEdit);
