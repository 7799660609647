import * as Yup from "yup";

import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  IEditClientContractProps,
  IEditClientContractState,
} from "../../types/contract.type";
import { Modal, ProgressBar } from "react-bootstrap";
import { PaperClipSVG, TrashSVG } from "../../assets/images/ImagesV2";
import {
  deleteAttachementId,
  editContract,
  listAllClient,
  updateContractFetchStatus,
  updateEditClientContractDlgStatus,
} from "../../shared/Reducers";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AttachmentDownloadSVG } from "../../assets/images/ImagesSVG";
import Button from "@material-ui/core/Button";
import Constants from "../../shared/Constants";
import { Dayjs } from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { ICleaner } from "../../shared/ActionTypes";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import { RootState } from "../../index";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import docImage from "../../assets/images/google-docs.png";
// date picker
import moment from "moment";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";

class EditClientContract extends React.Component<
  IEditClientContractProps,
  IEditClientContractState
> {
  constructor(props: IEditClientContractProps) {
    super(props);
    this.state = {
      endDatevalue: this.props.data ? this.props.data.end_date : "",
      startDatevalue: this.props.data ? this.props.data.start_date : "",
      client: "",
      attachments: "",
      attachmentUrls: [],
      deleteAttachementId: [],
      selectedCheckbox: this.props.data ? this.props.data.asic_reg : "",
      asic_value: ["acn", "arbn", "arsn", "arfn"],
    };
  }

  componentDidMount(): void {
    // date picker readonly
    let datePicker: NodeListOf<Element> = document.querySelectorAll(
      ".MuiFormControl-root input"
    ) as NodeListOf<Element>;
    if (datePicker.length > 0) {
      for (let index = 0; index < datePicker.length; index++) {
        const element = datePicker[index];
        datePicker[index].setAttribute("readonly", "true");
      }
    }

    this.props.listAllClient();
    if (this.props.data) {
      this.setState({
        attachmentUrls: this.props.data.attachments.map((obj: any) => ({
          url: obj.s3_url,
          name: obj.file_name,
          id: obj.id,
          type: "download",
          download_url: `${Constants.imgUrl}${obj.file_name}`,
          file_type: obj.type,
        })),
      });
    }

    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.add("d-none");
      button.removeAttribute("disabled");
    }
    console.log(this.props.progressPercentage);
  }

  componentDidUpdate(
    prevProps: Readonly<IEditClientContractProps>,
    prevState: Readonly<IEditClientContractState>,
    snapshot?: any
  ): void {
    if (prevProps.processTrigger !== this.props.processTrigger) {
    }
    if (prevProps.editContractSuccess !== this.props.editContractSuccess) {
    }
  }

  handleImageChange = async (e: any) => {
    let files = [];
    let urls = [];
    files = [...e.target.files];

    let fileurls: any = [];
    await files.map(async (file: any) => {
      fileurls.push({
        url: URL.createObjectURL(file),
        file: file,
        id: "0",
        file_type: file.type,
      });
    });
    urls = [...fileurls];
    this.setState({ attachmentUrls: [...this.state.attachmentUrls, ...urls] });
  };

  handleDeleteImage = (index: number) => {
    const urls = this.state.attachmentUrls;

    confirmAlert({
      title: "Confirm deletion",
      message: "Are you sure you want to delete this Attchment ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            // check exist file
            if (urls[index].id !== undefined && urls[index].id !== "0") {
              let deleteAttachementId = this.state.deleteAttachementId;
              deleteAttachementId.push(urls[index].id);
              this.setState({ deleteAttachementId: deleteAttachementId });

              urls.splice(index, 1);
              this.setState({ attachmentUrls: urls });
            } else {
              urls.splice(index, 1);
              this.setState({ attachmentUrls: urls });
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  handleSelectCheckBox = (option: any) => {
    this.setState({ selectedCheckbox: option });
  };

  handleStartDateClick = () => {
    let startDate: HTMLElement = document.querySelector(
      ".startdate button.MuiButtonBase-root"
    ) as HTMLElement;
    startDate.click();
  };

  handleEndDateClick = () => {
    let endDate: HTMLElement = document.querySelector(
      ".enddate button.MuiButtonBase-root"
    ) as HTMLElement;
    endDate.click();
  };

  handleStartDateChange = async (newValue: Dayjs | null) => {
    this.setState({ startDatevalue: newValue });
  };
  handleEndDateChange = async (newValue: Dayjs | null) => {
    this.setState({ endDatevalue: newValue });
  };

  handleEditClientContract = async (formValues: any) => {
    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.remove("d-none");
      button.setAttribute("disabled", "");
    }
    let data = new FormData();
    data.append("client", formValues.client);
    for (let index = 0; index < this.state.attachmentUrls.length; index++) {
      if (this.state.attachmentUrls[index].file !== undefined) {
        data.append("attachments", this.state.attachmentUrls[index].file);
      }
    }
    data.append(
      "start_date",
      moment(new Date(this.state.startDatevalue?.toString())).format(
        "DD/MM/YYYY"
      )
    );
    data.append(
      "end_date",
      moment(new Date(this.state.endDatevalue?.toString())).format("DD/MM/YYYY")
    );

    data.append("entity_name", formValues.entity_name);
    data.append("abn_status", formValues.abn_status);
    data.append("entity_type", formValues.entity_type);
    data.append("gst", formValues.gst);
    data.append("business_location", formValues.business_location);
    data.append("asic_reg", this.state.selectedCheckbox);

    if (this.props.id) {
      await this.state.deleteAttachementId.map(async (id: any) => {
        this.props.deleteAttachementId(id);
      });

      this.props.editContract(data, this.props.id);
    }
  };

  downloadFile = (url: string, name = "image.png") => {
    this.downloadResource(url, name);
  };

  forceDownload = (blob: any, filename: String) => {
    const a: any = document.createElement("a");
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  // Current blob size limit is around 500MB for browsers
  downloadResource = (url: any, filename: any) => {
    if (url) {
      if (!filename) filename = url.split("\\").pop().split("/").pop();
      fetch(url, {
        headers: new Headers({
          Origin: window.location.origin,
        }),
        mode: "cors",
      })
        .then((response) => response.blob())
        .then((blob) => {
          let blobUrl = window.URL.createObjectURL(blob);
          this.forceDownload(blobUrl, filename);
        })
        .catch((e) => console.error(e));
    }
  };

  validationSchema = () => {
    const clientId = this.props.clientList.map((client: ICleaner) => client.id);
    return Yup.object().shape({
      client: Yup.string()
        .oneOf([...clientId], "Invalid Client")
        .required()
        .nullable(),
      attachments: Yup.mixed(),
      entity_name: Yup.string().required("Entity Name is required"),
      abn_status: Yup.string().required("ABN Status is required"),
      entity_type: Yup.string().required("Entity Type is required"),
      business_location: Yup.string().required("Business location is required"),
      gst: Yup.string().required("GST Number is required"),
    });
  };

  render(): React.ReactNode {
    return (
      <div>
        <Formik
          initialValues={{
            client: this.props.data ? this.props.data.client_id : "",
            attachments: this.props.data ? this.props.data.attachments : "",
            entity_name: this.props.data ? this.props.data.entity_name : "",
            entity_type: this.props.data ? this.props.data.entity_type : "",
            gst: this.props.data ? this.props.data.gst : "",
            business_location: this.props.data
              ? this.props.data.business_location
              : "",
            abn_status: this.props.data ? this.props.data.abn_status : "",
          }}
          enableReinitialize={true}
          onSubmit={this.handleEditClientContract}
          validationSchema={this.validationSchema}
        >
          <Form>
            <div className="row">
              <div className="mb-2 col-md-6">
                <div>
                  <label className="form-label">
                    Client<span className="text-project">*</span>
                  </label>
                  <Field
                    as="select"
                    className="form-select dropdown-field"
                    name="client"
                  >
                    <option disabled selected>
                      Select client
                    </option>
                    {this.props.clientList.map((client: ICleaner) => (
                      <option
                        value={client.id.toString()}
                        key={client.id}
                      >{`${client.first_name} ${client.last_name}`}</option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="client"
                    component={"div"}
                    className="cus-alert"
                  />
                </div>
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Entity Name<span className="text-project">*</span>
                </label>
                <Field
                  name="entity_name"
                  type={"text"}
                  className="form-control"
                />
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  ABN Status<span className="text-project">*</span>
                </label>
                <Field
                  name="abn_status"
                  type={"text"}
                  className="form-control"
                />
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Entity Type<span className="text-project">*</span>
                </label>
                <Field
                  name="entity_type"
                  type={"text"}
                  className="form-control"
                />
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Goods & Service Tax (GST)
                  <span className="text-project">*</span>
                </label>
                <Field name="gst" type={"text"} className="form-control" />
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Main Business Location
                  <span className="text-project">*</span>
                </label>
                <Field
                  name="business_location"
                  type={"text"}
                  className="form-control"
                />
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  ASIC Registration
                  <span className="text-project">*</span>
                </label>
                <div className="flex-row-container">
                  {this.state.asic_value.map((item: any) => (
                    <div>
                      <label className="form-label ">
                        <input
                          type="checkbox"
                          checked={this.state.selectedCheckbox === item}
                          onChange={() => this.handleSelectCheckBox(item)}
                        />
                        {item.toUpperCase()}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mb-2 col-md-6">
                <div>
                  <label className="form-label">Attachment</label>
                  <div className="attachment-btn dropdown-field">
                    <input
                      type="file"
                      multiple
                      accept=".pdf,.PDF,.mp4,.MP4,.webm,.WEBM,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.doc,.DOC,.docx.DOCX"
                      style={{ display: "none" }}
                      onChange={this.handleImageChange}
                      id="contained-button-file"
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        color="default"
                        component="span"
                      >
                        <PaperClipSVG /> Add attachment
                      </Button>
                    </label>
                  </div>
                </div>
              </div>

              {this.state.attachmentUrls.length > 0 ? (
                <div className="col-md-12 mb-2">
                  <label className="form-label">Attachments</label>
                </div>
              ) : (
                <></>
              )}
              <div className="row">
                <div className=" mb-3 d-flex row">
                  {this.state.attachmentUrls &&
                    this.state.attachmentUrls.map((obj: any, index: number) => (
                      <div className="img-card mr-16px mb-3">
                        <span className="delete">
                          <a onClick={() => this.handleDeleteImage(index)}>
                            <TrashSVG />
                          </a>
                        </span>
                        {obj.type === "download" && (
                          <span className="download">
                            <a
                              href="javascript:void(0)"
                              onClick={() =>
                                this.downloadFile(
                                  obj.download_url,
                                  obj.file_name
                                )
                              }
                            >
                              <AttachmentDownloadSVG />
                            </a>
                          </span>
                        )}

                        <div className="img-preview">
                          <img
                            className="img-thumb-perview"
                            src={
                              obj.file_type === "application/pdf"
                                ? pdfImage
                                : obj.file_type === "video/mp4" ||
                                  obj.file_type === "video/webm"
                                ? videoImage
                                : obj.file_type === "image/png" ||
                                  obj.file_type === "image/jpeg" ||
                                  obj.file_type === "image/jpg"
                                ? obj.url
                                : docImage
                            }
                            alt="attach"
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              
              <div className="mb-2 col-md-6">
                <label className="form-label">Start date</label>
                <div
                  className="startdate"
                  onClick={() => this.handleStartDateClick()}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    id="startDate"
                  >
                    <Stack spacing={3}>
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        maxDate={this.state.endDatevalue}
                        value={this.state.startDatevalue}
                        onChange={this.handleStartDateChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </div>

              <div className="mb-2 col-md-6">
                <label className="form-label">End date</label>
                <div
                  className="enddate"
                  onClick={() => this.handleEndDateClick()}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs} id="endDate">
                    <Stack spacing={3}>
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        minDate={this.state.startDatevalue}
                        value={this.state.endDatevalue}
                        onChange={this.handleEndDateChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </div>
             
            </div>
            <div className="row">
              
              <div className="row mt-3 mb-3">
                <div className="col-md-12 cus-progress">
                  <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.props.processTrigger}
                  >
                    <Modal.Body className="p-0">
                      <ProgressBar
                        now={this.props.progressPercentage}
                        label={
                          this.props.progressPercentage === 100
                            ? `Processing your request...`
                            : `Contract Updating....${this.props.progressPercentage}%`
                        }
                      />
                    </Modal.Body>
                  </Modal>
                  <div className="d-flex">
                    <div className="mr-16px">
                      <button
                        type="submit"
                        id="create-button"
                        className="btn btn-primary create-button"
                      >
                        Save{" "}
                        <span
                          id="spinner-border"
                          className="spinner-border spinner-border-sm d-none"
                        ></span>
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn cancel-button"
                        onClick={() =>
                          this.props.updateEditClientContractDlgStatus(false)
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>

        <iframe
          style={{ display: "none" }}
          name="hiddenIframe"
          id="hiddenIframe"
        />
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  clientList: state.contract.clientList,
  processTrigger: state.contract.processTrigger,
  editContractSuccess: state.contract.editContractSuccess,
  progressPercentage: state.contract.progressPercentage,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    listAllClient: bindActionCreators(listAllClient, dispatch),
    editContract: bindActionCreators(editContract, dispatch),
    deleteAttachementId: bindActionCreators(deleteAttachementId, dispatch),
    updateEditClientContractDlgStatus: bindActionCreators(
      updateEditClientContractDlgStatus,
      dispatch
    ),
    updateContractFetchStatus: bindActionCreators(
      updateContractFetchStatus,
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditClientContract);
