import { AnyAction } from "redux";

export const UPDATE_REPORT = "UPDATE_REPORT";
export const API_GET_ALL_REPORTS = "get_all_report/LOAD";
export const API_GET_ALL_REPORTS_SUCCESS = "get_all_report/LOAD_SUCCESS";
export const API_GET_ALL_REPORTS_FAIL = "get_all_report/LOAD_FAIL";
export const API_UPDATE_INOUT_DATE = "activity_update/LOAD";
export const API_UPDATE_INOUT_DATE_SUCCESS = "activity_update/LOAD_SUCCESS";
export const API_UPDATE_INOUT_DATE_FAIL = "activity_update/LOAD_FAIL";
export const FILTER_REPORT = "FILTER_REPORT";
export const UPDATE_REPORT_FETCH_STATUS = "UPDATE_REPORT_FETCH_STATUS";
export const API_UPDATE_INOUT_DATE_PROGRESS = "API_UPDATE_INOUT_DATE_PROGRESS";
export const FILTER_REPORT_FROM_DATE = "FILTER_REPORT_FROM_DATE";
export const FILTER_REPORT_TO_DATE = "FILTER_REPORT_TO_DATE";
export const API_GET_ALL_DELETE_REPORT = "deleterepot/LOAD";
export const API_GET_ALL_DELETE_REPORT_SUCCESS = "deleterepot/LOAD_SUCCESS";
export const API_GET_ALL_DELETE_REPORT_FAIL = "deleterepot/LOAD_FAIL";

export interface ReportState {
  reportData: IReportTable[];
  filteredReportData: IReportTable[];
  selectedReportData: IReportTable | null;
  reportFetchStatus: boolean;
  total_count: number;
  filterReportFromDate: string;
  filterReportToDate: string;
  Deletereport: any;
  deletereportstatus: boolean;
  deletereport_success: any;
}

export interface IReportTable {
  id: string;
  sign_in_date_time: string;
  sign_out_date_time: string | null;
  sign_in_lat: string;
  sign_in_lng: string;
  sign_out_lat: string | null;
  sign_out_lng: string | null;
  reason: string | null;
  created_at: string;
  client_id: string;
  cleaner_id: string;
  branch_id: string;
  first_name: string;
  last_name: string;
  branch_name: string;
  client_first_name: string;
  client_last_name: string;
  branch_lat: any;
  branch_lng: any;
}

export type ReportDispatch = (args: AnyAction) => AnyAction;
