import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../..';
import { Dispatch, bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Constants from '../../shared/Constants';
import { editRecommendedTask } from '../../shared/Reducers';

interface ICleanerInOutProps {
    displayApproved: any;
    handleViewClose: any;
    id: any;
    editRecommendedTask: any;
}
interface IRecommendedTableState {
    signatureModal: any;
    signatureImageError: any;
    isDrawing: any;
    context: any;
    signLastX: any;
    signLastY: any;
    signatureImage: any;
    isSignChange: any;
}

class ApprovedRecommendedTask extends Component<ICleanerInOutProps, IRecommendedTableState> {
    canvasRef: React.RefObject<HTMLCanvasElement>;
    constructor(props: ICleanerInOutProps) {
        super(props);
        this.canvasRef = React.createRef();
        this.state = {
            signatureModal: false,
            signatureImageError: "",
            isDrawing: false,
            context: null,
            signLastX: 0,
            signLastY: 0,
            signatureImage: "",
            isSignChange: false,
        }
    };

    handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
        this.setState({ signatureImageError: "" });

        this.setState({ isDrawing: true });
        const canvas = this.canvasRef.current;

        if (!canvas) return;
        this.context = canvas.getContext("2d");
        this.setState({ context: canvas.getContext("2d") });
        if (!this.context) return;

        const { offsetX, offsetY } = event.nativeEvent;

        this.setState({ signLastX: offsetX, signLastY: offsetY });
    };

    handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
        this.setState({ signatureImageError: "" });

        if (!this.state.isDrawing) return;
        const canvas = this.canvasRef.current;

        if (canvas) {
            const { offsetX, offsetY } = event.nativeEvent;

            // Draw a line segment from the last recorded point to the current point
            let context: any = this.state.context;
            context.strokeStyle = "#000"; // Stroke color
            context.lineWidth = 2; // Stroke width
            context.beginPath();
            context.moveTo(this.state.signLastX, this.state.signLastY);
            context.lineTo(offsetX, offsetY);
            context.stroke();

            this.setState({ signLastX: offsetX, signLastY: offsetY });
        }
    };

    handleMouseUp = () => {
        this.setState({ signatureImageError: "" });
        this.setState({ isDrawing: false });
    };

    handleTouchStart = (event: React.TouchEvent<HTMLCanvasElement>) => {
        this.setState({ signatureImageError: "" });

        this.setState({ isDrawing: true });
        const canvas = this.canvasRef.current;
        console.log(canvas);

        if (!canvas) return;
        let context: any = this.state.context;
        context = canvas.getContext("2d");
        const rect = canvas.getBoundingClientRect();
        if (!context) return;

        const { clientX, clientY } = event.nativeEvent.changedTouches[0];

        this.setState({ signLastX: clientX - rect.left, signLastY: clientY - 111 });
    };

    handleTouchEnd = () => {
        this.setState({ signatureImageError: "", isDrawing: false });
    };

    handleTouchMove = (event: React.TouchEvent<HTMLCanvasElement>) => {
        this.setState({ signatureImageError: "" });

        if (!this.state.isDrawing) return;
        const canvas = this.canvasRef.current;
        let context: any = this.state.context;
        if (!canvas || !context) return;

        const { clientX, clientY } = event.nativeEvent.changedTouches[0];
        const rect = canvas.getBoundingClientRect();
        // const x = touch.clientX - rect.left;
        // const y = touch.clientY - rect.top;

        // Draw a line segment from the last recorded point to the current point
        context.strokeStyle = "#000"; // Stroke color
        context.lineWidth = 2; // Stroke width
        context.beginPath();
        context.moveTo(this.state.signLastX, this.state.signLastY);
        context.lineTo(clientX - rect.left, clientY - rect.top);
        context.stroke();
        this.setState({
            signLastX: clientX - rect.left,
            signLastY: clientY - rect.top,
        });
    };

    handleClear = () => {
        const canvas = this.canvasRef.current;
        let context: any = this.state.context;
        if (!canvas || !context) return;
        context.clearRect(0, 0, canvas.width, canvas.height);
    };

    handleConvertToImage = () => {
        const canvas = this.canvasRef.current;
        if (!canvas) return;

        const image: string = canvas.toDataURL("image/png");

        if (
            image !==
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACWCAYAAABkW7XSAAAAAXNSR0IArs4c6QAABGJJREFUeF7t1AEJAAAMAsHZv/RyPNwSyDncOQIECEQEFskpJgECBM5geQICBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAgQdWMQCX4yW9owAAAABJRU5ErkJggg=="
        ) {
            this.setState({
                signatureImage: image,
                signatureModal: false,
                isSignChange: true,
            });
        }
    };

    handleSignModal = () => {
        this.setState({ signatureModal: true });
    }

    handleAddDailyTask = async (formValues: any) => {
        console.log("formValues", formValues);
        let data = new FormData();
        data.append("recommended_task_approved_signature", this.state.signatureImage);
        // await this.props.addDailyTask(data);
        await this.props.editRecommendedTask(data, this.props.id)
        setTimeout(() => {
            //   this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
            // this.handleCloseAdditionalTaskModal();
            this.props.handleViewClose(false)
        }, 500);
    };


    render() {
        const initialValues = {
            signature: ""
        };

        return (
            <>
                <Modal
                    size="lg"
                    dialogClassName="modal-90w"
                    aria-labelledby="contained-modal-title-vcenter"
                    show={this.props.displayApproved}
                    onHide={() => this.props.handleViewClose(false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            <h3 className="m-0">Approve Recommended Task</h3>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize={true}
                            onSubmit={this.handleAddDailyTask}
                            validationSchema={""}
                        >
                            {(props) => {
                                const { values, errors, touched } = props;
                                return (
                                    <Form>
                                        {Constants.LOGIN_USER_TYPE !== 3 ? (
                                            <div className="row mt-3 mb-3">
                                                <label className="form-label">
                                                    Signature<span className="text-project">*</span>
                                                </label>
                                                <div>
                                                    {this.state.signatureImage !== "" && (
                                                        <img
                                                            src={this.state.signatureImage}
                                                            alt=""
                                                            style={{
                                                                width: "130px",
                                                                height: "100px",
                                                                border: "2px solid #E3DDDA",
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                <div>
                                                    <button
                                                        type="button"
                                                        id="create-button"
                                                        className="btn btn-primary create-button"
                                                        style={{ width: "40%" }}
                                                        onClick={() => this.handleSignModal()}
                                                    >
                                                        Add
                                                    </button>
                                                </div>
                                                <div className="d-flex mt-2">
                                                    <div className="mr-16px">
                                                        <button
                                                            type="submit"
                                                            id="create-button"
                                                            className="btn btn-primary create-button"
                                                        >
                                                            Create{" "}
                                                            <span
                                                                id="spinner-border"
                                                                className="spinner-border spinner-border-sm d-none"
                                                            ></span>
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="btn cancel-button"
                                                            onClick={() =>
                                                                this.props.handleViewClose(false)
                                                            }
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                        <Modal
                                            size="lg"
                                            show={this.state.signatureModal}
                                            onHide={() =>
                                                this.setState({
                                                    signatureModal: false,
                                                })
                                            }
                                            aria-labelledby="example-modal-sizes-title-lg"
                                        >
                                            <Modal.Header closeButton>
                                                <Modal.Title id="example-modal-sizes-title-lg">
                                                    <h3 className="m-0">Add signature</h3>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className="p-4">
                                                <div className="signature-pad">
                                                    <canvas
                                                        ref={this.canvasRef}
                                                        style={{ width: "100% !important" }}
                                                        onMouseDown={this.handleMouseDown}
                                                        onMouseMove={this.handleMouseMove}
                                                        onMouseUp={this.handleMouseUp}
                                                        onTouchStart={this.handleTouchStart}
                                                        onTouchEnd={this.handleTouchEnd}
                                                        onTouchMove={this.handleTouchMove}
                                                    />
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={this.handleClear}
                                                >
                                                    Clear
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    id="add-sign-image"
                                                    style={{ marginLeft: "10px" }}
                                                    onClick={this.handleConvertToImage}
                                                >
                                                    Add
                                                </button>
                                            </Modal.Footer>
                                        </Modal>
                                    </Form>
                                )
                            }}
                        </Formik >
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    selectedRecommendedTaskData: state.dailytask.selectedRecommendedTaskData,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    editRecommendedTask: bindActionCreators(editRecommendedTask, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ApprovedRecommendedTask);
