import { AnyAction } from "redux";
import { IAttendanceType } from "../../types/attendance.type";
import { TAttendanceTable } from "../../types/user.type";
import { ICleaner } from "./TaskTemplateActionTypes";

export const API_GET_ALL_ATTENDANCES = "get_all_attendance/LOAD";
export const API_GET_ALL_ATTENDANCES_SUCCESS = "get_all_attendance/LOAD_SUCCESS";
export const API_GET_ALL_ATTENDANCES_FAIL = "get_all_attendance/LOAD_FAIL";
export const API_ADD_ATTENDANCE = "attendance/LOAD";
export const API_ADD_ATTENDANCE_SUCCESS = "attendance/LOAD_SUCCESS";
export const API_ADD_ATTENDANCE_FAIL = "attendance/LOAD_FAIL";
export const API_ADD_ATTENDANCE_PROGRESS = "API_ADD_ATTENDANCE_PROGRESS";
export const UPDATE_ADD_ATTENDANCE_DLG_STATUS = "UPDATE_ADD_ATTENDANCE_DLG_STATUS";
export const API_EDIT_ATTENDANCE = "edit_attendance/LOAD";
export const API_EDIT_ATTENDANCE_SUCCESS = "edit_attendance/LOAD_SUCCESS";
export const API_EDIT_ATTENDANCE_FAIL = "edit_attendance/LOAD_FAIL";
export const API_EDIT_ATTENDANCE_PROGRESS = "API_EDIT_ATTENDANCE_PROGRESS"
export const UPDATE_EDIT_ATTENDANCE_DLG_STATUS =
  "UPDATE_EDIT_ATTENDANCE_DLG_STATUS";
export const API_DELETE_ATTENDANCE = "delete_attendance/LOAD";
export const API_DELETE_ATTENDANCE_SUCCESS = "delete_attendance/LOAD_SUCCESS";
export const API_DELETE_ATTENDANCE_FAIL = "delete_attendance/LOAD_FAIL";
export const UPDATE_DELETE_ATTENDANCE_DLG_STATUS =
  "UPDATE_DELETE_ATTENDANCE_DLG_STATUS";
export const API_GET_ATTENDANCE_BY_ID = "attendance_by_id/LOAD";
export const API_GET_ATTENDANCE_BY_ID_SUCCESS = "attendance_by_id/LOAD_SUCCESS";
export const API_GET_ATTENDANCE_BY_ID_FAIL = "attendance_by_id/LOAD_FAIL";
export const UPDATE_ATTENDANCE_FETCH_STATUS = "UPDATE_ATTENDANCE_FETCH_STATUS";
export const FILTER_ATTENDANCE = "FILTER_STATUS";
export const UPDATE_ATTENDANCES = "UPDATE_ATTENDANCES";
export const UPDATE_ATTENDANCE_DLG_STATUS = "UPDATE_ATTENDANCE_DLG_STATUS";
// export const API_DELETE_ATTACHMENT = "ATTACHMENT_DELETE";
export const API_VIEW_ATTENDANCE_BY_ID = "view_attendance/LOAD";
export const API_VIEW_ATTENDANCE_BY_ID_SUCCESS = "view_attendance/LOAD_SUCCESS";
export const API_VIEW_ATTENDANCE_BY_ID_FAIL = "view_attendance/LOAD_FAIL";
export const UPDATE_VIEW_ATTENDANCE = "UPDATE_VIEW_ATTENDANCE";
export const ATTENDANCE_SORT = "ATTENDANCE_SORT";

export interface IAddAttendance {
  client: string;
  attachments: any | Blob;
}

export interface IEditAttendance {
  client: string;
  branch: string;
  title: string;
  is_active: string;
  attachments: any | Blob;
  description: string;
}

export interface AttendanceTemplateState {
  cleanerList: ICleaner[];
  clientList: ICleaner[];
  addAttendanceStatus: boolean;
  editAttendanceStatus: boolean;
  deleteAttendanceStatus: boolean;
  attendanceFetchStatus: boolean;
  addAttendanceSuccess: string;
  addAttendanceError: string;
  editAttendanceSuccess: string;
  editAttendanceError: string;
  deleteAttendanceSuccess: string;
  deleteAttendanceError: string;
  selectedAttendanceData: IAttendanceType | null;
  id: string;
  attendanceData: TAttendanceTable[];
  filteredAttendanceData: TAttendanceTable[];
  total_count: number;
  processTrigger:boolean;
  progressPercentage:number;
  viewAttendanceData: IAttendanceType | null;
  viewAttendanceStatus: boolean;
  attendanceSortBy : string;
  attendanceOrderBy : string;
}

export type AttendanceTemplateDispatchType = (args: AnyAction) => AnyAction;
