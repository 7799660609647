import { Dispatch, AnyAction } from "redux";
import { AppDispatch } from "../..";
import { TAuditTable } from "../../types/user.type";
import {
  AuditTemplateState,
  API_GET_ALL_AUDIT,
  API_GET_ALL_AUDIT_SUCCESS,
  API_GET_ALL_AUDIT_FAIL,
  API_GET_INSPECTION_BY_ID,
  API_GET_INSPECTION_BY_ID_SUCCESS,
  API_GET_INSPECTION_BY_ID_FAIL,
  API_ADD_INSPECTION,
  API_ADD_INSPECTION_SUCCESS,
  API_ADD_INSPECTION_FAIL,
  API_EDIT_INSPECTION,
  API_EDIT_INSPECTION_SUCCESS,
  API_EDIT_INSPECTION_FAIL,
  API_DELETE_INSPECTION,
  API_DELETE_INSPECTION_SUCCESS,
  API_DELETE_INSPECTION_FAIL,
  API_DELETE_AUDIT_COMMENTS,
  API_DELETE_AUDIT_COMMENTS_SUCCESS,
  API_DELETE_AUDIT_COMMENTS_FAIL,
  UPDATE_ADD_INSPECTION_DLG_STATUS,
  UPDATE_EDIT_INSPECTION_DLG_STATUS,
  UPDATE_DELETE_INSPECTION_DLG_STATUS,
  UPDATE_INSPECTION_FETCH_STATUS,
  FILTER_INSPECTION,
  UPDATE_AUDIT,
  IEditAudit,
  API_ADD_INSPECTION_PROGRESS,
  API_EDIT_INSPECTION_PROGRESS,
  API_VIEW_INSPECTION_BY_ID,
  API_VIEW_INSPECTION_BY_ID_FAIL,
  API_VIEW_INSPECTION_BY_ID_SUCCESS,
  UPDATE_VIEW_INSPECTION,
  INSPECTION_SORT,
  API_ADD_INSPECTION_COMMENT,
  API_ADD_INSPECTION_COMMENT_FAIL,
  API_ADD_INSPECTION_COMMENT_SUCCESS,
  API_GET_ALL_AUDIT_REPORT,
  API_GET_ALL_AUDIT_REPORT_SUCCESS,
  API_GET_ALL_AUDIT_REPORT_FAIL
} from "../ActionTypes";

const initialState: AuditTemplateState = {
  cleanerList: [],
  addAuditStatus: false,
  addAuditSuccess: "",
  deleteAuditStatus: false,
  deleteAuditSuccess: "",
  editAuditStatus: false,
  editAuditSuccess: "",
  auditFetchStatus: false,
  selectedAuditData: null,
  auditData: [],
  filteredAuditData: [],
  id: "",
  total_count: 0,
  addAuditError: "",
  deleteAuditError: "",
  editAuditError: "",
  processTrigger: false,
  progressPercentage: 0,
  viewAuditData: null,
  viewAuditStatus: false,
  auditSortBy: "",
  auditOrderBy: "",
  addCommentStatus: false,
  comment_audit_delete: "",
  auditReportData: []
};

export const AuditReducer = (
  state: AuditTemplateState = initialState,
  action: AnyAction
): AuditTemplateState => {
  switch (action.type) {
    case UPDATE_AUDIT:
      return { ...state };
    case UPDATE_INSPECTION_FETCH_STATUS:
      return { ...state, auditFetchStatus: action.payload };
    case API_GET_ALL_AUDIT:
      return { ...state };
    case API_GET_ALL_AUDIT_SUCCESS:
      let auditData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "audit" in data && data.audit) {
          auditData = data.audit;
        }
      }
      let total_count = 0;
      if (action.payload) {
        const { data } = action.payload;
        if (data && "total_count" in data && data.total_count) {
          total_count = data.total_count;
        }
      }
      console.log(state.total_count, "state.total");

      return {
        ...state,
        total_count: total_count,
        editAuditStatus: false,
        addAuditStatus: false,
        deleteAuditStatus: false,
        filteredAuditData: auditData,
      };
    case API_GET_ALL_AUDIT_FAIL:
      return { ...state };
    case API_GET_INSPECTION_BY_ID:
      return { ...state, id: action.payload.id };
    case API_GET_INSPECTION_BY_ID_SUCCESS:
      let audits = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "audit" in data && data.audit) {
          audits = data.audit;
        }
      }
      return {
        ...state,
        selectedAuditData: audits,
        editAuditStatus: true,
        auditFetchStatus: false,
      };
    case API_GET_INSPECTION_BY_ID_FAIL:
      return { ...state };
    case API_VIEW_INSPECTION_BY_ID:
      return { ...state, id: action.payload.id };
    case API_VIEW_INSPECTION_BY_ID_SUCCESS:
      let audit = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "audit" in data && data.audit) {
          audit = data.audit;
        }
      }
      return {
        ...state,
        viewAuditData: audit,
        viewAuditStatus: true,
        auditFetchStatus: false,
      };
    case API_VIEW_INSPECTION_BY_ID_FAIL:
      return { ...state };
    case UPDATE_VIEW_INSPECTION:
      return { ...state, viewAuditStatus: action.payload };
    case API_ADD_INSPECTION:
      let values: boolean = false;
      if (action.payload.request.data.FormData === undefined) {
        values = true;
      }
      return {
        ...state,
        addAuditSuccess: "",
        addAuditError: "",
        processTrigger: values,
      };
    case API_ADD_INSPECTION_SUCCESS:
      let message = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          message = data.message;
        }
      }
      return {
        ...state,
        addAuditStatus: false,
        addAuditSuccess: message,
        processTrigger: false,
        progressPercentage: 0,
      };
    case API_ADD_INSPECTION_FAIL:
      let err = "";
      if (
        "error" in action &&
        action.error &&
        "response" in action.error &&
        action.error.response &&
        "data" in action.error.response &&
        action.error.response.data &&
        "message" in action.error.response.data
      ) {
        err = action.error.response.data.message;
      }
      return { ...state, addAuditError: err };
    case API_DELETE_AUDIT_COMMENTS:
      return { ...state };
    case API_DELETE_AUDIT_COMMENTS_SUCCESS:
      return { ...state, comment_audit_delete: "SuccessFully Deleted" };
    case API_DELETE_AUDIT_COMMENTS_FAIL:
      return { ...state };
    case API_ADD_INSPECTION_PROGRESS:
      return { ...state, progressPercentage: action.payload };
    case API_EDIT_INSPECTION_PROGRESS:
      return { ...state, progressPercentage: action.payload };
    case UPDATE_ADD_INSPECTION_DLG_STATUS:
      return { ...state, addAuditStatus: action.payload };
    case API_EDIT_INSPECTION:
      let value: boolean = true;
      return { ...state, editAuditSuccess: "", processTrigger: value };
    case API_EDIT_INSPECTION_SUCCESS:
      let messages = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          messages = data.message;
        }
      }
      return {
        ...state,
        editAuditStatus: false,
        auditFetchStatus: true,
        editAuditSuccess: messages,
        processTrigger: false,
        progressPercentage: 0,
      };
    case API_EDIT_INSPECTION_FAIL:
      let error = "";
      if (
        "error" in action &&
        action.error &&
        "response" in action.error &&
        action.error.response &&
        "data" in action.error.response &&
        action.error.response.data &&
        "message" in action.error.response.data
      ) {
        error = action.error.response.data.message;
      }
      return { ...state, editAuditError: error };
    case UPDATE_EDIT_INSPECTION_DLG_STATUS:
      return { ...state, editAuditStatus: action.payload };
    case API_DELETE_INSPECTION:
      return { ...state, deleteAuditSuccess: "" };
    case API_DELETE_INSPECTION_SUCCESS:
      let msg = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          msg = data.message;
        }
      }
      return {
        ...state,
        deleteAuditStatus: false,
        auditFetchStatus: true,
        deleteAuditSuccess: msg,
      };
    case API_DELETE_INSPECTION_FAIL:
      return { ...state };
    case UPDATE_DELETE_INSPECTION_DLG_STATUS:
      return { ...state, deleteAuditStatus: action.payload };
    case FILTER_INSPECTION:
      const filterAudit: TAuditTable[] = state.filteredAuditData.filter(
        (obj: any) => {
          return obj.name.toLowerCase().includes(action.payload);
          // ||
          // obj.first_name.includes(action.payload) ||
          // obj.last_name.includes(action.payload)
        }
      );
      return { ...state, filteredAuditData: filterAudit };
    case INSPECTION_SORT:
      return {
        ...state,
        auditSortBy: action.payload.sort,
        auditOrderBy: action.payload.order,
      };
    case API_ADD_INSPECTION_COMMENT:
      return { ...state, addCommentStatus: false };
    case API_ADD_INSPECTION_COMMENT_SUCCESS:
      return { ...state, addCommentStatus: true };
    case API_ADD_INSPECTION_COMMENT_FAIL:
      return {...state};
    case API_GET_ALL_AUDIT_REPORT:
        return { ...state, addCommentStatus: false };
    case API_GET_ALL_AUDIT_REPORT_SUCCESS:
      
      let audReport:any = [];
      let totalReport:any = 0
      if (action.payload) {
        const { data } = action.payload;
        if (data && "audit" in data && data.audit) {
          audReport = data.audit;
          totalReport = data.total_count
        }
      }
      return { ...state, auditReportData: audReport, total_count: totalReport };
    case API_GET_ALL_AUDIT_REPORT_FAIL:
        return {...state};
    default:
      return state;
  }
};
// update audit
export function updateAudits(data: any) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_AUDIT,
      payload: data,
    });
  };
}

// get audit by id
export function getAuditById(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_INSPECTION_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `audit/${id}` : `audit`,
        },
        id,
      },
    });
  };
}

// VIEW audit by id
export function viewAuditById(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_VIEW_INSPECTION_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `audit/${id}` : `audit`,
        },
        id,
      },
    });
  };
}

export function updateViewAudit(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_VIEW_INSPECTION,
      payload: status,
    });
  };
}

// get all audits for table
export function getAllAudits(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ALL_AUDIT,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `audit?${queryStr}` : `audit?`,
        },
      },
    });
  };
}

// get all audits for table
export function getAllAuditReports(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ALL_AUDIT_REPORT,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `audit/report?${queryStr}` : `audit?`,
        },
      },
    });
  };
}

// add audit
export function addAudit(payload: FormData) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_ADD_INSPECTION,
      payload: {
        request: {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            contentType: false,
          },
          method: "post",
          url: `audit`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_ADD_INSPECTION_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

export function updateAddAudit(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_ADD_INSPECTION_DLG_STATUS,
      payload: status,
    });
  };
}

// edit audit
export function editAudit(payload: IEditAudit, id: number) {
  console.log("payload", payload);

  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_EDIT_INSPECTION,
      payload: {
        request: {
          method: "put",
          url: `audit/${id}`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_EDIT_INSPECTION_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

// update edit employee
export function updateEditAuditDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_EDIT_INSPECTION_DLG_STATUS,
      payload: status,
    });
  };
}

// Fetch Audit after add, edit, delete
export function updateAuditFetchStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_INSPECTION_FETCH_STATUS,
      payload: status,
    });
  };
}

// delete audit
export function deleteAudit(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_DELETE_INSPECTION,
      payload: {
        request: {
          method: "delete",
          url: `audit/${id}`,
        },
      },
    });
  };
}

// delete audit
export function deleteAuditComments(id: number) {  
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_DELETE_AUDIT_COMMENTS,
      payload: {
        request: {
          method: "delete",
          url: `comments/${id}`,
        },
      },
    });
  };
}
// update delete audit
export function updateDeleteAuditDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_DELETE_INSPECTION_DLG_STATUS,
      payload: status,
    });
  };
}

// Filter value
export function filterAudit(filterVal: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: FILTER_INSPECTION,
      payload: filterVal,
    });
  };
}

// handleAuditSort
export function handleAuditSort(sort: any, order: any) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: INSPECTION_SORT,
      payload: {
        sort: sort,
        order: order,
      },
    });
  };
}

export function updateSatisfiedStatus(status: number, task_id: string) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_EDIT_INSPECTION,
      payload: {
        request: {
          method: "put",
          url: `audit/satisfied-status/${task_id}/${status}`,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_EDIT_INSPECTION_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

// Add Comment
// export function addAuditComments(payload: FormData) {
//   return async (dispatch: Dispatch) => {
//     dispatch({
//       type: API_ADD_INSPECTION_COMMENT,
//       payload: {
//         request: {
//           method: "post",
//           url: `audit/audit-comments`,
//           data: payload,
//         },
//       },
//     });
//   };
// }
