import { AnyAction } from "redux";
import { IComplaintType } from "../../types/complaint.type";
import { TComplaintTable } from "../../types/user.type";
import { ICleaner } from "./TaskTemplateActionTypes";

export const API_GET_ALL_COMPLAINTS = "get_all_complaint/LOAD";
export const API_GET_ALL_COMPLAINTS_SUCCESS = "get_all_complaint/LOAD_SUCCESS";
export const API_GET_ALL_COMPLAINTS_FAIL = "get_all_complaint/LOAD_FAIL";
export const API_ADD_COMPLAINT = "complaint/LOAD";
export const API_ADD_COMPLAINT_SUCCESS = "complaint/LOAD_SUCCESS";
export const API_ADD_COMPLAINT_FAIL = "complaint/LOAD_FAIL";
export const API_ADD_COMPLAINT_MESSAGE = "complaint_message/LOAD";
export const API_ADD_COMPLAINT_MESSAGE_SUCCESS = "complaint_message/LOAD_SUCCESS";
export const API_ADD_COMPLAINT_MESSAGE_FAIL = "complaint_message/LOAD_FAIL";
export const API_ADD_COMPLAINT_PROGRESS = "API_ADD_COMPLAINT_PROGRESS";
export const UPDATE_ADD_COMPLAINT_DLG_STATUS = "UPDATE_ADD_COMPLAINT_DLG_STATUS";
export const UPDATE_CHAT_COMPLAINT_DLG_STATUS = "UPDATE_CHAT_COMPLAINT_DLG_STATUS";
export const API_EDIT_COMPLAINT = "edit_complaint/LOAD";
export const API_EDIT_COMPLAINT_SUCCESS = "edit_complaint/LOAD_SUCCESS";
export const API_EDIT_COMPLAINT_FAIL = "edit_complaint/LOAD_FAIL";
export const API_EDIT_COMPLAINT_PROGRESS = "API_EDIT_COMPLAINT_PROGRESS"
export const UPDATE_EDIT_COMPLAINT_DLG_STATUS =
  "UPDATE_EDIT_COMPLAINT_DLG_STATUS";
export const API_DELETE_COMPLAINT = "delete_complaint/LOAD";
export const API_DELETE_COMPLAINT_SUCCESS = "delete_complaint/LOAD_SUCCESS";
export const API_DELETE_COMPLAINT_FAIL = "delete_complaint/LOAD_FAIL";
export const UPDATE_DELETE_COMPLAINT_DLG_STATUS =
  "UPDATE_DELETE_COMPLAINT_DLG_STATUS";
export const API_GET_COMPLAINT_BY_ID = "complaint_by_id/LOAD";
export const API_GET_COMPLAINT_BY_ID_SUCCESS = "complaint_by_id/LOAD_SUCCESS";
export const API_GET_COMPLAINT_BY_ID_FAIL = "complaint_by_id/LOAD_FAIL";
export const API_GET_COMPLAINT_BY_ID_FOR_CHAT = "chat_complaint_by_id/LOAD";
export const API_GET_COMPLAINT_BY_ID_FOR_CHAT_SUCCESS = "chat_complaint_by_id/LOAD_SUCCESS";
export const API_GET_COMPLAINT_BY_ID_FOR_CHAT_FAIL = "chat_complaint_by_id/LOAD_FAIL";
export const UPDATE_COMPLAINT_FETCH_STATUS = "UPDATE_COMPLAINT_FETCH_STATUS";
export const FILTER_COMPLAINT = "FILTER_STATUS";
export const UPDATE_COMPLAINTS = "UPDATE_COMPLAINTS";
export const UPDATE_COMPLAINT_DLG_STATUS = "UPDATE_COMPLAINT_DLG_STATUS";
export const API_VIEW_COMPLAINT_BY_ID = "view_complaint/LOAD";
export const API_VIEW_COMPLAINT_BY_ID_SUCCESS = "view_complaint/LOAD_SUCCESS";
export const API_VIEW_COMPLAINT_BY_ID_FAIL = "view_complaint/LOAD_FAIL";
export const UPDATE_VIEW_COMPLAINT = "UPDATE_VIEW_COMPLAINT";
export const COMPLAINT_SORT = "COMPLAINT_SORT";
export const API_MESSAGE_STATUS_CHANGE = "MESSAGE_STATUS_CHANGE"

export interface IAddComplaint {
  client: string;
  attachments: any | Blob;
}

export interface IEditComplaint {
  client: string;
  branch: string;
  title: string;
  is_active: string;
  attachments: any | Blob;
  description: string;
}

export interface ComplaintTemplateState {
  cleanerList: ICleaner[];
  clientList: ICleaner[];
  addComplaintStatus: boolean;
  chatComplaintStatus: boolean;
  editComplaintStatus: boolean;
  deleteComplaintStatus: boolean;
  complaintFetchStatus: boolean;
  addComplaintSuccess: string;
  addComplaintError: string;
  editComplaintSuccess: string;
  editComplaintError: string;
  deleteComplaintSuccess: string;
  deleteComplaintError: string;
  selectedComplaintData: IComplaintType | null;
  id: string;
  complaintData: TComplaintTable[];
  filteredComplaintData: TComplaintTable[];
  total_count: number;
  processTrigger: boolean;
  progressPercentage: number;
  viewComplaintData: IComplaintType | null;
  viewComplaintStatus: boolean;
  complaintSortBy: string;
  complaintOrderBy: string;
  messageSendSuccess: boolean;
  msgStatusChange: any
}

export type ComplaintTemplateDispatchType = (args: AnyAction) => AnyAction;
