import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  addComments,
  getAllDailyTasks,
  getTaskHistoryById,
  getUsermanagementById,
} from "../../shared/Reducers";
import Button from "@material-ui/core/Button";
import { PaperClipSVG, TrashSVG } from "../../assets/images/ImagesV2";
import Constants from "../../shared/Constants";
import { CustomSelect } from "../../shared/Components/CustomSelect";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";
import docImage from "../../assets/images/google-docs.png";

export interface IAddCommentsProps {
  addComments: any;
  task_id: any;
  getAllDailyTasks?: any;
  special_request_task_status: any;
  task_type: any;
  task_status: any;
  getTaskHistoryById?: any;
  getUsermanagementById?: any;
  userLoginTaskstatus?: any;
}

export interface IAddCommentsState {
  attachmentFiles: any;
  attachmentUrls: any;
  optionselect: any;
}

class AddComments extends React.Component<
  IAddCommentsProps,
  IAddCommentsState
> {
  constructor(props: IAddCommentsProps) {
    super(props);
    this.state = {
      attachmentFiles: [],
      attachmentUrls: [],
      optionselect: [],
    };
  }

  componentWillUnmount(): void {
    this.setState({
      attachmentFiles: [],
      attachmentUrls: [],
    });
  }
  async componentDidMount() {

    this.setState({
      optionselect: this.props.userLoginTaskstatus.map((i: any) => ({
        value: i.id,
        label: i.name,
      })),
    });
  }

  handleAddComment = async (formValues: any) => {
    console.log(formValues);

    let data = new FormData();
    for (let index = 0; index < this.state.attachmentFiles.length; index++) {
      data.append("attachments", this.state.attachmentFiles[index]);
    }
    data.append("comments", formValues.comments);
    data.append("comments_task_status", formValues.task_status);
    data.append("reference_id", this.props.task_id);
    data.append("task_type", this.props.task_type);
    data.append("reference_type", "task");

    await this.props.addComments(data);

    setTimeout(async () => {
      const dirtyFormID = "addCommentsFormReset";
      let resetForm: HTMLFormElement = document.getElementById(
        dirtyFormID
      ) as HTMLFormElement;
      resetForm.reset();
      await this.props.getTaskHistoryById(this.props.task_id);
      // const query = `search=&sort=&order=&offset=0&limit=10&client=all&branch=all&task_type=all`
      // await this.props.getAllDailyTasks(query);

      this.setState({ attachmentFiles: [] });
      this.setState({ attachmentUrls: [] });
    }, 1000);
  };

  handleImageChange = async (e: any) => {
    let files = [];
    let urls = [];
    files = [...e.target.files];

    let fileurls: any = [];
    await files.map(async (file: any) => {
      fileurls.push({
        url: URL.createObjectURL(file),
        file_type: file.type,
      });
    });
    urls = [...fileurls];
    this.setState({
      attachmentFiles: [...this.state.attachmentFiles, ...files],
    });
    this.setState(
      { attachmentUrls: [...this.state.attachmentUrls, ...urls] },
      () => {
        e.target.value = "";
      }
    );
  };

  handleDeleteImage = (index: number) => {
    const files = this.state.attachmentFiles;
    const urls = this.state.attachmentUrls;
    files.splice(index, 1);
    urls.splice(index, 1);
    this.setState({ attachmentFiles: files });
    this.setState({ attachmentUrls: urls });
  };

  validationSchema = () => {
    return Yup.object().shape({
      comments: Yup.string().required("Please enter comments"),
      task_status: Yup.string().required("Please select task status"),
      task_list: Yup.string(),
      attachments: Yup.mixed(),
    });
  };

  render(): React.ReactNode {
    //  Special request supervisor Activity options
    // if (
    //   this.props.task_type === Constants.SPECIAL_REQUEST &&
    //   (Constants.LOGIN_USER_TYPE === Constants.SUPERVISORUTINT ||
    //     Constants.LOGIN_USER_TYPE === Constants.SUPERVISOR_MANAGERUTINT)
    // ) {
    //   taskStatusOption = [
    //     { value: Constants.TASK_FOLLOWUP, label: "Followup" },
    //     { value: Constants.TASK_RESOLVED, label: "Resolved" },
    //   ];
    // }
    // // special request QA and admin or BM activity options
    // else if (
    //   this.props.task_type === Constants.SPECIAL_REQUEST &&
    //   (Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
    //     Constants.OPERATION_MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
    //     Constants.QAUTINT === Constants.LOGIN_USER_TYPE ||
    //     Constants.QA_MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
    //     Constants.ADMINUTINT === Constants.LOGIN_USER_TYPE) &&
    //   this.props.special_request_task_status === Constants.TASK_RESOLVED
    // ) {
    //   taskStatusOption = [
    //     { value: Constants.TASK_COMPLETED, label: "Completed" },
    //     { value: Constants.TASK_INCOMPLETED, label: "InCompleted" },
    //   ];
    // }
    // // regular request admin supervisor activity options
    // else if (
    //   this.props.task_type === Constants.REGULAR_TASK &&
    //   (Constants.SUPERVISORUTINT === Constants.LOGIN_USER_TYPE ||
    //     Constants.ADMINUTINT === Constants.LOGIN_USER_TYPE)
    // ) {
    //   taskStatusOption = [
    //     { value: Constants.TASK_INPROGRESS, label: "In progress" },
    //     { value: Constants.TASK_COMMENTED, label: "Commented" },
    //   ];
    // }

    const initialValues = {
      comments: "",
      task_status: "",
      task_list: "",
      attachments: "",
    };
    return (
      <div className="mt-2">
        <Formik
          validationSchema={this.validationSchema}
          onSubmit={this.handleAddComment}
          initialValues={initialValues}
        >
          <Form id="addCommentsFormReset">
            <div className="row">
              <div className="col-md-12 col-lg-9">
                <Field
                  as="textarea"
                  className="form-control comment-field w-100"
                  name="comments"
                  placeholder="Add a comment..."
                />
                <ErrorMessage
                  name={"comments"}
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="col-md-12 col-lg-3">
                <div className="mb-3 ipad-mt-3">
                  <Field
                    className="custom-select dropdown-field"
                    name="task_status"
                    options={this.state.optionselect}
                    component={CustomSelect}
                    placeholder="Select Status"
                    isMulti={false}
                  />
                  <ErrorMessage
                    name={"task_status"}
                    component={"div"}
                    className="cus-alert"
                  />
                  {/* <Field as="select" className="select-status w-100" name="task_status">
                                        <option value={""} disabled>Select Status</option>
                                        <option value={Constants.TASK_CREATED}>To do</option>
                                        <option value={Constants.TASK_INPROGRESS}>In progress</option>
                                        <option value={Constants.TASK_COMPLETED}>Completed</option>
                                        <option value={Constants.TASK_REOPENED}>Re-open</option>
                                        <option value={Constants.TASK_RESOLVED}>Resolved</option>
                                        <option value={Constants.TASK_RESOLVED}>followup</option>
                                    </Field> */}
                </div>
                <div className="attachment-btn">
                  <input
                    type="file"
                    multiple
                    accept=".pdf,.PDF,.mp4,.MP4,.webm,.WEBM,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.doc,.DOC,.docx.DOCX"
                    style={{ display: "none" }}
                    onChange={this.handleImageChange}
                    id="contained-button-file"
                  />
                  <label
                    htmlFor="contained-button-file"
                    style={{ width: "100%" }}
                  >
                    <Button
                      variant="contained"
                      component="span"
                      style={{ width: "100%", justifyContent: "left" }}
                    >
                      <PaperClipSVG /> Attach
                    </Button>
                  </label>
                </div>
              </div>
              <div className="col-md-12">

              </div>
            </div>
            <div className="row mt-3 mb-1">
              {this.state.attachmentUrls.length > 0 ? (
                <div className="mb-2">
                  <label className="form-label m-0">Attachments</label>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex row">
                  {this.state.attachmentUrls &&
                    this.state.attachmentUrls.map((url: any, index: number) => (
                      <div className="img-card mr-16px mb-3">
                        <span className="delete">
                          <a onClick={() => this.handleDeleteImage(index)}>
                            <TrashSVG />
                          </a>
                        </span>
                        <div className="img-preview">
                          <img
                            className="img-thumb-perview"
                            src={
                              url.file_type === "application/pdf"
                                ? pdfImage
                                : url.file_type === "video/mp4" ||
                                  url.file_type === "video/webm"
                                  ? videoImage
                                  : url.file_type === "image/png" ||
                                    url.file_type === "image/jpeg" ||
                                    url.file_type === "image/jpg"
                                    ? url.url
                                    : docImage
                            }
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <button
                  type="submit"
                  id="create-button"
                  className="btn btn-primary create-button"
                >
                  Save{" "}
                  <span
                    id="spinner-border"
                    className="spinner-border spinner-border-sm d-none"
                  ></span>
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  userLoginTaskstatus: state.sidebar.userLoginTaskstatus,
});
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getTaskHistoryById: bindActionCreators(getTaskHistoryById, dispatch),
    getAllDailyTasks: bindActionCreators(getAllDailyTasks, dispatch),
    getUsermanagementById: bindActionCreators(getUsermanagementById, dispatch),
    addComments: bindActionCreators(addComments, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddComments);
