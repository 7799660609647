import { AnyAction } from "redux";
import { IBranch } from "../../types/branch.type";
import { TBranchTable } from "../../types/user.type";

export const UPDATE_BRANCH = "UPDATE_BRANCH";
export const API_GET_ALL_BRANCHES = "get_all_branches/LOAD";
export const API_GET_ALL_BRANCHES_SUCCESS = "get_all_branches/LOAD_SUCCESS";
export const API_GET_ALL_BRANCHES_FAIL = "get_all_branches/LOAD_FAIL";
export const API_GET_BRANCH_BY_ID = "get_branch_by_id/LOAD";
export const API_GET_BRANCH_BY_ID_SUCCESS = "get_branch_by_id/LOAD_SUCCESS";
export const API_GET_BRANCH_BY_ID_FAIL = "get_branch_by_id/LOAD_FAIL";
export const API_GET_BRANCH_BY_ID_VIEW = "get_branch_by_id_view/LOAD";
export const API_GET_BRANCH_BY_ID_VIEW_SUCCESS = "get_branch_by_id_view/LOAD_SUCCESS";
export const API_GET_BRANCH_BY_ID_VIEW_FAIL = "get_branch_by_id_view/LOAD_FAIL";
export const API_GET_BRANCHES = "branch/LOAD";
export const API_GET_BRANCHES_SUCCESS = "branch/LOAD_SUCCESS";
export const API_GET_BRANCHES_FAIL = "branch/LOAD_FAIL";
export const API_ADD_BRANCHES = "add_branch/LOAD";
export const API_ADD_BRANCHES_SUCCESS = "add_branch/LOAD_SUCCESS";
export const API_ADD_BRANCHES_FAIL = "add_branch/LOAD_FAIL";
export const UPDATE_BRANCH_DLG_STATUS = "UPDATE_BRANCH_DLG_STATUS";
export const API_EDIT_BRANCH = "edit_branch/LOAD";
export const API_EDIT_BRANCH_SUCCESS = "edit_branch/LOAD_SUCCESS";
export const API_EDIT_BRANCH_FAIL = "edit_branch/LOAD_FAIL";
export const UPDATE_EDIT_BRANCH_DLG_STATUS = "UPDATE_EDIT_BRANCH_DLG_STATUS";
export const API_VIEW_BRANCH = "view_branch/LOAD";
export const API_VIEW_BRANCH_SUCCESS = "view_branch/LOAD_SUCCESS";
export const API_VIEW_BRANCH_FAIL = "view_branch/LOAD_FAIL";
export const UPDATE_VIEW_BRANCH_DLG_STATUS = "UPDATE_VIEW_BRANCH_DLG_STATUS";
export const UPDATE_BRANCH_FETCH_STATUS = "UPDATE_BRANCH_FETCH_STATUS";
export const API_DELETE_BRANCH = "delete_branch/LOAD";
export const API_DELETE_BRANCH_SUCCESS = "delete_branch/LOAD_SUCCESS";
export const API_DELETE_BRANCH_FAIL = "delete_branch/LOAD_FAIL";
export const UPDATE_DELETE_BRANCH_DLG_STATUS =
  "UPDATE_DELETE_BRANCH_DLG_STATUS";
export const FILTER_BRANCH = "FILTER_BRANCH";
export const API_ADD_BRANCH_PROGRESS = "API_ADD_BRANCH_PROGRESS";
export const API_EDIT_BRANCH_PROGRESS = "API_EDIT_BRANCH_PROGRESS";

export interface BranchState {
  branches: IBranch[];
  addBranchError: string;
  addBranchStatus: boolean;
  addBranchSuccess:string;
  editBranchStatus: boolean;
  editBranchError: string;
  editBranchSuccess:string;
  viewBranchStatus: boolean;
  branchFetchStatus: boolean;
  deleteBranchStatus: boolean;
  deleteBranchSuccess:string;
  selectedData: IBranch | null;
  viewData:IBranch | null;
  id: string;
  data: TBranchTable[];
  filteredData: TBranchTable[];
  total_count:number;
  processTrigger:boolean,
  progressPercentage:number,
}

// export interface AnyAction {
//     type: string;
//     payload: IBranch[];
// }

export interface IAddBranch {
  name: string;
  lat?: string;
  lng?: string;
  image: any;
  is_active: any;
  address: any;
  client: any;
}

export interface IEditBranch {
  id?: number;
  name: string;
  lat?: number;
  lng?: number;
}

export interface IDeleteBranch {
  id?: number;
}

export type BranchDispatchType = (args: AnyAction) => AnyAction;
