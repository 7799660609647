import { AnyAction } from "redux";
import { IShiftType } from "../../types/shift.type";
import { TShiftTable } from "../../types/user.type";
import { ICleaner } from "./TaskTemplateActionTypes";

export const API_GET_ALL_SHIFTS = "get_all_shift/LOAD";
export const API_GET_ALL_SHIFTS_SUCCESS = "get_all_shift/LOAD_SUCCESS";
export const API_GET_ALL_SHIFTS_FAIL = "get_all_shift/LOAD_FAIL";
export const API_ADD_SHIFT = "shift/LOAD";
export const API_ADD_SHIFT_SUCCESS = "shift/LOAD_SUCCESS";
export const API_ADD_SHIFT_FAIL = "shift/LOAD_FAIL";
export const API_ADD_SHIFT_PROGRESS = "API_ADD_SHIFT_PROGRESS";
export const UPDATE_ADD_SHIFT_DLG_STATUS = "UPDATE_ADD_SHIFT_DLG_STATUS";
export const API_EDIT_SHIFT = "edit_shift/LOAD";
export const API_EDIT_SHIFT_SUCCESS = "edit_shift/LOAD_SUCCESS";
export const API_EDIT_SHIFT_FAIL = "edit_shift/LOAD_FAIL";
export const API_EDIT_SHIFT_PROGRESS = "API_EDIT_SHIFT_PROGRESS"
export const UPDATE_EDIT_SHIFT_DLG_STATUS =
  "UPDATE_EDIT_SHIFT_DLG_STATUS";
export const API_DELETE_SHIFT = "delete_shift/LOAD";
export const API_DELETE_SHIFT_SUCCESS = "delete_shift/LOAD_SUCCESS";
export const API_DELETE_SHIFT_FAIL = "delete_shift/LOAD_FAIL";
export const UPDATE_DELETE_SHIFT_DLG_STATUS =
  "UPDATE_DELETE_SHIFT_DLG_STATUS";
export const API_GET_SHIFT_BY_ID = "shift_by_id/LOAD";
export const API_GET_SHIFT_BY_ID_SUCCESS = "shift_by_id/LOAD_SUCCESS";
export const API_GET_SHIFT_BY_ID_FAIL = "shift_by_id/LOAD_FAIL";
export const UPDATE_SHIFT_FETCH_STATUS = "UPDATE_SHIFT_FETCH_STATUS";
export const FILTER_SHIFT = "FILTER_STATUS";
export const UPDATE_SHIFTS = "UPDATE_SHIFTS";
export const UPDATE_SHIFT_DLG_STATUS = "UPDATE_SHIFT_DLG_STATUS";
// export const API_DELETE_ATTACHMENT = "ATTACHMENT_DELETE";
export const API_VIEW_SHIFT_BY_ID = "view_shift/LOAD";
export const API_VIEW_SHIFT_BY_ID_SUCCESS = "view_shift/LOAD_SUCCESS";
export const API_VIEW_SHIFT_BY_ID_FAIL = "view_shift/LOAD_FAIL";
export const UPDATE_VIEW_SHIFT = "UPDATE_VIEW_SHIFT";
export const SHIFT_SORT = "SHIFT_SORT";

export interface IAddShift {
  client: string;
  attachments: any | Blob;
}

export interface IEditShift {
  client: string;
  branch: string;
  title: string;
  is_active: string;
  attachments: any | Blob;
  description: string;
}

export interface ShiftTemplateState {
  cleanerList: ICleaner[];
  addShiftStatus: boolean;
  editShiftStatus: boolean;
  deleteShiftStatus: boolean;
  shiftFetchStatus: boolean;
  addShiftSuccess: string;
  addShiftError: string;
  editShiftSuccess: string;
  editShiftError: string;
  deleteShiftSuccess: string;
  deleteShiftError: string;
  selectedShiftData: IShiftType | null;
  id: string;
  shiftData: TShiftTable[];
  filteredShiftData: TShiftTable[];
  total_count: number;
  processTrigger:boolean;
  progressPercentage:number;
  viewShiftData: IShiftType | null;
  viewShiftStatus: boolean;
  shiftSortBy : string;
  shiftOrderBy : string;
}

export type ShiftTemplateDispatchType = (args: AnyAction) => AnyAction;
