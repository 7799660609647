import React, { Component } from "react";

import { CloseBtnSVG } from "../../assets/images/ImagesV2";
import { Dispatch } from "redux";
import { IUserPermissionProps, IUserPermissionState } from "../../types/userManagement.type";
import { Modal } from "react-bootstrap";
import { RootState } from "../..";
import { Switch } from "@material-ui/core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updatePermission } from "../../shared/Reducers";
import Form from "react-bootstrap/Form";

interface UserPermissionPageItem {
  id: string;
  permission_page: string;
  permission_access: string;
  status: number;
}

interface GroupedDataItem {
  permission_page: string;
  permission_access: UserPermissionPageItem[];
}
class UserPermission extends React.Component<IUserPermissionProps, IUserPermissionState> {
  constructor(props: IUserPermissionProps) {
    super(props);
    this.state = {
      permissionPages: []
    };
  }
  componentDidMount(): void {
    const groupedData = this.groupDataByPermissionPage(this.props.UserPermissionPages);

    this.setState({
      permissionPages: groupedData
    })
  }

  groupDataByPermissionPage = (data: UserPermissionPageItem[]): GroupedDataItem[] => {
    const groupedData: { [key: string]: UserPermissionPageItem[] } = {};

    for (const item of data) {
      const { permission_page, ...rest } = item;
      if (!groupedData[permission_page]) {
        groupedData[permission_page] = [];
      }
      groupedData[permission_page].push(item);
    }

    return Object.keys(groupedData).map((key) => ({
      permission_page: key,
      permission_access: groupedData[key],
    }));
  };

  updatePermissionState = (pageInx: number, accessInx: number, status: number) => {
    let permissionPages = this.state.permissionPages;
    permissionPages[pageInx].permission_access[accessInx].status = status;
    this.setState({ permissionPages: permissionPages });
  }
  render() {
    return (
      <div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Module</th>
                <th scope="col">Page</th>
                <th scope="col">Access</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.permissionPages &&
                this.state.permissionPages.map((val: any, index: number) => (
                  <>
                    {
                      val.permission_access &&
                      val.permission_access.map((access: any, inx: number) => (
                        <tr>
                          {
                            inx === 0 && (
                              <td scope="col" className="text-capitalize" id="table-page" rowSpan={val.permission_access.length}>{val.permission_page}</td>
                            )
                          }
                          <td scope="col" className={`text-capitalize ${inx === val.permission_access.length - 1 ? 'last-data-cell' : ''}`} id="table-module">
                            {access.permission_access.replace(/[_-]/g, ' ')}
                          </td>
                          <td scope="col" id="table-access" className={inx === val.permission_access.length - 1 ? 'last-data-cell' : ''}>
                            <Form.Check
                              type="switch"
                              checked={access.status === 0 ? false : true}
                              onClick={() => {
                                this.props.updatePermission({
                                  id: access.id,
                                  status: access.status === 0 ? 1 : 0,
                                  type: access.type,
                                  user_id: access.user_id
                                });
                                this.updatePermissionState(index, inx, access.status === 0 ? 1 : 0);
                              }}
                            />
                          </td>
                        </tr>
                      ))
                    }
                  </>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  UserPermissionPages: state.usermanagement.UserPermissionPages,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updatePermission: bindActionCreators(updatePermission, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserPermission);
