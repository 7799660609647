import {
  AttachmentDownloadSVG,
  CloseBtnSVG,
} from "../../assets/images/ImagesSVG";
import { Dispatch, bindActionCreators } from "redux";
import {
  IViewDailyTaskProps,
  IViewDailyTaskState,
} from "../../types/dailyTask.type";

import AddComments from "./AddComments";
import AttachmentPreview from "../../shared/Components/AttachmentPreview";
import Constants from "../../shared/Constants";
import DisplayComments from "./DisplayComments";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
import docImage from "../../assets/images/google-docs.png";
import moment from "moment";
import pdfImage from "../../assets/images/pdf.png";
import {
  updateViewDailyTaskDlgStatus,
  getUserById,
} from "../../shared/Reducers";
import videoImage from "../../assets/images/video.png";

// import Button from "react-bootstrap/Button";

class ViewDailyTask extends React.Component<
  IViewDailyTaskProps,
  IViewDailyTaskState
> {
  constructor(props: IViewDailyTaskProps) {
    super(props);
    this.state = {
      attachmentFiles: "",
      attachmentUrls: "",
      AttachmentPreviewStatus: false,
      previewUrl: "",
      previewType: "",
      CreatedName: "",
      ModifiedName: "",
    };
  }

  downloadFile = (url: string, name = "image.png") => {
    this.downloadResource(url, name);
  };

  forceDownload = (blob: any, filename: String) => {
    const a: any = document.createElement("a");
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  viewTaskStatus = (statusKey: number) => {
    switch (statusKey) {
      case Constants.TASK_CREATED:
        return "Created";
      case Constants.TASK_INPROGRESS:
        return "In Progress";
      case Constants.TASK_COMPLETED:
        return "Completed";
      case Constants.TASK_COMMENTED:
        return "Commented";
      case Constants.TASK_INCOMPLETED:
        return "In Completed";
      default:
        return "Created";
    }
  };
  viewTaskStatusClassName = (statusKey: number) => {
    switch (statusKey) {
      case Constants.TASK_CREATED:
        return "todo-option mb-2";
      case Constants.TASK_INPROGRESS:
        return "inprogress-option mb-2";
      case Constants.TASK_COMPLETED:
        return "completed-option mb-2";
      case Constants.TASK_COMMENTED:
        return "reopen-option mb-2";
      case Constants.TASK_INCOMPLETED:
        return "not-complete-option mb-2";
      default:
        return "todo-option mb-2";
    }
  };

  viewTaskFeedback = (feedbackKey: number) => {
    switch (feedbackKey) {
      case Constants.TASK_RESOLVED:
        return "Resolved";
      case Constants.TASK_FOLLOWUP:
        return "Followup";
      default:
        return "";
    }
  };
  viewTaskFeedbackClassName = (feedbackKey: number) => {
    switch (feedbackKey) {
      case Constants.TASK_RESOLVED:
        return "font-weight-bold satisfied-option mb-2";
      case Constants.TASK_FOLLOWUP:
        return "font-weight-bold unsatisfied-option mb-2";
      default:
        return "";
    }
  };

  downloadResource = (url: any, filename: any) => {
    if (url) {
      if (!filename) filename = url.split("\\").pop().split("/").pop();
      fetch(url, {
        headers: new Headers({
          Origin: window.location.origin,
        }),
        mode: "cors",
      })
        .then((response) => response.blob())
        .then((blob) => {
          let blobUrl = window.URL.createObjectURL(blob);
          this.forceDownload(blobUrl, filename);
        })
        .catch((e) => console.error(e));
    }
  };

  handlePreviewClose = () => {
    this.setState({ AttachmentPreviewStatus: false });
  };
  handlePreviewOpen = (type: any, url: any) => {
    console.log("preview", type, url);

    if (
      type === "image/png" ||
      type === "image/jpeg" ||
      type === "image/jpg" ||
      type === "video/mp4" ||
      type === "video/webm" ||
      type === "application/pdf"
    ) {
      this.setState({
        AttachmentPreviewStatus: true,
        previewUrl: url,
        previewType: type,
      });
    }
  };
  render(): React.ReactNode {
    const {
      id,
      title,
      description,
      task_date,
      any_time,
      task_type,
      commants,
      cleaners,
      start_time,
      end_time,
      task_status,
      created_at,
      branch_name,
      first_name,
      last_name,
      attachments,
      task_status_name,
      task_feedback,
      created_by_user,
      modified_by_user,
    } = this.props.data;
    return (
      <div>
        <Modal
          size="xl"
          className="task-history"
          dialogClassName="modal-90w"
          aria-labelledby="contained-modal-title-vcenter"
          show={this.props.viewDailyTaskStatus}
          onHide={() => this.props.updateViewDailyTaskDlgStatus(false)}
        >
          <Modal.Header>
            <h3 className="mb-0">Task History</h3>
            <a
              href="javascript:void(0)"
              className="qr-modal-close"
              onClick={() => this.props.updateViewDailyTaskDlgStatus(false)}
            >
              <CloseBtnSVG />
            </a>
          </Modal.Header>
          <Modal.Body className="task-view">
            <div className="row task-history">
              <div className="col-md-12 col-lg-8 scroll-view">
                <h5 className="fW-500 fs-17px text-capitalize">{title}</h5>
                <div className="mt-3">
                  <label className="form-label fw-600">Description</label>
                  <p>{description}</p>
                </div>
                <div
                  className={
                    attachments && attachments.length > 0 ? "mt-3" : "d-none"
                  }
                >
                  <label className="form-label fw-600">
                    Attachments ({attachments ? attachments.length : "0"})
                  </label>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <div className="d-flex row">
                        {attachments &&
                          attachments.map((obj: any, index: number) => {
                            console.log(obj);
                            return (
                              <div className="img-card mr-16px mb-3">
                                <span className="download">
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() =>
                                      this.downloadFile(
                                        Constants.imgUrl + obj.file_name,
                                        obj.file_name
                                      )
                                    }
                                  >
                                    <AttachmentDownloadSVG />
                                  </a>
                                </span>
                                <div
                                  className="img-preview"
                                  onClick={() =>
                                    this.handlePreviewOpen(obj.type, obj.s3_url)
                                  }
                                >
                                  <img
                                    className="img-thumb-perview"
                                    src={
                                      obj.type === "application/pdf"
                                        ? pdfImage
                                        : obj.type === "video/mp4" ||
                                          obj.type === "video/webm"
                                        ? videoImage
                                        : obj.type === "image/png" ||
                                          obj.type === "image/jpeg" ||
                                          obj.type === "image/jpg"
                                        ? obj.s3_url
                                        : docImage
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="mt-2">
                    <label className="form-label fw-600">Activity</label>
                  </div>
                  <div>
                    <AddComments
                      task_id={id}
                      special_request_task_status={task_feedback}
                      task_type={task_type}
                      task_status={task_status}
                    />
                  </div>
                </div>
                {/* Special request QA and admin comments box */}
                {/* {task_type === Constants.SPECIAL_REQUEST &&
                  (Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
                    Constants.OPERATION_MANAGERUTINT ===
                      Constants.LOGIN_USER_TYPE ||
                    Constants.QAUTINT === Constants.LOGIN_USER_TYPE ||
                    Constants.QA_MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
                    Constants.ADMINUTINT === Constants.LOGIN_USER_TYPE) &&
                  task_feedback === Constants.TASK_RESOLVED && (
                    <div>
                      <div className="mt-2">
                        <label className="form-label fw-600">Activity</label>
                      </div>
                      <div>
                        <AddComments
                          task_id={id}
                          special_request_task_status={
                            task_feedback
                          }
                          task_type={task_type}
                          task_status={task_status}
                        />
                      </div>
                    </div>
                  )} */}

                {/* special request supervisor comments box */}
                {/* {task_type === Constants.SPECIAL_REQUEST &&
                  (Constants.SUPERVISORUTINT === Constants.LOGIN_USER_TYPE ||
                    Constants.SUPERVISOR_MANAGERUTINT === Constants.LOGIN_USER_TYPE) &&
                  // task_feedback === Constants.TASK_RESOLVED && 
                  (
                    <div>
                      <div className="mt-2">
                        <label className="form-label fw-600">Activity</label>
                      </div>
                      <div>
                        <AddComments
                          task_id={id}
                          special_request_task_status={
                            task_feedback
                          }
                          task_type={task_type}
                          task_status={task_status}
                        />
                      </div>
                    </div>
                  )} */}

                {/* regular task admin and supervisor comments box */}
                {/* {task_type === Constants.REGULAR_TASK &&
                  (Constants.SUPERVISORUTINT === Constants.LOGIN_USER_TYPE ||
                    Constants.ADMINUTINT === Constants.LOGIN_USER_TYPE) && (
                    <div>
                      <div className="mt-2">
                        <label className="form-label fw-600">Activity</label>
                      </div>
                      <div>
                        <AddComments
                          task_id={id}
                          special_request_task_status={
                            task_feedback
                          }
                          task_type={task_type}
                          task_status={task_status}
                        />
                      </div>
                    </div>
                )} */}
                <div className="mt-2">
                  <DisplayComments
                    data={commants}
                    task_id={id}
                    special_request_task_status={task_feedback}
                    task_type={task_type}
                  />
                </div>
              </div>
              <div className="col-md-12 col-lg-4 scroll-view">
                <div className="card">
                  <div className="card-body">
                    <div>
                      <a href={Constants.baseUrl + `task/pdf-export/` + id}>
                        <button className="mb-3 btn btn-primary btn-border h-40 res-w-100">
                          Export
                        </button>
                      </a>
                    </div>
                    <label className="form-label fw-600">Status</label>
                    <p className={`${task_status_name}-option`}>
                      {task_status_name}
                    </p>

                    {task_feedback !== null ? (
                      <div>
                        <label className="form-label mb-2">Feedback</label>
                        <p className={`${task_feedback}-option`}>
                          {task_feedback}
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}

                    <label className="form-label fw-600 mt-3">Client</label>
                    <p>{`${first_name} ${last_name}`}</p>

                    <label className="form-label fw-600">Branch</label>
                    <p>{branch_name}</p>

                    <label className="form-label fw-600">Date</label>
                    <p>{moment(`${task_date}`).format("DD/MM/YYYY")}</p>

                    <label className="form-label fw-600">Time</label>
                    <p>
                      {any_time === 1
                        ? `Any Time`
                        : moment(start_time, "HH:mm:ss").format("hh:mm A") +
                          "-" +
                          moment(end_time, "HH:mm:ss").format("hh:mm A")}
                    </p>
                    <label className="form-label fw-600 mt-3">Created By</label>
                    <p>{created_by_user}</p>
                    {(modified_by_user !== null ||
                      modified_by_user !== undefined ||
                      modified_by_user !== "") && (
                      <>
                        <label className="form-label fw-600 mt-3">
                          Modified By
                        </label>
                        <p>{modified_by_user}</p>
                      </>
                    )}

                    {(Constants.QAUTINT !== Constants.LOGIN_USER_TYPE ||
                      Constants.QA_MANAGERUTINT !== Constants.LOGIN_USER_TYPE ||
                      Constants.MANAGERUTINT !== Constants.LOGIN_USER_TYPE) && (
                      <label className="form-label fw-600">
                        Cleaners{" "}
                        <span className="view-cleaner-count">
                          {cleaners ? cleaners.length : ""}
                        </span>
                      </label>
                    )}
                    {(Constants.QAUTINT !== Constants.LOGIN_USER_TYPE ||
                      Constants.QA_MANAGERUTINT !== Constants.LOGIN_USER_TYPE ||
                      Constants.MANAGERUTINT !== Constants.LOGIN_USER_TYPE) &&
                      cleaners &&
                      cleaners.map((val: any) => (
                        <div>
                          <p className="mb-1">
                            {val.first_name} {val.last_name}
                          </p>
                        </div>
                      ))}

                    <div className="daily-task-update-date mt-5 d-none">
                      <p>
                        Created {moment(`${created_at}`).format("LL [at] LT")}
                      </p>
                      <p>
                        Updated {moment(`${created_at}`).format("LL [at] LT")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <AttachmentPreview
          displayPreview={this.state.AttachmentPreviewStatus}
          previewUrl={this.state.previewUrl}
          previewType={this.state.previewType}
          handlePreviewClose={this.handlePreviewClose}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  viewDailyTaskStatus: state.dailytask.viewDailyTaskStatus,
  get_User: state.usermanagement.get_User,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateViewDailyTaskDlgStatus: bindActionCreators(
      updateViewDailyTaskDlgStatus,
      dispatch
    ),
    getUserById: bindActionCreators(getUserById, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewDailyTask);
