import * as Yup from "yup";

import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Modal, ProgressBar } from "react-bootstrap";
import {
  listAllBranch,
  listAllClient,
  addSchedule,
  taskDropDown,
  listAllCleaner,
  updateAddScheduleStatus,
  getAllSchedules,
} from "../../shared/Reducers";
import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
import moment from "moment";
import CustomSelect from "../../shared/Components/CustomSelect";
import Constants, {
  EveryMonth,
  EveryWeekDays,
  FILTER_SCHEDULE_TABLE,
  FILTER_USER_ROLE,
  FILTER_USER_TABLE,
  EveryWeekMonth,
  DaysOption,
  TASK_PRIORITY,
  SCHEDULE_OPTION,
} from "../../shared/Constants";
import FormCheck from "react-bootstrap/Form";
import {
  ScheduleBranch,
  ScheduleCleaner,
  ScheduleState,
  TaskCleaner,
} from "../../types/schedule.type";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import DatePicker from "react-multi-date-picker";
import dayjs, { Dayjs } from "dayjs";

type TAddScheduleProps = {
  listAllClient: any;
  listAllBranch: any;
  addSchedule: any;
  progressPercentage: any;
  processTrigger: boolean;
  addMessage?: any;
  cleanerList?: any;
  clientList?: any;
  branchList?: any;
  getTaskDropDown: any;
  taskDropDownList?: any;
  taskDropDown?: any;
  listAllCleaner?: any;
  updateAddScheduleStatus?: any;
  getAllSchedules: any;
};

type TAddScheduleState = {
  specificDate: any;
  dateType: any;
  anyTime: number;
  endSchedule: any;
  startSchedule: any;
  anyTimeFormat: boolean;
  timeFormat: boolean;
  startTime: any;
  scheduleEndDate: any;
  endTime: any;
};

const reactSelectCusStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#fff",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    },
  }),
};
class AddSchedule extends React.Component<
  TAddScheduleProps,
  TAddScheduleState
> {
  constructor(props: TAddScheduleProps) {
    super(props);
    this.state = {
      specificDate: [],
      dateType: "",
      anyTime: 1,
      endSchedule: "",
      startSchedule: "",
      anyTimeFormat: false,
      timeFormat: false,
      startTime: "",
      scheduleEndDate: "",
      endTime: "",
    };
  }

  componentDidMount(): void {
    this.props.listAllClient();
    this.props.listAllCleaner();

    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.add("d-none");
      button.removeAttribute("disabled");
    }
  }
  componentDidUpdate(
    prevProps: Readonly<TAddScheduleProps>,
    prevState: Readonly<TAddScheduleState>,
    snapshot?: any
  ): void {
    console.log(this.props.taskDropDownList);
  }
  handleAddShedule = async (formValue: any) => {
    console.log("scheduleFormvalue",formValue);
    
    if (this.state.dateType !== "") {
      let specificDateGet = [];
      for (let index = 0; index < this.state.specificDate.length; index++) {
        const element = this.state.specificDate[index];
        const monthFormatted = String(element.month).padStart(2, "0");
        const dayFormatted = String(element.day).padStart(2, "0");
        specificDateGet.push(
          element.year + "-" + monthFormatted + "-" + dayFormatted
        );
      }

      if (this.state.dateType === "every_month_days") {
        let dataArray = {
          schedule_name: formValue.schedule_name,
          is_active: formValue.is_active,
          client: formValue.client_select,
          branch: formValue.branchSelect,
          task: formValue.taskSelect,
          schedule_option: this.state.dateType,
          cleaners: formValue.cleanerSelect,
          days: formValue.selectDate,

          any_time: this.state.anyTime,
          start_time:
            this.state.anyTime === 0
              ? moment(new Date(this.state.startTime?.toString())).format(
                  "HH:mm"
                )
              : "",
          end_time:
            this.state.anyTime === 0
              ? moment(new Date(this.state.endTime?.toString())).format("HH:mm")
              : "",
          schedule_end_date: moment(
            new Date(this.state.scheduleEndDate?.toString())
          ).format("DD/MM/YYYY"),
          task_with_sign_in: formValue.task_with_sign_in,
          priority: formValue.priority,
        };
        console.log("evey month", dataArray);

        await this.props.addSchedule(dataArray);
        await this.getScheduleList();
      } else if (this.state.dateType === "every_week_days") {
        let dataArray = {
          schedule_name: formValue.schedule_name,
          is_active: formValue.is_active,
          client: formValue.client_select,
          branch: formValue.branchSelect,
          task: formValue.taskSelect,
          schedule_option: this.state.dateType,
          cleaners: formValue.cleanerSelect,
          days: formValue.everyWeek,
          week: [1, 2, 3, 4, 5, 6],
          any_time: this.state.anyTime,
          start_time:
            this.state.anyTime === 0
              ? moment(new Date(this.state.startTime?.toString())).format(
                  "HH:mm"
                )
              : "",
          end_time:
            this.state.anyTime === 0
              ? moment(new Date(this.state.endTime?.toString())).format("HH:mm")
              : "",
          schedule_end_date: moment(
            new Date(this.state.scheduleEndDate?.toString())
          ).format("DD/MM/YYYY"),
          task_with_sign_in: formValue.task_with_sign_in,
          priority: formValue.priority,
        };
        console.log("week days", dataArray);

        await this.props.addSchedule(dataArray);
        await this.getScheduleList();
      } else if (this.state.dateType === "every_month_week") {
        let dataArray = {
          schedule_name: formValue.schedule_name,
          is_active: formValue.is_active,
          client: formValue.client_select,
          branch: formValue.branchSelect,
          task: formValue.taskSelect,
          schedule_option: this.state.dateType,
          cleaners: formValue.cleanerSelect,
          days: formValue.everyMonthWeek,
          week: formValue.everyMonthWeekNo,
          any_time: this.state.anyTime,
          start_time:
            this.state.anyTime === 0
              ? moment(new Date(this.state.startTime?.toString())).format(
                  "HH:mm"
                )
              : "",
          end_time:
            this.state.anyTime === 0
              ? moment(new Date(this.state.endTime?.toString())).format("HH:mm")
              : "",
          schedule_end_date: moment(
            new Date(this.state.scheduleEndDate?.toString())
          ).format("DD/MM/YYYY"),
          task_with_sign_in: formValue.task_with_sign_in,
          priority: formValue.priority,
        };
        console.log("month week days", dataArray);

        await this.props.addSchedule(dataArray);
        await this.getScheduleList();
      } else if (this.state.dateType === "specific_dates") {
        let dataArray = {
          schedule_name: formValue.schedule_name,
          is_active: formValue.is_active,
          client: formValue.client_select,
          branch: formValue.branchSelect,
          task: formValue.taskSelect,
          schedule_option: this.state.dateType,
          cleaners: formValue.cleanerSelect,
          specific_dates: specificDateGet,
          any_time: this.state.anyTime,
          start_time:
            this.state.anyTime === 0
              ? moment(new Date(this.state.startTime?.toString())).format(
                  "HH:mm"
                )
              : "",
          end_time:
            this.state.anyTime === 0
              ? moment(new Date(this.state.endTime?.toString())).format("HH:mm")
              : "",
          schedule_end_date: moment(
            new Date(this.state.scheduleEndDate?.toString())
          ).format("DD/MM/YYYY"),
          task_with_sign_in: formValue.task_with_sign_in,
          priority: formValue.priority,
        };
        console.log("specific date", dataArray);

        await this.props.addSchedule(dataArray);
        await this.getScheduleList();
      } else if (this.state.dateType === "every_week") {
        let dataArray = {
          schedule_name: formValue.schedule_name,
          is_active: formValue.is_active,
          client: formValue.client_select,
          branch: formValue.branchSelect,
          task: formValue.taskSelect,
          schedule_option: this.state.dateType,
          cleaners: formValue.cleanerSelect,
          days: formValue.everyWeek,
          any_time: this.state.anyTime,
          start_time:
            this.state.anyTime === 0
              ? moment(new Date(this.state.startTime?.toString())).format(
                  "HH:mm"
                )
              : "",
          end_time:
            this.state.anyTime === 0
              ? moment(new Date(this.state.endTime?.toString())).format("HH:mm")
              : "",
          schedule_end_date: moment(
            new Date(this.state.scheduleEndDate?.toString())
          ).format("DD/MM/YYYY"),
          task_with_sign_in: formValue.task_with_sign_in,
          priority: formValue.priority,
        };
        console.log("che", dataArray);

        await this.props.addSchedule(dataArray);
        await this.getScheduleList();
      } else {
        let dataArray = {
          schedule_name: formValue.schedule_name,
          is_active: formValue.is_active,
          client: formValue.client_select,
          branch: formValue.branchSelect,
          task: formValue.taskSelect,
          schedule_option: this.state.dateType,
          cleaners: formValue.cleanerSelect,
          specific_dates: [
            moment(new Date(this.state.startSchedule?.toString())).format(
              "YYYY-MM-DD"
            ),
            moment(new Date(this.state.endSchedule?.toString())).format(
              "YYYY-MM-DD"
            ),
          ],
          any_time: this.state.anyTime,
          start_time:
            this.state.anyTime === 0
              ? moment(new Date(this.state.startTime?.toString())).format(
                  "HH:mm"
                )
              : "",
          end_time:
            this.state.anyTime === 0
              ? moment(new Date(this.state.endTime?.toString())).format("HH:mm")
              : "",
          schedule_end_date: moment(
            new Date(this.state.scheduleEndDate?.toString())
          ).format("DD/MM/YYYY"),
          task_with_sign_in: formValue.task_with_sign_in,
          priority: formValue.priority,
        };
        console.log("start end", dataArray);

        await this.props.addSchedule(dataArray);
        await this.getScheduleList();
      }

      this.setState({
        anyTime: 1,
        scheduleEndDate: "",
        startTime: "",
        endTime: "",
      });
    } else {
      let dateAlert: HTMLElement = document.getElementById(
        "date-alert"
      ) as HTMLElement;
      dateAlert.style.display = "block";
    }
  };

  getScheduleList = async () => {
    const query = `search=&sort=&order=&offset=0&limit=10&client=all&branch=all&task_reference_type=all`;
    this.props.getAllSchedules(query);
  };

  AddValidationSchedule = () => {
    // console.log("log");
    // const clientId = this.props.clientList.map(
    //   (client: ScheduleCleaner) => client.id
    // );
    // const branchId = this.props.branchList.map(
    //   (branch: ScheduleBranch) => branch.value
    // );
    if (this.state.dateType === "") {
      let dateAlert: HTMLElement = document.getElementById(
        "date-alert"
      ) as HTMLElement;
      dateAlert.style.display = "block";
    }
    if (this.state.scheduleEndDate === "") {
      let dateAlert: HTMLElement = document.getElementById(
        "schedule-end-date-alert"
      ) as HTMLElement;
      dateAlert.style.display = "block";
    }
    // const taskId = this.props.taskNames.map((task: TaskCleaner) => task.id);
    return Yup.object().shape({
      schedule_name: Yup.string().required("Enter a schedule name"),
      client_select: Yup.string().required("Select a Client"),
      branchSelect: Yup.string().required("Select a branch"),
      taskSelect: Yup.string().required("Select a task"),
      cleanerSelect: Yup.array().min(1, "Select atleast one cleaner"),
    });
  };

  handleChangeScedule = async (e: any) => {
    if (e.target.name === "group2") {
      if (e.target.id === "inline-1") {
        this.setState({ anyTime: 1, timeFormat: false, anyTimeFormat: true });
      } else {
        this.setState({ anyTime: 0, anyTimeFormat: false, timeFormat: true });
      }
    }
    if (e.target.name === "group1") {
      if (e.target.id === "inline-11") {
        this.setState({ dateType: "every_month_days" });
      } else if (e.target.id === "inline-12") {
        this.setState({ dateType: "specific_dates" });
      } else if (e.target.id === "inline-13") {
        this.setState({ dateType: "every_week" });
      } else if (e.target.id === "inline-14") {
        this.setState({ dateType: "date_to_date" });
      } else if (e.target.id === "inline-15") {
        this.setState({ dateType: "every_month_week" });
      }

      let dateAlert: HTMLElement = document.getElementById(
        "date-alert"
      ) as HTMLElement;
      dateAlert.style.display = "none";
    }

    if (e.target.id === "start-time") {
      this.setState({ startTime: e.target.value });
    } else if (e.target.id === "end-time") {
      this.setState({ endTime: e.target.value });
    }
  };

  handleEndDate = async (newValue: Dayjs | null) => {
    this.setState({ endSchedule: newValue });
  };

  handleEndDateClick = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    let dateAlert: HTMLElement = document.getElementById(
      "schedule-end-date-alert"
    ) as HTMLElement;
    dateAlert.style.display = "none";
    if (datepopup === null) {
      let scheduleEnd: HTMLElement = document.querySelector(
        ".endDateAdd button.MuiButtonBase-root"
      ) as HTMLElement;
      if (scheduleEnd !== null) {
        scheduleEnd.click();
      }
      let scheduleEditEnd: HTMLElement = document.querySelector(
        ".endDate button.MuiButtonBase-root"
      ) as HTMLElement;
      if (scheduleEditEnd !== null) {
        scheduleEditEnd.click();
      }
    }
  };

  handleStartDate = async (newValue: Dayjs | null) => {
    this.setState({ startSchedule: newValue });
    let endDate: HTMLElement = document.querySelector(
      ".start button.MuiButtonBase-root"
    ) as HTMLElement;
    if (endDate === null) {
      let startDate: HTMLElement = document.querySelector(
        ".start button.MuiButtonBase-root"
      ) as HTMLElement;
      startDate.click();
    }
    // endDate.click();
  };

  handleScheduleDate = async (newValue: Dayjs | null) => {
    this.setState({ scheduleEndDate: newValue });
    // if (this.state.dateType !== "") {
    //   let dateAlert: HTMLElement = document.getElementById(
    //     "date-alert"
    //   ) as HTMLElement;
    //   dateAlert.style.display = "none";
    // }
    let dateAlert: HTMLElement = document.getElementById(
      "schedule-end-date-alert"
    ) as HTMLElement;
    if (dateAlert !== null) {
      dateAlert.click();
    }
  };

  handleStart = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let startDate: HTMLElement = document.querySelector(
        ".start button.MuiButtonBase-root"
      ) as HTMLElement;
      startDate.click();
    }
  };
  handleEnd = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let endDate: HTMLElement = document.querySelector(
        ".end button.MuiButtonBase-root"
      ) as HTMLElement;
      endDate.click();
    }
  };

  handleStartTime = (newValue: Dayjs | null) => {
    this.setState({ startTime: newValue });
  };

  handleEndTime = (newValue: Dayjs | null) => {
    this.setState({ endTime: newValue });
  };
  render(): React.ReactNode {
    const initialValues = {};
    return (
      <div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={this.handleAddShedule}
          validationSchema={this.AddValidationSchedule}
        >
          <Form onChange={this.handleChangeScedule}>
            <div className="row">
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Schedule name
                  <span className="text-project">*</span>
                </label>
                <Field
                  name="schedule_name"
                  type="text"
                  className="form-control dropdown-field"
                />
                <ErrorMessage
                  name="schedule_name"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Client
                  <span className="text-project">*</span>
                </label>
                <Field
                  className="custom-select"
                  name="client_select"
                  component={CustomSelect} // Assuming you have a component named CustomSelect to handle the custom select functionality
                  placeholder="Select client"
                  options={this.props.clientList.map(
                    (client: ScheduleCleaner) => ({
                      value: client.id.toString(),
                      label: client.first_name + " " + client.last_name,
                    })
                  )}
                  onChange={async (e: any) => {
                    await this.props.listAllBranch(e.value);
                  }}
                />
                <ErrorMessage
                  name="client_select"
                  component={"div"}
                  className="cus-alert"
                />
              </div>

              <div className="col-md-12 mb-2">
                <label className="form-label">
                  Branch
                  <span className="text-project">*</span>
                </label>
                <Field
                  className="custom-select"
                  name="branchSelect"
                  component={CustomSelect} // Assuming you have a component named CustomSelect to handle the custom select functionality
                  placeholder="Select Branch"
                  options={this.props.branchList.map((branch: any) => ({
                    value: branch.value,
                    label: branch.label,
                  }))}
                  onChange={async (e: any) => {
                    await this.props.getTaskDropDown(e.value);
                  }}
                />
                <ErrorMessage
                  name="branchSelect"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="col-md-6 mb-2">
                <label className="form-label">
                  Task
                  <span className="text-project">*</span>
                </label>
                <Field
                  className="custom-select"
                  name="taskSelect"
                  component={CustomSelect} // Assuming you have a component named CustomSelect to handle the custom select functionality
                  placeholder="Select Task"
                  options={this.props.taskDropDownList?.map(
                    (task: { id: string; title: string }) => ({
                      value: task.id,
                      label: task.title,
                    })
                  )}
                />
                <ErrorMessage
                  name="taskSelect"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="col-md-12 mb-2">
                <label className="form-label">
                  Cleaner
                  <span className="text-project">*</span>
                </label>
                <Field
                  className="custom-select"
                  name="cleanerSelect"
                  options={this.props.cleanerList}
                  component={CustomSelect}
                  placeholder="Select Cleaner"
                  isMulti={true}
                  onChange={() => console.log("ee")}
                />
                <ErrorMessage
                  name="cleanerSelect"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
            </div>
            <div>
              <label className="form-label">
                Date<span className="text-project">*</span>
              </label>
              <div className="d-flex mt-2">
                <div className="">
                  <FormCheck.Check
                    inline
                    label="Every month"
                    value="every_month_days"
                    name="group1"
                    type={"radio"}
                    id={`inline-11`}
                  />
                </div>
                <div className="schedule-select-date">
                  <Field
                    className="custom-select"
                    name="selectDate"
                    options={EveryMonth}
                    component={CustomSelect}
                    placeholder="Select Date"
                    isMulti={true}
                    onChange={() => console.log("ee")}
                  />
                </div>
                <div className="">
                  <label className="form-label">Date</label>
                </div>
              </div>
              <ErrorMessage
                name="selectDate"
                component={"div"}
                className="cus-alert"
              />
              <div className="d-flex mt-3">
                <div className="">
                  <FormCheck.Check
                    inline
                    label="Specific dates"
                    value="specific_dates"
                    name="group1"
                    type={"radio"}
                    id={`inline-12`}
                  />
                </div>
                <div className="schedule-specific-date">
                  <DatePicker
                    multiple
                    format="DD/MM/YYYY"
                    placeholder="dd/mm/yyyy"
                    value={this.state.specificDate}
                    onChange={(e) => {
                      this.setState({ specificDate: e });
                    }}
                  />
                </div>
              </div>
              <div className="d-flex mt-3">
                <div className="">
                  <FormCheck.Check
                    inline
                    label="Every"
                    value="every_week"
                    name="group1"
                    type={"radio"}
                    id={`inline-13`}
                  />
                </div>
                <div className="schedule-every-select-day">
                  <Field
                    className="custom-select"
                    name="everyWeek"
                    options={EveryWeekDays}
                    component={CustomSelect}
                    placeholder="Select Day"
                    isMulti={true}
                    onChange={() => console.log("ee")}
                  ></Field>
                  <ErrorMessage
                    name="everyWeek"
                    component={"div"}
                    className="cus-alert"
                  />
                </div>
              </div>
              {/* <div className="d-flex mt-3"> */}
              {/* <div className="">
                    <FormCheck.Check
                    inline
                    label="Every month"
                    value="every_month_week"
                    name="group1"
                    type={"radio"}
                    id={`inline-15`}
                    />
                </div> */}
              {/* <div className="schedule-every-select-day">
                    <Field
                    className="custom-select"
                    name="everyMonthWeekNo"
                    options={EveryWeekMonth}
                    component={CustomSelect}
                    placeholder="Select Number of week"
                    isMulti={true}
                    onChange={() => console.log("ee")}
                    ></Field>
                    <ErrorMessage
                    name="everyMonthWeekNo"
                    component={"div"}
                    className="cus-alert"
                    />
                </div>
                <div className="schedule-every-select-day">
                    <Field
                    className="custom-select"
                    name="everyMonthWeek"
                    options={EveryWeekDays}
                    component={CustomSelect}
                    placeholder="Select Day"
                    isMulti={true}
                    onChange={() => console.log("ee")}
                    ></Field>
                    <ErrorMessage
                    name="everyMonthWeek"
                    component={"div"}
                    className="cus-alert"
                    />
                </div> */}
              {/* </div> */}
              <div className="d-flex mt-3">
                <div className="">
                  <FormCheck.Check
                    inline
                    label="Start date"
                    name="group1"
                    type={"radio"}
                    id={`inline-14`}
                  />
                </div>
                <div className="schedule-start-date">
                  <div className="start" onClick={() => this.handleStart()}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      id="startDate"
                    >
                      <Stack component={"form"} noValidate spacing={3}>
                        <DesktopDatePicker
                          inputFormat="DD/MM/YYYY"
                          maxDate={this.state.endSchedule}
                          value={this.state.startSchedule}
                          onChange={this.handleStartDate}
                          renderInput={(params) => <TextField {...params} />}
                          // disablePast={true}
                          // closeOnSelect={true}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </div>
                  <ErrorMessage
                    name="startDate"
                    component={"div"}
                    className="cus-alert"
                  />
                </div>
                <div className="">
                  <label className="form-label">End date</label>
                </div>
                <div className="schedule-end-date">
                  <div className="end" onClick={() => this.handleEnd()}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      id="startDate"
                    >
                      <Stack component={"form"} noValidate spacing={3}>
                        <DesktopDatePicker
                          inputFormat="DD/MM/YYYY"
                          minDate={this.state.startSchedule}
                          value={this.state.endSchedule}
                          onChange={this.handleEndDate}
                          renderInput={(params) => <TextField {...params} />}
                          // closeOnSelect={true}
                          // disablePast={true}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </div>
                  <ErrorMessage
                    name="endDate"
                    component={"div"}
                    className="cus-alert"
                  />
                </div>
              </div>
              <ErrorMessage
                name="group1"
                component={"div"}
                className="cus-alert"
              />
              <p
                className="cus-alert"
                id="date-alert"
                style={{ display: "none", color: "red" }}
              >
                Select a date
              </p>
            </div>

            <div className="row mt-3">
              <label className="form-label">
                Time<span className="text-project">*</span>
              </label>
              <div className="mt-3">
                <FormCheck.Check
                  inline
                  label="Any time"
                  name="group2"
                  type={"radio"}
                  id={`inline-1`}
                  checked={this.state.anyTimeFormat}
                />
              </div>
              <div className="d-flex mt-3">
                <div className="">
                  <FormCheck.Check
                    inline
                    label="Start"
                    name="group2"
                    type={"radio"}
                    id={`inline-2`}
                    checked={this.state.timeFormat}
                  />
                </div>
                <div className="schedule-start-time">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <TimePicker
                        value={this.state.startTime}
                        onChange={this.handleStartTime}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                  {/* <TextField
                    value={this.state.startTime}
                    id="start-time"
                    type="time"
                    InputLabelProps={{
                    shrink: true,
                    }}
                    onChange={(e) =>
                    this.setState({
                        startTime: e.target.value,
                    })
                    }
                    inputProps={{
                    step: 300, // 5 min
                    }}
                    sx={{ width: 100 }}
                /> */}
                </div>
                <div className="">
                  <label className="form-label">End</label>
                </div>
                <div className="schedule-end-time">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <TimePicker
                        value={this.state.endTime}
                        onChange={this.handleEndTime}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                  {/* <TextField
                    id="end-time"
                    type="time"
                    onChange={(e) =>
                    this.setState({
                        endTime: e.target.value,
                    })
                    }
                    value={this.state.endTime}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    inputProps={{
                    step: 300, // 5 min
                    }}
                    sx={{ width: 100 }}
                /> */}
                </div>
              </div>
              <p
                className="cus-alert"
                id="time-alert"
                style={{ display: "none", color: "red" }}
              >
                Please select a time
              </p>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="form-label">
                  Schedule end date <span className="text-project">*</span>
                </label>
                <div
                  className="endDateAdd"
                  onClick={() => this.handleEndDateClick()}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    id="startDate"
                  >
                    <Stack spacing={3}>
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        // maxDate={this.state.endDatevalue}
                        value={this.state.scheduleEndDate}
                        onChange={this.handleScheduleDate}
                        renderInput={(params) => <TextField {...params} />}
                        disablePast={true}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
                {/* </Field> */}
                <ErrorMessage
                  name="scheduleEndDate"
                  component={"div"}
                  className="cus-alert"
                />
                <p
                  className="cus-alert"
                  id="schedule-end-date-alert"
                  style={{ display: "none", color: "red" }}
                >
                  Select a schedule end date
                </p>
              </div>
              {Constants.MANAGERUTINT !== Constants.LOGIN_USER_TYPE ||
                (Constants.OPERATION_MANAGERUTINT !==
                  Constants.LOGIN_USER_TYPE && (
                  <div className="mt-2 mb-2 col-md-6">
                    <label className="form-label">
                      Task with sign in
                      <span className="text-project">*</span>
                    </label>
                    <Field
                      className="custom-select"
                      name="task_with_sign_in"
                      options={[
                        {
                          value: Constants.TASK_WITH_SIGN_IN_YES,
                          label: "Task with sign in",
                        },
                        {
                          value: Constants.TASK_WITH_SIGN_IN_NO,
                          label: "Task without sign in",
                        },
                      ]}
                      component={CustomSelect}
                      isMulti={false}
                    />
                    <ErrorMessage
                      name="task_with_sign_in"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                ))}
              <div className="mt-2 mb-2 col-md-6">
                <label className="form-label">
                  Task priority
                  <span className="text-project">*</span>
                </label>
                <Field
                  className="custom-select"
                  name="priority"
                  options={[
                    {
                      value: TASK_PRIORITY.LOW,
                      label: "Low",
                    },
                    {
                      value: TASK_PRIORITY.MEDIUM,
                      label: "Medium",
                    },
                    {
                      value: TASK_PRIORITY.HIGH,
                      label: "High",
                    },
                  ]}
                  component={CustomSelect}
                  isMulti={false}
                />
                <ErrorMessage
                  name="priority"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="mb-4 col-md-6">
                <label className="form-label">
                  Is Active
                  <span className="text-project">*</span>
                </label>
                <Field
                  className="custom-select"
                  name="is_active"
                  options={[
                    {
                      value: Constants.ENABLE,
                      label: "Enable",
                    },
                    {
                      value: Constants.DISABLE,
                      label: "Disable",
                    },
                  ]}
                  component={CustomSelect}
                  isMulti={false}
                />
              </div>
            </div>
            <div className="d-flex mb-3 mt-3 cus-progress">
              <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.processTrigger}
              >
                <Modal.Body className="p-0">
                  <ProgressBar
                    now={this.props.progressPercentage}
                    label={
                      this.props.progressPercentage === 100
                        ? `Processing your request...`
                        : `Schedule Updating....${this.props.progressPercentage}%`
                    }
                  />
                </Modal.Body>
              </Modal>
              <div className="mr-16px">
                <button type="submit" className="btn btn-primary create-button">
                  Create
                  <span
                    id="spinner-border"
                    className="spinner-border spinner-border-sm d-none"
                  ></span>
                </button>
              </div>
              <div>
                <button
                  onClick={() => this.props.updateAddScheduleStatus(false)}
                  className="btn cancel-button"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  clientList: state.tasktemplate.clientList,
  branchList: state.tasktemplate.branchList,
  processTrigger: state.tasktemplate.processTrigger,
  progressPercentage: state.tasktemplate.progressPercentage,
  addMessage: state.schedule.addTaskMessage,
  cleanerList: state.tasktemplate.cleanerList,
  taskDropDownList: state.schedule.taskDropDownList,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    listAllClient: bindActionCreators(listAllClient, dispatch),
    getAllSchedules: bindActionCreators(getAllSchedules, dispatch),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
    addSchedule: bindActionCreators(addSchedule, dispatch),
    getTaskDropDown: bindActionCreators(taskDropDown, dispatch),
    listAllCleaner: bindActionCreators(listAllCleaner, dispatch),
    updateAddScheduleStatus: bindActionCreators(
      updateAddScheduleStatus,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSchedule);
