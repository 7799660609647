import React from "react";
import { Button, Offcanvas, Toast } from "react-bootstrap";
import { SearchSVG } from "../../assets/images/EmployeesSVG";
import SideBar from "../../components/sidebar/SideBar";
import TopNav from "../../components/topnav/TopNav";
import {
  ITaskDashboardProps,
  ITaskDashboardState,
} from "../../types/task.type";
import AddTask from "./AddTask";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  filterTask,
  getAllTasks,
  updateAddTask,
  updateSidebar,
  updateTaskFetchStatus,
} from "../../shared/Reducers";
import TaskTable from "./TaskTable";
import ReactPaginate from "react-paginate";
import Constants from "../../shared/Constants";
import { SuccessToastSVG } from "../../assets/images/ImagesV2";
import { CloseBtnSVG } from "../../assets/images/Location";
import { Field, Form, Formik } from "formik";
import { CustomSelect } from "../../shared/Components/CustomSelect";
import * as Yup from "yup";

class Task extends React.Component<ITaskDashboardProps, ITaskDashboardState> {
  constructor(props: ITaskDashboardProps) {
    super(props);
    this.state = {
      show: false,
      total_task: "",
      searchTerm: "",
      addToast: false,
      deleteToast: false,
      editToast: false,
      addTaskStatus: false,
      fieldName: "",
      orderType: "",
      pagehandle: false,
      timeout: null,
    };
  }

  componentDidMount(): void {
    this.props.updateSidebar(true);
    document.title = "Task Template";
    this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
  }

  componentDidUpdate(
    prevProps: Readonly<ITaskDashboardProps>,
    prevState: Readonly<ITaskDashboardState>,
    snapshot?: any
  ): void {
    if (prevProps.addTaskStatus !== this.props.addTaskStatus) {
      if (!this.props.addTaskStatus) {
        this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
      }
    }

    if (prevProps.taskFetchStatus !== this.props.taskFetchStatus) {
      if (this.props.taskFetchStatus) {
        this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
      }
    }
    if (prevProps.addTaskSuccess !== this.props.addTaskSuccess) {
      if (this.props.addTaskSuccess === "Successfully created") {
        this.setState({ addToast: true });
        setTimeout(() => {
          this.setState({ addToast: false });
        }, 1000);
      }
    }
    if (prevProps.editTaskSuccess !== this.props.editTaskSuccess) {
      if (this.props.editTaskSuccess === "Successfully updated") {
        this.setState({ editToast: true });
        setTimeout(() => {
          this.setState({ editToast: false });
        }, 1000);
      }
    }
    if (prevProps.deleteTaskSuccess !== this.props.deleteTaskSuccess) {
      if (this.props.deleteTaskSuccess === "Successfully deleted") {
        this.setState({ deleteToast: true });
        console.log("delete", this.state.deleteToast);
        setTimeout(() => {
          this.setState({ deleteToast: false });
        }, 1000);
      }
    }
  }

  addToastClose = () => {
    this.setState({ addToast: false });
  };

  editToastClose = () => {
    this.setState({ editToast: false });
  };

  deleteToastClose = () => {
    this.setState({ deleteToast: false });
  };

  getTasks = async (offset: number, limit: number) => {
    try {
      const query = `search=&sort=&order=&offset=${offset}&limit=${limit}&client=all&branch=all`;
      this.props.getAllTasks(query);
    } catch (error) {
      console.log(error);
    }
  };

  handleAddTaskCLose = (status: boolean) => {
    this.handleClose();
    this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
  };

  // Canvas Show
  handleShow() {
    // this.props.updateAddTask(true);
    this.setState({ addTaskStatus: true });
  }

  // Canvas Close
  handleClose() {
    // this.props.updateAddTask(false);
    this.setState({ addTaskStatus: false });
  }

  // Search Branch Filter
  handleFilter = (e: any) => {
    const value: string = e.target.value.toLowerCase();
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

    this.setState({
      searchTerm: value,
      timeout: setTimeout(async () => {
        try {
          const query = `search=${value}&sort=&order=&offset=0&limit=10&client=all&branch=all`;
          this.props.getAllTasks(query);
        } catch (error) {
          console.log(error);
        }
        // Trigger your function here that you want to call when user stops typing
      }, 1000), // Set the delay time in ms
    });
  };

  handlePageClick = (e: any) => {
    console.log(e);
    if (e.selected < this.props.total_count / Constants.PER_PAGE) {
      const query = `search=${this.state.searchTerm}&sort=${this.state.fieldName
        }&order=${this.state.orderType}&offset=${e.selected * Constants.PER_PAGE
        }&limit=${Constants.PER_PAGE}&client=all&branch=all`;
      this.props.getAllTasks(query);
      // this.getTasks(e.selected * Constants.PER_PAGE, Constants.PER_PAGE);
    }
  };
  validationSchema = () => {
    return Yup.object().shape({
      column_name: Yup.string().required("Select Filter Column"),
      order_type: Yup.string().required("Select Sort Order"),
    });
  };
  handleSearch = (formValue: any) => {
    try {
      this.setState({ pagehandle: true });
      setTimeout(() => {
        this.setState({
          fieldName: formValue.column_name,
          orderType: formValue.order_type,
          pagehandle: false,
        });
        const query = `search=${this.state.searchTerm}&sort=${formValue.column_name}&order=${formValue.order_type}&offset=${Constants.OFFSET}&limit=${Constants.PER_PAGE}&client=all&branch=all`;
        this.props.getAllTasks(query);
      }, 0);
    } catch (error) {
      console.log(error);
    }
  };
  render(): React.ReactNode {
    const initialValue = {};
    let taskStatusOption: any = [];
    taskStatusOption = [
      { value: "title", label: "Task Name" },
      { value: "first_name", label: "Client" },
      { value: "branch_name", label: "Branch" },
      { value: "status", label: "Active" },
    ];
    let contractOrder: any = [];
    contractOrder = [
      { value: "asc", label: "Ascending" },
      { value: "desc", label: "Descending" },
    ];
    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className={this.props.status ? "main-panel" : "main-panel2"}>
          <TopNav />
          <div className="content-wrapper">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="mb-0">Task Template</h3>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-end">
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12 col-lg-4">
                  <div className="input-group search">
                    <span
                      className="input-group-text search-icon"
                      id="basic-addon1"
                    >
                      <SearchSVG />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={this.state.searchTerm}
                      onInput={this.handleFilter}
                      onChange={(e) =>
                        this.setState({ searchTerm: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12 col-lg-6 tab-res-mt-3">
                  <Formik
                    validationSchema={this.validationSchema}
                    onSubmit={this.handleSearch}
                    initialValues={{
                      column_name: "",
                      order_type: "asc",
                    }}
                  >
                    <Form className="d-flex flex-column flex-md-row">
                      <Field
                        className="custom-select dropdown-field flex-mr-16px filter-w-180px"
                        name="column_name"
                        options={taskStatusOption}
                        component={CustomSelect}
                        placeholder="Select Column"
                        isMulti={false}
                      />
                      <Field
                        className="custom-select dropdown-field flex-mr-16px res-mt-3 filter-w-180px"
                        name="order_type"
                        options={contractOrder}
                        component={CustomSelect}
                        placeholder="Select Order"
                        isMulti={false}
                      />
                      <button
                        type="submit"
                        className="btn btn-primary filter-button res-mt-3"
                      >
                        Filter
                      </button>
                    </Form>
                  </Formik>
                </div>
                <div className="col-md-12 col-lg-6 text-start text-lg-end tab-res-mt-3">
                  <Offcanvas
                    className="task-offcanvas"
                    placement="end"
                    show={this.state.addTaskStatus}
                    onHide={() => this.handleClose()}
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>
                        <span className="offc-title">Add Task Template</span>
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <AddTask onClose={this.handleAddTaskCLose} />
                    </Offcanvas.Body>
                  </Offcanvas>
                  <Button
                    aria-controls="offcanvasRight"
                    className="btn btn-primary login-button mob-res-w-100 w-170px"
                    onClick={() => this.handleShow()}
                  >
                    Add Task Template
                  </Button>
                  {this.state.addToast ? (
                    <Toast className="success-alerts">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <SuccessToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.addTaskSuccess}
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.addToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                  {this.state.editToast ? (
                    <Toast className="success-alerts">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <SuccessToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.editTaskSuccess}
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.editToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                  {this.state.deleteToast ? (
                    <Toast className="success-alerts">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <SuccessToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.deleteTaskSuccess}
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.deleteToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {this.props.filteredTaskData.length > 0 ? (
                      <div className="table-responsive">
                        <table className="table employee-table">
                          <thead>
                            <tr>
                              {/* <th scope="col">ID</th> */}
                              <th scope="col">TASK NAME</th>
                              <th scope="col">CLIENT</th>
                              <th scope="col">BRANCH</th>
                              <th scope="col">IS ACTIVE</th>
                              <th scope="col">ACTION</th>
                            </tr>
                          </thead>
                          <TaskTable
                            data={this.props.filteredTaskData}
                            onDelete={() =>
                              this.getTasks(
                                Constants.OFFSET,
                                Constants.PER_PAGE
                              )
                            }
                            onEditClose={() =>
                              this.getTasks(
                                Constants.OFFSET,
                                Constants.PER_PAGE
                              )
                            }
                            deleteTaskStatus={false}
                          />
                        </table>
                      </div>
                    ) : (
                      <p className="text-center">
                        There is no task template data found
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              {this.props.total_count > 10 ? (
                <div className="col-md-12">
                  {this.state.pagehandle ? null : (
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.props.total_count / Constants.PER_PAGE}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      nextClassName={"next-page"}
                      activeClassName={"active"}
                    />
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  addTaskStatus: state.tasktemplate.addTaskStatus,
  taskFetchStatus: state.tasktemplate.taskFetchStatus,
  taskData: state.tasktemplate.taskData,
  filteredTaskData: state.tasktemplate.filteredTaskData,
  total_count: state.tasktemplate.total_count,
  addTaskSuccess: state.tasktemplate.addTaskSuccess,
  editTaskSuccess: state.tasktemplate.editTaskSuccess,
  deleteTaskSuccess: state.tasktemplate.deleteTaskSuccess,
  status: state.sidebar.status,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateAddTask: bindActionCreators(updateAddTask, dispatch),
    updateTaskFetchStatus: bindActionCreators(updateTaskFetchStatus, dispatch),
    filterTask: bindActionCreators(filterTask, dispatch),
    getAllTasks: bindActionCreators(getAllTasks, dispatch),
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Task);
