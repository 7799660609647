import {
  API_DASHBOARD_COUNTS,
  API_DASHBOARD_COUNTS_SUCCESS,
  API_DASHBOARD_COUNTS_FAIL,
  API_DASHBOARD_REPORT,
  API_DASHBOARD_REPORT_SUCCESS,
  API_DASHBOARD_REPORT_FAIL,
  API_LAST_ACTIVITY,
  API_LAST_ACTIVITY_SUCCESS,
  API_LAST_ACTIVITY_FAIL,
  DashboardState,
  IDashboardData,
  API_DASHBOARD_COMPLAINT,
  API_DASHBOARD_COMPLAINT_SUCCESS,
  API_DASHBOARD_COMPLAINT_FAIL,
  UPDATE_DASHBOARD_VIEW_REPORT_STATUS
} from "../ActionTypes";
import { Dispatch, AnyAction } from "redux";
import { THomeActive } from "../../types/user.type";

const initialState: DashboardState = {
  Countdata: null,
  reportData: null,
  homeActivityData: [],
  complaintsData: [],
  dashboardViewReportStatus : false,
};

export const DashboardReducer = (
  state: DashboardState = initialState,
  action: AnyAction
): DashboardState => {
  switch (action.type) {
    case API_DASHBOARD_COMPLAINT:
      console.log(action, 'data');

      return { ...state };
    case API_DASHBOARD_COMPLAINT_SUCCESS:
      // let complaintShowData = []
      console.log(action, 'data');
      // if (action.payload) {
      //   const { data } = action.payload;

      //   if (data && "complaints" in data && data.complaints) {
      //     complaintShowData = data.complaints;
      //   }
      // }
      return { ...state, complaintsData: action.payload.data };
    case API_DASHBOARD_COMPLAINT_FAIL:
      return { ...state };
    case API_DASHBOARD_COUNTS:
      return { ...state };
    case API_DASHBOARD_COUNTS_SUCCESS:
      let allCounts = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "counts" in data && data.counts) {
          allCounts = data.counts;
        }
      }
      return { ...state, Countdata: allCounts };
    case API_DASHBOARD_COUNTS_FAIL:
      return { ...state };
    case API_DASHBOARD_REPORT:
      return { ...state };
    case API_DASHBOARD_REPORT_SUCCESS:
      let allReport = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "reports" in data && data.reports) {
          allReport = data.reports;
        }
      }
      return { ...state, reportData: allReport };
    case API_DASHBOARD_REPORT_FAIL:
      return { ...state };
    case API_LAST_ACTIVITY:
      return { ...state };
    case API_LAST_ACTIVITY_SUCCESS:
      let allActivity = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "last_activities" in data && data.last_activities) {
          allActivity = data.last_activities;
        }
      }
      return { ...state, homeActivityData: allActivity };

      return { ...state };
    case API_LAST_ACTIVITY_FAIL:
      return { ...state };
    case UPDATE_DASHBOARD_VIEW_REPORT_STATUS:
      return { ...state, dashboardViewReportStatus: action.payload };
    default:
      return state;
  }
};

export function dashboardCounts(payload: IDashboardData) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_DASHBOARD_COUNTS,
      payload: {
        request: {
          method: "get",
          url: `dashboard/report`,
          data: payload,
        },
      },
    });
  };
}
export function dashboardReport(payload: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_DASHBOARD_REPORT,
      payload: {
        request: {
          method: "get",
          url: `dashboard/report`,
          data: payload,
        },
      },
    });
  };
}

export function lastActivity(payload: THomeActive) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_LAST_ACTIVITY,
      payload: {
        request: {
          method: "get",
          url: `dashboard/latest-activity`,
          data: payload,
        },
      },
    });
  };
}

export function dashboardComplaint(query: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_DASHBOARD_COMPLAINT,
      payload: {
        request: {
          method: "get",
          url: query,
        },
      },
    });
  };
}

export function updateDashboardViewReportStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_DASHBOARD_VIEW_REPORT_STATUS,
      payload: status,
    });
  };
}
