import { Dispatch, AnyAction } from "redux";
import {
  API_GET_EMAIL_BY_SLUG,
  API_GET_EMAIL_BY_SLUG_SUCCESS,
  API_GET_EMAIL_BY_SLUG_FAIL,
  UPDATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_FAIL,
  EmailTemplateState,
  API_GET_ALL_EMAIL_TEMPLATE,
  API_GET_ALL_EMAIL_TEMPLATE_SUCCESS,
  API_GET_ALL_EMAIL_TEMPLATE_FAIL,
} from "../ActionTypes";

const initialState: EmailTemplateState = {
  email_Data: null,
  emailSuccess: "",
  emailTemplate: [],
};

export const EmailTemplateReducer = (
  state: EmailTemplateState = initialState,
  action: AnyAction
): EmailTemplateState => {
  switch (action.type) {
    case API_GET_EMAIL_BY_SLUG:
      return { ...state };
    case API_GET_EMAIL_BY_SLUG_SUCCESS:
      let message = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          message = data.message;
        }
      }
      return { ...state, email_Data: message };
    case API_GET_EMAIL_BY_SLUG_FAIL:
      return { ...state };
    case API_GET_ALL_EMAIL_TEMPLATE:
      return { ...state };
    case API_GET_ALL_EMAIL_TEMPLATE_SUCCESS:
      const { data } = action.payload;
      return { ...state, emailTemplate: data.emailData };
    case API_GET_ALL_EMAIL_TEMPLATE_FAIL:
      return { ...state };
    case UPDATE_EMAIL_TEMPLATE:
      return { ...state };
    case UPDATE_EMAIL_TEMPLATE_SUCCESS:
      return { ...state, emailSuccess: action.payload.data.message };
    case UPDATE_EMAIL_TEMPLATE_FAIL:
      return { ...state };
    default:
      return state;
  }
};

export function getEmailMessage(slug: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_EMAIL_BY_SLUG,
      payload: {
        request: {
          method: "get",
          url: `email-template/${slug}`,
        },
      },
    });
  };
}

export function editEmailMessage(payload: any, slug: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_EMAIL_TEMPLATE,
      payload: {
        request: {
          method: "put",
          url: `email-template/${slug}`,
          data: payload,
        },
      },
    });
  };
}

export function getAllEmailTemplate() {
  console.log("getAllEmailTemplate is true");

  return (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ALL_EMAIL_TEMPLATE,
      payload: {
        request: {
          method: "get",
          url: `email-template/`,
        },
      },
    });
  };
}
