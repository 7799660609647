import * as Yup from "yup";

import { Button, Dropdown, Offcanvas } from "react-bootstrap";
import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { EyeOffSVG, EyeSVG } from "../../assets/images/EmployeesSVG";
import { IProfileProps, IProfileState } from "../../types/topbar.type";

import AuthService from "../../services/auth.service";
import { DownArrowSVG } from "../../assets/images/Images";
import EditUser from "./EditUser";
import ProfileImg from "../../assets/images/profile.png";
import React from "react";
import { RootState } from "../../index";
import { TopNavEditSVG } from "../../assets/images/ImagesV2";
import { connect } from "react-redux";
import {
  updateProfile,
} from "../../shared/Reducers/index";

class Profile extends React.Component<IProfileProps, IProfileState> {
  constructor(props: IProfileProps) {
    super(props);

    this.state = {
      collapseMenu: true,
      old_password: "",
      new_pasword: "",
      // successful: false,
      message: "",
      confirm_password: "",
      show: false,
      hide_old: true,
      hide_new: true,
      hide_conf: true,
      editShow: false,
      messageClassName: "",
    };
  }

  // Edit User
  editAdminShow = () => {
    this.setState({ editShow: true });
  };
  editAdminClose = () => {
    this.setState({ editShow: false });
  };

  toggleOld = (e: any) => {
    e.preventDefault();
    this.setState({ hide_old: !this.state.hide_old });
  };

  toggleNew = (e: any) => {
    e.preventDefault();
    this.setState({ hide_new: !this.state.hide_new });
  };

  toggleConf = (e: any) => {
    e.preventDefault();
    this.setState({ hide_conf: !this.state.hide_conf });
  };

  showHide = (e: any) => {
    e.preventDefault();
    this.setState((prev) => ({ show: !prev.show }));
  };

  handleLogOut(): void {
    AuthService.logout();
    window.location.href = "/";
  }

  validationSchema() {
    return Yup.object().shape({
      old_password: Yup.string()
        .required("Enter Current Password")
        .test(
          "len",
          "Enter Current Password",
          (val: any) => val && val.toString().length >= 8
        ),
      new_password: Yup.string()
        .required("Enter new Password")
        .test(
          "len",
          "The password should be at least 8 letters",
          (val: any) => val && val.toString().length >= 8
        ),
      confirm_password: Yup.string()
        .required("Enter Confirm Password")
        .test(
          "len",
          "Enter confirm password",
          (val: any) => val && val.toString().length >= 8
        )
        .oneOf([Yup.ref("new_password"), null], "Passwords does not match"),
    });
  }

  handleChagePassword = async (
    formValue: {
      old_password: string;
      new_password: string;
      confirm_password: any;
    },
    { resetForm }: { resetForm: any }
  ) => {
    console.log(formValue);
    const { old_password, new_password } = formValue;

    this.setState({
      message: "",
      // successful: false
    });
    try {
      let update = await this.props.updatePassword({
        old_password: old_password,
        new_password: new_password,
      });
      console.log(this.props.updateWrongProfile);
      if (this.props.updateWrongProfile.status === 200) {
        formValue.old_password = "";
        formValue.new_password = "";
        formValue.confirm_password = "";
        this.setState(
          {
            message: this.props.updateWrongProfile.data.message,
            messageClassName: "success-alert",
          },
          () => {
            setTimeout(() => {
              this.setState({ editShow: false, message: "" }, () => {
                this.setState({ show: false });
              });
            }, 5000);
          }
        );
      } else if (this.props.updateWrongProfile.error.response.status === 406) {
        // formValue.old_password = "";
        // formValue.new_password = "";
        // formValue.confirm_password = "";
        this.setState({
          message: this.props.updateWrongProfile.response.data.message,
          messageClassName: "cus-alert",
        });
      }
    } catch (error) {
      // formValue.old_password = "";
      // formValue.new_password = "";
      // formValue.confirm_password = "";

      this.setState({
        message: this.props.updateWrongProfile.response.data.message,
        messageClassName: "cus-alert",
      });
      console.log(error);
    }

  };

  render(): React.ReactNode {
    const { message } = this.state;
    const initialValues = {
      old_password: "",
      new_password: "",
      confirm_password: "",
    };
    console.log(this.props.userLoginDetails, "user Props");

    return (
      <>
        {
          this.props.userLoginDetails?.permission?.topbar_profile === 1 && (
            <div>
              <Dropdown autoClose="outside" className="profile-bg">
                <Dropdown.Toggle
                  className="icon-style"
                  id="dropdown-autoclose-outside"
                >
                  <img src={ProfileImg} alt="profile" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="box-sized dropdown-size profile-bg">
                  <Dropdown.Item>
                    <div className="d-flex">
                      <div>
                        <img src={ProfileImg} alt="profile" />
                      </div>
                      <div style={{ marginLeft: "10px" }}>
                        <p className="mb-0">
                          {this.props.subAdmin
                            ? "Ricky Laurent"
                            : `${this.props.userLoginDetails?.first_name} ${this.props.userLoginDetails?.last_name}`}
                          <Offcanvas
                            placement="end"
                            show={this.state.editShow}
                            onHide={() => this.editAdminClose()}
                            className="default"
                          >
                            <Offcanvas.Header closeButton>
                              <Offcanvas.Title>
                                <img
                                  src={ProfileImg}
                                  className="offc-img"
                                  alt="profile"
                                />
                                <span className="offc-title">Edit Admin</span>
                              </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                              <EditUser
                                updateEditEmployeeDlgStatus={() =>
                                  this.editAdminClose()
                                }
                              />
                            </Offcanvas.Body>
                          </Offcanvas>
                        </p>
                        <Button
                          aria-controls="offcanvasRight"
                          className="btn btn-light profile-edit-icon"
                          onClick={() => this.editAdminShow()}
                        >
                          <TopNavEditSVG />
                        </Button>
                          <p className="mb-0 profile-ep text-container">
                            {this.props.userLoginDetails &&
                              this.props.userLoginDetails.email !== null
                              ? "E: " + this.props.userLoginDetails.email
                              : ""}
                            {this.props.userLoginDetails &&
                              this.props.userLoginDetails.phone !== null
                              ? " | P: " + this.props.userLoginDetails.phone
                              : ""}
                          </p>
                      </div>
                    </div>
                  </Dropdown.Item>
                  {!this.props.subAdmin && (
                    <Dropdown.Item>
                      <button
                        onClick={this.showHide}
                        className="btn full-width btn-text"
                        type="button"
                        data-target="#collapseExample"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        <span className="btn__label flex-hsb-vc">
                          <span className="btn__label--text">Change Password</span>
                          <DownArrowSVG />
                        </span>
                      </button>
                      <div className="update-pass">
                        {/* <Collapse in={!this.state.collapseMenu}>
                                            <Collapse in={!this.state.collapseMenu}> */}
                        {this.state.show && (
                          <div className="form-group">
                            <Formik
                              initialValues={initialValues}
                              validationSchema={this.validationSchema}
                              onSubmit={this.handleChagePassword}
                              enableReinitialize={true}
                            >
                              {({ handleSubmit }) => (
                                <div className="form-group">
                                  <Form>
                                    <div>
                                      <label className="form-label">
                                        Current Password
                                      </label>
                                      <div className=" eye-field">
                                        <Field
                                          name="old_password"
                                          type={
                                            this.state.hide_old ? "password" : "text"
                                          }
                                          className="form-control"
                                        />
                                        <button
                                          type="button"
                                          className="eye btn"
                                          onClick={this.toggleOld}
                                        >
                                          {this.state.hide_old ? (
                                            <EyeOffSVG />
                                          ) : (
                                            <EyeSVG />
                                          )}
                                        </button>
                                      </div>
                                      <ErrorMessage
                                        name="old_password"
                                        component={"div"}
                                        className="cus-alert"
                                      />
                                    </div>
                                    <div>
                                      <label className="form-label">
                                        New Password
                                      </label>
                                      <div className="eye-field">
                                        <Field
                                          name="new_password"
                                          type={
                                            this.state.hide_new ? "password" : "text"
                                          }
                                          className="form-control"
                                        />
                                        <button
                                          type="button"
                                          className="eye btn"
                                          onClick={this.toggleNew}
                                        >
                                          {this.state.hide_new ? (
                                            <EyeOffSVG />
                                          ) : (
                                            <EyeSVG />
                                          )}
                                        </button>
                                      </div>
                                      <ErrorMessage
                                        name="new_password"
                                        component={"div"}
                                        className="cus-alert"
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <label className="form-label">
                                        Confirm Password
                                      </label>
                                      <div className="eye-field">
                                        <Field
                                          name="confirm_password"
                                          type={
                                            this.state.hide_conf ? "password" : "text"
                                          }
                                          className="form-control"
                                        />
                                        <button
                                          type="button"
                                          className="btn eye"
                                          onClick={this.toggleConf}
                                        >
                                          {this.state.hide_conf ? (
                                            <EyeOffSVG />
                                          ) : (
                                            <EyeSVG />
                                          )}
                                        </button>
                                      </div>
                                      <ErrorMessage
                                        name="confirm_password"
                                        component={"div"}
                                        className="cus-alert"
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <button
                                        type="submit"
                                        onClick={(e: any) => handleSubmit(e)}
                                        className="btn btn-primary update-pass-btn"
                                      >
                                        Update Password
                                      </button>
                                    </div>
                                  </Form>
                                </div>
                              )}
                            </Formik>
                          </div>
                        )}
                        {message && (
                          <div className="form-group">
                            <div
                              className={this.state.messageClassName}
                              role={"alert"}
                            >
                              {message}
                            </div>
                          </div>
                        )}
                        {/* </Collapse></Collapse> */}
                      </div>
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item className="text-center">
                    <Button className="logout-button" onClick={this.handleLogOut}>
                      Log Out
                    </Button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )
        }
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  subAdmin: state.app.subAdmin,
  userLoginDetails: state.sidebar.userLoginDetails,
  updateWrongProfile: state.usermanagement.updateWrongProfile,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updatePassword: bindActionCreators(updateProfile, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
