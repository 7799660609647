import React from "react";
import SideBar from "../../components/sidebar/SideBar";
import TopNav from "../../components/topnav/TopNav";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "../../index";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { PaperClipSVG, TaskStatusSVG, TrashSVG } from "../../assets/images/ImagesV2";
import { ModalBackSVG } from "../../assets/images/ImagesV2";
import {
  getCleanerLoginBranchList,
  getCleanerLoginBranchTaskList,
  getCleanerLoginBranchTaskDetails,
  getCleanerLastActivity,
  changeViewTaskListStatus,
  changeViewTaskDetailsStatus,
  changeAddEditStatusCommetsStatus,
  updateSidebar,
  addDailyTask,
  getAllRecommendedTasks,
  getBranchById
} from "../../shared/Reducers";
import Constants, { TASK_PRIORITY } from "../../shared/Constants";
import moment from "moment";
import { Modal, ProgressBar, Offcanvas } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
// import Modal from "react-bootstrap/Modal";
import AddEditCommentsCleanerHome from "./AddEditCommentsCleanerHome";
import DisplayCommentsCleanerHome from "./DisplayCommentsCleanerHome";
import { AttachmentDownloadSVG } from "../../assets/images/ImagesSVG";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";
import docImage from "../../assets/images/google-docs.png";
import AttachmentPreview from "../../shared/Components/AttachmentPreview";
import { ErrorMessage, Field, Form, Formik } from "formik";
import DatePicker from "../DailyTask/DatePicker";
import CustomSelect from "../../shared/Components/CustomSelect";
import { ICleaner } from "../../shared/ActionTypes";

export interface ICleanerHomeProps {
  getCleanerLastActivity?: any;
  getCleanerLoginBranchList?: any;
  getCleanerLoginBranchTaskList?: any;
  getCleanerLoginBranchTaskDetails?: any;
  getCleanerBranchListData?: any;
  getCleanerBranchTaskListData?: any;
  getCleanerBranchTaskDetailsData?: any;
  getLastActivityData?: any;
  viewTaskListStatus: boolean;
  viewTaskDetailsStatus: boolean;
  changeViewTaskListStatus?: any;
  changeViewTaskDetailsStatus?: any;
  changeAddEditStatusCommetsStatus?: any;
  processTrigger: any;
  progressPercentage: number;
  addEditTaskCommentsModalStatus?: boolean;
  deleteCleanerCommentsSuccessStatus: boolean;
  updateSideBar: any;
  getAllRecommendedTasks: any;
  addDailyTask: any;
  filterTaskClientById: any;
  filterTaskBranchById: any;
  filterTaskPriorityById: any;
  filterTaskDueTypeByValue: any;
  filterTaskStatusById: any;
  filterSpecialRequestTaskStatusById: any;
  getBranchById: any;
  selectedData: any;
}

export interface ICleanerHomeState {
  redirect: string;
  AttachmentPreviewStatus: boolean;
  attachmentUrls: any,
  previewUrl: string;
  previewType: string;
  canvasModal: any;
  attachmentFiles: any;
  task_date: any;
  files_size: any;
  file_count: any;
  attachmentUrl: any;
}

class CleanerHome extends React.Component<
  ICleanerHomeProps,
  ICleanerHomeState
> {
  constructor(props: ICleanerHomeProps) {
    super(props);
    this.state = {
      attachmentUrls: [],
      attachmentUrl:[],
      AttachmentPreviewStatus: false,
      redirect: "",
      previewUrl: "",
      previewType: "",
      canvasModal: false,
      attachmentFiles: [],
      task_date: "",
      files_size: 0,
      file_count: "",
    };
  }

  componentDidMount(): void {
    document.title = "Home";
    this.props.updateSideBar(true);
    this.props.getCleanerLastActivity();
    this.props.getCleanerLoginBranchList();

    if (this.props.getCleanerBranchTaskDetailsData?.attachments !== undefined) {
      this.setState({
        attachmentUrls: this.props.getCleanerBranchTaskDetailsData?.attachments.map((obj: any) => ({ url: `${Constants.imgThumbUrl}${obj.file_name}`, name: obj.file_name, id: obj.id, type: 'download', download_url: `${Constants.imgUrl}${obj.file_name}`, file_type: obj.type }))
      });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<ICleanerHomeProps>,
    prevState: Readonly<ICleanerHomeState>,
    snapshot?: any
  ): void {

    if (this.props.getCleanerBranchTaskDetailsData !== prevProps.getCleanerBranchTaskDetailsData) {
      if (this.props.getCleanerBranchTaskDetailsData?.attachments !== undefined) {
        this.setState({
          attachmentUrls: this.props.getCleanerBranchTaskDetailsData?.attachments.map((obj: any) => ({ url: `${Constants.imgThumbUrl}${obj.file_name}`, name: obj.file_name, id: obj.id, type: 'download', download_url: `${Constants.imgUrl}${obj.file_name}`, file_type: obj.type }))
        });
      }
    }


    if (
      prevProps.addEditTaskCommentsModalStatus !==
      this.props.addEditTaskCommentsModalStatus &&
      this.props.addEditTaskCommentsModalStatus === false
    ) {
      this.props.getCleanerLoginBranchTaskDetails(
        this.props.getCleanerBranchTaskDetailsData.id
      );
    }
    if (
      prevProps.deleteCleanerCommentsSuccessStatus !==
      this.props.deleteCleanerCommentsSuccessStatus &&
      this.props.deleteCleanerCommentsSuccessStatus === true
    ) {
      this.props.getCleanerLoginBranchTaskDetails(
        this.props.getCleanerBranchTaskDetailsData.id
      );
    }
  }

  viewTaskList = (row: any) => {

    let taskListStatus = false;
    console.log(row.task_with_sign_in === Constants.TASK_WITH_SIGN_IN_NO);
    console.log(row, "hello");
    console.log(this.props.getLastActivityData.branch_id, "hiii");


    if (row.task_with_sign_in === Constants.TASK_WITH_SIGN_IN_NO) {
      taskListStatus = true;
    } else if (
      this.props.getLastActivityData.branch_id === row.branch_id &&
      this.props.getLastActivityData.sign_out_date_time === null
    ) {
      taskListStatus = true;
    } else {
      this.setState({ redirect: "/in-out" });
    }

    if (taskListStatus === true) {
      this.props.getCleanerLoginBranchTaskList(row.branch_id);
      this.props.getBranchById(row.branch_id);
    }
  };

  viewTaskDetails = (row: any) => {
    this.props.getCleanerLoginBranchTaskDetails(row.id);
  };

  downloadFile = (url: string, name = 'image.png') => {
    this.downloadResource(url, name);
  }

  // Current blob size limit is around 500MB for browsers
  downloadResource = (url: any, filename: any) => {
    if (url) {
      if (!filename) filename = url.split('\\').pop().split('/').pop();
      fetch(url, {
        headers: new Headers({
          'Origin': window.location.origin
        }),
        mode: 'cors'
      })
        .then(response => response.blob())
        .then(blob => {
          let blobUrl = window.URL.createObjectURL(blob);
          this.forceDownload(blobUrl, filename);
        })
        .catch(e => console.error(e));
    }
  }
  handlePreviewClose = () => {
    this.setState({ AttachmentPreviewStatus: false });
  }
  handlePreviewOpen = (file_type: any, url: any) => {
    console.log(url);

    if (file_type === "image/png" || file_type === "image/jpeg" || file_type === "image/jpg" || file_type === "video/mp4" || file_type === "video/webm" || file_type === "application/pdf") {
      this.setState({
        AttachmentPreviewStatus: true,
        previewUrl: url,
        previewType: file_type
      });
    }
  }


  forceDownload = (blob: any, filename: String) => {
    const a: any = document.createElement('a');
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  handleToOpenCanvas = async () => {
    await this.props.getCleanerLoginBranchList();
    await this.props.getCleanerLoginBranchList();
    await this.props.changeViewTaskListStatus(false);
    await this.setState({ canvasModal: true });
  };

  handleCloseAdditionalTaskModal = () => {
    this.setState({
      canvasModal: false,
      attachmentUrls: [],
    });
  };

  handleToRecommendedTask = async (formValues: any) => {
    console.log("CleanerFormValue", formValues);

    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");

    if (element !== null && button !== null) {
      element.classList.remove("d-none")
      button.setAttribute("disabled", "")
    }
    let data = new FormData();
    data.append("description", formValues.description)
    for (let index = 0; index < this.state.attachmentFiles.length; index++) {
      data.append("attachments", this.state.attachmentFiles[index]);
    }
    data.append("title", "recommended");
    data.append("client", this.props.selectedData.client_id);
    data.append("branch", this.props.selectedData.id);
    if (!this.state.task_date) {
      const currentDate = new Date().toISOString().split('T')[0];
      this.setState({ task_date: currentDate }, () => {
        data.append("task_date", this.state.task_date)
      })
    } else {
      data.append("task_date", this.state.task_date);
    }
    data.append("task_type", "3");
    data.append("any_time", "0");
    data.append("ref_task_id", "1");
    await this.props.addDailyTask(data);
    setTimeout(() => {
      this.handleCloseAdditionalTaskModal();
      this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
    }, 500);
  };

  getTasks = async (offset: number, limit: number) => {
    try {
      // list for table
      let query = `search=&sort=&order=&offset=${offset}&limit=${limit}&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=3&task_priority=${this.props.filterTaskPriorityById}&due_type=${this.props.filterTaskDueTypeByValue}&task_status=${this.props.filterTaskStatusById}&special_request_task_status=${this.props.filterSpecialRequestTaskStatusById}&recommended_task_status=all`;

      // list for excel report
      // let AllListquery = `search=&sort=&order=&offset=${offset}&limit=10000&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=3&task_priority=${this.props.filterTaskPriorityById}&due_type=${this.props.filterTaskDueTypeByValue}&task_status=${this.props.filterTaskStatusById}&special_request_task_status=${this.props.filterSpecialRequestTaskStatusById}&status&start_date=${this.props.filterTaskDateTypeValue.from_date}&end_date=${this.props.filterTaskDateTypeValue.to_date}`;
      await this.props.getAllRecommendedTasks(query);
      // await this.props.getAllRecommendedTasks(AllListquery);
    } catch (error) {
      console.log(error);
    }
  };

  validationSchema = () => {
    return Yup.object().shape({
      description: Yup.string().required("Enter Task Description"),
      attachments: Yup.mixed(),
      // task_date: Yup.string().required("Date is required")
    })
  };

  handleImageChange = async (e: any) => {
    const file = e.target.files[0];
    this.setState(
      { files_size: this.state.files_size + file.size },
      async () => {
        if (this.state.files_size > 524288000) {
          alert("File Size Must Be Less Than 500MB");
          e.target.value = null;
          return this.setState({
            files_size: this.state.files_size - file.size,
          });
        }

        let files = [];
        let urls = [];
        files = [...e.target.files];

        let fileurls: any = [];
        await files.map(async (file: any) => {
          fileurls.push({
            url: URL.createObjectURL(file),
            file_type: file.type,
          });
        });
        urls = [...fileurls];

        this.setState({
          attachmentFiles: [...this.state.attachmentFiles, ...files],
        });
        this.setState(
          { attachmentUrls: [...this.state.attachmentUrls, ...urls],  attachmentUrl: [...this.state.attachmentUrl, ...urls] },
          () => {
            e.target.value = "";
          }
        );
      }
    );
  };

  handleDeleteImage = (index: number) => {
    const files = this.state.attachmentFiles;
    const urls = this.state.attachmentUrls;
    files.splice(index, 1);
    urls.splice(index, 1);

    this.setState({ attachmentFiles: files });
    this.setState({ attachmentUrls: urls });
  };

  handleStartDateClick = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let startDate: HTMLElement = document.querySelector(
        ".startdate button.MuiButtonBase-root"
      ) as HTMLElement;
      startDate.click();
    }
  };

  handleTaskDate = (val: string) => {
    this.setState({ task_date: val });
  };

  handlePreviewOpend = (file_type: any, url: any, inx: any) => {
    if (
      file_type === "image/png" ||
      file_type === "image/jpeg" ||
      file_type === "image/jpg" ||
      file_type === "video/mp4" ||
      file_type === "video/webm" ||
      file_type === "application/pdf"
    ) {
      this.setState({
        AttachmentPreviewStatus: true,
        previewUrl: url,
        previewType: file_type,
        file_count: inx,
      });
    }
  };

  render(): React.ReactNode {
    const initialValues = {
      description: "",
      attachments: "",
      task_date: "",
    }
    // Navigate to Home
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className="main-panel">
          <TopNav />
          <div className="content-wrapper">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="mb-0">Today visit branch list</h3>
                </div>
              </div>
              {this.props.getCleanerBranchListData !== null &&
                this.props.getCleanerBranchListData.length > 0 && (
                  <div className="row">
                    {/* <div className='col-12'>
                                        <p>Today visit branch list</p>
                                    </div> */}
                    {this.props.getCleanerBranchListData.map(
                      (row: any, index: number) => (
                        <div className="col-md-4">
                          <div className="res-mb-3 mt-4">
                            <div
                              className="card cursor-pointer"
                              onClick={() => this.viewTaskList(row)}
                            >
                              <div className="card-body text-left p-4">
                                <p className="p-0 mb-2 fw-600 text-capitalize fs-18px">
                                  {row.branch_name}
                                </p>
                                <p className="p-0 mb-2">{row.address}</p>
                                <p className="p-0 mb-3 fw-500">
                                  <span>
                                    {row.any_time === 1
                                      ? "Any Time"
                                      : moment(
                                        row.start_time,
                                        "HH:mm:ss"
                                      ).format("hh:mm A") +
                                      " - " +
                                      moment(row.end_time, "HH:mm:ss").format(
                                        "hh:mm A"
                                      )}
                                  </span>
                                  -
                                  <span>
                                    {moment(row.task_date).format("DD MMM YY")}
                                  </span>
                                </p>
                                <p className="z-index-9999 mb-0">
                                  <a
                                    onClick={() => {
                                      setTimeout(() => {
                                        this.props.changeViewTaskListStatus(
                                          false
                                        );
                                      }, 100);
                                    }}
                                    href={
                                      "https://www.google.com/maps/dir/" +
                                      row.lat +
                                      "," +
                                      row.lng
                                    }
                                    target="_blank"
                                    className="btn btn-primary btn-sm locate-map-btn"
                                  >
                                    Locate on map
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                )}
              {this.props &&
                this.props.getCleanerBranchListData &&
                this.props.getCleanerBranchListData.length === 0 && (
                  <div>
                    <p className="text-center">No Task list found</p>
                  </div>
                )}
              <Modal
                size="lg"
                show={this.props.viewTaskListStatus}
                onHide={() => {
                  this.props.changeViewTaskListStatus(false);
                }}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    <h3 className="m-0">Task list</h3>
                  </Modal.Title>
                  <Button
                    variant="outlined"
                    onClick={this.handleToOpenCanvas}
                    className="add-recommended-btn"
                  >
                    Add Recommended
                  </Button>
                </Modal.Header>
                <Modal.Body className="p-4 pt-0">
                  {this.props.getCleanerBranchTaskListData !== null &&
                    this.props.getCleanerBranchTaskListData.length > 0 && (
                      <div className="row">
                        {this.props.getCleanerBranchTaskListData.map(
                          (row: any, index: number) => (
                            <div className="col-md-12">
                              <div className="mt-3">
                                <div
                                  className="card cursor-pointer"
                                  onClick={() => this.viewTaskDetails(row)}
                                >
                                  <div className="card-body text-left p-4">
                                    <p className="p-0 mb-2 fw-600 text-capitalize">
                                      {row.title}
                                    </p>
                                    <div className="row">
                                      <div className="col-md-12 col-lg-6">
                                        <p className="m-0">
                                          <span className="statisfied-badge view-task-comment-badge">
                                            {row.task_type ===
                                              Constants.REGULAR_TASK
                                              ? "Regular"
                                              : row.task_type ===
                                                Constants.SPECIAL_REQUEST
                                              ? "Special"
                                              : row.task_type === 
                                                Constants.ADDITIONAL_TASK
                                              ? "Approved Task"
                                              : row.task_type ===
                                                Constants.RECOMMENDED_TASK
                                              ? "Recommended Task"
                                              : ""  
                                              }
                                          </span>
                                          <span className="completed-badge view-task-comment-badge ml-8">
                                            {row.priority === TASK_PRIORITY.LOW
                                              ? "Low"
                                              : row.priority ===
                                                TASK_PRIORITY.MEDIUM
                                                ? "Medium"
                                                : row.priority ===
                                                  TASK_PRIORITY.HIGH
                                                  ? "High"
                                                  : ""}
                                          </span>
                                        </p>
                                      </div>
                                      <div className="col-md-12 col-lg-6 text-lg-end">
                                        <p className="m-0">
                                          {row.task_status_name !== null && (
                                            <span className={row.task_status_name + "-badge view-task-comment-badge"}>
                                              {row.task_status_name}
                                            </span>
                                          )}
                                          {row.task_feedback_name !== null && (
                                            <span className={row.task_feedback_name + "-badge view-task-comment-badge"}>
                                              {row.task_feedback_name}
                                            </span>
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12 col-lg-6 text-md-start text-lg-end lg-mt-3"></div>
                                      <div className="col-md-12 col-lg-6 text-md-start text-lg-end lg-mt-3">
                                        <p className="p-0 mb-0 text-grey">
                                          <span>
                                            {row.any_time === 1
                                              ? "Any Time"
                                              : moment(
                                                row.start_time,
                                                "HH:mm:ss"
                                              ).format("hh:mm A") +
                                              " - " +
                                              moment(
                                                row.end_time,
                                                "HH:mm:ss"
                                              ).format("hh:mm A")}
                                          </span>
                                          <span>&nbsp;-&nbsp;</span>
                                          <span>
                                            {moment(row.task_date).format(
                                              "DD MMM YY"
                                            )}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                </Modal.Body>
              </Modal>

              <Offcanvas
                className="task-offcanvas"
                placement='end'
                show={this.state.canvasModal}
                onHide={() => this.handleCloseAdditionalTaskModal()}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>
                    <span className="offc-title">Add Recommended Task</span>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={this.handleToRecommendedTask}
                    validationSchema={this.validationSchema}
                  >
                    <Form>
                      <div className='mb-2'>
                        <label className='form-label'>
                          Recommended Description<span className="text-project">*</span>
                        </label>
                        <Field
                          as="textarea"
                          name="description"
                          className="form-control text-area"
                        />
                        <ErrorMessage
                          name="description"
                          component={"div"}
                          className="cus-alert"
                        />
                      </div>
                      <div>
                        <label className="form-label">Attachment
                          <span className="text-project">*</span>
                        </label>
                        <div className='attachment-btn dropdown-field'>
                          <input
                            type='file'
                            multiple
                            accept='.pdf,.PDF,.mp4,.MP4,.webm,.WEBM,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.doc,.DOC,.docx,.DOCX'
                            style={{ display: "none" }}
                            onChange={this.handleImageChange}
                            id='recommended-attachment'
                          />
                          <label htmlFor='recommended-attachment'>
                            <Button
                              variant="contained"
                              color="default"
                              component="span"
                            >
                              <PaperClipSVG /> Add attachment
                            </Button>
                          </label>
                        </div>
                      </div>
                      {this.state.attachmentUrls.length > 0 ? (
                        <div className='mb-2'>
                          <label className="form-label">Attachments</label>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='d-flex row'>
                            {this.state.attachmentUrl && this.state.attachmentUrl.map((obj: any, index: number) => {
                              return (
                                <div className='img-card mr-16px mb-3'>
                                  <span className='delete'>
                                    <a onClick={() => this.handleDeleteImage(index)}>
                                      <TrashSVG />
                                    </a>
                                  </span>
                                  <div className='img-preview'
                                    onClick={() => this.handlePreviewOpend(
                                      obj.file_type,
                                      obj.url,
                                      index
                                    )}
                                  >
                                    <img className='img-thumb-perview'
                                      src={
                                        obj.file_type ===
                                          "application/pdf"
                                          ? pdfImage
                                          : obj.file_type ===
                                            "video/mp4" ||
                                            obj.file_type === "video/webm"
                                            ? videoImage
                                            : obj.file_type ===
                                              "image/png" ||
                                              obj.file_type ===
                                              "image/jpeg" ||
                                              obj.file_type === "image/jpg"
                                              ? obj.url
                                              : docImage
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      {this.props.selectedData !== null && (
                        <div className='row'>
                          <div className='mb-2 col-md-6'>
                            <label className='form-label'>
                              Client<span className="text-project">:</span>
                            </label>
                            <br />
                            {this.props.selectedData
                              .first_name +
                              " " +
                              this.props.selectedData
                                .last_name}
                          </div>
                          <div className='mb-2 col-md-6'>
                            <label className='form-label'>
                              Branch<span className="text-project">:</span>
                            </label><br />
                            {this.props.selectedData.name}
                          </div>
                        </div>
                      )}
                      <div className='mb-2 col-md-6'>
                        <label className='form-label'>
                          Date<span className="text-project">*</span>
                        </label>
                        <div className='startdate'
                          onClick={() => this.handleStartDateClick()}
                        >
                          <DatePicker
                            name="task_date"
                            value={this.state.task_date}
                            onChange={this.handleTaskDate}
                          />
                          <ErrorMessage
                            name="task_date"
                            component={"div"}
                            className="cus-alert"
                          />
                        </div>
                      </div>
                      <div className='d-flex'>
                        <div className='mr-16px'>
                          <button
                            type='submit'
                            id='create-button'
                            className='btn btn-primary create-button'
                          >
                            Request{" "}
                            <span
                              id="spinner-border"
                              className="spinner-border spinner-border-sm d-none"
                            ></span>
                          </button>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn cancel-button"
                            onClick={() =>
                              this.handleCloseAdditionalTaskModal()
                            }
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </Formik>
                </Offcanvas.Body>
              </Offcanvas>

              <Modal
                className="task-details-modal"
                size="lg"
                show={this.props.viewTaskDetailsStatus}
                onHide={() => {
                  this.props.changeViewTaskDetailsStatus(false);
                  this.props.changeViewTaskListStatus(true);
                }}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                {/* <Modal.Header closeButton>
                                    <Modal.Title id="example-modal-sizes-title-lg">
                                        <h3 className="m-0">Task details</h3>
                                    </Modal.Title>
                                </Modal.Header> */}
                <Modal.Header>
                  <div className="w-100">
                    <div className="row">
                      <div className="col-10">
                        <h3 className="mt-1 mb-0">Task details</h3>
                      </div>
                      <div className="col-2 text-end">
                        <Button
                          className="btn btn-primary modal-back-btn"
                          onClick={() => {
                            this.props.changeViewTaskDetailsStatus(false);
                            this.props.changeViewTaskListStatus(true);
                          }}
                        >
                          <ModalBackSVG />
                          &nbsp;Back
                        </Button>
                      </div>
                    </div>
                  </div>
                </Modal.Header>
                <Modal.Body className="p-4">
                  {this.props.getCleanerBranchTaskDetailsData !== null && (
                    <div className="row">
                      <div className="col-md-12">
                        <h3 className="text-center text-capitalize">
                          {this.props.getCleanerBranchTaskDetailsData.title}
                        </h3>
                        <div className="card mt-3">
                          <div className="card-body">
                            <p className="mb-2 fw-600">Descriptionas</p>
                            <p className="mb-0">
                              {
                                this.props.getCleanerBranchTaskDetailsData
                                  .description
                              }
                            </p>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <>{console.log(this.state.attachmentUrls.length)}</>
                          <>{console.log(this.state.attachmentUrls)}</>
                          {this.state.attachmentUrls.length > 0 ? (
                            <div className="mb-2">
                              <label className="form-label m-0">Attachments</label>
                            </div>
                          ) : (<></>)}
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="d-flex row">
                              {this.state.attachmentUrls &&
                                this.state.attachmentUrls.map((obj: any, index: number) => (
                                  <div className="img-card mr-16px mb-3">
                                    {
                                      obj.type === 'download' && <span className="download">
                                        <a href="javascript:void(0)" onClick={() => this.downloadFile(obj.download_url, obj.file_name)}><AttachmentDownloadSVG /></a>
                                      </span>
                                    }

                                    <div className="img-preview" onClick={() => this.handlePreviewOpen(obj.file_type, obj.download_url)}>
                                      <img className="img-thumb-perview" src={
                                        obj.file_type === "application/pdf" ? pdfImage :
                                          obj.file_type === "video/mp4" || obj.file_type === "video/webm" ? videoImage :
                                            obj.file_type === "image/png" || obj.file_type === "image/jpeg" || obj.file_type === "image/jpg" ? obj.url : docImage
                                      } alt="attach" />
                                    </div>
                                  </div>
                                ))}
                              <AttachmentPreview displayPreview={this.state.AttachmentPreviewStatus} previewUrl={this.state.previewUrl} previewType={this.state.previewType} handlePreviewClose={this.handlePreviewClose} />
                            </div>
                          </div>
                        </div>
                        <div className="card mt-3">
                          <div className="card-body">
                            <p className="mb-2 fw-600">Other information</p>
                            <p className="mb-2">
                              Client :{" "}
                              {this.props.getCleanerBranchTaskDetailsData
                                .first_name +
                                " " +
                                this.props.getCleanerBranchTaskDetailsData
                                  .last_name}
                            </p>
                            <p className="mb-2">
                              Branch :{" "}
                              {
                                this.props.getCleanerBranchTaskDetailsData
                                  .branch_name
                              }
                            </p>
                            <p className="mb-2">
                              Date :{" "}
                              {moment(
                                this.props.getCleanerBranchTaskDetailsData
                                  .task_date
                              ).format("DD MMM YY")}
                            </p>
                            <p className="mb-2">
                              Time :{" "}
                              {this.props.getCleanerBranchTaskDetailsData
                                .any_time === 1
                                ? "Any Time"
                                : moment(
                                  this.props.getCleanerBranchTaskDetailsData
                                    .start_time,
                                  "HH:mm:ss"
                                ).format("hh:mm A") +
                                " - " +
                                moment(
                                  this.props.getCleanerBranchTaskDetailsData
                                    .end_time,
                                  "HH:mm:ss"
                                ).format("hh:mm A")}
                            </p>
                          </div>
                        </div>
                        <div className="card mt-3">
                          <div className="card-body">
                            <p className="mb-2 fw-600">Task history</p>
                            {this.props.getCleanerBranchTaskDetailsData.commants
                              .length > 0 && (
                                <div>
                                  <DisplayCommentsCleanerHome
                                    data={
                                      this.props.getCleanerBranchTaskDetailsData
                                        .commants
                                    }
                                  />
                                </div>
                              )}
                            {this.props.getCleanerBranchTaskDetailsData.commants
                              .length === 0 && <div>No history found</div>}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 text-end mt-3">
                        <Button
                          onClick={() => {
                            this.props.changeViewTaskDetailsStatus(false);
                            this.props.changeAddEditStatusCommetsStatus(true);
                          }}
                          className="task-status-btn"
                        >
                          <TaskStatusSVG />
                        </Button>
                        {/* <button onClick={() => {
                                                        this.props.changeViewTaskDetailsStatus(false);
                                                        this.props.changeAddEditStatusCommetsStatus(true)
                                                    }} className='btn btn-primary create-button pull-right'>Add status</button> */}
                        {/* <button onClick={() => {
                                                        this.props.changeViewTaskDetailsStatus(false);
                                                        this.props.changeAddEditStatusCommetsStatus(true)
                                                    }}><taskStatusSVG/></button> */}
                      </div>
                    </div>
                  )}
                </Modal.Body>
              </Modal>
              <AddEditCommentsCleanerHome />
              <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.processTrigger}
              >
                <Modal.Body className="p-0">
                  <ProgressBar
                    now={this.props.progressPercentage}
                    label={
                      this.props.progressPercentage === 100
                        ? `Processing your request...`
                        : `Loading....${this.props.progressPercentage}%`
                    }
                  />
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  getLastActivityData: state.cleanerinout.getLastActivityData,
  getCleanerBranchListData: state.cleanerhome.getCleanerBranchListData,
  getCleanerBranchTaskListData: state.cleanerhome.getCleanerBranchTaskListData,
  getCleanerBranchTaskDetailsData:
    state.cleanerhome.getCleanerBranchTaskDetailsData,
  viewTaskListStatus: state.cleanerhome.viewTaskListStatus,
  viewTaskDetailsStatus: state.cleanerhome.viewTaskDetailsStatus,
  processTrigger: state.cleanerhome.processTrigger,
  progressPercentage: state.cleanerhome.progressPercentage,
  addEditTaskCommentsModalStatus:
    state.cleanerhome.addEditTaskCommentsModalStatus,
  deleteCleanerCommentsSuccessStatus:
    state.cleanerhome.deleteCleanerCommentsSuccessStatus,
  filterTaskClientById: state.dailytask.filterTaskClientById,
  filterTaskBranchById: state.dailytask.filterTaskBranchById,
  filterTaskPriorityById: state.dailytask.filterTaskPriorityById,
  filterTaskDueTypeByValue: state.dailytask.filterTaskDueTypeByValue,
  filterTaskStatusById: state.dailytask.filterTaskStatusById,
  filterSpecialRequestTaskStatusById:
    state.dailytask.filterSpecialRequestTaskStatusById,
  selectedData: state.branch.selectedData
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getCleanerLastActivity: bindActionCreators(
      getCleanerLastActivity,
      dispatch
    ),
    getCleanerLoginBranchList: bindActionCreators(
      getCleanerLoginBranchList,
      dispatch
    ),
    changeViewTaskListStatus: bindActionCreators(
      changeViewTaskListStatus,
      dispatch
    ),
    changeViewTaskDetailsStatus: bindActionCreators(
      changeViewTaskDetailsStatus,
      dispatch
    ),
    getCleanerLoginBranchTaskList: bindActionCreators(
      getCleanerLoginBranchTaskList,
      dispatch
    ),
    getCleanerLoginBranchTaskDetails: bindActionCreators(
      getCleanerLoginBranchTaskDetails,
      dispatch
    ),
    changeAddEditStatusCommetsStatus: bindActionCreators(
      changeAddEditStatusCommetsStatus,
      dispatch
    ),
    updateSideBar: bindActionCreators(updateSidebar, dispatch),
    addDailyTask: bindActionCreators(addDailyTask, dispatch),
    getAllRecommendedTasks: bindActionCreators(getAllRecommendedTasks, dispatch),
    getBranchById: bindActionCreators(getBranchById, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CleanerHome);
