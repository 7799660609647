import { Dispatch } from "redux";
import { Modal } from "react-bootstrap";
import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
import moment from "moment";
import { viewConfirmProps } from "../../types/schedule.type";
import { DisableSVG, EnableSVG } from "../../assets/images/UsermanagementsSVG";
import Constants from "../../shared/Constants";

class DeleteConfirm extends React.Component<viewConfirmProps> {
  componentDidMount(): void {
    console.log(this.props.scheduleData, "f");
  }
  render(): React.ReactNode {
    const { title } = this.props.scheduleData;
    console.log(this.props.scheduleData);

    return (
      <div>
        <Modal
          size="lg"
          dialogClassName="modal-90w"
          aria-labelledby="contained-modal-title-vcenter"
          show={this.props.viewSchedule}
          onHide={() => this.props.handleViewClose(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              <h3 className="m-0">Schedule Details</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <div className="row">
              <div className="col-6">
                <p className="mb-1">Task Name</p>
                <p className="fw-600">{this.props.scheduleData.schedule_name}</p>
              </div>
              <div className="col-6">
                <p className="mb-1">Client</p>
                <p className="fw-600">
                  {this.props.scheduleData.first_name +
                    " " +
                    this.props.scheduleData.last_name}
                </p>
              </div>
              <div className="col-6">
                <p className="mb-1">Branch</p>
                <p className="fw-600">{this.props.scheduleData.branch_name}</p>
              </div>
              {Constants.QAUTINT === Constants.LOGIN_USER_TYPE ||
              Constants.QA_MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
              Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE ? null : (
                <div className="col-6">
                  <p className="mb-1">Cleaners count</p>
                  {this.props.scheduleData.cleaners !== "" &&
                  this.props.scheduleData.cleaners !== null &&
                  this.props.scheduleData.cleaners !== undefined ? (
                    <p className="fw-600">
                      {this.props.scheduleData.cleaners.length > 0
                        ? this.props.scheduleData.cleaners.length
                        : ""}
                    </p>
                  ) : null}
                </div>
              )}
              {Constants.QAUTINT === Constants.LOGIN_USER_TYPE ||
              Constants.QA_MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
              Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE ? null : (
                <div className="col-6">
                  <p className="mb-1">Cleaners list</p>
                  {this.props.scheduleData.cleaners !== "" &&
                  this.props.scheduleData.cleaners !== undefined ? (
                    <>
                      {this.props.scheduleData.cleaners.map(
                        (name: any, index: number) => (
                          <p className="fw-600">
                            {index + 1 + ". "}
                            {name.first_name + " " + name.last_name}
                          </p>
                        )
                      )}
                    </>
                  ) : null}
                </div>
              )}
              <div className="col-6">
                <p className="mb-1">Date</p>
                {this.props.scheduleData.specific_dates !== null ? (
                  <>
                    <p className="fw-600">Specific Dates</p>
                    <p className="fw-300">
                      {this.props.scheduleData.specific_dates}
                    </p>
                  </>
                ) : this.props.scheduleData.days !== null ? (
                  <>
                    <p className="fw-600">Every Month</p>
                    <p className="fw-300">{this.props.scheduleData.days}</p>
                  </>
                ) : this.props.scheduleData.week_names !== null ? (
                  <>
                    <p className="fw-600">Every Month</p>
                    <p className="fw-300">
                      {this.props.scheduleData.day_names}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="fw-600">Every Week</p>
                    <p className="fw-300">
                      {this.props.scheduleData.day_names}
                    </p>
                  </>
                )}
              </div>
              <div className="col-6">
                <p className="mb-1">Time</p>
                {this.props.scheduleData.any_time === 0 ? (
                  <>
                    <p className="fw-600">
                      Start time:
                      {moment(
                        this.props.scheduleData.start_time,
                        "HH:mm:ss"
                      ).format("hh:mm A")}
                    </p>
                    <p className="fw-600">
                      End time:
                      {moment(
                        this.props.scheduleData.end_time,
                        "HH:mm:ss"
                      ).format("hh:mm A")}
                    </p>
                  </>
                ) : (
                  <p className="fw-600">Any time</p>
                )}
              </div>
              <div className="col-6">
                <p className="mb-1">Schedule end date</p>
                <p className="fw-600">
                  {moment(this.props.scheduleData.schedule_end_date).format(
                    "DD/MM/YYYY"
                  )}
                </p>
              </div>
              <div className="col-6">
                <p className="mb-1">Schedule created date</p>
                <p className="fw-600">
                  {moment(this.props.scheduleData.created_at).format(
                    "DD/MM/YYYY"
                  )}
                </p>
              </div>
              <div className="col-6">
                <p className="mb-1">Active status</p>
                <p className="fw-600">
                  {this.props.scheduleData.status === 1 ? <EnableSVG/> : <DisableSVG/>}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="mb-2">
                {/* {this.state.attachmentUrls &&
                  this.state.attachmentUrls.map((obj: any, index: number) => (
                    <div className="img-card mr-16px mb-3">
                      {obj.type === "download" && (
                        <span className="download">
                          <a
                            href="javascript:void(0)"
                            onClick={() => this.downloadFile(obj.download_url, obj.name)}
                          >
                            <AttachmentDownloadSVG />
                          </a>
                        </span>
                      )}
                      <div className="img-preview">
                        <img className="img-thumb-perview" src={obj.url} />
                      </div>
                    </div>
                  ))} */}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteConfirm);
