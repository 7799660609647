import { AnyAction } from "redux";
import { ITaskType } from "../../types/task.type";
import { TTaskTable } from "../../types/user.type";

export const API_GET_ALL_CLIENTS = "get_all_clients/LOAD";
export const API_GET_ALL_CLIENTS_SUCCESS = "get_all_clients/LOAD_SUCCESS";
export const API_GET_ALL_CLIENTS_FAIL = "get_all_clients/LOAD_FAIL";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const API_GET_ALL_CLEANER = "get_all_cleaner/LOAD";
export const API_GET_ALL_CLEANER_SUCCESS = "get_all_cleaner/LOAD_SUCCESS";
export const API_GET_ALL_CLEANER_FAIL = "get_all_cleaner/LOAD_FAIL";
export const API_GET_ALL_BRANCH = "get_all_branch/LOAD";
export const API_GET_ALL_BRANCH_SUCCESS = "get_all_branch/LOAD_SUCCESS";
export const API_GET_ALL_BRANCH_FAIL = "get_all_branch/LOAD_FAIL";

export const API_GET_ALL_TASKS = "get_all_task/LOAD";
export const API_GET_ALL_TASKS_SUCCESS = "get_all_task/LOAD_SUCCESS";
export const API_GET_ALL_TASKS_FAIL = "get_all_task/LOAD_FAIL";
export const API_ADD_TASK = "task/LOAD";
export const API_ADD_TASK_SUCCESS = "task/LOAD_SUCCESS";
export const API_ADD_TASK_FAIL = "task/LOAD_FAIL";
export const UPDATE_ADD_TASK_DLG_STATUS = "UPDATE_ADD_TASK_DLG_STATUS";
export const API_EDIT_TASK = "edit_task/LOAD";
export const API_EDIT_TASK_SUCCESS = "edit_task/LOAD_SUCCESS";
export const API_EDIT_TASK_FAIL = "edit_task/LOAD_FAIL";
export const UPDATE_EDIT_TASK_DLG_STATUS = "UPDATE_EDIT_TASK_DLG_STATUS";
export const API_DELETE_TASK = "delete_task/LOAD";
export const API_DELETE_TASK_SUCCESS = "delete_task/LOAD_SUCCESS";
export const API_DELETE_TASK_FAIL = "delete_task/LOAD_FAIL";
export const UPDATE_DELETE_TASK_DLG_STATUS = "UPDATE_DELETE_TASK_DLG_STATUS";
export const API_GET_TASK_BY_ID = "task_by_id/LOAD";
export const API_GET_TASK_BY_ID_SUCCESS = "task_by_id/LOAD_SUCCESS";
export const API_GET_TASK_BY_ID_FAIL = "task_by_id/LOAD_FAIL";
export const UPDATE_TASK_FETCH_STATUS = "UPDATE_TASK_FETCH_STATUS";
export const FILTER_TASK = "FILTER_TASK";
export const UPDATE_TASKS = "UPDATE_TASKS";
export const UPDATE_TASK_DLG_STATUS = "UPDATE_TASK_DLG_STATUS";
export const API_ADD_TASK_PROGRESS = "API_ADD_TASK_PROGRESS";
export const API_EDIT_TASK_PROGRESS = "API_EDIT_TASK_PROGRESS";
export const API_GET_ALL_SETTING = "get_settings/LOAD";
export const API_GET_ALL_SETTING_SUCCESS = "get_settings/LOAD_SUCCESS";
export const API_GET_ALL_SETTING_FAIL = "get_settings/LOAD_FAIL";
export const API_GET_ALL_SETTING_UPDATED = "get_settings_updated/LOAD";
export const API_GET_ALL_SETTING_UPDATED_SUCCESS = "get_settings_updated/LOAD_SUCCESS";
export const API_GET_ALL_SETTING_UPDATED_FAIL = "get_settings_updateds/LOAD_FAIL";
export interface ICleaner {
  id: string;
  first_name: string;
  last_name: string;
}

export interface IBranch {
  id: string;
  name: string;
}

export interface IAddTask {
  client: string;
  branch: string;
  title: string;
  is_active: string;
  attachments: any | Blob;
  description: string;
}

export interface IEditTask {
  client: string;
  branch: string;
  title: string;
  is_active: string;
  attachments: any | Blob;
  description: string;
}

export interface IBranchSelect {
  label: string;
  value: string;
}

export interface TaskTemplateState {
  cleanerList: IBranchSelect[];
  clientList: ICleaner[];
  branchList: IBranchSelect[];
  addTaskStatus: boolean;
  editTaskStatus: boolean;
  deleteTaskStatus: boolean;
  taskFetchStatus: boolean;
  addTaskSuccess: string;
  editTaskSuccess: string;
  deleteTaskSuccess: string;
  selectedTaskData: ITaskType | null;
  id: string;
  taskData: TTaskTable[];
  filteredTaskData: TTaskTable[];
  total_count:number;
  getbranchlistStatus:boolean;
  processTrigger:boolean,
  progressPercentage:number,
  settingList: any,
  settingUpdated: any
}

export type TaskTemplateDispatchType = (args: AnyAction) => AnyAction;
