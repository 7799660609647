import * as Yup from "yup";

import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { IEditAuditProps, IEditAuditState } from "../../types/audit.type";
import { Modal, ProgressBar } from "react-bootstrap";
import {
  addAudit,
  editAudit,
  listAllClient,
  listAllBranch,
  getAllAudits,
  updateEditAuditDlgStatus,
  updateAuditFetchStatus,
} from "../../shared/Reducers";

import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
// date picker
import Select from "react-select";
import { PlusSVG, PlusCloseSVG } from "../../assets/images/UsermanagementsSVG";

const reactSelectCusStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#fff",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    },
  }),
};

class EditAudit extends React.Component<IEditAuditProps, IEditAuditState> {
  constructor(props: IEditAuditProps) {
    super(props);
    this.state = {
      areaTask:
        this.props.data && this.props.data.area ? this.props.data.area : [],
      client_id:
        this.props.data && this.props.data.client_id
          ? this.props.data.client_id
          : "",
      branch_id:
        this.props.data && this.props.data.branch_id
          ? this.props.data.branch_id
          : "",
      editClient: {
        value: this.props.data?.client_id,
        label: this.props.data?.first_name + " " + this.props.data?.last_name,
      },
      editBranch: {
        value: this.props.data?.branch_id,
        label: this.props.data?.branch_name,
      },
    };
  }

  componentDidMount(): void {
    this.listData();

    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.add("d-none");
      button.removeAttribute("disabled");
    }
  }

  listData = async () => {
    await this.props.listAllClient();
  };
  componentDidUpdate(
    prevProps: Readonly<IEditAuditProps>,
    prevState: Readonly<IEditAuditState>,
    snapshot?: any
  ): void {
    if (prevProps.processTrigger !== this.props.processTrigger) {
    }
    if (prevProps.editAuditSuccess !== this.props.editAuditSuccess) {
    }
  }

  handleEditAudit = async (formValues: any) => {
    console.log("FormValues",formValues);
    
    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.remove("d-none");
      button.setAttribute("disabled", "");
    }
    let data: any = [];
    if (
      this.state.client_id !== "" &&
      this.state.branch_id !== "" &&
      this.state.areaTask.length > 0
    ) {
      data = {
        name: formValues.name,
        client: this.state.client_id,
        branch: this.state.branch_id,
        area_task: this.state.areaTask,
      };
      console.log("data", data);

      var element = document.getElementById("spinner-border");
      var button = document.getElementById("create-button");
      if (element !== null && button !== null) {
        element.classList.remove("d-none");
        button.setAttribute("disabled", "");
      }
      if(this.props.copyAuditStatus === true){
      await this.props.addAudit(data);
      setTimeout(() => {
        var element = document.getElementById("spinner-border");
        var button = document.getElementById("create-button");
        if (element !== null && button !== null) {
          element.classList.remove("d-none");
          button.setAttribute("disabled", "");
        }
        this.props.updateEditAuditDlgStatus(false)
        const query = `search=&sort=&order=&offset=0&limit=10&client=all&branch=all`
        this.props.getAllAudit(query);
      }, 1000);
      }else{
      if (this.props.id) {
        console.log("update log");
        
        this.props.editAudit(data, this.props.id);
      }
    }
    }
  };

  validationSchema = () => {
    return Yup.object().shape({
      name: Yup.string().required("Enter Audit Name"),
    });
  };

  handleOnChangeClient = async (id: number) => {
    this.setState({ client_id: id });
    await this.props.listAllBranch(id);
  };

  handleAddArea = () => {
    this.setState((prevState) => ({
      areaTask: [...prevState.areaTask, { area: "", task: [""] }],
    }));
  };

  handleRemoveArea = async (inx: number) => {
    let areaRemoveTask: any = this.state.areaTask;
    if (inx >= 0 && inx < areaRemoveTask.length) {
      // areaTask.splice(inx, 1);
      areaRemoveTask.map((area: any, index: number) => {
        if (index === inx) {
          area["delete"] = 0;
        }
        return area;
      });
      areaRemoveTask[inx].task = areaRemoveTask[inx].task.map(
        (task: any, index: number) => {
          task["delete"] = 0; // Set the 'delete' property to 0 for tasks that satisfy the condition

          return task; // Return the modified or unchanged task object
        }
      );
    }
    this.setState({ areaTask: areaRemoveTask });
  };

  handleAddTask = (inx: number) => {
    this.setState((prevState) => ({
      areaTask: prevState.areaTask.map((area: any, index: number) => ({
        ...area,
        task: index === inx ? [...area.task, { task: "" }] : area.task,
      })),
    }));
  };

  handleRemoveTask = async (p_inx: number, c_inx: number) => {
    let areaTaskRemove: any = [...this.state.areaTask];
    if (p_inx >= 0 && p_inx < areaTaskRemove.length) {
      if (c_inx >= 0 && c_inx < areaTaskRemove[p_inx].task.length) {
        // areaTask[p_inx].task.splice(c_inx, 1);
        areaTaskRemove[p_inx].task = areaTaskRemove[p_inx].task.map(
          (task: any, index: number) => {
            if (index === c_inx) {
              task["delete"] = 0; // Set the 'delete' property to 0 for tasks that satisfy the condition
            }
            return task; // Return the modified or unchanged task object
          }
        );
      }
    }
    this.setState({ areaTask: [] }, () => {
      this.setState({ areaTask: areaTaskRemove });
    });
    console.log(areaTaskRemove, "areaTaskRemove");
  };

  changeAreaValue = (value: any, inx: number) => {
    this.setState((prevState) => ({
      areaTask: prevState.areaTask.map((area: any, index: number) => ({
        ...area,
        area: index === inx ? value : area.area,
      })),
    }));
  };

  changeTaskValue = (value: any, area_inx: number, task_inx: number) => {
    this.setState((prevState) => ({
      areaTask: prevState.areaTask.map((area: any, index: number) => ({
        ...area,
        task:
          index === area_inx
            ? area.task.map((task: any, taskIndex: number) => ({
                ...task,
                task: taskIndex === task_inx ? value : task.task,
              }))
            : area.task,
      })),
    }));
  };

  render(): React.ReactNode {
    const initialValues = this.props.data;
    return (
      <div>
        <Formik
          initialValues={{
            name: this.props.data ? this.props.data.name : "",
          }}
          enableReinitialize={true}
          onSubmit={this.handleEditAudit}
          validationSchema={this.validationSchema}
        >
          <Form>
            <div className="row">
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Name<span className="text-project">*</span>
                </label>
                <Field
                  name="name"
                  type={"text"}
                  className="form-control text-field"
                />
                <ErrorMessage
                  name="name"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div>
                <label className="form-label">Client</label>
                <Select
                  styles={reactSelectCusStyles}
                  placeholder="Select Client"
                  isMulti={false}
                  value={this.state.editClient}
                  options={this.props.clientList.map((client: any) => ({
                    value: client.id,
                    label: client.first_name + " " + client.last_name,
                  }))}
                  onChange={(e: any) => {
                    this.handleOnChangeClient(e.value);
                    this.setState({ editClient: e });
                  }}
                />
              </div>
              <div>
                <label className="form-label">Branch</label>
                <Select
                  styles={reactSelectCusStyles}
                  placeholder="Select Branch"
                  isMulti={false}
                  value={this.state.editBranch}
                  options={this.props.branchList.map((branch: any) => ({
                    value: branch.value,
                    label: branch.label,
                  }))}
                  onChange={(e: any) =>
                    this.setState({ branch_id: e.value, editBranch: e })
                  }
                />
              </div>
              <div className="">
                <button
                  type="button"
                  className="btn btn-primary plus-btn data-ins-plus"
                  onClick={this.handleAddArea}
                >
                  <PlusSVG />
                </button>
              </div>
            </div>
            {this.state.areaTask &&
              this.state.areaTask.map(
                (val: any, index: number) =>
                  !val.hasOwnProperty("delete") && (
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <div className="card">
                          <div className="card-body p-4">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="d-flex gap-3">
                                  <div className="col mb-3">
                                    <label className="form-label">Area</label>
                                    <input
                                      type="area"
                                      value={val.area}
                                      className="form-control br-dark"
                                      onChange={(e) =>
                                        this.changeAreaValue(
                                          e.target.value,
                                          index
                                        )
                                      }
                                    />
                                  </div>
                                  <div>
                                    <button
                                      type="button"
                                      className="btn close-new-btn data-ins-plus p-0"
                                      onClick={() => {
                                        this.handleRemoveArea(index);
                                      }}
                                    >
                                      <PlusCloseSVG />
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      type="button"
                                      className="btn btn-primary plus-btn data-ins-plus"
                                      onClick={() => {
                                        this.handleAddTask(index);
                                      }}
                                    >
                                      <PlusSVG />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {val.task &&
                              val.task.map(
                                (task: any, t_inx: number) =>
                                  !task.hasOwnProperty("delete") && (
                                    <div className="row">
                                      <div className="col-md-11">
                                        <div className="d-flex gap-3">
                                          <div className="col mb-3">
                                            <label className="form-label">
                                              Task
                                            </label>
                                            <textarea
                                              className="form-control br-dark"
                                              style={{ height: "40px" }}
                                              onChange={(e) =>
                                                this.changeTaskValue(
                                                  e.target.value,
                                                  index,
                                                  t_inx
                                                )
                                              }
                                            >
                                              {task.task}
                                            </textarea>
                                          </div>
                                          <div>
                                            <button
                                              type="button"
                                              className="btn close-new-btn data-ins-plus p-0"
                                              onClick={() => {
                                                this.handleRemoveTask(
                                                  index,
                                                  t_inx
                                                );
                                              }}
                                            >
                                              <PlusCloseSVG />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
            <div className="row">
              <div className="row mt-3 mb-3">
                <div className="col-md-12 cus-progress">
                  <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.props.processTrigger}
                  >
                    <Modal.Body className="p-0">
                      <ProgressBar
                        now={this.props.progressPercentage}
                        label={
                          this.props.progressPercentage === 100
                            ? `Processing your request...`
                            : `Audit Updating....${this.props.progressPercentage}%`
                        }
                      />
                    </Modal.Body>
                  </Modal>
                  <div className="d-flex">
                    <div className="mr-16px">
                      <button
                        type="submit"
                        id="create-button"
                        className="btn btn-primary create-button"
                      >
                          {this.props.copyAuditStatus === true
                           ? "Copy" 
                            : "Save"}{" "} 
                        <span
                          id="spinner-border"
                          className="spinner-border spinner-border-sm d-none"
                        ></span>
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn cancel-button"
                        onClick={() =>
                          this.props.updateEditAuditDlgStatus(false)
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>

        <iframe
          style={{ display: "none" }}
          name="hiddenIframe"
          id="hiddenIframe"
        />
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  processTrigger: state.audit.processTrigger,
  editAuditSuccess: state.audit.editAuditSuccess,
  progressPercentage: state.audit.progressPercentage,
  clientList: state.contract.clientList,
  branchList: state.tasktemplate.branchList,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    editAudit: bindActionCreators(editAudit, dispatch),
    addAudit: bindActionCreators(addAudit, dispatch),
    updateEditAuditDlgStatus: bindActionCreators(
      updateEditAuditDlgStatus,
      dispatch
    ),
    updateAuditFetchStatus: bindActionCreators(
      updateAuditFetchStatus,
      dispatch
    ),
    listAllClient: bindActionCreators(listAllClient, dispatch),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
    getAllAudit: bindActionCreators(getAllAudits, dispatch)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditAudit);
