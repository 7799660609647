import 'bootstrap/dist/css/bootstrap.min.css';

import * as Yup from "yup";

import { CloseBtnSVG, SuccessToastSVG } from "../../assets/images/ImagesV2";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Link, Navigate } from "react-router-dom";
import { Toast, ToastContainer } from 'react-bootstrap';

import AuthService from "../../services/auth.service";
import { Dispatch } from "redux";
import { IResetPasswordState } from "../../types/user.type";
import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";

class ResetPassword extends React.Component<any, IResetPasswordState> {
  constructor(props: any) {
    super(props);
    this.state = {
      new_password: "",
      confirm_password: "",
      successful: false,
      message: "",
      redirect: "",
      x_auth_token: "",
      showToast: false,
    };
  }

  componentDidMount(): void {
    const path = window.location.search;
    const part1 = path.split("?")[1];
    const token = part1.split("=")[1];
    this.setState({ x_auth_token: token });
  }

  deleteToastClose = () => {
    this.setState({ showToast: false });
  };

  handleSetPassword = (
    formValue: { new_password: string, confirm_password: string },
    { resetForm }: { resetForm: any }
  ) => {
    console.log(formValue);
    const { new_password, confirm_password } = formValue;

    this.setState({
      message: "",
    });
    AuthService.resetPassword(this.state.x_auth_token, new_password)
      .then((response) => {
        resetForm({ formValue: "" });
        this.setState({
          message: response.data.message,
          showToast: true,
          successful: true,
          redirect: "/",
        });
        // setTimeout(() => {
        //   this.setState({
        //     message: "",
        //     showToast: false,
        //   });
        //   this.setState({redirect: "/"});
        // }, 1000);
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          message: resMessage,
        });
      });
  };

  validationSchema = () => {
    return Yup.object().shape({
      new_password: Yup.string()
        .required("Enter new Password")
        .test(
          "len",
          "Enter new Password",
          (val: any) => val && val.toString().length >= 8
        ),
      confirm_password: Yup.string()
        .required("Enter Confirm Password")
        .test(
          "len",
          "Enter confirm password",
          (val: any) => val && val.toString().length >= 8
        )
        .oneOf([Yup.ref("new_password"), null], "Passwords does not match"),
    });
  };

  render(): React.ReactNode {
    const initialValues = {
      new_password: "",
      confirm_password: "",
      x_auth_token: "",
    };

    return (
      <div className="bg-grey">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 info-card">
              <div className="card">
                <div className="card-body p-5">
                  <div className="row">
                    <div className="col-md-12">
                      <h2 className="text-green fs-27 lh-27px text-center mb-3">
                        Set Password
                      </h2>
                      <Formik
                        initialValues={initialValues}
                        onSubmit={this.handleSetPassword}
                        validationSchema={this.validationSchema}
                      >
                        <Form>
                          <div className="mb-2">
                            <label className="form-label">
                              Enter a new password
                            </label>
                            <Field
                              name="new_password"
                              type="password"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="new_password"
                              component={"div"}
                              className="cus-alert"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Enter a confirm password
                            </label>
                            <Field
                              name="confirm_password"
                              type="password"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="confirm_password"
                              component={"div"}
                              className="cus-alert"
                            />
                          </div>
                          <div>
                            <p className="mb-2">Your password must:</p>
                            <ul>
                              <li>
                                <p className="m-0">
                                  Be at least 8 characters long
                                </p>
                              </li>
                              <li>
                                <p className="m-0">
                                  Include numbers, upper case and lower case
                                  letters
                                </p>
                              </li>
                            </ul>
                          </div>
                          <div className="text-center mt-4">
                            <button
                              type="submit"
                              className="btn-primary login-button btn"
                            >
                              Set password
                            </button>
                          </div>
                        </Form>
                      </Formik>
                      {this.state.showToast ? (
                <Toast className="success-alerts">
                  <Toast.Body>
                    <div className="row align-items-center">
                      <div className="col-md-2">
                        <SuccessToastSVG />
                      </div>
                      <div className="col-md-8">
                      {this.state.message}
                      </div>
                      <div className="col-md-2">
                        <button
                          type="button"
                          className="btn"
                          onClick={this.deleteToastClose}
                        >
                          <CloseBtnSVG />
                        </button>
                      </div>
                    </div>
                  </Toast.Body>
                </Toast>
              ) : (
                ""
              )}
              {this.state.successful && <Navigate to={this.state.redirect}/>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mt-3">
                <a className=" text-project" id="login-page" href="/">
                  Login to Dashboard
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  // selectedEmployee: state.employee.selectedEmployee
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    // selectEmployee: bindActionCreators(selectEmployee, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
