import { Dispatch, bindActionCreators } from "redux";
import {
  IReportDashboardProps,
  IReportDashboardState,
} from "../../types/report.type";
import dayjs, { Dayjs } from "dayjs";
import {
  filterReport,
  getAllReports,
  handleFilterReportFromDate,
  handleFilterReportToDate,
  listAllClient,
  listAllBranch,
  updateSidebar,
} from "../../shared/Reducers";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Autocomplete } from "@mui/material";
import Constants from "../../shared/Constants";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import ReactPaginate from "react-paginate";
import ReactSelect from "react-select";
import ReportTable from "./ReportTable";
import { RootState } from "../../index";
import { SearchSVG } from "../../assets/images/EmployeesSVG";
import SideBar from "../../components/sidebar/SideBar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import TopNav from "../../components/topnav/TopNav";
import { connect } from "react-redux";
// date picker
import moment from "moment";

class CleanerLogs extends React.Component<
  IReportDashboardProps,
  IReportDashboardState
> {
  constructor(props: IReportDashboardProps) {
    console.log(props);
    super(props);
    this.state = {
      searchTerm: "",
      filteredBranch: "all",
      filteredClient: "all",
      timeout: null,
      reportFromTo: "",
    };
  }

  componentDidMount(): void {
    this.props.updateSidebar(true);
    document.title = "Report";
    this.props.listAllClient();
    this.props.listAllBranch(0);
    this.props.handleFilterReportFromDate("");
    this.props.handleFilterReportToDate("");
    this.getReports(Constants.OFFSET, Constants.PER_PAGE);

    // date picker readonly
    let datePicker: NodeListOf<Element> = document.querySelectorAll(
      ".MuiFormControl-root input"
    ) as NodeListOf<Element>;
    if (datePicker.length > 0) {
      for (let index = 0; index < datePicker.length; index++) {
        const element = datePicker[index];
        datePicker[index].setAttribute("readonly", "true");
      }
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IReportDashboardProps>,
    prevState: Readonly<IReportDashboardState>,
    snapshot?: any
  ): void {
    console.log(prevProps.filterReportToDate , this.props.filterReportToDate);
    
    if (prevProps.filterReportFromDate !== this.props.filterReportFromDate) {
      this.getReports(Constants.OFFSET, Constants.PER_PAGE);
    }else if (prevProps.filterReportToDate !== this.props.filterReportToDate) {
      this.getReports(Constants.OFFSET, Constants.PER_PAGE);
    }else if (prevProps.deletereport_success !== this.props.deletereport_success) {
      if (this.props.deletereport_success === "Successfully deleted") {
        this.getReports(Constants.OFFSET, Constants.PER_PAGE);
      }
    }
  }

  getReports = async (offset: number, limit: number) => {
    try {
      const query = `client=${this.state.filteredClient}&branch=${this.state.filteredBranch}&search=${this.state.searchTerm}&sort=&order=&offset=${offset}&limit=${limit}&cleaner=all&from=${this.props.filterReportFromDate === null ? "" : this.props.filterReportFromDate }&to=${this.props.filterReportToDate === null ? "" : this.props.filterReportToDate }`;
      this.props.getAllReports(query);
    } catch (error) {
      console.log(error);
    }
  };

  // Search Branch Filter
  handleFilter = (e: any) => {
    const value: string = e.target.value.toLowerCase();
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

    this.setState({
      searchTerm: value,
      timeout: setTimeout(async () => {
        try {
          const query = `branch=${this.state.filteredBranch}&search=${value}&sort=&order=&offset=0&limit=10&cleaner=all&from=${this.props.filterReportFromDate}&to=${this.props.filterReportToDate}`;
          this.props.getAllReports(query);
        } catch (error) {
          console.log(error);
        }
        // Trigger your function here that you want to call when user stops typing
      }, 1000), // Set the delay time in ms
    });
  };

  handlePageClick = (e: any) => {
    if (e.selected < this.props.total_count / Constants.PER_PAGE) {
      this.getReports(e.selected * Constants.PER_PAGE, Constants.PER_PAGE);
    }
  };
  updateSearch = (e: any) => {
    this.setState({ searchTerm: e.target.value }, () => {
      this.getReports(Constants.OFFSET, Constants.PER_PAGE);
    });
  };
  branchChanged = (e: any) => {
    this.setState({ filteredBranch: e.value }, () => {
      this.getReports(Constants.OFFSET, Constants.PER_PAGE);
    });
  };
  clientChanged = (e: any) => {
    this.props.listAllBranch(e.value);
    this.setState({ filteredClient: e.value }, () => {
      this.getReports(Constants.OFFSET, Constants.PER_PAGE);
    });
  };
  
  handleStartDateClick = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let startDate: HTMLElement = document.querySelector(
        ".startdate button.MuiButtonBase-root"
      ) as HTMLElement;
      startDate.click();
    }
  };
  handleEndDateClick = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let endDate: HTMLElement = document.querySelector(
        ".enddate button.MuiButtonBase-root"
      ) as HTMLElement;
      endDate.click();
      
    }
  };

  refreshBtn = () => {
    this.setState({
      searchTerm: "",
      filteredBranch: "all",
      filteredClient: "all",
    });
    this.props.handleFilterReportFromDate(null);
    this.props.handleFilterReportToDate(null);
    // this.getReports(Constants.OFFSET, Constants.PER_PAGE);
  }

  render(): React.ReactNode {
    let branchOption: any = [{ label: "All Branch", value: "all" }];
    this.props.branchList.map((branch: any) => {
      branchOption.push(branch);
    });
    let clientOption: any = [{ label: "All Client", value: "all" }];
    this.props.clientList.map((client: any) => {
      clientOption.push({
        value: client.id,
        label: client.first_name + " " + client.last_name,
      });
    });
    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className={this.props.status ? "main-panel" : "main-panel2"}>
          <TopNav />
          <div className="content-wrapper">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="mb-0">Report</h3>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-end">
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12 col-lg-4 mb-3">
                  <div className="input-group search">
                    <span
                      className="input-group-text search-icon"
                      id="basic-addon1"
                    >
                      <SearchSVG />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={this.state.searchTerm}
                      onInput={this.handleFilter}
                      onChange={(e) => this.setState({
                        searchTerm: e.target.value
                      })}
                    />
                  </div>
                </div>
                <div className="col-md-12 col-lg-4 mb-3">
                  <a
                    className="btn btn-primary btn-border"
                    href={
                      Constants.baseUrl +
                      `activity/export?client=${this.state.filteredClient}&branch=${this.state.filteredBranch}&search=${this.state.searchTerm}&sort=&order=&from=` +
                      this.props.filterReportFromDate +
                      "&to=" +
                      this.props.filterReportToDate
                    }
                  >
                    Export
                  </a>
                  <a
                    className="btn btn-primary btn-border ml-4"
                    onClick={this.refreshBtn}
                  >
                    Refresh
                  </a>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-12 col-lg-3 startDate"
                >
                  <label className="form-label">From date</label>
                  <div 
                  className="startdate"
                  onClick={() => this.handleStartDateClick()}
                  >
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    id="startDate"
                  >
                    <Stack spacing={3}>
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        value={this.props.filterReportFromDate}
                        maxDate={this.props.filterReportToDate}
                        onChange={(e: any) => {
                          this.props.handleFilterReportFromDate(
                            moment(new Date(e?.toString())).format(
                              "YYYY-MM-DD"
                            )
                          );
                          this.setState({
                            reportFromTo: {
                              filterReportFromDate:
                                this.props.filterReportFromDate,
                              filterReportToDate:
                                this.props.filterReportToDate,
                            },
                          });
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                  </div>
                </div>
                <div
                  className="col-md-12 col-lg-3 endDate"
                >
                  <label className="form-label">To date</label>
                  <div
                  className="enddate"
                  onClick={() => this.handleEndDateClick()}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      id="endDate"
                    >
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          inputFormat="DD/MM/YYYY"
                          minDate={this.props.filterReportFromDate}
                          value={this.props.filterReportToDate}
                          onChange={(e: any) => {
                            this.props.handleFilterReportToDate(
                              moment(new Date(e?.toString())).format(
                                "YYYY-MM-DD"
                              )
                            );
                            this.setState({
                              reportFromTo: {
                                filterReportFromDate:
                                  this.props.filterReportFromDate,
                                filterReportToDate:
                                  this.props.filterReportToDate,
                              },
                            });
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3">
                  <label className="form-label">Client</label>
                  <div>
                    <ReactSelect
                      options={clientOption}
                      placeholder={`All Client`}
                      onChange={this.clientChanged}
                    />
                  </div>
                </div>
                <div className="col-md-12 col-lg-3">
                  <label className="form-label">Branch</label>
                  <div>
                    <ReactSelect
                      options={branchOption}
                      placeholder={`All Branch`}
                      onChange={this.branchChanged}
                    />
                  </div>
                </div>
                {/* <div className="col-md-12 col-lg-4 text-md-start text-lg-end"> */}
                  {/* <div className="d-flex justify-content-end"> */}
                  {/* <label className="form-label w-100">&nbsp;</label> */}
                  
                  {/* </div> */}
                {/* </div> */}
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      {this.props.filteredReportData.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table employee-table">
                            <thead>
                              <tr>
                                <th scope="col">CLEANER</th>
                                <th scope="col">CLIENT</th>
                                <th scope="col">BRANCH</th>
                                <th scope="col">SIGNED IN</th>
                                <th scope="col">SIGNED OUT</th>
                                <th scope="col">REASON</th>
                                {(Constants.LOGIN_USER_TYPE ===
                                  Constants.ADMINUTINT ||
                                  Constants.LOGIN_USER_TYPE ===
                                    Constants.SUPERVISORUTINT) && (
                                  <th scope="col">AWAY</th>
                                )}
                                <th scope="col">ACTION</th>
                              </tr>
                            </thead>
                            <ReportTable
                              curState={this.state}
                              data={this.props.filteredReportData}
                            />
                          </table>
                        </div>
                      ) : (
                        <p className="text-center">
                          There is no report data found
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                {this.props.total_count > 10 ? (
                  <div className="col-md-12">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.props.total_count / Constants.PER_PAGE}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      nextClassName={"next-page"}
                      activeClassName={"active"}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  reportData: state.report.reportData,
  filteredReportData: state.report.filteredReportData,
  reportFetchStatus: state.report.reportFetchStatus,
  total_count: state.report.total_count,
  filterReportFromDate: state.report.filterReportFromDate,
  filterReportToDate: state.report.filterReportToDate,
  branchList: state.tasktemplate.branchList,
  clientList: state.tasktemplate.clientList,
  status: state.sidebar.status,
  deletereport_success: state.report.deletereport_success,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    filterReport: bindActionCreators(filterReport, dispatch),
    getAllReports: bindActionCreators(getAllReports, dispatch),
    handleFilterReportFromDate: bindActionCreators(
      handleFilterReportFromDate,
      dispatch
    ),
    handleFilterReportToDate: bindActionCreators(
      handleFilterReportToDate,
      dispatch
    ),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
    listAllClient: bindActionCreators(listAllClient, dispatch),
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CleanerLogs);
