import React from "react";
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Stack from "@mui/material/Stack";
import moment from "moment";
import { RootState } from '../../index';
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';


class TimePicker extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            value: this.getTime(props),
        }
    }

    getTime(props: any) {
        if (props.value) {
            let timeArr = props.value.split(':');
            if (timeArr.length > 2) {
                return dayjs().set('hour', timeArr[0]).set('minute', timeArr[1]).set('second', timeArr[2]);
            }
        }

        return dayjs();
    }

    // Date Change
    handleChange = (newValue: Dayjs | null) => {
        this.setState({ value: newValue });
        if (newValue) {
            this.props.onChange(moment(new Date(newValue?.toString())).format("HH:mm"));
        }
    }
    render(): React.ReactNode {
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                    <DesktopTimePicker
                        value={this.state.value}
                        onChange={this.handleChange}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </Stack>
            </LocalizationProvider>
        )
    }
}
const mapStateToProps = (state: RootState) => ({

})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimePicker);