import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import DailyTaskCard from "./DailyTaskCard";
import { Offcanvas } from "react-bootstrap";
import {
  deleteDailyTask,
  getDailyTaskById,
  getDailyTaskByIdForView,
  getDailyTaskByIdForDuplicate,
  getDailyTaskByIdForMakeSchedule,
  getAllDailyTasks,
  getTaskHistoryById,
  updateEditDailyTaskDlgStatus,
  getReassignDailyTaskById,
  updateReassignDailyTaskDlgStatus,
  getSendQAEmailDailyTaskById,
  updateSendQAEmailDailyTaskDlgStatus,
  EditModalDailyTask,
} from "../../shared/Reducers";
import EditDailyTask from "./EditDailyTask";
import ReassignDailyTask from "./ReassignDailyTask";
import SendQAEmailDailyTask from "./SendQAEmailDailyTask";
import { IDailyTaskTableProps } from "../../types/dailyTask.type";
import ViewDailyTask from "./ViewDailyTask";
import Constants from "../../shared/Constants";

class DailyTaskTable extends React.Component<IDailyTaskTableProps, any> {
  constructor(props: IDailyTaskTableProps) {
    super(props);
    this.state = {
      viewDailyTask: false,
    };
  }

  handleDeleteDailyTask = async (id: string) => {
    await this.props.deleteDailyTask(id);

    let Offset: number = 0;
    let paginationActive = document.querySelector(
      ".pagination li.active a"
    ) as HTMLInputElement;
    if (paginationActive !== null) {
      let page: string | null = paginationActive.textContent;
      if (page !== null) {
        let pageNum: number = parseInt(page);
        console.log(pageNum);
        Offset = (pageNum - 1) * Constants.PER_PAGE;
        console.log(Offset);
        setTimeout(() => {
          try {
            const query = `search=&sort=&order=&offset=${Offset}&limit=${Constants.PER_PAGE}&client=all&branch=all&task_type=all&additional_task_status=1&recommended_task_status=1`;
            this.props.getAllDailyTasks(query);
          } catch (error) {
            console.log(error);
          }
        }, 500);
      }
    }
  };

  handleEditDailyTask = async (id: string) => {
    try {
      this.props.getDailyTaskById(id);
      // this.props.EditModalDailyTask(true)
      // this.setState({ viewDailyTask: true });
    } catch (error) {
      console.log(error);
    }
  };
  handleViewDailyTaskModel = async (id: string) => {
    try {
      await this.props.getDailyTaskByIdForView(id);
    } catch (error) {
      console.log(error);
    }
  };

  handleCopyDailyTask = async (id: string) => {
    try {
      this.props.getDailyTaskByIdForDuplicate(id);
    } catch (error) {
      console.log(error);
    }
  };
  handleMakeScheduleDailyTask = async (id: string) => {
    try {
      this.props.getDailyTaskByIdForMakeSchedule(id);
    } catch (error) {
      console.log(error);
    }
  };

  handleSendQAEmailDailyTask = async (id: string) => {
    console.log(id);

    try {
      this.props.getSendQAEmailDailyTaskById(id);
    } catch (error) {
      console.log(error);
    }
  };

  handleReassignDailyTask = async (id: string) => {
    try {
      this.props.getReassignDailyTaskById(id);
    } catch (error) {
      console.log(error);
    }
  };

  handleTaskHistory = async (id: string) => {
    try {
      this.props.getTaskHistoryById(id);
    } catch (error) {
      console.log(error);
    }
  };

  handleViewDailyTask = async (id: string) => {
    try {
      this.props.getDailyTaskById(id);
    } catch (error) {
      console.log(error);
    }
  };

  render(): React.ReactNode {
    return (
      <tbody>
        {this.props.data.map((task, index) => (
          <DailyTaskCard
            task={task}
            key={index + task.title}
            handleDeleteDailyTask={this.handleDeleteDailyTask}
            handleCopyDailyTask={this.handleCopyDailyTask}
            handleMakeScheduleDailyTask={this.handleMakeScheduleDailyTask}
            handleEditDailyTask={this.handleEditDailyTask}
            handleReassignDailyTask={this.handleReassignDailyTask}
            handleSendQAEmailDailyTask={this.handleSendQAEmailDailyTask}
            handleTaskHistory={this.handleTaskHistory}
            handleViewDailyTask={this.handleViewDailyTask}
            deleteDailyTaskStatus={false}
            handleViewDailyTaskModel={this.handleViewDailyTaskModel}
          />
        ))}
        <Offcanvas
          placement="end"
          show={this.props.editDailyTaskstatusvalue}
          onHide={() =>{
            this.props.EditModalDailyTask(false)
          }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <span className="offc-title">Edit Task</span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <EditDailyTask
              id={this.props.id}
              data={this.props.selectedDailyTaskData}
              copyDailyTaskStatus={this.props.copyDailyTaskStatus}
              makeScheduleDailyTaskStatus={
                this.props.makeScheduleDailyTaskStatus
              }
            />
          </Offcanvas.Body>
        </Offcanvas>
        <Offcanvas
          placement="end"
          show={this.props.copyDailyTaskStatus}
          onHide={() => this.props.updateEditDailyTaskDlgStatus(false)}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <span className="offc-title">Copy Task</span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <EditDailyTask
              id={this.props.id}
              data={this.props.selectedDailyTaskData}
              copyDailyTaskStatus={this.props.copyDailyTaskStatus}
              makeScheduleDailyTaskStatus={
                this.props.makeScheduleDailyTaskStatus
              }
            />
          </Offcanvas.Body>
        </Offcanvas>
        <Offcanvas
          placement="end"
          show={this.props.makeScheduleDailyTaskStatus}
          onHide={() => this.props.updateEditDailyTaskDlgStatus(false)}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <span className="offc-title">Make Schedule</span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <EditDailyTask
              id={this.props.id}
              data={this.props.selectedDailyTaskData}
              copyDailyTaskStatus={this.props.copyDailyTaskStatus}
              makeScheduleDailyTaskStatus={
                this.props.makeScheduleDailyTaskStatus
              }
            />
          </Offcanvas.Body>
        </Offcanvas>
        <Offcanvas
          placement="end"
          show={this.props.reassignDailyTaskStatus}
          onHide={() => this.props.updateReassignDailyTaskDlgStatus(false)}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <span className="offc-title">Reassign task</span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ReassignDailyTask
              id={this.props.id}
              data={this.props.selectedDailyTaskData}
            />
          </Offcanvas.Body>
        </Offcanvas>
        <Offcanvas
          placement="end"
          show={this.props.sendqaemailDailyTaskStatus}
          onHide={() => this.props.updateSendQAEmailDailyTaskDlgStatus(false)}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <span className="offc-title">Send QA Email task</span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <SendQAEmailDailyTask
              id={this.props.id}
              data={this.props.selectedDailyTaskData}
            />
          </Offcanvas.Body>
        </Offcanvas>
        <ViewDailyTask id={this.props.id} data={this.props.taskHistoryData} />
      </tbody>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  editDailyTaskStatus: state.dailytask.editDailyTaskStatus,
  copyDailyTaskStatus: state.dailytask.copyDailyTaskStatus,
  makeScheduleDailyTaskStatus: state.dailytask.makeScheduleDailyTaskStatus,
  reassignDailyTaskStatus: state.dailytask.reassignDailyTaskStatus,
  sendqaemailDailyTaskStatus: state.dailytask.sendqaemailDailyTaskStatus,
  selectedDailyTaskData: state.dailytask.selectedDailyTaskData,
  id: state.dailytask.id,
  taskHistoryData: state.dailytask.taskHistoryData,
  editDailyTaskstatusvalue: state.dailytask.editDailyTaskstatusvalue,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateEditDailyTaskDlgStatus: bindActionCreators(
      updateEditDailyTaskDlgStatus,
      dispatch
    ),
    getDailyTaskById: bindActionCreators(getDailyTaskById, dispatch),
    getDailyTaskByIdForView: bindActionCreators(getDailyTaskByIdForView, dispatch),
    getDailyTaskByIdForDuplicate: bindActionCreators(
      getDailyTaskByIdForDuplicate,
      dispatch
    ),
    getDailyTaskByIdForMakeSchedule: bindActionCreators(
      getDailyTaskByIdForMakeSchedule,
      dispatch
    ),
    updateReassignDailyTaskDlgStatus: bindActionCreators(
      updateReassignDailyTaskDlgStatus,
      dispatch
    ),
    getReassignDailyTaskById: bindActionCreators(
      getReassignDailyTaskById,
      dispatch
    ),
    updateSendQAEmailDailyTaskDlgStatus: bindActionCreators(
      updateSendQAEmailDailyTaskDlgStatus,
      dispatch
    ),
    getSendQAEmailDailyTaskById: bindActionCreators(
      getSendQAEmailDailyTaskById,
      dispatch
    ),
    deleteDailyTask: bindActionCreators(deleteDailyTask, dispatch),
    getAllDailyTasks: bindActionCreators(getAllDailyTasks, dispatch),
    getTaskHistoryById: bindActionCreators(getTaskHistoryById, dispatch),
    EditModalDailyTask: bindActionCreators(EditModalDailyTask, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DailyTaskTable);
