import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../index";
import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik, FieldProps } from "formik";
import * as Yup from "yup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Stack from "@mui/material/Stack";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import LogoSVG from "../../assets/images/controctor-logo.jpeg";
import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import {
  ContractcontactForm,
  getContractorById,
  updateContractcontactForm,
  deleteAttachementId,
} from "../../shared/Reducers";
import Button from "@material-ui/core/Button";
import { Offcanvas, Toast } from "react-bootstrap";
import { SuccessToastSVG } from "../../assets/images/ImagesV2";
import { CloseBtnSVG } from "../../assets/images/Location";
import { PaperClipSVG, TrashSVG } from "../../assets/images/ImagesV2";
import pdfImage from "../../assets/images/pdf.png";
import AttachmentPreview from "../../shared/Components/AttachmentPreview";
import SignaturePad from "../../shared/Components/SignaturePad";
import { Modal } from "react-bootstrap";
import ProfileImg from "../../assets/images/contract-profile.png";
import { log } from "console";
import Constants from "../../shared/Constants";
import { ProfileSVG } from "../../assets/images/Images";

type Props = {
  ContractcontactForm: any;
  getContractorById: any;
  contractContactFormSuccessStatus: any;
  selectedContractorData: any;
  updateContractcontactForm: any;
  update_contractContactFormSuccessStatus: any;
  deleteAttachementId: any;
};

type State = {
  dateOfBirthvalue: any;
  dateOfStartvalue: any;
  dateOfContractvalue: any;
  insuranceFiles: any | Blob[];
  insuranceUrls: any[];
  InsurancePreviewStatus: boolean;
  licensesFiles: any | Blob[];
  licensesUrls: any[];
  allLicensesFiles: any | Blob[];
  allLicensesUrls: any[];
  LicensesPreviewStatus: boolean;
  visaFiles: any | Blob[];
  visaUrls: any[];
  visaPreviewStatus: boolean;
  previewUrl: string;
  previewType: string;
  license_name: string;
  addedLicenseName: any | Blob[];
  isMaleChecked: boolean;
  isFemaleChecked: boolean;
  selectGender: any | Blob[];
  signatureModal: boolean;
  licenseModal: boolean;
  isDrawing: boolean;
  signLastX: number;
  signLastY: number;
  context: CanvasRenderingContext2D | null;
  signatureImage: string;
  signatureImageError: string;
  profileImageError: string;
  contractProfileFiles: any;
  contractProfileUrls: any;
  licenseExpDatevalue: any;
  visaExpDatevalue: any;
  insExpDatevalue: any;
};

class CleanerContactEditForm extends React.Component<Props, State> {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  constructor(props: Props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      dateOfBirthvalue: dayjs(),
      licenseExpDatevalue: dayjs(),
      visaExpDatevalue: dayjs(),
      insExpDatevalue: dayjs(),
      dateOfStartvalue: dayjs(),
      dateOfContractvalue: dayjs(),
      insuranceFiles: [],
      insuranceUrls: [],
      InsurancePreviewStatus: false,
      licensesFiles: [],
      licensesUrls: [],
      allLicensesFiles: [],
      allLicensesUrls: [],
      LicensesPreviewStatus: false,
      visaFiles: [],
      visaUrls: [],
      visaPreviewStatus: false,
      previewUrl: "",
      previewType: "",
      license_name: "",
      addedLicenseName: [],
      isMaleChecked: false,
      isFemaleChecked: false,
      selectGender: [],
      signatureModal: false,
      licenseModal: false,
      isDrawing: false,
      signLastX: 0,
      signLastY: 0,
      context: null,
      signatureImage: "",
      signatureImageError: "",
      profileImageError: "",
      contractProfileFiles: [],
      contractProfileUrls: ProfileImg,
    };
  }

  async componentDidMount(): Promise<void> {
    document.title = "Contractor form";

    const path = window.location.search;
    let userId = atob(path.split("?")[1]).split(":")[1];
    console.log(userId);

    await this.props.getContractorById(userId);
    console.log(this.props.selectedContractorData, "hello");
    // date picker readonly
    let datePicker: NodeListOf<Element> = document.querySelectorAll(
      ".MuiFormControl-root input"
    ) as NodeListOf<Element>;
    if (datePicker.length > 0) {
      for (let index = 0; index < datePicker.length; index++) {
        const element = datePicker[index];
        datePicker[index].setAttribute("readonly", "true");
      }
    }
  }

  handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
    this.setState({ signatureImageError: "" });

    this.setState({ isDrawing: true });
    const canvas = this.canvasRef.current;

    if (!canvas) return;
    this.context = canvas.getContext("2d");
    this.setState({ context: canvas.getContext("2d") });
    if (!this.context) return;

    const { offsetX, offsetY } = event.nativeEvent;

    this.setState({ signLastX: offsetX, signLastY: offsetY });
  };

  handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    this.setState({ signatureImageError: "" });

    if (!this.state.isDrawing) return;
    const canvas = this.canvasRef.current;

    if (canvas) {
      const { offsetX, offsetY } = event.nativeEvent;

      // Draw a line segment from the last recorded point to the current point
      let context: any = this.state.context;
      context.strokeStyle = "#000"; // Stroke color
      context.lineWidth = 2; // Stroke width
      context.beginPath();
      context.moveTo(this.state.signLastX, this.state.signLastY);
      context.lineTo(offsetX, offsetY);
      context.stroke();

      this.setState({ signLastX: offsetX, signLastY: offsetY });
    }
  };

  handleMouseUp = () => {
    this.setState({ signatureImageError: "" });

    this.setState({ isDrawing: false });
  };

  handleTouchStart = (event: React.TouchEvent<HTMLCanvasElement>) => {
    this.setState({ signatureImageError: "" });

    this.setState({ isDrawing: true });
    const canvas = this.canvasRef.current;
    console.log(canvas);

    if (!canvas) return;
    let context: any = this.state.context;
    context = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    if (!context) return;

    const { clientX, clientY } = event.nativeEvent.changedTouches[0];

    this.setState({ signLastX: clientX - rect.left, signLastY: clientY - 111 });
  };

  handleTouchMove = (event: React.TouchEvent<HTMLCanvasElement>) => {
    this.setState({ signatureImageError: "" });

    if (!this.state.isDrawing) return;
    const canvas = this.canvasRef.current;
    let context: any = this.state.context;
    if (!canvas || !context) return;

    const { clientX, clientY } = event.nativeEvent.changedTouches[0];
    const rect = canvas.getBoundingClientRect();
    // const x = touch.clientX - rect.left;
    // const y = touch.clientY - rect.top;

    // Draw a line segment from the last recorded point to the current point
    context.strokeStyle = "#000"; // Stroke color
    context.lineWidth = 2; // Stroke width
    context.beginPath();
    context.moveTo(this.state.signLastX, this.state.signLastY);
    context.lineTo(clientX - rect.left, clientY - rect.top);
    context.stroke();
    this.setState({
      signLastX: clientX - rect.left,
      signLastY: clientY - rect.top,
    });
  };

  handleTouchEnd = () => {
    this.setState({ signatureImageError: "" });

    this.setState({ isDrawing: false });
  };

  handleClear = () => {
    const canvas = this.canvasRef.current;
    let context: any = this.state.context;
    if (!canvas || !context) return;

    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  handleConvertToImage = () => {
    const canvas = this.canvasRef.current;
    if (!canvas) return;

    const image: string = canvas.toDataURL("image/png");

    if (
      image !==
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACWCAYAAABkW7XSAAAAAXNSR0IArs4c6QAABGJJREFUeF7t1AEJAAAMAsHZv/RyPNwSyDncOQIECEQEFskpJgECBM5geQICBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAgQdWMQCX4yW9owAAAABJRU5ErkJggg=="
    ) {
      this.setState({ signatureImage: image, signatureModal: false });
    }
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (
      this.props.update_contractContactFormSuccessStatus === true &&
      this.props.update_contractContactFormSuccessStatus !==
      prevProps.update_contractContactFormSuccessStatus
    ) {
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
    if (
      this.props.selectedContractorData !== prevProps.selectedContractorData
    ) {
      this.selectGender(this.props.selectedContractorData?.gender);
      console.log(this.props.selectedContractorData, "Incoming data");
      const filteredData =
        this.props.selectedContractorData?.attachments.filter(
          (item: any) => item.contractor_attachment_type === "contract_profile"
        );
      const filtervisa = this.props.selectedContractorData?.attachments.filter(
        (item: any) => item.contractor_attachment_type === "visa"
      );
      // const filterlicense =  this.props.selectedContractorData?.attachments.filter((item:any)=> item.contractor_attachment_type === "license-car");
      const filterinsurance =
        this.props.selectedContractorData?.attachments.filter(
          (item: any) => item.contractor_attachment_type === "insurance"
        );
      const filterlicense = this.props.selectedContractorData?.attachments
        ? this.props.selectedContractorData.attachments.filter(
          (item: any) =>
            item?.contractor_attachment_type !== null &&
            item.contractor_attachment_type.includes("license")
        )
        : [];

      this.setState({
        contractProfileUrls:
          filteredData.length > 0
            ? `${Constants.imgUrl}${filteredData[0].file_name}`
            : ProfileImg,
        //  visaUrls: [{ url: `${Constants.imgUrl}${filtervisa[0].file_name}`, file_type: filtervisa[0].type }],
        visaUrls: filtervisa.map((item: any) => ({
          url: item.s3_url,
          file_type: item.type,
          id: item.id,
        })),
        allLicensesUrls: filterlicense.map((item: any) => ({
          url: item.s3_url,
          file_type: item.type,
          id: item.id,
        })),
        //  allLicensesUrls: [{ url: `${Constants.imgUrl}${filterlicense[0].file_name}`, file_type: filterlicense[0].type }],
        insuranceUrls: filterinsurance.map((item: any) => ({
          url: item.s3_url,
          file_type: item.type,
          id: item.id,
        })),
        //  insuranceUrls: [{ url: `${Constants.imgUrl}${filterinsurance[0].file_name}`, file_type: filterinsurance[0].type }],
        signatureImage: this.props.selectedContractorData?.contractor_signature,
        visaExpDatevalue: this.props.selectedContractorData?.visa_expiry_date,
        licenseExpDatevalue: this.props.selectedContractorData?.license_expiry_date,
        insExpDatevalue: this.props.selectedContractorData?.insurance_expiry_date,
      });
    }
  }

  handleOpen = () => {
    this.setState({ signatureModal: !this.state.signatureModal });
  };

  handleLicenseModalOpen = () => {
    this.setState({ licenseModal: !this.state.licenseModal });
  };

  handleUpdateClientContract = async (formValues: any) => {
    if (this.state.signatureImage === "") {
      this.setState({ signatureImageError: "Please add signature" });
    } else if (
      this.state.contractProfileFiles.length === 0 &&
      this.state.contractProfileUrls === ""
    ) {
      this.setState({ profileImageError: "Please add profile" });
    } else {
      var element = document.getElementById("spinner-border");
      var button = document.getElementById("create-button");
      if (element !== null && button !== null) {
        element.classList.remove("d-none");
        button.setAttribute("disabled", "");
      }

      let data = new FormData();

      for (let index = 0; index < this.state.insuranceFiles.length; index++) {
        data.append("attachments", this.state.insuranceFiles[index]);
        data.append("attachments_name", "insurance");
      }
      for (
        let index = 0;
        index < this.state.contractProfileFiles.length;
        index++
      ) {
        data.append("attachments", this.state.contractProfileFiles[index]);
        data.append("attachments_name", "contract_profile");
      }
      for (let index = 0; index < this.state.allLicensesFiles.length; index++) {
        data.append("attachments", this.state.allLicensesFiles[index]);
        data.append(
          "attachments_name",
          "license-" + this.state.addedLicenseName[index]
        );
      }
      for (let index = 0; index < this.state.visaFiles.length; index++) {
        data.append("attachments", this.state.visaFiles[index]);
        data.append("attachments_name", "visa");
      }

      data.append("first_name", formValues.first_name);
      data.append("last_name", formValues.last_name);
      data.append("business_name", formValues.business_name);
      data.append("abn_no", formValues.abn_no);
      data.append(
        "date_of_start",
        moment(new Date(this.state.dateOfStartvalue?.toString())).format(
          "YYYY-MM-DD"
        )
      );
      data.append("job_title", formValues.job_title);
      data.append(
        "date_of_birth",
        moment(new Date(this.state.dateOfBirthvalue?.toString())).format(
          "YYYY-MM-DD"
        )
      );
      data.append("gender", this.state.selectGender);
      data.append("email", formValues.email);
      data.append("business_contact_no", formValues.business_contact_no);
      data.append("personal_contact_no", formValues.personal_contact_no);
      data.append("home_address", formValues.home_address);
      data.append("home_city_state", formValues.home_city_state);
      data.append("business_address", formValues.business_address);
      data.append("business_city_state", formValues.business_city_state);
      data.append("emergency_name", formValues.emergency_name);
      data.append("emergency_address", formValues.emergency_address);
      data.append("emergency_relationship", formValues.emergency_relationship);
      data.append("emergency_home_no", formValues.emergency_home_no);
      data.append("emergency_mobile", formValues.emergency_mobile);
      data.append("acc_name", formValues.acc_name);
      data.append("bsb_no", formValues.bsb_no);
      data.append("acc_no", formValues.acc_no);
      data.append("license_expiry_date", this.state.licenseExpDatevalue);
      data.append("insurance_expiry_date", this.state.insExpDatevalue);
      data.append("visa_expiry_date", this.state.visaExpDatevalue);
      data.append("contractor_signature", this.state.signatureImage);
      data.append(
        "contract_date",
        moment(new Date(this.state.dateOfContractvalue?.toString())).format(
          "YYYY-MM-DD"
        )
      );
      console.log(data);
      
      this.props.updateContractcontactForm(
        this.props.selectedContractorData.id,
        data,this.state.signatureImage
      );
    }
  };

  validationSchema = () => {
    if (
      this.state.contractProfileFiles.length === 0 &&
      this.state.contractProfileUrls === ""
    ) {
      this.setState({ profileImageError: "Please add profile" });
    }
    return Yup.object().shape({
      abn_no: Yup.string().required("Enter ABN NO"),
      acc_name: Yup.string().required("Enter Accounts Name"),
      acc_no: Yup.string().required("Enter Account Number"),
      bsb_no: Yup.string().required("Enter BSB Number"),
      business_contact_no: Yup.string().required("Enter Contact Telephone"),
      email: Yup.string()
        .email("Enter valid Email address")
        .required("Enter Email"),
      emergency_address: Yup.string().required("Enter Address"),
      emergency_home_no: Yup.string().required("Enter Home Contact number"),
      emergency_mobile: Yup.string().required("Enter Mobile number"),
      emergency_name: Yup.string().required("Enter Name"),
      emergency_relationship: Yup.string().required("Enter Relationship"),
      first_name: Yup.string().required("Enter First Name"),
      gender: Yup.array().min(1, "Select Gender"),
      home_address: Yup.string().required("Enter Address"),
      home_city_state: Yup.string().required("Enter City/ State"),
      job_title: Yup.string().required("Enter Job Title"),
      last_name: Yup.string().required("Enter Last Name"),
      personal_contact_no: Yup.string().required("Enter Mobile number"),
    });
  };

  handleDateOfBirthClick = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let dateOfBirth: HTMLElement = document.querySelector(
        ".dateOfBirth button.MuiButtonBase-root"
      ) as HTMLElement;
      dateOfBirth.click();
    }
  };

  handleDateOfStartClick = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let dateOfStart: HTMLElement = document.querySelector(
        ".dateOfStart button.MuiButtonBase-root"
      ) as HTMLElement;
      dateOfStart.click();
    }
  };

  handleDateOfContractClick = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let dateOfContract: HTMLElement = document.querySelector(
        ".dateOfContract button.MuiButtonBase-root"
      ) as HTMLElement;
      dateOfContract.click();
    }
  };

  handleDateOfBirthChange = async (newValue: Dayjs | null) => {
    this.setState({ dateOfBirthvalue: newValue });
  };
  handleDateOfInsChange = async (newValue: any) => {
    let datestr:any = newValue.$d
    const year = datestr.getFullYear();
    const month = String(datestr.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(datestr.getDate()).padStart(2, '0');
    this.setState({ insExpDatevalue: 
       `${year}-${month}-${day}`
     });
  };
  handleDateOfVisaChange = async (newValue: any) => {
    let datestr:any = newValue.$d
    const year = datestr.getFullYear();
    const month = String(datestr.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(datestr.getDate()).padStart(2, '0');
    this.setState({ visaExpDatevalue: 
      `${year}-${month}-${day}`
     });
  };
  handleDateOfLicenseChange = async (newValue: any) => {
    let datestr:any = newValue.$d
    const year = datestr.getFullYear();
    const month = String(datestr.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(datestr.getDate()).padStart(2, '0');
    this.setState({ licenseExpDatevalue: 
      `${year}-${month}-${day}`
     });
  };
  handleDateOfStartChange = async (newValue: Dayjs | null) => {
    this.setState({ dateOfStartvalue: newValue });
  };
  handleDateOfContractChange = async (newValue: Dayjs | null) => {
    
    this.setState({ dateOfContractvalue: newValue });
  };

  handleContractProfileImageChange = async (e: any) => {
    let files = [];
    let urls: string = "";
    files = [...e.target.files];

    await files.map(async (file: any) => {
      urls = URL.createObjectURL(file);
    });

    this.setState({
      contractProfileFiles: files,
      profileImageError: "",
    });
    this.setState({ contractProfileUrls: urls }, () => {
      e.target.value = "";
    });
  };

  handleInsuranceImageChange = async (e: any) => {
    const file = e.target.files[0];

    let files = [];
    let urls = [];
    files = [...e.target.files];

    let fileurls: any = [];
    await files.map(async (file: any) => {
      fileurls.push({
        url: URL.createObjectURL(file),
        file_type: file.type,
      });
    });
    urls = [...fileurls];

    this.setState({
      insuranceFiles: [...this.state.insuranceFiles, ...files],
    });
    this.setState(
      { insuranceUrls: [...this.state.insuranceUrls, ...urls] },
      () => {
        e.target.value = "";
      }
    );
  };

  handleLicensesImageChange = async (e: any) => {
    console.log("target", e.target);

    const file = e.target.files[0];

    let files = [];
    let urls = [];
    files = [...e.target.files];
    console.log("files", files);

    let fileurls: any = [];
    await files.map(async (file: any) => {
      fileurls.push({
        url: URL.createObjectURL(file),
        file_type: file.type,
      });
    });
    urls = [...fileurls];
    console.log("filesurls", urls);

    this.setState({
      licensesFiles: files,
    });
    console.log(files);

    this.setState({ licensesUrls: urls }, () => {
      e.target.value = "";
    });
  };

  addLicense = async () => {
    if (this.state.license_name !== "" && this.state.licensesFiles.length > 0) {
      this.setState({
        addedLicenseName: [
          ...this.state.addedLicenseName,
          this.state.license_name,
        ],
        allLicensesFiles: [
          ...this.state.allLicensesFiles,
          ...this.state.licensesFiles,
        ],
        allLicensesUrls: [
          ...this.state.allLicensesUrls,
          ...this.state.licensesUrls,
        ],
        license_name: "",
        licensesFiles: [],
        licensesUrls: [],
        licenseModal: false,
      });
    }
  };

  handleVisaImageChange = async (e: any) => {
    const file = e.target.files[0];

    let files = [];
    let urls = [];
    files = [...e.target.files];

    let fileurls: any = [];
    await files.map(async (file: any) => {
      fileurls.push({
        url: URL.createObjectURL(file),
        file_type: file.type,
      });
    });
    urls = [...fileurls];

    this.setState({
      visaFiles: files,
    });
    this.setState({ visaUrls: urls }, () => {
      e.target.value = "";
    });
  };

  handlePreviewClose = () => {
    this.setState({ InsurancePreviewStatus: false });
  };
  handleImageChange = async (e: any) => {
    console.log(e);
  };

  handleDeleteImage = (id: number, index: number, type: string) => {
    console.log("handleDelete", id, type, index);

    if (type === "insurance") {
      if (id !== undefined) {
        this.props.deleteAttachementId(id);
      }
      const files = this.state.insuranceFiles;
      console.log("insurancefiles", files);

      const urls = this.state.insuranceUrls;
      console.log("insuranceurls", urls);
      files.splice(index, 1);
      urls.splice(index, 1);

      this.setState({ insuranceFiles: files });
      this.setState({ insuranceUrls: urls });
    } else if (type === "license") {
      const files = this.state.licensesFiles;
      const urls = this.state.allLicensesUrls;
      const licenseUrls = this.state.licensesUrls;

      files.splice(index, 1);
      urls.splice(index, 1);
      licenseUrls.splice(index, 1);

      this.setState({
        licensesFiles: files,
        allLicensesUrls: urls,
        licensesUrls: licenseUrls,
      });
      if (id !== undefined) {
        this.props.deleteAttachementId(id);
      }
    } else if (type === "visa") {
      if (id !== undefined) {
        this.props.deleteAttachementId(id);
      }
      const files = this.state.visaFiles;
      const urls = this.state.visaUrls;
      files.splice(index, 1);
      urls.splice(index, 1);

      this.setState({ visaFiles: files });
      this.setState({ visaUrls: urls });
    }
  };

  handlePreviewOpen = (file_type: any, url: any) => {
    if (
      file_type === "image/png" ||
      file_type === "image/jpeg" ||
      file_type === "image/jpg" ||
      file_type === "video/mp4" ||
      file_type === "video/webm" ||
      file_type === "application/pdf"
    ) {
      this.setState({
        InsurancePreviewStatus: true,
        previewUrl: url,
        previewType: file_type,
      });
    }
  };

  selectGender = (gender: string) => {
    if (gender === "male") {
      this.setState({
        isFemaleChecked: false,
        isMaleChecked: true,
        selectGender: ["male"],
      });
    } else {
      this.setState({
        isFemaleChecked: true,
        isMaleChecked: false,
        selectGender: ["female"],
      });
    }
  };

  addedSignatureImage = (sign: string) => {
    console.log(sign);
  };

  render(): React.ReactNode {
    const initialValues = {
      first_name: this.props.selectedContractorData?.first_name,
      last_name: this.props.selectedContractorData?.last_name,
      business_name: this.props.selectedContractorData?.business_name,
      abn_no: this.props.selectedContractorData?.abn_no,
      job_title: this.props.selectedContractorData?.job_title,
      //  gender : this.props.selectedContractorData?.gender,
      email: this.props.selectedContractorData?.email,
      business_contact_no:
        this.props.selectedContractorData?.business_contact_no,
      personal_contact_no:
        this.props.selectedContractorData?.personal_contact_no,
      home_address: this.props.selectedContractorData?.home_address,
      home_city_state: this.props.selectedContractorData?.home_city_state,
      business_address: this.props.selectedContractorData?.business_address,
      business_city_state:
        this.props.selectedContractorData?.business_city_state,
      emergency_name: this.props.selectedContractorData?.emergency_name,
      emergency_address: this.props.selectedContractorData?.emergency_address,
      emergency_relationship:
        this.props.selectedContractorData?.emergency_relationship,
      emergency_home_no: this.props.selectedContractorData?.emergency_home_no,
      emergency_mobile: this.props.selectedContractorData?.emergency_mobile,
      acc_name: this.props.selectedContractorData?.acc_name,
      bsb_no: this.props.selectedContractorData?.bsb_no,
      acc_no: this.props.selectedContractorData?.acc_no,
      license_expiry_date: this.props.selectedContractorData?.license_expiry_date,
      insurance_expiry_date: this.props.selectedContractorData?.insurance_expiry_date,
      visa_expiry_date: this.props.selectedContractorData?.visa_expiry_date
    };

    return (
      <div className="contract-form container mt-4">
        <div className="row">
          <div className="col-md-12 text-left text-md-end">
            <img src={LogoSVG} alt="profile" className="w-100" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <h2>Contractor Contact Form</h2>
          </div>
        </div>
        <hr className="mt-4 mb-4"></hr>
        <div className="row mt-4">
          <div className="col-md-12 col-lg-6 text-start text-lg-end tab-res-mt-3">
            {this.props.update_contractContactFormSuccessStatus ? (
              <Toast className="success-alerts">
                <Toast.Body>
                  <div className="row align-items-center">
                    <div className="col-md-2">
                      <SuccessToastSVG />
                    </div>
                    <div className="col-md-8">Updated Successfully</div>
                    <div className="col-md-2">
                      <button type="button" className="btn">
                        <CloseBtnSVG />
                      </button>
                    </div>
                  </div>
                </Toast.Body>
              </Toast>
            ) : (
              ""
            )}
          </div>
          <div className="col-12">
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={this.handleUpdateClientContract}
              validationSchema={this.validationSchema}
            >
              <Form encType="multipart/form-data">
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-12">
                        <p className="dashboard-title">Name :</p>
                      </div>
                      <div className="col-md-12 mb-2">
                        <label className="form-label">
                          First Name :<span className="text-project">*</span>
                        </label>
                        <Field
                          name="first_name"
                          type={"text"}
                          className="form-control contact-form text-field"
                        />
                        <ErrorMessage
                          name="first_name"
                          component={"div"}
                          className="cus-alert"
                        />
                      </div>
                      <div className="col-md-12 mb-2">
                        <label className="form-label">
                          Last Name : <span className="text-project">*</span>
                        </label>
                        <Field
                          name="last_name"
                          type={"text"}
                          className="form-control contact-form text-field"
                        />
                        <ErrorMessage
                          name="last_name"
                          component={"div"}
                          className="cus-alert"
                        />
                      </div>
                      <div className="col-md-12 mb-2">
                        <label className="form-label">
                          Business Name :If Available :
                        </label>
                        <Field
                          name="business_name"
                          type={"text"}
                          className="form-control contact-form text-field"
                        />
                        <ErrorMessage
                          name="business_name"
                          component={"div"}
                          className="cus-alert"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-12">
                        <p className="dashboard-title">Profile Image :</p>
                      </div>
                      <div className="col-md-12 mb-2 text-center">
                        <div className="attachment-btn">
                          <input
                            type="file"
                            accept=".pdf,.PDF,.webm,.WEBM,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                            style={{ display: "none" }}
                            onChange={this.handleContractProfileImageChange}
                            id="contained-button-file-contract-profile"
                          />
                          <label
                            htmlFor="contained-button-file-contract-profile"
                            style={{ width: "200px", height: "185px" }}
                          >

                            <img
                              src={this.state.contractProfileUrls}
                              alt="profile"
                              style={{ height: "100%", cursor: "pointer", width: "100%", objectFit: "contain" }}
                            />

                          </label>
                          <div className="pt-2">
                            <label
                              className={
                                this.state.contractProfileUrls ===
                                  "/static/media/contract-profile.e9905b4ed7bab1340f9d.png"
                                  ? "d-none"
                                  : ""
                              }
                              htmlFor="contained-button-file-contract-profile"
                            >
                              <Button
                                variant="contained"
                                color="default"
                                component="span"
                              >
                                <PaperClipSVG /> Change Profile
                              </Button>
                            </label>
                          </div>
                        </div>
                        <div className="cus-alert">
                          {this.state.profileImageError}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="mt-4 mb-4"></hr>
                <div className="row">
                  <div className="col-md-12">
                    <p className="dashboard-title">Tax File Number :</p>
                  </div>
                </div>

                <div className="row">
                  <div className="mb-2 col-md-4">
                    <label className="form-label">
                      ABN/ ACN Number : <span className="text-project">*</span>
                    </label>
                    <Field
                      name="abn_no"
                      type={"text"}
                      className="form-control contact-form text-field"
                    />
                    <ErrorMessage
                      name="abn_no"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>

                  <div className="mb-2 col-md-4">
                    <label className="form-label contact-form">
                      Date of Start : <span className="text-project">*</span>
                    </label>
                    <div
                      className="dateOfStart"
                      onClick={() => this.handleDateOfStartClick()}
                    >
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        id="dateOfStart"
                      >
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            inputFormat="DD/MM/YYYY"
                            maxDate={dayjs()}
                            value={this.state.dateOfStartvalue}
                            onChange={this.handleDateOfStartChange}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="mb-2 col-md-4">
                    <label className="form-label">
                      Job Title : <span className="text-project">*</span>
                    </label>
                    <Field
                      name="job_title"
                      type={"text"}
                      className="form-control contact-form text-field"
                    />
                    <ErrorMessage
                      name="job_title"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                  <div className="mb-2 col-md-4">
                    <label className="form-label contact-form">
                      Date of Birth : <span className="text-project">*</span>
                    </label>
                    <div
                      className="dateOfBirth"
                      onClick={() => this.handleDateOfBirthClick()}
                    >
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        id="dateOfBirth"
                      >
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            inputFormat="DD/MM/YYYY"
                            maxDate={dayjs()}
                            value={this.state.dateOfBirthvalue}
                            onChange={this.handleDateOfBirthChange}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="mb-2 col-md-4">
                    <label className="form-label">
                      Gender : <span className="text-project">*</span>
                    </label>
                    <div className="d-flex">
                      <div style={{ marginRight: "20px" }}>
                        <Field
                          name="gender"
                          type="checkbox"
                          checked={this.state.isMaleChecked}
                          value="male"
                          id="gender-male"
                          onClick={() => this.selectGender("male")}
                        />
                        <label
                          className="form-label"
                          style={{ marginLeft: "8px" }}
                        >
                          Male
                        </label>
                      </div>
                      <div>
                        <Field
                          name="gender"
                          type="checkbox"
                          checked={this.state.isFemaleChecked}
                          value="female"
                          id="gender-female"
                          onClick={() => this.selectGender("female")}
                        />
                        <label
                          className="form-label"
                          style={{ marginLeft: "8px" }}
                        >
                          Female
                        </label>
                      </div>
                    </div>

                    <ErrorMessage
                      name="gender"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                </div>
                <hr className="mt-4 mb-4"></hr>
                <div className="row">
                  <div className="col-md-12">
                    <p className="dashboard-title">Personal Contact Info:</p>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-2 col-md-4">
                    <label className="form-label">
                      Email Address: <span className="text-project">*</span>
                    </label>
                    <Field
                      name="email"
                      type={"text"}
                      className="form-control contact-form text-field"
                    />
                    <ErrorMessage
                      name="email"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                  <div className="mb-2 col-md-4">
                    <label className="form-label">
                      Contact Telephone No : Business :
                      <span className="text-project">*</span>
                    </label>
                    <Field name="business_contact_no">
                      {({ field, form, meta }: FieldProps<any>) => (
                        <div>
                          <PhoneInput
                            {...field}
                            inputProps={{ name: "business_contact_no" }}
                            specialLabel={""}
                            onChange={(value, data, e) => field.onChange(e)}
                            onBlur={field.onBlur}
                            value={field.value}
                            country={"au"}
                            inputStyle={{
                              paddingTop: 15,
                              paddingRight: 15,
                              paddingBottom: 15,
                              paddingLeft: 57,
                              width: "100%",
                              background: "#FFFFFF",
                              border: "1px solid #F0F0F0",
                              borderRadius: "8px",
                            }}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="mb-2 col-md-4">
                    <label className="form-label">
                      Mobile : <span className="text-project">*</span>
                    </label>
                    <Field name="personal_contact_no">
                      {({ field, form, meta }: FieldProps<any>) => (
                        <div>
                          <PhoneInput
                            {...field}
                            inputProps={{ name: "personal_contact_no" }}
                            specialLabel={""}
                            onChange={(value, data, e) => field.onChange(e)}
                            onBlur={field.onBlur}
                            value={field.value}
                            country={"au"}
                            inputStyle={{
                              paddingTop: 15,
                              paddingRight: 15,
                              paddingBottom: 15,
                              paddingLeft: 57,
                              width: "100%",
                              background: "#FFFFFF",
                              border: "1px solid #F0F0F0",
                              borderRadius: "8px",
                            }}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="row">
                    <div className="mb-2 col-md-4">
                      <label className="form-label">
                        Home Address : <span className="text-project">*</span>
                      </label>
                      <Field
                        name="home_address"
                        type={"text"}
                        className="form-control contact-form text-field"
                      />
                      <ErrorMessage
                        name="home_address"
                        component={"div"}
                        className="cus-alert"
                      />
                    </div>
                    <div className="mb-2 col-md-4">
                      <label className="form-label">
                        City/ State : <span className="text-project">*</span>
                      </label>
                      <Field
                        name="home_city_state"
                        type={"text"}
                        className="form-control contact-form text-field"
                      />
                      <ErrorMessage
                        name="home_city_state"
                        component={"div"}
                        className="cus-alert"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-2 col-md-4">
                      <label className="form-label">
                        Business Address: (If Apply) :
                      </label>
                      <Field
                        name="business_address"
                        type={"text"}
                        className="form-control contact-form text-field"
                      />
                      <ErrorMessage
                        name="business_address"
                        component={"div"}
                        className="cus-alert"
                      />
                    </div>
                    <div className="mb-2 col-md-4">
                      <label className="form-label">
                        City/ State : <span className="text-project">*</span>
                      </label>
                      <Field
                        name="business_city_state"
                        type={"text"}
                        className="form-control contact-form text-field"
                      />
                      <ErrorMessage
                        name="business_city_state"
                        component={"div"}
                        className="cus-alert"
                      />
                    </div>
                  </div>
                </div>
                <hr className="mt-4 mb-4"></hr>
                <div className="row">
                  <div className="col-md-12">
                    <p className="dashboard-title">Emergency Contact Info :</p>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-2 col-md-4">
                    <label className="form-label">
                      Name : <span className="text-project">*</span>
                    </label>
                    <Field
                      name="emergency_name"
                      type={"text"}
                      className="form-control contact-form text-field"
                    />
                    <ErrorMessage
                      name="emergency_name"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                  <div className="mb-2 col-md-4">
                    <label className="form-label">
                      Address : <span className="text-project">*</span>
                    </label>
                    <Field
                      name="emergency_address"
                      type={"text"}
                      className="form-control contact-form text-field"
                    />
                    <ErrorMessage
                      name="emergency_address"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                  <div className="mb-2 col-md-4">
                    <label className="form-label">
                      Relationship : <span className="text-project">*</span>
                    </label>
                    <Field
                      name="emergency_relationship"
                      type={"text"}
                      className="form-control contact-form text-field"
                    />
                    <ErrorMessage
                      name="emergency_relationship"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                  <div className="mb-2 col-md-4">
                    <label className="form-label">
                      Contact Number: Home :
                      <span className="text-project">*</span>
                    </label>
                    <Field name="emergency_home_no">
                      {({ field, form, meta }: FieldProps<any>) => (
                        <div>
                          <PhoneInput
                            {...field}
                            inputProps={{ name: "emergency_home_no" }}
                            specialLabel={""}
                            onChange={(value, data, e) => field.onChange(e)}
                            onBlur={field.onBlur}
                            value={field.value}
                            country={"au"}
                            inputStyle={{
                              paddingTop: 15,
                              paddingRight: 15,
                              paddingBottom: 15,
                              paddingLeft: 57,
                              width: "100%",
                              background: "#FFFFFF",
                              border: "1px solid #F0F0F0",
                              borderRadius: "8px",
                            }}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="mb-2 col-md-4">
                    <label className="form-label">
                      Mobile : <span className="text-project">*</span>
                    </label>
                    <Field name="emergency_mobile">
                      {({ field, form, meta }: FieldProps<any>) => (
                        <div>
                          <PhoneInput
                            {...field}
                            inputProps={{ name: "emergency_mobile" }}
                            specialLabel={""}
                            onChange={(value, data, e) => field.onChange(e)}
                            onBlur={field.onBlur}
                            value={field.value}
                            country={"au"}
                            inputStyle={{
                              paddingTop: 15,
                              paddingRight: 15,
                              paddingBottom: 15,
                              paddingLeft: 57,
                              width: "100%",
                              background: "#FFFFFF",
                              border: "1px solid #F0F0F0",
                              borderRadius: "8px",
                            }}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
                <hr className="mt-4 mb-4"></hr>
                <div className="row">
                  <div className="col-md-12">
                    <p className="dashboard-title">Bank Details :</p>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-2 col-md-4">
                    <label className="form-label">
                      Accounts Name : <span className="text-project">*</span>
                    </label>
                    <Field
                      name="acc_name"
                      type={"text"}
                      className="form-control contact-form text-field"
                    />
                    <ErrorMessage
                      name="acc_name"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                  <div className="mb-2 col-md-4">
                    <label className="form-label">
                      BSB Number : <span className="text-project">*</span>
                    </label>
                    <Field
                      name="bsb_no"
                      type={"text"}
                      className="form-control contact-form text-field"
                    />
                    <ErrorMessage
                      name="bsb_no"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                  <div className="mb-2 col-md-4">
                    <label className="form-label">
                      Account Number : <span className="text-project">*</span>
                    </label>
                    <Field
                      name="acc_no"
                      type={"text"}
                      className="form-control contact-form text-field"
                    />
                    <ErrorMessage
                      name="acc_no"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                </div>
                <hr className="mt-4 mb-4"></hr>
                <div className="row">
                  <div className="col-md-12">
                    <p className="dashboard-title">Upload Documents :</p>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-2 col-md-12">
                    <label className="form-label">Insurance :</label>
                    <div className="attachment-btn dropdown-field">
                      <input
                        type="file"
                        multiple
                        accept=".pdf,.PDF,.webm,.WEBM,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                        style={{ display: "none" }}
                        onChange={this.handleInsuranceImageChange}
                        id="contained-button-file1"
                      />
                      <label htmlFor="contained-button-file1">
                        <Button
                          variant="contained"
                          color="default"
                          component="span"
                        >
                          <PaperClipSVG /> Add insurance
                        </Button>
                      </label>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="d-flex row">
                          {this.state.insuranceUrls &&
                            this.state.insuranceUrls.map(
                              (url: any, index: number) => {
                                console.log("insuranceUrls", url);
                                return (
                                  <div className="col-md-3 img-card mb-3">
                                    <span className="delete">
                                      <a
                                        onClick={() =>
                                          this.handleDeleteImage(
                                            url.id,
                                            index,
                                            "insurance"
                                          )
                                        }
                                      >
                                        <TrashSVG />
                                      </a>
                                    </span>
                                    <div
                                      className="img-preview"
                                      onClick={() =>
                                        this.handlePreviewOpen(
                                          url.file_type,
                                          url.url
                                        )
                                      }
                                    >
                                      <img
                                        className="img-thumb-perview"
                                        src={
                                          url.file_type === "application/pdf"
                                            ? pdfImage
                                            : url.file_type === "image/png" ||
                                              url.file_type === "image/jpeg" ||
                                              url.file_type === "image/jpg"
                                              ? url.url
                                              : url.url
                                        }
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          <AttachmentPreview
                            displayPreview={this.state.InsurancePreviewStatus}
                            previewUrl={this.state.previewUrl}
                            previewType={this.state.previewType}
                            handlePreviewClose={this.handlePreviewClose}
                          />
                        </div>
                      </div>
                      <div className="mb-2 col-md-4">
                    <label className="form-label">
                      Insurance Expiry Date : 
                    </label>
                    {/* <Field
                      name="insurance_expiry_date"
                      type={"text"}
                      className="form-control contact-form text-field"
                    /> */}

<div
                      className="dateOfBirth"
                      onClick={() => this.handleDateOfBirthClick()}
                    >
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        id="InsuranceExpiry"
                      >
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            inputFormat="DD-MM-YYYY"
                            // maxDate={dayjs()}
                            value={this.state.insExpDatevalue}
                            onChange={this.handleDateOfInsChange}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                    </div>
                    </div>
                  </div>
                  <div className="mb-2 col-md-12">
                    <label className="form-label">Licenses :</label>
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.handleLicenseModalOpen}
                      >
                        Add Licenses
                      </button>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12">
                        <div className="d-flex row">
                          {this.state.allLicensesUrls &&
                            this.state.allLicensesUrls.map(
                              (url: any, index: number) => {
                                console.log("alllicensesUrls", url);
                                console.log("licenseUrl", url.id);
                                return (
                                  <div className="col-md-3 img-card mb-3">
                                    <label className="text-uppercase">
                                      {this.state.addedLicenseName[index]}
                                    </label>
                                    <span className="delete">
                                      <a
                                        onClick={() =>
                                          this.handleDeleteImage(
                                            url.id,
                                            index,
                                            "license"
                                          )
                                        }
                                      >
                                        <TrashSVG />
                                      </a>
                                    </span>
                                    <div
                                      className="img-preview"
                                      onClick={() =>
                                        this.handlePreviewOpen(
                                          url.file_type,
                                          url.url
                                        )
                                      }
                                    >
                                      <img
                                        className="img-thumb-perview"
                                        src={
                                          url.file_type === "application/pdf"
                                            ? pdfImage
                                            : url.file_type === "image/png" ||
                                              url.file_type === "image/jpeg" ||
                                              url.file_type === "image/jpg"
                                              ? url.url
                                              : url.url
                                        }
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          <AttachmentPreview
                            displayPreview={this.state.LicensesPreviewStatus}
                            previewUrl={this.state.previewUrl}
                            previewType={this.state.previewType}
                            handlePreviewClose={this.handlePreviewClose}
                          />
                        </div>
                      </div>
                      <div className="mb-2 col-md-4">
                    <label className="form-label">
                      License Expiry Date : 
                    </label>
                    {/* <Field
                      name="license_expiry_date"
                      type={"text"}
                      className="form-control contact-form text-field"
                    /> */}
                                 <div
                      className="dateOfBirth"
                      onClick={() => this.handleDateOfBirthClick()}
                    >
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        id="licenseExpiry"
                      >
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            inputFormat="DD-MM-YYYY"
                            // maxDate={dayjs()}
                            value={this.state.licenseExpDatevalue}
                            onChange={this.handleDateOfLicenseChange}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                    </div>
                    </div>
                  </div>
                  <div className="mb-2 col-md-12">
                    <label className="form-label">VISA Details :</label>
                    <div className="attachment-btn dropdown-field">
                      <input
                        type="file"
                        accept=".pdf,.PDF,.webm,.WEBM,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                        style={{ display: "none" }}
                        onChange={this.handleVisaImageChange}
                        id="contained-button-file3"
                      />
                      <label htmlFor="contained-button-file3">
                        <Button
                          variant="contained"
                          color="default"
                          component="span"
                        >
                          <PaperClipSVG />
                          Add Visa
                        </Button>
                      </label>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="d-flex row">
                          {this.state.visaUrls &&
                            this.state.visaUrls.map(
                              (url: any, index: number) => (
                                <div className="col-md-3 img-card mb-3">
                                  <span className="delete">
                                    <a
                                      onClick={() =>
                                        this.handleDeleteImage(
                                          url.id,
                                          index,
                                          "visa"
                                        )
                                      }
                                    >
                                      <TrashSVG />
                                    </a>
                                  </span>
                                  <div
                                    className="img-preview"
                                    onClick={() =>
                                      this.handlePreviewOpen(
                                        url.file_type,
                                        url.url
                                      )
                                    }
                                  >
                                    <img
                                      className="img-thumb-perview"
                                      src={
                                        url.file_type === "application/pdf"
                                          ? pdfImage
                                          : url.file_type === "image/png" ||
                                            url.file_type === "image/jpeg" ||
                                            url.file_type === "image/jpg"
                                            ? url.url
                                            : url.url
                                      }
                                    />
                                  </div>
                                </div>
                              )
                            )}
                          <AttachmentPreview
                            displayPreview={this.state.visaPreviewStatus}
                            previewUrl={this.state.previewUrl}
                            previewType={this.state.previewType}
                            handlePreviewClose={this.handlePreviewClose}
                          />
                        </div>
                      </div>
                      <div className="mb-2 col-md-4">
                    <label className="form-label">
                      Visa Expiry Date : 
                    </label>
                    {/* <Field
                      name="visa_expiry_date"
                      type={"text"}
                      className="form-control contact-form text-field"
                    /> */}
                                        <div
                      className="dateOfBirth"
                      onClick={() => this.handleDateOfBirthClick()}
                    >
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        id="visaExpiry"
                      >
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            inputFormat="DD-MM-YYYY"
                            // maxDate={dayjs()}
                            value={this.state.visaExpDatevalue}
                            onChange={this.handleDateOfVisaChange}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                    </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="mb-2 col-md-12 fw-normal">
                    <p>
                      I have provided the above contact information and
                      authorize SM&MN PROPERTY Group Pty Ltd and its
                      representatives to contact any of the above on my behalf
                      in the event of emergency.
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    {this.state.signatureImage !== "" && (
                      <img src={this.state.signatureImage} alt="" />
                    )}
                  </div>
                  <label
                    className="form-label contact-form"
                    style={{ marginRight: "30px" }}
                  >
                    Contractor Signature
                  </label>

                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleOpen}
                  >
                    Add Signature
                  </button>
                  <div className="cus-alert">
                    {this.state.signatureImageError}
                  </div>
                </div>
                {/* <div>
                                <SignaturePad width={500} height={200} />
                            </div> */}
                <div className="row">
                  <div className="mb-2 col-md-4">
                    <label className="form-label contact-form">
                      Date : <span className="text-project">*</span>
                    </label>
                    <div
                      className="dateOfContract"
                      onClick={() => this.handleDateOfContractClick()}
                    >
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        id="dateOfContract"
                      >
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            inputFormat="DD/MM/YYYY"
                            maxDate={dayjs()}
                            value={this.state.dateOfContractvalue}
                            onChange={this.handleDateOfContractChange}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <hr className="mt-4 mb-4"></hr>
                <div>
                  <div className="row mt-3 mb-5">
                    <div className="col-md-12 cus-progress">
                      <div className="d-flex">
                        <div className="mr-16px">
                          <button
                            type="submit"
                            id="create-button"
                            className="btn btn-primary create-button"
                          // onClick={this.progress}
                          >
                            Update
                            <span
                              id="spinner-border"
                              className="spinner-border spinner-border-sm d-none"
                            ></span>
                          </button>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.signatureModal}
          onHide={() => this.setState({ signatureModal: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              <h3 className="m-0">Add signature</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <div className="signature-pad">
              <canvas
                ref={this.canvasRef}
                style={{ width: "100% !important" }}
                onMouseDown={this.handleMouseDown}
                onMouseMove={this.handleMouseMove}
                onMouseUp={this.handleMouseUp}
                onTouchStart={this.handleTouchStart}
                onTouchEnd={this.handleTouchEnd}
                onTouchMove={this.handleTouchMove}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleClear}
            >
              Clear
            </button>
            <button
              type="button"
              className="btn btn-primary"
              id="add-sign-image"
              style={{ marginLeft: "10px" }}
              onClick={this.handleConvertToImage}
            >
              Add
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="lg"
          show={this.state.licenseModal}
          onHide={() => this.setState({ licenseModal: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              <h3 className="m-0">Add License</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <div className="row">
              <div className="col-md-6">
                <input
                  placeholder="Name (Ex : car, forklift, scissor lift, working in heights etc)"
                  name="license_name"
                  value={this.state.license_name}
                  onChange={(e: any) =>
                    this.setState({ license_name: e.target.value })
                  }
                  type={"text"}
                  className="form-control contact-form text-field"
                />
              </div>
              <div className="col-md-6">
                <div className="attachment-btn dropdown-field">
                  <input
                    type="file"
                    accept=".pdf,.PDF,.webm,.WEBM,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                    style={{ display: "none" }}
                    onChange={this.handleLicensesImageChange}
                    id="contained-button-file2"
                  />
                  <label htmlFor="contained-button-file2">
                    <Button
                      variant="contained"
                      color="default"
                      component="span"
                    >
                      <PaperClipSVG />
                      Add Licenses
                    </Button>
                  </label>
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <div className="d-flex row">
                  {this.state.licensesUrls &&
                    this.state.licensesUrls.map((url: any, index: number) => {
                      return (
                        <div className="col-md-3 img-card mb-3">
                          <span className="delete">
                            <a
                              onClick={() =>
                                this.handleDeleteImage(
                                  url?.id,
                                  index,
                                  "license"
                                )
                              }
                            >
                              <TrashSVG />
                            </a>
                          </span>
                          <div
                            className="img-preview"
                            onClick={() =>
                              this.handlePreviewOpen(url.file_type, url.url)
                            }
                          >
                            <img
                              className="img-thumb-perview"
                              src={
                                url.file_type === "application/pdf"
                                  ? pdfImage
                                  : url.file_type === "image/png" ||
                                    url.file_type === "image/jpeg" ||
                                    url.file_type === "image/jpg"
                                    ? url.url
                                    : url.url
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                  <AttachmentPreview
                    displayPreview={this.state.LicensesPreviewStatus}
                    previewUrl={this.state.previewUrl}
                    previewType={this.state.previewType}
                    handlePreviewClose={this.handlePreviewClose}
                  />
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <span className="mr-20px">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.addLicense}
                  >
                    Add
                  </button>
                </span>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  contractContactFormSuccessStatus: state.app.contractContactFormSuccessStatus,
  selectedContractorData: state.contractor.selectedContractorData,
  update_contractContactFormSuccessStatus:
    state.app.update_contractContactFormSuccessStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ContractcontactForm: bindActionCreators(ContractcontactForm, dispatch),
    getContractorById: bindActionCreators(getContractorById, dispatch),
    updateContractcontactForm: bindActionCreators(
      updateContractcontactForm,
      dispatch
    ),
    deleteAttachementId: bindActionCreators(deleteAttachementId, dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CleanerContactEditForm);
