import React from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { SearchSVG } from "../../assets/images/EmployeesSVG";
import SideBar from "../../components/sidebar/SideBar";
import TopNav from "../../components/topnav/TopNav";
import {
  IBranchDashboardProps,
  IBranchDashboardState,
} from "../../types/branch.type";
import AddBranch from "./AddBranch";
import { connect } from "react-redux";
import { RootState } from "../../index";
import { bindActionCreators, Dispatch } from "redux";
import {
  filterBranch,
  getAllBranches,
  updateBranchFetchStatus,
  updateBranchStatus,
  updateSidebar,
} from "../../shared/Reducers";
import BranchTable from "./BranchTable";
import ReactPaginate from "react-paginate";
import Constants from "../../shared/Constants";
import { Toast } from "react-bootstrap";
import { SuccessToastSVG } from "../../assets/images/ImagesV2";
import { CloseBtnSVG } from "../../assets/images/Location";
import ProfileImg from "../../assets/images/profile.png";
import { Field, Form, Formik } from "formik";
import { CustomSelect } from "../../shared/Components/CustomSelect";
import * as Yup from "yup";

class Branch extends React.Component<
  IBranchDashboardProps,
  IBranchDashboardState
> {
  constructor(props: IBranchDashboardProps) {
    super(props);
    this.state = {
      searchTerm: "",
      total_branch: "",
      demoShow: false,
      addToast: false,
      deleteToast: false,
      editToast: false,
      addBranchStatus: false,
      fieldName: "",
      orderType: "",
      pageCount: "",
      pagehandle: false,
      loaderApi: true,
      timeout: null,
    };
  }

  componentDidMount(): void {
    this.props.updateSidebar(true);
    document.title = "Branch";
    this.getBranches(Constants.OFFSET, Constants.PER_PAGE);
  }

  componentDidUpdate(
    prevProps: Readonly<IBranchDashboardProps>,
    prevState: Readonly<IBranchDashboardState>,
    snapshot?: any
  ): void {
    if (prevProps.addBranchStatus !== this.props.addBranchStatus) {
      if (this.props.addBranchStatus) {
        this.getBranches(Constants.OFFSET, Constants.PER_PAGE);
      }
    }

    if (prevProps.branchFetchStatus !== this.props.branchFetchStatus) {
      if (this.props.branchFetchStatus) {
        this.getBranches(Constants.OFFSET, Constants.PER_PAGE);
      }
    }
    if (prevProps.addBranchSuccess !== this.props.addBranchSuccess) {
      if (
        this.props.addBranchSuccess === "Successfully created" ||
        this.props.addBranchSuccess === "Internal server error"
      ) {
        if (this.props.addBranchSuccess === "Successfully created") {
          this.getBranches(Constants.OFFSET, Constants.PER_PAGE);
        }
        this.setState({ addToast: true });
        setTimeout(() => {
          this.setState({ addToast: false });
        }, 5000);
      }
    }
    if (prevProps.editBranchSuccess !== this.props.editBranchSuccess) {
      if (
        this.props.editBranchSuccess === "Successfully updated" ||
        this.props.editBranchSuccess === "Internal server error"
      ) {
        this.setState({ editToast: true });
        setTimeout(() => {
          this.setState({ editToast: false });
        }, 5000);
      }
    }
    if (prevProps.deleteBranchSuccess !== this.props.deleteBranchSuccess) {
      if (
        this.props.deleteBranchSuccess === "Successfully deleted" ||
        this.props.deleteBranchSuccess === "Internal server error"
      ) {
        // page reset
        let Offset: number = 0;
        let paginationActive = document.querySelector(
          ".pagination li.active a"
        ) as HTMLInputElement;
        if (paginationActive !== null) {
          let page: string | null = paginationActive.textContent;
          if (page !== null) {
            let pageNum: number = parseInt(page);
            Offset = (pageNum - 1) * Constants.PER_PAGE;
            console.log(Offset, Constants.PER_PAGE);

            this.getBranches(Offset, Constants.PER_PAGE);
          }
        }
        this.setState({ deleteToast: true });
        setTimeout(() => {
          this.setState({ deleteToast: false });
        }, 5000);
      }
    }
  }

  addToastClose = () => {
    this.setState({ addToast: false });
  };

  editToastClose = () => {
    this.setState({ editToast: false });
  };

  deleteToastClose = () => {
    this.setState({ deleteToast: false });
  };

  getBranches = async (offset: number, limit: number) => {
    try {
      const query = `search=${this.state.searchTerm}&sort=${this.state.fieldName}&order=${this.state.orderType}&offset=${offset}&limit=${limit}&client=all`;
      await this.props.getAllBranches(query);
      this.setState({ pageCount: this.props.total_count, loaderApi: false });
    } catch (error) {
      console.log(error);
    }
  };

  handleAddBranchClose = (status: boolean) => {
    this.handleClose();
    if (status) {
      this.getBranches(Constants.OFFSET, Constants.PER_PAGE);
    }
  };

  // Canvas Show
  handleShow() {
    // this.props.updateBranchStatus(true);
    this.setState({ addBranchStatus: true });
  }

  // Canvas Close
  handleClose() {
    // this.props.updateBranchStatus(false);
    this.setState({ addBranchStatus: false });
  }

  // Search Branch Filter
  handleFilter = (e: any) => {
    // const filterVal: string = e.target.value.toLowerCase();
    // this.props.filterBranch(filterVal);
    const value: string = e.target.value.toLowerCase();
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

    this.setState({
      searchTerm: value,
      timeout: setTimeout(async () => {
        try {
          const query = `search=${value}&sort=${this.state.fieldName}&order=${this.state.orderType}&offset=0&limit=10&client=all`;
          await this.props.getAllBranches(query);
          this.setState({ pageCount: this.props.total_count });
        } catch (error) {
          console.log(error);
        }
        // Trigger your function here that you want to call when user stops typing
      }, 1000), // Set the delay time in ms
    });
  };

  handlePageClick = (e: any) => {
    if (e.selected < this.props.total_count / Constants.PER_PAGE) {
      console.log("pagination condition in");
      const query = `search=${this.state.searchTerm}&sort=${
        this.state.fieldName
      }&order=${this.state.orderType}&offset=${
        e.selected * Constants.PER_PAGE
      }&limit=${Constants.PER_PAGE}&client=all`;
      this.props.getAllBranches(query);
      // this.getBranches(e.selected * Constants.PER_PAGE, Constants.PER_PAGE);
    }
  };

  validationSchema = () => {
    return Yup.object().shape({
      column_name: Yup.string().required("Select Filter Column"),
      order_type: Yup.string().required("Select Sort Order"),
    });
  };
  handleSearch = async (formValue: any) => {
    console.log(formValue.column_name);
    // this.handlePageClick({ selected: 1 });
    try {
      this.setState({ pagehandle: true });
      setTimeout(async () => {
        this.setState({
          fieldName: formValue.column_name,
          orderType: formValue.order_type,
          pagehandle: false,
        });
        const query = `search=${this.state.searchTerm}&sort=${formValue.column_name}&order=${formValue.order_type}&offset=${Constants.OFFSET}&limit=${Constants.PER_PAGE}&client=all`;
        await this.props.getAllBranches(query);
        this.setState({ pageCount: this.props.total_count });
      }, 0);
    } catch (error) {
      console.log(error);
    }
  };
  render(): React.ReactNode {
    let taskStatusOption: any = [];
    taskStatusOption = [
      { value: "client", label: "Client" },
      { value: "name", label: "Branch" },
      { value: "address", label: "Address" },
      { value: "status", label: "Active" },
    ];
    let contractOrder: any = [];
    contractOrder = [
      { value: "asc", label: "Ascending" },
      { value: "desc", label: "Descending" },
    ];
    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className={this.props.status ? "main-panel" : "main-panel2"}>
          <TopNav />
          <div className="content-wrapper">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="mb-0">Branch</h3>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-end">
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12 col-lg-4">
                  <div className="input-group search">
                    <span
                      className="input-group-text search-icon"
                      id="basic-addon1"
                    >
                      <SearchSVG />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={this.state.searchTerm}
                      onInput={this.handleFilter}
                      onChange={(e) =>
                        this.setState({ searchTerm: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12 col-lg-6">
                  <div>
                    <Formik
                      validationSchema={this.validationSchema}
                      onSubmit={this.handleSearch}
                      initialValues={{
                        column_name: "",
                        order_type: "asc",
                      }}
                    >
                      <Form className="d-flex flex-column flex-md-row">
                        <Field
                          className="custom-select dropdown-field flex-mr-16px w-180px"
                          name="column_name"
                          options={taskStatusOption}
                          component={CustomSelect}
                          placeholder="Select Column"
                          isMulti={false}
                        />
                        <Field
                          className="custom-select dropdown-field flex-mr-16px res-mt-3 w-180px"
                          name="order_type"
                          options={contractOrder}
                          component={CustomSelect}
                          placeholder="Select Order"
                          isMulti={false}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary filter-button res-mt-3"
                        >
                          Filter
                        </button>
                      </Form>
                    </Formik>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 text-start text-lg-end tab-res-mt-3">
                  {Constants.LOGIN_USER_TYPE !== Constants.SUPERVISORUTINT ? (
                    <Offcanvas
                      placement="end"
                      show={this.state.addBranchStatus}
                      onHide={() => this.handleClose()}
                    >
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                          <img
                            src={ProfileImg}
                            className="offc-img"
                            alt="profile"
                          />
                          <span className="offc-title">Add Branch</span>
                        </Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <AddBranch onClose={this.handleAddBranchClose} />
                      </Offcanvas.Body>
                    </Offcanvas>
                  ) : (
                    <></>
                  )}
                  {Constants.LOGIN_USER_TYPE !== Constants.SUPERVISORUTINT ? (
                    <Button
                      aria-controls="offcanvasRight"
                      className="btn btn-primary login-button mob-res-w-100"
                      onClick={() => this.handleShow()}
                    >
                      Add Branch
                    </Button>
                  ) : (
                    <></>
                  )}
                  {this.state.addToast ? (
                    <Toast className="success-alerts">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <SuccessToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.addBranchSuccess}
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.addToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                  {this.state.editToast ? (
                    <Toast className="success-alerts">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <SuccessToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.editBranchSuccess}
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.editToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                  {this.state.deleteToast ? (
                    <Toast className="success-alerts">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <SuccessToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.deleteBranchSuccess}
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.deleteToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {this.props.filteredData.length > 0 ? (
                      <div className="table-responsive">
                        <table className="table branch-table">
                          <thead>
                            <tr>
                              {/* <th scope="col">ID</th> */}
                              <th scope="col">CLIENT</th>
                              <th scope="col">BRANCH</th>
                              <th scope="col">ADDRESS</th>
                              <th scope="col">LATITUDE</th>
                              <th scope="col">LONGITUDE</th>
                              <th scope="col">QR CODE</th>
                              <th scope="col">IS ACTIVE</th>
                              <th scope="col">ACTION</th>
                            </tr>
                          </thead>
                          <BranchTable
                            data={this.props.filteredData}
                            onDelete={() =>
                              this.getBranches(
                                Constants.OFFSET,
                                Constants.PER_PAGE
                              )
                            }
                            onEditClose={() =>
                              this.getBranches(
                                Constants.OFFSET,
                                Constants.PER_PAGE
                              )
                            }
                            onViewClose={() =>
                              this.getBranches(
                                Constants.OFFSET,
                                Constants.PER_PAGE
                              )
                            }
                            deleteBranchStatus={false}
                          />
                        </table>
                      </div>
                    ) : this.props.filteredData.length === 0 &&
                      this.state.loaderApi === false ? (
                      <p className="text-center">
                        There is no branch record found
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              {this.props.total_count > 10 ? (
                <div className="col-md-12">
                  {this.state.pagehandle ? null : (
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.props.total_count / Constants.PER_PAGE}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      nextClassName={"next-page"}
                      activeClassName={"active"}
                    />
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  addBranchStatus: state.branch.addBranchStatus,
  branchFetchStatus: state.branch.branchFetchStatus,
  data: state.branch.data,
  filteredData: state.branch.filteredData,
  total_count: state.branch.total_count,
  addBranchSuccess: state.branch.addBranchSuccess,
  editBranchSuccess: state.branch.editBranchSuccess,
  deleteBranchSuccess: state.branch.deleteBranchSuccess,
  status: state.sidebar.status,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateBranchStatus: bindActionCreators(updateBranchStatus, dispatch),
    updateBranchFetchStatus: bindActionCreators(
      updateBranchFetchStatus,
      dispatch
    ),
    filterBranch: bindActionCreators(filterBranch, dispatch),
    getAllBranches: bindActionCreators(getAllBranches, dispatch),
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Branch);
