import React from "react";
import Profile from "./Profile";
import { IUser } from "../../types/user.type";
import authService from "../../services/auth.service";
import { MenuSVG } from "../../assets/images/TopNavSVG";
import { connect } from "react-redux";
import { Offcanvas } from "react-bootstrap";
import {
  updateSidebar,
} from "../../shared/Reducers/index";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "../../index";
import { ITopNavProps, ITopNavState } from "../../types/topbar.type";
import { Complaint } from "../../assets/images/ImagesV2";
import moment from "moment";
import Constants from "../../shared/Constants";
import Notification from "./Notification";
import { dashboardReport } from "../../shared/Reducers"
import { Link } from "react-router-dom";

class TopNav extends React.Component<ITopNavProps, ITopNavState> {
  constructor(props: ITopNavProps) {
    super(props);
    this.state = {
      user: null,
      australiaTime: moment(
        new Date().toLocaleString("en-AU", { timeZone: "Australia/Sydney" }),
        "DD/MM/YYYY, h:mm:ss a"
      ).format("DD MMM YYYY hh:mm:ss A"),
      completeShow: false,
    };
  }

  componentDidMount(): void {
    const user: IUser = authService.getCurrentuser();
    this.setState({ user });

    const interval: any = setInterval(() => {
      this.setState({
        australiaTime: moment(
          new Date().toLocaleString("en-AU", { timeZone: "Australia/Sydney" }),
          "DD/MM/YYYY, h:mm:ss a"
        ).format("DD MMM YYYY hh:mm:ss A"),
      });
    }, 1000);
    this.props.dashboardReport();
  }
  componentDidUpdate(
    prevProps: Readonly<ITopNavProps>,
    prevState: Readonly<ITopNavState>,
    snapshot?: any
  ): void {
    if (this.props.userLoginDetails !== prevProps.userLoginDetails) {
      // console.log(this.props.userLoginDetails);
    }
  }

  handdleClick = () => {
    this.props.updateSidebar(!this.props.status);
  };

  completeAdminClose = () => {
    this.setState({ completeShow: false });
  };
  render(): React.ReactNode {
    // console.log(this.props.userLoginDetails, "userLoginDetails");

    return (
      <div
        className={`container-fluid ${this.props.status ? "topnav" : "topnavshow"
          } `}
      >
        <div className="row align-items-center h-100">
          <div className="col-md-6 col-8">
            <div className="head-nav d-flex">
              <div className="res-d-block d-none">
                <button className="mob-menu" onClick={this.handdleClick}>
                  <MenuSVG />
                </button>
              </div>
              {this.props.userLoginDetails ? (
                <h3 className="mb-0">
                  Hi{" "}
                  {this.props.userLoginDetails.first_name &&
                    this.props.userLoginDetails.last_name
                    ? (
                      <>
                        <span>{this.props.userLoginDetails.first_name} {this.props.userLoginDetails.last_name}</span>{" "}
                        <span className="user-type">{this.props.userLoginDetails.user_type_name.replace(/_/g, ' ')}</span>
                      </>
                    ) : 'User'
                  }
                </h3>
              ) : (
                <h3 className="mb-0">Hi User</h3>
              )}
            </div>
          </div>

          <div className="col-md-6 col-4">
            <div className="gap-2 d-flex align-items-center justify-content-end">
              <p className="m-0 d-none d-md-block">{this.state.australiaTime}</p>
              <>
                {
                  this.props.userLoginDetails?.permission?.topbar_complaint_count === 1 &&
                  (
                    <>
                      {this.props.reportData && this.props.reportData.new_complaint_count > 0 &&
                        <div className="position-relative">
                          <p className="m-0" style={{ paddingLeft: "30px", paddingRight: "10px" }}><Link to="/complaints"><Complaint /></Link></p>
                          <div className="complaint-count">
                            {this.props.reportData && this.props.reportData.new_complaint_count > 0 ? (
                              <p className="m-0">{this.props.reportData.new_complaint_count}</p>
                            ) : (<></>)}
                          </div>
                        </div>
                      }
                    </>
                  )
                }
              </>

              {Constants.CLEANERUTINT === Constants.LOGIN_USER_TYPE ? null : (
                <p className="m-0"><Notification user={this.state.user} /></p>
              )}
              <Profile user={this.state.user} />
            </div>
          </div>
        </div>
        <Offcanvas
          placement="end"
          show={this.state.completeShow}
          onHide={() => this.completeAdminClose()}
          className="default"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              {/* <img src={ProfileImg} className="offc-img" alt="profile" /> */}
              <span className="offc-title">Edit Admin</span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body></Offcanvas.Body>
        </Offcanvas>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  status: state.sidebar.status,
  complaintData: state.dashboard.complaintsData,
  userLoginDetails: state.sidebar.userLoginDetails,
  subAdmin: state.app.subAdmin,
  reportData: state.dashboard.reportData,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
    dashboardReport: bindActionCreators(dashboardReport, dispatch),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
