import * as Yup from "yup";

import Constants, {
  EveryMonth,
  EveryWeekDays,
  TASK_PRIORITY,
} from "../../shared/Constants";
import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { IBranchSelect, ICleaner } from "../../shared/ActionTypes";
import {
  IEditDailyTaskProps,
  IEditDailyTaskState,
} from "../../types/dailyTask.type";
import { PaperClipSVG, TrashSVG } from "../../assets/images/ImagesV2";
import {
  copyDailyTask,
  deleteAttachementId,
  editDailyTask,
  listAllBranch,
  listAllCleaner,
  listAllClient,
  makeScheduleDailyTask,
  addSchedule,
  updateDailyTaskFetchStatus,
  updateEditDailyTaskDlgStatus,
  EditModalDailyTask,
  sendQAEmailDailyTask,
  getSendmailUsers,
} from "../../shared/Reducers";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AttachmentDownloadSVG } from "../../assets/images/ImagesSVG";
import AttachmentPreview from "../../shared/Components/AttachmentPreview";
import ImageDrawing from "../../export/ImageDrawing";
import Button from "@material-ui/core/Button";
import CustomSelect from "../../shared/Components/CustomSelect";
import DatePicker from "./DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import FormCheck from "react-bootstrap/Form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Modal } from "react-bootstrap";
import MulitipleDatePicker from "react-multi-date-picker";
import ProgressBar from "react-bootstrap/ProgressBar";
import React from "react";
import { RootState } from "../../index";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import TimePicker from "./TimePicker";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import docImage from "../../assets/images/google-docs.png";
import moment from "moment";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";

// Radio input
const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  className,
  ...props
}: any) => {
  return (
    <div>
      <input
        name={name}
        id={id}
        type="radio"
        value={id} // could be something else for output?
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        className={"radio-button"}
        {...props}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

// Radio group
const RadioButtonGroup = ({
  value,
  error,
  touched,
  // id,
  label,
  className,
  children,
}: any) => {
  const classes = `input-field ${
    value || (!error && touched) ? "is-success" : ""
  } ${!!error && touched ? "is-error" : ""} ${className}`;

  return (
    <div className={classes}>
      <fieldset>
        <legend>{label}</legend>
        {children}
        {/* {touched && <InputFeedback error={error} />} */}
      </fieldset>
    </div>
  );
};

class EditDailyTask extends React.Component<
  IEditDailyTaskProps,
  IEditDailyTaskState
> {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  constructor(props: IEditDailyTaskProps) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      attachmentUrls: [],
      task_date: this.props.data?.task_date,
      start_time:
        this.props.data?.start_time === null
          ? "00:00:00"
          : this.props.data?.start_time,
      end_time:
        this.props.data?.end_time === null
          ? "00:00:00"
          : this.props.data?.end_time,
      deleteAttachementId: [],
      AttachmentPreviewStatus: false,
      previewUrl: "",
      previewType: "",
      endSchedule: "",
      startSchedule: "",
      specificDate: "",
      ScheduleDateType: "",
      selectDateErrorAlert: false,
      file_count: "",
      signatureImage: [],
      signState: false,
      signatureModal: false,
      signLastX: 0,
      signLastY: 0,
      isDrawing: false,
      signatureImageError: "",
      context: null,
    };
  }

  componentDidMount(): void {
    if (this.props.data) {
      this.setState({
        attachmentUrls: this.props.data.attachments.map((obj: any) => ({
          url: obj.s3_url,
          name: obj.file_name,
          id: obj.id,
          type: "download",
          download_url: `${Constants.imgUrl}${obj.file_name}`,
          file_type: obj.type,
          contractor_attachment_type: obj.contractor_attachment_type,
        })),
        task_date: this.props.data?.task_date,
        start_time:
          this.props.data.start_time === null
            ? "00:00:00"
            : this.props.data.start_time,
        end_time:
          this.props.data.end_time === null
            ? "00:00:00"
            : this.props.data.end_time,
        signatureImage: this.props.data.attachments.map((item: any) => ({
          url: item.s3_url,
          name: item.file_name,
          id: item.id,
          type: "download",
          download_url: `${Constants.imgUrl}${item.file_name}`,
          file_type: item.type,
          contractor_attachment_type: item.contractor_attachment_type,
        })),
        signState: false,
      });
    }
    // if (
    //   Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
    //   Constants.QA_MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
    //   Constants.OPERATION_MANAGERUTINT === Constants.LOGIN_USER_TYPE
    // ) {
    //   this.props.listAllBranch(0);
    // } else {
    this.props.listAllClient();
    this.props.listAllCleaner();
    if (this.props.data) {
      this.props.listAllBranch(this.props.data.client_id);
    }
    // }
    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.add("d-none");
      button.removeAttribute("disabled");
    }

    // date picker readonly
    let datePicker: NodeListOf<Element> = document.querySelectorAll(
      ".MuiFormControl-root input"
    ) as NodeListOf<Element>;
    if (datePicker.length > 0) {
      for (let index = 0; index < datePicker.length; index++) {
        datePicker[index].setAttribute("readonly", "true");
      }
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IEditDailyTaskProps>,
    prevState: Readonly<IEditDailyTaskState>,
    snapshot?: any
  ): void {
    if (this.props.data !== prevProps.data) {
      this.setState({
        attachmentUrls: this.props.data?.attachments.map((obj: any) => ({
          url: `${Constants.imgThumbUrl}${obj.file_name}`,
          name: obj.file_name,
          id: obj.id,
          type: "download",
          download_url: `${Constants.imgUrl}${obj.file_name}`,
          file_type: obj.type,
        })),
        task_date: this.props.data?.task_date,
        start_time:
          this.props.data?.start_time === null
            ? "00:00:00"
            : this.props.data?.start_time,
        end_time:
          this.props.data?.end_time === null
            ? "00:00:00"
            : this.props.data?.end_time,
      });

      this.props.listAllBranch(this.props.data?.client_id);
    }
  }

  handleImageChange = async (e: any) => {
    let files = [];
    let urls = [];
    files = [...e.target.files];

    let fileurls: any = [];
    await files.map(async (file: any) => {
      fileurls.push({
        url: URL.createObjectURL(file),
        file: file,
        id: "0",
        file_type: file.type,
        download_url: URL.createObjectURL(file),
      });
    });
    urls = [...fileurls];
    this.setState({ attachmentUrls: [...this.state.attachmentUrls, ...urls] });
  };

  handleDeleteImage = (index: number) => {
    const urls = this.state.attachmentUrls;

    confirmAlert({
      title: "Confirm deletion",
      message: "Are you sure you want to delete this Attchment ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            // check exist file
            if (urls[index].id !== undefined && urls[index].id !== "0") {
              let deleteAttachementId = this.state.deleteAttachementId;
              deleteAttachementId.push(urls[index].id);
              this.setState({ deleteAttachementId: deleteAttachementId });

              urls.splice(index, 1);
              this.setState({ attachmentUrls: urls });
            } else {
              urls.splice(index, 1);
              this.setState({ attachmentUrls: urls });
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  handleEditTask = async (formValues: any) => {
    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (
      this.props.makeScheduleDailyTaskStatus === true &&
      this.state.ScheduleDateType === ""
    ) {
      this.setState({ selectDateErrorAlert: true });
    } else {
      if (element !== null && button !== null) {
        element.classList.remove("d-none");
        button.setAttribute("disabled", "");
      }
    }

    if (this.props.copyDailyTaskStatus === true) {
      let data = new FormData();
      data.append("task_list", this.props.id);
      data.append("title", formValues.title);
      // data.append('branch', formValues.branch);
      for (let index = 0; index < this.state.attachmentUrls.length; index++) {
        if (this.state.attachmentUrls[index].id === "0") {
          data.append("attachments", this.state.attachmentUrls[index].file);
        }
      }
      // data.append('task_type', Constants.SPECIAL_REQUEST_STR);
      data.append("branch", formValues.branch);
      data.append("description", formValues.description);
      data.append("task_date", this.state.task_date);
      if (formValues.any_time === "1") {
        data.append("any_time", formValues.any_time);
      } else if (formValues.any_time === "0") {
        data.append("any_time", "0");
        data.append("start_time", this.state.start_time);
        data.append("end_time", this.state.end_time);
      }
      data.append("is_active", formValues.is_active);
      data.append("priority", formValues.priority);
      data.append("additional_task_status", "1");
      data.append("ref_task_id", "1");
      data.append(
        "deleted_attachment",
        JSON.stringify(this.state.deleteAttachementId)
      );

      // let data = new FormData();
      // data.append("title", formValues.title);
      // // data.append('branch', formValues.branch);
      // for (let index = 0; index < this.state.attachmentUrls.length; index++) {
      //   if (this.state.attachmentUrls[index].id === "0") {
      //     data.append("attachments", this.state.attachmentUrls[index].file);
      //   }
      // }
      // // data.append('task_type', Constants.SPECIAL_REQUEST_STR);
      // data.append("branch", formValues.branch);
      // data.append("description", formValues.description);
      // data.append("task_date", moment(this.state.task_date, "YYYY-MM-DD").format("DD/MM/YYYY"));
      // if (formValues.any_time === "1") {
      //   data.append("any_time", formValues.any_time);
      // } else if (formValues.any_time === "0") {
      //   data.append("any_time", "0");
      //   data.append("start_time", this.state.start_time);
      //   data.append("end_time", this.state.end_time);
      // }
      // data.append("status", formValues.status);
      // data.append("priority", formValues.priority);
      // data.append("ref_task_id", "1");
      // data.append(
      //   "deleted_attachment",
      //   JSON.stringify(this.state.deleteAttachementId)
      // );
      if (this.props.id) {
        this.props.copyDailyTask(data);
        this.props.getSendmailUsers(JSON.stringify(formValues.branch));
        let value = [...this.props.sendMailUsers];
        const emailTags: any = [];
        value.map((item) => {
          emailTags.push(item.email);
        });
        let taskSendToMail = {
          receipents: emailTags,
          task_list: this.props.id,
        };
        this.props.sendQAEmailDailyTask(taskSendToMail);
      }
    } else if (this.props.makeScheduleDailyTaskStatus === true) {
      if (this.state.ScheduleDateType === "") {
        this.setState({ selectDateErrorAlert: true });
      } else {
        let data: any = [];
        let task_id = this.props.id;
        let schedule_name = formValues.title;
        let schedule_end_date = moment(this.state.task_date).format(
          "DD/MM/YYYY"
        );
        let any_time = "";
        let start_time = "";
        let end_time = "";
        if (formValues.any_time === "1") {
          any_time = formValues.any_time;
        } else if (formValues.any_time === "0") {
          any_time = "0";
          start_time = this.state.start_time;
          end_time = this.state.end_time;
        }
        let status = formValues.status;
        let priority = formValues.priority;
        let schedule_option = this.state.ScheduleDateType;

        if (this.state.ScheduleDateType === "every_month_days") {
          data = {
            task: task_id,
            schedule_name: schedule_name,
            schedule_end_date: schedule_end_date,
            any_time: any_time === "1" ? 1 : 0,
            branch: formValues.branch,
            client: formValues.client,
            cleaners: formValues.cleaners,
            start_time: start_time,
            end_time: end_time,
            status: status,
            priority: priority,
            schedule_option: schedule_option,
            days: formValues.selectDate,
          };
        } else if (this.state.ScheduleDateType === "specific_dates") {
          let specificDateGet: any = [];
          for (let index = 0; index < this.state.specificDate.length; index++) {
            const element = this.state.specificDate[index];
            const monthFormatted = String(element.month).padStart(2, "0");
            const dayFormatted = String(element.day).padStart(2, "0");
            if (element.day !== undefined) {
              specificDateGet.push(
                element.year + "-" + monthFormatted + "-" + dayFormatted
              );
            } else {
              specificDateGet.push(
                moment(element, "DD/MM/YYYY").format("DD/MM/YYYY")
              );
            }
          }
          data = {
            task: task_id,
            schedule_name: schedule_name,
            schedule_end_date: schedule_end_date,
            any_time: any_time === "1" ? 1 : 0,
            branch: formValues.branch,
            client: formValues.client,
            cleaners: formValues.cleaners,
            start_time: start_time,
            end_time: end_time,
            status: status,
            priority: priority,
            schedule_option: schedule_option,
            specific_dates: specificDateGet,
          };
        } else if (this.state.ScheduleDateType === "date_to_date") {
          let specific_dates: any = [
            moment(this.state.startSchedule?.toString()).format("YYYY/MM/DD"),
            moment(this.state.endSchedule?.toString()).format("YYYY/MM/DD"),
          ];
          data = {
            task: task_id,
            schedule_name: schedule_name,
            schedule_end_date: schedule_end_date,
            any_time: any_time === "1" ? 1 : 0,
            client: formValues.client,
            branch: formValues.branch,
            cleaners: formValues.cleaners,
            start_time: start_time,
            end_time: end_time,
            status: status,
            priority: priority,
            schedule_option: schedule_option,
            specific_dates: specific_dates,
          };
        } else if (this.state.ScheduleDateType === "every_week") {
          data = {
            task: task_id,
            schedule_name: schedule_name,
            schedule_end_date: schedule_end_date,
            any_time: any_time === "1" ? 1 : 0,
            branch: formValues.branch,
            client: formValues.client,
            cleaners: formValues.cleaners,
            start_time: start_time,
            end_time: end_time,
            status: status,
            priority: priority,
            schedule_option: schedule_option,
            days: formValues.everyWeek,
          };
        }

        this.props.addSchedule(data);
        this.props.getSendmailUsers(JSON.stringify(formValues.branch));
        let value = [...this.props.sendMailUsers];
        const emailTags: any = [];
        value.map((item) => {
          emailTags.push(item.email);
        });
        let taskSendToMail = {
          receipents: emailTags,
          task_list: this.props.id,
        };
        this.props.sendQAEmailDailyTask(taskSendToMail);
        if (element !== null && button !== null) {
          element.classList.add("d-none");
          button.removeAttribute("disabled");
        }
      }
      // } else if (Constants.QAUTINT === Constants.LOGIN_USER_TYPE) {
      //   console.log(formValues);
      //   let data = new FormData();
      //   data.append("title", formValues.title);
      //   for (let index = 0; index < this.state.attachmentUrls.length; index++) {
      //     if (this.state.attachmentUrls[index].id === "0") {
      //       data.append("attachments", this.state.attachmentUrls[index].file);
      //     }
      //   }
      //   data.append("task_type", Constants.SPECIAL_REQUEST_STR);
      //   data.append("description", formValues.description);
      //   data.append("task_date", moment(this.state.task_date, "YYYY-MM-DD").format("DD/MM/YYYY");
      //   if (formValues.any_time === "1") {
      //     data.append("any_time", formValues.any_time);
      //   } else if (formValues.any_time === "0") {
      //     data.append("any_time", "0");
      //     data.append("start_time", this.state.start_time);
      //     data.append("end_time", this.state.end_time);
      //   }
      //   data.append("status", formValues.status);
      //   data.append("priority", formValues.priority);
      //   data.append("ref_task_id", "1");
      //   if (this.props.id) {
      //     await this.state.deleteAttachementId.map(async (id: any) => {
      //       this.props.deleteAttachementId(id);
      //     });
      //     this.props.editDailyTask(data, this.props.id);
      //   }
      // } else if (
      //   Constants.QA_MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
      //   Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
      //   Constants.SUPERVISOR_MANAGERUTINT === Constants.LOGIN_USER_TYPE
      // ) {
      //   console.log(formValues);
      //   let data = new FormData();
      //   data.append("title", formValues.title);
      //   data.append("branch", formValues.branch);
      //   for (let index = 0; index < this.state.attachmentUrls.length; index++) {
      //     if (this.state.attachmentUrls[index].id === "0") {
      //       data.append("attachments", this.state.attachmentUrls[index].file);
      //     }
      //   }
      //   data.append("task_type", Constants.SPECIAL_REQUEST_STR);
      //   data.append("description", formValues.description);
      //   data.append("task_date", moment(this.state.task_date, "YYYY-MM-DD").format("DD/MM/YYYY");
      //   if (formValues.any_time === "1") {
      //     data.append("any_time", formValues.any_time);
      //   } else if (formValues.any_time === "0") {
      //     data.append("any_time", "0");
      //     data.append("start_time", this.state.start_time);
      //     data.append("end_time", this.state.end_time);
      //   }
      //   data.append("status", formValues.status);
      //   data.append("priority", formValues.priority);
      //   data.append("ref_task_id", "1");
      //   if (this.props.id) {
      //     await this.state.deleteAttachementId.map(async (id: any) => {
      //       this.props.deleteAttachementId(id);
      //     });
      //     this.props.editDailyTask(data, this.props.id);
      //   }
      // } else if (Constants.OPERATION_MANAGERUTINT === Constants.LOGIN_USER_TYPE) {
      //   console.log(formValues);
      //   let data = new FormData();
      //   data.append("client", formValues.client);
      //   data.append("branch", formValues.branch);
      //   data.append("title", formValues.title);
      //   for (let index = 0; index < this.state.attachmentUrls.length; index++) {
      //     if (this.state.attachmentUrls[index].id === "0") {
      //       data.append("attachments", this.state.attachmentUrls[index].file);
      //     }
      //   }
      //   data.append("description", formValues.description);
      //   data.append("task_date", moment(this.state.task_date, "YYYY-MM-DD").format("DD/MM/YYYY"));
      //   if (formValues.any_time === "1") {
      //     data.append("any_time", formValues.any_time);
      //   } else if (formValues.any_time === "0") {
      //     data.append("any_time", "0");
      //     data.append("start_time", this.state.start_time);
      //     data.append("end_time", this.state.end_time);
      //   }
      //   data.append("ref_task_id", "1");
      //   data.append("status", formValues.status);
      //   data.append("priority", formValues.priority);
      //   if (this.props.id) {
      //     await this.state.deleteAttachementId.map(async (id: any) => {
      //       this.props.deleteAttachementId(id);
      //     });
      //     this.props.editDailyTask(data, this.props.id);
      //   }
    } else {
      console.log("formValues", formValues);
      let data = new FormData();
      data.append("client", formValues.client);
      data.append("branch", formValues.branch);
      data.append("title", formValues.title);
      for (let index = 0; index < this.state.attachmentUrls.length; index++) {
        if (this.state.attachmentUrls[index].id === "0") {
          data.append("attachments", this.state.attachmentUrls[index].file);
        }
      }
      data.append("cleaners", JSON.stringify(formValues.cleaners));
      data.append("task_type", formValues.task_type);
      data.append("description", formValues.description);
      data.append(
        "task_date",
        moment(this.state.task_date, "YYYY-MM-DD").format("DD/MM/YYYY")
      );
      if (formValues.any_time === "1") {
        data.append("any_time", formValues.any_time);
      } else if (formValues.any_time === "0") {
        data.append("any_time", "0");
        data.append("start_time", this.state.start_time);
        data.append("end_time", this.state.end_time);
      }
      data.append("ref_task_id", "1");
      data.append("task_with_sign_in", formValues.task_with_sign_in);
      data.append("status", formValues.status);
      data.append("priority", formValues.priority);
      data.append("sp_task_signature", this.state.signatureImage);
      if (this.props.id) {
        await this.state.deleteAttachementId.map(async (id: any) => {
          this.props.deleteAttachementId(id);
        });
        this.props.editDailyTask(data, this.props.id);
      }
      this.props.getSendmailUsers(JSON.stringify(formValues.branch));
      let value = [...this.props.sendMailUsers];
      const emailTags: any = [];
      value.map((item) => {
        emailTags.push(item.email);
      });
      let taskSendToMail = {
        receipents: emailTags,
        task_list: this.props.id,
      };
      this.props.sendQAEmailDailyTask(taskSendToMail);
    }
    this.props.EditModalDailyTask(false);
    this.props.updateEditDailyTaskDlgStatus(false);
  };

  // handleStartDateClick = () => {
  //   let startDate: HTMLElement = document.querySelector(
  //     ".startdate button.MuiButtonBase-root"
  //   ) as HTMLElement;
  //   startDate.click();
  // };

  handleTaskDate = async (val: any) => {
    // let startDate: HTMLElement = document.querySelector(
    //   ".startdate button.MuiButtonBase-root"
    // ) as HTMLElement;
    // startDate.click();
    await this.setState({ task_date: val });
  };

  handleStartTime = (val: string) => {
    this.setState({ start_time: val });
  };

  handleEndTime = (val: string) => {
    this.setState({ end_time: val });
  };
  handlePreviewClose = (bool: any, urlArray: any, attachFiles: any) => {
    this.setState({ AttachmentPreviewStatus: false, attachmentUrls: urlArray });
  };
  handlePreviewOpen = (file_type: any, url: any, inx: any) => {
    if (
      file_type === "image/png" ||
      file_type === "image/jpeg" ||
      file_type === "image/jpg" ||
      file_type === "video/mp4" ||
      file_type === "video/webm" ||
      file_type === "application/pdf"
    ) {
      this.setState({
        AttachmentPreviewStatus: true,
        previewUrl: url,
        previewType: file_type,
        file_count: inx,
      });
    }
  };

  validationSchema = () => {
    // if (Constants.QAUTINT === Constants.LOGIN_USER_TYPE) {
    //   return Yup.object().shape({
    //     title: Yup.string().required("Enter Task Name"),
    //     description: Yup.string().required("Enter Task Description"),
    //   });
    // } else if (
    //   Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
    //   Constants.QA_MANAGERUTINT === Constants.LOGIN_USER_TYPE
    // ) {
    //   const branchId = this.props.branchList.map(
    //     (branch: IBranchSelect) => branch.value
    //   );
    //   return Yup.object().shape({
    //     title: Yup.string().required("Enter Task Name"),
    //     description: Yup.string().required("Enter Task Description"),
    //     branch: Yup.string()
    //       .oneOf([...branchId], "Invalid Branch")
    //       .required("Please select branch")
    //       .nullable(),
    //   });
    // } else if (Constants.OPERATION_MANAGERUTINT === Constants.LOGIN_USER_TYPE) {
    //   const branchId = this.props.branchList.map(
    //     (branch: IBranchSelect) => branch.value
    //   );
    //   return Yup.object().shape({
    //     title: Yup.string().required("Enter Task Name"),
    //     description: Yup.string().required("Enter Task Description"),
    //     branch: Yup.string()
    //       .oneOf([...branchId], "Invalid Branch")
    //       .required("Please select branch")
    //       .nullable(),
    //   });
    // } else {
    // const clientId = this.props.clientList.map((client: ICleaner) => client.id);
    // const branchId = this.props.branchList.map((branch: IBranchSelect) => branch.value);
    // const cleanerId = this.props.cleanerList.map((cleaners: IBranchSelect) => cleaners.value);
    return Yup.object().shape({
      title: Yup.string().required("Enter Task Name"),
      description: Yup.string().required("Enter Task Description"),
      cleaners: Yup.array().when("copyDailyTaskStatus", {
        is: false, // Apply validation when copyDailyTaskStatus is false
        then: Yup.array().min(1, "Select at least one cleaner"),
      }),
      // cleaners: Yup.array().min(1, "Select atleast one cleaner"),
      // client: Yup.string().oneOf([...clientId], 'Invalid Client').required().nullable(),
      // branch: Yup.string().oneOf([...branchId], 'Invalid Branch').required().nullable(),
      // is_active: Yup.string(),
      // attachment: Yup.mixed(),
      // cleaners: Yup.array()
      //     .min(1, 'Atleast one cleaner has to be selected')
      //     .of(Yup.string())
      //     .required()
      //     .nullable(),
      // task_type: Yup.string(),
      // any_time: Yup.string(),
      // start_time: Yup.string(),
      // end_time: Yup.string(),
    });
    // }
  };

  handleChange = (e: any) => {
    if (e.target.name === "client") {
      this.props.listAllBranch(e.target.value);
    }
  };

  downloadFile = (url: string, name = "image.png") => {
    this.downloadResource(url, name);
  };

  forceDownload = (blob: any, filename: String) => {
    const a: any = document.createElement("a");
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  // Current blob size limit is around 500MB for browsers
  downloadResource = (url: any, filename: any) => {
    if (url) {
      if (!filename) filename = url.split("\\").pop().split("/").pop();
      fetch(url, {
        headers: new Headers({
          Origin: window.location.origin,
        }),
        mode: "cors",
      })
        .then((response) => response.blob())
        .then((blob) => {
          let blobUrl = window.URL.createObjectURL(blob);
          this.forceDownload(blobUrl, filename);
        })
        .catch((e) => console.error(e));
    }
  };

  handleSignModal = () => {
    this.setState({ signatureModal: true, signState: true });
  };

  handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
    this.setState({ signatureImageError: "" });

    this.setState({ isDrawing: true });
    const canvas = this.canvasRef.current;

    if (!canvas) return;
    this.context = canvas.getContext("2d");
    this.setState({ context: canvas.getContext("2d") });
    if (!this.context) return;

    const { offsetX, offsetY } = event.nativeEvent;

    this.setState({ signLastX: offsetX, signLastY: offsetY });
  };

  handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    this.setState({ signatureImageError: "" });

    if (!this.state.isDrawing) return;
    const canvas = this.canvasRef.current;

    if (canvas) {
      const { offsetX, offsetY } = event.nativeEvent;

      // Draw a line segment from the last recorded point to the current point
      let context: any = this.state.context;
      context.strokeStyle = "#000"; // Stroke color
      context.lineWidth = 2; // Stroke width
      context.beginPath();
      context.moveTo(this.state.signLastX, this.state.signLastY);
      context.lineTo(offsetX, offsetY);
      context.stroke();

      this.setState({ signLastX: offsetX, signLastY: offsetY });
    }
  };

  handleMouseUp = () => {
    this.setState({ signatureImageError: "" });

    this.setState({ isDrawing: false });
  };

  handleTouchStart = (event: React.TouchEvent<HTMLCanvasElement>) => {
    this.setState({ signatureImageError: "" });

    this.setState({ isDrawing: true });
    const canvas = this.canvasRef.current;
    console.log(canvas);

    if (!canvas) return;
    let context: any = this.state.context;
    context = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    if (!context) return;

    const { clientX, clientY } = event.nativeEvent.changedTouches[0];

    this.setState({ signLastX: clientX - rect.left, signLastY: clientY - 111 });
  };

  handleTouchMove = (event: React.TouchEvent<HTMLCanvasElement>) => {
    this.setState({ signatureImageError: "" });

    if (!this.state.isDrawing) return;
    const canvas = this.canvasRef.current;
    let context: any = this.state.context;
    if (!canvas || !context) return;

    const { clientX, clientY } = event.nativeEvent.changedTouches[0];
    const rect = canvas.getBoundingClientRect();
    // const x = touch.clientX - rect.left;
    // const y = touch.clientY - rect.top;

    // Draw a line segment from the last recorded point to the current point
    context.strokeStyle = "#000"; // Stroke color
    context.lineWidth = 2; // Stroke width
    context.beginPath();
    context.moveTo(this.state.signLastX, this.state.signLastY);
    context.lineTo(clientX - rect.left, clientY - rect.top);
    context.stroke();
    this.setState({
      signLastX: clientX - rect.left,
      signLastY: clientY - rect.top,
    });
  };

  handleTouchEnd = () => {
    this.setState({ signatureImageError: "" });

    this.setState({ isDrawing: false });
  };

  handleClear = () => {
    const canvas = this.canvasRef.current;
    let context: any = this.state.context;
    if (!canvas || !context) return;

    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  handleConvertToImage = () => {
    const canvas = this.canvasRef.current;
    if (!canvas) return;

    const image: string = canvas.toDataURL("image/png");

    if (
      image !==
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACWCAYAAABkW7XSAAAAAXNSR0IArs4c6QAABGJJREFUeF7t1AEJAAAMAsHZv/RyPNwSyDncOQIECEQEFskpJgECBM5geQICBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAgQdWMQCX4yW9owAAAABJRU5ErkJggg=="
    ) {
      this.setState({ signatureImage: image, signatureModal: false });
    }
  };

  render(): React.ReactNode {
    return (
      <div>
        <Formik
          initialValues={{
            client: this.props.data ? this.props.data.client_id : "",
            branch: this.props.data ? this.props.data.branch_id : "",
            title: this.props.data ? this.props.data.title : "",
            status: this.props.data ? this.props.data?.status : "",
            attachments: this.props.data ? this.props.data.attachments : "",
            cleaners: this.props.data
              ? this.props.data.cleaners.map((val: any) => val.cleaner_id)
              : "",
            task_type: this.props.data ? this.props.data.task_type : "",
            description: this.props.data ? this.props.data.description : "",
            any_time: this.props.data
              ? this.props.data.any_time.toString()
              : "",
            start_time: this.props.data
              ? this.props.data.start_time === null
                ? "00:00:00"
                : this.props.data.start_time
              : "",
            end_time: this.props.data
              ? this.props.data.end_time === null
                ? "00:00:00"
                : this.props.data.end_time
              : "",
            ref_task_id: this.props.data ? this.props.data.ref_task_id : "",
            task_with_sign_in: this.props.data
              ? this.props.data.task_with_sign_in
              : "",
            priority: this.props.data ? this.props.data.priority : "",
            everyWeek: [],
            selectDate: [],
          }}
          enableReinitialize={true}
          onSubmit={this.handleEditTask}
          validationSchema={this.validationSchema}
        >
          {(props) => {
            const { values, errors, touched } = props;

            return (
              <Form onChange={this.handleChange}>
                <div className="mb-2">
                  <label className="form-label">
                    {this.props.makeScheduleDailyTaskStatus === true
                      ? "Schedule Name"
                      : "Task Name"}
                    <span className="text-project">*</span>
                  </label>
                  <Field
                    name="title"
                    type={"text"}
                    className="form-control text-field"
                  />
                  <ErrorMessage
                    name="title"
                    component={"div"}
                    className="cus-alert"
                  />
                </div>
                {this.props.makeScheduleDailyTaskStatus !== true && (
                  <div>
                    <div className="mb-2">
                      <label className="form-label">
                        Task Description<span className="text-project">*</span>
                      </label>
                      <Field
                        as="textarea"
                        name="description"
                        className="form-control text-area"
                      />
                      <ErrorMessage
                        name="description"
                        component={"div"}
                        className="cus-alert"
                      />
                    </div>
                    {this.props.copyDailyTaskStatus !== true && (
                      <>
                        <div>
                          <label className="form-label">Attachment</label>
                          <div className="attachment-btn dropdown-field">
                            <input
                              type="file"
                              multiple
                              accept=".pdf,.PDF,.mp4,.MP4,.webm,.WEBM,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.doc,.DOC,.docx.DOCX"
                              style={{ display: "none" }}
                              onChange={this.handleImageChange}
                              id="contained-button-file"
                            />
                            <label htmlFor="contained-button-file">
                              <Button
                                variant="contained"
                                color="default"
                                component="span"
                              >
                                <PaperClipSVG /> Add attachment
                              </Button>
                            </label>
                          </div>
                        </div>
                        {this.state.attachmentUrls.length > 0 ? (
                          <div className="mb-2">
                            <label className="form-label">Attachments</label>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="row">
                          <div className=" mb-3 d-flex row">
                            {this.state.attachmentUrls &&
                              this.state.attachmentUrls.map(
                                (obj: any, index: number) => {
                                  if (
                                    obj.contractor_attachment_type !==
                                    "sp_task_signature"
                                  ) {
                                    return (
                                      <div
                                        className="img-card mr-16px mb-3"
                                        key={index}
                                      >
                                        <span className="delete">
                                          <a
                                            onClick={() =>
                                              this.handleDeleteImage(index)
                                            }
                                          >
                                            <TrashSVG />
                                          </a>
                                        </span>
                                        {obj.type === "download" && (
                                          <span className="download">
                                            <a
                                              href="javascript:void(0)"
                                              onClick={() =>
                                                this.downloadFile(
                                                  obj.download_url,
                                                  obj.file_name
                                                )
                                              }
                                            >
                                              <AttachmentDownloadSVG />
                                            </a>
                                          </span>
                                        )}

                                        <div
                                          className="img-preview"
                                          onClick={() =>
                                            this.handlePreviewOpen(
                                              obj.file_type,
                                              obj.download_url,
                                              index
                                            )
                                          }
                                        >
                                          <img
                                            className="img-thumb-perview"
                                            src={
                                              obj.file_type ===
                                              "application/pdf"
                                                ? pdfImage
                                                : obj.file_type ===
                                                    "video/mp4" ||
                                                  obj.file_type === "video/webm"
                                                ? videoImage
                                                : obj.file_type ===
                                                    "image/png" ||
                                                  obj.file_type ===
                                                    "image/jpeg" ||
                                                  obj.file_type === "image/jpg"
                                                ? obj.url
                                                : docImage
                                            }
                                            alt="attach"
                                          />
                                        </div>
                                      </div>
                                    );
                                  }
                                }
                              )}

                            {/* <AttachmentPreview
                              displayPreview={
                                this.state.AttachmentPreviewStatus
                              }
                              previewUrl={this.state.previewUrl}
                              previewType={this.state.previewType}
                              handlePreviewClose={this.handlePreviewClose}
                            /> */}
                            <ImageDrawing
                              displayPreview={
                                this.state.AttachmentPreviewStatus
                              }
                              previewUrl={this.state.previewUrl}
                              previewType={this.state.previewType}
                              handlePreviewClose={this.handlePreviewClose}
                              clickingImage={this.state.file_count}
                              attachUrlArray={this.state.attachmentUrls}
                              attachFiles={this.state.attachmentUrls}
                              page="edit"
                              deleteProps={this.props.deleteAttachementId}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
                {/* {Constants.QAUTINT !== Constants.LOGIN_USER_TYPE &&
                  Constants.QA_MANAGERUTINT !== Constants.LOGIN_USER_TYPE &&
                  Constants.MANAGERUTINT !== Constants.LOGIN_USER_TYPE && ( */}
                <div className="row">
                  {/* {Constants.SUPERVISOR_MANAGERUTINT !==
                      Constants.LOGIN_USER_TYPE ? ( */}
                  <div className="mb-2 col-md-6">
                    <label className="form-label">
                      Client<span className="text-project">*</span>
                    </label>
                    <Field
                      as="select"
                      className="form-select dropdown-field"
                      name="client"
                    >
                      {this.props.clientList.map((client: ICleaner) => (
                        <option
                          value={client.id.toString()}
                          key={client.id}
                        >{`${client.first_name} ${client.last_name}`}</option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="client"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                  {/* ) : null} */}
                  <div className="mb-2 col-md-6">
                    <label className="form-label">
                      Branch<span className="text-project">*</span>
                    </label>
                    <Field
                      className="custom-select dropdown-field"
                      name="branch"
                      options={this.props.branchList}
                      component={CustomSelect}
                      placeholder="Select Branch"
                      isMulti={false}
                    />
                    <ErrorMessage
                      name="branch"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                  {this.props.userLoginDetails?.permission
                    ?.task_view_cleaner === 1 &&
                    this.props.copyDailyTaskStatus !== true && (
                      <div className="mb-2 col-md-12">
                        <label className="form-label">
                          Cleaner<span className="text-project">*</span>
                        </label>
                        <Field
                          className="custom-select"
                          name="cleaners"
                          options={this.props.cleanerList}
                          component={CustomSelect}
                          placeholder="Select Cleaner"
                          isMulti={true}
                        />
                        <ErrorMessage
                          name="cleaners"
                          component={"div"}
                          className="cus-alert"
                        />
                      </div>
                    )}
                </div>
                <div className="row">
                  {/* {Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
                    (Constants.QA_MANAGERUTINT ===
                      Constants.LOGIN_USER_TYPE && (
                      <div className="mb-2 col-md-6">
                        <label className="form-label">
                          Branch<span className="text-project">*</span>
                        </label>
                        <Field
                          className="custom-select dropdown-field"
                          name="branch"
                          options={this.props.branchList}
                          component={CustomSelect}
                          placeholder="Select Branch"
                          isMulti={false}
                        />
                        <ErrorMessage
                          name="branch"
                          component={"div"}
                          className="cus-alert"
                        />
                      </div>
                    ))} */}
                  <div className="mb-2 col-md-6">
                    <label className="form-label">
                      {this.props.makeScheduleDailyTaskStatus === true
                        ? "Schedule End"
                        : ""}{" "}
                      Date<span className="text-project">*</span>
                    </label>
                    {/* <div
                      className="startdate"
                      onClick={() => this.handleStartDateClick()}
                    >
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        id="startDate"
                      >
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            inputFormat="DD/MM/YYYY"
                            value={this.state.task_date}
                            onChange={this.handleTaskDate}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div> */}
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      name="task_date"
                      value={this.state.task_date}
                      onChange={async (e: any) => {
                        await this.setState({ task_date: e });
                      }}
                    />
                    <ErrorMessage
                      name="task_date"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                  {Constants.ADMIN === Constants.LOGIN_USER_TYPE &&
                    Constants.SUPERVISORUTINT === Constants.LOGIN_USER_TYPE && (
                      <div className="mb-2 col-md-6">
                        <label className="form-label">
                          Task type<span className="text-project">*</span>
                        </label>
                        <Field
                          as="select"
                          className="form-select dropdown-field"
                          name="task_type"
                        >
                          <option value={Constants.REGULAR_TASK}>
                            Regular
                          </option>
                          <option value={Constants.SPECIAL_REQUEST}>
                            Special type
                          </option>
                        </Field>
                        <ErrorMessage
                          name="task_type"
                          component={"div"}
                          className="cus-alert"
                        />
                      </div>
                    )}

                  {this.props.makeScheduleDailyTaskStatus === true && (
                    <div>
                      <label className="form-label">
                        Date<span className="text-project">*</span>
                      </label>
                      <div className="d-flex mt-2">
                        <div className="">
                          <FormCheck.Check
                            inline
                            label="Every month"
                            name="schedule_datetype"
                            onClick={() =>
                              this.setState({
                                ScheduleDateType: "every_month_days",
                                selectDateErrorAlert: false,
                              })
                            }
                            type={"radio"}
                          />
                        </div>
                        <div className="schedule-select-date">
                          <Field
                            className="custom-select"
                            name="selectDate"
                            options={EveryMonth}
                            component={CustomSelect}
                            placeholder="Select Date"
                            isMulti={true}
                            // onChange={() => console.log("ee")}
                          />
                        </div>
                        <div className="">
                          <label className="form-label">Day</label>
                        </div>
                      </div>
                      <ErrorMessage
                        name="selectDate"
                        component={"div"}
                        className="cus-alert"
                      />
                      <div className="d-flex mt-3">
                        <div className="">
                          <FormCheck.Check
                            inline
                            label="Specific dates"
                            name="schedule_datetype"
                            onClick={() =>
                              this.setState({
                                ScheduleDateType: "specific_dates",
                                selectDateErrorAlert: false,
                              })
                            }
                            type={"radio"}
                          />
                        </div>
                        <div className="schedule-specific-date">
                          <MulitipleDatePicker
                            multiple
                            format="DD/MM/YYYY"
                            placeholder="DD/MM/YYYY"
                            value={this.state.specificDate}
                            onChange={(e: any) => {
                              this.setState({ specificDate: e });
                            }}
                          />
                        </div>
                      </div>
                      <div className="d-flex mt-3">
                        <div className="">
                          <FormCheck.Check
                            inline
                            label="Every"
                            name="schedule_datetype"
                            onClick={() =>
                              this.setState({
                                ScheduleDateType: "every_week",
                                selectDateErrorAlert: false,
                              })
                            }
                            type={"radio"}
                          />
                        </div>
                        <div className="schedule-every-select-day">
                          <Field
                            className="custom-select"
                            name="everyWeek"
                            options={EveryWeekDays}
                            component={CustomSelect}
                            placeholder="Select Day"
                            isMulti={true}
                            onChange={() => console.log("ee")}
                          ></Field>
                          <ErrorMessage
                            name="everyWeek"
                            component={"div"}
                            className="cus-alert"
                          />
                        </div>
                      </div>
                      <div className="d-flex mt-3">
                        <div className="">
                          <FormCheck.Check
                            inline
                            label="Start date"
                            name="schedule_datetype"
                            onClick={() =>
                              this.setState({
                                ScheduleDateType: "date_to_date",
                                selectDateErrorAlert: false,
                              })
                            }
                            type={"radio"}
                          />
                        </div>
                        <div className="schedule-start-date">
                          <div
                            className="start"
                            // onClick={() => this.handleStart()}
                          >
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              id="startDate"
                            >
                              <Stack component={"form"} noValidate spacing={3}>
                                <DesktopDatePicker
                                  inputFormat="DD/MM/YYYY"
                                  value={this.state.startSchedule}
                                  onChange={(e) =>
                                    this.setState({ startSchedule: e })
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  disablePast={true}
                                  closeOnSelect={true}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </div>
                          <ErrorMessage
                            name="startDate"
                            component={"div"}
                            className="cus-alert"
                          />
                        </div>
                        <div className="">
                          <label className="form-label">End date</label>
                        </div>
                        <div className="schedule-end-date">
                          <div
                            className="end"
                            // onClick={() => this.handleEnd()}
                          >
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              id="endDate"
                            >
                              <Stack component={"form"} noValidate spacing={3}>
                                <DesktopDatePicker
                                  inputFormat="DD/MM/YYYY"
                                  // maxDate={this.state.endDatevalue}
                                  value={this.state.endSchedule}
                                  onChange={(e) => {
                                    this.setState({ endSchedule: e });
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  closeOnSelect={true}
                                  disablePast={true}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </div>
                          <ErrorMessage
                            name="endDate"
                            component={"div"}
                            className="cus-alert"
                          />
                        </div>
                      </div>
                      <ErrorMessage
                        name="group1"
                        component={"div"}
                        className="cus-alert"
                      />
                      <div
                        className={
                          this.state.selectDateErrorAlert === true
                            ? "cus-alert"
                            : "cus-alert d-none"
                        }
                        style={{ color: "red !important" }}
                      >
                        Select a date
                      </div>
                    </div>
                  )}
                  <div className="mb-2 col-md-12 edit-task-time">
                    <label className="form-label">
                      Time<span className="text-project">*</span>
                    </label>
                    <RadioButtonGroup
                      id="radioGroup"
                      label=""
                      value={values.any_time}
                      error={errors.any_time}
                      touched={touched.any_time}
                    >
                      <Field
                        component={RadioButton}
                        name="any_time"
                        id="1"
                        label="Any time"
                      />
                      <Field
                        component={RadioButton}
                        name="any_time"
                        id="0"
                        label=""
                      />
                    </RadioButtonGroup>
                    <div className="edit-task-start-end">
                      <label className="check-time">
                        <span className="check-left-space d-flex">
                          <div className="mr-8px">Start</div>
                          <TimePicker
                            name="start_time"
                            value={values.start_time}
                            onChange={this.handleStartTime}
                          />
                        </span>
                      </label>
                      <label
                        className="check-time"
                        style={{ marginLeft: "16px" }}
                      >
                        <span className="d-flex">
                          <div className="mr-8px">End</div>
                          <TimePicker
                            name="end_time"
                            value={values.end_time}
                            onChange={this.handleEndTime}
                          />
                        </span>
                      </label>
                    </div>
                  </div>

                  <div className="mt-2 mb-2 col-md-6">
                    <label className="form-label">
                      Task with sign in
                      <span className="text-project">*</span>
                    </label>
                    <Field
                      className="custom-select"
                      name="task_with_sign_in"
                      options={[
                        {
                          value: Constants.TASK_WITH_SIGN_IN_YES,
                          label: "Task with sign in",
                        },
                        {
                          value: Constants.TASK_WITH_SIGN_IN_NO,
                          label: "Task without sign in",
                        },
                      ]}
                      component={CustomSelect}
                      isMulti={false}
                    />
                    <ErrorMessage
                      name="task_with_sign_in"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>

                  <div className="mb-2 col-md-6">
                    <label className="form-label">
                      Task priority<span className="text-project">*</span>
                    </label>
                    <Field
                      className="custom-select"
                      name="priority"
                      options={[
                        { value: TASK_PRIORITY.LOW, label: "Low" },
                        { value: TASK_PRIORITY.MEDIUM, label: "Medium" },
                        { value: TASK_PRIORITY.HIGH, label: "High" },
                      ]}
                      component={CustomSelect}
                      isMulti={false}
                    />
                    <ErrorMessage
                      name="priority"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>

                  <div className="mb-2 col-md-6">
                    <label className="form-label">
                      Is Active<span className="text-project">*</span>
                    </label>
                    <Field
                      className="custom-select"
                      name="status"
                      options={[
                        { value: Constants.ENABLE, label: "Enable" },
                        { value: Constants.DISABLE, label: "Disable" },
                      ]}
                      component={CustomSelect}
                      isMulti={false}
                    />
                    <ErrorMessage
                      name="status"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                </div>
                {/* {this.props.addSpecialRequestTaskStatus === true && ( */}
                {/* <div className="row mt-3 mb-3">
                  <label className="form-label">
                    Signature<span className="text-project">*</span>
                  </label>
                  {this.state.signState === false ? (
                    <div className=" mb-3 d-flex row">
                      {this.state.signatureImage &&
                        this.state.signatureImage.map(
                          (obj: any, index: number) => {
                            if (
                              obj.contractor_attachment_type ===
                              "sp_task_signature"
                            ) {
                              return (
                                <div
                                  className="img-card mr-16px mb-3"
                                  key={index}
                                >
                                  <img
                                    className="img-thumb-perview"
                                    src={
                                      obj.file_type === "image/png" ||
                                      obj.file_type === "image/jpeg" ||
                                      obj.file_type === "image/jpg"
                                        ? obj.url
                                        : docImage
                                    }
                                    alt="attach"
                                  />
                                </div>
                              );
                            }
                          }
                        )}
                    </div>
                  ) : (
                    <div>
                      {this.state.signatureImage !== "" && (
                        <img src={this.state.signatureImage} alt="" />
                      )}
                    </div>
                  )}

                  <button
                    type="button"
                    id="create-button"
                    className="btn btn-primary create-button"
                    style={{ width: "40%" }}
                    onClick={() => this.handleSignModal()}
                  >
                    Add
                  </button>
                </div> */}
                {/* )} */}
                <div className="row mt-3">
                  <div className="col-md-12 cus-progress">
                    <Modal
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      show={this.props.processTrigger}
                    >
                      <Modal.Body className="p-0">
                        <ProgressBar
                          now={this.props.progressPercentage}
                          label={
                            this.props.progressPercentage === 100
                              ? `Processing your request...`
                              : `Task Adding....${this.props.progressPercentage}%`
                          }
                        />
                      </Modal.Body>
                    </Modal>
                    <div className="d-flex">
                      <div className="mr-16px">
                        <button
                          type="submit"
                          id="create-button"
                          className="btn btn-primary create-button"
                        >
                          {this.props.copyDailyTaskStatus === true
                            ? "Copy"
                            : "Update"}{" "}
                          <span
                            id="spinner-border"
                            className="spinner-border spinner-border-sm d-none"
                          ></span>
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn cancel-button"
                          onClick={() => {
                            this.props.EditModalDailyTask(false);
                            this.props.updateEditDailyTaskDlgStatus(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <Modal
                  size="lg"
                  show={this.state.signatureModal}
                  onHide={() => this.setState({ signatureModal: false })}
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                      <h3 className="m-0">Add signature</h3>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="p-4">
                    <div className="signature-pad">
                      <canvas
                        ref={this.canvasRef}
                        style={{ width: "100% !important" }}
                        onMouseDown={this.handleMouseDown}
                        onMouseMove={this.handleMouseMove}
                        onMouseUp={this.handleMouseUp}
                        onTouchStart={this.handleTouchStart}
                        onTouchEnd={this.handleTouchEnd}
                        onTouchMove={this.handleTouchMove}
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.handleClear}
                    >
                      Clear
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      id="add-sign-image"
                      style={{ marginLeft: "10px" }}
                      onClick={this.handleConvertToImage}
                    >
                      Add
                    </button>
                  </Modal.Footer>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  clientList: state.tasktemplate.clientList,
  branchList: state.tasktemplate.branchList,
  userLoginDetails: state.sidebar.userLoginDetails,
  cleanerList: state.tasktemplate.cleanerList,
  sendMailUsers: state.dailytask.sendMailUsers,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    listAllClient: bindActionCreators(listAllClient, dispatch),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
    deleteAttachementId: bindActionCreators(deleteAttachementId, dispatch),
    listAllCleaner: bindActionCreators(listAllCleaner, dispatch),
    editDailyTask: bindActionCreators(editDailyTask, dispatch),
    copyDailyTask: bindActionCreators(copyDailyTask, dispatch),
    EditModalDailyTask: bindActionCreators(EditModalDailyTask, dispatch),
    makeScheduleDailyTask: bindActionCreators(makeScheduleDailyTask, dispatch),
    addSchedule: bindActionCreators(addSchedule, dispatch),
    updateEditDailyTaskDlgStatus: bindActionCreators(
      updateEditDailyTaskDlgStatus,
      dispatch
    ),
    updateDailyTaskFetchStatus: bindActionCreators(
      updateDailyTaskFetchStatus,
      dispatch
    ),
    sendQAEmailDailyTask: bindActionCreators(sendQAEmailDailyTask, dispatch),
    getSendmailUsers: bindActionCreators(getSendmailUsers, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditDailyTask);
