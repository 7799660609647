import React from "react";
import { Navigate } from "react-router-dom";
import authService from "../../services/auth.service";
import { IActivateAccState } from "../../types/user.type";
import { RootState } from '../../index';
import { connect } from "react-redux";
import { VerifiedSVG } from "../../assets/images/VerifyEmail";
import { Dispatch } from "redux";
import Constants from "../../shared/Constants";

class ActivateAcc extends React.Component<any, IActivateAccState>{
    constructor(props: any) {
        super(props);
        this.state = {
            redirect: null,
            message: "",
            loginstatus:false
        }
    }

    componentDidMount(): void {
        const path = window.location.search;
        const [part1, part2, part3] = (path.split('?'))[1].split('&')
        const token = part1.split('=')[1];
        const id = part2.split('=')[1];
        let type:any = part3.split('=')[1];
        type = parseInt(type);
        this.activateAcc(id, token, type);
    }

    activateAcc = (id: string, x_auth_token: string, type: number) => {
        this.setState({
            message: ""
        })
        authService.activateAcc(id, x_auth_token).then(
            () => {

console.log(Constants.CLEANERUTINT , type);

if(Constants.CLEANERUTINT === type){
    setTimeout(() => {
        this.setState({
            redirect: "/cleaner-certificate?x-auth="+x_auth_token+"&id="+id
        })
    }, 3000);
}else{
this.setState({ loginstatus: true });
}
                
                
            },
            error => {
                const resMessage = (
                    error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();

                this.setState({
                    message: resMessage
                });
            }
        );
    }
    
    handleLogin = () => {
        window.location.href = "/"
    }
    render(): React.ReactNode {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <div className="bg-grey">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 info-card">
                            <div className="card align-items-center">
                                <div className="card-body p-5">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <h2 className="text-green fs-27 lh-27px">Clean Task</h2>
                                            <div className="mt-4"><VerifiedSVG /></div>
                                            {/* <p className="mt-4 mb-2 d-none">Hi Nithish Amstrong</p> */}
                                            <p className="mb-2 fw-700">Your account has been successfully activated!</p>
                                            {
                                                this.state.loginstatus === true && (
                                                    <>
                                                    <p className="mb-2">You can log in to your account!</p>
                                            <button className="btn btn-primary login-button mt-4" type="button" onClick={this.handleLogin}>Log in</button>
                                            </>
                                                )
                                            }
                                            {/* <p className="mb-2">You can log in to your account!</p>
                                            <button className="btn btn-primary login-button mt-4" type="button" onClick={this.handleLogin}>Log in</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state: RootState) => ({
    // selectedEmployee: state.employee.selectedEmployee
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        // selectEmployee: bindActionCreators(selectEmployee, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivateAcc);