import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Dispatch, bindActionCreators } from "redux";
import React, { Component } from "react";

import { InvoiceCardProps } from "../../types/invoice.type";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { updateViewInvoice } from "../../shared/Reducers/InvoiceReducer";

class InvoiceCard extends Component<InvoiceCardProps> {
  render() {
    const { id, email, cleaner_name, client_name, branch_name, hours, shift } =
      this.props.invoice_report;
    return (
      <tr>
        <td>{cleaner_name}</td>
        <td>{client_name}</td>
        <td>{branch_name}</td>
        <td>{email}</td>
        <td>{hours}</td>
        <td>{shift}</td>
        <td>
          <span className="mr-20px">
            <a
              href="javascript:void(0)"
              onClick={() => this.props.updateViewInvoice(true)}
            >
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{"History"}</Tooltip>}
              >
                <Button
                  className="btn btn-secondary btn-cleaner-count fs-12px"
                  onClick={() => this.props.handleViewInvoice(id)}
                >
                  Invoice
                </Button>
              </OverlayTrigger>
            </a>
          </span>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateViewInvoice: bindActionCreators(updateViewInvoice, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceCard);
