import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Dispatch, bindActionCreators } from "redux";
import DeleteNotification from "./DeleteNotification";
import { Navigate } from "react-router-dom";
import React from "react";
import { RootState } from "../../index";
import { TableDeleteSVG } from "../../assets/images/ImagesV2";
import { connect } from "react-redux";
import { deleteComments } from "../../shared/Reducers";
import { getTaskHistoryById, allTaskNotification } from "../../shared/Reducers";
import moment from "moment";
import Constants from "../../shared/Constants";

export interface ITaskCardProps {
  tasks: any;
  taskCount: number;
  getTaskHistoryById?: any;
  delete_Notification: any;
  deleteComments: any;
  allTaskNotification: any
  deleteCommentmessage: any
  deleteCommentStatus: any
}
export interface ITaskCardState {
  redirect: string;
  displayDelete: boolean;
  currentDeleteId: string;
}

class TaskCard extends React.Component<ITaskCardProps, ITaskCardState> {
  constructor(props: ITaskCardProps) {
    super(props);
    this.state = {
      redirect: "",
      displayDelete: false,
      currentDeleteId: "",
    };
  }


  handleViewTaskDetails = (id: number) => {
    console.log(this.props.allTaskNotification,"task notification");
    
    this.setState({ redirect: "/task" });
    this.props.getTaskHistoryById(id);
  };

  handleDelete = (id: string) => {
    console.log(id, "handleDelete");

    this.setState({ displayDelete: true, currentDeleteId: id });
  }

  // handleDeleteClose = (status: boolean) => {
  //   this.setState({ displayDelete: false, currentDeleteId: "" });
  //   this.props.deleteComments(this.state.currentDeleteId);
  //   if (this.props.deleteCommentmessage === "Successfully deleted") {
  //     let query = `notification?search=&sort=&order=&offset=${0}&limit=${10}`;
  //     this.props.allTaskNotification(query);
  //   }
  // };

  handleDeleteClose = (status: boolean) => {
    this.setState({ displayDelete: false, currentDeleteId: "" });
    if(status) {
      this.props.deleteComments(this.state.currentDeleteId);
      let query = `notification?search=&sort=&order=&offset=${0}&limit=${10}`;
      this.props.allTaskNotification(query)
    }
  };

  render(): React.ReactNode {
    // Navigate to Home
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    const {
      created_at,
      task_name,
      first_name,
      last_name,
      branch_name,
      title,
      description,
      id,
      reference_id
    } = this.props.tasks;
    
    return (
      <>
        <div>
          <div className="card mb-3 cursor-pointer">
            <div className="card-body pl-pr-25px">
              <div className="row">
                <div
                  className="col-md-11"
                  onClick={() => this.handleViewTaskDetails(reference_id)}
                >
                  {" "}
                  {/* ${first_name} ${last_name}  */}
                  <p className="mb-1">
                    <span className="fw-600">{` ${first_name} ${last_name} `}</span>
                    {`${title}  `}
                    {
                      task_name !== null && task_name !== undefined && (
                        <>
                        {/* task <small className='fw-600 fs-12px'>{task_name}</small> */}
                        {task_name === "completed" || task_name === "resolved" ? (
                          <span>
                          task has been <small className="fw-600 fs-12px">{task_name}</small>
                          </span>
                        ): task_name === "incompleted" || task_name === "followup" ?(
                          <span>
                            task was <small className="fw-600 fs-12px">{task_name}</small>
                          </span>
                        ): null}
                        </>
                      )
                    }
                    {` in the branch ${branch_name}`}
                  </p>
                  <p className="mb-0 fs-12">
                    {moment(created_at).format("MMM D[,] LT")}
                  </p>
                </div>
                <div className="col-md-1 align-self-center text-end">
                  <Button
                    className="action-btn"
                    onClick={() => this.handleDelete(id)}
                  >
                    <TableDeleteSVG />
                  </Button>
                </div>
                <DeleteNotification
                  displayDelete={this.state.displayDelete}
                  handleDeleteClose={this.handleDeleteClose}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  deleteCommentmessage: state.app.deleteCommentmessage,
  deleteCommentStatus: state.app.deleteCommentStatus
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getTaskHistoryById: bindActionCreators(getTaskHistoryById, dispatch),
    deleteComments: bindActionCreators(deleteComments, dispatch),
    allTaskNotification: bindActionCreators(allTaskNotification, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskCard);
