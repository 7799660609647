import { Button, Offcanvas } from "react-bootstrap";
import { Dispatch, bindActionCreators } from "redux";
import { InvoiceProps, InvoiceState } from "../../types/invoice.type";
import {
  Invoice_Report_ById,
  exportinvoice,
  getAllInvoiceReport,
  handleFilterInvoiceBranch,
  handleFilterInvoiceClient,
  listAllBranch_Invoice,
  listAllClient_Invoice,
  updateExportInvoice,
  updateInvoiceFromDate,
  updateInvoiceToDate,
  updateViewInvoice,
} from "../../shared/Reducers/InvoiceReducer";
import React, { Component } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Constants from "../../shared/Constants";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import InvoiceReportTable from "./InvoiceExport";
import InvoiceTable from "./InvoiceTable";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { RootState } from "../../index";
import { SearchSVG } from "../../assets/images/UsermanagementsSVG";
import Select from "react-select";
import SideBar from "../../components/sidebar/SideBar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import TopNav from "../../components/topnav/TopNav";
import { connect } from "react-redux";
import moment from "moment";
import { updateSidebar } from "../../shared/Reducers";

const reactSelectCusStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#fff",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    },
  }),
};

class Invoice extends Component<InvoiceProps, InvoiceState> {
  constructor(props: InvoiceProps) {
    super(props);
    this.state = {
      show_FilterOption: false,
      invoice_from_date: "",
      invoice_to_date: "",
      invoice_status: "all",
      invoice_client: "all",
      invoice_branch: "all",
      Search_Invoice: "",
      timeout: null,
      final_amount: [],
      subTotal: 0,
      gst_amount: 0,
    };
  }

  componentDidMount(): void {
    this.props.updateSidebar(true);
    this.props.listAllClient_Invoice();
    this.props.listAllBranch_Invoice(0);
    this.getInvoice_Report(Constants.OFFSET, Constants.PER_PAGE);
    // this.props.Invoice_Report_ById(123,"all","all");
    this.setState({ final_amount: this.props.invoiceReportdata });
  }

  componentDidUpdate(
    prevProps: Readonly<InvoiceProps>,
    prevState: Readonly<InvoiceState>,
    snapshot?: any
  ): void {
    if (prevState.invoice_client != this.state.invoice_client) {
      this.getInvoice_Report(Constants.OFFSET, Constants.PER_PAGE);
    } else if (prevState.invoice_branch != this.state.invoice_branch) {
      this.getInvoice_Report(Constants.OFFSET, Constants.PER_PAGE);
    } else if (prevProps.invoice_from_date != this.props.invoice_from_date) {
      this.getInvoice_Report(Constants.OFFSET, Constants.PER_PAGE);
    } else if (prevProps.invoice_to_date != this.props.invoice_to_date) {
      this.getInvoice_Report(Constants.OFFSET, Constants.PER_PAGE);
    } else if (prevState.invoice_status != this.state.invoice_status) {
      this.getInvoice_Report(Constants.OFFSET, Constants.PER_PAGE);
    }
  }

  handleInvoiceFilter = async (status: boolean) => {
    this.setState({
      show_FilterOption: status,
    });
  };

  handleInvoiceStartDate = (val: any) => {
    this.setState({ invoice_from_date: val });
  };

  handleInvoiceToDate = (val: any) => {
    this.setState({ invoice_to_date: val });
  };

  handleInvoiceStatus = (status: any) => {
    this.setState({ invoice_status: status });
  };
  handleFilter_InvoiceClient = (data: any) => {
    let arrayId: any = [];
    arrayId.push(data.map((client: any) => client.value));
    let client_Id: string = arrayId.join();
    if (client_Id === "") {
      this.setState({ invoice_client: "all" }, () => {
        this.props.listAllBranch_Invoice(0);
      });
    } else {
      this.setState({ invoice_client: client_Id }, () => {
        this.props.listAllBranch_Invoice(client_Id);
      });
    }
  };

  handleFilter_InvoiceBranch = async (data: any) => {
    let arrayId: any = [];
    arrayId.push(data.map((clients: any) => clients.value));
    let branchIds: string = arrayId.join();
    if (branchIds === "") {
      this.setState({ invoice_branch: "all" });
    } else {
      this.setState({ invoice_branch: branchIds });
    }
  };

  handlePageClick_Invoice = (e: any) => {
    if (e.selected < this.props.allInvoice_Data / Constants.PER_PAGE) {
      this.getInvoice_Report(
        e.selected * Constants.PER_PAGE,
        Constants.PER_PAGE
      );
    }
  };

  handleInvoiceStartDateclick = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let startDate: HTMLElement = document.querySelector(
        ".startDate button.MuiButtonBase-root"
      ) as HTMLElement;
      startDate.click();
    }
  };

  handleInvoiceEndDateclick = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let endDate: HTMLElement = document.querySelector(
        ".endDate button.MuiButtonBase-root"
      ) as HTMLElement;
      endDate.click();
    }
  };

  handleSearchInvoice = (e: any) => {
    const value: string = e.target.value.toLowerCase();
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

    this.setState({
      Search_Invoice: value,
      timeout: setTimeout(async () => {
        try {
          const query = `search=${encodeURIComponent(this.state.Search_Invoice)}&sort=&order=&offset=${Constants.OFFSET}&limit=${Constants.PER_PAGE}&client=${this.state.invoice_client}&branch=${this.state.invoice_branch}&status=${this.state.invoice_status}&from_date=${this.props.invoice_from_date}&to_date=${this.props.invoice_to_date}`;
          await this.props.getAllInvoiceReport(query);
        } catch (error) {
          console.log(error);
        }
        // Trigger your function here that you want to call when user stops typing
      }, 1000), // Set the delay time in ms
    });
  };

  getInvoice_Report = async (offset: number, limit: number) => {
    try {
      console.log("client ", this.state.invoice_client);
      
      let client = Array.isArray(this.state.invoice_client)  ? this.state.invoice_client[0].value : this.state.invoice_client
      let branch = Array.isArray(this.state.invoice_branch) ? this.state.invoice_branch[0].value :this.state.invoice_branch
      const query = `search=&sort=&order=&offset=${offset}&limit=${limit}&client=${client}&branch=${branch}&status=${this.state.invoice_status}&from_date=${this.props.invoice_from_date}&to_date=${this.props.invoice_to_date}`;
      await this.props.getAllInvoiceReport(query);
    } catch (error) {
      console.log(error);
    }
  };

  handlePageClick_Complaint = (e: any) => {
    if (e.selected < this.props.totalInvoiceCount / Constants.PER_PAGE) {
      const query = `search=${this.state.Search_Invoice}&sort=&order=&offset=${e.selected * Constants.PER_PAGE}&limit=${Constants.PER_PAGE}&client=all`;
      this.props.getAllInvoiceReport(query);
    }
  }
  render() {
    console.log(this.state.invoice_client, "invoice", this.state.invoice_branch);

    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className={this.props.status ? "main-panel" : "main-panel2"}>
          <TopNav />
          <div className="content-wrapper">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="mb-0"> Invoice </h3>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-end">
                    <div className="clearfix">
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12 col-lg-6">
                    <div className="input-group search">
                      <span
                        className="input-group-text search-icon"
                        id="basic-addon1"
                      >
                        <SearchSVG />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        value={this.state.Search_Invoice}
                        onInput={this.handleSearchInvoice}
                        onChange={(e) => this.setState({
                          Search_Invoice: e.target.value
                        })}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6 text-start text-lg-end tab-res-mt-3">
                    <Button
                      aria-controls="offcanvasRight"
                      className="btn btn-primary filter-button res-mt-3"
                      onClick={() => this.handleInvoiceFilter(true)}
                    >
                      <span>Filter</span>
                    </Button>
                  </div>
                </div>
                {/* <div className="d-flex justify-content-start justify-content-md-end res-mt-3">

                    <div style={{ marginLeft: "10px" }}>

                    </div>
                  </div> */}
                <Offcanvas
                  className="task-offcanvas filter-canvas"
                  placement="end"
                  show={this.state.show_FilterOption}
                  onHide={() => this.handleInvoiceFilter(false)}
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                      <span className="offc-title">Invoice Filter</span>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <div className="row mt-4">
                      <div className="col-md-6 res-mt-3">
                        <label className="mb-2">Select Client</label>
                        <Select
                          placeholder="Select Client"
                          styles={reactSelectCusStyles}
                          isMulti={true}
                          value={this.state.invoice_client}
                          options={this.props.clientList_Invoice.map(
                            (client: any) => ({
                              value: client.id,
                              label:
                                client.first_name + " " + client.last_name,
                            })
                          )}
                          // options={this.props.clientList_Invoice}
                          onChange={(e: any) => {
                            this.props.handleFilterInvoiceClient(
                              e.map((val: any) => {
                                return (
                                  val.value
)
                              })
                            );
                            this.handleFilter_InvoiceClient(e);
                            this.setState({ invoice_client: e });
                          }}
                        />
                      </div>
                      <div className="col-md-6 res-mt-3">
                        <label className="mb-2">Select Branch</label>
                        <Select
                          placeholder="Select Branch"
                          styles={reactSelectCusStyles}
                          isMulti={true}
                          value={this.state.invoice_branch}
                          options={this.props.branchList_Invoice}
                          onChange={(e: any) => {
                            console.log(e, "complaint of e");
                            this.props.handleFilterInvoiceBranch(
                              e.map((val: any) => {
                                return (
                                  val.value
                                )
                              })
                            );
                            this.handleFilter_InvoiceBranch(e);
                            this.setState({ invoice_branch: e })
                          }}
                        />
                      </div>
                      {/* <div className="col-md-6">
                          <label className="form-label">From date</label>
                          <div
                            className="startDate"
                            onClick={() => this.handleInvoiceStartDateclick()}
                          >
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              id="startDate"
                            >
                              <Stack spacing={3}>
                                <DesktopDatePicker
                                  inputFormat="DD/MM/YYYY"
                                  maxDate={this.props.invoice_to_date}
                                  value={this.props.invoice_from_date}
                                  onChange={(e: any) => {
                                    console.log(e, "handleInvoiceStartDate");

                                    this.props.updateInvoiceFromDate(
                                      moment(new Date(e?.toString())).format(
                                        "YYYY-MM-DD"
                                      )
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">To date</label>
                          <div
                            className="endDate"
                            onClick={() => this.handleInvoiceEndDateclick()}
                          >
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              id="endDate"
                            >
                              <Stack spacing={3}>
                                <DesktopDatePicker
                                  inputFormat="DD/MM/YYYY"
                                  minDate={this.props.invoice_from_date}
                                  value={this.props.invoice_to_date}
                                  onChange={(e: any) => {
                                    this.props.updateInvoiceToDate(
                                      moment(new Date(e?.toString())).format(
                                        "YYYY-MM-DD"
                                      )
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </div>
                        </div> */}
                      {/* <div className="col-md-6">
                        <label className="mb-2">Status</label>
                        <Select
                          placeholder="Select Status ..."
                          styles={reactSelectCusStyles}
                          isMulti={false}
                          value={this.state.invoice_status}
                          options={[
                            { value: "1", label: "all" },
                            { value: "2", label: "DAY" },
                            { value: "3", label: "Night" },
                            { value: "4", label: "D/N" },
                          ]}
                          onChange={(e: any) => {
                            this.handleInvoiceStatus(e.value);
                            this.setState({ invoice_status: e.value })
                          }}
                        />
                      </div> */}
                    </div>
                  </Offcanvas.Body>
                </Offcanvas>

                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        {this.props.allInvoice_Data.length > 0 ? (
                          <div className="table-responsive">
                            <table className="table employee-table">
                              <thead>
                                <tr>
                                  <th scope="col">CLEANER</th>
                                  <th scope="col">CLIENT</th>
                                  <th scope="col">BRNACH</th>
                                  <th scope="col">EMAIL</th>
                                  <th scope="col">WORKING HRS</th>
                                  <th scope="col">SHIFT</th>
                                  <th scope="col">ACTION</th>
                                </tr>
                              </thead>
                              <InvoiceTable data={this.props.allInvoice_Data} />
                            </table>
                          </div>
                        ) : (
                          <p className="text-center">
                            There is no task data found
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                {this.props.totalInvoiceCount > 10 ? (
                  <div className="col-md-12">
                    {/* {this.state.pagehandle ? null : ( */}
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={
                        this.props.totalInvoiceCount / Constants.PER_PAGE
                      }
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick_Complaint}
                      containerClassName={"pagination"}
                      nextClassName={"next-page"}
                      activeClassName={"active"}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <Modal
            size="xl"
            show={this.props.invoice_Status}
            onHide={() => {
              this.props.updateViewInvoice(false);
            }}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                <h3 className="m-0">Invoice Report</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      {this.props.invoiceReportdata.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table employee-table">
                            <thead>
                              <tr>
                                <th scope="col">CLIENT</th>
                                <th scope="col">BRNACH</th>
                                <th scope="col">REASON</th>
                                <th scope="col">SHIFT TYPE</th>
                                <th scope="col">IN TIME</th>
                                <th scope="col">OUT TIME</th>
                                <th scope="col">HOURS</th>
                                <th scope="col">AMOUNT</th>
                                <th scope="col">TOTAL</th>
                              </tr>
                            </thead>
                            <InvoiceReportTable
                              invoice={this.props.invoiceReportdata}
                            />
                          </table>
                        </div>
                      ) : (
                        <p className="text-center">
                          There is no task data found
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  status: state.sidebar.status,
  clientList_Invoice: state.invoice?.clientList_Invoice,
  branchList_Invoice: state.invoice?.branchList_Invoice,
  allInvoice_Data: state.invoice?.allInvoice_Data,
  totalInvoiceCount: state.invoice?.totalInvoiceCount,
  invoice_Status: state.invoice.invoice_Status,
  export_status: state.invoice.export_status,
  invoiceReportdata: state.invoice.invoiceReportdata,
  invoice_from_date: state.invoice.invoice_from_date,
  invoice_to_date: state.invoice.invoice_to_date,
});
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
    listAllClient_Invoice: bindActionCreators(listAllClient_Invoice, dispatch),
    listAllBranch_Invoice: bindActionCreators(listAllBranch_Invoice, dispatch),
    handleFilterInvoiceClient: bindActionCreators(
      handleFilterInvoiceClient,
      dispatch
    ),
    handleFilterInvoiceBranch: bindActionCreators(
      handleFilterInvoiceBranch,
      dispatch
    ),
    getAllInvoiceReport: bindActionCreators(getAllInvoiceReport, dispatch),
    updateViewInvoice: bindActionCreators(updateViewInvoice, dispatch),
    updateExportInvoice: bindActionCreators(updateExportInvoice, dispatch),
    Invoice_Report_ById: bindActionCreators(Invoice_Report_ById, dispatch),
    updateInvoiceFromDate: bindActionCreators(updateInvoiceFromDate, dispatch),
    updateInvoiceToDate: bindActionCreators(updateInvoiceToDate, dispatch),
    exportinvoice: bindActionCreators(exportinvoice, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
