import { Dispatch, bindActionCreators } from "redux";
import {
  deleteUsermanagement,
  emailVerifyUsermanagement,
  getAllUsermanagements,
  getUsermanagementById,
  updateEditUsermanagementDlgStatus,
  updateViewUserManagement,
  viewUsermanagementById,
  hendleviewPermission
} from "../../shared/Reducers";

import EditUsermanagement from "./EditUsermanagement";
import { IUsermanagementTableProps } from "../../types/userManagement.type";
import { Modal } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import UserPermission from "./UserPermission";
import ProfileImg from "../../assets/images/profile.png";
import React from "react";
import { RootState } from "../../index";
import UsermanagementCard from "./UsermanagementCard";
import ViewUsermanagement from "./ViewUsermanagement";
import { connect } from "react-redux";

class UsermanagementTable extends React.Component<IUsermanagementTableProps> {
  // constructor(props: IUsermanagementTableProps) {
  //     super(props);
  // }

  // Delete Usermanagement By Id
  handleDeleteUsermanagement = async (id: string) => {
    await this.props.deleteUsermanagement(id);
  };
  // Delete Usermanagement By Id
  handleEmailVerifyUsermanagement = async (id: string, status: number) => {
    await this.props.emailVerifyUsermanagement(id, status);
  };

  // view Usermanagement By Id
  handleViewUsermanagement = async (id: string) => {
    try {
      this.props.viewUsermanagementById(id);
    } catch (error) {
      console.log(error);
    }
  };

  // Edit Usermanagement With Usermanagement Data
  handleEditUsermanagement = async (id: string) => {
    try {
      this.props.getUsermanagementById(id);
    } catch (error) {
      console.log(error);
    }
  };

  render(): React.ReactNode {
    return (
      <tbody>
        {this.props.data.map((usermanagement, index) => (
          <UsermanagementCard
            usermanagement={usermanagement}
            key={index + usermanagement.email}
            handleDeleteUsermanagement={this.handleDeleteUsermanagement}
            handleEmailVerifyUsermanagement={
              this.handleEmailVerifyUsermanagement
            }
            handleEditUsermanagement={this.handleEditUsermanagement}
            handleViewUsermanagement={this.handleViewUsermanagement}
            deleteUsermanagementStatus={false}
            emailVerifyUsermanagementStatus={false}
          />
        ))}
        <Offcanvas
          placement="end"
          show={this.props.editUsermanagementStatus}
          onHide={() => this.props.updateEditUsermanagementDlgStatus(false)}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <img src={ProfileImg} className="offc-img" alt="profile" />
              <span className="offc-title">Edit User</span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <EditUsermanagement
              id={this.props.currentUsermanagementId}
              data={this.props.usermanagementData}
            />
          </Offcanvas.Body>
        </Offcanvas>
        <Modal
          size="lg"
          show={this.props.viewUsermanagementStatus}
          onHide={() => {
            this.props.updateViewUserManagement(false);
          }}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              <h3 className="m-0">User Details</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <ViewUsermanagement data={this.props.viewUsermanagementData} />
          </Modal.Body>
        </Modal>
        <Modal
          size="lg"
          show={this.props.viewPermission}
          onHide={() => {
            this.props.hendleviewPermission(false);
          }}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              <h3 className="m-0">User permission</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <UserPermission data={this.props.viewUsermanagementData} />
          </Modal.Body>
        </Modal>
        <Offcanvas
          placement="end"
          show={this.props.editUsermanagementStatus}
          onHide={() => this.props.updateEditUsermanagementDlgStatus(false)}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <img src={ProfileImg} className="offc-img" alt="profile" />
              <span className="offc-title">Edit User</span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <EditUsermanagement
              id={this.props.currentUsermanagementId}
              data={this.props.usermanagementData}
            />
          </Offcanvas.Body>
        </Offcanvas>
      </tbody>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  editUsermanagementStatus: state.usermanagement.editUsermanagementStatus,
  usermanagementData: state.usermanagement.usermanagementData,
  currentUsermanagementId: state.usermanagement.currentUsermanagementId,
  viewUsermanagementStatus: state.usermanagement.viewUsermanagementStatus,
  viewUsermanagementData: state.usermanagement.viewUsermanagementData,
  viewPermission: state.usermanagement.viewPermission,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateEditUsermanagementDlgStatus: bindActionCreators(
      updateEditUsermanagementDlgStatus,
      dispatch
    ),
    hendleviewPermission: bindActionCreators(hendleviewPermission, dispatch),
    getUsermanagementById: bindActionCreators(getUsermanagementById, dispatch),
    getAllUsermanagements: bindActionCreators(getAllUsermanagements, dispatch),
    deleteUsermanagement: bindActionCreators(deleteUsermanagement, dispatch),
    emailVerifyUsermanagement: bindActionCreators(
      emailVerifyUsermanagement,
      dispatch
    ),
    viewUsermanagementById: bindActionCreators(
      viewUsermanagementById,
      dispatch
    ),
    updateViewUserManagement: bindActionCreators(
      updateViewUserManagement,
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsermanagementTable);
