import { Dispatch, AnyAction } from "redux";
import { AppDispatch } from "../..";
import { TQuotedTaskTable } from "../../types/user.type";
import {
  API_GET_ALL_CLIENTS,
  API_GET_ALL_CLIENTS_SUCCESS,
  API_GET_ALL_CLIENTS_FAIL,
  API_GET_ALL_BRANCH_FAIL,
  QuotedTaskTemplateState,
  API_GET_ALL_QUOTEDTASKS,
  API_GET_ALL_QUOTEDTASKS_SUCCESS,
  API_GET_ALL_QUOTEDTASKS_FAIL,
  API_GET_QUOTEDTASK_BY_ID,
  API_GET_QUOTEDTASK_BY_ID_SUCCESS,
  API_GET_QUOTEDTASK_BY_ID_FAIL,
  API_ADD_QUOTEDTASK,
  API_ADD_QUOTEDTASK_SUCCESS,
  API_ADD_QUOTEDTASK_FAIL,
  API_EDIT_QUOTEDTASK,
  API_EDIT_QUOTEDTASK_SUCCESS,
  API_EDIT_QUOTEDTASK_FAIL,
  API_DELETE_QUOTEDTASK,
  API_DELETE_QUOTEDTASK_SUCCESS,
  API_DELETE_QUOTEDTASK_FAIL,
  UPDATE_ADD_QUOTEDTASK_DLG_STATUS,
  UPDATE_EDIT_QUOTEDTASK_DLG_STATUS,
  UPDATE_DELETE_QUOTEDTASK_DLG_STATUS,
  UPDATE_QUOTEDTASK_FETCH_STATUS,
  FILTER_QUOTEDTASK,
  UPDATE_QUOTEDTASKS,
  IEditQuotedTask,
  API_ADD_QUOTEDTASK_PROGRESS,
  API_EDIT_QUOTEDTASK_PROGRESS,
  API_VIEW_QUOTEDTASK_BY_ID,
  API_VIEW_QUOTEDTASK_BY_ID_FAIL,
  API_VIEW_QUOTEDTASK_BY_ID_SUCCESS,
  UPDATE_VIEW_QUOTEDTASK,
  QUOTEDTASK_SORT,
  QUOTEDTASK_TASKTYPE,
  QUOTEDTASK_BRANCH,
  API_EXPORT_EXCEL_QUOTED_TASK,
  API_EXPORT_EXCEL_QUOTED_TASK_SUCCESS,
  API_EXPORT_EXCEL_QUOTED_TASK_FAIL,
  API_EXPORT_EXCEL_QUOTED_TASK_PROGRESS
} from "../ActionTypes";

const initialState: QuotedTaskTemplateState = {
  cleanerList: [],
  clientList: [],
  addQuotedTaskStatus: false,
  addQuotedTaskSuccess: "",
  deleteQuotedTaskStatus: false,
  deleteQuotedTaskSuccess: "",
  editQuotedTaskStatus: false,
  editQuotedTaskSuccess: "",
  quotedTaskFetchStatus: false,
  selectedQuotedTaskData: null,
  quotedTaskData: [],
  filteredQuotedTaskData: [],
  id: "",
  total_count: 0,
  addQuotedTaskError: "",
  deleteQuotedTaskError: "",
  editQuotedTaskError: "",
  processTrigger: false,
  progressPercentage: 0,
  viewQuotedTaskData: null,
  viewQuotedTaskStatus: false,
  quotedTaskSortBy: "",
  quotedTaskOrderBy: "",
  quotedTaskTaskType : "all",
  quotedTaskBranch : "all",  
  exportQuotedTaskFetchStatus: false,
  exportQuotedTaskData: [],
  filteredExportQuotedTaskData: [],
  export_total_count: 0,
  exportQuotedTaskSortBy: "",
  exportQuotedTaskOrderBy: "",
  exportFileURL: "",
};

export const QuotedTaskReducer = (
  state: QuotedTaskTemplateState = initialState,
  action: AnyAction
): QuotedTaskTemplateState => {
  switch (action.type) {
    case UPDATE_QUOTEDTASKS:
      return { ...state };
    case API_GET_ALL_CLIENTS:
      return { ...state };
    case API_GET_ALL_CLIENTS_SUCCESS:
      let clientData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "clients" in data && data.clients) {
          clientData = data.clients;
        }
      }
      return { ...state, clientList: clientData };
    case API_GET_ALL_CLIENTS_FAIL:
      return { ...state };
    case API_GET_ALL_BRANCH_FAIL:
      return { ...state };
    case UPDATE_QUOTEDTASK_FETCH_STATUS:
      return { ...state, quotedTaskFetchStatus: action.payload };
    case API_GET_ALL_QUOTEDTASKS:
      return { ...state };
    case API_GET_ALL_QUOTEDTASKS_SUCCESS:
      let quotedTaskData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "fixedtasks" in data && data.fixedtasks) {
          quotedTaskData = data.fixedtasks;
        }
      }
      let total_count = 0;
      if (action.payload) {
        const { data } = action.payload;
        if (data && "total_count" in data && data.total_count) {
          total_count = data.total_count;
        }
      }
      return {
        ...state,
        total_count: total_count,
        editQuotedTaskStatus: false,
        addQuotedTaskStatus: false,
        deleteQuotedTaskStatus: false,
        filteredQuotedTaskData: quotedTaskData,
      };
    case API_GET_ALL_QUOTEDTASKS_FAIL:
      return { ...state };
    case API_GET_QUOTEDTASK_BY_ID:
      return { ...state, id: action.payload.id };
    case API_GET_QUOTEDTASK_BY_ID_SUCCESS:
      let quotedTasks = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "fixedtask" in data && data.fixedtask) {
          quotedTasks = data.fixedtask;
        }
      }
      return {
        ...state,
        selectedQuotedTaskData: quotedTasks,
        editQuotedTaskStatus: true,
        quotedTaskFetchStatus: false,
      };
    case API_GET_QUOTEDTASK_BY_ID_FAIL:
      return { ...state };
    case API_VIEW_QUOTEDTASK_BY_ID:
      return { ...state, id: action.payload.id };
    case API_VIEW_QUOTEDTASK_BY_ID_SUCCESS:
      let quotedTask = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "fixedtask" in data && data.fixedtask) {
          quotedTask = data.fixedtask;
        }
      }
      return {
        ...state,
        viewQuotedTaskData: quotedTask,
        viewQuotedTaskStatus: true,
        quotedTaskFetchStatus: false,
      };
    case API_VIEW_QUOTEDTASK_BY_ID_FAIL:
      return { ...state };
    case UPDATE_VIEW_QUOTEDTASK:
      return { ...state, viewQuotedTaskStatus: action.payload };
    case API_ADD_QUOTEDTASK:
      let values: boolean = false;
      if (action.payload.request.data.FormData === undefined) {
        values = true;
      }
      return {
        ...state,
        addQuotedTaskSuccess: "",
        addQuotedTaskError: "",
        processTrigger: values,
      };
    case API_ADD_QUOTEDTASK_SUCCESS:
      let message = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          message = data.message;
        }
      }
      return {
        ...state,
        addQuotedTaskStatus: false,
        addQuotedTaskSuccess: message,
        processTrigger: false,
        progressPercentage: 0,
      };
    case API_ADD_QUOTEDTASK_FAIL:
      let err = "";
      if (
        "error" in action &&
        action.error &&
        "response" in action.error &&
        action.error.response &&
        "data" in action.error.response &&
        action.error.response.data &&
        "message" in action.error.response.data
      ) {
        err = action.error.response.data.message;
      }
      return { ...state, addQuotedTaskError: err };
    case API_ADD_QUOTEDTASK_PROGRESS:
      return { ...state, progressPercentage: action.payload };
    case API_EDIT_QUOTEDTASK_PROGRESS:
      return { ...state, progressPercentage: action.payload };
    case UPDATE_ADD_QUOTEDTASK_DLG_STATUS:
      return { ...state, addQuotedTaskStatus: action.payload };
    case API_EDIT_QUOTEDTASK:
      let value: boolean = false;
      if (action.payload.request.data.FormData === undefined) {
        value = true;
      }
      return { ...state, editQuotedTaskSuccess: "", processTrigger: value };
    case API_EDIT_QUOTEDTASK_SUCCESS:
      let messages = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          messages = data.message;
        }
      }
      return {
        ...state,
        editQuotedTaskStatus: false,
        quotedTaskFetchStatus: true,
        editQuotedTaskSuccess: messages,
        processTrigger: false,
        progressPercentage: 0,
      };
    case API_EDIT_QUOTEDTASK_FAIL:
      let error = "";
      if (
        "error" in action &&
        action.error &&
        "response" in action.error &&
        action.error.response &&
        "data" in action.error.response &&
        action.error.response.data &&
        "message" in action.error.response.data
      ) {
        error = action.error.response.data.message;
      }
      return { ...state, editQuotedTaskError: error };
    case UPDATE_EDIT_QUOTEDTASK_DLG_STATUS:
      return { ...state, editQuotedTaskStatus: action.payload };
    case API_DELETE_QUOTEDTASK:
      return { ...state, deleteQuotedTaskSuccess: "" };
    case API_DELETE_QUOTEDTASK_SUCCESS:
      let msg = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          msg = data.message;
        }
      }
      return {
        ...state,
        deleteQuotedTaskStatus: false,
        quotedTaskFetchStatus: true,
        deleteQuotedTaskSuccess: msg,
      };
    case API_DELETE_QUOTEDTASK_FAIL:
      return { ...state };
    case UPDATE_DELETE_QUOTEDTASK_DLG_STATUS:
      return { ...state, deleteQuotedTaskStatus: action.payload };
    case FILTER_QUOTEDTASK:
      console.log(action.payload, state.quotedTaskData);

      const filterQuotedTask: TQuotedTaskTable[] = state.filteredQuotedTaskData.filter(
        (obj:any) => {
          console.log(action.payload);

          console.log(obj.first_name.toLowerCase().includes(action.payload));
          return (
            obj.first_name.toLowerCase().includes(action.payload)
          );
        }
      );
      return { ...state, filteredQuotedTaskData: filterQuotedTask };
      case QUOTEDTASK_SORT:
        return { ...state, quotedTaskSortBy: action.payload.sort, quotedTaskOrderBy: action.payload.order };
      case QUOTEDTASK_TASKTYPE:
        return { ...state, quotedTaskTaskType: action.payload.task_type };
      case QUOTEDTASK_BRANCH:
        return { ...state, quotedTaskBranch: action.payload.branch };
      case API_EXPORT_EXCEL_QUOTED_TASK:
        let process: boolean = false;
        if (action.payload.request.data.FormData === undefined) {
          process = true;
        }
        return {
          ...state,
          exportFileURL: "",
          processTrigger: process,
        };
      case API_EXPORT_EXCEL_QUOTED_TASK_SUCCESS:
        let file = [];
        if (action.payload) {
          const { data } = action.payload;
          if (data && "file" in data && data.file) {
            file = data.file;
          }
        }
        return {
          ...state,
          exportFileURL: file,
          processTrigger: false,
          progressPercentage: 0,
        };
      case API_EXPORT_EXCEL_QUOTED_TASK_FAIL:
        return { ...state, exportFileURL: "" };
      case API_EXPORT_EXCEL_QUOTED_TASK_PROGRESS:
        return { ...state, progressPercentage: action.payload };
      default:
      return state;
  }
};
// update quotedTask
export function updateQuotedTasks(data: any) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_QUOTEDTASKS,
      payload: data,
    });
  };
}

// get quotedTask by id
export function getQuotedTaskById(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_QUOTEDTASK_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `fixed-task/${id}` : `fixed-task`,
        },
        id,
      },
    });
  };
}

// VIEW quotedTask by id
export function viewQuotedTaskById(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_VIEW_QUOTEDTASK_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `fixed-task/${id}` : `fixed-task`,
        },
        id,
      },
    });
  };
}

export function updateViewQuotedTask(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_VIEW_QUOTEDTASK,
      payload: status,
    });
  };
}


// get all quotedTasks for table
export function getAllQuotedTasks(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ALL_QUOTEDTASKS,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `fixed-task?${queryStr}` : `fixed-task?`,
        },
      },
    });
  };
}

// add quotedTask
export function addQuotedTask(payload: FormData) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_ADD_QUOTEDTASK,
      payload: {
        request: {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            contentType: false,
          },
          method: "post",
          url: `fixed-task`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_ADD_QUOTEDTASK_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

export function updateAddQuotedTask(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_ADD_QUOTEDTASK_DLG_STATUS,
      payload: status,
    });
  };
}

// edit quotedTask
export function editQuotedTask(payload: IEditQuotedTask, id: number) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_EDIT_QUOTEDTASK,
      payload: {
        request: {
          method: "put",
          url: `fixed-task/${id}`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_EDIT_QUOTEDTASK_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

// update edit employee
export function updateEditQuotedTaskDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_EDIT_QUOTEDTASK_DLG_STATUS,
      payload: status,
    });
  };
}

// Fetch QuotedTask after add, edit, delete
export function updateQuotedTaskFetchStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_QUOTEDTASK_FETCH_STATUS,
      payload: status,
    });
  };
}

// delete quotedTask
export function deleteQuotedTask(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_DELETE_QUOTEDTASK,
      payload: {
        request: {
          method: "delete",
          url: `fixed-task/${id}`,
        },
      },
    });
  };
}

// update delete quotedTask
export function updateDeleteQuotedTaskDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_DELETE_QUOTEDTASK_DLG_STATUS,
      payload: status,
    });
  };
}

// Filter value
export function filterQuotedTask(filterVal: string) {
  console.log(filterVal)
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_QUOTEDTASK,
      payload: filterVal,
    });
  };
}

// handleQuotedTaskSort
export function handleQuotedTaskSort(sort: any, order: any) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: QUOTEDTASK_SORT,
      payload: {
        sort: sort,
        order: order
      },
    });
  };
}



// Filter value
export function filterExportQuotedTask(filterVal: string) {
  console.log(filterVal)
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_QUOTEDTASK,
      payload: filterVal,
    });
  };
}

// handleExportQuotedTaskSort
export function handleExportQuotedTaskSort(sort: any, order: any) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: QUOTEDTASK_SORT,
      payload: {
        sort: sort,
        order: order
      },
    });
  };
}

// get all quotedTasks for table
export function getAllExportQuotedTask(ClientID : any, BranchId : any, ExportView : any,search:string="") {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ALL_QUOTEDTASKS,
      payload: {
        request: {
          method: "get",
          url: `fixed-task/export/${ClientID}/${BranchId}/${ExportView}?search=${search}`,
        },
      },
    });
  };
}

// Export excel quoted task
export function ExportExcelQuotedTask(payload: FormData) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_EXPORT_EXCEL_QUOTED_TASK,
      payload: {
        request: {
          method: "post",
          url: `fixed-task/exportexcel`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_EXPORT_EXCEL_QUOTED_TASK_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

// get all quotedTasks for table
// export function ExportQuotedTask(ClientID : any, BranchId : any, ExportView : any) {
//   return async (dispatch: Dispatch) => {
//     dispatch({
//       type: API_GET_ALL_QUOTEDTASKS,
//       payload: {
//         request: {
//           method: "get",
//           url: `fixed-task/export/${ClientID}/${BranchId}/${ExportView}`,
//         },
//       },
//     });
//   };
// }