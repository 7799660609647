import React from "react";
import { BackSVG } from "../../assets/images/Images";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import AuthService from "../../services/auth.service";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from '../../index';
import { bindActionCreators, Dispatch } from "redux";
import { updateSignin } from '../../shared/Reducers/index';
import { IForgotPasswordProps, IForgotPasswordState } from "../../types/user.type";
import { LogoSVG } from "../../assets/images/ImagesV2";


class ForgotPassword extends React.Component<IForgotPasswordProps, IForgotPasswordState> {
    constructor(props: IForgotPasswordProps) {
        super(props);
        this.state = {
            email: "",
            loading: false,
            message: "",
            redirect: null
        }
    }

    validationSchema() {
        return Yup.object().shape({
            email: Yup.string()
                .email("Invalid Email Format")
                .required("Enter Email address !")
        })
    }
    handleSend = (formValue: { email: string; }) => {
        console.log(formValue);
        const { email } = formValue;

        this.setState({
            message: "",
            loading: true
        });
        AuthService.forgotpassword(email).then(
            () => {
                this.props.updateSignin('forget-password');
                this.setState({
                    redirect: "/"
                });
            },
            error => {
                const resMessage = (
                    error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();

                this.setState({
                    loading: false,
                    message: resMessage
                });
            }
        );
    }

    render(): React.ReactNode {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }

        const { loading, message } = this.state;
        const initialValues = {
            email: "",
        };

        return (
            <div className="container-fluid h-100">
                <div className="row vh-100">
                    <div className="col-md-5 d-flex justify-content-center flex-column bg-green p-6-5-rem p-1-5-rem res-d-none">
                        <div className="forgotpasswordlogo" style={{ marginBottom: '22px' }}>
                            <LogoSVG />
                        </div>
                        <div className="w-100">
                            <h2 className="text-white forgot-text">Forgot Password?</h2>
                            <p className="text-white mt-3">Enter the email address you used when you joined and we'll send you instructions to reset your password.</p>
                            <p className="text-white mt-3">Enter the email address you used when you joined and we'll send you instructions to reset your password.</p>
                        </div>
                    </div>
                    <div className="col-md-7 d-flex justify-content-center align-items-center flex-column  p-6-5-rem p-1-5-rem">
                        <div>
                            <p className="pos-back"><a className="terms text-project" href="/"><BackSVG /><span> Back</span></a></p>
                            {/* <p className="pos-signin-up">Not a member? <a className="terms text-project" href="/signup">Sign up now</a></p> */}
                        </div>
                        <div className=" responsive-w-100">
                            <h2>Forgot Password? <span className="text-project">Clean Task</span></h2>
                            {/* Forgot Password Form */}
                            <Formik
                                initialValues={initialValues}
                                validationSchema={this.validationSchema}
                                onSubmit={this.handleSend}
                            >
                                <Form className="mt-30px">
                                    <div className="mb-3">
                                        <label className="form-label">Email Address</label>
                                        <Field name="email" type="text" className="form-control" />
                                        <ErrorMessage name="email"
                                            component={"div"}
                                            className="cus-alert" />
                                    </div>
                                    <div className="pt-2">
                                        <button type="submit" className="btn btn-primary send-btn" disabled={loading}>
                                            {loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                            <span>Send</span>
                                        </button>
                                    </div>
                                    {message && (
                                        <div className="form-group">
                                            <div className="cus-alert" role={"alert"}>
                                                {message}
                                            </div>
                                        </div>
                                    )}
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state: RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateSignin: bindActionCreators(updateSignin, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
