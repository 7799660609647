import * as Yup from "yup";

import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { PaperClipSVG, TrashSVG } from "../../assets/images/ImagesV2";
import { addContract, listAllClient } from "../../shared/Reducers";
import dayjs, { Dayjs } from "dayjs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AttachmentPreview from "../../shared/Components/AttachmentPreview";
import Button from "@material-ui/core/Button";
import { CustomSelect } from "../../shared/Components/CustomSelect";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { ICleaner } from "../../shared/ActionTypes";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Modal } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import React from "react";
import { RootState } from "../../index";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import docImage from "../../assets/images/google-docs.png";
// date picker
import moment from "moment";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";

type Props = {
  onClose: (status: boolean) => void;
  clientList?: any;
  listAllClient?: any;
  addContractError?: any;
  addContract?: any;
  processTrigger: any;
  addContractSuccess: any;
  progressPercentage: number;
};

type TAddClientContractState = {
  attachmentFiles: any | Blob[];
  attachmentUrls: any[];
  endDatevalue: any;
  startDatevalue: any;
  AttachmentPreviewStatus: boolean;
  previewUrl: string;
  previewType: string;
  files_size: any;
  selectedCheckbox: any;
  asic_reg: any;
  client_id: any;
  startErrorShow: any
  endErrorShow: any
};

class AddClientContract extends React.Component<
  Props,
  TAddClientContractState
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      attachmentFiles: [],
      attachmentUrls: [],
      endDatevalue: "",
      startDatevalue: "",
      AttachmentPreviewStatus: false,
      previewUrl: "",
      previewType: "",
      files_size: 0,
      selectedCheckbox: null,
      asic_reg: false,
      client_id: "",
      startErrorShow: false,
      endErrorShow: false,
    };
  }
  componentDidMount(): void {
    this.listData();
    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.add("d-none");
      button.removeAttribute("disabled");
    }

    // date picker readonly
    let datePicker: NodeListOf<Element> = document.querySelectorAll(
      ".MuiFormControl-root input"
    ) as NodeListOf<Element>;
    if (datePicker.length > 0) {
      for (let index = 0; index < datePicker.length; index++) {
        const element = datePicker[index];
        datePicker[index].setAttribute("readonly", "true");
      }
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<TAddClientContractState>,
    snapshot?: any
  ): void {
    if (prevProps.processTrigger !== this.props.processTrigger) {
    }
    if (prevProps.addContractSuccess !== this.props.addContractSuccess) {
    }
  }

  listData = () => {
    this.props.listAllClient();
  };

  handleContractClient = async (id: string) => {
    // console.log(id, "handlecontractclient");
    // this.props.listAllClient(id);
    this.setState({ client_id: id });
  };

  handleSelectCheckBox = (option: any) => {
    this.setState({ selectedCheckbox: option });
    this.setState({ asic_reg: false });
  };

  handleAddClientContract = async (formValues: any) => {
    if (this.state.endDatevalue === "") {
      return false
    }
    if (
      this.state.selectedCheckbox === null ||
      this.state.selectedCheckbox === undefined
    ) {
      return false;
    }
    let data = new FormData();
    data.append("client", formValues.client);

    for (let index = 0; index < this.state.attachmentFiles.length; index++) {
      data.append("attachments", this.state.attachmentFiles[index]);
    }

    data.append(
      "start_date", this.state.startDatevalue,
    );

    data.append(
      "end_date",
      this.state.endDatevalue);

    data.append("entity_name", formValues.entity_name);
    data.append("abn_status", formValues.abn_status);
    data.append("entity_type", formValues.entity_type);
    data.append("gst", formValues.gst);
    data.append("business_location", formValues.business_location);
    data.append("asic_reg", this.state.selectedCheckbox);

    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.remove("d-none");
      button.setAttribute("disabled", "");
    }
    await this.props.addContract(data);
    setTimeout(() => {
      this.props.onClose(false);
    }, 1000);
  };

  handleImageChange = async (e: any) => {
    console.log(e);
    const file = e.target.files[0];
    this.setState(
      { files_size: this.state.files_size + file.size },
      async () => {
        if (this.state.files_size > 524288000) {
          alert("File Size Must Be Less Than 500MB");
          e.target.value = null;
          return this.setState({
            files_size: this.state.files_size - file.size,
          });
        }

        let files = [];
        let urls = [];
        files = [...e.target.files];

        let fileurls: any = [];
        await files.map(async (file: any) => {
          fileurls.push({
            url: URL.createObjectURL(file),
            file_type: file.type,
          });
        });
        urls = [...fileurls];
        this.setState({
          attachmentFiles: [...this.state.attachmentFiles, ...files],
        });
        this.setState(
          { attachmentUrls: [...this.state.attachmentUrls, ...urls] },
          () => {
            e.target.value = "";
          }
        );
      }
    );
  };

  handleDeleteImage = (index: number) => {
    const files = this.state.attachmentFiles;
    const urls = this.state.attachmentUrls;
    files.splice(index, 1);
    urls.splice(index, 1);

    this.setState({ attachmentFiles: files });
    this.setState({ attachmentUrls: urls });
  };

  validationSchema = () => {
    if (
      this.state.selectedCheckbox === null ||
      this.state.selectedCheckbox === undefined
    ) {
      this.setState({ asic_reg: true });
    } else {
      this.setState({ asic_reg: false });
    }

    if (this.state.startDatevalue === "") {
      this.setState({ startErrorShow: true })
    } else {
      this.setState({ startErrorShow: false })
    }

    if (this.state.endDatevalue === "") {
      this.setState({ endErrorShow: true })
    } else {
      this.setState({ endErrorShow: false })
    }
    const clientId = this.props.clientList.map((client: ICleaner) => client.id);
    return Yup.object().shape({
      client: Yup.string()
        .oneOf([...clientId], "Invalid Client")
        .required()
        .nullable(),
      attachment: Yup.mixed(),
      entity_name: Yup.string().required("Entity Name is required"),
      abn_status: Yup.string().required("ABN Status is required"),
      entity_type: Yup.string().required("Entity Type is required"),
      business_location: Yup.string().required("Business location is required"),
      gst: Yup.string().required("GST Number is required"),
    });
  };

  handleStartDateClick = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let startDate: HTMLElement = document.querySelector(
        ".startdate button.MuiButtonBase-root"
      ) as HTMLElement;
      startDate.click();
    }
  };

  handleEndDateClick = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let endDate: HTMLElement = document.querySelector(
        ".enddate button.MuiButtonBase-root"
      ) as HTMLElement;
      endDate.click();

    }
  };

  // handleStartDateChange = async (newValue: Dayjs | null) => {
  //   let value: any = newValue?.format("DD/MM/YYYY");
  //   console.log(value,"start date");

  //   this.setState({ startDatevalue: value, startErrorShow: false });
  // };

  handleStartDateChange = async (newValue: Dayjs | null) => {
    if (newValue) {
      const formattedDate = newValue.format("DD/MM/YYYY");
      this.setState({ startDatevalue: formattedDate, startErrorShow: false });
    }
  };

  // handleEndDateChange = async (newValue: Dayjs | null) => {
  //   console.log(newValue, "handle new value");
  //   let value: any = newValue?.format("DD/MM/YYYY")
  //   console.log(value,"log values");

  //   this.setState({ endDatevalue: value, endErrorShow: false });
  // };

  handleEndDateChange = async (newValue: Dayjs | null) => {
    if (newValue) {
      const formattedDate = newValue.format("DD/MM/YYYY");
      this.setState({ endDatevalue: formattedDate, endErrorShow: false });
    }
  };

  handlePreviewClose = () => {
    this.setState({ AttachmentPreviewStatus: false });
  };
  handlePreviewOpen = (file_type: any, url: any) => {
    if (
      file_type === "image/png" ||
      file_type === "image/jpeg" ||
      file_type === "image/jpg" ||
      file_type === "video/mp4" ||
      file_type === "video/webm" ||
      file_type === "application/pdf"
    ) {
      this.setState({
        AttachmentPreviewStatus: true,
        previewUrl: url,
        previewType: file_type,
      });
    }
  };

  progress = (e: any) => {
    console.log("progress", e);
  };

  render(): React.ReactNode {
    const initialValues = {
      client: "",
      attachments: "",
      entity_name: "",
      entity_type: "",
      abn_status: "",
      business_location: "",
      gst: "",
    };
    return (
      <div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={this.handleAddClientContract}
          validationSchema={this.validationSchema}
        >
          <Form encType="multipart/form-data">
            <div className="row">
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Client<span className="text-project">*</span>
                </label>
                <Field
                  className="custom-select dropdown-field"
                  name="client"
                  options={this.props.clientList.map((client: any) => ({
                    value: client.id,
                    label: client.first_name + " " + client.last_name,
                  }))}
                  onChange={(e: any) => this.handleContractClient(e.value)}
                  component={CustomSelect}
                  placeholder="Select Client"
                  isMulti={false}
                ></Field>
                <ErrorMessage
                  name="client"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Entity Name<span className="text-project">*</span>
                </label>
                <Field
                  name="entity_name"
                  type={"text"}
                  className="form-control"
                />
                <ErrorMessage
                  name="entity_name"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  ABN Status<span className="text-project">*</span>
                </label>
                <Field
                  name="abn_status"
                  type={"text"}
                  className="form-control"
                />
                <ErrorMessage
                  name="abn_status"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Entity Type<span className="text-project">*</span>
                </label>
                <Field
                  name="entity_type"
                  type={"text"}
                  className="form-control"
                />
                <ErrorMessage
                  name="entity_type"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  GST Number
                  <span className="text-project">*</span>
                </label>
                <Field name="gst" type={"text"} className="form-control" />
                <ErrorMessage
                  name="gst"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Main Business Location
                  <span className="text-project">*</span>
                </label>
                <Field
                  name="business_location"
                  type={"text"}
                  className="form-control"
                />
                <ErrorMessage
                  name="business_location"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  ASIC Registration
                  <span className="text-project">*</span>
                </label>
                <div className="flex-row-container">
                  <div>
                    <label className="form-label">
                      <input
                        type="checkbox"
                        id="acn"
                        checked={this.state.selectedCheckbox === "acn"}
                        onChange={() => this.handleSelectCheckBox("acn")}
                      />
                      ACN
                    </label>
                  </div>
                  <div>
                    <label className="form-label">
                      <input
                        type="checkbox"
                        id="arbn"
                        checked={this.state.selectedCheckbox === "arbn"}
                        onChange={() => this.handleSelectCheckBox("arbn")}
                      />
                      ARBN
                    </label>
                  </div>
                  <div>
                    <label className="form-label">
                      <input
                        type="checkbox"
                        id="arsn"
                        checked={this.state.selectedCheckbox === "arsn"}
                        onChange={() => this.handleSelectCheckBox("arsn")}
                      />
                      ARSN
                    </label>
                  </div>
                  <div>
                    <label className="form-label">
                      <input
                        type="checkbox"
                        id="arfn"
                        checked={this.state.selectedCheckbox === "arfn"}
                        onChange={() => this.handleSelectCheckBox("arfn")}
                      />
                      ARFN
                    </label>
                  </div>
                </div>
                {this.state.asic_reg === true && (
                  <p className="text-danger">
                    Select Anyone ASIC registration box
                  </p>
                )}
              </div>
              <div className=" mb-2 col-md-6">
                <label className="form-label">Attachment</label>
                <div className="attachment-btn dropdown-field">
                  <input
                    type="file"
                    multiple
                    accept=".pdf,.PDF,.mp4,.MP4,.webm,.WEBM,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.doc,.DOC,.docx,.DOCX"
                    style={{ display: "none" }}
                    onChange={this.handleImageChange}
                    id="contained-button-file"
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="default"
                      component="span"
                    >
                      <PaperClipSVG /> Add attachment
                    </Button>
                  </label>
                </div>
              </div>
              {this.state.attachmentUrls.length > 0 ? (
                <div className="col-md-12 mb-2">
                  <label className="form-label">Attachments</label>
                </div>
              ) : (
                <></>
              )}
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex row">
                    {this.state.attachmentUrls &&
                      this.state.attachmentUrls.map(
                        (url: any, index: number) => (
                          <div className="img-card mr-16px mb-3">
                            <span className="delete">
                              <a onClick={() => this.handleDeleteImage(index)}>
                                <TrashSVG />
                              </a>
                            </span>
                            <div
                              className="img-preview"
                              onClick={() =>
                                this.handlePreviewOpen(url.file_type, url.url)
                              }
                            >
                              <img
                                className="img-thumb-perview"
                                src={
                                  url.file_type === "application/pdf"
                                    ? pdfImage
                                    : url.file_type === "video/mp4" ||
                                      url.file_type === "video/webm"
                                      ? videoImage
                                      : url.file_type === "image/png" ||
                                        url.file_type === "image/jpeg" ||
                                        url.file_type === "image/jpg"
                                        ? url.url
                                        : docImage
                                }
                              />
                            </div>
                          </div>
                        )
                      )}
                    <AttachmentPreview
                      displayPreview={this.state.AttachmentPreviewStatus}
                      previewUrl={this.state.previewUrl}
                      previewType={this.state.previewType}
                      handlePreviewClose={this.handlePreviewClose}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="mb-2 col-md-6">
                  <label className="form-label">Start date</label>
                  <div
                    className="startdate"
                    onClick={() => this.handleStartDateClick()}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      id="startDate"
                    >
                      <Stack spacing={3} >
                        <DesktopDatePicker
                          inputFormat="DD/MM/YYYY"
                          maxDate={dayjs(this.state.endDatevalue, "DD/MM/YYYY")}
                          value={dayjs(this.state.startDatevalue, "DD/MM/YYYY")}
                          onChange={this.handleStartDateChange}
                          renderInput={(params) => (<TextField {...params} />)}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="mb-2 col-md-6">
                  <label className="form-label">End date</label>
                  <div
                    className="enddate"
                    onClick={() => this.handleEndDateClick()}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs} id="endDate">
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          inputFormat="DD/MM/YYYY"
                          minDate={dayjs(this.state.startDatevalue, "DD/MM/YYYY")}
                          value={dayjs(this.state.endDatevalue, "DD/MM/YYYY")}
                          onChange={this.handleEndDateChange}
                          renderInput={(params) => (
                            <TextField {...params} />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                    {this.state.endErrorShow === true && (
                      <p className="text-danger">Please select the End Date</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-3 mb-3">
                <div className="col-md-12 cus-progress">
                  <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.props.processTrigger}
                  >
                    <Modal.Body className="p-0">
                      <ProgressBar
                        now={this.props.progressPercentage}
                        label={
                          this.props.progressPercentage === 100
                            ? `Processing your request...`
                            : `Contract Adding....${this.props.progressPercentage}%`
                        }
                      />
                    </Modal.Body>
                  </Modal>
                  {/* {this.props.processTrigger ? <ProgressBar now={this.state.processValue} label={`${this.state.processValue}%`} /> : ""} */}
                  <div className="d-flex">
                    <div className="mr-16px">
                      <button
                        type="submit"
                        id="create-button"
                        className="btn btn-primary create-button"
                      >
                        Create
                        <span
                          id="spinner-border"
                          className="spinner-border spinner-border-sm d-none"
                        ></span>
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn cancel-button"
                        onClick={() => this.props.onClose(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  clientList: state.contract.clientList,
  processTrigger: state.contract.processTrigger,
  addContractSuccess: state.contract.addContractSuccess,
  progressPercentage: state.contract.progressPercentage,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    listAllClient: bindActionCreators(listAllClient, dispatch),
    addContract: bindActionCreators(addContract, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddClientContract);
