import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IComplaintTableProps } from "../../types/complaint.type";
import ComplaintCard from "./ComplaintCard";
import EditComplaint from "./EditComplaint";
import ChatComplaint from "./ChatComplaint";
import { Modal, Offcanvas } from "react-bootstrap";
import {
  deleteComplaint,
  getAllComplaints,
  getComplaintById,
  updateEditComplaintDlgStatus,
  updateViewComplaint,
  viewComplaintById,
  updateChatComplaint,
} from "../../shared/Reducers";
import ViewComplaint from "./ViewComplaint";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

class ComplaintTable extends React.Component<IComplaintTableProps> {
  // constructor(props: IComplaintTableProps ){
  //     super(props);
  // }

  handleDeleteComplaint = async (id: string) => {
    await this.props.deleteComplaint(id);
    console.log(id);
    const query = `search=&sort=&order=&offset=0&limit=10&client=all&branch=all`;
    this.props.getAllComplaints(query);
  };

  handleEditBranch = async (id: string) => {
    try {
      this.props.getComplaintById(id);
    } catch (error) {
      console.log(error);
    }
  };

  handleViewComplaint = async (id: string) => {
    try {
      this.props.viewComplaintById(id);
    } catch (error) {
      console.log(error);
    }
  };

  render(): React.ReactNode {
    return (
      <tbody>
        {this.props.data.map((complaints, index) => (
          <ComplaintCard
            complaints={complaints}
            key={index + complaints.id}
            handleDeleteComplaint={this.handleDeleteComplaint}
            handleEditComplaint={this.handleEditBranch}
            handleViewComplaint={this.handleViewComplaint}
            deleteComplaintStatus={false}
          />
        ))}
        <Offcanvas
          placement="end"
          show={this.props.editComplaintStatus}
          onHide={() => this.props.updateEditComplaintDlgStatus(false)}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <span className="offc-title">Edit Complaint</span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <EditComplaint
              id={this.props.id}
              data={this.props.selectedComplaintData}
            />
          </Offcanvas.Body>
        </Offcanvas>
        <Offcanvas
          placement="end"
          show={this.props.chatComplaintStatus}
          onHide={() => {
            this.props.onChatClose();
            this.props.updateChatComplaint(false);
          }}
        >
          <Offcanvas.Header closeButton className="pb-3">
            <Offcanvas.Title className="chat-complaint">
              <span className="offc-title">
                {this.props.selectedComplaintData?.name}
              </span>
              <span>&nbsp;</span>
              <span className="branch-text">
                {this.props.selectedComplaintData?.branch_name}
              </span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ChatComplaint />
          </Offcanvas.Body>
        </Offcanvas>
        <Modal
          size="lg"
          show={this.props.viewComplaintStatus}
          onHide={() => this.props.updateViewComplaint(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              <h3 className="m-0">User Details</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <ViewComplaint data={this.props.viewComplaintData} />
          </Modal.Body>
        </Modal>
      </tbody>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  editComplaintStatus: state.complaint.editComplaintStatus,
  selectedComplaintData: state.complaint.selectedComplaintData,
  id: state.complaint.id,
  viewComplaintStatus: state.complaint.viewComplaintStatus,
  viewComplaintData: state.complaint.viewComplaintData,
  chatComplaintStatus: state.complaint.chatComplaintStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateEditComplaintDlgStatus: bindActionCreators(
      updateEditComplaintDlgStatus,
      dispatch
    ),
    getComplaintById: bindActionCreators(getComplaintById, dispatch),
    getAllComplaints: bindActionCreators(getAllComplaints, dispatch),
    deleteComplaint: bindActionCreators(deleteComplaint, dispatch),
    viewComplaintById: bindActionCreators(viewComplaintById, dispatch),
    updateViewComplaint: bindActionCreators(updateViewComplaint, dispatch),
    updateChatComplaint: bindActionCreators(updateChatComplaint, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ComplaintTable);
