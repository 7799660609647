import { AnyAction } from "redux";
import { IAuditType } from "../../types/audit.type";
import { TAuditTable } from "../../types/user.type";
import { ICleaner } from "./TaskTemplateActionTypes";

export const API_GET_ALL_AUDIT = "get_all_audit/LOAD";
export const API_GET_ALL_AUDIT_SUCCESS = "get_all_audit/LOAD_SUCCESS";
export const API_GET_ALL_AUDIT_FAIL = "get_all_audit/LOAD_FAIL";
export const API_ADD_INSPECTION = "audit/LOAD";
export const API_ADD_INSPECTION_SUCCESS = "audit/LOAD_SUCCESS";
export const API_ADD_INSPECTION_FAIL = "audit/LOAD_FAIL";
export const API_ADD_INSPECTION_PROGRESS = "API_ADD_INSPECTION_PROGRESS";
export const UPDATE_ADD_INSPECTION_DLG_STATUS =
  "UPDATE_ADD_INSPECTION_DLG_STATUS";
export const API_EDIT_INSPECTION = "edit_audit/LOAD";
export const API_EDIT_INSPECTION_SUCCESS = "edit_audit/LOAD_SUCCESS";
export const API_EDIT_INSPECTION_FAIL = "edit_audit/LOAD_FAIL";
export const API_EDIT_INSPECTION_PROGRESS = "API_EDIT_INSPECTION_PROGRESS";
export const UPDATE_EDIT_INSPECTION_DLG_STATUS =
  "UPDATE_EDIT_INSPECTION_DLG_STATUS";
export const API_DELETE_INSPECTION = "delete_audit/LOAD";
export const API_DELETE_INSPECTION_SUCCESS = "delete_audit/LOAD_SUCCESS";
export const API_DELETE_INSPECTION_FAIL = "delete_audit/LOAD_FAIL";
export const API_DELETE_AUDIT_COMMENTS = "delete_audit_comments/LOAD";
export const API_DELETE_AUDIT_COMMENTS_SUCCESS =
  "delete_audit_comments/LOAD_SUCCESS";
export const API_DELETE_AUDIT_COMMENTS_FAIL =
  "delete_audit_comments/LOAD_FAIL";
export const UPDATE_DELETE_INSPECTION_DLG_STATUS =
  "UPDATE_DELETE_INSPECTION_DLG_STATUS";
export const API_GET_INSPECTION_BY_ID = "audit_by_id/LOAD";
export const API_GET_INSPECTION_BY_ID_SUCCESS = "audit_by_id/LOAD_SUCCESS";
export const API_GET_INSPECTION_BY_ID_FAIL = "audit_by_id/LOAD_FAIL";
export const UPDATE_INSPECTION_FETCH_STATUS = "UPDATE_INSPECTION_FETCH_STATUS";
export const FILTER_INSPECTION = "FILTER_STATUS";
export const UPDATE_AUDIT = "UPDATE_AUDIT";
export const UPDATE_INSPECTION_DLG_STATUS = "UPDATE_INSPECTION_DLG_STATUS";
// export const API_DELETE_ATTACHMENT = "ATTACHMENT_DELETE";
export const API_VIEW_INSPECTION_BY_ID = "view_audit/LOAD";
export const API_VIEW_INSPECTION_BY_ID_SUCCESS = "view_audit/LOAD_SUCCESS";
export const API_VIEW_INSPECTION_BY_ID_FAIL = "view_audit/LOAD_FAIL";
export const UPDATE_VIEW_INSPECTION = "UPDATE_VIEW_INSPECTION";
export const INSPECTION_SORT = "INSPECTION_SORT";
export const API_ADD_INSPECTION_COMMENT = "audit_comments/LOAD";
export const API_ADD_INSPECTION_COMMENT_SUCCESS = "audit_comments/LOAD_SUCCESS";
export const API_ADD_INSPECTION_COMMENT_FAIL = "audit_comments/LOAD_FAIL";
export const API_GET_ALL_AUDIT_REPORT = "audit_report/LOAD";
export const API_GET_ALL_AUDIT_REPORT_SUCCESS = "audit_report/LOAD_SUCCESS";
export const API_GET_ALL_AUDIT_REPORT_FAIL = "audit_report/LOAD_FAIL";

export interface IAddAudit {
  client: string;
  attachments: any | Blob;
}

export interface IEditAudit {
  client: string;
  branch: string;
  title: string;
  is_active: string;
  attachments: any | Blob;
  description: string;
}

export interface AuditTemplateState {
  cleanerList: ICleaner[];
  addAuditStatus: boolean;
  editAuditStatus: boolean;
  deleteAuditStatus: boolean;
  auditFetchStatus: boolean;
  addAuditSuccess: string;
  addAuditError: string;
  editAuditSuccess: string;
  editAuditError: string;
  deleteAuditSuccess: string;
  deleteAuditError: string;
  selectedAuditData: IAuditType | null;
  id: string;
  auditData: TAuditTable[];
  filteredAuditData: TAuditTable[];
  total_count: number;
  processTrigger: boolean;
  progressPercentage: number;
  viewAuditData: IAuditType | null;
  viewAuditStatus: boolean;
  auditSortBy: string;
  auditOrderBy: string;
  addCommentStatus: boolean;
  comment_audit_delete: any;
  auditReportData: any;
}

export type AuditTemplateDispatchType = (args: AnyAction) => AnyAction;
