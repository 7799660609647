
export const GET_CLEANER_LOGIN_BRANCH_LIST = "get_cleaner_branch_list/LOAD";
export const GET_CLEANER_LOGIN_BRANCH_LIST_SUCCESS = "get_cleaner_branch_list/LOAD_SUCCESS";
export const GET_CLEANER_LOGIN_BRANCH_LIST_FAIL = "get_cleaner_branch_list/LOAD_FAIL";
export const GET_CLEANER_LOGIN_BRANCH_TASK_LIST = "get_cleaner_branch_task_list/LOAD";
export const GET_CLEANER_LOGIN_BRANCH_TASK_LIST_SUCCESS = "get_cleaner_branch_task_list/LOAD_SUCCESS";
export const GET_CLEANER_LOGIN_BRANCH_TASK_LIST_FAIL = "get_cleaner_branch_task_list/LOAD_FAIL";
export const GET_CLEANER_LOGIN_BRANCH_TASK_DETAILS = "get_cleaner_branch_task_details/LOAD";
export const GET_CLEANER_LOGIN_BRANCH_TASK_DETAILS_SUCCESS = "get_cleaner_branch_task_details/LOAD_SUCCESS";
export const GET_CLEANER_LOGIN_BRANCH_TASK_DETAILS_FAIL = "get_cleaner_branch_task_details/LOAD_FAIL";
export const UPDATE_VIEW_TASK_LIST_STATUS = "UPDATE_VIEW_TASK_LIST_STATUS";
export const UPDATE_VIEW_TASK_DETAILS_STATUS = "UPDATE_VIEW_TASK_DETAILS_STATUS";
export const UPDATE_ADD_EDIT_COMMENTS_STATUS = "UPDATE_ADD_EDIT_COMMENTS_STATUS";
export const API_CLEANER_ADD_COMMENT = "add_cleaner_comment/LOAD";
export const API_CLEANER_ADD_COMMENT_SUCCESS = "add_cleaner_comment/LOAD_SUCCESS";
export const API_CLEANER_ADD_COMMENT_FAIL = "add_cleaner_comment/LOAD_FAIL";
export const API_CLEANER_ADD_COMMENT_PROGRESS = "API_CLEANER_ADD_COMMENT_PROGRESS";
export const API_CLEANER_EDIT_COMMENT = "edit_cleaner_comment/LOAD";
export const API_CLEANER_EDIT_COMMENT_SUCCESS = "edit_cleaner_comment/LOAD_SUCCESS";
export const API_CLEANER_EDIT_COMMENT_FAIL = "edit_cleaner_comment/LOAD_FAIL";
export const API_CLEANER_EDIT_COMMENT_PROGRESS = "API_CLEANER_EDIT_COMMENT_PROGRESS";
export const EDIT_CLEANER_TASK_DETAILS_DATA = "EDIT_CLEANER_TASK_DETAILS_DATA";
export const API_CLEANER_DELETE_COMMENT = "API_CLEANER_DELETE_COMMENT";

export interface CleanerHomeState {
    getCleanerBranchListData:any;
    getCleanerBranchTaskListData:any;
    getCleanerBranchTaskDetailsData:any;
    viewTaskListStatus : boolean;
    viewTaskDetailsStatus : boolean;
    addEditTaskCommentsModalStatus : boolean;
    processTrigger : boolean;
    progressPercentage : number;
    getEditTaskDetailsData : any;
    deleteCleanerCommentsSuccessStatus : boolean;
}
