import * as Yup from "yup";

import { Button, Offcanvas, Toast } from "react-bootstrap";
import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { IContractorDashboardState, IContractorProps } from "../../types/contractor.type"
import {
  SortDown,
  SortReqular,
  SortUp,
  SuccessToastSVG,
} from "../../assets/images/ImagesV2";
import {
  filterContractor,
  getAllContractors,
  handleContractorSort,
  updateAddClientContractor,
  updateContractorFetchStatus,
  updateSidebar,
} from "../../shared/Reducers";

// import AddClientContractor from "./AddClientContractor";
import { CloseBtnSVG } from "../../assets/images/Location";
import Constants from "../../shared/Constants";
import ContractorTable from "./ContractorTable";
import { CustomSelect } from "../../shared/Components/CustomSelect";
import React from "react";
import ReactPaginate from "react-paginate";
import { RootState } from "../../index";
import { SearchSVG } from "../../assets/images/EmployeesSVG";
import SideBar from "../../components/sidebar/SideBar";
import TopNav from "../../components/topnav/TopNav";
import { connect } from "react-redux";

class Contractor extends React.Component<
  IContractorProps,
  IContractorDashboardState
> {
  constructor(props: IContractorProps) {
    super(props);
    this.state = {
      show: false,
      total_contractor: "",
      searchTerm: "",
      addToast: false,
      deleteToast: false,
      editToast: false,
      closeAdd: false,
      sortBy: null,
      sortDirection: null,
      pageOffset: Constants.OFFSET,
      addContractorStatus: false,
      fieldName: "",
      orderType: "",
      initialPagestate: 0,
      pagehandle: false,
      timeout: null,
    };
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount(): void {
    this.props.updateSidebar(true);
    document.title = "Contractor";
    this.getContractors(Constants.OFFSET, Constants.PER_PAGE);
  }

  componentDidUpdate(
    prevProps: Readonly<IContractorProps>,
    prevState: Readonly<IContractorDashboardState>,
    snapshot?: any
  ): void {
    if(prevProps.contractorSortBy !== this.props.contractorSortBy || prevProps.contractorOrderBy !== this.props.contractorOrderBy){
      this.getContractors(Constants.OFFSET, Constants.PER_PAGE);
    }

    if (prevProps.addContractorStatus !== this.props.addContractorStatus) {
      if (!this.props.addContractorStatus) {
        this.getContractors(Constants.OFFSET, Constants.PER_PAGE);
      }
    }

    if (prevProps.contractorFetchStatus !== this.props.contractorFetchStatus) {
      if (this.props.contractorFetchStatus) {
        this.getContractors(Constants.OFFSET, Constants.PER_PAGE);
      }
    }
    if (prevProps.addContractorSuccess !== this.props.addContractorSuccess) {
      if (this.props.addContractorSuccess === "Successfully created") {
        this.setState({ addToast: true });
        this.setState({ closeAdd: false });
        setTimeout(() => {
          this.setState({ addToast: false });
          this.setState({ closeAdd: false });
        }, 5000);
      }
    }
    if (prevProps.editContractorSuccess !== this.props.editContractorSuccess) {
      if (this.props.editContractorSuccess === "Successfully updated") {
        this.setState({ editToast: true });
        setTimeout(() => {
          this.setState({ editToast: false });
        }, 5000);
      }
    }
    if (prevProps.deleteContractorSuccess !== this.props.deleteContractorSuccess) {
      
      if (this.props.deleteContractorSuccess == "Successfully deleted") {
        this.setState({ deleteToast: true });
        setTimeout(() => {
          this.setState({ deleteToast: false });
        }, 5000);
        //page reset 
        
        let Offset: number = 0;
        let paginationActive = document.querySelector(
          ".pagination li.active a"
        ) as HTMLInputElement;
        if (paginationActive !== null) {
          let page: string | null = paginationActive.textContent;
          if (page !== null) {
            let pageNum: number = parseInt(page);
            Offset = (pageNum - 1) * Constants.PER_PAGE;
            console.log(Offset, Constants.PER_PAGE);
            
            this.getContractors(Offset, Constants.PER_PAGE);
          }
        }

      }
    }
  }
  deleteClose = () => {
    console.log("call");
    this.setState({ deleteToast: false });
  };
  addToastClose = () => {
    this.setState({ addToast: false });
  };

  editToastClose = () => {
    this.setState({ editToast: false });
  };

  deleteToastClose = () => {
    this.setState({ deleteToast: false });
  };

  getContractors = async (offset: number, limit: number) => {
    try {
      const query = `search=&sort=${this.props.contractorSortBy}&order=${this.props.contractorOrderBy}&offset=${offset}&limit=${limit}&client=all&branch=all`;
      this.props.getAllContractors(query);
    } catch (error) {
      console.log(error);
    }
  };

  handleAddClientContractorCLose = (status: boolean) => {
    this.handleClose();
    this.getContractors(Constants.OFFSET, Constants.PER_PAGE);

    // if (status) {
    //   this.getContractors(Constants.OFFSET, Constants.PER_PAGE);
    // }
  };

  // Canvas Show
  handleShow() {
    // this.props.updateAddClientContractor(true);
    this.setState({ addContractorStatus: true });
  }

  // Canvas Close
  handleClose() {
    // this.props.updateAddClientContractor(false);
    this.setState({ addContractorStatus: false });
  }

  // Search Branch Filter
  handleFilter = async (e: any) => {
    const value: string = e.target.value.toLowerCase();
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

  
    this.setState({
      searchTerm: value,
      timeout: setTimeout(async () => {
        console.log("User stopped typing:", value);
        try {
          const query = `search=${encodeURIComponent(value)}&sort=${this.props.contractorSortBy}&order=${this.props.contractorOrderBy}&offset=0&limit=10&client=all&branch=all`;
          this.props.getAllContractors(query);
        } catch (error) {
          console.log(error);
        }
        // Trigger your function here that you want to call when user stops typing
      }, 1000), // Set the delay time in ms
    });
  };

  handlePageClick = (e: any) => {
    if (e.selected < this.props.total_count / Constants.PER_PAGE) {
      const query = `search=${this.state.searchTerm}&sort=${
        this.state.fieldName
      }&order=${this.state.orderType}&offset=${
        e.selected * Constants.PER_PAGE
      }&limit=${Constants.PER_PAGE}&client=all&branch=all`;
      this.props.getAllContractors(query);
      // this.getContractors(e.selected * Constants.PER_PAGE, Constants.PER_PAGE);
    }
  };

  handleSort = (column: string) => {
    if (this.state.sortBy === column) {
      this.setState({
        sortDirection: this.state.sortDirection === "asc" ? "desc" : "asc",
      });
      this.props.handleContractorSort(
        column,
        this.state.sortDirection === "asc" ? "desc" : "asc"
      );
    } else {
      this.setState({ sortBy: column });
      this.setState({ sortDirection: "asc" });
      this.props.handleContractorSort(column, "asc");
    }
  };

  validationSchema = () => {
    return Yup.object().shape({
      column_name: Yup.string().required("Select Filter Column"),
      order_type: Yup.string().required("Select Sort Order"),
    });
  };
  handleSearch = async (formValue: any) => {
    try {
      this.setState({ pagehandle: true }, () => {
        setTimeout(async () => {
          this.setState({
            fieldName: formValue.column_name,
            orderType: formValue.order_type,
            pagehandle: false,
          });
          const query = `search=&sort=${formValue.column_name}&order=${formValue.order_type}&offset=${Constants.OFFSET}&limit=${Constants.PER_PAGE}&client=all&branch=all`;
          await this.props.getAllContractors(query);
        }, 0);
      });
    } catch (error) {
      console.log(error);
    }
  };

  render(): React.ReactNode {
    let taskStatusOption: any = [];
    taskStatusOption = [
      { value: "first_name", label: "Client" },
      { value: "start_date", label: "Start Date" },
      { value: "end_date", label: "End Date" },
    ];
    let contractorOrder: any = [];
    contractorOrder = [
      { value: "asc", label: "Ascending" },
      { value: "desc", label: "Descending" },
    ];

    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className={this.props.status ? "main-panel" : "main-panel2"}>
          <TopNav />
          <div className="content-wrapper">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="mb-0">Contractor contact details</h3>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-end">
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12 col-lg-4">
                  <div className="input-group search">
                    <span
                      className="input-group-text search-icon"
                      id="basic-addon1"
                    >
                      <SearchSVG />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={this.state.searchTerm}
                      onInput={this.handleFilter}
                      onChange={(e) =>
                        this.setState({ searchTerm: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      {this.props.filteredContractorData.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table employee-table">
                            <thead>
                              <tr>
                                <th className="text-uppercase">First Name</th>
                                <th className="text-uppercase">Last Name</th>
                                <th className="text-uppercase">Business Name</th>
                                <th className="text-uppercase">Email Address</th>
                                <th className="text-uppercase">Mobile</th>
                                <th className="text-uppercase">Contract Date</th>
                                <th className="text-uppercase">Make PDF</th>
                                <th className="text-uppercase">View Details</th>
                              </tr>
                            </thead>
                            <ContractorTable
                              data={this.props.filteredContractorData}
                              onDelete={() =>
                                this.getContractors(
                                  Constants.OFFSET,
                                  Constants.PER_PAGE
                                )
                              }
                              onEditClose={() =>
                                this.getContractors(
                                  Constants.OFFSET,
                                  Constants.PER_PAGE
                                )
                              }
                              deleteContractorStatus={false}
                            />
                          </table>
                        </div>
                      ) : (
                        <p className="text-center">
                          There is no contractor record found
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                {this.props.total_count > 10 ? (
                  <div className="col-md-12">
                    {this.state.pagehandle ? null : (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.props.total_count / Constants.PER_PAGE}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        nextClassName={"next-page"}
                        activeClassName={"active"}
                      />
                    )}
                  </div>
                ) : null}
              </div>
              {this.state.deleteToast ? (
                  <Toast className="success-alerts">
                    <Toast.Body>
                      <div className="row align-items-center">
                        <div className="col-md-2">
                          <SuccessToastSVG />
                        </div>
                        <div className="col-md-8">
                          {this.props.deleteContractorSuccess}
                        </div>
                        <div className="col-md-2">
                          <button
                            type="button"
                            className="btn"
                            onClick={this.deleteClose}
                          >
                            <CloseBtnSVG />
                          </button>
                        </div>
                      </div>
                    </Toast.Body>
                  </Toast>
                ) : (
                  ""
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  addContractorStatus: state.contractor.addContractorStatus,
  contractorFetchStatus: state.contractor.contractorFetchStatus,
  contractorData: state.contractor.contractorData,
  filteredContractorData: state.contractor.filteredContractorData,
  total_count: state.contractor.total_count,
  addContractorSuccess: state.contractor.addContractorSuccess,
  editContractorSuccess: state.contractor.editContractorSuccess,
  deleteContractorSuccess: state.contractor.deleteContractorSuccess,
  contractorSortBy: state.contractor.contractorSortBy,
  contractorOrderBy: state.contractor.contractorOrderBy,
  status: state.sidebar.status,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateAddClientContractor: bindActionCreators(updateAddClientContractor, dispatch),
    updateContractorFetchStatus: bindActionCreators(
      updateContractorFetchStatus,
      dispatch
    ),
    filterContractor: bindActionCreators(filterContractor, dispatch),
    getAllContractors: bindActionCreators(getAllContractors, dispatch),
    handleContractorSort: bindActionCreators(handleContractorSort, dispatch),
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Contractor);
