import * as Yup from "yup";

import { Button, Offcanvas, Toast } from "react-bootstrap";
import { Dispatch, bindActionCreators } from "redux";
import { Field, Form, Formik } from "formik";
import {
  IComplaintDashboardState,
  IComplaintProps,
} from "../../types/complaint.type";
import { SuccessToastSVG } from "../../assets/images/ImagesV2";
import {
  filterComplaint,
  getAllComplaints,
  handleComplaintSort,
  updateAddComplaint,
  updateComplaintFetchStatus,
  updateSidebar,
} from "../../shared/Reducers";

import AddComplaint from "./AddComplaint";
import { CloseBtnSVG } from "../../assets/images/Location";
import Constants from "../../shared/Constants";
import ComplaintTable from "./ComplaintTable";
import { CustomSelect } from "../../shared/Components/CustomSelect";
import React from "react";
import ReactPaginate from "react-paginate";
import { RootState } from "../../index";
import { SearchSVG } from "../../assets/images/EmployeesSVG";
import SideBar from "../../components/sidebar/SideBar";
import TopNav from "../../components/topnav/TopNav";
import { connect } from "react-redux";

class Complaint extends React.Component<
  IComplaintProps,
  IComplaintDashboardState
> {
  constructor(props: IComplaintProps) {
    super(props);
    this.state = {
      show: false,
      total_complaint: "",
      searchTerm: "",
      addToast: false,
      deleteToast: false,
      editToast: false,
      closeAdd: false,
      sortBy: null,
      sortDirection: null,
      pageOffset: Constants.OFFSET,
      pagehandle: false,
      timeout: "",
      filterOption: "modified_at",
      orderOption: "desc",
    };
  }

  componentDidMount(): void {
    this.props.updateSidebar(true);
    document.title = "Complaint";
    this.getComplaints(Constants.OFFSET, Constants.PER_PAGE);
  }

  componentDidUpdate(
    prevProps: Readonly<IComplaintProps>,
    prevState: Readonly<IComplaintDashboardState>,
    snapshot?: any
  ): void {
    if (prevProps.addComplaintStatus !== this.props.addComplaintStatus) {
      if (!this.props.addComplaintStatus) {
        this.getComplaints(Constants.OFFSET, Constants.PER_PAGE);
      }
    }

    if (prevProps.complaintFetchStatus !== this.props.complaintFetchStatus) {
      if (this.props.complaintFetchStatus) {
        this.getComplaints(Constants.OFFSET, Constants.PER_PAGE);
      }
    }
    if (prevProps.addComplaintSuccess !== this.props.addComplaintSuccess) {
      if (this.props.addComplaintSuccess === "Successfully created") {
        this.setState({ addToast: true });
        this.setState({ closeAdd: false });
        setTimeout(() => {
          this.setState({ addToast: false });
          this.setState({ closeAdd: false });
        }, 5000);
      }
    }
    if (prevProps.editComplaintSuccess !== this.props.editComplaintSuccess) {
      if (this.props.editComplaintSuccess === "Successfully updated") {
        this.setState({ editToast: true });
        setTimeout(() => {
          this.setState({ editToast: false });
        }, 5000);
      }
    }
    if (
      prevProps.deleteComplaintSuccess !== this.props.deleteComplaintSuccess
    ) {
      if (this.props.deleteComplaintSuccess === "Successfully deleted") {
        this.setState({ deleteToast: true });
        setTimeout(() => {
          this.setState({ deleteToast: false });
        }, 5000);
      }
    }
  }

  addToastClose = () => {
    this.setState({ addToast: false });
  };

  editToastClose = () => {
    this.setState({ editToast: false });
  };

  deleteToastClose = () => {
    this.setState({ deleteToast: false });
  };

  getComplaints = async (offset: number, limit: number) => {
    try {
      await this.props.handleComplaintSort("modified_at", "desc");
      const query = `search=${this.state.searchTerm}&sort=${this.props.complaintSortBy}&order=${this.props.complaintOrderBy}&offset=${offset}&limit=${limit}&client=all&branch=all&user_id=${Constants.LOGIN_USER_ID}&user_type=${Constants.LOGIN_USER_TYPE}&all=0`;
      await this.props.getAllComplaints(query);
    } catch (error) {
      console.log(error);
    }
  };

  handleAddComplaintCLose = (status: boolean) => {
    this.handleClose();
    if (status) {
      this.getComplaints(Constants.OFFSET, Constants.PER_PAGE);
    }
  };

  // Canvas Show
  handleShow() {
    this.props.updateAddComplaint(true);
  }

  // Canvas Close
  handleClose() {
    this.props.updateAddComplaint(false);
  }

  // Search Branch Filter
  handleFilter = (e: any) => {
    const filterVal: string = e.target.value.toLowerCase();
    console.log(filterVal);
    // let query: any = `search=${filterVal}&sort=${
    //   this.props.complaintSortBy
    // }&order=${
    //   this.props.complaintOrderBy
    // }&offset=${0}&limit=${10}&client=all&branch=all`;
    // this.props.getAllComplaints(query);
    // console.log(this.props.filteredComplaintData);
    const value: string = e.target.value.toLowerCase();
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

    this.setState({
      searchTerm: value,
      timeout: setTimeout(async () => {
        try {
          const query = `search=${this.state.searchTerm}&sort=${this.state.filterOption}&order=${this.state.orderOption}&offset=0&limit=10&client=all&branch=all`;
          this.props.getAllComplaints(query);
        } catch (error) {
          console.log(error);
        }
        // Trigger your function here that you want to call when user stops typing
      }, 1000), // Set the delay time in ms
    });
  };

  handlePageClick = async (e: any) => {
    console.log(e);
    if (e.selected < this.props.total_count / Constants.PER_PAGE) {
      await this.props.handleComplaintSort("modified_at", "desc");
      const query = `search=${this.state.searchTerm}&sort=${
        this.props.complaintSortBy
      }&order=${this.props.complaintOrderBy}&offset=${
        e.selected * Constants.PER_PAGE
      }&limit=${Constants.PER_PAGE}&client=all&branch=all`;
      await this.props.getAllComplaints(query);
      // this.getComplaints(e.selected * Constants.PER_PAGE, Constants.PER_PAGE);
    }
  };

  handleSort = (column: string) => {
    if (this.state.sortBy === column) {
      this.setState({
        sortDirection: this.state.sortDirection === "asc" ? "desc" : "asc",
      });
      this.props.handleComplaintSort(
        column,
        this.state.sortDirection === "asc" ? "desc" : "asc"
      );
    } else {
      this.setState({ sortBy: column });
      this.setState({ sortDirection: "asc" });
      this.props.handleComplaintSort(column, "asc");
    }
  };

  validationSchema = () => {
    return Yup.object().shape({
      column_name: Yup.string().required("Select Filter Column"),
      order_type: Yup.string().required("Select Sort Order"),
    });
  };
  handleSearch = (formValue: any) => {
    try {
      this.setState({ pagehandle: true }, () => {
        this.setState({
          pagehandle: false,
          filterOption: formValue.column_name,
          orderOption: formValue.order_type,
        });
        const query = `search=${this.state.searchTerm}&sort=${formValue.column_name}&order=${formValue.order_type}&offset=${Constants.OFFSET}&limit=${Constants.PER_PAGE}&client=all&branch=all`;
        this.props.getAllComplaints(query);
      });
    } catch (error) {
      console.log(error);
    }
  };

  render(): React.ReactNode {
    let taskStatusOption: any = [];
    taskStatusOption = [
      { value: "name", label: "Complaint Name" },
      { value: "user_first_name", label: "User" },
      { value: "client_first_name", label: "Client" },
      { value: "branch_name", label: "Branch" },
    ];
    let complaintOrder: any = [];
    complaintOrder = [
      { value: "asc", label: "Ascending" },
      { value: "desc", label: "Descending" },
    ];
    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className={this.props.status ? "main-panel" : "main-panel2"}>
          <TopNav />
          <div className="content-wrapper">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="mb-0">Complaint management</h3>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-end">
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12 col-lg-4">
                  <div className="input-group search">
                    <span
                      className="input-group-text search-icon"
                      id="basic-addon1"
                    >
                      <SearchSVG />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={this.state.searchTerm}
                      onInput={this.handleFilter}
                      onChange={(e) =>
                        this.setState({ searchTerm: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12 col-lg-6">
                  <div>
                    <Formik
                      validationSchema={this.validationSchema}
                      onSubmit={this.handleSearch}
                      initialValues={{
                        column_name: "",
                        order_type: "asc",
                      }}
                    >
                      <Form className="d-flex flex-column flex-md-row">
                        <Field
                          className="custom-select dropdown-field flex-mr-16px w-180px"
                          name="column_name"
                          options={taskStatusOption}
                          component={CustomSelect}
                          placeholder="Select Column"
                          isMulti={false}
                        />
                        <Field
                          className="custom-select dropdown-field flex-mr-16px res-mt-3 w-180px"
                          name="order_type"
                          options={complaintOrder}
                          component={CustomSelect}
                          placeholder="Select Order"
                          isMulti={false}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary filter-button res-mt-3"
                        >
                          Filter
                        </button>
                      </Form>
                    </Formik>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 text-start text-lg-end tab-res-mt-3">
                  <Offcanvas
                    className="complaint-offcanvas"
                    placement="end"
                    show={this.props.addComplaintStatus}
                    onHide={() => this.handleClose()}
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>
                        <span className="offc-title">Add Complaint</span>
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <AddComplaint onClose={this.handleAddComplaintCLose} />
                    </Offcanvas.Body>
                  </Offcanvas>
                  <Button
                    aria-controls="offcanvasRight"
                    className="btn btn-primary login-button add-complaint-btn mob-res-w-100 res-mt-3"
                    onClick={() => this.handleShow()}
                  >
                    Add Complaint
                  </Button>
                  {this.state.addToast ? (
                    <Toast className="success-alerts">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <SuccessToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.addComplaintSuccess}
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.addToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                  {this.state.editToast ? (
                    <Toast className="success-alerts">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <SuccessToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.editComplaintSuccess}
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.editToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                  {this.state.deleteToast ? (
                    <Toast className="success-alerts">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <SuccessToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.deleteComplaintSuccess}
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.deleteToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      {this.props.filteredComplaintData.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table employee-table">
                            <thead>
                              <tr>
                                <th>Complaint Name</th>
                                <th>User</th>
                                <th>Client</th>
                                <th>Branch</th>
                                <th>Complaint status</th>
                                <th>Created date</th>
                                <th>Chat</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <ComplaintTable
                              data={this.props.filteredComplaintData}
                              onDelete={() =>
                                this.getComplaints(
                                  Constants.OFFSET,
                                  Constants.PER_PAGE
                                )
                              }
                              onEditClose={() =>
                                this.getComplaints(
                                  Constants.OFFSET,
                                  Constants.PER_PAGE
                                )
                              }
                              deleteComplaintStatus={false}
                              onChatClose={() =>
                                this.getComplaints(
                                  Constants.OFFSET,
                                  Constants.PER_PAGE
                                )
                              }
                            />
                          </table>
                        </div>
                      ) : (
                        <p className="text-center">
                          There is no complaint records found
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                {this.props.total_count > 10 ? (
                  <div className="col-md-12">
                    {this.state.pagehandle ? null : (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.props.total_count / Constants.PER_PAGE}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        nextClassName={"next-page"}
                        activeClassName={"active"}
                      />
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  addComplaintStatus: state.complaint.addComplaintStatus,
  chatComplaintStatus: state.complaint.chatComplaintStatus,
  complaintFetchStatus: state.complaint.complaintFetchStatus,
  complaintData: state.complaint.complaintData,
  filteredComplaintData: state.complaint.filteredComplaintData,
  total_count: state.complaint.total_count,
  addComplaintSuccess: state.complaint.addComplaintSuccess,
  editComplaintSuccess: state.complaint.editComplaintSuccess,
  deleteComplaintSuccess: state.complaint.deleteComplaintSuccess,
  complaintSortBy: state.complaint.complaintSortBy,
  complaintOrderBy: state.complaint.complaintOrderBy,
  status: state.sidebar.status,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateAddComplaint: bindActionCreators(updateAddComplaint, dispatch),
    updateComplaintFetchStatus: bindActionCreators(
      updateComplaintFetchStatus,
      dispatch
    ),
    filterComplaint: bindActionCreators(filterComplaint, dispatch),
    getAllComplaints: bindActionCreators(getAllComplaints, dispatch),
    handleComplaintSort: bindActionCreators(handleComplaintSort, dispatch),
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Complaint);
