import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from '../../index';
import { changeAddEditStatusCommetsStatus, changeViewTaskDetailsStatus, changeEditTaskDetailsData, deleteCleanerComments } from '../../shared/Reducers';
import Constants from "../../shared/Constants";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";
import docImage from "../../assets/images/google-docs.png";
import AttachmentPreview from "../../shared/Components/AttachmentPreview";
import { AttachmentDownloadSVG } from "../../assets/images/ImagesSVG";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import { CloseBtnSVG } from "../../assets/images/Location";

export interface IDisplayCommentsCleanerHomeProps { 
    data : any;
    changeViewTaskDetailsStatus? : any;
    changeEditTaskDetailsData? : any;
    changeAddEditStatusCommetsStatus ? : any;
    deleteCleanerComments? :any;
}

export interface IDisplayCommentsCleanerHomeState {
    AttachmentPreviewStatus : boolean,
    previewUrl  : string,
    previewType : string,
    displayDelete : boolean,
    deleteCommentsId : number,
}

class DisplayCommentsCleanerHome extends React.Component<IDisplayCommentsCleanerHomeProps, IDisplayCommentsCleanerHomeState> {
    constructor(props: IDisplayCommentsCleanerHomeProps) {
        super(props);
        this.state = {
            AttachmentPreviewStatus : false,
            previewUrl  : "",
            previewType : "",
            displayDelete : false,
            deleteCommentsId : 0
        }
    }

    componentDidMount(): void {
        // console.log(this.props.data);
    }
    downloadFile = (url: string, name = 'image.png') => {
        this.downloadResource(url, name);
    }
    downloadResource = (url: any, filename: any) => {
        if (url) {
            if (!filename) filename = url.split('\\').pop().split('/').pop();
            fetch(url, {
                headers: new Headers({
                    'Origin': window.location.origin
                }),
                mode: 'cors'
            })
                .then(response => response.blob())
                .then(blob => {
                    let blobUrl = window.URL.createObjectURL(blob);
                    this.forceDownload(blobUrl, filename);
                })
                .catch(e => console.error(e));
        }
    }
    forceDownload = (blob: any, filename: String) => {
        const a: any = document.createElement('a');
        a.download = filename;
        a.href = blob;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }
    handlePreviewClose = () => {
        this.setState({ AttachmentPreviewStatus : false });
    }
    handlePreviewOpen = (file_type:any, url:any) => {
        if(file_type === "image/png" || file_type === "image/jpeg" || file_type === "image/jpg" || file_type === "video/mp4" || file_type === "video/webm" || file_type === "application/pdf"){
            this.setState({ 
                AttachmentPreviewStatus : true,
                previewUrl  : url,
                previewType : file_type
            });
        }
    }

    handleDeleteModalStatus = (status:boolean) => {
        this.setState({ displayDelete : status });
    }

    deleteCleanerComments = () => {

        this.props.deleteCleanerComments(this.state.deleteCommentsId);

        var element = document.getElementById("spinner-border-delete");
        var button = document.getElementById("delete-button");
        if (element !== null && button !== null) {
            element.classList.remove("d-none");
            button.setAttribute('disabled', '');
        }

        setTimeout(() => {
            this.setState({ displayDelete : false, deleteCommentsId : 0 });
        }, 500);
    }
    render(): React.ReactNode {
        const initialValues = {
            comments: "",
            task_status: "",
            task_list: "",
            attachments: ""
        }
        return (
            <div>
                {this.props.data &&
                    this.props.data.map((row: any, index: number) => {
                        return (
                            <div className="mt-3">
                                <div id={"comments-details-"+index} className="comments-details">
                                    <p className="mb-2"><span className="fw-600">{row.first_name} {row.last_name}</span> <span className="text-secondary font-weight-light">{moment(`${row.created_at}`).format('MMMM DD,YYYY')} at {moment(`${row.created_at}`).format('hh:mm A')}</span></p>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="d-flex row">
                                                {row.attachment &&
                                                row.attachment.map((obj: any, index: number) => (
                                                    <div className="img-card mr-16px mb-3">
                                                        <span className="download">
                                                            <a href="javascript:void(0)" onClick={() => this.downloadFile(Constants.imgUrl+obj.file_name, obj.file_name)}><AttachmentDownloadSVG /></a>
                                                        </span>
                                                        <div className="img-preview" onClick={() => this.handlePreviewOpen(obj.type,Constants.imgUrl+obj.file_name)}>
                                                            <img className="img-thumb-perview" src={
                                                                obj.type === "application/pdf" ? pdfImage : 
                                                                obj.type === "video/mp4" || obj.type === "video/webm" ? videoImage : 
                                                                obj.type === "image/png" || obj.type === "image/jpeg" || obj.type === "image/jpg" ? Constants.imgThumbUrl+obj.file_name : docImage
                                                            } />
                                                        </div>
                                                    </div>
                                            ))}
                                            </div>
                                        </div>
                                    </div>
                                    <p>{row.comments}</p>
                                    <p
                                        className={row.task_status+ "-badge view-task-comment-badge"}
                                    >
                                        {row.task_status}
                                    </p>
                                    {Constants.LOGIN_USER_ID === row.user_id && (
                                        <p>
                                            <span>
                                                <a className="terms cursor-pointer"  onClick={() => {
                                                        this.props.changeEditTaskDetailsData(row);
                                                        this.props.changeViewTaskDetailsStatus(false);
                                                        this.props.changeAddEditStatusCommetsStatus(true)
                                                    }}>Edit . </a>
                                                <a href="javascript:void(0)" className="terms cursor-pointer"  onClick={() => {
                                                    this.handleDeleteModalStatus(true);
                                                    this.setState({ deleteCommentsId : row.id });
                                                }}>Delete</a>
                                            </span>
                                        </p>
                                    )}
                                    <hr></hr>
                                </div>
                            </div>
                        )
                    })
                }

                <AttachmentPreview displayPreview={this.state.AttachmentPreviewStatus} previewUrl={this.state.previewUrl} previewType={this.state.previewType} handlePreviewClose={this.handlePreviewClose}/>

                <Modal className="delete-modal" show={this.state.displayDelete} onHide={() => this.handleDeleteModalStatus(false)}>
                    <Modal.Body>
                        <a href="javascript:void(0)" className="qr-modal-close" onClick={() => this.handleDeleteModalStatus(false)}>
                            <CloseBtnSVG />
                        </a>

                        <div className="delete-modal-body">
                            <h3 className="mb-3">Delete this comments</h3>
                            <p>Do you want to delete the comment</p>
                        </div>

                        <div className="delete-footer qr-modal-footer d-flex justify-content-end pt-2">
                            <Button className="btn btn-outline-success delete-cancel" variant="outline" onClick={() => this.handleDeleteModalStatus(false)}>Cancel</Button>
                            <Button className="delete-cancel delete-button" id="delete-button" variant="primary" onClick={() => this.deleteCleanerComments()}>Delete <span id="spinner-border-delete" className="spinner-border spinner-border-sm d-none"></span></Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = (state: RootState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        changeViewTaskDetailsStatus : bindActionCreators(changeViewTaskDetailsStatus, dispatch),
        changeEditTaskDetailsData : bindActionCreators(changeEditTaskDetailsData, dispatch),
        deleteCleanerComments : bindActionCreators(deleteCleanerComments, dispatch),
        changeAddEditStatusCommetsStatus: bindActionCreators(changeAddEditStatusCommetsStatus, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplayCommentsCleanerHome);