import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  updateEditComplaintDlgStatus,
  updateChatComplaint,
  getComplaintByIdForChat,
} from "../../shared/Reducers";
import DeleteComplaint from "./DeleteComplaint";
import {
  IComplaintCardProps,
  IComplaintCardState,
} from "../../types/complaint.type";
import moment from "moment";
import { TableDeleteSVG, TableEditSVG } from "../../assets/images/ImagesV2";
import {
  OpenComplaintStatus,
  CloseComplaintStatus,
} from "../../assets/images/ImagesV2";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

class ComplaintCard extends React.Component<
  IComplaintCardProps,
  IComplaintCardState
> {
  constructor(props: IComplaintCardProps) {
    super(props);
    this.state = {
      show: false,
      displayDelete: false,
      currentComplaintId: "",
      editShow: false,
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  // Delete complaint based on ID
  handleDelete = (id: string) => {
    this.setState({ displayDelete: true, currentComplaintId: id });
  };

  // delete popup close
  handleDeleteClose = (status: boolean) => {
    this.setState({ displayDelete: false, currentComplaintId: "" });
    if (status) {
      this.props.handleDeleteComplaint(this.state.currentComplaintId);
    }
  };

  render(): React.ReactNode {
    const {
      id,
      name,
      user_first_name,
      user_last_name,
      branch_name,
      client_first_name,
      client_last_name,
      complaint_status,
      unread_messages_count,
      created_at,
    } = this.props.complaints;
    return (
      <tr>
        <td>{name}</td>
        <td>{user_first_name + " " + user_last_name}</td>
        <td>{client_first_name + " " + client_last_name}</td>
        <td>{branch_name}</td>
        <td>
          {complaint_status === 1 ? (
            <OpenComplaintStatus />
          ) : (
            <CloseComplaintStatus />
          )}
        </td>
        <td>{moment(created_at).format("DD-MM-YYYY")}</td>
        <td>
          <Button
            className="chat-btn d-flex justify-content-between align-items-center"
            onClick={() => this.props.getComplaintByIdForChat(id)}
          >
            <span>Chat</span>
            {unread_messages_count === "0" ? (
              ""
            ) : (
              <span className="chat-count">{unread_messages_count}</span>
            )}
          </Button>
        </td>
        <td>
          <div className="btn-group">
            <span className="mr-20px">
              <a
                href="javascript:void(0)"
                onClick={() => this.props.handleEditComplaint(id)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"Edit"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableEditSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
            <span>
              <a
                href="javascript:void(0)"
                onClick={() => this.handleDelete(id)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"Delete"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableDeleteSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
            <DeleteComplaint
              displayDelete={this.state.displayDelete}
              deletedName={name}
              handleDeleteClose={this.handleDeleteClose}
            />
          </div>
        </td>
      </tr>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  editComplaintStatus: state.complaint.editComplaintStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateEditComplaintDlgStatus: bindActionCreators(
      updateEditComplaintDlgStatus,
      dispatch
    ),
    updateChatComplaint: bindActionCreators(updateChatComplaint, dispatch),
    getComplaintByIdForChat: bindActionCreators(
      getComplaintByIdForChat,
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ComplaintCard);
