import React from "react";
import { Modal, Button } from "react-bootstrap";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { CloseBtnSVG } from "../../assets/images/Location";
import { IDeleteUserProps } from "../../types/task.type";

class DeleteDailyTask extends React.Component<IDeleteUserProps> {
  render(): React.ReactNode {
    return (
      <div>
        <Modal
          className="delete-modal"
          show={this.props.displayDelete}
          onHide={() => this.props.handleDeleteClose(false)}
        >
          <Modal.Body>
            <a
              href="javascript:void(0)"
              className="qr-modal-close"
              onClick={() => this.props.handleDeleteClose(false)}
            >
              <CloseBtnSVG />
            </a>

            <div className="delete-modal-body">
              <h3 className="mb-3">Delete Task</h3>
              <p>
                Do you want to delete the{" "}
                <span className="font-weight-bold">
                  {this.props.deletedName}
                </span>
              </p>
            </div>

            <div className="delete-footer qr-modal-footer d-flex justify-content-end pt-2">
              <Button
                className="btn btn-outline-success delete-cancel"
                variant="outline"
                onClick={() => this.props.handleDeleteClose(false)}
              >
                Cancel
              </Button>
              <Button
                className="delete-cancel delete-button"
                variant="primary"
                onClick={() => this.props.handleDeleteClose(true)}
              >
                Delete
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(DeleteDailyTask);
