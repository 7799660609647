import React from "react";
import dayjs, { Dayjs } from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Stack from "@mui/material/Stack";
import moment from "moment";
import { RootState } from '../../index';
import { connect } from "react-redux";
import { Dispatch } from "redux";


class DatePicker extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            value: this.getDate(props)
        }
    }

    getDate(props:any){
        if(props.value){
            let DateArr = props.value.split('-');
            if(DateArr){
                return dayjs().set('date',DateArr[2]).set('month',+DateArr[1]-1).set('year',DateArr[0])
            }
        }
        return dayjs();
    }

    // Date Change
    handleChange = (newValue: Dayjs | null) => {
        this.setState({ value: newValue });
        if (newValue) {
            this.props.onChange(moment(new Date(newValue?.toString())).format("YYYY-MM-DD"));
        }
    }
    render(): React.ReactNode {
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                    <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        value={this.state.value}
                        onChange={this.handleChange}
                        minDate={dayjs()}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </Stack>
            </LocalizationProvider>
        )
    }
}
const mapStateToProps = (state: RootState) => ({
    
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker);