import axios from "axios";
import Constants from "../shared/Constants";

const API_URL = Constants.baseUrl;

class AuthService {
  async signin(email: string, password: string) {
    const response = await axios.post(API_URL + "auth/login", {
      email,
      password,
    });
    if (
      "data" in response &&
      "refresh_token" in response.data &&
      response.data.refresh_token
    ) {
      localStorage.setItem("user", JSON.stringify(response.data));
    } else {
      throw new Error(
        "data" in response &&
          "message" in response.data &&
          response.data.message
          ? response.data.message
          : "Login Failed"
      );
    }
    return response.data;
  }

  logout() {
    localStorage.removeItem("subAdmin");
    localStorage.removeItem("user");
  }

  signup(
    first_name: string,
    last_name: string,
    email: string,
    phone: string,
    company: string,
    address: string,
    password: string
  ) {
    return axios.post(API_URL + "register", {
      first_name,
      last_name,
      email,
      phone,
      company,
      address,
      password,
    });
  }

  getCurrentuser() {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);

    return null;
  }

  forgotpassword(email: string) {
    return axios.post(API_URL + "forgot-password", {
      email,
    });
  }

  changePassword(old_password: string, new_password: string) {
    return axios.put(API_URL + "users/update-password", {
      old_password,
      new_password,
    });
  }

  resetPassword(x_auth_token: string, password: string) {
    return axios.put(API_URL + "reset-password", {
      x_auth_token,
      password,
    });
  }

  activateAcc(id: string, x_auth_token: string) {
    return axios.post(API_URL + "activate-account", {
      id,
      x_auth_token,
    })
  }
}

export default new AuthService();
