import { AnyAction } from "redux";
import { IBranchSelect } from "./TaskTemplateActionTypes";
import { TUsermanagementTable } from "../../types/user.type";

export const USERMANAGEMENT_SELECTION = "USERMANAGEMENT_SELECTION";
export const UPDATE_USERMANAGEMENT = "UPDATE_USERMANAGEMENT";
export const API_GET_ALL_USERMANAGEMENTS = "get_all_USERMANAGEMENTs/LOAD";
export const API_GET_ALL_USERMANAGEMENTS_SUCCESS =
  "get_all_USERMANAGEMENTs/LOAD_SUCCESS";
export const API_GET_ALL_USERMANAGEMENTS_FAIL =
  "get_all_USERMANAGEMENTs/LOAD_FAIL";
export const API_GET_USERMANAGEMENTS = "users/LOAD";
export const API_GET_branch = "users/branch/drop-down-list/";
export const API_GET_USERMANAGEMENTS_SUCCESS = "users/LOAD_SUCCESS";
export const API_GET_USERMANAGEMENTS_FAIL = "users/LOAD_FAIL";
export const API_GET_USERMANAGEMENT_BY_ID = "users_by_id/LOAD";
export const API_GET_USERMANAGEMENT_BY_ID_SUCCESS = "users_by_id/LOAD_SUCCESS";
export const API_GET_USERMANAGEMENT_BY_ID_FAIL = "users_by_id/LOAD_FAIL";
export const API_GET_USER_BY_ID = "user_by_id/LOAD";
export const API_GET_USER_BY_ID_SUCCESS = "user_by_id/LOAD_SUCCESS";
export const API_GET_USER_BY_ID_FAIL = "user_by_id/LOAD_FAIL";
export const API_GET_ACTIVITY = "user/LOAD";
export const API_GET_ACTIVITY_SUCCESS = "user/LOAD_SUCCESS";
export const API_GET_ACTIVITY_FAIL = "user/LOAD_FAIL";
export const API_ADD_USERMANAGEMENT = "user/LOAD";
export const API_ADD_USERMANAGEMENT_SUCCESS = "user/LOAD_SUCCESS";
export const API_ADD_USERMANAGEMENT_FAIL = "user/LOAD_FAIL";
export const UPDATE_USERMANAGEMENT_DLG_STATUS =
  "UPDATE_USERMANAGEMENT_DLG_STATUS";
export const API_EDIT_USERMANAGEMENT = "edit_user/LOAD";
export const API_EDIT_USERMANAGEMENT_SUCCESS = "edit_user/LOAD_SUCCESS";
export const API_EDIT_USERMANAGEMENT_FAIL = "edit_user/LOAD_FAIL";
export const UPDATE_EDIT_USERMANAGEMENT_DLG_STATUS =
  "UPDATE_EDIT_USERMANAGEMENT_DLG_STATUS";
export const UPDATE_USERMANAGEMENT_FETCH_STATUS =
  "UPDATE_USERMANAGEMENT_FETCH_STATUS";
export const API_DELETE_USERMANAGEMENT = "delete_user/LOAD";
export const API_DELETE_USERMANAGEMENT_SUCCESS = "delete_user/LOAD_SUCCESS";
export const API_DELETE_USERMANAGEMENT_FAIL = "delete_user/LOAD_FAIL";
export const UPDATE_DELETE_USERMANAGEMENT_DLG_STATUS =
  "UPDATE_DELETE_USERMANAGEMENT_DLG_STATUS";
export const API_EMAIL_VERIFY_USERMANAGEMENT = "email_verify_user/LOAD";
export const API_EMAIL_VERIFY_USERMANAGEMENT_SUCCESS =
  "email_verify_user/LOAD_SUCCESS";
export const API_EMAIL_VERIFY_USERMANAGEMENT_FAIL =
  "email_verify_user/LOAD_FAIL";
export const UPDATE_EMAIL_VERIFY_USERMANAGEMENT_DLG_STATUS =
  "UPDATE_EMAIL_VERIFY_USERMANAGEMENT_DLG_STATUS";
export const FILTER_USERMANAGEMENT = "FILTER_USERMANAGEMENT";
export const FILTER_STATUS = "FILTER_STATUS";
export const API_ADD_USERMANAGEMENT_PROGRESS =
  "API_ADD_USERMANAGEMENT_PROGRESS";
export const API_EDIT_USERMANAGEMENT_PROGRESS =
  "API_EDIT_USERMANAGEMENT_PROGRESS";
export const API_VIEW_USERMANAGEMENT = "view_user_management/LOAD";
export const API_VIEW_USERMANAGEMENT_SUCCESS =
  "view_user_management/LOAD_SUCCESS";
export const API_VIEW_USERMANAGEMENT_FAIL = "view_user_management/LOAD_FAIL";
export const UPDATE_VIEW_USERMANAGEMENT = "UPDATE_VIEW_USERMANAGEMENT";
export const FILTER_ROLE_ID = "FILTER_ROLE_ID";
export const API_UPDATE_PROFILE = "UPDATE_PROFILE/LOAD";
export const API_UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE/LOAD_SUCCESS";
export const API_UPDATE_PROFILE_FAIL = "UPDATE_PROFILE/LOAD_FAIL";
export const API_UPDATE_PROFILE_EDIT = "UPDATE_PROFILE/LOAD";
export const API_UPDATE_PROFILE_EDIT_SUCCESS = "UPDATE_PROFILE/LOAD_SUCCESS";
export const API_UPDATE_PROFILE_EDIT_FAIL = "UPDATE_PROFILE/LOAD_FAIL";

export const API_UPDATE_USERTYPES = "UPDATE_USERTYPE/LOAD";
export const API_UPDATE_USERTYPES_SUCCESS = "UPDATE_USERTYPE/LOAD_SUCCESS";
export const API_UPDATE_USERTYPES_FAIL = "UPDATE_USERTYPE/LOAD_FAIL";

export const UPDATE_VIEW_PERMISSION = "update_view_permission";
export const API_GET_PERMISSION = "get_permission/LOAD";
export const API_GET_PERMISSION_SUCCESS = "get_permission/LOAD_SUCCESS";
export const API_GET_PERMISSION_FAIL = "get_permission/LOAD_FAIL";
export const API_UPDATE_PERMISSION = "update_permission/LOAD";
export const API_UPDATE_PERMISSION_SUCCESS = "update_permission/LOAD_SUCCESS";
export const API_UPDATE_PERMISSION_FAIL = "update_permission/LOAD_FAIL";
export const API_GET_ALLUSERROLEPERMISSION =
  "update_alluserrolepermission/LOAD";
export const API_GET_ALLUSERROLEPERMISSION_SUCCESS =
  "update_alluserrolepermission/LOAD_SUCCESS";
export const API_GET_ALLUSERROLEPERMISSION_FAIL =
  "update_alluserrolepermission/LOAD_FAIL";
export const API_GET_CERTIFICATES_DROP_DOWN = "certificate_drop_down/LOAD"
export const API_GET_CERTIFICATES_DROP_DOWN_SUCCESS = "certificate_drop_down/LOAD_SUCCESS"
export const API_GET_CERTIFICATES_DROP_DOWN_FAIL = "certificate_drop_down/LOAD_FAIL"

export interface IUsermanagement {
  id?: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  is_active: number;
  is_email_verified: number;
  branch_name: string;
  last_activity_status: number;
  option: string;
  branch: string;
  date: string;
  imgURL: string;
  user_type: string;
  user_type_id: number;
}

export interface IBranchDropDown {
  id?: string;
  name: string;
}

export interface UsermanagementState {
  branchList: IBranchSelect[];
  usermanagements: IUsermanagement[];
  branchdropdown: IBranchDropDown[];
  selectedUsermanagement: number | null;
  addUsermanagementStatus: boolean;
  addUsermanagementError: string;
  editUsermanagementStatus: boolean;
  deleteUsermanagementStatus: boolean;
  emailVerifyUsermanagementStatus: boolean;
  editUsermanagementError: string;
  deleteUsermanagementSuccess: string;
  emailVerifyUsermanagementSuccess: string;
  usermanagementFetchStatus: boolean;
  usermanagementData: IUsermanagement | null;
  currentUsermanagementId: string;
  data: TUsermanagementTable[];
  filteredData: TUsermanagementTable[];
  total_count: number;
  addUsermanagementSuccess: string;
  editUsermanagementSuccess: string;
  processTrigger: boolean;
  progressPercentage: number;
  viewUsermanagementData: IUsermanagement | any;
  viewUsermanagementStatus: boolean;
  filterUserRoleById: string;
  updateProfileState: any;
  updateWrongProfile: any;
  viewPermission: boolean;
  UserPermissionPages: any;
  update_permission: any;
  permissionUpdatestatus: boolean;
  alluserrolepermission: any;
  updateProfileEditState: any;
  updateWrongProfileEdit: any;
  updateUserTypesState: any;
  updateWrongUserTypes: any;
  currentUserUsertype: any;
  get_User: any;
  cleanerCertificates: any;
}

export interface IAddUsermanagement {
  first_name: string;
  last_name: string;
  user_type: string;
  email: string;
  password: string;
  phone: string;
  branch: string | null;
}

export interface IEditUsermanagement {
  first_name: string;
  last_name: string;
  user_type: string;
  email: string;
  phone: string;
  branch: string | null;
}

export type UsermanagementDispatchType = (args: AnyAction) => AnyAction;
