import * as Yup from "yup";

import { Button, Offcanvas, Toast } from "react-bootstrap";
import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  IQuotedTaskDashboardState,
  IQuotedTaskProps,
} from "../../types/quotedTask.type";
import {
  SortDown,
  SortReqular,
  SortUp,
  SuccessToastSVG,
} from "../../assets/images/ImagesV2";
import {
  filterQuotedTask,
  getAllQuotedTasks,
  handleQuotedTaskSort,
  updateAddQuotedTask,
  updateQuotedTaskFetchStatus,
  updateSidebar,
} from "../../shared/Reducers";

import AddQuotedTask from "./AddQuotedTask";
import { CloseBtnSVG } from "../../assets/images/Location";
import Constants from "../../shared/Constants";
import QuotedTaskTable from "./QuotedTaskTable";
import { CustomSelect } from "../../shared/Components/CustomSelect";
import React from "react";
import ReactPaginate from "react-paginate";
import { RootState } from "../../index";
import { SearchSVG } from "../../assets/images/EmployeesSVG";
import SideBar from "../../components/sidebar/SideBar";
import TopNav from "../../components/topnav/TopNav";
import { connect } from "react-redux";

class QuotedTask extends React.Component<
  IQuotedTaskProps,
  IQuotedTaskDashboardState
> {
  constructor(props: IQuotedTaskProps) {
    super(props);
    this.state = {
      show: false,
      total_quotedTask: "",
      searchTerm: "",
      addToast: false,
      deleteToast: false,
      editToast: false,
      closeAdd: false,
      sortBy: null,
      sortDirection: null,
      pageOffset: Constants.OFFSET,
      addQuotedTaskStatus: false,
      fieldName: "",
      orderType: "",
      initialPagestate: 0,
      pagehandle: false,
      timeout: null,
    };
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount(): void {
    this.props.updateSidebar(true);
    document.title = "Quoted Task";
    this.getQuotedTasks(Constants.OFFSET, Constants.PER_PAGE);
  }

  componentDidUpdate(
    prevProps: Readonly<IQuotedTaskProps>,
    prevState: Readonly<IQuotedTaskDashboardState>,
    snapshot?: any
  ): void {
    if(prevProps.quotedTaskSortBy !== this.props.quotedTaskSortBy || prevProps.quotedTaskOrderBy !== this.props.quotedTaskOrderBy){
      this.getQuotedTasks(Constants.OFFSET, Constants.PER_PAGE);
    }
    if(prevProps.quotedTaskBranch !== this.props.quotedTaskBranch){
      this.getQuotedTasks(Constants.OFFSET, Constants.PER_PAGE);
    }
    if(prevProps.quotedTaskTaskType !== this.props.quotedTaskTaskType){
      this.getQuotedTasks(Constants.OFFSET, Constants.PER_PAGE);
    }

    if (prevProps.addQuotedTaskStatus !== this.props.addQuotedTaskStatus) {
      if (!this.props.addQuotedTaskStatus) {
        this.getQuotedTasks(Constants.OFFSET, Constants.PER_PAGE);
      }
    }

    if (prevProps.quotedTaskFetchStatus !== this.props.quotedTaskFetchStatus) {
      if (this.props.quotedTaskFetchStatus) {
        this.getQuotedTasks(Constants.OFFSET, Constants.PER_PAGE);
      }
    }
    if (prevProps.addQuotedTaskSuccess !== this.props.addQuotedTaskSuccess) {
      if (this.props.addQuotedTaskSuccess === "Successfully created") {
        this.setState({ addToast: true });
        this.setState({ closeAdd: false });
        setTimeout(() => {
          this.setState({ addToast: false });
          this.setState({ closeAdd: false });
        }, 5000);
      }
    }
    if (prevProps.editQuotedTaskSuccess !== this.props.editQuotedTaskSuccess) {
      if (this.props.editQuotedTaskSuccess === "Successfully updated") {
        this.setState({ editToast: true });
        setTimeout(() => {
          this.setState({ editToast: false });
        }, 5000);
      }
    }
    if (prevProps.deleteQuotedTaskSuccess !== this.props.deleteQuotedTaskSuccess) {
      if (this.props.deleteQuotedTaskSuccess === "Successfully deleted") {
        // page reset 
        let Offset: number = 0;
        let paginationActive = document.querySelector(
          ".pagination li.active a"
        ) as HTMLInputElement;
        if (paginationActive !== null) {
          let page: string | null = paginationActive.textContent;
          if (page !== null) {
            let pageNum: number = parseInt(page);
            Offset = (pageNum - 1) * Constants.PER_PAGE;
            console.log(Offset, Constants.PER_PAGE);
            
            this.getQuotedTasks(Offset, Constants.PER_PAGE);
          }
        }
        this.setState({ deleteToast: true });
        setTimeout(() => {
          this.setState({ deleteToast: false });
        }, 5000);
      }
    }
  }

  addToastClose = () => {
    this.setState({ addToast: false });
  };

  editToastClose = () => {
    this.setState({ editToast: false });
  };

  deleteToastClose = () => {
    this.setState({ deleteToast: false });
  };

  getQuotedTasks = async (offset: number, limit: number) => {
    try {
      const query = `search=${this.state.searchTerm}&sort=${this.props.quotedTaskSortBy}&order=${this.props.quotedTaskOrderBy}&offset=${offset}&limit=${limit}&task_type=${this.props.quotedTaskTaskType}&branch=${this.props.quotedTaskBranch}`;
      this.props.getAllQuotedTasks(query);
    } catch (error) {
      console.log(error);
    }
  };

  handleAddQuotedTaskCLose = (status: boolean) => {
    this.handleClose();
    this.getQuotedTasks(Constants.OFFSET, Constants.PER_PAGE);
  };

  // Canvas Show
  handleShow() {
    // this.props.updateAddQuotedTask(true);
    this.setState({ addQuotedTaskStatus: true });
  }

  // Canvas Close
  handleClose() {
    // this.props.updateAddQuotedTask(false);
    this.setState({ addQuotedTaskStatus: false });
  }

  // Search Branch Filter
  handleFilter = async (e: any) => {
    const value: string = e.target.value.toLowerCase();
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

    this.setState({
      searchTerm: value,
      timeout: setTimeout(async () => {
        console.log("User stopped typing:", value);
        try {
          const query = `search=${this.state.searchTerm}&sort=${this.props.quotedTaskSortBy}&order=${this.props.quotedTaskOrderBy}&offset=0&limit=10&task_type=${this.props.quotedTaskTaskType}&branch=${this.props.quotedTaskBranch}`;
          this.props.getAllQuotedTasks(query);
        } catch (error) {
          console.log(error);
        }
        // Trigger your function here that you want to call when user stops typing
      }, 1000), // Set the delay time in ms
    });
  };

  handlePageClick = (e: any) => {
    if (e.selected < this.props.total_count / Constants.PER_PAGE) {
      const query = `search=${this.state.searchTerm}&sort=${
        this.props.quotedTaskSortBy
      }&order=${this.props.quotedTaskOrderBy}&offset=${
        e.selected * Constants.PER_PAGE
      }&limit=${Constants.PER_PAGE}&task_type=${this.props.quotedTaskTaskType}&branch=${this.props.quotedTaskBranch}`;
      this.props.getAllQuotedTasks(query);
      // this.getQuotedTasks(e.selected * Constants.PER_PAGE, Constants.PER_PAGE);
    }
  };

  handleSort = (column: string) => {
    if (this.state.sortBy === column) {
      this.setState({
        sortDirection: this.state.sortDirection === "asc" ? "desc" : "asc",
      });
      this.props.handleQuotedTaskSort(
        column,
        this.state.sortDirection === "asc" ? "desc" : "asc"
      );
    } else {
      this.setState({ sortBy: column });
      this.setState({ sortDirection: "asc" });
      this.props.handleQuotedTaskSort(column, "asc");
    }
  };

  validationSchema = () => {
    return Yup.object().shape({
      column_name: Yup.string().required("Select Filter Column"),
      order_type: Yup.string().required("Select Sort Order"),
    });
  };
  handleSearch = async (formValue: any) => {
    try {
      this.setState({ pagehandle: true }, () => {
        setTimeout(async () => {
          this.setState({
            fieldName: formValue.column_name,
            orderType: formValue.order_type,
            pagehandle: false,
          });
          const query = `search=${this.state.searchTerm}&sort=${formValue.column_name}&order=${formValue.order_type}&offset=${Constants.OFFSET}&limit=${Constants.PER_PAGE}&task_type=${this.props.quotedTaskTaskType}&branch=${this.props.quotedTaskBranch}`;
          await this.props.getAllQuotedTasks(query);
        }, 0);
      });
    } catch (error) {
      console.log(error);
    }
  };

  render(): React.ReactNode {
    
    let taskStatusOption: any = [];
    taskStatusOption = [
      { value: "first_name", label: "Client" },
      { value: "start_date", label: "Start Date" },
      { value: "end_date", label: "End Date" },
    ];
    let quotedTaskOrder: any = [];
    quotedTaskOrder = [
      { value: "asc", label: "Ascending" },
      { value: "desc", label: "Descending" },
    ];

    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className={this.props.status ? "main-panel" : "main-panel2"}>
          <TopNav />
          <div className="content-wrapper">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="mb-0">Quoted Task</h3>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-end">
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12 col-lg-6">
                  <div className="input-group search">
                    <span
                      className="input-group-text search-icon"
                      id="basic-addon1"
                    >
                      <SearchSVG />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={this.state.searchTerm}
                      onInput={this.handleFilter}
                      onChange={(e) =>
                        this.setState({ searchTerm: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 text-start text-lg-end tab-res-mt-3">
                  <Offcanvas
                    className="quotedTask-offcanvas"
                    placement="end"
                    show={this.state.addQuotedTaskStatus}
                    onHide={() => this.handleClose()}
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>
                        <span className="offc-title">Add Quoted Task</span>
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <AddQuotedTask onClose={this.handleAddQuotedTaskCLose} />
                    </Offcanvas.Body>
                  </Offcanvas>
                  <Button
                    aria-controls="offcanvasRight"
                    className="btn btn-primary login-button add-quoted-task mob-res-w-100"
                    onClick={() => this.handleShow()}
                  >
                    Add Quoted Task
                  </Button>
                  {this.state.addToast ? (
                    <Toast className="success-alerts">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <SuccessToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.addQuotedTaskSuccess}
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.addToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                  {this.state.editToast ? (
                    <Toast className="success-alerts">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <SuccessToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.editQuotedTaskSuccess}
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.editToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                  {this.state.deleteToast ? (
                    <Toast className="success-alerts">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <SuccessToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.deleteQuotedTaskSuccess}
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.deleteToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      {this.props.filteredQuotedTaskData.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table employee-table">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  onClick={() => this.handleSort("task")}
                                >
                                  TASK
                                  <span className="table-sort-wrap">
                                    <span className={ this.state.sortBy === 'task' && this.state.sortDirection === 'asc' ? '' : 'd-none' }><SortDown /></span>
                                    <span className={ this.state.sortBy === 'task' && this.state.sortDirection === 'desc' ? '' : 'd-none' }><SortUp /></span>
                                    <span className={ this.state.sortBy !== 'task' ? '' : 'd-none' }><SortReqular /></span>
                                  </span>
                                </th>
                                <th
                                  scope="col"
                                  onClick={() => this.handleSort("first_name")}
                                >
                                  CLIENT
                                  <span className="table-sort-wrap">
                                    <span className={ this.state.sortBy === 'branch_name' && this.state.sortDirection === 'asc' ? '' : 'd-none' }><SortDown /></span>
                                    <span className={ this.state.sortBy === 'branch_name' && this.state.sortDirection === 'desc' ? '' : 'd-none' }><SortUp /></span>
                                    <span className={ this.state.sortBy !== 'branch_name' ? '' : 'd-none' }><SortReqular /></span>
                                  </span>
                                </th>
                                <th
                                  scope="col"
                                  onClick={() => this.handleSort("branch_name")}
                                >
                                  BRANCH
                                  <span className="table-sort-wrap">
                                    <span className={ this.state.sortBy === 'branch_name' && this.state.sortDirection === 'asc' ? '' : 'd-none' }><SortDown /></span>
                                    <span className={ this.state.sortBy === 'branch_name' && this.state.sortDirection === 'desc' ? '' : 'd-none' }><SortUp /></span>
                                    <span className={ this.state.sortBy !== 'branch_name' ? '' : 'd-none' }><SortReqular /></span>
                                  </span>
                                </th>
                                <th
                                  scope="col"
                                  onClick={() => this.handleSort("task_type")}
                                >
                                  TASK TYPE
                                  <span className="table-sort-wrap">
                                    <span className={ this.state.sortBy === 'task_type' && this.state.sortDirection === 'asc' ? '' : 'd-none' }><SortDown /></span>
                                    <span className={ this.state.sortBy === 'task_type' && this.state.sortDirection === 'desc' ? '' : 'd-none' }><SortUp /></span>
                                    <span className={ this.state.sortBy !== 'task_type' ? '' : 'd-none' }><SortReqular /></span>
                                  </span>
                                </th>
                                <th scope="col">
                                  TOTAL TIME (Hrs)
                                </th>
                                <th scope="col">ACTION</th>
                              </tr>
                            </thead>
                            <QuotedTaskTable
                              data={this.props.filteredQuotedTaskData}
                              onDelete={() =>
                                this.getQuotedTasks(
                                  Constants.OFFSET,
                                  Constants.PER_PAGE
                                )
                              }
                              onEditClose={() =>
                                this.getQuotedTasks(
                                  Constants.OFFSET,
                                  Constants.PER_PAGE
                                )
                              }
                              deleteQuotedTaskStatus={false}
                            />
                          </table>
                        </div>
                      ) : (
                        <p className="text-center">
                          There is no quotedTask record found
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                {this.props.total_count > 10 ? (
                  <div className="col-md-12">
                    {this.state.pagehandle ? null : (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.props.total_count / Constants.PER_PAGE}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        nextClassName={"next-page"}
                        activeClassName={"active"}
                      />
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  addQuotedTaskStatus: state.quotedTask.addQuotedTaskStatus,
  quotedTaskFetchStatus: state.quotedTask.quotedTaskFetchStatus,
  quotedTaskData: state.quotedTask.quotedTaskData,
  filteredQuotedTaskData: state.quotedTask.filteredQuotedTaskData,
  total_count: state.quotedTask.total_count,
  addQuotedTaskSuccess: state.quotedTask.addQuotedTaskSuccess,
  editQuotedTaskSuccess: state.quotedTask.editQuotedTaskSuccess,
  deleteQuotedTaskSuccess: state.quotedTask.deleteQuotedTaskSuccess,
  quotedTaskSortBy: state.quotedTask.quotedTaskSortBy,
  quotedTaskTaskType: state.quotedTask.quotedTaskTaskType,
  quotedTaskBranch: state.quotedTask.quotedTaskBranch,
  quotedTaskOrderBy: state.quotedTask.quotedTaskOrderBy,
  status: state.sidebar.status,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateAddQuotedTask: bindActionCreators(updateAddQuotedTask, dispatch),
    updateQuotedTaskFetchStatus: bindActionCreators(
      updateQuotedTaskFetchStatus,
      dispatch
    ),
    filterQuotedTask: bindActionCreators(filterQuotedTask, dispatch),
    getAllQuotedTasks: bindActionCreators(getAllQuotedTasks, dispatch),
    handleQuotedTaskSort: bindActionCreators(handleQuotedTaskSort, dispatch),
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(QuotedTask);
