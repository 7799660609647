import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Dispatch, bindActionCreators } from "redux";
import {
  IClientContractCardProps,
  IClientContractCardState,
} from "../../types/contract.type";
import {
  TableDeleteSVG,
  TableEditSVG,
  TableViewSVG,
} from "../../assets/images/ImagesV2";

import DeleteContract from "./DeleteClientContract";
import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
import moment from "moment";
import { updateEditClientContractDlgStatus } from "../../shared/Reducers";

class ClientContractCard extends React.Component<
  IClientContractCardProps,
  IClientContractCardState
> {
  constructor(props: IClientContractCardProps) {
    super(props);
    this.state = {
      show: false,
      displayDelete: false,
      currentContractId: "",
      editShow: false,
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  // Delete contract based on ID
  handleDelete = (id: string) => {
    this.setState({ displayDelete: true, currentContractId: id });
  };

  // delete popup close
  handleDeleteClose = (status: boolean) => {
    this.setState({ displayDelete: false, currentContractId: "" });
    if (status) {
      this.props.handleDeleteContract(this.state.currentContractId);
    }
  };

  render(): React.ReactNode {
    const {
      id,
      start_date,
      end_date,
      first_name,
      last_name,
      entity_name,
      abn_status,
      asic_reg,
      business_location,
      entity_type,
    } = this.props.contracts;
    return (
      <tr>
        {/* <td>C{id}</td> */}
        <td>
          {first_name} {last_name}
        </td>
        <td>
          {moment(start_date).format("DD/MM/YYYY")} To{" "}
          {moment(end_date).format("DD/MM/YYYY")}
        </td>
        <td>{entity_name}</td>
        <td>{abn_status}</td>
        <td>{entity_type}</td>
        <td>{business_location}</td>
        <td>
          <div className="btn-group">
            <span className="mr-20px">
              <a
                href="javascript:void(0)"
                onClick={() => this.props.handleViewContract(id)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"View"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableViewSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
            <span className="mr-20px">
              <a
                href="javascript:void(0)"
                onClick={() => this.props.handleEditClientContract(id)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"Edit"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableEditSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
            <span>
              <a
                href="javascript:void(0)"
                onClick={() => this.handleDelete(id)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"Delete"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableDeleteSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
            <DeleteContract
              displayDelete={this.state.displayDelete}
              deletedName={
                first_name +
                " " +
                last_name +
                " ( Start : " +
                moment(new Date(start_date?.toString())).format("DD/MM/YYYY") +
                " to End : " +
                moment(new Date(end_date?.toString())).format("DD/MM/YYYY") +
                ")"
              }
              handleDeleteClose={this.handleDeleteClose}
            />
          </div>
        </td>
      </tr>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  editContractStatus: state.contract.editContractStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateEditClientContractDlgStatus: bindActionCreators(
      updateEditClientContractDlgStatus,
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientContractCard);
