import {
  IDailyTaskTable,
  IDailyTaskType,
  IGetComments,
} from "../../types/dailyTask.type";

import { AnyAction } from "redux";

export const API_GET_ALL_DAILY_TASKS = "get_all_daily_task/LOAD";
export const API_GET_ALL_DAILY_TASKS_SUCCESS =
  "get_all_daily_task/LOAD_SUCCESS";
export const API_GET_ALL_DAILY_TASKS_FAIL = "get_all_daily_task/LOAD_FAIL";
export const API_GET_ALL_DAILY_TASKS_WITHOUT_LIMIT =
  "get_all_daily_task_no_limit/LOAD";
export const API_GET_ALL_DAILY_TASKS_WITHOUT_LIMIT_SUCCESS =
  "get_all_daily_task_no_limit/LOAD_SUCCESS";
export const API_GET_ALL_DAILY_TASKS_WITHOUT_LIMIT_FAIL =
  "get_all_daily_task_no_limit/LOAD_FAIL";
export const API_GET_ALL_APPROVED_TASKS = "get_all_approved_task_no_limit/LOAD";
export const API_GET_ALL_APPROVED_TASKS_SUCCESS =
  "get_all_approved_task_no_limit/LOAD_SUCCESS";
export const API_GET_ALL_APPROVED_TASKS_FAIL =
  "get_all_approved_task_no_limit/LOAD_FAIL";
export const API_GET_ALL_RECOMMENDED_TASKS =
  "get_all_recommended_task_no_limit/LOAD";
export const API_GET_ALL_RECOMMENDED_TASKS_SUCCESS =
  "get_all_recommended_task_no_limit/LOAD_SUCCESS";
export const API_GET_ALL_RECOMMENDED_TASKS_FAIL =
  "get_all_recommended_task_no_limit/LOAD_FAIL";
export const API_GET_SENDMAIL_APPROVED_TASK = "get_sendMail_additional_task";
export const API_GET_ALL_ADDITIONAL_TASK = "get_all_additional_task/LOAD";
export const API_GET_ALL_ADDITIONAL_TASK_SUCCESS =
  "get_all_additional_task/LOAD_SUCCESS";
export const API_GET_ALL_ADDITIONAL_TASK_FAIL =
  "get_all_additional_task/LOAD_FAIL";
export const API_GET_ALL_COMPLETE_DAILY_TASKS =
  "get_all_complete_daily_task/LOAD";
export const API_GET_ALL_COMPLETE_DAILY_TASKS_SUCCESS =
  "get_all_complete_daily_task/LOAD_SUCCESS";
export const API_GET_ALL_COMPLETE_DAILY_TASKS_FAIL =
  "get_all_complete_daily_task/LOAD_FAIL";
export const API_GET_ALL_FOLLOWUP_DAILY_TASKS =
  "get_all_follow_daily_task/LOAD";
export const API_GET_ALL_FOLLOWUP_DAILY_TASKS_SUCCESS =
  "get_all_follow_daily_task/LOAD_SUCCESS";
export const API_GET_ALL_FOLLOWUP_DAILY_TASKS_FAIL =
  "get_all_follow_daily_task/LOAD_FAIL";
export const API_ADD_DAILY_TASK = "daily_task/LOAD";
export const API_ADD_DAILY_TASK_SUCCESS = "daily_task/LOAD_SUCCESS";
export const API_ADD_DAILY_TASK_FAIL = "daily_task/LOAD_FAIL";
export const UPDATE_ADD_DAILY_TASK_DLG_STATUS =
  "UPDATE_ADD_DAILY_TASK_DLG_STATUS";
export const API_EDIT_DAILY_TASK = "edit_daily_task/LOAD";
export const API_EDIT_DAILY_TASK_SUCCESS = "edit_daily_task/LOAD_SUCCESS";
export const API_EDIT_DAILY_TASK_FAIL = "edit_daily_task/LOAD_FAIL";
export const API_EDIT_ADDITIONAL_TASK = "edit_additional_task/LOAD";
export const API_EDIT_ADDITIONAL_TASK_SUCCESS =
  "edit_additional_task/LOAD_SUCCESS";
export const API_EDIT_ADDITIONAL_TASK_FAIL = "edit_additional_task/LOAD_FAIL";
export const API_EDIT_MOVE_TO_TASK = "edit_move_to_task/LOAD";
export const API_EDIT_MOVE_TO_TASK_SUCCESS = "edit_move_to_task/LOAD_SUCCESS";
export const API_EDIT_MOVE_TO_TASK_FAIL = "edit_move_to_task/LOAD_FAIL";
export const API_EDIT_RECOMMENDED_TASK = "edit_recommended_task/LOAD";
export const API_EDIT_RECOMMENDED_TASK_SUCCESS =
  "edit_recommended_task/LOAD_SUCCESS";
export const API_EDIT_RECOMMENDED_TASK_FAIL = "edit_recommended_task/LOAD_FAIL";
export const API_REASSIGN_DAILY_TASK = "reassign_daily_task/LOAD";
export const API_REASSIGN_DAILY_TASK_SUCCESS =
  "reassign_daily_task/LOAD_SUCCESS";
export const API_REASSIGN_DAILY_TASK_FAIL = "reassign_daily_task/LOAD_FAIL";
export const UPDATE_EDIT_DAILY_TASK_DLG_STATUS =
  "UPDATE_EDIT_DAILY_TASK_DLG_STATUS";
export const UPDATE_REASSIGN_DAILY_TASK_DLG_STATUS =
  "UPDATE_REASSIGN_DAILY_TASK_DLG_STATUS";
export const API_DELETE_DAILY_TASK = "delete_daily_task/LOAD";
export const API_DELETE_DAILY_TASK_SUCCESS = "delete_daily_task/LOAD_SUCCESS";
export const API_DELETE_DAILY_TASK_FAIL = "delete_daily_task/LOAD_FAIL";
export const UPDATE_DELETE_DAILY_TASK_DLG_STATUS =
  "UPDATE_DELETE_DAILY_TASK_DLG_STATUS";
export const API_GET_DAILY_TASK_BY_ID = "daily_task_by_id/LOAD";
export const API_GET_DAILY_TASK_BY_ID_SUCCESS = "daily_task_by_id/LOAD_SUCCESS";
export const API_GET_DAILY_TASK_BY_ID_FAIL = "daily_task_by_id/LOAD_FAIL";
export const API_GET_ADDITIONAL_TASK_BY_ID = "get_additional_task_byID/LOAD";
export const API_GET_ADDITIONAL_TASK_BY_ID_SUCCESS =
  "get_additional_task_byID/LOAD_SUCCESS";
export const API_GET_ADDITIONAL_TASK_BY_ID_FAIL =
  "get_additional_task_byID/LOAD_FAIL";
export const API_GET_MOVE_TO_TASK_BY_ID = "get_additional_move_task_byID/LOAD";
export const API_GET_MOVE_TO_TASK_BY_ID_SUCESS =
  "get_additional_move_task_byID/LOAD_SUCCESS";
export const API_GET_MOVE_TO_TASK_BY_ID_FAIL =
  "get_additional_move-task_byID/LOAD_FAIL";
export const API_GET_RECOMMENDED_TASK_BY_ID = "get_recommended_task_byID/LOAD";
export const API_GET_RECOMMENDED_TASK_BY_ID_SUCCESS =
  "get_recommended_task_byID/LOAD_SUCCESS";
export const API_GET_RECOMMENDED_TASK_BY_ID_FAIL =
  "get_recommended_task_byID/LOAD_FAIL";
export const API_GET_DAILY_TASK_BY_ID_FOR_COPY = "copy_daily_task_by_id/LOAD";
export const API_GET_DAILY_TASK_BY_ID_FOR_COPY_SUCCESS =
  "copy_daily_task_by_id/LOAD_SUCCESS";
export const API_GET_DAILY_TASK_BY_ID_FOR_COPY_FAIL =
  "copy_daily_task_by_id/LOAD_FAIL";
export const API_GET_DAILY_TASK_BY_ID_FOR_VIEW = "view_daily_task_by_id/LOAD";
export const API_GET_DAILY_TASK_BY_ID_FOR_VIEW_SUCCESS =
  "view_daily_task_by_id/LOAD_SUCCESS";
export const API_GET_DAILY_TASK_BY_ID_FOR_VIEW_FAIL =
  "view_daily_task_by_id/LOAD_FAIL";
export const API_GET_USERS_DETAILS_FOR_MAIL = "GET_USERS_DETAILS/LOAD";
export const API_GET_USERS_DETAILS_FOR_MAIL_SUCCESS = "GET_USERS_DETAILS/LOAD_SUCCESS";
export const API_GET_USERS_DETAILS_FOR_MAIL_FAIL = "GET_USERS_DETAILS/LOAD_FAIL";
export const API_GET_DAILY_TASK_BY_ID_FOR_MAKESCHEDULE =
  "makeschedule_daily_task_by_id/LOAD";
export const API_GET_DAILY_TASK_BY_ID_FOR_MAKESCHEDULE_SUCCESS =
  "makeschedule_daily_task_by_id/LOAD_SUCCESS";
export const API_GET_DAILY_TASK_BY_ID_FOR_MAKESCHEDULE_FAIL =
  "makeschedule_daily_task_by_id/LOAD_FAIL";
export const API_GET_REASSIGN_DAILY_TASK_BY_ID =
  "reassign_daily_task_by_id/LOAD";
export const API_GET_REASSIGN_DAILY_TASK_BY_ID_SUCCESS =
  "reassign_daily_task_by_id/LOAD_SUCCESS";
export const API_GET_REASSIGN_DAILY_TASK_BY_ID_FAIL =
  "reassign_daily_task_by_id/LOAD_FAIL";
export const UPDATE_DAILY_TASK_FETCH_STATUS = "UPDATE_DAILY_TASK_FETCH_STATUS";
export const FILTER_DAILY_TASK = "FILTER_DAILY_TASK";
export const UPDATE_DAILY_TASKS = "UPDATE_DAILY_TASKS";
export const UPDATE_DAILY_TASK_DLG_STATUS = "UPDATE_DAILY_TASK_DLG_STATUS";
export const API_GET_TASK_HISTORY_BY_ID = "task_history/LOAD";
export const API_GET_TASK_HISTORY_BY_ID_SUCCESS = "task_history/LOAD_SUCCESS";
export const API_GET_TASK_HISTORY_BY_ID_FAIL = "task_history/LOAD_FAIL";
export const UPDATE_REASSING_DAILY_TASK = "UPDATE_REASSING_DAILY_TASK";
export const UPDATE_TASK_HISTORY_DLG_STATUS = "UPDATE_TASK_HISTORY_DLG_STATUS";
export const API_ADD_COMMENT_OLD = "add_comment/LOAD";
export const API_ADD_COMMENT_OLD_SUCCESS = "add_comment/LOAD_SUCCESS";
export const API_ADD_COMMENT_OLD_FAIL = "add_comment/LOAD_FAIL";
export const API_UPDATE_COMMENT = "add_comment/LOAD";
export const API_UPDATE_COMMENT_SUCCESS = "add_comment/LOAD_SUCCESS";
export const API_UPDATE_COMMENT_FAIL = "add_comment/LOAD_FAIL";
export const API_ADD_DAILY_TASK_PROGRESS = "API_ADD_DAILY_TASK_PROGRESS";
export const UPDATE_ADD_COMMENT = "UPDATE_ADD_COMMENT";
export const API_EDIT_COMMENT = "edit_comment/LOAD";
export const API_EDIT_COMMENT_SUCCESS = "edit_comment/LOAD_SUCCESS";
export const API_EDIT_COMMENT_FAIL = "edit_comment/LOAD_FAIL";
export const UPDATE_EDIT_COMMENT = "UPDATE_EDIT_COMMENT";
export const API_GET_COMMENT_BY_ID = "get_comment_by_id/LOAD";
export const API_GET_COMMENT_BY_ID_SUCCESS = "get_comment_by_id/LOAD_SUCCESS";
export const API_GET_COMMENT_BY_ID_FAIL = "get_comment_by_id/LOAD_FAIL";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const API_DELETE_COMMENT = "delete_comment/LOAD";
export const API_DELETE_COMMENT_SUCCESS = "delete_comment/LOAD_SUCCESS";
export const API_DELETE_COMMENT_FAIL = "delete_comment/LOAD_FAIL";
export const API_DELETE_USER_TYPES = "delete_user_types/LOAD";
export const API_DELETE_USER_TYPES_SUCCESS = "delete_user_types/LOAD_SUCCESS";
export const API_DELETE_USER_TYPES_FAIL = "delete_user_types/LOAD_FAIL";
export const UPDATE_DELETE_COMMENT = "UPDATE_DELETE_COMMENT";

export const API_GET_SEND_QA_EMAIL_DAILY_TASK_BY_ID =
  "send_qa_email_daily_task_by_id/LOAD";
export const API_GET_SEND_QA_EMAIL_DAILY_TASK_BY_ID_SUCCESS =
  "send_qa_email_daily_task_by_id/LOAD_SUCCESS";
export const API_GET_SEND_QA_EMAIL_DAILY_TASK_BY_ID_FAIL =
  "send_qa_email_daily_task_by_id/LOAD_FAIL";
export const API_SEND_QA_EMAIL_DAILY_TASK = "send_qa_email_daily_task/LOAD";
export const API_SEND_QA_EMAIL_DAILY_TASK_SUCCESS =
  "send_qa_email_daily_task/LOAD_SUCCESS";
export const API_SEND_QA_EMAIL_DAILY_TASK_FAIL =
  "send_qa_email_daily_task/LOAD_FAIL";
export const UPDATE_SEND_QA_EMAIL_DAILY_TASK_DLG_STATUS =
  "UPDATE_SEND_QA_EMAIL_DAILY_TASK_DLG_STATUS";
export const FILTER_TASK_TYPE = "FILTER_TASK_TYPE";
export const FILTER_TASK_PRIORITY = "FILTER_TASK_PRIORITY";
export const FILTER_TASK_STATUS = "FILTER_TASK_STATUS";
export const FILTER_SPECIAL_REQUEST_TASK_STATUS =
  "FILTER_SPECIAL_REQUEST_TASK_STATUS";
export const FILTER_TASK_DUE_TYPE = "FILTER_TASK_DUE_TYPE";
export const FILTER_TASK_DATE = "FILTER_TASK_DATE";
export const FILTER_TASK_CLIENT = "FILTER_TASK_CLIENT";
export const FILTER_TASK_BRANCH = "FILTER_TASK_BRANCH";
export const EDIT_MODAL_DAILY_TASK = "EditModalDailyTask";

export interface IAddDailyTask {
  client: string;
  branch: string;
  title: string;
  is_active: string;
  attachments: any | Blob;
  description: string;
}

export interface IEditDailyTask {
  client: string;
  branch: string;
  title: string;
  is_active: string;
  attachments: any | Blob;
  description: string;
}

export interface DailyTaskState {
  addDailyTaskStatus: boolean;
  addSpecialRequestTaskStatus?: boolean;
  editDailyTaskStatus: boolean;
  copyDailyTaskStatus: boolean;
  makeScheduleDailyTaskStatus: boolean;
  reassignDailyTaskStatus: boolean;
  sendqaemailDailyTaskStatus: boolean;
  deleteDailyTaskStatus: boolean;
  dailyTaskFetchStatus: boolean;
  addDailyTaskSuccess: string;
  editDailyTaskSuccess: string;
  deleteDailyTaskSuccess: string;
  selectedDailyTaskData: IDailyTaskType | null;
  selectedAdditionalTaskData: IDailyTaskType | null;
  selectedRecommendedTaskData: IDailyTaskType | null;
  id: string;
  dailyTaskData: IDailyTaskTable[];
  filteredDailyTaskData: IDailyTaskTable[];
  filteredAdditionalTaskData: IDailyTaskTable[];
  filteredDailyTaskAllData: IDailyTaskTable[];
  filteredAllApprovedTask: any[];
  filteredAllRecommendedTask: any[];
  taskHistoryData: ITaskHistory[];
  viewDailyTaskStatus: boolean;
  editadditionalTaskSuccess: string;
  addCommentStatus: boolean;
  editCommentStatus: boolean;
  deleteCommentStatus: boolean;
  commentFetchStatus: boolean;
  addCommentSuccess: string;
  editCommentSuccess: string;
  deleteCommentSuccess: string;
  commentsData: IGetComments[];
  total_count: number;
  total_Count: number;
  editRecommendedTaskSuccess: any;
  recommended_count: number;
  processTrigger: boolean;
  progressPercentage: number;
  filterTaskTypeById?: string | number;
  filterTaskPriorityById?: string | number;
  filterTaskStatusById?: string;
  filterSpecialRequestTaskStatusById?: string;
  filterTaskDueTypeByValue?: string;
  filterTaskClientById?: string;
  filterTaskBranchById?: string;
  filterTaskDateTypeValue?: any;
  followDailyTask: IDailyTaskTable[];
  completeDailyTask: IDailyTaskTable[];
  editDailyTaskstatusvalue?: any;
  EditModalDailyTask?: any;
  additionalTaskid: any;
  recommendedTaskId: any;
  sendMailModal: boolean;
  sendMailUsers: any;
  currentTask_createdTaskid: any;
}

export interface ITaskHistory {
  id: string;
  task_date: string;
  title: string;
  description: string;
  any_time: number;
  task_type: number;
  completed: number;
  start_time: string;
  end_time: string;
  task_status: number;
  is_active: number;
  status: number;
  created_at: string;
  ref_task_id: string;
  client_id: string;
  branch_id: string;
  task_id: null | string;
  schedule_id: null | string;
  branch_name: string;
  first_name: string;
  last_name: string;
  attachments: any;
}

export interface IComments {
  commants: string;
  task_status: number;
  attachments: any;
  task_list: any;
}

export type DailyTaskDispatchType = (args: AnyAction) => AnyAction;
