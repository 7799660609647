import { Dispatch, AnyAction } from "redux";
import { AppDispatch } from "../..";
import { TContractorTable } from "../../types/user.type";
import {
  API_GET_ALL_CLIENTS,
  API_GET_ALL_CLIENTS_SUCCESS,
  API_GET_ALL_CLIENTS_FAIL,
  API_GET_ALL_BRANCH_FAIL,
  ContractorTemplateState,
  API_GET_ALL_CONTRACTORS,
  API_GET_ALL_CONTRACTORS_SUCCESS,
  API_GET_ALL_CONTRACTORS_FAIL,
  API_GET_CONTRACTOR_BY_ID,
  API_GET_CONTRACTOR_BY_ID_SUCCESS,
  API_GET_CONTRACTOR_BY_ID_FAIL,
  API_ADD_CONTRACTOR,
  API_ADD_CONTRACTOR_SUCCESS,
  API_ADD_CONTRACTOR_FAIL,
  API_EDIT_CONTRACTOR,
  API_EDIT_CONTRACTOR_SUCCESS,
  API_EDIT_CONTRACTOR_FAIL,
  API_DELETE_CONTRACTOR,
  API_DELETE_CONTRACTOR_SUCCESS,
  API_DELETE_CONTRACTOR_FAIL,
  UPDATE_ADD_CONTRACTOR_DLG_STATUS,
  UPDATE_EDIT_CONTRACTOR_DLG_STATUS,
  UPDATE_DELETE_CONTRACTOR_DLG_STATUS,
  UPDATE_CONTRACTOR_FETCH_STATUS,
  FILTER_CONTRACTOR,
  UPDATE_CONTRACTORS,
  IEditClientContractor,
  API_ADD_CONTRACTOR_PROGRESS,
  API_EDIT_CONTRACTOR_PROGRESS,
  API_DELETE_ATTACHMENT,
  API_VIEW_CONTRACTOR_BY_ID,
  API_VIEW_CONTRACTOR_BY_ID_FAIL,
  API_VIEW_CONTRACTOR_BY_ID_SUCCESS,
  UPDATE_VIEW_CONTRACTOR,
  CONTRACTOR_SORT
} from "../ActionTypes";

const initialState: ContractorTemplateState = {
  cleanerList: [],
  clientList: [],
  addContractorStatus: false,
  addContractorSuccess: "",
  deleteContractorStatus: false,
  deleteContractorSuccess: "",
  editContractorStatus: false,
  editContractorSuccess: "",
  contractorFetchStatus: false,
  selectedContractorData: null,
  contractorData: [],
  filteredContractorData: [],
  id: "",
  total_count: 0,
  addContractorError: "",
  deleteContractorError: "",
  editContractorError: "",
  processTrigger: false,
  progressPercentage: 0,
  viewContractorData: null,
  viewContractorStatus: false,
  contractorSortBy: "",
  contractorOrderBy: "",
};

export const ContractorReducer = (
  state: ContractorTemplateState = initialState,
  action: AnyAction
): ContractorTemplateState => {
  switch (action.type) {
    case UPDATE_CONTRACTORS:
      return { ...state };
    case API_GET_ALL_CLIENTS:
      return { ...state };
    case API_GET_ALL_CLIENTS_SUCCESS:
      let clientData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "clients" in data && data.clients) {
          clientData = data.clients;
        }
      }
      return { ...state, clientList: clientData };
    case API_GET_ALL_CLIENTS_FAIL:
      return { ...state };
    case API_GET_ALL_BRANCH_FAIL:
      return { ...state };
    case UPDATE_CONTRACTOR_FETCH_STATUS:
      return { ...state, contractorFetchStatus: action.payload };
    case API_GET_ALL_CONTRACTORS:
      return { ...state };
    case API_GET_ALL_CONTRACTORS_SUCCESS:
      let contractorData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "contractors" in data && data.contractors) {
          contractorData = data.contractors;
        }
      }
      let total_count = 0;
      if (action.payload) {
        const { data } = action.payload;
        if (data && "total_count" in data && data.total_count) {
          total_count = data.total_count;
        }
      }
      return {
        ...state,
        total_count: total_count,
        editContractorStatus: false,
        addContractorStatus: false,
        deleteContractorStatus: false,
        filteredContractorData: contractorData,
      };
    case API_GET_ALL_CONTRACTORS_FAIL:
      return { ...state };
    case API_GET_CONTRACTOR_BY_ID:
      return { ...state, id: action.payload.id };
    case API_GET_CONTRACTOR_BY_ID_SUCCESS:
      let contractors = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "contractor" in data && data.contractor) {
          contractors = data.contractor;
        }
      }
      return {
        ...state,
        selectedContractorData: contractors,
        editContractorStatus: true,
        contractorFetchStatus: false,
      };
    case API_GET_CONTRACTOR_BY_ID_FAIL:
      return { ...state };
    case API_VIEW_CONTRACTOR_BY_ID:
      return { ...state, id: action.payload.id };
    case API_VIEW_CONTRACTOR_BY_ID_SUCCESS:
      let contractor = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "contractor" in data && data.contractor) {
          contractor = data.contractor;
        }
      }
      return {
        ...state,
        viewContractorData: contractor,
        viewContractorStatus: true,
        contractorFetchStatus: false,
      };
    case API_VIEW_CONTRACTOR_BY_ID_FAIL:
      return { ...state };
    case UPDATE_VIEW_CONTRACTOR:
      return { ...state, viewContractorStatus: action.payload };
    case API_ADD_CONTRACTOR:
      let values: boolean = false;
      if (action.payload.request.data.FormData === undefined) {
        values = true;
      }
      return {
        ...state,
        addContractorSuccess: "",
        addContractorError: "",
        processTrigger: values,
      };
    case API_ADD_CONTRACTOR_SUCCESS:
      let message = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          message = data.message;
        }
      }
      return {
        ...state,
        addContractorStatus: false,
        addContractorSuccess: message,
        processTrigger: false,
        progressPercentage: 0,
      };
    case API_ADD_CONTRACTOR_FAIL:
      let err = "";
      if (
        "error" in action &&
        action.error &&
        "response" in action.error &&
        action.error.response &&
        "data" in action.error.response &&
        action.error.response.data &&
        "message" in action.error.response.data
      ) {
        err = action.error.response.data.message;
      }
      return { ...state, addContractorError: err };
    case API_ADD_CONTRACTOR_PROGRESS:
      return { ...state, progressPercentage: action.payload };
    case API_EDIT_CONTRACTOR_PROGRESS:
      return { ...state, progressPercentage: action.payload };
    case UPDATE_ADD_CONTRACTOR_DLG_STATUS:
      return { ...state, addContractorStatus: action.payload };
    case API_EDIT_CONTRACTOR:
      let value: boolean = false;
      if (action.payload.request.data.FormData === undefined) {
        value = true;
      }
      return { ...state, editContractorSuccess: "", processTrigger: value };
    case API_EDIT_CONTRACTOR_SUCCESS:
      let messages = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          messages = data.message;
        }
      }
      return {
        ...state,
        editContractorStatus: false,
        contractorFetchStatus: true,
        editContractorSuccess: messages,
        processTrigger: false,
        progressPercentage: 0,
      };
    case API_EDIT_CONTRACTOR_FAIL:
      let error = "";
      if (
        "error" in action &&
        action.error &&
        "response" in action.error &&
        action.error.response &&
        "data" in action.error.response &&
        action.error.response.data &&
        "message" in action.error.response.data
      ) {
        error = action.error.response.data.message;
      }
      return { ...state, editContractorError: error };
    case UPDATE_EDIT_CONTRACTOR_DLG_STATUS:
      return { ...state, editContractorStatus: action.payload };
    case API_DELETE_CONTRACTOR:
      return { ...state, deleteContractorSuccess: "" };
    case API_DELETE_CONTRACTOR_SUCCESS:
      let msg = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          // alert(data.message)
          msg = data.message;
        }
      }
      return {
        ...state,
        deleteContractorStatus: false,
        contractorFetchStatus: true,
        deleteContractorSuccess: msg,
      };
    case API_DELETE_CONTRACTOR_FAIL:
      return { ...state };
    case UPDATE_DELETE_CONTRACTOR_DLG_STATUS:
      return { ...state, deleteContractorStatus: action.payload };
    case FILTER_CONTRACTOR:
      console.log(action.payload, state.contractorData);

      const filterContractor: TContractorTable[] = state.filteredContractorData.filter(
        (obj : any) => {
          console.log(action.payload);

          console.log(obj.first_name.toLowerCase().includes(action.payload));
          return (
            obj.first_name.toLowerCase().includes(action.payload)
          );
        }
      );
      return { ...state, filteredContractorData: filterContractor };
    case CONTRACTOR_SORT:
      return { ...state, contractorSortBy: action.payload.sort, contractorOrderBy: action.payload.order };
    default:
      return state;
  }
};
// update contractor
export function updateContractors(data: any) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_CONTRACTORS,
      payload: data,
    });
  };
}

// get contractor by id
export function getContractorById(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_CONTRACTOR_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `contractor/${id}` : `contractor`,
        },
        id,
      },
    });
  };
}

// VIEW contractor by id
export function viewContractorById(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_VIEW_CONTRACTOR_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `contractor/${id}` : `contractor`,
        },
        id,
      },
    });
  };
}

export function updateViewContractor(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_VIEW_CONTRACTOR,
      payload: status,
    });
  };
}


// get all contractors for table
export function getAllContractors(queryStr: string) {
  console.log(queryStr,"querystr");
  
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ALL_CONTRACTORS,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `contractor?${queryStr}` : `contractor?`,
        },
      },
    });
  };
}

// add contractor
export function addContractor(payload: FormData) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_ADD_CONTRACTOR,
      payload: {
        request: {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            contentType: false,
          },
          method: "post",
          url: `contractor`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_ADD_CONTRACTOR_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

export function updateAddClientContractor(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_ADD_CONTRACTOR_DLG_STATUS,
      payload: status,
    });
  };
}

// edit contractor
export function editContractor(payload: IEditClientContractor, id: number) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_EDIT_CONTRACTOR,
      payload: {
        request: {
          method: "put",
          url: `contractor/${id}`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_EDIT_CONTRACTOR_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

// update edit employee
export function updateEditClientContractorDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_EDIT_CONTRACTOR_DLG_STATUS,
      payload: status,
    });
  };
}

// Fetch Contractor after add, edit, delete
export function updateContractorFetchStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_CONTRACTOR_FETCH_STATUS,
      payload: status,
    });
  };
}

// delete contractor
export function deleteContractor(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_DELETE_CONTRACTOR,
      payload: {
        request: {
          method: "delete",
          url: `contractor/${id}`,
        },
      },
    });
  };
}

// update delete contractor
export function updateDeleteContractorDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_DELETE_CONTRACTOR_DLG_STATUS,
      payload: status,
    });
  };
}

// Filter value
export function filterContractor(filterVal: string) {
  console.log(filterVal)
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_CONTRACTOR,
      payload: filterVal,
    });
  };
}

// handleContractorSort
export function handleContractorSort(sort: any, order: any) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CONTRACTOR_SORT,
      payload: {
        sort: sort,
        order: order
      },
    });
  };
}
