import { Dispatch } from "redux";
import { IReportTableProps } from "../../types/report.type";
import React from "react";
import ReportCard from "./ReportCard";
import { RootState } from "../../index";
import { connect } from "react-redux";

type IReportTableState = {
  editDateTime: any;
  locationName: any;
};
class ReportTable extends React.Component<
  IReportTableProps,
  IReportTableState
> {
  constructor(props: IReportTableProps) {
    super(props);
    this.state = {
      editDateTime: "",
      locationName: [],
    };
  }

  getLocationDetails = async () => {
    new Promise(async (resolve, reject) => {
      if (this.props.data.length !== 0) {
        const processedAttachments = await Promise.all(
          this.props.data.map(async (data: any) => {
            if (
              data.sign_out_lat !== "0" &&
              data.sign_out_lng !== "0" &&
              data.sign_out_lat !== "0" &&
              data.sign_out_lng !== "0" &&
              this.state.locationName.length === 0
            ) {
              let address = await this.reverseGeocode(
                data.sign_out_lat,
                data.sign_out_lng
              );
              this.setState({
                locationName: [...this.state.locationName, address],
              });
            } else {
              this.setState({ locationName: [...this.state.locationName, ""] });
            }
            console.log(this.state.locationName);
          })
        );
      }
    });
  };

  reverseGeocode = async (
    latitude: any,
    longitude: any
  ): Promise<string | undefined> => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
      );
      const data = await response.json();
      return data.display_name;
    } catch (error) {
      console.error("Error reverse geocoding location:", error);
    }
  };
  render(): React.ReactNode {
    return (
      <tbody>
        {this.props.data.map((task, index) => (
          <ReportCard
            latestState={this.props.curState}
            task={task}
            key={index + task.branch_name}
            Delete_Report={false}
          />
        ))}
      </tbody>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  selectedReportData: state.report.selectedReportData,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportTable);
