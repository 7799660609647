import React from "react";
import { Modal, Button } from "react-bootstrap";
import { CloseBtnSVG } from "../../assets/images/Location";
import QRCode from 'qrcode';
import { IDisplayQRProps, IDisplayQRState } from "../../types/branch.type";
import { RootState } from '../../index';
import { connect } from "react-redux";
import { Dispatch } from "redux";

class DisplayQR extends React.Component<IDisplayQRProps, IDisplayQRState> {
    constructor(props: IDisplayQRProps) {
        super(props);
        this.state = {
            imgURL: ''
        }
    }

    componentDidMount(): void {
        this.generateQR();
    }

    // Generate QR Based on Table Data
    generateQR = async() => {
        const {lat, lng, name, id, client_id} = this.props.branch;
        try{
            const qrData:any = {lat, lng, name, id, client_id}
            const dataURL = await QRCode.toDataURL(JSON.stringify(qrData));
            this.setState({imgURL: dataURL})
        } catch (err) {
            console.log(err)
        }
    }

    // Download QR
    handleDownload = () => {
        const a = document.createElement("a"); //Create <a>
        a.href = this.state.imgURL; //Image Base64 Goes here
        a.download = "QR_Code.png"; //File name Here
        a.click(); //Downloaded file
        this.props.handleClose();
    }

    render(): React.ReactNode {
        return (
            <div>
                <Modal className="qr-modal" show={this.props.displayQR} onHide={this.props.handleClose}>
                    <Modal.Body>
                        <h3 className="text-center">{this.props.branch.name}</h3>
                        <a href="javascript:void(0)" className="qr-modal-close" onClick={this.props.handleClose}>
                            <CloseBtnSVG />
                        </a>

                        <div className="qr-modal-img">
                            <img className="img-thumb-perview" src={this.state.imgURL} alt="imageUrl"/>
                        </div>

                        <div className="qr-modal-footer d-flex justify-content-center align-items-center">
                            <Button className="login-button" variant="primary" onClick={this.handleDownload}>Download</Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = (state: RootState) => ({
    // selectedEmployee: state.employee.selectedEmployee
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        // selectEmployee: bindActionCreators(selectEmployee, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplayQR);