import React, { Component } from 'react';
import { connect } from 'react-redux';
import SideBar from "../../components/sidebar/SideBar";
import TopNav from "../../components/topnav/TopNav";
import { RootState } from '../..';
import { SearchSVG } from "../../assets/images/UsermanagementsSVG";
import RecommendedTable from '../Recommended/RecommendedTable';
import { Offcanvas } from 'react-bootstrap';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { PaperClipSVG, TrashSVG } from '../../assets/images/ImagesV2';
import { CustomSelect } from "../../shared/Components/CustomSelect";
import DatePicker from "../DailyTask/DatePicker";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";
import docImage from "../../assets/images/google-docs.png";
import TimePicker from "../DailyTask/TimePicker";
import Constants, { TASK_PRIORITY } from "../../shared/Constants";
import * as Yup from "yup";
import { addDailyTask, listAllClient, listAllCleaner, listAllBranch, getAllRecommendedTasks, getAllAdditionalTasks, updateSidebar, getAllRecommendedCleanerTasks } from '../../shared/Reducers';
import { bindActionCreators } from 'redux';
import { ICleaner } from '../../shared/ActionTypes';
import Button from "@material-ui/core/Button";
import ReactPaginate from 'react-paginate';

interface ICleanerInOutProps {
    status: any;
    clientList: any;
    branchList: any;
    listAllClient: any;
    listAllCleaner: any;
    listAllBranch: any;
    addDailyTask: any;
    getAllRecommendedTasks: any;
    getAllRecommendedCleanerTasks: any;
    getAllAdditionalTasks: any;
    filterTaskClientById: any;
    filterTaskBranchById: any;
    filterTaskPriorityById: any;
    filterTaskDueTypeByValue: any;
    filterSpecialRequestTaskStatusById: any;
    filterTaskStatusById: any;
    filterTaskDateTypeValue: any;
    filteredAllRecommendedTask: any;
    recommended_count: any;
    updateSidebar: any;
    userLoginPermission: any;
}
interface IRecommendedState {
    canvasModal: boolean;
    files_size: any;
    attachmentFiles: any;
    attachmentUrls: any;
    AttachmentPreviewStatus: any;
    previewUrl: any;
    previewType: any;
    file_count: any;
    task_date: any;
    selectedBranch: any;
    timeout: any;
    searchTerm: any;
    pagehandle: any;
    fieldName: any;
    orderType: any;
}
class Recommended extends Component<ICleanerInOutProps, IRecommendedState> {
    constructor(props: ICleanerInOutProps) {
        super(props);
        this.state = {
            canvasModal: false,
            files_size: 0,
            attachmentFiles: [],
            attachmentUrls: [],
            AttachmentPreviewStatus: false,
            previewUrl: "",
            previewType: "",
            file_count: "",
            selectedBranch: { value: "all", label: "All" },
            task_date: "",
            timeout: null,
            pagehandle: false,
            searchTerm: "",
            fieldName: "",
            orderType: "",
        };
    }

    componentDidMount(): void {
        this.props.listAllClient();
        this.props.listAllBranch(0);
        this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
        this.props.updateSidebar(true);
    }

    handlePageClick = (e: any) => {
        if (e.selected < this.props.recommended_count / Constants.PER_PAGE) {
            let query = `search=${this.state.searchTerm}&sort=${this.state.fieldName
                }&order=${this.state.orderType}&offset=${e.selected * Constants.PER_PAGE
                }&limit=${Constants.PER_PAGE}&client=${this.props.filterTaskClientById
                }&branch=${this.props.filterTaskBranchById}&task_type=3&task_priority=${this.props.filterTaskPriorityById
                }&due_type=${this.props.filterTaskDueTypeByValue}&status&start_date=${this.props.filterTaskDateTypeValue.from_date
                }&end_date=${this.props.filterTaskDateTypeValue.to_date
                }&recommended_task_status=all`;
            this.props.getAllRecommendedTasks(query);
            // this.getTasks(e.selected * Constants.PER_PAGE, Constants.PER_PAGE);
        }
    };

    getTasks = async (offset: number, limit: number) => {
        try {
            console.log(offset, "RecommendedLog", limit);

            if (Constants.LOGIN_USER_TYPE === 3) {
                // list for table
                let query = `search=&sort=&order=&offset=0&limit=10&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_priority=${this.props.filterTaskPriorityById}&due_type=${this.props.filterTaskDueTypeByValue}&task_status=${this.props.filterTaskStatusById}&special_request_task_status=${this.props.filterSpecialRequestTaskStatusById}&recommended_task_status=all&cleaner_id=${Constants.LOGIN_USER_ID}`;

                // list for excel report
                // let AllListquery = `search=&sort=&order=&offset=${offset}&limit=10000&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=3&task_priority=${this.props.filterTaskPriorityById}&due_type=${this.props.filterTaskDueTypeByValue}&task_status=${this.props.filterTaskStatusById}&special_request_task_status=${this.props.filterSpecialRequestTaskStatusById}&status&start_date=${this.props.filterTaskDateTypeValue.from_date}&end_date=${this.props.filterTaskDateTypeValue.to_date}`;
                await this.props.getAllRecommendedCleanerTasks(query);
                // await this.props.getAllRecommendedTasks(AllListquery);
            } else {
                // list for table
                let query = `search=&sort=&order=&offset=0&limit=10&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=3&task_priority=${this.props.filterTaskPriorityById}&due_type=${this.props.filterTaskDueTypeByValue}&task_status=${this.props.filterTaskStatusById}&special_request_task_status=${this.props.filterSpecialRequestTaskStatusById}&recommended_task_status=all`;

                // list for excel report
                // let AllListquery = `search=&sort=&order=&offset=${offset}&limit=10000&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=3&task_priority=${this.props.filterTaskPriorityById}&due_type=${this.props.filterTaskDueTypeByValue}&task_status=${this.props.filterTaskStatusById}&special_request_task_status=${this.props.filterSpecialRequestTaskStatusById}&status&start_date=${this.props.filterTaskDateTypeValue.from_date}&end_date=${this.props.filterTaskDateTypeValue.to_date}`;
                await this.props.getAllRecommendedTasks(query);
                // await this.props.getAllRecommendedTasks(AllListquery);
            }
        } catch (error) {
            console.log(error);
        }
    };

    handleFilter = (e: any) => {
        const value: string = e.target.value.toLowerCase();
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
        }
        this.setState({
            searchTerm: value,
            timeout: setTimeout(async () => {
                try {
                    if (Constants.LOGIN_USER_TYPE !== 3) {
                        let query = `search=${this.state.searchTerm}&sort=&order=&offset=0&limit=10&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=3&task_priority=${this.props.filterTaskPriorityById}&status&recommended_task_status=all`;
                        // no limit data
                        let AllListquery = `search=${this.state.searchTerm}&sort=&order=&offset=0&limit=10000&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=3&task_priority=${this.props.filterTaskPriorityById}&status&recommended_task_status=all`;
                        await this.props.getAllRecommendedTasks(query);
                        await this.props.getAllRecommendedTasks(AllListquery);
                    } else {
                        let query = `search=${this.state.searchTerm}&sort=&order=&offset=0&limit=10&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=3&task_priority=${this.props.filterTaskPriorityById}&status&recommended_task_status=all&cleaner_id=${Constants.LOGIN_USER_ID}`;
                        // no limit data
                        let AllListquery = `search=${this.state.searchTerm}&sort=&order=&offset=0&limit=10000&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=3&task_priority=${this.props.filterTaskPriorityById}&status&recommended_task_status=all&cleaner_id=${Constants.LOGIN_USER_ID}`;
                        await this.props.getAllRecommendedCleanerTasks(query);
                        await this.props.getAllRecommendedCleanerTasks(AllListquery);
                    }

                } catch (error) {
                    console.log(error);
                }
            }, 1000), // Set the delay time in ms
        });
    };

    validationSchema = () => {
        return Yup.object().shape({
            description: Yup.string().required("Enter Task Description"),
            attachments: Yup.mixed(),
            client: Yup.string().required("Client is required"),
            branch: Yup.string().required("Branch is required"),
            // task_date: Yup.string().required("Date is required")
        })
    };

    handleSubmit = async (formValues: any) => {
        var element = document.getElementById("spinner-border");
        var button = document.getElementById("create-button");

        if (element !== null && button !== null) {
            element.classList.remove("d-none")
            button.setAttribute("disabled", "")
        }
        let data = new FormData();
        data.append("description", formValues.description)
        for (let index = 0; index < this.state.attachmentFiles.length; index++) {
            data.append("attachments", this.state.attachmentFiles[index]);
        }
        data.append("title", "recommended");
        data.append("client", formValues.client);
        data.append("branch", formValues.branch);
        if (!this.state.task_date) {
            const currentDate = new Date().toISOString().split('T')[0];
            this.setState({ task_date: currentDate }, () => {
                data.append("task_date", this.state.task_date)
            })
        } else {
            data.append("task_date", this.state.task_date);
        }
        data.append("task_type", "3");
        data.append("any_time", "0");
        data.append("ref_task_id", "1");
        await this.props.addDailyTask(data);
        setTimeout(() => {
            this.handleCloseAdditionalTaskModal();
            this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
        }, 500);
    };

    handleCloseAdditionalTaskModal = () => {
        this.setState({
            canvasModal: false,
            attachmentUrls: [],
        });
    };

    handleToOpenCanvas = async () => {
        await this.props.listAllClient();
        // await this.props.listAllBranch();
        // await this.props.listAllCleaner();
        await this.setState({ canvasModal: true });
    };

    // handleToCloseCanvas = () => {
    //     this.setState({ canvasModal: false });
    // };

    handleImageChange = async (e: any) => {
        const file = e.target.files[0];
        this.setState(
            { files_size: this.state.files_size + file.size },
            async () => {
                if (this.state.files_size > 524288000) {
                    alert("File Size Must Be Less Than 500MB");
                    e.target.value = null;
                    return this.setState({
                        files_size: this.state.files_size - file.size,
                    });
                }

                let files = [];
                let urls = [];
                files = [...e.target.files];

                let fileurls: any = [];
                await files.map(async (file: any) => {
                    fileurls.push({
                        url: URL.createObjectURL(file),
                        file_type: file.type,
                    });
                });
                urls = [...fileurls];

                this.setState({
                    attachmentFiles: [...this.state.attachmentFiles, ...files],
                });
                this.setState(
                    { attachmentUrls: [...this.state.attachmentUrls, ...urls] },
                    () => {
                        e.target.value = "";
                    }
                );
            }
        );
    };

    handleDeleteImage = (index: number) => {
        const files = this.state.attachmentFiles;
        const urls = this.state.attachmentUrls;
        files.splice(index, 1);
        urls.splice(index, 1);

        this.setState({ attachmentFiles: files });
        this.setState({ attachmentUrls: urls });
    };

    handlePreviewOpen = (file_type: any, url: any, inx: any) => {
        if (
            file_type === "image/png" ||
            file_type === "image/jpeg" ||
            file_type === "image/jpg" ||
            file_type === "video/mp4" ||
            file_type === "video/webm" ||
            file_type === "application/pdf"
        ) {
            this.setState({
                AttachmentPreviewStatus: true,
                previewUrl: url,
                previewType: file_type,
                file_count: inx,
            });
        }
    };

    handleStartDateClick = () => {
        let datepopup: HTMLElement = document.querySelector(
            ".MuiPickersPopper-root"
        ) as HTMLElement;
        if (datepopup === null) {
            let startDate: HTMLElement = document.querySelector(
                ".startdate button.MuiButtonBase-root"
            ) as HTMLElement;
            startDate.click();
        }
    };

    handleTaskDate = (val: string) => {
        this.setState({ task_date: val });
    };

    handleChange = (e: any) => {
        if (e.target.name === "client") {
            this.setState({ selectedBranch: { value: "all", label: "All" } });
            this.props.listAllBranch(e.target.value);
        }
    };

    render() {
        const initialValues = {
            description: "",
            client: "",
            branch: "",
            attachments: "",
            task_date: "",
        }
        return (
            <div className="container-fluid p-0 m-0 page-body-wrapper background">
                <SideBar />
                <div className={this.props.status ? "main-panel" : "main-panel2"}>
                    <TopNav />
                    <div className='content-wrapper'>
                        <div className='container-fluid p-0'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <h3 className='mb-0'>Recommended</h3>
                                </div>
                            </div>
                        </div>
                        <div className='container-fluid p-0 mt-4'>
                            {/* <div className='container justify-content-md-center'> */}
                            <div className='row'>
                                <div className='col-md-4 col-lg-4'>
                                    <div className='input-group search'>
                                        <span
                                            className="input-group-text search-icon"
                                            id="basic-addon1"
                                        >
                                            <SearchSVG />
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search"
                                            value={this.state.searchTerm}
                                            onInput={this.handleFilter}
                                            onChange={(e) => this.setState({
                                                searchTerm: e.target.value
                                            })}
                                        />
                                    </div>
                                </div>
                                <Offcanvas
                                    className="task-offcanvas"
                                    placement='end'
                                    show={this.state.canvasModal}
                                    onHide={() => this.handleCloseAdditionalTaskModal()}
                                >
                                    <Offcanvas.Header closeButton>
                                        <Offcanvas.Title>
                                            <span className="offc-title">Add Recommended Task</span>
                                        </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <Formik
                                            initialValues={initialValues}
                                            enableReinitialize={true}
                                            onSubmit={this.handleSubmit}
                                            validationSchema={this.validationSchema}
                                        >
                                            {(props) => (
                                                <Form onChange={this.handleChange}>
                                                    <div className='mb-2'>
                                                        <label className='form-label'>
                                                            Recommended Description<span className="text-project">*</span>
                                                        </label>
                                                        <Field
                                                            as="textarea"
                                                            name="description"
                                                            className="form-control text-area"
                                                        />
                                                        <ErrorMessage
                                                            name="description"
                                                            component={"div"}
                                                            className="cus-alert"
                                                        />
                                                    </div>
                                                    <div>
                                                        <label className="form-label">Attachment
                                                            <span className="text-project">*</span>
                                                        </label>
                                                        <div className='attachment-btn dropdown-field'>
                                                            <input
                                                                type='file'
                                                                multiple
                                                                accept='.pdf,.PDF,.mp4,.MP4,.webm,.WEBM,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.doc,.DOC,.docx,.DOCX'
                                                                style={{ display: "none" }}
                                                                onChange={this.handleImageChange}
                                                                id='recommended-attachment'
                                                            />
                                                            <label htmlFor='recommended-attachment'>
                                                                <Button
                                                                    variant="contained"
                                                                    color="default"
                                                                    component="span"
                                                                >
                                                                    <PaperClipSVG /> Add attachment
                                                                </Button>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {this.state.attachmentUrls.length > 0 ? (
                                                        <div className='mb-2'>
                                                            <label className="form-label">Attachments</label>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <div className='row'>
                                                        <div className='col-md-12'>
                                                            <div className='d-flex row'>
                                                                {this.state.attachmentUrls && this.state.attachmentUrls.map((obj: any, index: number) => {
                                                                    return (
                                                                        <div className='img-card mr-16px mb-3'>
                                                                            <span className='delete '>
                                                                                <a onClick={() => this.handleDeleteImage(index)}>
                                                                                    <TrashSVG />
                                                                                </a>
                                                                            </span>
                                                                            <div className='img-preview'
                                                                                onClick={() => this.handlePreviewOpen(
                                                                                    obj.file_type,
                                                                                    obj.url,
                                                                                    index
                                                                                )}
                                                                            >
                                                                                <img className='img-thumb-perview'
                                                                                    src={
                                                                                        obj.file_type ===
                                                                                            "application/pdf"
                                                                                            ? pdfImage
                                                                                            : obj.file_type ===
                                                                                                "video/mp4" ||
                                                                                                obj.file_type === "video/webm"
                                                                                                ? videoImage
                                                                                                : obj.file_type ===
                                                                                                    "image/png" ||
                                                                                                    obj.file_type ===
                                                                                                    "image/jpeg" ||
                                                                                                    obj.file_type === "image/jpg"
                                                                                                    ? obj.url
                                                                                                    : docImage
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='mb-2 col-md-6'>
                                                            <label className='form-label'>
                                                                Client<span className="text-project">*</span>
                                                            </label>
                                                            <Field
                                                                as="select"
                                                                className="form-select dropdown-field"
                                                                name="client"
                                                            >
                                                                <option value={""} disabled>
                                                                    Select Client
                                                                </option>
                                                                {this.props.clientList.map((client: ICleaner) => (
                                                                    <option
                                                                        value={client.id.toString()}
                                                                        key={client.id}
                                                                    >
                                                                        {`${client.first_name} ${client.last_name}`}
                                                                    </option>
                                                                ))}
                                                            </Field>
                                                            <ErrorMessage
                                                                name="client"
                                                                component={"div"}
                                                                className="cus-alert"
                                                            />
                                                        </div>
                                                        <div className='mb-2 col-md-6'>
                                                            <label className='form-label'>
                                                                Branch<span className="text-project">*</span>
                                                            </label>
                                                            <Field
                                                                className="custom-select dropdown-field"
                                                                name="branch"
                                                                options={[
                                                                    {
                                                                        value: "all", label: "All"
                                                                    },
                                                                    ...this.props.branchList
                                                                ]}
                                                                value={this.state.selectedBranch}
                                                                component={CustomSelect}
                                                                placeholder="Select Branch"
                                                                isMulti={false}
                                                            />
                                                            <ErrorMessage
                                                                name="branch"
                                                                component={"div"}
                                                                className="cus-alert"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='mb-2 col-md-6'>
                                                        <label className='form-label'>
                                                            Date<span className="text-project">*</span>
                                                        </label>
                                                        <div className='startdate'
                                                            onClick={() => this.handleStartDateClick()}
                                                        >
                                                            <DatePicker
                                                                name="task_date"
                                                                value={this.state.task_date}
                                                                onChange={this.handleTaskDate}
                                                            />
                                                            <ErrorMessage
                                                                name="task_date"
                                                                component={"div"}
                                                                className="cus-alert"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <div className='mr-16px'>
                                                            <button
                                                                type='submit'
                                                                id='create-button'
                                                                className='btn btn-primary create-button'
                                                            >
                                                                Request{" "}
                                                                <span
                                                                    id="spinner-border"
                                                                    className="spinner-border spinner-border-sm d-none"
                                                                ></span>
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <button
                                                                type="button"
                                                                className="btn cancel-button"
                                                                onClick={() =>
                                                                    this.handleCloseAdditionalTaskModal()
                                                                }
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Offcanvas.Body>
                                </Offcanvas>
                                {Constants.LOGIN_USER_TYPE !== 3 && (
                                    <div className='col'>
                                        <Button
                                            variant="outlined"
                                            onClick={this.handleToOpenCanvas}
                                            className='res-mt-3 btn btn-primary login-button w-170px ml-2 flex-mr-16px mob-res-w-100 btn btn-primary'
                                            style={{ border: "1px solid lightgray" }}
                                        >
                                            Add Recommended
                                        </Button>
                                    </div>
                                )}
                            </div>
                            {/* </div> */}
                            {/* <div className='container'> */}
                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <div className='table-responsive'>
                                                <table className='table daily-task-table'>
                                                    <thead>
                                                        <tr>
                                                            <th scope='col'>CREATED_BY</th>
                                                            <th scope='col'>NAME</th>
                                                            {this.props.userLoginPermission?.recommended_approved === 1 &&
                                                                <th scope='col'>TASK STATUS</th>
                                                            }
                                                            <th scope='col'>TYPE</th>
                                                            <th scope='col'>CLIENT</th>
                                                            <th scope='col'>BRANCH</th>
                                                            <th scope='col'>DATE</th>
                                                            {/* {this.props.userLoginPermission?.recommended_approved === 1 &&
                                                                <th scope='col'>TASK STATUS</th>
                                                            } */}
                                                            <th scope='col'>ACTION</th>
                                                        </tr>
                                                    </thead>
                                                    <RecommendedTable
                                                        data={this.props.filteredAllRecommendedTask}
                                                    />
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                        <div className="row mt-2">
                            {this.props.recommended_count > 10 ? (
                                <div className="col-md-12">
                                    {this.state.pagehandle ? null : (
                                        <ReactPaginate
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={this.props.recommended_count / Constants.PER_PAGE}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={2}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={"pagination"}
                                            nextClassName={"next-page"}
                                            activeClassName={"active"}
                                        />
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    status: state.sidebar.status,
    clientList: state.tasktemplate.clientList,
    filterTaskClientById: state.dailytask.filterTaskClientById,
    branchList: state.tasktemplate.branchList,
    filterTaskBranchById: state.dailytask.filterTaskBranchById,
    filterTaskDueTypeByValue: state.dailytask.filterTaskDueTypeByValue,
    filterTaskPriorityById: state.dailytask.filterTaskPriorityById,
    filterTaskStatusById: state.dailytask.filterTaskStatusById,
    filterSpecialRequestTaskStatusById:
        state.dailytask.filterSpecialRequestTaskStatusById,
    filterTaskDateTypeValue: state.dailytask.filterTaskDateTypeValue,
    filteredAllRecommendedTask: state.dailytask.filteredAllRecommendedTask,
    recommended_count: state.dailytask.recommended_count,
    userLoginPermission: state.sidebar.userLoginPermission,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateSidebar: bindActionCreators(updateSidebar, dispatch),
        addDailyTask: bindActionCreators(addDailyTask, dispatch),
        listAllCleaner: bindActionCreators(listAllCleaner, dispatch),
        listAllClient: bindActionCreators(listAllClient, dispatch),
        listAllBranch: bindActionCreators(listAllBranch, dispatch),
        getAllAdditionalTasks: bindActionCreators(getAllAdditionalTasks, dispatch),
        getAllRecommendedTasks: bindActionCreators(getAllRecommendedTasks, dispatch),
        getAllRecommendedCleanerTasks: bindActionCreators(getAllRecommendedCleanerTasks, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Recommended);
