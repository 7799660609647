import { Dispatch, AnyAction } from "redux";
import { AppDispatch } from "../..";
import { TShiftTable } from "../../types/user.type";
import {
  ShiftTemplateState,
  API_GET_ALL_SHIFTS,
  API_GET_ALL_SHIFTS_SUCCESS,
  API_GET_ALL_SHIFTS_FAIL,
  API_GET_SHIFT_BY_ID,
  API_GET_SHIFT_BY_ID_SUCCESS,
  API_GET_SHIFT_BY_ID_FAIL,
  API_ADD_SHIFT,
  API_ADD_SHIFT_SUCCESS,
  API_ADD_SHIFT_FAIL,
  API_EDIT_SHIFT,
  API_EDIT_SHIFT_SUCCESS,
  API_EDIT_SHIFT_FAIL,
  API_DELETE_SHIFT,
  API_DELETE_SHIFT_SUCCESS,
  API_DELETE_SHIFT_FAIL,
  UPDATE_ADD_SHIFT_DLG_STATUS,
  UPDATE_EDIT_SHIFT_DLG_STATUS,
  UPDATE_DELETE_SHIFT_DLG_STATUS,
  UPDATE_SHIFT_FETCH_STATUS,
  FILTER_SHIFT,
  UPDATE_SHIFTS,
  IEditShift,
  API_ADD_SHIFT_PROGRESS,
  API_EDIT_SHIFT_PROGRESS,
  API_VIEW_SHIFT_BY_ID,
  API_VIEW_SHIFT_BY_ID_FAIL,
  API_VIEW_SHIFT_BY_ID_SUCCESS,
  UPDATE_VIEW_SHIFT,
  SHIFT_SORT
} from "../ActionTypes";

const initialState: ShiftTemplateState = {
  cleanerList: [],
  addShiftStatus: false,
  addShiftSuccess: "",
  deleteShiftStatus: false,
  deleteShiftSuccess: "",
  editShiftStatus: false,
  editShiftSuccess: "",
  shiftFetchStatus: false,
  selectedShiftData: null,
  shiftData: [],
  filteredShiftData: [],
  id: "",
  total_count: 0,
  addShiftError: "",
  deleteShiftError: "",
  editShiftError: "",
  processTrigger: false,
  progressPercentage: 0,
  viewShiftData: null,
  viewShiftStatus: false,
  shiftSortBy : "",
  shiftOrderBy : "",
};

export const ShiftReducer = (
  state: ShiftTemplateState = initialState,
  action: AnyAction
): ShiftTemplateState => {
  switch (action.type) {
    case UPDATE_SHIFTS:
      return { ...state };
    case UPDATE_SHIFT_FETCH_STATUS:
      return { ...state, shiftFetchStatus: action.payload };
    case API_GET_ALL_SHIFTS:
      return { ...state };
    case API_GET_ALL_SHIFTS_SUCCESS:
      let shiftData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "shiftes" in data && data.shiftes) {
          shiftData = data.shiftes;
        }
      }
      let total_count = 0;
      if (action.payload) {
        const { data } = action.payload;
        if (data && "total_count" in data && data.total_count) {
          total_count = data.total_count;
        }
      }
      return {
        ...state,
        total_count: total_count,
        editShiftStatus: false,
        addShiftStatus: false,
        deleteShiftStatus: false,
        filteredShiftData: shiftData,
      };
    case API_GET_ALL_SHIFTS_FAIL:
      return { ...state };
    case API_GET_SHIFT_BY_ID:
      return { ...state, id: action.payload.id };
    case API_GET_SHIFT_BY_ID_SUCCESS:
      let shifts = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "shift" in data && data.shift) {
          shifts = data.shift;
        }
      }
      return {
        ...state,
        selectedShiftData: shifts,
        editShiftStatus: true,
        shiftFetchStatus: false,
      };
    case API_GET_SHIFT_BY_ID_FAIL:
      return { ...state };
    case API_VIEW_SHIFT_BY_ID:
      return { ...state, id: action.payload.id };
    case API_VIEW_SHIFT_BY_ID_SUCCESS:
      let shift = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "shift" in data && data.shift) {
          shift = data.shift;
        }
      }
      return {
        ...state,
        viewShiftData: shift,
        viewShiftStatus: true,
        shiftFetchStatus: false,
      };
    case API_VIEW_SHIFT_BY_ID_FAIL:
      return { ...state };
    case UPDATE_VIEW_SHIFT:
      return { ...state, viewShiftStatus: action.payload };
    case API_ADD_SHIFT:
      let values: boolean = false;
      if (action.payload.request.data.FormData === undefined) {
        values = true;
      }
      return {
        ...state,
        addShiftSuccess: "",
        addShiftError: "",
        processTrigger: values,
      };
    case API_ADD_SHIFT_SUCCESS:
      let message = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          message = data.message;
        }
      }
      return {
        ...state,
        addShiftStatus: false,
        addShiftSuccess: message,
        processTrigger: false,
        progressPercentage: 0,
      };
    case API_ADD_SHIFT_FAIL:
      let err = "";
      if (
        "error" in action &&
        action.error &&
        "response" in action.error &&
        action.error.response &&
        "data" in action.error.response &&
        action.error.response.data &&
        "message" in action.error.response.data
      ) {
        err = action.error.response.data.message;
      }
      return { ...state, addShiftError: err };
    case API_ADD_SHIFT_PROGRESS:
      return { ...state, progressPercentage: action.payload };
    case API_EDIT_SHIFT_PROGRESS:
      return { ...state, progressPercentage: action.payload };
    case UPDATE_ADD_SHIFT_DLG_STATUS:
      return { ...state, addShiftStatus: action.payload };
    case API_EDIT_SHIFT:
      let value: boolean = false;
      if (action.payload.request.data.FormData === undefined) {
        value = true;
      }
      return { ...state, editShiftSuccess: "", processTrigger: value };
    case API_EDIT_SHIFT_SUCCESS:
      let messages = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          messages = data.message;
        }
      }
      return {
        ...state,
        editShiftStatus: false,
        shiftFetchStatus: true,
        editShiftSuccess: messages,
        processTrigger: false,
        progressPercentage: 0,
      };
    case API_EDIT_SHIFT_FAIL:
      let error = "";
      if (
        "error" in action &&
        action.error &&
        "response" in action.error &&
        action.error.response &&
        "data" in action.error.response &&
        action.error.response.data &&
        "message" in action.error.response.data
      ) {
        error = action.error.response.data.message;
      }
      return { ...state, editShiftError: error };
    case UPDATE_EDIT_SHIFT_DLG_STATUS:
      return { ...state, editShiftStatus: action.payload };
    case API_DELETE_SHIFT:
      return { ...state, deleteShiftSuccess: "" };
    case API_DELETE_SHIFT_SUCCESS:
      let msg = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          msg = data.message;
        }
      }
      return {
        ...state,
        deleteShiftStatus: false,
        shiftFetchStatus: true,
        deleteShiftSuccess: msg,
      };
    case API_DELETE_SHIFT_FAIL:
      return { ...state };
    case UPDATE_DELETE_SHIFT_DLG_STATUS:
      return { ...state, deleteShiftStatus: action.payload };
    case FILTER_SHIFT:
      const filterShift: TShiftTable[] = state.filteredShiftData.filter(
        (obj:any) => {
          return (
            obj.name.toLowerCase().includes(action.payload) 
            // ||
            // obj.first_name.includes(action.payload) ||
            // obj.last_name.includes(action.payload)
          );
        }
      );
      return { ...state, filteredShiftData: filterShift };
    case SHIFT_SORT:
      return { ...state, shiftSortBy: action.payload.sort, shiftOrderBy : action.payload.order };
    default:
      return state;
  }
};
// update shift
export function updateShifts(data: any) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_SHIFTS,
      payload: data,
    });
  };
}

// get shift by id
export function getShiftById(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_SHIFT_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `shift/${id}` : `shift`,
        },
        id,
      },
    });
  };
}

// VIEW shift by id
export function viewShiftById(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_VIEW_SHIFT_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `shift/${id}` : `shift`,
        },
        id,
      },
    });
  };
}

export function updateViewShift(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_VIEW_SHIFT,
      payload: status,
    });
  };
}


// get all shifts for table
export function getAllShifts(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ALL_SHIFTS,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `shift?${queryStr}` : `shift?`,
        },
      },
    });
  };
}

// add shift
export function addShift(payload: FormData) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_ADD_SHIFT,
      payload: {
        request: {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            contentType: false,
          },
          method: "post",
          url: `shift`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_ADD_SHIFT_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

export function updateAddShift(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_ADD_SHIFT_DLG_STATUS,
      payload: status,
    });
  };
}

// edit shift
export function editShift(payload: IEditShift, id: number) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_EDIT_SHIFT,
      payload: {
        request: {
          method: "put",
          url: `shift/${id}`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_EDIT_SHIFT_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

// update edit employee
export function updateEditShiftDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_EDIT_SHIFT_DLG_STATUS,
      payload: status,
    });
  };
}

// Fetch Shift after add, edit, delete
export function updateShiftFetchStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_SHIFT_FETCH_STATUS,
      payload: status,
    });
  };
}

// delete shift
export function deleteShift(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_DELETE_SHIFT,
      payload: {
        request: {
          method: "delete",
          url: `shift/${id}`,
        },
      },
    });
  };
}

// update delete shift
export function updateDeleteShiftDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_DELETE_SHIFT_DLG_STATUS,
      payload: status,
    });
  };
}

// Filter value
export function filterShift(filterVal: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: FILTER_SHIFT,
      payload: filterVal,
    });
  };
}

// handleShiftSort
export function handleShiftSort(sort : any, order : any){
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SHIFT_SORT,
      payload: {
        sort : sort,
        order : order
      },
    });
  };
}
