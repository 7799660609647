import * as Yup from "yup";

import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  editEmailMessage,
  getEmailMessage,
  updateSidebar,
  getAllEmailTemplate,
} from "../../shared/Reducers";

import { CloseBtnSVG } from "../../assets/images/Location";
import { CustomSelect } from "../../shared/Components/CustomSelect";
import { EMAIL_TEMPLATE_SLUG } from "../../shared/Constants";
import { Editor } from "@tinymce/tinymce-react";
import React from "react";
import { RootState } from "../../index";
import SideBar from "../../components/sidebar/SideBar";
import { SuccessToastSVG } from "../../assets/images/ImagesV2";
import { Toast } from "react-bootstrap";
import TopNav from "../../components/topnav/TopNav";
import { connect } from "react-redux";

export interface IEmailTemplateProps {
  email_Data: any;
  getEmailMessage: any;
  editEmailMessage: any;
  emailSuccess: string;
  status: any;
  updateSidebar: any;
  getAllEmailTemplate: any;
  emailTemplate: any;
}

export interface IEmailTemplateState {
  message: string;
  subject: string;
  success: string;
  responseData: string;
  displayEditor: boolean;
  addToast: boolean;
  template: any;
}

let templateName = "";
class EmailTemplate extends React.Component<
  IEmailTemplateProps,
  IEmailTemplateState
> {
  constructor(props: IEmailTemplateProps) {
    super(props);
    this.state = {
      message: "",
      subject: "",
      success: "",
      responseData: "",
      displayEditor: false,
      addToast: false,
      template: null,
    };
  }

  componentDidMount(): void {
    this.props.updateSidebar(true);
    document.title = "Email Template";
    // if (this.props.emailData) {
    //   console.log(this.props.emailData, "emaildata");

    //   this.setState({
    //     message: this.props.emailData.message,
    //     subject: this.props.emailData.subject,
    //     template: this.props.emailData.template,
    //   });
    // }
    this.setState({ displayEditor: true });
    this.props.getAllEmailTemplate();
  }

  componentWillUnmount(): void {
    this.setState({
      displayEditor: false,
    });
  }
  addToastClose = () => {
    this.setState({ addToast: false });
  };
  handleEmailTemplate = async (formValue: any) => {
    this.setState({ addToast: true });
    const payload = {
      message: formValue.message,
      subject: formValue.subject,
      template: formValue.template,
    };
    this.props.editEmailMessage(payload, this.state.responseData);
    setTimeout(() => {
      this.setState({ addToast: false });
    }, 5000);
  };

  handleChange = (e: any) => {
    console.log("handleChange", e.value);
    console.log("handleChange", e.label);
    console.log("handleChange", e.message);
    const setName = e.value;
    this.setState({ template: setName, subject: e.label, message: e.message });
    if (this.state.responseData !== e.value) {
      this.setState({ responseData: e.value });
      this.props.getEmailMessage(e.value);
    }
  };

  validationSchema = () => {
    return Yup.object().shape({
      message: Yup.string().required("Message is required"),
      subject: Yup.string().required("Subject is required"),
      // template: Yup.string().required("template name is required"),
    });
  };

  render(): React.ReactNode {
    const initialValues = {
      message: this.state.message,
      subject: this.state.subject,
      template: this.state.template,
    };
    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className={this.props.status ? "main-panel" : "main-panel2"}>
          <TopNav />
          <div className="content-wrapper">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="mb-0">Email template</h3>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-end">
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              {this.state.addToast ? (
                <Toast className="success-alerts">
                  <Toast.Body>
                    <div className="row align-items-center">
                      <div className="col-md-2">
                        <SuccessToastSVG />
                      </div>
                      <div className="col-md-8">{this.props.emailSuccess}</div>
                      <div className="col-md-2">
                        <button
                          type="button"
                          className="btn"
                          onClick={this.addToastClose}
                        >
                          <CloseBtnSVG />
                        </button>
                      </div>
                    </div>
                  </Toast.Body>
                </Toast>
              ) : (
                ""
              )}
              <Formik
                onSubmit={this.handleEmailTemplate}
                validationSchema={this.validationSchema}
                enableReinitialize={true}
                initialValues={initialValues}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className="row mt-4">
                      <div className="col-md-12 col-lg-3">
                        <label className="form-label">
                          Template name<span className="text-project">*</span>
                        </label>
                        <Field
                          className="custom-select dropdown-field"
                          value={templateName}
                          options={this.props.emailTemplate.map((item: any) => {
                            return {
                              value: item.id,
                              label: item.slug,
                              message: item.message,
                            };
                          })}
                          onChange={(e: any) => {
                            this.handleChange(e);
                          }}
                          component={CustomSelect}
                          placeholder="Select the Role"
                          isMulti={false}
                        ></Field>
                        <ErrorMessage
                          name="template"
                          component={"div"}
                          className="cus-alert"
                        />
                      </div>
                      <div className="col-md-12 col-lg-9">
                        <label className="form-label">
                          Subject<span className="text-project">*</span>
                        </label>
                        <Field
                          type="text"
                          name="subject"
                          value={this.state.subject}
                          className="email-template form-control"
                        />
                        <ErrorMessage
                          name="subject"
                          component={"div"}
                          className="cus-alert"
                        />
                      </div>
                      <div className="col-md-12 mt-2">
                        <label className="form-label mt-2">
                          Message<span className="text-project">*</span>
                        </label>
                        <Field name="message">
                          {({ field }: any) =>
                          {
                            console.log("FieldMessage",field);
                            
                            return(
                              this.state.displayEditor ? (
                                <Editor
                                  tinymceScriptSrc="/tinymce/tinymce.min.js"
                                  apiKey="8s18c14jxrf9f401ko83bu871zhvtsvi3ujrfx3cw3h7sofb"
                                  initialValue={field.value}
                                  onChange={(e: any) =>
                                    setFieldValue(
                                      field.name,
                                      e.target.getContent()
                                    )
                                  }
                                  id="article_body"
                                  init={{
                                    height: 428,
                                    menubar: true,
                                    width: 1097,
                                    statusbar: false,
                                    promotion: false,
                                    plugins: [
                                      "advlist autolink lists link image charmap print preview anchor",
                                      "searchreplace visualblocks code fullscreen",
                                      "insertdatetime media table paste code help wordcount",
                                      "link",
                                      "codesample",
                                      "code",
                                    ],
                                    toolbar:
                                      "undo redo | formatselect | link | code |" +
                                      "bold italic backcolor | alignleft aligncenter " +
                                      "alignright alignjustify | bullist numlist outdent indent | " +
                                      "removeformat | help",
                                    content_style:
                                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                  }}
                                />
                              ) : (
                                <></>
                              )
                            )
                           
                          }
                            
                          }
                        </Field>
                        <ErrorMessage
                          name="message"
                          component={"div"}
                          className="cus-alert"
                        />
                      </div>
                    </div>
                    <div className="row mt-3 mb-3">
                      <div className="col-md-12">
                        <div className="d-flex">
                          <div className="mr-16px">
                            <button
                              type="submit"
                              id="create-button"
                              className="btn btn-primary create-button"
                            >
                              Update
                              <span
                                id="spinner-border"
                                className="spinner-border spinner-border-sm d-none"
                              ></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="text-project">
                      {this.props.emailSuccess}
                    </div> */}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  email_Data: state.email.email_Data,
  emailSuccess: state.email.emailSuccess,
  status: state.sidebar.status,
  emailTemplate: state.email.emailTemplate,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getEmailMessage: bindActionCreators(getEmailMessage, dispatch),
    editEmailMessage: bindActionCreators(editEmailMessage, dispatch),
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
    getAllEmailTemplate: bindActionCreators(getAllEmailTemplate, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplate);
