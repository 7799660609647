import { Dispatch, AnyAction } from "redux";
import { AppDispatch } from "../..";
import { TComplaintTable } from "../../types/user.type";
import {
  API_GET_ALL_CLIENTS,
  API_GET_ALL_CLIENTS_SUCCESS,
  API_GET_ALL_CLIENTS_FAIL,
  API_GET_ALL_BRANCH_FAIL,
  ComplaintTemplateState,
  API_GET_ALL_COMPLAINTS,
  API_GET_ALL_COMPLAINTS_SUCCESS,
  API_GET_ALL_COMPLAINTS_FAIL,
  API_GET_COMPLAINT_BY_ID,
  API_GET_COMPLAINT_BY_ID_SUCCESS,
  API_GET_COMPLAINT_BY_ID_FAIL,
  API_GET_COMPLAINT_BY_ID_FOR_CHAT,
  API_GET_COMPLAINT_BY_ID_FOR_CHAT_SUCCESS,
  API_GET_COMPLAINT_BY_ID_FOR_CHAT_FAIL,
  API_ADD_COMPLAINT,
  API_ADD_COMPLAINT_SUCCESS,
  API_ADD_COMPLAINT_FAIL,
  API_ADD_COMPLAINT_MESSAGE,
  API_ADD_COMPLAINT_MESSAGE_SUCCESS,
  API_ADD_COMPLAINT_MESSAGE_FAIL,
  API_EDIT_COMPLAINT,
  API_EDIT_COMPLAINT_SUCCESS,
  API_EDIT_COMPLAINT_FAIL,
  API_DELETE_COMPLAINT,
  API_DELETE_COMPLAINT_SUCCESS,
  API_DELETE_COMPLAINT_FAIL,
  UPDATE_ADD_COMPLAINT_DLG_STATUS,
  UPDATE_CHAT_COMPLAINT_DLG_STATUS,
  UPDATE_EDIT_COMPLAINT_DLG_STATUS,
  UPDATE_DELETE_COMPLAINT_DLG_STATUS,
  UPDATE_COMPLAINT_FETCH_STATUS,
  FILTER_COMPLAINT,
  UPDATE_COMPLAINTS,
  IEditComplaint,
  API_ADD_COMPLAINT_PROGRESS,
  API_EDIT_COMPLAINT_PROGRESS,
  API_VIEW_COMPLAINT_BY_ID,
  API_VIEW_COMPLAINT_BY_ID_FAIL,
  API_VIEW_COMPLAINT_BY_ID_SUCCESS,
  UPDATE_VIEW_COMPLAINT,
  COMPLAINT_SORT,
  API_MESSAGE_STATUS_CHANGE
} from "../ActionTypes";

const initialState: ComplaintTemplateState = {
  cleanerList: [],
  clientList: [],
  addComplaintStatus: false,
  chatComplaintStatus: false,
  addComplaintSuccess: "",
  deleteComplaintStatus: false,
  deleteComplaintSuccess: "",
  editComplaintStatus: false,
  editComplaintSuccess: "",
  complaintFetchStatus: false,
  selectedComplaintData: null,
  complaintData: [],
  filteredComplaintData: [],
  id: "",
  total_count: 0,
  addComplaintError: "",
  deleteComplaintError: "",
  editComplaintError: "",
  processTrigger: false,
  progressPercentage: 0,
  viewComplaintData: null,
  viewComplaintStatus: false,
  complaintSortBy: "",
  complaintOrderBy: "",
  messageSendSuccess: false,
  msgStatusChange: ""
};

export const ComplaintReducer = (
  state: ComplaintTemplateState = initialState,
  action: AnyAction
): ComplaintTemplateState => {
  switch (action.type) {
    case UPDATE_COMPLAINTS:
      return { ...state };
    case API_GET_ALL_CLIENTS:
      return { ...state };
    case API_GET_ALL_CLIENTS_SUCCESS:
      let clientData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "clients" in data && data.clients) {
          clientData = data.clients;
        }
      }
      return { ...state, clientList: clientData };
    case API_GET_ALL_CLIENTS_FAIL:
      return { ...state };
    case API_GET_ALL_BRANCH_FAIL:
      return { ...state };
    case UPDATE_COMPLAINT_FETCH_STATUS:
      return { ...state, complaintFetchStatus: action.payload };
    case API_GET_ALL_COMPLAINTS:
      return { ...state };
    case API_GET_ALL_COMPLAINTS_SUCCESS:
      let complaintData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "complaints" in data && data.complaints) {
          complaintData = data.complaints;
        }
      }
      let total_count = 0;
      if (action.payload) {
        const { data } = action.payload;
        if (data && "total_count" in data && data.total_count) {
          total_count = data.total_count;
        }
      }
      return {
        ...state,
        total_count: total_count,
        editComplaintStatus: false,
        addComplaintStatus: false,
        deleteComplaintStatus: false,
        filteredComplaintData: complaintData,
      };
    case API_GET_ALL_COMPLAINTS_FAIL:
      return { ...state };
    case API_GET_COMPLAINT_BY_ID:
      return { ...state, id: action.payload.id };
    case API_GET_COMPLAINT_BY_ID_SUCCESS:
      let complaints = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "complaint" in data && data.complaint) {
          complaints = data.complaint;
        }
      }
      return {
        ...state,
        selectedComplaintData: complaints,
        editComplaintStatus: true,
        complaintFetchStatus: false,
      };
    case API_GET_COMPLAINT_BY_ID_FAIL:
      return { ...state };
    case API_GET_COMPLAINT_BY_ID_FOR_CHAT:
      return { ...state, id: action.payload.id };
    case API_GET_COMPLAINT_BY_ID_FOR_CHAT_SUCCESS:
      let getComplaint = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "complaint" in data && data.complaint) {
          getComplaint = data.complaint;
        }
      }
      return {
        ...state,
        selectedComplaintData: getComplaint,
        chatComplaintStatus: true,
        complaintFetchStatus: false,
      };
    case API_GET_COMPLAINT_BY_ID_FOR_CHAT_FAIL:
      return { ...state };
    case API_VIEW_COMPLAINT_BY_ID:
      return { ...state, id: action.payload.id };
    case API_VIEW_COMPLAINT_BY_ID_SUCCESS:
      let complaint = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "complaint" in data && data.complaint) {
          complaint = data.complaint;
        }
      }
      return {
        ...state,
        viewComplaintData: complaint,
        viewComplaintStatus: true,
        complaintFetchStatus: false,
      };
    case API_VIEW_COMPLAINT_BY_ID_FAIL:
      return { ...state };
    case UPDATE_VIEW_COMPLAINT:
      return { ...state, viewComplaintStatus: action.payload };
    case API_ADD_COMPLAINT:
      let values: boolean = false;
      if (action.payload.request.data.FormData === undefined) {
        values = true;
      }
      return {
        ...state,
        addComplaintSuccess: "",
        addComplaintError: "",
        processTrigger: values,
      };
    case API_ADD_COMPLAINT_SUCCESS:
      let message = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          message = data.message;
        }
      }
      return {
        ...state,
        addComplaintStatus: false,
        addComplaintSuccess: message,
        processTrigger: false,
        progressPercentage: 0,
      };
    case API_ADD_COMPLAINT_FAIL:
      let err = "";
      if (
        "error" in action &&
        action.error &&
        "response" in action.error &&
        action.error.response &&
        "data" in action.error.response &&
        action.error.response.data &&
        "message" in action.error.response.data
      ) {
        err = action.error.response.data.message;
      }
      return { ...state, addComplaintError: err, processTrigger: false, progressPercentage: 0 };
    case API_ADD_COMPLAINT_PROGRESS:
      return { ...state, progressPercentage: action.payload };
    case API_EDIT_COMPLAINT_PROGRESS:
      return { ...state, progressPercentage: action.payload };
    case UPDATE_ADD_COMPLAINT_DLG_STATUS:
      return { ...state, addComplaintStatus: action.payload };
    case UPDATE_CHAT_COMPLAINT_DLG_STATUS:
      return { ...state, chatComplaintStatus: action.payload };
    case API_EDIT_COMPLAINT:
      let value: boolean = false;
      if (action.payload.request.data.FormData === undefined) {
        value = true;
      }
      return { ...state, editComplaintSuccess: "", processTrigger: value };
    case API_EDIT_COMPLAINT_SUCCESS:
      let messages = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          messages = data.message;
        }
      }
      return {
        ...state,
        editComplaintStatus: false,
        complaintFetchStatus: true,
        editComplaintSuccess: messages,
        processTrigger: false,
        progressPercentage: 0,
      };
    case API_EDIT_COMPLAINT_FAIL:
      let error = "";
      if (
        "error" in action &&
        action.error &&
        "response" in action.error &&
        action.error.response &&
        "data" in action.error.response &&
        action.error.response.data &&
        "message" in action.error.response.data
      ) {
        error = action.error.response.data.message;
      }
      return { ...state, editComplaintError: error };
    case UPDATE_EDIT_COMPLAINT_DLG_STATUS:
      return { ...state, editComplaintStatus: action.payload };
    case API_DELETE_COMPLAINT:
      return { ...state, deleteComplaintSuccess: "" };
    case API_DELETE_COMPLAINT_SUCCESS:
      let msg = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          msg = data.message;
        }
      }
      return {
        ...state,
        deleteComplaintStatus: false,
        complaintFetchStatus: true,
        deleteComplaintSuccess: msg,
      };
    case API_DELETE_COMPLAINT_FAIL:
      return { ...state };
    case UPDATE_DELETE_COMPLAINT_DLG_STATUS:
      return { ...state, deleteComplaintStatus: action.payload };
    case FILTER_COMPLAINT:
      const filterComplaint: TComplaintTable[] = state.complaintData.filter(
        (obj) => {
          return (
            obj.name.toLowerCase().includes(action.payload) ||
            obj.user_first_name.toLowerCase().includes(action.payload) ||
            obj.user_last_name.toLowerCase().includes(action.payload)
          );
        }
      );
      console.log(filterComplaint);

      return { ...state, filteredComplaintData: filterComplaint };
    case COMPLAINT_SORT:
      return { ...state, complaintSortBy: action.payload.sort, complaintOrderBy: action.payload.order };
    case API_ADD_COMPLAINT_MESSAGE:
      return { ...state, messageSendSuccess: false };
    case API_ADD_COMPLAINT_MESSAGE_SUCCESS:
      return { ...state, messageSendSuccess: true };
    case API_ADD_COMPLAINT_MESSAGE_FAIL:
      return { ...state, messageSendSuccess: false };
    case API_MESSAGE_STATUS_CHANGE:
      return { ...state, msgStatusChange: action.payload };
    default:
      return state;
  }
};
// update complaint
export function updateComplaints(data: any) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_COMPLAINTS,
      payload: data,
    });
  };
}

// get complaint by id
export function getComplaintById(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_COMPLAINT_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `complaints/${id}` : `complaints`,
        },
        id,
      },
    });
  };
}
// get complaint by id
export function getComplaintByIdForChat(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_COMPLAINT_BY_ID_FOR_CHAT,
      payload: {
        request: {
          method: "get",
          url: id ? `complaints/${id}` : `complaints`,
        },
        id,
      },
    });
  };
}

// VIEW complaint by id
export function viewComplaintById(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_VIEW_COMPLAINT_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `complaints/${id}` : `complaints`,
        },
        id,
      },
    });
  };
}

export function updateViewComplaint(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_VIEW_COMPLAINT,
      payload: status,
    });
  };
}


// get all complaints for table
export function getAllComplaints(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ALL_COMPLAINTS,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `complaints?${queryStr}` : `complaints?`,
        },
      },
    });
  };
}

// add complaint
export function addComplaint(payload: FormData) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_ADD_COMPLAINT,
      payload: {
        request: {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            contentType: false,
          },
          method: "post",
          url: `complaints`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_ADD_COMPLAINT_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

export function updateAddComplaint(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_ADD_COMPLAINT_DLG_STATUS,
      payload: status,
    });
  };
}

export function updateChatComplaint(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_CHAT_COMPLAINT_DLG_STATUS,
      payload: status,
    });
  };
}

// edit complaint
export function editComplaint(payload: IEditComplaint, id: number) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_EDIT_COMPLAINT,
      payload: {
        request: {
          method: "put",
          url: `complaints/${id}`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_EDIT_COMPLAINT_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

// update edit employee
export function updateEditComplaintDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_EDIT_COMPLAINT_DLG_STATUS,
      payload: status,
    });
  };
}

// Fetch Complaint after add, edit, delete
export function updateComplaintFetchStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_COMPLAINT_FETCH_STATUS,
      payload: status,
    });
  };
}

// delete complaint
export function deleteComplaint(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_DELETE_COMPLAINT,
      payload: {
        request: {
          method: "delete",
          url: `complaints/${id}`,
        },
      },
    });
  };
}

// update delete complaint
export function updateDeleteComplaintDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_DELETE_COMPLAINT_DLG_STATUS,
      payload: status,
    });
  };
}

// Filter value
export function filterComplaint(filterVal: string) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_COMPLAINT,
      payload: filterVal,
    });
  };
}

// handleComplaintSort
export function handleComplaintSort(sort: any, order: any) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: COMPLAINT_SORT,
      payload: {
        sort: sort,
        order: order
      },
    });
  };
}

export function sendMessage(payload: any) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_ADD_COMPLAINT_MESSAGE,
      payload: {
        request: {
          method: "post",
          url: `complaint-messages`,
          data: payload,
        },
      },
    });
  };
}

// message read status change
export function msgReadStatusChange(id: number, reference_type:string) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_MESSAGE_STATUS_CHANGE,
      payload: {
        request: {
          method: "PUT",
          url: `comments/mark_as_read/${id}/${reference_type}`,
        },
      },
    });
  };
}
