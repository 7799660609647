import * as Yup from "yup";

import { Button, Offcanvas, Toast } from "react-bootstrap";
import { Dispatch, bindActionCreators } from "redux";
import { Field, Form, Formik } from "formik";
import {
  IAttendanceDashboardState,
  IAttendanceProps,
} from "../../types/attendance.type";
import {
  filterAttendance,
  getAllAttendances,
  handleAttendanceSort,
  updateAddAttendance,
  updateAttendanceFetchStatus,
  updateSidebar,
} from "../../shared/Reducers";

import AttendanceTable from "./AttendanceTable";
import { CloseBtnSVG } from "../../assets/images/Location";
import Constants from "../../shared/Constants";
import { CustomSelect } from "../../shared/Components/CustomSelect";
import React from "react";
import ReactPaginate from "react-paginate";
import { RootState } from "../../index";
import { SearchSVG } from "../../assets/images/EmployeesSVG";
import { SuccessToastSVG } from "../../assets/images/ImagesV2";
import SideBar from "../../components/sidebar/SideBar";
import TopNav from "../../components/topnav/TopNav";
import { connect } from "react-redux";

class Attendance extends React.Component<
  IAttendanceProps,
  IAttendanceDashboardState
> {
  constructor(props: IAttendanceProps) {
    super(props);
    this.state = {
      show: false,
      total_attendance: "",
      searchTerm: "",
      addToast: false,
      deleteToast: false,
      editToast: false,
      closeAdd: false,
      sortBy: null,
      sortDirection: null,
      pageOffset: Constants.OFFSET,
      tableFieldName: "",
      orderFilter: "",
      pagehandle: false,
      timeout: null,
    };
  }

  componentDidMount(): void {
    this.props.updateSidebar(true);
    document.title = "Attendance";
    this.getAttendances(Constants.OFFSET, Constants.PER_PAGE);
  }

  componentDidUpdate(
    prevProps: Readonly<IAttendanceProps>,
    prevState: Readonly<IAttendanceDashboardState>,
    snapshot?: any
  ): void {
    if (prevProps.attendanceFetchStatus !== this.props.attendanceFetchStatus) {
      if (this.props.attendanceFetchStatus) {
        this.getAttendances(Constants.OFFSET, Constants.PER_PAGE);
      }
    }
    if (prevProps.editAttendanceSuccess !== this.props.editAttendanceSuccess) {
      if (this.props.editAttendanceSuccess === "Successfully updated") {
        this.setState({ editToast: true });
        setTimeout(() => {
          this.setState({ editToast: false });
        }, 5000);
      }
    }
    if (
      prevProps.deleteAttendanceSuccess !== this.props.deleteAttendanceSuccess
    ) {
      if (this.props.deleteAttendanceSuccess === "Successfully deleted") {
        this.setState({ deleteToast: true });
        setTimeout(() => {
          this.setState({ deleteToast: false });
        }, 5000);
      }
    }
  }

  addToastClose = () => {
    this.setState({ addToast: false });
  };

  editToastClose = () => {
    this.setState({ editToast: false });
  };

  deleteToastClose = () => {
    this.setState({ deleteToast: false });
  };

  getAttendances = async (offset: number, limit: number) => {
    try {
      const query = `search=${this.state.searchTerm}&sort=${this.props.attendanceSortBy}&order=${this.props.attendanceOrderBy}&offset=${offset}&limit=${limit}&client=all&branch=all`;
      this.props.getAllAttendances(query);
    } catch (error) {
      console.log(error);
    }
  };

  handleAddAttendanceCLose = (status: boolean) => {
    this.handleClose();
    if (status) {
      this.getAttendances(Constants.OFFSET, Constants.PER_PAGE);
    }
  };

  // Canvas Show
  handleShow() {
    this.props.updateAddAttendance(true);
  }

  // Canvas Close
  handleClose() {
    this.props.updateAddAttendance(false);
  }

  // Search Branch Filter
  handleFilter = (e: any) => {
    // const filterVal: string = e.target.value.toLowerCase();
    // this.props.filterAttendance(filterVal);
    // if (e.target.value === "") {
    //   this.getAttendances(Constants.OFFSET, Constants.PER_PAGE);
    // }
    const value: string = e.target.value.toLowerCase();
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

    this.setState({
      searchTerm: value,
      timeout: setTimeout(async () => {
        try {
          const query = `search=${this.state.searchTerm}&sort=${this.props.attendanceSortBy}&order=${this.props.attendanceOrderBy}&offset=0&limit=10&client=all&branch=all`;
          this.props.getAllAttendances(query);
        } catch (error) {
          console.log(error);
        }
        // Trigger your function here that you want to call when user stops typing
      }, 1000), // Set the delay time in ms
    });
  };

  handlePageClick = async (e: any) => {
    if (e.selected < this.props.total_count / Constants.PER_PAGE) {
      // this.getAttendances(e.selected * Constants.PER_PAGE, Constants.PER_PAGE);
      let query = `search=${this.state.searchTerm}&sort=${
        this.state.tableFieldName
      }&order=${this.state.orderFilter}&offset=${
        e.selected * Constants.PER_PAGE
      }&limit=${Constants.PER_PAGE}&client=all&branch=all`;
      this.props.getAllAttendances(query);
    }
  };

  handleSort = (column: string) => {
    if (this.state.sortBy === column) {
      this.setState({
        sortDirection: this.state.sortDirection === "asc" ? "desc" : "asc",
      });
      this.props.handleAttendanceSort(
        column,
        this.state.sortDirection === "asc" ? "desc" : "asc"
      );
    } else {
      this.setState({ sortBy: column });
      this.setState({ sortDirection: "asc" });
      this.props.handleAttendanceSort(column, "asc");
    }
  };

  validationSchema = () => {
    return Yup.object().shape({
      column_name: Yup.string().required("Select Filter Column"),
      order_type: Yup.string().required("Select Sort Order"),
    });
  };
  handleSearch = (formValue: any) => {
    try {
      this.setState({ pagehandle: true }, () => {
        this.setState({
          tableFieldName: formValue.column_name,
          orderFilter: formValue.order_type,
          pagehandle: false,
        });
        const query = `search=${this.state.searchTerm}&sort=${formValue.column_name}&order=${formValue.order_type}&offset=${Constants.OFFSET}&limit=${Constants.PER_PAGE}&client=all&branch=all`;
        this.props.getAllAttendances(query);
      });
    } catch (error) {
      console.log(error);
    }
  };

  render(): React.ReactNode {
    let taskStatusOption: any = [];
    taskStatusOption = [
      { value: "shift_name", label: "Shift Name" },
      { value: "first_name", label: "Cleaner" },
      { value: "shift_date", label: "Shift Date" },
      { value: "start_time", label: "Start Time" },
      { value: "end_time", label: "End Time" },
      { value: "status", label: "Status" },
    ];

    let attendanceOrder: any = [];
    attendanceOrder = [
      { value: "asc", label: "Ascending" },
      { value: "desc", label: "Descending" },
    ];
    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className={this.props.status ? "main-panel" : "main-panel2"}>
          <TopNav />
          <div className="content-wrapper">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="mb-0">Attendance management</h3>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-end">
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12 col-lg-4">
                  <div className="input-group search">
                    <span
                      className="input-group-text search-icon"
                      id="basic-addon1"
                    >
                      <SearchSVG />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={this.state.searchTerm}
                      onInput={this.handleFilter}
                      onChange={(e) =>
                        this.setState({ searchTerm: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12 col-lg-6 tab-res-mt-3">
                  <div>
                    <Formik
                      validationSchema={this.validationSchema}
                      onSubmit={this.handleSearch}
                      initialValues={{
                        column_name: "",
                        order_type: "asc",
                      }}
                    >
                      <Form className="d-flex flex-column flex-md-row">
                        <Field
                          className="custom-select dropdown-field mr-16px res-mt-3 filter-w-180px"
                          name="column_name"
                          options={taskStatusOption}
                          component={CustomSelect}
                          placeholder="Select Column"
                          isMulti={false}
                        />
                        <Field
                          className="custom-select dropdown-field mr-16px res-mt-3 filter-w-180px"
                          name="order_type"
                          options={attendanceOrder}
                          component={CustomSelect}
                          placeholder="Select Order"
                          isMulti={false}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary filter-button res-mt-3"
                        >
                          Filter
                        </button>
                      </Form>
                    </Formik>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 text-start text-lg-end tab-res-mt-3">
                  {this.state.deleteToast ? (
                    <Toast className="success-alerts">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <SuccessToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.deleteAttendanceSuccess}
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.deleteToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      {this.props.filteredAttendanceData.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table employee-table">
                            <thead>
                              <tr>
                                <th scope="col">SHIFT NAME</th>
                                <th scope="col">CLEANER</th>
                                <th scope="col">SHIFT DATE</th>
                                <th scope="col">START TIME</th>
                                <th scope="col">END TIME</th>
                                <th scope="col">STATUS</th>
                                <th scope="col">ACTION</th>
                              </tr>
                            </thead>
                            <AttendanceTable
                              data={this.props.filteredAttendanceData}
                              onDelete={() =>
                                this.getAttendances(
                                  Constants.OFFSET,
                                  Constants.PER_PAGE
                                )
                              }
                              onEditClose={() =>
                                this.getAttendances(
                                  Constants.OFFSET,
                                  Constants.PER_PAGE
                                )
                              }
                              deleteAttendanceStatus={false}
                            />
                          </table>
                        </div>
                      ) : (
                        <p className="text-center mt-3">
                          There is no attendance record found
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                {this.props.total_count > 10 ? (
                  <div className="col-md-12">
                    {this.state.pagehandle ? null : (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.props.total_count / Constants.PER_PAGE}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        nextClassName={"next-page"}
                        activeClassName={"active"}
                      />
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  addAttendanceStatus: state.attendance.addAttendanceStatus,
  attendanceFetchStatus: state.attendance.attendanceFetchStatus,
  attendanceData: state.attendance.attendanceData,
  filteredAttendanceData: state.attendance.filteredAttendanceData,
  total_count: state.attendance.total_count,
  addAttendanceSuccess: state.attendance.addAttendanceSuccess,
  editAttendanceSuccess: state.attendance.editAttendanceSuccess,
  deleteAttendanceSuccess: state.attendance.deleteAttendanceSuccess,
  attendanceSortBy: state.attendance.attendanceSortBy,
  attendanceOrderBy: state.attendance.attendanceOrderBy,
  status: state.sidebar.status,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateAddAttendance: bindActionCreators(updateAddAttendance, dispatch),
    updateAttendanceFetchStatus: bindActionCreators(
      updateAttendanceFetchStatus,
      dispatch
    ),
    filterAttendance: bindActionCreators(filterAttendance, dispatch),
    getAllAttendances: bindActionCreators(getAllAttendances, dispatch),
    handleAttendanceSort: bindActionCreators(handleAttendanceSort, dispatch),
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Attendance);
