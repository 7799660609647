import React from "react";
import { Modal, Button } from "react-bootstrap";
import { CloseBtnSVG } from "../../assets/images/Location";
import {
  IDeleteConfirmProps,
  IDeleteConfirmState,
} from "../../types/schedule.type";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Constants from "../../shared/Constants";

class ScheduleDeleteConfirm extends React.Component<
  IDeleteConfirmProps,
  IDeleteConfirmState
> {
  constructor(props: IDeleteConfirmProps) {
    super(props);
    this.state = {
      deleteWarningPopup: false,
      // displayView: true,
      // currentScheduleId: "",
    };
  }
  handleOpenPopup = () => {
    if (this.props.handleRenewDate.date !== null) {
      this.props.handleDeleteClose(false);
      this.setState({ deleteWarningPopup: true });
    } else {
      this.props.handleDeleteSuccess();
    }
  };
  handleClosePopup = () => {
    this.setState({ deleteWarningPopup: false });
    this.props.handleDeleteClose(false);
  };
  render(): React.ReactNode {
    return (
      <div>
        <Modal
          className="delete-modal"
          show={this.props.displayDelete}
          onHide={() => this.props.handleDeleteClose(false)}
        >
          <Modal.Body>
            <a
              href="javascript:void(0)"
              className="qr-modal-close"
              onClick={() => this.props.handleDeleteClose(false)}
            >
              <CloseBtnSVG />
            </a>

            <div className="delete-modal-body">
              <h3 className="mb-3">Delete schedule</h3>
              <p>
                Do you want to delete this schedule{" "}
                {this.props.deletedScheduleName}
              </p>
            </div>

            <div className="delete-footer qr-modal-footer d-flex justify-content-end pt-2">
              <Button
                className="btn btn-outline-success delete-cancel"
                variant="outline"
                onClick={() => this.props.handleDeleteClose(false)}
              >
                Cancel
              </Button>
              <Button
                className="delete-cancel delete-button"
                variant="primary"
                onClick={() => this.handleOpenPopup()}
              >
                Delete
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          className="delete-modal"
          show={this.state.deleteWarningPopup}
          onHide={() => this.handleClosePopup()}
        >
          <Modal.Body>
            <a
              href="javascript:void(0)"
              className="qr-modal-close"
              onClick={() => this.handleClosePopup()}
            >
              <CloseBtnSVG />
            </a>

            <div className="delete-modal-body">
              <h3 className="mb-3">Warning</h3>
              <p>
                This schedule is running, Are you want to delete this schedule
                option {this.props.deletedScheduleName}
              </p>
            </div>

            <div className="delete-footer qr-modal-footer d-flex justify-content-end pt-2">
              <Button
                className="btn btn-outline-success delete-cancel"
                variant="outline"
                onClick={() => this.handleClosePopup()}
              >
                Cancel
              </Button>
              <Button
                className="delete-cancel delete-button"
                variant="primary"
                onClick={() => {
                  this.props.handleSpDelete(this.props.handleRenewDate.id);
                  this.setState({ deleteWarningPopup: false });
                }}
              >
                Delete
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleDeleteConfirm);
