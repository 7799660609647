import {
  AssignedTaskSVG,
  AttendanceSVG,
  AuditSVG,
  BranchSVG,
  CleanerHome,
  CleanerLogSvg,
  ComplaintsSVG,
  ContractSVG,
  ContractorContractSVG,
  DashboardSVG,
  EmailSVG,
  ExportQuoTaskSVG,
  GenerateQuoTaskSVG,
  LogoSVG,
  NotificationSVG,
  ReportSVG,
  ScheduleSVG,
  ShiftSVG,
  TaskSVG,
  UserSVG,
  UserRoleSvg,
  InvoiceSvg,
  RecommendedSVG,
  ApprovedTaskSVG,
  CertificateSVG,
} from "../../assets/images/ImagesV2";
import { Dispatch, bindActionCreators } from "redux";
import { Menu, ProSidebarProvider, Sidebar } from "react-pro-sidebar";

import Constants from "../../shared/Constants";
import { ISideBarProps } from "../../types/sidebar.type";
import { NavLink } from "react-router-dom";
import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { updateSidebar } from "../../shared/Reducers/index";
import { SettingsSVG } from "../../assets/images/SideBarSVG";

class SideBar extends React.Component<ISideBarProps> {
  // constructor(props: ISideBarProps) {
  //     super(props);
  // }

  handleMenuShow = (menu: string) => {
    switch (menu) {
      case "/attendance":
        return this.props.userLoginPermission?.sidebar_attendance === 1
          ? true
          : false;
      case "/audit":
        return this.props.userLoginPermission?.sidebar_audit === 1
          ? true
          : false;
      case "/in-out":
        return this.props.userLoginPermission?.sidebar_branch_login_out === 1
          ? true
          : false;
      case "/branch":
        return this.props.userLoginPermission?.sidebar_branch === 1
          ? true
          : false;
      case "/contractor":
        return this.props.userLoginPermission?.sidebar_cleaner_contract === 1
          ? true
          : false;
      case "/home":
        return this.props.userLoginPermission?.sidebar_cleaner_home === 1
          ? true
          : false;
      case "/client-contract":
        return this.props.userLoginPermission?.sidebar_client_contract === 1
          ? true
          : false;
      case "/complaints":
        return this.props.userLoginPermission?.sidebar_complaints === 1
          ? true
          : false;
      case "/":
        return this.props.userLoginPermission?.sidebar_dashboard === 1
          ? true
          : false;
      case "/dashboard":
        return this.props.userLoginPermission?.sidebar_dashboard === 1
          ? true
          : false;
      case "/recommended":
        return this.props.userLoginPermission?.sidebar_recommended === 1
          ? true
          : false;
      case "/email-template":
        return this.props.userLoginPermission?.sidebar_email_template === 1
          ? true
          : false;
      case "/notification":
        return this.props.userLoginPermission?.sidebar_notification === 1
          ? true
          : false;
      case "/quoted-tasks":
        return this.props.userLoginPermission?.sidebar_quated_task === 1
          ? true
          : false;
      case "/export-quoted-tasks":
        return this.props.userLoginPermission?.sidebar_quated_task === 1
          ? true
          : false;
      case "/cleaner-log":
        return this.props.userLoginPermission?.sidebar_report === 1
          ? true
          : false;
      case "/invoice":
        return this.props.userLoginPermission?.sidebar_report === 1
          ? true
          : false;
      case "/report":
        return this.props.userLoginPermission?.sidebar_report === 1
          ? true
          : false;
      case "/settings":
        return this.props.userLoginPermission?.sidebar_settings === 1
          ? true
          : false;
      case "/schedule":
        return this.props.userLoginPermission?.sidebar_schedule === 1
          ? true
          : false;
      case "/make-schedule":
        return this.props.userLoginPermission?.sidebar_schedule === 0 &&
          this.props.userLoginPermission?.sidebar_make_schedule === 1
          ? true
          : false;
      case "/approvedtask":
        return this.props.userLoginPermission?.sidebar_approved_task === 1
          ? true
          : false;
      case "/shift":
        return this.props.userLoginPermission?.sidebar_shift === 1
          ? true
          : false;
      case "/task-template":
        return this.props.userLoginPermission?.sidebar_task_template === 1
          ? true
          : false;
      case "/task":
        return this.props.userLoginPermission?.sidebar_task === 1
          ? true
          : false;
      case "/user":
        return this.props.userLoginPermission?.sidebar_user === 1
          ? true
          : false;
      case "/userrole":
        return this.props.userLoginPermission?.sidebar_user_role === 1
          ? true
          : false;
      case "/certificate":
        return this.props.userLoginPermission?.sidebar_certificate === 1
          ? true
          : false;
      case "/privacy":
      case "from-signup/:user":
      case "signup":
      case "forgotpassword":
      case "activate-account":
      case "reset-password":
      case "loginsuccess":
        return true;
      default:
        return false;
    }
  };

  render(): React.ReactNode {
    console.log("recommended Props", this.props.userLoginPermission);

    return (
      <div
        className={`sidebar-container sidebar-offcanvas ${this.props.status ? "show" : "hide"
          }`}
      >
        <ProSidebarProvider>
          <Sidebar>
            {/* <label className="side-navhead text-white"><h2 className="text-white fs-27px m-0">Clean Task</h2></label> */}
            <Menu>
              <div className="sidebar-logo">
                <LogoSVG />
              </div>
              <div className="side-nav">
                <NavLink
                  to="/dashboard"
                  className={`text-white side-nav-link ${this.handleMenuShow("/dashboard") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <DashboardSVG />
                    <span className="side-menu-nav">Dashboard</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/userrole"
                  className={`text-white side-nav-link ${this.handleMenuShow("/userrole") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <UserRoleSvg />
                    <span className="side-menu-nav">User Role</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/user"
                  className={`text-white side-nav-link ${this.handleMenuShow("/user") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <UserSVG />
                    <span className="side-menu-nav">User</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/client-contract"
                  className={`text-white side-nav-link ${this.handleMenuShow("/client-contract") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <ContractSVG />
                    <span className="side-menu-nav">Client Contract</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/contractor"
                  className={`text-white side-nav-link ${this.handleMenuShow("/contractor") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <ContractorContractSVG />
                    <span className="side-menu-nav">Cleaner Contact</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/branch"
                  className={`text-white side-nav-link ${this.handleMenuShow("/branch") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <BranchSVG />
                    <span className="side-menu-nav">Branch</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/audit"
                  className={`text-white side-nav-link ${this.handleMenuShow("/audit") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <AuditSVG />
                    <span className="side-menu-nav">Audit</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/task-template"
                  className={`text-white side-nav-link ${this.handleMenuShow("/task-template") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <TaskSVG />
                    <span className="side-menu-nav">Task Template</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/schedule"
                  className={`text-white side-nav-link ${this.handleMenuShow("/schedule") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <ScheduleSVG />
                    <span className="side-menu-nav">Schedule</span>
                  </div>
                </NavLink>
                {/* <NavLink
                  to="/make-schedule"
                  className={`text-white side-nav-link ${
                    this.handleMenuShow("/make-schedule") ? "" : "d-none"
                  }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <ScheduleSVG />
                    <span className="side-menu-nav">Schedule</span>
                  </div>
                </NavLink> */}

                <NavLink
                  to="/task"
                  className={`text-white side-nav-link ${this.handleMenuShow("/task") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <AssignedTaskSVG />
                    <span className="side-menu-nav">
                      {this.props.userLoginDetails?.permission
                        ?.task_add_task === 1
                        ? "Task"
                        : "Special Request"}
                    </span>
                  </div>
                </NavLink>
                <NavLink
                  to="/approvedtask"
                  className={`text-white side-nav-link ${this.handleMenuShow("/approvedtask") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <ApprovedTaskSVG />
                    <span className="side-menu-nav">Approved Task</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/quoted-tasks"
                  className={`text-white side-nav-link ${this.handleMenuShow("/quoted-tasks") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <GenerateQuoTaskSVG />
                    <span className="side-menu-nav">Generate Quoted Task</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/export-quoted-tasks"
                  className={`text-white side-nav-link ${this.handleMenuShow("/export-quoted-tasks") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <ExportQuoTaskSVG />
                    <span className="side-menu-nav">Export Quoted Task</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/shift"
                  className={`text-white side-nav-link ${this.handleMenuShow("/shift") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <ShiftSVG />
                    <span className="side-menu-nav">Shift</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/attendance"
                  className={`text-white side-nav-link ${this.handleMenuShow("/attendance") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <AttendanceSVG />
                    <span className="side-menu-nav">Attendance</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/complaints"
                  className={`text-white side-nav-link ${this.handleMenuShow("/complaints") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <ComplaintsSVG />
                    <span className="side-menu-nav">Complaints</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/cleaner-log"
                  className={`text-white side-nav-link ${this.handleMenuShow("/cleaner-log") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <CleanerLogSvg />
                    <span className="side-menu-nav">Cleaner Log</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/invoice"
                  className={`text-white side-nav-link ${this.handleMenuShow("/report") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <InvoiceSvg />
                    <span className="side-menu-nav">Invoice</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/report"
                  className={`text-white side-nav-link ${this.handleMenuShow("/report") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <ReportSVG />
                    <span className="side-menu-nav">Report</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/notification"
                  className={`text-white side-nav-link ${this.handleMenuShow("/notification") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <NotificationSVG />
                    <span className="side-menu-nav">Notification</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/email-template"
                  className={`text-white side-nav-link ${this.handleMenuShow("/email-template") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <EmailSVG />
                    <span className="side-menu-nav">Email Template</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/add-certificate"
                  className={`text-white side-nav-link ${this.handleMenuShow("/certificate") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <CertificateSVG />
                    <span className="side-menu-nav">Certificate</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/settings"
                  className={`text-white side-nav-link ${this.handleMenuShow("/settings") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <SettingsSVG />
                    <span className="side-menu-nav">Settings</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/home"
                  className={`text-white side-nav-link ${this.handleMenuShow("/home") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <CleanerHome />
                    <span className="side-menu-nav">Home</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/recommended"
                  className={`text-white side-nav-link ${this.handleMenuShow("/recommended") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <RecommendedSVG />
                    <span className="side-menu-nav">Recommended</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/in-out"
                  className={`text-white side-nav-link ${this.handleMenuShow("/in-out") ? "" : "d-none"
                    }`}
                >
                  <div className="d-flex align-items-center side-nav-menu">
                    <EmailSVG />
                    <span className="side-menu-nav">Branch Sign In/Out</span>
                  </div>
                </NavLink>
              </div>
            </Menu>
          </Sidebar>
        </ProSidebarProvider>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  status: state.sidebar.status,
  subAdmin: state.app.subAdmin,
  userLoginDetails: state.sidebar.userLoginDetails,
  userLoginPermission: state.sidebar.userLoginPermission,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
