import React from "react";
import SideBar from "../../components/sidebar/SideBar";
import TopNav from "../../components/topnav/TopNav";
import Constants from "../../shared/Constants";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "../../index";
import { connect } from "react-redux";
import {
  getCleanerLastActivity,
  updateSidebar,
  userLoginLogs,
  listAllBranch,
  settingslists,
} from "../../shared/Reducers";
import QrReader from "react-qr-reader";
import moment from "moment";
import { SignOutSVG } from "../../assets/images/ImagesV2";
import { ScanSVG } from "../../assets/images/ImagesV2";
import { QrBtnSVG } from "../../assets/images/ImagesV2";
import Modal from "react-bootstrap/Modal";
// import DateTimePicker from "react-datetime-picker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import dayjs, { Dayjs } from "dayjs";
import { log } from "console";
import { Button } from "react-bootstrap";

const reactSelectCusStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#fff",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    },
  }),
};
export interface ICleanerInOutProps {
  getCleanerLastActivity?: any;
  getLastActivityData?: any;
  cleanerLoginLogs?: any;
  userLoginLogs?: any;
  updateSideBar: any;
  listAllBranch?: any;
  branchList?: any;
  settingslists: any;
  settingList: any;
}

export interface ICleanerInOutState {
  Scanning: boolean;
  logoutScanning: boolean;
  signoutReasonStatus: boolean;
  SignoutReason: string;
  SignoutReasonError: boolean;
  manual_id?: any;
  manual_client_id?: any;
  manual_lat?: any;
  manual_lng?: any;
  dateChange: any;
  cleanerLogoutCheckBranchError: string;
  startTimerValue: any;
  logoutTimer: string;
  timerset: any;
}

class CleanerInOut extends React.Component<
  ICleanerInOutProps,
  ICleanerInOutState
> {
  constructor(props: ICleanerInOutProps) {
    super(props);
    this.state = {
      Scanning: false,
      logoutScanning: false,
      signoutReasonStatus: false,
      SignoutReason: "",
      SignoutReasonError: false,
      manual_id: 0,
      manual_client_id: 0,
      manual_lat: "",
      manual_lng: "",
      cleanerLogoutCheckBranchError: "",
      dateChange: moment(
        new Date().toLocaleString("en-AU", { timeZone: "Australia/Sydney" }),
        "DD/MM/YYYY, h:mm:ss a"
      ).format("DD MMM YYYY hh:mm:ss A"),
      startTimerValue: null,
      logoutTimer: "05:00",
      timerset: 1,
    };
  }

  async componentDidMount() {
    document.title = "Branch Sign In/Out";
    this.props.updateSideBar(true);
    this.props.getCleanerLastActivity();
    this.props.listAllBranch(0);
    await this.props.settingslists();
    console.log(this.props.settingList, "settingList");
    let cleaner_logout_Timer = this.props.settingList.find(
      (o: any) => o.meta_key === "cleaner_logout_Timer"
    );
    console.log(cleaner_logout_Timer.meta_value);
    this.setState({ timerset: Number(cleaner_logout_Timer.meta_value) });
    var spinner = document.getElementById("spinner-border");
    var createbutton = document.querySelector(".create-button");

    if (spinner !== null && createbutton !== null) {
      spinner.classList.add("d-none");
      createbutton.removeAttribute("disabled");
    }

    // set interval for logout timer
    const interval: any = setInterval(() => {
      // console.log(this.state.startTimerValue);

      if (
        this.state.startTimerValue > 0 &&
        this.state.startTimerValue !== null
      ) {
        var timer: number = this.state.startTimerValue;
        --timer;
        this.setState({ startTimerValue: timer });
        var minutes: any = timer / 60;
        var seconds: any = timer % 60;
        minutes = Math.floor(minutes);
        seconds = Math.round(seconds);
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.setState({ logoutTimer: minutes + ":" + seconds });
      } else {
        if (this.state.startTimerValue === 0) {
          this.setState({ signoutReasonStatus: false, startTimerValue: null });
        }
      }
    }, 1000);
  }

  componentDidUpdate(
    prevProps: Readonly<ICleanerInOutProps>,
    prevState: Readonly<ICleanerInOutState>,
    snapshot?: any
  ): void {
    // console.log(this.props.getLastActivityData);

    if (prevProps.getLastActivityData !== this.props.getLastActivityData) {
      var spinner = document.getElementById("spinner-border");
      var createbutton = document.querySelector(".create-button");

      if (spinner !== null && createbutton !== null) {
        spinner.classList.add("d-none");
        createbutton.removeAttribute("disabled");
      }
    }
    if (prevProps.cleanerLoginLogs !== this.props.cleanerLoginLogs) {
      this.props.getCleanerLastActivity();
    }
  }
  toggleScanning = async () => {
    navigator.permissions
      .query({ name: "camera" as PermissionName })
      .then(function (permissionStatus) {
        // handle the permission status change
        // permissionStatus.onchange = function () {
        if (permissionStatus.state === "granted") {
          // camera permission granted
        } else if (permissionStatus.state === "denied") {
          // camera permission denied
          console.log("denied");
          alert(
            "Camera is blocked, Click the camera blocked icon in your browser's address bar"
          );
          // }
        }
      })
      .catch(function (error) {
        // handle the error
      });

    this.setState({ Scanning: this.state.Scanning === true ? false : true });
  };

  toggleLogoutScanning = () => {
    navigator.permissions
      .query({ name: "camera" as PermissionName })
      .then(function (permissionStatus) {
        // handle the permission status change
        // permissionStatus.onchange = function () {
        if (permissionStatus.state === "granted") {
          // camera permission granted
        } else if (permissionStatus.state === "denied") {
          // camera permission denied
          console.log("denied");
          alert(
            "Camera is blocked, Click the camera blocked icon in your browser's address bar"
          );
          // }
        }
      })
      .catch(function (error) {
        // handle the error
      });
    this.setState({
      logoutScanning: this.state.logoutScanning === true ? false : true,
    });
  };
  CleanerBranchSignin = async (data: any) => {
    let latitude = "";
    let longitude = "";
    navigator.geolocation.getCurrentPosition(async (position: any) => {
      latitude = position.coords.latitude;
      longitude = position.coords.longitude;
      data = JSON.parse(data);
      let scanData = {
        date: moment(
          new Date().toLocaleString("en-AU", { timeZone: "Australia/Sydney" }),
          "DD/MM/YYYY, h:mm:ss a"
        ).format("YYYY-MM-DD HH:mm:ss"),
        action: 1,
        lat: latitude,
        lng: longitude,
        log_app_type: Constants.LOG_APP_TYPE,
        reason: "",
        branch: {
          id: data.id,
          client: data.client_id,
          lat: data.lat,
          lng: data.lng,
        },
      };

      await this.props.userLoginLogs(scanData);
      this.setState({ Scanning: false });
    });
  };

  checkCleanerSigninBranch = async (data: any) => {
    data = JSON.parse(data);
    if (
      this.props.getLastActivityData &&
      data &&
      data.client_id === this.props.getLastActivityData.client_id &&
      data.id === this.props.getLastActivityData.branch_id
    ) {
      this.setState({ startTimerValue: 60 * this.state.timerset });
      this.setState({ logoutScanning: false });
      this.setState({
        signoutReasonStatus: true,
        logoutScanning: false,
        cleanerLogoutCheckBranchError: "",
        dateChange: moment(
          new Date().toLocaleString("en-AU", { timeZone: "Australia/Sydney" }),
          "DD/MM/YYYY, h:mm:ss a"
        ).format("DD MMM YYYY hh:mm:ss A"),
      });
    } else {
      this.setState({
        cleanerLogoutCheckBranchError:
          "Please scan logged in branch qr code for logout.",
      });
    }
  };

  CleanerBranchSignout = async () => {
    let latitude = "";
    let longitude = "";
    navigator.geolocation.getCurrentPosition(async (position: any) => {
      latitude = position.coords.latitude;
      longitude = position.coords.longitude;

      if (this.state.SignoutReason.toString().replace(/\s/g, "") === "") {
        this.setState({ SignoutReasonError: true });
      } else {
        let signoutData = {
          date: moment(new Date(this.state.dateChange)).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          action: 0,
          lat: latitude,
          lng: longitude,
          reason: this.state.SignoutReason,
          log_id: this.props.getLastActivityData.id,
          log_app_type: Constants.LOG_APP_TYPE,
        };
        await this.props.userLoginLogs(signoutData);
        setTimeout(async () => {
          await this.props.getCleanerLastActivity();
          this.setState({ signoutReasonStatus: false, SignoutReason: "" });
        }, 1000);
      }
    });
  };
  manualQR = () => {
    if (this.state.manual_id !== 0) {
      var spinner = document.getElementById("spinner-border");
      var signbutton = document.querySelector(".create-button");

      if (spinner !== null && signbutton !== null) {
        spinner.classList.remove("d-none");
        signbutton.setAttribute("disabled", "");
      }
      let data: any = JSON.stringify({
        id: this.state.manual_id,
        client_id: this.state.manual_client_id,
        lat: this.state.manual_lat,
        lng: this.state.manual_lng,
      });
      this.CleanerBranchSignin(data);
    }
  };

  manualSelectBranch = (data: any) => {
    this.setState({
      manual_id: data.value,
      manual_client_id: data.client_id,
      manual_lat: data.lat,
      manual_lng: data.lng,
    });
  };

  handleChangeDateTime = (newValue: any) => {
    this.setState({ dateChange: newValue });
  };
  render(): React.ReactNode {
    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className="main-panel">
          <TopNav />
          <div className="content-wrapper text-center">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="row">
                  <div className="col-md-12">
                    <p className="fs-16px">
                      <span className="fw-700" style={{ marginRight: "5px" }}>
                        Hi {Constants.LOGIN_USER_NAME}
                      </span>
                      {
                        // new user
                        Object.keys(this.props.getLastActivityData).length ===
                        0 ? (
                          <></>
                        ) : // logout user
                        this.props.getLastActivityData !== null &&
                          this.props.getLastActivityData.sign_out_date_time !==
                            null ? (
                          <span>
                            Signed out at{" "}
                            {moment(
                              this.props.getLastActivityData.sign_out_date_time
                            ).format("DD MMM YYYY HH:mm A")}
                            , {this.props.getLastActivityData.branch_name}.
                          </span>
                        ) : (
                          // login user
                          <span>
                            Signed in at{" "}
                            {moment(
                              this.props.getLastActivityData.sign_in_date_time
                            ).format("DD MMM YYYY HH:mm A")}
                            , {this.props.getLastActivityData.branch_name}.
                          </span>
                        )
                      }
                    </p>
                  </div>
                  {Object.keys(this.props.getLastActivityData).length === 0 && (
                    <div className="col-md-12">
                      <p>
                        A warm welcome from the whole Clean Task team. If you're
                        ready to simplify your workplace, let's get started.
                      </p>
                    </div>
                  )}
                </div>
                <div className="row justify-content-center mt-3">
                  <div className="col-md-5 text-start">
                    <div className="card">
                      <div className="card-body p-37px">
                        {Object.keys(this.props.getLastActivityData).length ===
                          0 ||
                        (this.props.getLastActivityData !== null &&
                          this.props.getLastActivityData.sign_out_date_time !==
                            null) ? (
                          <h2 className="fw-700 mb-4">Sign in your account</h2>
                        ) : (
                          <h2 className="fw-700 mb-4">Sign out your account</h2>
                        )}
                        <div className="error text-center p-2">
                          {this.state.cleanerLogoutCheckBranchError}
                        </div>
                        <label className="form-label">Select branch</label>
                        <Select
                          placeholder="Select Branch"
                          styles={reactSelectCusStyles}
                          isMulti={false}
                          options={this.props.branchList}
                          onChange={(e: any) => {
                            this.manualSelectBranch(e);
                          }}
                        />
                        {Object.keys(this.props.getLastActivityData).length ===
                          0 ||
                        (this.props.getLastActivityData !== null &&
                          this.props.getLastActivityData.sign_out_date_time !==
                            null) ? (
                          <div className="signin-btn">
                            <button
                              className="btn btn-primary create-button w-100 mt-3"
                              onClick={this.manualQR}
                            >
                              Sign in
                              <span
                                id="spinner-border"
                                className="spinner-border spinner-border-sm d-none"
                              ></span>
                            </button>
                          </div>
                        ) : (
                          <button
                            className="btn btn-primary create-button w-100 mt-3"
                            onClick={() =>
                              this.checkCleanerSigninBranch(
                                JSON.stringify({
                                  id: this.state.manual_id,
                                  client_id: this.state.manual_client_id,
                                  lat: this.state.manual_lat,
                                  lng: this.state.manual_lng,
                                })
                              )
                            }
                          >
                            Sign out
                          </button>
                        )}
                        <p className="mt-3 mb-0 text-center">or</p>

                        {Object.keys(this.props.getLastActivityData).length ===
                          0 ||
                        (this.props.getLastActivityData !== null &&
                          this.props.getLastActivityData.sign_out_date_time !==
                            null) ? (
                          <button
                            className="btn btn-primary black-button  w-100 mt-3 mb-2"
                            onClick={this.toggleScanning}
                          >
                            {" "}
                            <QrBtnSVG />
                            &nbsp; &nbsp; Scan QR code to sign in
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary black-button  w-100 mt-3 mb-2"
                            onClick={this.toggleLogoutScanning}
                          >
                            {" "}
                            <QrBtnSVG />
                            &nbsp; &nbsp; Scan QR code to sign out
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                size="lg"
                show={this.state.signoutReasonStatus}
                onHide={() =>
                  this.setState({
                    signoutReasonStatus: false,
                    SignoutReason: "",
                  })
                }
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    <h3 className="m-0">Reason for Signout</h3>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                  <div className="row">
                    <div className="col-12">
                      <label>Please select date and time</label>
                    </div>
                    <div className="col-12 mt-3">
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        id="startDate"
                      >
                        <Stack spacing={3}>
                          <DateTimePicker
                            inputFormat="DD/MM/YYYY hh:mm A"
                            value={this.state.dateChange}
                            onChange={this.handleChangeDateTime}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                    <div className="col-12 mt-3">
                      <textarea
                        onChange={(e) => {
                          this.setState({ SignoutReason: e.target.value });
                        }}
                        className="form-control mh-100px"
                      >
                        {this.state.SignoutReason}
                      </textarea>
                      {this.state.SignoutReasonError === true && (
                        <p className="text-danger">Please enter Reason</p>
                      )}
                    </div>
                    <div className="col-12 mt-3">
                      <button
                        className="btn btn-primary create-button"
                        onClick={this.CleanerBranchSignout}
                      >
                        Submit
                      </button>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="text-danger">
                        Signout closes in {this.state.logoutTimer} minutes!
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal
                size="lg"
                show={this.state.Scanning}
                onHide={() => this.setState({ Scanning: false })}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    <h3 className="m-0">Scan QR code sign in</h3>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                  <div className="row">
                    <div className="col-md-12 scan-modal-box">
                      {this.state.Scanning && (
                        <QrReader
                          delay={300}
                          onError={(error) => {
                            // console.log(error.message);
                          }}
                          onScan={(data) => {
                            if (data) {
                              this.setState({ Scanning: false });
                              this.CleanerBranchSignin(data);
                            }
                          }}
                        />
                      )}
                    </div>
                    <div className="col-12 mt-3 text-center">
                      <button
                        className="btn btn-primary create-button"
                        onClick={this.CleanerBranchSignout}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal
                size="lg"
                show={this.state.logoutScanning}
                onHide={() => this.setState({ logoutScanning: false })}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    <h3 className="m-0">Scan QR code sign out</h3>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className="error text-center p-2">
                        {this.state.cleanerLogoutCheckBranchError}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 scan-modal-box">
                      {this.state.logoutScanning && (
                        <QrReader
                          delay={300}
                          onError={(error) => {
                            // console.log(error.message);
                          }}
                          onScan={(data) => {
                            if (data) {
                              this.checkCleanerSigninBranch(data);
                            }
                          }}
                        />
                      )}
                    </div>
                    <div className="col-12 mt-3 text-center">
                      <button
                        className="btn btn-primary create-button"
                        onClick={() => this.setState({ logoutScanning: false })}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  branchList: state.tasktemplate.branchList,
  getLastActivityData: state.cleanerinout.getLastActivityData,
  cleanerLoginLogs: state.cleanerinout.cleanerLoginLogs,
  settingList: state.tasktemplate.settingList,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getCleanerLastActivity: bindActionCreators(
      getCleanerLastActivity,
      dispatch
    ),
    userLoginLogs: bindActionCreators(userLoginLogs, dispatch),
    updateSideBar: bindActionCreators(updateSidebar, dispatch),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
    settingslists: bindActionCreators(settingslists, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CleanerInOut);
