import React from "react";
import { RootState } from '../../index';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IAttendanceTableProps } from "../../types/attendance.type";
import AttendanceCard from "./AttendanceCard";
import { Modal, Offcanvas } from "react-bootstrap";
import { deleteAttendance, getAllAttendances, getAttendanceById, updateEditAttendanceDlgStatus, updateViewAttendance, viewAttendanceById } from "../../shared/Reducers";

class AttendanceTable extends React.Component<IAttendanceTableProps> {
    // constructor(props: IAttendanceTableProps ){
    //     super(props);
    // }

    handleDeleteAttendance = async (id: string) => {
        await this.props.deleteAttendance(id);
        console.log(id);
        const query = `search=&sort=&order=&offset=0&limit=10&client=all&branch=all`
        this.props.getAllAttendances(query);
    }

    handleEditBranch = async (id:string) => {
        try {
            this.props.getAttendanceById(id);
        } catch (error) {
            console.log(error);
        }
    }

    handleViewAttendance = async (id:string) => {
        try {
            this.props.viewAttendanceById(id);
        } catch (error) {
            console.log(error);
        }
    }

    render(): React.ReactNode {
        return (
            <tbody>
                {
                    this.props.data.map((attendances, index) =>
                        <AttendanceCard attendances={attendances}
                            key={index + attendances.shift_date}
                            handleDeleteAttendance={this.handleDeleteAttendance}
                            handleEditAttendance={this.handleEditBranch}
                            handleViewAttendance={this.handleViewAttendance}
                            deleteAttendanceStatus={false} />)
                }
            </tbody>
        )
    }
}
const mapStateToProps = (state: RootState) => ({
    editAttendanceStatus: state.attendance.editAttendanceStatus,
    selectedAttendanceData: state.attendance.selectedAttendanceData,
    id: state.attendance.id,
    viewAttendanceStatus: state.attendance.viewAttendanceStatus,
    viewAttendanceData: state.attendance.viewAttendanceData
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateEditAttendanceDlgStatus: bindActionCreators(updateEditAttendanceDlgStatus, dispatch),
        getAttendanceById: bindActionCreators(getAttendanceById, dispatch),
        getAllAttendances: bindActionCreators(getAllAttendances, dispatch),
        deleteAttendance: bindActionCreators(deleteAttendance, dispatch),
        viewAttendanceById: bindActionCreators(viewAttendanceById, dispatch),
        updateViewAttendance: bindActionCreators (updateViewAttendance, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AttendanceTable);