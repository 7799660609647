import { Button, Modal } from "react-bootstrap";
import React, { Component } from "react";

import { CloseBtnSVG } from "../../assets/images/Location";
import { Dispatch } from "redux";
import { IDeleteNotification } from "../../types/notification.type";
import { RootState } from "../../index";
import { connect } from "react-redux";

class DeleteNotification extends Component<IDeleteNotification> {
  render(): React.ReactNode {
    return (
      <div>
        <Modal
          className="delete-modal"
          show={this.props.displayDelete}
          onHide={() => this.props.handleDeleteClose(false)}
        >
          <Modal.Body>
            <a
              href="javascript:void(0)"
              className="qr-modal-close"
              onClick={() => this.props.handleDeleteClose(false)}
            >
              <CloseBtnSVG />
            </a>

            <div className="delete-modal-body">
              <h3 className="mb-3">Delete the Notification</h3>
              <p>
                Do you want to delete the Notification{" "}
                <span className="font-weight-bold">
                  {this.props.deletedName}
                </span>
              </p>
            </div>

            <div className="delete-footer qr-modal-footer d-flex justify-content-end pt-2">
              <Button
                className="btn btn-outline-success delete-cancel"
                variant="outline"
                onClick={() => this.props.handleDeleteClose(false)}
              >
                Cancel
              </Button>
              <Button
                className="delete-cancel delete-button"
                variant="primary"
                onClick={() => this.props.handleDeleteClose(true)}
              >
                Delete
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(DeleteNotification);
