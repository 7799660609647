import React, { Component } from "react";

import AllTaskCard from "./AllTaskCard";
import { AllTaskTableProps } from "../../types/allreport";

export default class AllTaskTable extends Component<AllTaskTableProps> {
  render() {
    return (
      <tbody>
        {this.props.data.map((task: any, index: any) => {
          return <AllTaskCard key={index + task.title} all_task={task}/>;
        })}
      </tbody>
    );
  }
}
