import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../..';
import RecommendedCard from '../Recommended/RecommendedCard';
import RecommendedEdit from '../Recommended/RecommendedEdit';
import { Offcanvas } from 'react-bootstrap';
import { getRecommendedTaskById, deleteDailyTask } from '../../shared/Reducers';
import { Dispatch, bindActionCreators } from 'redux';
import Constants from '../../shared/Constants';
interface ICleanerInOutProps {
    status: any;
    id: any;
    data: any;
    getRecommendedTaskById?: any;
    selectedRecommendedTaskData: any;
    deleteDailyTask: any;
}
interface IRecommendedTableState {
    editModal: boolean;
}

class RecommendedTable extends Component<ICleanerInOutProps, IRecommendedTableState> {
    constructor(props: ICleanerInOutProps) {
        super(props);
        this.state = {
            editModal: false,
        }
    }

    handleEditDailyTask = async (id: string) => {
        try {
            await this.props.getRecommendedTaskById(id);
            console.log("Additional task successfully retrieved", this.props.selectedRecommendedTaskData);
            setTimeout(() => {
                this.setState({ editModal: true });
            }, 180);
            
        } catch (error) {
            console.error("Error occurred while getting additional task:", error);
        }
    };

    // handleToOpenEdit = () => {
    //     console.log("Click");
    //     this.setState({ editModal: true })
    // };

    handleEditModelClose = () => {
        this.setState({ editModal: false });
    };

    handleDeleteDailyTask = async (id: string) => {
        await this.props.deleteDailyTask(id);
    
        let Offset: number = 0;
        let paginationActive = document.querySelector(
          ".pagination li.active a"
        ) as HTMLInputElement;
        if (paginationActive !== null) {
          let page: string | null = paginationActive.textContent;
          if (page !== null) {
            let pageNum: number = parseInt(page);
            console.log(pageNum);
            // Offset = (pageNum - 1) * Constants.PER_PAGE;
            console.log(Offset);
            // setTimeout(() => {
            //   try {
            //     const query = `search=&sort=&order=&offset=${Offset}&limit=${Constants.PER_PAGE}&client=all&branch=all&task_type=all`;
            //     this.props.getAllDailyTasks(query);
            //   } catch (error) {
            //     console.log(error);
            //   }
            // }, 500);
          }
        }
      };

    render() {
        return (
            <>
                <tbody>
                    {this.props.data.map((task: any, index: any) => (
                        <RecommendedCard
                            task={task}
                            key={index + task.title}
                            handleDeleteDailyTask={this.handleDeleteDailyTask}
                            handleEditDailyTask={this.handleEditDailyTask}
                        />
                    ))}
                    <Offcanvas
                        placement='end'
                        show={this.state.editModal}
                        onHide={() => this.handleEditModelClose()}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                {Constants.LOGIN_USER_TYPE === 3 ? (
                                <span className="offc-title">Edit Recommended Task</span>
                                ) : (
                                <span className="offc-title">Edit Recommended Task</span>
                                )}
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <RecommendedEdit
                                id={this.props.id}
                                data={this.props.selectedRecommendedTaskData}
                                handleEditModelClose={() => this.handleEditModelClose()}
                            />
                        </Offcanvas.Body>
                    </Offcanvas>
                </tbody>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    status: state.sidebar.status,
    selectedRecommendedTaskData: state.dailytask.selectedRecommendedTaskData,
    id: state.dailytask.recommendedTaskId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getRecommendedTaskById: bindActionCreators(getRecommendedTaskById, dispatch),
    deleteDailyTask: bindActionCreators(deleteDailyTask, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedTable);
