import * as FileSaver from "file-saver";
import { Button } from "reactstrap";
import XLSX from "sheetjs-style";
import moment from "moment";
import Constants from "../shared/Constants";

const ExportExcel = ({ excelData, fileName }) => {
  let excelFormatData = [];
  for (let index = 0; index < excelData.length; index++) {
    const element = excelData[index];
    console.log("export Excel", element);
    let cleaners = "";
    element.cleaners.map(
      (clr) => (cleaners += clr.first_name + " " + clr.last_name + ",")
    );
    excelFormatData.push({
      "TASK NAME	": element.title,
      Description: element.description,
      CLIENT: element.first_name + " " + element.last_name,
      BRANCH: element.branch_name,
      CLEANERS: cleaners,
      DATE: moment(element.task_date).format("DD/MM/YYYY"),
      TIME:
        element.any_time === 1
          ? `Any Time`
          : moment(element.start_time, "HH:mm:ss").format("hh:mm A") +
            "-" +
            moment(element.end_time, "HH:mm:ss").format("hh:mm A"),
      "TASK TYPE":
        element.task_type === Constants.REGULAR_TASK
          ? "Regular"
          : element.task_type === Constants.SPECIAL_REQUEST
          ? "Special Request"
          : element.task_type === Constants.ADDITIONAL_TASK
          ? "Additional Task"
          : element.task_type === Constants.RECOMMENDED_TASK
          ? "Recommended Task"
          : "",
      "TASK STATUS":
        Number(element.task_status) === Constants.TASK_CREATED
          ? "Created"
          : Number(element.task_status) === Constants.TASK_INPROGRESS
          ? "Inprogress"
          : Number(element.task_status) === Constants.TASK_COMPLETED
          ? "Completed"
          : Number(element.task_status) === Constants.TASK_COMMENTED
          ? "Commented"
          : Number(element.task_status) === Constants.TASK_INCOMPLETED
          ? "Task Incompleted"
          : "",
      "FEEDBACK STATUS": element.task_feedback_name,
      STATUS: element.status === Constants.IS_ACTIVE ? "Active" : "Deactive",
      "Created By": element.created_user_name,
    });
  }
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelFormatData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <Button
        variant="primary"
        type="submit"
        className="btn btn-primary btn-border h-40 res-w-100"
        onClick={(e) => exportToExcel(fileName)}
      >
        Export
      </Button>
    </>
  );
};

export default ExportExcel;
