import * as Yup from "yup";

import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { PaperClipSVG, TrashSVG } from "../../assets/images/ImagesV2";
import { addQuotedTask, listAllBranch, listAllClient } from "../../shared/Reducers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Button from "@material-ui/core/Button";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Modal } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import React from "react";
import { RootState } from "../../index";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import docImage from "../../assets/images/google-docs.png";
import { IBranchSelect, ICleaner } from "../../shared/ActionTypes";
import { CustomSelect } from "../../shared/Components/CustomSelect";

type Props = {
  onClose: (status: boolean) => void;
  addQuotedTaskError?: any;
  addQuotedTask?: any;
  processTrigger: any;
  addQuotedTaskSuccess: any;
  progressPercentage: number;
  clientList?: any;
  listAllClient?: any;
  branchList?: any;
  listAllBranch?: any;
};

type TAddQuotedTaskState = {
  taskType : string;
};

class AddQuotedTask extends React.Component<Props, TAddQuotedTaskState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      taskType : "1",
    };
  }

  componentDidMount(): void {
    this.props.listAllClient();

    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.add("d-none");
      button.removeAttribute("disabled");
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<TAddQuotedTaskState>,
    snapshot?: any
  ): void {
    if (prevProps.processTrigger !== this.props.processTrigger) {
    }
    if (prevProps.addQuotedTaskSuccess !== this.props.addQuotedTaskSuccess) {
    }
  }

  handleAddQuotedTask = async (formValues: any) => {
    let data: any = [];
    data = {
      client_id: formValues.client,
      branch_id: formValues.branch,
      task: formValues.task,
      sunday: formValues.sunday,
      total_time: formValues.total_time,
      monday: formValues.monday,
      wednesday: formValues.wednesday,
      thursday: formValues.thursday,
      tuesday: formValues.tuesday,
      friday: formValues.friday,
      saturday: formValues.saturday,
      task_type: formValues.task_type,
    };

    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.remove("d-none");
      button.setAttribute("disabled", "");
    }
    await this.props.addQuotedTask(data);
    setTimeout(() => {
      this.props.onClose(false);
    }, 1000);
  };

  
  handleOnChangeClient = (id: any) => {
    this.props.listAllBranch(id);
  };

  validationSchema = () => {
    const clientId = this.props.clientList.map((client: ICleaner) => client.id);
    const branchId = this.props.branchList.map((branch: IBranchSelect) => branch.value);
    return Yup.object().shape({
      task: Yup.string().required("Enter Task Name"),
      client: Yup.string()
        .oneOf([...clientId], "Invalid Client")
        .required()
        .nullable(),
      branch: Yup.string().required("Select a branch"),
      task_type: Yup.string().required("Select a Task type"),
      is_active: Yup.string(),
    });
  };
  render(): React.ReactNode {
    const initialValues = {
      client : "",
      is_active : "",
      branch : "",
      friday : 0,
      monday : 0,
      saturday : 0,
      sunday : 0,
      total_time : 0,
      task : "",
      task_type : "1",
      thursday : 0,
      tuesday : 0,
      wednesday : 0,
    };
    return (
      <div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={this.handleAddQuotedTask}
          validationSchema={this.validationSchema}
        >
          <Form encType="multipart/form-data">
            <div className="row">
              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="mb-2">
                    <label className="form-label">
                      Task Name<span className="text-project">*</span>
                    </label>
                    <Field
                      name="task"
                      type={"text"}
                      className="form-control text-field"
                    />
                    <ErrorMessage
                      name="task"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                </div>
                <div className="mb-2 col-md-6">
                  <label className="form-label">
                    Client<span className="text-project">*</span>
                  </label>
                  <Field
                    className="custom-select dropdown-field"
                    name="client"
                    options={this.props.clientList.map((client: any) => ({
                      value: client.id,
                      label: client.first_name + " " + client.last_name,
                    }))}
                    onChange={(e: any) =>
                      this.handleOnChangeClient(e.value)
                    }
                    component={CustomSelect}
                    placeholder="Select Client"
                    isMulti={false}
                  />
                  <ErrorMessage
                    name="client"
                    component={"div"}
                    className="cus-alert"
                  />
                </div>
                <div className="mb-2 col-md-6">
                  <label className="form-label">
                    Branch<span className="text-project">*</span>
                  </label>
                  <Field
                    className="custom-select dropdown-field"
                    name="branch"
                    options={this.props.branchList}
                    component={CustomSelect}
                    placeholder="Select Branch"
                    isMulti={false}
                  />
                  <ErrorMessage
                    name="branch"
                    component={"div"}
                    className="cus-alert"
                  />
                </div>
                <div className="mb-2 col-md-6">
                  <label className="form-label">
                    Task type<span className="text-project">*</span>
                  </label>
                  <Field
                    className="custom-select dropdown-field"
                    name="task_type"
                    options={[
                      { value: "0", label: "Additional Task" },
                      { value: "1", label: "Fixed Task" },
                    ]}
                    onChange={(e: any) =>
                      this.setState({ taskType : e.value })
                    }
                    component={CustomSelect}
                    placeholder="Select Task Type"
                    isMulti={false}
                  />
                  <ErrorMessage
                    name="task_type"
                    component={"div"}
                    className="cus-alert"
                  />
                </div>
                {
                  this.state.taskType === "1" ? (
                    <><div className="col-md-6">
                    <div className="mb-2">
                      <label className="form-label">
                        Total time (Hrs)
                      </label>
                      <Field
                        name="total_time"
                        type={"number"}
                        className="form-control text-field"
                      />
                      <ErrorMessage
                        name="total_time"
                        component={"div"}
                        className="cus-alert"
                      />
                    </div>
                  </div></>
                  ) : (
                      <><div className="col-md-6">
                      <div className="mb-2">
                        <label className="form-label">
                          Sunday
                        </label>
                        <Field
                          name="sunday"
                          type={"number"}
                          className="form-control text-field"
                        />
                        <ErrorMessage
                          name="sunday"
                          component={"div"}
                          className="cus-alert"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-2">
                        <label className="form-label">
                          Monday
                        </label>
                        <Field
                          name="monday"
                          type={"number"}
                          className="form-control text-field"
                        />
                        <ErrorMessage
                          name="monday"
                          component={"div"}
                          className="cus-alert"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-2">
                        <label className="form-label">
                          Tuesday
                        </label>
                        <Field
                          name="tuesday"
                          type={"number"}
                          className="form-control text-field"
                        />
                        <ErrorMessage
                          name="tuesday"
                          component={"div"}
                          className="cus-alert"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-2">
                        <label className="form-label">
                          Wednesday
                        </label>
                        <Field
                          name="wednesday"
                          type={"number"}
                          className="form-control text-field"
                        />
                        <ErrorMessage
                          name="wednesday"
                          component={"div"}
                          className="cus-alert"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-2">
                        <label className="form-label">
                          Thursday
                        </label>
                        <Field
                          name="thursday"
                          type={"number"}
                          className="form-control text-field"
                        />
                        <ErrorMessage
                          name="thursday"
                          component={"div"}
                          className="cus-alert"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-2">
                        <label className="form-label">
                          Friday
                        </label>
                        <Field
                          name="friday"
                          type={"number"}
                          className="form-control text-field"
                        />
                        <ErrorMessage
                          name="friday"
                          component={"div"}
                          className="cus-alert"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-2">
                        <label className="form-label">
                          Saturday
                        </label>
                        <Field
                          name="saturday"
                          type={"number"}
                          className="form-control text-field"
                        />
                        <ErrorMessage
                          name="saturday"
                          component={"div"}
                          className="cus-alert"
                        />
                      </div>
                    </div>
                    </>
                  )
                }
                <div className="col-md-12 cus-progress">
                  <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.props.processTrigger}
                  >
                    <Modal.Body className="p-0">
                      <ProgressBar
                        now={this.props.progressPercentage}
                        label={
                          this.props.progressPercentage === 100
                            ? `Processing your request...`
                            : `QuotedTask Adding....${this.props.progressPercentage}%`
                        }
                      />
                    </Modal.Body>
                  </Modal>
                  <div className="d-flex mt-3">
                    <div className="mr-16px">
                      <button
                        type="submit"
                        id="create-button"
                        className="btn btn-primary create-button"
                      >
                        Create
                        <span
                          id="spinner-border"
                          className="spinner-border spinner-border-sm d-none"
                        ></span>
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn cancel-button"
                        onClick={() => this.props.onClose(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  clientList: state.tasktemplate.clientList,
  branchList: state.tasktemplate.branchList,
  processTrigger: state.quotedTask.processTrigger,
  addQuotedTaskSuccess: state.quotedTask.addQuotedTaskSuccess,
  progressPercentage: state.quotedTask.progressPercentage,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addQuotedTask: bindActionCreators(addQuotedTask, dispatch),
    listAllClient: bindActionCreators(listAllClient, dispatch),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddQuotedTask);
