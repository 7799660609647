import React, { Component } from 'react'
import { DeleteApprovedTaskProps } from '../../types/approvedtask'
import { Modal, Button } from "react-bootstrap";
import { CloseBtnSVG } from "../../assets/images/Location";

class DeleteApprovedTask extends React.Component<DeleteApprovedTaskProps> {
    constructor(props:DeleteApprovedTaskProps){
        super(props)
        this.state={

        }
    }
  render() {
    return (
      <div>
        <Modal
          className="delete-modal"
          show={this.props.displayDelete}
          onHide={() => this.props.handleDeleteClose(false)}
        >
          <Modal.Body>
            <a
              href="javascript:void(0)"
              className="qr-modal-close"
              onClick={() => this.props.handleDeleteClose(false)}
            >
              <CloseBtnSVG />
            </a>

            <div className="delete-modal-body">
              <h3 className="mb-3">Delete Task</h3>
              <p>
                Do you want to delete the{" "}
                <span className="font-weight-bold">
                  {this.props.deletedName}
                </span>
              </p>
            </div>

            <div className="delete-footer qr-modal-footer d-flex justify-content-end pt-2">
              <Button
                className="btn btn-outline-success delete-cancel"
                variant="outline"
                onClick={() => this.props.handleDeleteClose(false)}
              >
                Cancel
              </Button>
              <Button
                className="delete-cancel delete-button"
                variant="primary"
                onClick={() => this.props.handleDeleteClose(true)}
              >
                Delete
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
export default DeleteApprovedTask