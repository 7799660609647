import { AllReportProps, AllReportState } from "../../types/allreport";
import { Button, Offcanvas } from "react-bootstrap";
import { Dispatch, bindActionCreators } from "redux";
import {
  ListComplaint_ById,
  getAllList_DailyTasks,
  getAll_Complaints,
  getComplaint_ById,
  handle_ComplaintSort,
  handle_FilterComplaintClient,
  handle_FilterSpecialTaskStatus,
  handle_FilterTaskBranch,
  handle_FilterTaskClient,
  handle_FilterTaskDate,
  handle_FilterTaskDueType,
  handle_FilterTaskStatus,
  handle_FilterTaskType,
  handle_FiltercomplaintBranch,
  list_AllBranch,
  list_AllClient,
  updateSidebar,
  getAllReports,
  handleFilterReportFromDate,
} from "../../shared/Reducers";
import React from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AllComplaintTable from "./AllComplaintTable";
import AllReportTable from "./AllReportTable";
import AllTaskTable from "./AllTaskTable";
import Constants from "../../shared/Constants";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ExportExcel from "../../export/exportExcel";
import AuditExportExcel from "../../export/auditExport"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ReactPaginate from "react-paginate";
import ReactSelect from "react-select";
import { RootState } from "../../index";
import { SearchSVG } from "../../assets/images/UsermanagementsSVG";
import Select from "react-select";
import SideBar from "../../components/sidebar/SideBar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import TopNav from "../../components/topnav/TopNav";
import { connect } from "react-redux";
import {
  getAll_DailyTasks,
  handleFilterReportToDate,
  get_AllReports,
  handleFilterReport_FromDate,
  handleFilterReport_ToDate,
  listAllBranch,
  listAllClient,
  getAllAuditReports
} from "../../shared/Reducers";
import moment from "moment";
import AuditTable from "../Audit/AuditTable";

const reactSelectCusStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#fff",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    },
  }),
};
class AllReport extends React.Component<AllReportProps, AllReportState> {
  constructor(props: AllReportProps) {
    super(props);
    this.state = {
      selectedOption: null,
      searchterm: "",
      searchTask: "",
      timeout: null,
      filteredBranch: "all",
      report_FromTo: "",
      show_FilterOption: false,
      start_Date: "",
      to_Date: "",
      complaint_start_date: "",
      complaint_to_date: "",
      urlLastSegment: "",
      search_complaint: "",
      filterOption_complaint: "modified_at",
      orderOption_complaint: "desc",
      searchComplaintFilter: "all",
      user_name: "",
      Complaint_status: "all",
      complaint_client: "all",
      complaint_branch: "all",
      filteredClient: "all",
      searchTerm: "",
      reportFromTo: "",
      selectedFilterClient: [],
      selectedFilterBranch: [],
      search_audit:""
    };
  }

  componentDidMount(): void {
    this.props.list_AllClient();
    this.props.list_AllBranch(0);
    this.props.updateSidebar(true);
    document.title = "Report";

    // get url last params
    const currentUrl = window.location.href;
    const segments = currentUrl.split("/");
    let urlLastSegment = segments[segments.length - 1];
    this.setState({ urlLastSegment: urlLastSegment });

    this.props.handle_FilterTaskType("all");
    if (urlLastSegment === "followup") {
      this.props.handle_FilterSpecialTaskStatus(Constants.TASK_FOLLOWUP);
    } else {
      this.props.handle_FilterSpecialTaskStatus("all");
    }

    if (urlLastSegment === "completed") {
      this.props.handle_FilterTaskStatus(Constants.TASK_COMPLETED);
    } else {
      this.props.handle_FilterTaskStatus("all");
    }

    if (urlLastSegment === "overdue") {
      this.props.handle_FilterTaskDueType("overdue");
    } else if (urlLastSegment === "due") {
      this.props.handle_FilterTaskDueType("due");
    } else {
      this.props.handle_FilterTaskDueType("all");
    }

    if (urlLastSegment === "task") {
      this.getTask_Reports(Constants.OFFSET, Constants.PER_PAGE);
    }

    let stateLastUrl = this.state.urlLastSegment;
    const interval: any = setInterval(async () => {
      // get url last params
      const currentUrl = window.location.href;
      const segments = currentUrl.split("/");
      let urlLastSegment = segments[segments.length - 1];

      // console.log("stateLastUrl",stateLastUrl);

      if (urlLastSegment === "task" && stateLastUrl !== "task") {
        stateLastUrl = urlLastSegment;
        console.log(urlLastSegment);
        console.log(stateLastUrl);

        if (this.state.urlLastSegment === "overdue") {
          this.props.handle_FilterTaskDueType("");
        } else if (this.state.urlLastSegment === "due") {
          this.props.handle_FilterTaskDueType("all");
        } else if (this.state.urlLastSegment === "completed") {
          this.props.handle_FilterTaskStatus("all");
        } else if (this.state.urlLastSegment === "followup") {
          this.props.handle_FilterSpecialTaskStatus("all");
        }
      }
    }, 1000);

    // Add event listener for URL changes
    window.addEventListener("popstate", this.handleUrlChange);
  }
  componentDidUpdate(
    prevProps: Readonly<AllReportProps>,
    prevState: Readonly<AllReportState>,
    snapshot?: any
  ): void {
    if (prevProps.filterReportFromDate !== this.props.filterReportFromDate) {
      this.getReports(Constants.OFFSET, Constants.PER_PAGE);
    }
    if (prevProps.filterReportToDate !== this.props.filterReportToDate) {
      this.getReports(Constants.OFFSET, Constants.PER_PAGE);
    }
    if (prevProps.filter_TaskTypeById !== this.props.filter_TaskTypeById) {
      this.getTask_Reports(Constants.OFFSET, Constants.PER_PAGE);
    } else if (
      prevProps.filter_TaskDueTypeByValue !==
      this.props.filter_TaskDueTypeByValue
    ) {
      this.getTask_Reports(Constants.OFFSET, Constants.PER_PAGE);
    } else if (
      prevProps.filter_SpecialRequestTaskStatusById !==
      this.props.filter_SpecialRequestTaskStatusById
    ) {
      this.getTask_Reports(Constants.OFFSET, Constants.PER_PAGE);
    } else if (
      prevProps.filter_TaskClientById !== this.props.filter_TaskClientById
    ) {
      this.getTask_Reports(Constants.OFFSET, Constants.PER_PAGE);
    } else if (
      prevProps.filter_TaskBranchById !== this.props.filter_TaskBranchById
    ) {
      this.getTask_Reports(Constants.OFFSET, Constants.PER_PAGE);
    } else if (
      prevProps.filter_TaskStatusById !== this.props.filter_TaskStatusById
    ) {
      this.getTask_Reports(Constants.OFFSET, Constants.PER_PAGE);
    } else if (prevState.complaint_client !== this.state.complaint_client) {
      this.getComplaints_Report(Constants.OFFSET, Constants.PER_PAGE);
    } else if (prevState.complaint_branch !== this.state.complaint_branch) {
      this.getComplaints_Report(Constants.OFFSET, Constants.PER_PAGE);
    } else if (prevState.Complaint_status !== this.state.Complaint_status) {
      this.getComplaints_Report(Constants.OFFSET, Constants.PER_PAGE);
    } else if (
      prevState.complaint_start_date !== this.state.complaint_start_date
    ) {
      this.getComplaints_Report(Constants.OFFSET, Constants.PER_PAGE);
    } else if (prevState.complaint_to_date !== this.state.complaint_to_date) {
      this.getComplaints_Report(Constants.OFFSET, Constants.PER_PAGE);
    }
    if (
      prevProps.filter_TaskDateTypeValue !== this.props.filter_TaskDateTypeValue
    ) {
      this.getTask_Reports(Constants.OFFSET, Constants.PER_PAGE);
    }
  }

  handleUrlChange = () => {
    console.log("test");
  };

  handleSelectedOption = async (item: any) => {
    this.setState({ selectedOption: item.label });
    if (item.label === "Cleaner SignIN / SignOUT") {
      this.getReports(Constants.OFFSET, Constants.PER_PAGE);
      this.props.listAllClient();
      this.props.listAllBranch(0);
    } else if (item.label === "Task") {
      this.getTask_Reports(Constants.OFFSET, Constants.PER_PAGE);
    }
    else if (item.label === "Audit"){
      const query = `search=&sort=&order=&offset=0&limit=${Constants.PER_PAGE}&client=all&branch=all`
      await this.props.listAllAuditReport(query)
    }
    else {
      this.getComplaints_Report(Constants.OFFSET, Constants.PER_PAGE);
    }
  };

  handle_StartDateclick = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let startDate: HTMLElement = document.querySelector(
        ".startDate button.MuiButtonBase-root"
      ) as HTMLElement;
      startDate.click();
    }
  };
  handleComplaintStartDateclick = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let startDate: HTMLElement = document.querySelector(
        ".startDate button.MuiButtonBase-root"
      ) as HTMLElement;
      startDate.click();
    }
  };

  handle_EndDateclick = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let endDate: HTMLElement = document.querySelector(
        ".endDate button.MuiButtonBase-root"
      ) as HTMLElement;
      endDate.click();
    }
  };

  handleComplaintEndDateclick = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let endDate: HTMLElement = document.querySelector(
        ".endDate button.MuiButtonBase-root"
      ) as HTMLElement;
      endDate.click();
    }
  };

  handle_Filter = (e: any) => {
    const value: string = e.target.value.toLowerCase();
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }
    this.setState({
      searchterm: value,
      timeout: setTimeout(async () => {
        try {
          const query = `client=${this.state.filteredClient}&branch=${this.state.filteredBranch}&search=${value}&sort=&order=&offset=0&limit=10&cleaner=all&from=${this.props.filterReport_FromDate}&to=${this.props.filterReport_ToDate}`;
          this.props.get_AllReports(query);
        } catch (error) {
          console.log(error);
        }
      }, 1000),
    });
  };

  getReports = async (offset: number, limit: number) => {
    try {
      const query = `client=${this.state.filteredClient}&branch=${this.state.filteredBranch}&search=${this.state.searchTerm}&sort=&order=&offset=${offset}&limit=${limit}&cleaner=all&from=${this.props.filterReportFromDate}&to=${this.props.filterReportToDate}`;
      this.props.getAllReports(query);
    } catch (error) {
      console.log(error);
    }
  };

  getTask_Reports = async (offset: number, limit: number) => {
    try {
      let query = `search=${this.state.searchTask}&sort=&order=&offset=${offset}&limit=${limit}&client=${this.props.filter_TaskClientById}&branch=${this.props.filter_TaskBranchById}&task_type=${this.props.filter_TaskTypeById}&task_priority=&due_type=${this.props.filter_TaskDueTypeByValue}&task_status=${this.props.filter_TaskStatusById}&special_request_task_status=${this.props.filter_SpecialRequestTaskStatusById}&status&start_date=${this.props.filter_TaskDateTypeValue.from_date}&end_date=${this.props.filter_TaskDateTypeValue.to_date}`;
      let AllListquery = `search=${this.state.searchTask}&sort=&order=&offset=${offset}&limit=10000&client=${this.props.filter_TaskClientById}&branch=${this.props.filter_TaskBranchById}&task_type=${this.props.filter_TaskTypeById}&task_priority=&due_type=${this.props.filter_TaskDueTypeByValue}&task_status=${this.props.filter_TaskStatusById}&special_request_task_status=${this.props.filter_SpecialRequestTaskStatusById}&status&start_date=${this.props.filter_TaskDateTypeValue.from_date}&end_date=${this.props.filter_TaskDateTypeValue.to_date}`;

      await this.props.getAll_DailyTasks(query);
      await this.props.getAllList_DailyTasks(AllListquery);
    } catch (error) {
      console.log(error);
    }
  };

  getComplaints_Report = async (offset: number, limit: number) => {
    try {
      const query = `search=${this.state.search_complaint}&sort=&order=&offset=${offset}&limit=${limit}&client=${this.state.complaint_client}&branch=${this.state.complaint_branch}&status=${this.state.Complaint_status}&from_date=${this.state.complaint_start_date}&to_date=${this.state.complaint_to_date}&user_id=${Constants.LOGIN_USER_ID}&user_type=${Constants.LOGIN_USER_TYPE}&all=0`;
      await this.props.getAll_Complaints(query);
    } catch (error) {
      console.log(error);
    }
  };

  branch_Changed = (e: any) => {
    this.setState({ filteredBranch: e.value }, () => {
      this.getReports(Constants.OFFSET, Constants.PER_PAGE);
    });
  };

  handlePageClick_Report = (e: any) => {
    if (e.selected < this.props.total_count / Constants.PER_PAGE) {
      this.getReports(e.selected * Constants.PER_PAGE, Constants.PER_PAGE);
    }
  };

  handlePageClick_Task = (e: any) => {
    if (e.selected < this.props.total_task_count / Constants.PER_PAGE) {
      this.getTask_Reports(e.selected * Constants.PER_PAGE, Constants.PER_PAGE);
    }
  };

  handlePageClick_Complaint = (e: any) => {
    if (e.selected < this.props.total_complaint_count / Constants.PER_PAGE) {
      this.getComplaints_Report(
        e.selected * Constants.PER_PAGE,
        Constants.PER_PAGE
      );
    }
  };

  handlePageClick_audit = (e: any) => {

    if (e.selected < this.props.auditReportCount / Constants.PER_PAGE) {
      const query = `search=&sort=&order=&offset=${e.selected * Constants.PER_PAGE}&limit=${Constants.PER_PAGE}&client=all&branch=all`
      this.props.listAllAuditReport(
        query
      );
    }
  };

  handleComplaintFilter = (e: any) => {
    this.props.getAll_Complaints(e);
  };

  handletask_Filter = (status: boolean) => {
    this.setState({ show_FilterOption: status });
  };

  handleFilterClientBranch = (data: any) => {
    let arrayId: any = [];
    arrayId.push(data.map((client: any) => client.value));
    let clientId: string = arrayId.toString();
    const query = `search=&sort=&order=&offset=0&limit=10&client=${this.state.complaint_client}&branch=all`;
    this.props.getAll_Complaints(query);
    this.props.list_AllBranch(clientId);
  };

  handleFilterComplaintClient = (data: any) => {
    let arrayId: any = [];
    arrayId.push(data.map((client: any) => client.value));
    let client_Id: string = arrayId.join();

    if (client_Id === "") {
      this.setState({ complaint_client: "all" }, () => {
        this.props.list_AllBranch(0);
      });
    } else {
      this.setState({ complaint_client: client_Id }, () => {
        this.props.list_AllBranch(client_Id);
      });
    }
  };

  handleFilterComplaintBranch = async (data: any) => {
    let arrayId: any = [];
    arrayId.push(data.map((clients: any) => clients.value));
    let branchIds: string = arrayId.join();
    if (branchIds === "") {
      this.setState({ complaint_branch: "all" });
    } else {
      this.setState({ complaint_branch: branchIds });
    }
  };

  handleComplaintStatus = (data: any) => {
    if (data === "") {
      this.setState({ Complaint_status: "all" });
    } else {
      this.setState({ Complaint_status: data });
    }
  };

  handleComplaintStartDate = (val: any) => {
    this.setState({ complaint_start_date: val });
  };

  handleComplaintToDate = (val: any) => {
    this.setState({ complaint_to_date: val });
  };

  handleStartDate = (val: any) => {
    console.log(val, "date value");
    this.setState({ start_Date: val }, () => {
      if (this.state.to_Date !== "" && this.state.start_Date !== "") {
        let value: any = {
          from_date: this.state.start_Date,
          to_date: this.state.to_Date,
        };
        this.props.handle_FilterTaskDate(value);
      }
    });
  };

  handleStartDateClick = () => {
    let startDate: HTMLElement = document.querySelector(
      ".css-1lwbda4-MuiStack-root button.MuiButtonBase-root"
    ) as HTMLElement;

    startDate.click();
  };

  handleToDate = (val: any) => {
    console.log(val, "ToDate value");
    this.setState({ to_Date: val }, () => {
      if (this.state.to_Date !== "" && this.state.start_Date !== "") {
        let value: any = {
          from_date: this.state.start_Date,

          to_date: this.state.to_Date,
        };
        console.log(value, "date test");

        this.props.handle_FilterTaskDate(value);
      }
    });
  };

  handleEndDateClick = () => {
    let endDate: HTMLElement = document.querySelector(
      ".endDate button.MuiButtonBase-root"
    ) as HTMLElement;

    endDate.click();
  };

  handleSearchComplaint = (e: any) => {
    const value: string = e.target.value.toLowerCase();
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

    this.setState({
      search_complaint: value,
      timeout: setTimeout(async () => {
        try {
          const query = `search=${this.state.search_complaint}&sort=&order=&offset=${Constants.OFFSET}&limit=${Constants.PER_PAGE}&client=${this.state.complaint_client}&branch=${this.state.complaint_branch}&status=${this.state.Complaint_status}&from_date=${this.state.complaint_start_date}&to_date=${this.state.complaint_to_date}`;
          this.props.getAll_Complaints(query);
        } catch (error) {
          console.log(error);
        }
        // Trigger your function here that you want to call when user stops typing
      }, 1000), // Set the delay time in ms
    });
  };


  handleSearchAudit = (e: any) => {
    const value: string = e.target.value.toLowerCase();
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

    this.setState({
      search_audit: value,
      timeout: setTimeout(async () => {
        try {
          const query = `search=${this.state.search_audit}&sort=&order=&offset=${Constants.OFFSET}&limit=${Constants.PER_PAGE}&client=${this.state.complaint_client}&branch=${this.state.complaint_branch}`;
          this.props.listAllAuditReport(query);
        } catch (error) {
          console.log(error);
        }
        // Trigger your function here that you want to call when user stops typing
      }, 1000), // Set the delay time in ms
    });
  };

  handleSearchTask = (e: any) => {
    const value: string = e.target.value.toLowerCase();
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

    this.setState({
      searchTask: value,
      timeout: setTimeout(async () => {
        try {
          let query = `search=${this.state.searchTask}&sort=&order=&offset=0&limit=10&client=${this.props.filter_TaskClientById}&branch=${this.props.filter_TaskBranchById}&task_type=${this.props.filter_TaskTypeById}&task_priority=&due_type=${this.props.filter_TaskDueTypeByValue}&task_status=${this.props.filter_TaskStatusById}&special_request_task_status=${this.props.filter_SpecialRequestTaskStatusById}&status`;
          let AllListquery = `search=${this.state.searchTask}&sort=&order=&offset=0&limit=10000&client=${this.props.filter_TaskClientById}&branch=${this.props.filter_TaskBranchById}&task_type=${this.props.filter_TaskTypeById}&task_priority=&due_type=${this.props.filter_TaskDueTypeByValue}&task_status=${this.props.filter_TaskStatusById}&special_request_task_status=${this.props.filter_SpecialRequestTaskStatusById}&status`;
          await this.props.getAll_DailyTasks(query);

          // no limit data
          await this.props.getAllList_DailyTasks(AllListquery);
        } catch (error) {
          console.log(error);
        }
        // Trigger your function here that you want to call when user stops typing
      }, 1000), // Set the delay time in ms
    });
  };

  branchChanged = (e: any) => {
    this.setState({ filteredBranch: e.value }, () => {
      this.getReports(Constants.OFFSET, Constants.PER_PAGE);
    });
  };

  clientChanged = (e: any) => {
    this.props.listAllBranch(e.value);
    this.setState({ filteredClient: e.value }, () => {
      this.getReports(Constants.OFFSET, Constants.PER_PAGE);
    });
  };

  handleFilterComplaintName = (data: any) => {
    try {
      this.setState({
        filterOption_complaint: data.column_name,
      });
      const query = `search=&sort=${data.column_name}&order=&offset=${Constants.OFFSET}&limit=${Constants.PER_PAGE}&client=all&branch=all`;
      this.props.getAll_Complaints(query);
    } catch (error) {
      console.log(error);
    }
  };

  // Search Branch Filter
  handleFilter = (e: any) => {
    const value: string = e.target.value.toLowerCase();
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

    this.setState({
      searchTerm: value,
      timeout: setTimeout(async () => {
        try {
          const query = `branch=${this.state.filteredBranch}&search=${value}&sort=&order=&offset=0&limit=10&cleaner=all&from=${this.props.filterReportFromDate}&to=${this.props.filterReportToDate}`;
          this.props.getAllReports(query);
        } catch (error) {
          console.log(error);
        }
        // Trigger your function here that you want to call when user stops typing
      }, 1000), // Set the delay time in ms
    });
  };
  render() {
    console.log(this.state.searchTask, "searchTerm");

    const ALL_REPORT_OPTION = [
      { label: "Cleaner SignIN / SignOUT", value: "report" },
      { label: "Task", value: "task" },
      {
        label: "Complaint",
        value: "complaint",
      },
      {
        label: "Audit",
        value: "audit",
      },
    ];

    let branchOption: any = [{ label: "All Branch", value: "all" }];
    this.props.branchList.map((branch: any) => {
      branchOption.push(branch);
    });
    let clientOption: any = [{ label: "All Client", value: "all" }];
    this.props.clientList.map((client: any) => {
      clientOption.push({
        value: client.id,
        label: client.first_name + " " + client.last_name,
      });
    });
    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className={this.props.status ? "main-panel" : "main-panel2"}>
          <TopNav />
          <div className="content-wrapper">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="mb-0">ALL REPORT DETAILS</h3>
                </div>
                <div className="col-md-12 col-lg-4 mb-3">
                  <Select
                    placeholder="Select The Export Report..."
                    isMulti={false}
                    options={ALL_REPORT_OPTION}
                    onChange={this.handleSelectedOption}
                  />
                </div>
                <div>
                  {this.state.selectedOption === "Cleaner SignIN / SignOUT" ? (
                    <div>
                      <div className="row mt-3">
                        <div className="col-md-12 col-lg-4 mb-3">
                          <div className="input-group search">
                            <span
                              className="input-group-text search-icon"
                              id="basic-addon1"
                            >
                              <SearchSVG />
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              value={this.state.searchTerm}
                              onInput={this.handleFilter}
                              onChange={(e) =>
                                this.setState({ searchTerm: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-4 mb-3">
                          <a
                            className="btn btn-primary btn-border"
                            href={
                              Constants.baseUrl +
                              `activity/export?client=${this.state.filteredClient}&branch=${this.state.filteredBranch}&search=${this.state.searchTerm}&sort=&order=&from=` +
                              this.props.filterReportFromDate +
                              "&to=" +
                              this.props.filterReportToDate
                            }
                          >
                            Export
                          </a>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-md-12 col-lg-3 startDate"
                          onClick={() => this.handle_StartDateclick()}
                        >
                          <label className="form-label">From date</label>
                          <div>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              id="startDate"
                            >
                              <Stack spacing={3}>
                                <DesktopDatePicker
                                  inputFormat="DD/MM/YYYY"
                                  value={this.props.filterReportFromDate}
                                  onChange={(e: any) => {
                                    this.props.handleFilterReportFromDate(
                                      moment(new Date(e?.toString())).format(
                                        "YYYY-MM-DD"
                                      )
                                    );
                                    this.setState({
                                      reportFromTo: {
                                        filterReportFromDate:
                                          this.props.filterReportFromDate,
                                        filterReportToDate:
                                          this.props.filterReportToDate,
                                      },
                                    });
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </div>
                        </div>
                        {/* <div
                          className="col-md-12 col-lg-3 endDate"
                          onClick={() => this.handleEndDateClick()}
                        >
                          <label className="form-label">To date</label>
                          <div>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              id="startDate"
                            >
                              <Stack spacing={3}>
                                <DesktopDatePicker
                                  inputFormat="DD/MM/YYYY"
                                  value={this.props.filterReportFromDate}
                                  onChange={(e: any) => {
                                    this.props.handleFilterReportFromDate(
                                      moment(new Date(e?.toString())).format(
                                        "YYYY-MM-DD"
                                      )
                                    );
                                    this.setState({
                                      reportFromTo: {
                                        filterReportFromDate:
                                          this.props.filterReportFromDate,
                                        filterReportToDate:
                                          this.props.filterReportToDate,
                                      },
                                    });
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </div>
                        </div> */}
                        <div className="col-md-12 col-lg-3">
                          <label className="form-label">To date</label>
                          <div
                            className="endDate"
                            onClick={() => this.handle_EndDateclick()}
                          >
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              id="endDate"
                            >
                              <Stack spacing={3}>
                                <DesktopDatePicker
                                  inputFormat="DD/MM/YYYY"
                                  value={this.props.filterReportToDate}
                                  onChange={(e: any) => {
                                    this.props.handleFilterReportToDate(
                                      moment(new Date(e?.toString())).format(
                                        "YYYY-MM-DD"
                                      )
                                    );
                                    // this.setState({
                                    //   reportFromTo: {
                                    //     filterReportFromDate:
                                    //       this.props.filterReportFromDate,
                                    //     filterReportToDate:
                                    //       this.props.filterReportToDate,
                                    //   },
                                    // });
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-3">
                          <label className="form-label">Client</label>
                          <div>
                            <ReactSelect
                              options={clientOption}
                              placeholder={`All Client`}
                              onChange={this.clientChanged}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-3">
                          <label className="form-label">Branch</label>
                          <div>
                            <ReactSelect
                              options={branchOption}
                              placeholder={`All Branch`}
                              onChange={this.branchChanged}
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-12 col-lg-4 text-md-start text-lg-end"> */}
                        {/* <div className="d-flex justify-content-end"> */}
                        {/* <label className="form-label w-100">&nbsp;</label> */}

                        {/* </div> */}
                        {/* </div> */}
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <div className="card">
                            <div className="card-body">
                              {this.props.filtered_ReportData.length > 0 ? (
                                <div className="table-responsive">
                                  <table className="table employee-table">
                                    <thead>
                                      <tr>
                                        <th scope="col">CLEANER</th>
                                        <th scope="col">CLIENT</th>
                                        <th scope="col">BRANCH</th>
                                        <th scope="col">SIGNED IN</th>
                                        <th scope="col">SIGNED OUT</th>
                                        <th scope="col">REASON</th>
                                        {(Constants.LOGIN_USER_TYPE ===
                                          Constants.ADMINUTINT ||
                                          Constants.LOGIN_USER_TYPE ===
                                            Constants.SUPERVISORUTINT) && (
                                          <th scope="col">AWAY</th>
                                        )}
                                      </tr>
                                    </thead>
                                    <AllReportTable
                                      data={this.props.filtered_ReportData}
                                    />
                                  </table>
                                </div>
                              ) : (
                                <p className="text-center">
                                  There is no report data found
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* {this.state.selectedOption === "Cleaner SignIN / SignOUT" ? (


                      ) : ()} */}
                      <div className="row mt-2">
                        {this.props.total_count > 10 ? (
                          <div className="col-md-12">
                            <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={
                                this.props.total_count / Constants.PER_PAGE
                              }
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={2}
                              onPageChange={this.handlePageClick_Report}
                              containerClassName={"pagination"}
                              nextClassName={"next-page"}
                              activeClassName={"active"}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : this.state.selectedOption === "Task" ? (
                    <div className="row">
                      {/* <div className="col-md-4 col-lg-4"> */}
                      <div className="col-md-6">
                        <h3 className="mb-0"> Task</h3>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-lg-6">
                          <div className="input-group search">
                            <span
                              className="input-group-text search-icon"
                              id="basic-addon1"
                            >
                              <SearchSVG />
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              value={this.state.searchTask}
                              onInput={this.handleSearchTask}
                              onChange={(e) =>
                                this.setState({
                                  searchTask: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                          <div className="d-flex justify-content-start justify-content-md-end res-mt-3">
                            <div className="mob-res-w-100">
                              <Button
                                aria-controls="offcanvasRight"
                                style={{ marginRight: "10px" }}
                                className="btn btn-primary filter-button res-mt-3"
                                onClick={() => this.handletask_Filter(true)}
                              >
                                Filter
                              </Button>
                            </div>
                            <div className="mob-res-w-100">
                              <ExportExcel
                                excelData={this.props.filtered_DailyTaskAllData}
                                fileName={"Task list report"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                      <Offcanvas
                        className="task-offcanvas filter-canvas"
                        placement="end"
                        show={this.state.show_FilterOption}
                        onHide={() => this.handletask_Filter(false)}
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            <span className="offc-title">Filter table</span>
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <div className="row mt-4">
                            <div className="col-md-6">
                              <label className="mb-2">Task Type</label>
                              <Select
                                placeholder="Select Task Type"
                                styles={reactSelectCusStyles}
                                isMulti={false}
                                value={{
                                  value: this.props.filter_TaskTypeById,
                                  label:
                                    this.props.filter_TaskTypeById === "all"
                                      ? "All"
                                      : this.props.filter_TaskTypeById ===
                                        Constants.REGULAR_TASK
                                      ? "Regular"
                                      : "Special Request",
                                }}
                                options={[
                                  { value: "all", label: "All" },
                                  {
                                    value: Constants.REGULAR_TASK,
                                    label: "Regular",
                                  },
                                  {
                                    value: Constants.SPECIAL_REQUEST,
                                    label: "Special Request",
                                  },
                                ]}
                                onChange={(e: any) => {
                                  this.props.handle_FilterTaskType(e.value);
                                }}
                              />
                            </div>

                            <div className="col-md-6 res-mt-3">
                              <label className="mb-2">Select Client</label>
                              <Select
                                placeholder="Select Client"
                                styles={reactSelectCusStyles}
                                isMulti={true}
                                value={this.state.selectedFilterClient}
                                options={this.props.client_List.map(
                                  (client: any) => ({
                                    value: client.id,
                                    label:
                                      client.first_name +
                                      " " +
                                      client.last_name,
                                  })
                                )}
                                onChange={(e: any) => {
                                  this.props.handle_FilterTaskClient(
                                    e.map((val: any) => val.value)
                                  );
                                  this.handleFilterClientBranch(e);
                                  this.setState({ selectedFilterClient: e });
                                }}
                              />
                            </div>

                            {Constants.LOGIN_USER_TYPE !== Constants.QAUTINT ? (
                              <div className="col-md-6 res-mt-3">
                                <label className="mb-2">Select Branch</label>
                                <Select
                                  placeholder="Select Branch"
                                  styles={reactSelectCusStyles}
                                  isMulti={true}
                                  value={this.state.selectedFilterBranch}
                                  options={this.props.branch_List}
                                  onChange={(e: any) => {
                                    this.props.handle_FilterTaskBranch(
                                      e.map((val: any) => val.value)
                                    );
                                    this.setState({ selectedFilterBranch: e });
                                  }}
                                />
                              </div>
                            ) : (
                              <></>
                            )}

                            <div className="col-md-6 res-mt-3">
                              <label className="mb-2">Task Status</label>
                              <Select
                                placeholder="Select Task Status"
                                styles={reactSelectCusStyles}
                                isMulti={false}
                                value={{
                                  value: this.props.filter_TaskStatusById,
                                  label:
                                    this.props.filter_TaskStatusById ===
                                    Constants.TASK_CREATED
                                      ? "Create"
                                      : this.props.filter_TaskStatusById ===
                                        Constants.TASK_INPROGRESS
                                      ? "Inprogress"
                                      : this.props.filter_TaskStatusById ===
                                        Constants.TASK_COMPLETED
                                      ? "Completed"
                                      : this.props.filter_TaskStatusById ===
                                        Constants.TASK_COMMENTED
                                      ? "Commented"
                                      : this.props.filter_TaskStatusById ===
                                        Constants.TASK_INCOMPLETED
                                      ? "Incompleted"
                                      : "All",
                                }}
                                options={[
                                  { value: "all", label: "All" },
                                  {
                                    value: Constants.TASK_CREATED,
                                    label: "Create",
                                  },
                                  {
                                    value: Constants.TASK_INPROGRESS,
                                    label: "Inprogress",
                                  },
                                  {
                                    value: Constants.TASK_COMPLETED,
                                    label: "Completed",
                                  },
                                  {
                                    value: Constants.TASK_COMMENTED,
                                    label: "Commented",
                                  },
                                  {
                                    value: Constants.TASK_INCOMPLETED,
                                    label: "Incompleted",
                                  },
                                ]}
                                onChange={(e: any) =>
                                  this.props.handle_FilterTaskStatus(e.value)
                                }
                              />
                            </div>
                            <div className="col-md-6 res-mt-3">
                              <label className="mb-2">
                                Special Request Status
                              </label>
                              <Select
                                placeholder="Select Task Status"
                                styles={reactSelectCusStyles}
                                isMulti={false}
                                value={{
                                  value:
                                    this.props
                                      .filter_SpecialRequestTaskStatusById,
                                  label:
                                    this.props
                                      .filter_SpecialRequestTaskStatusById ===
                                    Constants.TASK_RESOLVED
                                      ? "Resolved"
                                      : this.props
                                          .filter_SpecialRequestTaskStatusById ===
                                        Constants.TASK_FOLLOWUP
                                      ? "Followup"
                                      : "All",
                                }}
                                options={[
                                  { value: "all", label: "All" },
                                  {
                                    value: Constants.TASK_RESOLVED,
                                    label: "Resolved",
                                  },
                                  {
                                    value: Constants.TASK_FOLLOWUP,
                                    label: "Followup",
                                  },
                                ]}
                                onChange={(e: any) =>
                                  this.props.handle_FilterSpecialTaskStatus(
                                    e.value
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-6 res-mt-3">
                              <label className="mb-2">Due Type</label>
                              <Select
                                placeholder="Select Due type"
                                styles={reactSelectCusStyles}
                                isMulti={false}
                                value={{
                                  value: this.props.filter_TaskDueTypeByValue,
                                  label:
                                    this.props.filter_TaskDueTypeByValue ===
                                    "overdue"
                                      ? "Over due"
                                      : this.props.filter_TaskDueTypeByValue ===
                                        "due"
                                      ? "Due"
                                      : "All",
                                }}
                                options={[
                                  { value: "", label: "All" },
                                  { value: "overdue", label: "Over due" },
                                  { value: "due", label: "Due" },
                                ]}
                                onChange={(e: any) =>
                                  this.props.handle_FilterTaskDueType(e.value)
                                }
                              />
                            </div>
                            <div className="col-md-6 res-mt-3">
                              <label className="mb-2">Date</label>
                              <Select
                                placeholder="Select Date"
                                styles={reactSelectCusStyles}
                                isMulti={false}
                                // value={{
                                //   value: this.props.filter_TaskDateTypeValue,
                                //   label:
                                //     this.props.filter_TaskDateTypeValue
                                //       .from_date ===
                                //     moment(new Date()).format("YYYY-MM-DD")
                                //       ? "Next week"
                                //       : this.props.filter_TaskDateTypeValue
                                //           .from_date ===
                                //           moment()
                                //             .subtract(1, "weeks")
                                //             .endOf("week")
                                //             .format("YYYY-MM-DD") &&
                                //         this.props.filter_TaskDateTypeValue
                                //           .to_date ===
                                //           moment()
                                //             .add(1, "weeks")
                                //             .endOf("week")
                                //             .format("YYYY-MM-DD")
                                //       ? "Current week"
                                //       : this.props.filter_TaskDateTypeValue
                                //           .to_date ===
                                //         moment(new Date()).format("YYYY-MM-DD")
                                //       ? "Last week"
                                //       : "All",
                                // }}
                                options={[
                                  {
                                    value: { from_date: "", to_date: "" },
                                    label: "All",
                                  },
                                  {
                                    value: {
                                      from_date: moment()
                                        .startOf("week")
                                        .subtract(1, "weeks")
                                        .format("YYYY-MM-DD"),
                                      to_date: moment()
                                        .startOf("week")
                                        .subtract(1, "weeks")
                                        .add(6, "days")
                                        .format("YYYY-MM-DD"),
                                    },
                                    label: "Last week",
                                  },
                                  {
                                    value: {
                                      from_date: moment()
                                        .startOf("week")
                                        .format("YYYY-MM-DD"),
                                      to_date: moment()
                                        .endOf("week")
                                        .format("YYYY-MM-DD"),
                                    },
                                    label: "Current week",
                                  },
                                  {
                                    value: {
                                      from_date: moment()
                                        .startOf("week")
                                        .add(7, "days")
                                        .format("YYYY-MM-DD"),
                                      to_date: moment()
                                        .add(1, "weeks")
                                        .endOf("week")
                                        .format("YYYY-MM-DD"),
                                    },
                                    label: "Next week",
                                  },
                                ]}
                                onChange={(e: any) => {
                                  this.props.handle_FilterTaskDate(e.value);
                                }}
                              />
                            </div>
                            <div
                              className="col-md-6 res-mt-3 startDate"
                              onClick={() => this.handle_StartDateclick()}
                            >
                              <label className="mb-2">From Date</label>
                              {/* <div ></div> */}
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                id="startDate"
                              >
                                <Stack spacing={3}>
                                  <DesktopDatePicker
                                    inputFormat="DD/MM/YYYY"
                                    value={this.state.start_Date}
                                    onChange={(e: any) =>
                                      this.handleStartDate(
                                        moment(new Date(e?.toString())).format(
                                          "YYYY-MM-DD"
                                        )
                                      )
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="DD/MM/YYYY"
                                      />
                                    )}
                                  />
                                </Stack>
                              </LocalizationProvider>
                            </div>
                            <div
                              className="col-md-6 res-mt-3 endDate"
                              onClick={() => this.handle_EndDateclick()}
                            >
                              <label className="mb-2">To Date</label>
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                id="endDate"
                              >
                                <Stack spacing={3}>
                                  <DesktopDatePicker
                                    inputFormat="DD/MM/YYYY"
                                    value={this.state.to_Date}
                                    onChange={(e: any) => {
                                      this.handleToDate(
                                        moment(new Date(e?.toString())).format(
                                          "YYYY-MM-DD"
                                        )
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="DD/MM/YYYY"
                                      />
                                    )}
                                  />
                                </Stack>
                              </LocalizationProvider>
                            </div>
                          </div>
                        </Offcanvas.Body>
                      </Offcanvas>
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div className="card">
                            <div className="card-body">
                              {this.props.filtered_DailyTaskData.length > 0 ? (
                                <div className="table-responsive">
                                  <table className="table daily-task-table">
                                    <thead>
                                      <tr>
                                        <th scope="col">NAME</th>
                                        <th scope="col">CLEANERS</th>
                                        <th scope="col">TYPE</th>
                                        <th scope="col">STATUS</th>
                                        <th scope="col">FEEDBACK</th>
                                        <th scope="col">CLIENT</th>
                                        <th scope="col">BRANCH</th>
                                        <th scope="col">DATE</th>
                                        <th scope="col">TIME</th>
                                        <th scope="col">IS ACTIVE</th>
                                      </tr>
                                    </thead>
                                    <AllTaskTable
                                      data={this.props.filtered_DailyTaskData}
                                    />
                                  </table>
                                </div>
                              ) : (
                                <p className="text-center">
                                  There is no task data found
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        {this.props.total_task_count > 10 ? (
                          <div className="col-md-12">
                            {/* {this.state.pagehandle ? null : ( */}
                            <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={
                                this.props.total_task_count / Constants.PER_PAGE
                              }
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={2}
                              onPageChange={this.handlePageClick_Task}
                              containerClassName={"pagination"}
                              nextClassName={"next-page"}
                              activeClassName={"active"}
                            />
                            {/* )} */}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : this.state.selectedOption === "Complaint" ? (
                    <div className="row">
                      {/* <div className="col-md-6 col-lg-6"> */}
                      <div className="col-md-6">
                        <h3 className="mb-0"> Complaint </h3>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-group search">
                            <span
                              className="input-group-text search-icon"
                              id="basic-addon1"
                            >
                              <SearchSVG />
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              value={this.state.search_complaint}
                              onInput={this.handleSearchComplaint}
                              onChange={(e) =>
                                this.setState({
                                  search_complaint: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6 text-end">
                          <Button
                            style={{ marginRight: "10px" }}
                            aria-controls="offcanvasRight"
                            className="btn btn-primary filter-button res-mt-3"
                            onClick={() => this.handletask_Filter(true)}
                          >
                            <span>Filter</span>
                          </Button>
                          {/* <div className="col-md-12 col-lg-4 text-md-start text-lg-end"> */}
                          {/* <label className="form-label w-100">&nbsp;</label> */}
                          <a
                            className="btn btn-primary btn-border"
                            href={
                              Constants.baseUrl +
                              `complaints/pdf-export/complaints?search=${this.state.search_complaint}&sort=&order=&offset=0&limit=10000&client=${this.state.complaint_client}&branch=${this.state.complaint_branch}&status=all&from_date=&to_date=&user_id=${Constants.LOGIN_USER_ID}&user_type=${Constants.LOGIN_USER_TYPE}`
                            }
                          >
                            Export
                          </a>
                          {/* </div> */}
                        </div>
                      </div>
                      <div className="d-flex justify-content-start justify-content-md-end res-mt-3"></div>
                      <Offcanvas
                        className="task-offcanvas filter-canvas"
                        placement="end"
                        show={this.state.show_FilterOption}
                        onHide={() => this.handletask_Filter(false)}
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            <span className="offc-title">Filter table</span>
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <div className="row mt-4">
                            <div className="col-md-6 res-mt-3">
                              <label className="mb-2">Select Client</label>
                              <Select
                                placeholder="Select Client"
                                styles={reactSelectCusStyles}
                                isMulti={true}
                                options={this.props.client_List.map(
                                  (client: any) => ({
                                    value: client.id,
                                    label:
                                      client.first_name +
                                      " " +
                                      client.last_name,
                                  })
                                )}
                                onChange={(e: any) => {
                                  console.log(e, "console of e");
                                  this.props.handle_FilterComplaintClient(
                                    e.map((val: any) => val.value)
                                  );
                                  this.handleFilterComplaintClient(e);
                                }}
                              />
                            </div>
                            <div className="col-md-6 res-mt-3">
                              <label className="mb-2">Select Branch</label>
                              <Select
                                placeholder="Select Branch"
                                styles={reactSelectCusStyles}
                                isMulti={true}
                                options={this.props.branch_List}
                                onChange={(e: any) => {
                                  console.log(e, "complaint of e");
                                  this.props.handle_FiltercomplaintBranch(
                                    e.map((val: any) => val.value)
                                  );
                                  this.handleFilterComplaintBranch(e);
                                }}
                              />
                            </div>

                            <div className="col-md-6">
                              <label className="form-label">From date</label>
                              <div
                                className="startDate"
                                onClick={() =>
                                  this.handleComplaintStartDateclick()
                                }
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  id="startDate"
                                >
                                  <Stack spacing={3}>
                                    <DesktopDatePicker
                                      inputFormat="DD/MM/YYYY"
                                      maxDate={this.state.complaint_to_date}
                                      value={this.state.complaint_start_date}
                                      onChange={(e: any) => {
                                        this.handleComplaintStartDate(
                                          moment(
                                            new Date(e?.toString())
                                          ).format("YYYY-MM-DD")
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </Stack>
                                </LocalizationProvider>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">To date</label>
                              <div
                                className="endDate"
                                onClick={() =>
                                  this.handleComplaintEndDateclick()
                                }
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  id="endDate"
                                >
                                  <Stack spacing={3}>
                                    <DesktopDatePicker
                                      inputFormat="DD/MM/YYYY"
                                      minDate={this.state.complaint_start_date}
                                      value={this.state.complaint_to_date}
                                      onChange={(e: any) => {
                                        this.handleComplaintToDate(
                                          moment(
                                            new Date(e?.toString())
                                          ).format("YYYY-MM-DD")
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </Stack>
                                </LocalizationProvider>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <label className="mb-2">Status</label>
                              <Select
                                placeholder="Select Status ..."
                                styles={reactSelectCusStyles}
                                isMulti={false}
                                options={[
                                  { value: "all", label: "All" },
                                  { value: "1", label: "Open" },
                                  { value: "0", label: "Close" },
                                ]}
                                onChange={(e: any) => {
                                  this.handleComplaintStatus(e.value);
                                }}
                              />
                            </div>
                          </div>
                        </Offcanvas.Body>
                      </Offcanvas>

                      {/* </div> */}

                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div className="card">
                            <div className="card-body">
                              {this.props.filtered_ComplaintData.length > 0 ? (
                                <div className="table-responsive">
                                  <table className="table employee-table">
                                    <thead>
                                      <tr>
                                        <th scope="col">Complaint Name</th>
                                        <th scope="col">User</th>
                                        <th scope="col">Client</th>
                                        <th scope="col">Branch</th>
                                        <th scope="col">Complaint Status</th>
                                        <th scope="col">Created Date</th>
                                      </tr>
                                    </thead>
                                    <AllComplaintTable
                                      data={this.props.filtered_ComplaintData}
                                    />
                                  </table>
                                </div>
                              ) : (
                                <p className="text-center">
                                  There is no task data found
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        {this.props.total_complaint_count > 10 ? (
                          <div className="col-md-12">
                            {/* {this.state.pagehandle ? null : ( */}
                            <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={
                                this.props.total_complaint_count /
                                Constants.PER_PAGE
                              }
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={2}
                              onPageChange={this.handlePageClick_Complaint}
                              containerClassName={"pagination"}
                              nextClassName={"next-page"}
                              activeClassName={"active"}
                            />
                            {/* )} */}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) :
                  this.state.selectedOption === "Audit" ? (
                    <div className="row">
                      {/* <div className="col-md-6 col-lg-6"> */}
                      <div className="col-md-6">
                        <h3 className="mb-0"> Audit </h3>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-group search">
                            <span
                              className="input-group-text search-icon"
                              id="basic-addon1"
                            >
                              <SearchSVG />
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              value={this.state.search_audit}
                              onInput={this.handleSearchAudit}
                              onChange={(e) =>
                                this.setState({
                                  search_audit: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6 text-end">
                          {/* <Button
                            style={{ marginRight: "80px" }}
                            aria-controls="offcanvasRight"
                            className="btn btn-primary filter-button res-mt-3"
                            onClick={() => this.handletask_Filter(true)}
                          >
                            <span>Filter</span>
                          </Button> */}
                          {/* <div className="col-md-12 col-lg-4 text-md-start text-lg-end"> */}
                          {/* <label className="form-label w-100">&nbsp;</label> */}
                          {/* <a
                            className="btn btn-primary btn-border"
                            href={
                              Constants.baseUrl +
                              `audit/export?client=${this.state.filteredClient}&branch=${this.state.filteredBranch}&search=${this.state.searchTerm}&sort=&order=&from=` +
                              this.props.filterReportFromDate +
                              "&to=" +
                              this.props.filterReportToDate
                            }
                          >
                            Export
                          </a> */}
                               <div className="mob-res-w-100" style={{ marginTop: "-7%" }}>
                               {this.props.auditReportData && this.props.auditReportData.length > 0 ? (
                              <AuditExportExcel
                                excelData={this.props.auditReportData}
                                fileName={"Audit list report"}
                              />
                               ):""}
                            </div>
                          {/* </div> */}
                        </div>
                      </div>
                      <div className="d-flex justify-content-start justify-content-md-end res-mt-3"></div>
                      <Offcanvas
                        className="task-offcanvas filter-canvas"
                        placement="end"
                        show={this.state.show_FilterOption}
                        onHide={() => this.handletask_Filter(false)}
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            <span className="offc-title">Filter table</span>
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <div className="row mt-4">
                            <div className="col-md-6 res-mt-3">
                              <label className="mb-2">Select Client</label>
                              <Select
                                placeholder="Select Client"
                                styles={reactSelectCusStyles}
                                isMulti={true}
                                options={this.props.client_List.map(
                                  (client: any) => ({
                                    value: client.id,
                                    label:
                                      client.first_name +
                                      " " +
                                      client.last_name,
                                  })
                                )}
                                onChange={(e: any) => {
                                  console.log(e, "console of e");
                                  this.props.handle_FilterComplaintClient(
                                    e.map((val: any) => val.value)
                                  );
                                  this.handleFilterComplaintClient(e);
                                }}
                              />
                            </div>
                            <div className="col-md-6 res-mt-3">
                              <label className="mb-2">Select Branch</label>
                              <Select
                                placeholder="Select Branch"
                                styles={reactSelectCusStyles}
                                isMulti={true}
                                options={this.props.branch_List}
                                onChange={(e: any) => {
                                  console.log(e, "complaint of e");
                                  this.props.handle_FiltercomplaintBranch(
                                    e.map((val: any) => val.value)
                                  );
                                  this.handleFilterComplaintBranch(e);
                                }}
                              />
                            </div>

                            <div className="col-md-6">
                              <label className="form-label">From date</label>
                              <div
                                className="startDate"
                                onClick={() =>
                                  this.handleComplaintStartDateclick()
                                }
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  id="startDate"
                                >
                                  <Stack spacing={3}>
                                    <DesktopDatePicker
                                      inputFormat="DD/MM/YYYY"
                                      maxDate={this.state.complaint_to_date}
                                      value={this.state.complaint_start_date}
                                      onChange={(e: any) => {
                                        this.handleComplaintStartDate(
                                          moment(
                                            new Date(e?.toString())
                                          ).format("YYYY-MM-DD")
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </Stack>
                                </LocalizationProvider>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">To date</label>
                              <div
                                className="endDate"
                                onClick={() =>
                                  this.handleComplaintEndDateclick()
                                }
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  id="endDate"
                                >
                                  <Stack spacing={3}>
                                    <DesktopDatePicker
                                      inputFormat="DD/MM/YYYY"
                                      minDate={this.state.complaint_start_date}
                                      value={this.state.complaint_to_date}
                                      onChange={(e: any) => {
                                        this.handleComplaintToDate(
                                          moment(
                                            new Date(e?.toString())
                                          ).format("YYYY-MM-DD")
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </Stack>
                                </LocalizationProvider>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <label className="mb-2">Status</label>
                              <Select
                                placeholder="Select Status ..."
                                styles={reactSelectCusStyles}
                                isMulti={false}
                                options={[
                                  { value: "all", label: "All" },
                                  { value: "1", label: "Open" },
                                  { value: "0", label: "Close" },
                                ]}
                                onChange={(e: any) => {
                                  this.handleComplaintStatus(e.value);
                                }}
                              />
                            </div>
                          </div>
                        </Offcanvas.Body>
                      </Offcanvas>

                      {/* </div> */}

                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div className="card">
                            <div className="card-body">
                              {this.props.auditReportData && this.props.auditReportData.length > 0 ? (
                                <div className="table-responsive">
                                  <table className="table employee-table">
                                    <thead>
                                      <tr>
                                      <th>NAME</th>
                                <th>CLIENT</th>
                                <th>BRANCH</th>
                                <th>NO. OF AREAS</th>
                                <th>NO. OF TASKS</th>
                                <th>NO. OF COMMENTS</th>
                                <th>SCORE</th>
                                      </tr>
                                    </thead>
                                    <AuditTable
                                      data={this.props.auditReportData}
                                      onDelete={
                                       ()=>{}
                                      }
                                      onEditClose={
                                    ()=>{}
                                      }
                                      deleteAuditStatus={false}
                                      reqPage={"report"}
                                    />
                                  </table>
                                </div>
                              ) : (
                                <p className="text-center">
                                  There is no task data found
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        {this.props.auditReportCount > 10 ? (
                          <div className="col-md-12">
                            {/* {this.state.pagehandle ? null : ( */}
                            <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={
                                this.props.auditReportCount /
                                Constants.PER_PAGE
                              }
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={2}
                              onPageChange={this.handlePageClick_audit}
                              containerClassName={"pagination"}
                              nextClassName={"next-page"}
                              activeClassName={"active"}
                            />
                            {/* )} */}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) :
                  (
                    "To Select any Option to Export here ..."
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  status: state.sidebar.status,
  filtered_ReportData: state.allreport.filtered_ReportData,
  filterReport_FromDate: state.allreport.filterReport_FromDate,
  filterReport_ToDate: state.allreport.filterReport_ToDate,
  branchList: state.tasktemplate.branchList,
  total_count: state.allreport.total_count,
  total_task_count: state.allreport.total_task_count,
  filtered_DailyTaskData: state.allreport.filtered_DailyTaskData,
  filter_TaskClientById: state.allreport.filter_TaskClientById,
  filter_TaskBranchById: state.allreport.filter_TaskBranchById,
  filter_TaskTypeById: state.allreport.filter_TaskTypeById,
  filter_TaskStatusById: state.allreport.filter_TaskStatusById,
  filter_SpecialRequestTaskStatusById:
    state.allreport.filter_SpecialRequestTaskStatusById,
  filter_TaskDateTypeValue: state.allreport.filter_TaskDateTypeValue,
  filtered_DailyTaskAllData: state.allreport.filtered_DailyTaskAllData,
  client_List: state.allreport.client_List,
  branch_List: state.allreport.branch_List,
  filter_TaskDueTypeByValue: state.allreport.filter_TaskDueTypeByValue,
  filtered_ComplaintData: state.allreport.filtered_ComplaintData,
  selected_ComplaintData: state.allreport.selected_ComplaintData,
  complaint_SortBy: state.allreport.complaint_SortBy,
  complaint_OrderBy: state.allreport.complaint_OrderBy,
  total_complaint_count: state.allreport.total_complaint_count,
  filterReportToDate: state.report.filterReportToDate,
  clientList: state.tasktemplate.clientList,
  filterReportFromDate: state.report.filterReportFromDate,
  auditReportData: state.audit.auditReportData,
  auditReportCount: state.audit.total_count
});
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
    get_AllReports: bindActionCreators(get_AllReports, dispatch),
    handleFilterReport_FromDate: bindActionCreators(
      handleFilterReport_FromDate,
      dispatch
    ),
    handleFilterReport_ToDate: bindActionCreators(
      handleFilterReport_ToDate,
      dispatch
    ),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
    getAll_DailyTasks: bindActionCreators(getAll_DailyTasks, dispatch),
    getAllList_DailyTasks: bindActionCreators(getAllList_DailyTasks, dispatch),
    handle_FilterTaskClient: bindActionCreators(
      handle_FilterTaskClient,
      dispatch
    ),
    handle_FilterTaskBranch: bindActionCreators(
      handle_FilterTaskBranch,
      dispatch
    ),
    handle_FiltercomplaintBranch: bindActionCreators(
      handle_FiltercomplaintBranch,
      dispatch
    ),
    handle_FilterTaskType: bindActionCreators(handle_FilterTaskType, dispatch),
    handle_FilterTaskStatus: bindActionCreators(
      handle_FilterTaskStatus,
      dispatch
    ),
    handle_FilterSpecialTaskStatus: bindActionCreators(
      handle_FilterSpecialTaskStatus,
      dispatch
    ),
    handle_FilterTaskDate: bindActionCreators(handle_FilterTaskDate, dispatch),
    list_AllClient: bindActionCreators(list_AllClient, dispatch),
    list_AllBranch: bindActionCreators(list_AllBranch, dispatch),
    handle_FilterTaskDueType: bindActionCreators(
      handle_FilterTaskDueType,
      dispatch
    ),
    getAll_Complaints: bindActionCreators(getAll_Complaints, dispatch),
    getComplaint_ById: bindActionCreators(getComplaint_ById, dispatch),
    handle_ComplaintSort: bindActionCreators(handle_ComplaintSort, dispatch),
    handle_FilterComplaintClient: bindActionCreators(
      handle_FilterComplaintClient,
      dispatch
    ),
    getAllReports: bindActionCreators(getAllReports, dispatch),
    ListComplaint_ById: bindActionCreators(ListComplaint_ById, dispatch),
    handleFilterReportToDate: bindActionCreators(
      handleFilterReportToDate,
      dispatch
    ),
    handleFilterReportFromDate: bindActionCreators(
      handleFilterReportFromDate,
      dispatch
    ),
    listAllClient: bindActionCreators(listAllClient, dispatch),
    listAllAuditReport: bindActionCreators(getAllAuditReports, dispatch)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AllReport);
