import * as Yup from "yup";

import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import {
  getLoginUserDetails,
  updateProfile,
  updateProfileEdit,
} from "../../shared/Reducers/index";

import Constants from "../../shared/Constants";
import PhoneInput from "react-phone-input-2";
import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";

type Props = {
  userLoginDetails: any;
  updateEditEmployeeDlgStatus: any;
  updatePassword: any;
  updateProfileEdit: any;
  updateProfileEditState: any;
  updateWrongProfileEdit: any;
  getLoginUserDetails: any;
};

type State = {};

class EditUser extends React.Component<Props, State> {
  handleEditAdmin = async (
    formValue: {
      first_name: string;
      last_name: string;
      email: string;
      phone: any;
    },
    { resetForm }: { resetForm: any }
  ) => {
    console.log(formValue);
    const { first_name, last_name, email, phone } = formValue;

    this.setState({
      message: "",
    });
    try {
      let update = await this.props.updateProfileEdit({
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: phone,
      });
      this.props.getLoginUserDetails(Constants.LOGIN_USER_ID);
      if (this.props.updateProfileEditState.status === 200) {
        formValue.first_name = "";
        formValue.last_name = "";
        formValue.email = "";
        formValue.phone = "";
        this.setState(
          {
            message: "Your password is changed successfully",
            messageClassName: "success-alert",
          },
          () => {
            setTimeout(() => {
              this.setState({ show: false, editShow: false, message: "" });
            }, 500);
          }
        );
      }
      console.log(this.props.updateWrongProfileEdit);
      if (this.props.updateWrongProfileEdit.error.response.status === 406) {
        formValue.first_name = "";
        formValue.last_name = "";
        formValue.email = "";
        formValue.phone = "";
        this.setState({
          message:
            this.props.updateWrongProfileEdit.error.response.data.message,
          messageClassName: "cus-alert",
        });
      }
    } catch (error) {
      formValue.first_name = "";
      formValue.last_name = "";
      formValue.email = "";
      formValue.phone = "";

      console.log(error);
    }
  };
  validationSchema = () => {
    return Yup.object().shape({
      first_name: Yup.string()
        .required("Enter a valid First Name")
        .test(
          "len",
          "First Name must be between 3 to 10 characters",
          (val: any) => val && val.toString().length >= 3 && val.toString().length <= 10
        ),
      last_name: Yup.string()
        .required("Enter a valid Last Name")
        .test(
          "len",
          "Last Name must be between 3 to 10 characters",
          (val: any) => val && val.toString().length >= 3 && val.toString().length <= 10
        ),
      email: Yup.string()
        .email("Invalid Email Format")
        .required("Enter valid Email address"),
      phone: Yup.string().required("Enter Phone Number"),
    });
  };
  render(): React.ReactNode {
    return (
      <div>
        <Formik
          initialValues={{
            first_name: this.props.userLoginDetails
              ? this.props.userLoginDetails.first_name
              : "",
            last_name: this.props.userLoginDetails
              ? this.props.userLoginDetails.last_name
              : "",
            email: this.props.userLoginDetails
              ? this.props.userLoginDetails.email
              : "",
            phone: this.props.userLoginDetails
              ? this.props.userLoginDetails.phone
              : "",
          }}
          onSubmit={this.handleEditAdmin}
          validationSchema={this.validationSchema}
        >
          {({ handleSubmit }) => (
            <Form>
              <div className="mb-2">
                <label className="form-label">
                  First name<span className="text-project">*</span>
                </label>
                <Field
                  name="first_name"
                  type={"text"}
                  className="form-control"
                />
                <ErrorMessage
                  name="first_name"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="mb-2">
                <label className="form-label">Last name</label>
                <Field
                  name="last_name"
                  type={"text"}
                  className="form-control employee-input"
                />
                <ErrorMessage
                  name="last_name"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="mb-2">
                <label className="form-label">
                  Email<span className="text-project">*</span>
                </label>
                <Field
                  name="email"
                  type={"text"}
                  className="form-control employee-input"
                />
                <ErrorMessage
                  name="email"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="mb-2">
                <label className="form-label">Phone</label>
                <Field name="phone">
                  {({ field, form, meta }: FieldProps<any>) => (
                    <div>
                      <PhoneInput
                        {...field}
                        inputProps={{ name: "phone" }}
                        specialLabel={""}
                        onChange={(value, data, e) => field.onChange(e)}
                        onBlur={field.onBlur}
                        value={field.value}
                        country={"in"}
                        inputStyle={{
                          paddingTop: 15,
                          paddingRight: 15,
                          paddingBottom: 15,
                          paddingLeft: 57,
                          width: "100%",
                          background: "#FFFFFF",
                          border: "1px solid #F0F0F0",
                          borderRadius: "8px",
                        }}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
              </div>
              <div className="row mt-4">
                <div className=" mb-3 d-flex">
                  <div className="d-flex justify-content-between align-items-center mr-16px">
                    <button
                      type="submit"
                      id="create-button"
                      className="btn btn-primary create-button"
                      onClick={(e: any) => {
                        handleSubmit(e);
                        this.props.updateEditEmployeeDlgStatus(false);
                      }}
                    >
                      Save{" "}
                      <span
                        id="spinner-border"
                        className="spinner-border spinner-border-sm d-none"
                      ></span>
                    </button>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <button
                      type="button"
                      className="btn cancel-button"
                      onClick={() =>
                        this.props.updateEditEmployeeDlgStatus(false)
                      }
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  subAdmin: state.app.subAdmin,
  userLoginDetails: state.sidebar.userLoginDetails,
  updateProfileEditState: state.usermanagement.updateProfileEditState,
  updateWrongProfileEdit: state.usermanagement.updateWrongProfileEdit,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updatePassword: bindActionCreators(updateProfile, dispatch),
    updateProfileEdit: bindActionCreators(updateProfileEdit, dispatch),
    getLoginUserDetails: bindActionCreators(getLoginUserDetails, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
