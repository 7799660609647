import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  DisableSVG,
  EnableSVG,
  TableDeleteSVG,
  TableEditSVG,
  TableViewSVG,
} from "../../assets/images/ImagesV2";
import { Dispatch, bindActionCreators } from "redux";
import { ITaskCardProps, ITaskCardState } from "../../types/task.type";

import DeleteTask from "./DeleteTask";
import React from "react";
import { RootState } from "../../index";
import ViewTask from "./ViewTask";
import { connect } from "react-redux";
import { updateEditTaskDlgStatus } from "../../shared/Reducers";

class TaskCard extends React.Component<ITaskCardProps, ITaskCardState> {
  constructor(props: ITaskCardProps) {
    super(props);
    this.state = {
      show: false,
      displayDelete: false,
      currentTaskId: "",
      editShow: false,
      displayView: false,
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  // Delete task based on ID
  // handleDelete = (id: string) => {
  //     this.setState({ displayDelete: true, currentTaskId: id })
  // }
  handleDelete = (id: string) => {
    this.setState({ currentTaskId: id, displayDelete: true }, () => {});
  };

  // delete popup close
  handleDeleteClose = (status: boolean) => {
    this.setState({ displayDelete: false, currentTaskId: "" });
    if (status) {
      this.props.handleDeleteTask(this.state.currentTaskId);
    }
  };

  handleView = (id: string) => {
    this.setState({ displayView: true, currentTaskId: id });
  };

  handleViewClose = (status: boolean) => {
    this.setState({ displayView: false });
  };

  render(): React.ReactNode {
    const { id, title, status, first_name, last_name, branch_name } =
      this.props.task;
    return (
      <tr>
        {/* <td>T{id}</td> */}
        <td>{title}</td>
        <td>{`${first_name} ${last_name}`}</td>
        <td>{branch_name}</td>
        <td>{status === 1 ? <EnableSVG /> : <DisableSVG />}</td>
        <td>
          <div className="btn-group">
            <span className="mr-20px">
              <a href="javascript:void(0)" onClick={() => this.handleView(id)}>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"View"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableViewSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
            <span className="mr-20px">
              <a
                href="javascript:void(0)"
                onClick={() => this.props.handleEditTask(id)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"Edit"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableEditSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
            <span>
              <a
                href="javascript:void(0)"
                onClick={() => this.handleDelete(id)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"Delete"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableDeleteSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
            <ViewTask
              displayView={this.state.displayView}
              handleViewClose={this.handleViewClose}
              data={this.props.task}
              id={this.props.id}
            />
            <DeleteTask
              displayDelete={this.state.displayDelete}
              deletedName={
                title +
                "? If you deleted then the related  schedule would be deleted"
              }
              handleDeleteClose={(status) => this.handleDeleteClose(status)}
            />
          </div>
        </td>
      </tr>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  editTaskStatus: state.tasktemplate.editTaskStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateEditTaskDlgStatus: bindActionCreators(
      updateEditTaskDlgStatus,
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TaskCard);
