import * as Yup from "yup";
import SideBar from "../../components/sidebar/SideBar";
import TopNav from "../../components/topnav/TopNav";
import { AlertToastSVG, LogoSVG } from "../../assets/images/ImagesV2";
import { Dispatch, bindActionCreators } from "redux";
import { EyeOffSVG, EyeSVG } from "../../assets/images/EmployeesSVG";
import { ISigninProps, ISigninState } from "../../types/user.type";
import { settingslists, settingsUpdated,updateSidebar } from "../../shared/Reducers";
import { Form, Navbar } from "react-bootstrap";

import { CloseBtnSVG } from "../../assets/images/Location";
import { DrawerSVG } from "../../assets/images/SigninSVG";
import { Navigate } from "react-router-dom";
import React from "react";
import { RootState } from "../../index";
import { SuccessToastSVG } from "../../assets/images/ImagesV2";
import Toast from "react-bootstrap/Toast";
import { connect } from "react-redux";
export interface ICleanerInOutProps {
  settingslists: any;
  settingList: any;
  settingsUpdated: any;
  settingUpdated: any;
  status: any;
  updateSidebar:any;
}
export interface ICleanerInOutState {
  sign_in_km: any;
  addToast: boolean;
}

class Settings extends React.Component<ICleanerInOutProps, ICleanerInOutState> {
  constructor(props: ICleanerInOutProps) {
    super(props);
    // Login Functionality handling
    // State Declaration
    this.state = {
      sign_in_km: [],
      addToast: false,
    };
  }

  // User authentication
  async componentDidMount() {
    this.props.updateSidebar(true);
    document.title = "settings";
    await this.props.settingslists();

    console.log(this.props.settingList, "settingList");
    this.setState({ sign_in_km: this.props.settingList });
    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.add("d-none");
      button.removeAttribute("disabled");
    }
  }
  handleUpdated: any = (data: any, meta_key: any) => {
    const updatedSignInKm = this.state.sign_in_km.map((i: any) => {
      if (i.meta_key === meta_key) {
        i.meta_value = data.target.value;
        return i;
      } else {
        return i;
      }
    }
    );

    this.setState({ sign_in_km: updatedSignInKm });
  };
  submit: any = async () => {
    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.remove("d-none");
      button.setAttribute("disabled", "");
    }
    console.log("working");

    await this.props.settingsUpdated(this.state.sign_in_km);
    this.setState({ addToast: true });
    setTimeout(() => {
      this.setState({ addToast: false });
    }, 3000);
    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.add("d-none");
      button.removeAttribute("disabled");
    }
  };
  addToastClose = () => {
    this.setState({ addToast: false });
  };

  render(): React.ReactNode {
    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className={this.props.status ? "main-panel" : "main-panel2"}>
          <TopNav />
          <div className="content-wrapper">
            <div className="container-fluid p-0">
              <div className="col-md-6">
                <h3 className="mb-0">Settings</h3>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <Form>
                        <div className="row">
                          {this.state.sign_in_km.map((val: any, index: any) => (
                            <div className="col-md-6">
                              <div className="mb-4 row">
                                <label className="col-sm-6 col-form-label">
                                  {val.meta_label}
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={val.meta_value}
                                    onChange={(event) =>
                                      this.handleUpdated(event, val.meta_key)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className="row mt-3 mb-3">
                          <div className="col-md-12">
                            <div className="d-flex">
                              <div className="mr-16px">
                                <button
                                  id="create-button"
                                  type="button"
                                  onClick={this.submit}
                                  className="btn btn-primary create-button"
                                >
                                  Update
                                  <span
                                    id="spinner-border"
                                    className="spinner-border spinner-border-sm d-none"
                                  ></span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
                {this.state.addToast ? (
                  <Toast className="success-alerts">
                    <Toast.Body>
                      <div className="row align-items-center">
                        <div className="col-md-2">
                          <SuccessToastSVG />
                        </div>
                        <div className="col-md-8">
                          {this.props.settingUpdated &&
                            this.props.settingUpdated.message}
                        </div>
                        <div className="col-md-2">
                          <button
                            type="button"
                            className="btn"
                            onClick={this.addToastClose}
                          >
                            <CloseBtnSVG />
                          </button>
                        </div>
                      </div>
                    </Toast.Body>
                  </Toast>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  settingList: state.tasktemplate.settingList,
  settingUpdated: state.tasktemplate.settingUpdated,
  status: state.sidebar.status,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
    settingslists: bindActionCreators(settingslists, dispatch),
    settingsUpdated: bindActionCreators(settingsUpdated, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
