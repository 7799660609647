import * as Yup from "yup";

import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { IBranchSelect, ICleaner } from "../../shared/ActionTypes";
import { Modal, ProgressBar } from "react-bootstrap";
import { PaperClipSVG, TrashSVG } from "../../assets/images/ImagesV2";
import { addTask, listAllBranch, listAllClient } from "../../shared/Reducers";
import dayjs, { Dayjs } from "dayjs";

import AttachmentPreview from "../../shared/Components/AttachmentPreview";
import Button from "@material-ui/core/Button";
import React from "react";
import { RootState } from "../../index";
import Select from "react-select";
import { connect } from "react-redux";
import docImage from "../../assets/images/google-docs.png";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";

type Props = {
  onClose: (status: boolean) => void;
  clientList?: any;
  listAllClient?: any;
  addTaskError?: any;
  addTask?: any;
  branchList?: any;
  listAllBranch?: any;
  processTrigger: any;
  progressPercentage: any;
  addTaskSuccess: any;
};

type TAddTaskState = {
  attachmentFiles: any | Blob[];
  attachmentUrls: any[];
  endDatevalue: any;
  startDatevalue: any;
  AttachmentPreviewStatus: boolean;
  previewUrl: string;
  previewType: string;
  files_size: any;
  client_id: any;
  branch_id: any;
  clientErrorShow: any;
  branchErrorShow: any;
};

const reactSelectCusStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#fff",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    },
  }),
};
class AddTask extends React.Component<Props, TAddTaskState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      attachmentFiles: [],
      attachmentUrls: [],
      endDatevalue: "",
      startDatevalue: dayjs(),
      AttachmentPreviewStatus: false,
      previewUrl: "",
      previewType: "",
      files_size: 0,
      client_id: "",
      branch_id: "",
      clientErrorShow: false,
      branchErrorShow: false,
    };
  }

  componentDidMount(): void {
    this.props.listAllClient();

    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.add("d-none");
      button.removeAttribute("disabled");
    }
  }

  handleTaskClient = async (id: number) => {
    this.setState({ client_id: id });
    await this.props.listAllBranch(id);
    this.setState({ clientErrorShow: false });
  };

  handleTaskBranch = (id: number) => {
    this.setState({ branch_id: id });
    this.setState({ branchErrorShow: false });
  };

  handleAddTask = async (formValues: any) => {
    if (this.state.client_id === "" || null || undefined) {
      return false;
    }
    if (this.state.branch_id === "" || null || undefined) {
      return false;
    }
    let data = new FormData();
    data.append("client", this.state.client_id);
    data.append("branch", this.state.branch_id);
    data.append("title", formValues.title);
    data.append("status", formValues.status);
    for (let index = 0; index < this.state.attachmentFiles.length; index++) {
      data.append("attachments", this.state.attachmentFiles[index]);
    }
    data.append("description", formValues.description);

    // spinner
    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.remove("d-none");
      button.setAttribute("disabled", "");
    }
    await this.props.addTask(data);
    setTimeout(() => {
      this.props.onClose(false);
    }, 1000);
  };

  handleImageChange = async (e: any) => {
    const file = e.target.files[0];
    this.setState(
      { files_size: this.state.files_size + file.size },
      async () => {
        if (this.state.files_size > 524288000) {
          alert("File Size Must Be Less Than 500MB");
          e.target.value = null;
          return this.setState({
            files_size: this.state.files_size - file.size,
          });
        }

        let files = [];
        let urls = [];
        files = [...e.target.files];
        let fileurls: any = [];
        await files.map(async (file: any) => {
          fileurls.push({
            url: URL.createObjectURL(file),
            file_type: file.type,
          });
        });
        this.setState(
          {
            attachmentFiles: [...this.state.attachmentFiles, ...files],
          },
          () => {
            urls = [...fileurls];
            this.setState(
              { attachmentUrls: [...this.state.attachmentUrls, ...urls] },
              () => {
                e.target.value = "";
              }
            );
          }
        );
      }
    );
  };
  handlePreviewClose = () => {
    this.setState({ AttachmentPreviewStatus: false });
  };
  handlePreviewOpen = (file_type: any, url: any) => {
    if (
      file_type === "image/png" ||
      file_type === "image/jpeg" ||
      file_type === "image/jpg" ||
      file_type === "video/mp4" ||
      file_type === "video/webm" ||
      file_type === "application/pdf"
    ) {
      this.setState({
        AttachmentPreviewStatus: true,
        previewUrl: url,
        previewType: file_type,
      });
    }
  };

  handleDeleteImage = (index: number) => {
    const files = this.state.attachmentFiles;
    const urls = this.state.attachmentUrls;
    files.splice(index, 1);
    urls.splice(index, 1);
    this.setState({ attachmentFiles: files }, () => {
      this.setState({ attachmentUrls: urls });
    });
  };

  validationSchema = () => {
    if (this.state.client_id === "" || null || undefined) {
      this.setState({ clientErrorShow: true });
    } else {
      this.setState({ clientErrorShow: false });
    }
    if (this.state.branch_id === "" || null || undefined) {
      this.setState({ branchErrorShow: true });
    } else {
      this.setState({ branchErrorShow: false });
    }
    return Yup.object().shape({
      title: Yup.string().required("Enter Task Name"),
      description: Yup.string().required("Enter Task Description"),
      status: Yup.string(),
      attachment: Yup.mixed(),
    });
  };

  handleChange = (e: any) => {
    if (e.target.name === "client") {
      if (e.target.value !== "") {
        this.props.listAllBranch(e.target.value);
      }
    }
  };

  render(): React.ReactNode {
    const initialValues = {
      title: "",
      description: "",
      status: 1,
      attachments: "",
    };
    return (
      <div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={this.handleAddTask}
          validationSchema={this.validationSchema}
        >
          {(props) => {
            const { values } = props;
            return (
              <Form onChange={this.handleChange}>
                <div className="mb-2">
                  <label className="form-label">
                    Task Name<span className="text-project">*</span>
                  </label>
                  <Field
                    name="title"
                    type={"text"}
                    className="form-control text-field"
                  />
                  <ErrorMessage
                    name="title"
                    component={"div"}
                    className="cus-alert"
                  />
                </div>
                <div className="mb-2">
                  <label className="form-label">
                    Task Description<span className="text-project">*</span>
                  </label>
                  <Field
                    as="textarea"
                    name="description"
                    className="form-control text-area"
                  />
                  <ErrorMessage
                    name="description"
                    component={"div"}
                    className="cus-alert"
                  />
                </div>
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <label className="form-label">Client</label>
                    <Select
                      styles={reactSelectCusStyles}
                      placeholder="Select Client"
                      isMulti={false}
                      options={this.props.clientList.map((client: any) => ({
                        value: client.id,
                        label: client.first_name + " " + client.last_name,
                      }))}
                      onChange={(e: any) => {
                        return this.handleTaskClient(e.value);
                      }}
                    />
                    {this.state.clientErrorShow === true && (
                      <p className="text-danger"> Please Select the Client </p>
                    )}
                  </div>
                  {/* branchList */}
                  <div className="col-md-6 mb-2">
                    <label className="form-label">Branch</label>
                    <Select
                      styles={reactSelectCusStyles}
                      placeholder="Select the Branch"
                      isMulti={false}
                      options={this.props.branchList.map((branch: any) => ({
                        value: branch.value,
                        label: branch.label,
                      }))}
                      onChange={(e: any) => {
                        return this.handleTaskBranch(e.value);
                      }}
                    />
                    {this.state.branchErrorShow === true && (
                      <p className="text-danger"> Please Select the Branch</p>
                    )}
                  </div>
                  <div className="col-md-6 mb-2">
                    <label className="form-label">Is Active</label>
                    <Field
                      as="select"
                      className="form-select dropdown-field"
                      name="status"
                    >
                      <option value={1}>Enable</option>
                      <option value={0}>Disable</option>
                    </Field>
                    <ErrorMessage
                      name="status"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <label className="form-label">Attachment</label>
                    <div className="attachment-btn dropdown-field">
                      <input
                        type="file"
                        multiple
                        accept=".pdf,.PDF,.mp4,.MP4,.webm,.WEBM,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.doc,.DOC,.docx,.DOCX"
                        style={{ display: "none" }}
                        onChange={this.handleImageChange}
                        id="contained-button-file"
                      />
                      <label htmlFor="contained-button-file">
                        <Button
                          variant="contained"
                          color="default"
                          component="span"
                        >
                          <PaperClipSVG /> Add attachment
                        </Button>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {this.state.attachmentUrls.length > 0 ? (
                    <div className="col-md-12 mb-2">
                      <label className="form-label">Attachments</label>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex row">
                        {this.state.attachmentUrls &&
                          this.state.attachmentUrls.map(
                            (url: any, index: number) => (
                              <div className="img-card mr-16px mb-3">
                                <span className="delete">
                                  <a
                                    onClick={() =>
                                      this.handleDeleteImage(index)
                                    }
                                  >
                                    <TrashSVG />
                                  </a>
                                </span>
                                <div
                                  className="img-preview"
                                  onClick={() =>
                                    this.handlePreviewOpen(
                                      url.file_type,
                                      url.url
                                    )
                                  }
                                >
                                  <img
                                    className="img-thumb-perview"
                                    src={
                                      url.file_type === "application/pdf"
                                        ? pdfImage
                                        : url.file_type === "video/mp4" ||
                                          url.file_type === "video/webm"
                                        ? videoImage
                                        : url.file_type === "image/png" ||
                                          url.file_type === "image/jpeg" ||
                                          url.file_type === "image/jpg"
                                        ? url.url
                                        : docImage
                                    }
                                  />
                                </div>
                              </div>
                            )
                          )}
                        <AttachmentPreview
                          displayPreview={this.state.AttachmentPreviewStatus}
                          previewUrl={this.state.previewUrl}
                          previewType={this.state.previewType}
                          handlePreviewClose={this.handlePreviewClose}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3 mb-3">
                    <div className="col-md-12 cus-progress">
                      <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={this.props.processTrigger}
                      >
                        <Modal.Body className="p-0">
                          <ProgressBar
                            now={this.props.progressPercentage}
                            label={
                              this.props.progressPercentage === 100
                                ? `Processing your request...`
                                : `Task Adding....${this.props.progressPercentage}%`
                            }
                          />
                        </Modal.Body>
                      </Modal>
                      <div className="d-flex">
                        <div className="mr-16px">
                          <button
                            type="submit"
                            id="create-button"
                            className="btn btn-primary create-button"
                          >
                            Create{" "}
                            <span
                              id="spinner-border"
                              className="spinner-border spinner-border-sm d-none"
                            ></span>
                          </button>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn cancel-button"
                            onClick={() => this.props.onClose(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  clientList: state.tasktemplate.clientList,
  branchList: state.tasktemplate.branchList,
  processTrigger: state.tasktemplate.processTrigger,
  progressPercentage: state.tasktemplate.progressPercentage,
  addTaskSuccess: state.tasktemplate.addTaskSuccess,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    listAllClient: bindActionCreators(listAllClient, dispatch),
    addTask: bindActionCreators(addTask, dispatch),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTask);
