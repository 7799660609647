import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Dispatch, bindActionCreators } from "redux";
import { IAttendanceCardProps, IAttendanceCardState } from "../../types/attendance.type";
import { TableDeleteSVG, TableEditSVG } from "../../assets/images/ImagesV2";

import { ATTENDENCE_STATUS } from "../../shared/Constants";
import { CloseBtnSVG } from "../../assets/images/ImagesV2";
import DeleteAttendance from "./DeleteAttendance";
import React from "react";
import { RootState } from '../../index';
import Select from "react-select";
import { UpdateBtnSVG } from "../../assets/images/ImagesV2";
import { connect } from "react-redux";
import { editAttendance } from "../../shared/Reducers";
import moment from "moment";

const reactSelectCusStyles = {
    control: (base: any, state: any) => ({
      ...base,
      background: "#fff",
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
      },
    }),
  };
class AttendanceCard extends React.Component<IAttendanceCardProps, IAttendanceCardState> {
    constructor(props: IAttendanceCardProps) {
        super(props);
        this.state = {
            show: false,
            displayDelete: false,
            currentAttendanceId: "",
            editShow: false,
            changeAttendanceStatus : 0,
        }
    }

    componentDidUpdate(prevProps: Readonly<IAttendanceCardProps>, prevState: Readonly<IAttendanceCardState>, snapshot?: any): void {
        if (prevProps.attendanceFetchStatus !== this.props.attendanceFetchStatus) {
            if (this.props.attendanceFetchStatus) {
                this.setState({ changeAttendanceStatus : 0 })

                var allAttendanceText = document.getElementsByClassName("AttendanceText");
                var allAttendanceInput = document.getElementsByClassName("AttendanceInput");
                if (allAttendanceInput !== null && allAttendanceText !== null) {
                    for (let i = 0; i < allAttendanceText.length; i++) {
                        allAttendanceText[i].classList.remove("d-none");
                    }
                    for (let i = 0; i < allAttendanceInput.length; i++) {
                        allAttendanceInput[i].classList.add("d-none");
                    }
                }
            }
        }
    }

    handleClose = () => {
        this.setState({ show: false })
    }

    handleShow = () => {
        this.setState({ show: true })
    }

    // Delete attendance based on ID
    handleDelete = (id: string) => {
        this.setState({ displayDelete: true, currentAttendanceId: id })
    }

    // delete popup close
    handleDeleteClose = (status: boolean) => {
        this.setState({ displayDelete: false, currentAttendanceId: "" })
        if (status) {
            this.props.handleDeleteAttendance(this.state.currentAttendanceId)
        }
    }

    attendanceStatusChange = (status:number,id: string) => {
        this.setState({ changeAttendanceStatus : status })

        var allAttendanceText = document.getElementsByClassName("AttendanceText");
        var allAttendanceInput = document.getElementsByClassName("AttendanceInput");
        if (allAttendanceInput !== null && allAttendanceText !== null) {
            for (let i = 0; i < allAttendanceText.length; i++) {
                allAttendanceText[i].classList.remove("d-none");
            }
            for (let i = 0; i < allAttendanceInput.length; i++) {
                allAttendanceInput[i].classList.add("d-none");
            }
        }

        var AttendanceText = document.getElementById("AttendanceText-"+id);
        var AttendanceInput = document.getElementById("AttendanceInput-"+id);
        if (AttendanceInput !== null && AttendanceText !== null) {
            AttendanceText.classList.add("d-none");
            AttendanceInput.classList.remove("d-none");
        }
    }
 
    closeattendanceStatusUpdate = () => {
        this.setState({ changeAttendanceStatus : 0 })

        var allAttendanceText = document.getElementsByClassName("AttendanceText");
        var allAttendanceInput = document.getElementsByClassName("AttendanceInput");
        if (allAttendanceInput !== null && allAttendanceText !== null) {
            for (let i = 0; i < allAttendanceText.length; i++) {
                allAttendanceText[i].classList.remove("d-none");
            }
            for (let i = 0; i < allAttendanceInput.length; i++) {
                allAttendanceInput[i].classList.add("d-none");
            }
        }
    }

    attendanceStatusUpdate = (id : string) => {
        let data = {
            attendence_status : this.state.changeAttendanceStatus
        }
        this.props.editAttendance(data, id);
    }

    render(): React.ReactNode {
        const { id, shift_name, shift_date, first_name, last_name, attendence_status, start_time, end_time } = this.props.attendances;
        return (
            <tr>
                <td>{ shift_name }</td>
                <td>{ first_name } { last_name }</td>
                <td>{ moment(new Date(shift_date?.toString())).format("DD/MM/YYYY") }</td>
                <td>{ moment(start_time, "HH:mm:ss").format("hh:mm A") }</td>
                <td>{ moment(end_time, "HH:mm:ss").format("hh:mm A") }</td>
                <td style={{ width: "250px" }}>
                    <p onClick={() => this.attendanceStatusChange(attendence_status,"EditAttendanceStatus-"+id)} id={"AttendanceText-EditAttendanceStatus-"+id} className="m-0 AttendanceText">
                        {
                            attendence_status === ATTENDENCE_STATUS.APPROVED ? "Approved" :
                            attendence_status === ATTENDENCE_STATUS.PENDING ? "Pending" : "Leave"
                        }
                    </p>
                    <div id={"AttendanceInput-EditAttendanceStatus-"+id} className="d-flex align-items-center d-none AttendanceInput">
                        <div className="mr-6px">
                            <Select
                                placeholder="Change Attendance Status"
                                styles={reactSelectCusStyles}
                                isMulti={false}
                                value = {{ value : this.state.changeAttendanceStatus, label : this.state.changeAttendanceStatus === ATTENDENCE_STATUS.APPROVED ? "Approved" : this.state.changeAttendanceStatus === ATTENDENCE_STATUS.PENDING ? "Pending" : "Leave" }}
                                options={[
                                { value: ATTENDENCE_STATUS.PENDING, label: "Pending" },
                                { value: ATTENDENCE_STATUS.APPROVED, label: "Approved" },
                                { value: ATTENDENCE_STATUS.LEAVE, label: "Leave" },
                                ]}
                                onChange={(e: any) =>
                                    this.setState({ changeAttendanceStatus : e.value })
                                }
                            />
                        </div>
                        <div className="mr-6px">
                            <button className="btn btn-primary update-btn-svg" onClick={() => this.attendanceStatusUpdate(id)}><UpdateBtnSVG/></button>
                        </div>
                        <div className="mr-16px">
                            <button className="btn btn-primary close-btn-svg" onClick={() => this.closeattendanceStatusUpdate()}><CloseBtnSVG/></button>
                        </div>
                    </div>
                    
                </td>
                <td>
                    <div className="btn-group">
                        <span>
                            <a href="javascript:void(0)" onClick={() => this.handleDelete(id)}>
                                <OverlayTrigger placement="top"
                                    overlay={<Tooltip>{"Delete"}</Tooltip>}>
                                   <Button className="action-btn"><TableDeleteSVG /></Button>
                                </OverlayTrigger>
                            </a>
                        </span>
                        <DeleteAttendance displayDelete={this.state.displayDelete} deletedName={first_name+" "+last_name+" - Shift : "+shift_name} handleDeleteClose={this.handleDeleteClose} />
                    </div>
                </td>
            </tr>
        )
    }
}
const mapStateToProps = (state: RootState) => ({
    editAttendanceStatus: state.attendance.editAttendanceStatus,
    attendanceFetchStatus: state.attendance.attendanceFetchStatus
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        editAttendance: bindActionCreators(editAttendance, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AttendanceCard);