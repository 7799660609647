import React, { Component } from "react";
import ApprovedCard from "./ApprovedCard";
import { RootState } from "../../index";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { Offcanvas } from "react-bootstrap";
import {
  ApprovedTaskTableProps,
  ApprovedTaskTableState,
} from "../../types/approvedtask";
import SendMail from "./SendMail";
import EditApprovedTask from "../ApprovedTask/EditApprovedTask";
import {
  getDailyTaskByIdForDuplicate,
  updateSendQAEmailDailyTaskDlgStatus,
  getSendQAEmailDailyTaskById,
  updateEditDailyTaskDlgStatus,
  getDailyTaskById,
  deleteDailyTask,
  getDailyTaskByIdForView,
  getAdditionalTaskById,
  SendMailModalApprovedTask,
} from "../../shared/Reducers";
import { log } from "console";

class ApprovedTable extends React.Component<
  ApprovedTaskTableProps,
  ApprovedTaskTableState
> {
  constructor(props: ApprovedTaskTableProps) {
    super(props);
    this.state = {
      editModel: false,
      mailModal: false,
    };
  }

  handleSendQAEmailDailyTask = async (id: string) => {
    try {
      this.props.SendMailModalApprovedTask(true);
      this.props.getAdditionalTaskById(id);
    } catch (error) {
      console.log(error);
    }
  };

  handleEditDailyTask = async (id: string) => {
    try {
      await this.props.getAdditionalTaskById(id);
      this.setState({ editModel: true });
    } catch (error) {
      console.error("Error occurred while getting additional task:", error);
    }
  };

  handleEditModelClose = () => {
    this.setState({ editModel: false });
  };

  handleDeleteDailyTask = async (id: string) => {
    await this.props.deleteDailyTask(id);

    let Offset: number = 0;
    let paginationActive = document.querySelector(
      ".pagination li.active a"
    ) as HTMLInputElement;
    if (paginationActive !== null) {
      let page: string | null = paginationActive.textContent;
      if (page !== null) {
        let pageNum: number = parseInt(page);
        console.log(pageNum);
        // Offset = (pageNum - 1) * Constants.PER_PAGE;
        console.log(Offset);
        // setTimeout(() => {
        //   try {
        //     const query = `search=&sort=&order=&offset=${Offset}&limit=${Constants.PER_PAGE}&client=all&branch=all&task_type=all`;
        //     this.props.getAllDailyTasks(query);
        //   } catch (error) {
        //     console.log(error);
        //   }
        // }, 500);
      }
    }
  };

  handleViewDailyTaskModel = async (id: string) => {
    try {
      await this.props.getDailyTaskByIdForView(id);
    } catch (error) {
      console.log(error);
    }
  };

  handleCopyDailyTask = async (id: string) => {
    try {
      this.props.getDailyTaskByIdForDuplicate(id);
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <tbody>
        {this.props.data.map((task: any, index: any) => (
          <ApprovedCard
            task={task}
            key={index + task.title}
            handleSendQAEmailDailyTask={this.handleSendQAEmailDailyTask}
            handleEditDailyTask={this.handleEditDailyTask}
            handleDeleteDailyTask={this.handleDeleteDailyTask}
            handleViewDailyTaskModel={this.handleViewDailyTaskModel}
            handleCopyDailyTask={this.handleCopyDailyTask}
          />
        ))}
        <Offcanvas
          placement="top"
          show={this.props.sendMailModal}
          onHide={() => this.props.SendMailModalApprovedTask(false)}
          style={{ height: "250px" }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <span className="offc-title">Send Email Approved task</span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <SendMail
              id={this.props.id}
              data={this.props.selectedAdditionalTaskData}
            />
          </Offcanvas.Body>
        </Offcanvas>
        <Offcanvas
          placement="end"
          show={this.state.editModel}
          onHide={() => this.handleEditModelClose()}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <span className="offc-title">Edit Approved Task</span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <EditApprovedTask
              id={this.props.id}
              data={this.props.selectedAdditionalTaskData}
              handleEditModelClose={() => this.handleEditModelClose()}
              copyDailyTaskStatus={this.props.copyDailyTaskStatus}
            />
          </Offcanvas.Body>
        </Offcanvas>
        <Offcanvas
          placement="end"
          show={this.props.copyDailyTaskStatus}
          onHide={() => this.props.updateEditDailyTaskDlgStatus(false)}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <span className="offc-title">Copy Task</span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <EditApprovedTask
              id={this.props.id}
              data={this.props.selectedDailyTaskData}
              copyDailyTaskStatus={this.props.copyDailyTaskStatus}
              handleEditModelClose={() => this.handleEditModelClose()}
            />
          </Offcanvas.Body>
        </Offcanvas>
      </tbody>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  sendqaemailDailyTaskStatus: state.dailytask.sendqaemailDailyTaskStatus,
  selectedAdditionalTaskData: state.dailytask.selectedAdditionalTaskData,
  id: state.dailytask.additionalTaskid,
  sendMailModal: state.dailytask.sendMailModal,
  copyDailyTaskStatus: state.dailytask.copyDailyTaskStatus,
  selectedDailyTaskData: state.dailytask.selectedDailyTaskData,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getDailyTaskByIdForDuplicate: bindActionCreators(
      getDailyTaskByIdForDuplicate,
      dispatch
    ),
    updateEditDailyTaskDlgStatus: bindActionCreators(
      updateEditDailyTaskDlgStatus,
      dispatch
    ),
    updateSendQAEmailDailyTaskDlgStatus: bindActionCreators(
      updateSendQAEmailDailyTaskDlgStatus,
      dispatch
    ),
    getSendQAEmailDailyTaskById: bindActionCreators(
      getSendQAEmailDailyTaskById,
      dispatch
    ),
    getDailyTaskById: bindActionCreators(getDailyTaskById, dispatch),
    getAdditionalTaskById: bindActionCreators(getAdditionalTaskById, dispatch),
    deleteDailyTask: bindActionCreators(deleteDailyTask, dispatch),
    getDailyTaskByIdForView: bindActionCreators(
      getDailyTaskByIdForView,
      dispatch
    ),
    SendMailModalApprovedTask: bindActionCreators(
      SendMailModalApprovedTask,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovedTable);
