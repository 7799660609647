// reducer.js

import {
  API_GET_ALLBRANCH,
  API_GET_ALLBRANCH_FAIL,
  API_GET_ALLBRANCH_SUCCESS,
  API_GET_ALLCLIENTS,
  API_GET_ALLCLIENTS_FAIL,
  API_GET_ALLCLIENTS_SUCCESS,
  API_GET_ALL_COMPLAINT,
  API_GET_ALL_COMPLAINT_BY_ID,
  API_GET_ALL_COMPLAINT_BY_ID_FAIL,
  API_GET_ALL_COMPLAINT_BY_ID_SUCCESS,
  API_GET_ALL_COMPLAINT_FAIL,
  API_GET_ALL_COMPLAINT_SUCCESS,
  API_GET_ALL_DAILYTASKS_WITHOUT_LIMIT,
  API_GET_ALL_DAILYTASKS_WITHOUT_LIMIT_FAIL,
  API_GET_ALL_DAILYTASKS_WITHOUT_LIMIT_SUCCESS,
  API_GET_ALL_REPORT,
  API_GET_ALL_REPORT_FAIL,
  API_GET_ALL_REPORT_SUCCESS,
  API_GET_COMPLAINTS_BY_ID,
  API_GET_COMPLAINTS_BY_ID_FAIL,
  API_GET_COMPLAINTS_BY_ID_SUCCESS,
  API_GET_COMPLAINT_ID_FOR_CHAT,
  API_GET_COMPLAINT_ID_FOR_CHAT_FAIL,
  API_GET_COMPLAINT_ID_FOR_CHAT_SUCCESS,
  API_GET_DAILY_TASKS,
  API_GET_DAILY_TASKS_FAIL,
  API_GET_DAILY_TASKS_SUCCESS,
  AllReortState,
  COMPLAINTS_SORT,
  FILTER_ALL_COMPLAINT_BRANCH,
  FILTER_ALL_COMPLAINT_CLIENT,
  FILTER_ALL_REPORT_FROM_DATE,
  FILTER_ALL_REPORT_TO_DATE,
  FILTER_ALL_TASK_BRANCH,
  FILTER_ALL_TASK_CLIENT,
  FILTER_ALL_TASK_DATE,
  FILTER_ALL_TASK_STATUS,
  FILTER_ALL_TASK_TYPE,
  FILTER_SPECIAL_REQUEST_ALL_TASK_STATUS,
  FILTER_TASK_DUE_TYPES,
} from "../ActionTypes";
import { AnyAction, Dispatch } from "redux";
import { IBranchSelect, ICleaner } from "../../shared/ActionTypes";

import { AppDispatch } from "../../";
import Constants from "../Constants";

const initialState: AllReortState = {
  branch_List: [],
  filterReport_FromDate: "",
  filterReport_ToDate: "",
  filtered_ReportData: [],
  filtered_DailyTaskData: [],
  filtered_DailyTaskAllData: [],
  client_List: [],
  filter_TaskClientById: "all",
  filter_ComplaintClientById: "all",
  filter_TaskBranchById: "all",
  filter_complaintBranchById: "all",
  filter_TaskTypeById: "all",
  filter_TaskStatusById: "all",
  filter_SpecialRequestTaskStatusById: "all",
  filter_TaskDateTypeValue: { from_date: "", to_date: "" },
  total_count: 0,
  total_task_count: 0,
  total_complaint_count: 0,
  filter_TaskDueTypeByValue: "all",
  filtered_ComplaintData: [],
  selected_ComplaintData: null,
  chat_ComplaintStatus: false,
  complaint_FetchStatus: false,
  id: "",
  complaint_SortBy: "",
  complaint_OrderBy: "",
  complaint_name: null,
};

// Define the reducer function
export const AllReportReducer = (state = initialState, action: AnyAction) => {
  // console.log(action, "action in all reducer");

  switch (action.type) {
    case FILTER_ALL_REPORT_FROM_DATE:
      return { ...state, filterReport_FromDate: action.payload };
    case FILTER_ALL_REPORT_TO_DATE:
      return { ...state, filterReport_ToDate: action.payload };
    case API_GET_ALL_REPORT:
      return { ...state };
    case API_GET_ALL_REPORT_SUCCESS:
      let reportData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "activities" in data && data.activities) {
          reportData = data.activities;
        }
      }
      let total_count = 0;
      if (action.payload) {
        const { data } = action.payload;
        if (data && "total_count" in data && data.total_count) {
          total_count = data.total_count;
        }
      }
      return {
        ...state,
        total_count: total_count,
        filtered_ReportData: reportData,
      };
    case API_GET_ALL_REPORT_FAIL:
      return { ...state };
    case API_GET_DAILY_TASKS:
      return { ...state };
    case API_GET_DAILY_TASKS_SUCCESS:
      let taskData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task_list" in data && data.task_list) {
          taskData = data.task_list;
        }
      }
      let totalcount = 0;
      if (action.payload) {
        const { data } = action.payload;
        if (data && "total_count" in data && data.total_count) {
          totalcount = data.total_count;
        }
      }
      return {
        ...state,
        filtered_DailyTaskData: taskData,
        total_task_count: totalcount,
      };
    case API_GET_DAILY_TASKS_FAIL:
      return { ...state };
    case FILTER_ALL_TASK_CLIENT:
      return { ...state, filter_TaskClientById: action.payload };
    case FILTER_ALL_COMPLAINT_CLIENT:
      return { ...state, filter_ComplaintClientById: action.payload };
    case FILTER_ALL_TASK_BRANCH:
      return { ...state, filter_TaskBranchById: action.payload };
    case FILTER_ALL_COMPLAINT_BRANCH:
      return { ...state, filter_complaintBranchById: action.payload };
    case FILTER_ALL_TASK_TYPE:
      return { ...state, filter_TaskTypeById: action.payload };
    case FILTER_ALL_TASK_STATUS:
      return { ...state, filter_TaskStatusById: action.payload };
    case FILTER_SPECIAL_REQUEST_ALL_TASK_STATUS:
      return { ...state, filter_SpecialRequestTaskStatusById: action.payload };
    case FILTER_ALL_TASK_DATE:
      return { ...state, filter_TaskDateTypeValue: action.payload };
    case API_GET_ALL_DAILYTASKS_WITHOUT_LIMIT:
      return { ...state };
    case API_GET_ALL_DAILYTASKS_WITHOUT_LIMIT_SUCCESS:
      let allTaskData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task_list" in data && data.task_list) {
          allTaskData = data.task_list;
        }
      }
      return {
        ...state,
        filtered_DailyTaskAllData: allTaskData,
      };
    case API_GET_ALL_DAILYTASKS_WITHOUT_LIMIT_FAIL:
      return { ...state };
    case API_GET_ALLCLIENTS:
      return { ...state };
    case API_GET_ALLCLIENTS_SUCCESS:
      let clientData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "clients" in data && data.clients) {
          clientData = data.clients;
        }
      }
      return { ...state, client_List: clientData };
    case API_GET_ALLCLIENTS_FAIL:
      return { ...state };
    case API_GET_ALLBRANCH:
      return { ...state };
    case API_GET_ALLBRANCH_SUCCESS:
      let branchData: IBranchSelect[] = [];
      let branchDataUpdate: IBranchSelect[] = [];
      // let branchClientId = 0;
      if (action.payload) {
        const { data } = action.payload;
        // branchClientId = data.client_id;
        if (data && "branches" in data && data.branches) {
          branchData = data.branches.map((branchValue: any) => ({
            value: branchValue.id,
            label: branchValue.name,
            lat: branchValue.lat,
            lng: branchValue.lng,
            client_id:
              data.client_id > 0 ? data.client_id : branchValue.client_id,
          }));
          if (Constants.LOGIN_USER_TYPE === Constants.MANAGERUTINT) {
            branchDataUpdate = [
              {
                value: "all",
                label: "All",
              },
            ].concat(branchData);
          }
        }
      }
      // , getBranchClientId : true
      if (Constants.LOGIN_USER_TYPE === Constants.MANAGERUTINT) {
        return { ...state, branch_List: branchDataUpdate };
      } else {
        return { ...state, branch_List: branchData };
      }
    case API_GET_ALLBRANCH_FAIL:
      return { ...state };

    case FILTER_TASK_DUE_TYPES:
      return { ...state, filter_TaskDueTypeByValue: action.payload };
    case API_GET_ALL_COMPLAINT:
      return { ...state };
    case API_GET_ALL_COMPLAINT_SUCCESS:
      let complaintData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "complaints" in data && data.complaints) {
          complaintData = data.complaints;
        }
      }
      let totalcount_ = 0;
      if (action.payload) {
        const { data } = action.payload;
        if (data && "total_count" in data && data.total_count) {
          totalcount_ = data.total_count;
        }
      }
      return {
        ...state,
        total_complaint_count: totalcount_,
        filtered_ComplaintData: complaintData,
      };
    case API_GET_ALL_COMPLAINT_FAIL:
      return { ...state };
    case API_GET_ALL_COMPLAINT_BY_ID:
      return { ...state, id: action.payload.id };
    case API_GET_ALL_COMPLAINT_BY_ID_SUCCESS:
      let complaint = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "complaint" in data && data.complaint) {
          complaint = data.complaint;
        }
      }
      return {
        ...state,
        complaint_name: complaint,
      };
    case API_GET_ALL_COMPLAINT_BY_ID_FAIL:
      return { ...state };
    case API_GET_COMPLAINT_ID_FOR_CHAT:
      return { ...state, id: action.payload.id };
    case API_GET_COMPLAINT_ID_FOR_CHAT_SUCCESS:
      let getComplaint = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "complaint" in data && data.complaint) {
          getComplaint = data.complaint;
        }
      }
      return {
        ...state,
        selected_ComplaintData: getComplaint,
        chat_ComplaintStatus: true,
        complaint_FetchStatus: false,
      };
    case API_GET_COMPLAINT_ID_FOR_CHAT_FAIL:
      return { ...state };
    case API_GET_COMPLAINTS_BY_ID:
      return { ...state, id: action.payload.id };
    case API_GET_COMPLAINTS_BY_ID_SUCCESS:
      let complaints = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "complaint" in data && data.complaint) {
          complaints = data.complaint;
        }
      }
      return {
        ...state,
        selected_ComplaintData: complaints,
        complaint_FetchStatus: false,
      };
    case API_GET_COMPLAINTS_BY_ID_FAIL:
      return { ...state };
    case COMPLAINTS_SORT:
      return {
        ...state,
        complaint_SortBy: action.payload.sort,
        complaint_OrderBy: action.payload.order,
      };
    default:
      return state;
  }
};

export function handleFilterReport_FromDate(Fromdate: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_ALL_REPORT_FROM_DATE,
      payload: Fromdate,
    });
  };
}

export function handleFilterReport_ToDate(Todate: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_ALL_REPORT_TO_DATE,
      payload: Todate,
    });
  };
}

export function get_AllReports(queryStr: string) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_ALL_REPORT,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `activity?${queryStr}` : `activity?`,
        },
      },
    });
  };
}

// get all tasks for table
export function getAll_DailyTasks(queryStr: string) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_GET_DAILY_TASKS,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `task?${queryStr}` : `task?`,
        },
      },
    });
  };
}

export function handle_FilterTaskClient(id: any) {
  id = id.join();

  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_ALL_TASK_CLIENT,
      payload: id,
    });
  };
}

export function handle_FilterComplaintClient(id: any) {
  id = id.join();

  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_ALL_COMPLAINT_CLIENT,
      payload: id,
    });
  };
}

export function handle_FilterTaskBranch(id: any) {
  id = id.join();

  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_ALL_TASK_BRANCH,
      payload: id,
    });
  };
}

export function handle_FiltercomplaintBranch(id: any) {
  id = id.join();

  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_ALL_COMPLAINT_BRANCH,
      payload: id,
    });
  };
}

export function handle_FilterTaskType(status: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_ALL_TASK_TYPE,
      payload: status,
    });
  };
}

export function handle_FilterTaskStatus(status: number) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_ALL_TASK_STATUS,
      payload: status,
    });
  };
}

export function handle_FilterSpecialTaskStatus(status: number) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_SPECIAL_REQUEST_ALL_TASK_STATUS,
      payload: status,
    });
  };
}

export function handle_FilterTaskDate(status: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_ALL_TASK_DATE,
      payload: status,
    });
  };
}

// get all data with no limit
export function getAllList_DailyTasks(queryStr: string) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_GET_ALL_DAILYTASKS_WITHOUT_LIMIT,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `task?${queryStr}` : `task?`,
        },
      },
    });
  };
}

// list client
export function list_AllClient() {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_ALLCLIENTS,
      payload: {
        request: {
          method: "get",
          url: `users/client/drop-down-list`,
        },
      },
    });
  };
}

// List Branch
export function list_AllBranch(id: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_ALLBRANCH,
      payload: {
        request: {
          method: "get",
          url: `branch/drop-down-list/${id}`,
        },
      },
    });
  };
}

export function handle_FilterTaskDueType(status: string) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_TASK_DUE_TYPES,
      payload: status,
    });
  };
}

// get all complaints for table
export function getAll_Complaints(queryStr: string) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_ALL_COMPLAINT,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `complaints?${queryStr}` : `complaints?`,
        },
      },
    });
  };
}

// get complaint by id
export function getComplaint_ByIdForChat(id: string) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_COMPLAINT_ID_FOR_CHAT,
      payload: {
        request: {
          method: "get",
          url: id ? `complaints/${id}` : `complaints`,
        },
        id,
      },
    });
  };
}

// get complaint by id
export function getComplaint_ById(id: string) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_COMPLAINTS_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `complaints/${id}` : `complaints`,
        },
        id,
      },
    });
  };
}

// handleComplaintSort
export function handle_ComplaintSort(sort: any, order: any) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: COMPLAINTS_SORT,
      payload: {
        sort: sort,
        order: order,
      },
    });
  };
}

// get complaint by id
export function ListComplaint_ById(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ALL_COMPLAINT_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `complaints/${id}` : `complaints`,
        },
        id,
      },
    });
  };
}
