import { AnyAction } from "redux";
import * as actionTypes from "../ActionTypes/index";

const initialState: actionTypes.SidebarState = {
  status: true,
  userLoginDetails : [],
  userLoginDetailsResStatus : false,
  userLoginTaskstatus:[],
  userLoginPermission:[],
  userLoginTaskStatusPermission:[]
};

export const SidebarReducer = (
  state: actionTypes.SidebarState = initialState,
  action: AnyAction
): actionTypes.SidebarState => {
  switch (action.type) {
    case actionTypes.UPDATE_SIDEBAR:
      return { ...state, status: action.status };
    case actionTypes.API_GET_LOGIN_USER_DETAILS:
      return { ...state, userLoginDetailsResStatus : false };
    case actionTypes.API_GET_LOGIN_USER_DETAILS_SUCCESS:
      let details = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "user" in data && data.user) {
          details = data.user;
        }
      }
      return { 
        ...state, 
        userLoginDetails: details, 
        userLoginDetailsResStatus : true,
        userLoginTaskstatus: details?.permission?.task_status
      };
    case actionTypes.API_GET_LOGIN_USER_DETAILS_FAIL:
      return { ...state, userLoginDetailsResStatus : true };
    case actionTypes.API_GET_LOGIN_USER_PERMISSION:
      return { ...state };
    case actionTypes.API_GET_LOGIN_USER_PERMISSION_SUCCESS:
      let permission = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "user_permission" in data && data.user_permission) {
          permission = data.user_permission;
        }
      }
      return { 
        ...state, 
        userLoginPermission: permission, 
        userLoginTaskStatusPermission: permission?.permission?.task_status
      };
    case actionTypes.API_GET_LOGIN_USER_PERMISSION_FAIL:
      return { ...state };
    default:
      return state;
  }
};

export function updateSidebar(status: boolean) {
  return async (dispatch: actionTypes.SidebarDispatchType) => {
    await dispatch({
      type: actionTypes.UPDATE_SIDEBAR,
      status,
    });
  };
}
// get Login user details
export function getLoginUserDetails(id: string) {
  return async (dispatch: actionTypes.SidebarDispatchType) => {
    await dispatch({
      type: actionTypes.API_GET_LOGIN_USER_DETAILS,
      payload: {
        request: {
          method: "get",
          url: id ? `users/${id}` : `users`,
        },
        id,
      },
    });
  };
}
// get Login user permission
export function getLoginUserPermission(id: string) {
  return async (dispatch: actionTypes.SidebarDispatchType) => {
    await dispatch({
      type: actionTypes.API_GET_LOGIN_USER_PERMISSION,
      payload: {
        request: {
          method: "get",
          url: id ? `users/user-permission/${id}` : `users/user-permission`,
        },
        id,
      },
    });
  };
}
