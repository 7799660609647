import * as Yup from "yup";

import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { IEditShiftProps, IEditShiftState } from "../../types/shift.type";
import { Modal, ProgressBar } from "react-bootstrap";
import { editShift, listAllCleaner, updateEditShiftDlgStatus, updateShiftFetchStatus } from "../../shared/Reducers";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CustomSelect from "../../shared/Components/CustomSelect";
import { Dayjs } from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from "react";
import { RootState } from '../../index';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import TimePicker from "../DailyTask/TimePicker";
import { connect } from "react-redux";
// date picker
import moment from "moment";

class EditShift extends React.Component<IEditShiftProps, IEditShiftState> {
    constructor(props: IEditShiftProps) {
        super(props);
        this.state = {
            endDatevalue    : this.props.data ? this.props.data.end_date : '',
            startDatevalue  : this.props.data ? this.props.data.start_date : '',
            start_time: this.props.data?.start_time === null ? "00:00:00" : this.props.data?.start_time,
            end_time: this.props.data?.end_time === null ? "00:00:00" : this.props.data?.end_time,
        }
    }

    componentDidMount(): void {

        // date picker readonly
        let datePicker: NodeListOf<Element> = document.querySelectorAll('.MuiFormControl-root input') as NodeListOf<Element>;
        if(datePicker.length > 0){
            for (let index = 0; index < datePicker.length; index++) {
                const element = datePicker[index];
                datePicker[index].setAttribute("readonly", "true");
            }
        }
        this.props.listAllCleaner()

        var element = document.getElementById("spinner-border");
        var button = document.getElementById("create-button");
        if (element !== null && button !== null) {
            element.classList.add("d-none");
            button.removeAttribute('disabled');
        }
        console.log(this.props.progressPercentage);
    }

    componentDidUpdate(prevProps: Readonly<IEditShiftProps>, prevState: Readonly<IEditShiftState>, snapshot?: any): void {
        if (prevProps.processTrigger !== this.props.processTrigger) {
        }
        if (prevProps.editShiftSuccess !== this.props.editShiftSuccess) {
        }

    }

    handleStartDateClick = () => {
        let startDate: HTMLElement = document.querySelector('.startdate button.MuiButtonBase-root') as HTMLElement;
        startDate.click();
    }

    handleEndDateClick = () => {
        let endDate: HTMLElement = document.querySelector('.enddate button.MuiButtonBase-root') as HTMLElement;
        endDate.click();
    }

    handleStartDateChange = async (newValue: Dayjs | null) => {
        this.setState({ startDatevalue: newValue })
    };
    handleEndDateChange = async (newValue: Dayjs | null) => {
        this.setState({ endDatevalue: newValue })
    };

    handleEditShift = async (formValues: any) => {

        var element = document.getElementById("spinner-border");
        var button = document.getElementById("create-button");
        if (element !== null && button !== null) {
            element.classList.remove("d-none");
            button.setAttribute('disabled', '');
        }
        let data: any = [];

        data = {
            name : formValues.name,
            start_time : this.state.start_time,
            end_time : this.state.end_time,
            start_date : moment(new Date(this.state.startDatevalue?.toString())).format("YYYY/MM/DD"),
            end_date : moment(new Date(this.state.endDatevalue?.toString())).format("YYYY/MM/DD"),
            cleaners : formValues.cleaners
        }

        if (this.props.id) {
            this.props.editShift(data, this.props.id);
        }
    }

    validationSchema = () => {
        return Yup.object().shape({
            name: Yup.string().required("Enter Shift Name"),
            cleaners: Yup.array().min(1, "Select atleast one cleaner")
        })
    }
    handleStartTime = (val: string) => {
        this.setState({ start_time: val })
    }

    handleEndTime = (val: string) => {
        this.setState({ end_time: val })
    }
    render(): React.ReactNode {
        return (
            <div>
                <Formik initialValues={{
                    name: this.props.data ? this.props.data.name : '',
                    cleaners: this.props.data ? this.props.data.cleaners.map((val: any) => val.cleaner_id) : '',
                }}
                    enableReinitialize={true}
                    onSubmit={this.handleEditShift}
                    validationSchema={this.validationSchema}>
                    <Form>
                        <div className="row">
                            <div className="mb-2 col-md-6">
                                <label className="form-label">Name<span className="text-project">*</span></label>
                                <Field name="name" type={"text"} className="form-control text-field" />
                                <ErrorMessage
                                    name="name"
                                    component={"div"}
                                    className="cus-alert" />
                            </div>
                            <div className="mb-2 col-md-6">
                                <div>
                                    <label className="form-label">Cleaner<span className="text-project">*</span></label>
                                    <Field
                                        className="custom-select"
                                        name="cleaners"
                                        options={this.props.cleanerList}
                                        component={CustomSelect}
                                        placeholder="Select Cleaner"
                                        isMulti={true}
                                    />
                                    <ErrorMessage
                                        name="cleaners"
                                        component={"div"}
                                        className="cus-alert" />
                                </div>
                            </div>
                            <div className="mb-2 col-md-6">
                                <label className="form-label">Start date</label>
                                <div className="startdate" onClick={() => this.handleStartDateClick()}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} id="startDate">
                                        <Stack spacing={3}>
                                            <DesktopDatePicker
                                                inputFormat="DD/MM/YYYY"
                                                maxDate={this.state.endDatevalue}
                                                value={this.state.startDatevalue}
                                                onChange={this.handleStartDateChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Stack>
                                    </LocalizationProvider>
                                </div>
                            </div>

                            <div className="mb-2 col-md-6">
                                <label className="form-label">End date</label>
                                <div className="enddate" onClick={() => this.handleEndDateClick()}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} id="endDate">
                                        <Stack spacing={3}>
                                            <DesktopDatePicker
                                                inputFormat="DD/MM/YYYY"
                                                minDate={this.state.startDatevalue}
                                                value={this.state.endDatevalue}
                                                onChange={this.handleEndDateChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Stack>
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className=" mb-2 col-md-6">
                                <label className="form-label">Start Time</label>
                                <TimePicker name="start_time" value={this.state.start_time} onChange={this.handleStartTime} />
                            </div>
                            <div className=" mb-2 col-md-6">
                                <label className="form-label">End Time</label>
                                <TimePicker name="end_time" value={this.state.end_time} onChange={this.handleEndTime} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="row mt-3 mb-3">
                                <div className="col-md-12 cus-progress">
                                    <Modal
                                        size="lg"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                        show={this.props.processTrigger}
                                    >
                                        <Modal.Body className="p-0">
                                            <ProgressBar now={this.props.progressPercentage} label={this.props.progressPercentage === 100 ? `Processing your request...` : `Shift Updating....${this.props.progressPercentage}%`} />
                                        </Modal.Body>
                                    </Modal>
                                    <div className="d-flex">
                                        <div className="mr-16px">
                                            <button type="submit" id="create-button" className="btn btn-primary create-button">Save <span id="spinner-border" className="spinner-border spinner-border-sm d-none"></span></button>
                                        </div>
                                        <div>
                                            <button type="button" className="btn cancel-button" onClick={() => this.props.updateEditShiftDlgStatus(false)}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Formik>

                <iframe style={{ display: "none" }} name="hiddenIframe" id="hiddenIframe" />
            </div>
        )
    }
}
const mapStateToProps = (state: RootState) => ({
    processTrigger: state.shift.processTrigger,
    editShiftSuccess: state.shift.editShiftSuccess,
    progressPercentage: state.shift.progressPercentage,
    cleanerList: state.tasktemplate.cleanerList
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        editShift: bindActionCreators(editShift, dispatch),
        updateEditShiftDlgStatus: bindActionCreators(updateEditShiftDlgStatus, dispatch),
        updateShiftFetchStatus: bindActionCreators(updateShiftFetchStatus, dispatch),
        listAllCleaner: bindActionCreators(listAllCleaner, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditShift);