import { AnyAction } from "redux";

export const FILTER_ALL_REPORT_FROM_DATE = "FILTER_ALL_REPORT_FROM_DATE";
export const FILTER_ALL_REPORT_TO_DATE = "FILTER_ALL_REPORT_TO_DATE";
export const API_GET_ALL_REPORT = "get_all_report/LOAD";
export const API_GET_ALL_REPORT_SUCCESS = "get_all_report/LOAD_SUCCESS";
export const API_GET_ALL_REPORT_FAIL = "get_all_report/LOAD_FAIL";
export const API_GET_DAILY_TASKS = "get_daily_tasks/LOAD";
export const API_GET_DAILY_TASKS_SUCCESS = "get_daily_tasks/LOAD_SUCCESS";
export const API_GET_DAILY_TASKS_FAIL = "get_daily-tasks/LOAD_FAIL";
export const FILTER_ALL_TASK_CLIENT = "FILTER_ALL_TASK_CLIENT";
export const FILTER_ALL_COMPLAINT_CLIENT = "FILTER_ALL_COMPLAINT_CLIENT";
export const FILTER_ALL_TASK_BRANCH = "FILTER_ALL_TASK_BRANCH";
export const FILTER_ALL_COMPLAINT_BRANCH = "FILTER_ALL_COMPLAINT_BRANCH";
export const FILTER_ALL_TASK_TYPE = "FILTER_ALL_TASK_TYPE_";
export const FILTER_ALL_TASK_STATUS = "FILTER_ALL_TASK_STATUS";
export const FILTER_SPECIAL_REQUEST_ALL_TASK_STATUS =
  "FILTER_SPECIAL_REQUEST_ALL_TASK_STATUS";
export const FILTER_ALL_TASK_DATE = "FILTER_ALL_TASK_DATE";
export const API_GET_ALL_DAILYTASKS_WITHOUT_LIMIT =
  " all_dailytask_without_limit/LOAD";
export const API_GET_ALL_DAILYTASKS_WITHOUT_LIMIT_SUCCESS =
  " all_dailytask_without_limit/LOAD_SUCCESS";
export const API_GET_ALL_DAILYTASKS_WITHOUT_LIMIT_FAIL =
  " all_dailytask_without_limit/LOAD_FAIL";
export const API_GET_ALLCLIENTS = "api_get_allClients/LOAD";
export const API_GET_ALLCLIENTS_SUCCESS = "api_get_allClients/LOAD_SUCCESS";
export const API_GET_ALLCLIENTS_FAIL = "api_get_allClients/LOAD_FAIL";
export const API_GET_ALLBRANCH = "api_get_allBranch/LOAD";
export const API_GET_ALLBRANCH_SUCCESS = "api_get_allBranch/LOAD_SUCCESS";
export const API_GET_ALLBRANCH_FAIL = "api_get_allBranch/LOAD_FAIL";
export const API_GET_ALL_COMPLAINT_BRANCH = "api_get_all_complaint"
export const FILTER_TASK_DUE_TYPES = "FILTER_TASK_DUE_TYPES";
export const API_GET_ALL_COMPLAINT = "api_get_all_complaint/LOAD";
export const API_GET_ALL_COMPLAINT_SUCCESS =
  "api_get_all_complaint/LOAD_SUCCESS";
export const API_GET_ALL_COMPLAINT_FAIL = "api_get_all_complaint/LOAD_FAIL";
export const API_GET_COMPLAINT_ID_FOR_CHAT = "api_get_complaint_id_chat/LOAD";
export const API_GET_COMPLAINT_ID_FOR_CHAT_SUCCESS =
  "api_get_complaint_id_chat/LOAD_SUCCESS";
export const API_GET_COMPLAINT_ID_FOR_CHAT_FAIL =
  "api_get_complaint_id_chat/LOAD_FAIL";
export const API_GET_COMPLAINTS_BY_ID = "api_get_all_complaints_by_id/LOAD";
export const API_GET_COMPLAINTS_BY_ID_SUCCESS =
  "api_get_all_complaints_by_id/LOAD_SUCCESS";
export const API_GET_COMPLAINTS_BY_ID_FAIL =
  "api_get_all_complaints_by_id/LOAD_FAIL";
export const COMPLAINTS_SORT = "complaints_sort";
export const API_GET_ALL_COMPLAINT_BY_ID = "API_GET_ALL_COMPLAINT_BY_ID/LOAD"
export const API_GET_ALL_COMPLAINT_BY_ID_SUCCESS = "API_GET_ALL_COMPLAINT_BY_ID/LOAD_SUCCESS"
export const API_GET_ALL_COMPLAINT_BY_ID_FAIL = "API_GET_ALL_COMPLAINT_BY_ID/LOAD_FAIL"

export interface AllReortState {
  filterReport_FromDate: any;
  filterReport_ToDate: any;
  filtered_ReportData: any;
  filtered_DailyTaskData: any;
  total_count: number;
  total_task_count: number;
  total_complaint_count: number;
  filter_TaskClientById: any;
  filter_ComplaintClientById: any;
  filter_TaskBranchById: any;
  filter_complaintBranchById: any;
  filter_TaskTypeById: any;
  filter_TaskStatusById: any;
  filter_SpecialRequestTaskStatusById: any;
  filter_TaskDateTypeValue: any;
  filtered_DailyTaskAllData: any;
  client_List: any;
  branch_List: any;
  filter_TaskDueTypeByValue: any;
  filtered_ComplaintData: any;
  selected_ComplaintData: any;
  chat_ComplaintStatus: boolean;
  complaint_FetchStatus: boolean;
  id: any;
  complaint_SortBy: any;
  complaint_OrderBy: any;
  complaint_name: any;
}
