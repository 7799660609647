import React from "react";
import { Modal, Button } from "react-bootstrap";
import { CloseBtnSVG } from "../../assets/images/Location";
import { IEmailVerificationConfirmProps } from "../../types/userManagement.type";
import { RootState } from '../../index';
import { connect } from "react-redux";
import { Dispatch } from "redux";

class EmailVerificationConfirm extends React.Component<IEmailVerificationConfirmProps> {
    render(): React.ReactNode {
        return (
            <div >
                <Modal className="delete-modal" show={this.props.displayVerifyEmailConfirm} onHide={() => this.props.handleEmailVerificationClose(false)}>
                    <Modal.Body>
                        <a href="javascript:void(0)" className="qr-modal-close" onClick={() => this.props.handleEmailVerificationClose(false)}>
                            <CloseBtnSVG />
                        </a>

                        <div className="delete-modal-body">
                            <h3 className="mb-3">Verify Email</h3>
                            <p>Do you want to verify the {this.props.verifyUser} - <span className="font-weight-bold">{this.props.verifyEmail}</span> email</p>
                        </div>

                        <div className="delete-footer qr-modal-footer d-flex justify-content-end pt-2">
                            <Button className="btn btn-outline-success delete-cancel" variant="outline" onClick={() => this.props.handleEmailVerificationClose(false)}>Cancel</Button>
                            <Button className="delete-cancel delete-button" variant="primary" onClick={() => this.props.handleEmailVerificationClose(true)}>Verify</Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = (state: RootState) => ({

})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerificationConfirm);