import React, { Component } from "react";
import * as Yup from "yup";
import { connect } from "react-redux";
import { RootState } from "../../index";
import { AttachmentDownloadSVG } from "../../assets/images/ImagesSVG";
import Constants, {
  EveryMonth,
  EveryWeekDays,
  TASK_PRIORITY,
} from "../../shared/Constants";
import { Dispatch, bindActionCreators } from "redux";
import { Modal, Toast } from "react-bootstrap";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { IBranchSelect, ICleaner } from "../../shared/ActionTypes";
import {
  copyDailyTask,
  deleteAttachementId,
  editDailyTask,
  editAdditionalTask,
  listAllBranch,
  listAllCleaner,
  listAllClient,
  makeScheduleDailyTask,
  updateDailyTaskFetchStatus,
  updateEditDailyTaskDlgStatus,
  EditModalDailyTask,
  getAllDailyTasks,
  getAllAdditionalTasks,
} from "../../shared/Reducers";
import docImage from "../../assets/images/google-docs.png";
import Button from "@material-ui/core/Button";
import DatePicker from "../DailyTask/DatePicker";
import moment from "moment";
import CustomSelect from "../../shared/Components/CustomSelect";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";
import { CloseBtnSVG, PaperClipSVG, SuccessToastSVG, TrashSVG } from "../../assets/images/ImagesV2";
import { EditApprovedProps, EditApprovedState } from "../../types/approvedtask";
import { confirmAlert } from "react-confirm-alert";
import TimePicker from "../DailyTask/TimePicker";
import { log } from "console";
// import  DesktopDatePicker from '@mui/x-date-pickers/DesktopDatePicker';
// import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers-pro';
import { TextFieldProps } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Stack from "@mui/material/Stack";
import TextField from '@mui/material/TextField';
import dayjs, { Dayjs } from 'dayjs';


const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  className,
  ...props
}: any) => {
  return (
    <div>
      <input
        name={name}
        id={id}
        type="radio"
        value={id} // could be something else for output?
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        className={"radio-button"}
        {...props}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

const RadioButtonGroup = ({
  value,
  error,
  touched,
  // id,
  label,
  className,
  children,
}: any) => {
  const classes = `input-field ${value || (!error && touched) ? "is-success" : ""
    } ${!!error && touched ? "is-error" : ""} ${className}`;

  return (
    <div className={classes}>
      <fieldset>
        <legend>{label}</legend>
        {children}
        {/* {touched && <InputFeedback error={error} />} */}
      </fieldset>
    </div>
  );
};

class EditApprovedTask extends React.Component<
  EditApprovedProps,
  EditApprovedState
> {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  constructor(props: EditApprovedProps) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      attachmentUrls: [],
      deleteAttachementId: [],
      AttachmentPreviewStatus: false,
      previewUrl: "",
      previewType: "",
      file_count: "",
      task_date: this.props.data?.task_date,
      start_time:
        this.props.data?.start_time === null
          ? "00:00:00"
          : this.props.data?.start_time,
      end_time:
        this.props.data?.end_time === null
          ? "00:00:00"
          : this.props.data?.end_time,
      signatureModal: false,
      signState: true,
      signatureImage: [],
      context: null,
      signatureImageError: "",
      isDrawing: false,
      signLastX: 0,
      signLastY: 0,
      isSignChanged: false,
      editToast: false,
    };
  }

  componentDidMount(): void {

    if (this.props.data) {

      this.setState(
        {
          attachmentUrls: this.props.data.attachments.map((obj: any) => ({
            url: obj.s3_url,
            name: obj.file_name,
            id: obj.id,
            type: "download",
            download_url: `${Constants.imgUrl}${obj.file_name}`,
            file_type: obj.type,
            contractor_attachment_type: obj.contractor_attachment_type,
          })),
          // task_date:this.props.data.task_date ? this.props.data?.task_date : "",
          task_date: this.props.data?.task_date,

          start_time:
            this.props.data.start_time === null
              ? "00:00:00"
              : this.props.data.start_time,
          end_time:
            this.props.data.end_time === null
              ? "00:00:00"
              : this.props.data.end_time,
          signatureImage: this.props.data.attachments.map((item: any) => ({
            url: item.s3_url,
            name: item.file_name,
            id: item.id,
            type: "download",
            download_url: `${Constants.imgUrl}${item.file_name}`,
            file_type: item.type,
            contractor_attachment_type: item.contractor_attachment_type,
          })),
        },
        () => {
          if (this.state.signatureImage.length === 0) {
            this.setState({ signState: false });
          }
        }
      );
    }
    this.props.listAllClient();
    this.props.listAllCleaner();
    if (this.props.data) {
      this.props.listAllBranch(this.props.data.client_id);
    }
    // }
    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.add("d-none");
      button.removeAttribute("disabled");
    }

    // date picker readonly
    let datePicker: NodeListOf<Element> = document.querySelectorAll(
      ".MuiFormControl-root input"
    ) as NodeListOf<Element>;
    if (datePicker.length > 0) {
      for (let index = 0; index < datePicker.length; index++) {
        datePicker[index].setAttribute("readonly", "true");
      }
    }
  }

  componentDidUpdate(
    prevProps: Readonly<EditApprovedProps>,
    prevState: Readonly<EditApprovedState>,
    snapshot?: any
  ): void {
    if (this.props.data !== prevProps.data) {
      this.setState({
        attachmentUrls: this.props.data?.attachments.map((obj: any) => ({
          url: `${Constants.imgThumbUrl}${obj.file_name}`,
          name: obj.file_name,
          id: obj.id,
          type: "download",
          download_url: `${Constants.imgUrl}${obj.file_name}`,
          file_type: obj.type,
          contractor_attachment_type: obj.contractor_attachment_type,
        })),
        // task_date: this.props.data ? this.props.data?.task_date : "",
        task_date: this.props.data?.task_date,

        start_time:
          this.props.data?.start_time === null
            ? "00:00:00"
            : this.props.data?.start_time,
        end_time:
          this.props.data?.end_time === null
            ? "00:00:00"
            : this.props.data?.end_time,
        signatureImage: this.props.data.attachments.map((item: any) => ({
          url: item.s3_url,
          name: item.file_name,
          id: item.id,
          type: "download",
          download_url: `${Constants.imgUrl}${item.file_name}`,
          file_type: item.type,
          contractor_attachment_type: item.contractor_attachment_type,
        })),
      });

      this.props.listAllBranch(this.props.data?.client_id);
    }
    if (prevProps.editadditionalTaskSuccess !== this.props.editadditionalTaskSuccess) {
      if (
        this.props.editadditionalTaskSuccess === "Successfully updated" ||
        this.props.editadditionalTaskSuccess === "Internal server error"
      ) {
        this.setState({ editToast: true });

        setTimeout(() => {
          this.setState({ editToast: false });
        }, 5000);
      }
    }
  }

  getTasks = async (offset: number, limit: number) => {
    try {
      // list for table
      let query = `search=&sort=&order=&offset=${offset}&limit=10000&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=2&task_priority=${this.props.filterTaskPriorityById}&due_type=${this.props.filterTaskDueTypeByValue}&task_status=${this.props.filterTaskStatusById}&special_request_task_status=${this.props.filterSpecialRequestTaskStatusById}&status&start_date=${this.props.filterTaskDateTypeValue.from_date}&end_date=${this.props.filterTaskDateTypeValue.to_date}&additional_task_status='all'`;

      // list for excel report
      // let AllListquery = `search=&sort=&order=&offset=${offset}&limit=10000&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=2&task_priority=${this.props.filterTaskPriorityById}&due_type=${this.props.filterTaskDueTypeByValue}&task_status=${this.props.filterTaskStatusById}&special_request_task_status=${this.props.filterSpecialRequestTaskStatusById}&status&start_date=${this.props.filterTaskDateTypeValue.from_date}&end_date=${this.props.filterTaskDateTypeValue.to_date}`;
      await this.props.getAllAdditionalTasks(query);
      // await this.props.getAllListDailyTasks(AllListquery);
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (e: any) => {
    if (e.target.name === "client") {
      this.props.listAllBranch(e.target.value);
    }
  };

  handleDeleteImage = (index: number) => {
    const urls = this.state.attachmentUrls;

    confirmAlert({
      title: "Confirm deletion",
      message: "Are you sure you want to delete this Attchment ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            // check exist file
            if (urls[index].id !== undefined && urls[index].id !== "0") {
              let deleteAttachementId = this.state.deleteAttachementId;
              deleteAttachementId.push(urls[index].id);
              this.setState({ deleteAttachementId: deleteAttachementId });

              urls.splice(index, 1);
              this.setState({ attachmentUrls: urls });
            } else {
              urls.splice(index, 1);
              this.setState({ attachmentUrls: urls });
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  downloadFile = (url: string, name = "image.png") => {
    this.downloadResource(url, name);
  };

  forceDownload = (blob: any, filename: String) => {
    const a: any = document.createElement("a");
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  handleImageChange = async (e: any) => {
    let files = [];
    let urls = [];
    files = [...e.target.files];

    let fileurls: any = [];
    await files.map(async (file: any) => {
      fileurls.push({
        url: URL.createObjectURL(file),
        file: file,
        id: "0",
        file_type: file.type,
        download_url: URL.createObjectURL(file),
      });
    });
    urls = [...fileurls];
    this.setState({ attachmentUrls: [...this.state.attachmentUrls, ...urls] });
  };

  // Current blob size limit is around 500MB for browsers
  downloadResource = (url: any, filename: any) => {
    if (url) {
      if (!filename) filename = url.split("\\").pop().split("/").pop();
      fetch(url, {
        headers: new Headers({
          Origin: window.location.origin,
        }),
        mode: "cors",
      })
        .then((response) => response.blob())
        .then((blob) => {
          let blobUrl = window.URL.createObjectURL(blob);
          this.forceDownload(blobUrl, filename);
        })
        .catch((e) => console.error(e));
    }
  };

  handlePreviewOpen = (file_type: any, url: any, inx: any) => {
    if (
      file_type === "image/png" ||
      file_type === "image/jpeg" ||
      file_type === "image/jpg" ||
      file_type === "video/mp4" ||
      file_type === "video/webm" ||
      file_type === "application/pdf"
    ) {
      this.setState({
        AttachmentPreviewStatus: true,
        previewUrl: url,
        previewType: file_type,
        file_count: inx,
      });
    }
  };

  handlePreviewClose = (bool: any, urlArray: any, attachFiles: any) => {
    this.setState({ AttachmentPreviewStatus: false, attachmentUrls: urlArray });
  };

  handleStartTime = (val: string) => {
    this.setState({ start_time: val });
  };

  handleEndTime = (val: string) => {
    this.setState({ end_time: val });
  };

  handleSignModal = () => {
    this.setState({ signatureModal: true, signState: false });
  };

  handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
    this.setState({ signatureImageError: "" });

    this.setState({ isDrawing: true });
    const canvas = this.canvasRef.current;

    if (!canvas) return;
    this.context = canvas.getContext("2d");
    this.setState({ context: canvas.getContext("2d") });
    if (!this.context) return;

    const { offsetX, offsetY } = event.nativeEvent;

    this.setState({ signLastX: offsetX, signLastY: offsetY });
  };

  handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    this.setState({ signatureImageError: "" });

    if (!this.state.isDrawing) return;
    const canvas = this.canvasRef.current;

    if (canvas) {
      const { offsetX, offsetY } = event.nativeEvent;

      // Draw a line segment from the last recorded point to the current point
      let context: any = this.state.context;
      context.strokeStyle = "#000"; // Stroke color
      context.lineWidth = 2; // Stroke width
      context.beginPath();
      context.moveTo(this.state.signLastX, this.state.signLastY);
      context.lineTo(offsetX, offsetY);
      context.stroke();

      this.setState({ signLastX: offsetX, signLastY: offsetY });
    }
  };

  handleMouseUp = () => {
    this.setState({ signatureImageError: "" });

    this.setState({ isDrawing: false });
  };

  handleTouchStart = (event: React.TouchEvent<HTMLCanvasElement>) => {
    this.setState({ signatureImageError: "" });

    this.setState({ isDrawing: true });
    const canvas = this.canvasRef.current;
    console.log(canvas);

    if (!canvas) return;
    let context: any = this.state.context;
    context = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    if (!context) return;

    const { clientX, clientY } = event.nativeEvent.changedTouches[0];

    this.setState({ signLastX: clientX - rect.left, signLastY: clientY - 111 });
  };

  handleTouchMove = (event: React.TouchEvent<HTMLCanvasElement>) => {
    this.setState({ signatureImageError: "" });

    if (!this.state.isDrawing) return;
    const canvas = this.canvasRef.current;
    let context: any = this.state.context;
    if (!canvas || !context) return;

    const { clientX, clientY } = event.nativeEvent.changedTouches[0];
    const rect = canvas.getBoundingClientRect();
    // const x = touch.clientX - rect.left;
    // const y = touch.clientY - rect.top;

    // Draw a line segment from the last recorded point to the current point
    context.strokeStyle = "#000"; // Stroke color
    context.lineWidth = 2; // Stroke width
    context.beginPath();
    context.moveTo(this.state.signLastX, this.state.signLastY);
    context.lineTo(clientX - rect.left, clientY - rect.top);
    context.stroke();
    this.setState({
      signLastX: clientX - rect.left,
      signLastY: clientY - rect.top,
    });
  };

  handleTouchEnd = () => {
    this.setState({ signatureImageError: "" });

    this.setState({ isDrawing: false });
  };

  handleClear = () => {
    const canvas = this.canvasRef.current;
    let context: any = this.state.context;
    if (!canvas || !context) return;

    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  handleConvertToImage = () => {
    const canvas = this.canvasRef.current;
    if (!canvas) return;

    const image: string = canvas.toDataURL("image/png");

    if (
      image !==
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACWCAYAAABkW7XSAAAAAXNSR0IArs4c6QAABGJJREFUeF7t1AEJAAAMAsHZv/RyPNwSyDncOQIECEQEFskpJgECBM5geQICBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAgQdWMQCX4yW9owAAAABJRU5ErkJggg=="
    ) {
      this.setState({
        signatureImage: image,
        signatureModal: false,
        isSignChanged: true,
      });
    }
  };

  handleEditTask = async (formValues: any) => {
    try {
      if (this.props.copyDailyTaskStatus === true) {
        let data = new FormData();
        data.append("task_list", this.props.data.id);
        data.append("client", formValues.client);
        data.append("branch", formValues.branch);
        data.append("title", formValues.title);
        for (let index = 0; index < this.state.attachmentUrls.length; index++) {
          if (this.state.attachmentUrls[index].id === "0") {
            data.append("attachments", this.state.attachmentUrls[index].file);
          }
        }
        data.append("cleaners", JSON.stringify(formValues.cleaners));
        data.append("task_type", formValues.task_type);
        data.append("description", formValues.description);
        data.append(
          "task_date",
          moment(this.state.task_date, "YYYY-MM-DD").format("DD/MM/YYYY")
        );
        if (formValues.any_time === "1") {
          data.append("any_time", formValues.any_time);
        } else if (formValues.any_time === "0") {
          data.append("any_time", "0");
          data.append("start_time", this.state.start_time);
          data.append("end_time", this.state.end_time);
        }
        data.append("ref_task_id", "1");
        data.append("task_with_sign_in", formValues.task_with_sign_in);
        data.append("status", formValues.status);
        data.append("priority", formValues.priority);
        if (this.state.isSignChanged) {
          data.append("sp_task_signature", this.state.signatureImage);
        }
        data.append("isSignChanged", this.state.isSignChanged ? "1" : "0");

        if (this.props.data.id) {
          await this.props.copyDailyTask(data);
          // await this.getTasks(0, 10)
          this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
          this.setState({ isSignChanged: false });
          this.props.handleEditModelClose();
        }
      } else {
        console.log("Normal Edit");

        let data = new FormData();
        data.append("client", formValues.client);
        data.append("branch", formValues.branch);
        data.append("title", formValues.title);
        for (let index = 0; index < this.state.attachmentUrls.length; index++) {
          if (this.state.attachmentUrls[index].id === "0") {
            data.append("attachments", this.state.attachmentUrls[index].file);
          }
        }
        data.append("cleaners", JSON.stringify(formValues.cleaners));
        data.append("task_type", formValues.task_type);
        data.append("description", formValues.description);
        data.append(
          "task_date",
          moment(this.state.task_date, "YYYY-MM-DD").format("DD/MM/YYYY")
        );
        if (formValues.any_time === "1") {
          data.append("any_time", formValues.any_time);
        } else if (formValues.any_time === "0") {
          data.append("any_time", "0");
          data.append("start_time", this.state.start_time);
          data.append("end_time", this.state.end_time);
        }
        data.append("ref_task_id", "1");
        data.append("task_with_sign_in", formValues.task_with_sign_in);
        data.append("status", formValues.status);
        data.append("priority", formValues.priority);
        if (this.state.isSignChanged) {
          data.append("sp_task_signature", this.state.signatureImage);
        }
        data.append("isSignChanged", this.state.isSignChanged ? "1" : "0");
        if (this.props.id) {
          await this.state.deleteAttachementId.map(async (id: any) => {
            this.props.deleteAttachementId(id);
          });
          this.props.editAdditionalTask(data, this.props.id);
          this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
          this.setState({ isSignChanged: false });
          this.props.handleEditModelClose();
        }
      }
    } catch (error) {
      console.log("ApprovedLog", error);

    }

  };

  editToastClose = () => {
    this.setState({ editToast: false });
  };

  validationSchema = () => {
    const branchId = this.props.branchList.map(
      (branch: IBranchSelect) => branch.value
    );
    return Yup.object().shape({
      title: Yup.string().required("Enter Task Name"),
      description: Yup.string().required("Enter Task Description"),
      cleaners: Yup.array().when("copyDailyTaskStatus", {
        is: false, // Apply validation when copyDailyTaskStatus is false
        then: Yup.array().min(1, "Select at least one cleaner"),
      }),
      branch: Yup.string()
        .oneOf([...branchId], "Invalid Branch")
        .required("Please select branch")
        .nullable(),
    });
  };

  render() {
    console.log(this.props.clientList, "ClientList");

    return (
      <div>
        <Formik

          initialValues={
            {
              client: this.props.data ? this.props.data.client_id : "",
              branch: this.props.data ? this.props.data.branch_id : "",
              cleaner: this.props.data ? this.props.data.branch_id : "",
              title: this.props.data ? this.props.data.title : "",
              status: this.props.data ? this.props.data?.status : "",
              attachments: this.props.data ? this.props.data.attachments : "",
              cleaners: this.props.data
                ? this.props.data.cleaners.map((val: any) => val.cleaner_id)
                : "",
              task_type: this.props.data ? this.props.data.task_type : "",
              // task_date: this.props.data ? this.props.data?.task_date : "",
              description: this.props.data ? this.props.data.description : "",
              any_time: this.props.data
                ? this.props.data.any_time.toString()
                : "",
              start_time: this.props.data
                ? this.props.data.start_time === null
                  ? "00:00:00"
                  : this.props.data.start_time
                : "",
              end_time: this.props.data
                ? this.props.data.end_time === null
                  ? "00:00:00"
                  : this.props.data.end_time
                : "",
              ref_task_id: this.props.data ? this.props.data.ref_task_id : "",
              task_with_sign_in: this.props.data
                ? this.props.data.task_with_sign_in
                : "",
              priority: this.props.data ? this.props.data.priority : "",
              everyWeek: [],
              selectDate: [],
            }
          }

          enableReinitialize={true}
          onSubmit={this.handleEditTask}
          validationSchema={this.validationSchema}

        >

          {(props) => {
            const { values, errors, touched } = props;

            return (
              <Form onChange={this.handleChange}>
                <div className="mb-2">
                  <label className="form-label">
                    Task Name
                    <span className="text-project">*</span>
                  </label>
                  <Field
                    name="title"
                    type={"text"}
                    className="form-control text-field"
                  />
                  <ErrorMessage
                    name="title"
                    component={"div"}
                    className="cus-alert"
                  />
                </div>
                <div className="mb-2">
                  <label className="form-label">
                    Task Description<span className="text-project">*</span>
                  </label>
                  <Field
                    as="textarea"
                    name="description"
                    className="form-control text-area"
                  />
                  <ErrorMessage
                    name="description"
                    component={"div"}
                    className="cus-alert"
                  />
                </div>
                {this.props.copyDailyTaskStatus !== true && (
                  <>
                    <div>
                      <label className="form-label">Attachment</label>
                      <div className="attachment-btn dropdown-field">
                        <input
                          type="file"
                          multiple
                          accept=".pdf,.PDF,.mp4,.MP4,.webm,.WEBM,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.doc,.DOC,.docx.DOCX"
                          style={{ display: "none" }}
                          onChange={this.handleImageChange}
                          id="contained-button-file"
                        />
                        <label htmlFor="contained-button-file">
                          <Button
                            variant="contained"
                            color="default"
                            component="span"
                          >
                            <PaperClipSVG /> <span style={{ color: "black" }}>Add attachment</span>
                          </Button>
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" mb-3 d-flex row">
                        {this.state.attachmentUrls &&
                          this.state.attachmentUrls.map(
                            (obj: any, index: number) => {

                              if (
                                obj.contractor_attachment_type !=
                                "sp_task_signature"
                              ) {
                                return (
                                  <div
                                    className="img-card mr-16px mb-3"
                                    key={index}
                                  >
                                    <span className="delete">
                                      <a
                                        onClick={() =>
                                          this.handleDeleteImage(index)
                                        }
                                      >
                                        <TrashSVG />
                                      </a>
                                    </span>
                                    {obj.type === "download" && (
                                      <span className="download">
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() =>
                                            this.downloadFile(
                                              obj.download_url,
                                              obj.file_name
                                            )
                                          }
                                        >
                                          <AttachmentDownloadSVG />
                                        </a>
                                      </span>
                                    )}

                                    <div
                                      className="img-preview"
                                      onClick={() =>
                                        this.handlePreviewOpen(
                                          obj.file_type,
                                          obj.download_url,
                                          index
                                        )
                                      }
                                    >
                                      <img
                                        className="img-thumb-perview"
                                        src={
                                          obj.file_type === "application/pdf"
                                            ? pdfImage
                                            : obj.file_type === "video/mp4" ||
                                              obj.file_type === "video/webm"
                                              ? videoImage
                                              : obj.file_type === "image/png" ||
                                                obj.file_type === "image/jpeg" ||
                                                obj.file_type === "image/jpg"
                                                ? obj.url
                                                : docImage
                                        }
                                        alt="attach"
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            }
                          )}
                      </div>
                    </div>
                  </>
                )}
                <div className="row">
                  {/* {Constants.SUPERVISOR_MANAGERUTINT !==
                      Constants.LOGIN_USER_TYPE ? ( */}
                  <div className="mb-2 col-md-6">
                    <label className="form-label">
                      Client<span className="text-project">*</span>
                    </label>
                    <Field
                      as="select"
                      className="form-select dropdown-field"
                      name="client"
                    >
                      {this.props.clientList.map((client: ICleaner) => (
                        <option
                          value={client.id.toString()}
                          key={client.id}
                        >{`${client.first_name} ${client.last_name}`}</option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="client"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                  {/* ) : null} */}
                  <div className="mb-2 col-md-6">
                    <label className="form-label">
                      Branch<span className="text-project">*</span>
                    </label>
                    <Field
                      className="custom-select dropdown-field"
                      name="branch"
                      options={this.props.branchList}
                      component={CustomSelect}
                      placeholder="Select Branch"
                      isMulti={false}
                    />
                    <ErrorMessage
                      name="branch"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                </div>
                {this.props.copyDailyTaskStatus !== true && (
                  <div className="mb-2 col-md-12">
                    <label className="form-label">
                      Cleaner<span className="text-project">*</span>
                    </label>
                    <Field
                      className="custom-select"
                      name="cleaners"
                      options={this.props.cleanerList}
                      component={CustomSelect}
                      placeholder="Select Cleaner"
                      isMulti={true}
                    />
                    <ErrorMessage
                      name="cleaners"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                )}
                {this.state.editToast ? (
                  <Toast className="success-alerts">
                    <Toast.Body>
                      <div className="row align-items-center">
                        <div className="col-md-2">
                          <SuccessToastSVG />
                        </div>
                        <div className="col-md-8">
                          {this.props.editadditionalTaskSuccess}
                        </div>
                        <div className="col-md-2">
                          <button
                            type="button"
                            className="btn"
                            onClick={this.editToastClose}
                          >
                            <CloseBtnSVG />
                          </button>
                        </div>
                      </div>
                    </Toast.Body>
                  </Toast>
                ) : (
                  ""
                )}
                <div className="mb-2 col-md-6">
                  <label className="form-label">
                    Date<span className="text-project">*</span>
                  </label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>

                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"

                      value={this.state.task_date}
                      onChange={(newValue: any | null) => {
                        this.setState({ task_date: moment(new Date(newValue?.toString())).format("YYYY-MM-DD") });
                      }} renderInput={(params) => <TextField {...params} />} />
                  </LocalizationProvider>


                  <ErrorMessage
                    name="task_date"
                    component={"div"}
                    className="cus-alert"
                  />
                </div>
                <div className="mb-2 col-md-12 edit-task-time">
                  <label className="form-label">
                    Time<span className="text-project">*</span>
                  </label>
                  <RadioButtonGroup
                    id="radioGroup"
                    label=""
                    value={values.any_time}
                    error={errors.any_time}
                    touched={touched.any_time}
                  >
                    <Field
                      component={RadioButton}
                      name="any_time"
                      id="1"
                      label="Any time"
                    />
                    <Field
                      component={RadioButton}
                      name="any_time"
                      id="0"
                      label=""
                    />
                  </RadioButtonGroup>
                  <div className="edit-task-start-end">
                    <label className="check-time">
                      <span className="check-left-space d-flex">
                        <div className="mr-8px">Start</div>
                        <TimePicker
                          name="start_time"
                          // value={values.start_time}
                          onChange={this.handleStartTime}
                        />
                      </span>
                    </label>
                    <label
                      className="check-time"
                      style={{ marginLeft: "16px" }}
                    >
                      <span className="d-flex">
                        <div className="mr-8px">End</div>
                        <TimePicker
                          name="end_time"
                          // value={values.end_time}
                          onChange={this.handleEndTime}
                        />
                      </span>
                    </label>
                  </div>
                </div>
                <div className="mb-2 col-md-6">
                  <label className="form-label">
                    Task priority<span className="text-project">*</span>
                  </label>
                  <Field
                    className="custom-select"
                    name="priority"
                    options={[
                      { value: TASK_PRIORITY.LOW, label: "Low" },
                      { value: TASK_PRIORITY.MEDIUM, label: "Medium" },
                      { value: TASK_PRIORITY.HIGH, label: "High" },
                    ]}
                    component={CustomSelect}
                    isMulti={false}
                  />
                  <ErrorMessage
                    name="priority"
                    component={"div"}
                    className="cus-alert"
                  />
                </div>
                {this.props.copyDailyTaskStatus !== true ?
                  <div className="row mt-3 mb-3">
                    <label className="form-label">
                      Signature<span className="text-project">*</span>
                    </label>
                    {this.state.signState === true ? (
                      <div className=" mb-3 d-flex row">
                        {this.state.signatureImage &&
                          this.state.signatureImage.map(
                            (obj: any, index: number) => {
                              if (
                                obj.contractor_attachment_type ===
                                "sp_task_signature"
                              ) {
                                return (
                                  <div
                                    className="img-card mr-16px mb-3"
                                    key={index}
                                  >
                                    <img
                                      className="img-thumb-perview"
                                      src={
                                        obj.file_type === "image/png" ||
                                          obj.file_type === "image/jpeg" ||
                                          obj.file_type === "image/jpg"
                                          ? obj.url
                                          : docImage
                                      }
                                      alt="attach"
                                      style={{
                                        width: "130px",
                                        height: "100px",
                                        border: "2px solid #E3DDDA",
                                      }}
                                    />
                                  </div>
                                );
                              }
                            }
                          )}
                      </div>
                    ) : (
                      <div>
                        {this.state.signatureImage !== "" && (
                          <img
                            src={this.state.signatureImage}
                            alt=""
                            style={{
                              width: "130px",
                              height: "100px",
                              border: "2px solid #E3DDDA",
                            }}
                          />
                        )}
                      </div>
                    )}

                    <button
                      type="button"
                      id="create-button"
                      className="btn btn-primary create-button"
                      style={{ width: "40%" }}
                      onClick={() => this.handleSignModal()}
                    >
                      Add
                    </button>
                  </div>
                  : null
                }

                <div className="d-flex">
                  <div className="mr-16px">
                    <button
                      type="submit"
                      id="create-button"
                      className="btn btn-primary create-button"
                    >
                      {this.props.copyDailyTaskStatus === true
                        ? "Copy"
                        : "Update"}{" "}
                      <span
                        id="spinner-border"
                        className="spinner-border spinner-border-sm d-none"
                      ></span>
                      <span
                        id="spinner-border"
                        className="spinner-border spinner-border-sm d-none"
                      ></span>
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn cancel-button"
                      onClick={() => this.props.handleEditModelClose()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <Modal
                  size="lg"
                  show={this.state.signatureModal}
                  onHide={() => this.setState({ signatureModal: false })}
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                      <h3 className="m-0">Add signature</h3>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="p-4">
                    <div className="signature-pad">
                      <canvas
                        ref={this.canvasRef}
                        style={{ width: "100% !important" }}
                        onMouseDown={this.handleMouseDown}
                        onMouseMove={this.handleMouseMove}
                        onMouseUp={this.handleMouseUp}
                        onTouchStart={this.handleTouchStart}
                        onTouchEnd={this.handleTouchEnd}
                        onTouchMove={this.handleTouchMove}
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.handleClear}
                    >
                      Clear
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      id="add-sign-image"
                      style={{ marginLeft: "10px" }}
                      onClick={this.handleConvertToImage}
                    >
                      Add
                    </button>
                  </Modal.Footer>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  clientList: state.tasktemplate.clientList,
  branchList: state.tasktemplate.branchList,
  // userLoginDetails: state.sidebar.userLoginDetails,
  cleanerList: state.tasktemplate.cleanerList,
  filterTaskTypeById: state.dailytask.filterTaskTypeById,
  filterTaskPriorityById: state.dailytask.filterTaskPriorityById,
  filterTaskStatusById: state.dailytask.filterTaskStatusById,
  filterTaskDueTypeByValue: state.dailytask.filterTaskDueTypeByValue,
  filterTaskDateTypeValue: state.dailytask.filterTaskDateTypeValue,
  filterTaskClientById: state.dailytask.filterTaskClientById,
  filterTaskBranchById: state.dailytask.filterTaskBranchById,
  filterSpecialRequestTaskStatusById:
    state.dailytask.filterSpecialRequestTaskStatusById,
  editadditionalTaskSuccess: state.dailytask.editadditionalTaskSuccess,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    listAllClient: bindActionCreators(listAllClient, dispatch),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
    deleteAttachementId: bindActionCreators(deleteAttachementId, dispatch),
    listAllCleaner: bindActionCreators(listAllCleaner, dispatch),
    editDailyTask: bindActionCreators(editDailyTask, dispatch),
    editAdditionalTask: bindActionCreators(editAdditionalTask, dispatch),
    copyDailyTask: bindActionCreators(copyDailyTask, dispatch),
    EditModalDailyTask: bindActionCreators(EditModalDailyTask, dispatch),
    makeScheduleDailyTask: bindActionCreators(makeScheduleDailyTask, dispatch),
    updateEditDailyTaskDlgStatus: bindActionCreators(
      updateEditDailyTaskDlgStatus,
      dispatch
    ),
    updateDailyTaskFetchStatus: bindActionCreators(
      updateDailyTaskFetchStatus,
      dispatch
    ),
    getAllAdditionalTasks: bindActionCreators(getAllAdditionalTasks, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditApprovedTask);
