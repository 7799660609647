import React, { Component } from "react";

import { AllReportCardProps } from "../../types/allreport";
import Constants from "../../shared/Constants";
import haversine from "haversine-distance";
import moment from "moment";

export default class AllReportCard extends Component<AllReportCardProps> {
  render() {
    const {
      id,
      first_name,
      last_name,
      branch_name,
      reason,
      sign_in_date_time,
      sign_out_date_time,
      branch_lat,
      branch_lng,
      sign_out_lat,
      sign_out_lng,
      client_first_name,
      client_last_name
    } = this.props.task;

    if (
      branch_lat !== "0" &&
      branch_lng !== "0" &&
      sign_out_lat !== "0" &&
      sign_out_lng !== "0" &&
      branch_lat !== null &&
      branch_lng !== null &&
      sign_out_lat !== null &&
      sign_out_lng !== null
    ) {
      //First point in your haversine calculation
      var point1: any = { lat: branch_lat, lng: branch_lng };

      //Second point in your haversine calculation
      var point2: any = { lat: sign_out_lat, lng: sign_out_lng };

      var haversine_m = haversine(point1, point2); //Results in meters (default)
      var haversine_km: any = haversine_m / 1000; //Results in kilometers
      haversine_km = haversine_km.toFixed(2) + " KM";
    } else {
      var haversine_km: any = "";
    }
    return (
      <tr>
        <td>{`${first_name} ${last_name}`}</td>
        <td>{client_first_name +' '+ client_last_name}</td>
        <td>{branch_name}</td>
        <td>
          <p>
            {sign_in_date_time != null
              ? moment(sign_in_date_time, "YYYY-MM-DD HH:mm:ss").format(
                  "DD/MM/YYYY hh:mm A"
                )
              : ""}
          </p>
        </td>
        <td>
          <p>
            {sign_out_date_time != null
              ? moment(sign_out_date_time, "YYYY-MM-DD HH:mm:ss").format(
                  "DD/MM/YYYY hh:mm A"
                )
              : ""}
          </p>
        </td>
        <td>{reason}</td>
        {(Constants.LOGIN_USER_TYPE === Constants.ADMINUTINT ||
          Constants.LOGIN_USER_TYPE === Constants.SUPERVISORUTINT) && (
          <td>{haversine_km}</td>
        )}
      </tr>
    );
  }
}
