import * as Yup from "yup";

import { Button, Offcanvas, Toast } from "react-bootstrap";
import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  IContractDashboardState,
  IContractProps,
} from "../../types/contract.type";
import {
  SortDown,
  SortReqular,
  SortUp,
  SuccessToastSVG,
} from "../../assets/images/ImagesV2";
import {
  filterContract,
  getAllContracts,
  handleContractSort,
  updateAddClientContract,
  updateContractFetchStatus,
  updateSidebar,
} from "../../shared/Reducers";

import AddClientContract from "./AddClientContract";
import ClientContractTable from "./ClientContractTable";
import { CloseBtnSVG } from "../../assets/images/Location";
import Constants from "../../shared/Constants";
import { CustomSelect } from "../../shared/Components/CustomSelect";
import React from "react";
import ReactPaginate from "react-paginate";
import { RootState } from "../../index";
import { SearchSVG } from "../../assets/images/EmployeesSVG";
import SideBar from "../../components/sidebar/SideBar";
import TopNav from "../../components/topnav/TopNav";
import { connect } from "react-redux";

class ClientContract extends React.Component<
  IContractProps,
  IContractDashboardState
> {
  constructor(props: IContractProps) {
    super(props);
    this.state = {
      show: false,
      total_contract: "",
      searchTerm: "",
      addToast: false,
      deleteToast: false,
      editToast: false,
      closeAdd: false,
      sortBy: null,
      sortDirection: null,
      pageOffset: Constants.OFFSET,
      addContractStatus: false,
      fieldName: "",
      orderType: "",
      initialPagestate: 0,
      pagehandle: false,
      timeout: null,
    };
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount(): void {
    this.props.updateSidebar(true);
    document.title = "Client Contract";
    this.getContracts(Constants.OFFSET, Constants.PER_PAGE);
  }

  componentDidUpdate(
    prevProps: Readonly<IContractProps>,
    prevState: Readonly<IContractDashboardState>,
    snapshot?: any
  ): void {
    if (
      prevProps.contractSortBy !== this.props.contractSortBy ||
      prevProps.contractOrderBy !== this.props.contractOrderBy
    ) {
      this.getContracts(Constants.OFFSET, Constants.PER_PAGE);
    }

    if (prevProps.addContractStatus !== this.props.addContractStatus) {
      if (!this.props.addContractStatus) {
        this.getContracts(Constants.OFFSET, Constants.PER_PAGE);
      }
    }

    if (prevProps.contractFetchStatus !== this.props.contractFetchStatus) {
      if (this.props.contractFetchStatus) {
        this.getContracts(Constants.OFFSET, Constants.PER_PAGE);
      }
    }
    if (prevProps.addContractSuccess !== this.props.addContractSuccess) {
      if (this.props.addContractSuccess === "Successfully created") {
        this.setState({ addToast: true });
        this.setState({ closeAdd: false });
        setTimeout(() => {
          this.setState({ addToast: false });
          this.setState({ closeAdd: false });
        }, 5000);
      }
    }
    if (prevProps.editContractSuccess !== this.props.editContractSuccess) {
      if (this.props.editContractSuccess === "Successfully updated") {
        this.setState({ editToast: true });
        setTimeout(() => {
          this.setState({ editToast: false });
        }, 5000);
      }
    }
    if (prevProps.deleteContractSuccess !== this.props.deleteContractSuccess) {
      if (this.props.deleteContractSuccess === "Successfully deleted") {
        // page reset
        let Offset: number = 0;
        let paginationActive = document.querySelector(
          ".pagination li.active a"
        ) as HTMLInputElement;
        if (paginationActive !== null) {
          let page: string | null = paginationActive.textContent;
          if (page !== null) {
            let pageNum: number = parseInt(page);
            Offset = (pageNum - 1) * Constants.PER_PAGE;
            console.log(Offset, Constants.PER_PAGE);

            this.getContracts(Offset, Constants.PER_PAGE);
          }
        }
        this.setState({ deleteToast: true });
        setTimeout(() => {
          this.setState({ deleteToast: false });
        }, 5000);
      }
    }
  }

  addToastClose = () => {
    this.setState({ addToast: false });
  };

  editToastClose = () => {
    this.setState({ editToast: false });
  };

  deleteToastClose = () => {
    this.setState({ deleteToast: false });
  };

  getContracts = async (offset: number, limit: number) => {
    try {
      const query = `search=&sort=${this.props.contractSortBy}&order=${this.props.contractOrderBy}&offset=${offset}&limit=${limit}&client=all&branch=all`;
      this.props.getAllContracts(query);
    } catch (error) {
      console.log(error);
    }
  };

  handleAddClientContractCLose = (status: boolean) => {
    this.handleClose();
    this.getContracts(Constants.OFFSET, Constants.PER_PAGE);

    // if (status) {
    //   this.getContracts(Constants.OFFSET, Constants.PER_PAGE);
    // }
  };

  // Canvas Show
  handleShow() {
    // this.props.updateAddClientContract(true);
    this.setState({ addContractStatus: true });
  }

  // Canvas Close
  handleClose() {
    // this.props.updateAddClientContract(false);
    this.setState({ addContractStatus: false });
  }

  // Search Branch Filter
  handleFilter = async (e: any) => {
    const value: string = e.target.value.toLowerCase();
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

    this.setState({
      searchTerm: value,
      timeout: setTimeout(async () => {
        console.log("User stopped typing:", value);
        try {
          const query = `search=${value}&sort=${this.props.contractSortBy}&order=${this.props.contractOrderBy}&offset=0&limit=10&client=all&branch=all`;
          this.props.getAllContracts(query);
          console.log(query,"today");
          
        } catch (error) {
          console.log(error);
        }
        // Trigger your function here that you want to call when user stops typing
      }, 1000), // Set the delay time in ms
    });
  };

  handlePageClick = (e: any) => {
    if (e.selected < this.props.total_count / Constants.PER_PAGE) {
      const query = `search=${this.state.searchTerm}&sort=${
        this.state.fieldName
      }&order=${this.state.orderType}&offset=${
        e.selected * Constants.PER_PAGE
      }&limit=${Constants.PER_PAGE}&client=all&branch=all`;
      this.props.getAllContracts(query);
      // this.getContracts(e.selected * Constants.PER_PAGE, Constants.PER_PAGE);
    }
  };

  handleSort = (column: string) => {
    if (this.state.sortBy === column) {
      this.setState({
        sortDirection: this.state.sortDirection === "asc" ? "desc" : "asc",
      });
      this.props.handleContractSort(
        column,
        this.state.sortDirection === "asc" ? "desc" : "asc"
      );
    } else {
      this.setState({ sortBy: column });
      this.setState({ sortDirection: "asc" });
      this.props.handleContractSort(column, "asc");
    }
  };

  validationSchema = () => {
    return Yup.object().shape({
      column_name: Yup.string().required("Select Filter Column"),
      order_type: Yup.string().required("Select Sort Order"),
    });
  };
  handleSearch = async (formValue: any) => {
    try {
      this.setState({ pagehandle: true }, () => {
        setTimeout(async () => {
          this.setState({
            fieldName: formValue.column_name,
            orderType: formValue.order_type,
            pagehandle: false,
          });
          const query = `search=${this.state.searchTerm}&sort=${formValue.column_name}&order=${formValue.order_type}&offset=${Constants.OFFSET}&limit=${Constants.PER_PAGE}&client=all&branch=all`;
          await this.props.getAllContracts(query);
        }, 0);
      });
    } catch (error) {
      console.log(error);
    }
  };

  render(): React.ReactNode {
    let taskStatusOption: any = [];
    taskStatusOption = [
      { value: "first_name", label: "Client" },
      { value: "start_date", label: "Start Date" },
      { value: "end_date", label: "End Date" },
    ];
    let contractOrder: any = [];
    contractOrder = [
      { value: "asc", label: "Ascending" },
      { value: "desc", label: "Descending" },
    ];

    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className={this.props.status ? "main-panel" : "main-panel2"}>
          <TopNav />
          <div className="content-wrapper">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="mb-0">Client Contract</h3>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-end">
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12 col-lg-4">
                  <div className="input-group search">
                    <span
                      className="input-group-text search-icon"
                      id="basic-addon1"
                    >
                      <SearchSVG />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={this.state.searchTerm}
                      onInput={this.handleFilter}
                      onChange={(e) =>
                        this.setState({ searchTerm: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12 col-lg-6">
                  <div>
                    <Formik
                      validationSchema={this.validationSchema}
                      onSubmit={this.handleSearch}
                      initialValues={{
                        column_name: "",
                        order_type: "asc",
                      }}
                    >
                      <Form className="d-flex flex-column flex-md-row">
                        <Field
                          className="custom-select dropdown-field flex-mr-16px w-180px"
                          name="column_name"
                          options={taskStatusOption}
                          component={CustomSelect}
                          placeholder="Select Column"
                          isMulti={false}
                        />
                        <Field
                          className="custom-select dropdown-field flex-mr-16px res-mt-3 w-180px"
                          name="order_type"
                          options={contractOrder}
                          component={CustomSelect}
                          placeholder="Select Order"
                          isMulti={false}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary filter-button res-mt-3"
                        >
                          Filter
                        </button>
                      </Form>
                    </Formik>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 text-start text-lg-end tab-res-mt-3">
                  <Offcanvas
                    className="contract-offcanvas"
                    placement="end"
                    show={this.state.addContractStatus}
                    onHide={() => this.handleClose()}
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>
                        <span className="offc-title">Add Contract</span>
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <AddClientContract
                        onClose={this.handleAddClientContractCLose}
                      />
                    </Offcanvas.Body>
                  </Offcanvas>
                  <Button
                    aria-controls="offcanvasRight"
                    className="btn btn-primary login-button mob-res-w-100 add-client-contract"
                    onClick={() => this.handleShow()}
                  >
                    Add Contract
                  </Button>
                  {this.state.addToast ? (
                    <Toast className="success-alerts">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <SuccessToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.addContractSuccess}
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.addToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                  {this.state.editToast ? (
                    <Toast className="success-alerts">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <SuccessToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.editContractSuccess}
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.editToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                  {this.state.deleteToast ? (
                    <Toast className="success-alerts">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <SuccessToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.deleteContractSuccess}
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn"
                              onClick={this.deleteToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      {this.props.filteredContractData.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table employee-table">
                            <thead>
                              <tr>
                                {/* <th scope="col">ID</th> */}
                                <th
                                  scope="col"
                                  onClick={() => this.handleSort("first_name")}
                                >
                                  CLIENT
                                  <span className="table-sort-wrap">
                                    <span
                                      className={
                                        this.state.sortBy === "first_name" &&
                                        this.state.sortDirection === "asc"
                                          ? ""
                                          : "d-none"
                                      }
                                    >
                                      <SortDown />
                                    </span>
                                    <span
                                      className={
                                        this.state.sortBy === "first_name" &&
                                        this.state.sortDirection === "desc"
                                          ? ""
                                          : "d-none"
                                      }
                                    >
                                      <SortUp />
                                    </span>
                                    <span
                                      className={
                                        this.state.sortBy !== "first_name"
                                          ? ""
                                          : "d-none"
                                      }
                                    >
                                      <SortReqular />
                                    </span>
                                  </span>
                                </th>
                                <th
                                  scope="col"
                                  // onClick={() => this.handleSort("start_date")}
                                >
                                 DURATION OF PERIOD
                                </th>
                                <th scope="col">ENTITY NAME</th>
                                <th scope="col">ABN STATUS</th>
                                <th scope="col">ENTITY TYPE</th>
                                <th scope="col">BUSINESS LOCATION</th>
                                <th scope="col">ACTION</th>
                              </tr>
                            </thead>
                            <ClientContractTable
                              data={this.props.filteredContractData}
                              onDelete={() =>
                                this.getContracts(
                                  Constants.OFFSET,
                                  Constants.PER_PAGE
                                )
                              }
                              onEditClose={() =>
                                this.getContracts(
                                  Constants.OFFSET,
                                  Constants.PER_PAGE
                                )
                              }
                              deleteContractStatus={false}
                            />
                          </table>
                        </div>
                      ) : (
                        <p className="text-center">
                          There is no contract record found
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                {this.props.total_count > 10 ? (
                  <div className="col-md-12">
                    {this.state.pagehandle ? null : (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.props.total_count / Constants.PER_PAGE}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        nextClassName={"next-page"}
                        activeClassName={"active"}
                      />
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  addContractStatus: state.contract.addContractStatus,
  contractFetchStatus: state.contract.contractFetchStatus,
  contractData: state.contract.contractData,
  filteredContractData: state.contract.filteredContractData,
  total_count: state.contract.total_count,
  addContractSuccess: state.contract.addContractSuccess,
  editContractSuccess: state.contract.editContractSuccess,
  deleteContractSuccess: state.contract.deleteContractSuccess,
  contractSortBy: state.contract.contractSortBy,
  contractOrderBy: state.contract.contractOrderBy,
  status: state.sidebar.status,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateAddClientContract: bindActionCreators(
      updateAddClientContract,
      dispatch
    ),
    updateContractFetchStatus: bindActionCreators(
      updateContractFetchStatus,
      dispatch
    ),
    filterContract: bindActionCreators(filterContract, dispatch),
    getAllContracts: bindActionCreators(getAllContracts, dispatch),
    handleContractSort: bindActionCreators(handleContractSort, dispatch),
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientContract);
