import React from "react";
import { RootState } from '../../index';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IAuditTableProps, IAuditTableState } from "../../types/audit.type";
import AuditCard from "./CertificateCard";
import EditAudit from "./EditCertificate";
import ViewAudit from "./ViewAudit";
import { Modal, Offcanvas } from "react-bootstrap";
import { deleteCertificate, getAllCertificate, getCertificateById, updateEditAuditDlgStatus, updateEditCertificateDlgStatus, updateViewAudit, viewAuditById } from "../../shared/Reducers";
import { ICertificateTableProps, ICertificateTableState } from "../../types/certificate.type";

class CertificateTable extends React.Component<ICertificateTableProps, ICertificateTableState> {
    constructor(props: ICertificateTableProps) {
        super(props);
        this.state = {
            copyStatus:false
        }
    }

    handleDeleteCertificate = async (id: string) => {
        await this.props.deleteCerticate(id);
        console.log(id);
        // const query = `search=&sort=&order=&offset=0&limit=10&client=all&branch=all`
        // setTimeout(() => {
        // this.props.getAllCertificate(query);
        // }, 1000);
    }

    handleEditBranch = async (id:string) => {
        try {
            this.props.getAuditById(id);
        } catch (error) {
            console.log(error);
        }
    }

    handleViewAudit = async (id:string) => {
        try {
            this.props.viewAuditById(id);
        } catch (error) {
            console.log(error);
        }
    }

    handleCopyAuditTask = async (id: string) => {
        try {
            this.setState({copyStatus:true})
        //   this.props.getDailyTaskByIdForDuplicate(id);
        this.props.getAuditById(id);


        } catch (error) {
          console.log(error);
        }
      };

    render(): React.ReactNode {
        console.log(this.props.data);
        
        return (
            <tbody>
                 {
                    this.props.data.map((audits, index) =>
                        <AuditCard certificates={audits}
                            key={index + audits.id}
                            handleDeleteCertificate={this.handleDeleteCertificate}
                            handleEditAudit={this.handleEditBranch}
                            handleViewAudit={this.handleViewAudit}
                            deleteAuditStatus={false}
                            handleCopyAuditTask={this.handleCopyAuditTask}
                            reqPage={this.props.reqPage}
                            />)
                } 
                <Offcanvas placement="end" show={this.props.editAuditStatus} onHide={() => this.props.updateEditAuditDlgStatus(false)}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title><span className="offc-title">Edit Certificate</span></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <EditAudit  id={this.props.id} data={this.props.selectedAuditData} copyAuditStatus={this.state.copyStatus} />
                    </Offcanvas.Body>
                </Offcanvas>
                <Modal
                size="xl"
                className="task-history"
                dialogClassName="modal-90w"
                show={this.props.viewAuditStatus}
                onHide={() => this.props.updateViewAudit(false)}
                aria-labelledby="example-modal-sizes-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            <h3 className="m-0">{this.props.viewAuditData && this.props.viewAuditData.name}</h3>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                        <ViewAudit/>
                    </Modal.Body>
                </Modal>
            </tbody>
        )
    }
}
const mapStateToProps = (state: RootState) => ({
    editAuditStatus: state.certificate.editCertificateStatus,
    selectedAuditData: state.certificate.editCertificateData,
    id: state.certificate.id,
    viewAuditStatus: state.audit.viewAuditStatus,
    viewAuditData: state.audit.viewAuditData
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateEditAuditDlgStatus: bindActionCreators(updateEditCertificateDlgStatus, dispatch),
        getAuditById: bindActionCreators(getCertificateById, dispatch),
        getAllCertificate: bindActionCreators(getAllCertificate, dispatch),
        deleteCerticate: bindActionCreators(deleteCertificate, dispatch),
        viewAuditById: bindActionCreators(viewAuditById, dispatch),
        updateViewAudit: bindActionCreators (updateViewAudit, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CertificateTable);