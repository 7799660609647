import { Button, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import {
  DisableSVG,
  EnableSVG,
  NotVerifiedSVG,
  VerifiedSVG,
} from "../../assets/images/UsermanagementsSVG";
import { Dispatch, bindActionCreators } from "redux";
import {
  IUsermanagement,
  IUsermanagementCardProps,
  IUsermanagementCardState,
} from "../../types/userManagement.type";
import {
  TableDeleteSVG,
  TableEditSVG,
  TableViewSVG,
} from "../../assets/images/ImagesV2";
import {
  getAllUsermanagements,
  updateEditUsermanagementDlgStatus,
  getUserPermission,
  getCleanerSignout
} from "../../shared/Reducers";

import Constants from "../../shared/Constants";
import DeleteConfirm from "./DeleteConfirm";
import EmailVerificationConfirm from "./EmailVerificationConfirm";
import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { constants } from "buffer";


class UsermanagementCard extends React.Component<
  IUsermanagementCardProps,
  IUsermanagementCardState
> {
  constructor(props: IUsermanagementCardProps) {
    super(props);
    this.state = {
      displayVerifyEmailConfirm: false,
      displayDelete: false,
      currentUsermanagementId: "",
      displayView: false,
      show: false,
      Permission_modal: false,
      usermanagementData: {} as IUsermanagement,
      showModal: false,
    };
  }

  // Delete Usermanagement Based on ID
  handleDelete = (id: string) => {
    this.setState({ displayDelete: true, currentUsermanagementId: id });
  };
  // Verify Email Usermanagement Based on ID
  handleVerifiyEmail = (id: string) => {
    this.setState({
      displayVerifyEmailConfirm: true,
      currentUsermanagementId: id,
    });
  };

  handleEmailVerificationClose = (status: boolean) => {
    this.setState({
      displayVerifyEmailConfirm: false,
      currentUsermanagementId: "",
    });
    if (status) {
      let verifyStatus = 1;
      this.props.handleEmailVerifyUsermanagement(
        this.state.currentUsermanagementId,
        verifyStatus
      );
    }
  };

  // Delete Popup close
  handleDeleteClose = (status: boolean) => {
    console.log("call");
    this.setState({ displayDelete: false, currentUsermanagementId: "" });
    if (status) {
      this.props.handleDeleteUsermanagement(this.state.currentUsermanagementId);
      const query = `search=&sort=&order=&offset=${Constants.OFFSET}&limit=${Constants.PER_PAGE}&user_type=all`;
      this.props.getAllUsermanagements(query);
    }
  };

  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  handleSignOut = (id: any) => {
    this.props.getCleanerSignout(id)
    this.closeModal()
    const query = `search=&sort=&order=&offset=${Constants.OFFSET}&limit=${Constants.PER_PAGE}&user_type=all`;
    this.props.getAllUsermanagements(query);
  }

  render(): React.ReactNode {
    const { showModal } = this.state;
    const {
      id,
      user_type_id,
      first_name,
      last_name,
      email,
      phone,
      is_email_verified,
      sign_out_date_time,
      status,
      user_type_name,
      sign_id
    } = this.props.usermanagement;

    return (
      <tr>
        <td className="text-uppercase">
          {user_type_name.replace(/_/g, ' ')}
          {(user_type_id === 3 && sign_out_date_time === null) && (
            <button style={{ border: "none", borderRadius: "5px", backgroundColor: "rgb(218, 37, 55)", color: "white", marginLeft: "10px" }} onClick={this.openModal}>SignOut</button>
          )}
        </td>
        <Modal className="delete-modal" show={showModal} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>SignOut Cleaner</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="delete-modal-body">
              <p>Do you want to sign out this cleaner</p>
            </div>

            <div className="delete-footer qr-modal-footer d-flex justify-content-end pt-2">
              <Button className="btn btn-outline-success delete-cancel" variant="outline" onClick={this.closeModal}>No</Button>
              <Button className="delete-cancel delete-button" variant="primary"
                onClick={() => {
                  this.handleSignOut(sign_id)
                }

                }
              >Yes</Button>
            </div>
          </Modal.Body>
        </Modal>
        <td>{first_name} {last_name}</td>
        <td>
          <span>
            {email}
            <span className="p-1">
              {is_email_verified !== Constants.IS_ACTIVE &&
                user_type_id !== Constants.CLIENTUTINT && <NotVerifiedSVG />}
              {is_email_verified === Constants.IS_ACTIVE &&
                user_type_id !== Constants.CLIENTUTINT && <VerifiedSVG />}
            </span>
            <span className="p-1">
              {is_email_verified !== Constants.IS_ACTIVE &&
                user_type_id !== Constants.CLIENTUTINT && (
                  <p
                    className="completed-badge view-task-comment-badge cursor-pointer"
                    onClick={() => this.handleVerifiyEmail(id)}
                  >
                    Verify
                  </p>
                )}
              {/* {is_email_verified === Constants.IS_ACTIVE &&
                user_type_id !== Constants.CLIENTUTINT && <p className="unstatisfied-badge view-task-comment-badge" >Not verify</p>} */}
            </span>
          </span>
        </td>
        {/* <td>
          <span className="mr-20px">
            <a
              href="javascript:void(0);"
            >
              <Button
                className="varient view-task-comment-badge completed-badge"
                onClick={() => this.props.getUserPermission("users",id)}
              >
                Permission
              </Button>
            </a>
          </span>
        </td> */}
        <td>{phone}</td>
        <td>
          <span>
            {status !== Constants.IS_ACTIVE && <DisableSVG />}
            {status === Constants.IS_ACTIVE && <EnableSVG />}
          </span>
        </td>
        <td>
          <div className="btn-group">
            <span className="mr-20px">
              <a
                href="javascript:void(0);"
                onClick={() => this.props.handleViewUsermanagement(id)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"View"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableViewSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
            <span className="mr-20px">
              <a
                href="javascript:void(0);"
                onClick={() => this.props.handleEditUsermanagement(id)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"Edit"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableEditSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
            <span>
              <a
                href="javascript:void(0);"
                onClick={() => this.handleDelete(id)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"Delete"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableDeleteSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
            <EmailVerificationConfirm
              displayVerifyEmailConfirm={this.state.displayVerifyEmailConfirm}
              verifyEmail={email}
              verifyUser={first_name + " " + last_name}
              handleEmailVerificationClose={this.handleEmailVerificationClose}
            />
            <DeleteConfirm
              displayDelete={this.state.displayDelete}
              deletedName={
                user_type_id === Constants.CLIENTUTINT
                  ? "client " +
                  first_name +
                  " " +
                  last_name +
                  "? If you deleted then the related branch, contract, task template, schedule and task would be deleted."
                  : first_name + " " + last_name
              }
              handleDeleteClose={this.handleDeleteClose}
            />
          </div>
        </td>
      </tr>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  editUsermanagementStatus: state.usermanagement.editUsermanagementStatus,
  singOut_id: state.app.singOut_id,

});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateEditUsermanagementDlgStatus: bindActionCreators(
      updateEditUsermanagementDlgStatus,
      dispatch
    ),
    getAllUsermanagements: bindActionCreators(getAllUsermanagements, dispatch),
    getUserPermission: bindActionCreators(getUserPermission, dispatch),
    getCleanerSignout: bindActionCreators(getCleanerSignout, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsermanagementCard);
