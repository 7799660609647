import React from "react";
import { RootState } from '../../index';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { updateEditAuditDlgStatus } from "../../shared/Reducers";
import DeleteAudit from "./DeleteAudit";
import { IAuditCardProps, IAuditCardState } from "../../types/audit.type";
import { TableDeleteSVG, TableEditSVG, TableViewSVG } from "../../assets/images/ImagesV2";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

class AuditCard extends React.Component<IAuditCardProps, IAuditCardState> {
    constructor(props: IAuditCardProps) {
        super(props);
        this.state = {
            show: false,
            displayDelete: false,
            currentAuditId: "",
            editShow: false,
            displayView: false
        }
    }

    handleClose = () => {
        this.setState({ show: false })
    }

    handleShow = () => {
        this.setState({ show: true })
    }
    handleViewAudit = (id: string) => {
        this.setState({ displayView: true, currentAuditId: id })
    }

    handleViewClose = (status: boolean) => {
        this.setState({ displayView: false });
    }


    // Delete audit based on ID
    handleDelete = (id: string) => {
        this.setState({ displayDelete: true, currentAuditId: id })
    }

    // delete popup close
    handleDeleteClose = (status: boolean) => {
        this.setState({ displayDelete: false, currentAuditId: "" })
        if (status) {
            this.props.handleDeleteAudit(this.state.currentAuditId)
        }
    }

    render(): React.ReactNode {
        const { branch_id,  branch_name,  client_id,  first_name,  id,  last_name,  name, area_count, task_count, comments_count, score } = this.props.audits;
        console.log("this.props.audits",this.props.audits);
        
        return (
            <tr>
                <td>{name}</td>
                <td>{first_name+" "+last_name}</td>
                <td>{branch_name}</td>
                <td>{area_count}</td>
                <td>{task_count}</td>
                <td>{comments_count}</td>
                <td>{score+"/"+task_count}</td>
                {this.props.reqPage==="audit"?
                <>
                <td>
          <span>
            <a
              href="javascript:void(0)"
              onClick={() => this.props.handleCopyAuditTask(id)}
            >
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{"Copy"}</Tooltip>}
              >
                <Button className="btn btn-secondary btn-cleaner-count fs-12px">
                  Copy
                </Button>
              </OverlayTrigger>
            </a>
          </span>
        </td>
                {/* <td>Created</td> */}
                <td>
                    <div className="btn-group">
                        <span className="mr-20px">
                            <a href="javascript:void(0)" onClick={() => this.props.handleViewAudit(id)}>
                                <OverlayTrigger placement="top"
                                    overlay={<Tooltip>{"View"}</Tooltip>}>
                                   <Button className="action-btn"><TableViewSVG/></Button>
                                </OverlayTrigger>
                            </a>
                            </span>
                            <span className="mr-20px">
                            <a href="javascript:void(0)" onClick={() => this.props.handleEditAudit(id)}>
                                <OverlayTrigger placement="top"
                                    overlay={<Tooltip>{"Edit"}</Tooltip>}>
                                    <Button className="action-btn"><TableEditSVG /></Button>
                                </OverlayTrigger>
                            </a>
                        </span>
                       
                        <span>
                            <a href="javascript:void(0)" onClick={() => this.handleDelete(id)}>
                                <OverlayTrigger placement="top"
                                    overlay={<Tooltip>{"Delete"}</Tooltip>}>
                                   <Button className="action-btn"><TableDeleteSVG /></Button>
                                </OverlayTrigger>
                            </a>
                        </span>
                        <DeleteAudit displayDelete={this.state.displayDelete} deletedName={name} handleDeleteClose={this.handleDeleteClose} />
                    </div>
                </td>
                </>
                :null}
            </tr>
        )
    }
}
const mapStateToProps = (state: RootState) => ({
    editAuditStatus: state.audit.editAuditStatus
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateEditAuditDlgStatus: bindActionCreators(updateEditAuditDlgStatus, dispatch),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AuditCard);