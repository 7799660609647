import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DisableSVG, EnableSVG } from "../../assets/images/UsermanagementsSVG";
import React, { Component } from "react";

import { AllTaskCardProps } from "../../types/allreport";
import Constants from "../../shared/Constants";
import moment from "moment";

export default class AllTaskCard extends Component<AllTaskCardProps> {
  render() {
    console.log("this.props.all_task", this.props.all_task);

    const {
      title,
      first_name,
      special_request_task_status,
      task_status,
      cleaners,
      task_type,
      last_name,
      branch_name,
      start_time,
      end_time,
      any_time,
      id,
      task_date,
      is_active,
      status,
      priority,
      task_status_name,
      task_feedback_name
    } = this.props.all_task;
    return (
      <tr>
        <td>{title}</td>
        <td>
          {" "}
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip>
                {cleaners.map((element: any) => (
                  <p className="text-white mb-0 fs-12px">
                    {element.first_name + " " + element.last_name}
                  </p>
                ))}
              </Tooltip>
            }
          >
            {cleaners.length > 0 ? (
              <Button
                variant="light"
                className="btn btn-secondary btn-cleaner-count fs-12px"
              >
                {cleaners.length}
              </Button>
            ) : (
              <></>
            )}
          </OverlayTrigger>
        </td>
        <td>
          {/* Task types */}
          {task_type === Constants.REGULAR_TASK
            ? "Regular"
            : task_type === Constants.SPECIAL_REQUEST
            ? "Special Request"
            : task_type === Constants.ADDITIONAL_TASK
            ? "Additional Task"
            : task_type === Constants.RECOMMENDED_TASK
            ? "Recommended Task"
            : ""
          }
        </td>
        <td>
          {" "}
          {
            task_status_name !== null && (
              <p className={task_status_name + "-badge view-task-comment-badge"}>
                {task_status_name}
              </p>
            )
          }

        </td>
        <td>
          {
            task_feedback_name !== null && (
              <p className={task_feedback_name + "-badge view-task-comment-badge"}>
                {task_feedback_name}
              </p>
            )
          }

        </td>
        <td>{`${first_name} ${last_name}`}</td>
        <td>{branch_name}</td>
        <td>{moment(task_date).format("DD/MM/YYYY")}</td>
        <td>
          {any_time === 1
            ? `Any Time`
            : moment(start_time, "HH:mm:ss").format("hh:mm A") +
            "-" +
            moment(end_time, "HH:mm:ss").format("hh:mm A")}
        </td>
        <td>
          {" "}
          <span>
            {status !== Constants.IS_ACTIVE && <DisableSVG />}
            {status === Constants.IS_ACTIVE && <EnableSVG />}
          </span>
        </td>
      </tr>
    );
  }
}
