import * as Yup from "yup";

import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { addAudit, listAllClient, listAllBranch } from "../../shared/Reducers";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
// date picker
import moment from "moment";
import { PlusSVG, PlusCloseSVG } from "../../assets/images/UsermanagementsSVG";
import Select from "react-select";
import { Modal, ProgressBar, Toast } from "react-bootstrap";

type Props = {
  onClose: (status: boolean) => void;
  addAuditError?: any;
  addAudit?: any;
  processTrigger: any;
  addAuditSuccess: any;
  progressPercentage: number;
  clientList?: any;
  branchList?: any;
  listAllClient?: any;
  listAllBranch?: any;
};

type TAddAuditState = {
  areaTask: any;
  client_id: any;
  branch_id: any;
  clientErrorShow: boolean;
  branchErrorShow: boolean;
};

const reactSelectCusStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#fff",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    },
  }),
};

class AddAudit extends React.Component<Props, TAddAuditState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      areaTask: [],
      client_id: "",
      branch_id: "",
      clientErrorShow: false,
      branchErrorShow: false,
    };
  }

  componentDidMount(): void {
    this.listData();

    // default area task
    let addArea: any = {
      area: "",
      task: [""],
    };
    let areaTask: any = this.state.areaTask;
    areaTask.push(addArea);

    this.setState({ areaTask: areaTask });

    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.add("d-none");
      button.removeAttribute("disabled");
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<TAddAuditState>,
    snapshot?: any
  ): void {
    if (prevProps.processTrigger !== this.props.processTrigger) {
    }
    if (prevProps.addAuditSuccess !== this.props.addAuditSuccess) {
    }
  }

  listData = async () => {
    await this.props.listAllClient();
  };

  handleAddAudit = async (formValues: any) => {

    let data: any = [];
    this.setState({ clientErrorShow: false });
    this.setState({ branchErrorShow: false });

    if (
      this.state.client_id !== "" &&
      this.state.branch_id !== "" &&
      this.state.areaTask.length > 0
    ) {
      data = {
        name: formValues.name,
        client: this.state.client_id,
        branch: this.state.branch_id,
        area_task: this.state.areaTask,
      };
      var element = document.getElementById("spinner-border");
      var button = document.getElementById("create-button");
      if (element !== null && button !== null) {
        element.classList.remove("d-none");
        button.setAttribute("disabled", "");
      }
      await this.props.addAudit(data);
      setTimeout(() => {
        this.props.onClose(false);
      }, 1000);
    } else {
      if (this.state.client_id === "") {
        this.setState({ clientErrorShow: true });
      }
      if (this.state.branch_id === "") {
        this.setState({ branchErrorShow: true });
      }
      if (this.state.areaTask.length === 0) {
      }
    }
  };

  validationSchema = () => {
    this.setState({ clientErrorShow: false });
    this.setState({ branchErrorShow: false });
    if (this.state.client_id === "") {
      this.setState({ clientErrorShow: true });
    }
    if (this.state.branch_id === "") {
      this.setState({ branchErrorShow: true });
    }
    return Yup.object().shape({
      name: Yup.string().required("Enter Audit Name"),
      areaTask: Yup.array().of(
        Yup.object().shape({
          area: Yup.string().required("Enter Area"),
          task: Yup.array().of(Yup.string().required("Enter Task")),
        })
      ),
    });
  };

  progress = (e: any) => {
    console.log("progress", e);
  };

  handleOnChangeClient = async (id: number) => {
    this.setState({ client_id: id });
    await this.props.listAllBranch(id);
  };

  handleAddArea = async () => {
    const addArea: any = {
      area: "",
      task: [""],
    };
    // this.setState((prevState) => ({
    //   areaTask: [...prevState.areaTask, addArea],
    // }));
    let areaTask: any = this.state.areaTask;
    areaTask.push(addArea);
    this.setState({
      areaTask: areaTask
    });
  };

  handleRemoveArea = (inx: any) => {
    this.setState((prevState) => ({
      areaTask: prevState.areaTask.filter(
        (_: any, index: any) => index !== inx
      ),
    }));
  };

  // handleAddTask = (inx: any) => {
  //   this.setState((prevState) => {
  //     const areaTask = [...prevState.areaTask];
  //     areaTask[inx].task.push("");
  //     return { areaTask };
  //   });
  // };

  // handleRemoveTask = (p_inx: any, c_inx: any) => {
  //   this.setState((prevState) => {
  //     const areaTask = [...prevState.areaTask];
  //     if (p_inx >= 0 && p_inx < areaTask.length) {
  //       areaTask[p_inx].task.splice(c_inx, 1);
  //     }
  //     return { areaTask };
  //   });
  // };

  // changeAreaValue = (value: any, inx: any) => {
  //   this.setState((prevState) => {
  //     const areaTask = [...prevState.areaTask];
  //     areaTask[inx].area = value;
  //     return { areaTask };
  //   });
  // };

  // changeTaskValue = (value: any, area_inx: any, task_inx: any) => {
  //   this.setState((prevState) => {
  //     const areaTask = [...prevState.areaTask];
  //     areaTask[area_inx].task[task_inx] = value;
  //     return { areaTask };
  //   });
  // };

  handleAddTask = async (inx: number) => {
    let areaTask: any = this.state.areaTask;
    areaTask[inx].task.push("");
    this.setState({ areaTask: areaTask });
  }

  handleRemoveTask = async (p_inx: number, c_inx: number) => {
    let areaTask: any = this.state.areaTask;
    if (p_inx >= 0 && p_inx < areaTask.length) {
      if (c_inx >= 0 && c_inx < areaTask[p_inx].task.length) {
        areaTask[p_inx].task.splice(c_inx, 1);
      }
    }
    this.setState({ areaTask: areaTask });
  }

  changeAreaValue = async (value: any, inx: number) => {
    let areaTask: any = this.state.areaTask;
    areaTask[inx].area = value;
    this.setState({ areaTask: areaTask });
  }

  changeTaskValue = async (value: any, area_inx: number, task_inx: number) => {
    let areaTask: any = this.state.areaTask;
    areaTask[area_inx].task[task_inx] = value;
    this.setState({ areaTask: areaTask });
  }

  render(): React.ReactNode {
    const initialValues = {
      name: "",
      areaTask: this.state.areaTask,
    };
    return (
      <div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={this.handleAddAudit}
          validationSchema={this.validationSchema}
        >
          <Form encType="multipart/form-data">
            <div className="mt-4">
              <div className="d-flex gap-2">
                <div style={{ width: "28%" }}>
                  <label className="form-label">Name</label>
                  <Field
                    name="name"
                    type={"text"}
                    className="form-control usermanagement-input"
                  />
                  <ErrorMessage
                    name="name"
                    component={"div"}
                    className="cus-alert"
                  />
                </div>
                <div style={{ width: "28%" }}>
                  <label className="form-label">Client</label>
                  <Select
                    styles={reactSelectCusStyles}
                    placeholder="Select Client"
                    isMulti={false}
                    options={this.props.clientList.map((client: any) => ({
                      value: client.id,
                      label: client.first_name + " " + client.last_name,
                    }))}
                    onChange={(e: any) => {
                      this.handleOnChangeClient(e.value);
                      this.setState({ clientErrorShow: false });
                    }}
                  />
                  {this.state.clientErrorShow === true && (
                    <p className="text-danger"> Please Select the Client</p>
                  )}
                </div>
                <div style={{ width: "27%" }}>
                  <label className="form-label">Branch</label>
                  <Select
                    styles={reactSelectCusStyles}
                    placeholder="Select Branch"
                    isMulti={false}
                    options={this.props.branchList.map((branch: any) => ({
                      value: branch.value,
                      label: branch.label,
                    }))}
                    onChange={(e: any) =>
                      this.setState({
                        branch_id: e.value,
                        branchErrorShow: false,
                      })
                    }
                  />
                  {this.state.branchErrorShow === true && (
                    <p className="text-danger"> Please Select the Branch</p>
                  )}
                </div>
                <div style={{ width: "17%", marginTop: "31px" }}>
                  <button
                    type="button"
                    className="btn btn-primary plus-btn data-ins-plus w-100"
                    onClick={this.handleAddArea}
                  >
                    <PlusSVG /> <span>Add Area</span>
                  </button>
                </div>
              </div>

              {this.state.areaTask &&
                this.state.areaTask.map((val: any, index: number) => (
                  <div className="row mt-3 p-0" key={index}>
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body p-4">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="d-flex gap-3">
                                <div className="col mb-2">
                                  <label className="form-label">Area</label>
                                  {/* <input type="area" value={val.area} className="form-control br-dark" onChange={(e) => this.changeAreaValue(e.target.value, index)} /> */}
                                  <Field
                                    type="area"
                                    value={val.area}
                                    name={`areaTask[${index}].area`}
                                    className="form-control br-dark"
                                    onChange={(e: any) =>
                                      this.changeAreaValue(
                                        e.target.value,
                                        index
                                      )
                                    }
                                  />
                                  <ErrorMessage
                                    name={`areaTask[${index}].area`}
                                    component="div"
                                    className="cus-alert"
                                  />
                                </div>
                                <div style={{ marginTop: "29px" }}>
                                  <button
                                    type="button"
                                    className="btn close-new-btn data-ins-plus p-0"
                                    onClick={() => {
                                      this.handleRemoveArea(index);
                                    }}
                                  >
                                    <PlusCloseSVG />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          {val.task &&
                            val.task.map((task: any, t_inx: number) => (
                              <div className="row" key={t_inx}>
                                <div className="col-md-11">
                                  <div className="d-flex gap-3">
                                    <div className="col mb-2">
                                      <label className="form-label">Task</label>
                                      {/* <textarea className="form-control br-dark" style={{ height: "40px" }}
                                        onChange={(e) => this.changeTaskValue(e.target.value, index, t_inx)}></textarea> */}
                                      <Field
                                        as="textarea"
                                        name={`areaTask[${index}].task[${t_inx}]`}
                                        className="form-control br-dark"
                                        style={{ height: "40px" }}
                                        onChange={(e: any) =>
                                          this.changeTaskValue(
                                            e.target.value,
                                            index,
                                            t_inx
                                          )
                                        }
                                      />
                                      <ErrorMessage
                                        name={`areaTask[${index}].task[${t_inx}]`}
                                        component="div"
                                        className="cus-alert"
                                      />
                                    </div>

                                    <div style={{ marginTop: "29px" }}>
                                      <button
                                        type="button"
                                        className="btn close-new-btn data-ins-plus p-0"
                                        onClick={() => {
                                          this.handleRemoveTask(index, t_inx);
                                        }}
                                      >
                                        <PlusCloseSVG />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          <div className="row mt-3">
                            <div className="col-md-12">
                              <button
                                type="button"
                                className="btn btn-primary plus-btn data-ins-plus"
                                onClick={() => {
                                  this.handleAddTask(index);
                                }}
                              >
                                <PlusSVG />
                                <span style={{ paddingRight: "4px" }}>
                                  Add Task
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div className="row">
              <div className="col-md-12 cus-progress">
                <Modal
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={this.props.processTrigger}
                >
                  <Modal.Body className="p-0">
                    <ProgressBar
                      now={this.props.progressPercentage}
                      label={
                        this.props.progressPercentage === 100
                          ? `Processing your request...`
                          : `User Adding....${this.props.progressPercentage}%`
                      }
                    />
                  </Modal.Body>
                </Modal>
                <div className="mt-3 mb-3 d-flex ">
                  <div className="mr-16px">
                    <button
                      type="submit"
                      id="create-button"
                      className="btn btn-primary create-button"
                    >
                      Create
                      <span
                        id="spinner-border"
                        className="spinner-border spinner-border-sm d-none"
                      ></span>
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn cancel-button"
                      onClick={() => {
                        this.props.onClose(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  processTrigger: state.audit.processTrigger,
  addAuditSuccess: state.audit.addAuditSuccess,
  progressPercentage: state.audit.progressPercentage,
  clientList: state.contract.clientList,
  branchList: state.tasktemplate.branchList,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addAudit: bindActionCreators(addAudit, dispatch),
    listAllClient: bindActionCreators(listAllClient, dispatch),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAudit);
