import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { bindActionCreators, Dispatch } from "redux";
import {
  IEditComplaintProps,
  IEditComplaintState,
} from "../../types/complaint.type";
import { ICleaner } from "../../shared/ActionTypes";
import {
  editComplaint,
  listAllClient,
  updateEditComplaintDlgStatus,
  updateComplaintFetchStatus,
  listAllBranch,
} from "../../shared/Reducers";
import { CustomSelect } from "../../shared/Components/CustomSelect";
import { Modal } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import Constants, { COMPLAINT_STATUS } from "../../shared/Constants";

class EditComplaint extends React.Component<
  IEditComplaintProps,
  IEditComplaintState
> {
  constructor(props: IEditComplaintProps) {
    super(props);
    this.state = {
      userTypeDetail: "",
    };
  }

  componentDidMount(): void {
    this.props.listAllClient();

    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.add("d-none");
      button.removeAttribute("disabled");
    }
    if (this.props.data) {
      console.log(this.props.data,"listAllBranch");
      
      this.props.listAllBranch(this.props.data.client_id);
    }
    this.setState({ userTypeDetail: this.props.loginUserDetail });
    console.log(this.props.loginUserDetail);
  }

  componentDidUpdate(
    prevProps: Readonly<IEditComplaintProps>,
    prevState: Readonly<IEditComplaintState>,
    snapshot?: any
  ): void {
    if (prevProps.processTrigger !== this.props.processTrigger) {
    }
    if (prevProps.editComplaintSuccess !== this.props.editComplaintSuccess) {
    }
  }

  handleEditComplaint = async (formValues: any) => {
    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.remove("d-none");
      button.setAttribute("disabled", "");
    }
    let data: any = [];
    data = {
      client_id:
        // Constants.QAUTINT === Constants.LOGIN_USER_TYPE
        //   ? this.state.userTypeDetail.client.id
        //   : Constants.QA_MANAGERUTINT === Constants.LOGIN_USER_TYPE
        //   ? this.state.userTypeDetail.assigned_branches.client.id
        //   : Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE
        //   ? this.state.userTypeDetail.assigned_branches.client.id
        //   : Constants.SUPERVISOR_MANAGERUTINT === Constants.LOGIN_USER_TYPE
        //   ? this.state.userTypeDetail.assigned_branches.client.id
             formValues.client,
      branch_id:
        // Constants.QAUTINT === Constants.LOGIN_USER_TYPE
        //   ? this.state.userTypeDetail.branch.id
            formValues.branch,
      name: formValues.title,
      complaint_status: formValues.complaintStatus,
    };
    if (this.props.id) {
      this.props.editComplaint(data, this.props.id);
    }
  };



  validationSchema = () => {
    const clientId = this.props.clientList.map((client: ICleaner) => client.id);
    if (Constants.QAUTINT === Constants.LOGIN_USER_TYPE) {
      return Yup.object().shape({
        title: Yup.string().required("Enter a complaint title"),
      });
    } else if (
      Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
      Constants.QA_MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
      Constants.SUPERVISOR_MANAGERUTINT === Constants.LOGIN_USER_TYPE
    ) {
      console.log("user");
      return Yup.object().shape({
        title: Yup.string().required("Enter a complaint title"),
        branch: Yup.string().required("Select a any branch"),
      });
    } else {
      console.log("user");

      return Yup.object().shape({
        title: Yup.string().required("Enter a complaint title"),
        client: Yup.string()
          .oneOf([...clientId], "Invalid Client")
          .required()
          .nullable(),
        branch: Yup.string().required("Select a any branch"),
      });
    }
  };

  handleOnChangeClient = (id: any) => {
    console.log(id,"id");
    
    this.props.listAllBranch(id);
  };
  render(): React.ReactNode {
    return (
      <div>
        <Formik
          initialValues={{
            title: this.props.data ? this.props.data.name : "",
            client: this.props.data ? this.props.data.client_id : "",
            branch: this.props.data ? this.props.data.branch_id : "",
            complaintStatus: this.props.data
              ? this.props.data.complaint_status
              : "",
          }}
          enableReinitialize={true}
          onSubmit={this.handleEditComplaint}
          validationSchema={this.validationSchema}
        >
          <Form>
            <div className="row">
              <div className="col-md-6 mb-2">
                <label className="form-label">
                  Complaint Title<span className="text-project">*</span>
                </label>
                <Field
                  name="title"
                  type={"text"}
                  className="form-control text-field"
                />
                <ErrorMessage
                  name="title"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="col-md-12">
                <div className="row">
                  {Constants.QAUTINT === Constants.LOGIN_USER_TYPE ? null : (
                    <>
                      {Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
                      Constants.SUPERVISOR_MANAGERUTINT ===
                        Constants.LOGIN_USER_TYPE ||
                      Constants.QA_MANAGERUTINT ===
                        Constants.LOGIN_USER_TYPE ? null : (
                        <div className="mb-2 col-md-6">
                          <label className="form-label">
                            Client<span className="text-project">*</span>
                          </label>
                          <Field
                            className="custom-select dropdown-field"
                            name="client"
                            options={this.props.clientList.map(
                              (client: any) => ({
                                value: client.id,
                                label:
                                  client.first_name + " " + client.last_name,
                              })
                            )}
                            onChange={(e: any) =>
                              this.handleOnChangeClient(e.value)
                            }
                            component={CustomSelect}
                            placeholder="Select Client"
                            isMulti={false}
                          />
                          <ErrorMessage
                            name="client"
                            component={"div"}
                            className="cus-alert"
                          />
                        </div>
                      )}
                      <div className="mb-2 col-md-6">
                        <label className="form-label">
                          Branch<span className="text-project">*</span>
                        </label>
                        <Field
                          className="custom-select dropdown-field"
                          name="branch"
                          options={this.props.branchList}
                          component={CustomSelect}
                          placeholder="Select Branch"
                          isMulti={false}
                        />
                        <ErrorMessage
                          name="branch"
                          component={"div"}
                          className="cus-alert"
                        />
                      </div>
                    </>
                  )}
                  <div className="mb-2 col-md-6">
                    <label className="form-label">
                      Complaint Status<span className="text-project">*</span>
                    </label>
                    <Field
                      className="custom-select dropdown-field"
                      name="complaintStatus"
                      options={COMPLAINT_STATUS}
                      component={CustomSelect}
                      isMulti={false}
                      // onClick={() =>
                      //   this.handlestatusComplaint(this.props.data)
                      // }
                    />
                    <ErrorMessage
                      name="complaintStatus"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="row mt-3 mb-3">
                <div className="col-md-12 cus-progress">
                  <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.props.processTrigger}
                  >
                    <Modal.Body className="p-0">
                      <ProgressBar
                        now={this.props.progressPercentage}
                        label={
                          this.props.progressPercentage === 100
                            ? `Processing your request...`
                            : `Complaint Updating....${this.props.progressPercentage}%`
                        }
                      />
                    </Modal.Body>
                  </Modal>
                  <div className="d-flex">
                    <div className="mr-16px">
                      <button
                        type="submit"
                        id="create-button"
                        className="btn btn-primary create-button"
                      >
                        Save{" "}
                        <span
                          id="spinner-border"
                          className="spinner-border spinner-border-sm d-none"
                        ></span>
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn cancel-button"
                        onClick={() =>
                          this.props.updateEditComplaintDlgStatus(false)
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>

        <iframe
          style={{ display: "none" }}
          name="hiddenIframe"
          id="hiddenIframe"
        />
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  clientList: state.complaint.clientList,
  branchList: state.tasktemplate.branchList,
  processTrigger: state.complaint.processTrigger,
  editComplaintSuccess: state.complaint.editComplaintSuccess,
  progressPercentage: state.complaint.progressPercentage,
  loginUserDetail: state.sidebar.userLoginDetails,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    listAllClient: bindActionCreators(listAllClient, dispatch),
    editComplaint: bindActionCreators(editComplaint, dispatch),
    updateEditComplaintDlgStatus: bindActionCreators(
      updateEditComplaintDlgStatus,
      dispatch
    ),
    updateComplaintFetchStatus: bindActionCreators(
      updateComplaintFetchStatus,
      dispatch
    ),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditComplaint);
