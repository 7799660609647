import {
  API_GET_ALL_INVOICE_REPORT,
  API_GET_ALL_INVOICE_REPORT_FAIL,
  API_GET_ALL_INVOICE_REPORT_SUCCESS,
  API_GET_INVOICE_ALLBRANCH,
  API_GET_INVOICE_ALLBRANCH_FAIL,
  API_GET_INVOICE_ALLBRANCH_SUCCESS,
  API_GET_INVOICE_ALLCLIENTS,
  API_GET_INVOICE_ALLCLIENTS_FAIL,
  API_GET_INVOICE_ALLCLIENTS_SUCCESS,
  API_GET_INVOICE_EXPORT_REPORT,
  API_GET_INVOICE_EXPORT_REPORT_FAIL,
  API_GET_INVOICE_EXPORT_REPORT_SUCCESS,
  API_GET_INVOICE_REPORT,
  API_GET_INVOICE_REPORT_FAIL,
  API_GET_INVOICE_REPORT_SUCCESS,
  FILTER_ALL_INVOICE_BRANCH,
  FILTER_ALL_INVOICE_CLIENT,
  UPDATE_EXPORT_INVOICE,
  UPDATE_INVOICE_FROM_DATE,
  UPDATE_INVOICE_TO_DATE,
  UPDATE_VIEW_INVOICE,
} from "../ActionTypes/InvoiceReducerType";
import { AnyAction, Dispatch } from "redux";

import Constants from "../Constants";
import { IBranchSelect } from "../ActionTypes";
import { InvoiceReducerState } from "../ActionTypes/InvoiceReducerType";

const initialState: InvoiceReducerState = {
  clientList_Invoice: [],
  branchList_Invoice: [],
  filter_InvoiceClientById: "all",
  filter_InvoiceBranchById: "all",
  allInvoice_Data: [],
  totalInvoiceCount: 0,
  invoice_Status: false,
  export_status: false,
  invoiceReportdata: [],
  invoice_from_date: "",
  invoice_to_date: "",
  export: [],
};
export const InvoiceReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case API_GET_INVOICE_ALLCLIENTS:
      return { ...state };
    case API_GET_INVOICE_ALLCLIENTS_SUCCESS:
      let clientData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "clients" in data && data.clients) {
          clientData = data.clients;
        }
      }
      return { ...state, clientList_Invoice: clientData };
    case API_GET_INVOICE_ALLCLIENTS_FAIL:
      return { ...state };
    case API_GET_INVOICE_ALLBRANCH:
      return { ...state };
    case API_GET_INVOICE_ALLBRANCH_SUCCESS:
      let branchData: IBranchSelect[] = [];
      let branchDataUpdate: IBranchSelect[] = [];
      // let branchClientId = 0;
      if (action.payload) {
        const { data } = action.payload;
        // branchClientId = data.client_id;
        if (data && "branches" in data && data.branches) {
          branchData = data.branches.map((branchValue: any) => ({
            value: branchValue.id,
            label: branchValue.name,
            lat: branchValue.lat,
            lng: branchValue.lng,
            client_id:
              data.client_id > 0 ? data.client_id : branchValue.client_id,
          }));
          if (Constants.LOGIN_USER_TYPE === Constants.MANAGERUTINT) {
            branchDataUpdate = [
              {
                value: "all",
                label: "All",
              },
            ].concat(branchData);
          }
        }
      }
      // , getBranchClientId : true
      if (Constants.LOGIN_USER_TYPE === Constants.MANAGERUTINT) {
        return { ...state, branchList_Invoice: branchDataUpdate };
      } else {
        return { ...state, branchList_Invoice: branchData };
      }
    case API_GET_INVOICE_ALLBRANCH_FAIL:
      return { ...state };
    case FILTER_ALL_INVOICE_CLIENT:
      return { ...state, filter_InvoiceClientById: action.payload };
    case FILTER_ALL_INVOICE_BRANCH:
      return { ...state, filter_InvoiceBranchById: action.payload };
    case API_GET_ALL_INVOICE_REPORT:
      return { ...state };
    case API_GET_ALL_INVOICE_REPORT_SUCCESS:
      let invoiceData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "invoice_list" in data && data.invoice_list) {
          invoiceData = data.invoice_list;
        }
      }
      let totalcount_ = 0;
      if (action.payload) {
        const { data } = action.payload;
        if (data && "total_count" in data && data.total_count) {
          totalcount_ = data.total_count;
        }
      }
      return {
        ...state,
        totalInvoiceCount: totalcount_,
        allInvoice_Data: invoiceData,
      };
    case API_GET_ALL_INVOICE_REPORT_FAIL:
      return { ...state };
    case UPDATE_VIEW_INVOICE:
      return { ...state, invoice_Status: action.payload };
    case UPDATE_EXPORT_INVOICE:
      return { ...state, export_status: action.payload };
    case API_GET_INVOICE_REPORT:
      return { ...state };
    case API_GET_INVOICE_REPORT_SUCCESS:
      let invoice = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "cleanerLoginDetails" in data && data.cleanerLoginDetails) {
          invoice = data.cleanerLoginDetails;
        }
      }
      return { ...state, invoiceReportdata: invoice };
    case API_GET_INVOICE_REPORT_FAIL:
      return { ...state };
    case UPDATE_INVOICE_FROM_DATE:
      return { ...state, invoice_from_date: action.payload };
    case UPDATE_INVOICE_TO_DATE:
      return { ...state, invoice_to_date: action.payload };
    case API_GET_INVOICE_EXPORT_REPORT:
      return { ...state };
    case API_GET_INVOICE_EXPORT_REPORT_SUCCESS:
      return { ...state };
    case API_GET_INVOICE_EXPORT_REPORT_FAIL:
      return { ...state };
    default:
      return state;
  }
};

export function listAllClient_Invoice() {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_INVOICE_ALLCLIENTS,
      payload: {
        request: {
          method: "get",
          url: `users/client/drop-down-list`,
        },
      },
    });
  };
}

export function listAllBranch_Invoice(id: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_INVOICE_ALLBRANCH,
      payload: {
        request: {
          method: "get",
          url: `branch/drop-down-list/${id}`,
        },
      },
    });
  };
}

export function handleFilterInvoiceClient(id: any) {
  id = id.join();

  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_ALL_INVOICE_CLIENT,
      payload: id,
    });
  };
}

export function handleFilterInvoiceBranch(id: any) {
  id = id.join();

  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_ALL_INVOICE_BRANCH,
      payload: id,
    });
  };
}

export function getAllInvoiceReport(queryStr: string) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_ALL_INVOICE_REPORT,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `invoice?${queryStr}` : `invoice?`,
        },
      },
    });
  };
}

// update view user management
export function updateViewInvoice(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_VIEW_INVOICE,
      payload: status,
    });
  };
}

export function updateExportInvoice(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_EXPORT_INVOICE,
      payload: status,
    });
  };
}

export function Invoice_Report_ById(id: string, from: any, to: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_INVOICE_REPORT,
      payload: {
        request: {
          method: "get",
          url: `invoice/${id}/${from}/${to}`,
        },
      },
    });
  };
}

export function updateInvoiceFromDate(id: any) {
  console.log(id, "updateInvoiceFromDate");

  return async (dispatch: Dispatch) => {
    await dispatch({
      type: UPDATE_INVOICE_FROM_DATE,
      payload: id,
    });
  };
}

export function updateInvoiceToDate(id: any) {
  console.log(id, "updateInvoiceToDate");
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: UPDATE_INVOICE_TO_DATE,
      payload: id,
    });
  };
}

export function exportinvoice(data: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_INVOICE_EXPORT_REPORT,
      payload: {
        request: {
          method: "post",
          url: `invoice`,
          data: data,
        },
      },
    });
  };
}
