import * as Yup from "yup";

import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import {
  EyeOffSVG,
  EyeSVG,
  PlusCloseSVG,
  PlusSVG,
} from "../../assets/images/UsermanagementsSVG";
import {
  IEditUsermanagementProps,
  IEditUsermanagementState,
  IUsermanagement,
  IUsermanagementPayload,
} from "../../types/userManagement.type";
import { Modal, ProgressBar, Toast } from "react-bootstrap";
import {
  editUsermanagement,
  listAllBranch,
  listAllClient,
  updateEditUsermanagementDlgStatus,
  updateUsermanagementFetchStatus,
  getUserTypesList,
  getAllCertificates,
} from "../../shared/Reducers/index";

import { AlertToastSVG } from "../../assets/images/ImagesV2";
import { CloseBtnSVG } from "../../assets/images/ImagesSVG";
import Constants from "../../shared/Constants";
import CustomSelect from "../../shared/Components/CustomSelect";
import PhoneInput from "react-phone-input-2";
import React from "react";
import { RootState } from "../../index";
import Select from "react-select";
import { USER_ROLE } from "../../shared/Constants";
import { connect } from "react-redux";

const reactSelectCusStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#fff",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    },
  }),
};

class EditUsermanagement extends React.Component<
  IEditUsermanagementProps,
  IEditUsermanagementState
> {
  constructor(props: IEditUsermanagementProps) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      user_type: "",
      status: "",
      password: "",
      email: "",
      phone: "",
      hidden: true,
      branch: null,
      addBranches: [
        {
          options: [],
        },
      ],
      clientList: [],
      userSelectedActiveStatus: [],
      editToast: false,
      clientSelectErrorShow: false,
      branchSelectErrorShow: false,
      selectClientInx: 0,
      selectClientVal: [],
      handleOnChangeStatus: false,
      userTypesStateList: [],
      branchCondition: "",
      clearnerCertificateList: [],
      cleanerDocumentShow: false,
      cleaner_certificate: [],
      cleanerValidate: false
    };
  }

  // Edit Usermanagement Field Data
  componentDidMount(): void {
    (async () => {
      await this.listData();
      await this.props.getUserTypesList();
      await this.props.getCertificateList();
      this.setState({
        userTypesStateList: this.props.userTypesList.filter(
          (Utypes: any) => Utypes.id !== Constants.ADMIN
        ),
      });

      const clearnerCertificateList = this.props.getCeritficateData.map((certi: any) => ({
        label: certi.name,
        value: certi.id
      }));
      console.log(clearnerCertificateList);
      
      this.setState({ clearnerCertificateList:clearnerCertificateList });
      if (this.props.usermanagements){
        if(this.props.usermanagements.user_type_id===3){
           this.setState({cleanerDocumentShow:true})
        }
      }

    })();

    if (this.props.usermanagements) {
      let user_type = new Number(this.props.usermanagements.user_type_id);
      // let user_lable = "";
      // if (user_type.toString() == Constants.SUPERVISOR_UT) {
      //   user_lable = "Supervisor";
      this.setClientBranchDataArray(
        this.props.usermanagements.assigned_branches
      );
      // } else if (user_type.toString() == Constants.CLEANER_UT) {
      //   user_lable = "Cleaner";
      // } else if (user_type.toString() == Constants.CLIENT_UT) {
      //   user_lable = "Client";
      // } else if (user_type.toString() == Constants.QA_UT) {
      //   user_lable = "QA";
      //   this.setQABranchSelectedData(
      //     this.props.usermanagements.assigned_branches

      //   );
      // } else if (user_type.toString() == Constants.SUPERVISOR_MANAGER_UT) {
      //   user_lable = "Supervisor Manager";
      //   this.setSupervisorManagerBranchSelectedData(
      //     this.props.usermanagements.assigned_branches
      //   );
      // } else if (user_type.toString() == Constants.OPERATION_MANAGER_UT) {
      //   user_lable = "Operation Manager";
      //   this.setOperationManagerBranchSelectedData(
      //     this.props.usermanagements.assigned_branches
      //   );
      // } else if (user_type.toString() == Constants.MANAGER_UT) {
      //   user_lable = "Manager";
      //   this.setManagerBranchSelectedData(
      //     this.props.usermanagements.assigned_branches
      //   );
      // } else if (user_type.toString() == Constants.QA_MANAGER_UT) {
      //   user_lable = "QA Manager";
      //   this.setManagerBranchSelectedData(
      //     this.props.usermanagements.assigned_branches
      //   );
      // }

      this.setState({
        first_name: this.props.usermanagements.first_name,
        last_name: this.props.usermanagements.last_name,
        user_type: user_type.toString(),
        status: this.props.usermanagements.status,
        email: this.props.usermanagements.email,
        password: this.props.usermanagements.password,
        phone: this.props.usermanagements.phone,
        userSelectedActiveStatus: {
          value: this.props.usermanagements.status,
          label: this.props.usermanagements.status === 1 ? "Enable" : "Disable",
        },
        // addBranches:this.props.usermanagements.assigned_branches,
        // branchCondition:this.props.usermanagements.user.permission["user_multi_client_branch"] === 1 ? "multi_client_branch":""
        branchCondition:
          this.props.usermanagements.permission["user_multi_client_branch"] ===
          1
            ? "multi_client_branch"
            : this.props.usermanagements.permission[
                "user_single_client_branch"
              ] === 1
            ? "single_client_branch"
            : this.props.usermanagements.permission[
                "user_single_client_multi_branch"
              ] === 1
            ? "single_client_multi_branch"
            : null,
      });
    }
  }

  //   checkBranchCondition=async(permission:any)=>{
  // switch (permission) {
  //   case value:

  //     break;

  //   default:
  //     break;
  // }
  //   }

  componentDidUpdate(
    prevProps: Readonly<IEditUsermanagementProps>,
    prevState: Readonly<IEditUsermanagementState>,
    snapshot?: any
  ): void {
    if (
      prevProps.editUsermanagementError !== this.props.editUsermanagementError
    ) {
      if (this.props.editUsermanagementError === "Email already exist") {
        this.setState({ editToast: true });
        setTimeout(() => {
          this.setState({ editToast: false });
        }, 5000);
      }
    }
    if (prevProps.processTrigger !== this.props.processTrigger) {
    }
    if (
      prevProps.editUsermanagementSuccess !==
      this.props.editUsermanagementSuccess
    ) {
    }

    if (
      this.props.branchList !== prevProps.branchList &&
      this.state.handleOnChangeStatus === true
    ) {
      let addBranches = this.state.addBranches;

      addBranches[this.state.selectClientInx].options = this.props.branchList;
      addBranches[this.state.selectClientInx].client_id =
        this.state.selectClientVal.value;
      addBranches[this.state.selectClientInx].branch = [];
      addBranches[this.state.selectClientInx].client =
        this.state.selectClientVal;

      this.setState({ addBranches: [...addBranches] });
      this.setState({ clientSelectErrorShow: false });
      this.setState({ handleOnChangeStatus: false });
    }
  }

  onChangeCertificate = (e:any) => {
    setTimeout(() => {
console.log(this.state.clearnerCertificateList);
      
    }, 3000);
  }

  editToastClose = () => {
    this.setState({ editToast: false });
  };

  setClientBranchDataArray = async (val: any) => {
    try {
      let addBranchesArray: any = [];
      for (let i = 0; i < val.length; i++) {
        const element = val[i];
        await this.props.listAllBranch(element.client.id);
        let branchlist = element.branches.map((branch: any) => ({
          value: branch.id,
          label: branch.name,
        }));

        addBranchesArray.push({
          options: this.props.branchList,
          client_id: element.client.id,
          client: {
            value: element.client.id,
            label: element.client.first_name + " " + element.client.last_name,
          },
          branch: branchlist,
        });
      }

      this.setState({ addBranches: [...addBranchesArray] });
    } catch (error) {}
  };

  // setOperationManagerBranchSelectedData = async (val: any) => {
  //   let addBranchesArray: any = [];
  //   await this.props.listAllBranch(val.client.id);
  //   let branchlist = val.branches.map((branch: any) => ({
  //     value: branch.id,
  //     label: branch.name,
  //   }));

  //   addBranchesArray.push({
  //     options: this.props.branchList,
  //     client_id: val.client.id,
  //     client: {
  //       value: val.client.id,
  //       label: val.client.first_name + " " + val.client.last_name,
  //     },
  //     branch: branchlist,
  //   });

  //   this.setState({ addBranches: [...addBranchesArray] });
  // };

  // setSupervisorManagerBranchSelectedData = async (val: any) => {
  //   let addBranchesArray: any = [];
  //   await this.props.listAllBranch(val.client.id);
  //   let branchlist = val.branches.map((branch: any) => ({
  //     value: branch.id,
  //     label: branch.name,
  //   }));

  //   addBranchesArray.push({
  //     options: this.props.branchList,
  //     client_id: val.client.id,
  //     client: {
  //       value: val.client.id,
  //       label: val.client.first_name + " " + val.client.last_name,
  //     },
  //     branch: branchlist,
  //   });

  //   this.setState({ addBranches: [...addBranchesArray] });
  // };

  // setManagerBranchSelectedData = async (val: any) => {
  //   try {
  //     let addBranchesArray: any = [];
  //     for (let i = 0; i < val.length; i++) {
  //       const element = val[i];
  //       await this.props.listAllBranch(element.client.id);

  //       let branchlist = element.branches.map((branch: any) => ({
  //         value: branch.id,
  //         label: branch.name,
  //       }));

  //       addBranchesArray.push({
  //         options: this.props.branchList,
  //         client_id: element.client.id,
  //         client: {
  //           value: element.client.id,
  //           label: element.client.first_name + " " + element.client.last_name,
  //         },
  //         branch: branchlist,
  //       });
  //     }

  //     this.setState({ addBranches: [...addBranchesArray] });
  //   } catch (error) {

  //   }

  // };

  // setQABranchSelectedData = async (val:any) => {
  //   try {

  //     let addBranchesArray: any = [];
  //     for (let i = 0; i < val.length; i++) {
  //       const element = val[i];
  //   await this.props.listAllBranch(element.client.id);
  //   addBranchesArray.push({
  //     options: this.props.branchList,
  //     client_id: element.client.id,
  //     client: {
  //       value: element.client.id,
  //       label: element.client.first_name + " " + element.client.last_name,
  //     },
  //     branch: element.branches.map((i: any) => ({ value: i.id, label: i.name })),
  //   });

  //     }

  //   this.setState({ addBranches: [...addBranchesArray] });
  //   } catch (error) {

  //   }

  // };

  listData = async () => {
    await this.props.listAllClient();
    // let clientList = this.props.clientList.map((client: any) => ({ value: client.id, label: client.first_name+" "+client.last_name }));
    // this.setState({ clientList: clientList });
  };

  onUserRoleChangeHandle=(val:any, value:any)=>{
    this.setState({  cleanerDocumentShow: false, cleanerValidate:false });

    if (val.value === Constants.CLEANERUTINT ) {
      this.setState({  cleanerDocumentShow: true });

      if(this.state.cleaner_certificate.length>0){
        this.setState({cleanerValidate:false})
      }else{
        this.setState({cleanerValidate:true})
      }
    }
  }

  handleOnChangeBranch = async (e: any, inx: number) => {
    let addBranches = this.state.addBranches;
    addBranches[inx].branch = e;
    console.log(addBranches, "todayy");

    this.setState({ addBranches: [...addBranches] });
    this.setState({ branchSelectErrorShow: false });
  };

  handleAddMultiSelect = () => {
    let addBranchesArray = [
      {
        options: [],
      },
    ];
    this.setState({
      addBranches: [...this.state.addBranches, ...addBranchesArray],
    });
  };

  handleCloseMultiSelect = (inx: number) => {
    let addBranches = this.state.addBranches;
    addBranches.splice(inx, 1);

    this.setState({ addBranches: addBranches });
  };

  onUserStatusChangeHandle = (val: any, value: any) => {
    this.setState({ userSelectedActiveStatus: val });
    this.setState({ status: val.value });
  };

  handleOnChangeClient = async (val: any, inx: number) => {
    this.setState({ handleOnChangeStatus: true });
    this.setState({ selectClientInx: inx });
    this.setState({ selectClientVal: val });
    this.props.listAllBranch(val.value);

    // let addBranches = this.state.addBranches;
    // addBranches[inx].client = val;
    // this.setState({ addBranches: [...addBranches] });

    // addBranches[inx].options = this.props.branchList;
    // addBranches[inx].client_id = val.value;
    // addBranches[inx].branch = [];
    // addBranches[inx].client = val;
    // this.setState({ addBranches: [...addBranches] });
    // this.setState({ clientSelectErrorShow: false });
  };

  // Password Eye Show/Hide
  toggleShow = (e: any) => {
    e.preventDefault();
    this.setState({ hidden: !this.state.hidden });
  };
  // Edit Usermanagement to Update current data
  handleEditUsermanagement = async (formValue: IEditUsermanagementState) => {
    console.log(formValue, "formvalue");
    if(Constants.CLEANER_UT ==this.state.user_type) {
      console.log(formValue.cleaner_certificate, "cleaner");
      
      if(formValue.cleaner_certificate.length>0){
       this.setState({cleanerValidate: false})
      }else{
       return this.setState({cleanerValidate: true})
      }
    }

    if (this.state.branchCondition === "multi_client_branch") {
      let assigned_branches_array: any = [];
      if (this.state.addBranches.length === 0) {
        return false;
      }
      await this.state.addBranches.map(async (val: any, index: number) => {
        let branchList: any = [];

        if (val.branch !== undefined) {
          await val.branch.map((brn_val: any, index: number) => {
            branchList.push(brn_val.value);
          });
        }
        if (val.client_id === undefined || branchList.length === 0) {
          return false;
        }
        let assigned_branches = {
          client: val.client_id,
          branches: branchList,
        };
        assigned_branches_array.push(assigned_branches);
      });
      if (assigned_branches_array.length === 0) {
        return false;
      }
      const payload: IUsermanagementPayload = {
        first_name: formValue.first_name,
        last_name: formValue.last_name,
        email: formValue.email,
        phone: formValue.phone,
        password: formValue.password ? formValue.password : this.state.password,
        user_type: formValue.user_type,
        assigned_branches: assigned_branches_array,
        status: this.state.status,
        certificates: formValue.cleaner_certificate
      };
      this.props.editUsermanagement(payload, this.props.id);
    } else if (this.state.branchCondition === "single_client_branch") {
      let clientID = "";
      let branchId = "";
      if (this.state.addBranches.length === 0) {
        return false;
      }
      await this.state.addBranches.map(async (val: any, index: number) => {
        if (val.branch !== undefined) {
          branchId = val.branch.value;
        }
        clientID = val.client_id;
      });
      if (clientID === "" || branchId === "") {
        return false;
      }
      let assigned_branches_array: any = [];
      if (this.state.addBranches.length === 0) {
        return false;
      }
      await this.state.addBranches.map(async (val: any, index: number) => {
        let branchList: any = [];

        if (val.branch !== undefined) {
          await val.branch.map((brn_val: any, index: number) => {
            branchList.push(brn_val.value);
          });
        }
        if (val.client_id === undefined || branchList.length === 0) {
          return false;
        }
        let assigned_branches = {
          client: val.client_id,
          branches: branchList,
        };
        assigned_branches_array.push(assigned_branches);
      });
      if (assigned_branches_array.length === 0) {
        return false;
      }
      const payload: IUsermanagementPayload = {
        client: clientID,
        branch: branchId,
        first_name: formValue.first_name,
        last_name: formValue.last_name,
        email: formValue.email,
        phone: formValue.phone,
        password: formValue.password ? formValue.password : this.state.password,
        user_type: this.state.user_type,
        status: this.state.status,
        assigned_branches: assigned_branches_array,
        certificates: formValue.cleaner_certificate
      };
      this.props.editUsermanagement(payload, this.props.id);
    } else if (this.state.branchCondition === "single_client_multi_branch") {
      // let [assigned_branches_obj] = this.state.addBranches;

      // if (assigned_branches_obj) {
      //   let assigned_branches = {
      //     client: assigned_branches_obj.client_id,
      //     branches: assigned_branches_obj.branch.map((obj: any) => obj.value),
      //   };

      //   const payload: IUsermanagementPayload = {
      //     first_name: formValue.first_name,
      //     last_name: formValue.last_name,
      //     email: formValue.email,
      //     phone: formValue.phone,
      //     password: formValue.password
      //       ? formValue.password
      //       : this.state.password,
      //     user_type: formValue.user_type,
      //     assigned_branches: assigned_branches,
      //     is_active: this.state.is_active,
      //   };
      //   this.props.editUsermanagement(payload, this.props.id);
      // }

      let assigned_branches_array: any = [];

      if (this.state.addBranches.length === 0) {
        return false;
      }
      await this.state.addBranches.map(async (val: any, index: number) => {
        console.log(val, "dates value");

        let branchList: any = [];

        if (val.branch !== undefined) {
          await val.branch.map((brn_val: any, index: number) => {
            branchList.push(brn_val.value);
          });
        }
        if (val.client_id === undefined || branchList.length === 0) {
          return false;
        }
        console.log(val.client_id, "clientss");
        console.log(branchList, "branchhh");
        // console.log(assigned_branches_array,"branchhhe");

        let assigned_branches = {
          client: val.client_id,
          branches: branchList,
        };
        assigned_branches_array.push(assigned_branches);
        console.log(assigned_branches_array, "eeeeee");
      });

      const payload: IUsermanagementPayload = {
        first_name: formValue.first_name,
        last_name: formValue.last_name,
        email: formValue.email,
        phone: formValue.phone,
        password: formValue.password ? formValue.password : this.state.password,
        user_type: formValue.user_type,
        assigned_branches: assigned_branches_array,
        status: this.state.status,
        certificates: formValue.cleaner_certificate
      };
      this.props.editUsermanagement(payload, this.props.id);
    } else {
      const payload: IUsermanagementPayload = {
        first_name: formValue.first_name,
        last_name: formValue.last_name,
        user_type:
          this.state.user_type === undefined
            ? this.props.usermanagements
              ? this.props.usermanagements.user_type_id
              : ""
            : this.state.user_type,
        status:
          this.state.status === undefined
            ? this.props.usermanagements
              ? this.props.usermanagements.is_active_id
              : ""
            : this.state.status,
        email: formValue.email,
        phone: formValue.phone,
        password: formValue.password ? formValue.password : this.state.password,
        certificates: formValue.cleaner_certificate
      } 
      if (this.props.id) {
        try {
          this.props.editUsermanagement(payload, this.props.id);
        } catch (error) {}
      }
    }
  };

  // Form Validation
  validationSchema = () => {
    // if (
    //   this.state.user_type === Constants.MANAGER_UT ||
    //   this.state.user_type === Constants.SUPERVISOR_UT ||
    //   this.state.user_type === Constants.QA_UT ||
    //   this.state.user_type === Constants.QA_MANAGER_UT ||
    //   this.state.user_type === Constants.SUPERVISOR_MANAGER_UT ||
    //   this.state.user_type === Constants.OPERATION_MANAGER_UT
    // )

    if (
      this.state.branchCondition !== "" &&
      this.state.branchCondition !== undefined
    ) {
      this.state.addBranches.map((val: any, index: number) => {
        console.log("log,", val);
        if (val.options.length > 0) {
          if (val.client_id === undefined) {
            this.setState({ clientSelectErrorShow: true });
          }
          if (val.branch.length === 0) {
            this.setState({ branchSelectErrorShow: true });
          }
        }
      });
    }

    return Yup.object().shape({
      user_type: Yup.string().required("Select User type"),
      first_name: Yup.string()
        .required("Enter a valid First Name")
        .test(
          "len",
          "Enter First Name",
          (val: any) =>
            val &&
            val.toString() !== "" &&
            val.toString().replace(/\s/g, "") !== ""
        ),
      last_name: Yup.string()
        .required("Enter a valid Last Name")
        .test(
          "len",
          "Enter Last Name",
          (val: any) =>
            val &&
            val.toString() !== "" &&
            val.toString().replace(/\s/g, "") !== ""
        ),
      password: Yup.string().when("user_type", {
        is: (userType: any) => {
          return userType === Constants.CLIENT_UT;
        }, // Adjust this condition based on your user types
        then: Yup.string(), // It doesn't check the password field for Client Role but it checks the password field for other user roles.
        otherwise: Yup.string()
          .required("Enter Password")
          .test(
            "len",
            "Enter min 8 Character",
            (val: any) =>
              val &&
              val.toString().length >= 8 &&
              val.toString().replace(/\s/g, "") !== ""
          ), // No validation for other user types
      }),
      email: Yup.string()
        .email("Enter valid Email address")
        .required("Enter Email"),
      phone: Yup.string(),
    });
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // The value of the input field is available in e.target.value
  };

  handlebranch = () => {
    if (this.state.branchCondition === "single_client_multi_branch") {
      return (
        <>
          <div className="col-md-12">
            <div className="row mt-3">
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Client<span className="text-project">*</span>
                </label>
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Branch (you can select multiple)
                  <span className="text-project">*</span>
                </label>
              </div>
            </div>
          </div>
          {this.state.addBranches &&
            this.state.addBranches.map((val: any, index: number) => (
              <div className="col-md-12">
                <div className="row">
                  <div className="mb-2 col-md-6">
                    <Select
                      styles={reactSelectCusStyles}
                      placeholder="Select Client"
                      isMulti={false}
                      options={this.props.clientList.map((client: any) => ({
                        value: client.id,
                        label: client.first_name + " " + client.last_name,
                      }))}
                      id={"select-client-" + index}
                      value={val.client}
                      onChange={(e: any) => this.handleOnChangeClient(e, index)}
                    />
                  </div>
                  <div className="mb-2 col-md-6">
                    <Select
                      styles={reactSelectCusStyles}
                      placeholder="Select Branch"
                      id={"select-branch-" + index}
                      isMulti
                      value={val.branch}
                      options={val.options}
                      onChange={(e: any) => this.handleOnChangeBranch(e, index)}
                    />
                  </div>
                </div>
              </div>
            ))}
        </>
      );
    } else if (this.state.branchCondition === "single_client_branch") {
      return (
        <>
          <div className="col-md-12">
            <div className="row mt-3">
              <div className="mb-2 col-md-6">
                {/* <label className="form-label">
                  Client<span className="text-project">*</span>
                </label> */}
              </div>
              <div className="mb-2 col-md-6">
                {/* <label className="form-label">
                  Branch (you can select multiple)
                  <span className="text-project">*</span>
                </label> */}
              </div>
            </div>
          </div>
          {this.state.addBranches &&
            this.state.addBranches.map((val: any, index: number) => (
              <div className="row">
                <div className="mb-2 col-md-6">
                  <label className="form-label">
                    Client<span className="text-project">*</span>
                  </label>
                  <Select
                    styles={reactSelectCusStyles}
                    placeholder="Select Client"
                    isMulti={false}
                    options={this.props.clientList.map((client: any) => ({
                      value: client.id,
                      label: client.first_name + " " + client.last_name,
                    }))}
                    value={val.client}
                    onChange={(e: any) => {
                      this.handleOnChangeClient(e, index);
                    }}
                  />
                </div>
                <div className="mb-2 col-md-6">
                  <label className="form-label">
                    Branch<span className="text-project">*</span>
                  </label>
                  <Select
                    styles={reactSelectCusStyles}
                    placeholder="Select Branch"
                    id={"select-branch-" + index}
                    value={val.branch}
                    options={val.options}
                    onChange={(e: any) => {
                      let newArray: any = [];
                      newArray.push(e);
                      this.handleOnChangeBranch(newArray, index);
                    }}
                  />
                </div>
              </div>
            ))}
        </>
      );
    } else if (this.state.branchCondition === "multi_client_branch") {
      return (
        <>
          <div className="col-md-12">
            <div className="row mt-3">
              <div className="mb-2 col-md-6">
                <label className="form-label">
                  Client<span className="text-project">*</span>
                </label>
              </div>
              <div className="mb-2 col-md-5">
                <label className="form-label">
                  Branch (you can select multiple)
                  <span className="text-project">*</span>
                </label>
              </div>
              <div className="mb-2 col-md-1 ">
                <button
                  onClick={this.handleAddMultiSelect}
                  type="button"
                  className="btn btn-primary plus-btn"
                >
                  <PlusSVG />
                </button>
              </div>
            </div>
          </div>
          {this.state.addBranches &&
            this.state.addBranches.map((val: any, index: number) => (
              <div className="col-md-12">
                <div className="row">
                  <div className="mb-2 col-md-6">
                    <Select
                      styles={reactSelectCusStyles}
                      placeholder="Select Client"
                      isMulti={false}
                      options={this.props.clientList.map((client: any) => ({
                        value: client.id,
                        label: client.first_name + " " + client.last_name,
                      }))}
                      id={"select-client-" + index}
                      value={val.client}
                      onChange={(e: any) => {
                        this.handleOnChangeClient(e, index);
                      }}
                    />
                  </div>
                  <div className="mb-2 col-md-5">
                    <Select
                      styles={reactSelectCusStyles}
                      placeholder="Select Branch"
                      id={"select-branch-" + index}
                      isMulti
                      value={val.branch}
                      options={val.options}
                      onChange={(e: any) => this.handleOnChangeBranch(e, index)}
                    />
                  </div>
                  <div className="mb-2 col-md-1">
                    <a
                      className="close-new-btn"
                      onClick={() => this.handleCloseMultiSelect(index)}
                    >
                      <PlusCloseSVG />
                    </a>
                  </div>
                </div>
              </div>
            ))}
        </>
      );
    } else {
      return null;
    }
  };

  render(): React.ReactNode {
    const userActiveStatus = [
      { value: Constants.ENABLE, label: "Enable" },
      { value: Constants.DISABLE, label: "Disable" },
    ];
    return (
      <div>
        <Formik
          initialValues={{
            addBranches: [
              {
                options: [],
              },
            ],
            first_name: this.props.usermanagements
              ? this.props.usermanagements.first_name
              : "",
            last_name: this.props.usermanagements
              ? this.props.usermanagements.last_name
              : "",
            user_type: this.props.usermanagements.user_type_id,
            email: this.props.usermanagements
              ? this.props.usermanagements.email
              : "",
            phone: this.props.usermanagements
              ? this.props.usermanagements.phone
              : "",
            status: this.props.usermanagements
              ? this.props.usermanagements.status
              : "",
            password: this.props.usermanagements
              ? this.props.usermanagements.password
              : "",
            branch: null,
            selectClientInx: 0,
            selectClientVal: null,
            handleOnChangeStatus: false,
            userTypesStateList: "",
            branchCondition: "",
            clearnerCertificateList: this.props.usermanagements
            ? this.props.usermanagements.certificates
            : "",
            cleanerDocumentShow: this.props.usermanagements.user_type_id===3? true:false,
            cleaner_certificate: this.props.usermanagements
            ? this.props.usermanagements.certificates
            : "",
            cleanerValidate: false,
          }}
          enableReinitialize={true}
          onSubmit={this.handleEditUsermanagement}
          validationSchema={this.validationSchema}
        >
          {({ handleChange, setFieldValue }) => (
            <Form>
              <div className="row">
                <div className="mb-2 col-md-6">
                  <label className="form-label">
                    Role<span className="text-project">*</span>
                  </label>
                  <Field
                    className="custom-select dropdown-field"
                    name="user_type"
                    // options={USER_ROLE}
                    options={this.state.userTypesStateList.map((Utype: any) => {
                      return {
                        value: Utype.id,
                        label: Utype.name,
                        branchaccess: Utype.clientbranchaccess,
                      };
                    })}
                    component={CustomSelect}
                    // value = {this.state.user_type}
                    placeholder="Select Role"
                    isMulti={false}
                    onChange={(e: any) => {
                      this.setState({ user_type: e.value });
                      this.setState({ clientSelectErrorShow: false });
                      this.setState({ branchSelectErrorShow: false });
                      this.setState({ branchCondition: e.branchaccess });
                      this.setState({
                        addBranches: [
                          {
                            options: [],
                          },
                        ],
                      });
                      this.onUserRoleChangeHandle(e, e.value)
                    }}
                  />
                  <ErrorMessage
                    name="user_type"
                    component={"div"}
                    className="cus-alert"
                  />
                </div>
                <div className="mb-2 col-md-6">
                  <label className="form-label">
                    First name<span className="text-project">*</span>
                  </label>
                  <Field
                    name="first_name"
                    type={"text"}
                    className="form-control usermanagement-input"
                  />
                  <ErrorMessage
                    name="first_name"
                    component={"div"}
                    className="cus-alert"
                  />
                </div>
                <div className="mb-2 col-md-6">
                  <label className="form-label">Last name</label>
                  <Field
                    name="last_name"
                    type={"text"}
                    className="form-control usermanagement-input"
                  />
                  <ErrorMessage
                    name="last_name"
                    component={"div"}
                    className="cus-alert"
                  />
                </div>
                <div className="mb-2 col-md-6">
                  <label className="form-label">
                    Email<span className="text-project">*</span>
                  </label>
                  <Field
                    name="email"
                    type={"text"}
                    className="form-control usermanagement-input"
                  />
                  <ErrorMessage
                    name="email"
                    component={"div"}
                    className="cus-alert"
                  />
                  {/* {this.props.addUsermanagementError === "Email already exist" && <div className="cus-alert">{this.props.addUsermanagementError}</div>} */}
                  {this.state.editToast ? (
                    <Toast className="yellow-alert">
                      <Toast.Body>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <AlertToastSVG />
                          </div>
                          <div className="col-md-8">
                            {this.props.editUsermanagementError}
                          </div>
                          <div className="col-md-2">
                            <button
                              className="btn"
                              type="button"
                              onClick={this.editToastClose}
                            >
                              <CloseBtnSVG />
                            </button>
                          </div>
                        </div>
                      </Toast.Body>
                    </Toast>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mb-2 col-md-6">
                  <label className="form-label">Phone</label>
                  <Field name="phone">
                    {({ field, form, meta }: FieldProps<any>) => (
                      <div>
                        <PhoneInput
                          {...field}
                          inputProps={{ name: "phone" }}
                          specialLabel={""}
                          onChange={(value, data, e) => field.onChange(e)}
                          onBlur={field.onBlur}
                          value={field.value}
                          country={"au"}
                          inputStyle={{
                            paddingTop: 15,
                            paddingRight: 15,
                            paddingBottom: 15,
                            paddingLeft: 57,
                            width: "100%",
                            background: "#FFFFFF",
                            border: "1px solid #F0F0F0",
                            borderRadius: "8px",
                          }}
                        />
                        {meta.touched && meta.error && (
                          <div className="error">{meta.error}</div>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                {this.state.user_type !== Constants.CLIENT_UT ? (
                  <div className="mb-2 col-md-6">
                    <label className="form-label">
                      Password<span className="text-project">*</span>
                    </label>
                    <div className="eye-field">
                      <Field
                        name="password"
                        className="form-control usermanagement-input password-input"
                        type={this.state.hidden ? "password" : "text"}
                      />
                      <button
                        type="button"
                        className="eye btn"
                        onClick={this.toggleShow}
                      >
                        {this.state.hidden ? <EyeOffSVG /> : <EyeSVG />}
                      </button>
                    </div>
                    <ErrorMessage
                      name="password"
                      component={"div"}
                      className="cus-alert"
                    />
                  </div>
                ) : (
                  <></>
                )}

                <>{this.handlebranch()}</>

                {/* {this.state.user_type === Constants.SUPERVISOR_UT ||
                  this.state.user_type === Constants.OPERATION_MANAGER_UT ? (
                  <div className="col-md-12">
                    <div className="row mt-3">
                      <div className="mb-2 col-md-6">
                        <label className="form-label">
                          Client<span className="text-project">*</span>
                        </label>
                      </div>
                      <div className="mb-2 col-md-5">
                        <label className="form-label">
                          Branch (you can select multiple)
                          <span className="text-project">*</span>
                        </label>
                      </div>
                      <div className="mb-2 col-md-1">
                        <button
                          onClick={this.handleAddMultiSelect}
                          type="button"
                          className="btn btn-primary plus-btn"
                        >
                          <PlusSVG />
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {this.state.user_type === Constants.SUPERVISOR_UT &&
                  this.state.addBranches &&
                  this.state.addBranches.map((val: any, index: number) => (
                    <div>
                      <div className="row">
                        <div className="mb-2 col-md-6">
                          <Select
                            styles={reactSelectCusStyles}
                            placeholder="Select Client"
                            isMulti={false}
                            value={val.client}
                            options={this.props.clientList.map(
                              (client: any) => ({
                                value: client.id,
                                label:
                                  client.first_name + " " + client.last_name,
                              })
                            )}
                            id={"select-client-" + index}
                            onChange={(e: any) => {

                              this.handleOnChangeClient(e, index)
                            }
                            }
                          />
                        </div>
                        <div className="mb-2 col-md-5">
                          <Select
                            styles={reactSelectCusStyles}
                            id={"select-branch-" + index}
                            value={val.branch}
                            isMulti
                            options={val.options}
                            onChange={(e: any) =>
                              this.handleOnChangeBranch(e, index)
                            }
                          />
                        </div>
                        <div className="mb-2 col-md-1">
                          <a
                            className="close-new-btn"
                            onClick={() => this.handleCloseMultiSelect(index)}
                          >
                            <PlusCloseSVG />
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                {this.state.user_type === Constants.OPERATION_MANAGER_UT &&
                  this.state.addBranches &&
                  this.state.addBranches.map((val: any, index: number) => (
                    <div>
                      <div className="row">
                        <div className="mb-2 col-md-6">
                          <Select
                            styles={reactSelectCusStyles}
                            placeholder="Select Client"
                            isMulti={false}
                            value={val.client}
                            options={this.props.clientList.map(
                              (client: any) => ({
                                value: client.id,
                                label:
                                  client.first_name + " " + client.last_name,
                              })
                            )}
                            id={"select-client-" + index}
                            onChange={(e: any) => {
                              this.handleOnChangeClient(e, index)
                            }
                            }
                          />
                        </div>
                        <div className="mb-2 col-md-5">
                          <Select
                            styles={reactSelectCusStyles}
                            id={"select-branch-" + index}
                            value={val.branch}
                            isMulti
                            options={val.options}
                            onChange={(e: any) =>
                              this.handleOnChangeBranch(e, index)
                            }
                          />
                        </div>
                        <div className="mb-2 col-md-1">
                          <a
                            className="close-new-btn"
                            onClick={() => this.handleCloseMultiSelect(index)}
                          >
                            <PlusCloseSVG />
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                {this.state.user_type === Constants.QA_UT &&
                  this.state.addBranches &&
                  this.state.addBranches.map((val: any, index: number) => (
                    <div className="row">
                      <div className="mb-2 col-md-6">
                        <label className="form-label">
                          Client<span className="text-project">*</span>
                        </label>
                        <Select
                          styles={reactSelectCusStyles}
                          placeholder="Select Client"
                          isMulti={false}
                          value={val.client}
                          options={this.props.clientList.map((client: any) => ({
                            value: client.id,
                            label: client.first_name + " " + client.last_name,
                          }))}
                          onChange={(e: any) => {

                            this.handleOnChangeClient(e, index)
                          }
                          }
                        />
                      </div>
                      <div className="mb-2 col-md-6">
                        <label className="form-label">
                          Branch<span className="text-project">*</span>
                        </label>
                        <Select
                          styles={reactSelectCusStyles}
                          id={"select-branch-" + index}
                          value={val.branch}
                          options={val.options}
                          onChange={(e: any) =>
                            this.handleOnChangeBranch(e, index)
                          }
                        />
                      </div>
                    </div>
                  ))}
                {this.state.user_type === Constants.MANAGER_UT ||
                  this.state.user_type === Constants.QA_MANAGER_UT ||
                  this.state.user_type === Constants.SUPERVISOR_MANAGER_UT ? (
                  <div className="col-md-12">
                    <div className="row mt-3">
                      <div className="mb-2 col-md-6">
                        <label className="form-label">
                          Client<span className="text-project">*</span>
                        </label>
                      </div>
                      <div className="mb-2 col-md-6">
                        <label className="form-label">
                          Branch (you can select multiple)
                          <span className="text-project">*</span>
                        </label>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {(this.state.user_type === Constants.MANAGER_UT ||
                  this.state.user_type === Constants.QA_MANAGER_UT) &&
                  this.state.addBranches &&
                  this.state.addBranches.map((val: any, index: number) => (
                    <div className="col-md-12">
                      <div className="row">
                        <div className="mb-2 col-md-6">
                          <Select
                            styles={reactSelectCusStyles}
                            placeholder="Select Client"
                            isMulti={false}
                            value={val.client}
                            options={this.props.clientList.map(
                              (client: any) => ({
                                value: client.id,
                                label:
                                  client.first_name + " " + client.last_name,
                              })
                            )}
                            id={"select-client-" + index}
                            onChange={(e: any) =>
                              this.handleOnChangeClient(e, index)
                            }
                          />
                        </div>
                        <div className="mb-2 col-md-6">
                          <Select
                            styles={reactSelectCusStyles}
                            id={"select-branch-" + index}
                            value={val.branch}
                            isMulti
                            options={this.props.branchList}
                            onChange={(e: any) =>
                              this.handleOnChangeBranch(e, index)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                {this.state.user_type === Constants.SUPERVISOR_MANAGER_UT &&
                  this.state.addBranches &&
                  this.state.addBranches.map((val: any, index: number) => (
                    <div className="col-md-12">
                      <div className="row">
                        <div className="mb-2 col-md-6">
                          <Select
                            styles={reactSelectCusStyles}
                            placeholder="Select Client"
                            isMulti={false}
                            value={val.client}
                            options={this.props.clientList.map(
                              (client: any) => ({
                                value: client.id,
                                label:
                                  client.first_name + " " + client.last_name,
                              })
                            )}
                            id={"select-client-" + index}
                            onChange={(e: any) =>
                              this.handleOnChangeClient(e, index)
                            }
                          />
                        </div>
                        <div className="mb-2 col-md-6">
                          <Select
                            styles={reactSelectCusStyles}
                            id={"select-branch-" + index}
                            value={val.branch}
                            isMulti
                            options={this.props.branchList}
                            onChange={(e: any) =>
                              this.handleOnChangeBranch(e, index)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))} */}
                {this.state.addBranches !== 0 && (
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-6">
                        {this.state.clientSelectErrorShow === true && (
                          <p className="text-danger">Please select Client</p>
                        )}
                      </div>
                      <div className="col-6">
                        {this.state.branchSelectErrorShow === true && (
                          <p className="text-danger">Please select Branch</p>
                        )}
                      </div>
                    </div>
                  </div>
                )}

{this.props.getCeritficateData && this.state.cleanerDocumentShow === true ?(
<div className="mb-4 col-md-6">
                <label className="form-label">Cleaner Ceritficate</label>
                <Field
                  className="custom-select"
                  name="cleaner_certificate"
                  options={this.state.clearnerCertificateList}
                  component={CustomSelect}
                  isMulti={true}
                  onChange={()=> this.setState({cleanerValidate:false})}
                />
                {this.state.cleanerValidate && this.state.cleanerDocumentShow?
                     <div style={{color:"red"}}>
                      Please atleast assign one certificate to cleaner
                     </div>
            :null}
              </div>
              ):null}
                <div className="mb-2 col-md-6">
                  <label className="form-label">
                    Is Active<span className="text-project">*</span>
                  </label>
                  <Select
                    styles={reactSelectCusStyles}
                    isMulti={false}
                    value={this.state.userSelectedActiveStatus}
                    options={userActiveStatus}
                    onChange={(e: any) =>
                      this.onUserStatusChangeHandle(e, e.value)
                    }
                  />
                </div>
                <div className="row mt-3 mb-3">
                  <div className="col-md-12 cus-progress">
                    <Modal
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      show={this.props.processTrigger}
                    >
                      <Modal.Body className="p-0">
                        <ProgressBar
                          now={this.props.progressPercentage}
                          label={
                            this.props.progressPercentage === 100
                              ? `Processing your request...`
                              : `User Updating....${this.props.progressPercentage}%`
                          }
                        />
                      </Modal.Body>
                    </Modal>
                    <div className="d-flex">
                      <div className="d-flex mr-16px">
                        <button
                          type="submit"
                          id="create-button"
                          className="btn btn-primary create-button"
                        >
                          Save{" "}
                          <span
                            id="spinner-border"
                            className="spinner-border spinner-border-sm d-none"
                          ></span>
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn cancel-button"
                          onClick={() =>
                            this.props.updateEditUsermanagementDlgStatus(false)
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  usermanagements: state.usermanagement.usermanagements,
  branchList: state.tasktemplate.branchList,
  clientList: state.contract.clientList,
  editUsermanagementError: state.usermanagement.editUsermanagementError,
  processTrigger: state.usermanagement.processTrigger,
  editUsermanagementSuccess: state.usermanagement.editUsermanagementSuccess,
  progressPercentage: state.usermanagement.progressPercentage,
  userTypesList: state.app.userTypesList,
  getCeritficateData: state.usermanagement.cleanerCertificates
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    editUsermanagement: bindActionCreators(editUsermanagement, dispatch),
    updateEditUsermanagementDlgStatus: bindActionCreators(
      updateEditUsermanagementDlgStatus,
      dispatch
    ),
    updateUsermanagementFetchStatus: bindActionCreators(
      updateUsermanagementFetchStatus,
      dispatch
    ),
    listAllClient: bindActionCreators(listAllClient, dispatch),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
    getUserTypesList: bindActionCreators(getUserTypesList, dispatch),
    getCertificateList: bindActionCreators(getAllCertificates, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUsermanagement);
