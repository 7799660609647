import { Dispatch, AnyAction } from "redux";
import {
  GET_CLEANER_LOGIN_BRANCH_LIST,
  GET_CLEANER_LOGIN_BRANCH_LIST_SUCCESS,
  GET_CLEANER_LOGIN_BRANCH_LIST_FAIL,
  GET_CLEANER_LOGIN_BRANCH_TASK_LIST,
  GET_CLEANER_LOGIN_BRANCH_TASK_LIST_SUCCESS,
  GET_CLEANER_LOGIN_BRANCH_TASK_LIST_FAIL,
  GET_CLEANER_LOGIN_BRANCH_TASK_DETAILS,
  GET_CLEANER_LOGIN_BRANCH_TASK_DETAILS_SUCCESS,
  GET_CLEANER_LOGIN_BRANCH_TASK_DETAILS_FAIL,
  UPDATE_VIEW_TASK_LIST_STATUS,
  UPDATE_VIEW_TASK_DETAILS_STATUS,
  UPDATE_ADD_EDIT_COMMENTS_STATUS,
  API_CLEANER_ADD_COMMENT,
  API_CLEANER_ADD_COMMENT_SUCCESS,
  API_CLEANER_ADD_COMMENT_FAIL,
  API_CLEANER_ADD_COMMENT_PROGRESS,
  API_CLEANER_EDIT_COMMENT,
  API_CLEANER_EDIT_COMMENT_SUCCESS,
  API_CLEANER_EDIT_COMMENT_FAIL,
  API_CLEANER_EDIT_COMMENT_PROGRESS,
  EDIT_CLEANER_TASK_DETAILS_DATA,
  API_CLEANER_DELETE_COMMENT,
  CleanerHomeState
} from "../ActionTypes";

const initialState: CleanerHomeState = {
  getCleanerBranchListData : null,
  getCleanerBranchTaskListData : null,
  getCleanerBranchTaskDetailsData : null,
  viewTaskListStatus : false,
  viewTaskDetailsStatus : false,
  addEditTaskCommentsModalStatus : false,
  processTrigger : false,
  progressPercentage : 0,
  getEditTaskDetailsData : [],
  deleteCleanerCommentsSuccessStatus : false,
};

export const CleanerHomeReducer = (
  state: CleanerHomeState = initialState,
  action: AnyAction
): CleanerHomeState => {
  switch (action.type) {
    case GET_CLEANER_LOGIN_BRANCH_LIST:
      return { ...state };
    case GET_CLEANER_LOGIN_BRANCH_LIST_SUCCESS:
      let getCleanerBranchListData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "branches" in data && data.branches) {
          getCleanerBranchListData = data.branches;
        }
      }
      return {
        ...state,
        getCleanerBranchListData: getCleanerBranchListData,
      };
    case GET_CLEANER_LOGIN_BRANCH_LIST_FAIL:
      return { ...state };
    case GET_CLEANER_LOGIN_BRANCH_TASK_LIST:
      return { ...state };
    case GET_CLEANER_LOGIN_BRANCH_TASK_LIST_SUCCESS:
      let getCleanerBranchTaskListData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task_list" in data && data.task_list) {
          getCleanerBranchTaskListData = data.task_list;
        }
      }
      return {
        ...state,
        getCleanerBranchTaskListData: getCleanerBranchTaskListData,
        viewTaskListStatus : true
      };
    case GET_CLEANER_LOGIN_BRANCH_TASK_LIST_FAIL:
      return { ...state };
    case GET_CLEANER_LOGIN_BRANCH_TASK_DETAILS:
      return { ...state };
    case GET_CLEANER_LOGIN_BRANCH_TASK_DETAILS_SUCCESS:
      let getCleanerBranchTaskDetailsData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task" in data && data.task) {
          getCleanerBranchTaskDetailsData = data.task;
        }
      }
      return {
        ...state,
        getCleanerBranchTaskDetailsData: getCleanerBranchTaskDetailsData,
        viewTaskDetailsStatus : true,
        viewTaskListStatus : false,
        deleteCleanerCommentsSuccessStatus : false,
      };
    case GET_CLEANER_LOGIN_BRANCH_TASK_DETAILS_FAIL:
      return { ...state };
    case UPDATE_VIEW_TASK_LIST_STATUS:
      return { ...state, viewTaskListStatus: action.payload };
    case UPDATE_VIEW_TASK_DETAILS_STATUS:
      return { ...state, viewTaskDetailsStatus: action.payload };
    case UPDATE_ADD_EDIT_COMMENTS_STATUS:
      return { ...state, addEditTaskCommentsModalStatus: action.payload };
    case API_CLEANER_ADD_COMMENT:
        return { ...state, processTrigger: true };
    case API_CLEANER_ADD_COMMENT_SUCCESS:
        return { ...state, addEditTaskCommentsModalStatus: false, viewTaskDetailsStatus : true, processTrigger: false, progressPercentage:0 };
    case API_CLEANER_ADD_COMMENT_FAIL:
    case API_CLEANER_ADD_COMMENT_PROGRESS:
      return {...state,progressPercentage: action.payload }
    case API_CLEANER_EDIT_COMMENT:
      return { ...state, addEditTaskCommentsModalStatus: true, processTrigger: true };
    case API_CLEANER_EDIT_COMMENT_SUCCESS:
        return { ...state, addEditTaskCommentsModalStatus: false, viewTaskDetailsStatus : true, processTrigger: false, progressPercentage:0 };
    case API_CLEANER_EDIT_COMMENT_FAIL:
    case API_CLEANER_EDIT_COMMENT_PROGRESS:
      return {...state,progressPercentage: action.payload }
    case EDIT_CLEANER_TASK_DETAILS_DATA:
      return {...state,getEditTaskDetailsData: action.payload }
    case API_CLEANER_DELETE_COMMENT:
      return {...state,deleteCleanerCommentsSuccessStatus: true }
    default:
      return state;
  }
};

export function getCleanerLoginBranchList() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: GET_CLEANER_LOGIN_BRANCH_LIST,
      payload: {
        request: {
          method: "get",
          url: `branch/cleaner-branch-list`,
        },
      },
    });
  };
}

export function getCleanerLoginBranchTaskList(branch_id : number) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: GET_CLEANER_LOGIN_BRANCH_TASK_LIST,
      payload: {
        request: {
          method: "get",
          url: `task/my-task/${branch_id}`,
        },
      },
    });
  };
}

export function getCleanerLoginBranchTaskDetails(task_id : number) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: GET_CLEANER_LOGIN_BRANCH_TASK_DETAILS,
      payload: {
        request: {  
          method: "get",
          url: `task/cleaner/${task_id}`,
        },
      },
    });
  };
}

export function changeViewTaskListStatus(status:boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_VIEW_TASK_LIST_STATUS,
      payload: status,
    });
  };
}
export function changeViewTaskDetailsStatus(status:boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_VIEW_TASK_DETAILS_STATUS,
      payload: status,
    });
  };
}

export function changeEditTaskDetailsData(data:any){
  return async (dispatch: Dispatch) => {
    dispatch({
      type: EDIT_CLEANER_TASK_DETAILS_DATA,
      payload: data,
    });
  };
}
export function changeAddEditStatusCommetsStatus(status:boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_ADD_EDIT_COMMENTS_STATUS,
      payload: status,
    });
  };
}

// export function addCleanerComments(payload : any) {
//   return async (dispatch: Dispatch) => {
//     dispatch({
//       type: API_CLEANER_ADD_COMMENT,
//       payload: {
//         request: {
//           method: "post",
//           url: `comments`,
//           data: payload,
//           onUploadProgress: (progressEvent:any) => {
//             const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
//             return dispatch({ type: API_CLEANER_ADD_COMMENT_PROGRESS, payload: progress });
//           }
//         },
//       },
//     });
//   };
// }

export function editCleanerComments(payload : any, id : number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_CLEANER_EDIT_COMMENT,
      payload: {
        request: {
          method: "put",
          url: `comments/${id}`,
          data: payload,
          onUploadProgress: (progressEvent:any) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            return dispatch({ type: API_CLEANER_EDIT_COMMENT_PROGRESS, payload: progress });
          }
        },
      },
    });
  };
}

export function deleteCleanerComments(id : number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_CLEANER_DELETE_COMMENT,
      payload: {
        request: {
          method: "delete",
          url: `comments/${id}`,
        },
      },
    });
  };
}
