import React, { Component } from "react";
import * as Yup from "yup";
import SideBar from "../../components/sidebar/SideBar";
import TopNav from "../../components/topnav/TopNav";
import { connect } from "react-redux";
import { SearchSVG } from "../../assets/images/UsermanagementsSVG";
import { AlertToastSVG, CloseBtnSVG, PaperClipSVG, TrashSVG } from "../../assets/images/ImagesV2";
import Constants, { TASK_PRIORITY } from "../../shared/Constants";
import ImageDrawing from "../../export/ImageDrawing";
import ApprovedTable from "./ApprovedTable";
import ApprovedExcel from "../../export/approvedExcel";
import ReactPaginate from "react-paginate";
import {
  addDailyTask,
  listAllBranch,
  listAllCleaner,
  listAllClient,
  getAllAdditionalTasks,
  getAllApprovedTasks,
  updateSidebar,
} from "../../shared/Reducers";
import { Modal, Toast } from "react-bootstrap";
import { IBranchSelect, ICleaner } from "../../shared/ActionTypes";
import DatePicker from "../DailyTask/DatePicker";
import docImage from "../../assets/images/google-docs.png";
import { CustomSelect } from "../../shared/Components/CustomSelect";
import TimePicker from "../DailyTask/TimePicker";
import moment from "moment";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Offcanvas } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { RootState } from "../../index";
import { Dispatch, bindActionCreators } from "redux";
import { ApprovedTaskProps, ApprovedTaskState } from "../../types/approvedtask";

class ApprovedTask extends React.Component<
  ApprovedTaskProps,
  ApprovedTaskState
> {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  constructor(props: ApprovedTaskProps) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      canvasModal: false,
      attachmentFiles: [],
      attachmentUrls: [],
      files_size: 0,
      AttachmentPreviewStatus: false,
      previewUrl: "",
      previewType: "",
      file_count: "",
      selectedBranch: { value: "all", label: "All" },
      task_date: moment(new Date()).format("YYYY-MM-DD"),
      start_time: moment(new Date()).format("HH:mm"),
      end_time: moment(new Date()).format("HH:mm"),
      signatureImage: "",
      signatureModal: false,
      signatureImageError: "",
      context: null,
      isDrawing: false,
      signLastX: 0,
      signLastY: 0,
      searchTerm: "",
      fieldName: "",
      orderType: "",
      pagehandle: false,
      timeout: null,
      isSignChange: false,
      addToast: false,
    };
  }

  componentDidMount(): void {
    this.props.updateSidebar(true);
    if (
      Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
      Constants.QA_MANAGERUTINT === Constants.LOGIN_USER_TYPE ||
      Constants.SUPERVISOR_MANAGERUTINT === Constants.LOGIN_USER_TYPE
    ) {
      this.props.listAllBranch(0);
    } else {
      this.listData();
    }
    this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.add("d-none");
      button.removeAttribute("disabled");
    }

    // date picker readonly
    let datePicker: NodeListOf<Element> = document.querySelectorAll(
      ".MuiFormControl-root input"
    ) as NodeListOf<Element>;
    if (datePicker.length > 0) {
      for (let index = 0; index < datePicker.length; index++) {
        const element = datePicker[index];
        datePicker[index].setAttribute("readonly", "true");
      }
    }
  }

  componentDidUpdate(
    prevProps: Readonly<ApprovedTaskProps>,
    prevState: Readonly<ApprovedTaskState>,
    snapshot?: any
  ): void {  
    if (prevProps.addDailyTaskSuccess !== this.props.addDailyTaskSuccess) {
      if (
        this.props.addDailyTaskSuccess === "Successfully created" ||
        this.props.addDailyTaskSuccess === "Internal server error"
      ) {
        this.setState({ addToast: true });
        setTimeout(() => {
          this.setState({ addToast: false });
        }, 5000);
      }
    }
  }

  handlePageClick = (e: any) => {
    console.log("handlepageclick", e);

    if (e.selected < this.props.total_Count / Constants.PER_PAGE) {
      let query = `search=${this.state.searchTerm}&sort=${this.state.fieldName
        }&order=${this.state.orderType}&offset=${e.selected * Constants.PER_PAGE
        }&limit=${Constants.PER_PAGE}&client=${this.props.filterTaskClientById
        }&branch=${this.props.filterTaskBranchById}&task_type=2&task_priority=${this.props.filterTaskPriorityById
        }&due_type=${this.props.filterTaskDueTypeByValue}&status&start_date=${this.props.filterTaskDateTypeValue.from_date
        }&end_date=${this.props.filterTaskDateTypeValue.to_date
        }&additional_task_status=all`;
      this.props.getAllAdditionalTasks(query);
      // this.getTasks(e.selected * Constants.PER_PAGE, Constants.PER_PAGE);
    }
  };

  handleFilter = (e: any) => {
    const value: string = e.target.value.toLowerCase();
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }
    this.setState({
      searchTerm: value,
      timeout: setTimeout(async () => {
        try {
          let query = `search=${value}&sort=&order=&offset=0&limit=10&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=2&task_priority=${this.props.filterTaskPriorityById}&status&additional_task_status=all`;
          // no limit data
          console.log(query, "QueryLog");
          let AllListquery = `search=${value}&sort=&order=&offset=0&limit=10000&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=2&task_priority=${this.props.filterTaskPriorityById}&status&additional_task_status=all`;
          await this.props.getAllAdditionalTasks(query);
          await this.props.getAllApprovedTasks(AllListquery);
        } catch (error) {
          console.log(error);
        }
      }, 1000), // Set the delay time in ms
    });
  };

  getTasks = async (offset: number, limit: number) => {
    console.log(offset, "LogOffset");
    try {
      // list for table
      let query = `search=&sort=&order=&offset=${offset}&limit=${limit}&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=2&task_priority=${this.props.filterTaskPriorityById}&due_type=${this.props.filterTaskDueTypeByValue}&task_status=${this.props.filterTaskStatusById}&special_request_task_status=${this.props.filterSpecialRequestTaskStatusById}&status&start_date=${this.props.filterTaskDateTypeValue.from_date}&end_date=${this.props.filterTaskDateTypeValue.to_date}&additional_task_status=all`;

      // list for excel report
      let AllListquery = `search=&sort=&order=&offset=${offset}&limit=10000&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=2&task_priority=${this.props.filterTaskPriorityById}&due_type=${this.props.filterTaskDueTypeByValue}&task_status=${this.props.filterTaskStatusById}&special_request_task_status=${this.props.filterSpecialRequestTaskStatusById}&status&start_date=${this.props.filterTaskDateTypeValue.from_date}&end_date=${this.props.filterTaskDateTypeValue.to_date}`;
      await this.props.getAllAdditionalTasks(query);
      await this.props.getAllApprovedTasks(AllListquery);
    } catch (error) {
      console.log(error);
    }
  };

  listData = () => {
    this.props.listAllClient();
    this.props.listAllCleaner();
  };

  handleOpenAdditionalTaskModal = () => {
    this.props.listAllClient();
    this.props.listAllCleaner();
    this.setState({ canvasModal: true });
  };

  handleCloseAdditionalTaskModal = () => {
    this.setState({
      canvasModal: false,
      signatureImage: "",
      attachmentUrls: [],
    });
  };

  handleChange = (e: any) => {
    if (e.target.name === "client") {
      this.setState({ selectedBranch: { value: "all", label: "All" } });
      this.props.listAllBranch(e.target.value);
    }
  };

  handleImageChange = async (e: any) => {
    const file = e.target.files[0];
    this.setState(
      { files_size: this.state.files_size + file.size },
      async () => {
        if (this.state.files_size > 524288000) {
          alert("File Size Must Be Less Than 500MB");
          e.target.value = null;
          return this.setState({
            files_size: this.state.files_size - file.size,
          });
        }

        let files = [];
        let urls = [];
        files = [...e.target.files];

        let fileurls: any = [];
        await files.map(async (file: any) => {
          fileurls.push({
            url: URL.createObjectURL(file),
            file_type: file.type,
          });
        });
        urls = [...fileurls];

        this.setState({
          attachmentFiles: [...this.state.attachmentFiles, ...files],
        });
        this.setState(
          { attachmentUrls: [...this.state.attachmentUrls, ...urls] },
          () => {
            e.target.value = "";
          }
        );
      }
    );
  };

  handlePreviewOpen = (file_type: any, url: any, inx: any) => {
    if (
      file_type === "image/png" ||
      file_type === "image/jpeg" ||
      file_type === "image/jpg" ||
      file_type === "video/mp4" ||
      file_type === "video/webm" ||
      file_type === "application/pdf"
    ) {
      this.setState({
        AttachmentPreviewStatus: true,
        previewUrl: url,
        previewType: file_type,
        file_count: inx,
      });
    }
  };

  handleDeleteImage = (index: number) => {
    const files = this.state.attachmentFiles;
    const urls = this.state.attachmentUrls;
    files.splice(index, 1);
    urls.splice(index, 1);

    this.setState({ attachmentFiles: files });
    this.setState({ attachmentUrls: urls });
  };

  handleStartDateClick = () => {
    let datepopup: HTMLElement = document.querySelector(
      ".MuiPickersPopper-root"
    ) as HTMLElement;
    if (datepopup === null) {
      let startDate: HTMLElement = document.querySelector(
        ".startdate button.MuiButtonBase-root"
      ) as HTMLElement;
      startDate.click();
    }
  };

  handleTaskDate = (val: string) => {
    console.log(val, "val");

    this.setState({ task_date: val });
  };

  handleStartTime = (val: string) => {
    this.setState({ start_time: val });
  };

  handleEndTime = (val: string) => {
    this.setState({ end_time: val });
  };

  handleSignModal = () => {
    this.setState({ signatureModal: true });
  };

  handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
    this.setState({ signatureImageError: "" });

    this.setState({ isDrawing: true });
    const canvas = this.canvasRef.current;

    if (!canvas) return;
    this.context = canvas.getContext("2d");
    this.setState({ context: canvas.getContext("2d") });
    if (!this.context) return;

    const { offsetX, offsetY } = event.nativeEvent;

    this.setState({ signLastX: offsetX, signLastY: offsetY });
  };

  handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    this.setState({ signatureImageError: "" });

    if (!this.state.isDrawing) return;
    const canvas = this.canvasRef.current;

    if (canvas) {
      const { offsetX, offsetY } = event.nativeEvent;

      // Draw a line segment from the last recorded point to the current point
      let context: any = this.state.context;
      context.strokeStyle = "#000"; // Stroke color
      context.lineWidth = 2; // Stroke width
      context.beginPath();
      context.moveTo(this.state.signLastX, this.state.signLastY);
      context.lineTo(offsetX, offsetY);
      context.stroke();

      this.setState({ signLastX: offsetX, signLastY: offsetY });
    }
  };

  handleMouseUp = () => {
    this.setState({ signatureImageError: "" });

    this.setState({ isDrawing: false });
  };

  handleTouchStart = (event: React.TouchEvent<HTMLCanvasElement>) => {
    this.setState({ signatureImageError: "" });

    this.setState({ isDrawing: true });
    const canvas = this.canvasRef.current;
    console.log(canvas);

    if (!canvas) return;
    let context: any = this.state.context;
    context = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    if (!context) return;

    const { clientX, clientY } = event.nativeEvent.changedTouches[0];

    this.setState({ signLastX: clientX - rect.left, signLastY: clientY - 111 });
  };

  handleTouchMove = (event: React.TouchEvent<HTMLCanvasElement>) => {
    this.setState({ signatureImageError: "" });

    if (!this.state.isDrawing) return;
    const canvas = this.canvasRef.current;
    let context: any = this.state.context;
    if (!canvas || !context) return;

    const { clientX, clientY } = event.nativeEvent.changedTouches[0];
    const rect = canvas.getBoundingClientRect();
    // const x = touch.clientX - rect.left;
    // const y = touch.clientY - rect.top;

    // Draw a line segment from the last recorded point to the current point
    context.strokeStyle = "#000"; // Stroke color
    context.lineWidth = 2; // Stroke width
    context.beginPath();
    context.moveTo(this.state.signLastX, this.state.signLastY);
    context.lineTo(clientX - rect.left, clientY - rect.top);
    context.stroke();
    this.setState({
      signLastX: clientX - rect.left,
      signLastY: clientY - rect.top,
    });
  };

  handleTouchEnd = () => {
    this.setState({ signatureImageError: "", isDrawing: false });
  };

  handleClear = () => {
    const canvas = this.canvasRef.current;
    let context: any = this.state.context;
    if (!canvas || !context) return;

    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  handleConvertToImage = () => {
    const canvas = this.canvasRef.current;
    if (!canvas) return;

    const image: string = canvas.toDataURL("image/png");

    if (
      image !==
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACWCAYAAABkW7XSAAAAAXNSR0IArs4c6QAABGJJREFUeF7t1AEJAAAMAsHZv/RyPNwSyDncOQIECEQEFskpJgECBM5geQICBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAgQdWMQCX4yW9owAAAABJRU5ErkJggg=="
    ) {
      this.setState({
        signatureImage: image,
        signatureModal: false,
        isSignChange: true,
      });
    }
  };

  addToastClose = () => {
    this.setState({
      addToast: false,
    });
  };

  handleAddDailyTask = async (formValues: any) => {
    console.log("formValues", formValues);

    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.remove("d-none");
      button.setAttribute("disabled", "");
    }
    let data = new FormData();
    data.append("title", formValues.title);
    // data.append("is_active", `${Constants.IS_ACTIVE}`);
    for (let index = 0; index < this.state.attachmentFiles.length; index++) {
      data.append("attachments", this.state.attachmentFiles[index]);
    }
    data.append("sp_task_signature", this.state.signatureImage);
    data.append("client", formValues.client);
    data.append("branch", formValues.branch);
    data.append("task_type", "2");
    data.append("description", formValues.description);
    data.append("task_date", this.state.task_date);
    if (formValues.any_time === "1") {
      data.append("any_time", formValues.any_time);
    } else {
      data.append("any_time", "0");
      data.append("start_time", this.state.start_time);
      data.append("end_time", this.state.end_time);
    }

    data.append("ref_task_id", "1");
    data.append("priority", formValues.priority);
    data.append("additional_task_status", "0");
    await this.props.addDailyTask(data);
    await this.props.getAllAdditionalTasks();
    await this.props.getAllApprovedTasks();
    setTimeout(() => {
      this.getTasks(Constants.OFFSET, Constants.PER_PAGE);
      this.handleCloseAdditionalTaskModal();
    }, 500);
  };

  validationSchema = () => {
    return Yup.object().shape({
      title: Yup.string().required("Enter Task Name"),
      description: Yup.string().required("Enter Task Description"),
      is_active: Yup.string(),
      attachment: Yup.mixed(),
      any_time: Yup.string(),
      custom_time: Yup.string(),
      start_time: Yup.string(),
      end_time: Yup.string(),
      client: Yup.string().required("Client is required"),
      branch: Yup.string().required("Branch is required"),
      priority: Yup.string().required("Task Priority is required"),
    });
  };

  render() {
    const initialValues = {
      title: "",
      description: "",
      client: "",
      cleaners: [],
      // branch: this.state.selectedBranch,
      attachments: "",
      task_type: 0,
      start_time: "00:00",
      end_time: "00:00",
      any_time: "1",
      custom_time: "0",
      // task_with_sign_in: Constants.TASK_WITH_SIGN_IN_YES,
      // is_active: Constants.IS_ACTIVE,
      priority: TASK_PRIORITY.MEDIUM,
      task_date: "",
    };
    console.log("Approved_Task", this.props.filteredAdditionalTaskData);

    return (
      <div className="container-fluid p-0 m-0 page-body-wrapper background">
        <SideBar />
        <div className={this.props.status ? "main-panel" : "main-panel2"}>
          <TopNav />
          <div className="content-wrapper">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="mb-3">Approved Task</h3>
                </div>
              </div>

              {/* <div className="container justify-content-md-center response"> */}
              <div className="row">
                <div className="col-md-4 col-lg-4">
                  <div className="input-group search">
                    <span
                      className="input-group-text search-icon"
                      id="basic-addon1"
                    >
                      <SearchSVG />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={this.state.searchTerm}
                      onInput={this.handleFilter}
                      onChange={(e) =>
                        this.setState({ searchTerm: e.target.value })
                      }
                    />
                  </div>
                </div>
                <Offcanvas
                  className="task-offcanvas"
                  placement="end"
                  show={this.state.canvasModal}
                  onHide={() => this.handleCloseAdditionalTaskModal()}
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                      <span className="offc-title">Add Additional Task</span>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Formik
                      initialValues={initialValues}
                      enableReinitialize={true}
                      onSubmit={this.handleAddDailyTask}
                      validationSchema={this.validationSchema}
                    >
                      {(props) => (
                        <Form onChange={this.handleChange}>
                          <div className="mb-2">
                            <label className="form-label">
                              Task Name<span className="text-project">*</span>
                            </label>
                            <Field
                              name="title"
                              type="text"
                              className="form-control text-field"
                            />
                            <ErrorMessage
                              name="title"
                              component="div"
                              className="cus-alert"
                            />
                          </div>
                          <div className="mb-2">
                            <label className="form-label">
                              Task Description
                              <span className="text-project">*</span>
                            </label>
                            <Field
                              as="textarea"
                              name="description"
                              className="form-control text-area"
                            />
                            <ErrorMessage
                              name="description"
                              component={"div"}
                              className="cus-alert"
                            />
                          </div>
                          {this.state.addToast ? (
                            <Toast className="yellow-alert">
                              <Toast.Body>
                                <div className="row align-items-center">
                                  <div className="col-md-2">
                                    <AlertToastSVG />
                                  </div>
                                  <div className="col-md-8">
                                    {this.props.addDailyTaskSuccess}
                                  </div>
                                  <div className="col-md-2">
                                    <button
                                      className="btn"
                                      type="button"
                                      onClick={this.addToastClose}
                                    >
                                      <CloseBtnSVG />
                                    </button>
                                  </div>
                                </div>
                              </Toast.Body>
                            </Toast>
                          ) : (
                            ""
                          )}
                          <div>
                            <label className="form-label">Attachment</label>
                            <div className="attachment-btn dropdown-field">
                              <input
                                type="file"
                                multiple
                                accept=".pdf,.PDF,.mp4,.MP4,.webm,.WEBM,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.doc,.DOC,.docx,.DOCX"
                                style={{ display: "none" }}
                                onChange={this.handleImageChange}
                                id="contained-attachment"
                              />
                              <label htmlFor="contained-attachment">
                                <Button
                                  variant="contained"
                                  color="default"
                                  component="span"
                                >
                                  <PaperClipSVG /> Add attachment
                                </Button>
                              </label>
                            </div>
                          </div>
                          {this.state.attachmentUrls.length > 0 ? (
                            <div className="mb-2">
                              <label className="form-label">Attachments</label>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="row">
                            <div className="col-md-12">
                              <div className="d-flex row">
                                {this.state.attachmentUrls &&
                                  this.state.attachmentUrls.map(
                                    (obj: any, index: number) => {
                                      return (
                                        <div className="img-card mr-16px mb-3">
                                          <span className="delete">
                                            <a
                                              onClick={() =>
                                                this.handleDeleteImage(index)
                                              }
                                            >
                                              <TrashSVG />
                                            </a>
                                          </span>
                                          <div
                                            className="img-preview"
                                            onClick={() =>
                                              this.handlePreviewOpen(
                                                obj.file_type,
                                                obj.url,
                                                index
                                              )
                                            }
                                          >
                                            <img
                                              className="img-thumb-perview"
                                              src={
                                                obj.file_type ===
                                                  "application/pdf"
                                                  ? pdfImage
                                                  : obj.file_type ===
                                                    "video/mp4" ||
                                                    obj.file_type ===
                                                    "video/webm"
                                                    ? videoImage
                                                    : obj.file_type ===
                                                      "image/png" ||
                                                      obj.file_type ===
                                                      "image/jpeg" ||
                                                      obj.file_type ===
                                                      "image/jpg"
                                                      ? obj.url
                                                      : docImage
                                              }
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                {/* <AttachmentPreview
                        displayPreview={this.state.AttachmentPreviewStatus}
                        previewUrl={this.state.previewUrl}
                        previewType={this.state.previewType}
                        handlePreviewClose={this.handlePreviewClose}
                      /> */}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="mb-2 col-md-6">
                              <label className="form-label">
                                Client<span className="text-project">*</span>
                              </label>
                              <Field
                                as="select"
                                className="form-select dropdown-field"
                                name="client"
                              >
                                <option value={""} disabled>
                                  Select Client
                                </option>
                                {this.props.clientList.map(
                                  (client: ICleaner) => (
                                    <option
                                      value={client.id.toString()}
                                      key={client.id}
                                    >{`${client.first_name} ${client.last_name}`}</option>
                                  )
                                )}
                              </Field>
                              <ErrorMessage
                                name="client"
                                component={"div"}
                                className="cus-alert"
                              />
                            </div>
                            <div className="mb-2 col-md-6">
                              <label className="form-label">
                                Branch<span className="text-project">*</span>
                              </label>
                              <Field
                                className="custom-select dropdown-field"
                                name="branch"
                                options={[
                                  { value: "all", label: "All" },
                                  ...this.props.branchList, // Spread the elements of the input array
                                ]}
                                value={this.state.selectedBranch}
                                component={CustomSelect}
                                placeholder="Select Branch"
                                isMulti={false}
                              />
                              <ErrorMessage
                                name="branch"
                                component={"div"}
                                className="cus-alert"
                              />
                            </div>
                          </div>
                          <div className="mb-2 col-md-6">
                            <label className="form-label">
                              Date<span className="text-project">*</span>
                            </label>
                            <div
                              className="startdate"
                              onClick={() => this.handleStartDateClick()}
                            >
                              <DatePicker
                                name="task_date"
                                value={this.state.task_date}
                                onChange={this.handleTaskDate}
                              />
                              <ErrorMessage
                                name="task_date"
                                component={"div"}
                                className="cus-alert"
                              />
                            </div>
                          </div>
                          <div className="mb-2 col-md-12">
                            <label className="form-label">
                              Time<span className="text-project">*</span>
                            </label>
                            <div>
                              <label>
                                <Field type="radio" name="any_time" value="1" />
                                <span className="check-left-space">
                                  Any Time
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="mt-2 mb-2 col-md-12">
                            <Field type="radio" name="any_time" value="0" />
                            <label className="check-time">
                              <span className="check-left-space d-flex">
                                <div className="mr-8px">Start</div>
                                <TimePicker
                                  name="start_time"
                                  value={this.state.start_time}
                                  onChange={this.handleStartTime}
                                />
                              </span>
                            </label>
                            <label
                              className="check-time"
                              style={{ marginLeft: "16px" }}
                            >
                              <span className="d-flex">
                                <div className="mr-8px">End</div>
                                <TimePicker
                                  name="end_time"
                                  value={this.state.end_time}
                                  onChange={this.handleEndTime}
                                />
                              </span>
                            </label>
                          </div>
                          <div className="mb-2 col-md-6">
                            <label className="form-label">
                              Task priority
                              <span className="text-project">*</span>
                            </label>
                            <Field
                              className="custom-select"
                              name="priority"
                              options={[
                                { value: TASK_PRIORITY.LOW, label: "Low" },
                                {
                                  value: TASK_PRIORITY.MEDIUM,
                                  label: "Medium",
                                },
                                { value: TASK_PRIORITY.HIGH, label: "High" },
                              ]}
                              component={CustomSelect}
                              isMulti={false}
                            />
                            <ErrorMessage
                              name="priority"
                              component={"div"}
                              className="cus-alert"
                            />
                          </div>
                          <div className="row mt-3 mb-3">
                            <label className="form-label">
                              Signature<span className="text-project">*</span>
                            </label>
                            <div>
                              {this.state.signatureImage !== "" && (
                                <img
                                  src={this.state.signatureImage}
                                  alt=""
                                  style={{
                                    width: "130px",
                                    height: "100px",
                                    border: "2px solid #E3DDDA",
                                  }}
                                />
                              )}
                            </div>
                            <div>
                              <button
                                type="button"
                                id="create-button"
                                className="btn btn-primary create-button"
                                style={{ width: "40%" }}
                                onClick={() => this.handleSignModal()}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="mr-16px">
                              <button
                                type="submit"
                                id="create-button"
                                className="btn btn-primary create-button"
                              >
                                Create{" "}
                                <span
                                  id="spinner-border"
                                  className="spinner-border spinner-border-sm d-none"
                                ></span>
                              </button>
                            </div>
                            <div>
                              <button
                                type="button"
                                className="btn cancel-button"
                                onClick={() =>
                                  this.handleCloseAdditionalTaskModal()
                                }
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                          <Modal
                            size="lg"
                            show={this.state.signatureModal}
                            onHide={() =>
                              this.setState({
                                signatureModal: false,
                              })
                            }
                            aria-labelledby="example-modal-sizes-title-lg"
                          >
                            <Modal.Header closeButton>
                              <Modal.Title id="example-modal-sizes-title-lg">
                                <h3 className="m-0">Add signature</h3>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="p-4">
                              <div className="signature-pad">
                                <canvas
                                  ref={this.canvasRef}
                                  style={{ width: "100% !important" }}
                                  onMouseDown={this.handleMouseDown}
                                  onMouseMove={this.handleMouseMove}
                                  onMouseUp={this.handleMouseUp}
                                  onTouchStart={this.handleTouchStart}
                                  onTouchEnd={this.handleTouchEnd}
                                  onTouchMove={this.handleTouchMove}
                                />
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.handleClear}
                              >
                                Clear
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                id="add-sign-image"
                                style={{ marginLeft: "10px" }}
                                onClick={this.handleConvertToImage}
                              >
                                Add
                              </button>
                            </Modal.Footer>
                          </Modal>
                        </Form>
                      )}
                    </Formik>
                  </Offcanvas.Body>
                </Offcanvas>

                <div className="col-6 col-lg-3">
                  <Button
                    className="res-mt-3 btn btn-primary login-button ml-2 flex-mr-16px mob-res-w-100 btn btn-primary"
                    variant="outlined"
                    onClick={() => this.handleOpenAdditionalTaskModal()}
                  >
                    Add Additional Task
                  </Button>

                  {/* </div>
              <div className="col"> */}
                  {/* <ApprovedExcel
                excelData={this.props.filteredAllApprovedTask}
                fileName={"Task list report"}
               /> */}
                </div>
                <div className="col-3">
                  <a
                    download
                    href={
                      Constants.baseUrl +
                      `approved-task-pdf?search=${this.state.searchTerm}&sort=&order=&offset=0&limit=10000&client=${this.props.filterTaskClientById}&branch=${this.props.filterTaskBranchById}&task_type=2&task_priority=${this.props.filterTaskPriorityById}&due_type=${this.props.filterTaskDueTypeByValue}&task_status=${this.props.filterTaskStatusById}&special_request_task_status=${this.props.filterSpecialRequestTaskStatusById}&status&start_date=${this.props.filterTaskDateTypeValue.from_date}&end_date=${this.props.filterTaskDateTypeValue.to_date}&user_id=${Constants.LOGIN_USER_ID}&user_type=${Constants.LOGIN_USER_TYPE}`
                    }
                    className="mb-0 float-end"
                  >
                    <Button
                      className="res-mt-3 btn btn-primary login-button w-170px ml-2 flex-mr-16px mob-res-w-100 btn btn-primary"
                      variant="outlined"
                    >
                      Export
                    </Button>
                  </a>
                </div>
              </div>
              {/* </div> */}
              <div>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table daily-task-table">
                            <thead>
                              <tr>
                                <th scope="col">NAME</th>
                                {/* <th scope="col">CLEANERS</th> */}
                                <th scope="col">TYPE</th>
                                <th scope="col">STATUS</th>
                                <th scope="col">MOVE TO TASK</th>
                                <th scope="col">CLIENT</th>
                                <th scope="col">BRANCH</th>
                                <th scope="col">DATE</th>
                                <th scope="col">TIME</th>
                                <th scope="col">PRIORITY</th>
                                <th scope="col">TASK DUPILCATE</th>
                                <th scope="col">SEND MAIL</th>
                                <th scope="col">Make PDF</th>
                                <th scope="col">ACTION</th>
                              </tr>
                            </thead>
                            <ApprovedTable
                              data={this.props.filteredAdditionalTaskData}
                            />
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                {this.props.total_Count > 10 ? (
                  <div className="col-md-12">
                    {this.state.pagehandle ? null : (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.props.total_Count / Constants.PER_PAGE}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        nextClassName={"next-page"}
                        activeClassName={"active"}
                      />
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  status: state.sidebar.status,
  addDailyTaskSuccess: state.dailytask.addDailyTaskSuccess,
  clientList: state.tasktemplate.clientList,
  branchList: state.tasktemplate.branchList,
  cleanerList: state.tasktemplate.cleanerList,
  addSpecialRequestTaskStatus: state.dailytask.addSpecialRequestTaskStatus,
  userLoginDetails: state.sidebar.userLoginDetails,
  filteredDailyTaskData: state.dailytask.filteredDailyTaskData,
  filteredAdditionalTaskData: state.dailytask.filteredAdditionalTaskData,
  filteredAllApprovedTask: state.dailytask.filteredAllApprovedTask,
  filterTaskClientById: state.dailytask.filterTaskClientById,
  filterTaskBranchById: state.dailytask.filterTaskBranchById,
  filterTaskPriorityById: state.dailytask.filterTaskPriorityById,
  filterTaskDueTypeByValue: state.dailytask.filterTaskDueTypeByValue,
  filterTaskStatusById: state.dailytask.filterTaskStatusById,
  filterSpecialRequestTaskStatusById:
    state.dailytask.filterSpecialRequestTaskStatusById,
  filterTaskDateTypeValue: state.dailytask.filterTaskDateTypeValue,
  total_count: state.dailytask.total_count,
  total_Count: state.dailytask.total_Count,
  filterTaskTypeById: state.dailytask.filterTaskTypeById,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateSidebar: bindActionCreators(updateSidebar, dispatch),
    listAllClient: bindActionCreators(listAllClient, dispatch),
    addDailyTask: bindActionCreators(addDailyTask, dispatch),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
    listAllCleaner: bindActionCreators(listAllCleaner, dispatch),
    getAllAdditionalTasks: bindActionCreators(getAllAdditionalTasks, dispatch),
    getAllApprovedTasks: bindActionCreators(getAllApprovedTasks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovedTask);
