import React from "react";
import { RootState } from '../../index';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { updateEditClientContractorDlgStatus } from "../../shared/Reducers";
import DeleteContractor from "./DeleteContractor";
import { IContractorCardProps, IContractorCardState } from "../../types/contractor.type";
import moment from "moment";
import { TableDeleteSVG, TableEditSVG, TableViewSVG } from "../../assets/images/ImagesV2";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Constants from "../../shared/Constants";
import { Navigate } from "react-router-dom";

class ContractorCard extends React.Component<IContractorCardProps, IContractorCardState> {
    constructor(props: IContractorCardProps) {
        super(props);
        this.state = {
            redirect: "",
            show: false,
            displayDelete: false,
            currentContractorId: "",
            editShow: false
        }
    }

    handleClose = () => {
        this.setState({ show: false })
    }

    handleShow = () => {
        this.setState({ show: true })
    }

    // Delete contractor based on ID
    handleDelete = (id: string) => {
        this.setState({ displayDelete: true, currentContractorId: id })
    }

    // delete popup close
    handleDeleteClose = (status: boolean) => {
        this.setState({ displayDelete: false, currentContractorId: "" })
        if (status) {
            this.props.handleDeleteContractor(this.state.currentContractorId)
        }
    }

    render(): React.ReactNode {
        const { id, contract_date, first_name, last_name, business_name, email, personal_contact_no } = this.props.contractors;
        return (
            <tr>
                <td>{first_name}</td>
                <td>{last_name}</td>
                <td>{business_name}</td>
                <td>{email}</td>
                <td>{personal_contact_no}</td>
                <td>{moment(new Date(contract_date?.toString())).format("DD/MM/YYYY")}</td>
                <td>
                    <div>
                        <a
                        href={Constants.baseUrl + `contractor/make-pdf/` + id}>
                        <button className="cont-export-pdf btn btn-primary btn-border h-40 res-w-100" style={{fontSize:"12px"}}>
                            Export PDF
                        </button>
                        </a>
                    </div>
                </td>
                <td>
                    <div className="btn-group">
                        <span className="mr-20px">
                            <a href="javascript:void(0)" onClick={() => this.props.handleViewContractor(id)}>
                                <OverlayTrigger placement="top"
                                    overlay={<Tooltip>{"View"}</Tooltip>}>
                                    <Button className="action-btn"><TableViewSVG /></Button>
                                </OverlayTrigger>
                            </a>
                        </span>
                        <span className="mr-20px">
                            <a
                                href={"/contract-contact-edit-form?"+ btoa("user_id:"+id)}
                                target="_blank"
                            >
                                <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>{"Edit"}</Tooltip>}
                                >
                                <Button className="action-btn">
                                    <TableEditSVG />
                                </Button>
                                </OverlayTrigger>
                            </a>
                        </span>
                        <span>
                            <a href="javascript:void(0)" onClick={() => this.handleDelete(id)}>
                                <OverlayTrigger placement="top"
                                    overlay={<Tooltip>{"Delete"}</Tooltip>}>
                                   <Button className="action-btn"><TableDeleteSVG /></Button>
                                </OverlayTrigger>
                            </a>
                        </span>
                        <DeleteContractor displayDelete={this.state.displayDelete} deletedName={first_name+" "+last_name} handleDeleteClose={this.handleDeleteClose} />
                    </div>
                </td>
            </tr>
        )
    }
}
const mapStateToProps = (state: RootState) => ({
    editContractorStatus: state.contractor.editContractorStatus
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateEditClientContractorDlgStatus: bindActionCreators(updateEditClientContractorDlgStatus, dispatch),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContractorCard);