import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ITaskTableProps } from "../../types/task.type";
import TaskCard from "./TaskCard";
import EditTask from "./EditTask";
import { Offcanvas } from "react-bootstrap";
import {
  deleteTask,
  getTaskById,
  getAllTasks,
  updateEditTaskDlgStatus,
} from "../../shared/Reducers";

class TaskTable extends React.Component<ITaskTableProps> {
  constructor(props: ITaskTableProps) {
    super(props);
  }

  handleDeleteTask = async (id: string) => {
    await this.props.deleteTask(id);
    const query = `search=&sort=&order=&offset=0&limit=10&client=all&branch=all`;
    this.props.getAllTasks(query);
  };

  handleEditBranch = async (id: string) => {
    try {
      this.props.getTaskById(id);
    } catch (error) {
      console.log(error);
    }
  };

  handleViewTask = async (id: string) => {
    try {
      this.props.getTaskById(id);
    } catch (error) {
      console.log(error);
    }
  };
  render(): React.ReactNode {
    return (
      <tbody>
        {this.props.data.map((task, index) => (
          <TaskCard
            task={task}
            key={index + task.title}
            handleDeleteTask={(id: any) => this.handleDeleteTask(id)}
            handleEditTask={(id: any) => this.handleEditBranch(id)}
            handleViewTask={(id: any) => this.handleViewTask(id)}
            deleteTaskStatus={false}
            viewData={this.props.data}
            id={this.props.id}
          />
        ))}
        <Offcanvas
          placement="end"
          show={this.props.editTaskStatus}
          onHide={() => this.props.updateEditTaskDlgStatus(false)}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <span className="offc-title">Edit Task</span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <EditTask id={this.props.id} data={this.props.selectedTaskData} />
          </Offcanvas.Body>
        </Offcanvas>
      </tbody>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  editTaskStatus: state.tasktemplate.editTaskStatus,
  selectedTaskData: state.tasktemplate.selectedTaskData,
  id: state.tasktemplate.id,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateEditTaskDlgStatus: bindActionCreators(
      updateEditTaskDlgStatus,
      dispatch
    ),
    getTaskById: bindActionCreators(getTaskById, dispatch),
    getAllTasks: bindActionCreators(getAllTasks, dispatch),
    deleteTask: bindActionCreators(deleteTask, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TaskTable);
