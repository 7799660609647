import * as Yup from "yup";

import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { IBranchSelect, ICleaner } from "../../shared/ActionTypes";
import { IEditTaskProps, IEditTaskState } from "../../types/task.type";
import { Modal, ProgressBar } from "react-bootstrap";
import { PaperClipSVG, TrashSVG } from "../../assets/images/ImagesV2";
import {
  deleteAttachementId,
  editTask,
  listAllBranch,
  listAllClient,
  updateEditTaskDlgStatus,
  updateTaskFetchStatus,
} from "../../shared/Reducers";

import { AttachmentDownloadSVG } from "../../assets/images/ImagesSVG";
import AttachmentPreview from "../../shared/Components/AttachmentPreview";
import Button from "@material-ui/core/Button";
import Constants from "../../shared/Constants";
import React from "react";
import { RootState } from "../../index";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { connect } from "react-redux";
import docImage from "../../assets/images/google-docs.png";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";

const reactSelectCusStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#fff",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    },
  }),
};
class EditTask extends React.Component<IEditTaskProps, IEditTaskState> {
  constructor(props: IEditTaskProps) {
    super(props);
    this.state = {
      deleteAttachementId: [],
      attachmentUrls: [],
      client_id: this.props.data?.client_id,
      editClient: {
        value: this.props.data?.client_id,
        label: this.props.data?.first_name + " " + this.props.data?.last_name,
      },
      editBranch: {
        value: this.props.data?.branch_id,
        label: this.props.data?.branch_name,
      },
      branch_id:
        this.props.data && this.props.data.branch_id
          ? this.props.data.branch_id
          : "",
      clientErrorShow: false,
      branchErrorShow: false,
    };
  }

  componentDidMount(): void {
    this.props.listAllClient();

    if (this.props.data) {
      this.setState({
        attachmentUrls: this.props.data.attachments.map((obj: any) => ({
          url: `${Constants.imgThumbUrl}${obj.file_name}`,
          name: obj.file_name,
          id: obj.id,
          type: "download",
          download_url: `${Constants.imgUrl}${obj.file_name}`,
          file_type: obj.type,
        })),
      });
      this.props.listAllBranch(this.props.data.client_id);
    }

    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.add("d-none");
      button.removeAttribute("disabled");
    }
  }

  handleImageChange = async (e: any) => {
    let files = [];
    let urls = [];
    files = [...e.target.files];

    let fileurls: any = [];
    await files.map(async (file: any) => {
      fileurls.push({
        url: URL.createObjectURL(file),
        file: file,
        id: "0",
        file_type: file.type,
      });
    });
    urls = [...fileurls];
    this.setState({ attachmentUrls: [...this.state.attachmentUrls, ...urls] });
  };

  handleDeleteImage = (index: number) => {
    const urls = this.state.attachmentUrls;

    confirmAlert({
      title: "Confirm delete",
      message: "Are you sure you want to delete this Attachment ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            // check exist file
            if (urls[index].id !== undefined && urls[index].id !== "0") {
              let deleteAttachementId = this.state.deleteAttachementId;
              deleteAttachementId.push(urls[index].id);
              this.setState({ deleteAttachementId: deleteAttachementId });

              urls.splice(index, 1);
              this.setState({ attachmentUrls: urls });
            } else {
              urls.splice(index, 1);
              this.setState({ attachmentUrls: urls });
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  handleEditTask = async (formValues: any) => {
    if (this.state.client_id === "" || null || undefined) {
      return false;
    } else if (this.state.branch_id === "" || null || undefined) {
      return false;
    }
    let data = new FormData();
    data.append("client", this.state.client_id);
    data.append("branch", this.state.branch_id);
    data.append("title", formValues.title);
    data.append("status", formValues.status);
    for (let index = 0; index < this.state.attachmentUrls.length; index++) {
      if (this.state.attachmentUrls[index].file !== undefined) {
        data.append("attachments", this.state.attachmentUrls[index].file);
      }
    }
    data.append("description", formValues.description);

    if (this.props.id) {
      var element = document.getElementById("spinner-border");
      if (element !== null) {
        element.classList.remove("d-none");
      }

      await this.state.deleteAttachementId.map(async (id: any) => {
        this.props.deleteAttachementId(id);
      });
      this.props.editTask(data, this.props.id);
    }
  };

  handleEditeClient = async (id: number) => {
    this.setState({
      client_id: id,
      editBranch: {
        value: "",
        label: "Select the Branch",
      },
      branch_id: "",
    });
    await this.props.listAllBranch(id);
    this.setState({ clientErrorShow: false });
  };

  handleEditBranch = (e: any) => {
    this.setState({ branch_id: e.value, editBranch: e });
    this.setState({ branchErrorShow: false });
  };

  handleChange = (e: any) => {
    if (e.target.name === "client") {
      this.props.listAllBranch(e.target.value);
    }
  };

  downloadFile = (url: string, name = "image.png") => {
    this.downloadResource(url, name);
  };

  // https://stackoverflow.com/questions/49474775/chrome-65-blocks-cross-origin-a-download-client-side-workaround-to-force-down
  forceDownload = (blob: any, filename: String) => {
    const a: any = document.createElement("a");
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  // Current blob size limit is around 500MB for browsers
  downloadResource = (url: any, filename: any) => {
    if (url) {
      if (!filename) filename = url.split("\\").pop().split("/").pop();
      fetch(url, {
        headers: new Headers({
          Origin: window.location.origin,
        }),
        mode: "cors",
      })
        .then((response) => response.blob())
        .then((blob) => {
          let blobUrl = window.URL.createObjectURL(blob);
          this.forceDownload(blobUrl, filename);
        })
        .catch((e) => console.error(e));
    }
  };

  validationSchema = () => {
    if (this.state.client_id === "" || null || undefined) {
      this.setState({ clientErrorShow: true });
    } else {
      this.setState({ clientErrorShow: false });
    }

    if (this.state.branch_id === "" || null || undefined) {
      this.setState({ branchErrorShow: true });
    } else {
      this.setState({ branchErrorShow: false });
    }
    // const clientId = this.props.clientList.map((client: ICleaner) => client.id);
    // const branchId = this.props.branchList.map(
    //   (branch: IBranchSelect) => branch.value
    // );
    return Yup.object().shape({
      title: Yup.string().required("Enter Task Name"),
      description: Yup.string().required("Enter Task Description"),
      // client: Yup.string()
      //   .oneOf([...clientId], "Invalid Client")
      //   .required()
      //   .nullable(),
      // branch: Yup.string()
      //   .oneOf([...branchId], "Invalid Branch")
      //   .required()
      //   .nullable(),
      status: Yup.string(),
      attachments: Yup.mixed(),
    });
  };

  render(): React.ReactNode {
    return (
      <div>
        <Formik
          initialValues={{
            title: this.props.data ? this.props.data.title : "",
            description: this.props.data ? this.props.data.description : "",
            client: this.props.data ? this.props.data.client_id : "",
            branch: this.props.data ? this.props.data.branch_id : "",
            status: this.props.data ? this.props.data.status : "",
            attachments: this.props.data ? this.props.data.attachments : "",
          }}
          enableReinitialize={true}
          onSubmit={this.handleEditTask}
          validationSchema={this.validationSchema}
        >
          <Form onChange={this.handleChange}>
            <div className="mb-2">
              <label className="form-label">
                Task Name<span className="text-project">*</span>
              </label>
              <Field
                name="title"
                type={"text"}
                className="form-control text-field"
              />
              <ErrorMessage
                name="title"
                component={"div"}
                className="cus-alert"
              />
            </div>
            <div className="mb-2">
              <label className="form-label">
                Task Description<span className="text-project">*</span>
              </label>
              <Field
                as="textarea"
                name="description"
                className="form-control text-area"
              />
              <ErrorMessage
                name="description"
                component={"div"}
                className="cus-alert"
              />
            </div>
            <div className="row">
              <div className="mb-2 col-md-6">
                <label className="form-label">Client</label>
                <Select
                  styles={reactSelectCusStyles}
                  placeholder="Select Client"
                  isMulti={false}
                  value={this.state.editClient}
                  options={this.props.clientList.map((client: any) => ({
                    value: client.id,
                    label: client.first_name + " " + client.last_name,
                  }))}
                  onChange={(e: any) => {
                    this.handleEditeClient(e.value);
                    this.setState({ editClient: e });
                  }}
                />
                {this.state.clientErrorShow === true && (
                  <p className="text-danger"> Please Select The Client</p>
                )}
              </div>
              <div className="mb-2 col-md-6">
                <label className="form-label">Branch</label>
                <Select
                  styles={reactSelectCusStyles}
                  placeholder="Select Branch"
                  isMulti={false}
                  value={this.state.editBranch}
                  options={this.props.branchList.map((branch: any) => ({
                    value: branch.value,
                    label: branch.label,
                  }))}
                  onChange={(e: any) => this.handleEditBranch(e)}
                />
                {this.state.branchErrorShow === true && (
                  <p className="text-danger"> Please Select the Branch</p>
                )}
              </div>
              <div className="col-md-6 mb-2">
                <label className="form-label">Is Active</label>
                <Field
                  as="select"
                  className="form-select dropdown-field"
                  name="status"
                >
                  <option value={1}>Enable</option>
                  <option value={0}>Disable</option>
                </Field>
                <ErrorMessage
                  name="status"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              <div className="col-md-6 mb-2">
                <label className="form-label">Attachment</label>
                <div className="attachment-btn dropdown-field">
                  <input
                    type="file"
                    multiple
                    accept=".pdf,.PDF,.mp4,.MP4,.webm,.WEBM,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.doc,.DOC,.docx.DOCX"
                    style={{ display: "none" }}
                    onChange={this.handleImageChange}
                    id="contained-button-file"
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="default"
                      component="span"
                    >
                      <PaperClipSVG /> Add attachment
                    </Button>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              {this.state.attachmentUrls.length > 0 ? (
                <div className="col-md-12 mb-2">
                  <label className="form-label">Attachments</label>
                </div>
              ) : (
                <></>
              )}
              <div className="row">
                <div className="d-flex row">
                  {this.state.attachmentUrls &&
                    this.state.attachmentUrls.map((obj: any, index: number) => (
                      <div className="img-card mr-16px mb-3">
                        <span className="delete">
                          <a onClick={() => this.handleDeleteImage(index)}>
                            <TrashSVG />
                          </a>
                        </span>
                        {obj.type === "download" && (
                          <span className="download">
                            <a
                              href="javascript:void(0)"
                              onClick={() =>
                                this.downloadFile(
                                  obj.download_url,
                                  obj.file_name
                                )
                              }
                            >
                              <AttachmentDownloadSVG />
                            </a>
                          </span>
                        )}

                        <div className="img-preview">
                          <img
                            className="img-thumb-perview"
                            src={
                              obj.file_type === "application/pdf"
                                ? pdfImage
                                : obj.file_type === "video/mp4" ||
                                  obj.file_type === "video/webm"
                                ? videoImage
                                : obj.file_type === "image/png" ||
                                  obj.file_type === "image/jpeg" ||
                                  obj.file_type === "image/jpg"
                                ? obj.url
                                : docImage
                            }
                            alt="attach"
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 cus-progress">
                  <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.props.processTrigger}
                  >
                    <Modal.Body className="p-0">
                      <ProgressBar
                        now={this.props.progressPercentage}
                        label={
                          this.props.progressPercentage === 100
                            ? `Processing your request...`
                            : `Task Updating....${this.props.progressPercentage}%`
                        }
                      />
                    </Modal.Body>
                  </Modal>
                  <div className="mt-3 mb-3 d-flex">
                    <div className="mr-16px">
                      <button
                        type="submit"
                        id="create-button"
                        className="btn btn-primary create-button"
                      >
                        Save
                        <span
                          id="spinner-border"
                          className="spinner-border spinner-border-sm d-none"
                        ></span>
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn cancel-button"
                        onClick={() =>
                          this.props.updateEditTaskDlgStatus(false)
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>

        <iframe
          style={{ display: "none" }}
          name="hiddenIframe"
          id="hiddenIframe"
        />
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  clientList: state.tasktemplate.clientList,
  branchList: state.tasktemplate.branchList,
  progressPercentage: state.tasktemplate.progressPercentage,
  processTrigger: state.tasktemplate.processTrigger,
  editTaskSuccess: state.tasktemplate.editTaskSuccess,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    listAllClient: bindActionCreators(listAllClient, dispatch),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
    deleteAttachementId: bindActionCreators(deleteAttachementId, dispatch),
    editTask: bindActionCreators(editTask, dispatch),
    updateEditTaskDlgStatus: bindActionCreators(
      updateEditTaskDlgStatus,
      dispatch
    ),
    updateTaskFetchStatus: bindActionCreators(updateTaskFetchStatus, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditTask);
