import {
  API_GET_REFRESH_TOKEN,
  API_GET_REFRESH_TOKEN_FAIL,
  API_GET_REFRESH_TOKEN_SUCCESS,
  API_LOGIN,
  API_LOGIN_FAIL,
  API_LOGIN_SUCCESS,
  AppDispatchType,
  AppState,
  CONTRACTOR_CONTACT_FORM,
  CONTRACTOR_CONTACT_FORM_FAIL,
  CONTRACTOR_CONTACT_FORM_SUCCESS,
  UPDATE_CONTRACTOR_CONTACT_FORM,
  UPDATE_CONTRACTOR_CONTACT_FORM_SUCCESS,
  UPDATE_CONTRACTOR_CONTACT_FORM_FAIL,
  UPDATE_USER_TYPES,
  UPDATE_USER_TYPES_SUCCESS,
  UPDATE_USER_TYPES_FAIL,
  ILoginPayload,
  IRefreshToken,
  UPDATE_SUB_ADMIN,
  API_ADD_COMMENT,
  API_ADD_COMMENT_SUCCESS,
  API_ADD_COMMENT_FAIL,
  API_SINGOUT_CLEANER,
  API_SINGOUT_CLEANER_FAIL,
  API_SINGOUT_CLEANER_SUCCESS,
  API_TASK_STATUS_LIST,
  API_TASK_STATUS_LIST_SUCCESS,
  API_TASK_STATUS_LIST_FAIL,
  API_USER_TYPES_LIST,
  API_USER_TYPES_LIST_SUCCESS,
  API_USER_TYPES_LIST_FAIL,
  API_DELETE_COMMENT,
  API_DELETE_COMMENT_FAIL,
  API_DELETE_COMMENT_SUCCESS,
  API_DELETE_USER_TYPES,
  API_DELETE_USER_TYPES_SUCCESS,
  API_DELETE_USER_TYPES_FAIL,
  API_CLEANER_ADD_COMMENT_PROGRESS,
} from "../ActionTypes/index";
import { AnyAction, Dispatch } from "redux";

const initialState: AppState = {
  user: null,
  loginError: "",
  loginCertificateUrl: "",
  token: "",
  loading: false,
  subAdmin: null,
  refreshToken: null,
  contractContactFormSuccessStatus: null,
  contractFormMessage: "",
  update_contractContactFormSuccessStatus: null,
  addCommentStatus: false,
  taskStatusList: null,
  userTypesList: [],
  deleteCommentStatus: false,
  deleteCommentmessage: "",
  commentFetchStatus: false,
  signoutCleaner: "",
  singOut_id: null,
  progressPercentage: 0,
  update_UserTypes: false,
  delete_UserTypes: false,
  contractformResponse: null,

  // userTypesList: null,
};

export const Reducer = (
  state: AppState = initialState,
  action: AnyAction
): AppState => {
  switch (action.type) {
    case API_LOGIN:
      return {
        ...state,
        loading: true,
        loginError: "",
        loginCertificateUrl: "",
      };
    case API_LOGIN_SUCCESS:
      if (
        "data" in action.payload &&
        "refresh_token" in action.payload.data &&
        action.payload.data.refresh_token
      ) {
        localStorage.setItem("user", JSON.stringify(action.payload.data));
      }
      return {
        ...state,
        user: action.payload.data,
        loading: false,
        token: action.payload.data.token,
        loginError: "",
        loginCertificateUrl: "",
      };
    case API_LOGIN_FAIL:
      let error: string = "Login failed";
      let certificate_url: string = "";
      if (
        action &&
        "error" in action &&
        (action as any).error &&
        "response" in (action as any).error &&
        "data" in (action as any).error.response &&
        "message" in (action as any).error.response.data
      ) {
        error = (action as any).error.response.data.message;
        certificate_url = (action as any).error.response.data.url;
      }
      return {
        ...state,
        loginError: error,
        loginCertificateUrl:
          certificate_url === undefined ? "" : certificate_url,
        loading: false,
      };
    case API_SINGOUT_CLEANER:
      return { ...state, signoutCleaner: "" };
    case API_SINGOUT_CLEANER_SUCCESS:
      return { ...state, signoutCleaner: true, singOut_id: action.payload };
    case API_SINGOUT_CLEANER_FAIL:
      return { ...state, signoutCleaner: false };
    case CONTRACTOR_CONTACT_FORM:
      return { ...state, contractContactFormSuccessStatus: false };
    case CONTRACTOR_CONTACT_FORM_SUCCESS:
      return {
        ...state,
        contractContactFormSuccessStatus: true,
        contractformResponse: action.payload,
      };
    case CONTRACTOR_CONTACT_FORM_FAIL:
      console.log("Reducer_contract_fail", action.error.response.data);
      return {
        ...state,
        contractContactFormSuccessStatus: false,
        contractformResponse: action.error.response.data,
      };
    case UPDATE_CONTRACTOR_CONTACT_FORM:
      return { ...state, update_contractContactFormSuccessStatus: false };
    case UPDATE_CONTRACTOR_CONTACT_FORM_SUCCESS:
      return { ...state, update_contractContactFormSuccessStatus: true };
    case UPDATE_CONTRACTOR_CONTACT_FORM_FAIL:
      return { ...state, update_contractContactFormSuccessStatus: false };
    case UPDATE_USER_TYPES:
      return { ...state, update_UserTypes: false };
    case UPDATE_USER_TYPES_SUCCESS:
      return { ...state, update_UserTypes: true };
    case UPDATE_USER_TYPES_FAIL:
      return { ...state, update_UserTypes: false };
    case API_GET_REFRESH_TOKEN:
      return { ...state };
    case API_GET_REFRESH_TOKEN_SUCCESS:
      if (
        "data" in action.payload &&
        "refresh_token" in action.payload.data &&
        action.payload.data.refresh_token
      ) {
        localStorage.setItem(
          "refresh_token",
          JSON.stringify(action.payload.data)
        );
      }
      return {
        ...state,
        token: action.payload.data.token,
        refreshToken: action.payload.data,
      };
    case API_GET_REFRESH_TOKEN_FAIL:
      return { ...state };
    case API_DELETE_USER_TYPES:
      return { ...state, delete_UserTypes: false };
    case API_DELETE_USER_TYPES_SUCCESS:
      return { ...state, delete_UserTypes: true };
    case API_DELETE_USER_TYPES_FAIL:
      return { ...state, delete_UserTypes: false };
    case UPDATE_SUB_ADMIN:
      return { ...state, subAdmin: action.payload };
    case API_ADD_COMMENT:
      return { ...state, addCommentStatus: false };
    case API_ADD_COMMENT_SUCCESS:
      return { ...state, addCommentStatus: true };
    case API_CLEANER_ADD_COMMENT_PROGRESS:
      return { ...state, progressPercentage: action.payload };
    case API_ADD_COMMENT_FAIL:
      return { ...state };
    case API_TASK_STATUS_LIST:
      return { ...state };
    case API_TASK_STATUS_LIST_SUCCESS:
      let taskStatusList = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task_status_list" in data && data.task_status_list) {
          taskStatusList = data.task_status_list;
        }
      }
      return {
        ...state,
        taskStatusList: taskStatusList,
      };
    case API_TASK_STATUS_LIST_FAIL:
      return { ...state };
    case API_USER_TYPES_LIST:
      return { ...state };
    case API_USER_TYPES_LIST_SUCCESS:
      let userTypesList = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "user_types_list" in data && data.user_types_list) {
          userTypesList = data.user_types_list;
        }
      }

      return {
        ...state,
        userTypesList: userTypesList,
      };
    case API_USER_TYPES_LIST_FAIL:
    case API_DELETE_COMMENT:
      return { ...state };
    case API_DELETE_COMMENT_SUCCESS:
      let message = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          message = data.message;
        }
      }
      return {
        ...state,
        deleteCommentStatus: false,
        deleteCommentmessage: message,
        commentFetchStatus: true,
      };
    case API_DELETE_COMMENT_FAIL:
      return { ...state };
    default:
      return state;
  }
};

export const login = (data: ILoginPayload) => async (dispatch: Dispatch) => {
  console.log("LogIn_Reducer", data);
  return dispatch({
    type: API_LOGIN,
    payload: {
      request: {
        method: "post",
        url: "auth/login",
        data,
      },
    },
  });
};

export const refreshToken = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: API_LOGIN,
    payload: {
      request: {
        method: "get",
        url: "auth/refresh",
      },
    },
  });
};

export const updateSubAdmin = (user: ILoginPayload) => {
  return async (dispatch: AppDispatchType) => {
    dispatch({
      type: UPDATE_SUB_ADMIN,
      payload: user,
    });
  };
};

export const ContractcontactForm =
  (data: any) => async (dispatch: Dispatch) => {
    return dispatch({
      type: CONTRACTOR_CONTACT_FORM,
      payload: {
        request: {
          method: "post",
          url: "contract-contact-form",
          data,
        },
      },
    });
  };

export const updateContractcontactForm =
  (id: any, data: any) => async (dispatch: Dispatch) => {
    return dispatch({
      type: UPDATE_CONTRACTOR_CONTACT_FORM,
      payload: {
        request: {
          method: "put",
          url: `contract-contact-form/${id}`,
          data,
        },
      },
    });
  };

export function addComments(payload: any) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_ADD_COMMENT,
      payload: {
        request: {
          method: "post",
          url: `comments`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_CLEANER_ADD_COMMENT_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

// // Add Comment
// export function addComments(payload: FormData) {
//   return async (dispatch: Dispatch) => {
//     dispatch({
//       type: API_ADD_COMMENT,
//       payload: {
//         request: {
//           method: "post",
//           url: `comments`,
//           data: payload,
//         },
//       },
//     });
//   };
// }

// get task status list
export function getTaskStatusList() {
  return async (dispatch: Dispatch) => {
    return dispatch({
      type: API_TASK_STATUS_LIST,
      payload: {
        request: {
          method: "get",
          url: "users/task-status/drop-down-list",
        },
      },
    });
  };
}

//update user types
export const updateUserTypes =
  (id: any, data: any) => async (dispatch: Dispatch) => {
    return dispatch({
      type: UPDATE_USER_TYPES,
      payload: {
        request: {
          method: "put",
          url: `user_types/${id}`,
          data,
        },
      },
    });
  };

//Delete user types
export function deleteUserType(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_DELETE_USER_TYPES,
      payload: {
        request: {
          method: "delete",
          url: `user_types/${id}`,
        },
      },
    });
  };
}

// get user types list
export function getUserTypesList() {
  return async (dispatch: Dispatch) => {
    return dispatch({
      type: API_USER_TYPES_LIST,
      payload: {
        request: {
          method: "get",
          url: "users/user-types/drop-down-list",
        },
      },
    });
  };
}

export function getCleanerSignout(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_SINGOUT_CLEANER,
      payload: {
        request: {
          method: "get",
          url: `users/cleaner_sign_out/${id}`,
        },
      },
    });
  };
}

export function deleteComments(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_DELETE_COMMENT,
      payload: {
        request: {
          method: "delete",
          url: `comments/${id}`,
        },
      },
    });
  };
}
