import "../src/assets/styles/Styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/inter";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-fancybox/lib/fancybox.css";

import {
  AllReportReducer,
  AttendanceReducer,
  AuditReducer,
  BranchReducer,
  CleanerCertificateReducer,
  CleanerHomeReducer,
  CleanerInOutReducer,
  CertificateReducer,
  ComplaintReducer,
  ContractReducer,
  ContractorReducer,
  DailyTaskReducer,
  DashboardReducer,
  EmailTemplateReducer,
  InvoiceReducer,
  NotificationReducer,
  QuotedTaskReducer,
  Reducer,
  ReportReducer,
  ScheduleReducer,
  ShiftReducer,
  SidebarReducer,
  SigninReducer,
  TaskTemplateReducer,
  UsermanagementReducer,
} from "./shared/Reducers/index";
import axios, { AxiosRequestConfig } from "axios";

import App from "./App";
import AuthService from "./services/auth.service";
import { BrowserRouter } from "react-router-dom";
import Constants from "./shared/Constants";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom/client";
import axiosMiddleware from "redux-axios-middleware";
import { configureStore } from "@reduxjs/toolkit";
import { jwtInterceptor } from "./_helpers/http-interceptor";
import reportWebVitals from "./reportWebVitals";
import thunk from "redux-thunk";

// axios instance
const client = axios.create({
  baseURL: Constants.baseUrl,
  responseType: "json",
});

client.interceptors.request.use((config:any) => {
  const userStr = localStorage.getItem("user");
  // console.log(config);
  let user = null;
  if (userStr) {
    user = JSON.parse(userStr);
  }
  const isLoggedIn = user?.token;
  if (isLoggedIn && config.url !== `${Constants.baseUrl}auth/refresh`) {
    config.headers = { Authorization: `Bearer ${user.token}` };
  }
  return config;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.request?.responseURL !== `${Constants.baseUrl}auth/refresh`
    ) {
      const userStr = localStorage.getItem("user");
      console.log(userStr);

      let user: any = null;
      if (userStr) {
        user = JSON.parse(userStr);
      }
      client.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${user.refresh_token}`;
      return client
        .get(`${Constants.baseUrl}auth/refresh`, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.refresh_token}`,
          },
        })
        .then((response) => {
          const config = error.config as AxiosRequestConfig;
          user.token = response.data.token;
          user.refresh_token = response.data.refresh_token;
          localStorage.setItem("user", JSON.stringify(user));
          client.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${user.token}`;
          return client.request(config);
        })
        .catch((err) => {
          console.log(err);
          AuthService.logout();
          window.location.href = "/";
        });
    }
    return Promise.reject(error);
  }
);

const store = configureStore({
  reducer: {
    app: Reducer,
    sidebar: SidebarReducer,
    signin: SigninReducer,
    usermanagement: UsermanagementReducer,
    tasktemplate: TaskTemplateReducer,
    contract: ContractReducer,
    quotedTask: QuotedTaskReducer,
    contractor: ContractorReducer,
    complaint: ComplaintReducer,
    shift: ShiftReducer,
    audit: AuditReducer,
    attendance: AttendanceReducer,
    branch: BranchReducer,
    schedule: ScheduleReducer,
    dailytask: DailyTaskReducer,
    dashboard: DashboardReducer,
    report: ReportReducer,
    email: EmailTemplateReducer,
    activity: NotificationReducer,
    cleanerinout: CleanerInOutReducer,
    cleanerhome: CleanerHomeReducer,
    cleanercertificate: CleanerCertificateReducer,
    allreport: AllReportReducer,
    invoice: InvoiceReducer,
    certificate: CertificateReducer
  },
  middleware: [thunk, axiosMiddleware(client)],
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
