import React, { Component } from "react";
import { ApprovedCardProps, ApprovedCardState } from "../../types/approvedtask";
import Constants, { TASK_PRIORITY } from "../../shared/Constants";
import moment from "moment";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  TableDeleteSVG,
  TableEditSVG,
  TableReassignSVG,
  TableViewSVG,
} from "../../assets/images/ImagesV2";
import { RootState } from "../../index";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import DeleteApprovedTask from "./DeleteApprovedTask";
import ViewApprovedTask from "./ViewApprovedTask";
import { getAdditionalTaskById, getAllAdditionalTasks, getAllApprovedTasks, moveToTask } from "../../shared/Reducers";

class ApprovedCard extends React.Component<
  ApprovedCardProps,
  ApprovedCardState
> {
  constructor(props: ApprovedCardProps) {
    super(props);
    this.state = {
      mailModel: false,
      displayDelete: false,
      currentTaskId: "",
      displayView: false,
    };
  }

  handleMailOpen = () => {
    this.setState({ mailModel: true });
  };

  handleDelete = (id: string) => {
    this.setState({ displayDelete: true, currentTaskId: id });
    console.log(id);
  };

  handleDeleteClose = async (status: boolean) => {
    console.log("click", status);

    this.setState({ displayDelete: false, currentTaskId: "" });
    if (status) {
      await this.props.handleDeleteDailyTask(this.state.currentTaskId);
    }
  };

  handleView = (id: string) => {
    this.setState({ displayView: true });
    this.props.getAdditionalTaskById(id);
    console.log("View id", id);
  };

  handleToMoveTask = async (id: string) => {
    console.log("handleToMoveTask", id);
    let data = { recommended_task_status: 1, additional_task_status: 1 };
    await this.props.moveToTask(data, id);
    await this.getTasks(0,10);
  };

  handleViewClose = () => {
    this.setState({ displayView: false });
  };

  getTasks = async (offset: number, limit: number) => {
    try {
      // list for table
      let query = `search=&sort=&order=&offset=${offset}&limit=${limit}&client=all&branch=all&task_type=2&task_priority=&due_type=&task_status=&special_request_task_status=&status&start_date=&end_date=&additional_task_status=all`;

      // list for excel report
      // let AllListquery = `search=&sort=&order=&offset&limit=10000&client=&branch=&task_type=2&task_priority=&due_type=&task_status=&special_request_task_status=&status&start_date=&end_date=`;
      await this.props.getAllAdditionalTasks(query);
      // await this.props.getAllApprovedTasks(AllListquery);
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      title,
      first_name,
      special_request_task_status,
      task_status,
      cleaners,
      task_type,
      last_name,
      branch_name,
      start_time,
      end_time,
      any_time,
      id,
      task_date,
      is_active,
      task_status_name,
      task_feedback_name,
      priority,
      status,
      additional_task_status,
    } = this.props.task;
    return (
      <tr>
        <td>{title}</td>
        {/* <td>
          {cleaners.length > 0 ? (
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip>
                  {cleaners.map((element: any, index: any) => (
                    <p key={index} className="text-white mb-0 fs-12px">
                      {element.first_name + " " + element.last_name}
                    </p>
                  ))}
                </Tooltip>
              }
            >
              <Button
                variant="light"
                className="btn btn-secondary btn-cleaner-count fs-12px"
              >
                {cleaners.length}
              </Button>
            </OverlayTrigger>
          ) : (
            <></>
          )}
        </td> */}
        <td>{task_type === 2 ? "Additional Task" : ""}</td>
        <td>
          <p
            className={
              additional_task_status === 0
                ? "commented-option view-task-comment-badge"
                : "created-badge view-task-comment-badge"
            }
          >
            {additional_task_status === 0 ? "Created" : "Moved To Task"}
          </p>
        </td>
        <td>
          <a
            className="pointer"
            href="javascript:void(0)"
            onClick={() => this.handleToMoveTask(id)}
          >
            {additional_task_status === 0 ?
              <button
                className="created-badge view-task-comment-badge"
              >
                <span className="move-to-task-text">Move To Task</span>
              </button> :
              <button className='move-to-recommende-task-btn' disabled>
                <span className="move-to-recommended-task-text">Moved</span>
              </button>
            }
          </a>
        </td>
        <td>{`${first_name} ${last_name}`}</td>
        <td>{branch_name}</td>
        <td>{moment(task_date).format("DD/MM/YYYY")}</td>
        <td>
          {any_time === 1
            ? `Any Time`
            : moment(start_time, "HH:mm:ss").format("hh:mm A") +
            "-" +
            moment(end_time, "HH:mm:ss").format("hh:mm A")}
        </td>
        <td>
          {" "}
          <p
            className={
              priority === TASK_PRIORITY.LOW
                ? "commented-option view-task-comment-badge"
                : priority === TASK_PRIORITY.MEDIUM
                  ? "inprogress-badge view-task-comment-badge"
                  : priority === TASK_PRIORITY.HIGH
                    ? "created-badge view-task-comment-badge"
                    : ""
            }
          >
            {priority === TASK_PRIORITY.LOW
              ? "Low"
              : priority === TASK_PRIORITY.MEDIUM
                ? "Medium"
                : priority === TASK_PRIORITY.HIGH
                  ? "High"
                  : ""}
          </p>
        </td>
        <td
          // className={
          //  this.props.userLoginDetails?.permission?.task_copy === 1 
          //     ? ""
          //     : "d-none"
          // }
        >
          <span>
            <a
              href="javascript:void(0)"
              onClick={() => this.props.handleCopyDailyTask(id)}
            >
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{"Task duplicate"}</Tooltip>}
              >
                <Button className="btn btn-secondary btn-cleaner-count fs-12px">
                  Copy
                </Button>
              </OverlayTrigger>
            </a>
          </span>
        </td>
        <td>
          <span>
            <a href="javascript:void(0)">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{"Send mail"}</Tooltip>}
              >
                {/* <TableMailSVG /> */}
                <Button
                  className="btn btn-secondary btn-cleaner-count fs-12px"
                  onClick={() => this.props.handleSendQAEmailDailyTask(id)}
                >
                  Send Email
                </Button>
              </OverlayTrigger>
            </a>
          </span>
        </td>
        <td>
          <div>
            <a
              href={Constants.baseUrl + `approved-task-pdf/` + id}>
              <button className="cont-export-pdf btn btn-primary btn-border h-40 res-w-100" style={{ fontSize: "12px" }}>
                Export PDF
              </button>
            </a>
          </div>
        </td>
        <td>
          <div className="btn-group">
            <span className="mr-20px">
              <a
                className="pointer"
                href="javascript:void(0)"
                onClick={() => this.handleView(id)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"View"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableViewSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
            <span className="mr-20px">
              <a
                href="javascript:void(0)"
                onClick={() => this.props.handleEditDailyTask(id)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"Edit"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableEditSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
            <span className="mr-20px" title="Delete">
              <a
                href="javascript:void(0)"
                onClick={() => this.handleDelete(id)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{"Delete"}</Tooltip>}
                >
                  <Button className="action-btn">
                    <TableDeleteSVG />
                  </Button>
                </OverlayTrigger>
              </a>
            </span>
            {this.state.displayView &&
              this.props.selectedAdditionalTaskData && (
                <ViewApprovedTask
                  displayView={this.state.displayView}
                  handleViewClose={this.handleViewClose}
                />
              )}
            <DeleteApprovedTask
              deletedName={title}
              displayDelete={this.state.displayDelete}
              handleDeleteClose={this.handleDeleteClose}
            />
          </div>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  makeScheduleDailyTaskStatus: state.dailytask.makeScheduleDailyTaskStatus,
  selectedAdditionalTaskData: state.dailytask.selectedAdditionalTaskData,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getAdditionalTaskById: bindActionCreators(getAdditionalTaskById, dispatch),
    getAllAdditionalTasks: bindActionCreators(getAllAdditionalTasks, dispatch),
    getAllApprovedTasks: bindActionCreators(getAllApprovedTasks, dispatch),
    moveToTask: bindActionCreators(moveToTask, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovedCard);
