import { Dispatch, AnyAction } from "redux";
import {
  GET_CLEANER_DETAILS,
  GET_CLEANER_DETAILS_SUCCESS,
  GET_CLEANER_DETAILS_FAIL,
  CLEANER_CERTIFICATE_COMPLETION,
  CLEANER_CERTIFICATE_COMPLETION_SUCCESS,
  CLEANER_CERTIFICATE_COMPLETION_FAIL,
  CleanerCertificateState
} from "../ActionTypes";

const initialState: CleanerCertificateState = {
  getCleanerDetails : [],
  certificateCompleteStatus : false,
};

export const CleanerCertificateReducer = (
  state: CleanerCertificateState = initialState,
  action: AnyAction
): CleanerCertificateState => {
  switch (action.type) {
    case GET_CLEANER_DETAILS:
      return { ...state };
    case GET_CLEANER_DETAILS_SUCCESS:
      let getCleanerDetails:any |null = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "cleaner_details" in data && data.cleaner_details) {
          getCleanerDetails = data.cleaner_details;
        }else{
          getCleanerDetails = null;
        }
      }
      return {
        ...state,
        getCleanerDetails: getCleanerDetails,
      };
    case GET_CLEANER_DETAILS_FAIL:
      return { ...state, getCleanerDetails: null };
    case CLEANER_CERTIFICATE_COMPLETION:
      return { ...state, certificateCompleteStatus : false };
    case CLEANER_CERTIFICATE_COMPLETION_SUCCESS:
      return {
        ...state,
        certificateCompleteStatus: true,
      };
    case CLEANER_CERTIFICATE_COMPLETION_FAIL:
      return { ...state, certificateCompleteStatus: false };
    default:
      return state;
  }
};


export function getCleanerDetails(payload:any){
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_CLEANER_DETAILS,
      payload: {
        request: {
          method: "post",
          url: `get-cleaner-details`,
          data: payload,
        },
      },
    });
  };
}

export function certificateCompletion(payload:any){
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CLEANER_CERTIFICATE_COMPLETION,
      payload: {
        request: {
          method: "post",
          url: `certificates`,
          data: payload,
        },
      },
    });
  };
}