import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AttachmentDownloadSVG } from "../../assets/images/ImagesSVG";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";
import docImage from "../../assets/images/google-docs.png";
import Constants from "../../shared/Constants";
import { DisableSVG, EnableSVG } from "../../assets/images/ImagesV2";
import AttachmentPreview from "../../shared/Components/AttachmentPreview";
import moment from "moment";

class ViewContractor extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      attachments: "",
      attachmentUrls: [],
      AttachmentPreviewStatus: false,
      previewUrl: "",
      previewType: "",
    };
  }

  componentDidMount(): void {

    if (this.props.data) {
      this.setState({
        attachmentUrls: this.props.data.attachments.map((obj: any) => ({
          url: obj.s3_url_thumb,
          name: obj.file_name,
          id: obj.id,
          type: "download",
          download_url: obj.s3_url,
          file_type: obj.type,
          attachment_type: obj.contractor_attachment_type,
        })),
      });
    }
  }

  downloadFile = (url: string, name = "image.png") => {
    this.downloadResource(url, name);
  };

  forceDownload = (blob: any, filename: String) => {
    const a: any = document.createElement("a");
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  // Current blob size limit is around 500MB for browsers
  downloadResource = (url: any, filename: any) => {
    console.log("download_url", url);
    
    if (url) {
      if (!filename) filename = url.split("\\").pop().split("/").pop();
      fetch(url, {
        headers: new Headers({
          Origin: window.location.origin,
        }),
        mode: "cors",
      })
        .then((response) => response.blob())
        .then((blob) => {
          let blobUrl = window.URL.createObjectURL(blob);
          this.forceDownload(blobUrl, filename);
        })
        .catch((e) => console.error(e));
    }
  };

  handlePreviewClose = () => {
    this.setState({ AttachmentPreviewStatus: false });
  };
  handlePreviewOpen = (file_type: any, url: any) => {
    console.log("handlePreviewOpen",url);
    console.log("handlePreviewOpen_fileType",file_type);
    if (
      file_type === "image/png" ||
      file_type === "image/jpeg" ||
      file_type === "image/jpg" ||
      file_type === "video/mp4" ||
      file_type === "video/webm" ||
      file_type === "application/pdf"
    ) {
      this.setState({
        AttachmentPreviewStatus: true,
        previewUrl: url,
        previewType: file_type,
      });
    }
  };
  render(): React.ReactNode {
    const {
      emergency_mobile,
      pdf_file,
      personal_contact_no,
      abn_no,
      acc_name,
      acc_no,
      attachments,
      bsb_no,
      business_address,
      business_city_state,
      business_contact_no,
      business_name,
      contract_date,
      contractor_signature,
      date_of_birth,
      date_of_start,
      email,
      emergency_address,
      emergency_home_no,
      emergency_name,
      emergency_relationship,
      first_name,
      gender,
      home_address,
      home_city_state,
      id,
      job_title,
      last_name,
    } = this.props.data;
    return (
      <div>
        <div className="row">
          <div className="col-md-12 row">
            <div className="col-md-6 row">
              <div className="col-md-12">
                <p className="dashboard-title">Name:</p>
                <p className="fw-600">{`${first_name} ${last_name}`}</p>
              </div>
              <div className="col-md-12">
                <p className="mb-1">Contractor:</p>
              </div>
              {business_name ? (
                <div className="col-md-12">
                  <p className="mb-1">Business name:</p>
                  <p className="fw-600">{business_name}</p>
                </div>
              ) : null}
            </div>
            <div className="col-md-6 row">
              <div className="col-md-12">
                <p className="dashboard-title">Profile Image:</p>
              </div>
              <div className="col-md-12 text-center">
                {console.log(this.state.attachmentUrls, "attachment url")}
                {this.state.attachmentUrls &&
                  this.state.attachmentUrls.map((obj: any, index: number) => 
                  {
                    console.log("this.state.attachmentUrls",obj);
                    return (
                      <>
                        {obj.attachment_type === "contract_profile" && (
                          <div className="img-card mr-16px mb-3">
                            <span className="contract-document-download">
                              <a
                                href="javascript:void(0)"
                                onClick={() =>
                                  this.downloadResource(
                                    obj.download_url,
                                    obj.name
                                  )
                                }
                              >
                                <AttachmentDownloadSVG />
                              </a>
                            </span>
                            <div
                              className="img-preview"
                              onClick={() =>
                                this.handlePreviewOpen(
                                  obj.file_type,
                                  obj.url
                                )
                              }
                            >
                              <img
                                className="img-thumb-perview"
                                src={
                                  obj.file_type === "application/pdf"
                                    ? pdfImage
                                    : obj.file_type === "video/mp4" ||
                                      obj.file_type === "video/webm"
                                      ? videoImage
                                      : obj.file_type === "image/png" ||
                                        obj.file_type === "image/jpeg" ||
                                        obj.file_type === "image/jpg"
                                        ? obj.url
                                        : docImage
                                }
                                alt="attach"
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )
                  }
                  )}
                <AttachmentPreview
                  displayPreview={this.state.AttachmentPreviewStatus}
                  previewUrl={this.state.previewUrl}
                  previewType={this.state.previewType}
                  handlePreviewClose={this.handlePreviewClose}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p className="dashboard-title">Tax File Number:</p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">ABN/ ACN Number:</p>
            <p className="fw-600">{abn_no}</p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">Date of Start:</p>
            <p className="fw-600">
              {moment(new Date(date_of_start?.toString())).format("DD/MM/YYYY")}
            </p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">Job Title:</p>
            <p className="fw-600">{job_title}</p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">Date of Birth:</p>
            <p className="fw-600">
              {moment(new Date(date_of_birth?.toString())).format("DD/MM/YYYY")}
            </p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">Gender:</p>
            <p className="fw-600">{gender}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p className="dashboard-title">Personal Contact Info: </p>
          </div>
          <div className="col-md-12">
            <p className="mb-1">Email Address:</p>
            <p className="fw-600">{email}</p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">Contact Telephone No: Business</p>
            <p className="fw-600">{business_contact_no}</p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">Mobile:</p>
            <p className="fw-600">{personal_contact_no}</p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">Home Address:</p>
            <p className="fw-600">{home_address}</p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">Home City/ State:</p>
            <p className="fw-600">{home_city_state}</p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">Business Address:</p>
            <p className="fw-600">{business_address}</p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">Business City/ State:</p>
            <p className="fw-600">{business_city_state}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p className="dashboard-title">Emergency Contact Info: </p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">Name:</p>
            <p className="fw-600">{emergency_name}</p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">Address:</p>
            <p className="fw-600">{emergency_address}</p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">Relationship:</p>
            <p className="fw-600">{emergency_relationship}</p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">Contact Number: Home</p>
            <p className="fw-600">{emergency_home_no}</p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">Mobile:</p>
            <p className="fw-600">{emergency_mobile}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p className="dashboard-title">Bank Details: </p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">Accounts Name:</p>
            <p className="fw-600">{acc_name}</p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">BSB Number:</p>
            <p className="fw-600">{bsb_no}</p>
          </div>
          <div className="col-md-6">
            <p className="mb-1">Account Number:</p>
            <p className="fw-600">{acc_no}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p className="dashboard-title">Documents: </p>
          </div>
          <div className="col-md-12">
            <p className="mb-1">Insurance:</p>
          </div>
          <div className="col-md-12">
            <div className="row">
              {this.state.attachmentUrls &&
                this.state.attachmentUrls.map((obj: any, index: number) => (
                  <>
                    {obj.attachment_type === "insurance" && (
                      <div className="img-card mr-16px mb-3">
                        <span className="contract-document-download">
                          <a
                            href="javascript:void(0)"
                            onClick={() =>
                              this.downloadResource(
                                obj.download_url,
                                obj.name
                              )
                            }
                          >
                            <AttachmentDownloadSVG />
                          </a>
                        </span>
                        <div
                          className="img-preview"
                          onClick={() =>
                            this.handlePreviewOpen(
                              obj.file_type,
                              obj.url
                            )
                          }
                        >
                          <img
                            className="img-thumb-perview"
                            src={
                              obj.file_type === "application/pdf"
                                ? pdfImage
                                : obj.file_type === "video/mp4" ||
                                  obj.file_type === "video/webm"
                                  ? videoImage
                                  : obj.file_type === "image/png" ||
                                    obj.file_type === "image/jpeg" ||
                                    obj.file_type === "image/jpg"
                                    ? obj.url
                                    : docImage
                            }
                            alt="attach"
                          />
                        </div>
                      </div>
                    )}
                  </>
                ))}
              <AttachmentPreview
                displayPreview={this.state.AttachmentPreviewStatus}
                previewUrl={this.state.previewUrl}
                previewType={this.state.previewType}
                handlePreviewClose={this.handlePreviewClose}
              />
            </div>
          </div>
          <div className="col-md-12">
            <p className="mb-1">Visa:</p>
          </div>
          <div className="col-md-12">
            <div className="row">
              {this.state.attachmentUrls &&
                this.state.attachmentUrls.map((obj: any, index: number) => (
                  <>
                    {obj.attachment_type === "visa" && (
                      <div className="img-card mr-16px mb-3">
                        <span className="contract-document-download">
                          <a
                            href="javascript:void(0)"
                            onClick={() =>
                              this.downloadResource(
                                obj.download_url,
                                obj.name
                              )
                            }
                          >
                            <AttachmentDownloadSVG />
                          </a>
                        </span>
                        <div
                          className="img-preview"
                          onClick={() =>
                            this.handlePreviewOpen(
                              obj.file_type,
                              obj.url
                            )
                          }
                        >
                          <img
                            className="img-thumb-perview"
                            src={
                              obj.file_type === "application/pdf"
                                ? pdfImage
                                : obj.file_type === "video/mp4" ||
                                  obj.file_type === "video/webm"
                                  ? videoImage
                                  : obj.file_type === "image/png" ||
                                    obj.file_type === "image/jpeg" ||
                                    obj.file_type === "image/jpg"
                                    ? obj.url
                                    : docImage
                            }
                            alt="attach"
                          />
                        </div>
                      </div>
                    )}
                  </>
                ))}
              <AttachmentPreview
                displayPreview={this.state.AttachmentPreviewStatus}
                previewUrl={this.state.previewUrl}
                previewType={this.state.previewType}
                handlePreviewClose={this.handlePreviewClose}
              />
            </div>
          </div>
          <div className="col-md-12">
            <p className="mb-1">License:</p>
          </div>
          <div className="col-md-12">
            <div className="row">
              {this.state.attachmentUrls &&
                this.state.attachmentUrls.map((obj: any, index: number) => (
                  <>
                    {obj.attachment_type !== "visa" &&
                      obj.attachment_type !== "contract_profile" &&
                      obj.attachment_type !== "insurance" &&
                      obj.attachment_type !== null &&
                      obj.attachment_type !== 'contract_signature' &&
                      (
                        <div className="img-card mr-16px mb-3">
                          <span className="contract-document-download">
                            <a
                              href="javascript:void(0)"
                              onClick={() =>
                                this.downloadResource(
                                  obj.download_url,
                                  obj.name
                                )
                              }
                            >
                              <AttachmentDownloadSVG />
                            </a>
                          </span>
                          <div
                            className="img-preview"
                            onClick={() =>
                              this.handlePreviewOpen(
                                obj.file_type,
                                obj.url
                              )
                            }
                          >
                            <img
                              className="img-thumb-perview"
                              src={
                                obj.file_type === "application/pdf"
                                  ? pdfImage
                                  : obj.file_type === "video/mp4" ||
                                    obj.file_type === "video/webm"
                                    ? videoImage
                                    : obj.file_type === "image/png" ||
                                      obj.file_type === "image/jpeg" ||
                                      obj.file_type === "image/jpg"
                                      ? obj.url
                                      : docImage
                              }
                              alt="attach"
                            />
                          </div>
                          <span style={{ position: "relative" }}>
                            {obj.attachment_type
                              ? obj.attachment_type.substring(8)
                              : ""}
                          </span>
                        </div>
                      )}
                  </>
                ))}
              <AttachmentPreview
                displayPreview={this.state.AttachmentPreviewStatus}
                previewUrl={this.state.previewUrl}
                previewType={this.state.previewType}
                handlePreviewClose={this.handlePreviewClose}
              />
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-12">
            <p>
              I have provided the above contact information and authorize SM&MN
              PROPERTY Group Pty Ltd and its representatives to contact any of
              the above on my behalf in the event of emergency
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <div>
              {contractor_signature !== "" && (
                <img src={contractor_signature} alt="" />
              )}
            </div>
            <p className="mb-1">Contractor signature:</p>
            <p className="mb-1">
              Contract Date :{" "}
              {moment(new Date(contract_date?.toString())).format("DD/MM/YYYY")}{" "}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewContractor);
