import {
  API_ADD_SCHEDULE_PROGRESS,
  API_ADD_SCHEDULE,
  API_ADD_SCHEDULE_FAIL,
  API_ADD_SCHEDULE_SUCCESS,
  API_BRANCH_DROP_DOWN,
  API_BRANCH_DROP_DOWN_FAIL,
  API_BRANCH_DROP_DOWN_SUCCESS,
  API_DELETE_BY_SCHEDULE,
  API_DELETE_BY_SCHEDULE_FAIL,
  API_DELETE_BY_SCHEDULE_SUCCESS,
  API_EDIT_SCHEDULE,
  API_EDIT_SCHEDULE_FAIL,
  API_EDIT_SCHEDULE_PROGRESS,
  API_EDIT_SCHEDULE_SCCESSS,
  API_GET_ALL_SCHEDULES,
  API_GET_ALL_SCHEDULES_FAIL,
  API_GET_ALL_SCHEDULES_SUCCESS,
  API_GET_TASK_BY_ID,
  API_GET_TASK_NAME,
  API_GET_TASK_NAME_FAIL,
  API_GET_TASK_NAME_SUCCESS,
  API_TASK_DROP_DOWN,
  API_TASK_DROP_DOWN_FAIL,
  API_TASK_DROP_DOWN_SUCCESS,
  API_UPDATE_SCHEDULE,
  API_UPDATE_SCHEDULE_FAIL,
  API_UPDATE_SCHEDULE_SCCESSS,
  FILTER_SCHEDULE_BRANCH,
  RUN_SCHEDULE_API,
  RUN_SCHEDULE_API_FAIL,
  RUN_SCHEDULE_API_SUCCESS,
  ScheduleState,
  UPDATE_SCHEDULE_FETCH_STATUS,
  API_DELETE_BY_SP_SCHEDULE,
  ADD_SCHEDULE_UPDATE_STATUS,
} from "../ActionTypes/index";
import { AnyAction, Dispatch } from "redux";

import { TScheduleTable } from "../../types/schedule.type";

const initialState: ScheduleState = {
  scheduleFetch: [],
  data: [],
  deleteResponse: {},
  taskDetails: [],
  addTaskMessage: [],
  editScheduleDetails: [],
  scheduleFilterData: [],
  updateMessage: [],
  taskDropDownList: [],
  branchDropDown: [],
  total_count: "",
  addScheduleSuccess: "",
  deleteScheduleSuccess: "",
  editScheduleSuccess: "",
  scheduleFetchStatus: false,
  processTrigger: false,
  progressPercentage: 0,
  runScheduleResponse: "",
  addScheduleModalStatus: false,
};

export const ScheduleReducer = (
  state: ScheduleState = initialState,
  action: AnyAction
): ScheduleState => {
  switch (action.type) {
    case API_GET_ALL_SCHEDULES:
      return { ...state, data: action.payload };
    case API_GET_ALL_SCHEDULES_SUCCESS:
      return {
        ...state,
        data: action.payload.data.schedules,
        total_count: action.payload.data.total_count,
        scheduleFilterData: action.payload.data.schedules,
      };
    case API_GET_ALL_SCHEDULES_FAIL:
      return { ...state };

    case API_DELETE_BY_SCHEDULE:
      return {
        ...state,
        deleteResponse: action.payload,
        deleteScheduleSuccess: "",
      };
    case API_DELETE_BY_SCHEDULE_SUCCESS:
      let msg = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          msg = data.message;
        }
      }
      return {
        ...state,
        deleteResponse: action.payload,
        deleteScheduleSuccess: msg,
        scheduleFetchStatus: true,
      };
    case API_DELETE_BY_SCHEDULE_FAIL:
      return { ...state };
    case API_DELETE_BY_SP_SCHEDULE:
      return {
        ...state,
        deleteResponse: action.payload,
        deleteScheduleSuccess: "",
      };
    case API_GET_TASK_NAME:
      return { ...state, taskDetails: action.payload };
    case API_GET_TASK_NAME_SUCCESS:
      return { ...state, taskDetails: action.payload };
    case API_GET_TASK_NAME_FAIL:
      return { ...state };

    case API_ADD_SCHEDULE:
      let values: boolean = false;
      if (action.payload.request.data.FormData === undefined) {
        values = true;
      }
      return {
        ...state,
        addTaskMessage: action.payload,
        addScheduleSuccess: "",
        processTrigger: values,
      };
    case API_ADD_SCHEDULE_SUCCESS:
      let message = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          message = data.message;
        }
      }
      return {
        ...state,
        addTaskMessage: action.payload,
        addScheduleSuccess: message,
        progressPercentage: 0,
        processTrigger: false,
        addScheduleModalStatus: false,
      };
    case API_ADD_SCHEDULE_FAIL:
      return { ...state };
    case API_ADD_SCHEDULE_PROGRESS:
      return { ...state, progressPercentage: action.payload };
    case API_EDIT_SCHEDULE_PROGRESS:
      return { ...state, progressPercentage: action.payload };
    case API_EDIT_SCHEDULE:
      return {
        ...state,
      };
    case API_EDIT_SCHEDULE_SCCESSS:
      let messages = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          messages = data.message;
        }
      }
      console.log(messages, "messages");
      console.log("actionPayload", action.payload.data);
      return {
        ...state,
        editScheduleDetails: action.payload.data.schedule,
        editScheduleSuccess: messages,
        scheduleFetchStatus: true,
      };
    case API_EDIT_SCHEDULE_FAIL:
      return { ...state };

    case API_UPDATE_SCHEDULE:
      let value: boolean = false;
      if (action.payload.request.data.FormData === undefined) {
        value = true;
      }
      return { ...state, updateMessage: action.payload, processTrigger: value };
    case API_UPDATE_SCHEDULE_SCCESSS:
      return {
        ...state,
        updateMessage: action.payload,
        progressPercentage: 0,
        processTrigger: false,
      };
    case API_UPDATE_SCHEDULE_FAIL:
      return { ...state, progressPercentage: 0, processTrigger: false };
    case API_BRANCH_DROP_DOWN:
      return { ...state, branchDropDown: action.payload };
    case API_BRANCH_DROP_DOWN_SUCCESS:
      return { ...state, branchDropDown: action.payload };
    case API_BRANCH_DROP_DOWN_FAIL:
      return { ...state };

    case API_TASK_DROP_DOWN:
      return { ...state, taskDropDownList: [] };
    case API_TASK_DROP_DOWN_SUCCESS:
      let taskDropDownList = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "task" in data && data.task) {
          taskDropDownList = data.task;
        }
      }
      return { ...state, taskDropDownList: taskDropDownList };
    case API_TASK_DROP_DOWN_FAIL:
      return { ...state };
    case UPDATE_SCHEDULE_FETCH_STATUS:
      return { ...state, scheduleFetchStatus: action.payload };
    case FILTER_SCHEDULE_BRANCH:
      // console.log(action.payload, ";;;");
      const filterLoc: TScheduleTable[] = state.data.filter((obj: any) => {
        console.log(obj, "object");
        return (
          obj.title.toLowerCase().includes(action.payload) ||
          obj.branch_name.toLowerCase().includes(action.payload) ||
          (obj.schedule_name !== null &&
            obj.schedule_name.toLowerCase().includes(action.payload)) ||
          obj.first_name.toLowerCase().includes(action.payload)
        );
      });
      return { ...state, scheduleFilterData: filterLoc };
    case RUN_SCHEDULE_API:
      return { ...state, runScheduleResponse: "" };
    case RUN_SCHEDULE_API_SUCCESS:
      return { ...state, runScheduleResponse: action.payload.data.message };
    case RUN_SCHEDULE_API_FAIL:
      return { ...state };
    case ADD_SCHEDULE_UPDATE_STATUS:
      return { ...state, addScheduleModalStatus: action.payload };
    default:
      return state;
  }
};

// Get all schedule by query for table
export function getAllSchedules(query: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_ALL_SCHEDULES,
      payload: {
        request: {
          method: "get",
          url: query ? `schedule?${query}` : `schedule?`,
        },
      },
    });
  };
}

// Get all Task schedule by query for table
export function getAllTaskSchedules(query: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_ALL_SCHEDULES,
      payload: {
        request: {
          method: "get",
          url: query,
        },
      },
    });
  };
}

export function getTaskName() {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_TASK_BY_ID,
      payload: {
        request: {
          method: "get",
          url: `task/6`,
        },
      },
    });
  };
}

export function scheduleDelete(id: any) {
  console.log("reducer schedule id", id);
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_DELETE_BY_SCHEDULE,
      payload: {
        request: {
          method: "delete",
          url: `schedule/${id}`,
        },
      },
    });
  };
}

// special request schedules delete function
export function spScheduleDelete(id: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_DELETE_BY_SP_SCHEDULE,
      payload: {
        request: {
          method: "delete",
          url: `sp-schedules/${id}`,
        },
      },
    });
  };
}

export function getTaskDetails() {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_GET_TASK_NAME,
      payload: {
        request: {
          method: "get",
          url: `task-template?search=&sort=&order=&offset=0&limit=100&client=all&branch=all`,
        },
      },
    });
  };
}

export function addSchedule(payload: FormData) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_ADD_SCHEDULE,
      payload: {
        request: {
          method: "post",
          url: `/schedule`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_ADD_SCHEDULE_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

export function editSchedule(id: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_EDIT_SCHEDULE,
      payload: {
        request: {
          method: "get",
          url: `/schedule/${id}`,
        },
      },
    });
  };
}

export function editSPSchedule(id: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_EDIT_SCHEDULE,
      payload: {
        request: {
          method: "get",
          url: `/sp-schedules/${id}`,
        },
      },
    });
  };
}

export function updateSchedule(id: any, data: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_UPDATE_SCHEDULE,
      payload: {
        request: {
          method: "put",
          url: `/schedule/${id}`,
          data: data,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_EDIT_SCHEDULE_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

export function updateSPSchedule(id: any, data: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_UPDATE_SCHEDULE,
      payload: {
        request: {
          method: "put",
          url: `/sp-schedules/${id}`,
          data: data,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_EDIT_SCHEDULE_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

export function branchDropDown(id: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_BRANCH_DROP_DOWN,
      payload: {
        request: {
          method: "get",
          url: `/branch/drop-down-list/${id}`,
        },
      },
    });
  };
}

export function taskDropDown(id: any) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: API_TASK_DROP_DOWN,
      payload: {
        request: {
          method: "get",
          url: `/task-template/drop-down-list/${id}`,
        },
      },
    });
  };
}

// filter table branch
export function filterSchedule(filterVal: string) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: FILTER_SCHEDULE_BRANCH,
      payload: filterVal,
    });
  };
}

export function updateScheduleFetchStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: UPDATE_SCHEDULE_FETCH_STATUS,
      payload: {
        status: status,
      },
    });
  };
}

export function updateAddScheduleStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: ADD_SCHEDULE_UPDATE_STATUS,
      payload: status,
    });
  };
}

export function runApiSchedule() {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: RUN_SCHEDULE_API,
      payload: {
        request: {
          method: "get",
          url: `/run-schedule`,
        },
      },
    });
  };
}
