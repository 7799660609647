// import React, { useState } from "react";
// import DrawingBoard from "react-drawing-board";

// function MyDrawingBoard() {
//   const [operations, setOperations] = useState([]);

//   return (
//     <div className="App">
//       <DrawingBoard
//         userId="user1"
//         operations={operations}
//         onChange={(newOperation, afterOperation) => {
//           console.log(`TODO: send ${newOperation}`);
//           setOperations(afterOperation);
//         }}
//       />
//     </div>
//   );
// }

// export default MyDrawingBoard;

import React, { useState } from "react";
import ImageUpload from "./ImageUpload";
import ImageDrawing from "./ImageDrawing";

function App() {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [operations, setOperations] = useState([]);

  return (
    <div>
      <h1>Image Upload and Drawing with Fabric.js</h1>
      <ImageUpload onImageUpload={setUploadedImage} />
      <ImageDrawing image={uploadedImage} />
    </div>
  );
}

export default App;
