import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import AuthService from "../../services/auth.service";
import * as Yup from "yup";
import {
  updateProfile,
  getAllCompletedDailyTasks,
  getAllAudits
} from "../../shared/Reducers/index";
import { RootState } from "../../index";
import { connect } from "react-redux";
import {
  INotificationProps,
  INotificationState,
} from "../../types/topbar.type";
import {
  WithNotificationSVG,
} from "../../assets/images/ImagesV2";
import Constants from "../../shared/Constants";
import { bindActionCreators, Dispatch } from "redux";
import { Navigate } from "react-router-dom";
import moment from "moment";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

class Notification extends React.Component<
  INotificationProps,
  INotificationState
> {
  constructor(props: INotificationProps) {
    super(props);

    this.state = {
      collapseMenu: true,
      old_password: "",
      new_pasword: "",
      // successful: false,
      message: "",
      confirm_password: "",
      show: false,
      hide_old: true,
      hide_new: true,
      hide_conf: true,
      editShow: false,
      messageClassName: "",
      redirect: "",
    };
  }
  async componentDidMount() {
    try {
      const query = `search=&sort=&order=&offset=${Constants.OFFSET}&limit=5&client=all&branch=all&task_type=${Constants.SPECIAL_REQUEST}&task_status=${Constants.TASK_COMPLETED}&status`;
      await this.props.getAllCompletedDailyTasks(query);
      const auditquery = `search=&sort=&order=&offset=${Constants.OFFSET}&limit=5&client=all&branch=all&task_status=${Constants.TASK_COMPLETED}&status`;
      this.props.getAllAudits(auditquery);
    } catch (error) {
      console.log(error);
    }
  }
  // Edit User
  editAdminShow = () => {
    this.setState({ editShow: true });
  };
  editAdminClose = () => {
    this.setState({ editShow: false });
  };

  toggleOld = (e: any) => {
    e.preventDefault();
    this.setState({ hide_old: !this.state.hide_old });
  };

  toggleNew = (e: any) => {
    e.preventDefault();
    this.setState({ hide_new: !this.state.hide_new });
  };

  toggleConf = (e: any) => {
    e.preventDefault();
    this.setState({ hide_conf: !this.state.hide_conf });
  };

  showHide = (e: any) => {
    e.preventDefault();
    this.setState((prev) => ({ show: !prev.show }));
  };

  handleLogOut(): void {
    AuthService.logout();
    window.location.href = "/";
  }

  validationSchema() {
    return Yup.object().shape({
      old_password: Yup.string()
        .required("Enter Current Password")
        .test(
          "len",
          "Enter Current Password",
          (val: any) => val && val.toString().length >= 8
        ),
      new_password: Yup.string()
        .required("Enter new Password")
        .test(
          "len",
          "Enter new Password",
          (val: any) => val && val.toString().length >= 8
        ),
      confirm_password: Yup.string()
        .required("Enter Confirm Password")
        .test(
          "len",
          "Enter confirm password",
          (val: any) => val && val.toString().length >= 8
        )
        .oneOf([Yup.ref("new_password"), null], "Passwords does not match"),
    });
  }

  handleChagePassword = async (
    formValue: {
      old_password: string;
      new_password: string;
      confirm_password: any;
    },
    { resetForm }: { resetForm: any }
  ) => {
    console.log(formValue);
    const { old_password, new_password } = formValue;

    this.setState({
      message: "",
      // successful: false
    });
    try {
      await this.props.updatePassword({
        old_password: old_password,
        new_password: new_password,
      });
      console.log(this.props.updateProfile);
      if (this.props.updateProfile.status === 200) {
        formValue.old_password = "";
        formValue.new_password = "";
        formValue.confirm_password = "";
        this.setState(
          {
            message: "Your password is changed successfully",
            messageClassName: "success-alert",
          },
          () => {
            setTimeout(() => {
              this.setState({ show: false, editShow: false, message: "" });
            }, 2000);
          }
        );
      }
      console.log(this.props.updateWrongProfile);
      if (this.props.updateWrongProfile.error.response.status === 406) {
        formValue.old_password = "";
        formValue.new_password = "";
        formValue.confirm_password = "";
        this.setState({
          message: this.props.updateWrongProfile.error.response.data.message,
          messageClassName: "cus-alert",
        });
      }
    } catch (error) {
      formValue.old_password = "";
      formValue.new_password = "";
      formValue.confirm_password = "";

      console.log(error);
    }
  };

  renderCompleted = (data: any) => {
    return data.map((row: any, index: number) => {
      return (
        <tr key={index}>
          <td>{row.branch_name}</td>
          <td>{row.title}</td>
          <td>{row.task_date}</td>
        </tr>
      );
    });
  };

  render(): React.ReactNode {
    console.log("abcd", this.props.userLoginDetails?.permission)
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    var defaultTabKey: any = this.props.userLoginDetails?.permission?.topbar_completed_task_notification === 1 ? "completed" : "audit";
    return (
      <div>
        <Dropdown autoClose="outside" className="profile-bg">
          <Dropdown.Toggle
            className="icon-style"
            id="dropdown-autoclose-outside"
          >
            {/* <img src={WithNotificationSVG} alt="profile" />
             */}
            <WithNotificationSVG />
          </Dropdown.Toggle>
          <Dropdown.Menu className="box-size dropdown-size profile-bg p-0">
            <Dropdown.Item>
              <Tabs
                defaultActiveKey={defaultTabKey}
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                {
                  this.props.userLoginDetails?.permission?.topbar_completed_task_notification === 1 && (
                    < Tab eventKey="completed" title="Completed Task">

                      <div className="dash-noti-cont-scroll">

                        {this.props.completeDailyTask &&
                          this.props.completeDailyTask.length > 0 &&
                          this.props.completeDailyTask.map((row: any, index: number) => {
                            return (

                              <div className="card mb-2">
                                <div className="card-body">
                                  <p className="mb-0">{row.title}</p>
                                  <p className="mb-0 profile-ep">
                                    <span>{row.branch_name} </span> | <span> {moment(row.task_date, "YYYY-MM-DD").format("DD MMM YYYY")}</span>
                                  </p>
                                </div>
                              </div>

                            );
                          })
                        }
                        {this.props.completeDailyTask &&
                          this.props.completeDailyTask.length === 0 &&
                          <div>
                            <p className="mb-0">No task found</p>
                          </div>
                        }
                      </div>

                      {this.props.completeDailyTask &&
                        this.props.completeDailyTask.length ? (
                        <Button
                          className="logout-button noti-view-all-btn"
                          onClick={() => {
                            this.setState({
                              redirect: "/task/completed",
                            });
                          }}
                        >
                          View All
                        </Button>
                      ) : (
                        <></>
                      )}

                    </Tab>
                  )}
                {
                  this.props.userLoginDetails?.permission?.topbar_audit_task_notification === 1 && (
                    <Tab eventKey="audit" title="Audit Task">
                      <div className="dash-noti-cont-scroll">

                        {this.props.filteredAuditData &&
                          this.props.filteredAuditData.length > 0 &&
                          this.props.filteredAuditData.map((row: any, index: number) => {
                            return (
                              <div>
                                {
                                  index <= 10 &&
                                  <div className="card mb-2">
                                    <div className="card-body">
                                      <p className="mb-0">{row.name}</p>
                                      <p className="mb-0 profile-ep">
                                        <span>{row.first_name + " " + row.last_name} - {row.branch_name} </span>
                                      </p>
                                      <p className="mb-0 profile-ep">
                                        ({row.score + "/" + row.task_count})
                                      </p>
                                    </div>
                                  </div>
                                }
                              </div>
                            );
                          })
                        }
                        {this.props.filteredAuditData &&
                          this.props.filteredAuditData.length === 0 &&
                          <div>
                            <p className="mb-0">No task found</p>
                          </div>
                        }
                      </div>

                      {this.props.filteredAuditData &&
                        this.props.filteredAuditData.length ? (
                        <Button
                          className="logout-button noti-view-all-btn"
                          onClick={() => {
                            this.setState({
                              redirect: "/audit",
                            });
                          }}
                        >
                          View All
                        </Button>
                      ) : (
                        <></>
                      )}
                    </Tab>
                  )}
              </Tabs>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div >
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  userLoginDetails: state.sidebar.userLoginDetails,
  subAdmin: state.app.subAdmin,
  updateProfile: state.usermanagement.updateProfileState,
  updateWrongProfile: state.usermanagement.updateWrongProfile,
  completeDailyTask: state.dailytask.completeDailyTask,
  filteredAuditData: state.audit.filteredAuditData
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updatePassword: bindActionCreators(updateProfile, dispatch),
    getAllAudits: bindActionCreators(getAllAudits, dispatch),
    getAllCompletedDailyTasks: bindActionCreators(
      getAllCompletedDailyTasks,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
