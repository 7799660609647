import * as Yup from "yup";

import { Dispatch, bindActionCreators } from "redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  addComplaint,
  listAllClient,
  listAllBranch,
} from "../../shared/Reducers";

import { ICleaner } from "../../shared/ActionTypes";
import { Modal } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
import { CustomSelect } from "../../shared/Components/CustomSelect";
import Constants from "../../shared/Constants";


type Props = {
  onClose: (status: boolean) => void;
  clientList?: any;
  listAllClient?: any;
  addComplaintError?: any;
  branchList?: any;
  listAllBranch?: any;
  addComplaint?: any;
  processTrigger: any;
  addComplaintSuccess: any;
  progressPercentage: number;
  loginUserDetail: any;
};

type TAddComplaintState = {
  userTypeDetail: any;
};

class AddComplaint extends React.Component<Props, TAddComplaintState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userTypeDetail: "",
    };
  }

  componentDidMount(): void {
    this.listData();
    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.add("d-none");
      button.removeAttribute("disabled");
    }
    this.setState({ userTypeDetail: this.props.loginUserDetail });
    // console.log(this.props.loginUserDetail);
    // if (Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE || Constants.QA_MANAGERUTINT === Constants.LOGIN_USER_TYPE || Constants.SUPERVISOR_MANAGERUTINT === Constants.LOGIN_USER_TYPE) {
    //   this.props.listAllBranch(
    //     this.props.loginUserDetail.assigned_branches.client.id
    //   );
    // }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<TAddComplaintState>,
    snapshot?: any
  ): void {
    if (prevProps.processTrigger !== this.props.processTrigger) {
    }
    if (prevProps.addComplaintSuccess !== this.props.addComplaintSuccess) {
    }
  }

  listData = () => {
    this.props.listAllClient();
  };

  handleAddComplaint = async (formValues: any) => {
    let data: any = [];
    data = {
      client_id: formValues.client,
      branch_id: formValues.branch,
      name: formValues.title,
    };
    var element = document.getElementById("spinner-border");
    var button = document.getElementById("create-button");
    if (element !== null && button !== null) {
      element.classList.remove("d-none");
      button.setAttribute("disabled", "");
    }
    await this.props.addComplaint(data);
    setTimeout(() => {
      this.props.onClose(false);
    }, 1000);
  };

  validationSchema = () => {
    const clientId = this.props.clientList.map((client: ICleaner) => client.id);
    // const branchId = this.props.branchList.map((branch: IBranch) => branch.id);
    // if (Constants.QAUTINT === Constants.LOGIN_USER_TYPE) {
    //   return Yup.object().shape({
    //     title: Yup.string().required("Enter a complaint title"),
    //   });
    // } else if (Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE || Constants.QA_MANAGERUTINT === Constants.LOGIN_USER_TYPE || Constants.SUPERVISOR_MANAGERUTINT === Constants.LOGIN_USER_TYPE) {
    //   return Yup.object().shape({
    //     title: Yup.string().required("Enter a complaint title"),
    //     branch: Yup.string().required("Choose a branch"),
    //   });
    // } else {
      return Yup.object().shape({
        title: Yup.string().required("Enter a complaint title"),
        client: Yup.string()
          .oneOf([...clientId], "Invalid Client")
          .required()
          .nullable(),
        branch: Yup.string().required("Choose a branch"),
      });
    // }
  };

  handleOnChangeClient = (id: any) => {
    this.props.listAllBranch(id);
  };
  progress = (e: any) => {
    console.log("progress", e);
  };

  render(): React.ReactNode {
    const initialValues = {
      title: "",
      client: "",
      branch: "",
    };
    return (
      <div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={this.handleAddComplaint}
          validationSchema={this.validationSchema}
        >
          <Form encType="multipart/form-data">
            <div className="row">
              <div className="col-md-6 mb-2">
                <label className="form-label">
                  Complaint Title<span className="text-project">*</span>
                </label>
                <Field
                  name="title"
                  type={"text"}
                  className="form-control text-field"
                />
                <ErrorMessage
                  name="title"
                  component={"div"}
                  className="cus-alert"
                />
              </div>
              {/* {Constants.QAUTINT === Constants.LOGIN_USER_TYPE ? null : ( */}
                <div className="col-md-12">
                  <div className="row">
                    {/* {Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE || 
                    Constants.QA_MANAGERUTINT === Constants.LOGIN_USER_TYPE || 
                    Constants.SUPERVISOR_MANAGERUTINT === Constants.LOGIN_USER_TYPE ? null : ( */}
                      <div className="mb-2 col-md-6">
                        <label className="form-label">
                          Client<span className="text-project">*</span>
                        </label>
                        <Field
                          className="custom-select dropdown-field"
                          name="client"
                          options={this.props.clientList.map((client: any) => ({
                            value: client.id,
                            label: client.first_name + " " + client.last_name,
                          }))}
                          onChange={(e: any) =>
                            this.handleOnChangeClient(e.value)
                          }
                          component={CustomSelect}
                          placeholder="Select Client"
                          isMulti={false}
                        />
                        <ErrorMessage
                          name="client"
                          component={"div"}
                          className="cus-alert"
                        />
                      </div>
                    {/* )} */}
                    <div className="mb-2 col-md-6">
                      <label className="form-label">
                        Branch<span className="text-project">*</span>
                      </label>
                      <Field
                        className="custom-select dropdown-field"
                        name="branch"
                        options={this.props.branchList}
                        component={CustomSelect}
                        placeholder="Select Branch"
                        isMulti={false}
                      />
                      <ErrorMessage
                        name="branch"
                        component={"div"}
                        className="cus-alert"
                      />
                    </div>
                  </div>
                </div>
              {/* )} */}
              <div className="row mt-3 mb-3">
                <div className="col-md-12 cus-progress">
                  <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.props.processTrigger}
                  >
                    <Modal.Body className="p-0">
                      <ProgressBar
                        now={this.props.progressPercentage}
                        label={
                          this.props.progressPercentage === 100
                            ? `Processing your request...`
                            : `Complaint Adding....${this.props.progressPercentage}%`
                        }
                      />
                    </Modal.Body>
                  </Modal>
                  <div className="d-flex">
                    <div className="mr-16px">
                      <button
                        type="submit"
                        id="create-button"
                        className="btn btn-primary create-button"
                        onClick={(e) => this.progress(e)}
                      >
                        Create
                        <span
                          id="spinner-border"
                          className="spinner-border spinner-border-sm d-none"
                        ></span>
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn cancel-button"
                        onClick={() => this.props.onClose(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  clientList: state.complaint.clientList,
  branchList: state.tasktemplate.branchList,
  processTrigger: state.complaint.processTrigger,
  addComplaintSuccess: state.complaint.addComplaintSuccess,
  progressPercentage: state.complaint.progressPercentage,
  loginUserDetail: state.sidebar.userLoginDetails,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    listAllClient: bindActionCreators(listAllClient, dispatch),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
    addComplaint: bindActionCreators(addComplaint, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddComplaint);
