import * as Yup from "yup";

import { Dispatch, bindActionCreators } from "redux";

import {
  AttachmentDownloadSVG,
  CloseBtnSVG,
} from "../../assets/images/ImagesSVG";
import { Form, Formik, Field, ErrorMessage } from "formik";
import {
  addComplaint,
  editComplaint,
  listAllClient,
  listAllBranch,
  addComments,
  sendMessage,
  getComplaintByIdForChat,
  msgReadStatusChange,
} from "../../shared/Reducers";

import React from "react";
import { RootState } from "../../index";
import { connect } from "react-redux";
// date picker
import moment from "moment";
import {
  PaperClipSVG,
  Reload,
  Send,
  TrashSVG,
} from "../../assets/images/ImagesV2";
import ChatDummyImg from "../../assets/images/chat-dummy-img.jpg";
import Constants, {
  COMMENTS_TASK_STATUS,
  COMPLAINT_STATUS,
} from "../../shared/Constants";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import pdfImage from "../../assets/images/pdf.png";
import videoImage from "../../assets/images/video.png";
import docImage from "../../assets/images/google-docs.png";
import AttachmentPreview from "../../shared/Components/AttachmentPreview";

const reactSelectCusStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "#fff",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#86b7fe" : "#F0F0F0",
    },
  }),
};

type Props = {
  data: any;
  sendMessage: any;
  messageSendSuccess: boolean;
  getComplaintByIdForChat: any;
  msgReadStatusChange: any;
  editComplaint: any;
  addComments: any;
  userLoginTaskstatus?: any;
  editComplaintSuccess: any;
};

type State = {
  sendValue: any;
  attachmentFiles: any;
  attachmentUrls: any;
  taskStatusOption: any;
  statusoption: any;
  task_status: string;
  AttachmentPreviewStatus: boolean;
  previewUrl: any;
  previewType: any;
  optionselect: any;
};

class AddComplaint extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sendValue: "",
      attachmentFiles: [],
      attachmentUrls: [],
      taskStatusOption: [],
      statusoption: "",
      task_status: "",
      AttachmentPreviewStatus: false,
      previewUrl: "",
      previewType: "",
      optionselect: [],
    };
  }

  resetState = () => {
    this.setState({
      sendValue: "",
      attachmentFiles: [],
      attachmentUrls: [],
      taskStatusOption: [],
      statusoption: "",
      task_status: "",
    });
  };
  componentDidMount(): void {
    document.addEventListener("keydown", this.handleEnterKeyPress);

    if (this.props.data !== null || this.props.data !== undefined) {
      this.props.msgReadStatusChange(this.props.data.id, "complaint");
    }

    this.setState({
      optionselect: this.props.userLoginTaskstatus.map((i: any) => ({
        value: i.id,
        label: i.name,
      })),
    });
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEnterKeyPress);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (this.props.data !== prevProps.data) {
      this.props.msgReadStatusChange(this.props.data.id, "complaint");
    }
    if (this.props.editComplaintSuccess !== prevProps.editComplaintSuccess) {
      this.props.getComplaintByIdForChat(this.props.data.id);
    }
    if (
      this.props.messageSendSuccess === true &&
      this.props.messageSendSuccess !== prevProps.messageSendSuccess
    ) {
      this.setState({ sendValue: "" });
      this.props.getComplaintByIdForChat(this.props.data.id);
    }
  }
  handlestatusComplaint = async (checked: any) => {
    let data: any = [];
    data = {
      client_id: this.props.data.client_id,
      branch_id: this.props.data.branch_id,

      name: this.props.data.name,
      complaint_status: checked,
    };

    if (this.props.data.id) {
      this.props.editComplaint(data, this.props.data.id);
    }
  };

  statusOption = (e: any) => {
    console.log(e, "satus option");
    this.setState({ statusoption: e });
    this.setState({ task_status: e?.value ?? "" });
  };
  handleSendMessage = async () => {
    if (
      this.state.sendValue !== "" &&
      this.state.sendValue.toString().replace(/\s/g, "") !== ""
    ) {
      //   let data: any = {
      //     complaint_id: this.props.data ? this.props.data.id : 0,
      //     message: this.state.sendValue,
      //   };
      //   this.props.sendMessage(data);
      let data = new FormData();
      for (let index = 0; index < this.state.attachmentFiles.length; index++) {
        data.append("attachments", this.state.attachmentFiles[index]);
      }
      data.append("comments", this.state.sendValue);
      // data.append('comments_task_status', this.state.task_status === "" ? null : this.state.task_status);
      if (this.state.task_status !== "") {
        data.append("comments_task_status", this.state.task_status);
      }
      data.append("reference_type", "complaint");
      data.append("reference_id", this.props.data ? this.props.data.id : 0);
      await this.props.addComments(data);

      // Clear selected options and message after sending
      setTimeout(() => {
        this.handleGetComplaintById();
      }, 1000);
      this.resetState();
    }
  };

  handleGetComplaintById = () => {
    // Call your action to get complaint data
    this.props.getComplaintByIdForChat(this.props.data.id);

    // Call the resetState function to reset the state properties
    this.resetState();
  };

  handleImageChange = async (e: any) => {
    let files = [];
    let urls = [];
    files = [...e.target.files];

    let fileurls: any = [];
    await files.map(async (file: any) => {
      fileurls.push({
        url: URL.createObjectURL(file),
        file_type: file.type,
      });
    });
    urls = [...fileurls];
    this.setState({
      attachmentFiles: [...this.state.attachmentFiles, ...files],
    });
    this.setState(
      { attachmentUrls: [...this.state.attachmentUrls, ...urls] },
      () => {
        e.target.value = "";
      }
    );
  };

  handleDeleteImage = (index: number) => {
    const files = this.state.attachmentFiles;
    const urls = this.state.attachmentUrls;
    files.splice(index, 1);
    urls.splice(index, 1);
    this.setState({ attachmentFiles: files });
    this.setState({ attachmentUrls: urls });
  };

  handlePreviewOpen = (file_type: any, url: any) => {
    console.log("output", url);

    if (
      file_type === "image/png" ||
      file_type === "image/jpeg" ||
      file_type === "image/jpg" ||
      file_type === "video/mp4" ||
      file_type === "video/webm" ||
      file_type === "application/pdf"
    ) {
      this.setState({
        AttachmentPreviewStatus: true,
        previewUrl: url,
        previewType: file_type,
      });
    }
  };
  handlePreviewClose = () => {
    this.setState({ AttachmentPreviewStatus: false });
  };
  handleEnterKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      const sendButton = document.getElementById("sendButton");
      if (sendButton) {
        sendButton.click();
      }
    }
  };

  downloadFile = (url: string, name = "image.png") => {
    this.downloadResource(url, name);
  };
  downloadResource = (url: any, filename: any) => {
    if (url) {
      if (!filename) filename = url.split("\\").pop().split("/").pop();
      fetch(url, {
        headers: new Headers({
          Origin: window.location.origin,
        }),
        mode: "cors",
      })
        .then((response) => response.blob())
        .then((blob) => {
          let blobUrl = window.URL.createObjectURL(blob);
          this.forceDownload(blobUrl, filename);
        })
        .catch((e) => console.error(e));
    }
  };
  forceDownload = (blob: any, filename: String) => {
    const a: any = document.createElement("a");
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
  render(): React.ReactNode {
    let taskStatusOption: any = [];
    // //  Special request supervisor Activity options
    // if (Constants.LOGIN_USER_TYPE === Constants.SUPERVISORUTINT || Constants.LOGIN_USER_TYPE === Constants.SUPERVISOR_MANAGERUTINT) {
    //   taskStatusOption = [
    //     { value: Constants.TASK_FOLLOWUP, label: "Followup" },
    //     { value: Constants.TASK_RESOLVED, label: "Resolved" },
    //   ];
    // }
    // // special request QA and admin or BM activity options
    // else if (Constants.MANAGERUTINT === Constants.LOGIN_USER_TYPE || Constants.OPERATION_MANAGERUTINT === Constants.LOGIN_USER_TYPE || Constants.QAUTINT === Constants.LOGIN_USER_TYPE || Constants.QA_MANAGERUTINT === Constants.LOGIN_USER_TYPE || Constants.ADMINUTINT === Constants.LOGIN_USER_TYPE) {
    //   taskStatusOption = [
    //     { value: Constants.TASK_COMPLETED, label: "Completed" },
    //     { value: Constants.TASK_INCOMPLETED, label: "InCompleted" },
    //   ];
    // }

    return (
      <div>
        <div className="row justify-content-between mb-4">
          <div className="col-md-6 col-49per">
            <div className="row chat-complaint-des" style={{ height: "39px" }}>
              <div className="col">
                <p className="m-0 text-grey" style={{ paddingTop: "6px" }}>
                  Created By
                </p>
              </div>
              <div className="col">
                <p className="m-0" style={{ paddingTop: "6px" }}>
                  {this.props.data.user_first_name +
                    " " +
                    this.props.data.user_last_name}
                </p>
              </div>
            </div>
            <div className="row chat-complaint-des" style={{ height: "39px" }}>
              <div className="col">
                <p className="m-0 text-grey" style={{ paddingTop: "8px" }}>
                  Client
                </p>
              </div>
              <div className="col">
                <p className="m-0" style={{ paddingTop: "8px" }}>
                  {this.props.data.client_first_name +
                    " " +
                    this.props.data.client_last_name}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-49per" style={{ height: "39px" }}>
            <div className="row chat-complaint-des" style={{ height: "39px" }}>
              <div className="col">
                <p className="m-0 text-grey" style={{ paddingTop: "8px" }}>
                  Created Date
                </p>
              </div>
              <div className="col">
                <p className="m-0" style={{ paddingTop: "8px" }}>
                  {moment(this.props.data.created_at).format("DD/MM/YYYY")}
                </p>
              </div>
            </div>
            <div className="row chat-complaint-des">
              <div className="col">
                <p className="m-0 text-grey" style={{ paddingTop: "6px" }}>
                  Complaint Status
                </p>
              </div>
              <div className="col">
                <Select
                  className="complaint-status"
                  isMulti={false}
                  options={COMPLAINT_STATUS}
                  value={{
                    value: this.props.data.complaint_status,
                    label:
                      this.props.data.complaint_status === 1 ? "Open" : "Close",
                  }}
                  onChange={(e: any) => {
                    this.handlestatusComplaint(e.value);
                  }}
                  // component={CustomSelect}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="chat-list-wrap row">
          {this.props.data &&
            this.props.data.messages &&
            this.props.data.messages.length > 0 &&
            this.props.data.messages.map((msg: any, inx: number) => (
              <>
                {localStorage.getItem("login_user_id") === msg.user_id ? (
                  <>
                    <div className="col-2"></div>
                    <div className="col-10 mt-2">
                      <p className="mb-1 text-end">
                        <span className="fw-600 ">
                          {msg.first_name + " " + msg.last_name}
                        </span>
                        <span
                          style={{
                            marginLeft: "7px",
                            fontSize: "12px",
                            color: "#7D7D7D",
                          }}
                        >
                          {moment(msg.created_at).format("DD/MM/YYYY hh:mm a")}
                        </span>
                        {msg.task_status !== null && (
                          <span
                            style={{
                              marginLeft: "7px",
                            }}
                            className={
                              msg.task_status + "-badge view-task-comment-badge"
                            }
                          >
                            {msg.task_status}
                          </span>
                        )}
                      </p>
                      <div className="card my-chat">
                        <div className="card-body">
                          <p className="mb-0">{msg.comments}</p>
                          <div className="row">
                            <div className="col-md-12 ">
                              <div className="d-flex row">
                                {msg.attachment &&
                                  msg.attachment.map(
                                    (obj: any, index: number) => (
                                      <div className="img-card mr-16px mb-3">
                                        <span
                                          className="download"
                                          style={{ right: "25px" }}
                                        >
                                          <a
                                            href="javascript:void(0)"
                                            onClick={() =>
                                              this.downloadFile(
                                                Constants.imgUrl +
                                                  obj.file_name,
                                                obj.file_name
                                              )
                                            }
                                          >
                                            <AttachmentDownloadSVG />
                                          </a>
                                        </span>
                                        <div
                                          className="img-preview"
                                          onClick={() =>
                                            this.handlePreviewOpen(
                                              obj.type,
                                              Constants.imgUrl + obj.file_name
                                            )
                                          }
                                        >
                                          <img
                                            className="img-thumb-perview"
                                            src={
                                              obj.type === "application/pdf"
                                                ? pdfImage
                                                : obj.type === "video/mp4" ||
                                                  obj.type === "video/webm"
                                                ? videoImage
                                                : obj.type === "image/png" ||
                                                  obj.type === "image/jpeg" ||
                                                  obj.type === "image/jpg"
                                                ? Constants.imgThumbUrl +
                                                  obj.file_name
                                                : docImage
                                            }
                                          />
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-10 mt-2">
                      <p className="mb-1">
                        <span className="fw-600">
                          {msg.first_name + " " + msg.last_name}
                        </span>
                        <span
                          style={{
                            marginLeft: "7px",
                            fontSize: "12px",
                            color: "#7D7D7D",
                          }}
                        >
                          {moment(msg.created_at).format("DD/MM/YYYY hh:mm a")}
                        </span>
                        {msg.task_status !== null && (
                          <span
                            style={{
                              marginLeft: "7px",
                            }}
                            className={
                              msg.task_status + "-badge view-task-comment-badge"
                            }
                          >
                            {msg.task_status}
                          </span>
                        )}
                      </p>
                      <div className="card">
                        <div className="card-body">
                          <p className="mb-0">{msg.comments}</p>
                          <div className="row">
                            <div className="col-md-12 ">
                              <div className="d-flex row">
                                {msg.attachment &&
                                  msg.attachment.map(
                                    (obj: any, index: number) => (
                                      <div className="img-card mr-16px mb-3">
                                        <span
                                          className="download"
                                          style={{ right: "25px" }}
                                        >
                                          <a
                                            href="javascript:void(0)"
                                            onClick={() =>
                                              this.downloadFile(
                                                Constants.imgUrl +
                                                  obj.file_name,
                                                obj.file_name
                                              )
                                            }
                                          >
                                            <AttachmentDownloadSVG />
                                          </a>
                                        </span>
                                        <div
                                          className="img-preview"
                                          onClick={() =>
                                            this.handlePreviewOpen(
                                              obj.type,
                                              obj.s3_url
                                            )
                                          }
                                        >
                                          <img
                                            className="img-thumb-perview"
                                            src={
                                              obj.type === "application/pdf"
                                                ? pdfImage
                                                : obj.type === "video/mp4" ||
                                                  obj.type === "video/webm"
                                                ? videoImage
                                                : obj.type === "image/png" ||
                                                  obj.type === "image/jpeg" ||
                                                  obj.type === "image/jpg"
                                                ? obj.s3_url
                                                : docImage
                                            }
                                          />
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-2"></div>
                  </>
                )}
              </>
            ))}
          <div className="col-md-2"></div>
          {/* <div className="col-md-10 mt-2">
            <p className="mb-1 text-end">
              <span className="fw-600">admin admin</span>
              <span
                style={{
                  marginLeft: "7px",
                  fontSize: "12px",
                  color: "#7D7D7D",
                }}
              >
                10/07/2023
              </span>
              <span className="resolved-badge view-task-comment-badge">
                Resolved
              </span>
            </p>
            <div className="card my-chat">
              <div className="card-body">
                <p className="mb-0">test</p>
                <div className="d-flex flex-wrap gap-2 chat-upload-img mt-2 mb-2">
                  <img src={ChatDummyImg} />
                  <img src={ChatDummyImg} />
                </div>
              </div>
            </div>
          </div> */}
          <div></div>
        </div>
        <div className="chat-input">
          <div className="d-flex flex-wrap gap-2 mb-3">
            {this.state.attachmentUrls &&
              this.state.attachmentUrls.map((url: any, index: number) => (
                <div className="img-box">
                  <span className="delete">
                    <a onClick={() => this.handleDeleteImage(index)}>
                      <TrashSVG />
                    </a>
                  </span>
                  <div className="img-preview">
                    <img
                      className="img-thumb-perview"
                      src={
                        url.file_type === "application/pdf"
                          ? pdfImage
                          : url.file_type === "video/mp4" ||
                            url.file_type === "video/webm"
                          ? videoImage
                          : url.file_type === "image/png" ||
                            url.file_type === "image/jpeg" ||
                            url.file_type === "image/jpg"
                          ? url.url
                          : docImage
                      }
                    />
                  </div>
                </div>
                // <div className="img-card mr-16px mb-3">
                //     <span className="delete"><a onClick={() => this.handleDeleteImage(index)}><TrashSVG /></a></span>
                //     <div className="img-preview">
                // <img className="img-thumb-perview" src={
                //     url.file_type === "application/pdf" ? pdfImage :
                //         url.file_type === "video/mp4" || url.file_type === "video/webm" ? videoImage :
                //             url.file_type === "image/png" || url.file_type === "image/jpeg" || url.file_type === "image/jpg" ? url.url : docImage
                // } />
                //     </div>
                // </div>
              ))}
            {/* <div className="img-box">
              <img src={ChatDummyImg} />
            </div>
            <div className="img-box">
              <img src={ChatDummyImg} />
            </div> */}
          </div>
          <div className="row">
            <div className="col-md-7">
              <input
                type="text"
                className="form-control"
                placeholder="Send a message below 256 characters"
                maxLength={256}
                value={this.state.sendValue}
                onChange={(e) => this.setState({ sendValue: e.target.value })}
              />
            </div>
            <div className="col-md-5">
              <div className="d-flex gap-2 align-items-center res-mt-3">
                <div className="attachment-btn">
                  <input
                    type="file"
                    multiple
                    accept=".pdf,.PDF,.mp4,.MP4,.webm,.WEBM,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.doc,.DOC,.docx.DOCX"
                    style={{ display: "none" }}
                    onChange={this.handleImageChange}
                    id="contained-button-file"
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      className="btn btn-primary chat-attachment"
                      variant="contained"
                      component="span"
                    >
                      <PaperClipSVG />
                    </Button>
                  </label>
                </div>
                {/* <button className="btn btn-primary chat-attachment">
                  <PaperClipSVG />
                </button> */}
                <button
                  onClick={this.handleSendMessage}
                  className="btn btn-primary login-button send-icon-btn"
                  id="sendButton"
                >
                  <Send />
                </button>
                {/* <select className="form-select">
                  <option value="1">Resolved</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select> */}
                <Select
                  placeholder="Select Status"
                  isMulti={false}
                  options={this.state.optionselect}
                  value={this.state.statusoption}
                  menuPlacement="top"
                  onChange={(e: any) => {
                    this.statusOption(e);
                  }}
                />
                <button
                  onClick={this.handleGetComplaintById}
                  className="btn btn-primary reload-icon-btn"
                >
                  <Reload />
                </button>
              </div>
            </div>
          </div>
        </div>
        <AttachmentPreview
          displayPreview={this.state.AttachmentPreviewStatus}
          previewUrl={this.state.previewUrl}
          previewType={this.state.previewType}
          handlePreviewClose={this.handlePreviewClose}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  processTrigger: state.complaint.processTrigger,
  addComplaintSuccess: state.complaint.addComplaintSuccess,
  progressPercentage: state.complaint.progressPercentage,
  data: state.complaint.selectedComplaintData,
  messageSendSuccess: state.complaint.messageSendSuccess,
  userLoginTaskstatus: state.sidebar.userLoginTaskstatus,
  editComplaintSuccess: state.complaint.editComplaintSuccess,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    listAllClient: bindActionCreators(listAllClient, dispatch),
    listAllBranch: bindActionCreators(listAllBranch, dispatch),
    editComplaint: bindActionCreators(editComplaint, dispatch),
    addComplaint: bindActionCreators(addComplaint, dispatch),
    sendMessage: bindActionCreators(sendMessage, dispatch),
    addComments: bindActionCreators(addComments, dispatch),
    getComplaintByIdForChat: bindActionCreators(
      getComplaintByIdForChat,
      dispatch
    ),
    msgReadStatusChange: bindActionCreators(msgReadStatusChange, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddComplaint);
