import { Dispatch, bindActionCreators } from "redux";
import { InvoiceTableProps, InvoiceTableState } from "../../types/invoice.type";
import React, { Component } from "react";

import InvoiceCard from "./InvoiceCard";
import { Invoice_Report_ById } from "../../shared/Reducers/InvoiceReducer";
import { RootState } from "../../index";
import { connect } from "react-redux";

class InvoiceTable extends Component<InvoiceTableProps, InvoiceTableState> {
  constructor(props: InvoiceTableProps) {
    super(props);
    this.state = {
      show_Modal: false,
    };
  }

  handleViewInvoice = async (id: string) => {
    try {
      await this.props.Invoice_Report_ById(
        id,
        this.props.invoice_from_date === ""
          ? "all"
          : this.props.invoice_from_date,
        this.props.invoice_to_date === "" ? "all" : this.props.invoice_to_date
      );
    } catch (error) {
      console.log(error);
    }
  };
  handleModal_Invoice = (status: boolean) => {
    this.setState({ show_Modal: status });
  };

  render() {
    return (
      <tbody>
        {this.props.data.map((invoice: any, index: any) => {
          return (
            <InvoiceCard
              key={index + invoice.id}
              invoice_report={invoice}
              handleViewInvoice={this.handleViewInvoice}
            />
          );
        })}
      </tbody>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  invoice_to_date: state.invoice.invoice_to_date,
  invoice_from_date: state.invoice.invoice_from_date,
});
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    Invoice_Report_ById: bindActionCreators(Invoice_Report_ById, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceTable);
