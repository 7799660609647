import { Dispatch, AnyAction } from "redux";
import { AppDispatch } from "../..";
import { TAttendanceTable } from "../../types/user.type";
import {
  API_GET_ALL_CLIENTS,
  API_GET_ALL_CLIENTS_SUCCESS,
  API_GET_ALL_CLIENTS_FAIL,
  API_GET_ALL_BRANCH_FAIL,
  AttendanceTemplateState,
  API_GET_ALL_ATTENDANCES,
  API_GET_ALL_ATTENDANCES_SUCCESS,
  API_GET_ALL_ATTENDANCES_FAIL,
  API_GET_ATTENDANCE_BY_ID,
  API_GET_ATTENDANCE_BY_ID_SUCCESS,
  API_GET_ATTENDANCE_BY_ID_FAIL,
  API_ADD_ATTENDANCE,
  API_ADD_ATTENDANCE_SUCCESS,
  API_ADD_ATTENDANCE_FAIL,
  API_EDIT_ATTENDANCE,
  API_EDIT_ATTENDANCE_SUCCESS,
  API_EDIT_ATTENDANCE_FAIL,
  API_DELETE_ATTENDANCE,
  API_DELETE_ATTENDANCE_SUCCESS,
  API_DELETE_ATTENDANCE_FAIL,
  UPDATE_ADD_ATTENDANCE_DLG_STATUS,
  UPDATE_EDIT_ATTENDANCE_DLG_STATUS,
  UPDATE_DELETE_ATTENDANCE_DLG_STATUS,
  UPDATE_ATTENDANCE_FETCH_STATUS,
  FILTER_ATTENDANCE,
  UPDATE_ATTENDANCES,
  IEditAttendance,
  API_ADD_ATTENDANCE_PROGRESS,
  API_EDIT_ATTENDANCE_PROGRESS,
  API_DELETE_ATTACHMENT,
  API_VIEW_ATTENDANCE_BY_ID,
  API_VIEW_ATTENDANCE_BY_ID_FAIL,
  API_VIEW_ATTENDANCE_BY_ID_SUCCESS,
  UPDATE_VIEW_ATTENDANCE,
  ATTENDANCE_SORT
} from "../ActionTypes";

const initialState: AttendanceTemplateState = {
  cleanerList: [],
  clientList: [],
  addAttendanceStatus: false,
  addAttendanceSuccess: "",
  deleteAttendanceStatus: false,
  deleteAttendanceSuccess: "",
  editAttendanceStatus: false,
  editAttendanceSuccess: "",
  attendanceFetchStatus: false,
  selectedAttendanceData: null,
  attendanceData: [],
  filteredAttendanceData: [],
  id: "",
  total_count: 0,
  addAttendanceError: "",
  deleteAttendanceError: "",
  editAttendanceError: "",
  processTrigger: false,
  progressPercentage: 0,
  viewAttendanceData: null,
  viewAttendanceStatus: false,
  attendanceSortBy: "",
  attendanceOrderBy: "",
};

export const AttendanceReducer = (
  state: AttendanceTemplateState = initialState,
  action: AnyAction
): AttendanceTemplateState => {
  switch (action.type) {
    case UPDATE_ATTENDANCES:
      return { ...state };
    case API_GET_ALL_CLIENTS:
      return { ...state };
    case API_GET_ALL_CLIENTS_SUCCESS:
      let clientData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "clients" in data && data.clients) {
          clientData = data.clients;
        }
      }
      return { ...state, clientList: clientData };
    case API_GET_ALL_CLIENTS_FAIL:
      return { ...state };
    case API_GET_ALL_BRANCH_FAIL:
      return { ...state };
    case UPDATE_ATTENDANCE_FETCH_STATUS:
      return { ...state, attendanceFetchStatus: action.payload };
    case API_GET_ALL_ATTENDANCES:
      return { ...state };
    case API_GET_ALL_ATTENDANCES_SUCCESS:
      let attendanceData = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "attendences" in data && data.attendences) {
          attendanceData = data.attendences;
        }
      }
      let total_count = 0;
      if (action.payload) {
        const { data } = action.payload;
        if (data && "total_count" in data && data.total_count) {
          total_count = data.total_count;
        }
      }
      return {
        ...state,
        total_count: total_count,
        editAttendanceStatus: false,
        addAttendanceStatus: false,
        deleteAttendanceStatus: false,
        filteredAttendanceData: attendanceData,
      };
    case API_GET_ALL_ATTENDANCES_FAIL:
      return { ...state };
    case API_GET_ATTENDANCE_BY_ID:
      return { ...state, id: action.payload.id };
    case API_GET_ATTENDANCE_BY_ID_SUCCESS:
      let attendances = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "attendance" in data && data.attendance) {
          attendances = data.attendance;
        }
      }
      return {
        ...state,
        selectedAttendanceData: attendances,
        editAttendanceStatus: true,
        attendanceFetchStatus: false,
      };
    case API_GET_ATTENDANCE_BY_ID_FAIL:
      return { ...state };
    case API_VIEW_ATTENDANCE_BY_ID:
      return { ...state, id: action.payload.id };
    case API_VIEW_ATTENDANCE_BY_ID_SUCCESS:
      let attendance = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "attendance" in data && data.attendance) {
          attendance = data.attendance;
        }
      }
      return {
        ...state,
        viewAttendanceData: attendance,
        viewAttendanceStatus: true,
        attendanceFetchStatus: false,
      };
    case API_VIEW_ATTENDANCE_BY_ID_FAIL:
      return { ...state };
    case UPDATE_VIEW_ATTENDANCE:
      return { ...state, viewAttendanceStatus: action.payload };
    case API_ADD_ATTENDANCE:
      let values: boolean = false;
      if (action.payload.request.data.FormData === undefined) {
        values = true;
      }
      return {
        ...state,
        addAttendanceSuccess: "",
        addAttendanceError: "",
        processTrigger: values,
      };
    case API_ADD_ATTENDANCE_SUCCESS:
      let message = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          message = data.message;
        }
      }
      return {
        ...state,
        addAttendanceStatus: false,
        addAttendanceSuccess: message,
        processTrigger: false,
        progressPercentage: 0,
      };
    case API_ADD_ATTENDANCE_FAIL:
      let err = "";
      if (
        "error" in action &&
        action.error &&
        "response" in action.error &&
        action.error.response &&
        "data" in action.error.response &&
        action.error.response.data &&
        "message" in action.error.response.data
      ) {
        err = action.error.response.data.message;
      }
      return { ...state, addAttendanceError: err };
    case API_ADD_ATTENDANCE_PROGRESS:
      return { ...state, progressPercentage: action.payload };
    case API_EDIT_ATTENDANCE_PROGRESS:
      return { ...state, progressPercentage: action.payload };
    case UPDATE_ADD_ATTENDANCE_DLG_STATUS:
      return { ...state, addAttendanceStatus: action.payload };
    case API_EDIT_ATTENDANCE:
      let value: boolean = false;
      if (action.payload.request.data.FormData === undefined) {
        value = true;
      }
      return { ...state, editAttendanceSuccess: "", attendanceFetchStatus: false, processTrigger: value };
    case API_EDIT_ATTENDANCE_SUCCESS:
      let messages = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          messages = data.message;
        }
      }
      return {
        ...state,
        editAttendanceStatus: false,
        attendanceFetchStatus: true,
        editAttendanceSuccess: messages,
        processTrigger: false,
        progressPercentage: 0,
      };
    case API_EDIT_ATTENDANCE_FAIL:
      let error = "";
      if (
        "error" in action &&
        action.error &&
        "response" in action.error &&
        action.error.response &&
        "data" in action.error.response &&
        action.error.response.data &&
        "message" in action.error.response.data
      ) {
        error = action.error.response.data.message;
      }
      return { ...state, editAttendanceError: error };
    case UPDATE_EDIT_ATTENDANCE_DLG_STATUS:
      return { ...state, editAttendanceStatus: action.payload };
    case API_DELETE_ATTENDANCE:
      return { ...state, deleteAttendanceSuccess: "" };
    case API_DELETE_ATTENDANCE_SUCCESS:
      let msg = [];
      if (action.payload) {
        const { data } = action.payload;
        if (data && "message" in data && data.message) {
          msg = data.message;
        }
      }
      return {
        ...state,
        deleteAttendanceStatus: false,
        attendanceFetchStatus: true,
        deleteAttendanceSuccess: msg,
      };
    case API_DELETE_ATTENDANCE_FAIL:
      return { ...state };
    case UPDATE_DELETE_ATTENDANCE_DLG_STATUS:
      return { ...state, deleteAttendanceStatus: action.payload };
    case FILTER_ATTENDANCE:
      const filterAttendance: TAttendanceTable[] = state.filteredAttendanceData.filter(
        (obj: any) => {
          return (
            obj.shift_name.toLowerCase().includes(action.payload) ||
            obj.last_name.toLowerCase().includes(action.payload) ||
            obj.first_name.toLowerCase().includes(action.payload)
          );
        }
      );
      return { ...state, filteredAttendanceData: filterAttendance };
    case ATTENDANCE_SORT:
      return { ...state, attendanceSortBy: action.payload.sort, attendanceOrderBy: action.payload.order };
    default:
      return state;
  }
};
// update attendance
export function updateAttendances(data: any) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_ATTENDANCES,
      payload: data,
    });
  };
}

// get attendance by id
export function getAttendanceById(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ATTENDANCE_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `cleaner-attendence/${id}` : `cleaner-attendence`,
        },
        id,
      },
    });
  };
}

// VIEW attendance by id
export function viewAttendanceById(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_VIEW_ATTENDANCE_BY_ID,
      payload: {
        request: {
          method: "get",
          url: id ? `cleaner-attendence/${id}` : `cleaner-attendence`,
        },
        id,
      },
    });
  };
}

export function updateViewAttendance(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_VIEW_ATTENDANCE,
      payload: status,
    });
  };
}


// get all attendances for table
export function getAllAttendances(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_GET_ALL_ATTENDANCES,
      payload: {
        request: {
          method: "get",
          url: queryStr ? `cleaner-attendence?${queryStr}` : `cleaner-attendence?`,
        },
      },
    });
  };
}

// add attendance
export function addAttendance(payload: FormData) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_ADD_ATTENDANCE,
      payload: {
        request: {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            contentType: false,
          },
          method: "post",
          url: `cleaner-attendence`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_ADD_ATTENDANCE_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

export function updateAddAttendance(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_ADD_ATTENDANCE_DLG_STATUS,
      payload: status,
    });
  };
}

// edit attendance
export function editAttendance(payload: any, id: number) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: API_EDIT_ATTENDANCE,
      payload: {
        request: {
          method: "put",
          url: `cleaner-attendence/${id}`,
          data: payload,
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            return dispatch({
              type: API_EDIT_ATTENDANCE_PROGRESS,
              payload: progress,
            });
          },
        },
      },
    });
  };
}

// update edit employee
export function updateEditAttendanceDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_EDIT_ATTENDANCE_DLG_STATUS,
      payload: status,
    });
  };
}

// Fetch Attendance after add, edit, delete
export function updateAttendanceFetchStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_ATTENDANCE_FETCH_STATUS,
      payload: status,
    });
  };
}

// delete attendance
export function deleteAttendance(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: API_DELETE_ATTENDANCE,
      payload: {
        request: {
          method: "delete",
          url: `cleaner-attendence/${id}`,
        },
      },
    });
  };
}

// update delete attendance
export function updateDeleteAttendanceDlgStatus(status: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_DELETE_ATTENDANCE_DLG_STATUS,
      payload: status,
    });
  };
}

// Filter value
export function filterAttendance(filterVal: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: FILTER_ATTENDANCE,
      payload: filterVal,
    });
  };
}

// handleAttendanceSort
export function handleAttendanceSort(sort: any, order: any) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ATTENDANCE_SORT,
      payload: {
        sort: sort,
        order: order
      },
    });
  };
}
